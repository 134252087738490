import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { TeamUiDto } from '../../models/user/TeamUiDto';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CompanyUiDto } from '../../models/CompanyUiDto';
import { OnboardingService } from '../../services/onboarding.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-onboarding-new-team',
  templateUrl: './onboarding-new-team.component.html',
  styleUrls: ['./onboarding-new-team.component.sass']
})
export class OnboardingNewTeamComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;

  selectedCompanyUiDto?: CompanyUiDto;

  isLoading: boolean = false;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  
  selectedCompanySubscription$?: Subscription;
  
  constructor(
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private ref: DynamicDialogRef
  ) {
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      description: [''],
    });
  }

  ngOnInit(): void {
    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
      } else {
        this.selectedCompanyUiDto = undefined;
      }
    })
  }
  
  get fc() { return this.formGroup.controls; }

  closeModal() {
    this.ref.close();
  }

  mergeTeamUiDto() {
    let formValue = this.formGroup.value;

    let teamUiDto = new TeamUiDto();
    teamUiDto.orgCode = this.selectedCompanyUiDto?.orgCode;
    teamUiDto.companyCode = this.selectedCompanyUiDto?.companyCode;
    teamUiDto.name = formValue.name;
    teamUiDto.description = formValue.description;

    return teamUiDto;
  }

  saveTeamUiDto() {
    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    let teamUiDto = this.mergeTeamUiDto()
    this.isLoading = true;

    this.onboardingService.saveTeamUiDto(teamUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let newTeamUiDto = apiResponseDto.data as TeamUiDto;
          this.onboardingService.updateTeamUiDto(newTeamUiDto)

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving Team");
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }    
  }
}
