<div class="bg-white-new">
  <div class="col-12 container mt-4 px-0">
    <div class=" nav-bg text-start"  >
    <ul class="" style="list-style: none;" class="ps-0">
      <li class="list-class"(click)="toggleDetailsTab(1)" [ngClass]="currentTab == 1 ? 'btn-active' : 'btn-default'">
        Auction
      </li>
      <li class="list-class" (click)="toggleDetailsTab(2)" [ngClass]="currentTab == 2 ? 'btn-active' : 'btn-default'">
        Registration
      </li>
      <li class="list-class"  (click)="toggleDetailsTab(3)" [ngClass]="currentTab == 3 ? 'btn-active' : 'btn-default'">
       Bidding
      </li>
      <li class="list-class"  (click)="toggleDetailsTab(4)" [ngClass]="currentTab == 4 ? 'btn-active' : 'btn-default'">
        Question
      </li>
      <li class="list-class" (click)="toggleDetailsTab(5)" 
      [ngClass]="currentTab == 5 ? 'btn-active' : 'btn-default'" *ngIf="activeStage == 'POST_EVENT'">
        Post Auction
      </li>
    </ul>
  </div>
   <!-- <div class="col-9 m-auto background-nav text-center">
    <div class="row justify-content-center">
      <div class="col-xl-2 col-lg-3 col-md-4 col-6 c-pointer" 
      (click)="toggleDetailsTab(1)" [ngClass]="currentTab == 1 ? 'btn-active' : 'btn-default'">
       SetUp
      </div>
      <div class="col-xl-2 col-lg-3 col-md-4 col-6 c-pointer" 
      (click)="toggleDetailsTab(2)" [ngClass]="currentTab == 2 ? 'btn-active' : 'btn-default'">
        Registration
      </div>
      <div class="col-xl-2 col-lg-3 col-md-4 col-6 c-pointer" 
      (click)="toggleDetailsTab(3)" [ngClass]="currentTab == 3 ? 'btn-active' : 'btn-default'">
        Bidding
      </div>
      <div class="col-xl-2 col-lg-3 col-md-4 col-6 c-pointer" 
      (click)="toggleDetailsTab(4)" [ngClass]="currentTab == 4 ? 'btn-active' : 'btn-default'">
        Question
      </div>
      <div class="col-xl-2 col-lg-3 col-md-4 col-6 c-pointer" 
      (click)="toggleDetailsTab(5)" [ngClass]="currentTab == 5 ? 'btn-active' : 'btn-default'" *ngIf="activeStage == 'POST_EVENT'">
        Award
      </div>
    </div>
   </div> -->

    <!-- <div class="">
      <div class="col-md-10 col-12 m-md-auto py-3 bg-white text-center">
        <button class="btn btn-sm me-3  button-Active mb-2 zoom " 
        (click)="toggleDetailsTab(1)" [ngClass]="currentTab == 1 ? 'selected-tiles' : 'btn-default'">
          <h6 class="heading-h6"  [ngClass]="{'text-white': currentTab == 1}">AUCTION</h6>
        </button>
        <button class="btn  btn-sm me-3  button-Active mb-2 zoom" (click)="toggleDetailsTab(2)" [ngClass]="currentTab == 2 ? 'selected-tiles' : 'btn-default'">
          <h6 class="heading-h6" [ngClass]="{'text-white': currentTab == 2}">REGISTRATION</h6>
        </button>
        <button class="btn  btn-sm me-3   button-Active mb-2 zoom" (click)="toggleDetailsTab(3)" [ngClass]="currentTab == 3 ? 'selected-tiles' : 'btn-default'">
          <h6 class="heading-h6" [ngClass]="{'text-white': currentTab == 3}">BIDDING</h6>
        </button>
        <button class="btn  btn-sm me-3  button-Active mb-2 zoom" (click)="toggleDetailsTab(4)" [ngClass]="currentTab == 4 ? 'selected-tiles' : 'btn-default'">
          <h6 class="heading-h6" [ngClass]="{'text-white': currentTab == 4}">QUESTIONS</h6>
        </button>

        <button class="btn  btn-sm me-3 button-Active mb-2 zoom" (click)="toggleDetailsTab(5)" [ngClass]="currentTab == 5 ? 'selected-tiles' : 'btn-default'">
          <h6 class="heading-h6" [ngClass]="{'text-white': currentTab == 5}">POST AUCTION</h6>
        </button>
      </div>

    </div> -->
  </div>
<div class="container">
  <!-- Details -->
  <div *ngIf="_renderView$ | async">
    <app-creator-auction-title *ngIf="currentTab == 1"></app-creator-auction-title>
   <div class="mt-5">
    <app-creator-lot-information *ngIf="currentTab == 1"></app-creator-lot-information>


    <app-creator-multi-lot *ngIf="currentTab == 1"></app-creator-multi-lot>
  </div>
  </div>

  <!-- Bidder -->
  <app-auction-creator-registration *ngIf="currentTab == 2 && (_renderView$ | async)"></app-auction-creator-registration>

  <!-- Transaction -->
  <app-auction-creator-bidder *ngIf="currentTab == 3 && (_renderView$ | async)"></app-auction-creator-bidder>

  <app-questions-update *ngIf="currentTab == 4 && (_renderView$ | async)"></app-questions-update>

  <!-- <app-admin-post-auction-view *ngIf="currentTab == 5 && (_renderView$ | async)"></app-admin-post-auction-view> -->
  <app-auction-awarded-bidders *ngIf="currentTab == 5 && (_renderView$ | async)"></app-auction-awarded-bidders>


  <div class="d-grid gap-2 mb-3" *ngIf="!(_renderView$ | async)">
    <div class="e-spinner center-spinner"></div>
  </div>

</div>

</div>
<ng-template #bannerImage>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>DISABLE AUCTION HOUSE</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <form>
       <div class="form-floating mb-3">
         <input type="email" class="form-control" placeholder="Auction Title" id="aucTitle">
         <label for="aucTitle">Upload Banner Image</label>
       </div>
      </form>

     </div>
   </div>
  </div>
</ng-template>
