import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, BehaviorSubject } from 'rxjs';
import { HtmlViewComponent } from 'src/app/shared/components/html-view/html-view.component';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AddOrganizationComponent } from '../../sadmin/add-organization/add-organization.component';

@Component({
  selector: 'app-admin-auction-house-section',
  templateUrl: './admin-auction-house-section.component.html',
  styleUrls: ['./admin-auction-house-section.component.sass']
})
export class AdminAuctionHouseSectionComponent  implements OnInit, OnDestroy {
  auctionHouse?: AuctionHouseDto
  currentViewSubscription$?: Subscription;
  currentAuctionHouseSubscription$?: Subscription;
  isShowDescription$ = new BehaviorSubject<boolean>(false);
  @ViewChild('descriptionRef') descriptionRef?: ElementRef<HTMLParagraphElement>

  constructor(
    private ngbModal: NgbModal,
    private adminDashboardService: AdminDashboardService
  ) {}

  ngOnInit(): void {
    this.currentAuctionHouseSubscription$ = this.adminDashboardService.getCurrentAuctionHouse$.subscribe((data) => {
      if (data) {
        this.auctionHouse = data;
      }
    })
  }
  ngAfterViewInit(): void {
    this.descriptionRef!.nativeElement.innerHTML = this.auctionHouse?.description!;
  }
  openEditAuctionHouse() {
    let modalRef = this.ngbModal.open(AddOrganizationComponent, { size: 'md', backdrop: 'static', keyboard: false , centered: true });
    modalRef.componentInstance.auctionHouse = this.auctionHouse;
  }

  ngOnDestroy(): void {
    if (this.currentViewSubscription$) {
      this.currentViewSubscription$.unsubscribe();
    }
    if (this.currentAuctionHouseSubscription$) {
      this.currentAuctionHouseSubscription$.unsubscribe();
    }
  }
  showOrHideDescription(){
    this.isShowDescription$.next(!this.isShowDescription$.value);
    if (!this.isShowDescription$.value) {
      this.descriptionRef!.nativeElement.style.height = '120px'
    } else {
      this.descriptionRef!.nativeElement.style.height = this.descriptionRef!.nativeElement.scrollHeight + 'px';
    }
  }

  formattedInputDescription(input: string){
    if (input) {
      let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
      formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
      formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
      formattedInput = formattedInput.replace(/&amp;/g, '&');
      return formattedInput;
    }
    return "";
  }

  truncateText(input: string) {
    let formattedData = this.formattedInputDescription(input);
    if (formattedData) {
      return ApplicationUtils.truncateString(formattedData, 150);
    }
    return '';
  }

  openShowMoreModal(title: string, description: string) {
    let modalRef = this.ngbModal.open(HtmlViewComponent, { size: 'md', backdrop: 'static', keyboard: false , centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.content = description;
  }

}

