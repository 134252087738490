import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminAuctionBidderRegistrationDto } from 'src/app/shared/models/user/AdminAuctionBidderRegistrationDto ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';

@Component({
  selector: 'app-auction-registration-summary',
  templateUrl: './auction-registration-summary.component.html',
  styleUrls: ['./auction-registration-summary.component.sass']
})
export class AuctionRegistrationSummaryComponent implements OnInit, OnDestroy {

  bidderRegistrationDtoList: Array<AdminAuctionBidderRegistrationDto> = [];
  auctionBidderRegistrationSubscription$?: Subscription;

  constructor(

    private adminDashboardService: AdminDashboardService
  ) { }

  ngOnInit(): void {

    this.auctionBidderRegistrationSubscription$ = this.adminDashboardService.getAuctionBidderRegistrationDtos$.subscribe(data => {
      if (data && data.length > 0) {
        this.bidderRegistrationDtoList = data;

      }
    })
  }



  countSummary(status: string): number {
    let toReturn = 0;

    if (this.bidderRegistrationDtoList && this.bidderRegistrationDtoList.length > 0) {
      if (status == 'ALL') {
        toReturn = this.bidderRegistrationDtoList.length;
      } else {
        let filteredList = this.bidderRegistrationDtoList.filter((item => item?.auctionRegistrationStatus == status));
        toReturn = filteredList.length;
      }
    }
    return toReturn;
  }

  ngOnDestroy(): void {
    if (this.auctionBidderRegistrationSubscription$) {
      this.auctionBidderRegistrationSubscription$.unsubscribe();
    }
  }

}
