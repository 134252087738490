
import { Component, OnInit, OnDestroy } from '@angular/core';

import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { SourcingEventDto } from 'src/app/shared/models/user/SourcingEventDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { Router } from '@angular/router';
import { TimeZoneDto } from 'src/app/shared/models/TimeZoneDto';
import { Store } from '@ngrx/store';
import { NavigationEventsDto } from 'src/app/shared/models/NavigationEventsDto';
import { NavigationActions } from 'src/app/shared/state-management/navigation/navigation.actions';

@Component({
  selector: 'app-admin-post-sourcing-section',
  templateUrl: './admin-post-sourcing-section.component.html',
  styleUrls: ['./admin-post-sourcing-section.component.sass']
})
export class AdminPostSourcingSectionComponent implements OnInit, OnDestroy {

  ctrlSearchAuction: FormControl = new FormControl('');


  allSourcingEvents: SourcingEventDto[] = []
  allSourcingEvents$ = new BehaviorSubject<SourcingEventDto[]>([]);

  pageSizeAuctions: number = 50
  pageAuctions: number = 1

  constructor(
    private dataRedirectionService: DashboardRedirectionService,
    private auctionService: AdminSourcingEventsDataHolderService,
    private adminService: AdminDashboardService,
    private router: Router,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.auctionService.getPostEventsList$.subscribe((data) => {
      if (data) {
        this.allSourcingEvents = data;
        this.paginateAuctionsList();
      }
    })
    this.ctrlSearchAuction.valueChanges.subscribe(() => {
      this.searchSourcing()
    })

  }


  async navigateToPostAuction(eventNo: number) {
    // await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(auction.auctionId!);

    // this.dataRedirectionService.storeNavigationContext(
    //   ApplicationConstants.ADMIN,
    //   ApplicationConstants.AUCTION_CREATOR_PAGE,
    //   ApplicationConstants.POST_AUCTION_TAB,
    //   eventNo.toString(),
    //   undefined
    // );

    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.ADMIN;
    navigation.pageName = ApplicationConstants.AUCTION_CREATOR_PAGE;
    navigation.tabName = ApplicationConstants.POST_AUCTION_TAB;
    navigation.resourceNo = eventNo.toString();

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));

    this.router.navigate(['/redirectToAuction'], { skipLocationChange: true });
  }


  resetAuction() {
    this.ctrlSearchAuction.patchValue('');
  }

  changePageSize(size: number, type: string) {
    this.pageSizeAuctions = size;
  }

  paginateAuctionsList() {
    this.ctrlSearchAuction.reset();
    if (this.allSourcingEvents && this.allSourcingEvents.length > 0) {
      this.allSourcingEvents$.next(this.allSourcingEvents.map((history, i) => ({ id: i + 1, ...history })).slice(
        (this.pageAuctions - 1) * this.pageSizeAuctions,
        (this.pageAuctions - 1) * this.pageSizeAuctions + this.pageSizeAuctions,
      ));
    } else {
      this.allSourcingEvents$.next([]);
    }
  }


  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getDisplayDateAdmin(date, time);
  }

  getDisplayTimezone(timezone: string) {
    let timeZones = this.adminService.getMasterTimezones$.value as TimeZoneDto[];
    if (timeZones && timeZones.length > 0) {
      return timeZones.find(item => item.locale == timezone)?.fullName;
    }
    return '';
  }

  // Filter
  searchSourcing() {
    let searchText = this.ctrlSearchAuction.value;
    let allPostAuctions = [...this.allSourcingEvents];

    if (searchText && searchText.trim() != '') {
      let tempAllPostAuctions = allPostAuctions.filter((item) => item.eventName?.toLowerCase().includes(searchText.trim().toLowerCase())
        || item.sequenceNo?.toString()?.includes(searchText.trim()));
      this.allSourcingEvents$.next(tempAllPostAuctions);
    } else {
      this.allSourcingEvents$.next(allPostAuctions);
    }
  }

  ngOnDestroy(): void {
    this.adminService._notAwardedAuctionNotification$.next(null);
  }
}
