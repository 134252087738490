import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionSaveWrapperDto } from 'src/app/shared/models/user/AuctionSaveWrapperDto';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.sass']
})
export class BankDetailsComponent {

  isEdit:boolean =  false;
  selectedAuctionSubscription$?: Subscription;
  auctionEntityDto? : AuctionEntityDto;
  formGroup: FormGroup;
  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  isLoading?: boolean;
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);

  constructor(
    private transalteService: TranslateService,
    private adminDashboardService: AdminDashboardService,
    private formBuilder: FormBuilder,
    private adminSourcingEventsDataHolderService : AdminSourcingEventsDataHolderService,
    private ngbModal: NgbModal) {

      this.formGroup = this.formBuilder.group({
        bankName: new FormControl('', Validators.required),
        accountNo: new FormControl('', [Validators.required, Validators.pattern(Pattern.onlyNumber)]),
      })
  }

  ngOnInit(): void {
    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(auction => {
      this.auctionEntityDto = auction!;
      if(this.auctionEntityDto && this.auctionEntityDto.accountNo && this.auctionEntityDto.bankName){
        this.isEdit = true;

      }
    })

  }


  mergeAuctionEntityDto(formValue: any){
    let auctionEntityDto = ApplicationUtils.clone(this.auctionEntityDto);
    auctionEntityDto!.accountNo = formValue.accountNo;
    auctionEntityDto!.bankName = formValue.bankName;
    return auctionEntityDto;
  }

  get fc(): any { return this.formGroup.controls; }

  saveBankDetails() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    if(this.formGroup.invalid){
      this.formGroup.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    let formValue = this.formGroup.value;
    let auctionEntityDto = this.mergeAuctionEntityDto(formValue);

    this.adminDashboardService.saveAuctionDetails(auctionEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionSaveWrapperDto = apiResponseDto.data  as AuctionSaveWrapperDto;
          let auctionValidationErrorCodeDtoList =  auctionSaveWrapperDto.auctionValidationErrorCodeDtos;
          if(!auctionValidationErrorCodeDtoList ||  auctionValidationErrorCodeDtoList.length == 0){
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
          }else{
          this.isLoading = false;
          this.closeModal();
          this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
          this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this.isLoading = false;
          // this._isSaveButtonEnable$.next(true);

        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
        // this._isSaveButtonEnable$.next(true);

      }
    })
  }

  openErrorModel() {
    let errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
      });
    errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }

    getErrorMsg(error: AuctionValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.transalteService)
  }


  ngOnDestroy(): void {

    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
  }



  openBankDetailModal(content: any) {
    this.formGroup.reset();
    this.fc.bankName.setValue(this.auctionEntityDto?.bankName);
    this.fc.accountNo.setValue(this.auctionEntityDto?.accountNo);
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }


  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}

