import { GraphCharEnum } from "../enums/GraphCharEnum";

export class RequestGraphChart {
  id?: GraphCharEnum;
  auctionId?: string;
  lotIds?: Array<string>;

  startDate?: string;
  endDate?: string;

  hotBid?: number;
}