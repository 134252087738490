<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>LEGAL</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" autocomplete="off" [formGroup]="formGroup" (keyup.enter)="saveLegalPolicy(false)">
        <label for="privacyEditor" class="text-area-label">CONFIDENTIALITY</label>
        <div class="form-group mb-3">
          <angular-editor #privacyEditor formControlName="confidentialAgreement" class="form-control" id="privacyEditor"
            [config]="config"></angular-editor>
          <div
            *ngIf="(_validationErrorPresent$ | async) || fc.confidentialAgreement.invalid && (fc.confidentialAgreement.dirty || fc.confidentialAgreement.touched)">
            <p class="e-fielderror e-fielderror-required" *ngIf="fc.confidentialAgreement.errors?.required">Required</p>
          </div>
        </div>
        <label for="termsConditionsEditor" class="text-area-label">TERMS AND CONDITIONS</label>
        <div class="form-group mb-3">
          <angular-editor #termsConditionsEditor formControlName="termsConditions" class="form-control" id="termsConditionsEditor"
            [config]="config"></angular-editor>
          <div
            *ngIf="(_validationErrorPresent$ | async) || fc.termsConditions.invalid && (fc.termsConditions.dirty || fc.termsConditions.touched)">
            <p class="e-fielderror e-fielderror-required" *ngIf="fc.termsConditions.errors?.required">Required</p>
          </div>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Legal Policy saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="'Error while saving legal policy. Try again.'"></app-message-toast>
        </div>

        <div class="mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="saveLegalPolicy(true)">SAVE</button>
        </div>
      </form>
    </div>
  </div>
</div>
