import { number } from 'echarts';
import { AdminDashboardService } from './../../../shared/services/admin-dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BidIncrementalWrapperDto } from 'src/app/shared/models/user/BidIncrementalWrapperDto';
import { BidIncrementalDto } from 'src/app/shared/models/user/BidIncrementalDto';
import { StringMinValidator } from 'src/app/shared/validators/string-min.directive';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { AuctionLotSaveWrapperDto } from 'src/app/shared/models/user/AuctionLotSaveWrapperDto';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { AuctionValidationService } from 'src/app/shared/services/auction-validation.service';
import { RankPriceConfigEnum } from 'src/app/shared/enums/RankPriceConfigEnum';
import { Pattern } from 'src/app/shared/util/Patterns';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { BidCalculationMethodEnum } from 'src/app/shared/enums/BidCalculationMethodEnum';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';

@Component({
  selector: 'app-auction-lot-bid-details',
  templateUrl: './auction-lot-bid-details.component.html',
  styleUrls: ['./auction-lot-bid-details.component.sass']
})
export class AuctionLotBidDetailsComponent implements OnInit, OnDestroy {

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>("");
  _isMultiLineError$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(false);

  auctionEntityDto?: AuctionEntityDto;
  lotEntityDto?: AuctionLotEntityDto;
  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];

  isSubmitted: boolean = false;
  isLoading: boolean = false;
  maxBidPlaced: number = 0;
  highestBidPrice: number = 0;
  incrementBidValue?: string;
  bidIncrementalList?: Array<BidIncrementalDto> = [];
  selectedAuctionSubscription$?: Subscription;
  selectedLotSubscription$?: Subscription;
  isShowWarnAndBlock: boolean = false;
  multipleBidIncrementDecrementAllowed?: boolean = false;

  rankPriceConfig: typeof RankPriceConfigEnum = RankPriceConfigEnum;

  formGroup: FormGroup
  radioBidIncrement: FormControl = new FormControl(true, Validators.required);
  singleBidIncrement: FormControl = new FormControl('', Validators.required);
  infinityBidIncrement: FormControl = new FormControl('', Validators.required);
  enableAutoSeller: FormControl = new FormControl(false, Validators.required);
  ctrlBidCalculationMethod: FormControl = new FormControl(false, Validators.required);
  percentBidIncrement: FormControl = new FormControl('', [Validators.required, Validators.pattern(Pattern.percentage)]);

  constructor(
    private fb: FormBuilder,
    private ngbModal: NgbModal,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private lotService: AdminLotsDataHolderService,
    private validationService: AuctionValidationService,
    private adminDashboardService: AdminDashboardService
  ) {
    this.formGroup = this.fb.group({
      startingBid: [''],
      reservePrice: [''],
      publishReservePrice: [null],
      enableAutoSeller: [null],
      estStartingPrice: [''],
      estEndingPrice: [''],
      warnBid: [false],
      warnBidValue: [''],
      warnBidValueInPercent: [''],
      blockBid: [false],
      blockBidValue: [''],
      blockBidValueInPercent: [''],
      bidIncrementalDtoList: this.fb.array([]),
      allowXRank: new FormControl('', Validators.pattern(Pattern.numberGreaterZero)),
      allowYMinutes: new FormControl('', Validators.pattern(Pattern.numberGreaterZero))
    })
  }

  ngOnInit(): void {
    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe((data) => {
      if (data) {
        this.auctionEntityDto = data;
        this.populateShowWarnAndBlock();
        this.updateCurrencyValidator();
        this.isMultipleBidIncrementDecrementAllowed();

      }
    })

    this.selectedLotSubscription$ = this.lotService.selectedAuctionLot$.subscribe((data) => {
      if (data) {
        this.infinityBidIncrement.reset();
        this.singleBidIncrement.reset();
        this.formGroup.controls['bidIncrementalDtoList'].reset();
        this.incrementalConfig.clear();

        this.lotEntityDto = data;
        this.populateLotBidDetails();
      }
    })

    this.formGroup.valueChanges.subscribe(() => {
      let value = this.formGroup.getRawValue();

      if (value != null) {
        if (this.lotEntityDto?.lotType == 'Reserve') {
          if (value?.startingBid != null && value?.reservePrice != null) {
            let startingBid = ApplicationUtils.resetPriceFormat(value?.startingBid);
            let reservePrice = ApplicationUtils.resetPriceFormat(value?.reservePrice);

            if (Number(startingBid) >= Number(reservePrice) && Number(startingBid) > 0 && Number(startingBid) > 0) {
              this._showErrorToast$.next(true);
              this._errorMsg$.next("Reserve Price should be greater than Starting Bid value!");
              return;
            } else {
              this._showErrorToast$.next(false);
            }
          }
        } else {
          this._errorMsg$.next("");
          this._showErrorToast$.next(false);
        }
      }
    });

    this.radioBidIncrement.valueChanges.subscribe((val) => {
      if (val && val == true) {
        this.formGroup.controls['bidIncrementalDtoList'].reset();
        this.incrementalConfig.clear();
        this.infinityBidIncrement.reset();
        this.ctrlBidCalculationMethod.patchValue(false);
      } else {
        this.singleBidIncrement.reset();
        this.percentBidIncrement.reset();
        this.ctrlBidCalculationMethod.patchValue(false);
      }
    })

    this.ctrlBidCalculationMethod.valueChanges.subscribe(val => {
      if (val) {    // val = true => percentage
        this.singleBidIncrement.clearValidators();
        this.percentBidIncrement.setValidators([Validators.required, Validators.pattern(Pattern.percentage)]);
        this.singleBidIncrement.disable();

        let warnBid = this.formGroup.controls['warnBid'].value;
        if(warnBid){
          this.formGroup.controls['warnBidValue'].clearValidators();
          this.formGroup.controls['warnBidValueInPercent'].setValidators([Validators.required, Validators.pattern(Pattern.percentage)]);
        }
        this.formGroup.controls['warnBidValue'].disable();

        let blockBid = this.formGroup.controls['blockBid'].value;
        if (blockBid) {
          this.formGroup.controls['blockBidValue'].clearValidators();
          this.formGroup.controls['blockBidValueInPercent'].setValidators([Validators.required, Validators.pattern(Pattern.percentage)]);
        }
        this.formGroup.controls['blockBidValue'].disable();
      } else {
        this.singleBidIncrement.enable();
        this.percentBidIncrement.clearValidators();
        this.singleBidIncrement.setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);

        this.formGroup.controls['warnBidValue'].enable();
        this.enableAndDisableWarnBid();
        this.formGroup.controls['warnBidValueInPercent'].clearValidators();

        this.formGroup.controls['blockBidValue'].enable();
        this.enableAndDisableBlockBid();
        this.formGroup.controls['blockBidValueInPercent'].clearValidators();
      }

      this.formGroup.updateValueAndValidity();
      this.percentBidIncrement.updateValueAndValidity();
      this.singleBidIncrement.updateValueAndValidity();
    })
  }

  populateShowWarnAndBlock(){
    let rankConfig = this.auctionEntityDto?.rankCurrentPriceConfig;
    if((rankConfig == RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION) || (rankConfig == RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION) ){
      this.isShowWarnAndBlock = true
    }else{
      this.isShowWarnAndBlock = false;
    }
  }

  isReverseAuction(){
    let toReturn = false;
    if(this.auctionEntityDto?.auctionBiddingMethod == ApplicationConstants.REVERSE_AUCTION){
      toReturn = true;
    }
    return toReturn;
  }

  get fc(): any { return this.formGroup.controls; }

  openLotBidDetailModal(content: any) {
    this.formGroup.reset();
    this.radioBidIncrement.reset();
    this.populateLotBidDetails();
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  closeModal() {
    this.ngbModal.dismissAll()
    this.isSubmitted = false;
    this.formGroup.reset();
    this.infinityBidIncrement.reset();
    this.singleBidIncrement.reset();
    this.incrementalConfig.clear();
  }

  enableAndDisableWarnBid(){
    let warnBid = this.formGroup.controls['warnBid'].value;
    if(warnBid){
      this.formGroup.controls['warnBidValue'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    }else{
      this.formGroup.controls['warnBidValue'].clearValidators();
      this.formGroup.controls['warnBidValue'].reset();
    }
    this.formGroup.controls['warnBidValue'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  enableAndDisableBlockBid(){
    let blockBid = this.formGroup.controls['blockBid'].value;
    if(blockBid){
      this.formGroup.controls['blockBidValue'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    }else{
      this.formGroup.controls['blockBidValue'].clearValidators();
      this.formGroup.controls['blockBidValue'].reset();
    }
    this.formGroup.controls['blockBidValue'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  checkAllowRankAfterMinutes() {
    let allowXRank = this.formGroup.controls['allowXRank'].value;
    let allowYMinutes = this.formGroup.controls['allowYMinutes'].value;

    if ((allowXRank && allowXRank > 0 )  || (allowYMinutes &&  allowYMinutes > 0)) {
      this.formGroup.controls['allowXRank'].setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
      this.formGroup.controls['allowYMinutes'].setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
    } else {
      this.formGroup.controls['allowXRank'].setValidators([Validators.pattern(Pattern.numberGreaterZero)]);
      this.formGroup.controls['allowYMinutes'].setValidators([Validators.pattern(Pattern.numberGreaterZero)]);
    }

    this.formGroup.controls['allowXRank'].updateValueAndValidity();
    this.formGroup.controls['allowYMinutes'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  isEnableAutoSellerSetting(){
    let config = this.adminDashboardService._deploymentConfiguration$.value;
    let auctionType = this.auctionEntityDto?.auctionType;
    if(config?.enableAutoSeller && auctionType == "Reserve"){
      return true;
    }
    return false;
  }

  populateLotBidDetails() {

    //set default setting
    this.radioBidIncrement.setValue(true);

    // Populate data in Form
    this.formGroup.patchValue(this.lotEntityDto!);

    // startingBidValue
    if (this.lotEntityDto?.startingBid) {
      let startingBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.startingBid);
      this.formGroup.get('startingBid')?.patchValue(startingBidValue);
    }

    // reserved Bid Value
    if (this.lotEntityDto?.reservePrice) {
      let reserveBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.reservePrice);
      this.formGroup.get('reservePrice')?.patchValue(reserveBidValue);
    }

    if (this.lotEntityDto?.estStartingPrice) {
      let estStartingPriceValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.estStartingPrice);
      this.formGroup.get('estStartingPrice')?.patchValue(estStartingPriceValue);
    }else{
      this.formGroup.get('estStartingPrice')?.patchValue('0');
    }

    //warnBid
    this.formGroup.get('warnBid')?.patchValue(this.lotEntityDto?.warnBid);
    this.formGroup.updateValueAndValidity();
    this.enableAndDisableWarnBid();
    if(this.lotEntityDto?.warnBid){
      let warnBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.warnBidValue);
      this.formGroup.get('warnBidValue')?.patchValue(warnBidValue);
      this.formGroup.get('warnBidValueInPercent')?.patchValue(this.lotEntityDto?.warnBidValueInPercent);
    }else{
      this.formGroup.get('warnBidValue')?.patchValue('0');
      this.formGroup.get('warnBidValueInPercent')?.patchValue(0);
    }

    //block bid
    this.formGroup.get('blockBid')?.patchValue(this.lotEntityDto?.blockBid);
    this.formGroup.updateValueAndValidity();
    this.enableAndDisableBlockBid();
    if(this.lotEntityDto?.blockBid){
      let blockBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.blockBidValue);
      this.formGroup.get('blockBidValue')?.patchValue(blockBidValue);
      this.formGroup.get('blockBidValueInPercent')?.patchValue(this.lotEntityDto?.blockBidValueInPercent);
    }else{
      this.formGroup.get('blockBidValue')?.patchValue('0');
      this.formGroup.get('blockBidValueInPercent')?.patchValue(0);
    }


    if (this.lotEntityDto?.estEndingPrice) {
      let estEndingPriceValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.estEndingPrice);
      this.formGroup.get('estEndingPrice')?.patchValue(estEndingPriceValue);
    }else{
      this.formGroup.get('estEndingPrice')?.patchValue('0');
    }

    this.formGroup.controls['allowXRank'].setValue(this.lotEntityDto?.allowXRank ?? '');
    this.formGroup.controls['allowYMinutes'].setValue(this.lotEntityDto?.allowYMinutes ?? '');

    // Bid Incremental Data
    if (this.lotEntityDto?.incrementalWrapperDto) {
      this.radioBidIncrement.patchValue(this.lotEntityDto?.incrementalWrapperDto?.incrementalBidType == 'SINGLE')
      if (this.lotEntityDto?.incrementalWrapperDto?.incrementalBidType == 'SINGLE') {
        if (this.lotEntityDto?.incrementalWrapperDto?.constantIncrement) {
          this.incrementBidValue = this.lotEntityDto?.incrementalWrapperDto?.constantIncrement!.toString();
          this.singleBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.incrementBidValue));
        } else {
          this.incrementBidValue = '0';
          this.singleBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.incrementBidValue));
        }

        // Percentage & Absolute
        if (this.lotEntityDto?.incrementalWrapperDto.bidCalculationMethodEnum == BidCalculationMethodEnum.PERCENTAGE) {
          this.ctrlBidCalculationMethod.patchValue(true);
          this.percentBidIncrement.patchValue(this.lotEntityDto?.incrementalWrapperDto.constantPercentage);
          this.formatPercentIncrement();
        } else {
          this.ctrlBidCalculationMethod.patchValue(false);
          this.formatSingleIncrement();
        }
        this.ctrlBidCalculationMethod.updateValueAndValidity();

      } else {
        let bidIncrementalDtoList = this.lotEntityDto.incrementalWrapperDto.bidIncrementalDtoList;
        if (bidIncrementalDtoList && bidIncrementalDtoList.length > 0) {
          this.incrementalConfig.clear();
          this.bidIncrementalList = [];
          bidIncrementalDtoList.sort((a, b) => a.upToAmount! - b.upToAmount!);
          bidIncrementalDtoList.forEach(config => {
            if (config.upToAmount != 999999999999999) {
              this.incrementalConfig.push(this.updateIncrementalConfig(config));
              let bidIncrementalDto = new BidIncrementalDto();
              bidIncrementalDto.upToAmount = config.upToAmount;
              bidIncrementalDto.incrementValue = config.incrementValue;
              this.bidIncrementalList?.push(bidIncrementalDto);
            } else {
              this.infinityBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, config.incrementValue!));
              this.incrementBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, config.incrementValue!);
            }
          })

          this.disableIncrementalRowsBasedOnMaxBid();
        } else {
          this.incrementBidValue = '0';
        }
      }
    } else {
      this.incrementBidValue = '0';
    }


    this.checkAllowRankAfterMinutes();

    this.updateReservePriceValidator();
    this.formGroup.updateValueAndValidity();
  }

  showReservedPrice(): boolean {
    return this.lotEntityDto?.lotType == "Reserve";
  }

  formatStartingBid() {
    let startingBid = this.formGroup.controls['startingBid'].value;
    let formattedStartingBid = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, startingBid);
    this.fc['startingBid'].patchValue(formattedStartingBid);
    this.updateEstPriceValidator();

    this.formatSingleIncrement();
    this.formatPercentIncrement();
  }

  formatWarningBidValue() {
    let warnBidValue =  ApplicationUtils.resetPriceFormat(this.formGroup.controls['warnBidValue'].value);
    let startingBid = ApplicationUtils.resetPriceFormat(this.formGroup.controls['startingBid'].value);

    let formattedWarnBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, warnBidValue);
    this.fc['warnBidValue'].patchValue(formattedWarnBidValue);

    if (Number(startingBid) > 0 && Number(warnBidValue) > 0) {
      let warnBidValueInPercent = (Number(warnBidValue) / Number(startingBid)) * 100;
      this.formGroup.controls['warnBidValueInPercent'].patchValue(parseFloat(Number(warnBidValueInPercent).toFixed(2)));
    } else {
      this.formGroup.controls['warnBidValueInPercent'].patchValue(0);
    }

    this.formGroup.updateValueAndValidity();
  }

  formatWarningBidValueInPercent() {
    let warnBidValueInPercent =  ApplicationUtils.resetPriceFormat(this.formGroup.controls['warnBidValueInPercent'].value);
    let startingBid = ApplicationUtils.resetPriceFormat(this.formGroup.controls['startingBid'].value);

    this.fc['warnBidValueInPercent'].patchValue(parseFloat(Number(warnBidValueInPercent).toFixed(2)));

    if (Number(startingBid) > 0 && Number(warnBidValueInPercent) > 0) {
      let warnBidValue = (Number(startingBid) * Number(warnBidValueInPercent)) / 100;
      this.fc['warnBidValue'].patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, warnBidValue));
    } else {
      this.fc['warnBidValue'].patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, 0));
    }

    this.formGroup.updateValueAndValidity();
  }

  formatBlockBidValue() {
    let blockBidValue = ApplicationUtils.resetPriceFormat(this.formGroup.controls['blockBidValue'].value);
    let startingBid = ApplicationUtils.resetPriceFormat(this.formGroup.controls['startingBid'].value);
    let formattedBlockBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, blockBidValue);
    this.fc['blockBidValue'].patchValue(formattedBlockBidValue);

    if (Number(startingBid) > 0 && Number(blockBidValue) > 0) {
      let blockBidValueInPercent = (Number(blockBidValue) / Number(startingBid)) * 100;
      this.formGroup.controls['blockBidValueInPercent'].patchValue(parseFloat(Number(blockBidValueInPercent).toFixed(2)));
    } else {
      this.formGroup.controls['blockBidValueInPercent'].patchValue(0);
    }

    this.formGroup.updateValueAndValidity();
  }

  formatBlockBidValueInPercent() {
    let blockBidValueInPercent =  ApplicationUtils.resetPriceFormat(this.formGroup.controls['blockBidValueInPercent'].value);
    let startingBid = ApplicationUtils.resetPriceFormat(this.formGroup.controls['startingBid'].value);

    this.fc['blockBidValueInPercent'].patchValue(parseFloat(Number(blockBidValueInPercent).toFixed(2)));

    if (Number(startingBid) > 0 && Number(blockBidValueInPercent) > 0) {
      let blockBidValue = (Number(startingBid) * Number(blockBidValueInPercent)) / 100;
      this.fc['blockBidValue'].patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, blockBidValue));
    } else {
      this.fc['blockBidValue'].patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, 0));
    }

    this.formGroup.updateValueAndValidity();
  }

  formatEstStartingPrice() {
    let estStartingPrice = this.formGroup.controls['estStartingPrice'].value;
    let formattedStartingBid = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, estStartingPrice);
    this.fc['estStartingPrice'].patchValue(formattedStartingBid);
    this.updateEstPriceValidator();
  }

  formatEstEndingPrice() {
    let estEndingPrice = this.formGroup.controls['estEndingPrice'].value;
    let formattedEstEndingPrice = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, estEndingPrice);
    this.fc['estEndingPrice'].patchValue(formattedEstEndingPrice);
    this.updateEstPriceValidator();
  }

  formatReserveBid() {
    let reservePrice = this.formGroup.controls['reservePrice'].value;
    let formattedReservePrice = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, reservePrice);
    this.fc['reservePrice'].patchValue(formattedReservePrice);
  }

  formatInfinityIncrement() {
    let infinityIncrementValue = this.infinityBidIncrement.value;
    this.infinityBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, infinityIncrementValue));
  }



  formatSingleIncrement() {
    let singleBidIncrementValue = ApplicationUtils.resetPriceFormat(this.singleBidIncrement.value);
    let startingBid = ApplicationUtils.resetPriceFormat(this.formGroup.controls['startingBid'].value);

    this.singleBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, singleBidIncrementValue));

    if (Number(startingBid) > 0 && Number(singleBidIncrementValue) > 0) {
      let percentBidIncrementValue = (Number(singleBidIncrementValue) / Number(startingBid)) * 100;
      this.percentBidIncrement.patchValue(parseFloat(Number(percentBidIncrementValue).toFixed(2)));
    } else {
      this.percentBidIncrement.patchValue(0);
    }

    this.percentBidIncrement.updateValueAndValidity();
    this.singleBidIncrement.updateValueAndValidity();
  }

  formatPercentIncrement() {
    let percentBidIncrementValue = ApplicationUtils.resetPriceFormat(this.percentBidIncrement.value);
    let startingBid = ApplicationUtils.resetPriceFormat(this.formGroup.controls['startingBid'].value);

    this.percentBidIncrement.patchValue(parseFloat(Number(percentBidIncrementValue).toFixed(2)));

    if (Number(startingBid) > 0 && Number(percentBidIncrementValue) > 0) {
      let singleBidIncrementValue = (Number(startingBid) * Number(percentBidIncrementValue)) / 100;
      this.singleBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, singleBidIncrementValue));
    } else {
      this.singleBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, 0));
    }

    this.percentBidIncrement.updateValueAndValidity();
    this.singleBidIncrement.updateValueAndValidity();
  }

  formatIncrementUpToValue(index: number) {
    let upToAmount = this.incrementalConfig.controls[index].get('upToAmount')?.value;
    let formattedUpToAmount = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, upToAmount);
    this.incrementalConfig.controls[index].get('upToAmount')?.patchValue(formattedUpToAmount);
  }

  formatIncrementValue(index: number) {
    let incrementValue = this.incrementalConfig.controls[index].get('incrementValue')?.value;
    let formattedIncrementValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, incrementValue);
    this.incrementalConfig.controls[index].get('incrementValue')?.patchValue(formattedIncrementValue);
  }

  updateCurrencyValidator() {
    this.infinityBidIncrement.setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    this.singleBidIncrement.setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    this.fc['startingBid'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);

    // Bid Increment
    // this.incrementalConfig.controls.forEach((item) => {
    //   item.get('upToAmount')?.setValidators([Validators.required, Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    //   item.get('incrementValue')?.setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    //   item.updateValueAndValidity();
    // })

    this.formGroup.updateValueAndValidity();
    this.infinityBidIncrement.updateValueAndValidity();
    this.singleBidIncrement.updateValueAndValidity();
  }




  disableIncrementalRowsBasedOnMaxBid() {
    this.incrementalConfig.controls.forEach((item) => {
      let resetUpToAmount = ApplicationUtils.resetPriceFormat(item.get('upToAmount')?.value);
      if (resetUpToAmount <= Number(`${this.maxBidPlaced}`) || resetUpToAmount < Number(`${this.highestBidPrice}`)) {
        item.disable();
        item.updateValueAndValidity();
      }
    })

    if (this.incrementalConfig && this.incrementalConfig.controls.length > 0) {
      let incrementalConfigValue = this.incrementalConfig.value as Array<any>;
      incrementalConfigValue.sort((a, b) => Number(a.upToAmount) - Number(b.upToAmount));
      let resetUpToAmount = ApplicationUtils.resetPriceFormat(incrementalConfigValue[0].upToAmount);

      if (resetUpToAmount <= Number(`${this.maxBidPlaced}`) || resetUpToAmount < Number(`${this.highestBidPrice}`)) {
        this.infinityBidIncrement.disable();
        this.infinityBidIncrement.updateValueAndValidity();
      }
    } else {
      if (this.maxBidPlaced) {
        this.infinityBidIncrement.disable();
        this.infinityBidIncrement.updateValueAndValidity();
      }
    }
  }

  updateReservePriceValidator() {
    if (this.lotEntityDto?.lotType == 'Reserve') {
      this.formGroup.controls['reservePrice'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
      this.formGroup.controls['publishReservePrice'].setValidators(Validators.required);
    } else {
      this.formGroup.controls['reservePrice'].clearValidators();
      this.formGroup.controls['publishReservePrice'].clearValidators();
    }

    this.formGroup.controls['reservePrice'].updateValueAndValidity();
    this.formGroup.controls['publishReservePrice'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  // Bid Incremental Config
  get incrementalConfig(): FormArray {
    return this.formGroup.get("bidIncrementalDtoList") as FormArray
  }

  newIncrementalConfig(): FormGroup {
    return this.fb.group({
      upToAmount: ['', [Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]],
      incrementValue: ['', [Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]]
    })
  }

  updateIncrementalConfig(config: BidIncrementalDto): FormGroup {
    return this.fb.group({
      upToAmount: [ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, config.upToAmount!), [Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]],
      incrementValue: [ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, config.incrementValue!), [Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]]
    })
  }

  addBidIncrementConfig() {
    this.incrementalConfig.push(this.newIncrementalConfig());
  }

  infinityBidIncrementConfig() {
    let bidIncrementalDto = new BidIncrementalDto();
    bidIncrementalDto.upToAmount = 999999999999999;
    bidIncrementalDto.incrementValue = ApplicationUtils.resetPriceFormat(this.infinityBidIncrement.value);
    return bidIncrementalDto;
  }

  removeBidIncrementConfig(i: number) {
    this.incrementalConfig.removeAt(i);
  }

  getFormattedPrice(price?: string | number) {
    return ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale, price);
  }

  checkIfIncrementalMatrixIsInValid() {
    if (this.formGroup.value.bidIncrementalDtoList && this.formGroup.value.bidIncrementalDtoList.length > 1) {
      for (var i = 1; i < this.formGroup.value.bidIncrementalDtoList.length; i++) {
        let previousUpToAmount = ApplicationUtils.resetPriceFormat(this.formGroup.value.bidIncrementalDtoList[i - 1].upToAmount);
        let ithUpToAmount = ApplicationUtils.resetPriceFormat(this.formGroup.value.bidIncrementalDtoList[i].upToAmount);
        if (previousUpToAmount >= ithUpToAmount) {
          return true;
        }
      }
    }
    return false;
  }

  mergeAuctionEntityDto(formValue: any): AuctionLotEntityDto {
    let incrementalWrapperDto = new BidIncrementalWrapperDto();
    if (this.radioBidIncrement.value == true) {
      incrementalWrapperDto.incrementalBidType = 'SINGLE';
      incrementalWrapperDto.constantIncrement = ApplicationUtils.resetPriceFormat(this.singleBidIncrement.value);
      incrementalWrapperDto.bidIncrementalDtoList = undefined;

      // Percentage Value
      incrementalWrapperDto.bidCalculationMethodEnum = this.ctrlBidCalculationMethod.value ? BidCalculationMethodEnum.PERCENTAGE : BidCalculationMethodEnum.ABSOLUTE;
      incrementalWrapperDto.constantPercentage = Number(this.percentBidIncrement.value);
    } else {
      incrementalWrapperDto.incrementalBidType = 'MULTIPLE';
      incrementalWrapperDto.constantIncrement = undefined;
      incrementalWrapperDto.bidIncrementalDtoList = [];
      incrementalWrapperDto.bidIncrementalDtoList?.push(this.infinityBidIncrementConfig());
    }

    if (this.radioBidIncrement.value == false && formValue.bidIncrementalDtoList) {
      formValue.bidIncrementalDtoList.forEach((item: any) => {
        let bidIncrement = new BidIncrementalDto();
        bidIncrement.upToAmount = ApplicationUtils.resetPriceFormat(item.upToAmount);
        bidIncrement.incrementValue = ApplicationUtils.resetPriceFormat(item.incrementValue);
        incrementalWrapperDto.bidIncrementalDtoList?.push(bidIncrement);
      });
    }

    let lotEntityDto = ApplicationUtils.clone(this.lotEntityDto);
    lotEntityDto.startingBid = ApplicationUtils.resetPriceFormat(formValue.startingBid);
    lotEntityDto.reservePrice = ApplicationUtils.resetPriceFormat(formValue.reservePrice);
    lotEntityDto.publishReservePrice = formValue.publishReservePrice;
    lotEntityDto.enableAutoSeller = formValue.enableAutoSeller;
    lotEntityDto.estStartingPrice = ApplicationUtils.resetPriceFormat(formValue.estStartingPrice);
    lotEntityDto.estEndingPrice = ApplicationUtils.resetPriceFormat(formValue.estEndingPrice);

    lotEntityDto.warnBid = formValue.warnBid;
    lotEntityDto.warnBidValue = ApplicationUtils.resetPriceFormat(formValue.warnBidValue);
    lotEntityDto.warnBidValueInPercent = ApplicationUtils.resetPriceFormat(formValue.warnBidValueInPercent);

    lotEntityDto.blockBid = formValue.blockBid;
    lotEntityDto.blockBidValue = ApplicationUtils.resetPriceFormat(formValue.blockBidValue);
    lotEntityDto.blockBidValueInPercent = ApplicationUtils.resetPriceFormat(formValue.blockBidValueInPercent);

    lotEntityDto.incrementalWrapperDto = incrementalWrapperDto;

    lotEntityDto.allowXRank = formValue.allowXRank;
    lotEntityDto.allowYMinutes = formValue.allowYMinutes;

    return lotEntityDto;
  }

  updateEstPriceValidator(){
    let formValue = this.formGroup.getRawValue();
    let startingBid = ApplicationUtils.resetPriceFormat(formValue.startingBid);
    let estStartingPrice = ApplicationUtils.resetPriceFormat(formValue.estStartingPrice);
    let estEndingPrice = ApplicationUtils.resetPriceFormat(formValue.estEndingPrice);


    if(!estStartingPrice &&  !estEndingPrice){
      this.formGroup.controls['estStartingPrice'].clearValidators();
      this.formGroup.controls['estEndingPrice'].clearValidators();
    }else{
      this.formGroup.controls['estStartingPrice'].setValidators([Validators.required, StringMinValidator(startingBid), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
      this.formGroup.controls['estEndingPrice'].setValidators([Validators.required, StringMinValidator(estStartingPrice + 1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    }

    this.formGroup.controls['estStartingPrice'].updateValueAndValidity();
    this.formGroup.controls['estEndingPrice'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }


  saveAuctionLot() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.formatStartingBid();
    this.formatReserveBid();
    this.updateEstPriceValidator();
    this.formatWarningBidValue();
    this.formatBlockBidValue();

    this.formGroup.markAllAsTouched();

    this._showErrorToast$.next(false);
    this.isSubmitted = true;
    this.auctionValidationErrorCodeDtoList = [];
    this._showErrorToast$.next(false);
    this._isMultiLineError$.next(false);
    this._errorMsg$.next("");

    let formValue = this.formGroup.getRawValue();

    // Validation Check For Reserve Price
    let startingBid = ApplicationUtils.resetPriceFormat(formValue?.startingBid);
    let reservePrice = ApplicationUtils.resetPriceFormat(formValue?.reservePrice);

    if (this.lotEntityDto?.lotType == 'Reserve' || reservePrice > 0) {
      if (Number(startingBid) >= Number(reservePrice) && Number(startingBid) > 0 && Number(startingBid) > 0) {
        this._showErrorToast$.next(true);
        let value = this.isReverseAuction()? 'lower' : 'greater';
        this._errorMsg$.next("Reserve Price should be "+value+" than Starting Bid value!");
        return;
      } else {
        this._showErrorToast$.next(false);
      }
    }



    if (this.formGroup.invalid) {
      return;
    } else {
      if ((this.singleBidIncrement.invalid || this.percentBidIncrement.invalid) && this.infinityBidIncrement.invalid ) {
        return;
      }
    }


    this._isSaveButtonEnable$.next(false);

    if (this.checkIfIncrementalMatrixIsInValid()) {
      this._showErrorToast$.next(true);
      this._errorMsg$.next("Invalid Incremental Bid Price Configuration");
      this.isLoading = false;
      this._isSaveButtonEnable$.next(true);

      return;
    }

    let auctionLotDto = this.mergeAuctionEntityDto(formValue);

    if(this.checkIfWarnValidationInvalid(auctionLotDto)){
      this._showErrorToast$.next(true);
      this._isSaveButtonEnable$.next(true);
      this._errorMsg$.next("Warning bid value must be greater than incremental bid value ");
      return;
    }

    if(this.checkIfBlockValidationInvalid(auctionLotDto)){
      this._showErrorToast$.next(true);
      this._isSaveButtonEnable$.next(true);
      this._errorMsg$.next("Block bid value must be greater than incremental bid value and warn bid value");
      return;
    }

    this.isLoading = true;

    this.lotService.saveAuctionLotDetails(auctionLotDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionLotSaveWrapperDto = apiResponseDto.data  as AuctionLotSaveWrapperDto;
          let auctionValidationErrorCodeDtoList =  auctionLotSaveWrapperDto.auctionValidationErrorCodeDtos;

          if(!auctionValidationErrorCodeDtoList ||  auctionValidationErrorCodeDtoList.length == 0){
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._isSaveButtonEnable$.next(true);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLoading = false;
            this._isSaveButtonEnable$.next(true);
            this.closeModal();
            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving lot. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);

      }
    })
  }

  checkIfBlockValidationInvalid(auctionLotDto: AuctionLotEntityDto) {
    //block bid value validation
    if (auctionLotDto?.blockBid) {

      if (auctionLotDto?.warnBid && auctionLotDto?.warnBidValue) {
        let warnBidValue = auctionLotDto?.warnBidValue;
        if (Number(auctionLotDto?.blockBidValue!) <= Number(warnBidValue!)) {
          return true;
        }

      } else {

        let incrementType = auctionLotDto?.incrementalWrapperDto?.incrementalBidType;
        let constantIncrement = auctionLotDto?.incrementalWrapperDto?.constantIncrement;
        let multiHighestIncrementValue = this.getMultiHighestIncrementValue(auctionLotDto?.incrementalWrapperDto?.bidIncrementalDtoList!);

        if (incrementType == 'SINGLE' && Number(auctionLotDto?.blockBidValue!) <= Number(constantIncrement!)) {
          //Blocking Bid Value is grater than increment Bid Value
          return true;

        } else if (Number(auctionLotDto?.blockBidValue) <= Number(multiHighestIncrementValue!)) {
          //Blocking Bid Value is grater than highest increment Bid Value
          return true;
        }
      }

    }
    return false;
  }
  checkIfWarnValidationInvalid(auctionLotDto: AuctionLotEntityDto){
      //warn bid value validation
      if (auctionLotDto?.warnBid) {

        let incrementType = auctionLotDto?.incrementalWrapperDto?.incrementalBidType;
        let constantIncrement = auctionLotDto?.incrementalWrapperDto?.constantIncrement;
        let multiHighestIncrementValue = this.getMultiHighestIncrementValue(auctionLotDto?.incrementalWrapperDto?.bidIncrementalDtoList!);

        if (incrementType == 'SINGLE' && Number(auctionLotDto?.warnBidValue!) <= Number(constantIncrement!)) {
          //Warning Bid Value is grater than increment Bid Value
          return true;

        } else if (Number(auctionLotDto?.warnBidValue) <= Number(multiHighestIncrementValue!)) {
          //Warning Bid Value is grater than highest increment Bid Value
          return true;
        }

      }
      return false;
  }

  getMultiHighestIncrementValue(bidIncrementalDtoList: BidIncrementalDto[]){
    if(bidIncrementalDtoList && bidIncrementalDtoList.length > 0){
      let incrementList = bidIncrementalDtoList.map(item => item.incrementValue).sort((a, b) => b! - a!);
      return incrementList[0];
    }
    return 0;
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
    if (this.selectedLotSubscription$) {
      this.selectedLotSubscription$.unsubscribe();
    }
  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }

  openBidIncrementListModal(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  invalidPriceValidator(item: AbstractControl|null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }

  haveBidSettingErrors() {
    if (this.auctionEntityDto && this.lotEntityDto) {
      return this.validationService.haveBidSettingErrors(this.auctionEntityDto!, this.lotEntityDto!);
    }
    return false;
  }

  isMultipleBidIncrementDecrementAllowed() {
    this.multipleBidIncrementDecrementAllowed = false;

    if (this.isReverseAuction()) {
      this.multipleBidIncrementDecrementAllowed = false;
      return;
    }
    if (this.auctionEntityDto?.rankCurrentPriceConfig == RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION) {
      this.multipleBidIncrementDecrementAllowed = true;
      return;
    }

  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }

}
