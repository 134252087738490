<div class="bg-white-new">
  <div class="container position-relative">
    <div class="border p-2 mt-3 mb-3 bg-white">
      <div class="row">
        <div class="col-auto mt-1">
          <img src="assets/icons/home_light.svg" class="svg-icon-class c-pointer" (click)="navigateToHome()">
          <span>
            <img src="assets/icons/forward-icon.svg" class="svg-icon-class-small opacity-svg-icon c-pointer mt-1  ps-2">
          </span>
        </div>
        <div class="col-auto mt-2 ps-0">
          <span class="text-secondary span-class">
           AUCTION CALENDAR
          </span>
        </div>
      </div>
    </div>

    <div class="col-12 text-center py-3">
      <div class="btn btn-sm rounded  c-pointer button-width-change me-md-3 zoom shadow"
      [ngClass]="filterBy == 'ALL_AUCTION_THIS_MONTH' ? 'selected-tiles' : 'btn-outline-secondary'"
      (click)="filterAuctionData('ALL_AUCTION_THIS_MONTH')">
    <div >
      <div class="row">
        <div class="text-center col-12  ">
          <h4 class="heading-h4"  [ngClass]="{'text-white': filterBy == 'ALL_AUCTION_THIS_MONTH'}">
            {{allAuctionForMonth$ | async}}
          </h4>
        </div>
        <div class="text-center col-12">
          <h6 class="heading-h5 position-relative "  [ngClass]="{'text-white': filterBy == 'ALL_AUCTION_THIS_MONTH'}">ALL</h6>
        </div>
      </div>
    </div>
  </div>
      <div class="btn btn-sm rounded  c-pointer button-width-change me-md-3 zoom shadow"
          [ngClass]="filterBy == 'LIVE_WAIT_AUCTION_THIS_MONTH' ? 'selected-tiles' : 'btn-outline-secondary'"
          (click)="filterAuctionData('LIVE_WAIT_AUCTION_THIS_MONTH')">
        <div >
          <div class="row">
            <div class="text-center col-12  ">
              <h4 class="heading-h4"  [ngClass]="{'text-white': filterBy == 'LIVE_WAIT_AUCTION_THIS_MONTH'}">
                {{liveWaitAuctionForMonth$ | async}}
              </h4>
            </div>
            <div class="text-center col-12">
              <h6 class="heading-h5 position-relative "  [ngClass]="{'text-white': filterBy == 'LIVE_WAIT_AUCTION_THIS_MONTH'}">UPCOMING</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="btn  rounded c-pointer button-width-change mt-2 mt-md-0 zoom shadow me-md-3"
      [ngClass]="filterBy == 'LIVE_AUCTION_THIS_MONTH' ? 'selected-tiles' : 'btn-outline-secondary'"
      (click)="filterAuctionData('LIVE_AUCTION_THIS_MONTH')">
        <div>
          <div class="row">

            <div class=" col-10 m-auto text-center" >
              <h4 class="heading-h4" [ngClass]="{'text-white': filterBy == 'LIVE_AUCTION_THIS_MONTH'}">
                {{liveAuctionForMonth$ | async}}
              </h4></div>
            <div class="text-center  col-12">
              <h6 class="heading-h5 position-relative " [ngClass]="{'text-white': filterBy == 'LIVE_AUCTION_THIS_MONTH'}">LIVE</h6>
            </div>

          </div>
        </div>
      </div>
      <div class="btn  rounded c-pointer button-width-change mt-2 mt-xl-0 me-md-3 zoom shadow"
      [ngClass]="filterBy == 'CLOSING_AUCTION_THIS_MONTH' ? 'selected-tiles' : 'btn-outline-secondary'"
      (click)="filterAuctionData('CLOSING_AUCTION_THIS_MONTH')">
        <div>
          <div class="row">

            <div class=" col-10 m-auto text-center" >
              <h4 class="heading-h4" [ngClass]="{'text-white': filterBy == 'CLOSING_AUCTION_THIS_MONTH'}">
                {{closingInAuctionForMonth$ | async}}
              </h4></div>
            <div class="text-center  col-12">
              <h6 class="heading-h5 position-relative " [ngClass]="{'text-white': filterBy == 'CLOSING_AUCTION_THIS_MONTH'}">CLOSING IN</h6>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row">

<hr class="hr my-3">
      <div class="col-lg-5 mt-3">
      <div class="border calendar-height">
        <app-auction-calendar-view [auctionList$]="filterAuctionList$" [selectedAuction$]="selectedAuction$" (onDateSelected)="onDateSelected($event)" (onMonthSelected)="onMonthSelected($event)"></app-auction-calendar-view>
      </div>
      </div>
      <div class="col-lg-7 mt-3">
        <div class="border calendar-height p-2 ">
     <app-calendar-list [auctionList$]="filterAuctionList$" (onAuctionSelected)="onAuctionSelected($event)"></app-calendar-list>
      </div>
    </div>
      </div>
      <div class="mt-3">
        <div class="mb-2" *ngIf="selectedAuctionList &&  selectedAuctionList.length > 1">
          <h5 class="heading-h5 text-uppercase"> Total Auction for this date</h5>
          <div>


          <span class=" span-class text-dark"> <span class="me-3" *ngIf="(liveWaitAuctionForSelectedDate$ | async) !=0"> Upcoming: <b>{{liveWaitAuctionForSelectedDate$ | async}}</b></span>
             <span class="me-3"  *ngIf="(liveAuctionForSelectedDate$ | async) !=0">Live: <b>{{liveAuctionForSelectedDate$ | async}}</b></span>
             <span *ngIf="(closingInAuctionForSelectedDate$ | async) !=0" >Closing In: <b>{{closingInAuctionForSelectedDate$ | async}}</b></span>
            </span>
          </div>
        </div>


      <div class="col-12 mt-5" *ngIf="(!selectedAuctionList ||  selectedAuctionList.length == 0) && selectedAuction">
        <app-auction-calendar-details [selectedAuction]="selectedAuction$|async"></app-auction-calendar-details>
      </div>

      <div class="col-12" *ngIf="selectedAuctionList && selectedAuctionList.length > 0">
        <div class="row justify-content-center pe-4 ps-2 pe-lg-0 ps-lg-0">
          <div class="col-1 py-5 d-none d-lg-block" *ngIf="selectedAuctionList.length > 1">
            <button class="scroll-left-icon ms-lg-5 landing-scroll-margin d-none d-lg-block"
            (click)="onNavigationPrev()" >
              <img src="assets/icons/leftarrow_dark.svg" class="svg-icon-class c-pointer">
            </button>
          </div>
          <div class="col-10 m-auto " >
            <swiper class="px-lg-4"  #swiper [virtual]="true" [config]="swiperConfig" >
              <ng-template swiperSlide *ngFor="let item of selectedAuctionList; index as i"  class="py-5">
                <app-auction-calendar-details [selectedAuction]="item"></app-auction-calendar-details>
              </ng-template>
            </swiper>
          </div>
          <div class="col-1 py-5 d-none d-lg-block" *ngIf="selectedAuctionList.length > 1">
            <button class=" scroll-right-icon landing-scroll-margin d-none d-lg-block" (click)="onNavigationNext()">
              <img src="assets/icons/rightarrow_dark.svg" class="svg-icon-class c-pointer">
            </button>
          </div>
        </div>
      </div>
    </div>



    <!-- <div class="container py-3">
      <div class="row  justify-content-between">
        <div class="col-md-5">
          <div class="btn-group">
            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              Previous
            </div>
            <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
              Today
            </div>
            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              Next
            </div>
          </div>
        </div>
        <div class="col-md-7 mt-2 mt-md-0">
          <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        </div>
      </div>
      <br />
      <div [ngSwitch]="view">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
          [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-month-view>
        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
          [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-week-view>
        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
          [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)">
        </mwl-calendar-day-view>
      </div>
    </div> -->

  </div>
</div>


<!-- <ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template> -->
