import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, OnChanges, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { ResourceSwapDto } from 'src/app/shared/models/questionnaire/ResourceSwapDto';
import { ResourceSwapWrapperDto } from 'src/app/shared/models/questionnaire/ResourceSwapWrapperDto';
import { SectionTemplate } from 'src/app/shared/models/questionnaire/SectionTemplate';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-technical-section',
  templateUrl: './technical-section.component.html',
  styleUrls: ['./technical-section.component.sass']
})
export class TechnicalSectionComponent implements OnInit, OnDestroy {
  @Input() selectedSectionId?: string;
  @Input() isNewQuestion: boolean = false;

  ctrlSectionTitle: FormControl = new FormControl('', Validators.required)
  ctrlSectionScore: FormControl = new FormControl('')

  sectionTemplateList: SectionTemplate[] = []
  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  selectedSectionTemplate?: SectionTemplate
  selectedAuction?: AuctionEntityDto;
  errorMsg: string | undefined;
  errorMsgForModal: string | undefined;
  isLoading: boolean = false;
  isLoadingForModal: boolean = false;
  sectionScoreValidationFailed: boolean = false;

  technicalModalRef?: NgbModalRef
  deleteConfirmModalRef?: NgbModalRef;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToastForModal$ = new BehaviorSubject<boolean>(false);
  _showErrorToastForModal$ = new BehaviorSubject<boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription

  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;

  isTechnicalActive = true;
  isQuestionnaireAvailable = true;
  isSelectOption = true;

  constructor(
    private ngbModal: NgbModal,
    private activeModal: NgbActiveModal,
    private questionnaireService: QuestionnaireService,
    private changeDetectRef: ChangeDetectorRef,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService
  ){
  }

  ngOnInit(): void {
    this.selectedAuction = this.adminSourcingEventsDataHolderService.selectedAuction;
    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        this.sectionTemplateList = this.questionnaireWrapperDto?.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL) ?? [];
        this.sectionTemplateList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

        if (this.sectionTemplateList.length == 0) {
          this.isNewQuestion = true;
        }

        if (this.isNewQuestion) {
          this.addNewSection();
        } else {
          this.populateData();
        }

        this.refreshValueChanges();
      }
    })
  }

  populateData() {
    if (this.selectedSectionId) {
      this.selectedSectionTemplate = this.sectionTemplateList.find(m => m.sectionId == this.selectedSectionId);
      this.selectedSectionTemplate = this.selectedSectionTemplate ?? this.sectionTemplateList[0];
    } else {
      this.selectedSectionTemplate = this.sectionTemplateList[0];
      this.selectedSectionId = this.selectedSectionTemplate.sectionId;
    }

    this.questionnaireService.setSelectedSection(this.selectedSectionTemplate);

    this.ctrlSectionTitle.patchValue(this.selectedSectionTemplate.sectionName);
    this.ctrlSectionTitle.updateValueAndValidity();

    if (this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate) {
      this.ctrlSectionScore.setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
      this.ctrlSectionScore.patchValue(this.selectedSectionTemplate.sectionScore);
    } else {
      this.ctrlSectionScore.clearValidators();
    }
    this.ctrlSectionScore.updateValueAndValidity();

    this.refreshValueChanges();
  }

  openDeleteConfirmModal(content: any) {
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.errorMsg = '';

    this.deleteConfirmModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    })
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }

  refreshValueChanges() {
    this.questionnaireService.runTechQuestionnaireValidation();

    this.sectionScoreValidationFailed = this.questionnaireService.checkSectionScoreValidation(SourcingEnvelopeType.TECHNICAL);

    this.changeDetectRef.detectChanges();
  }

  resetSectionTemplate() {
    this.ctrlSectionTitle.reset();
    this.ctrlSectionScore.reset();
  }

  changeSection(sectionTemplate: SectionTemplate) {
    this.resetSectionTemplate();
    this.selectedSectionTemplate = sectionTemplate;
    this.selectedSectionId = sectionTemplate.sectionId;
    this.populateData();
  }

  addNewSection() {
    let emptySections = this.sectionTemplateList.filter(item => !item.sectionName || item.sectionName == '');
    if (emptySections.length > 0) {
      return;
    }

    let selectedAuction = this.adminSourcingEventsDataHolderService._selectedAuction$.value;

    let sequenceNo = this.sectionTemplateList.length;
    let preSequenceText = ApplicationUtils.alphabetsList()[sequenceNo];

    let sectionTemplate = new SectionTemplate();
    sectionTemplate.sectionId = UUID.UUID().toString();
    sectionTemplate.sequenceNo = `${sequenceNo + 1}`;
    sectionTemplate.orgCode = selectedAuction?.orgCode;
    sectionTemplate.auctionId = selectedAuction?.auctionId;
    sectionTemplate.preSequenceText = preSequenceText;
    sectionTemplate.envelopeType = SourcingEnvelopeType.TECHNICAL;
    sectionTemplate.questionnaireId = this.questionnaireWrapperDto?.questionnaireTemplate?.templateId;
    sectionTemplate.templateDescription = '';
    sectionTemplate.rfxId = 'NA';
    sectionTemplate.rfxSubcategoryId = 'NA';

    this.sectionTemplateList.push(sectionTemplate);
    this.selectedSectionTemplate = sectionTemplate;
    this.selectedSectionId = sectionTemplate.sectionId;

    this.questionnaireService.setSelectedSection(this.selectedSectionTemplate);

    if (this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate) {
      this.ctrlSectionScore.setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
      this.ctrlSectionScore.updateValueAndValidity();
    }

    this.isNewQuestion = false;
    this.resetSectionTemplate();
  }

  mergeSectionTemplate() {

    let tempSectionTemplate = this.sectionTemplateList.find(item => item.sectionId == this.selectedSectionTemplate?.sectionId);
    let sectionTemplate: SectionTemplate = ApplicationUtils.clone(tempSectionTemplate);

    sectionTemplate.sectionName = this.ctrlSectionTitle.value.trim();
    sectionTemplate.sectionScore = this.ctrlSectionScore.value;

    return sectionTemplate;
  }

  saveSectionTemplate() {
    if (this.ctrlSectionTitle.invalid || this.ctrlSectionScore.invalid) {
      return;
    }

    console.log(this.ctrlSectionTitle.value);

    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let sectionsTemplate = this.mergeSectionTemplate();

    this.questionnaireService.saveSectionTemplate(sectionsTemplate).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
          }, 2000)

        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto?.message;

          setTimeout(() => {
            this._showErrorToast$.next(false);
          }, 2000)
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving sections. Try again.";

        setTimeout(() => {
          this._showErrorToast$.next(false);
        }, 2000)
      }
    })
  }

  deleteSectionTemplate() {
    this._showErrorToastForModal$.next(false);
    this.errorMsgForModal = "";
    this.isLoadingForModal = true;

    this.questionnaireService.deleteSection(this.selectedSectionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToastForModal$.next(true);
          this.isLoadingForModal = false;

          this.resetSectionTemplate();
          this.selectedSectionId = undefined;

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          setTimeout(() => {
            this._showSuccessToastForModal$.next(false);
            this.closeModal(this.deleteConfirmModalRef!);
          }, 2000)

        } else {
          this.isLoadingForModal = false;
          this._showErrorToastForModal$.next(true);
          this.errorMsgForModal = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoadingForModal = false;
        this._showErrorToastForModal$.next(true);
        this.errorMsgForModal = "Error while deleting question. Try again.";
      }
    })
  }

  handleSectionOrders() {
    let resourceSwapDtoList: ResourceSwapDto[] = [];

    this.sectionTemplateList.forEach((item, index) => {
      let resourceSwapDto = new ResourceSwapDto();
      resourceSwapDto.resourceId = item.sectionId;
      resourceSwapDto.sequenceNo = `${index + 1}`;
      resourceSwapDto.pretext = ApplicationUtils.alphabetsList()[index];
      resourceSwapDtoList.push(resourceSwapDto);
    })

    let resourceSwapWrapperDto = new ResourceSwapWrapperDto();
    resourceSwapWrapperDto.sourcingEnvelopeType = SourcingEnvelopeType.TECHNICAL;
    resourceSwapWrapperDto.resourceSwapDtoList = resourceSwapDtoList;

    this.appLoader?.openLoaderIcon('SECTION_REORDER', 'Reordering..');
    this.questionnaireService.updateSectionOrdering(resourceSwapWrapperDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.appLoader?.closeLoaderIcon('SECTION_REORDER');

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);
        } else {
          this.appLoader?.closeLoaderIcon('SECTION_REORDER');
        }
      },
      error: (err) => {
        console.error(err);
        this.appLoader?.closeLoaderIcon('SECTION_REORDER');
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    let previousItem = this.sectionTemplateList[event.previousIndex];
    let currentItem = this.sectionTemplateList[event.currentIndex];

    if (!previousItem.sectionName || !currentItem.sectionName) {
      return;
    }

    moveItemInArray(this.sectionTemplateList, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.handleSectionOrders();
    }
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
  }
}
