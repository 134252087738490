import { TranslateService } from './../../../shared/services/translate.service';
import { AuctionSaveWrapperDto } from './../../../shared/models/user/AuctionSaveWrapperDto';
import { AuctionValidationErrorCodeDto } from './../../../shared/models/user/AuctionValidationErrorCodeDto';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ListingContactDto } from 'src/app/shared/models/ListingContactDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { FileService } from 'src/app/shared/services/file.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { UUID } from 'angular2-uuid';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';

@Component({
  selector: 'app-auction-manage-contacts',
  templateUrl: './auction-manage-contacts.component.html',
  styleUrls: ['./auction-manage-contacts.component.sass']
})
export class AuctionManageContactsComponent {

  auctionHouse?: AuctionHouseDto
  auctionEntityDto?: AuctionEntityDto;
  listingContact?: ListingContactDto;
  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];
  listingContacts: Array<ListingContactDto> = [];
  selectedCountryCode = CountryCodeDto.default();

  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];

  currentFileInfoDto?: FileInfoDto | null
  currentFile?: File | null;
  imageUrl?: string | ArrayBuffer;
  errorMsg: string | undefined;
  fileUploadError: boolean = false;

  isLoading?: boolean = false;
  isRemoveLoading?: boolean = true;
  isEditContact: boolean = false;
  currentIndex?: number;

  formGroup: FormGroup

  _errorMsg$ = new BehaviorSubject<string>('');

  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _isImageActive$ = new BehaviorSubject<boolean>(false);
  _isOrderActive$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _fileDisplayName$ = new BehaviorSubject<string | null>("");
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonHide$ = new BehaviorSubject<boolean>(false);
  _isLocalListingContact$ = new BehaviorSubject<boolean>(false);
  countryCode$ = new BehaviorSubject<CountryCodeDto|null>(null);

  selectedAuctionSubscription$?: Subscription;
  currentAuctionHouseSubscription$?: Subscription;

  @ViewChild('profilePic') profilePic?: HTMLInputElement;

  constructor(
    private transalteService: TranslateService,
    private fb: FormBuilder,
    private ngbModal: NgbModal,
    private fileService: FileService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminDashboardService: AdminDashboardService,
    public onboardService: OnboardingService
  ) {
    this.formGroup = this.fb.group({
      name: new FormControl('', Validators.required),
      emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
      mobileNo: new FormControl('', [Validators.required, Validators.pattern(Pattern.mobile)])
    })
  }

  ngOnInit(): void {
    this.currentAuctionHouseSubscription$ = this.adminDashboardService.getCurrentAuctionHouse$.subscribe((data) => {
      if (data) {
        this.auctionHouse = data;
      }
    })

    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe((data) => {
      if (data) {
        this.auctionEntityDto = data;

        if (this.auctionEntityDto.listingContactDtos) {
          this.listingContacts = [...this.auctionEntityDto.listingContactDtos];
        }
      }
    });
  }

  openViewImageModal() {
    let imageUrl = this.currentFileInfoDto ? `/downloadLandingBlob?fileId=${this.currentFileInfoDto!.fileId}` : this.imageUrl;
    let modalRef = this.ngbModal.open(ImagePreviewComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.imageUrl = imageUrl
  }

  get fc(): any { return this.formGroup.controls; }

  isLocalListingContact(listingContactId: string){
    let index = 0;

    if(index > -1){
      this.formGroup.disable();
      this._isSaveButtonHide$.next(false);
    }else{
      this._isSaveButtonHide$.next(true);
    }
  }

  populateListingContact() {
    this.isLocalListingContact(this.listingContact?.listingContactId!);

    this.formGroup.controls['name'].setValue(this.listingContact?.name!);
    this.formGroup.controls['emailId'].setValue(this.listingContact?.emailId!);
    this.formGroup.controls['mobileNo'].setValue(this.listingContact?.mobileNo!);

    this.currentFileInfoDto = this.listingContact?.profile;
    this.selectedCountryCode = this.listingContact?.countryCode ?? CountryCodeDto.default();
    this.countryCode$.next(this.selectedCountryCode);

    if (this.currentFileInfoDto && this.currentFileInfoDto.displayName) {
      this._fileDisplayName$.next(this.currentFileInfoDto.displayName);
    } else {
      this._fileDisplayName$.next("");
    }
  }

  openModal(content: any, listingContactDto?: ListingContactDto) {
    this._showErrorToast$.next(false);
    this.formGroup.enable();

    if (listingContactDto) {
      this.listingContact = listingContactDto;
      this.isEditContact = true;
      this.populateListingContact();
    }else{
      this._isSaveButtonHide$.next(true);
    }

    this.ngbModal.open(content, { size: 'md', backdrop: 'static', keyboard: false , centered: true });
  }

  openSortingModal(content: any) {
    this._showErrorToast$.next(false);
    this._isOrderActive$.next(true);
    if (this.auctionEntityDto?.listingContactDtos) {
      this.listingContacts = [...this.auctionEntityDto.listingContactDtos];
    }
    this.ngbModal.open(content, { size: 'md', backdrop: 'static', keyboard: false , centered: true });
  }

  closeModal() {
    this._isOrderActive$.next(false);
    this._validationErrorPresent$.next(false);
    this.ngbModal.dismissAll();
    this.isLoading = false;
    this.removeProfileImage();
    this.errorMsg = "";
    this.listingContact = null!;
    this.formGroup.reset();

    this.isEditContact = false;
    this.currentIndex = undefined;
    this._fileDisplayName$.next(null);
    this._isSaveButtonDisable$.next(false);

    this.selectedCountryCode = CountryCodeDto.default();
    this.countryCode$.next(this.selectedCountryCode);
  }

  imageActive() {
    this._isImageActive$.next(!this._isImageActive$.value);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listingContacts, event.previousIndex, event.currentIndex);
  }

  formValidationFailed(){
    this._validationErrorPresent$.next(false);
    if(this.formGroup.invalid){
      this._validationErrorPresent$.next(true);
      return true;
    }
    if(!this._fileDisplayName$.value){
      this._validationErrorPresent$.next(true);
      return true;
    }

    if(!this.isEditContact && this.auctionEntityDto?.listingContactDtos && this.auctionEntityDto?.listingContactDtos.length > 0){
      let emailId = this.formGroup.controls['emailId'].value;

      let existingListingContact = this.auctionEntityDto?.listingContactDtos!.find(item => (item.emailId == emailId));
      if(existingListingContact){
        this._showErrorToast$.next(true);
        this.errorMsg = "Email id already exists!";

        return true;
      }
    }

    return false;
  }

  chooseFile(event: any) {
    this._showErrorToast$.next(false);

    this.currentFileInfoDto = null;
    this.errorMsg = "";
    this.currentFile = event.target.files[0];
    if (!this.imageTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this.currentFile = null;
      this._showErrorToast$.next(true);

      return;
    }

    this._fileDisplayName$.next(this.currentFile?.name as string);
    let reader = new FileReader();
    reader.readAsDataURL(this.currentFile!);
    reader.onload = () => {
      this.imageUrl = reader.result?.toString();
    }
  }

  removeProfileImage() {
    this.currentFileInfoDto = null;
    this.imageUrl = null!;
    this.currentFile = null;
    this._fileDisplayName$.next("");
    this._isImageActive$.next(false)
    // this.profilePic?.setAttribute('value', '');
  }



  handleValidSubmit() {
    if (!this.isAllowToEdit()) {
      return;
    }
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    let isUploadAction = false;

    if (this._isOrderActive$.value == false) {

      if (this.formValidationFailed() ) {
        return;
      }

      if (!this.currentFile) {
        let contactDto = null;
        if (!this.auctionEntityDto?.listingContactDtos) {
          this.auctionEntityDto!.listingContactDtos = [];
        }

        if (!this.isEditContact) {
          contactDto = new ListingContactDto();
          contactDto.listingContactId = UUID.UUID().toString()
          this.listingContacts!.push(contactDto!);
        } else {
          contactDto = this.listingContact;
        }

        contactDto!.name = this.formGroup.controls['name'].value!;
        contactDto!.emailId = this.formGroup.controls['emailId'].value!;
        contactDto!.mobileNo = this.formGroup.controls['mobileNo'].value!;
        contactDto!.companyId = this.auctionEntityDto?.companyId;
        contactDto!.profile = this.currentFileInfoDto!;
        contactDto!.countryCode = this.selectedCountryCode;

      } else {
        isUploadAction = true;
      }

      this.isLoading = true;

    } else {
      this.isLoading = true;
    }

    this.saveListingContacts(isUploadAction);
  }

  saveListingContacts(isUploadAction: boolean) {
    this._isSaveButtonDisable$.next(true);
    if (isUploadAction == true) {
      this.uploadFile(this.currentFile!);
    } else {
      this.save();
    }
  }

  uploadFile(currentFile: File) {
    this.fileUploadError = false;
    this.errorMsg = "";

    let metaData = {
      'mimeType': currentFile.type,
      'version': 0,
      'publicApi': true,
      'dataType': 'Square'
    };

    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;
          this.currentFileInfoDto = new FileInfoDto();
          this.currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          this.currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.fileSize = currentFile.size.toString();
          this.currentFileInfoDto.fileType = currentFile.type;
          this.currentFileInfoDto.uploadDate = new Date().toDateString();
          // this.toasterService.openSuccessToster("Uploaded Successfully");

          let contactDto = null;
          if (!this.auctionEntityDto?.listingContactDtos) {
            this.auctionEntityDto!.listingContactDtos = [];
          }

          if (!this.isEditContact) {
            contactDto = new ListingContactDto();
            contactDto.listingContactId = UUID.UUID().toString()
            this.listingContacts!.push(contactDto!);
          } else {
            contactDto = this.listingContact;
          }

          contactDto!.name = this.formGroup.controls['name'].value!;
          contactDto!.emailId = this.formGroup.controls['emailId'].value!;
          contactDto!.mobileNo = this.formGroup.controls['mobileNo'].value!;
          contactDto!.companyId = this.auctionEntityDto?.companyId;

          contactDto!.profile = this.currentFileInfoDto!;
          contactDto!.countryCode = this.selectedCountryCode;

          this.save();
        }
      } else {
        this.fileUploadError = true;
        this._isSaveButtonDisable$.next(false);
      }
    })
  }

  save() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";

    let auctionEntityDto: AuctionEntityDto = ApplicationUtils.clone(this.auctionEntityDto);
    auctionEntityDto.listingContactDtos = this.listingContacts;

    this.adminDashboardService.saveAuctionDetails(auctionEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionSaveWrapperDto = apiResponseDto.data  as AuctionSaveWrapperDto;
          let auctionValidationErrorCodeDtoList =  auctionSaveWrapperDto.auctionValidationErrorCodeDtos;
          if(!auctionValidationErrorCodeDtoList ||  auctionValidationErrorCodeDtoList.length == 0){
            this._showSuccessToast$.next(true);
            this.isLoading = false;
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this._isOrderActive$.next(false);
              this._isSaveButtonDisable$.next(false);
              this.isLoading = false;
              this.closeModal();
            }, 2000)
          }else{
            this.closeModal();
            this._showSuccessToast$.next(false);
            this._isOrderActive$.next(false);
            this._isSaveButtonDisable$.next(false);
            this.isLoading = false;
            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
            this.listingContacts = this.auctionEntityDto?.listingContactDtos ?? [];
          }
        } else {
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto.message;
          this._isOrderActive$.next(false);
          this._isSaveButtonDisable$.next(false);
          this.isLoading = false;
          this.listingContacts = this.auctionEntityDto?.listingContactDtos ?? [];
        }
      },
      error: (err) => {
        console.error(err);
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving contact. Try again.";
        this._isSaveButtonDisable$.next(false);
        this.isLoading = false;
        this.listingContacts = this.auctionEntityDto?.listingContactDtos ?? [];
      }
    })
  }

  openErrorModel() {
    let errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
      });
    errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }

  getErrorMsg(error: AuctionValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.transalteService)
  }

  openConfirmationModal(templateContent: TemplateRef<any>, index: number) {
    if (!this.isAllowToEdit()) {
      return;
    }
    this._errorMsg$.next("");
    this.isRemoveLoading = false;
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.currentIndex = index;

    this.ngbModal.open(templateContent, { size: 'md', backdrop: 'static', keyboard: false });
  }

  deleteAuctionContact() {
    this._isSaveButtonDisable$.next(true);
    this.isRemoveLoading = true;
    let auctionEntityDto = ApplicationUtils.clone(this.auctionEntityDto);
    auctionEntityDto?.listingContactDtos?.splice(this.currentIndex, 1);

    this.adminDashboardService.saveAuctionDetails(auctionEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto) {
          if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
            let auctionSaveWrapperDto = apiResponseDto.data  as AuctionSaveWrapperDto;
            let auctionValidationErrorCodeDtoList =  auctionSaveWrapperDto.auctionValidationErrorCodeDtos;
            if(!auctionValidationErrorCodeDtoList ||  auctionValidationErrorCodeDtoList.length == 0){
              this._showSuccessToast$.next(true);
              this.isRemoveLoading = false;
              setTimeout(() => {
                this._showSuccessToast$.next(false);
                this._isSaveButtonDisable$.next(false);
                this.closeModal();
              }, 2000)
            }else{
              this.isRemoveLoading = false;
              this._isSaveButtonDisable$.next(false);
              this.closeModal();
              this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
              this.openErrorModel();
            }
          } else {
            this._showErrorToast$.next(true);
            this._errorMsg$.next(apiResponseDto.message!);
            this.isRemoveLoading = false;
            this._isSaveButtonDisable$.next(false);
          }
        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next("Error while delete contact");
          this.isRemoveLoading = false;
          this._isSaveButtonDisable$.next(false);
        }
        this.currentIndex = undefined;
      },
      error: (err) => {
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while delete contact");
        this.isRemoveLoading = false;
        this.currentIndex = undefined;
        this._isSaveButtonDisable$.next(false);
      }
    })
  }

  handleCountryCodeEvent(countryCodeDto: CountryCodeDto) {
    this.selectedCountryCode = countryCodeDto;

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength+ "}"

    this.formGroup.controls['mobileNo'].clearValidators();
    this.formGroup.controls['mobileNo'].setValidators([Validators.required,  Validators.pattern(pattern)]);
    this.formGroup.controls['mobileNo'].updateValueAndValidity();
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
    if (this.currentAuctionHouseSubscription$) {
      this.currentAuctionHouseSubscription$.unsubscribe();
    }
  }


  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }

}
