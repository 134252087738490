import { TranslateService } from './../../../shared/services/translate.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AuctionValidationErrorCodeDto } from './../../../shared/models/user/AuctionValidationErrorCodeDto';
import { AuctionSaveWrapperDto } from './../../../shared/models/user/AuctionSaveWrapperDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { AngularEditorComponent, AngularEditorConfig } from 'src/assets/plugins/angular-editor/kolkov-angular-editor';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';


@Component({
  selector: 'app-auction-manage-legal-policy',
  templateUrl: './auction-manage-legal-policy.component.html',
  styleUrls: ['./auction-manage-legal-policy.component.sass']
})
export class AuctionManageLegalPolicyComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("privacyEditor") privacyEditor?: AngularEditorComponent;
  @ViewChild("termsConditionsEditor") termsConditionsEditor?: AngularEditorComponent;

  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];

  formGroup: FormGroup;
  auctionEntityDto?: AuctionEntityDto;

  isLoading?: boolean = false;
  currentModal?: string;
  selectedAuctionSubscription$?: Subscription;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '3rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '2',
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode'
      ]
    ],
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Page Divider',
        class: 'break',
        tag: 'div',
      },
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'hr',
      },
    ],
  };

  constructor(
    private transalteService: TranslateService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private adminDashboardService: AdminDashboardService,
  ) {
    this.formGroup = this.fb.group({
      confidentialAgreement: ['', Validators.required],
      termsConditions: ['', Validators.required]
    })

  }

  ngOnInit(): void {
    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(auction => {
      this.auctionEntityDto = auction!;
      this.formGroup.patchValue(this.auctionEntityDto!);
    })
  }

  ngAfterViewInit(): void {
    ApplicationUtils.clearTextFormatOnPaste('termsConditionsEditor')
    ApplicationUtils.clearTextFormatOnPaste('privacyEditor')
  }

  get fc(): any { return this.formGroup.controls; }

  closeModal() {
    this.ngbModal.dismissAll();
    this._validationErrorPresent$.next(false);
    this.currentModal = undefined;
  }

  formValidationFailed() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  mergeAuctionEntityDto(formValue: any): AuctionEntityDto {
    let auctionEntityDto = ApplicationUtils.clone(this.auctionEntityDto);
    auctionEntityDto!.confidentialAgreement = formValue.confidentialAgreement;
    auctionEntityDto!.termsConditions = formValue.termsConditions;
    return auctionEntityDto;
  }

  saveLegalPolicy(saveForcefully: boolean) {
    this._showErrorToast$.next(false);

    if (!saveForcefully) {
      if (this.privacyEditor?.focused || this.termsConditionsEditor?.focused) {
        return;
      }
    }

    if (this.formValidationFailed()) {
      return;
    }

    this.isLoading = true;
    let formValue = this.formGroup.value;

    let auctionEntityDto = this.mergeAuctionEntityDto(formValue);

    this.adminDashboardService.saveAuctionDetails(auctionEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionSaveWrapperDto = apiResponseDto.data  as AuctionSaveWrapperDto;
          let auctionValidationErrorCodeDtoList =  auctionSaveWrapperDto.auctionValidationErrorCodeDtos;
          if(!auctionValidationErrorCodeDtoList ||  auctionValidationErrorCodeDtoList.length == 0){
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
          }else{
          this.isLoading = false;
          this.closeModal();
          this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
          this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this.isLoading = false;
          // this._isSaveButtonEnable$.next(true);

        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
        // this._isSaveButtonEnable$.next(true);

      }
    })
  }

  openErrorModel() {
    let errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
      });
    errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }

    getErrorMsg(error: AuctionValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.transalteService)
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
  }

}
