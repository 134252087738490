import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PolygonTransaction } from 'src/app/shared/models/blockchain/PolygonTransaction';
import { BlockchainService } from 'src/app/shared/services/blockchain.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-blockchain-transaction-details',
  templateUrl: './blockchain-transaction-details.component.html',
  styleUrls: ['./blockchain-transaction-details.component.sass']
})
export class BlockchainTransactionDetailsComponent implements OnInit {
  @Input() transactionHash!: string;

  selectedTab: number = 1
  transaction?: PolygonTransaction;
  // transactionReceipt?: PolygonTransactionReceipt
  seeMore: boolean = false
  isLoading: boolean = false

  constructor(
    private activeModal: NgbActiveModal,
    private blockchainService: BlockchainService,
    private datePipe: DatePipe,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    // this.isLoading = true;
    // this.blockchainService.getTransactionByHash(this.transactionHash).subscribe({
    //   next: (apiResponseDto: ServerAPIResponseDto) => {
    //     if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
    //       let transactionResponse = apiResponseDto.data as PolygonTransactionResponse;
    //       this.transaction = transactionResponse.result;
    //       this.isLoading = false;
    //     }
    //   },
    //   error: (err) => {
    //     console.log(err);
    //     // this._errorMsg$.next("Error while rejecting award. Try again.");
    //   }
    // })

    // this.blockchainService.getTransactionReceipt(this.transactionHash).subscribe({
    //   next: (apiResponseDto: ServerAPIResponseDto) => {
    //     if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
    //       let transactionReceiptResponse = apiResponseDto.data as PolygonTransactionReceiptResponse;
    //       this.transactionReceipt = transactionReceiptResponse.result;
    //       this.isLoading = false;
    //     }
    //   },
    //   error: (err) => {
    //     console.log(err);
    //     // this._errorMsg$.next("Error while rejecting award. Try again.");
    //   }
    // })

    this.transaction = this.blockchainService.getTransactionDataByTxnHash(this.transactionHash);
  }

  changeSeeMore(){
    this.seeMore = !this.seeMore;
  }

  changeStatus(newStatus : number){
    this.selectedTab= newStatus
  }

  closeModal() {
    this.activeModal.close();
  }

  hexToInt(val: any) {
    if (!val) return 0;
    return parseInt(val);
  }

  displayTimestamp() {
    return this.datePipe.transform(Number(this.transaction?.timeStamp)*1000, 'MMM-dd-yyyy hh:mm:ss a Z')
  }

  copyStringToClipboard(val: any) {
    this.clipboard.copy(val);
  }

  formatPrice(val: any) {
    return ApplicationUtils.getFormattedPrice('en-US', val)
  }

  getTransactionFees() {
    let transactionFee = Number(this.transaction?.gasPrice) * Number(this.transaction?.gasUsed);
    return this.gasToMatic(transactionFee);
  }
  
  getGasUsedPercent() {
    let percent = (this.hexToInt(this.transaction?.gasUsed)/this.hexToInt(this.transaction?.gas))*100;
    return percent.toFixed(2) + '%';
  }

  gasToMatic(val: any) {    
    return (val * 0.000000000000000001).toFixed(10) + ' MATIC'
  }

  gasToGWei(val: any) {
    return (val * 0.000000001).toFixed(1) + ' Gwei'
  }
}
