<div class="p-4">
  <div class="grid px-6 py-12 items-center justify-center" *ngIf="!approvalWorkflowUiDtos.length">
    <img src="assets/images/approval-1.svg" class=" h-16 w-16 mx-auto my-0">
    <div class="flex items-center justify-center">
      <div class="text-xl font-medium text-gray-700  mt-6">
        No approval workflows yet.
      </div>
    </div>
    <div class="flex items-center justify-center">
      <div class="text-xs font-normal text-gray-500 mt-2 mb-6">
        You have not added any approval workflows yet.
      </div>
    </div>
    <div class="flex items-center justify-center">
      <button
        class="inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        (click)="openTenderApprovalDrawer('TENDER_PUBLISHERS')">
        <i-lucide name="plus" [size]="18" color="#fff" class="my-icon"></i-lucide>
        Add Approval Workflow
      </button>
    </div>
  </div>
  <div *ngIf="approvalWorkflowUiDtos.length > 0">
    <div class="grid grid-cols-12  gap-4 w-full mb-4">
      <div class="col-span-4" *ngIf="tenderPublisher?.appliedUiDtos && tenderPublisher?.appliedUiDtos!.length > 0">
        <div class="flex flex-row items-center justify-between mb-2">
          <div class="basis-1/2">
            <div class="text-gray-600 text-xs font-semibold uppercase">
              Tender publishers
            </div>
          </div>
          <div class="basis-1/2 text-end">
            <span
              class="inline-flex h-6 w-6 p-1 items-center justify-center bg-blue-100 text-blue-500 rounded-full me-2">
              {{tenderPublisher?.awfDefineUiDto?.mandatoryApprovals}}
            </span>
            <span
              class="inline-flex h-6 py-1 px-3 items-center justify-between bg-blue-100 text-blue-500 text-xs rounded-full me-2">
              Mandatory
            </span>
            <span>
              <p-menu #menu [model]="createNewMenuItems" [popup]="true">
                <ng-template pTemplate="item" let-item>
                  <div class="p-menuitem-link p-3 grid" (click)="openTenderApprovalDrawer(applicationConstants.TENDER_PUBLISHERS)">
                    <div class="text-gray-900 font-semibold text-sm text-start">
                     Edit
                    </div>
                  </div>
                </ng-template>
              </p-menu>
              <span class="inline-flex cursor-pointer"  (click)="menu.toggle($event)">
                <i-lucide name="ellipsis" [size]="18" color="#000" class="my-icon"></i-lucide>
              </span>
            </span>
          </div>
        </div>
        <div class="border border-gray-300 bg-white rounded-lg py-2 ps-2 gap-28 mb-3"
          *ngFor="let item of tenderPublisher?.appliedUiDtos;">
          <div class="flex flex-row">
            <div class="basis-1/12 mt-1">
              <span
                class="inline-flex h-8 w-8 p-1 items-center justify-center bg-blue-100 text-blue-500 rounded-full me-2">
                <img src="/downloadLandingBlob?fileId={{item?.userInfoUiDto?.profileImage?.fileId}}"
                  *ngIf="item?.userInfoUiDto?.profileImage">
                <span *ngIf="!item?.userInfoUiDto?.profileImage">
                  {{(item?.userInfoUiDto?.firstName + ' ' + item?.userInfoUiDto?.lastName) | textAvatar}}
                </span>
              </span>
            </div>
            <div class="basis-10/12">
              <div class=" text-gray-800 font-medium text-sm overflow-class-xsmall">
                {{item.userInfoUiDto?.firstName}} {{item.userInfoUiDto?.lastName}}
              </div>
              <div class=" text-gray-600 font-normal text-xs ">
                {{item.userInfoUiDto?.emailId}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-4" *ngIf="technicalApprover?.appliedUiDtos && technicalApprover?.appliedUiDtos!.length > 0">
        <div class="flex flex-row items-center justify-between mb-2">
          <div class="basis-1/2">
            <div class="text-gray-600 text-xs font-semibold uppercase">
              Technical opener
            </div>
          </div>
          <div class="basis-1/2 text-end">
            <span
              class="inline-flex h-6 w-6 p-1 items-center justify-center bg-blue-100 text-blue-500 rounded-full me-2">
              {{technicalApprover?.awfDefineUiDto?.mandatoryApprovals}}
            </span>
            <span
              class="inline-flex h-6 py-1 px-3 items-center justify-between bg-blue-100 text-blue-500 text-xs rounded-full me-2">
              Mandatory
            </span>
            <span>
              <p-menu #menu [model]="createTechItems" [popup]="true">
                <ng-template pTemplate="item" let-item>
                  <div class="p-menuitem-link p-3 grid" (click)="openTenderApprovalDrawer(applicationConstants.TECHNICAL_BID_OPENERS)">
                    <div class="text-gray-900 font-semibold text-sm text-start">
                     Edit
                    </div>
                  </div>
                </ng-template>
              </p-menu>
              <span class="inline-flex cursor-pointer"  (click)="menu.toggle($event)">
                <i-lucide name="ellipsis" [size]="18" color="#000" class="my-icon"></i-lucide>
              </span>
            </span>
          </div>
        </div>
        <div class="border border-gray-300 bg-white rounded-lg py-2 ps-2 gap-28 mb-3"
          *ngFor="let item of technicalApprover?.appliedUiDtos;">
          <div class="flex flex-row">
            <div class="basis-1/12 mt-1">
              <span
                class="inline-flex h-8 w-8 p-1 items-center justify-center bg-blue-100 text-blue-500 rounded-full me-2">
                <img src="/downloadLandingBlob?fileId={{item?.userInfoUiDto?.profileImage?.fileId}}"
                  *ngIf="item?.userInfoUiDto?.profileImage">
                <span *ngIf="!item?.userInfoUiDto?.profileImage">
                  {{(item?.userInfoUiDto?.firstName + ' ' + item?.userInfoUiDto?.lastName) | textAvatar}}
                </span>
              </span>
            </div>
            <div class="basis-10/12">
              <div class=" text-gray-800 font-medium text-sm overflow-class-xsmall">
                {{item?.userInfoUiDto?.firstName}} {{item?.userInfoUiDto?.lastName}}
              </div>
              <div class=" text-gray-600 font-normal text-xs ">
                {{item?.userInfoUiDto?.emailId}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-4" *ngIf="financialApprover?.appliedUiDtos && financialApprover?.appliedUiDtos!.length > 0">
        <div class="flex flex-row items-center justify-between mb-2">
          <div class="basis-1/2">
            <div class="text-gray-600 text-xs font-semibold uppercase">
              Financial opener
            </div>
          </div>
          <div class="basis-1/2 text-end">
            <span
              class="inline-flex h-6 w-6 p-1 items-center justify-center bg-blue-100 text-blue-500 rounded-full me-2">
              {{financialApprover?.awfDefineUiDto?.mandatoryApprovals}}
            </span>
            <span
              class="inline-flex h-6 py-1 px-3 items-center justify-center bg-blue-100 text-blue-500 text-xs rounded-full me-2">
              Mandatory
            </span>
            <span>
              <p-menu #menu [model]="createFinItems" [popup]="true">
                <ng-template pTemplate="item" let-item>
                  <div class="p-menuitem-link p-3 grid" (click)="openTenderApprovalDrawer(applicationConstants.FINANCIAL_BID_OPENERS)">
                    <div class="text-gray-900 font-semibold text-sm text-start">
                     Edit
                    </div>
                  </div>
                </ng-template>
              </p-menu>
              <span class="inline-flex cursor-pointer"  (click)="menu.toggle($event)">
                <i-lucide name="ellipsis" [size]="18" color="#000" class="my-icon"></i-lucide>
              </span>
            </span>
          </div>
        </div>
        <div class="border border-gray-300 bg-white rounded-lg py-2 ps-2 gap-28 mb-3"
          *ngFor="let item of financialApprover?.appliedUiDtos;">
          <div class="flex flex-row">
            <div class="basis-1/12 mt-1">
              <span
                class="inline-flex h-8 w-8 p-1 items-center justify-center bg-blue-100 text-blue-500 rounded-full me-2">

                <img src="/downloadLandingBlob?fileId={{item?.userInfoUiDto?.profileImage?.fileId}}"
                  *ngIf="item?.userInfoUiDto?.profileImage">
                <span *ngIf="!item?.userInfoUiDto?.profileImage">
                  {{(item?.userInfoUiDto?.firstName + ' ' + item?.userInfoUiDto?.lastName) | textAvatar}}
                </span>
              </span>
            </div>
            <div class="basis-10/12">
              <div class=" text-gray-800 font-medium text-sm overflow-class-xsmall">
                {{item?.userInfoUiDto?.firstName}} {{item?.userInfoUiDto?.lastName}}
              </div>
              <div class=" text-gray-600 font-normal text-xs ">
                {{item?.userInfoUiDto?.emailId}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>