import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TenderQuestionnaireTemplateDto } from 'src/app/shared/models/tender/TenderQuestionnaireTemplateDto';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { selectTenderWrapperUiDto, selectTenderOpportunityWrapperDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-tender-questionnaire',
  templateUrl: './tender-questionnaire.component.html',
  styleUrls: ['./tender-questionnaire.component.sass']
})
export class TenderQuestionnaireComponent implements OnInit, OnDestroy {
  tenderWrapperUiDto?: TenderWrapperUiDto;
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;
  tenderQuestionnaireTemplateDto?: TenderQuestionnaireTemplateDto;

  tenderWrapperUiDtoSubscription$?: Subscription;
  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  constructor(
    private store: Store,
    private drawerService: DrawerService
  ) { }

  ngOnInit(): void {
    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
        let tenderQuestionnaireWrapperDto = this.tenderOpportunityWrapperDto?.tenderQuestionnaireWrapperDto;

        if (tenderQuestionnaireWrapperDto) {
          this.tenderQuestionnaireTemplateDto = tenderQuestionnaireWrapperDto.tenderQuestionnaireTemplateDto;
        }
      } else {
        this.tenderOpportunityWrapperDto = undefined;
        this.tenderQuestionnaireTemplateDto = undefined;
      }
    })
  }

  openTenderQuestionnaireDrawer() {
    this.drawerService.openDrawer(ApplicationConstants.NEW_TENDER_QUESTIONNAIRE);
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }

    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
  
}
