<h2>Application Elements</h2>

<!-- <form>
  <div class="mb-3">
    <label for="login-username" class="form-label">Username / Email</label>
    <input type="email" class="form-control" id="login-username" />
  </div>
  <div class="mb-3">
    <label for="login-password" class="form-label">Password</label>
    <input type="password" class="form-control" id="login-password" />
  </div>
  <div class="mb-3 text-end">
    <button type="button" class="btn btn-primary">Login</button>
  </div>
</form> -->


<div class="container">
  <h3>Dialogs</h3>
  <div>
    <button class="btn btn-outline-primary mb-2 me-2" (click)="openMessageDialog(messageDialog)">
      Message Box Dialog
    </button>

    <button class="btn btn-outline-primary mb-2 me-2" (click)="openDialogLogin(dialogLogin)">
      Login
    </button>
  </div>

  <h3>UI Controls</h3>
  <div>
    <h4>Input Boxes</h4>
    <div>
      <div class="form-floating mb-3">
        <input type="email" class="form-control" id="elementsEmail" placeholder="name@example.com" />
        <label for="elementsEmail">Email address</label>
      </div>
      <div class="form-floating">
        <input type="password" class="form-control" id="elementsPassword" placeholder="Password" />
        <label for="elementsPassword">Password</label>
      </div>
    </div>
  </div>
  <div>
    <h4>Buttons</h4>
    <div>
      <button type="button" class="btn btn-primary">Primary</button>
      <button type="button" class="btn btn-secondary">Secondary</button>
      <button type="button" class="btn btn-success">Success</button>
      <button type="button" class="btn btn-danger">Danger</button>
      <button type="button" class="btn btn-warning">Warning</button>
      <button type="button" class="btn btn-info">Info</button>
      <button type="button" class="btn btn-light">Light</button>
      <button type="button" class="btn btn-dark">Dark</button>
      <button type="button" class="btn btn-link">Link</button>
    </div>
    <div>
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer">
    </div>

    <button type="button" class="btn btn-primary">
      Notifications <span class="badge bg-secondary">4</span>
    </button>

    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        Dropdown button
      </button>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item" href="#">Action</a></li>
        <li><a class="dropdown-item" href="#">Another action</a></li>
        <li><a class="dropdown-item" href="#">Something else here</a></li>
      </ul>
    </div>

    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span class="material-symbols-outlined">sort</span>Dropdown button
      </button>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item" href="#">Action</a></li>
        <li><a class="dropdown-item" href="#">Another action</a></li>
        <li><a class="dropdown-item" href="#">Something else here</a></li>
      </ul>
    </div>
  </div>
  <div>
    <h4>Checkbox</h4>
    <div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        <label class="form-check-label" for="flexCheckDefault">
          Default checkbox
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
        <label class="form-check-label" for="flexCheckChecked">
          Checked checkbox
        </label>
      </div>
    </div>
  </div>
  <div>
    <h4>Toggle Switch</h4>
    <div>
      <div class="form-check form-switch">
        <input class="form-check-input c-pointer" type="checkbox" id="flexSwitchCheckDefault" />
        <label class="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input c-pointer" type="checkbox" id="flexSwitchCheckChecked" checked />
        <label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>
      </div>
    </div>
  </div>
  <div>
    <h4>Popups</h4>
    <div>Popup - Close Button</div>
  </div>

  <div>
    <h4>Tables</h4>
    <div></div>
  </div>

  <div>
    <h4>Searchbox</h4>
    <div>
      <div class="input-group">
        <div class="form-outline">
          <input type="search" id="elementSearch" class="form-control" />
          <label class="form-label" for="elementSearch">Search</label>
        </div>
        <button type="button" class="btn btn-primary">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </div>

  <div>
    <h4>Help</h4>
    <button type="button" class="btn btn-lg btn-danger" data-bs-toggle="popover" data-bs-title="Information Title"
      data-bs-content="This is information content.">
      <span class="material-symbols-outlined">info</span>
    </button>
  </div>
</div>

<!-- TABLE -->

<div class="container mt-3">
  <form>
    <div class="mb-3 row">
      <div class="col-xs-3 col-sm-auto btn-group">
        <input id="table-filtering-search" class="form-control" type="text" [formControl]="filter" placeholder="Search" />
        <button type="button" class="btn btn-primary">
          <span class="material-symbols-outlined">search</span>
        </button>
      </div>
    </div>
  </form>
  <div class="table-responsive border">
    <table class="table">
      <thead class="table-primary">
        <tr>
          <th scope="col">#</th>
          <th scope="col">
            <span class="d-none d-sm-block">Country</span>
            <span class="d-block d-sm-none">
            </span>
          </th>
          <th scope="col">Area</th>
          <th scope="col">
            <span class="d-none d-sm-block">Population</span>
            <span class="d-block d-sm-none">
            </span></th>
          <th>Other</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let country of countries$ | async">
          <th scope="row">{{ country.id }}</th>
          <td>
           <div class="row">
          <div class="col-1">
            <img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="me-2"
            style="width: 20px" />
          </div>
          <div class="col-8">
            <span class="d-none d-sm-block">{{ country.name }}</span>
        <span class="d-block d-sm-none">
        </span>
          </div>
           </div>
          </td>
          <td>{{ country.area | number}}</td>
          <td>
            <span class="d-none d-sm-block">{{ country.population | number }}</span>
        <span class="d-block d-sm-none">
        </span>
            </td>
          <td>
            <div class="dropdown">
              <button class="btn btn-outline-primary btn-sm" type="button" data-bs-toggle="dropdown" placement="top"
                aria-expanded="false">
                <span class="material-symbols-outlined">
                  visibility
                </span>
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" routerLink="/aucmanage">
                    Edit
                  </a></li>
                <li><a class="dropdown-item">Delete</a></li>
                <li><a class="dropdown-item" (click)="openDialogLogin(dialogLogin)">Bidder Card View</a></li>
                <li><a class="dropdown-item" routerLink="/aucdetails">Bidder Detail View</a></li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-between p-2">
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
      (pageChange)="refreshCountries()">
    </ngb-pagination>
  </div>
  <!-- Templates -->
  <ng-template #messageDialog>
    <app-message-dialog></app-message-dialog>
  </ng-template>
  <ng-template #dialogLogin>
    <app-user-login></app-user-login>
  </ng-template>