<div class="model-body">
  <form class="needs-validation" novalidate [formGroup]="formGroup">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-3 w-full items-center justify-between">
      <div class="mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderFirstName">First Name</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderFirstName" formControlName="firstName"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.firstName.invalid}">

        <div
          *ngIf="fc.firstName.invalid && (_validationErrorPresent$ |async) ||fc.firstName.invalid && (fc.firstName.dirty || fc.firstName.touched)">
          <p class="text-red-500 " *ngIf="fc.firstName.errors?.required">Required
          </p>
        </div>
      </div>
      <div class="mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderLastName">Last Name</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderLastName"  formControlName="lastName"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.lastName.invalid}">

        <div
          *ngIf="fc.lastName.invalid &&  (_validationErrorPresent$ |async) || fc.lastName.invalid && (fc.lastName.dirty || fc.lastName.touched)">
          <p class="text-red-500 " *ngIf="fc.lastName.errors?.required">Required
          </p>
        </div>
      </div>

      <div class=" mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderEmail">Email (Username for
          Login)</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderEmail" formControlName="emailId"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.emailId.invalid}">
        <div
          *ngIf="fc.emailId.invalid && (_validationErrorPresent$ |async)|| fc.emailId.invalid && (fc.emailId.dirty || fc.emailId.touched)">
          <p class="text-red-500 " *ngIf="fc.emailId.errors?.required">Required</p>
          <p class="text-red-500 " *ngIf="fc.emailId.errors?.pattern">Invalid email address
          </p>
          <p class="text-red-500 " *ngIf="fc.emailId.value && fc.emailId.value.length > 50">
            Maximum Length
            Exceed</p>
        </div>
      </div>

      <div class="mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderPhone">Phone Number</label>
        <div class="flex w-full">
          <div class="input-group-append">
            <span class=""
              id="basic-addon2">
              <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                [countryCode$]="countryCodeForMobileNo$"></app-country-code-select>
            </span>
          </div>

          <input type="text"
            class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder "
            id="globalBidderPhone" formControlName="mobileNo"
            [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.mobileNo.invalid}">

          <div
            *ngIf="fc.mobileNo.invalid && (_validationErrorPresent$ |async) || fc.mobileNo.invalid && (fc.mobileNo.dirty || fc.mobileNo.touched)">
            <p class="text-red-500 " *ngIf="fc.mobileNo.errors?.required">Required
            </p>
            <p class="text-red-500 " *ngIf="fc.mobileNo.errors?.pattern">Invalid</p>
          </div>

        </div>
      </div>

      <div class=" mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderCompany">Company Name</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderCompany" formControlName="companyName"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.companyName.invalid}">
        <div
          *ngIf="fc.companyName.invalid && (_validationErrorPresent$ |async)|| fc.companyName.invalid && (fc.companyName.dirty || fc.companyName.touched)">
          <p class="text-red-500 " *ngIf="fc.companyName.errors?.required">Required</p>
        </div>
      </div>
      <div class=" mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderCompanyUrl">Company Url
          (Optional)</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderCompanyUrl" formControlName="companyUrl">
      </div>
      <div class="mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4"
          for="globalBidderDesignation">Designation(Optional)</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderDesignation" formControlName="designation">
      </div>
      <div class=" mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderFax">Fax No (Optional)</label>
        <div class="flex w-full">
          <div class="input-group-append">
            <span class=""
              id="basic-addon2">
              <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                [countryCode$]="countryCodeForFaxNo$"></app-country-code-select>
            </span>
          </div>

          <input type="text"
            class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
            id="globalBidderFax" formControlName="faxNo"
            [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.faxNo.invalid}">

          <div
            *ngIf="fc.faxNo.invalid && (_validationErrorPresent$ |async) || fc.faxNo.invalid && (fc.faxNo.dirty || fc.faxNo.touched)">
            <p class="text-red-500 " *ngIf="fc.faxNo.errors?.required">Required
            </p>
            <p class="text-red-500 " *ngIf="fc.faxNo.errors?.pattern">Invalid</p>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="mb-1">
          <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderSearch">Search Address</label>
          <input ngx-gp-autocomplete #placesRef="ngx-places" type="text"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
            [ngClass]="{'is-invalid': fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)}"
            id="globalBidderSearch" formControlName="searchAddress"
            (onAddressChange)="handleAddressChange($event)">

          <div
            *ngIf="(_validationErrorPresent$ |async) || fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)">
            <p class="text-red-500 " *ngIf="fc.searchAddress.errors?.required">Required</p>
            <p class="text-red-500 " *ngIf="fc.searchAddress.errors?.address">Invalid</p>
          </div>
        </div>
      </div>

      <div class=" mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderAddressOne"> Address Line
          1</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderAddressOne" formControlName="addressLine1"
          [ngClass]="{'is-invalid': fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)}">
        <div
          *ngIf="(_validationErrorPresent$ |async) || fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)">
          <p class="text-red-500 " *ngIf="fc.addressLine1.errors?.required">Required</p>
        </div>
      </div>

      <div class=" mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderAddressTwo">Address Line
          2</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderAddressTwo" formControlName="addressLine2">
      </div>


      <div class=" mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderCity">City</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderCity" formControlName="city"
          [ngClass]="{'is-invalid': fc.city.invalid && (fc.city.dirty || fc.city.touched)}">
        <div *ngIf="(_validationErrorPresent$ |async) || fc.city.invalid && (fc.city.dirty || fc.city.touched)">
          <p class="text-red-500 " *ngIf="fc.city.errors?.required">Required</p>
        </div>
      </div>


      <div class="mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderState">State</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderState" formControlName="state"
          [ngClass]="{'is-invalid': fc.state.invalid && (fc.state.dirty || fc.state.touched)}">

        <div *ngIf="(_validationErrorPresent$ |async) || fc.state.invalid && (fc.state.dirty || fc.state.touched)">
          <p class="text-red-500 " *ngIf="fc.state.errors?.required">Required</p>
        </div>
      </div>


      <div class="mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderCountry">Country</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderCountry" formControlName="country"
          [ngClass]="{'is-invalid': fc.country.invalid && (fc.country.dirty || fc.country.touched)}">
        <div
          *ngIf="(_validationErrorPresent$ |async) || fc.country.invalid && (fc.country.dirty || fc.country.touched)">
          <p class="text-red-500 " *ngIf="fc.country.errors?.required">Required</p>
        </div>
      </div>
      <div class="mb-1">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="globalBidderPin">PIN Code</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="globalBidderPin" formControlName="zipCode"
          [ngClass]="{'is-invalid': fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)}">

        <div
          *ngIf="(_validationErrorPresent$ |async) || fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)">
          <p class="text-red-500 " *ngIf="fc.zipCode.errors?.required">Required</p>
          <p class="text-red-500 " *ngIf="fc.zipCode.errors?.pattern">Invalid</p>
        </div>
      </div>
    </div>

    <!-- Category Preference -->
    <div class="col-12 mt-3">
      <div class="border p-2 bg-white" [ngClass]="{'border-danger' : !selectedPreferenceCategory}">
        <div class="flex flex-row mb-2">
          <div class="basis-8/12">
            <h6 class="heading-h5 mt-2">Category Preference</h6>
          </div>
          <div class="basis-4/12 text-end">
            <button
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex  ms-2 mt-1"
              (click)="openCategoryModal()">
              {{ selectedPreferenceCategory ? 'Edit' : 'Add' }}
            </button>
          </div>
        </div>

        <div class="p-3 text-center" *ngIf="!selectedPreferenceCategory">
          <h6>Preference Category Not Selected!</h6>
        </div>

        <div class="scroll-category-data">
          <div *ngIf="selectedPreferenceCategory">
            <label class="text-gray-700  text-sm font-semibold leading-4" for="description">Selected Material</label>
            <div class="table-responsive">
              <table class="table table-bordered medium-font mb-0 align-middle">
                <thead class="table-dark">
                  <tr class="text-center">
                    <th class=""><span>Label One</span></th>
                    <th class=""><span>Label Two</span></th>
                    <th class=""><span>Label Three</span></th>
                    <th class=""><span>Label Four</span></th>
                    <th class=""><span>Label Five</span></th>

                  </tr>
                </thead>

                <tbody class="bg-white">
                  <tr>
                    <td>{{selectedPreferenceCategory.categoryName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelOneName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelTwoName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelThreeName}}</td>
                    <td>{{selectedPreferenceCategory.categoryLevelFourName}}</td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Email & Mobile -->
    <div></div>

    <!-- <div class="col-lg-6" >
            <div class="bg-secondary text-center mb-3">
              <google-map >
                <map-marker #markerElem="mapMarker" >
                </map-marker>
                <map-info-window></map-info-window>
              </google-map>
            </div>
          </div> -->
    <!-- <div class="col-lg-12">
            <div class="mb-2">
              <label for="floatingInput" class="text-area-label">Select Category
              </label>

              <div ngbDropdown class="dropdown " #ngbDropdownCategory="ngbDropdown" [autoClose]="true">

                <button class="btn select-box-btn-class text-start dropdown-toggle mb-2 rounded-0" type="button"
                  data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false" placeholder="Select category"
                  [ngClass]="{'is-invalid': fc.category.invalid && (fc.category.dirty || fc.category.touched) && fc.category.errors?.required}">
                  <div class="row">
                    <div class="col-9">
                      <div class="overflow-class-sort">
                        {{fc.category.value}}
                      </div>
                    </div>
                    <div class="col-3 text-end">
                      <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-Xsmall">
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu text-start shadow  w-100 p-2 " ngbDropdownMenu>
                  <!-- SYMBOL SEARCH --/>
                  <div class="row text-center margin px-3">
                    <div class="col-md-12 fix-inputGroup">
                      <div class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control-sm text-primary">
                            <fa-icon [icon]="['fas','magnifying-glass']"></fa-icon>
                          </span>
                        </div>
                        <input #input autocomplete="no" type="text" (keyup)="searchCategory(input.value)"
                          placeholder=" Search Category" autocomplete="nofill" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <ul class="scroll-area-lg">
                    <li ngbDropdownItem (click)="populateSubCategoryList(item, ngbDropdownCategory)"
                      *ngFor="let item of (categories$ | async)" style="width: 100%; white-space: normal;">
                      {{item}}
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="fc.category.invalid && (fc.category.dirty || fc.category.touched)">
                <p class="e-fielderror e-fielderror-required text-red-500 " *ngIf="fc.category.errors?.required">Required
                </p>
              </div>
              <div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="mb-2">

              <label for="floatingInput" class="text-area-label">Sub Category 1
              </label>
              <div ngbDropdown class="dropdown " #ngbDropdownSubCategory="ngbDropdown" [autoClose]="true">
                <button class="btn select-box-btn-class text-start dropdown-toggle mb-2 rounded-0" type="button"
                  data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false" placeholder="Search Sub Category 1"
                  [ngClass]="{'is-invalid': fc.subCategory.invalid && (fc.subCategory.dirty || fc.subCategory.touched) && fc.subCategory.errors?.required}">
                  <div class="row">
                    <div class="col-9">
                      <div class="overflow-class-sort">
                        {{fc.subCategory.value}}
                      </div>
                    </div>
                    <div class="col-3 text-end">
                      <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-Xsmall">
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu text-start shadow  w-100 p-2 " ngbDropdownMenu>
                  <!-- SYMBOL SEARCH --/>
                  <div class="row text-center margin px-3">
                    <div class="col-md-12 fix-inputGroup">
                      <div class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control-sm text-primary">
                            <fa-icon [icon]="['fas','magnifying-glass']"></fa-icon>
                          </span>
                        </div>
                        <input #subcategory autocomplete="no" type="text" (keyup)="searchSubCategory(subcategory.value)"
                          placeholder=" Search Sub Category 1" autocomplete="nofill" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <ul class="scroll-area-lg">
                    <li ngbDropdownItem *ngFor="let item of (subCategories$ | async)"
                      style="width: 100%; white-space: normal;"
                      (click)="populateSubCategoryLevelOneList(fc.category.value, item!, ngbDropdownSubCategory)">
                      {{item}}
                    </li>
                  </ul>
                </div>
              </div>
              <div *ngIf="fc.subCategory.invalid && (fc.subCategory.dirty || fc.subCategory.touched)">
                <p class="e-fielderror e-fielderror-required text-red-500 " *ngIf="fc.subCategory.errors?.required">
                  Required</p>
              </div>
              <div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class=" mb-2">

              <label for="floatingInput" class="text-area-label">Sub Category 2
              </label>
              <div ngbDropdown class="dropdown " #ngbDropdownSubCategoryLevelOne="ngbDropdown" [autoClose]="true">
                <button class="btn select-box-btn-class text-start dropdown-toggle mb-2 rounded-0" type="button"
                  data-bs-toggle="dropdown" ngbDropdownToggle
                  [ngClass]="{'is-invalid': fc.subcategoryLevelOne.invalid && (fc.subcategoryLevelOne.dirty || fc.subcategoryLevelOne.touched) && fc.subcategoryLevelOne.errors?.required}"
                  aria-expanded="false" placeholder="Select category">
                  <div class="row">
                    <div class="col-9">
                      <div class="overflow-class-sort">
                        {{selectedSubCategoryLevelOneName}}
                      </div>
                    </div>
                    <div class="col-3 text-end">
                      <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-Xsmall">
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu text-start shadow  w-100 p-2 " ngbDropdownMenu>
                  <!-- SYMBOL SEARCH --/>
                  <div class="row text-center margin px-3">
                    <div class="col-md-12 fix-inputGroup">
                      <div class="input-group input-group-sm mb-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text form-control-sm text-primary">
                            <fa-icon [icon]="['fas','magnifying-glass']"></fa-icon>
                          </span>
                        </div>
                        <input #subcategoryLevelOne autocomplete="no" type="text"
                          (keyup)="searchSubCategoryLevelOne(subcategoryLevelOne.value)"
                          placeholder=" Search Sub Category 2" autocomplete="nofill" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <ul class="scroll-area-lg">
                    <li ngbDropdownItem
                      (click)="setSubcategoryLevelOne(item.id!, item?.subcategoryLevelOne!, ngbDropdownSubCategoryLevelOne)"
                      *ngFor="let item of (this.subcategoryLevelOneList$ | async)"
                      style="width: 100%; white-space: normal;">
                      {{item?.subcategoryLevelOne}}
                    </li>
                  </ul>
                </div>
              </div>

              <div>
              </div>
            </div>
          </div> -->


    <div class="mb-3">
      <app-message-toast *ngIf="_showSuccessToast$ | async"
        [message]="'Bidder added successfully.'"></app-message-toast>
      <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true"
        [message]="_showErrorMsg$ | async"></app-message-toast>
    </div>

    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner" *ngIf="isLoading"></div>
    </div>
    <div class="col-12 text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
      <button type="button"
        class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 me-3"
        (click)="closeModal()">
        Cancel
      </button>
      <button type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800btn-width-small btn-sm"
        *ngIf="!selectedBidder" [disabled]="!(_isSaveButtonEnable$ | async)" (click)="handleValidSubmit()">
        Add
      </button>
      <button type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800btn-width-small btn-sm"
        *ngIf="selectedBidder" [disabled]="!(_isSaveButtonEnable$ | async)" (click)="handleValidSubmit()">
        Update
      </button>

    </div>
  </form>
</div>