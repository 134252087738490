import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { ApplicationConstants } from '../../../../shared/util/ApplicationConstants';
import { UserAuctionEligibleStatus } from './../../../../shared/enums/UserAuctionEligibleStatus';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminAuctionBidderRegistrationDto } from 'src/app/shared/models/user/AdminAuctionBidderRegistrationDto ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { AdminBidderDetailsComponent } from 'src/app/shared/components/user/admin-bidder-details/admin-bidder-details.component';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { BidderQuestionnaireComponent } from 'src/app/layouts/bidder/bidder-questionnaire/bidder-questionnaire.component';

@Component({
  selector: 'app-auction-registration-list',
  templateUrl: './auction-registration-list.component.html',
  styleUrls: ['./auction-registration-list.component.sass']
})
export class AuctionRegistrationListComponent implements OnInit, OnDestroy {


  auctionBidderRegistrationDtos?: AdminAuctionBidderRegistrationDto[];
  selectedFilter: string = 'Recent';
  filteredBidderRegistrationList$ = new BehaviorSubject<Array<AdminAuctionBidderRegistrationDto>>([]);
  isLoading?: boolean = false;
  isExportLoading?: boolean = false;
  auctionBidderRegistrationSubscription$?: Subscription;

  page: number = 1;
  pageSize: number = 50;

  @ViewChild('search') searchField?: ElementRef<HTMLInputElement>;

  constructor(
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    public modalService: NgbModal,
    public adminService: AdminDashboardService
  ) {

  }

  ngOnInit(): void {
    this.auctionBidderRegistrationSubscription$ = this.adminService.getAuctionBidderRegistrationDtos$.subscribe(data => {
      this.isLoading = false;
      if (data && data.length > 0) {
        this.auctionBidderRegistrationDtos = data;
        this.filteredBidderRegistrationList$.next(this.auctionBidderRegistrationDtos);
        this.paginateBidderHistoryList();
      }
    })
  }

  refreshData() {
    this.isLoading = true;
    this.searchField!.nativeElement.value = '';
    this.adminService.loadBidderRegistrationList(this.adminSourcingEventsDataHolderService._selectedAuction$.value?.auctionId!);
  }

  openBidderDetailsModal(userId: string) {
    let modalRef = this.modalService.open(AdminBidderDetailsComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    })
    modalRef.componentInstance.userId = userId;
  }

  getStatusText(status: string) {
    let toReturn = '';
    if (status == 'INCOMPLETE') {
      toReturn = ' Incomplete';
    } else if (status == 'AWAITING_APPROVAL') {
      toReturn = ' Awaiting Approval';
    } else if (status == 'REJECTED_BIDDERS') {
      toReturn = ' Rejected';
    } else if (status == 'APPROVED_BIDDERS') {
      toReturn = ' Approved';
    }
    return toReturn?.toUpperCase();
  }

  getStatusClass(status: string) {
    let toReturn = '';
    if (status == 'INCOMPLETE') {
      toReturn = 'ee-null';
    } else if (status == 'AWAITING_APPROVAL') {
      toReturn = 'ee-undefined';
    } else if (status == 'REJECTED_BIDDERS') {
      toReturn = 'ee-no';
    } else if (status == 'APPROVED_BIDDERS') {
      toReturn = 'ee-yes';
    }
    return toReturn;
  }

  getStatusIcon(status: string) {
    let toReturn = '';
    if (status == 'INCOMPLETE') {
      toReturn = 'Incomplete';
    } else if (status == 'AWAITING_APPROVAL') {
      toReturn = '?';
    } else if (status == 'REJECTED_BIDDERS') {
      toReturn = 'No';
    } else if (status == 'APPROVED_BIDDERS') {
      toReturn = 'Yes';
    }
    return toReturn;
  }

  getApproveButtonText(status: string) {
    let toReturn = '';
    if (status == 'AWAITING_APPROVAL') {
      toReturn = 'APPROVE Bidder';
    } else if (status == 'REJECTED_BIDDERS') {
      toReturn = 'Change to APPROVED';
    } else if (status == 'INCOMPLETE') {
      toReturn = 'APPROVE Bidder';
    }
    return toReturn;
  }

  getRejectButtonText(status: string) {
    let toReturn = '';
    if (status == 'AWAITING_APPROVAL') {
      toReturn = 'REJECT Bidder';
    } else if (status == 'APPROVED_BIDDERS') {
      toReturn = 'Change to REJECTED';
    } else if (status == 'INCOMPLETE') {
      toReturn = 'REJECT Bidder';
    }
    return toReturn;
  }

  isShowApproveButton(status: string) {
    let toReturn = false;
    if (status == 'AWAITING_APPROVAL') {
      toReturn = true;
    } else if (status == 'REJECTED_BIDDERS') {
      toReturn = true;
    }
    return toReturn;
  }

  changePageSize(pageSize: number){
    this.pageSize = pageSize;
    this.paginateBidderHistoryList()
  }

  isShowRejectButton(status: string) {
    let toReturn = false;
    if (status == 'AWAITING_APPROVAL') {
      toReturn = true;
    } else if (status == 'APPROVED_BIDDERS') {
      toReturn = true;
    }
    return toReturn;
  }

  filterBidderRegistrationDto(filter: string) {
    let bidderRegistrationData = [...this.auctionBidderRegistrationDtos ?? []]
    if (filter != '') {
      let bidderRegistrationDto = bidderRegistrationData.filter(item => item.name?.toLowerCase().includes(filter.toLowerCase()))
      this.filteredBidderRegistrationList$.next(bidderRegistrationDto!);
    } else {
      this.filteredBidderRegistrationList$.next(bidderRegistrationData!);
    }

    this.sortBidderRegistrationDto(this.selectedFilter)
  }

  sortBidderRegistrationDto(sort: string) {
    this.selectedFilter = sort;
    let bidderRegistrationDtoList = [...this.filteredBidderRegistrationList$.value ?? []]
    if (sort == 'A to Z') {
      let auctionHouses = bidderRegistrationDtoList?.sort((a, b) => a.name!.toLowerCase() > b.name!.toLowerCase() ? 1 : -1);
      this.filteredBidderRegistrationList$.next(auctionHouses!);
    } else {
      let auctionHouses = bidderRegistrationDtoList?.sort((a, b) => Date.parse(a.registrationDate!) < Date.parse(b.registrationDate!) ? 1 : -1);
      this.filteredBidderRegistrationList$.next(auctionHouses);
    }
  }

  downloadBiderOfferData() {
    this.isExportLoading = true;
    this.adminService.downloadRegistrationData(this.auctionBidderRegistrationDtos![0].auctionId!).subscribe(data => {
      this.isExportLoading = false;
      if (data) {
        let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
  }



  approveBidder(item: AdminAuctionBidderRegistrationDto) {
    this.isLoading = true;
    this.adminService.changeRegistrationStatus(item.auctionId!, item.userId!, 'APPROVED_BIDDERS').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        //console.log(apiResponseDto);
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false;
          this.updateUserStatus(item.userId!, UserAuctionEligibleStatus.APPROVED_BIDDERS);
        } else {
          this.isLoading = false;
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        console.error(error);
      }
    })
  }

  updateUserStatus(userId: string, status: UserAuctionEligibleStatus) {
    let user = this.auctionBidderRegistrationDtos!.find(m => m.userId == userId);
    if (user) {
      let index = this.auctionBidderRegistrationDtos!.indexOf(user);
      user.auctionRegistrationStatus = status;
      this.auctionBidderRegistrationDtos![index] = user;
      this.filteredBidderRegistrationList$.next(this.auctionBidderRegistrationDtos!);
      this.adminService.updateRegistrationUserList(this.auctionBidderRegistrationDtos!);
    }
  }

   // Filter
   paginateBidderHistoryList() {
    if (this.auctionBidderRegistrationDtos && this.auctionBidderRegistrationDtos.length > 0) {
      let data  = ApplicationUtils.clone(this.auctionBidderRegistrationDtos);
      this.filteredBidderRegistrationList$.next(data.map((history: any, i: number) => ({ id: i + 1, ...history })).slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      ));
    }
  }

  rejectBidder(item: AdminAuctionBidderRegistrationDto) {
    this.isLoading = true;
    this.adminService.changeRegistrationStatus(item.auctionId!, item.userId!, 'REJECTED_BIDDERS').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        //console.log(apiResponseDto);
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false;
          this.updateUserStatus(item.userId!, UserAuctionEligibleStatus.REJECTED_BIDDERS);
        } else {
          this.isLoading = false;
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        console.error(error);
      }
    })
  }

  async openBidderQuestionnaire(userId: string) {
    let modalRef = this.modalService.open(BidderQuestionnaireComponent, {
      size: 'lg', keyboard: false,
      backdrop: 'static', centered: true
    })
    modalRef.componentInstance.userId = userId;
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  ngOnDestroy(): void {
    if (this.auctionBidderRegistrationSubscription$) {
      this.auctionBidderRegistrationSubscription$.unsubscribe();
    }
  }


}
