import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TenderFinancialQuestionTemplateDto } from 'src/app/shared/models/tender/TenderFinancialQuestionTemplateDto';
import { TenderQuestionnaireWrapperDto } from 'src/app/shared/models/tender/TenderQuestionnaireWrapperDto';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { selectTenderOpportunityWrapperDto, selectTenderWrapperUiDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-new-tender-financial-question',
  templateUrl: './new-tender-financial-question.component.html',
  styleUrls: ['./new-tender-financial-question.component.sass']
})
export class NewTenderFinancialQuestionComponent implements OnInit, OnDestroy {
  tenderWrapperUiDto?: TenderWrapperUiDto;
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;
  tenderQuestionnaireWrapperDto?: TenderQuestionnaireWrapperDto;

  tenderFinancialQuestionTemplates: TenderFinancialQuestionTemplateDto[] = []
  countAdditionalPrices: number = 0
  
  tenderWrapperUiDtoSubscription$?: Subscription;
  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  createNewMenuItems: MenuItem[] = [
    { title: 'Edit', escape: false, command: () => this.openAddFinancialDrawer() },
  ]

  constructor(
    private store: Store,
    private drawerService: DrawerService,
  ) { }

  ngOnInit(): void {
    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
        this.tenderQuestionnaireWrapperDto = this.tenderOpportunityWrapperDto?.tenderQuestionnaireWrapperDto;

        if (this.tenderQuestionnaireWrapperDto) {
          // this.tenderFinancialQuestionTemplates = [...(this.tenderQuestionnaireWrapperDto.tenderFinancialQuestionTemplateDtos || [])];
          this.tenderFinancialQuestionTemplates = this.tenderQuestionnaireWrapperDto.tenderFinancialQuestionTemplateDtos || [];
          this.tenderFinancialQuestionTemplates.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

          if (this.tenderFinancialQuestionTemplates.length > 0) {
            this.countAdditionalPrices = (this.tenderFinancialQuestionTemplates[0].additionalPrices || []).length;
          }
        }
      } else {
        this.tenderOpportunityWrapperDto = undefined;
        this.tenderQuestionnaireWrapperDto = undefined;
        this.tenderFinancialQuestionTemplates = [];
      }
    })
  }

  openAddFinancialDrawer() {
    this.drawerService.openDrawer(ApplicationConstants.NEW_TENDER_FINANCIAL_QUES);
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }

    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}
