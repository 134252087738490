import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { selectTenderOpportunityWrapperDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-opportunity-critical-dates',
  templateUrl: './opportunity-critical-dates.component.html',
  styleUrls: ['./opportunity-critical-dates.component.sass']
})
export class OpportunityCriticalDatesComponent implements OnInit, OnDestroy {
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto

  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  constructor(
    private store: Store,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
      } else {
        this.tenderOpportunityWrapperDto = undefined;
      }
    })
  }

  openEditOpportunityDrawer() {
    this.drawerService.isFormEditMode(true);
    this.drawerService.openDrawer(ApplicationConstants.NEW_OPPORTUNITY_PAGE, 'CRITICAL_DATES');
  }

  ngOnDestroy(): void {
    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}
