<div class="container" *ngIf="!isDataLoading && !purchaseOrderGenerated">
  <div class="row mt-3">
    <span class="me-1">
      Event Name: {{selectedSubcategorySubmissionDto?.subcategoryName}}
    </span>
  </div>
  <div class="row justify-content-between align-items-center mb-3">
    <div class="col-md-8">
      <div>Contract Type: {{selectedSubcategorySubmissionDto?.contractAlgo}} {{getFormattedContractType(selectedSubcategorySubmissionDto?.contractType!)}}</div>
      <div *ngIf="!isTechnicalEvaluationPending">Normalised Price is {{getFormattedPrice(normalizePrice)}}
        <!-- {{noOfItemsRequiredNormalization}} Items have
        different {{selectedSubcategorySubmissionDto?.contractAlgo == 'HCS' ? 'H1' : 'L1'}} Bidders. -->
        <a class="link-class" (click)="openConfirmRequestModal(confirmationRequest, 'REQUESTED')"
          *ngIf="selectedCompFinancialWrapperDto && isShowRequestLink">
          Request Normalisation to {{selectedSubcategorySubmissionDto?.contractAlgo == 'HCS' ? 'H' : 'L'}}{{selectedCompFinancialWrapperDto.compSequence}} Bidder?
        </a>
        <a class="link-class me-2" (click)="openConfirmRequestModal(confirmationRequest, 'ACCEPTED')"
          *ngIf="selectedCompFinancialWrapperDto && !isShowRequestLink">
          Accept on behalf of {{selectedSubcategorySubmissionDto?.contractAlgo == 'HCS' ? 'H' : 'L'}}{{selectedCompFinancialWrapperDto.compSequence}} Bidder?
        </a>
        <a class="link-class" (click)="openConfirmRequestModal(confirmationRequest, 'REVOKED')"
          *ngIf="selectedCompFinancialWrapperDto && !isShowRequestLink">
          Revoke for {{selectedSubcategorySubmissionDto?.contractAlgo == 'HCS' ? 'H' : 'L'}}{{selectedCompFinancialWrapperDto.compSequence}} Bidder?
        </a>
      </div>
    </div>
    <div class="col-md-4 text-end">
      <!-- <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0 me-2"
        (click)="changeDetailsPage('USER_SUBCATEGORY_SUBMISSION')">Back</button> -->
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0">Award Contract</button>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table medium-font mb-0 table-bordered">
      <thead class="table-dark">
        <tr class="align-middle">
          <th scope="col" class="text-center" *ngIf="selectedSubcategorySubmissionDto?.normalizationWorkflow == 'OVERLAP'">

          </th>
          <th scope="col" class="text-center">
            Bidder
          </th>
          <th scope="col" class="text-center">
            Name
          </th>
          <th scope="col" class="text-center">
            Total Bid Amount
          </th>
          <th scope="col" class="text-center">
            QCBS Score
          </th>
          <th scope="col" class="text-center">
            Technical
          </th>
          <th scope="col" class="text-center">
            Normalise
          </th>
          <th scope="col" class="text-center">
            Action
          </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr *ngIf="isTechnicalEvaluationPending && !isDataLoading">
          <td colSpan="8">
            <div class="d-grid gap-2 my-3">
              <p class="text-center fs-6">Normalize will be available after the technical evaluation is complete.</p>
            </div>
          </td>
        </tr>

        <tr class="align-middle" *ngFor="let item of (comNormaliseWrapperDtos$|async); index as i">
          <td class="text-center" *ngIf="selectedSubcategorySubmissionDto?.normalizationWorkflow == 'OVERLAP'">
            <input #radioEvent type="radio" name="radioEvent" id="radioEvent" (click)="selectComFinancialWrapper(item, radioEvent)">
          </td>
          <td class="text-center">{{selectedSubcategorySubmissionDto?.contractAlgo == 'HCS' ? 'H' : 'L'}}{{item.compSequence}}</td>
          <td class="text-center">{{item.companyName}}</td>
          <td class="text-center">{{getFormattedPrice(item.totalBidAmount!)}}</td>
          <td class="text-center">{{item.qcbsScore!}}</td>
          <td class="text-center">{{item.gradedTechnicalScore ? item.gradedTechnicalScore : item.technicalScore}}</td>
          <td class="text-center">{{item.normalizeStatus!}}</td>
          <td class="text-center">
            <a class="link-class" (click)="openEvaluationNormaliseModal(item)">View</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="d-grid gap-2 mb-3" *ngIf="isDataLoading">
  <div class="e-spinner"></div>
</div>

<div class="container text-center p-5" *ngIf="!isDataLoading && purchaseOrderGenerated">
  <h6 class="heading-new">Purchase Order already generated. </h6>
</div>

<ng-template #confirmationRequest>
  <div class="my-2">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>CONFIRM</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()"
            [ngClass]="{'disabled': isLoading}">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <span>
        Do you want to {{requestStatus == 'ACCEPTED' ? 'Accept' : requestStatus == 'REVOKED' ? 'Revoke' : 'Request'}}
        Normalisation to {{selectedSubcategorySubmissionDto?.contractAlgo == 'HCS' ? 'H' : 'L'}}{{selectedCompFinancialWrapperDto?.compSequence}} Bidder?
      </span>
    </div>

    <div class="m-3">
      <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true" [message]="errorMsg"></app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" message="Requested successfully.">
      </app-message-toast>
    </div>

    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner"></div>
    </div>

    <div class="m-3">
      <div class="text-end my-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-3 me-3" (click)="closeModal()">Cancel</button>
        <button type="button" class="btn btn-sm btn-outline-primary px-3"
          (click)="requestNormalizePrice()">Submit</button>
      </div>
    </div>
  </div>
</ng-template>
