import { SourcingEnvelopeType } from "../../enums/questionnaire/SourcingEnvelopeType";

export class SectionTemplate {
  sectionId?: string;
  questionnaireId?: string;
  sectionName?: string;
  templateDescription?: string;
  orgCode?: string;
  auctionId?: string;
  rfxId?: string;
  rfxSubcategoryId?: string;
  preSequenceText?: string;
  sequenceNo?: string;
  sectionScore?: number;
  envelopeType?: SourcingEnvelopeType;
  questionIds?: string[];
  updateTimeUtc?: string;
  updateTimeUtcMillis?: number;
}
