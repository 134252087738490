import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AuctionTargetedBiddersNotificationEntityDto } from '../../../shared/models/AuctionPremiumSuppliersEntityDto';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { Pattern } from 'src/app/shared/util/Patterns';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';

@Component({
  selector: 'app-auction-premium-supplier',
  templateUrl: './auction-premium-supplier.component.html',
  styleUrls: ['./auction-premium-supplier.component.sass']
})
export class AuctionPremiumSupplierComponent implements OnInit {

  auctionPremiumSuppliersDto?: AuctionTargetedBiddersNotificationEntityDto
  isLoading: boolean = false;
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");


  selectedIndex: number | undefined = undefined;
  auctionLotEntityDto?: AuctionLotEntityDto
  auction?: AuctionEntityDto;

  selectedLotSubscription$?: Subscription;

  isEdit: boolean = false;

  emailIdsFormGroup: FormControl = new FormControl('', [Validators.required])

  constructor(private ngbModal: NgbModal, private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService, private auctionLotService: AdminLotsDataHolderService) {

  }

  ngOnInit(): void {
    this.auction = this.adminSourcingEventsDataHolderService._selectedAuction$.value!;

    this.selectedLotSubscription$ = this.auctionLotService._selectedAuctionLot$.subscribe(lot => {
      this.auctionLotEntityDto = lot!;
      this.loadAuctionPremiumSuppliers();
    })
  }

  openPremiumSupplierModal(content: any, type: boolean) {
    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    this.isEdit = type;
    if(this.isEdit && this.auctionPremiumSuppliersDto && this.auctionPremiumSuppliersDto.emailList?.length! > 0){
      this.emailIdsFormGroup.setValue(this.auctionPremiumSuppliersDto.emailList);
    }else{
      this.emailIdsFormGroup.reset();
    }
    this.emailIdsFormGroup.updateValueAndValidity();

    this.ngbModal.open(content, { size: 'md', backdrop: 'static', keyboard: false, centered: true });
  }


  loadAuctionPremiumSuppliers() {
    this.isLoading = true
    this.adminSourcingEventsDataHolderService.getAuctionPremiumSuppliers(this.auctionLotEntityDto?.auctionHouseId!, this.auctionLotEntityDto?.auctionId!, this.auctionLotEntityDto?.lotId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.auctionPremiumSuppliersDto = apiResponseDto.data as AuctionTargetedBiddersNotificationEntityDto;

        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      }
    })
  }

  deleteEmailId(emailId: string){
    if (!this.isAllowToEdit()) {
      return;
    }

    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    let tempAuctionPremiumSuppliersDto = ApplicationUtils.clone(this.auctionPremiumSuppliersDto) as AuctionTargetedBiddersNotificationEntityDto;

    if(this.auctionPremiumSuppliersDto && this.auctionPremiumSuppliersDto?.emailList && this.auctionPremiumSuppliersDto?.emailList.length > 0){
      let index= tempAuctionPremiumSuppliersDto?.emailList!.findIndex(item => item.trim().toLowerCase() == emailId.trim().toLowerCase());
      this.selectedIndex = index;
      tempAuctionPremiumSuppliersDto?.emailList?.splice(index, 1);
    }

    this.isLoading = true;

    this.adminSourcingEventsDataHolderService.saveAuctionPremiumSuppliers(tempAuctionPremiumSuppliersDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {

        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false;
          this.loadAuctionPremiumSuppliers();
          this._showSuccessToast$.next(true);
          this.selectedIndex = undefined;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this.isLoading = false;
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.selectedIndex = undefined;
        }
      },
      error: (error) => {

        this.isLoading = false;
        console.error(error);
        this._showErrorMsg$.next("Error while deleting email");
        this._showErrorToast$.next(true);
        this.selectedIndex = undefined;
      }
    })
  }


  saveAuctionPremiumSuppliers() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);


    if(this.emailIdsFormGroup.invalid || !this.emailIdsFormGroup.dirty){
      this._showErrorToast$.next(true);
      this._showErrorMsg$.next("Required")
      return;
    }

    let emailIdInString = this.removeSpaces(this.emailIdsFormGroup.value);
    let emailList = emailIdInString.split(',') as string[];
    let finalEmailList: string[]= [];


    if (this.isEdit ) {
      finalEmailList = this.removeDuplicateEmails(emailList);
    } else {
      if (this.auctionPremiumSuppliersDto?.emailList && this.auctionPremiumSuppliersDto?.emailList.length > 0) {
        let list = [...emailList, ...this.auctionPremiumSuppliersDto?.emailList];
        finalEmailList = this.removeDuplicateEmails(list);

      } else {
        finalEmailList = this.removeDuplicateEmails(emailList);
      }
    }

    let tempAuctionPremiumSuppliersDto = ApplicationUtils.clone(this.auctionPremiumSuppliersDto) as AuctionTargetedBiddersNotificationEntityDto;

    if (!tempAuctionPremiumSuppliersDto) {
      tempAuctionPremiumSuppliersDto = new AuctionTargetedBiddersNotificationEntityDto();
    }
    tempAuctionPremiumSuppliersDto!.auctionHouseId = this.auctionLotEntityDto?.auctionHouseId
    tempAuctionPremiumSuppliersDto!.auctionId = this.auctionLotEntityDto?.auctionId
    tempAuctionPremiumSuppliersDto!.lotId = this.auctionLotEntityDto?.lotId
    tempAuctionPremiumSuppliersDto!.emailList = finalEmailList;

    this.isLoading = true;

    this.adminSourcingEventsDataHolderService.saveAuctionPremiumSuppliers(tempAuctionPremiumSuppliersDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false;
          this.loadAuctionPremiumSuppliers();
          this._showSuccessToast$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this.isLoading = false;
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
        this._showErrorMsg$.next("Error Saving EmailIds");
        this._showErrorToast$.next(true);
      }
    })
  }

   removeSpaces(emailString: string): string {
    return emailString.replace(/\s/g, '');
  }



  removeDuplicateEmails(emailList: string[]) {
    // Use a Set to automatically remove duplicates
    const uniqueValidEmails: Set<string> = new Set();

    emailList.forEach((email) => {
      // Check if the email is valid
      let isValidEmailId = this.isValidEmail(email)
      if (isValidEmailId) {
        // Add the valid email to the set
        uniqueValidEmails.add(email);
      }
    });

      // Convert the set back to an array
   const result: string[] = [...uniqueValidEmails];

    return result;
  }

   isValidEmail(email: string): boolean {
    // Regular expression to check if the email is valid
    const emailRegex = new RegExp(Pattern.email);
    return emailRegex.test(email);
  }



  closeModal() {
    this.ngbModal.dismissAll();
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.auction?.status == AuctionStatus.LIVE_WAIT && !this.auction.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auction?.status == AuctionStatus.LIVE && !this.auction.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}
