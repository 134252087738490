import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationStartupDto } from 'src/app/shared/models/ApplicationStartupDto';
import { CacheDesignationsDto } from 'src/app/shared/models/CacheDesignationsDto';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { OnboardingWorkflow } from 'src/app/shared/models/OnboardingWorkflow';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AWFAppliedUiDto } from 'src/app/shared/models/approval-workflow/AWFAppliedUiDto';
import { AWFChainDefineUiDto } from 'src/app/shared/models/approval-workflow/AWFChainDefineUiDto';
import { AWFDefineUiDto } from 'src/app/shared/models/approval-workflow/AWFDefineUiDto';
import { ApprovalWorkflowUiDto } from 'src/app/shared/models/approval-workflow/ApprovalWorkflowUiDto';
import { ApplicationStartupCacheService } from 'src/app/shared/services/application-startup-cache.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-approval-workflow',
  templateUrl: './approval-workflow.component.html',
  styleUrls: ['./approval-workflow.component.sass'],
})
export class ApprovalWorkflowComponent implements OnInit, OnDestroy {
  roleWorkflowSteps: MenuItem[] = [];
  currentStep: number = 0;
  stepOneMode: string = 'RULE_NAME'; // SELECT_WORKFLOW, SELECT_DESIGNATION

  isLoading: boolean = false;
  isCompanyLoading: boolean = false;
  isPlantLoading: boolean = false;
  isShowPlantsList: boolean = false;
  isShowDesignationTwo: boolean = false;
  isShowDesignationThree: boolean = false;
  responseMessage: string = '';

  organizationUiDto?: OrganizationWrapperUiDto;
  applicationStartupDto?: ApplicationStartupDto;
  onboardingWorkflows: OnboardingWorkflow[] = [];
  companiesList: CompanyUiDto[] = [];
  allPlantsList: PlantUiDto[] = [];
  firstDesignationList: CacheDesignationsDto[] = [];
  secondDesignationList: CacheDesignationsDto[] = [];
  thirdDesignationList: CacheDesignationsDto[] = [];
  approvalWorkflowUiDto?: ApprovalWorkflowUiDto;

  firstAppliedUiDtos: AWFAppliedUiDto[] = [];
  secondAppliedUiDtos: AWFAppliedUiDto[] = [];
  thirdAppliedUiDtos: AWFAppliedUiDto[] = [];

  selectedApprovalWorkflow?: OnboardingWorkflow;
  selectedApprovalCompany?: CompanyUiDto;
  selectedApprovalPlant?: PlantUiDto;
  selectedApprovalType: string = 'SEQUENCE';
  selectedDesignationOne?: CacheDesignationsDto;
  selectedDesignationTwo?: CacheDesignationsDto;
  selectedDesignationThree?: CacheDesignationsDto;

  ctrlRuleName: FormControl = new FormControl('', Validators.required);
  ctrlDesignationOne: FormControl = new FormControl('', Validators.required);
  ctrlDesignationTwo: FormControl = new FormControl('', Validators.required);
  ctrlDesignationThree: FormControl = new FormControl('', Validators.required);

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  selectedCompanySubscription$?: Subscription;
  companiesSubscription?: Subscription;
  plantsListSubscription$?: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private changeDetectRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private appStartupService: ApplicationStartupCacheService
  ) { }

  onChangeCurrentStep(currentStep: number) {
    this.currentStep = currentStep;
  }

  ngOnInit() {
    this.organizationUiDto = this.onboardingService.getOrganizationUiDto;
    this.applicationStartupDto =
      this.appStartupService.getApplicationStartupDto;
    this.onboardingWorkflows = this.onboardingService.getOnboardingWorkflows;
    this.firstDesignationList =
      this.applicationStartupDto?.cacheDesignationsDtos ?? [];
    this.firstDesignationList = this.firstDesignationList.sort(
      (a, b) => Number(a.rank) - Number(b.rank)
    );

    this.roleWorkflowSteps = [{ label: 'Define' }, { label: 'Review' }];

    this.companiesSubscription =
      this.onboardingService.getCompanyUiDtos$.subscribe((data) => {
        if (data) {
          this.companiesList = data;
        } else {
          this.companiesList = [];
        }
      });

    this.plantsListSubscription$ =
      this.onboardingService.getPlantUiDtos$.subscribe((data) => {
        if (data) {
          this.allPlantsList = data;
        } else {
          this.allPlantsList = [];
        }
      });
  }

  setStepOneMode(step: string) {
    this.stepOneMode = step;
  }

  nextStep() {
    if (this.currentStep == 0) {
      if (this.stepOneMode == 'RULE_NAME') {
        if (this.ctrlRuleName.valid) {
          this.setStepOneMode('SELECT_WORKFLOW');
        }
      } else if (this.stepOneMode == 'SELECT_WORKFLOW') {
        if (this.selectedApprovalWorkflow && this.selectedApprovalCompany && this.selectedApprovalType) {
          if (this.isShowPlantsList && !this.selectedApprovalPlant) {
            return;
          }

          this.setStepOneMode('SELECT_DESIGNATION');
        }
      } else {
        if (this.isShowDesignationTwo && !this.selectedDesignationTwo) {
          return;
        }
        if (this.isShowDesignationThree && !this.selectedDesignationThree) {
          return;
        }
        this.getWorkflowUsersByDefineDto();
      }
    } else {
      if (this.currentStep < this.roleWorkflowSteps.length) {
        this.onChangeCurrentStep(this.currentStep + 1);
      }

      this.setStepOneMode('SELECT_WORKFLOW');
    }
  }

  togglePlants() {
    this.isShowPlantsList = !this.isShowPlantsList;
    if (!this.isShowPlantsList) {
      this.selectedApprovalPlant = undefined;
    }
  }

  onChangeApprovalWorkflow(workflow: OnboardingWorkflow) {
    this.selectedApprovalWorkflow = workflow;
    this.fetchAllCompanies();
  }

  onChangeApprovalCompany(company: CompanyUiDto) {
    this.selectedApprovalCompany = company;
    this.fetchAllPlantsOfCompany();
  }

  onChangeApprovalPlant(plantUiDto: PlantUiDto) {
    this.selectedApprovalPlant = plantUiDto;
  }

  refreshDesignationWorkflow(designationType: number) {
    if (designationType == 1) {
      this.isShowDesignationTwo = false;
      this.isShowDesignationThree = false;
      this.selectedDesignationTwo = undefined;
      this.selectedDesignationThree = undefined;
    }
    if (designationType == 2) {
      this.isShowDesignationTwo = true;
      this.isShowDesignationThree = false;
      this.selectedDesignationThree = undefined;
    }
    if (designationType == 3) {
      this.isShowDesignationThree = true;
    }
  }

  onChangeDesignation(
    designation: CacheDesignationsDto,
    designationType: number
  ) {
    if (designationType == 1) {
      this.selectedDesignationOne = designation;
      this.selectedDesignationTwo = undefined;
      this.selectedDesignationThree = undefined;

      this.secondDesignationList = this.firstDesignationList.filter(
        (item) => Number(item.rank) < Number(designation.rank)
      );
      this.thirdDesignationList = [];
    }
    if (designationType == 2) {
      this.selectedDesignationTwo =
        this.selectedDesignationTwo == undefined ? designation : undefined;
      this.selectedDesignationThree = undefined;

      this.thirdDesignationList = this.firstDesignationList.filter(
        (item) => Number(item.rank) < Number(designation.rank)
      );
    }
    if (designationType == 3) {
      this.selectedDesignationThree =
        this.selectedDesignationThree == undefined ? designation : undefined;
    }
  }

  async fetchAllCompanies() {
    this.isCompanyLoading = true;
    await this.onboardingService.loadCompanyUiDtosByOrgCode();
    this.isCompanyLoading = false;
  }

  async fetchAllPlantsOfCompany() {
    this.isPlantLoading = true;
    await this.onboardingService.loadPlantUiDto(
      this.selectedApprovalCompany?.orgCode!,
      this.selectedApprovalCompany?.companyCode!
    );
    this.isPlantLoading = false;
  }

  openDesignationModal(content: any) {
    this.ngbModal.open(content, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  openWorkFlowModal(content: any) {
    this.ngbModal.open(content, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  openAddDocumentModal(content: any) {
    this.ngbModal.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  mergeAWFDefineUiDto() {
    let awfDefineUiDto = new AWFDefineUiDto();
    awfDefineUiDto.orgCode = this.organizationUiDto?.orgCode;
    awfDefineUiDto.name = this.ctrlRuleName.value;
    awfDefineUiDto.type = this.selectedApprovalType;
    // awfDefineUiDto.wfCode = this.selectedApprovalWorkflow?.code;
    awfDefineUiDto.companyCode = this.selectedApprovalCompany?.companyCode;
    awfDefineUiDto.plantCode =
      this.selectedApprovalPlant != undefined
        ? this.selectedApprovalPlant?.plantCode
        : 'ALL';
    awfDefineUiDto.ruleActive = true;

    // if (!awfDefineUiDto.chainDefineUiDtos) {
    //   awfDefineUiDto.chainDefineUiDtos = [];
    // }

    let chainDefineUiDto1 = new AWFChainDefineUiDto();
    chainDefineUiDto1.orgCode = this.organizationUiDto?.orgCode;
    chainDefineUiDto1.sequence = 1;
    chainDefineUiDto1.name = awfDefineUiDto.name;
    chainDefineUiDto1.roleCode1 = this.selectedDesignationOne?.code;
    chainDefineUiDto1.roleCode1Mandatory = true;

    // awfDefineUiDto.chainDefineUiDtos.push(chainDefineUiDto1);

    if (this.selectedDesignationTwo) {
      let chainDefineUiDto2 = new AWFChainDefineUiDto();
      chainDefineUiDto2.orgCode = this.organizationUiDto?.orgCode;
      chainDefineUiDto2.sequence = 2;
      chainDefineUiDto2.name = awfDefineUiDto.name;
      chainDefineUiDto2.roleCode1 = this.selectedDesignationTwo?.code;
      chainDefineUiDto2.roleCode1Mandatory = true;

      // awfDefineUiDto.chainDefineUiDtos.push(chainDefineUiDto2);
    }

    if (this.selectedDesignationThree) {
      let chainDefineUiDto3 = new AWFChainDefineUiDto();
      chainDefineUiDto3.orgCode = this.organizationUiDto?.orgCode;
      chainDefineUiDto3.sequence = 3;
      chainDefineUiDto3.name = awfDefineUiDto.name;
      chainDefineUiDto3.roleCode1 = this.selectedDesignationThree?.code;
      chainDefineUiDto3.roleCode1Mandatory = true;

      // awfDefineUiDto.chainDefineUiDtos.push(chainDefineUiDto3);
    }

    return awfDefineUiDto;
  }

  getWorkflowUsersByDefineDto() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this.responseMessage = '';
    this.isLoading = true;

    let awfDefineUiDto = this.mergeAWFDefineUiDto();

    this.onboardingService.getApprovalDefinedUsers(awfDefineUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as ApprovalWorkflowUiDto;
          this.approvalWorkflowUiDto = data;

          this.firstAppliedUiDtos =
            this.approvalWorkflowUiDto.appliedUiDtos?.filter(
              (item) => item.sequence == 1
            ) ?? [];
          this.secondAppliedUiDtos =
            this.approvalWorkflowUiDto.appliedUiDtos?.filter(
              (item) => item.sequence == 2
            ) ?? [];
          this.thirdAppliedUiDtos =
            this.approvalWorkflowUiDto.appliedUiDtos?.filter(
              (item) => item.sequence == 3
            ) ?? [];

          this.isLoading = false;

          this.onChangeCurrentStep(1);
        } else {
          this._showErrorToast$.next(true);
          this.responseMessage =
            apiResponseDto.message ??
            'Error while updating Approval Workflow. Try again.';
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this.responseMessage =
          'Error while updating Approval Workflow. Try again.';
        this.isLoading = false;
      },
    });
  }

  mergeApprovalWorkflowUiDto() {
    let approvalWorkflowUiDto = ApplicationUtils.clone(
      this.approvalWorkflowUiDto
    ) as ApprovalWorkflowUiDto;
    approvalWorkflowUiDto.appliedUiDtos = [];

    let appliedUiDto1 = this.approvalWorkflowUiDto?.appliedUiDtos?.find(
      (item) =>
        item.userId == this.ctrlDesignationOne.value && item.sequence == 1
    )!;
    approvalWorkflowUiDto.appliedUiDtos.push(appliedUiDto1);

    if (this.selectedDesignationTwo) {
      let appliedUiDto2 = this.approvalWorkflowUiDto?.appliedUiDtos?.find(
        (item) =>
          item.userId == this.ctrlDesignationTwo.value && item.sequence == 2
      )!;
      approvalWorkflowUiDto.appliedUiDtos.push(appliedUiDto2);
    }

    if (this.selectedDesignationThree) {
      let appliedUiDto3 = this.approvalWorkflowUiDto?.appliedUiDtos?.find(
        (item) =>
          item.userId == this.ctrlDesignationThree.value && item.sequence == 3
      )!;
      approvalWorkflowUiDto.appliedUiDtos.push(appliedUiDto3);
    }

    return approvalWorkflowUiDto;
  }

  saveWorkflow() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this.responseMessage = '';
    this.isLoading = true;

    if (
      this.ctrlDesignationOne.invalid ||
      (this.selectedDesignationTwo && this.ctrlDesignationTwo.invalid) ||
      (this.selectedDesignationThree && this.ctrlDesignationThree.invalid)
    ) {
      return;
    }

    let approvalWorkflowUiDto = this.mergeApprovalWorkflowUiDto();

    this.onboardingService
      .saveAppliedApprovalWorkflow(approvalWorkflowUiDto)
      .subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
            let data = apiResponseDto.data as ApprovalWorkflowUiDto;
            this._showSuccessToast$.next(true);
            this.responseMessage = "Workflow saved successfully";
            this.isLoading = false;

            this.onboardingService.loadOnboardingApprovalWorkflowsList(this.organizationUiDto?.orgCode!);

            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)
          } else {
            this._showErrorToast$.next(true);
            this.responseMessage =
              apiResponseDto.message ??
              'Error while updating Approval Workflow. Try again.';
            this.isLoading = false;
          }
        },
        error: (error) => {
          console.error(error);
          this._showErrorToast$.next(true);
          this.responseMessage =
            'Error while updating Approval Workflow. Try again.';
          this.isLoading = false;
        },
      });
  }

  ngOnDestroy(): void {
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
    if (this.companiesSubscription) {
      this.companiesSubscription.unsubscribe();
    }
    if (this.plantsListSubscription$) {
      this.plantsListSubscription$.unsubscribe();
    }
  }
}
