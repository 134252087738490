import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GooglePlacesService {

  private autocompleteService: google.maps.places.AutocompleteService;
  private placesService: google.maps.places.PlacesService;

  constructor() {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
  }

  getPlacePredictions(input: string): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable(observer => {
      this.autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          observer.next(predictions!);
          observer.complete();
        } else {
          observer.error(status);
        }
      });
    });
  }

  getPlaceDetails(placeId: string): Observable<google.maps.places.PlaceResult> {
    return new Observable(observer => {
      this.placesService.getDetails({ placeId }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          observer.next(place!);
          observer.complete();
        } else {
          observer.error(status);
        }
      });
    });
  }
}
