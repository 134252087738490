import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError, BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ErrorService } from './error.service';
import { QuestionnaireTemplate } from '../models/questionnaire/QuestionnaireTemplate';
import { SectionTemplate } from '../models/questionnaire/SectionTemplate';
import { QuestionnaireWrapperDto } from '../models/questionnaire/QuestionnaireWrapperDto';
import { ApplicationConstants } from '../util/ApplicationConstants';
import { SourcingEnvelopeType } from '../enums/questionnaire/SourcingEnvelopeType';
import { QuestionnaireValidationDto } from '../models/questionnaire/QuestionnaireValidationDto';
import { TechnicalQuestionTemplate } from '../models/questionnaire/TechnicalQuestionTemplate';
import { ResourceSwapWrapperDto } from '../models/questionnaire/ResourceSwapWrapperDto';
import { FinancialQuestionTemplate } from '../models/questionnaire/FinancialQuestionTemplate';
import { UserAuctionQuestionsDto } from '../models/questionnaire/UserAuctionQuestionsDto';
import { EventEnum } from '../enums/EventEnum';
import { EvaluationUserRfxQuestionsEntityDto } from '../models/rfx/EvaluationRfxQuestionsEntityDto';
import { ContractAlgoEnum } from '../enums/rfx/ContractAlgoEnum';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  private _questionnaireWrapperDtoList: QuestionnaireWrapperDto[] = [];
  public _questionnaireWrapperDtoList$ = new BehaviorSubject<QuestionnaireWrapperDto[]>([]);

  private currentRfxSubcategoryId?: string;
  private _questionnaireWrapperDto?: QuestionnaireWrapperDto;
  private _questionnaireWrapperDto$ = new BehaviorSubject<QuestionnaireWrapperDto | undefined>(undefined);
  private _questionnaireValidationList$ = new BehaviorSubject<QuestionnaireValidationDto[]>([]);

  private _selectedSection$ = new BehaviorSubject<SectionTemplate | undefined>(undefined);
  private _selectedTechQuestion$ = new BehaviorSubject<TechnicalQuestionTemplate | undefined>(undefined);
  private _selectedFinancialQuestion$ = new BehaviorSubject<FinancialQuestionTemplate | undefined>(undefined);

  get getQuestionnaireWrapper() { return this._questionnaireWrapperDto; }
  get getQuestionnaireWrapper$() { return this._questionnaireWrapperDto$.asObservable(); }
  get getQuestionnaireValidationList$() { return this._questionnaireValidationList$.asObservable(); }

  get getSelectedSection$() { return this._selectedSection$.asObservable(); }
  get getSelectedTechQuestion$() { return this._selectedTechQuestion$.asObservable(); }
  get getSelectedFinancialQuestion$() { return this._selectedFinancialQuestion$.asObservable(); }

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) { }

  getRemainingScoreOfSection(sectionId: string) {
    let sectionTemplate = (this._questionnaireWrapperDto?.sectionTemplateList ?? []).find(item => item.sectionId == sectionId);
    let totalQuestionScore = this.getTotalScoreOfQuestions(sectionId);
    return Number(sectionTemplate?.sectionScore) - Number(totalQuestionScore);
  }

  getTotalScoreOfSections(envelopeType: SourcingEnvelopeType): number {
    let sectionTemplateList = (this._questionnaireWrapperDto?.sectionTemplateList ?? []).filter(item => item.envelopeType == envelopeType);
    let sectionCalculatedScore = sectionTemplateList.reduce((prev, cur) => Number(prev) + Number(cur.sectionScore ?? 0), 0)
    return sectionCalculatedScore ?? 0;
  }

  checkSectionScoreValidation(envelopeType: SourcingEnvelopeType) {
    if (this._questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES' && this._questionnaireWrapperDto.sectionTemplateList && this._questionnaireWrapperDto.sectionTemplateList?.length > 0) {
      let sectionTemplateList = (this._questionnaireWrapperDto.sectionTemplateList ?? []).filter(item => item.envelopeType == envelopeType);

      let maximumScore = this._questionnaireWrapperDto?.questionnaireTemplate?.maximumScore;
      let sectionCalculatedScore = sectionTemplateList.reduce((prev, cur) => Number(prev) + Number(cur.sectionScore ?? 0), 0)

      return sectionCalculatedScore != Number(maximumScore);
    } else {
      return false;
    }
  }

  checkQuestionScoreValidation(sectionId: string) {
    if (this._questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES' && this._questionnaireWrapperDto.technicalQuestionTemplates && this._questionnaireWrapperDto.technicalQuestionTemplates?.length > 0) {
      let technicalQuestions = this._questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == sectionId) ?? [];
      let calculatedScore = technicalQuestions.reduce((prev, cur) => Number(prev) + Number(cur.score ?? 0), 0);
      let sectionTemplate = this._selectedSection$.value;

      return Number(sectionTemplate?.sectionScore) != calculatedScore;
    } else {
      return false;
    }
  }

  getTotalScoreOfQuestions(sectionId: string) {
    let technicalQuestions = this._questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == sectionId) ?? [];
    let calculatedScore = technicalQuestions.reduce((prev, cur) => Number(prev) + Number(cur.score ?? 0), 0);
    return calculatedScore;
  }

  getQuestionnaireWrapperDtoBySubcategoryId(subcategoryId: string) {
    let questionnairesWrapper = this._questionnaireWrapperDtoList.find(item => item.questionnaireTemplate?.rfxSubcategoryId == subcategoryId);
    return questionnairesWrapper;
  }

  updateCurrentSubcategoryId(rfxSubcategoryId: string) {
    this.currentRfxSubcategoryId = rfxSubcategoryId;
  }

  updateQuestionnaireWrapperDto(newQuestionnaireWrapperDto?: QuestionnaireWrapperDto) {
    this._questionnaireWrapperDto = newQuestionnaireWrapperDto;
    this._questionnaireWrapperDto$.next(newQuestionnaireWrapperDto);
    this.updateQuestionnaireWrapperDtosBySingleWrapper(newQuestionnaireWrapperDto)
  }

  updateQuestionnaireWrapperDtosBySingleWrapper(newQuestionnaireWrapperDto?: QuestionnaireWrapperDto) {

    if (newQuestionnaireWrapperDto) {
      let old = this._questionnaireWrapperDtoList.find(item => item.questionnaireTemplate?.templateId == newQuestionnaireWrapperDto.questionnaireTemplate?.templateId);
      if (old) {
        let index = this._questionnaireWrapperDtoList.findIndex(item => item.questionnaireTemplate?.templateId == newQuestionnaireWrapperDto.questionnaireTemplate?.templateId)!;
        Object.assign(this._questionnaireWrapperDtoList![index], newQuestionnaireWrapperDto);
      } else {
        this._questionnaireWrapperDtoList.push(newQuestionnaireWrapperDto);
      }
      this._questionnaireWrapperDtoList$.next(this._questionnaireWrapperDtoList!);
    }
  }

  updateQuestionnaireWrapperDtos(newQuestionnaireWrapperDtos: QuestionnaireWrapperDto[]) {
    this._questionnaireWrapperDtoList = newQuestionnaireWrapperDtos;
    this._questionnaireWrapperDtoList$.next(newQuestionnaireWrapperDtos);
  }



  setSelectedSection(sectionTemplate: SectionTemplate) {
    this._selectedSection$.next(sectionTemplate);
  }

  setSelectedTechQuestion(questionTemplate: TechnicalQuestionTemplate) {
    this._selectedTechQuestion$.next(questionTemplate);
  }

  setSelectedFinancialQuestion(questionTemplate: FinancialQuestionTemplate) {
    this._selectedFinancialQuestion$.next(questionTemplate);
  }

  runTechQuestionnaireValidation() {
    this._questionnaireValidationList$.next([]);
    let _questionnaireValidationList: QuestionnaireValidationDto[] = [];

    if (this._questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES') {
      let isScoreMismatch = this.checkSectionScoreValidation(SourcingEnvelopeType.TECHNICAL);

      if (isScoreMismatch) {
        let totalScoreOfSections = this.getTotalScoreOfSections(SourcingEnvelopeType.TECHNICAL);
        let maximumScore = this._questionnaireWrapperDto?.questionnaireTemplate?.maximumScore;

        let questionnaireValidationDto = new QuestionnaireValidationDto();
        questionnaireValidationDto.message = `The sum of section scores (${totalScoreOfSections}) does not match the questionnaire total score (${maximumScore})`;

        _questionnaireValidationList.push(questionnaireValidationDto);
      }

      let selectedSectionTemplate = this._selectedSection$.value;
      if (selectedSectionTemplate && (selectedSectionTemplate.questionIds ?? []).length > 0) {
        let totalScoreOfQuestions = this.getTotalScoreOfQuestions(selectedSectionTemplate.sectionId!);

        if (totalScoreOfQuestions != selectedSectionTemplate.sectionScore) {
          let questionnaireValidationDto = new QuestionnaireValidationDto();
          questionnaireValidationDto.message = `The sum of questions scores (${totalScoreOfQuestions}) does not match the section score (${selectedSectionTemplate.sectionScore})`;

          _questionnaireValidationList.push(questionnaireValidationDto);
        }
      }
    }

    this._questionnaireValidationList$.next(_questionnaireValidationList);
  }

  checkFinancialSectionsValidation() {
    let financialSections = this._questionnaireWrapperDto?.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.FINANCIAL) ?? [];
    let financialQuestions = this._questionnaireWrapperDto?.financialQuestionTemplates ?? [];

    if (financialSections.length > 0) {
      let haveFinancialQuestionsInAllSections = financialSections.every(section => financialQuestions.find(item => item.sectionId == section.sectionId));
      return !haveFinancialQuestionsInAllSections;
    }
    return false;
  }

  haveQuestionnaireValidationIssues(eventType: EventEnum): boolean {
    if (this._questionnaireWrapperDto) {
      let questionnaireTemplate = this._questionnaireWrapperDto.questionnaireTemplate;
      let financialSections = this._questionnaireWrapperDto.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.FINANCIAL) ?? [];
      let technicalSections = this._questionnaireWrapperDto.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL) ?? [];

      if (questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS || questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QBS) {
        if (financialSections.length == 0) {
          return true;
        }
        if (technicalSections.length == 0) {
          return true;
        }
        if (this.checkTechnicalQuestionnaireValidations()) {
          return true;
        }
        if (this.checkFinancialQuestionnaireValidations()) {
          return true;
        }
        return false;
      }

      if (questionnaireTemplate?.contractAlgo == ContractAlgoEnum.LCS || questionnaireTemplate?.contractAlgo == ContractAlgoEnum.HCS) {
        if (financialSections.length == 0) {
          return true;
        }
        if (this.checkFinancialQuestionnaireValidations()) {
          return true;
        }
        return false;
      }

      return false;
    } else {
      return eventType == EventEnum.RFX ? true : false;
    }
  }

  checkTechnicalQuestionnaireValidations(): boolean {
    if (this._questionnaireWrapperDto) {
      if (this._questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES') {
        let sectionTemplateList = this._questionnaireWrapperDto.sectionTemplateList;

        if (!sectionTemplateList || sectionTemplateList.length == 0) {
          return true;
        }

        let isScoreMismatch = this.checkSectionScoreValidation(SourcingEnvelopeType.TECHNICAL);
        if (isScoreMismatch) {
          return true;
        }

        for (let i = 0; i < sectionTemplateList.length; i++) {
          const sectionTemplate = sectionTemplateList[i];

          if (!sectionTemplate.questionIds || sectionTemplate.questionIds.length == 0) {
            return true;
          }

          let totalScoreOfQuestions = this.getTotalScoreOfQuestions(sectionTemplate.sectionId!);
          if (totalScoreOfQuestions != Number(sectionTemplate.sectionScore)) {
            return true
          }
        }
        return false;
      } else {
        let sectionTemplateList = this._questionnaireWrapperDto.sectionTemplateList;

        if (!sectionTemplateList || sectionTemplateList.length == 0) {
          return true;
        }

        for (let i = 0; i < sectionTemplateList.length; i++) {
          const sectionTemplate = sectionTemplateList[i];
          if (!sectionTemplate.questionIds || sectionTemplate.questionIds.length == 0) {
            return true;
          }
        }
        return false;
      }
    } else {
      return true;
    }
  }

  checkFinancialQuestionnaireValidations(): boolean {
    if (this._questionnaireWrapperDto) {
      let financialSections = this._questionnaireWrapperDto.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.FINANCIAL) ?? [];
      let financialQuestions = this._questionnaireWrapperDto.financialQuestionTemplates ?? [];

      if (financialSections.length > 0) {
        let haveFinancialQuestionsInAllSections = financialSections.every(section => financialQuestions.find(item => item.sectionId == section.sectionId));
        return !haveFinancialQuestionsInAllSections;
      }
      return false;
    } else {
      return true;
    }
  }



  async loadQuestionnaireWrapperDto(templateId: string, type: EventEnum) {
    try {
      let apiResponseDto = await firstValueFrom(this.getQuestionnairesWrapper(templateId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;

        if (type == EventEnum.AUCTION) {
          this.updateQuestionnaireWrapperDto(questionnaireWrapperDto);
        }

        if (type == EventEnum.RFX) {
          if (this.currentRfxSubcategoryId == questionnaireWrapperDto.questionnaireTemplate?.rfxSubcategoryId) {
            this.updateQuestionnaireWrapperDto(questionnaireWrapperDto);
          } else {
            this.updateQuestionnaireWrapperDto(undefined);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  saveQuestionnaires(questionnaireTemplate: QuestionnaireTemplate) {
    return this.http.post<ServerAPIResponseDto>('questionnaires', questionnaireTemplate).pipe(
      tap(_ => console.log("Saved questionnaireTemplate to DB" + questionnaireTemplate.templateName)),
      catchError(this.errorService.handleError<any>("Error while Saving Questionnaire to DB: " + questionnaireTemplate.templateName)))
  }

  saveSectionTemplate(sectionTemplate: SectionTemplate) {
    return this.http.post<ServerAPIResponseDto>('questionnaires-sections', sectionTemplate).pipe(
      tap(_ => console.log("Saved questionnaireTemplate to DB" + sectionTemplate.sectionName)),
      catchError(this.errorService.handleError<any>("Error while Saving Questionnaire to DB: " + sectionTemplate.sectionName)))
  }

  saveTechQuestionTemplate(technicalQuestionTemplate: TechnicalQuestionTemplate) {
    return this.http.post<ServerAPIResponseDto>('questionnaires-technicalQuestions', technicalQuestionTemplate).pipe(
      tap(_ => console.log("Saved saveTechQuestionTemplate to DB" + technicalQuestionTemplate.questionText)),
      catchError(this.errorService.handleError<any>("Error while Saving saveTechQuestionTemplate to DB: " + technicalQuestionTemplate.questionText)))
  }

  saveFinancialQuestionTemplate(financialQuestionTemplate: FinancialQuestionTemplate) {
    return this.http.post<ServerAPIResponseDto>('questionnaires-financialQuestions', financialQuestionTemplate).pipe(
      tap(_ => console.log("Saved saveTechQuestionTemplate to DB" + financialQuestionTemplate.questionId)),
      catchError(this.errorService.handleError<any>("Error while Saving saveTechQuestionTemplate to DB: " + financialQuestionTemplate.questionId)))
  }

  getQuestionnairesWrapper(templateId: string) {
    let params = new HttpParams().set('templateId', templateId);
    return this.http.get<ServerAPIResponseDto>('questionnaires', { params }).pipe(
      tap(_ => console.log("Getting questionnaires ")),
      catchError(this.errorService.handleError<any>("Error while getting questionnaires")))
  }

  deleteQuestionnaire(questionnaireId: string, eventType: string) {
    let params = new HttpParams().set('questionnaireId', questionnaireId).set('type', eventType);
    return this.http.get<ServerAPIResponseDto>('deleteQuestionnaires', { params }).pipe(
      tap(_ => console.log("Deleting questionnaire ")),
      catchError(this.errorService.handleError<any>("Error while getting questionnaire")))
  }

  deleteSection(sectionId: string) {
    let params = new HttpParams().set('sectionId', sectionId);
    return this.http.get<ServerAPIResponseDto>('deleteSections', { params }).pipe(
      tap(_ => console.log("Deleting sections ")),
      catchError(this.errorService.handleError<any>("Error while deleting sections")))
  }

  deleteQuestion(questionId: string) {
    let params = new HttpParams().set('questionId', questionId);
    return this.http.get<ServerAPIResponseDto>('deleteQuestions', { params }).pipe(
      tap(_ => console.log("Deleting questions ")),
      catchError(this.errorService.handleError<any>("Error while getting questions")))
  }

  updateSectionOrdering(resourceSwapWrapperDto: ResourceSwapWrapperDto) {
    return this.http.post<ServerAPIResponseDto>('reorderSections', resourceSwapWrapperDto).pipe(
      tap(_ => console.log("Saved reorderSections to DB" + resourceSwapWrapperDto.sourcingEnvelopeType)),
      catchError(this.errorService.handleError<any>("Error while Saving reorderSections to DB: " + resourceSwapWrapperDto.sourcingEnvelopeType)))
  }

  updateQuestionOrdering(resourceSwapWrapperDto: ResourceSwapWrapperDto) {
    return this.http.post<ServerAPIResponseDto>('reorderQuestions', resourceSwapWrapperDto).pipe(
      tap(_ => console.log("Saved reorderQuestions to DB" + resourceSwapWrapperDto.sourcingEnvelopeType)),
      catchError(this.errorService.handleError<any>("Error while Saving reorderQuestions to DB: " + resourceSwapWrapperDto.sourcingEnvelopeType)))
  }

  markQuestionnairePublish(questionnaireId: string) {
    let params = new HttpParams().set('questionnaireId', questionnaireId);
    return this.http.post<ServerAPIResponseDto>('markQuestionnairePublish', null, { params }).pipe(
      tap(_ => console.log("Saved markQuestionnairePublish to DB" + questionnaireId)),
      catchError(this.errorService.handleError<any>("Error while Saving markQuestionnairePublish to DB: " + questionnaireId)))
  }

  getUserAuctionQuestions(auctionId: string, userId: string) {
    let params = new HttpParams().set('userId', userId).set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('userAuctionQuestions', { params }).pipe(
      tap(_ => console.log("Saved userAuctionQuestions to DB" + auctionId)),
      catchError(this.errorService.handleError<any>("Error while Saving userAuctionQuestions to DB: " + auctionId)))
  }

  subcategorySubmissionQuestions(evaluationRfxQuestionsEntityDto: EvaluationUserRfxQuestionsEntityDto) {
    return this.http.post<ServerAPIResponseDto>('subcategorySubmissionQuestions', evaluationRfxQuestionsEntityDto).pipe(
      tap(_ => console.log("Saved subcategorySubmissionQuestions to DB" + evaluationRfxQuestionsEntityDto.questionId)),
      catchError(this.errorService.handleError<any>("Error while Saving subcategorySubmissionQuestions to DB: " + evaluationRfxQuestionsEntityDto.questionId)))
  }

  downloadSampleExcel() {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
    };

    return this.http.get<ServerAPIResponseDto>('assets/config/financial_questions.xlsx', httpOptions).pipe(
      tap(_ => console.log("Downloaded sample excel file.")),
      catchError(this.errorService.handleError<any>("Error while downloading sample excel file.")))
  }

  uploadBulkFinancialSampleExcel(excelFile: FormData, questionnaireId: string, sectionId: string): Observable<ServerAPIResponseDto> {
    let params = new HttpParams().set('questionnaireId', questionnaireId).set('sectionId', sectionId);
    return this.http.post<ServerAPIResponseDto>('bulkQuestions', excelFile).pipe(
      tap(_ => console.log("Saved bulk sample excel" + sectionId)),
      catchError(this.errorService.handleError<any>("Error while uploading sample excel: " + sectionId)))
  }
}
