<div class="container my-2">
  <div class="bg-white p-3">
    <h4 class="heading-h4 text-dark">Shopping Cart</h4>

    <div class="mt-3">
      <div class="col-12">
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-6">
                Item
              </div>
              <div class="col-2 text-center">
                Price
              </div>
              <div class="col-2 text-center">
                Qty
              </div>
              <div class="col-2 text-center">
                Subtotal
              </div>
            </div>
            <hr class="hr-light">
          </div>

          <div class="col-md-4">
            <div class="font-weight-normal">
              SUMMARY
            </div>
            <hr class="hr-light">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-8">
            <div class="row" *ngFor="let item of cartItems; index as i">
              <div class="col-6">
                <div class="row">
                  <div class="col-3">
                    <img src="/downloadLandingBlob?fileId={{item.catalogueLineItemsEntityDto?.fileInfoDto?.fileId}}" class="catalogue-cart-image">
                  </div>
                  <div class="col-9">
                    <div>
                      {{item.catalogueLineItemsEntityDto?.title}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2 text-center">
                {{currency.symbol}}{{getFormattedPrice(item.catalogueVendor?.currency!, item.catalogueVendor?.amount!)}}
              </div>
              <div class="col-2 text-center">
                <div class="input-group mb-3">
                  <span class="input-group-text bg-white" (click)="quantityItemMinus(item.catalogueLineItemsEntityDto!, quantityInput)">-</span>
                  <input #quantityInput type="text" class="form-control text-center" id="quantityItems" [value]="item.quantity">
                  <span class="input-group-text bg-white" (click)="quantityItemPlus(item.catalogueLineItemsEntityDto!, quantityInput)">+</span>
                </div>
              </div>
              <div class="col-2 text-center">
                {{getSubtotalPriceOfItem(item, quantityInput)}}
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="div-class row my-1">
              <div class="col-7 ">
                Estimate pricing
              </div>
              <div class="col-5 text-end">
                <!-- <img src="assets/icons/angle-downn.svg" class="svg-icon-class c-pointer ">
                <img src="assets/icons/angle-upp.svg" class="svg-icon-class c-pointer d-none"> -->
              </div>
            </div>
            <hr class="hr-light my-1">
            <div class="div-class row my-1">
              <div class="col-7">
                Subtotal
              </div>
              <div class="col-5 text-end">
                {{currency.symbol}}{{getFormattedPrice(currency, totalAmount)}}
              </div>
              <!-- <div class="col-7 mt-2">
                <b>Shipping</b>(Flat Rate - Fixed)
              </div>
              <div class="col-5 text-end mt-2">
                Rs.500
              </div> -->
            </div>
            <hr class="hr-light my-1">
            <div class="div-class row my-1">
              <div class="col-7">
                Tax
              </div>
              <div class="col-5 text-end">
                {{currency.symbol}}{{getFormattedPrice(currency, totalTax)}}
                <!-- <span>
                  <img src="assets/icons/angle-downn.svg" class="svg-icon-class c-pointer ">
                  <img src="assets/icons/angle-upp.svg" class="svg-icon-class c-pointer d-none">
                </span> -->
              </div>

            </div>
            <hr class="hr-light my-1">
            <!-- <div class="div-class row my-1">
              <div class="col-7">
                Total
              </div>
              <div class="col-5 text-end">
                {{currency.symbol}}{{getFormattedPrice(currency, totalAmount)}}
                <span>
                  <img src="assets/icons/angle-downn.svg" class="svg-icon-class c-pointer ">
                  <img src="assets/icons/angle-upp.svg" class="svg-icon-class c-pointer d-none">
                </span>
              </div>

            </div>
            <hr class="hr-light my-1"> -->
            <div class="div-class row my-1">
              <div class="col-7">
                ORDER TOTAL INCL. TAX
              </div>
              <div class="col-5 text-end div-class">
                <span class="fs-6">
                  <b>{{currency.symbol}}{{getFormattedPrice(currency, totalAmount + totalTax)}}</b>
                </span>
              </div>

            </div>
            <!-- <div class="div-class row mb-3">
    <div class="col-7">
      ORDER TOTAL EXCL. TAX
    </div>
    <div class="col-5 text-end text-red-500 ">
      <span class="fs-6">Rs.1340.00</span>
    </div>
 
  </div> -->
            <!-- <hr class="hr-light my-1">
            <div class="div-class row">
              <div class="col-7">
                Apply Discount Code
              </div>
              <div class="col-5 text-end">
                <img src="assets/icons/angle-downn.svg" class="svg-icon-class c-pointer ">
                <img src="assets/icons/angle-upp.svg" class="svg-icon-class c-pointer d-none">

              </div>
            </div> -->
            <hr class="hr-light my-1">

            <div class="d-grid gap-2 my-3" *ngIf="isLoading">
              <div class="e-spinner"></div>
            </div>

            <div class="my-3">
              <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
                [message]="'Requisition submitted successfully.'"></app-message-toast>
            </div>

            <div class="mt-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class w-100" (click)="submitRequisition()" [disabled]="cartItems.length == 0">
                Submit Requisition
              </button>
            </div>

            <!-- <div class="mt-3 text-center div-class">
    Checkout with multiple addresses
</div> -->
          </div>
        </div>
      </div>
      <div class="col-12 my-3 ">
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class text-white me-3 mb-2" (click)="closeModal();" [disabled]="isLoading">
          Continue Shopping
        </button>
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class me-3 mb-2" (click)="clearCart()" [disabled]="cartItems.length == 0 || isLoading">
          Clear Shopping Cart
        </button>
        <!-- <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class me-3 ">
    Update Shopping Cart
    </button> -->
      </div>
    </div>

  </div>
</div>