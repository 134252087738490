import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ActionTypeEnum } from 'src/app/shared/enums/ActionTypeEnum';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserActivityDto } from 'src/app/shared/models/user/UserActivityDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-admin-user-activity',
  templateUrl: './admin-user-activity.component.html',
  styleUrls: ['./admin-user-activity.component.sass']
})
export class AdminUserActivityComponent implements OnInit {
  currentViewTabName: string = "TODAY";
  isLoading = false;

  @Input() userId?: string;

  userActivityList: Array<UserActivityDto> = []

  constructor(
    private modalService: NgbModal,
    private adminDashboardService : AdminDashboardService,

  ) { }

  ngOnInit(): void {
    this.getUserActivities();

  }

  toggleCurrentViewTab(type: string){
    this.currentViewTabName =type;
    this.userActivityList = []
    this.getUserActivities();
  }



  closeModal() {
    this.modalService.dismissAll();
  }

  getUserActivities() {
    this.isLoading = true;
    this.adminDashboardService.getUserActivity(this.userId!, this.currentViewTabName).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as UserActivityDto[];

          this.userActivityList = data;
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
      },
    })
  }


  isAllowToShowBadge(type: string){
    let toReturn= false;
    if(type == ActionTypeEnum.AUCTION_MARKED_ACTIVE || type == ActionTypeEnum.AUCTION_MARKED_INACTIVE || type == ActionTypeEnum.AUCTION_DISCARD || type == ActionTypeEnum.LOT_DISCARD
       || type == ActionTypeEnum.LOT_MARKED_ACTIVE || type == ActionTypeEnum.LOT_MARKED_INACTIVE || type == ActionTypeEnum.BID_REJECTED
       || type == ActionTypeEnum.USER_REGISTRATION_APPROVED || type == ActionTypeEnum.USER_REGISTRATION_REJECTED){
        toReturn =  true;
    }
    return toReturn;
  }

  getBadgeValue(type: string){
    let toReturn= "";
    if(type == ActionTypeEnum.AUCTION_MARKED_ACTIVE || type == ActionTypeEnum.LOT_MARKED_ACTIVE){
      toReturn = "ACTIVE";
    }else if(type == ActionTypeEnum.AUCTION_MARKED_INACTIVE || type == ActionTypeEnum.LOT_MARKED_INACTIVE ){
      toReturn = "INACTIVE";
    }else if(type == ActionTypeEnum.USER_REGISTRATION_APPROVED){
      toReturn = "APPROVED";
    }else if(type == ActionTypeEnum.USER_REGISTRATION_REJECTED){
      toReturn = "REJECTED";
    }else if(type == ActionTypeEnum.BID_REJECTED){
      toReturn = "BID REJECTED"
    }else if(type == ActionTypeEnum.LOT_DISCARD || type == ActionTypeEnum.AUCTION_DISCARD){
      return toReturn = "DISCARD"
    }
    return toReturn;
  }

  getBadgeClass(type: string){
    let toReturn= "";
    if(type == ActionTypeEnum.AUCTION_MARKED_ACTIVE || type == ActionTypeEnum.LOT_MARKED_ACTIVE || type == ActionTypeEnum.USER_REGISTRATION_APPROVED){
      toReturn = "bg-info";
    }else if(type == ActionTypeEnum.AUCTION_MARKED_INACTIVE || type == ActionTypeEnum.LOT_MARKED_INACTIVE || type == ActionTypeEnum.USER_REGISTRATION_REJECTED
      || type == ActionTypeEnum.BID_REJECTED|| type == ActionTypeEnum.LOT_DISCARD || type == ActionTypeEnum.AUCTION_DISCARD){
      toReturn = "bg-danger";
    }
    return toReturn;
  }



  getCreatedDate(timestamp: string) {
    if (timestamp) {
      var stillUtc = moment.utc(timestamp).toDate();
      var localDate = moment(stillUtc).local()

      let date = localDate.toDate().toDateString();
      let time = localDate.toDate().toTimeString();
      return ApplicationUtils.getDisplayDateAdmin(date, time);
    }
    return '';
  }
}
