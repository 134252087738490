
  <div class=" mt-3">
      <div class="border-b flex px-6 py-0 items-center gap-3 bg-white h-20 border-slate-50">
        <div class="grid grid-cols-1 md:grid-cols-12 gap-4 w-full items-center justify-between">
          <div class="col-span-6">
            <div class="text-gray-900 text-2xl font-semibold  ">
              Rule Meta Data
            </div>
          </div>
          <div class="col-span-6 text-right">
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800inline-flex"
            (click)="openPurchaseRequestModal()">
              Add
            </button>
          </div>
        </div>
      </div>
      <div class="bg-gray-100 gap-2.5 py-4 px-6 item-start flex-col self-stretch flex">
        <div class="table-responsive">
          <table class="table table-bordered mb-0 medium-font">
            <thead class="table-dark">
              <tr class="text-center">
                <th class="text-center"><span>SNo</span></th>
                <th class=""><span>Rule</span></th>
                <th class=""><span>Workflow Code</span></th>
                <th class=""><span>Company Code</span></th>
                <th class=""><span>Plant Code</span></th>
                <th class=""><span>
                    Action
                  </span>
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr *ngIf="isDataLoading" class="align-middle">
                <td colspan="9" class="text-center">
                  <div class="e-spinner"></div>
                </td>
              </tr>
              <tr>
                <td class="text-center" colspan="9" *ngIf="!isDataLoading && approvalWorkflowUiDtos.length == 0">
                  <h5 class="heading-new"> No Data Available</h5>
                </td>
              </tr>

              <tr class="align-middle text-center" *ngFor="let item of approvalWorkflowUiDtos; index as i">
                <td class=" text-center">{{i+1}}</td>
                <td class="">{{item.awfDefineUiDto?.name}}</td>
                <td class="">{{getOnboardingWorkflow(item.awfDefineUiDto?.wfCode!)}}</td>
                <td class="">{{item.awfDefineUiDto?.companyCode}}</td>
                <td class="">{{item.awfDefineUiDto?.plantCode}}</td>
                <td class="">
                  <a class="link-class" (click)="openMetaDataView(item)">View</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
   
  </div>
