import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';



import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuItem, TreeNode, PrimeIcons } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AdminUserPrivilege } from 'src/app/shared/enums/AdminUserPrivilege';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AddUserDto } from 'src/app/shared/models/user/AddAdminDto';
import { AdminDataUIWrapperDto } from 'src/app/shared/models/user/AdminDataUIWrapperDto';
import { UserPlantsRoleUiDto } from 'src/app/shared/models/user/UserPlantsRoleUiDto';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';

@Component({
  selector: 'app-admin-auctioneer-section',
  templateUrl: './admin-auctioneer-section.component.html',
  styleUrls: ['./admin-auctioneer-section.component.sass']
})
export class AdminAuctioneerSectionComponent implements OnInit, AfterViewInit, OnDestroy {
  userActiveFlag: boolean = false;
  isAuctioneerModal: boolean = false;
  formGroup: FormGroup;
  ctrlSearchAgent: FormControl = new FormControl('');
  ctrlToggleOrgChart: FormControl = new FormControl('HIDE');
  ctrlParentUser: FormControl = new FormControl('');

  toggleStateOptions: any[] = [
    { label: 'Show', value: 'SHOW' },
    { label: 'Hide', value: 'HIDE' }
  ];
  organisationMenuItems: MenuItem[] = [];

  agentsList: Array<UserUiDto> = [];
  _filteredUsers$ = new BehaviorSubject<Array<UserUiDto>>([]);

  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _isPasswordMatch$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _errorMsg$ = new BehaviorSubject<string>("");

  isLoading: boolean = false;
  auctioneerStatus: boolean = false;
  isPasswordVisible: boolean = false;
  isRepeatPasswordVisible: boolean = false;
  showOrganizationChart: boolean = false;

  selectedOrgChart: TreeNode[] = [];
  orgChartData: TreeNode[] = []

  adminUIWrapper?: AdminDataUIWrapperDto;

  activeUserEntityInfoDto?: UserUiDto;
  adminDashboardDataSubscription$?: Subscription;

  @ViewChild('addAuctioneerModal') addModalTemplate?: TemplateRef<any>
  @ViewChild('disableAdmin') disableAdminModalTemplate?: TemplateRef<any>

  constructor(
    private adminDashboardService: AdminDashboardService,
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    public datePipe: DatePipe
  ) {
    this.formGroup = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      designation: new FormControl('', Validators.required),
      parentUserId: new FormControl(''),
      emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
      password: new FormControl('', [Validators.required, Validators.minLength(9), PasswordStrengthValidator]),
      confirmPassword: new FormControl('', [Validators.required])
    })
  }

  ngOnInit(): void {
    this.adminDashboardDataSubscription$ = this.adminDashboardService.getAdminDashboardData$.subscribe((data) => {
      if (data) {
        this.adminUIWrapper = data;
        this.populateOrganizationChart();
        this.searchAgentData()
      }
    })

    this.ctrlSearchAgent.valueChanges.subscribe((value) => {
      if (value) {
        this.searchAgentData()
      }
    });

    this.ctrlToggleOrgChart.valueChanges.subscribe(val => {
      if (val) {
        this.showOrganizationChart = val == 'SHOW';
      } else {
        this.showOrganizationChart = false;
      }
    })
  }

  ngAfterViewInit(): void {

  }

  get fc(): any { return this.formGroup.controls; }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleRepeatPasswordVisibility() {
    this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
  }

  populateOrganizationChart() {
    this.agentsList = this.adminUIWrapper?.agentList ?? [];
    let filteredAgentList = [...this.agentsList].filter(item => item?.designation && item?.designation != '');
    this.orgChartData = this.buildTreeNode(filteredAgentList);

    if (this.agentsList.length == 0) {
      this.formGroup.controls['parentUserId'].clearValidators();
    } else {
      this.formGroup.controls['parentUserId'].setValidators(Validators.required);
    }
    this.formGroup.controls['parentUserId'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  selectOrganizationUser(user: any) {
    let selectedUser = this.agentsList.find(item => item.userId == user.userId);
    this.activeUserEntityInfoDto = selectedUser;

    this.refreshOrganizationUserMenu();

    this.formGroup.controls['parentUserId'].patchValue(this.activeUserEntityInfoDto?.userId);
    this.formGroup.controls['parentUserId'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  refreshOrganizationUserMenu() {
    this.organisationMenuItems = [
      {
        icon: PrimeIcons.PLUS, command: () => {
          this.openAddModal(false);
        }
      },
      // { icon: PrimeIcons.PENCIL, command: () => { } },
      this.activeUserEntityInfoDto?.active ? {
        icon: PrimeIcons.BAN, command: () => {
          this.openDisableUserModal(this.disableAdminModalTemplate, this.activeUserEntityInfoDto!);
        }
      } : {
        icon: PrimeIcons.CHECK_CIRCLE, command: () => {
          this.openDisableUserModal(this.disableAdminModalTemplate, this.activeUserEntityInfoDto!);
        }
      },
    ]
  }

  openAddModal(isShowParentUsers: boolean) {
    if (isShowParentUsers) {
      this.activeUserEntityInfoDto = undefined
    }

    this._errorMsg$.next("");
    this._showErrorToast$.next(false);
    this.isAuctioneerModal = true
    // this.ngbModal.open(content, {
    //   size: 'md', backdrop: 'static', keyboard: false, centered: true
    // });
  }

  openDisableUserModal(content: any, activeUserEntityInfoDto: UserUiDto) {
    this.activeUserEntityInfoDto = activeUserEntityInfoDto;
    this.userActiveFlag = activeUserEntityInfoDto.active;
    this.auctioneerStatus = activeUserEntityInfoDto.active;
    let modalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });

    modalRef.result.then((result) => {
      // toggleRef.checked = this.auctioneerStatus;
    }, (reason) => {
      // toggleRef.checked = this.auctioneerStatus;
    });
  }

  getMemberSinceYear(year: string) {
    if (year) {
      return ApplicationUtils.getDateInMMMddyyyy(year, this.datePipe)
    }
    return '';
  }

  validateFormGroupData() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  onChange() {
    // this.authService.errorMsg = null!;
    if (this.formGroup.controls['password'].value == this.formGroup.controls['confirmPassword'].value) {
      this._isPasswordMatch$.next(true);
    } else {
      this._isPasswordMatch$.next(false);
    }
  }

  populateSuperAdminData() {
    let addSuperAdminDto = new AddUserDto();
    addSuperAdminDto.firstName = this.formGroup?.controls['firstName'].value!;
    addSuperAdminDto.lastName = this.formGroup?.controls['lastName'].value!;
    addSuperAdminDto.designation = this.formGroup?.controls['designation'].value!;
    addSuperAdminDto.emailId = this.formGroup?.controls['emailId'].value!;
    addSuperAdminDto.parentUserId = this.formGroup?.controls['parentUserId'].value!;
    addSuperAdminDto.password = window.btoa(this.formGroup?.controls['password'].value!);
    addSuperAdminDto.userType = AdminUserPrivilege.IT_SETUP;
    addSuperAdminDto.auctionHouseId = this.adminUIWrapper?.auctionHouseDto?.orgCode;
    return addSuperAdminDto;
  }

  handleValidSubmit() {
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    if (this.validateFormGroupData() || this._isPasswordMatch$.value == false) {
      this.formGroup.markAllAsTouched();
      return;
    }
    if (this.formGroup?.dirty) {
      this.isLoading = true;
      this._isSaveButtonEnable$.next(false);
      let superAdminDto = this.populateSuperAdminData();
      this.adminDashboardService.updateAdmin(superAdminDto).subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
            this.updateUserData(apiResponseDto.data as UserUiDto)
            this._showSuccessToast$.next(true);
            this._isSaveButtonEnable$.next(true);
            this.isLoading = false;
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this._isSaveButtonEnable$.next(true);
              this.closeModal();
            }, 2000)
          } else {
            this._errorMsg$.next(apiResponseDto.message!);
            this._showErrorToast$.next(true);
            this._isSaveButtonEnable$.next(true);
            this.isLoading = false;
          }
        },
        error: (err) => {
          console.error(err);
          this._errorMsg$.next("Error while adding user. Try again.");
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      })
    }
  }

  markResourceActiveOrInActive(action: string) {
    let active = action == "ENABLE";
    this._showErrorToast$.next(false);

    this.isLoading = true;
    this.adminDashboardService.markResourceActiveOrInActive(this.activeUserEntityInfoDto?.userId!, active, 'AGENT').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.updateUserData(apiResponseDto.data as UserUiDto);
          this._showSuccessToast$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.isLoading = false;
            this.auctioneerStatus = active;
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err: any) => {
        console.error(err);
        this.isLoading = false;
      }
    })
  }

  changeReportingUser() {
    this._showErrorToast$.next(false);
    this.isLoading = true;

    let superAdminDto = ApplicationUtils.clone(this.activeUserEntityInfoDto);

    this.adminDashboardService.updateAdmin(superAdminDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.updateUserData(apiResponseDto.data as UserUiDto)
          this._showSuccessToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this._isSaveButtonEnable$.next(true);
            this.closeModal();
          }, 2000)
        } else {
          this._errorMsg$.next(apiResponseDto.message!);
          this._showErrorToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this._errorMsg$.next("Error while adding user. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  updateUserData(userUiDto: UserUiDto) {
    let data = null;

    if (ApplicationUtils.checkIfRoleIsAssigned(userUiDto?.userPrivileges!, AdminUserPrivilege.IT_SETUP)) {
      if (this.adminUIWrapper && this.adminUIWrapper?.agentList) {
        data = this.adminUIWrapper?.agentList?.find(item => item.userId == userUiDto.userId);
      } else {
        this.adminUIWrapper!.agentList = [];
      }

    } else {
      if (this.adminUIWrapper && this.adminUIWrapper?.agentList) {
        data = this.adminUIWrapper?.agentList?.find(item => item.userId == userUiDto.userId);
      } else {
        this.adminUIWrapper!.agentList = [];
      }

    }

    if (data) {
      Object.assign(data!, userUiDto);
    } else {
      if (ApplicationUtils.checkIfRoleIsAssigned(userUiDto?.userPrivileges!, AdminUserPrivilege.IT_SETUP)) {
        this.adminUIWrapper?.agentList?.push(userUiDto);
      } else {
        this.adminUIWrapper?.agentList?.push(userUiDto);
      }

    }

    this.adminDashboardService._adminDashboardData$.next(this.adminUIWrapper!);
  }

  closeModal() {
    this.ngbModal.dismissAll();
    this.formGroup.reset();
  }

  searchAgentData() {
    if (this.ctrlSearchAgent.value.trim() != '') {
      this._filteredUsers$.next([]);

      let searchValue = this.ctrlSearchAgent.value.trim().toLowerCase();
      let agentList = [...this.adminUIWrapper?.agentList ?? []]

      let tempUsersList = agentList.filter(
        (item) => item.firstName?.toLowerCase().includes(searchValue)
          || item.lastName?.toLowerCase().includes(searchValue)
          || item.primaryEmailId?.toLowerCase().includes(searchValue));

      this._filteredUsers$.next(tempUsersList)
    } else {
      let agentList = [...this.adminUIWrapper?.agentList ?? []]
      this._filteredUsers$.next(agentList)
    }
  }

  buildTreeNode(users: UserUiDto[]): TreeNode[] {
    const userMap = new Map<string, TreeNode>();

    // Initialize the map with all users
    users.forEach(user => {

      const node: TreeNode = {
        expanded: true,
        type: 'person',
        data: {
          image: user.profileImage ? '/downloadLandingBlob?fileId=' + user.profileImage?.fileId : '/assets/images/avatar05.jpg',
          name: `${user.firstName} ${user.lastName}`,
          title: user?.designation,
          userId: user.userId,
          // parentUserId: userPlantsRoleUiDto.parentUserId
        },
        children: []
      };
      userMap.set(user.userId!, node);
    });

    const rootNodes: TreeNode[] = [];

    // Build the hierarchy
    // users.forEach(user => {
    //   const node = userMap.get(user.userId!);
    //   if (user.parentUserId) {
    //     const parent = userMap.get(user.parentUserId);
    //     if (parent) {
    //       parent.children?.push(node!);
    //     }
    //   } else {
    //     rootNodes.push(node!);
    //   }
    // });

    return rootNodes;
  }

  ngOnDestroy(): void { 
    if (this.adminDashboardDataSubscription$) {
      this.adminDashboardDataSubscription$.unsubscribe();
    }
  }
}
