import { Component, Input, OnInit } from '@angular/core';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { CountryCodeDto } from '../../models/CountryCodeDto';
import { DashboardRedirectionService } from '../../services/dashboard-redirection.service';
import { LandingService } from '../../services/LandingAndBidder.service';
import { AuctionHouseDto } from '../../models/user/AuctionHouseDto';
import { firstValueFrom, Subscription } from 'rxjs';
import { AuctionCardWrapperDto } from '../../models/AuctionCardWrapperDto';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { Store } from '@ngrx/store';
import { NavigationEventsDto } from '../../models/NavigationEventsDto';
import { NavigationActions } from '../../state-management/navigation/navigation.actions';
import { selectUserUiDto } from '../../state-management/session.features';

@Component({
  selector: 'app-closed-auction-card',
  templateUrl: './closed-auction-card.component.html',
  styleUrls: ['./closed-auction-card.component.sass']
})
export class ClosedAuctionCardComponent implements OnInit {
  @Input() auctionCardWrapperDto?: AuctionCardWrapperDto;

  public screenWidth: any;
  isLoading: boolean = false;
  selectedIndex?: number;

  auctionHouse?: AuctionHouseDto;
  auctionEntityDto?: AuctionEntityDto;
  currentAuctionHouseSubscription$?: Subscription;

  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private landingService: LandingService,
    private router: Router,
    private store: Store
  ) { }


  ngOnInit(): void {
    this.auctionEntityDto = this.auctionCardWrapperDto?.auctionEntityDto;
    this.currentAuctionHouseSubscription$ = this.landingService.getCurrentAuctionHouse$.subscribe((data) => {
      if (data) {
        this.auctionHouse = data;
      }
    })
  }

  ngAfterContentInit(): void {
    this.screenWidth = window.innerWidth;
  }

  async navigateAuctionView() {
    this.isLoading = true;
    // this.dataRedirectionService.setResourceIds(this.auctionEntityDto?.auctionHouseId!, this.auctionEntityDto?.auctionId!);
    // this.dataRedirectionService.handleDashboardAppNavigation();
    // this.dataRedirectionService.storeNavigationContext(
    //   this.userService.getUserUiDto() ? ApplicationConstants.SUPPLIER : ApplicationConstants.LANDING,
    //   this.auctionEntityDto?.singleLot || this.auctionEntityDto?.noOfActiveLots == 1 ? ApplicationConstants.SINGLE_LOT : ApplicationConstants.MULTI_LOT,
    //   '',
    //   this.auctionEntityDto?.sequenceNo!.toString() ?? '',
    //   undefined
    // );

    let userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));

    const navigation = new NavigationEventsDto();
    navigation.contextName = userUiDto ? ApplicationConstants.SUPPLIER : ApplicationConstants.LANDING;
    navigation.pageName = this.auctionEntityDto?.singleLot || this.auctionEntityDto?.noOfActiveLots == 1 ? ApplicationConstants.SINGLE_LOT : ApplicationConstants.MULTI_LOT;
    navigation.resourceNo = this.auctionEntityDto?.sequenceNo!.toString() ?? '';

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));

    this.router.navigate(['/redirectToAuction'], { skipLocationChange: true });
  }



  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

}
