import { OpportunityTypeEnum } from "../../enums/OpportunityTypeEnum";
import { TenderStatus } from "../../enums/TenderStatus";
import { CacheUpdateTypes } from "../../util/CacheUpdateTypes";
import { EntityActionDto } from "../EntityActionDto";
import { FileInfoDto } from "../FileInfoDto";
import { TenderValidationErrorCodeDto } from "../tender/TenderValidationErrorCodeDto";
import { TenderDocumentInfoDto } from "../TenderDocumentInfoDto";
import { TenderOpportunityWrapperDto } from "./TenderOpportunityWrapperDto";
import { TenderWrapperUiDtoPageContext } from "./TenderWrapperUiDtoPageContext";

export class TenderWrapperUiDto {
    orgCode?: string;
    tenderStatus?: TenderStatus;
    tenderId?: string;
    tenderReferenceNo?: string;
    title?: string;
    timeZone?: string;
    opportunityType?: OpportunityTypeEnum;
    themeName?: string;
    description?: string;
    nitDocuments?: Array<TenderDocumentInfoDto>;
    tenderOpportunityWrapperDtos?: Array<TenderOpportunityWrapperDto>;
    version?: Number;
    entityActionDtos?: Array<EntityActionDto>;
    pageContext?: TenderWrapperUiDtoPageContext;
    cacheUpdateType?: string = CacheUpdateTypes.TENDER_WRAPPER;

    validationErrorCodeDtoList?: TenderValidationErrorCodeDto[];
}