import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { BidderDetailsInfoDto } from 'src/app/shared/models/user/BidderDetailsInfoDto';
import { UserMaxBidsDto } from 'src/app/shared/models/user/UserMaxBidsDto';
import { UserAuctionBidsHistoryDto } from 'src/app/shared/models/UserAuctionBidsHistoryDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { Store } from '@ngrx/store';
import { selectUserUiDto } from 'src/app/shared/state-management/session.features';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';

@Component({
  selector: 'app-bidder-details',
  templateUrl: './bidder-details.component.html',
  styleUrls: ['./bidder-details.component.sass']
})
export class BidderDetailsComponent implements OnInit {

  @Input() userAuctionBidHistoryDto?: UserAuctionBidsHistoryDto;
  bidderDetailsInfoDto?: BidderDetailsInfoDto;
  auctionLotEntityDto?: AuctionLotEntityDto;
  isLoading?: boolean = false;
  isMaxBidUpdated: boolean = false;
  auctionEntityDto?: AuctionEntityDto;
  userUiDto?: UserUiDto;
  auctionEntityDtoSubscription?: Subscription;

  maxBidModal?: NgbActiveModal;

  responseMessage?: string;
  responseValue?: string;
  isLoading$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _disableBidButton$ = new BehaviorSubject<boolean>(false);
  _showErrorButtonToast$ = new BehaviorSubject<boolean>(false);

  userInputMaxBid: FormControl = new FormControl('', Validators.required);

  constructor(
    public modalService: NgbModal,
    public adminService: AdminDashboardService,
    public datePipe: DatePipe,
    private lotService: AdminLotsDataHolderService,
    private activeModal: NgbActiveModal,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private store: Store
  ) {

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getUserUiDto()
    let userId = this.userAuctionBidHistoryDto?.userId;
    let auctionId = this.userAuctionBidHistoryDto?.auctionId;
    let auctionHouseId = this.userUiDto?.orgCode;
    let lotId = this.userAuctionBidHistoryDto?.lotId;
    let timeZone = this.userAuctionBidHistoryDto?.timeZone;
    this.adminService.fetchBidderDetailsData(userId!, auctionId!, auctionHouseId!, lotId!, timeZone!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.bidderDetailsInfoDto = apiResponseDto.data as BidderDetailsInfoDto;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      }
    })

    this.auctionEntityDtoSubscription = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(auction => {
      this.auctionEntityDto = auction!
    })

    this.auctionLotEntityDto = this.lotService.getAuctionLotEntityDtoById(this.userAuctionBidHistoryDto?.lotId!);
  }

  async getUserUiDto() {
    this.userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));
  }

  isReverseAuction() {
    let toReturn = false;
    if (this.auctionEntityDto?.auctionBiddingMethod == ApplicationConstants.REVERSE_AUCTION) {
      toReturn = true;
    }
    return toReturn;
  }

  getDisplayDate(date?: string) {
    if (date != null) {
      return ApplicationUtils.getDateInMMMdd(date!, this.datePipe);
    } else {
      return '';
    }
  }

  getRemainingTime() {
    if (this.auctionLotEntityDto?.endDate && this.auctionLotEntityDto?.endTime) {
      let convertedDate = this.auctionLotEntityDto?.endDate + " " + this.auctionLotEntityDto?.endTime;
      return ApplicationUtils.getRemainingTime(convertedDate!, this.auctionLotEntityDto?.timeZone!);
    }
    return 0;
  }

  openMaxBidModal(content: any) {
    this.maxBidModal = this.modalService.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false, centered: true
    });
  }

  formatCurrentMaxBid() {
    let maxBid = ApplicationUtils.resetPriceFormat(this.userInputMaxBid.value);
    this.userInputMaxBid.patchValue(ApplicationUtils.getFormattedPrice(this.userAuctionBidHistoryDto?.currency?.locale!, maxBid));
  }

  updateMaxBidPrice() {
    this.userInputMaxBid.patchValue(this.responseValue);
    this.userInputMaxBid.updateValueAndValidity();

    this._showErrorButtonToast$.next(false);
  }

  doMaxBid() {
    this._showErrorButtonToast$.next(false);
    if (this.userInputMaxBid.invalid) {
      return;
    }

    this.isLoading$.next(true);
    this._disableBidButton$.next(true);

    let userMaxBidDto = new UserMaxBidsDto();
    userMaxBidDto.userId = this.userAuctionBidHistoryDto!.userId;
    userMaxBidDto.userName = this.userAuctionBidHistoryDto!.name;
    userMaxBidDto.emailId = this.userAuctionBidHistoryDto!.companyEmailId;
    userMaxBidDto.auctionId = this.userAuctionBidHistoryDto?.auctionId;
    userMaxBidDto.auctionHouseId = this.userUiDto?.orgCode;
    userMaxBidDto.lotId = this.userAuctionBidHistoryDto?.lotId;
    userMaxBidDto.bucketSize = Number(ApplicationUtils.resetPriceFormat(this.userInputMaxBid.value));

    this.adminService.doMaxBids(userMaxBidDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        //console.log(apiResponseDto);

        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          // let auctionBidsDetails = apiResponseDto.data as AuctionBidsDetailsUIDto;
          let data = apiResponseDto.data as UserMaxBidsDto;

          this.bidderDetailsInfoDto!.lastMaxBidAmount = data.bucketSize;
          this.bidderDetailsInfoDto!.maxBidDate = this.datePipe.transform(data.updateTimeUtc, 'dd/MM/yyyy')!;
          this.bidderDetailsInfoDto!.maxBidTime = this.datePipe.transform(data.updateTimeUtc, 'h:mm:ss a')!;

          this.isMaxBidUpdated = true;

          // this.userAuctionBidHistoryDto!.highestMaxBid = auctionBidsDetails.userBucketSize!;
          // this.adminAuctionService.updateAdminBidderRegistrationWrapper(this.item?.userId!, auctionBidsDetails.userBucketSize!);
          // this.adminAuctionService.getUserBidsHistoryData();

          this.responseMessage = apiResponseDto.message;

          this._showSuccessToast$.next(true);
          this.hideToaster(false);
        } else {
          if (apiResponseDto && apiResponseDto.message) {
            this.responseMessage = apiResponseDto.message;
          } else {
            this.responseMessage = "Error while do Proxy bid!";
          }

          if (apiResponseDto.param1) {
            this.responseValue = ApplicationUtils.getFormattedPrice(this.userAuctionBidHistoryDto?.currency?.locale!, apiResponseDto.param1);
            this._showErrorButtonToast$.next(true);
          } else {
            this._showErrorToast$.next(true);
          }

          this.hideToaster(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this.responseMessage = "Error while do Proxy bid!";
        this.hideToaster(true);
      }
    })
  }

  // Remove Toaster
  hideToaster(isError: boolean) {
    this.isLoading$.next(false);
    setTimeout(() => {
      this._disableBidButton$.next(false);
      this._showSuccessToast$.next(false);
      this._showErrorToast$.next(false);
      if (!isError) this.userInputMaxBid.reset();
      if (!isError) this.closeMaxModal();
    }, 4000);
  }

  closeMaxModal() {
    this.maxBidModal?.close()
  }

  closeModal() {
    this.activeModal.close(this.isMaxBidUpdated);
  }

  getFormattedPrice(price: number) {
    return ApplicationUtils.getFormattedPrice(this.userAuctionBidHistoryDto?.currency?.locale, price);
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

}
