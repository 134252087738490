<div class="row justify-content-center">
  <div class="col-md-10 m-md-auto">
    <div class="row justify-content-center my-2">
      <div class=" col-lg-3  col-md-5   mb-2">
        <div class="btn btn-sm btn-outline-secondary  c-pointer registration-width-class me-md-3">
          <div class="m-1">
            <div class="text-start">
              <img src="assets/icons/bidder_lg_primary.svg" class=" c-pointer ms-2">
            </div>
            <div class=" bold-size-font-registration">
              {{countSummary('ALL')}}
            </div>
            <div class="text-center col-12 div-class-small">
              <b class="b-class">Registration</b>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-xxl-2 col-md-4 mb-2">
        <div class="btn btn-sm btn-outline-secondary  c-pointer registration-width-class me-md-3">
          <div class="m-1">
            <div class="text-start">
              <img src="assets/icons/bidder_lg_light.svg" class=" c-pointer  ms-2">
            </div>
            <div class=" bold-size-font-registration">
              {{countSummary('INCOMPLETE')}}
            </div>
            <div class="text-center col-12 div-class-small">
              <b>Incomplete</b>
            </div>
          </div>
        </div>
      </div> -->
      <div class=" col-lg-3  col-md-5   mb-2">
        <div class="btn btn-sm btn-outline-warning  c-pointer registration-width-class me-md-3">
          <div class="m-1">
            <div class="text-start">
              <img src="assets/icons/bidder_lg_awaitingapproval.svg" class=" c-pointer ms-2">
            </div>
            <div class=" bold-size-font-registration">
              {{countSummary('AWAITING_APPROVAL')}}
            </div>
            <div class="text-center col-12 div-class-small">
            <b class="b-class">  Awaiting Approval</b>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-2"></div>
      </div>
      <div class=" col-lg-3  col-md-5   mb-2">
        <div class="btn btn-sm btn-outline-danger  c-pointer registration-width-class me-md-3">
          <div class="m-1">
            <div class="text-start">
              <img src="assets/icons/bidder_lg_rejected.svg" class=" c-pointer  ms-2">
            </div>
            <div class=" bold-size-font-registration">
              {{countSummary('REJECTED_BIDDERS')}}
            </div>
            <div class="text-center col-12 div-class-small">
             <b class="b-class"> Rejected</b>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3  col-md-5   mb-2">
        <div class="btn btn-sm btn-outline-success  c-pointer registration-width-class me-md-3">
          <div class="m-1">
            <div class="text-start">
              <img src="assets/icons/bidder_lg_approved.svg" class=" c-pointer  ms-2">
            </div>
            <div class=" bold-size-font-registration">
              {{countSummary('APPROVED_BIDDERS')}}
            </div>
            <div class="text-center col-12 div-class-small">
              <b class="b-class">Approved</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
