import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {

  private _isDrawerOpen: boolean = false;
  isDrawerLoaded: boolean = false;
  isEditForm: boolean = false;
  drawerPageName!: string;
  drawerPageTab?: string;

  private _drawerOpened$ = new BehaviorSubject<boolean>(false);
  private _drawerPageName$ = new BehaviorSubject<string>('');

  constructor() { }

  get isDrawerOpen() { return this._isDrawerOpen }
  get checkDrawerOpened$() { return this._drawerOpened$.asObservable() }
  get drawerPageName$() { return this._drawerPageName$.asObservable() }

  loadDrawer() {
    setTimeout(() => {
      this.isDrawerLoaded = true;
    }, 1000)
  }

  public toggleDrawer() {
    this._isDrawerOpen = !this._isDrawerOpen;
    this._drawerOpened$.next(this._isDrawerOpen);

    if (!this._isDrawerOpen) {
      this.closeDrawer();
    }
  }

  public openDrawer(pageName: string, pageTab?: string) {
    this.drawerPageName = pageName;

    this.loadDrawer();

    if (pageTab) {
      this.drawerPageTab = pageTab;
    }
    
    this._isDrawerOpen = true;
    this._drawerOpened$.next(this._isDrawerOpen);
    this._drawerPageName$.next(this.drawerPageName);
  }

  public closeDrawer() {
    this._isDrawerOpen = false;
    this.drawerPageName = '';
    this.drawerPageTab = undefined;
    this.isEditForm = false;
    this.isDrawerLoaded = false;

    this._drawerPageName$.next(this.drawerPageName);
  }

  isFormEditMode(isEdit: boolean) {
    this.isEditForm = isEdit;
  }
}
