<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>VIEW MATA DATA</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <div class="row">
        <div class="col-6 col-md-3 mb-2">
          {{ getOnboardingWorkflow(approvalWorkflowUiDto.awfDefineUiDto?.wfCode!)}}
          <div class="small-font">
            Workflow Name
          </div>
        </div>
        <div class="col-6 col-md-3 mb-2">
          {{ approvalWorkflowUiDto.awfDefineUiDto?.companyCode}}
          <div class="small-font">
            Company Name
          </div>
        </div>

        <div class="col-6 col-md-3 mb-2">
          {{ approvalWorkflowUiDto.awfDefineUiDto?.plantCode}}
          <div class="small-font">
            Plant Name
          </div>
        </div>
        <div class="col-6 col-md-3 mb-2">
          {{ approvalWorkflowUiDto.awfDefineUiDto?.type}}
          <div class="small-font">
            Type
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="card bg-white ">
              <div class="py-2 bg-grey1 outline-border">
                <div class="row px-2">
                  <div class="">
                    <b>
                      {{selectedDesignationOne?.name}}
                    </b>
                  </div>
                </div>
                <hr class="hr my-1">
              </div>
              <div class="p-2">
                <div class="card p-2 bg-white">
                  <div class="row">
                    <div class="col-12 div-class">
                      <!-- {{ appliedUiDtos[0].name}} -->
                    </div>
                    <div class="col-12 small-font">
                      {{ appliedUiDtos![0].emailId}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" *ngIf="appliedUiDtos[1]">
            <div class="card bg-white ">
              <div class="py-2 bg-grey1 outline-border">
                <div class="row px-2">
                  <div class="">
                    <b>
                      {{selectedDesignationTwo?.name}}
                    </b>
                  </div>
                </div>
                <hr class="hr my-1">
              </div>
              <div class="p-2">
                <div class="card p-2 bg-white">
                  <div class="row">
                    <div class="col-12 div-class">
                      <!-- {{ appliedUiDtos[1].name}} -->
                    </div>
                    <div class="col-12 small-font">
                      {{ appliedUiDtos[1].emailId}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4" *ngIf="appliedUiDtos[2]">
            <div class="card bg-white ">
              <div class="py-2 bg-grey1 outline-border">
                <div class="row px-2">
                  <div class="">
                    <b>
                      {{selectedDesignationThree?.name}}
                    </b>
                  </div>
                </div>
                <hr class="hr my-1">
              </div>
              <div class="p-2">
                <div class="card p-2 bg-white">
                  <div class="row">
                    <div class="col-12 div-class">
                      <!-- {{ appliedUiDtos[2].name}} -->
                    </div>
                    <div class="col-12 small-font">
                      {{ appliedUiDtos[2].emailId}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>