export class AddUserDto {
  firstName? : string;
  lastName?: string;
  designation?: string;
  emailId?: string;
  parentUserId?: string;
  mobileNo?: string;
  password?: string;
  userType?: string;
  auctionHouseId?: string;
}
