import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TenderDocumentInfoDto } from 'src/app/shared/models/TenderDocumentInfoDto';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { TenderManagementService } from 'src/app/shared/services/tender-management.service';
import { selectTenderOpportunityWrapperDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-opportunity-fees',
  templateUrl: './opportunity-fees.component.html',
  styleUrls: ['./opportunity-fees.component.sass']
})
export class OpportunityFeesComponent implements OnInit, OnDestroy {
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto
  feeDocuments: Array<TenderDocumentInfoDto> = [];

  currentIndex: number = -1;
  isNavLoading: boolean = false;

  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  constructor(
    private store: Store,
    private drawerService: DrawerService,
    private tenderManagementService: TenderManagementService
  ) { }

  ngOnInit(): void {
    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);

        if (this.tenderOpportunityWrapperDto?.tenderFeeDetails) {
          this.feeDocuments = this.tenderOpportunityWrapperDto.tenderFeeDetails.feeDocuments || [];
        }
      } else {
        this.tenderOpportunityWrapperDto = undefined;
      }
    })
  }

  openEditOpportunityDrawer() {
    this.drawerService.isFormEditMode(true);
    this.drawerService.openDrawer(ApplicationConstants.NEW_OPPORTUNITY_PAGE, 'FEE');
  }

  previewDocument(feeDocument: TenderDocumentInfoDto, index: number) {
    this.currentIndex = index;
    this.isNavLoading = true;

    this.tenderManagementService.downloadTenderDocument(feeDocument?.fileId!).subscribe({
      next: (response) => {
        if (response) {
          let file = new Blob([response], { type: feeDocument?.fileType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        }
        
        this.isNavLoading = false;
        this.currentIndex = -1;
      },
      error: (err) => {
        console.error(err);
        console.log("Error while previewing document");
        
        this.isNavLoading = false;
        this.currentIndex = -1;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}
