<div class="model-body ">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>
          Technical Questionnaire
        </h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3 height-of-modal">
      <div class="row">
        <div class="col-md-4 border-end mb-3">
          <!-- <div *ngIf="isSelectOption">
            <div class="medium-font">
              Select Section
            </div>
            <div class="text-center py-1" [ngClass]="isTechnicalActive? 'active': ''">
              A. Section Title
            </div>
          </div> -->
          <div>
            <div class="col-12 text-end ">
              <a class="link-class text-end" (click)="openNewSectionModal()">Add Section</a>
            </div>
            <hr class="hr my-2">
            <div class="border p-2" [ngClass]="{'border-danger' : sectionScoreValidationFailed}">
              <div class=" row">
                <div class="medium-font col-6 ">
                  Name
                </div>
                <div class="text-center col-4"
                  *ngIf="this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES'">
                  Score
                </div>
                <div class="col-2 text-center medium-font">
                </div>
              </div>
              <div cdkDropList (cdkDropListDropped)="drop($event)">
                <div class="col-12 border mb-2 c-pointer" *ngFor="let item of sectionTemplateList"
                  [ngClass]="item.sectionId == selectedSectionTemplate?.sectionId ? 'active-border': ''"
                  (click)="changeSection(item)" cdkDrag cdkDragLockAxis="y">
                  <div class="row p-2 align-items-center">
                    <div class="medium-font"
                      [ngClass]="this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES' ? 'col-6' : 'col-10'">
                      {{item.preSequenceText}}. {{item.sectionName}}
                    </div>
                    <div class="text-center col-4 border-end"
                      *ngIf="this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES'">
                      {{item.sectionScore}}
                    </div>
                    <div class="col-2 text-center" cdkDragHandle>
                      <img src="assets/icons/reorder_light.svg" class="svg-icon-class-small c-pointer">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mt-2 text-end">
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class" (click)="addNewSection()">
              Add Section
            </button>
          </div> -->
          <!-- <div class="mt-5 text-center">
            <button class="btn btn-sm btn-grey-clr-class ">
              <span class="small-font text-white">
                Save as template
              </span>
            </button>
          </div> -->
        </div>
        <div class="col-md-8 mb-1">
          <div class="row mx-0">
            <div class="col-6">
              <div class="row">
                <div class="col-2 font-weight-normal py-2">
                  Name:
                </div>
                <div class="col-10 div-class py-2">
                  {{selectedSectionTemplate?.sectionName}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-2 font-weight-normal py-2"
                  *ngIf="this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES'">
                  Score:
                </div>
                <div class="col-10 div-class py-2"
                  *ngIf="this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES'">
                  {{selectedSectionTemplate?.sectionScore}}
                </div>
              </div>
            </div>
            <hr class="hr" />

            <div class="col-12 mt-2 text-end" *ngIf="selectedSectionTemplate?.sectionName">
              <span class="me-3">
                <img src="assets/icons/edit_light.svg" class="svg-icon-class c-pointer"
                  (click)="openNewSectionModal(true)">
              </span>
              <span>
                <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
                  (click)="openDeleteConfirmModal(deleteConfirmModal)">
              </span>
            </div>
          </div>


          <!-- <div class="col-md-6 text-md-end mb-2">
                  <span class="medium-font me-2">All Question View</span>
                  <span class="form-switch c-pointer">
                    <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
                  </span>
                </div>
              </div>
            </div>
          </div>-->
          <div>

            <div class="hr-class my-2"></div>
            <app-rfx-questionnaire-technical-question></app-rfx-questionnaire-technical-question>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #deleteConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>DELETE SECTION</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoadingForModal}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(deleteConfirmModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Are you sure you want to delete this section?</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToastForModal$ |async" [isError]="true"
          [message]="errorMsgForModal"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToastForModal$ |async" [isError]="false"
          message="Section deleted successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoadingForModal">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoadingForModal && !(_showSuccessToastForModal$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3"
          (click)="closeModal(deleteConfirmModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="deleteSectionTemplate()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<app-loader #appLoader></app-loader>


<ng-template #newSectionModalTemplate>
  <div class="model-body ">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>
            Technical Questionnaire
          </h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(addSectionModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3 height-of-modal">
        <div class="row mb-2">
          <div class="col-md-6 mb-2">
            <div class="input-group">
              <div class="form-floating">
                <input #title class=" form-control" [formControl]="ctrlSectionTitle" id="ctrlSectionTitle"
                  [ngClass]="{'border-danger': ctrlSectionTitle.invalid || (ctrlSectionTitle.invalid && title.focus)}">
                <label for="ctrlSectionTitle">Title</label>
              </div>
            </div>
            <div *ngIf="ctrlSectionTitle.invalid && (ctrlSectionTitle.dirty || ctrlSectionTitle.touched)">
              <p class="text-red-500 " *ngIf="ctrlSectionTitle.errors?.['required']">Required</p>
            </div>
          </div>
          <div class=" col-md-6 mb-2"
            *ngIf="this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES'">
            <div class="input-group">
              <div class="form-floating">
                <input #sectionScore class="form-control" [formControl]="ctrlSectionScore" id="ctrlSectionScore"
                  [ngClass]="{'border-danger': ctrlSectionScore.invalid}">
                <label for="ctrlSectionScore">Score</label>
              </div>
            </div>
            <div *ngIf="ctrlSectionScore.invalid && (ctrlSectionScore.dirty || ctrlSectionScore.touched)">
              <p class="text-red-500 " *ngIf="ctrlSectionScore.errors?.['required']">Required</p>
              <p class="text-red-500 " *ngIf="ctrlSectionScore.errors?.['pattern']">Invalid</p>
            </div>
          </div>
        </div>

        <div class="my-1">
          <app-message-toast *ngIf="_showErrorToastForModal$ |async" [isError]="true"
            [message]="errorMsg"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToastForModal$ |async" [isError]="false"
            [message]="'Section saved successfully.'"></app-message-toast>
        </div>

        <div class="d-grid gap-2 my-1" *ngIf="isLoadingForModal">
          <div class="e-spinner"></div>
        </div>

        <div class="col-12 mt-2 text-end">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            [ngClass]="{'disabled': selectedRfx?.status == 'LIVE' || selectedRfx?.status == 'LIVE_WAIT' || ctrlSectionTitle.invalid || ctrlSectionScore.invalid}"
            (click)="saveSectionTemplate()" *ngIf="!isLoadingForModal && !(_showSuccessToastForModal$ | async)">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>