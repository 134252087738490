import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { StreamingMessage } from '../../models/StreamingMessage';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from '../../models/user/AuctionLotEntityDto';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-supplier-floor-notification',
  templateUrl: './supplier-floor-notification.component.html',
  styleUrls: ['./supplier-floor-notification.component.sass']
})
export class SupplierFloorNotificationComponent implements OnInit, OnDestroy {
  @Input() lastMessage?: StreamingMessage;

  auctionEntityDto? : AuctionEntityDto;
  lotEntityDto?: AuctionLotEntityDto;

  selectedAuctionSubscription$?: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    // private landingService: LandingAndBidderService,
  ) {}

  ngOnInit(): void {
    // this.selectedAuctionSubscription$ = this.landingAndBidderService.getSelectedAuction$.subscribe((data) => {
    //   if (data) {
    //     this.auctionEntityDto = data;
    //   }
    // })

    // if (this.lastMessage?.lotId) {
    //   this.lotEntityDto = this.landingAndBidderService.getLotByLotId(this.lastMessage.lotId);
    // }
    // this.streamingMessageSubscription$ = this.streamService.getStreamingMessages$.subscribe( data => {
    //   if(data && data.length > 0){
    //     this.messagesList = data;

    //     // Get Last Message
    //     this.messagesList.sort((a, b) => +b.createdAt!.toDate() - +a.createdAt!.toDate());
    //     this.lastMessage = this.messagesList[0];

    //     // Get Auction Lot

    //   }
    // })

    setTimeout(() => {
      this.closeModal();
    }, 30000);
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {

    if(this.selectedAuctionSubscription$){
      this.selectedAuctionSubscription$.unsubscribe();
    }
  }
}
