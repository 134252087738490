<div id="pdfContent" class="container px-5" style="width: 294mm;">
  <div class="row">
    <div class="row my-2">
      <div class="col-md-2 col-3">
        <img src="/downloadLandingBlob?fileId={{auctionReportWrapper?.companyDetailsDto?.companyLogo?.fileId}}"
          class="user-profile-image border" alt="">
      </div>
      <div class="col-md-4 col-9">
        <h5 class="heading-h5 text-uppercase"> {{auctionReportWrapper?.companyDetailsDto?.companyName}}</h5>
      </div>
      <div class="col-md-6 text-md-end div-class">
        {{auctionReportWrapper?.companyDetailsDto?.address?.addressLine1}},
        {{auctionReportWrapper?.companyDetailsDto?.address?.addressLine2 ?
        auctionReportWrapper?.companyDetailsDto?.address?.addressLine2 + ', ' : ''}}<br>
        {{auctionReportWrapper?.companyDetailsDto?.address?.city}},<br>
        {{auctionReportWrapper?.companyDetailsDto?.address?.state}},
        {{auctionReportWrapper?.companyDetailsDto?.address?.country}} -
        {{auctionReportWrapper?.companyDetailsDto?.address?.zipCode}}
      </div>
    </div>
    <hr class="hr my-3">
    <h5 class=" heading-h5 text-center col-12 mb-2">Auction Details</h5>
    <div class="row justify-content-between mt-2">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-5 small mb-2">
            Auction ID
          </div>
          <div class="col-md-7 small mb-2">
            {{auctionReportWrapper?.auctionIndex}}
          </div>
          <div class="col-md-5 small mb-2">
            Auction Name
          </div>
          <div class="col-md-7 small mb-2">
            {{auctionReportWrapper?.auctionName}}
          </div>
          <div class="col-md-5 small mb-2">
            Owner Name
          </div>
          <div class="col-md-7 small mb-2">
            {{auctionReportWrapper?.listingContactName}}
          </div>
          <div class="col-md-5 small mb-2">
            Auction Type
          </div>
          <div class="col-md-7 small mb-2">
            {{auctionReportWrapper?.auctionBiddingMethod == "REVERSE_AUCTION" ? 'Reverse Auction' : 'Forward Auction'}}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-5 small mb-2">
            No Of Lots
          </div>
          <div class="col-md-7 small mb-2">
            {{auctionReportWrapper?.noOfLots}}
          </div>
          <div class="col-md-5 small mb-2">
            Start Date Of Auction
          </div>
          <div class="col-md-7 small mb-2">
            {{auctionReportWrapper?.startDate}}
          </div>
          <div class="col-md-5 small mb-2">
            Currency
          </div>
          <div class="col-md-7 small mb-2">
            {{auctionReportWrapper?.currency?.symbol}}
          </div>
        </div>
      </div>
    </div>

    <h5 class=" heading-h5 text-center col-12 mb-2">Lot Details</h5>
    <hr class="hr my-3">
    <div class="mt-2" *ngFor="let lotWrapperReportDto of auctionReportWrapper?.lotWrapperReportDtoList; index as i">
      <div class="row justify-content-between mt-3" [ngClass]="{'page-break' : i > 0}">
        <div class="col-md-6">

          <div class="row">
            <div class="col-md-5 small mb-2">
              Lot ID
            </div>
            <div class="col-md-7 small mb-2">
              {{lotWrapperReportDto.lotIndex}}
            </div>
            <div class="col-md-5 small mb-2">
              Lot Name
            </div>
            <div class="col-md-7 small mb-2">
              {{lotWrapperReportDto.lotName}}
            </div>
            <div class="col-md-5 small mb-2">
              Starting Bid Price
            </div>
            <div class="col-md-7 small mb-2">
              {{getFormattedPrice(auctionReportWrapper?.currency!, lotWrapperReportDto.startingBidPrice)}}
            </div>
            <div class="col-md-5 small mb-2" *ngIf="lotWrapperReportDto.reservePrice! > 0">
              Reserve Price
            </div>
            <div class="col-md-7 small mb-2" *ngIf="lotWrapperReportDto.reservePrice! > 0">
              {{getFormattedPrice(auctionReportWrapper?.currency!, lotWrapperReportDto.reservePrice)}}
            </div>
            <div class="col-md-5 small mb-2">
              End Date Of Lot
            </div>
            <div class="col-md-7 small mb-2">
              {{lotWrapperReportDto?.endDate}}
            </div>

          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5 small mb-2">
              Current Price
            </div>
            <div class="col-md-7 small mb-2">

              {{getFormattedPrice(auctionReportWrapper?.currency!, lotWrapperReportDto.currentBidPrice)}}
            </div>
            <div class="col-md-5 small mb-2">
              Saving Incurred
            </div>
            <div class="col-md-7 small mb-2">

              {{getFormattedPrice(auctionReportWrapper?.currency!, lotWrapperReportDto.savingIncurredValue)}}
            </div>
            <div class="col-md-5 small mb-2">
              Saving Incurred %
            </div>
            <div class="col-md-7 small mb-2">
              {{lotWrapperReportDto.savingIncurredPercent}}
            </div>
            <div class="col-md-5 small mb-2">
              Lot Time
            </div>
            <div class="col-md-7 small mb-2">
              {{lotWrapperReportDto?.liveTime}}
            </div>
          </div>
        </div>
      </div>
      <hr class="hr my-2">

      <h5 class=" heading-h5 text-center mb-2 page-break avoidPageBreak">Ranking</h5>
      <div class="row border p-2 me-2 mt-2 avoidPageBreak">
        <div class="font-weight-bold col-1">Rank </div>
        <div class="font-weight-bold col-2">Company</div>
        <div class="font-weight-bold col-2">Supplier</div>
        <!-- <div class="font-weight-bold col-1">Paddle No</div> -->
        <div class="font-weight-bold col-1">Contact Number</div>
        <div class="font-weight-bold col-2">Supplier Email</div>
        <!-- <div class="font-weight-bold col-1">Company Name</div> -->
        <div class="font-weight-bold col-1">No of Bids Submitted </div>
        <!-- <div class="font-weight-bold col-1" *ngIf="lotWrapperReportDto.reservePrice! > 0 ">Reserve price </div>
        <div class="font-weight-bold col-1">Starting price</div> -->
        <div class="font-weight-bold col-1">Last Bid Value</div>
        <div class="font-weight-bold col-1">Saving Value</div>
        <div class="font-weight-bold col-1">Saving %</div>
      </div>
      <div class="row border p-2 me-2 mt-2 avoidPageBreak"
        *ngFor="let item of lotWrapperReportDto.supplierRankWrapperDtoList">
        <div class="col-1 small" scope="row">{{item?.rankIndex}}</div>
        <div class="col-2 small text-break">{{item?.companyName}}</div>
        <div class="col-2 small text-break">{{item?.supplierName}}</div>
        <!-- <div class="col-1">{{item?.paddleNo}}</div> -->
        <div class="col-1 small">{{item?.contactNo}}</div>
        <div class="col-2 small text-break">{{item?.supplierEmailId}}</div>
        <!-- <div class="col-1">{{item?.companyName}}</div> -->
        <div class="col-1 small">{{item?.noOfBids}}</div>
        <!-- <div class="col-1"*ngIf="lotWrapperReportDto.reservePrice! > 0 "> -->
        <!-- {{getFormattedPrice(selectedAuction.currency!, lotWrapperReportDto.reservePrice)}}</div>
        <div class="col-1"> {{getFormattedPrice(selectedAuction.currency!, lotWrapperReportDto.startingBidPrice)}}</div> -->
        <div class="col-1 small">{{getFormattedPrice(auctionReportWrapper?.currency!, item?.lastBidPrice)}}</div>
        <div class="col-1 small">{{getFormattedPrice(auctionReportWrapper?.currency!, item?.savingValue)}}</div>
        <div class="col-1 small">{{item?.savingPercent}}</div>
      </div>

      <hr class="hr my-2">

      <h5 class=" heading-h5 text-center mb-2 page-break avoidPageBreak">Bidding History</h5>

      <div class="row border p-2 me-2 mt-2 avoidPageBreak">
        <!-- <div class="font-weight-bold col-1">Supplier Name</div> -->
        <!-- <div class="font-weight-bold col-1"> Paddle No</div> -->
        <div class="font-weight-bold col-2">Company Name</div>
        <!-- <div class="font-weight-bold col-1"*ngIf="lotWrapperReportDto.reservePrice! > 0 ">Reserve price</div> -->
        <!-- <div class="font-weight-bold col-1">Starting price</div> -->
        <div class="font-weight-bold col-1">Bid Value</div>
        <div class="font-weight-bold col-2">Bid Date and Time</div>
        <div class="font-weight-bold col-2">Last Bid of Supplier</div>
        <div class="font-weight-bold col-1">Lot Last Bid</div>
        <div class="font-weight-bold col-2">{{getReductionOrIncreaseMsg(auctionReportWrapper?.auctionBiddingMethod!)}}
          Based on Starting Price</div>
        <div class="font-weight-bold col-2">{{getReductionOrIncreaseMsg(auctionReportWrapper?.auctionBiddingMethod!)}}
          Based on Starting Price % </div>
        <!-- <div class="font-weight-bold col-1">{{getReductionOrIncreaseMsg(auctionReportWrapper?.auctionBiddingMethod!)}} Cumulative</div>
        <div class="font-weight-bold col-1">{{getReductionOrIncreaseMsg(auctionReportWrapper?.auctionBiddingMethod!)}} Cumulative % </div> -->
      </div>
      <div class="row border p-2 me-2 mt-2 avoidPageBreak"
        *ngFor="let item of lotWrapperReportDto.biddingWrapperDtoList">
        <!-- <div class="col-1 text-break">{{item?.supplierName}}</div> -->
        <!-- <div class="col-1">{{item?.paddleNo}}</div> -->
        <div class="col-2 small">{{item?.companyName}}</div>
        <!-- <div class="col-1" *ngIf="lotWrapperReportDto.reservePrice! > 0 "> -->
        <!-- {{getFormattedPrice(selectedAuction.currency!, lotWrapperReportDto.reservePrice)}}</div> -->
        <!-- <div class="col-1" scope="row">{{getFormattedPrice(selectedAuction.currency!, lotWrapperReportDto.startingBidPrice)}}</div> -->
        <div class="col-1 small">{{getFormattedPrice(auctionReportWrapper?.currency!, item?.bidValue)}}</div>
        <div class="col-2 small">{{item?.bidDateAndTime}}</div>
        <div class="col-2 small">{{item?.lastBidOfSupplier? 'YES' : 'NO'}}</div>
        <div class="col-1 small">{{item?.lotLastBid? 'YES' : 'NO'}}</div>
        <div class="col-2 small">{{getFormattedPrice(auctionReportWrapper?.currency!,
          item?.reductionOrIncreaseBasedOnStartingPrice)}}</div>
        <div class="col-2 small">{{item?.reductionOrIncreaseBasedOnStartingPricePercent}}</div>
        <!-- <div class="col-1">{{getFormattedPrice(selectedAuction.currency!, item?.reductionOrIncreaseCumulative)}}</div>
        <div class="col-1">{{item?.reductionOrIncreaseCumulativePercent}}</div> -->
      </div>

      <div class="page-break"></div>
      <div #eCharts id="echarts" class="bg-white" echarts [options]="chartOption[lotWrapperReportDto.lotIndex!]"
        *ngIf="chartOption[lotWrapperReportDto.lotIndex!] && lotWrapperReportDto.biddingWrapperDtoList?.length">
      </div>

    </div>
  </div>

</div>