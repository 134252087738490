<div class=" col-12 p-2">
  <div class=" bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
    <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
      <div class="grid grid-cols-3 gap-4 w-full">
        <div class="col-span-2 text-gray-500 font-semibold leading-4 tracking-tight text-base">
          <span class="">
            <img src="/assets/icons/tr-basic-detail.svg" class="tr-md-icon inline-flex">
          </span>
          Opportunity Details
        </div>

        <div class="text-end  text-sm font-medium leading-4 text-blue-100 tracking-tight ">
          <span class="c-pointer" (click)="openEditOpportunityDrawer()">
            <i-lucide name="pencil" [size]="15" class="c-pointer my-icon inline-flex"></i-lucide>
            Edit
          </span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full p-4">
      <div class="">
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Title
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.title}}
          </div>
        </div>
       

        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
           Account Head
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.accountHead | titleCase}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Category
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.category}} 
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
           Form of contract
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.formOfContract | titleCase}}
            
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Description
          </div>
          <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
            <p class="p-class animate-show-more" #descriptionRef
            [innerHTML]="tenderOpportunityWrapperDto?.description ?? '' | safe: 'html'"
              [ngClass]="{'hide-description': !isShowDescription}"></p>
            <div class="bold-border my-2" *ngIf="descriptionRef.scrollHeight > 120"></div>
            <div class="col-12 text-end ">
              <span class="btn-link" (click)="showOrHideDescription()" *ngIf="descriptionRef.scrollHeight > 120">
                {{isShowDescription ? 'HIDE MORE' : 'SHOW MORE'}}
              </span>
            </div>
          </div>

        </div>
      </div>
      <div class="">
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Tender Type
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.tenderType | titleCase}}
          </div>
        </div>
        
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Tender Category
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.tenderCategory | titleCase}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Sub Category
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.subcategory}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Tender Opening
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.tenderOpeningType}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Contract Type
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.tenderContractType | titleCase}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
