import { TenderFeePaymentMode } from "../../enums/TenderFeePaymentMode";
import { TenderOfflineFeePaymentMode } from "../../enums/TenderOfflineFeePaymentMode";
import { TenderDocumentInfoDto } from "../TenderDocumentInfoDto";

export class TenderFeeDetails {
    tenderFeePaymentMode?: TenderFeePaymentMode;
    tenderOfflineFeePaymentMode?: TenderOfflineFeePaymentMode;
    tenderFee?: number;
    processingFee?: number;
    surcharges?: number;
    otherCharges?: number;
    tenderFeePayableTo?: string;
    tenderFeePayableAt?: string;
    emdAmount?: number;
    allowEmdExemption?: boolean;
    emdFeePayableTo?: string;
    emdFeePayableAt?: string;
    feeDocuments?: Array<TenderDocumentInfoDto>;
    offlinePaymentOptions?: Array<string>;

}