import { DeploymentConfigurationDto } from './../models/user/DeploymentConfigurationDto';
import { AdminDataUIWrapperDto } from './../models/user/AdminDataUIWrapperDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, firstValueFrom, Observable, tap } from "rxjs";
import { ServerAPIResponseDto } from "../models/ServerAPIResponseDto";
import { AddUserDto } from "../models/user/AddAdminDto";
import { ErrorService } from "./error.service";
import { AuctionEntityDto } from '../models/user/AuctionEntityDto';
import { DocumentData } from '@angular/fire/firestore';
import { AuctionHouseDto } from '../models/user/AuctionHouseDto';
import { AdminAuctionBidderRegistrationDto } from '../models/user/AdminAuctionBidderRegistrationDto ';
import { UserMaxBidsDto } from '../models/user/UserMaxBidsDto';
import { AuctionLotEntityDto } from '../models/user/AuctionLotEntityDto';
import { AuctionEnableWrapperDto } from '../models/AuctionEnableWrapperDto';
import { UserAuctionRegistrationDto } from '../models/user/UserAuctionRegistrationDto';
import { AuctionQuestionDto } from '../models/user/AuctionQuestionDto';
import { RequestGraphChart } from '../models/RequestGraphChart';
import { InvoiceRequestDto } from '../models/InvoiceRequestDto';
import { MasterDataDto } from '../models/MasterDataDto';
import { LotDetailsWrapperDto } from '../models/LotDetailsWrapperDto';
import { ClientSubscriptionService } from './client-subscription.service';
import { BidderDto } from '../models/BidderDto';
import { RfxUiDto } from '../models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from '../models/rfx/RfxSubcategoryUiDto';
import { RfxEnableWrapperDto } from '../models/rfx/RfxEnableWrapperDto';
import { CatalogueL1EntityDto } from '../models/CatalogueL1EntityDto';
import { CatalogueL2EntityDto } from '../models/CatalogueL2EntityDto';
import { SubcategorySubmissionDto } from '../models/rfx/SubcategorySubmissionDto';
import { RfxEvaluationTechnicalScoresWrapperDto } from '../models/rfx/RfxEvaluationTechnicalScoresWrapperDto';
import { RfxUserQuestionsEvaluationWrapperDto } from '../models/rfx/RfxUserQuestionsEvaluationWrapperDto';
import { RFXEvaluationUserQuestionResponseWrapperDto } from '../models/rfx/RFXEvaluationUserQuestionResponseWrapperDto';
import { CompFinancialWrapperDto } from '../models/rfx/CompFinancialWrapperDto';
import { CompFinancialQuestionWrapperDto } from '../models/rfx/CompFinancialQuestionWrapperDto';
import { CompNormalizeItemsWrapperDto } from '../models/rfx/CompNormalizeItemsWrapperDto';
import { ApplicationNotificationEntityDto } from '../models/ApplicationNotificationEntityDto';
import { CatalogueLineItemsEntityDto } from '../models/CatalogueLineItemsEntityDto';
import { TimeZoneDto } from '../models/TimeZoneDto';
import { ComparisonSheetWrapperDto } from '../models/rfx/ComparisonSheetWrapperDto';
import { RfxPurchaseOrderDto } from '../models/rfx/RfxPurchaseOrderDto';
import { RfxPurchaseInvoiceDto } from '../models/rfx/RfxPurchaseInvoiceDto';
import { UserUiDto } from '../models/user/UserUiDto';
import { AppWorkflowUiDto } from '../models/AppWorkflowUiDto';
import { MaterialUiDto } from '../models/MaterialUiDto';

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardService {

  currentNotificationPage: number = 0;
  notificationPageSize: number = 20;

  _adminDashboardData$ = new BehaviorSubject<AdminDataUIWrapperDto | null>(null);
  _adminCurrentViewTabName$ = new BehaviorSubject<string>('AUCTIONS_ADMIN')
  _adminAuctionsList$ = new BehaviorSubject<Array<DocumentData>>([]);
  _currentAuctionHouse$ = new BehaviorSubject<AuctionHouseDto | null>(null);
  _auctionFeatures$ = new BehaviorSubject<any | null>(null);
  _masterTimezones$ = new BehaviorSubject<any | null>(null);
  _masterCategories$ = new BehaviorSubject<any | null>(null);

  public _bidderLoadDataEvent$ = new BehaviorSubject<boolean>(false);

  _notAwardedAuctionNotification$ = new BehaviorSubject<AuctionEntityDto | null>(null);

  _auctionBidderRegistrationDtos$ = new BehaviorSubject<AdminAuctionBidderRegistrationDto[]>([]);
  _deploymentConfiguration$ = new BehaviorSubject<DeploymentConfigurationDto | null>(null);

  _organizationDataLoaded$ = new BehaviorSubject<boolean>(false);
  // _allAuctionDataLoaded$ = new BehaviorSubject<boolean>(false);
  _allLotsDataLoaded$ = new BehaviorSubject<boolean>(false);
  _masterDataLoaded$ = new BehaviorSubject<boolean>(false);
  _isShowAuctionsListView$ = new BehaviorSubject<boolean>(false);

  _allUserAuctionRegistrationsList$ = new BehaviorSubject<Array<UserAuctionRegistrationDto>>([]);
  auctionQuestionsList$ = new BehaviorSubject<AuctionQuestionDto[]>([]);
  private _subcategorySubmissionList$ = new BehaviorSubject<SubcategorySubmissionDto[]>([]);
  private _userSubcategorySubmissionWrapper$ = new BehaviorSubject<RfxEvaluationTechnicalScoresWrapperDto | undefined>(undefined);
  private _userSubmissionQuestionsWrapper$ = new BehaviorSubject<RfxUserQuestionsEvaluationWrapperDto | undefined>(undefined);
  private _isTechnicalEvaluationPending$ = new BehaviorSubject<boolean>(false);
  private _comFinancialWrapperDtos$ = new BehaviorSubject<CompFinancialWrapperDto[]>([]);
  private _compFinancialQuestionWrapperDto$ = new BehaviorSubject<CompFinancialQuestionWrapperDto | undefined>(undefined);
  private _compNormalizeItemsWrapperDto$ = new BehaviorSubject<CompNormalizeItemsWrapperDto | undefined>(undefined);
  private _applicationNotificationsList$ = new BehaviorSubject<ApplicationNotificationEntityDto[]>([]);
  private _selectedApplicationNotificationDto$ = new BehaviorSubject<ApplicationNotificationEntityDto | undefined>(undefined);
  private _comparisonSheetWrapperDto$ = new BehaviorSubject<ComparisonSheetWrapperDto | undefined>(undefined);
  private _materialUiDtos$ = new BehaviorSubject<MaterialUiDto[]>([]);
  private _selectedMaterialUiDto$ = new BehaviorSubject<MaterialUiDto | undefined>(undefined);

  _notificationPreset$ = new BehaviorSubject<string>("");
  _bidderCount$ = new BehaviorSubject<number>(0);

  _allRfxSubcategoryDataLoaded$ = new BehaviorSubject<boolean>(false);

  get getOrganizationDataLoaded$() { return this._organizationDataLoaded$.asObservable(); }
  // get getAllAuctionDataLoaded$() { return this._allAuctionDataLoaded$.asObservable(); }
  get getAllLotsDataLoaded$() { return this._allLotsDataLoaded$.asObservable(); }
  get getMasterDataLoaded$() { return this._masterDataLoaded$.asObservable(); }
  get getAllRfxSubcategoryDataLoaded$() { return this._allRfxSubcategoryDataLoaded$.asObservable(); }

  get getDeploymentConfiguration$() { return this._deploymentConfiguration$.asObservable() }
  get getAdminDashboardData$() { return this._adminDashboardData$; };
  get getAdminCurrentView$() { return this._adminCurrentViewTabName$; };
  get getCurrentAuctionHouse$() { return this._currentAuctionHouse$; };
  get getAuctionFeatures$() { return this._auctionFeatures$ };
  get getMasterTimezones$() { return this._masterTimezones$ };
  get getMasterCategories$() { return this._masterCategories$ };
  get getAuctionBidderRegistrationDtos$() { return this._auctionBidderRegistrationDtos$ };
  get getAllUserAuctionRegistrationsList$() { return this._allUserAuctionRegistrationsList$ };
  get getAuctionQuestionsList$() { return this.auctionQuestionsList$; };
  get getNotificationPreset$() { return this._notificationPreset$; };
  get getSubcategorySubmissionList$() { return this._subcategorySubmissionList$; };
  get getUserSubcategorySubmissionWrapper$() { return this._userSubcategorySubmissionWrapper$; };
  get getUserSubmissionQuestionsWrapper$() { return this._userSubmissionQuestionsWrapper$; };
  get getTechnicalEvaluationStatus$() { return this._isTechnicalEvaluationPending$; };
  get getComFinancialWrapperDtos$() { return this._comFinancialWrapperDtos$; };
  get getCompFinancialQuestionWrapperDto$() { return this._compFinancialQuestionWrapperDto$; };
  get getCompNormalizeItemsWrapperDto$() { return this._compNormalizeItemsWrapperDto$; };
  get getApplicationNotificationsList$() { return this._applicationNotificationsList$.asObservable() }
  get getSelectedApplicationNotificationDto$() { return this._selectedApplicationNotificationDto$; };
  get getComparisonSheetWrapperDto$() { return this._comparisonSheetWrapperDto$; };
  get getMaterialUiDtos$() { return this._materialUiDtos$; };
  get getSelectedMaterialUiDto$() { return this._selectedMaterialUiDto$; };

  constructor(
    private clientSubscriptionService: ClientSubscriptionService,
    private http: HttpClient,
    private errorService: ErrorService,
  ) {

  }

  setOrganizationDataLoaded(value: boolean) {
    this._organizationDataLoaded$.next(value);
  }

  setNotificationPreset(notificationPreset: string) {
    this._notificationPreset$.next(notificationPreset);
  }

  // setAllAuctionDataLoaded(value: boolean) {
  //   this._allAuctionDataLoaded$.next(value);
  // }

  loadGlobalBiddersCount() {
    this.fetchBidderCount().subscribe(apiResponseDto => {
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let count = apiResponseDto.data as number;
        this.setGlobalBidderCount(count);
      }
    })
  }

  setAllLotsDataLoaded(value: boolean) {
    this._allLotsDataLoaded$.next(value);
  }

  setAllRfxSubcategoryDataLoaded(value: boolean) {
    this._allRfxSubcategoryDataLoaded$.next(value);
  }

  setGlobalBidderCount(value: number) {
    this._bidderCount$.next(value);
  }

  setMasterDataLoaded(value: boolean) {
    this._masterDataLoaded$.next(value);
  }

  setSubcategorySubmissionList(subcategorySubmissionDtos: SubcategorySubmissionDto[]) {
    this._subcategorySubmissionList$.next(subcategorySubmissionDtos);
  }

  setComparisonSheetWrapperDto(comparisonSheetWrapperDto?: ComparisonSheetWrapperDto) {
    this._comparisonSheetWrapperDto$.next(comparisonSheetWrapperDto);
  }

  updateDeploymentConfiguration(deploymentConfigurationDto: DeploymentConfigurationDto) {
    this._deploymentConfiguration$.next(deploymentConfigurationDto)
  };

  updateCurrentView(newView: string) {
    this._adminCurrentViewTabName$.next(newView);
  }

  updateAuctionsList(auctionsList: Array<DocumentData>) {
    this._adminAuctionsList$.next(auctionsList);
  }

  updateCurrentAuctionHouse(doc: DocumentData) {
    let auctionHouse = doc as AuctionHouseDto
    this._currentAuctionHouse$.next(auctionHouse);
  }

  isShowAuctionsListView(value: boolean) {
    this._isShowAuctionsListView$.next(value);
  }

  updateAllUserAuctionRegistrationsList(userAuctionRegistrations: UserAuctionRegistrationDto[]) {
    this._allUserAuctionRegistrationsList$.next(userAuctionRegistrations);
  }

  updateTimezonesData(timezones: TimeZoneDto[]) {
    this._masterTimezones$.next(timezones);
  }

  updateCategoriesData(categories: any) {
    this._masterCategories$.next(categories);
  }

  setCurrentMaterialUiDto(materialUiDto: MaterialUiDto) {
    this._selectedMaterialUiDto$.next(materialUiDto);
  }

  updateMasterData(masterData: MasterDataDto) {
    this._auctionFeatures$.next(masterData.auctionFeatures);
    this._masterTimezones$.next(masterData.timezones);

    let activeCategories = masterData.categories.filter(item => Boolean(item.active) == true);
    this._masterCategories$.next(activeCategories);

    // data.forEach(item => {
    //   for (let key in item) {
    //     if (key == 'auction_features') {
    //       this._auctionFeatures$.next(item[key]);
    //     } else if (key == 'currencies') {
    //       this._auctionCurrencies$.next(item[key]);
    //     } else if (key == 'timezones') {
    //       this._auctionTimezones$.next(item[key]);
    //     } else if (key == 'categories') {
    //       let activeCategories = item['categories'].filter((item: { active: boolean; }) => item.active == true);
    //       this._auctionCategories$.next(activeCategories);
    //     }
    //   }
    // })
  }

  async getAndLoadSubcategorySubmissions(rfxId: string) {
    this.setSubcategorySubmissionList([]);
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getSubcategorySubmissions(rfxId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          let data = apiResponseDto.data as SubcategorySubmissionDto[]
          this.setSubcategorySubmissionList(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAndLoadComparisonSheets(rfxId: string, rfxSubcategoryId: string) {
    this.setComparisonSheetWrapperDto(undefined);
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getComparisonSheets(rfxId, rfxSubcategoryId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          let data = apiResponseDto.data as ComparisonSheetWrapperDto
          this.setComparisonSheetWrapperDto(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAndLoadUserSubcategorySubmissions(rfxId: string, rfxSubcategoryId: string) {
    this._userSubcategorySubmissionWrapper$.next(undefined);
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getUserSubcategorySubmissions(rfxId, rfxSubcategoryId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          let data = apiResponseDto.data as RfxEvaluationTechnicalScoresWrapperDto
          this.updateRfxUserSubcategoryEvaluationWrapper(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAndLoadSubcategorySubmissionQuestions(rfxId: string, rfxSubcategoryId: string, userId: string) {
    this._userSubmissionQuestionsWrapper$.next(undefined);
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getSubcategorySubmissionQuestions(rfxId, rfxSubcategoryId, userId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          let data = apiResponseDto.data as RfxUserQuestionsEvaluationWrapperDto
          this.updateRfxUserQuestionsEvaluationWrapper(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAndLoadComFinancialWrapperDtosSync(rfxId: string, rfxSubcategoryId: string) {
    this._comFinancialWrapperDtos$.next([]);

    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getCompFinancialWrappers(rfxId, rfxSubcategoryId));

      if (apiResponseDto) {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          if (apiResponseDto.data) {
            let data = apiResponseDto.data as CompFinancialWrapperDto[];
            this.updateComFinancialWrapperDtos(data);
            this._isTechnicalEvaluationPending$.next(false);
          }
        }
        if (apiResponseDto.code == 'RFX-ERROR-107') {
          this._isTechnicalEvaluationPending$.next(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAndLoadCompFinancialQuestionWrapperDtoSync(rfxId: string, rfxSubcategoryId: string, companyId: string) {
    this._compFinancialQuestionWrapperDto$.next(undefined);

    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getCompFinancialsQuestionsWrapper(rfxId, rfxSubcategoryId, companyId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          let data = apiResponseDto.data as CompFinancialQuestionWrapperDto
          this.updateCompFinancialQuestionWrapperDto(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAndLoadCompNormalizeItemsWrapperDtoSync(rfxId: string, rfxSubcategoryId: string, companyId: string) {
    this._compNormalizeItemsWrapperDto$.next(undefined);

    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getCompNormalizeItemsWrapperDto(rfxId, rfxSubcategoryId, companyId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          let data = apiResponseDto.data as CompNormalizeItemsWrapperDto
          this.updateCompNormalizeItemsWrapperDto(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadApplicationNotifications(userId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getApplicationNotifications(userId, this.currentNotificationPage, this.notificationPageSize));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let notifications = apiResponseDto.data as ApplicationNotificationEntityDto[];
        this.updateApplicationNotificationDtos(notifications);
      }
    } catch (error) {
      console.error(error);
    }
  }

  updateApplicationNotificationDtos(notifications: ApplicationNotificationEntityDto[]) {
    let allNotifications = this._applicationNotificationsList$.value;
    allNotifications.push(...notifications);
    this._applicationNotificationsList$.next(allNotifications);
    this.currentNotificationPage = this.currentNotificationPage + 1;
  }

  setSelectedApplicationNotificationDto(data: ApplicationNotificationEntityDto) {
    this._selectedApplicationNotificationDto$.next(data);
  }

  updateRfxUserSubcategoryEvaluationWrapper(data: RfxEvaluationTechnicalScoresWrapperDto) {
    this._userSubcategorySubmissionWrapper$.next(data);
  }

  updateRfxUserQuestionsEvaluationWrapper(data: RfxUserQuestionsEvaluationWrapperDto) {
    this._userSubmissionQuestionsWrapper$.next(data);

    if (data.userRfxSubcategoryEntityDto) {
      let rfxSubcategoryId = data.userRfxSubcategoryEntityDto.subcategoryId;
      let userId = data.userRfxSubcategoryEntityDto.companyId;

      // Update Rfx User Subcategory Evaluation Wrapper
      let rfxUserSubcategoryEvaluationWrapper = this._userSubcategorySubmissionWrapper$.value;
      let evaluationUserRfxSubcategoryEntityDtoList = rfxUserSubcategoryEvaluationWrapper!.evaluationUserRfxSubcategoryEntityDtoList ?? [];
      let subcategoryIndex = evaluationUserRfxSubcategoryEntityDtoList.findIndex(item => item.subcategoryId == rfxSubcategoryId && item.companyId == userId);
      if (subcategoryIndex > -1) {
        Object.assign(evaluationUserRfxSubcategoryEntityDtoList[subcategoryIndex], data.userRfxSubcategoryEntityDto);
      } else {
        evaluationUserRfxSubcategoryEntityDtoList.push(data.userRfxSubcategoryEntityDto);
      }

      rfxUserSubcategoryEvaluationWrapper!.evaluationUserRfxSubcategoryEntityDtoList = evaluationUserRfxSubcategoryEntityDtoList;

      this.updateRfxUserSubcategoryEvaluationWrapper(rfxUserSubcategoryEvaluationWrapper!);
    }
  }

  updateRfxEvaluationUserSubcategoryAndQuestion(rfxEvaluationWrapper: RFXEvaluationUserQuestionResponseWrapperDto,) {
    let evaluationUserRfxQuestionsEntityDto = rfxEvaluationWrapper.evaluationUserRfxQuestionsEntityDto;
    let evaluationUserRfxSubcategoryEntityDto = rfxEvaluationWrapper.evaluationUserRfxSubcategoryEntityDto;

    let rfxSubcategoryId = evaluationUserRfxQuestionsEntityDto?.rfxSubcategoryId;
    let userId = evaluationUserRfxQuestionsEntityDto?.companyId;
    let questionId = evaluationUserRfxQuestionsEntityDto?.questionId;

    let rfxUserQuestionsEvaluationWrapper = this._userSubmissionQuestionsWrapper$.value;
    let rfxUserSubcategoryEvaluationWrapper = this._userSubcategorySubmissionWrapper$.value;

    // Update Rfx User Questions Evaluation Wrapper
    let evaluationRfxQuestionsEntityDtoList = rfxUserQuestionsEvaluationWrapper!.evaluationUserRfxQuestionsEntityDtoList ?? [];
    let questionIndex = evaluationRfxQuestionsEntityDtoList.findIndex(item => item.questionId == questionId);
    Object.assign(evaluationRfxQuestionsEntityDtoList[questionIndex], evaluationUserRfxQuestionsEntityDto);

    rfxUserQuestionsEvaluationWrapper!.evaluationUserRfxQuestionsEntityDtoList = evaluationRfxQuestionsEntityDtoList;

    this.updateRfxUserQuestionsEvaluationWrapper(rfxUserQuestionsEvaluationWrapper!);

    // Update Rfx User Subcategory Evaluation Wrapper
    let evaluationUserRfxSubcategoryEntityDtoList = rfxUserSubcategoryEvaluationWrapper!.evaluationUserRfxSubcategoryEntityDtoList ?? [];
    let subcategoryIndex = evaluationUserRfxSubcategoryEntityDtoList.findIndex(item => item.subcategoryId == rfxSubcategoryId && item.companyId == userId);
    Object.assign(evaluationUserRfxSubcategoryEntityDtoList[subcategoryIndex], evaluationUserRfxSubcategoryEntityDto);

    rfxUserSubcategoryEvaluationWrapper!.evaluationUserRfxSubcategoryEntityDtoList = evaluationUserRfxSubcategoryEntityDtoList;

    this.updateRfxUserSubcategoryEvaluationWrapper(rfxUserSubcategoryEvaluationWrapper!);
  }

  updateComFinancialWrapperDtos(data: CompFinancialWrapperDto[]) {
    this._comFinancialWrapperDtos$.next(data);
  }

  updateCompFinancialQuestionWrapperDto(data: CompFinancialQuestionWrapperDto) {
    this._compFinancialQuestionWrapperDto$.next(data);
  }

  updateCompNormalizeItemsWrapperDto(data: CompNormalizeItemsWrapperDto) {
    this._compNormalizeItemsWrapperDto$.next(data);
  }

  updateRegistrationUserList(data: AdminAuctionBidderRegistrationDto[]) {
    this._auctionBidderRegistrationDtos$.next(data);
  }

  updateAuctionQuestions(auctionQuestions: AuctionQuestionDto[]) {
    this.auctionQuestionsList$.next(auctionQuestions);
  }

  setMaterialUiDtos(materialUiDtos: MaterialUiDto[]) {
    this._materialUiDtos$.next(materialUiDtos);
  }

  updateMaterialUiDtos(materialUiDto: MaterialUiDto) {
    let materialUiDtos = this._materialUiDtos$.value;
    let index = materialUiDtos.findIndex(item => item.materialId == materialUiDto.materialId);
    if (index > -1) {
      Object.assign(materialUiDtos[index], materialUiDto);
    } else {
      materialUiDtos.push(materialUiDto);
    }
    this._materialUiDtos$.next(materialUiDtos);
  }

  loadBidderRegistrationList(auctionId: string) {

    this.getAllBidderRegistrationList(auctionId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let _auctionBidderRegistrationDtos = apiResponseDto.data as AdminAuctionBidderRegistrationDto[];
          this._auctionBidderRegistrationDtos$.next(_auctionBidderRegistrationDtos);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }


  async loadAndGetLoadDetailsWrapperDtoSync(auctionId: string, lotId: string) {
    let lotDetailsWrapperDto: LotDetailsWrapperDto | undefined;

    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getLandingLotDetailsWrapperDto(auctionId, lotId));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        if (apiResponseDto.data) {
          lotDetailsWrapperDto = apiResponseDto.data as LotDetailsWrapperDto
        }
      }
    } catch (error) {
      console.error(error);
    }
    return lotDetailsWrapperDto;
  }

  async loadMaterialUiDtosByOrgCode(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getMaterialsByOrgCode(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setMaterialUiDtos(apiResponseDto.data as MaterialUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }


  getUserBidChartData(userId: string) {
    let params = new HttpParams().set('userId', userId);
    return this.http.get<ServerAPIResponseDto>('userBidChartData', { params }).pipe(
      tap(_ => console.log("Getting  userBidChartData ")),
      catchError(this.errorService.handleError<any>("Error while getting userBidChartData")))
  }

  _getAdminDashboardData() {
    return this.http.get<ServerAPIResponseDto>('adminDashboardData').pipe(
      tap(_ => console.log("Getting  Admin Dashboard Data")),
      catchError(this.errorService.handleError<any>("Error while getting AdminDashboardData")))
  }


  _getCatalogueL1List() {
    return this.http.get<ServerAPIResponseDto>('catalogueL1List').pipe(
      tap(_ => console.log("Getting  CatalogueL1List")),
      catchError(this.errorService.handleError<any>("Error while getting CatalogueL1List")))
  }
  _getCatalogueL1WrapperList() {
    return this.http.get<ServerAPIResponseDto>('catalogueL1WrapperList').pipe(
      tap(_ => console.log("Getting  CatalogueL1WrapperList")),
      catchError(this.errorService.handleError<any>("Error while getting CatalogueL1List")))
  }

  _getCatalogueL2List(categoryL1Id: string) {
    let params = new HttpParams().set('categoryL1Id', categoryL1Id);
    return this.http.get<ServerAPIResponseDto>('catalogueL2List', { params }).pipe(
      tap(_ => console.log("Getting  CatalogueL2List")),
      catchError(this.errorService.handleError<any>("Error while getting CatalogueL2List")))
  }

  getCatalogueLineItemsList(categoryL1Id: string, categoryL2Id: string) {
    let params = new HttpParams().set('categoryL1Id', categoryL1Id).set('categoryL2Id', categoryL2Id);
    return this.http.get<ServerAPIResponseDto>('catalogueLineItemsList', { params }).pipe(
      tap(_ => console.log("Getting  catalogueLineItemsList")),
      catchError(this.errorService.handleError<any>("Error while getting catalogueLineItemsList")))
  }


  _getNotAwardAuction(auctionHouseId: string) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId);
    return this.http.get<ServerAPIResponseDto>('notAwardAuction', { params }).pipe(
      tap(_ => console.log("Getting  notAwardAuction Data")),
      catchError(this.errorService.handleError<any>("Error while getting notAwardAuction")))
  }


  addAdmin(addAdminDto: AddUserDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('addUser', addAdminDto).pipe(
      tap(_ => console.log("Super Admin Add Successfully : " + addAdminDto.emailId)),
      catchError(this.errorService.handleError("Error while adding super admin : " + addAdminDto.emailId)))
  }

  updateAdmin(addAdminDto: AddUserDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('addUser', addAdminDto).pipe(
      tap(_ => console.log("Super Admin Add Successfully : " + addAdminDto.emailId)),
      catchError(this.errorService.handleError("Error while adding super admin : " + addAdminDto.emailId)))
  }

  addAndUpdateBidder(bidder: BidderDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('bidder', bidder).pipe(
      tap(_ => console.log("Bidder Add Successfully : " + bidder.primaryEmailId)),
      catchError(this.errorService.handleError("Error while adding Bidder : " + bidder.primaryEmailId)))
  }

  manageBidderNotification(bidderDto: BidderDto) {
    return this.http.post<ServerAPIResponseDto>('manageBidderNotification', bidderDto).pipe(
      tap(_ => console.log("Getting  manageNotification Data")),
      catchError(this.errorService.handleError<any>("Error while  manageNotification")))
  }

  manageRegisteredBidderNotification(userEntityDto: UserUiDto) {
    return this.http.post<ServerAPIResponseDto>('manageRegisteredBidderNotification', userEntityDto).pipe(
      tap(_ => console.log("Getting  manageNotification Data")),
      catchError(this.errorService.handleError<any>("Error while  manageNotification")))
  }


  markResourceActiveOrInActive(resourceId: string, active: boolean, actionName: string) {
    let params = new HttpParams().set('resourceId', resourceId).set('active', active).set('actionName', actionName);
    return this.http.post<ServerAPIResponseDto>('markResourceActiveOrInActive', null, { params }).pipe(
      tap(_ => console.log("User active status change" + resourceId)),
      catchError(this.errorService.handleError<any>("Error while changing user active status" + resourceId)))
  }

  saveAuctionDetails(auctionEntityDto: AuctionEntityDto) {

    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('auction', auctionEntityDto).pipe(
      tap(_ => console.log("Saved Auction to DB" + auctionEntityDto.auctionId)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction to DB" + auctionEntityDto.auctionId)))
  }


  saveRfxDetails(rfxEntityDto: RfxUiDto) {

    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('rfxs', rfxEntityDto).pipe(
      tap(_ => console.log("Saved Rfx to DB" + rfxEntityDto.rfxId)),
      catchError(this.errorService.handleError<any>("Error while Saving Rfx to DB" + rfxEntityDto.rfxId)))
  }



  private getSubscriptionExpiredServerResponseDto() {
    let response = new BehaviorSubject<ServerAPIResponseDto>(new ServerAPIResponseDto());
    let serverApiResponse = new ServerAPIResponseDto();
    serverApiResponse.code = "COMMON-118";
    serverApiResponse.message = "Subscription Expired.";
    response.next(serverApiResponse);
    return response.asObservable();
  }

  saveInitialAuctionDetails(auctionEntityDto: AuctionEntityDto) {

    if (!this.clientSubscriptionService.isAllowToAuctionCreate()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('addAuctionsInitial', auctionEntityDto).pipe(
      tap(_ => console.log("Saved Auction to DB" + auctionEntityDto.auctionId)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction to DB" + auctionEntityDto.auctionId)))
  }

  saveInitialRfxDetails(rfxEntityDto: RfxUiDto) {
    if (!this.clientSubscriptionService.isAllowToAuctionCreate()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('addRfxInitial', rfxEntityDto).pipe(
      tap(_ => console.log("Saved Auction to DB" + rfxEntityDto.rfxId)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction to DB" + rfxEntityDto.rfxId)))
  }

  markAuctionActiveOrInActive(auctionEnableWrapper: AuctionEnableWrapperDto) {
    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('markAuctionActiveOrInActive', auctionEnableWrapper).pipe(
      tap(_ => console.log("mark auction Saved Auction to DB" + auctionEnableWrapper?.auctionEntityDto?.auctionId)),
      catchError(this.errorService.handleError<any>("Error while mark auction Saving Auction to DB" + auctionEnableWrapper?.auctionEntityDto?.auctionId)))
  }

  markRfxActiveOrInActive(rfxEnableWrapperDto: RfxEnableWrapperDto) {
    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('markRfxActiveOrInActive', rfxEnableWrapperDto).pipe(
      tap(_ => console.log("mark auction Saved Auction to DB" + rfxEnableWrapperDto?.rfxUiDto?.rfxId)),
      catchError(this.errorService.handleError<any>("Error while mark auction Saving Auction to DB" + rfxEnableWrapperDto?.rfxUiDto?.rfxId)))
  }

  auctionDiscard(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId)
    return this.http.get<ServerAPIResponseDto>('discardAuction', { params }).pipe(
      tap(_ => console.log("auction discard auctionId" + auctionId)),
      catchError(this.errorService.handleError<any>("Error while auction discard" + auctionId)))
  }

  rfxDiscard(rfxId: string) {
    let params = new HttpParams().set('rfxId', rfxId)
    return this.http.get<ServerAPIResponseDto>('discardRFX', { params }).pipe(
      tap(_ => console.log("rfx discard rfxId" + rfxId)),
      catchError(this.errorService.handleError<any>("Error while rfx discard" + rfxId)))
  }

  auctionLotDiscard(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    return this.http.get<ServerAPIResponseDto>('discardAuctionLot', { params }).pipe(
      tap(_ => console.log("auction lot discard auctionId" + auctionId + " lotId" + lotId)),
      catchError(this.errorService.handleError<any>("Error while auction lot discard" + auctionId + " lotId" + lotId)))
  }

  markAuctionLotActiveOrInActive(auctionLotEntityDto: AuctionLotEntityDto) {
    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('markLotActiveOrInActive', auctionLotEntityDto).pipe(
      tap(_ => console.log("mark auction lot Saved to DB" + auctionLotEntityDto.lotId)),
      catchError(this.errorService.handleError<any>("Error while mark auction lot Saving to DB" + auctionLotEntityDto.lotId)))
  }

  markLotsActive(auctionEnableWrapper: AuctionEnableWrapperDto) {
    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('markLotsActive', auctionEnableWrapper).pipe(
      tap(_ => console.log("mark lots active to DB" + auctionEnableWrapper?.auctionEntityDto?.auctionId)),
      catchError(this.errorService.handleError<any>("Error while mark lots active to DB" + auctionEnableWrapper?.auctionEntityDto?.auctionId)))
  }

  markSubcategoriesActive(rfxEnableWrapperDto: RfxEnableWrapperDto) {
    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('markSubcategoriesActive', rfxEnableWrapperDto).pipe(
      tap(_ => console.log("mark lots active to DB" + rfxEnableWrapperDto?.rfxUiDto?.rfxId)),
      catchError(this.errorService.handleError<any>("Error while mark lots active to DB" + rfxEnableWrapperDto?.rfxUiDto?.rfxId)))
  }

  updateAuctionHouse(auctionHouse: AuctionHouseDto) {
    return this.http.post<ServerAPIResponseDto>('auctionHouse', auctionHouse).pipe(
      tap(_ => console.log("Saved Auction House to DB" + auctionHouse.name)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction House to DB" + auctionHouse.name)));
  }

  saveCatalogueL1(catalogueL1EntityDto: CatalogueL1EntityDto) {
    return this.http.post<ServerAPIResponseDto>('catalogueL1', catalogueL1EntityDto).pipe(
      tap(_ => console.log("Saved catalogueL1 to DB" + catalogueL1EntityDto.name)),
      catchError(this.errorService.handleError<any>("Error while Saving catalogueL1 to DB" + catalogueL1EntityDto.name)));
  }


  saveCatalogueL2(catalogueL2: CatalogueL2EntityDto) {
    return this.http.post<ServerAPIResponseDto>('catalogueL2', catalogueL2).pipe(
      tap(_ => console.log("Saved catalogueL2 to DB" + catalogueL2.name)),
      catchError(this.errorService.handleError<any>("Error while Saving catalogueL2 to DB" + catalogueL2.name)));
  }

  saveCatalogueLineItem(catalogueLineItem: CatalogueLineItemsEntityDto) {
    return this.http.post<ServerAPIResponseDto>('catalogueLineItems', catalogueLineItem).pipe(
      tap(_ => console.log("Saved catalogueLineItems to DB" + catalogueLineItem.title)),
      catchError(this.errorService.handleError<any>("Error while Saving catalogueLineItems to DB" + catalogueLineItem.title)));
  }

  getAllBidderRegistrationList(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId)
    return this.http.get<ServerAPIResponseDto>('registrationList', { params }).pipe(
      tap(_ => console.log("Getting bidder registration list" + auctionId)),
      catchError(this.errorService.handleError<any>("Error while getting bidder registration list" + auctionId)));
  }

  changeRegistrationStatus(auctionId: string, userId: string, auctionRegistartionStatus: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('userId', userId).set('registrationStatus', auctionRegistartionStatus);
    return this.http.post<ServerAPIResponseDto>('changeRegistrationStatus', null, { params: params }).pipe(
      tap(_ => console.log(`Change Status : userId => " + ${userId} | status => ${status}`)),
      catchError(this.errorService.handleError<any>("Error while change user status" + userId)));
  }

  fetchBidderDetailsData(userId: string, auctionId: string, auctionHouseId: string, lotId: string, timeZone: string) {
    let params = new HttpParams().set('userId', userId).set('auctionId', auctionId).set('auctionHouseId', auctionHouseId)
      .set('lotId', lotId).set('timeZone', timeZone);
    return this.http.get<ServerAPIResponseDto>('fetchBidderDetailsData', { params }).pipe(
      tap(_ => console.log("Fetch Bidder Data Successfully")),
      catchError(this.errorService.handleError<any>("Error while getting data")))
  }

  doMaxBids(auctionBidsDto: UserMaxBidsDto) {
    return this.http.post<ServerAPIResponseDto>('managed-bucketSize', auctionBidsDto).pipe(
      tap(_ => console.log("Proxy Bids Placed" + auctionBidsDto.bucketSize)),
      catchError(this.errorService.handleError<any>("Error while Proxy Bids Placed" + auctionBidsDto.bucketSize)));
  }

  acceptOffer(auctionId: string, lotId: string, userId: string, offerPrice: number): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId).set('userId', userId).set('offerPrice', offerPrice);
    return this.http.post<ServerAPIResponseDto>('acceptOffer', null, { params }).pipe(
      tap(_ => console.log("Offer Accept Successfully for User : " + userId)),
      catchError(this.errorService.handleError("Error while accepting offer : " + userId)))
  }

  downloadListBidderOfferData(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
      'params': params
    };
    return this.http.get<any>('downloadBidderOfferData', httpOptions);
  }

  downloadRegistrationData(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
      'params': params
    };
    return this.http.get<any>('downloadListBidderRegistrationData', httpOptions);
  }

  downloadListBidHistoryData(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
      'params': params
    };
    return this.http.get<any>('downloadListBidHistoryData', httpOptions);
  }

  downloadUserOfferData(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
      'params': params
    };
    return this.http.get<any>('downloadListBidHistoryData', httpOptions);
  }



  fetchBidderCount() {
    return this.http.get<ServerAPIResponseDto>('bidderCount').pipe(
      tap(_ => console.log("Fetch Bidders count Successfully")),
      catchError(this.errorService.handleError<any>("Error while getting Bidders Count")))
  }

  userLotAllocate(auctionId: string, lotId: string, userId: string, bidPrice: number): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId).set('userId', userId).set('bidPrice', bidPrice);
    return this.http.post<ServerAPIResponseDto>('userLotAllocate', null, { params }).pipe(
      tap(_ => console.log("Lot Allocate Successfully for User : " + userId)),
      catchError(this.errorService.handleError("Error while allocating lot : " + userId)))
  }

  rejectLotAllocate(auctionId: string, lotId: string, userId: string, bidPrice: number, offer: boolean): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId).set('userId', userId).set('bidPrice', bidPrice).set('offer', offer);
    return this.http.post<ServerAPIResponseDto>('rejectLotAllocation', null, { params }).pipe(
      tap(_ => console.log("Lot reject Allocation Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while allocating lot : " + auctionId)))
  }

  charData(requestGraphChart: RequestGraphChart): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('charData', requestGraphChart).pipe(
      tap(_ => console.log("Getting charData Successfully for auctionId : " + requestGraphChart.auctionId)),
      catchError(this.errorService.handleError("Error while getting charData : " + requestGraphChart.auctionId)))
  }

  getWinnerBidders(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('winnerBidders', { params }).pipe(
      tap(_ => console.log("Getting awarded bidders Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting awarded bidders : " + auctionId)))
  }

  getUserActivity(userId: string, searchType: string): Observable<any> {
    let params = new HttpParams().set('userId', userId).set('searchType', searchType);
    return this.http.get<ServerAPIResponseDto>('userActivities', { params }).pipe(
      tap(_ => console.log("Getting User Activity Successfully for userId : " + userId)),
      catchError(this.errorService.handleError("Error while getting user activities : " + userId)))
  }

  getNotAwardedLots(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('notAwardedLots', { params }).pipe(
      tap(_ => console.log("Getting not awarded lot List  Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting not Awarded Lots : " + auctionId)))
  }

  getWinnerBidder(auctionId: string, userId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('userId', userId);
    return this.http.get<ServerAPIResponseDto>('winnerBidder', { params }).pipe(
      tap(_ => console.log("Getting awarded bidders Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting awarded bidders : " + auctionId)))
  }

  updateInvoicePaymentStatus(auctionId: string, lotIds: Array<string | undefined>, userId: string, status: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('status', status).set('userId', userId).set('lotIds', lotIds.toString());
    return this.http.get<ServerAPIResponseDto>('updateInvoicePaymentStatus', { params }).pipe(
      tap(_ => console.log("update invoice payment status Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while update invoice payment status : " + auctionId)))
  }

  downloadInvoice(invoiceRequest: InvoiceRequestDto): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
    };
    return this.http.post<ServerAPIResponseDto>('downloadInvoice', invoiceRequest, httpOptions).pipe(
      tap(_ => console.log("Downloading Invoice Successfully for userId : " + invoiceRequest.userId)),
      catchError(this.errorService.handleError("Error while downloading invoice : " + invoiceRequest.userId)))
  }

  emailInvoice(invoiceRequest: InvoiceRequestDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('sendInvoiceEmail', invoiceRequest).pipe(
      tap(_ => console.log("Downloading Invoice Successfully for userId : " + invoiceRequest.userId)),
      catchError(this.errorService.handleError("Error while downloading invoice : " + invoiceRequest.userId)))
  }

  getGeneratedInvoiceList(auctionHouseId: string, auctionId: string, userId: string,): Observable<any> {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('auctionId', auctionId).set('userId', userId);
    return this.http.get<ServerAPIResponseDto>('invoiceList', { params }).pipe(
      tap(_ => console.log("Getting invoiceList  Successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting invoiceList bidders : " + auctionId)))
  }

  getAuctionDashboardData(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionDashboardData', { params }).pipe(
      tap(_ => console.log("Getting dashboard data successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting dashboard data : " + auctionId)));
  }

  getAuctionsData(auctionHouseId: string): Observable<any> {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId);
    return this.http.get<ServerAPIResponseDto>('auctions', { params }).pipe(
      tap(_ => console.log("Getting auctions data successfully for auctionHouseId : " + auctionHouseId)),
      catchError(this.errorService.handleError("Error while getting auctions data : " + auctionHouseId)));
  }

  getLandingLotDetailsWrapperDto(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    return this.http.get<ServerAPIResponseDto>('landingLotDetailsWrapperDto', { params: params }).pipe(
      tap(_ => console.log("Get landingLotDetailsWrapperDto")),
      catchError(this.errorService.handleError<any>("Error while getting landingLotDetailsWrapperDto")));
  }

  getAuctionReportWrapper(auctionId: string): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionReportWrapper', { params }).pipe(
      tap(_ => console.log("Getting auctions data successfully for auctionId : " + auctionId)),
      catchError(this.errorService.handleError("Error while getting auctions data : " + auctionId)));
  }

  downloadAuctionReport(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers,
      'params': params
    };
    return this.http.get<any>('downloadAuctionReport', httpOptions);
  }

  getBidderListData(auctionHouseId: string) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId);
    return this.http.get<ServerAPIResponseDto>('fetchBidders', { params }).pipe(
      tap(_ => console.log("Get fetchBidders")),
      catchError(this.errorService.handleError<any>("Error while getting fetchBidders")));
  }


  rfxSubcategoryDiscard(rfxId: string, subcategoryId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('subcategoryId', subcategoryId);
    return this.http.get<ServerAPIResponseDto>('discardRfxSubcategory', { params }).pipe(
      tap(_ => console.log("Rfx Subcategory discard rfxId" + rfxId + " subcategoryId" + subcategoryId)),
      catchError(this.errorService.handleError<any>("Error while Rfx Subcategory discard" + rfxId + " subcategoryId" + subcategoryId)))
  }



  markRfxSubcategoryActiveOrInActive(rfxSubcategoryEntityDto: RfxSubcategoryUiDto) {
    if (!this.clientSubscriptionService.isAllowToAdminActions()) {
      return this.getSubscriptionExpiredServerResponseDto();
    }

    return this.http.post<ServerAPIResponseDto>('markSubcategoryActiveOrInActive', rfxSubcategoryEntityDto).pipe(
      tap(_ => console.log("mark Rfx Subcategory Saved to DB" + rfxSubcategoryEntityDto.subcategoryId)),
      catchError(this.errorService.handleError<any>("Error while mark Rfx Subcategory Saving to DB" + rfxSubcategoryEntityDto.subcategoryId)))
  }


  getSubcategorySubmissions(rfxId: string) {
    let params = new HttpParams().set('rfxId', rfxId);
    return this.http.get<ServerAPIResponseDto>('subcategorySubmissions', { params }).pipe(
      tap(_ => console.log("getSubcategorySubmissions rfxId" + rfxId)),
      catchError(this.errorService.handleError<any>("Error while getSubcategorySubmissions" + rfxId)))
  }

  getUserSubcategorySubmissions(rfxId: string, rfxSubcategoryId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId);
    return this.http.get<ServerAPIResponseDto>('userSubmissionsScore', { params }).pipe(
      tap(_ => console.log("getUserSubcategorySubmissions rfxId" + rfxId)),
      catchError(this.errorService.handleError<any>("Error while getUserSubcategorySubmissions" + rfxId)))
  }

  getSubcategorySubmissionQuestions(rfxId: string, rfxSubcategoryId: string, userId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId).set('userId', userId);
    return this.http.get<ServerAPIResponseDto>('subcategorySubmissionQuestions', { params }).pipe(
      tap(_ => console.log("getSubcategorySubmissionQuestions rfxId" + rfxId)),
      catchError(this.errorService.handleError<any>("Error while getSubcategorySubmissionQuestions" + rfxId)))
  }

  bulkSubcategorySubmissionQuestions(rfxId: string, rfxSubcategoryId: string, userId: string, envelopeType: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId).set('userId', userId).set('envelopeType', envelopeType);
    return this.http.post<ServerAPIResponseDto>('bulkSubcategorySubmissionQuestions', null, { params }).pipe(
      tap(_ => console.log("bulkSubcategorySubmissionQuestions to DB" + rfxSubcategoryId)),
      catchError(this.errorService.handleError<any>("Error while bulkSubcategorySubmissionQuestions to DB" + rfxSubcategoryId)))
  }

  bulkSubcategoryUserSubmission(rfxId: string, rfxSubcategoryId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId);
    return this.http.post<ServerAPIResponseDto>('bulkSubcategoryUserSubmission', null, { params }).pipe(
      tap(_ => console.log("bulkSubcategoryUserSubmission to DB" + rfxSubcategoryId)),
      catchError(this.errorService.handleError<any>("Error while bulkSubcategoryUserSubmission to DB" + rfxSubcategoryId)))
  }

  getCompFinancialWrappers(rfxId: string, rfxSubcategoryId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId);
    return this.http.get<ServerAPIResponseDto>('compFinancials', { params }).pipe(
      tap(_ => console.log("compFinancials to DB" + rfxSubcategoryId)),
      catchError(this.errorService.handleError<any>("Error while compFinancials to DB" + rfxSubcategoryId)))
  }

  getCompFinancialsQuestionsWrapper(rfxId: string, rfxSubcategoryId: string, companyId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId).set('companyId', companyId);
    return this.http.get<ServerAPIResponseDto>('compFinancialsQuestions', { params }).pipe(
      tap(_ => console.log("compFinancialsQuestions to DB" + rfxSubcategoryId)),
      catchError(this.errorService.handleError<any>("Error while compFinancialsQuestions to DB" + rfxSubcategoryId)))
  }

  getCompNormalizeItemsWrapperDto(rfxId: string, subcategoryId: string, companyId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('subcategoryId', subcategoryId).set('companyId', companyId);
    return this.http.get<ServerAPIResponseDto>('compNormalizeItems', { params }).pipe(
      tap(_ => console.log("compNormalizeItems to DB" + subcategoryId)),
      catchError(this.errorService.handleError<any>("Error while compNormalizeItems to DB" + subcategoryId)))
  }

  requestNormalizePrice(rfxId: string, rfxSubcategoryId: string, userId: string, status: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId).set('userId', userId).set('status', status);
    return this.http.post<ServerAPIResponseDto>('manageNormalizeRequests', null, { params }).pipe(
      tap(_ => console.log("manageNormalizeRequests to DB" + rfxSubcategoryId)),
      catchError(this.errorService.handleError<any>("Error while manageNormalizeRequests to DB" + rfxSubcategoryId)))
  }

  getApplicationNotifications(userId: string, page: number, pageSize: number) {
    let params = new HttpParams().set('userId', userId).set('page', page).set('pageSize', pageSize);
    return this.http.get<ServerAPIResponseDto>('applicationNotifications', { params }).pipe(
      tap(_ => console.log("Get applicationNotifications")),
      catchError(this.errorService.handleError<any>("Error while getting applicationNotifications")));
  }

  seenApplicationNotifications(userId: string, ids: string[]) {
    let idsInString = JSON.stringify(ids).replaceAll('[', '').replaceAll(']', '').replaceAll(' ', '');
    let params = new HttpParams().set('userId', userId).set('ids', idsInString);
    return this.http.post<ServerAPIResponseDto>('applicationNotificationsSeen', null, { params }).pipe(
      tap(_ => console.log("Get applicationNotificationsSeen")),
      catchError(this.errorService.handleError<any>("Error while getting applicationNotificationsSeen")));
  }

  openFinancialEnvelope(rfxId: string, rfxSubcategoryId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId);
    return this.http.post<ServerAPIResponseDto>('openFinancialEnvelope', null, { params }).pipe(
      tap(_ => console.log("Get openFinancialEnvelope" + rfxSubcategoryId)),
      catchError(this.errorService.handleError<any>("Error while getting openFinancialEnvelope" + rfxSubcategoryId)))
  }

  openTechnicalEnvelope(rfxId: string, rfxSubcategoryId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId);
    return this.http.post<ServerAPIResponseDto>('openTechnicalEnvelope', null, { params }).pipe(
      tap(_ => console.log("Get openFinancialEnvelope" + rfxSubcategoryId)),
      catchError(this.errorService.handleError<any>("Error while getting openFinancialEnvelope" + rfxSubcategoryId)))
  }

  getComparisonSheets(rfxId: string, rfxSubcategoryId: string) {
    let params = new HttpParams().set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId);
    return this.http.get<ServerAPIResponseDto>('compSheets', { params }).pipe(
      tap(_ => console.log("compSheets rfxId" + rfxId)),
      catchError(this.errorService.handleError<any>("Error while compSheets" + rfxId)))
  }

  generatePurchaseOrder(rfxPurchaseOrderDto: RfxPurchaseOrderDto) {
    return this.http.post<ServerAPIResponseDto>('purchaseOrders', rfxPurchaseOrderDto).pipe(
      tap(_ => console.log("Submit purchaseOrders" + rfxPurchaseOrderDto.subcategoryId)),
      catchError(this.errorService.handleError<any>("Error while submitting purchaseOrders" + rfxPurchaseOrderDto.subcategoryId))
    );
  }

  getPurchaseOrders() {
    return this.http.get<ServerAPIResponseDto>('purchaseOrders').pipe(
      tap(_ => console.log("get purchaseOrders")),
      catchError(this.errorService.handleError<any>("Error while getting purchaseOrders"))
    );
  }

  getPurchaseInvoices(orgCode: string) {
    let params = new HttpParams().set('orgCode', orgCode);
    return this.http.get<ServerAPIResponseDto>('purchaseInvoices', { params }).pipe(
      tap(_ => console.log("get purchaseInvoices")),
      catchError(this.errorService.handleError<any>("Error while getting purchaseInvoices"))
    );
  }

  updatePurchaseInvoice(rfxPurchaseInvoiceDto: RfxPurchaseInvoiceDto) {
    return this.http.post<ServerAPIResponseDto>('purchaseInvoices', rfxPurchaseInvoiceDto).pipe(
      tap(_ => console.log("Update purchaseInvoices" + rfxPurchaseInvoiceDto.invoiceId)),
      catchError(this.errorService.handleError<any>("Error while updating purchaseInvoices" + rfxPurchaseInvoiceDto.invoiceId))
    );
  }

  getApprovalWorkflowUiDtos(prCode: string, type: string) {
    return this.http.get<ServerAPIResponseDto>(`demo_Approval_workflow/${prCode}/${type}`).pipe(
      tap(_ => console.log("get demo app workflows")),
      catchError(this.errorService.handleError<any>("Error while getting demo app workflows"))
    );
  }

  updateApprovalWorkflowUiDtos(appWorkflowUiDto: AppWorkflowUiDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('demo_Approval_workflow', appWorkflowUiDto).pipe(
      tap(_ => console.log("Getting updateApprovalWorkflowUiDtos Successfully")),
      catchError(this.errorService.handleError("Error while getting updateApprovalWorkflowUiDtos")))
  }

  saveMaterialDetails(materialUiDto: MaterialUiDto) {
    return this.http.post<ServerAPIResponseDto>('materials', materialUiDto).pipe(
      tap(_ => console.log("Saved material " + materialUiDto.materialId)),
      catchError(this.errorService.handleError<any>("Error while Saving Material to DB" + materialUiDto.materialId)));
  }

  getMaterialsByOrgCode(orgCode: string) {
    return this.http.get<ServerAPIResponseDto>(`materials/${orgCode}`).pipe(
      tap(_ => console.log("Get material ")),
      catchError(this.errorService.handleError<any>("Error while getting materials")));
  }
}
