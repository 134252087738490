import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DrawerService } from 'src/app/shared/services/drawer.service';

@Component({
  selector: 'app-onboarding-drawer',
  templateUrl: './onboarding-drawer.component.html',
  styleUrls: ['./onboarding-drawer.component.sass']
})
export class OnboardingDrawerComponent implements OnInit, OnDestroy  {
  pageName!: string;
  @Output() isOpen = new EventEmitter<void>();
  
  drawerSubscription$?: Subscription;

  constructor(
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.drawerSubscription$ = this.drawerService.checkDrawerOpened$.subscribe(data => {
      if (data) {
        this.pageName = this.drawerService.drawerPageName;
      }
    })
  }

  toggleDrawer() {
    this.isOpen.emit();
  }

  updatePageName(pageName?: string) {
    if (pageName) {
      this.pageName = pageName;
    } else {
      this.toggleDrawer();
    }
  }

  ngOnDestroy(): void {
    if (this.drawerSubscription$) {
      this.drawerSubscription$.unsubscribe();
    }
  }

}
