import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function multipleOfFiveValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value == null || value === '') {
      return null; // Don't validate if the value is empty (leave that to the required validator)
    }
    // Check if value is a positive number and a multiple of 5
    return (value > 0 && value % 5 === 0) ? null : { multipleOfFive: true };
  };
}

