import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxUserQuestionsEvaluationWrapperDto } from 'src/app/shared/models/rfx/RfxUserQuestionsEvaluationWrapperDto';
import { RfxEvaluationTechnicalScoresWrapperDto } from 'src/app/shared/models/rfx/RfxEvaluationTechnicalScoresWrapperDto';
import { SubcategorySubmissionDto } from 'src/app/shared/models/rfx/SubcategorySubmissionDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { RfxEvaluationTechnicalQuestionsComponent } from '../rfx-evaluation-technical-questions/rfx-evaluation-technical-questions.component';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { DatePipe } from '@angular/common';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { EvaluationQuestionStatus } from 'src/app/shared/enums/rfx/EvaluationQuestionStatus';
import { QualifiedStatus } from 'src/app/shared/enums/rfx/QualifiedStatus';

@Component({
  selector: 'app-rfx-evaluation-graph',
  templateUrl: './rfx-evaluation-graph.component.html',
  styleUrls: ['./rfx-evaluation-graph.component.sass']
})
export class RfxEvaluationGraphComponent implements OnInit, OnDestroy {
  rfxEntityDto?: RfxUiDto;
  subcategorySubmissionList: SubcategorySubmissionDto[] = [];
  rfxUserSubcategoryEvaluationWrapper?: RfxEvaluationTechnicalScoresWrapperDto;
  rfxUserQuestionsEvaluationWrapper?: RfxUserQuestionsEvaluationWrapperDto;

  selectedSubcategorySubmissionDto?: SubcategorySubmissionDto;

  isChartLoading: boolean = false;

  selectedRfxSubscription$?: Subscription;
  subcategorySubmissionsSubscription$?: Subscription;
  userSubcategoryEvaluationWrapperSubscription$?: Subscription;
  userSubmissionQuestionsWrapperSubscription$?: Subscription;

  chartOptions: EChartsOption = {}

  constructor(
    private datePipe: DatePipe,
    private ngbModal: NgbModal,
    private activeModal: NgbActiveModal,
    private adminDashboardService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
  ) { }

  ngOnInit(): void {
    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(rfx => {
      if (rfx) {
        this.rfxEntityDto = rfx;
      }
    })

    this.subcategorySubmissionsSubscription$ = this.adminDashboardService.getSubcategorySubmissionList$.subscribe(data => {
      if (data) {
        this.subcategorySubmissionList = data;
        this.updateGraph()
      } else {
        this.subcategorySubmissionList = [];
      }
    })

    this.userSubcategoryEvaluationWrapperSubscription$ = this.adminDashboardService.getUserSubcategorySubmissionWrapper$.subscribe(data => {
      if (data) {
        this.rfxUserSubcategoryEvaluationWrapper = data;
        this.updateGraph();
      } else {
        this.rfxUserSubcategoryEvaluationWrapper = undefined;
      }
    })

    this.userSubmissionQuestionsWrapperSubscription$ = this.adminDashboardService.getUserSubmissionQuestionsWrapper$.subscribe(data => {
      if (data) {
        this.rfxUserQuestionsEvaluationWrapper = data;
        this.updateGraph();
      } else {
        this.rfxUserQuestionsEvaluationWrapper = undefined;
      }
    })
  }

  closeModal() {
    this.activeModal.close();
  }

  updateGraph() {
    this.isChartLoading = true;

    let data: any = {
      name: this.rfxEntityDto?.rfxTitle,
      formatter: this.rfxEntityDto?.rfxTitle,
      itemStyle: {
        color: '#000',
        borderColor: '#000'
      },
      label: 'RFX',
      children: []
    };

    for (let i = 0; i < this.subcategorySubmissionList.length; i++) {
      const subcategory = this.subcategorySubmissionList[i];
      let subcategoryMap: any = {
        name: subcategory.subcategoryId,
        itemStyle: {
          color: subcategory.evaluatorStatus == 'Complete' ? '#059669' : '#dc2626',
          borderColor: subcategory.evaluatorStatus == 'Complete' ? '#059669' : '#dc2626',
        },
        formatter: subcategory.subcategoryName,
        label: 'SUBCATEGORY',
        children: []
      }

      ///  All Users
      if (this.rfxUserSubcategoryEvaluationWrapper) {
        let usersOfSubcategory = this.rfxUserSubcategoryEvaluationWrapper?.evaluationUserRfxSubcategoryEntityDtoList ?? [];

        for (let j = 0; j < usersOfSubcategory.length; j++) {
          const userRfxSubcategory = usersOfSubcategory[j];

          let userRfxSubcategoryMap: any = {
            name: userRfxSubcategory.companyId,
            itemStyle: {
              color: userRfxSubcategory.status == QualifiedStatus.QUALIFIED ? '#059669' : '#dc2626',
              borderColor: userRfxSubcategory.status == QualifiedStatus.QUALIFIED ? '#059669' : '#dc2626',
            },
            formatter: userRfxSubcategory.companyName,
            label: 'SUBCATEGORY_USERS',
            children: []
          }

          // Questions
          if (this.rfxUserQuestionsEvaluationWrapper) {
            let userRfxQuestionsEntityDtoList = this.rfxUserQuestionsEvaluationWrapper.userRfxQuestionsEntityDtoList ?? []
            if (userRfxQuestionsEntityDtoList.length > 0 && userRfxQuestionsEntityDtoList[0].companyId == userRfxSubcategory.companyId) {

              let evaluationRfxQuestions = this.rfxUserQuestionsEvaluationWrapper?.evaluationUserRfxQuestionsEntityDtoList ?? [];
              let technicalQuestions = this.rfxUserQuestionsEvaluationWrapper?.technicalQuestionsTemplateList ?? [];
              technicalQuestions = this.sortTechnicalQuestions(technicalQuestions);

              for (let k = 0; k < technicalQuestions.length; k++) {
                const technicalQuestion = technicalQuestions[k];
                const evaluationRfxQuestion = evaluationRfxQuestions.find(item => item.questionId == technicalQuestion.questionId);

                let technicalQuestionMap: any = {
                  name: technicalQuestion.questionId,
                  itemStyle: {
                    color: evaluationRfxQuestion?.status == EvaluationQuestionStatus.SUBMITTED ? '#059669' : '#dc2626',
                    borderColor: evaluationRfxQuestion?.status == EvaluationQuestionStatus.SUBMITTED ? '#059669' : '#dc2626',
                  },
                  formatter: technicalQuestion.questionText,
                  label: 'QUESTION:TECHNICAL'
                }

                userRfxSubcategoryMap.children.push(technicalQuestionMap);
              }
            }
          }

          if (subcategoryMap.name == userRfxSubcategory.subcategoryId) {
            subcategoryMap.children.push(userRfxSubcategoryMap);
          }
        }
      }

      data.children.push(subcategoryMap);
    }


    this.chartOptions = {
      grid: { containLabel: true },
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        formatter: (params: any) => {
          if (params.data.label == 'RFX') {
            return `#${this.rfxEntityDto?.sequenceNo} ${this.rfxEntityDto?.rfxTitle}`
          }

          if (params.data.label == 'SUBCATEGORY') {
            let subcategory = this.subcategorySubmissionList.find(item => item.subcategoryId == params.name);
            return `#${subcategory?.subcategorySequenceNo} ${subcategory?.subcategoryName}<br>
            Ends: ${this.getDisplayDate(subcategory?.endDate!, subcategory?.endTime!)}<br>
            Submissions: ${subcategory?.submissions}<br>
            Evaluated: ${subcategory?.evaluatorStatus}`
          }

          if (params.data.label == 'SUBCATEGORY_USERS') {
            let usersOfSubcategory = this.rfxUserSubcategoryEvaluationWrapper?.evaluationUserRfxSubcategoryEntityDtoList ?? [];
            let evaluationUser = usersOfSubcategory.find(item => item.companyId == params.name);
            return `${evaluationUser?.companyName}<br>
            Score: ${evaluationUser?.score}<br>
            Status: ${evaluationUser?.status == 'QUALIFIED' ? 'Qualified' : 'Not Qualified'}`
          }

          if (params.data.label && (params.data.label as string).startsWith('QUESTION')) {
            let technicalQuestions = this.rfxUserQuestionsEvaluationWrapper?.technicalQuestionsTemplateList ?? [];
            let technicalQuestion = technicalQuestions?.find(item => item.questionId == params.name);
            return `Q No: ${technicalQuestion?.preSequenceText}.${technicalQuestion?.sequenceNo}<br>
            Q Criteria: ${technicalQuestion?.questionText}<br>
            Response Type: ${this.getTitleResponseType(technicalQuestion?.responseType!)}<br>
            Score: ${this.getEvaluationScore(technicalQuestion?.questionId!)}<br>
            Total Score: ${technicalQuestion?.score}<br>
            Status: ${this.getEvaluationStatus(technicalQuestion?.questionId!)}`
          }
          return params.name;
        }
      },
      series: [
        {
          type: 'tree',
          name: 'tree1',
          data: [data],
          top: '5%',
          left: '20%',
          bottom: '2%',
          right: '20%',
          symbolSize: 16,
          label: {
            position: 'left',
            verticalAlign: 'middle',
            align: 'right',
            formatter: function (params: any) {
              return `${params.data.formatter}`
            }
          },
          leaves: {
            label: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left'
            }
          },
          emphasis: {
            focus: 'descendant'
          },
          expandAndCollapse: true,
          initialTreeDepth: -1,
          animationDuration: 550,
          animationDurationUpdate: 750
        },
      ]
    }

    this.isChartLoading = false;
  }

  onChartClick(event: any) {
    console.log(event);
    if (event.data.label == 'SUBCATEGORY') {
      let subcategorySubmissionDto = this.subcategorySubmissionList.find(item => item.subcategoryId == event.name);
      this.selectedSubcategorySubmissionDto = subcategorySubmissionDto;
      this.loadUserSubcategorySubmissions(subcategorySubmissionDto!);
    }

    if (event.data.label == 'SUBCATEGORY_USERS') {
      let usersOfSubcategory = this.rfxUserSubcategoryEvaluationWrapper?.evaluationUserRfxSubcategoryEntityDtoList ?? [];
      let evaluationUser = usersOfSubcategory.find(item => item.companyId == event.name);
      this.loadUserSubmissionQuestions(evaluationUser?.subcategoryId!, evaluationUser?.companyId!);
    }

    if (event.data.label && (event.data.label as string).startsWith('QUESTION')) {
      let config = (event.data.label as string).split(':');
      let envelopeType = config[1];

      if (envelopeType == SourcingEnvelopeType.TECHNICAL) {
        let technicalQuestions = this.rfxUserQuestionsEvaluationWrapper?.technicalQuestionsTemplateList ?? [];
        let selectedTechnicalQuestion = technicalQuestions?.find(item => item.questionId == event.name);
        this.openEvaluationQuestionModal(selectedTechnicalQuestion!);
      }
    }
  }

  async loadUserSubcategorySubmissions(subcategorySubmissionDto: SubcategorySubmissionDto) {
    this.selectedSubcategorySubmissionDto = subcategorySubmissionDto;

    this.isChartLoading = true;
    await this.adminDashboardService.getAndLoadUserSubcategorySubmissions(this.rfxEntityDto?.rfxId!, subcategorySubmissionDto.subcategoryId!);
    this.isChartLoading = false;
  }

  async loadUserSubmissionQuestions(subcategoryId: string, userId: string) {
    this.isChartLoading = true;
    await this.adminDashboardService.getAndLoadSubcategorySubmissionQuestions(this.rfxEntityDto?.rfxId!, subcategoryId, userId);
    this.isChartLoading = false;
  }

  openEvaluationQuestionModal(item: TechnicalQuestionTemplate) {
    let modalRef = this.ngbModal.open(RfxEvaluationTechnicalQuestionsComponent, {
      centered: true, size: 'xl',
      backdrop: 'static', keyboard: false
    });
    modalRef.componentInstance.selectedTechnicalQuestion = item;
    modalRef.componentInstance.selectedSubcategorySubmissionDto = this.selectedSubcategorySubmissionDto;
    modalRef.componentInstance.rfxUserQuestionsEvaluationWrapper = this.rfxUserQuestionsEvaluationWrapper;
  }

  randomColorsList(length: number): string[] {
    const colors = new Set();

    while (colors.size < length) {
      const color = ApplicationUtils.getRandomLightColorHex();
      colors.add(color);
    }

    return Array.from(colors) as string[];
  }

  sortTechnicalQuestions(data: TechnicalQuestionTemplate[]): TechnicalQuestionTemplate[] {
    return data.sort((a, b) => {
      // Split the strings at the dot (.) to separate letter and number parts
      const [letterA, numberA] = `${a.preSequenceText}.${a.sequenceNo}`.split('.');
      const [letterB, numberB] = `${b.preSequenceText}.${b.sequenceNo}`.split('.');
   
      // Sort by letter (ascending)
      if (letterA < letterB) return -1;
      if (letterA > letterB) return 1;
   
      // If letters are the same, sort by number (ascending)
      return Number(numberA) - Number(numberB);
    });
  }

  getDisplayDate(date: string, time: string) {
    let convertedDate = ApplicationUtils.convertedDate(date, time);
    return this.datePipe.transform(convertedDate, 'dd MMM yyyy');
  }

  getTitleResponseType(responseType: QuestionResponseType) {
    if (responseType == QuestionResponseType.SINGLE_CHOICE) {
      return "Single Choice Response";
    } else if (responseType == QuestionResponseType.MULTI_CHOICE) {
      return "Multi Choice Response";
    } else if (responseType == QuestionResponseType.DATE) {
      return "Date Response";
    } else if (responseType == QuestionResponseType.DATE_RANGE) {
      return "Date Range Response";
    } else if (responseType == QuestionResponseType.ALPHANUMERIC) {
      return "Alphanumeric Response";
    }
    return "Select Response Type";
  }

  getEvaluationScore(questionId: string) {
    let evaluationQuestion = this.rfxUserQuestionsEvaluationWrapper?.evaluationUserRfxQuestionsEntityDtoList?.find(item => item.questionId == questionId);
    if (evaluationQuestion) {
      return evaluationQuestion.evaluatorScore;
    }
    return '';
  }

  getEvaluationStatus(questionId: string) {
    let evaluationQuestion = this.rfxUserQuestionsEvaluationWrapper?.evaluationUserRfxQuestionsEntityDtoList?.find(item => item.questionId == questionId);
    if (evaluationQuestion) {
      if (evaluationQuestion.status == EvaluationQuestionStatus.SUBMITTED) {
        return 'Verified';
      }
    }
    return 'Pending';
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
    if (this.subcategorySubmissionsSubscription$) {
      this.subcategorySubmissionsSubscription$.unsubscribe();
    }
    if (this.userSubcategoryEvaluationWrapperSubscription$) {
      this.userSubcategoryEvaluationWrapperSubscription$.unsubscribe();
    }
    if (this.userSubmissionQuestionsWrapperSubscription$) {
      this.userSubmissionQuestionsWrapperSubscription$.unsubscribe();
    }
  }
}
