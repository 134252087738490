
<div class="col-12">
  <div class="table-responsive">
    <table class="table table-bordered medium-font mb-0">
      <thead class="table-dark">
        <tr>
          <th class="text-center "><span>Status</span></th>
          <th class=" "><span>Name</span></th>
          <th class=" "><span>Email</span></th>
          <th class=" "><span>Phone</span></th>
          <th class=" "><span>Registered On</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isLoading">
          <td colspan="5">
            <div class="d-grid gap-2 mb-3">
              <div class="e-spinner"></div>
            </div>
          </td>
        </tr>
        <tr *ngIf="(bidderRegistrationList$ | async)!.length == 0 && !isLoading">
          <td colspan="5" class="text-center">
            <h5 class="heading-new text-dark">No Registrations Available</h5>
          </td>
        </tr>
        <tr *ngFor="let registrationDto of bidderRegistrationList$ | async ; index as i">
          <td class="text-center">
            <img src="assets/icons/question_light.svg" class=" c-pointer svg-icon-class ms-2" ngbTooltip="Email Verification Pending"
              *ngIf="registrationDto.auctionRegistrationStatus!.toString() == 'AWAITING_APPROVAL'">
            <img src="assets/icons/approved_green.svg" class=" c-pointer svg-icon-class ms-2" ngbTooltip="Email Verified"
              *ngIf="registrationDto.auctionRegistrationStatus!.toString() == 'APPROVED_BIDDERS'">
            <img src="assets/icons/rejected_red.svg" class=" c-pointer svg-icon-class ms-2"
              *ngIf="registrationDto.auctionRegistrationStatus!.toString() == 'REJECTED_BIDDERS'" ngbTooltip="Rejected">
            <button class="btn btn-warning btn-sm rounded-circle"
              *ngIf="registrationDto.auctionRegistrationStatus!.toString() == 'DISABLED_BIDDERS'">
              <img src="assets/icons/edit_dark.svg" class=" c-pointer svg-icon-class ms-2">
            </button>

          </td>
          <td class="font-weight-normal">{{registrationDto?.name}}</td>
          <td class="font-weight-normal btn-link text-lowercase"><a
              href="mailto:{{registrationDto?.emailId}}">{{registrationDto?.emailId}}</a></td>
          <td class="font-weight-normal btn-link"><a
              href="tel:{{registrationDto?.mobileNo}}">{{getCountryCode(registrationDto?.countryCode)}} {{registrationDto?.mobileNo}}</a></td>
          <td class="font-weight-normal">{{registrationDto?.registrationDate}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 mt-2" *ngIf="(bidderRegistrationList$ | async)!.length > 0">
    <div class="row justify-content-between">
      <div class="col-md-6 mb-2">
        <div class="row">
          <div class="col-md-7 col-sm-5 col-6 pe-0">
            <span class="span-class">Show rows per page </span>
          </div>
          <div class="col-md-5 col-6 ps-0">
            <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
              <span ngbDropdownToggle data-bs-toggle="dropdown">
                <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
              </span>
              <ul ngbDropdownMenu class="text-center shadow">

                <li ngbDropdownItem (click)="changePageSize(50)">
                  50
                </li>
                <hr class="hr" />
                <li ngbDropdownItem (click)="changePageSize(100)">
                  100
                </li>

              </ul>
            </div>

          </div>
        </div>

      </div>
      <div class="col-auto text-md-end mb-2">
        <ngb-pagination [collectionSize]="(bidderRegistrationList$ | async)?.length!" [(page)]="page"
          [pageSize]="pageSize" (pageChange)="paginateBidderHistoryList()">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>