import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Firestore, Timestamp, Unsubscribe, collection, onSnapshot, query, where } from '@angular/fire/firestore';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { AuctionSorting } from 'src/app/shared/enums/AuctionSorting';
import { Currency } from 'src/app/shared/models/Currency';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { TimeZoneDto } from 'src/app/shared/models/TimeZoneDto';
import { PurchaseRequestUiDto } from 'src/app/shared/models/rfx/PurchaseRequestUiDto';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { PurchaseRequestStatus } from 'src/app/shared/enums/rfx/PurchaseRequestStatus';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileService } from 'src/app/shared/services/file.service';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { UserService } from 'src/app/shared/services/user.service';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { Pattern } from 'src/app/shared/util/Patterns';
import { logEvent, getAnalytics } from '@angular/fire/analytics';
import { AppWorkflowUiDto } from 'src/app/shared/models/AppWorkflowUiDto';
import { ApprovalWorkflowStatusEnum } from 'src/app/shared/enums/ApprovalWorkflowStatusEnum';
import { DatePipe } from '@angular/common';
import { AddRfxModalComponent } from '../../rfx/add-rfx-modal/add-rfx-modal.component';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { AddAuctionModalComponent } from '../add-auction-modal/add-auction-modal.component';
import { MaterialUiDto } from 'src/app/shared/models/MaterialUiDto';
import { Store } from '@ngrx/store';
import { selectOrgOrganogramUiDto } from 'src/app/shared/state-management/onboarding/onboarding.features';
import { selectUserUiDto } from 'src/app/shared/state-management/session.features';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

interface CostCenter {
  name: string
  code: string
}
@Component({
  selector: 'app-admin-purchase-requests',
  templateUrl: './admin-purchase-requests.component.html',
  styleUrls: ['./admin-purchase-requests.component.sass']
})
export class AdminPurchaseRequestsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() type: string = 'PR';

  formGroup: FormGroup;
  selectedSortBy: string = AuctionSorting.AUCTION_CREATED_DATE_DESC;
  selectedStatus: string = 'LIVE';

  isEdit: boolean = false;
  isLoading: boolean = false;
  isApprovalLoading: boolean = false;
  isDataLoading: boolean = true;
  isSubmitted: boolean = false;
  isAllowEventCreation: boolean = false;
  page: number = 1;
  pageSize: number = 50;
  approveRequest?: string;
  purchaseRequestStatus?: PurchaseRequestStatus;

  ctrlSearchPR: FormControl = new FormControl('');
  ctrlComments: FormControl = new FormControl('');

  filterModalRef?: NgbModalRef;
  approvalModalRef?: NgbModalRef;

  currencies: Array<Currency> = [];
  timezones: Array<TimeZoneDto> = [];
  costCenters: CostCenter[] = [];
  selectedCurrency?: Currency;
  selectedPurchaseRequestUiDto?: PurchaseRequestUiDto;
  selectedPurchaseRequests: PurchaseRequestUiDto[] = [];
  organizationUiDto?: OrganizationWrapperUiDto;
  userEntityInfoDto?: UserUiDto;
  isCreatePr: boolean = false;
  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];
  appWorkflowUiDtoList: AppWorkflowUiDto[] = [];
  currentAppWorkflowUiDto?: AppWorkflowUiDto;
  isApprovalWorkflow: boolean = false
  isPurchaseRequestApproval: boolean = false
  allPurchaseRequests: PurchaseRequestUiDto[] = [];
  tempPurchaseRequests: PurchaseRequestUiDto[] = [];
  filteredPurchaseRequestsList$ = new BehaviorSubject<PurchaseRequestUiDto[]>([]);
  teamDialogRef?: DynamicDialogRef;
  plantUiDtos: PlantUiDto[] = [];
  plantUiDtosForForm: PlantUiDto[] = [];

  materialUiDtos: MaterialUiDto[] = [];
  selectedMaterialUiDtos: MaterialUiDto[] = [];

  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');
  isMultiLineError$ = new BehaviorSubject<boolean>(false);

  plantsListSubscription$?: Subscription;
  auctionTimeZoneSubscription$?: Subscription;
  organizationUiDtoSubscription$?: Subscription;
  purchaseRequestsSubscription$?: Subscription;
  materialsListSubscription$?: Subscription;

  currentFileInfoDto?: FileInfoDto | null;
  _fileDisplayName$ = new BehaviorSubject<string>("");
  currentFile?: File | null;
  selectedFileInfoDto?: FileInfoDto | null;
  externalPRTitle: FormControl = new FormControl('', Validators.required);
  documentTypes = ['application/pdf'];
  fileUploadError: boolean = false;
  errorMsg?: string | undefined;
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);
  isDraftAndSubmitButtonDisable: boolean = false;
  @ViewChild('externalPR') externalPR?: HTMLInputElement;

  collectionPurchaseRequests: string = "PURCHASE_REQUEST";
  prListenerUnsubscribe: Unsubscribe | undefined;

  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  tableActionItems= [
    { escape: false },
  ]

  paginationItems: MenuItem[] = [];

  constructor(
    private fileService: FileService,
    private formBuilder: FormBuilder,
    private ngbModal: NgbModal,
    private datePipe: DatePipe,
    private currencyService: CurrencyService,
    private adminDashboardService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    public onboardingService: OnboardingService,
    private firestore: Firestore,
    private dialogService: DialogService,
    private store: Store
  ) {
    this.formGroup = this.formBuilder.group({
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      plantCode: new FormControl('', Validators.required),
      costCenterCode: new FormControl('', Validators.required),
      estimatedDeliveryDate: new FormControl('', Validators.required),
      expectedPrice: new FormControl('', Validators.pattern(Pattern.numberGreaterZero)),
      materialUiDtos: new FormControl([], Validators.required),
      leadTime: new FormControl('', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]),
    });
  }

  ngOnInit(): void {
    this.costCenters = this.onboardingService.getOnboardingCostCenters;
    this.currencies = this.currencyService.getCurrencies;
    this.getUserUiDto()

    this.ctrlSearchPR.valueChanges.subscribe(() => {
      this.handleFilterAndSortData()
    });

    this.plantsListSubscription$ = this.onboardingService.getPlantUiDtos$.subscribe(data => {
      if (data) {
        // filter plants based on company code
        this.plantUiDtos = data;
        this.plantUiDtosForForm = data.filter(item => item.companyCode == this.userEntityInfoDto?.companyCode);
      } else {
        this.plantUiDtos = [];
        this.plantUiDtosForForm = [];
      }
    })

    this.organizationUiDtoSubscription$ = this.store.select(selectOrgOrganogramUiDto).subscribe((data) => {
      if (data) {
        this.organizationUiDto = ApplicationUtils.deepClone(data).organizationWrapperUiDto;
        this.getAndLoadPurchaseRequests();
      }
    });

    this.auctionTimeZoneSubscription$ = this.adminDashboardService.getMasterTimezones$.subscribe((data) => {
      if (data) {
        this.timezones = data;
      }
    });

    this.purchaseRequestsSubscription$ = this.adminSourcingEventsDataHolderService.getPurchaseRequestsList$.subscribe((data) => {
      if (data) {
        this.allPurchaseRequests = data;
        this.handleFilterAndSortData()
      }
    });

    this.materialsListSubscription$ = this.adminDashboardService.getMaterialUiDtos$.subscribe(data => {
      if (data) {
        this.materialUiDtos = data.map(item => MaterialUiDto.convertForPurchaseRequest(item));
      } else {
        this.materialUiDtos = [];
      }
    })
  }

  ngAfterViewInit(): void {
    this.attachPurchaseRequestChangeListener();

    this.paginationItems = [
      {
        items: [
          {
            label: '50',
            icon: 'pi pi-refresh',
            command: () => {
              this.changePageSize(50);
            }
          },
          {
            label: '100',
            command: () => {
              this.changePageSize(100);
            }

          }
        ]
          }
   
    ]



  }

  get fc() { return this.formGroup.controls; }

  async getUserUiDto() {
    this.userEntityInfoDto = await firstValueFrom(this.store.select(selectUserUiDto));
  }

  async getAndLoadPurchaseRequests() {
    console.log(this.userEntityInfoDto);

    this.isDataLoading = true;
    await this.adminSourcingEventsDataHolderService.loadPurchaseRequests(this.organizationUiDto?.orgCode!);
    await this.adminDashboardService.loadMaterialUiDtosByOrgCode(this.organizationUiDto?.orgCode!);
    await this.onboardingService.loadPlantUiDtosByOrgCode(this.organizationUiDto?.orgCode!);
    this.isDataLoading = false;
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll();
    }

    this.isSubmitted = false;
    this._validationErrorPresent$.next(false);
  }

  openApprovalWorkflowModal(purchaseRequest: PurchaseRequestUiDto) {
    this.selectedPurchaseRequestUiDto = purchaseRequest;
    // this.ngbModal.open(content, {
    //   size: 'xl',
    //   backdrop: 'static',
    //   keyboard: false,
    //   centered: true,
    // });

    this.isApprovalWorkflow = true

    this.getApprovalWorkflowUiDtos();
  }

  openCreatePrModal( purchaseRequestDto?: PurchaseRequestUiDto) {
    this.selectedPurchaseRequestUiDto = purchaseRequestDto;
    this.currentFile = undefined;
    this.currentFileInfoDto = undefined;
    this._fileDisplayName$.next('');

    if (this.formGroup) {
      this.formGroup.reset();
    }

    if (purchaseRequestDto) {
      this.isEdit = true;
      this.populateInitialData(purchaseRequestDto);
    } else {
      this.isEdit = false;
    }

    this.isCreatePr = true;
  }

  openAddRFXModal(rfxType: string) {
    // let modalRef = this.ngbModal.open(AddRfxModalComponent, {
    //   size: 'md',
    //   backdrop: 'static',
    //   keyboard: false,
    //   centered: true,
    // });

    this.teamDialogRef = this.dialogService.open(AddRfxModalComponent, {
      header: 'Add Project',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false
    });

    // modalRef.componentInstance.type = rfxType;
    // modalRef.componentInstance.purchaseRequestUiDto = sele;
  }

  openAddAuctionModal() {
    let errorCodeDtos: any[] = []//this.auctionValidationService.doAuctionHouseValidation(this.organizationUiDto!);
    if (errorCodeDtos.length > 0) {
      let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        centered: true,
      });
      modalRef.componentInstance.auctionValidationErrorCodeDtoList = errorCodeDtos;
      return;
    }

    let modalRef = this.ngbModal.open(AddAuctionModalComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });

    // modalRef.componentInstance.projectUiDto = projectUiDto;
  }

  openFilterModal(content: any) {
    this.filterModalRef = this.ngbModal.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  invalidPriceValidator(item: AbstractControl | null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }

  onSelectPurchaseRequest(purchaseRequest: PurchaseRequestUiDto, event: HTMLInputElement) {
    let index = this.selectedPurchaseRequests.findIndex(item => item.prId == purchaseRequest.prId);
    if (index != undefined && index > -1) {
      this.selectedPurchaseRequests.splice(index, 1);
    } else {
      this.selectedPurchaseRequests.push(purchaseRequest);
    }

    if (this.selectedPurchaseRequests.length > 0) {
      this.isAllowEventCreation = true;
    } else {
      this.isAllowEventCreation = false;
    }
  }

  onSelectMaterialUiDtos(event: any) {
    if (event.value && event.value.length > 0) {
      this.selectedMaterialUiDtos = this.materialUiDtos.filter(item => event.value.includes(item.materialId));
    } else {
      this.selectedMaterialUiDtos = [];
    }
  }

  populateInitialData(purchaseRequestDto: PurchaseRequestUiDto) {
    this.formGroup.controls['title'].setValue(purchaseRequestDto.title);
    this.formGroup.controls['description'].setValue(purchaseRequestDto.description);
    this.formGroup.controls['plantCode'].setValue(purchaseRequestDto?.plantCode);
    this.formGroup.controls['costCenterCode'].setValue(purchaseRequestDto.costCenterCode);
    this.formGroup.controls['expectedPrice'].setValue(purchaseRequestDto.expectedPrice);
    this.formGroup.controls['leadTime'].setValue(purchaseRequestDto.leadTime);

    if (purchaseRequestDto.materialUiDtos) {
      this.selectedMaterialUiDtos = purchaseRequestDto.materialUiDtos ?? [];
      this.formGroup.controls['materialUiDtos'].patchValue(this.selectedMaterialUiDtos.map(item => item.materialId));
    }

    let estimatedDeliveryDate = ApplicationUtils.getNgbPickerDate(purchaseRequestDto.estimatedDeliveryDate!);
    this.formGroup.get('estimatedDeliveryDate')?.patchValue(estimatedDeliveryDate);

    if (purchaseRequestDto.fileInfoDtos && purchaseRequestDto.fileInfoDtos.length > 0) {
      this.currentFileInfoDto = purchaseRequestDto.fileInfoDtos[0];
      this._fileDisplayName$.next(this.currentFileInfoDto?.fileName ?? '');
    }

    this.formGroup.updateValueAndValidity();
  }

  populateDocumentData() {
    this.selectedFileInfoDto = this.currentFileInfoDto;
    this.externalPRTitle.setValue(this.currentFileInfoDto?.displayName);
    this._fileDisplayName$.next(this.currentFileInfoDto?.fileName!);
  }

  chooseFile(event: any) {
    this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this._showErrorToast$.next(true);
      this.currentFile = null;
      this.externalPR?.setAttribute('value', '');
      this._fileDisplayName$.next('');

      return;
    }

    this._fileDisplayName$.next(this.getFileName(this.currentFile?.name!) as string);
  }

  getFileName(fileName: string) {
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0, fileName.lastIndexOf('.'));
    let finalFileName = ApplicationUtils.removeSpecialCharacters(name!) as string
    return finalFileName + "" + extensionName;
  }

  removeAuctionDoc() {
    this.currentFileInfoDto = null;
    this.currentFile = null;
    this.externalPR?.setAttribute('value', '');
    this._fileDisplayName$.next("");
  }

  previewAuctionDoc(type: string) {
    if (type == 'LOCAL') {
      var fileURL = URL.createObjectURL(this.currentFile!);
      window.open(fileURL, '_blank');

    } else if (type == 'SERVER') {
      this.isLoading = true;

      this.fileService.downloadFile(this.currentFileInfoDto?.fileId!).subscribe({
        next: (response) => {
          if (response) {
            this.isLoading = false;
            let file = new Blob([response], { type: this.currentFileInfoDto?.fileType });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
          }
        },
        error: (err) => {
          console.error(err);
          console.log("Error while previewing document");
        }
      });
    }
  }

  mergePurchaseRequestDto(formValue: any) {
    let purchaseRequestDto = new PurchaseRequestUiDto();

    if (this.selectedPurchaseRequestUiDto) {
      purchaseRequestDto = ApplicationUtils.clone(this.selectedPurchaseRequestUiDto);
    }

    purchaseRequestDto.orgCode = this.userEntityInfoDto?.orgCode;
    purchaseRequestDto.companyCode = this.userEntityInfoDto?.companyCode;
    purchaseRequestDto.plantCode = formValue.plantCode;
    purchaseRequestDto.costCenterCode = formValue.costCenterCode;
    purchaseRequestDto.title = formValue.title;
    purchaseRequestDto.description = formValue.description;
    purchaseRequestDto.leadTime = formValue.leadTime;
    purchaseRequestDto.expectedPrice = Number(ApplicationUtils.resetPriceFormat(formValue.expectedPrice));

    if (!purchaseRequestDto.fileInfoDtos) {
      purchaseRequestDto.fileInfoDtos = []
    }
    purchaseRequestDto.fileInfoDtos = [this.currentFileInfoDto!];

    purchaseRequestDto.materialUiDtos = this.selectedMaterialUiDtos;

    let estimatedDeliveryDate = ApplicationUtils.getDateFromNgDatePicker(
      this.datePipe,
      formValue.estimatedDeliveryDate
    );
    purchaseRequestDto.estimatedDeliveryDate = estimatedDeliveryDate!;

    return purchaseRequestDto;
  }

  handleValidSubmit(status: string) {
    if (this.isDraftAndSubmitButtonDisable) {
      return;
    }

    this.purchaseRequestStatus = status as PurchaseRequestStatus;
    this.auctionValidationErrorCodeDtoList = [];
    this._validationErrorPresent$.next(false);
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched()
      this._validationErrorPresent$.next(true);
      return;
    }

    let materialUiDtos = this.formGroup.controls['materialUiDtos'].value ?? [];
    if (materialUiDtos.length == 0) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this._showErrorToast$.next(false);
    this.isMultiLineError$.next(false);
    this._errorMsg$.next("");
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    if (this.currentFile) {
      this.uploadFile(this.currentFile!);
    } else {
      this.addPurchaseRequest();
    }
  }

  uploadFile(currentFile: File) {
    this.fileUploadError = false;
    this.errorMsg = "";

    let metaData = {
      'mimeType': currentFile.type,
      'version': 0,
      'publicApi': true,
      'dataType': 'Square'
    };

    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          this.currentFileInfoDto = new FileInfoDto();
          this.currentFileInfoDto!.fileId = fileSaveResponseDto.fileId;
          this.currentFileInfoDto!.fileName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto!.fileSize = currentFile.size.toString();
          this.currentFileInfoDto!.fileType = currentFile.type;
          this.currentFileInfoDto!.uploadDate = new Date().toDateString();
          this.currentFileInfoDto!.displayName = fileSaveResponseDto.fileName;

          this.addPurchaseRequest();
        }
      } else {
        this.fileUploadError = true;
        this._isSaveButtonDisable$.next(false);
      }
    })
  }

  addPurchaseRequest() {
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");

    let formValue = this.formGroup.getRawValue();
    let purchaseRequestDto = this.mergePurchaseRequestDto(formValue);
    purchaseRequestDto.status = this.purchaseRequestStatus;

    this.adminSourcingEventsDataHolderService.savePurchaseRequestDetails(purchaseRequestDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as PurchaseRequestUiDto;

          this.adminSourcingEventsDataHolderService.updatePurchaseRequest(data);

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000);

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next('Error while saving PR. Try again.');
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      },
    });
  }



  markAsApproved(purchaseRequestDto: PurchaseRequestUiDto) {
    this.isLoading = true;
    purchaseRequestDto.status = PurchaseRequestStatus.APPROVAL_APPROVED;

    this.adminSourcingEventsDataHolderService.savePurchaseRequestDetails(purchaseRequestDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as PurchaseRequestUiDto;
          this.adminSourcingEventsDataHolderService.updatePurchaseRequest(data);
          this.isLoading = false;

        } else {
          this.isLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  approvePurchaseRequest() {
    this._showErrorToast$.next(false);
    this._errorMsg$.next('');

    this.isApprovalLoading = true;

    let appWorkflowUiDto = ApplicationUtils.clone(this.currentAppWorkflowUiDto) as AppWorkflowUiDto;
    appWorkflowUiDto.comment = this.ctrlComments.value;

    if (this.approveRequest == 'APPROVE') {
      appWorkflowUiDto.status = ApprovalWorkflowStatusEnum.APPROVED;
    } else {
      appWorkflowUiDto.status = ApprovalWorkflowStatusEnum.REJECTED;
    }

    this.adminDashboardService.updateApprovalWorkflowUiDtos(appWorkflowUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as AppWorkflowUiDto;

          Object.assign(this.currentAppWorkflowUiDto!, data);
          let index = this.appWorkflowUiDtoList.findIndex(item => item.emailId == this.userEntityInfoDto?.primaryEmailId);
          if (index > -1) {
            Object.assign(this.appWorkflowUiDtoList[index], data);
          }

          this._showSuccessToast$.next(true);
          this.isApprovalLoading = false;
          this._isSaveButtonEnable$.next(true);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.approvalModalRef);
          }, 2000);

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isApprovalLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next('Error while updating PR. Try again.');
        this.isApprovalLoading = false;
        this._isSaveButtonEnable$.next(true);
      },
    });
  }



  openPrApprovalConfirmModal(approveRequest: string) {
    this.approveRequest = approveRequest;
    // this.approvalModalRef = this.ngbModal.open(content, {
    //   size: 'md', backdrop: 'static', keyboard: false, centered: true
    // });
  
    this.isPurchaseRequestApproval = true

  }

  isShowApprovedButton(index: number) {
    let sequence = this.currentAppWorkflowUiDto?.sequence ?? 0;
    if (sequence == 1 && index == 0) {
      return this.currentAppWorkflowUiDto?.status == 'PENDING';
    }

    if (sequence == 2 && index == 1) {
      let firstApproved = this.appWorkflowUiDtoList[0].status == 'APPROVED';
      return this.currentAppWorkflowUiDto?.status == 'PENDING' && firstApproved;
    }

    if (sequence == 3 && index == 2) {
      let firstApproved = this.appWorkflowUiDtoList[0].status == 'APPROVED';
      let secondApproved = this.appWorkflowUiDtoList[1].status == 'APPROVED';
      return this.currentAppWorkflowUiDto?.status == 'PENDING' && firstApproved && secondApproved;
    }

    return false;
  }

  async getApprovalWorkflowUiDtos() {
    this.isLoading = true;
    try {
      let apiResponseDto = await firstValueFrom(this.adminDashboardService.getApprovalWorkflowUiDtos(this.selectedPurchaseRequestUiDto?.prCode!, 'PURCHASE_REQUEST'));

      this.isLoading = false;

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.appWorkflowUiDtoList = apiResponseDto.data as AppWorkflowUiDto[];
        this.refreshApprovalWorkflowUi();
      } else {
        console.error("Error while calling getApprovalWorkflowUiDtos : " + JSON.stringify(apiResponseDto));
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  refreshApprovalWorkflowUi() {
    this.appWorkflowUiDtoList = this.appWorkflowUiDtoList.filter(item => item.entityId == this.selectedPurchaseRequestUiDto?.prCode);
    this.appWorkflowUiDtoList = this.appWorkflowUiDtoList.sort((a, b) => Number(a.sequence) - Number(b.sequence));
    this.currentAppWorkflowUiDto = this.appWorkflowUiDtoList.find(item => item.emailId == this.userEntityInfoDto?.primaryEmailId);
  }

  attachPurchaseRequestChangeListener() {
    let lastFetchTimestamp = Timestamp.now();

    logEvent(getAnalytics(), "attachPurchaseRequestChangeListener");

    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let auctionRef = collection(this.firestore, this.collectionPurchaseRequests);
      let auctionQuery = query(auctionRef,
        where('orgCode', '==', orgCode),
        where('updateTimeUtc', '>', lastFetchTimestamp));

      this.prListenerUnsubscribe = onSnapshot(auctionQuery, documentSnapshots => {
        let purchaseRequestList = documentSnapshots.docChanges().map(change => change.doc.data() as PurchaseRequestUiDto);

        logEvent(getAnalytics(), 'attachPurchaseRequestChangeListener Data Received size : ' + purchaseRequestList.length);
        console.log("attachPurchaseRequestChangeListener Data Received size: " + purchaseRequestList.length);

        if (purchaseRequestList && purchaseRequestList.length > 0) {
          purchaseRequestList.forEach(updatedAuctionDto => {
            let timestamp = updatedAuctionDto.updateTimeUtc as Timestamp;
            updatedAuctionDto.updateTimeUtc = (timestamp.seconds * 1000);
          })

          this.adminSourcingEventsDataHolderService.updatePurchaseRequestsList(purchaseRequestList);
        }
      });
    }
  }

  getPlantName(companyCode: string, plantCode: string) {
    if (this.plantUiDtos.length > 0) {
      let plant = this.plantUiDtos.find(item => item.companyCode == companyCode && item.plantCode == plantCode)
      return `${plant?.plantName}-(${plant?.plantCode})`
    }
    return '';
  }

  formatPrStatus(status: string) {
    if (status == PurchaseRequestStatus.APPROVAL_PENDING) {
      return 'Approval Pending';
    } else if (status == PurchaseRequestStatus.APPROVAL_APPROVED) {
      return 'Approval Approved';
    } else if (status == PurchaseRequestStatus.COMPLETED) {
      return 'Completed';
    } else if (status == PurchaseRequestStatus.SUBMITTED) {
      return 'Submitted';
    } else if (status == PurchaseRequestStatus.DRAFT) {
      return 'Draft';
    }
    return ''
  }

  getApprovalWorkflowStatusClass(status?: ApprovalWorkflowStatusEnum) {
    if (status == ApprovalWorkflowStatusEnum.APPROVED) {
      return 'text-green-500 ';
    } else if (status == ApprovalWorkflowStatusEnum.REJECTED) {
      return 'text-red-500 ';
    } else {
      return 'text-orange-500 ';
    }
  }

  changePageSize(size: number) {
    this.pageSize = size;
  }

  isFilterApplied() {
    // return this.selectedEventStatusFilter.length > 0 || this.selectedEventTypeFilter.length > 0;
    return false;
  }

  clearAllFilters() {
    // this.selectedEventStatusFilter = [];
    // this.selectedEventTypeFilter = [];
    this.ctrlSearchPR.patchValue('');

    this.ctrlSearchPR.reset();

    this.handleFilterAndSortData();
  }

  handleFilterAndSortData() {
    this.tempPurchaseRequests = this.allPurchaseRequests;

    // Do Filter
    this.doFilter();

    // Do Search
    this.doSearch();

    // Sorting
    // this.doSorting()

    this.doPaginate();

    if (this.filterModalRef) {
      this.filterModalRef.close();
    }
  }

  doFilter() {
    // if (this.selectedEventTypeFilter.length > 0) {
    //   this.tempPurchaseRequests = this.tempPurchaseRequests.filter(item => this.selectedEventTypeFilter.includes(item.type!));
    // }
    // if (this.selectedEventStatusFilter.length > 0) {
    //   this.tempPurchaseRequests = this.tempPurchaseRequests.filter(item => this.selectedEventStatusFilter.includes(item.status!));
    // }
  }

  doSearch() {
    if (this.ctrlSearchPR.value != null && this.ctrlSearchPR.value != '') {
      let searchValue = this.ctrlSearchPR.value.trim().toLowerCase();

      let tempAuctionList = this.tempPurchaseRequests.filter(
        (item) => item.title?.toLowerCase().includes(searchValue)
      );

      this.tempPurchaseRequests = tempAuctionList;
    }
  }

  // doSorting() {
  //   let purchaseRequestsList: PurchaseRequestUiDto[] = [...this.tempPurchaseRequests];

  //   if (this.selectedSortBy == AuctionSorting.AUCTION_END_DATE_DESC) {
  //     let closeAuction = purchaseRequestsList?.filter((item) => item.type == EventEnum.AUCTION)
  //       .sort((a, b) => +ApplicationUtils.convertedDate(b.endDate!, b.endTime!)! - +ApplicationUtils.convertedDate(a.endDate!, a.endTime!)!);
  //     let remainingAuction = purchaseRequestsList?.filter((item) => item.type != EventEnum.AUCTION)
  //       .sort((a, b) => +ApplicationUtils.convertedDate(b.endDate!, b.endTime!)! - +ApplicationUtils.convertedDate(a.endDate!, a.endTime!)!);
  //     let combineAuction = remainingAuction.concat(closeAuction);
  //     this.tempPurchaseRequests = combineAuction;
  //   } else if (this.selectedSortBy == AuctionSorting.AUCTION_CREATED_DATE_DESC) {
  //     let auctions = purchaseRequestsList?.sort(
  //       (a, b) => Date.parse(b.createdDate!) - Date.parse(a.createdDate!)
  //     );
  //     this.tempPurchaseRequests = auctions;
  //   }
  // }

  doPaginate() {
    if (this.tempPurchaseRequests && this.tempPurchaseRequests.length > 0) {
      this.filteredPurchaseRequestsList$.next(this.tempPurchaseRequests.map((history, i) => ({ id: i + 1, ...history })).slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      ));
    } else {
      this.filteredPurchaseRequestsList$.next([]);
    }
  }

  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getSortDisplayDateAdmin(date, time);
  }

  getCreatedDate(timestamp?: string) {
    if (timestamp) {
      var stillUtc = moment.utc(timestamp).toDate();
      var localDate = moment(stillUtc).local()

      let date = localDate.toDate().toDateString();
      let time = localDate.toDate().toTimeString();
      return ApplicationUtils.getSortDisplayDateAdmin(date, time);
    }
    return '';
  }

  getMetadataDate(timestamp?: Timestamp | null) {
    if (timestamp) {
      let createdDate = timestamp.toDate();
      let date = createdDate.toDateString();
      let time = createdDate.toTimeString();
      return ApplicationUtils.getSortDisplayDateAdmin(date, time);
    }
    return '';
  }

  getTimeZone(dbTimeZone: string) {
    if (dbTimeZone && this.timezones.length > 0) {
      let utcCode = this.timezones.find((item) => item.locale == dbTimeZone)?.utcCode;
      return utcCode?.replaceAll('UTC', '');
    }
    return ''
  }

  ngOnDestroy(): void {
    if (this.plantsListSubscription$) {
      this.plantsListSubscription$.unsubscribe();
    }
    if (this.materialsListSubscription$) {
      this.materialsListSubscription$.unsubscribe();
    }
    if (this.teamDialogRef) {
      this.teamDialogRef.close();
    }
  }
}
