<div class=" m-4">
  <div class="grid grid-cols-1 md:grid-cols-12 gap-4 w-full items-center justify-between">
    <div class="md:col-span-3">
      <div class="flex w-full mb-3">
        <input
          class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0"
          #search id="search" placeholder="Search">
        <div class="input-group-append">
          <span class="input-group-text border-solid border-slate-50 border-l-0 rounded-l-lg h-8 bg-white"
            id="basic-addon2">
            <i-lucide name="search" [size]="16" class="my-icon c-pointer"></i-lucide>
          </span>
        </div>
      </div>
    </div>
    <div class="md:col-span-9 text-right">
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
       (click)="openAddProjectModal()">
        Add Projects
      </button>

    </div>
  </div>
  
  <div class="card bg-white">
    <p-treeTable [value]="tableTreeData" [scrollable]="true" [paginator]="true" [rows]="50"
      [rowsPerPageOptions]="[50, 100]" [loading]="isDataLoading" [tableStyle]="{'min-width':'50rem'}">
      <ng-template pTemplate="header">
        <tr>
          <th>Event Code</th>
          <th>Name</th>
          <th>Type</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr [ttRow]="rowNode">
          <td>
            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            {{ rowData.code }}
          </td>
          <td>{{ rowData.name }}</td>
          <td>{{ rowData.type }}</td>
          <td *ngIf="rowData.type != 'PR'">
            <div class="" [ngClass]="{'border-left-success' : rowData?.status! == 'LIVE', 'border-left-grey' : rowData?.status! == 'DRAFT' , 'border-left-danger' : rowData?.status! == 'CLOSE'
            , 'border-left-primary' : rowData?.status! == 'LIVE_WAIT'}">
                <span class="ms-2">{{ rowData.status }}</span>
              </div>
          </td>
          <td *ngIf="rowData.type == 'PR'">
            <div class="" [ngClass]="{'border-left-success' : rowData?.status! == 'COMPLETED', 'border-left-grey' : rowData?.status! == 'APPROVAL_PENDING' , 'border-left-info' : rowData?.status! == 'APPROVAL_APPROVED'
            , 'border-left-primary' : rowData?.status! == 'SUBMITTED'}">
                <span class="ms-2">{{ formatPrStatus(rowData.status) }}</span>
              </div>
            </td>
          <td>
            <span>
              <p-menu #menu [model]="createNewMenuItems" [popup]="true">
                <ng-template pTemplate="item" let-item>
                  <div class="p-menuitem-link p-3 grid"  (click)="openAddProjectModal(rowData)">
                    <div class="flex-row flex">
                      <div class="basis-2/12">
                        <img src="assets/icons/tender_bid.svg" />
                      </div>
                      <div class="basis-10/12">
                        <div class="text-gray-900 font-semibold text-sm">
                          View
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-menuitem-link p-3 grid"  (click)="openAddRFXModal('PQ', rowData)">
                    <div class="flex-row flex">
                      <div class="basis-2/12">
                        <img src="assets/icons/tender_bid.svg" />
                      </div>
                      <div class="basis-10/12">
                        <div class="text-gray-900 font-semibold text-sm">
                          PQ
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-menuitem-link p-3 grid"  (click)="openAddRFXModal('RFQ', rowData)">
                    <div class="flex-row flex">
                      <div class="basis-2/12">
                        <img src="assets/icons/tender_bid.svg" />
                      </div>
                      <div class="basis-10/12">
                        <div class="text-gray-900 font-semibold text-sm">
                          RFQ
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-menuitem-link p-3 grid"  (click)="openAddAuctionModal(rowData)">
                    <div class="flex-row flex">
                      <div class="basis-2/12">
                        <img src="assets/icons/tender_bid.svg" />
                      </div>
                      <div class="basis-10/12">
                        <div class="text-gray-900 font-semibold text-sm">
                          Auction
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </ng-template>
              </p-menu>
              <span (click)="menu.toggle($event)">
                <img class="tr-s-icon" src="/assets/icons/elipsis_horizontal_light.svg" />
              </span>
             
            </span>
            <!-- <div class="animate-dropdown" ngbDropdown #menuDropdown="ngbDropdown" container="body"
              placement="bottom-right" *ngIf="rowData.type == 'PROJECT'">
              <span ngbDropdownToggle data-bs-toggle="dropdown">
                <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class-small">
              </span>
              <ul ngbDropdownMenu class="shadow mt-1 ">
                <li ngbDropdownItem (click)="openAddProjectModal(rowData)">
                  View
                </li>
                <hr class="hr my-2">
                <li ngbDropdownItem (click)="openAddRFXModal('PQ', rowData)">
                  PQ
                </li>
                <hr class="hr my-2">
                <li ngbDropdownItem (click)="openAddRFXModal('RFQ', rowData)">
                  RFQ
                </li>
                <hr class="hr my-2">
                <li ngbDropdownItem (click)="openAddAuctionModal(rowData)">
                  Auction
                </li>
              </ul>
            </div> -->
            <div *ngIf="rowData.type != 'PROJECT' && rowData.type != 'PR'">
              <a class="link-class" (click)="openEventStagePage(rowNode.node, rowNode.parent)"
                *ngIf="currentEventCode != rowData.code">View</a>
              <div class="spinner-border text-primary" role="status" *ngIf="rowData.code == currentEventCode && isNavLoading">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </div>
</div>

<p-dialog header="{{selectedProjectUiDto ? 'Edit' : 'Add'}} Project" [(visible)]="isAddProject" [style]="{width: '50vw'}">
  <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="addProject()">
    <div class="mb-3">
      <label for="name"  class="text-gray-700  text-sm font-semibold leading-4">Name</label>
      <input type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm" id="name" formControlName="name"
        [ngClass]="{'is-invalid': fc['name'].invalid && (fc['name'].dirty || fc['name'].touched)}">
      <div *ngIf="fc['name'].invalid && (fc['name'].dirty || fc['name'].touched)">
        <p class="text-red-500 " *ngIf="fc['name'].errors?.['required']">Required
        </p>
      </div>
    </div>

    <label for="description"  class="text-gray-700  text-sm font-semibold leading-4">Description</label>
    <div class="mb-3">
      <textarea type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-12 px-2 py-0 text-gray-100 text-sm" id="description" formControlName="description" rows="3"
        [ngClass]="{'is-invalid': fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)}"></textarea>
      <div *ngIf="fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)">
        <p class="text-red-500 " *ngIf="fc['description'].errors?.['required']">Required
        </p>
      </div>
    </div>

    <div class="col-12 mb-3">
      <label for="file-upload" class="custom-file-upload">
        <div class="image-display">
          <img src="assets/icons/cloud-upload.svg" class="svg-icon-class-md c-pointer me-2">
        </div>
        Drag or drop here or browse file to upload
      </label>
      <input id="file-upload" type="file" onclick="this.value=null;" (change)="chooseFile($event)" />
    </div>

    <div class="col-12 mb-3">
      <div class="flex row-flex medium-grey-font px-2" *ngFor="let document of selectedDocuments; index as i">
        <div class="basis-8/12">
          <img src="assets/icons/file_danger.svg" class="svg-icon-class inline-flex c-pointer">
          {{document.displayName}}
        </div>
        <div class="basis-3/12 text-end">
          {{formatBytes(document.fileSize)}}
        </div>
        <div class="basis-1/12 text-end">
          <img src="assets/icons/delete_dark.svg" class="svg-icon-class-small inline-flex c-pointer"
            (click)="removeSelectedDocument(document.fileId!)">
        </div>
      </div>

      <div class="mb-3" *ngIf="isUploadLoading">
        <div class="e-spinner"></div>
      </div>
    </div>

    <div class="my-3">
      <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async">
      </app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
        message="Project {{selectedProjectUiDto ? 'updated' : 'created'}} successfully.">
      </app-message-toast>
    </div>

    
    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner"></div>
    </div>
    <div class="text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
      <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="addProject()">
        {{selectedProjectUiDto ? 'Update' : 'Save'}}
      </button>
    </div>


  </form>
</p-dialog>

