import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationStartupDto } from 'src/app/shared/models/ApplicationStartupDto';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationStartupCacheService } from 'src/app/shared/services/application-startup-cache.service';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-onboarding-users',
  templateUrl: './onboarding-users.component.html',
  styleUrls: ['./onboarding-users.component.sass']
})
export class OnboardingUsersComponent implements OnInit, AfterViewInit, OnDestroy {
  formGroup: FormGroup;
  ctrlSearch: FormControl = new FormControl('')
  currentTab: string = 'MANAGE_USERS';
  isDataLoading: boolean = true;
  isLoading: boolean = false;
  isShowUserView: boolean = false;

  applicationStartupDto?: ApplicationStartupDto;
  selectedCompanyUiDto?: CompanyUiDto;
  selectedUserUiDto?: UserUiDto;
  userUiDtos: UserUiDto[] = [];
  filteredUserUiDtos: UserUiDto[] = [];

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _filteredUserUiDtos$ = new BehaviorSubject<UserUiDto[]>([]);

  usersListSubscription$?: Subscription;
  selectedCompanySubscription$?: Subscription;

  userActiveFlag: boolean = false;
  tableActionItems: MenuItem[] = [];

  totalUsersCount: number = 0
  activeUsersCount: number = 0
  inActiveUsersCount: number = 0


  createNewMenuItems = [
    { escape: false },
  ]

  constructor(
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private changeDetectRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private appStartupService: ApplicationStartupCacheService,
    private drawerService: DrawerService,
    private adminDashboardService: AdminDashboardService,
  ) {
    this.formGroup = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      designation: ['', Validators.required],
      empCode: [''],
      primaryEmailId: ['', [Validators.required, Validators.pattern(Pattern.email)]],
      plantCodes: ['']
    })
  }

  ngOnInit(): void {
    this.applicationStartupDto = this.appStartupService.getApplicationStartupDto;

    this.usersListSubscription$ = this.onboardingService.getUserUiDtos$.subscribe(data => {
      if (data) {
        this.userUiDtos = data;
        this.refreshUsersList();

        if (this.selectedUserUiDto) {
          this.selectedUserUiDto = this.userUiDtos.find(item => item.userId == this.selectedUserUiDto?.userId);
        }
      } else {
        this.userUiDtos = [];
        this.filteredUserUiDtos = this.userUiDtos;
        this._filteredUserUiDtos$.next(this.filteredUserUiDtos);
      }
    })

    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
        this.loadUserEntityInfoDto();
      } else {
        this.selectedCompanyUiDto = undefined;
      }
    })

    this.ctrlSearch.valueChanges.subscribe((val) => {
      this.search(val);
    })
  }

  ngAfterViewInit(): void {

    this.tableActionItems = [
      {
        label: 'View',
        // icon: 'pi pi-refresh',
        // command: () => {
        //   this.viewUserDetails(item);
        // }
      },
      {
        label: '{{item?.active ? "InActive" : "Active"}}',
        // command: () => {
        //   this.openConfirmationModal(item);
        // }

      },
      {
        label: 'ExtendRole',
        // command: () => {
        //   this.openExtendProfileDrawer($event, item);
        // }

      }
   
    ]

  }


  toggleDetailsTab(tab: string) {
    this.currentTab = tab;
  }

  formattedDesignation(userUiDto: UserUiDto) {
    if (userUiDto) {
      let cacheDesignationsDtos = this.applicationStartupDto?.cacheDesignationsDtos ?? [];
      return cacheDesignationsDtos.find(item => item.code == userUiDto.designation)?.name;
    }
    return '';
  }

  markResourceActiveOrInActive(action: string) {
    let active = action == "ENABLE";
    this._showErrorToast$.next(false);

    this.isLoading = true;
    this.adminDashboardService.markResourceActiveOrInActive(this.selectedUserUiDto?.userId!, active, 'ADMIN').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.onboardingService.updateUserEntityInfoDto(apiResponseDto.data as UserUiDto);
          this._showSuccessToast$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.isLoading = false;
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err: any) => {
        console.error(err);
        this.isLoading = false;
      }
    })
  }

  openConfirmationModal(content: any, selectedUserEntityInfoDto: UserUiDto) {
    this.selectedUserUiDto = selectedUserEntityInfoDto;
    this.userActiveFlag = selectedUserEntityInfoDto.active;
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openNewUserDrawer(event: any) {
    event.stopPropagation();
    this.drawerService.openDrawer('ONBOARDING_NEW_USER');
  }

  openExtendProfileDrawer(event: any, userUiDto: UserUiDto) {
    event.stopPropagation();
    this.onboardingService.setSelectedUserUiDto(userUiDto!)
    this.drawerService.openDrawer('USER_EXTEND_PROFILE');
  }

  get fc(): any { return this.formGroup.controls; }

  async loadUserEntityInfoDto() {
    let orgCode = this.onboardingService.getOrgCode();
    this.isDataLoading = true;
    await this.onboardingService.loadOnboardingUsersList(orgCode!);
    await this.onboardingService.loadOnboardingTeamsList(orgCode!, this.selectedCompanyUiDto?.companyCode!);
    this.isDataLoading = false;
  }

  refreshUsersList() {
    if (this.selectedCompanyUiDto) {
      this.filteredUserUiDtos = this.userUiDtos.filter(item => this.isCurrentCompanyPrivilegeAvailable(item, this.selectedCompanyUiDto?.companyCode));
      this._filteredUserUiDtos$.next(this.filteredUserUiDtos);

      this.totalUsersCount = this.getTotalUserCount();
      this.activeUsersCount = this.getActiveUserCount();
      this.inActiveUsersCount = this.getInActiveUserCount()
    } else {
      // this.filteredUserUiDtos = this.userUiDtos;
      // this._filteredUserUiDtos$.next(this.filteredUserUiDtos);
    }
  }

  getTotalUserCount() {
    if (this.filteredUserUiDtos && this.filteredUserUiDtos.length > 0) {
      return this.filteredUserUiDtos.length;
    }

    return 0;
  }

  getActiveUserCount() {
    if (this.filteredUserUiDtos && this.filteredUserUiDtos.length > 0) {
      return this.filteredUserUiDtos.filter(item => item.active).length;
    }
    return 0;
  }

  getInActiveUserCount() {
    if (this.filteredUserUiDtos && this.filteredUserUiDtos.length > 0) {
      return this.filteredUserUiDtos.filter(item => !item.active).length;
    }
    return 0;
  }

  isCurrentCompanyPrivilegeAvailable(userUiDto: UserUiDto, selectedCompanyCode?: string){
    return userUiDto.companyCode == selectedCompanyCode;

  }

  search(searchText: string) {
    let userEntityInfoDtos = [...this.userUiDtos ?? []];

    if (searchText.trim() != '') {
      let filteredUserEntityInfoDtos = userEntityInfoDtos.filter((item) =>
        item.firstName?.toLowerCase().includes(searchText.trim().toLowerCase())
        || item.lastName?.toLowerCase()?.includes(searchText.trim())
        //TODO s|| this.getUserPlantsRoleUiDto(item).companyName?.toLowerCase()?.includes(searchText.trim())
        || item.companyCode?.toLowerCase()?.includes(searchText.trim())
        || item.primaryEmailId?.toLowerCase()?.includes(searchText.trim())
      );

      this.filteredUserUiDtos = filteredUserEntityInfoDtos;
    } else {
      this.filteredUserUiDtos = userEntityInfoDtos;
    }

    this._filteredUserUiDtos$.next(this.filteredUserUiDtos);
  }

  viewUserDetails(userUiDto: UserUiDto) {
    this.isShowUserView = true;
    this.onboardingService.setSelectedUserUiDto(userUiDto);
    this.drawerService.isFormEditMode(true);
    this.drawerService.openDrawer('ONBOARDING_NEW_USER');
  }

  backToAllUsersList() {
    this.isShowUserView = false;
    this.selectedUserUiDto = undefined;
  }

  openUserDetail(content: any) {
    this.ngbModal.open(content, { size: 'lg', backdrop: 'static', keyboard: false, centered: true })
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  ngOnDestroy(): void {
    if (this.usersListSubscription$) {
      this.usersListSubscription$.unsubscribe();
    }
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
  }
}
