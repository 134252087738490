<div class="h-screen">
  <div class=" p-6 tr-overflow-y-drawer ">
    <div class=" bg-white flex-col self-stretch rounded-t-xl border border-solid border-slate-50">
      <div class="p-4">
        <div class="flex py-0 h-11 items-center self-stretch gap-2.5 rounded-t-xl mb-4">
          <div class="grid grid-cols-1 w-full">
            <div class="text-gry-400 text-xs font-semibold mb-2">
              STEP 1
            </div>
            <div class="text-gray-800 font-semibold leading-4 tracking-tight text-base">
              Configure approval workflow
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12  gap-4 w-full mb-4">
          <div class="col-span-3">
            <label for="materialId" class="text-gray-700 text-xs font-medium leading-4">
              Number of approvals
            </label>
            <select name="Tender type" id="tenderType" [formControl]="ctrlSelectedApprovals"
              class="bg-white w-full rounded-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select">
              <option value="" Selected disabled>Select . . .</option>
              <option [value]="item" *ngFor="let item of noOfApprovals;">{{item}}</option>
            </select>
          </div>
          <div class="col-span-3">
            <label for="materialId" class="text-gray-700 text-xs font-medium leading-4">
              Select mandatory approvals
            </label>
            <select name="Tender type" id="tenderType" [formControl]="ctrlMandatoryApprovals"
              class="bg-white w-full rounded-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-500 font-medium text-sm form-select">
              <option value="" Selected disabled>Select . . .</option>
              <option [value]="item" *ngFor="let item of noOfMandatoryApprovals;">{{item}}</option>
            </select>
          </div>
        </div>
        <div class="grid grid-cols-12  gap-4 w-full mb-4"
          *ngIf="ctrlSelectedApprovals.value && ctrlSelectedApprovals.value > 0">
          <div class="col-span-3" *ngFor="let item of noOfMandatoryApprovals;">
            <div class="bg-gray-100 rounded-lg p-3">
              <label for="materialId" class="text-gray-700 text-xs font-medium leading-4">
                Select approver {{item}}
              </label>
              <select #approvalUser name="Tender type" id="tenderType"
                (change)="onChangeUserSelect(approvalUser.value, item)"
                class="bg-white w-full rounded-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-600 font-normal text-sm form-select">
                <option value="" Selected disabled>Email Id</option>
                <option [value]="userInfoUiDto.userId" [disabled]="selectedUserIds.has(userInfoUiDto.userId!)"
                  [selected]="getSelectedUserId(item) == userInfoUiDto.userId"
                  *ngFor="let userInfoUiDto of userInfoUiDtos;">
                  {{userInfoUiDto.emailId}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex flex-row gap-4 mb-8">
          <button type="button" (click)="resetApprovals()"
            class=" py-1 px-3 me-3 inline-flex text-xs font-medium text-gray-500 focus:outline-none bg-white rounded-lg border border-gray-500 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
            Reset</button>
          <button type="button" (click)="addAndVerifyApprovals()"
            class=" py-1 px-3 inline-flex text-xs font-medium text-blue-500 focus:outline-none bg-white rounded-lg border border-blue-500 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
            Add & Verify</button>
        </div>
        <div class="grid grid-cols-1  w-full mb-4">
          <div class="text-gry-400 text-xs font-semibold mb-2">
            STEP 2
          </div>
          <div class="text-gray-800 font-semibold leading-4 tracking-tight text-base">
            Verify approvers
          </div>
        </div>
      </div>
  
      <div>
        <p-table [value]="awfAppliedUiDtos" styleClass="p-datatable-gridlines" [tableStyle]="{'min-width': '50rem'}">
          <ng-template pTemplate="header">
            <tr>
              <th pResizableColumn>S no.</th>
              <th pResizableColumn>Approver Name</th>
              <th pResizableColumn>Email Id</th>
              <th pResizableColumn>DSC status</th>
              <th pResizableColumn>Verification Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr class="align-middle" *ngIf="isSubmitted">
              <td class="">
                {{i+1}}
              </td>
              <td class="">
                <span
                  class="inline-flex h-6 w-6 p-1 items-center justify-between bg-blue-100 text-blue-500 rounded-full me-2">
                  <img src="/downloadLandingBlob?fileId={{item?.userInfoUiDto?.profileImage?.fileId}}"
                    *ngIf="item?.userInfoUiDto?.profileImage">
                  <span *ngIf="!item?.userInfoUiDto?.profileImage">
                    {{(item?.userInfoUiDto?.firstName + ' ' + item?.userInfoUiDto?.lastName) | textAvatar}}
                  </span>
                </span>
                {{item?.userInfoUiDto?.firstName}} {{item?.userInfoUiDto?.lastName}}
              </td>
              <td class="">
                {{item?.emailId}}
              </td>
              <td class="">
                28-Dec-2024
              </td>
              <td class="">
                <div class="text-emerald-700 text-xs font-medium flex items-center">
                  <span class="inline-flex h-6 w-6  p-1 items-center justify-between bg-emerald-100  rounded-full me-2">
                    <i-lucide name="check" [size]="18" color="#047857" class="my-icon me-1"></i-lucide>
                  </span>
                  Verified
                </div>
                <div class="hidden">
                  <div class="text-rose-700 text-xs font-medium flex items-center">
                    <span class="inline-flex h-6 w-6 p-1 items-center justify-between bg-rose-100 rounded-full me-2">
                      <i-lucide name="ban" [size]="18" color="#BE123C" class="my-icon me-1"></i-lucide>
                    </span>
                    Not verified
                  </div>
                  <div class="text-gray-400 text-xs font-normal">
                    Ways to resolve the issue
                  </div>
                </div>
              </td>
              <td>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
  
  
      <div class="my-3">
        <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Approval saved successfully.'">
        </app-message-toast>
        <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg">
        </app-message-toast>
      </div>
  
      <div class="mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="flex flex-row justify-end h-8 my-2">
      <!-- <div class="basis-1/2 mt-2 ps-6">
        <div class="h-2 w-20 bg-blue-200 inline-block"></div>
        Some information is pending
      </div> -->
      <div class="text-end pe-7">
        <button (click)="closeDrawer()"
          class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-700 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 me-3">Cancel</button>
        <button [disabled]="!isSubmitted" (click)="saveTenderWrapperUiDto()"
          class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-700 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800">Save</button>
      </div>
    </div>
  </div>
</div>

<p-toast></p-toast>