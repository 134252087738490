<div>
  <div class="grid grid-cols-2 gap-2 w-full items-center justify-between py-4 px-6">
    <div class="">
      <span class=""> <img src="/assets/icons/drawer-head-icon-1.svg" class="tr-xl-icon inline-flex"></span>
      <div class="inline-flex ml-2  text-gray-300 font-semibold text-xl leading-4">
        Adding New Company
      </div>
    </div>
    <div class="text-end">
      <button class="bg-gray-50 text-gray-700 text-sm px-4 py-2 rounded-md mr-2 tr-margin-right-10px"
        (click)="closeDrawer()">Cancel</button>
      <button class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md" (click)="saveCompany()">Save as draft</button>
    </div>
  </div>
  <div class="block h-11 py-0 px-6 items-center gap-4 items-stretch bg-white border border-slate-50 border-y">
    <div class="mx-6">
      <div class="rounded-full items-center content-center flex w-full h-10 gap-2.5">
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full bg-gray-50"
          *ngIf="formGroup.invalid"></div>
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full p-1 bg-green-100"
          *ngIf="formGroup.valid">
          <img src="/assets/icons/onboarding-timeline-check.svg" class="svg-icon-class">
        </div>
        <div class="text-sm font-semibold leading-4 tracking-tight"
          [ngClass]="currentTab == 'BASIC_DETAILS' ? 'text-blue-100' : 'text-gray-300'">
          Basic Details
        </div>
        <div class="flex py-0 px-6 items-center gap-4 bg-white">
          <img src="/assets/icons/tr-arrow-right.svg" class="tr-md-icon c-pointer">
        </div>
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full bg-gray-50"
          *ngIf="formGroupAddress.invalid"></div>
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full p-1 bg-green-100"
          *ngIf="formGroupAddress.valid">
          <img src="/assets/icons/onboarding-timeline-check.svg" class="svg-icon-class">
        </div>
        <div class="text-sm font-semibold leading-4 tracking-tight"
          [ngClass]="currentTab == 'BILLING_AND_SHIPPING' ? 'text-blue-100' : 'text-gray-300'">
          Billing & Shipping address
        </div>
        <div class="flex py-0 px-6 items-center gap-4 bg-white">
          <img src="/assets/icons/tr-arrow-right.svg" class="tr-md-icon c-pointer">
        </div>
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full bg-gray-50"
          *ngIf="formGroupBank.invalid"></div>
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full p-1 bg-green-100"
          *ngIf="formGroupBank.valid">
          <img src="/assets/icons/onboarding-timeline-check.svg" class="svg-icon-class">
        </div>
        <div class="text-sm font-semibold leading-4 tracking-tight"
          [ngClass]="currentTab == 'BANK_DETAILS' ? 'text-blue-100' : 'text-gray-300'">
          Bank details
        </div>
      </div>

    </div>
  </div>

  <ng-container [ngTemplateOutlet]="
  currentTab == 'BASIC_DETAILS' ? basicDetails
  : currentTab == 'BILLING_AND_SHIPPING' ? billingAndShipping
  : currentTab == 'BANK_DETAILS' ? bankDetail
  : empty">
  </ng-container>
</div>

<ng-template #basicDetails>
  <div class="tr-form-space-and-background-drawer tr-overflow-y-drawer">

    <form class="needs-validation w-full" novalidate novalidate [formGroup]="formGroup" (keyup.enter)="saveCompany()">
      <div class="">
        <div class="grid grid-cols-1 w-full gap-2">
          <div class="mb-3">
            <label for="companyCode" class="text-gray-700  text-sm font-semibold leading-4 ">
              {{formConfig['companyName'].label}}
            </label>
            <div class="">
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                type="text" id="companyName" formControlName="companyName" placeholder="---"
                [ngClass]="{'is-invalid': fc['companyName'].invalid && (fc['companyName'].dirty || fc['companyName'].touched)}">
            </div>
            <div *ngIf="fc['companyName'].invalid && (fc['companyName'].dirty || fc['companyName'].touched)">
              <p class="tr-validations" *ngIf="fc['companyName'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['companyName'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['companyName'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['companyName'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['companyName'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['companyName'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['companyName'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['companyName'].validations['maxlength']}}
              </p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
          <div class=" mb-2">
            <label for="companyCode" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['companyCode'].label}}
            </label>
            <input type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              id="companyCode" placeholder="---" formControlName="companyCode"
              [ngClass]="{'is-invalid': fc['companyCode'].invalid && (fc['companyCode'].dirty || fc['companyCode'].touched)}">
            <div *ngIf="fc['companyCode'].invalid && (fc['companyCode'].dirty || fc['companyCode'].touched)">
              <p class="tr-validations" *ngIf="fc['companyCode'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['companyCode'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['companyCode'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['companyCode'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['companyCode'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['companyCode'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['companyCode'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['companyCode'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="mb-2">
            <label for="primaryCompany" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['primaryCompany'].label}}
            </label>
            <select name="Primary Company"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
              formControlName="primaryCompany" id="primaryCompany"
              [ngClass]="{'is-invalid': fc['primaryCompany'].invalid && (fc['primaryCompany'].dirty || fc['primaryCompany'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="true">Yes</option>
              <option [value]="false">No</option>
            </select>
            <div *ngIf="fc['primaryCompany'].invalid && (fc['primaryCompany'].dirty || fc['primaryCompany'].touched)">
              <p class="tr-validations" *ngIf="fc['primaryCompany'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['primaryCompany'].validations['required']}}
            </div>
          </div>


          <div class="mb-2">
            <label for="currency" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['currencyCode'].label}}
            </label>
            <select name="Select Region"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
              id="currency" formControlName="currencyCode"
              [ngClass]="{'is-invalid': fc['currencyCode'].invalid && (fc['currencyCode'].dirty || fc['currencyCode'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="currency.code" *ngFor=" let currency of orgCurrencyList">{{currency.name}}</option>

            </select>
            <div *ngIf="fc['currencyCode'].invalid && (fc['currencyCode'].dirty || fc['currencyCode'].touched)">
              <p class="tr-validations" *ngIf="fc['currencyCode'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['currencyCode'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['currencyCode'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['currencyCode'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['currencyCode'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['currencyCode'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['currencyCode'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['currencyCode'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="mb-2">
            <label for="language" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['languageCode'].label}}
            </label>
            <select name="Select Region"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
              id="language" formControlName="languageCode"
              [ngClass]="{'is-invalid': fc['languageCode'].invalid && (fc['languageCode'].dirty || fc['languageCode'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="languageDto.languageCode" *ngFor="let languageDto of organizationUiDto!.orgLanguageDtos">
                {{languageDto.languageCode}}</option>
            </select>
            <div *ngIf="fc['languageCode'].invalid && (fc['languageCode'].dirty || fc['languageCode'].touched)">
              <p class="tr-validations" *ngIf="fc['languageCode'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['languageCode'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['languageCode'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['languageCode'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['languageCode'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['languageCode'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['languageCode'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['languageCode'].validations['maxlength']}}
              </p>
            </div>
          </div>

          <div class="mb-2">
            <label for="legalType" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['legalType'].label}}
            </label>
            <select name="Select Region"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
              id="legalType" formControlName="legalType"
              [ngClass]="{'is-invalid': fc['legalType'].invalid && (fc['legalType'].dirty || fc['legalType'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option value="Limited Liability Company (LLC)">Limited Liability Company (LLC)</option>
              <option value="Limited Liability Partnership (LLP)">Limited Liability Partnership (LLP)</option>
              <option value="Private Limited">Private Limited</option>
              <option value="Public Limited">Public Limited</option>
              <option value="Sole Proprietary">Sole Proprietary</option>
              <option value="One Person Company">One Person Company</option>
              <option value="Non-profit">Non-profit</option>
              <option value="Government">Government</option>
            </select>
            <div *ngIf="fc['legalType'].invalid && (fc['legalType'].dirty || fc['legalType'].touched)">
              <p class="tr-validations" *ngIf="fc['legalType'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['legalType'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['legalType'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['legalType'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['legalType'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['legalType'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['legalType'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['legalType'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="mb-2">
            <label for="indsSec" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['industrySector'].label}}
            </label>
            <select name="Select Region"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
              id="indsSec" formControlName="industrySector"
              [ngClass]="{'is-invalid': fc['industrySector'].invalid && (fc['industrySector'].dirty || fc['industrySector'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Energy and Natural Resources">Energy and Natural Resources</option>
              <option value="Consumer Industries">Consumer Industries</option>
              <option value="Service Industries">Service Industries</option>
              <option value="Government">Government</option>
              <option value="Financial Services">Financial Services</option>
            </select>
            <div *ngIf="fc['industrySector'].invalid && (fc['industrySector'].dirty || fc['industrySector'].touched)">
              <p class="tr-validations" *ngIf="fc['industrySector'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['industrySector'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['industrySector'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['industrySector'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['industrySector'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['industrySector'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['industrySector'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['industrySector'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="mb-2">
            <label for="region" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['region'].label}}
            </label>
            <select name="Select Region"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
              formControlName="region" id="region"
              [ngClass]="{'is-invalid': fc['region'].invalid && (fc['region'].dirty || fc['region'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option value="North America">North America</option>
              <option value="Latin America (LATAM)">Latin America (LATAM)</option>
              <option value="Europe, Middle East, and Africa (EMEA)">Europe, Middle East, and Africa (EMEA)
              </option>
              <option value="Asia-Pacific (APAC)">Asia-Pacific (APAC)</option>
              <option value="Sub-Saharan Africa">Sub-Saharan Africa</option>
            </select>

            <div *ngIf="fc['region'].invalid && (fc['region'].dirty || fc['region'].touched)">
              <p class="tr-validations" *ngIf="fc['region'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['region'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['region'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['region'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['region'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['region'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['region'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['region'].validations['maxlength']}}
              </p>
            </div>

          </div>
          <div class="mb-2">
            <label for="country" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['country'].label}}
            </label>
            <select name="Select Region"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
              id="country" formControlName="country" (change)="setCountryValidation()"
              [ngClass]="{'is-invalid': fc['country'].invalid && (fc['country'].dirty || fc['country'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="item.name" *ngFor="let item of onboardingCountries;">
                {{item.name}}
              </option>
            </select>
            <div *ngIf="fc['country'].invalid && (fc['country'].dirty || fc['country'].touched)">
              <p class="tr-validations" *ngIf="fc['country'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['country'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['country'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['country'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['country'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['country'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['country'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['country'].validations['maxlength']}}
              </p>
            </div>
          </div>

          <div class="mb-2">
            <label for="phoneNo" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['phoneNumber'].label}}
            </label>
            <div class="flex w-full">
              <span class="">
                <app-country-code-select #countryCodePhone (countryCodeEvent)="handlePhoneCountryCode($event)"
                  [countryCode$]="countryCodePhone$">
                </app-country-code-select>
              </span>
              <div class="w-full">
                <input type="text"
                  class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                  id="phoneNo" placeholder="---" formControlName="phoneNumber"
                  [ngClass]="{'is-invalid': fc['phoneNumber'].invalid && (fc['phoneNumber'].dirty || fc['phoneNumber'].touched)}">
                <div *ngIf="fc['phoneNumber'].invalid && (fc['phoneNumber'].dirty || fc['phoneNumber'].touched)">
                  <p class="tr-validations" *ngIf="fc['phoneNumber'].errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['phoneNumber'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fc['phoneNumber'].errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['phoneNumber'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fc['phoneNumber'].errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['phoneNumber'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fc['phoneNumber'].errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['phoneNumber'].validations['maxlength']}}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-2">
            <label for="faxNo" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['faxNumber'].label}}
            </label>
            <div class="flex w-full">
              <span class="">
                <app-country-code-select #countryCodeFax (countryCodeEvent)="handleFaxCountryCode($event)"
                  [countryCode$]="countryCodeFax$">
                </app-country-code-select>
              </span>
              <div class="w-full">
                <input type="text"
                  class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                  id="faxNo" placeholder="---" formControlName="faxNumber"
                  [ngClass]="{'is-invalid': fc['faxNumber'].invalid && (fc['faxNumber'].dirty || fc['faxNumber'].touched)}">
                <div *ngIf="fc['faxNumber'].invalid && (fc['faxNumber'].dirty || fc['faxNumber'].touched)">
                  <p class="tr-validations" *ngIf="fc['faxNumber'].errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['faxNumber'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fc['faxNumber'].errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['faxNumber'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fc['faxNumber'].errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['faxNumber'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fc['faxNumber'].errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['faxNumber'].validations['maxlength']}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-2" *ngIf="selectedOnboardingCountry">
            <label for="faxNo"
              class="text-gray-700  text-sm font-semibold leading-4">{{selectedOnboardingCountry.taxOne}}</label>
            <input type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              id="textIdOne" formControlName="taxId1"
              [ngClass]="{'is-invalid': fc['taxId1'].invalid && (fc['taxId1'].dirty || fc['taxId1'].touched)}">
            <div *ngIf="fc['taxId1'].invalid && (fc['taxId1'].dirty || fc['taxId1'].touched)">
              <p class="tr-validations" *ngIf="fc['taxId1'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['taxId1'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['taxId1'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['taxId1'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['taxId1'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['taxId1'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['taxId1'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['taxId1'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="mb-2" *ngIf="selectedOnboardingCountry && selectedOnboardingCountry.taxTwo">
            <label for="faxNo"
              class="text-gray-700  text-sm font-semibold leading-4">{{selectedOnboardingCountry.taxTwo}}</label>
            <input type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              id="textIdTwo" formControlName="taxId2"
              [ngClass]="{'is-invalid': fc['taxId2'].invalid && (fc['taxId2'].dirty || fc['taxId2'].touched)}">
            <div *ngIf="fc['taxId2'].invalid && (fc['taxId2'].dirty || fc['taxId2'].touched)">
              <p class="tr-validations" *ngIf="fc['taxId2'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['taxId2'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['taxId2'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['taxId2'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['taxId2'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['taxId2'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['taxId2'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['taxId2'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="mb-2">
            <label for="branch" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['branch'].label}}
            </label>
            <select name="Select Branch"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
              formControlName="branch" id="branch"
              [ngClass]="{'is-invalid': fc['branch'].invalid && (fc['branch'].dirty || fc['branch'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="true">Yes</option>
              <option [value]="false">No</option>
            </select>
            <div *ngIf="fc['branch'].invalid && (fc['branch'].dirty || fc['branch'].touched)">
              <p class="tr-validations" *ngIf="fc['branch'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['branch'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['branch'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['branch'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['branch'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['branch'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['branch'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['branch'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="mb-2">
            <label for="faxNo" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['timeZone'].label}}
            </label>
            <select name="Select Timezone"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
              id="timeZone" formControlName="timeZone"
              [ngClass]="{'is-invalid': fc['timeZone'].invalid && (fc['timeZone'].dirty || fc['timeZone'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="item?.locale" *ngFor="let item of timezones">{{item?.fullName}}</option>
            </select>
            <div *ngIf="fc['timeZone'].invalid && (fc['timeZone'].dirty || fc['timeZone'].touched)">
              <p class="tr-validations" *ngIf="fc['timeZone'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['timeZone'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['timeZone'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['timeZone'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['timeZone'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['timeZone'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['timeZone'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['timeZone'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="mb-2">
            <label for="faxNo" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['fiscalYrStart'].label}}
            </label>
            <input ngbDatepicker #fiscalYrStart="ngbDatepicker" type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm "
              id="fiscalYrStart" formControlName="fiscalYrStart" (click)="fiscalYrStart.toggle()"
              [ngClass]="{'is-invalid': fc['fiscalYrStart'].invalid && (fc['fiscalYrStart'].dirty || fc['fiscalYrStart'].touched)}">
            <div *ngIf="fc['fiscalYrStart'].invalid && (fc['fiscalYrStart'].dirty || fc['fiscalYrStart'].touched)">
              <p class="tr-validations" *ngIf="fc['fiscalYrStart'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['fiscalYrStart'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['fiscalYrStart'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['fiscalYrStart'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['fiscalYrStart'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['fiscalYrStart'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['fiscalYrStart'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['fiscalYrStart'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="mb-2">
            <label for="faxNo" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['fiscalYrEnd'].label}}
            </label>
            <input ngbDatepicker #fiscalYrEnd="ngbDatepicker" type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              id="fiscalYrEnd" formControlName="fiscalYrEnd" (click)="fiscalYrEnd.toggle()"
              [ngClass]="{'is-invalid': fc['fiscalYrEnd'].invalid && (fc['fiscalYrEnd'].dirty || fc['fiscalYrEnd'].touched)}">

            <div *ngIf="fc['fiscalYrEnd'].invalid && (fc['fiscalYrEnd'].dirty || fc['fiscalYrEnd'].touched)">
              <p class="tr-validations" *ngIf="fc['fiscalYrEnd'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['fiscalYrEnd'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['fiscalYrEnd'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['fiscalYrEnd'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['fiscalYrEnd'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['fiscalYrEnd'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['fiscalYrEnd'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['fiscalYrEnd'].validations['maxlength']}}
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2">

          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="successMessages['update']"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="col-12 text-end mb-5" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md"
              (click)="saveCompany()">Save</button>
          </div>

        </div>
      </div>

    </form>
  </div>

</ng-template>

<ng-template #billingAndShipping>
  <div class="tr-form-space-and-background-drawer tr-overflow-y-drawer">
    <form class="needs-validation w-full" novalidate novalidate [formGroup]="formGroupAddress"
      (keyup.enter)="saveCompany()">
      <div class="col-12">
        <div class=" mb-4 grid grid-cols-1 md:grid-cols-2 gap-2 p-2 w-full">
          <div class=" " formGroupName="billingAddress">
            <div class="border p-4">
              <div class="mb-14 text-gray-300 text-sm font-semibold leading-4 uppercase">
                BILLING ADDRESS
              </div>
              <div class="mb-2">
                <label for="companyCode" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['addressType'].label}}
                </label>
                <div class="">
                  <input
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    type="text" id="addressType" formControlName="addressType" placeholder="---"
                    [ngClass]="{'is-invalid': fcAddress['billingAddress'].get('addressType')?.invalid && (fcAddress['billingAddress'].get('addressType')?.dirty || fcAddress['billingAddress'].get('addressType')?.touched)}">
                </div>
                <div
                  *ngIf="fcAddress['billingAddress'].get('addressType')?.invalid && (fcAddress['billingAddress'].get('addressType')?.dirty || fcAddress['billingAddress'].get('addressType')?.touched)">
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('addressType')?.errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressType'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('addressType')?.errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressType'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('addressType')?.errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressType'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('addressType')?.errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressType'].validations['maxlength']}}
                  </p>
                </div>
              </div>
              <div class="mb-2">
                <label for="companyCode" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['searchAddress'].label}}
                </label>
                <div class="">
                  <input ngx-gp-autocomplete #placesRef="ngx-places" type="text"
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    [ngClass]="{'is-invalid': fcAddress['billingAddress'].get('searchAddress')?.invalid && (fcAddress['billingAddress'].get('searchAddress')?.dirty || fcAddress['billingAddress'].get('searchAddress')?.touched)}"
                    id="searchAddress" placeholder="---" formControlName="searchAddress"
                    (onAddressChange)="handleBillingAddressChange($event)">
                </div>
                <div
                  *ngIf="fcAddress['billingAddress'].get('searchAddress')?.invalid && (fcAddress['billingAddress'].get('searchAddress')?.dirty || fcAddress['billingAddress'].get('searchAddress')?.touched)">
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('searchAddress')?.errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['searchAddress'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('searchAddress')?.errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['searchAddress'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('searchAddress')?.errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['searchAddress'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('searchAddress')?.errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['searchAddress'].validations['maxlength']}}
                  </p>
                </div>
              </div>
              <div class="mb-2">
                <label for="addressLine1" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['addressLine1'].label}}
                </label>
                <div class="">
                  <input
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    type="text" id="addressLine1" formControlName="addressLine1" placeholder="---"
                    [ngClass]="{'is-invalid': fcAddress['billingAddress'].get('addressLine1')?.invalid && (fcAddress['billingAddress'].get('addressLine1')?.dirty || fcAddress['billingAddress'].get('addressLine1')?.touched)}">
                </div>
                <div
                  *ngIf="fcAddress['billingAddress'].get('addressLine1')?.invalid && (fcAddress['billingAddress'].get('addressLine1')?.dirty || fcAddress['billingAddress'].get('addressLine1')?.touched)">
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('addressLine1')?.errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressLine1'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('addressLine1')?.errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressLine1'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('addressLine1')?.errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressLine1'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('addressLine1')?.errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressLine1'].validations['maxlength']}}
                  </p>
                </div>
              </div>
              <div class="mb-2">
                <label for="addressLine1" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['addressLine2'].label}}
                </label>
                <div class="">
                  <input
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    type="text" id="adressLineTwo" formControlName="addressLine2" placeholder="---">
                </div>
              </div>
              <div class="mb-2">
                <label for="city" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['city'].label}}
                </label>
                <div class="">
                  <input
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    type="text" id="city" formControlName="city" placeholder="---"
                    [ngClass]="{'is-invalid': fcAddress['billingAddress'].get('city')?.invalid && (fcAddress['billingAddress'].get('city')?.dirty || fcAddress['billingAddress'].get('city')?.touched)}">
                </div>
                <div
                  *ngIf="fcAddress['billingAddress'].get('city')?.invalid && (fcAddress['billingAddress'].get('city')?.dirty || fcAddress['billingAddress'].get('city')?.touched)">
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('city')?.errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['city'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('city')?.errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['city'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('city')?.errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['city'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('city')?.errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['city'].validations['maxlength']}}
                  </p>
                </div>
              </div>
              <div class="mb-2 grid grid-cols-3 gap-2 w-full">
                <div class="col-span-2">
                  <label for="state" class="text-gray-700  text-sm font-semibold leading-4">
                    {{formConfig['state'].label}}
                  </label>
                  <input type="text"
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    id="state" placeholder="State" formControlName="state"
                    [ngClass]="{'is-invalid': fcAddress['billingAddress'].get('state')?.invalid && (fcAddress['billingAddress'].get('state')?.dirty || fcAddress['billingAddress'].get('state')?.touched)}">
                  <div
                    *ngIf="fcAddress['billingAddress'].get('state')?.invalid && (fcAddress['billingAddress'].get('state')?.dirty || fcAddress['billingAddress'].get('state')?.touched)">
                    <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('state')?.errors?.['required']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['state'].validations['required']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('state')?.errors?.['pattern']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['state'].validations['pattern']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('state')?.errors?.['minlength']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['state'].validations['minlength']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('state')?.errors?.['maxlength']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['state'].validations['maxlength']}}
                    </p>
                  </div>
                </div>
                <div class="">
                  <label for="zipCode" class="text-gray-700  text-sm font-semibold leading-4">
                    {{formConfig['zipCode'].label}}
                  </label>
                  <input type="text"
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    id="zipCode" placeholder="Zip" formControlName="zipCode"
                    [ngClass]="{'is-invalid': fcAddress['billingAddress'].get('zipCode')?.invalid && (fcAddress['billingAddress'].get('zipCode')?.dirty || fcAddress['billingAddress'].get('zipCode')?.touched)}">
                  <div
                    *ngIf="fcAddress['billingAddress'].get('zipCode')?.invalid && (fcAddress['billingAddress'].get('zipCode')?.dirty || fcAddress['billingAddress'].get('zipCode')?.touched)">
                    <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('zipCode')?.errors?.['required']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['zipCode'].validations['required']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('zipCode')?.errors?.['pattern']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['zipCode'].validations['pattern']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('zipCode')?.errors?.['minlength']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['zipCode'].validations['minlength']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('zipCode')?.errors?.['maxlength']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['zipCode'].validations['maxlength']}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="">
                <label for="country" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['country'].label}}
                </label>
                <input type="text"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                  id="country" placeholder="Country" formControlName="country"
                  [ngClass]="{'is-invalid': fcAddress['billingAddress'].get('country')?.invalid && (fcAddress['billingAddress'].get('country')?.dirty || fcAddress['billingAddress'].get('country')?.touched)}">
                <div
                  *ngIf="fcAddress['billingAddress'].get('country')?.invalid && (fcAddress['billingAddress'].get('country')?.dirty || fcAddress['billingAddress'].get('country')?.touched)">
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('country')?.errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['country'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('country')?.errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['country'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('country')?.errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['country'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['billingAddress'].get('country')?.errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['country'].validations['maxlength']}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="" formGroupName="shippingAddress">
            <div class="border p-4">
              <div class="mb-14 text-gray-300 text-sm font-semibold leading-4 uppercase">
                SHIPPING ADDRESS
              </div>
              <div class="mb-2">
                <label for="companyCode" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['addressType'].label}}</label>
                <div class="">
                  <input
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    type="text" id="addressType" formControlName="addressType" placeholder="---"
                    [ngClass]="{'is-invalid': fcAddress['shippingAddress'].get('addressType')?.invalid && (fcAddress['shippingAddress'].get('addressType')?.dirty || fcAddress['shippingAddress'].get('addressType')?.touched)}">
                </div>
                <div
                  *ngIf="fcAddress['shippingAddress'].get('addressType')?.invalid && (fcAddress['shippingAddress'].get('addressType')?.dirty || fcAddress['shippingAddress'].get('addressType')?.touched)">
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('addressType')?.errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressType'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('addressType')?.errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressType'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('addressType')?.errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressType'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('addressType')?.errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressType'].validations['maxlength']}}
                  </p>
                </div>
              </div>
              <div class="mb-2">
                <label for="companyCode" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['searchAddress'].label}}</label>
                <div class="">
                  <input ngx-gp-autocomplete #placesRef="ngx-places" type="text"
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    [ngClass]="{'is-invalid': fcAddress['shippingAddress'].get('searchAddress')?.invalid && (fcAddress['shippingAddress'].get('searchAddress')?.dirty || fcAddress['shippingAddress'].get('searchAddress')?.touched)}"
                    id="searchAddress" placeholder="---" formControlName="searchAddress"
                    (onAddressChange)="handleShippingAddressChange($event)">
                </div>
                <div
                  *ngIf="fcAddress['shippingAddress'].get('searchAddress')?.invalid && (fcAddress['shippingAddress'].get('searchAddress')?.dirty || fcAddress['shippingAddress'].get('searchAddress')?.touched)">
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('searchAddress')?.errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['searchAddress'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('searchAddress')?.errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['searchAddress'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('searchAddress')?.errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['searchAddress'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('searchAddress')?.errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['searchAddress'].validations['maxlength']}}
                  </p>
                </div>
              </div>
              <div class="mb-2">
                <label for="addressLine1" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['addressLine1'].label}}
                </label>
                <div class="">
                  <input
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    type="text" id="addressLine1" formControlName="addressLine1" placeholder="---"
                    [ngClass]="{'is-invalid': fcAddress['shippingAddress'].get('addressLine1')?.invalid && (fcAddress['shippingAddress'].get('addressLine1')?.dirty || fcAddress['shippingAddress'].get('addressLine1')?.touched)}">
                </div>
                <div
                  *ngIf="fcAddress['shippingAddress'].get('addressLine1')?.invalid && (fcAddress['shippingAddress'].get('addressLine1')?.dirty || fcAddress['shippingAddress'].get('addressLine1')?.touched)">
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('addressLine1')?.errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressLine1'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('addressLine1')?.errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressLine1'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('addressLine1')?.errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressLine1'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('addressLine1')?.errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['addressLine1'].validations['maxlength']}}
                  </p>
                </div>
              </div>
              <div class="mb-2">
                <label for="addressLine1" class="text-gray-700  text-sm font-semibold leading-4">Address Line 2</label>
                <div class="">
                  <input
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    type="text" id="adressLineTwo" formControlName="addressLine2" placeholder="---">
                </div>
              </div>
              <div class="mb-2">
                <label for="city" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['city'].label}}
                </label>
                <div class="">
                  <input
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    type="text" id="city" formControlName="city" placeholder="---"
                    [ngClass]="{'is-invalid': fcAddress['shippingAddress'].get('city')?.invalid && (fcAddress['shippingAddress'].get('city')?.dirty || fcAddress['shippingAddress'].get('city')?.touched)}">
                </div>
                <div
                  *ngIf="fcAddress['shippingAddress'].get('city')?.invalid && (fcAddress['shippingAddress'].get('city')?.dirty || fcAddress['shippingAddress'].get('city')?.touched)">
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('city')?.errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['city'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('city')?.errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['city'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('city')?.errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['city'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('city')?.errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['city'].validations['maxlength']}}
                  </p>
                </div>
              </div>
              <div class="mb-2 grid grid-cols-3 gap-2 w-full">
                <div class="col-span-2">
                  <label for="state" class="text-gray-700  text-sm font-semibold leading-4">
                    {{formConfig['zipCode'].label}}
                  </label>
                  <input type="text"
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    id="state" placeholder="State" formControlName="state"
                    [ngClass]="{'is-invalid': fcAddress['shippingAddress'].get('state')?.invalid && (fcAddress['shippingAddress'].get('state')?.dirty || fcAddress['shippingAddress'].get('state')?.touched)}">
                  <div
                    *ngIf="fcAddress['shippingAddress'].get('state')?.invalid && (fcAddress['shippingAddress'].get('state')?.dirty || fcAddress['shippingAddress'].get('state')?.touched)">
                    <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('state')?.errors?.['required']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['state'].validations['required']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('state')?.errors?.['pattern']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['state'].validations['pattern']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('state')?.errors?.['minlength']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['state'].validations['minlength']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('state')?.errors?.['maxlength']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['state'].validations['maxlength']}}
                    </p>
                  </div>
                </div>
                <div class="">
                  <label for="zipCode" class="text-gray-700  text-sm font-semibold leading-4">
                    {{formConfig['zipCode'].label}}
                  </label>
                  <input type="text"
                    class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                    id="zipCode" placeholder="Zip" formControlName="zipCode"
                    [ngClass]="{'is-invalid': fcAddress['shippingAddress'].get('zipCode')?.invalid && (fcAddress['shippingAddress'].get('zipCode')?.dirty || fcAddress['shippingAddress'].get('zipCode')?.touched)}">
                  <div
                    *ngIf="fcAddress['shippingAddress'].get('zipCode')?.invalid && (fcAddress['shippingAddress'].get('zipCode')?.dirty || fcAddress['shippingAddress'].get('zipCode')?.touched)">
                    <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('zipCode')?.errors?.['required']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['zipCode'].validations['required']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('zipCode')?.errors?.['pattern']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['zipCode'].validations['pattern']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('zipCode')?.errors?.['minlength']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['zipCode'].validations['minlength']}}
                    </p>
                    <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('zipCode')?.errors?.['maxlength']">
                      <span class="mr-2">
                        <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                      </span>
                      {{formConfig['zipCode'].validations['maxlength']}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="">
                <label for="country" class="text-gray-700  text-sm font-semibold leading-4">
                  {{formConfig['country'].label}}
                </label>
                <input type="text"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                  id="country" placeholder="Country" formControlName="country"
                  [ngClass]="{'is-invalid': fcAddress['shippingAddress'].get('country')?.invalid && (fcAddress['shippingAddress'].get('country')?.dirty || fcAddress['shippingAddress'].get('country')?.touched)}">
                <div
                  *ngIf="fcAddress['shippingAddress'].get('country')?.invalid && (fcAddress['shippingAddress'].get('country')?.dirty || fcAddress['shippingAddress'].get('country')?.touched)">
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('country')?.errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['country'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('country')?.errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['country'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('country')?.errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['country'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fcAddress['shippingAddress'].get('country')?.errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['country'].validations['maxlength']}}
                  </p>

                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="mb-5">
          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="successMessages['update']"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="col-12 text-end mb-5" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md"
              (click)="saveCompany()">Save</button>
          </div>

        </div>

      </div>
    </form>
  </div>
</ng-template>

<ng-template #bankDetail>
  <div class="tr-form-space-and-background-drawer tr-overflow-y-drawer">
    <form class="needs-validation w-full" novalidate novalidate [formGroup]="formGroupBank"
      (keyup.enter)="saveCompany()">
      <div class="">
        <div class="mb-4 grid grid-cols-1 gap-2 w-full">
          <div class="mb-2">
            <label for="accountTitle" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['accountTitle'].label}}
            </label>
            <div class="">
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                type="text" id="accountTitle" formControlName="accountTitle" placeholder="---"
                [ngClass]="{'is-invalid': fcBank['accountTitle'].invalid && (fcBank['accountTitle'].dirty || fcBank['accountTitle'].touched)}">
            </div>
            <div
              *ngIf="fcBank['accountTitle'].invalid && (fcBank['accountTitle'].dirty || fcBank['accountTitle'].touched)">
              <p class="tr-validations" *ngIf="fcBank['accountTitle'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accountTitle'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accountTitle'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accountTitle'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accountTitle'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accountTitle'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accountTitle'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accountTitle'].validations['maxlength']}}
              </p>
            </div>
          </div>
        </div>
        <div class="mb-4 grid grid-cols-1 md:grid-cols-2 gap-2 w-full">
          <div class=" mb-4">
            <label for="accNumber" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['accNumber'].label}}
            </label>
            <input type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              id="accNumber" placeholder="---" formControlName="accNumber"
              [ngClass]="{'is-invalid': fcBank['accNumber'].invalid && (fcBank['accNumber'].dirty || fcBank['accNumber'].touched)}">
            <div *ngIf="fcBank['accNumber'].invalid && (fcBank['accNumber'].dirty || fcBank['accNumber'].touched)">
              <p class="tr-validations" *ngIf="fcBank['accNumber'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accNumber'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accNumber'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accNumber'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accNumber'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accNumber'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accNumber'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accNumber'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class=" mb-4">
            <label for="accHolderName" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['accHolderName'].label}}
            </label>
            <input type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              id="accHolderName" placeholder="---" formControlName="accHolderName"
              [ngClass]="{'is-invalid': fcBank['accHolderName'].invalid && (fcBank['accHolderName'].dirty || fcBank['accHolderName'].touched)}">
            <div
              *ngIf="fcBank['accHolderName'].invalid && (fcBank['accHolderName'].dirty || fcBank['accHolderName'].touched)">
              <p class="tr-validations" *ngIf="fcBank['accHolderName'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accHolderName'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accHolderName'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accHolderName'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accHolderName'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accHolderName'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accHolderName'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accHolderName'].validations['maxlength']}}
              </p>
            </div>
          </div>

          <div class=" mb-4">
            <label for="accType" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['accType'].label}}
            </label>
            <input type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              id="accType" placeholder="---" formControlName="accType"
              [ngClass]="{'is-invalid': fcBank['accType'].invalid && (fcBank['accType'].dirty || fcBank['accType'].touched)}">
            <div *ngIf="fcBank['accType'].invalid && (fcBank['accType'].dirty || fcBank['accType'].touched)">
              <p class="tr-validations" *ngIf="fcBank['accType'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accType'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accType'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accType'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accType'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accType'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accType'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accType'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class=" mb-4">
            <label for="accCode" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['accCode'].label}}
            </label>
            <input type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              id="accCode" placeholder="---" formControlName="accCode"
              [ngClass]="{'is-invalid': fcBank['accCode'].invalid && (fcBank['accCode'].dirty || fcBank['accCode'].touched)}">
            <div *ngIf="fcBank['accCode'].invalid && (fcBank['accCode'].dirty || fcBank['accCode'].touched)">
              <p class="tr-validations" *ngIf="fcBank['accCode'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accCode'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accCode'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accCode'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accCode'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accCode'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['accCode'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['accCode'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class=" mb-4">
            <label for="swift" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['swift'].label}}
            </label>
            <input type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              id="swift" placeholder="---" formControlName="swift"
              [ngClass]="{'is-invalid': fcBank['swift'].invalid && (fcBank['swift'].dirty || fcBank['swift'].touched)}">
            <div *ngIf="fcBank['swift'].invalid && (fcBank['swift'].dirty || fcBank['swift'].touched)">
              <p class="tr-validations" *ngIf="fcBank['swift'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['swift'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['swift'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['swift'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['swift'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['swift'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['swift'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['swift'].validations['maxlength']}}
              </p>

            </div>
          </div>
          <div class=" mb-4">
            <label for="iban" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['iban'].label}}
            </label>
            <input type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              id="iban" placeholder="---" formControlName="iban"
              [ngClass]="{'is-invalid': fcBank['iban'].invalid && (fcBank['iban'].dirty || fcBank['iban'].touched)}">
            <div *ngIf="fcBank['iban'].invalid && (fcBank['iban'].dirty || fcBank['iban'].touched)">
              <p class="tr-validations" *ngIf="fcBank['iban'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['iban'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['iban'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['iban'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['iban'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['iban'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fcBank['iban'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['iban'].validations['maxlength']}}
              </p>

            </div>
          </div>
          <!-- <div class=" mb-4 col-6">
          <label for="iban" class="text-gray-700  text-sm font-semibold leading-4">Bank Address</label>
          <input type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm" id="iban" placeholder="---"
            formControlName="iban"
            [ngClass]="{'is-invalid': fcBank['iban'].invalid && (fcBank['iban'].dirty || fcBank['iban'].touched)}">
         
        </div> -->
        </div>
        <div class="mb-2">
          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="successMessages['update']"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="col-12 text-end mb-5" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md"
              (click)="saveCompany()">Save</button>
            <button type="button" class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md tr-margin-left-16px"
              (click)="saveCompany(true)">Save
              and Add Plants</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #empty></ng-template>