import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { RfxPurchaseOrderEnum } from 'src/app/shared/enums/rfx/RfxPurchaseOrderEnum';
import { Currency } from 'src/app/shared/models/Currency';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { PurchaseOrderItemDto, RfxPurchaseOrderDto } from 'src/app/shared/models/rfx/RfxPurchaseOrderDto';
import { MobileWrapper } from 'src/app/shared/models/user/MobileWrapper';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

declare const require: any;
const html2pdf = require('html2pdf.js');

@Component({
  selector: 'app-rfx-purchase-order',
  templateUrl: './rfx-purchase-order.component.html',
  styleUrls: ['./rfx-purchase-order.component.sass']
})
export class RfxPurchaseOrderComponent implements OnInit {
  purchaseOrdersList: RfxPurchaseOrderDto[] = [];
  filteredPOsList: RfxPurchaseOrderDto[] = [];

  selectedRfxPurchaseOrderDto?: RfxPurchaseOrderDto;

  filteredPOsList$ = new BehaviorSubject<RfxPurchaseOrderDto[]>([]);
  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  purchaseOrderPdfTemplate: boolean = false;
  poApproveModal: boolean = false;
  isDataLoading: boolean = false;
  isLoading: boolean = false;
  isShowPdfView: boolean = false;
  errorMsg: string = ''

  constructor(
    private ngbModal: NgbModal,
    private changeDetectRef: ChangeDetectorRef,
    private adminService: AdminDashboardService,
  ) { }

  ngOnInit(): void {
    this.isDataLoading = true;
    this.adminService.getPurchaseOrders().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isDataLoading = false;
          if (apiResponseDto.data) {
            let data = apiResponseDto.data as RfxPurchaseOrderDto[]
            this.purchaseOrdersList = data;

            this.filterAndSortData();
          }
        }
      },
      error: (error) => {
        console.error(error);
        this.isDataLoading = false;
      }
    })
  }

  filterAndSortData() {
    this.filteredPOsList = [...this.purchaseOrdersList];
    this.filteredPOsList$.next(this.filteredPOsList);
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  getFormattedPrice(currency: Currency, price: any) {
    return currency.symbol + ApplicationUtils.getFormattedPrice(currency.locale, price);
  }

  getTotalAdditionalPrices(item: PurchaseOrderItemDto) {
    if (item.additionalPrices && item.additionalPrices.length > 0) {
      let additionalPrices = item.additionalPrices.reduce((prev, curr) => prev + Number(curr.additionalPrice), 0);
      return this.getFormattedPrice(this.selectedRfxPurchaseOrderDto?.currency!, additionalPrices);
    }
    return this.getFormattedPrice(this.selectedRfxPurchaseOrderDto?.currency!, 0);
  }

  getTotalBidPrice() {
    if (this.selectedRfxPurchaseOrderDto?.purchaseOrderItems && this.selectedRfxPurchaseOrderDto?.purchaseOrderItems.length > 0) {
      let totalBidPrice = this.selectedRfxPurchaseOrderDto?.purchaseOrderItems.reduce((prev, curr) => prev + Number(curr.unitPriceQtyTaxAdditionalPricesTotal), 0);
      return this.getFormattedPrice(this.selectedRfxPurchaseOrderDto?.currency!, totalBidPrice);
    }
    return this.getFormattedPrice(this.selectedRfxPurchaseOrderDto?.currency!, 0);
  }

  openPoApprovalModal(item: RfxPurchaseOrderDto) {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = false;

    this.selectedRfxPurchaseOrderDto = item;
    // this.ngbModal.open(content, {
    //   size: 'sm', backdrop: 'static', keyboard: false, centered: true
    // });
    this.poApproveModal = true
  
  }

  openPurchaseOrderPdfModal(item: RfxPurchaseOrderDto) {
    this.selectedRfxPurchaseOrderDto = item;
    // this.ngbModal.open(content, {
    //   size: 'xl', backdrop: 'static', keyboard: false, centered: true
    // });
    this.purchaseOrderPdfTemplate = true
  }

  getFormattedMobileNo(mobileWrapper?: MobileWrapper) {
    let countryCodeDto = mobileWrapper?.countryCode;
    if (countryCodeDto) {
      let countryCode = countryCodeDto.countryCode?.replace('(', '').replace(')', '');
      return countryCode + ' ' + mobileWrapper?.mobileNo;
    }
    return '+1 ' + mobileWrapper?.mobileNo;
  }

  markToApprove() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = false;

    let rfxRfxPurchaseOrderDto = ApplicationUtils.clone(this.selectedRfxPurchaseOrderDto) as RfxPurchaseOrderDto
    rfxRfxPurchaseOrderDto.status = RfxPurchaseOrderEnum.APPROVED;

    this.isLoading = true;

    this.adminService.generatePurchaseOrder(rfxRfxPurchaseOrderDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as RfxPurchaseOrderDto;
          this.selectedRfxPurchaseOrderDto = data;

          let index = this.purchaseOrdersList.findIndex(item => item.purchaseOrderId == this.selectedRfxPurchaseOrderDto?.purchaseOrderId);
          if (index != undefined && index > -1) {
            Object.assign(this.purchaseOrdersList[index], this.selectedRfxPurchaseOrderDto);
            this.filterAndSortData();
          }

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this.errorMsg = apiResponseDto.message ?? 'Error while approving PO. Try again.';
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while approving PO. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;

      }
    })
  }

  generatePdf() {
    this.isLoading = true;
    this.isShowPdfView = true;
    this.changeDetectRef.detectChanges();

    const element = document.getElementById('pdfContent');

    let auctionHouseDto = this.adminService.getCurrentAuctionHouse$.value;
    const secondaryColor = auctionHouseDto?.secondaryColor ?? '#1b5887';

    if (element) {
      let opt = {
        // margin: [25, 12, 30, 12],
        filename: 'PO_' + "test" + '.pdf',
        html2canvas: { scale: 6 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };

      html2pdf().from(element).set(opt).set({ margin: [25, 12, 25, 12] }).toPdf().get('pdf').then((pdf: any) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          pdf.setDrawColor(secondaryColor);
          pdf.setLineWidth(1);
          pdf.line(20, pdf.internal.pageSize.getHeight() - 25, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 25);

          pdf.setFontSize(9);
          pdf.setTextColor('#000000');

          let text = `Page ${i}`;
          let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          let textOffset = (pdf.internal.pageSize.getWidth() - textWidth) / 2;

          pdf.text(text, textOffset, pdf.internal.pageSize.getHeight() - 10);
        }

        this.isLoading = false;
        this.isShowPdfView = false;
        this.changeDetectRef.detectChanges();
      }).save();
    }
  }
}
