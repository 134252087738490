<div class=" col-12 p-2">
  <div class=" bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
    <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
      <div class="grid grid-cols-3 gap-4 w-full">
        <div class="col-span-2 text-gray-500 font-semibold leading-4 tracking-tight text-base">
          <span class="">
            <img src="/assets/icons/tr-basic-detail.svg" class="tr-md-icon inline-flex">
          </span>
          Opportunity Configuration
        </div>

        <div class="text-end  text-sm font-medium leading-4 text-blue-100 tracking-tight ">
          <span class="c-pointer" (click)="openEditOpportunityDrawer()">
            <i-lucide name="pencil" [size]="15" class="c-pointer my-icon inline-flex"></i-lucide>
            Edit
          </span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full p-4">
      <div class="">
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Should allow Re-bid submission
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            
            {{tenderOpportunityWrapperDto?.allowReBidSubmission ? 'Yes' : 'No'}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Should allow multi-currency
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.allowMultipleCurrency? 'Yes' : 'No'}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Tender Value
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.tenderValue | priceFormat }}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Bid Validity Date
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.bidValidityDays}}
          </div>
        </div>

      </div>
      <div class="">
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Should allow withdrawal of bids
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.allowWithdrawalOfBids? 'Yes' : 'No'}}
          </div>
        </div>

        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Currency
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{currenciesInString}}
        </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Show Tender value to bidder
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.showTenderValueToBidder}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Lead Time days
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.leadTimeDays}}
          </div>
        </div>

      </div>
    </div>
    <hr class="hr my-2 text-blue-500">
    <!-- Location -->
    <div>
      <div class="text-black font-semibold leading-normal text-base px-4">
        Location
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full p-4">
        <div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Title of the location
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.deliveryLocationDetails?.addressType }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Address Line 1
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.deliveryLocationDetails?.addressLine1 }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              City
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.deliveryLocationDetails?.city}}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Country
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.deliveryLocationDetails?.country }}
            </div>
          </div>
        </div>
        <div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Add location
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.deliveryLocationDetails?.searchAddress }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Address Line 2 
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.deliveryLocationDetails?.addressLine2 }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
             State
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.deliveryLocationDetails?.state }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Pincode
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.deliveryLocationDetails?.zipCode}}
            </div>
          </div>

        </div>
      </div>
    </div>
    <hr class="hr my-2 text-blue-500">
    <!-- Location -->
    <div *ngIf="tenderOpportunityWrapperDto?.allowPreBidMeeting">
      <div class="text-black font-semibold leading-normal text-base px-4 mb-4">
        Set Pre-Bid meeting with selected suppliers
      </div>
      <div class="text-black font-semibold leading-normal text-base px-4 mb-1">
        Pre-Bid meeting place
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full px-4 mb-2">
        <div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Title of the location
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
             {{tenderOpportunityWrapperDto?.preBidMeetingLocation?.addressType }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Address line 1
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
             {{tenderOpportunityWrapperDto?.preBidMeetingLocation?.addressLine1 }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
            City
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
             {{tenderOpportunityWrapperDto?.preBidMeetingLocation?.city }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Country
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
             {{tenderOpportunityWrapperDto?.preBidMeetingLocation?.country }}
            </div>
          </div>
        </div>
        <div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Add location
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
             {{tenderOpportunityWrapperDto?.preBidMeetingLocation?.searchAddress }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Address line 2
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
             {{tenderOpportunityWrapperDto?.preBidMeetingLocation?.addressLine2 }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              State
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
             {{tenderOpportunityWrapperDto?.preBidMeetingLocation?.state }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Pincode
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
             {{tenderOpportunityWrapperDto?.preBidMeetingLocation?.zipCode}}
            </div>
          </div>
        </div>
      </div>
      <div class="text-black font-semibold leading-normal text-base px-4 mb-1">
        Bid Opening place
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full px-4 mb-2">
        <div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Title of the location
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.bidOpeningLocation?.addressType }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Address line 1
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.bidOpeningLocation?.addressLine1 }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
            City
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.bidOpeningLocation?.city }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Country
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.bidOpeningLocation?.country }}
            </div>
          </div>
        </div>
        <div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Add location
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.bidOpeningLocation?.searchAddress }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Address line 2
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.bidOpeningLocation?.addressLine2 }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              State
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.bidOpeningLocation?.state }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Pincode
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.bidOpeningLocation?.zipCode}}
            </div>
          </div>
        </div>
      </div>
      <div class="text-black font-semibold leading-normal text-base ps-4 mb-1">
        Inviting officer details
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full px-4">
        <div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Title of the location
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.invitingOfficerLocation?.addressType }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Address line 1
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.invitingOfficerLocation?.addressLine1 }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
            City
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.invitingOfficerLocation?.city }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Country
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.invitingOfficerLocation?.country }}
            </div>
          </div>
        </div>
        <div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Add location
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.invitingOfficerLocation?.searchAddress }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Address line 2
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.invitingOfficerLocation?.addressLine2 }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              State
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.invitingOfficerLocation?.state }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Pincode
            </div>
            <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{tenderOpportunityWrapperDto?.invitingOfficerLocation?.zipCode}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>