<div class="mb-5">
  <div class="flex flex-row items-center justify-between my-2 px-3">
    <div class="basis-1/2 inline-flex items-center mt-2">
      <span class="inline-flex text-xs font-normal text-black underline  "> Home</span>
      <span class="mx-2">/</span>
      <span class="inline-flex text-xs font-normal text-black underline  ">Tender management</span>
      <span class="mx-2">/</span>
      <span class="inline-flex text-xs font-normal text-blue-500 ">{{tenderWrapperUiDto?.tenderReferenceNo}}</span>
    </div>
  </div>
  <div class=" gap-4 bg-gray-900 p-3">
    <div class="flex">
      <div class="inline-flex">
        <span class="h-12 w-12 p-3  items-center justify-between bg-blue-100 rounded-lg">
          <i-lucide name="gavel" color="#0555F5" class="my-icon"></i-lucide>
        </span>
      </div>
      <div class="basis-10/12 lg:basis-11/12">
        <div class="inline-block ms-2">
          <div class="font-semibold text-gray-200 text-2xl overflow-class-small"
          [pTooltip]="tooltipProjects" tooltipPosition="top" placeholder="Top">
            {{tenderWrapperUiDto?.title}}

            <span class="inline-flex cursor-pointer" (click)="openEditTenderDrawer()">
              <i-lucide name="pencil" [size]="18" color="#0555F5" class="my-icon"></i-lucide>
              <ng-template #tooltipProjects>
                <div class="flex items-center p-2">
                  {{tenderWrapperUiDto?.title}}
                </div>
              </ng-template>
            </span>
          </div>
          <span class="text-gray-100 text-xs font-normal ">
            Ref. number: <span class="font-semibold me-3 text-gray-200">{{tenderWrapperUiDto?.tenderReferenceNo}}</span>
          </span>
          <div class="inline-flex">
            <div class="text-gray-200 text-xs font-semibold" [ngClass]="{'overflow-class-xlarge' : !tenderDescShow}">
              <span class="text-gray-100 text-xs font-normal">Desc: </span>
              {{tenderWrapperUiDto?.description}}
            </div>
            <a *ngIf="(tenderWrapperUiDto?.description?.length ?? 0) > 64" class="btn-link" (click)="toggleTenderDescription()">
              {{ tenderDescShow ? 'Close' : 'View...' }}
            </a>
          </div>

        </div>
        <div class="ms-2">
          <div class="flex items-center justify-between mb-2">
            <div class="basis-8/12">
              <div class="text-gray-200 text-xs font-normal overflow-class-xlarge">
                NIT documents:
                <img src="assets/icons/nit-docs.svg" class="inline-flex mx-2">
                <span
                  class="inline-flex h-4 w-6  items-center justify-center bg-blue-300 text-blue-500 rounded-full me-2">
                  {{tenderWrapperUiDto?.nitDocuments?.length || 0}}
                </span>
                <a class="btn-link" (click)="viewNitDocs()">View...</a>
              </div>
            </div>
            <div class="basis-4/12 text-end">
              <div class="text-gray-200 text-xs font-normal">
                Number of opportunities:
                <span class="font-semibold">{{tenderWrapperUiDto?.tenderOpportunityWrapperDtos?.length || 0}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white">
    <div class="flex flex-row">
      <div class="w-72 py-6 px-4 border-e border-blue-100">
        <div class="flex flex-row items-center justfy-between">
          <div class="basis-3/4">
            <div class=" text-black font-semibold text-base">
              List of opportunity
            </div>
          </div>
          <div class="basis-1/4 text-end">
            <button *ngIf="tenderWrapperUiDto?.opportunityType == 'MULTIPLE'" class="bg-blue-500 rounded h-6 w-6 p-1 items-center inline-flex" (click)="addNewOpportunity()">
              <i-lucide name="plus" [size]="18" color="#fff" class="my-icon "></i-lucide>
            </button>
          </div>
        </div>
        <div class="mt-3">
          <div class="border border-blue-500 bg-blue-100 rounded-lg py-2 ps-2 gap-28 mb-3"
            *ngFor="let opportunity of tenderWrapperUiDto?.tenderOpportunityWrapperDtos; index as i"
            (click)="selectCurrentOpportunity(opportunity)">
            <div class="flex flex-row justify-between">
              <div class="basis-11/12">
                <div class=" text-black font-semibold text-sm overflow-class-xsmall">
                  {{opportunity.title}}
                </div>
                <div class=" text-blue-500 font-normal text-xs ">
                  {{opportunity.bidSubmissionStartDate | ordinalDate}} - {{opportunity.bidSubmissionEndDate | ordinalDate}}
                </div>
              </div>
              <div class="basis-1/12 mt-3">
                <i-lucide name="chevron-right" [size]="22" color="#0555F5" class="my-icon"></i-lucide>
              </div>
            </div>
          </div>
          <!-- <div class="border border-blue-500 bg-blue-100 rounded-lg py-2 ps-2 gap-28">
            <div class="flex flex-row justify-between">
              <div class="basis-11/12">
                <div class=" text-black font-semibold text-sm overflow-class-xsmall">
                  Procurement of audio and video analysis and enhancement system
                </div>
                <div class=" text-blue-500 font-normal text-xs ">
                  Start date - End date
                </div>
              </div>
              <div class="basis-1/12 mt-3">
                <i-lucide name="chevron-right" [size]="22" color="#0555F5" class="my-icon"></i-lucide>
              </div>
            </div>
          </div> -->
        </div>

      </div>
      <div class="w-full">
        <div class="grid px-6 py-12 items-center justify-center"
          *ngIf="!tenderWrapperUiDto?.tenderOpportunityWrapperDtos || tenderWrapperUiDto?.tenderOpportunityWrapperDtos!.length == 0">
          <img src="assets/images/approval-1.svg" class=" h-16 w-16 mx-auto my-0">
          <div class="flex items-center justify-center">
            <div class="text-xl font-medium text-gray-700  mt-6">
              No opportunity found
            </div>
          </div>
          <div class="flex items-center justify-center">
            <div class="text-xs font-normal text-gray-500 mt-2 mb-6">
              You have not created any opportunity yet
            </div>
          </div>
          <div class="flex items-center justify-center">
            <button
              class="inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              (click)="addNewOpportunity()">
              <i-lucide name="plus" [size]="18" color="#fff" class="my-icon"></i-lucide>
              Create opportunity
            </button>
          </div>
        </div>
        <div *ngIf="tenderWrapperUiDto?.tenderOpportunityWrapperDtos!.length > 0">
          <div class="bg-gray-100 h-32">
            <div class="ps-4 py-3">
              <div class="text-black text-xs font-normal">
                Opportunity {{tenderOpportunityWrapperDto?.sequenceNo}}
              </div>
              <div class="text-black text-base font-semibold">
                {{tenderOpportunityWrapperDto?.title}}
              </div>
              <div class="text-black text-xs font-normal overflow-class-large mb-2">
                Desc: {{tenderOpportunityWrapperDto?.description}}
              </div>
              <div>
                <span
                  class="inline-flex py-1 px-3 text-sm font-medium text-blue-500 focus:outline-none bg-blue-300 rounded-full border border-blue-500 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                  {{tenderOpportunityWrapperDto?.category}}</span>
                <span *ngIf="tenderOpportunityWrapperDto?.subcategory" class="mx-3 text-blue-100">|</span>
                <span *ngIf="tenderOpportunityWrapperDto?.subcategory"
                  class="inline-flex py-1 px-3 text-sm font-medium text-blue-500 focus:outline-none bg-blue-300 rounded-full border border-blue-500 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                  {{tenderOpportunityWrapperDto?.subcategory}}</span>

              </div>
            </div>
          </div>
          <div class="border-b border-color-100 mt-3">
            <div class=" nav-bg text-start ps-3">
              <ul class="" style="list-style: none;" class="ps-0">
                <li class="list-class items-center inline-flex" (click)="toggleDetailsTab('DETAILS')"
                  [ngClass]="currentTab == 'DETAILS' ? 'btn-active' : 'btn-default'">
                  <i-lucide name="receipt-text" [size]="18" [color]="currentTab == 'DETAILS' ? '#0555F5' : '#9CA3AF'"
                    class="my-icon me-1"></i-lucide>
                  Details
                </li>
                <li class="list-class" (click)="toggleDetailsTab('CONFIG')"
                  [ngClass]="currentTab == 'CONFIG' ? 'btn-active' : 'btn-default'">
                  <i-lucide name="settings" [size]="18" [color]="currentTab == 'CONFIG' ? '#0555F5' : '#9CA3AF'"
                    class="my-icon me-1"></i-lucide>
                  Configurations
                </li>
                <li class="list-class btn-default" (click)="toggleDetailsTab('FEES')"
                  [ngClass]="currentTab == 'FEES' ? 'btn-active' : 'btn-default'">
                  <i-lucide name="badge-percent" [size]="18" [color]="currentTab == 'FEES' ? '#0555F5' : '#9CA3AF'"
                    class="my-icon me-1"></i-lucide>
                  Fees
                </li>
                <li class="list-class " (click)="toggleDetailsTab('CRITICAL_DATES')"
                  [ngClass]="currentTab == 'CRITICAL_DATES' ? 'btn-active' : 'btn-default'">
                  <i-lucide name="calendar" [size]="18" [color]="currentTab == 'CRITICAL_DATES' ? '#0555F5' : '#9CA3AF'"
                    class="my-icon me-1"></i-lucide>
                  Critical
                  Dates
                </li>
                <li class="list-class btn-default" (click)="toggleDetailsTab('APPROVALS')"
                  [ngClass]="currentTab == 'APPROVALS' ? 'btn-active' : 'btn-default'">
                  <i-lucide name="signature" [size]="18" [color]="currentTab == 'APPROVALS' ? '#0555F5' : '#9CA3AF'"
                    class="my-icon me-1"></i-lucide>
                  Approvals
                </li>
                <li class="mx-3 w-3 text-gray-200 list-class">|</li>
                <li class="list-class" (click)="toggleDetailsTab('QUESTIONNAIRE')"
                  [ngClass]="currentTab == 'QUESTIONNAIRE' ? 'btn-active' : 'btn-default'">
                  <i-lucide name="vault" [size]="18" class="my-icon me-1"
                    [color]="currentTab == 'QUESTIONNAIRE' ? '#0555F5' : '#9CA3AF'"></i-lucide>
                  Questionnaire
                </li>
                <li class="list-class" (click)="toggleDetailsTab('TECH_QUES')"
                  [ngClass]="currentTab == 'TECH_QUES' ? 'btn-active' : 'btn-default'">
                  <i-lucide name="cpu" [size]="18" class="my-icon me-1"
                    [color]="currentTab == 'TECH_QUES' ? '#0555F5' : '#9CA3AF'"></i-lucide>
                  Technical questions
                </li>
                <li class="list-class" (click)="toggleDetailsTab('FINANCIAL_QUES')"
                  [ngClass]="currentTab == 'FINANCIAL_QUES' ? 'btn-active' : 'btn-default'">
                  <i-lucide name="mail" [size]="18" class="my-icon me-1"
                    [color]="currentTab == 'FINANCIAL_QUES' ? '#0555F5' : '#9CA3AF'"></i-lucide>
                  Financial questions
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="currentTab == 'DETAILS'">
            <app-opportunity-details></app-opportunity-details>
          </div>
          <div *ngIf="currentTab == 'CONFIG'">
            <app-opportunity-configurations></app-opportunity-configurations>
          </div>
          <div *ngIf="currentTab == 'FEES'">
            <app-opportunity-fees></app-opportunity-fees>
          </div>
          <div *ngIf="currentTab == 'CRITICAL_DATES'">
            <app-opportunity-critical-dates></app-opportunity-critical-dates>
          </div>
          <div *ngIf="currentTab == 'APPROVALS'">
            <app-tender-approvals></app-tender-approvals>
          </div>
          <div *ngIf="currentTab == 'QUESTIONNAIRE'">
            <app-tender-questionnaire></app-tender-questionnaire>
          </div>
          <div *ngIf="currentTab == 'TECH_QUES' && tenderOpportunityWrapperDto?.tenderQuestionnaireWrapperDto?.tenderQuestionnaireTemplateDto">
            <app-new-tender-technical-question></app-new-tender-technical-question>
          </div>
          <div *ngIf="currentTab == 'FINANCIAL_QUES' && tenderOpportunityWrapperDto?.tenderQuestionnaireWrapperDto?.tenderQuestionnaireTemplateDto">
            <app-new-tender-financial-question></app-new-tender-financial-question>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>