import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationStartupDto } from 'src/app/shared/models/ApplicationStartupDto';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationStartupCacheService } from 'src/app/shared/services/application-startup-cache.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-onboarding-companies',
  templateUrl: './onboarding-companies.component.html',
  styleUrls: ['./onboarding-companies.component.sass']
})
export class OnboardingCompaniesComponent implements OnInit, OnDestroy {
  @Output() onNavigateInternalPage = new EventEmitter<string>;

  ctrlSearch: FormControl = new FormControl('')

  isDataLoading: boolean = false;

  applicationStartupDto?: ApplicationStartupDto;
  companiesList: CompanyUiDto[] = []
  filteredCompanies: CompanyUiDto[] = []

  _filteredCompanies$ = new BehaviorSubject<CompanyUiDto[]>([]);

  companiesSubscription$?: Subscription

  constructor(
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private drawerService: DrawerService,
    private changeDetectRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private adminDashboardService: AdminDashboardService,
    private appStartupService: ApplicationStartupCacheService,
    private dataRedirectionService: DashboardRedirectionService,
  ) { }

  ngOnInit(): void {
    this.applicationStartupDto = this.appStartupService.getApplicationStartupDto;

    this.loadCompanyUiDto();

    this.companiesSubscription$ = this.onboardingService.getCompanyUiDtos$.subscribe(data => {
      if (data) {
        this.companiesList = data;
        this.filteredCompanies = this.companiesList;
        this._filteredCompanies$.next(this.filteredCompanies);
      } else {
        this.companiesList = [];
        this.filteredCompanies = this.companiesList;
        this._filteredCompanies$.next(this.filteredCompanies);
      }
    })

    this.ctrlSearch.valueChanges.subscribe((val) => {
      this.search(val);
    })
  }

  async loadCompanyUiDto() {
    this.isDataLoading = true;
    await this.onboardingService.loadCompanyUiDtosByOrgCode();
    this.isDataLoading = false;
  }

  search(searchText: string) {
    let companiesList = [...this.companiesList ?? []];

    if (searchText.trim() != '') {
      let filteredCompanies = companiesList.filter((item) => item.companyName?.toLowerCase().includes(searchText.trim().toLowerCase()) || item.companyCode?.toLowerCase()?.includes(searchText.trim()));
      this.filteredCompanies = filteredCompanies;
    } else {
      this.filteredCompanies = companiesList;
    }

    this._filteredCompanies$.next(this.filteredCompanies);
  }

  navigateInternalPage(page: string, companyUiDto: CompanyUiDto) {
    if (companyUiDto) {
      this.onboardingService.setCurrentCompanyUiDto(companyUiDto);
    }
    this.onNavigateInternalPage.emit(page);
  }

  openNewCompanyDrawer(event: any) {
    event.stopPropagation();
    this.drawerService.isFormEditMode(false);
    this.drawerService.openDrawer('ONBOARDING_NEW_COMPANY');
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  getFormattedFinancialYear(date: string) {
    let convertedDate = ApplicationUtils.convertedOnlyDate(date);
    return this.datePipe.transform(convertedDate, 'dd MMM');
  }

  ngOnDestroy(): void {
    if (this.companiesSubscription$) {
      this.companiesSubscription$.unsubscribe();
    }
  }

}
