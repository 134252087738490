<div class="border rounded bg-white" [ngClass]="{'border-danger border-2': haveBidSettingErrors()}">
  <header class="p-2">
    <div class="row ">
      <div class="col-md-6">
        <h5 class="heading-h5"> BID SETTING</h5>
      </div>
      <div class="col-md-6 text-md-end">
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 " (click)="openLotBidDetailModal(lotBidDetailModal)" [disabled]="!isAllowToEdit()">
          <!-- <img src="assets/icons/edit_dark.svg" class=" c-pointer svg-icon-class "> -->
          EDIT
        </button>
      </div>
    </div>
  </header>
  <hr class="hr" />
  <div class="col-12 div-class py-2 px-3">
    Bid Information
  </div>
  <hr class="hr" />
  <div class="row px-3">
    <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-red-500 ':!lotEntityDto?.startingBid}">
      Starting Bid
    </div>
    <div class="col-8 div-class py-2">
      {{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(lotEntityDto?.startingBid)}}
    </div>
    <hr class="hr" />
    <div class="col-4 font-weight-normal py-2"
      [ngClass]="{'text-red-500 ': lotEntityDto?.lotType == 'Reserve' && !lotEntityDto?.reservePrice}">
      Reserve Price
    </div>
    <div class="col-8 div-class py-2">
      {{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(lotEntityDto?.reservePrice)}}
    </div>
    <hr class="hr" />
    <div class="col-4 font-weight-normal py-2" *ngIf="showReservedPrice()">
      Publish Reserve Price
    </div>
    <div class="col-8 div-class py-2" *ngIf="showReservedPrice()">
      {{lotEntityDto?.publishReservePrice ? 'YES' : 'NO'}}
    </div>
    <hr class="hr"  *ngIf="isEnableAutoSellerSetting()" />
    <div class="col-4 font-weight-normal py-2" *ngIf="showReservedPrice() && isEnableAutoSellerSetting()">
      Enable Auto Seller
    </div>
    <div class="col-8 div-class py-2" *ngIf="showReservedPrice() && isEnableAutoSellerSetting()">
      {{lotEntityDto?.enableAutoSeller ? 'YES' : 'NO'}}
    </div>
    <hr class="hr" />
    <div class="col-4 font-weight-normal py-2">
      Est.
    </div>
    <div class="col-8 div-class py-2">
      {{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(lotEntityDto?.estStartingPrice)}} - {{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(lotEntityDto?.estEndingPrice)}}
    </div>

    <hr class="hr" *ngIf="lotEntityDto?.incrementalWrapperDto?.bidCalculationMethodEnum == 'PERCENTAGE'" />
    <div class="col-4 font-weight-normal py-2"
      *ngIf="lotEntityDto?.incrementalWrapperDto?.bidCalculationMethodEnum == 'PERCENTAGE'">
      {{isReverseAuction() ?  'Bid Decrement' : 'Bid Increment'}} in Percentage
    </div>
    <div class="col-6 div-class py-2" *ngIf="lotEntityDto?.incrementalWrapperDto?.bidCalculationMethodEnum == 'PERCENTAGE'">
      {{lotEntityDto?.incrementalWrapperDto?.constantPercentage}}%
    </div>

    <hr class="hr" />
    <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-red-500 ':!lotEntityDto?.incrementalWrapperDto?.constantIncrement
      && !lotEntityDto?.incrementalWrapperDto?.bidIncrementalDtoList}">
     {{isReverseAuction() ?  'Bid Decrement' : 'Bid Increment'}}
    </div>
    <div class="col-6 div-class py-2" *ngIf="lotEntityDto?.incrementalWrapperDto">
      {{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(incrementBidValue)}}
      <span *ngIf="lotEntityDto?.incrementalWrapperDto?.incrementalBidType != 'SINGLE'">(Up To Infinity)</span>
    </div>

    <div class="col-2 text-end mt-2"
      *ngIf="lotEntityDto?.incrementalWrapperDto && lotEntityDto?.incrementalWrapperDto?.incrementalBidType != 'SINGLE'">
      <img src="assets/icons/view_light.svg" class=" c-pointer svg-icon-class ms-2"
        (click)="openBidIncrementListModal(bidIncrementListModal)">
    </div>
    <div *ngIf="isShowWarnAndBlock" class="row">
      <hr class="hr" *ngIf="lotEntityDto?.warnBid"/>
      <div class="col-4 font-weight-normal py-2" *ngIf="lotEntityDto?.warnBid">
        Warning Bid Value
      </div>
      <div class="col-8 div-class py-2" *ngIf="lotEntityDto?.warnBid">
        {{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(lotEntityDto?.warnBidValue)}} 
        <span *ngIf="lotEntityDto?.incrementalWrapperDto?.bidCalculationMethodEnum == 'PERCENTAGE'">({{lotEntityDto?.warnBidValueInPercent!}}%)</span>
      </div>
    </div>

    <div *ngIf="isShowWarnAndBlock" class="row">
      <hr class="hr" *ngIf="lotEntityDto?.blockBid" />
      <div class="col-4 font-weight-normal py-2" *ngIf="lotEntityDto?.blockBid">
        Blocking Bid Value
      </div>
      <div class="col-8 div-class py-2" *ngIf="lotEntityDto?.blockBid">
        {{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(lotEntityDto?.blockBidValue)}}
        <span *ngIf="lotEntityDto?.incrementalWrapperDto?.bidCalculationMethodEnum == 'PERCENTAGE'">({{lotEntityDto?.blockBidValueInPercent!}}%)</span>
      </div>
    </div>

    <hr class="hr" *ngIf="auctionEntityDto?.rankCurrentPriceConfig != rankPriceConfig.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION" />
    <div class="col-4 font-weight-normal py-2" *ngIf="auctionEntityDto?.rankCurrentPriceConfig != rankPriceConfig.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION">
      Allow Top Rank After Minutes
    </div>
    <div class="col-8 div-class py-2" *ngIf="auctionEntityDto?.rankCurrentPriceConfig != rankPriceConfig.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION">
      <span *ngIf="lotEntityDto?.allowXRank">
        Upto {{lotEntityDto?.allowXRank}} Rank for {{lotEntityDto?.allowYMinutes}} Minutes
      </span>
    </div>
  </div>
</div>


<ng-template #lotBidDetailModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>BID SETTING</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
     
      
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="saveAuctionLot()">
        <!-- Starting Bid -->
        <!-- <div class="form-floating input-group">
          <span class="border  border-end-0 pt-3 px-2">
            {{auctionEntityDto?.currency?.symbol}}
          </span>
          <input type="text" class="form-control border-start-0" placeholder="Starting Bid"
            formControlName="startingBid" (blur)="formatStartingBid()">
          <label for="floatingInput" class="level-place">Starting Bid</label>
        </div> -->
        <div class="input-group mt-3">
          <span class="border border-end-0 pt-4 px-1 fa-lg">
            {{auctionEntityDto?.currency?.symbol}}
          </span>
          <div class="form-floating">
            <input name="bucketSize" type="text" placeholder="Starting Bid" formControlName="startingBid" id="startingBid"
              (blur)="formatStartingBid()" class="form-control border-start-0" />
            <label for="startingBid">Starting Bid</label>
          </div>
        </div>
        <div *ngIf="isSubmitted || fc.startingBid.invalid && (fc.startingBid.dirty || fc.startingBid.touched)">
          <p class="text-red-500 " *ngIf="fc.startingBid.errors?.required">Required</p>
          <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.startingBid)">Invalid</p>
        </div>
        <!-- Reserve Bid -->
        <!-- <div class="form-floating mt-3 input-group" *ngIf="showReservedPrice()">
          <span class="border  border-end-0 pt-3 px-2">
            {{auctionEntityDto?.currency?.symbol}}
          </span>
          <input type="text" class="form-control border-start-0" placeholder="Reserve Price"
            formControlName="reservePrice" (blur)="formatReserveBid()">
          <label for="floatingInput" class="level-place">Reserve Price</label>
        </div> -->
        <div class="input-group mt-3">
          <span class="border border-end-0 pt-4 px-1 fa-lg">
            {{auctionEntityDto?.currency?.symbol}}
          </span>
          <div class="form-floating">
            <input name="bucketSize" type="text" (blur)="formatReserveBid()" placeholder="Reserve Price"
              formControlName="reservePrice" class="form-control border-start-0" id="reservePrice" />
            <label for="reservePrice">Reserve Price</label>
          </div>
        </div>
        <div *ngIf="isSubmitted || fc.reservePrice.invalid && (fc.reservePrice.dirty || fc.reservePrice.touched)">
          <p class="text-red-500 " *ngIf="fc.reservePrice.errors?.required">Required</p>
          <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.reservePrice)">Invalid</p>
        </div>


        <div class="row">
          <div class="col-md-6">
            <div class="input-group mt-3">
              <span class="border border-end-0 pt-4 px-1 fa-lg">
                {{auctionEntityDto?.currency?.symbol}}
              </span>
              <div class="form-floating">
                <input  type="text" (blur)="formatEstStartingPrice()" placeholder="Est. Starting Price"
                  formControlName="estStartingPrice" id="estStartingPrice" class="form-control border-start-0" />
                <label for="estStartingPrice">Est. Starting Price</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.estStartingPrice.invalid && (fc.estStartingPrice.dirty || fc.estStartingPrice.touched)">
              <p class="text-red-500 " *ngIf="fc.estStartingPrice.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.estStartingPrice)">Invalid</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mt-3">
              <span class="border border-end-0 pt-4 px-1 fa-lg">
                {{auctionEntityDto?.currency?.symbol}}
              </span>
              <div class="form-floating">
                <input  type="text" (blur)="formatEstEndingPrice()" placeholder="Est Starting Price"
                  formControlName="estEndingPrice" id="estEndingPrice" class="form-control border-start-0" />
                <label for="estEndingPrice">Est. Ending Price</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.estEndingPrice.invalid && (fc.estEndingPrice.dirty || fc.estEndingPrice.touched)">
              <p class="text-red-500 " *ngIf="fc.estEndingPrice.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.estEndingPrice)">Invalid</p>
            </div>
          </div>

        </div>



        <!-- Publish Reserve Price (Radio Button) -->
        <div class="col-12 mt-3 mb-2" *ngIf="showReservedPrice()">
          <div class="row">
            <div class="col-md-6 mb-2">
              Publish Reserve Price
            </div>
            <div class="col-md-6 mb-2">
              <span class="me-2">
                <input type="radio" [value]="true" formControlName="publishReservePrice" id="publishReservePrice1">
                <label for="publishReservePrice1">Yes</label>
              </span>
              <span>
                <input type="radio" [value]="false" formControlName="publishReservePrice" id="publishReservePrice2">
                <label for="publishReservePrice2">No</label>
              </span>
            </div>
          </div>
        </div>
        
        <!-- Enable Auto Seller (Radio Button) -->
        <div class="col-12 mt-3 mb-2">
          <div class="row" *ngIf="isEnableAutoSellerSetting()">
            <div class="col-md-6 mb-2">
              Enable Auto Seller
            </div>
            <div class="col-md-6 mb-2">
              <span class="me-2">
                <input type="radio" [value]="true" formControlName="enableAutoSeller" id="enableAutoSeller1">
                <label for="enableAutoSeller1">Yes</label>
              </span>
              <span>
                <input type="radio" [value]="false" formControlName="enableAutoSeller" id="enableAutoSeller2">
                <label for="enableAutoSeller2">No</label>
              </span>
            </div>
          </div>
        </div>
        
        <!-- Single Bid Increment (Radio Button) -->
        <div class="col-12 mt-3 mb-2" *ngIf="this.multipleBidIncrementDecrementAllowed">
          <div class="row">
            <div class="col-md-6 mb-2">
              Single Bid Increment
            </div>
            <div class="col-md-6 mb-2">
              <span class="me-2">
                <input type="radio" [value]="true" [formControl]="radioBidIncrement" id="radioBidIncrement1">
                <label for="radioBidIncrement1">Yes</label>
              </span>
              <span>
                <input type="radio" [value]="false" [formControl]="radioBidIncrement" id="radioBidIncrement2">
                <label for="radioBidIncrement2">No</label>
              </span>
            </div>
          </div>
        </div>

        <div class="col-12 mt-3 mb-3 text-center" *ngIf="radioBidIncrement.value">
          <span class="span-class">Absolute </span>
          <label class="switch">
            <input type="checkbox" id="ctrlBidCalculationMethod" [formControl]="ctrlBidCalculationMethod">
            <span class="slider round"></span>
          </label>
          <span class="mt-3 mt-md-0 span-class">
            Percentage
          </span>
        </div>

        <!-- when choose absolute -->
        <div class="mt-3 input-group" *ngIf="radioBidIncrement.value && !ctrlBidCalculationMethod.value">
          <span class="border border-end-0 pt-4 px-1 fa-lg">
            {{auctionEntityDto?.currency?.symbol}}
          </span>
          <div class="form-floating">
            <input input type="text" placeholder="Bid Increment" [formControl]="singleBidIncrement" id="singleBidIncrement"
            (blur)="formatSingleIncrement()" class="form-control border-start-0 " />
            <label for="singleBidIncrement" *ngIf="!isReverseAuction()" >Bid Increment</label>
            <label for="singleBidDecrement" *ngIf="isReverseAuction()" >Bid Decrement</label>
          </div>
          <!-- <button type="button" class="btn border disabled text-dark w-50">
            {{percentBidIncrement.value}}%
          </button> -->
        </div>
        <div
          *ngIf="radioBidIncrement.value && (isSubmitted || singleBidIncrement.invalid && (singleBidIncrement.dirty || singleBidIncrement.touched))">
          <p class="text-red-500 " *ngIf="singleBidIncrement.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="invalidPriceValidator(singleBidIncrement)">Invalid</p>
        </div>

        <!-- when choose percent -->
        <div class="row mt-2">
          <div class="col-md-6">
            <div class="input-group" *ngIf="radioBidIncrement.value && ctrlBidCalculationMethod.value">
              <span class="border border-end-0 pt-4 px-1 fa-lg">
                {{auctionEntityDto?.currency?.symbol}}
              </span>
              <div class="form-floating">
                <input input type="text" placeholder="" [formControl]="singleBidIncrement" id="singleBidIncrement"
                 class="form-control " />
                <label for="singleBidIncrement" *ngIf="!isReverseAuction()" >Absolute Value</label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class=" input-group" *ngIf="radioBidIncrement.value && ctrlBidCalculationMethod.value">
              <div class="form-floating">
                <input input type="text" placeholder="In Percentage" [formControl]="percentBidIncrement" id="percentBidIncrement"
                (blur)="formatPercentIncrement()" class="form-control border-end-0" />
                <label for="percentBidIncrement" *ngIf="!isReverseAuction()" >In Percentage</label>
                <label for="percentBidDecrement" *ngIf="isReverseAuction()" >In Percentage</label>
              </div>
              <span class="border pt-4 px-1 ">
                <b>%</b>
              </span>
            </div>
            <div
            *ngIf="radioBidIncrement.value && (isSubmitted || percentBidIncrement.invalid && (percentBidIncrement.dirty || percentBidIncrement.touched))">
            <p class="text-red-500 " *ngIf="percentBidIncrement.errors?.['required']">Required</p>
            <p class="text-red-500 " *ngIf="percentBidIncrement.errors?.['pattern']">Invalid</p>
          </div>
          </div>
        </div>


        <!-- when choose percent -->
        <!-- <div class="mt-3 input-group" *ngIf="radioBidIncrement.value && ctrlBidCalculationMethod.value">
          <button type="button" class="btn border disabled text-dark w-50">
            {{auctionEntityDto?.currency?.symbol}} {{singleBidIncrement.value}}
          </button>
          <div class="form-floating">
            <input input type="text" placeholder="Bid Increment in Percent" [formControl]="percentBidIncrement"
            (blur)="formatPercentIncrement()" class="form-control border-start-0" />
            <label for="floatingInput" *ngIf="!isReverseAuction()" >Bid Increment in Percent</label>
            <label for="floatingInput" *ngIf="isReverseAuction()" >Bid Decrement in Percent</label>
          </div>
          <span class="border border-start-0 pt-4 px-1 fa-lg">
            %
          </span>
        </div>
        <div
          *ngIf="radioBidIncrement.value && (isSubmitted || percentBidIncrement.invalid && (percentBidIncrement.dirty || percentBidIncrement.touched))">
          <p class="text-red-500 " *ngIf="percentBidIncrement.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="percentBidIncrement.errors?.['pattern']">Invalid</p>
        </div> -->

        <!-- <div class="input-group mt-3" *ngIf="radioBidIncrement.value">
          <span class="border border-end-0 pt-4 px-1 fa-lg">
            {{auctionEntityDto?.currency?.symbol}}
          </span>
          <div class="form-floating">
            <input name="bucketSize" type="text" placeholder="Bid Increment" [formControl]="singleBidIncrement"
              (blur)="formatSingleIncrement()" class="form-control border-start-0" />
            <label for="floatingInput" *ngIf="!isReverseAuction()" >Bid Increment</label>
            <label for="floatingInput" *ngIf="isReverseAuction()" >Bid Decrement</label>
          </div>
        </div>
        <div
          *ngIf="radioBidIncrement.value && (isSubmitted || singleBidIncrement.invalid && (singleBidIncrement.dirty || singleBidIncrement.touched))">
          <p class="text-red-500 " *ngIf="singleBidIncrement.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="invalidPriceValidator(singleBidIncrement)">Invalid</p>
        </div> -->

        <div class="mt-3" *ngIf="!radioBidIncrement.value">
          <div class="mb-2 text-end">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="addBidIncrementConfig()"
              [disabled]="infinityBidIncrement.disabled">
              <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-small">
            </button>
            <!-- <fa-icon [icon]="['far', 'square-plus']" class="text-primary fa-lg-bold  c-pointer"></fa-icon> -->
          </div>
          <div formArrayName="bidIncrementalDtoList">
            <div class="row" *ngFor="let item of incrementalConfig.controls; index as i" [formGroupName]="i">
              <!-- UP TO AMOUNT -->
              <div class="col-5">
                <div class="mt-3 input-group">
                  <span class="border  border-end-0 pt-4 px-2">
                    {{auctionEntityDto?.currency?.symbol}}
                  </span>
                  <div class="form-floating ">

                    <input type="text" class="form-control border-start-0" placeholder="Up To Amount" id="upToAmount"
                      formControlName="upToAmount" (blur)="formatIncrementUpToValue(i)">
                    <label for="upToAmount">Up To Amount</label>
                  </div>
                </div>
                <div
                  *ngIf="isSubmitted || item.get('upToAmount')?.invalid && (item.get('upToAmount')?.dirty || item.get('upToAmount')?.touched)">
                  <p class="text-red-500 " *ngIf="item.get('upToAmount')?.errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="invalidPriceValidator(item.get('upToAmount'))">Invalid</p>
                </div>
              </div>
              <!-- BID INCREMENT VALUE -->
              <div class="col-5">
                <div class="mt-3 input-group">
                  <span class="border  border-end-0 pt-4 px-2">
                    {{auctionEntityDto?.currency?.symbol}}
                  </span>

                  <div class="form-floating ">

                    <input type="text" class="form-control border-start-0" placeholder="Bid Increment" id="incrementValue"
                      formControlName="incrementValue" (blur)="formatIncrementValue(i)">
                      <label for="incrementValue">Bid Increment</label>
                  </div>
                </div>
                <div
                  *ngIf="isSubmitted || item.get('incrementValue')?.invalid && (item.get('incrementValue')?.dirty || item.get('incrementValue')?.touched)">
                  <p class="text-red-500 " *ngIf="item.get('incrementValue')?.errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="invalidPriceValidator(item.get('incrementValue'))">Invalid</p>
                </div>
              </div>
              <div class="col-2 mt-4">
                <span (click)="removeBidIncrementConfig(i)" *ngIf="!item.disabled"
                  [ngClass]="{'disabled' : isLoading }">
                  <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <div class="form-floating mt-3 input-group">
                UP TO INFINITY
              </div>
            </div>
            <div class="col-5">
              <div class="mt-3 input-group">
                <span class="border  border-end-0 pt-3 px-2">
                  {{auctionEntityDto?.currency?.symbol}}
                </span>

                <div class="form-floating ">

                  <input type="text" class="form-control border-start-0" placeholder="Bid Increment" id="infinityBidIncrement"
                    [formControl]="infinityBidIncrement" (blur)="formatInfinityIncrement()">
                  <label for="infinityBidIncrement">Infinity Increment Value</label>
                </div>
              </div>
              <div
                *ngIf="isSubmitted || infinityBidIncrement?.invalid && (infinityBidIncrement?.dirty || infinityBidIncrement?.touched)">
                <p class="text-red-500 " *ngIf="infinityBidIncrement?.errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="invalidPriceValidator(infinityBidIncrement)">Invalid</p>
              </div>
            </div>
          </div>


        </div>

        <div class="row" *ngIf="isShowWarnAndBlock">
          <div class="col-12 text-start mt-3">
            <span class="span-class me-2">Warn if bid changes({{isReverseAuction()? 'lower' : 'greater'}} than)
            </span>
            <span class="form-switch c-pointer">
              <input type="checkbox" id="warnBid" formControlName="warnBid" (change)="enableAndDisableWarnBid()" /></span>
          </div>
          <div class="col-md-6 mt-3" *ngIf="fc.warnBid.value">
            <div class="input-group ">
              <span class="border border-end-0 pt-4 px-1 fa-lg">
                {{auctionEntityDto?.currency?.symbol}}
              </span>
              <div class="form-floating">
                <input name="warnBidValue" type="text" placeholder="Warning Bid Value" formControlName="warnBidValue" id="warnBidValue"
                  (blur)="formatWarningBidValue()" class="form-control border-start-0" />
                <label for="warnBidValue">Warning Bid Value</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.warnBidValue.invalid && (fc.warnBidValue.dirty || fc.warnBidValue.touched)">
              <p class="text-red-500 " *ngIf="fc.warnBidValue.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.warnBidValue)">Invalid</p>
            </div>
          </div>
          <div class="col-md-6 mt-3" *ngIf="fc.warnBid.value && ctrlBidCalculationMethod.value">
            <div class="input-group ">
              <div class="form-floating">
                <input name="warnBidValueInPercent" id="warnBidValueInPercent" type="number" class="form-control border-end-0" placeholder="Warning Bid Value In Percent" formControlName="warnBidValueInPercent"
                  (blur)="formatWarningBidValueInPercent()" />
                <label for="warnBidValueInPercent">In Percentage</label>
              </div>
              <span class="border border-start-0 pt-4 px-1 ">
                <b>%</b>
              </span>
            </div>
            <div *ngIf="isSubmitted || fc.warnBidValueInPercent.invalid && (fc.warnBidValueInPercent.dirty || fc.warnBidValueInPercent.touched)">
              <p class="text-red-500 " *ngIf="fc.warnBidValueInPercent.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.warnBidValueInPercent)">Invalid</p>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="isShowWarnAndBlock">
          <div class="col-12 text-start mt-3">
            <span class="span-class me-2">Block if bid changes({{isReverseAuction()? 'lower' : 'greater'}} than)
            </span>
            <span class="form-switch c-pointer">
              <input type="checkbox" id="blockBid" formControlName="blockBid" (change)="enableAndDisableBlockBid()" /></span>
          </div>
          <div class="col-md-6" *ngIf="fc.blockBid.value">
            <div class="input-group mt-3">
              <span class="border border-end-0 pt-4 px-1 fa-lg">
                {{auctionEntityDto?.currency?.symbol}}
              </span>
              <div class="form-floating">
                <input name="blockBidValue" type="text" placeholder="Block Bid Value" formControlName="blockBidValue" id="blockBidValue"
                  (blur)="formatBlockBidValue()" class="form-control border-start-0" />
                <label for="blockBidValue">Block Bid Value</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.blockBidValue.invalid && (fc.blockBidValue.dirty || fc.blockBidValue.touched)">
              <p class="text-red-500 " *ngIf="fc.blockBidValue.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.blockBidValue)">Invalid</p>
            </div>
          </div>
          <div class="col-md-6 mt-3" *ngIf="fc.blockBid.value && ctrlBidCalculationMethod.value">
            <div class="input-group ">
              <div class="form-floating">
                 <input name="blockBidValueInPercent" type="number" placeholder="Block Bid Value" formControlName="blockBidValueInPercent" id="blockBidValueInPercent"
                  (blur)="formatBlockBidValueInPercent()" class="form-control border-end-0" />
                <label for="blockBidValueInPercent">In Percentage</label>
              </div>
              
              <span class="border border-start-0 pt-4 px-1 ">
                <b>%</b>
              </span>
              </div>
              <div *ngIf="isSubmitted || fc.blockBidValueInPercent.invalid && (fc.blockBidValueInPercent.dirty || fc.blockBidValueInPercent.touched)">
                <p class="text-red-500 " *ngIf="fc.blockBidValueInPercent.errors?.required">Required</p>
                <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.blockBidValueInPercent)">Invalid</p>
              </div>
          </div>
        </div>        

        <div class="row mt-3" *ngIf="auctionEntityDto?.rankCurrentPriceConfig != rankPriceConfig.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION">
          <div class="col-6">
            <div class=" mb-3 input-group">        
              <div class="form-floating">
                <input type="text" class="form-control" id="allowXRank" placeholder="Allow X Rank"
                  formControlName="allowXRank" (change)="checkAllowRankAfterMinutes()"
                  [ngClass]="{'is-invalid': fc.allowXRank.invalid || fc.allowXRank.invalid && (fc.allowXRank.dirty || fc.allowXRank.touched)}">
                <label for="allowXRank">Allow Top Rank</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.allowXRank.invalid && (fc.allowXRank.dirty || fc.allowXRank.touched)">
              <p class="text-red-500 " *ngIf="fc.allowXRank.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="fc.allowXRank.errors?.pattern">Invalid</p>
            </div>
          </div>
        
          <div class="col-6">
            <div class=" mb-3 input-group">
              <div class="form-floating">
                <input type="text" class="form-control " id="allowYMinutes" placeholder="Allow Y Minutes"
                  formControlName="allowYMinutes" (change)="checkAllowRankAfterMinutes()"
                  [ngClass]="{'is-invalid': fc.allowYMinutes.invalid || fc.allowYMinutes.invalid && (fc.allowYMinutes.dirty || fc.allowYMinutes.touched)}">
                <label for="allowYMinutes">After Minutes</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.allowYMinutes.invalid && (fc.allowYMinutes.dirty || fc.allowYMinutes.touched)">
              <p class="text-red-500 " *ngIf="fc.allowYMinutes.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="fc.allowYMinutes.errors?.pattern">Invalid</p>
            </div>
          </div>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Lot saved successfully.'">
          </app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="_errorMsg$ | async">
          </app-message-toast>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " [disabled]="isLoading || !isAllowToEdit()" (click)="saveAuctionLot()"
            ngbAutoFocus>SAVE</button>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>
      </form>
    </div>
  </div>
  </div>
</ng-template>

<ng-template #bidIncrementListModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Bid Increment List</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col" class=" font-weight-bold">#</th>
              <th scope="col" class=" font-weight-bold"> Up to Amount</th>
              <th scope="col" class=" font-weight-bold">Increment Value</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of bidIncrementalList; index as i">
              <th scope="row" class="font-weight-normal">{{i+1}}</th>
              <td class="font-weight-normal">{{getFormattedPrice(item?.upToAmount)}}</td>
              <td class="font-weight-normal">{{getFormattedPrice(item?.incrementValue)}}</td>
            </tr>
            <tr>
              <th scope="row" class="font-weight-normal">{{bidIncrementalList!.length + 1}}</th>
              <td class="font-weight-normal">Up To Infinity</td>
              <td class="font-weight-normal">{{getFormattedPrice(incrementBidValue)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>
