import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal, NgbModalRef, NgbPopover, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { RfxTypeEnum } from 'src/app/shared/enums/rfx/RfxTypeEnum';
import { Currency } from 'src/app/shared/models/Currency';
import { NavigationEventsDto } from 'src/app/shared/models/NavigationEventsDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { ProjectUiDto } from 'src/app/shared/models/ProjectUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { TimeZoneDto } from 'src/app/shared/models/TimeZoneDto';
import { PurchaseRequestUiDto } from 'src/app/shared/models/rfx/PurchaseRequestUiDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import { UserService } from 'src/app/shared/services/user.service';
import { NavigationActions } from 'src/app/shared/state-management/navigation/navigation.actions';
import { selectUserUiDto } from 'src/app/shared/state-management/session.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { StringMinValidator } from 'src/app/shared/validators/string-min.directive';

@Component({
  selector: 'app-add-rfx-modal',
  templateUrl: './add-rfx-modal.component.html',
  styleUrls: ['./add-rfx-modal.component.sass']
})
export class AddRfxModalComponent implements OnInit, OnDestroy {
  type?: RfxTypeEnum;
  projectUiDto?: ProjectUiDto;

  formGroup: FormGroup;

  rfxUiDto?: RfxUiDto;
  selectedCurrency?: Currency;
  purchaseRequestUiDto?: PurchaseRequestUiDto;

  rfxValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];
  currencies: Array<Currency> = [];
  plantUiDtos: PlantUiDto[] = [];

  isSubmitted: boolean = false;
  isLoading: boolean = false;
  fileUploadError: boolean = false;
  errorMsg: string | undefined;
  selectedEditingConfig: boolean = false;
  selectedEditingType?: string;

  allowEditingConfirmModalRef?: NgbModalRef;
  projectDiscardModalRef!: NgbModalRef;
  startTime?: NgbTimeStruct;
  ngbPopover?: NgbPopover;

  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  isMultiLineError$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');

  plantsListSubscription$?: Subscription;
  masterTimeZoneSubscription$?: Subscription;

  timezones: Array<TimeZoneDto> = [];

  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  @ViewChild('allowEditingConfirmModalTemplate') allowEditingConfirmModalTemplate?: TemplateRef<any>;

  constructor(
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private adminDashboardService: AdminDashboardService,
    private serverDataFetchService: ServerDataFetchService,
    private currencyService: CurrencyService,
    private onboardingService: OnboardingService,
    private dialogConfig: DynamicDialogConfig,
    private store: Store
  ) {
    this.formGroup = this.formBuilder.group({
      rfxTitle: new FormControl('', Validators.required),
      timeZone: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      startTime: new FormControl('', Validators.required),
      currency: new FormControl('', Validators.required),
      allowEditWhileUpcoming: new FormControl(true),
      allowEditWhileRunning: new FormControl(true),
      estimatedDeliveryDate: new FormControl('', Validators.required),

      plantCode: new FormControl('', Validators.required),
      costCenterCode: new FormControl('', Validators.required),
      leadTime: new FormControl('', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]),
      estSpend: new FormControl('', Validators.required),
      estSaving: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.type = this.dialogConfig.data['type'];
    this.projectUiDto = this.dialogConfig.data['projectUiDto'];

    this.currencies = this.currencyService.getCurrencies;

    this.masterTimeZoneSubscription$ = this.adminDashboardService.getMasterTimezones$.subscribe((data) => {
      if (data) {
        this.timezones = data;
      }
    });

    this.plantsListSubscription$ = this.onboardingService.getPlantUiDtos$.subscribe(data => {
      if (data) {
        this.plantUiDtos = data;
      } else {
        this.plantUiDtos = [];
      }
    })

    this.startTime = ApplicationUtils.getNgbPickerTime(
      `${new Date().getHours()}:${new Date().getMinutes()}`
    );

    if (this.projectUiDto?.purchaseRequestUiDto) {
      this.populateDataFromPurchaseRequest();
    }
  }

  get fc(): any {
    return this.formGroup.controls;
  }

  populateDataFromPurchaseRequest() {
    this.purchaseRequestUiDto = this.projectUiDto?.purchaseRequestUiDto!
    let estimatedDeliveryDate = ApplicationUtils.getNgbPickerDate(this.purchaseRequestUiDto?.estimatedDeliveryDate!);

    this.formGroup.patchValue({
      rfxTitle: this.purchaseRequestUiDto?.title,
      plantCode: this.purchaseRequestUiDto?.plantCode,
      costCenterCode: this.purchaseRequestUiDto?.costCenterCode,
      leadTime: this.purchaseRequestUiDto?.leadTime,
      estimatedDeliveryDate: estimatedDeliveryDate
    })
  }

  getTimeZone(dbTimeZone: string) {
    if (dbTimeZone && this.timezones.length > 0) {
      return this.timezones.find((item) => item.locale == dbTimeZone)?.fullName;
    }
    return ''
  }

  invalidPriceValidator(item: AbstractControl | null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }

  formatEstSpendValue() {
    if (this.selectedCurrency) {
      let estSpendValue = this.formGroup.controls['estSpend'].value;
      let estSpendValueFinal = ApplicationUtils.getFormattedPrice(this.selectedCurrency?.locale!, estSpendValue);
      this.fc['estSpend'].patchValue(estSpendValueFinal);
    }
  }

  formatEstSavingValue() {
    if (this.selectedCurrency) {
      let estSavingValue = this.formGroup.controls['estSaving'].value;
      let estSavingValueFinal = ApplicationUtils.getFormattedPrice(this.selectedCurrency?.locale!, estSavingValue);
      this.fc['estSaving'].patchValue(estSavingValueFinal);
    }
  }

  onChangeCurrency(event: any) {
    let name = event.target.value;
    let currency = this.currencies.find(m => m.name == name);
    this.selectedCurrency = currency;

    this.fc['estSpend'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.selectedCurrency?.regex!)]);
    this.fc['estSpend'].updateValueAndValidity();
    this.fc['estSaving'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.selectedCurrency?.regex!)]);
    this.fc['estSaving'].updateValueAndValidity();

    this.formatEstSpendValue();
    this.formatEstSavingValue();
  }

  openEditingConfirmModal(config: boolean, status: string) {
    this.selectedEditingConfig = config;
    this.selectedEditingType = status;

    if (this.rfxUiDto && this.rfxUiDto.active
      && (this.rfxUiDto.status == RfxStatus.LIVE || this.rfxUiDto.status == RfxStatus.LIVE_WAIT)) {
      this.allowEditingConfirmModalRef = this.ngbModal.open(this.allowEditingConfirmModalTemplate, {
        size: 'md', keyboard: false, backdrop: 'static'
      });
      return;
    }
  }

  handleSubmit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (this.checkStartTimeValidation()) {
      return;
    }

    this.rfxValidationErrorCodeDtoList = [];
    this._showErrorToast$.next(false);
    this._errorMsg$.next('');
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    this.addRfx();
  }

  async navigateToCreatorDashboard(eventId: string) {
    await this.serverDataFetchService.loadRfxForAdminSync(eventId);
    await this.serverDataFetchService.loadAllSubcategoriesOfRfxIdForAdminSync(eventId);

    // this.dataRedirectionService.storeNavigationContext(
    //   ApplicationConstants.ADMIN,
    //   ApplicationConstants.RFX_CREATOR_PAGE,
    //   ApplicationConstants.RFX_TAB
    // );
    // this.dataRedirectionService.setUpdateBreadcrumbs(true);

    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.ADMIN;
    navigation.pageName = ApplicationConstants.RFX_CREATOR_PAGE;
    navigation.tabName = ApplicationConstants.RFX_TAB;

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
  }

  async addRfx() {
    this._validationErrorPresent$.next(false);
    this._showErrorToast$.next(false);
    this.isMultiLineError$.next(false);
    this._errorMsg$.next('');

    let formValue = this.formGroup.getRawValue();

    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return;
    }
    if (typeof formValue.startDate != 'object') {
      return;
    }

    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    let startDate = ApplicationUtils.getDateFromNgDatePicker(
      this.datePipe,
      formValue.startDate
    );

    formValue.startDate = startDate;

    let userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));
    this.mergeRfxEntityDto(formValue, userUiDto!);

    this.adminDashboardService.saveInitialRfxDetails(this.rfxUiDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as RfxUiDto;
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.navigateToCreatorDashboard(data.rfxId!);
            this.closeModal();
          }, 2000);

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next('Error while saving Project. Try again.');
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      },
    });
  }

  async mergeRfxEntityDto(formValue: any, userUiDto: UserUiDto) {
    this.rfxUiDto = new RfxUiDto();
    this.rfxUiDto.rfxId = UUID.UUID().toString();
    this.rfxUiDto.orgCode = userUiDto?.orgCode;
    this.rfxUiDto.projectCode = this.projectUiDto?.projectCode;
    this.rfxUiDto.companyCode = userUiDto.companyCode;
    this.rfxUiDto.ownerId = userUiDto?.userId;
    this.rfxUiDto.rfxType = this.type!;
    this.rfxUiDto.rfxTitle = formValue.rfxTitle;
    this.rfxUiDto.timeZone = formValue.timeZone;
    this.rfxUiDto.startDate = formValue.startDate;
    this.rfxUiDto.currency = this.selectedCurrency;
    this.rfxUiDto.rfxDocs = [];
    this.rfxUiDto.leadTime = formValue.leadTime;
    this.rfxUiDto.plantCode = formValue.plantCode;
    this.rfxUiDto.costCenterCode = formValue.costCenterCode;
    this.rfxUiDto.description = this.purchaseRequestUiDto?.description;
    this.rfxUiDto.rfxDocs = this.purchaseRequestUiDto?.fileInfoDtos;
    this.rfxUiDto.allowEditWhileRunning = true;
    this.rfxUiDto.allowEditWhileUpcoming = true;
    this.rfxUiDto.startTime = ApplicationUtils.convertTime12to24(
      formValue.startTime
    );

    let estimatedDeliveryDate = ApplicationUtils.getDateFromNgDatePicker(
      this.datePipe,
      formValue.estimatedDeliveryDate
    );
    this.rfxUiDto.estimatedDeliveryDate = estimatedDeliveryDate!;

    this.rfxUiDto.estSpend = ApplicationUtils.resetPriceFormat(formValue.estSpend).toString();
    this.rfxUiDto.estSaving = ApplicationUtils.resetPriceFormat(formValue.estSaving).toString();
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll();
    }

    this.isSubmitted = false;
    this._validationErrorPresent$.next(false);
  }

  checkStartTimeValidation() {
    if (
      this.formGroup.controls['startDate'].value &&
      this.formGroup.controls['startTime'].value
    ) {
      let startDate = ApplicationUtils.getDateFromNgDatePicker(
        this.datePipe,
        this.formGroup.controls['startDate'].value
      );
      let startTime = ApplicationUtils.convertTime12to24(
        this.formGroup.controls['startTime'].value
      );

      if (startDate && startTime) {
        let _sDate = ApplicationUtils.convertedDate(startDate, startTime);

        if (_sDate && _sDate < new Date()) {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(
            'PROJECT START DATE/TIME MUST BE AFTER CURRENT TIME'
          );
          return true;
        } else {
          this._showErrorToast$.next(false);
          this._errorMsg$.next('');
        }
      }
      return false;
    }
    return true;
  }

  setTime(time: string) {
    if (time == 'startTime') {
      if (
        this.startTime?.hour != null &&
        this.startTime?.minute != null &&
        this.startTime?.second != null
      ) {
        let convertedTime = ApplicationUtils.getTimeFromNgTimePicker(
          this.datePipe,
          this.startTime
        );
        this.formGroup.controls?.['startTime'].patchValue(convertedTime);
      }
    }
    if (this.ngbPopover?.isOpen()) this.ngbPopover?.close();
    this.formGroup.updateValueAndValidity();
    this.checkStartTimeValidation();
  }

  openTimepicker(p: NgbPopover, template: any) {
    if (this.ngbPopover?.isOpen) this.ngbPopover.close();

    if (!p.isOpen()) {
      this.ngbPopover = p;
      p.ngbPopover = template;
      p.open();
    } else {
      p.close();
    }
  }

  ngOnDestroy(): void {
    if (this.masterTimeZoneSubscription$) {
      this.masterTimeZoneSubscription$.unsubscribe();
    }
    if (this.plantsListSubscription$) {
      this.plantsListSubscription$.unsubscribe();
    }
  }
}
