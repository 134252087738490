<div>
  <div class="flex flex-row gap-4 items-center justify-between h-12 bg-white px-4 py-4 border-b">
    <div class="basis-1/2">
      <div class="inline-block">
        <div class="font-semibold text-black text-xl">
          Creating new approval workflow
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-100 h-8 py-2 px-6 flex items-center flex">
    <div class="inline-flex font-light text-base text-gray-600 me-2">Title:</div>
    <div class="inline-flex font-semibold text-base text-gray-800 me-3 overflow-class-small"  [pTooltip]="tooltipProjects" tooltipPosition="top" placeholder="Top">
      {{tenderWrapperUiDto?.title}}
      <ng-template #tooltipProjects>
        <div class="flex items-center p-2">
          {{tenderWrapperUiDto?.title}}
        </div>
      </ng-template>
    </div>
    <span class="font-light text-base text-gray-600 me-2">Ref. number:</span>
    <span class="font-semibold text-base text-gray-800 me-3">
      {{tenderWrapperUiDto?.tenderReferenceNo}}
    </span>
  </div>
  <div class="flex flex-row">
   
        <div class="w-full">
          <div class="border-b border-color-100 mt-3">
            <div class=" nav-bg text-start ps-3">
              <ul class="" style="list-style: none;" class="ps-0">
                <li class="list-class items-center inline-flex" (click)="toggleDetailsTab(applicationConstants.TENDER_PUBLISHERS)"
                  [ngClass]="currentTab == applicationConstants.TENDER_PUBLISHERS ? 'btn-active' : 'btn-default'">
                  <i-lucide name="signature" [size]="18"
                    [color]="currentTab == applicationConstants.TENDER_PUBLISHERS ? '#0555F5' : '#475569'" class="my-icon me-1"></i-lucide>
                  Tender Publishers
                </li>
                <li class="list-class" (click)="toggleDetailsTab(applicationConstants.TECHNICAL_BID_OPENERS)"
                  [ngClass]="currentTab == applicationConstants.TECHNICAL_BID_OPENERS ? 'btn-active' : 'btn-default'">
                  <i-lucide name="receipt-text" [size]="18"
                  [color]="currentTab == applicationConstants.TECHNICAL_BID_OPENERS ? '#0555F5' : '#475569'" class="my-icon me-1"></i-lucide>
                  Technical bid openers
                </li>
                <li class="list-class" (click)="toggleDetailsTab(applicationConstants.FINANCIAL_BID_OPENERS)"
                  [ngClass]="currentTab == applicationConstants.FINANCIAL_BID_OPENERS ? 'btn-active' : 'btn-default'">
                  <i-lucide name="mail" [size]="18"
                  [color]="currentTab == applicationConstants.FINANCIAL_BID_OPENERS ? '#0555F5' : '#475569'" class="my-icon me-1"></i-lucide>
                  Financial bid openers
                </li>
              </ul>
            </div>
          </div>

          <div *ngIf="currentTab == applicationConstants.TENDER_PUBLISHERS">
           <app-approvals-tender-publishers-drawer></app-approvals-tender-publishers-drawer>
          </div>
          <div *ngIf="currentTab == applicationConstants.TECHNICAL_BID_OPENERS">
            <app-approvals-technical-bid-openers-drawer></app-approvals-technical-bid-openers-drawer>
          </div>
          <div *ngIf="currentTab == applicationConstants.FINANCIAL_BID_OPENERS">
            <app-approvals-financial-bid-openers-drawer></app-approvals-financial-bid-openers-drawer>
          </div>
        </div>
      
  </div>
 
</div>