<div class="modalalert  my-2 p-2 rounded" *ngIf="!isErrorButton"
  [ngClass]="isError ? 'error-msg' : 'success-msg'">
  <div class="row ">
   <div class="col-1 me-2">
    <img *ngIf="!isError" src="assets/icons/approved_green.svg" class=" c-pointer svg-icon-class " >
    <img *ngIf="isError" src="assets/icons/rejected_red.svg" class=" c-pointer svg-icon-class ">
   </div>
  <div class="col-10">
    {{message}}
  </div>
  </div>
</div>


<div class="modalalert error-msg my-2 me-1 p-2 rounded" *ngIf="isErrorButton">
 <div class="row">
  <div class="col-1 me-2">
    <img src="assets/icons/rejected_red.svg" class=" c-pointer svg-icon-class">
  </div>
    
   <div class="col-10">
    {{message}}
    <a (click)="onButtonClick()" class="text-red-500  text-decoration-underline" role="button">{{messageButton}}</a>
 
  </div>
 </div>
</div>
