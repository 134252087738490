import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { selectTenderSourcingEventDtos } from 'src/app/shared/state-management/tender/tender.features';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { TenderSourcingEventDto } from 'src/app/shared/models/user/TenderSourcingEventDto';
import { NavigationEventsDto } from 'src/app/shared/models/NavigationEventsDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { NavigationActions } from 'src/app/shared/state-management/navigation/navigation.actions';
import { TenderManagementService } from 'src/app/shared/services/tender-management.service';
import { TenderManagementActions } from 'src/app/shared/state-management/tender/tender.actions';
import { UserInfoUiDto } from 'src/app/shared/models/user/UserInfoUiDto';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { selectOrganizationUiDto } from 'src/app/shared/state-management/onboarding/onboarding.features';

interface TenderFilters {
  status: string[];
  type: string[];
  creator: string[];
}

@Component({
  selector: 'app-tender-management',
  templateUrl: './tender-management.component.html',
  styleUrls: ['./tender-management.component.sass']
})
export class TenderManagementComponent implements OnInit, OnDestroy {
  tenderSourcingEventDtos$: Observable<TenderSourcingEventDto[]>;

  ctrlSearch: FormControl = new FormControl('');

  filteredEvents: TenderSourcingEventDto[] = [];
  filters: TenderFilters = {
    status: [],
    type: [],
    creator: []
  };

  currentIndex: number = -1;
  isLoading: boolean = false;
  isNavLoading: boolean = false;
  isEditLoading: boolean = false;
  isShowFilterSidebar: boolean = true;

  tenderSourcingEventsSubscription$?: Subscription;
  currentOrganizationSubscription$?: Subscription;

  createNewMenuItems = [
    { title: 'Tender', subtitle: 'Some dummy text about the tender', escape: false },
    { title: 'EOI', subtitle: 'Some dummy text about the tender', escape: false },
    { title: 'Pre-qualification', subtitle: 'Some dummy text about the tender', escape: false },
    { title: 'Auction', subtitle: 'Some dummy text about the tender', escape: false },
  ]
  TableMenuItems= [
    { title: 'Edit',escape: false },
  ]

  statusFilters: string[] = ['ALL', 'DRAFT', 'SCHEDULED', 'PUBLISH', 'AWARDED'];
  typeFilters: string[] = ['ALL', 'TENDER', 'EOI', 'PQ', 'AUCTION'];
  creatorFilters: UserInfoUiDto[] = [];

  constructor(
    private onboardingService: OnboardingService,
    private store: Store,
    private drawerService: DrawerService,
    private tenderManagementService: TenderManagementService
  ) {
    this.tenderSourcingEventDtos$ = this.store.pipe(select(selectTenderSourcingEventDtos));
  }

  ngOnInit(): void {
    this.currentOrganizationSubscription$ = this.store.pipe(select(selectOrganizationUiDto)).subscribe((data) => {
      if (data) {
        this.loadTenderSourcingEvents()
      }
    });

    this.tenderSourcingEventsSubscription$ = this.tenderSourcingEventDtos$.subscribe(events => {
      this.filteredEvents = ApplicationUtils.deepClone(events);
      this.creatorFilters = [...new Map(this.filteredEvents.map(item => [item.createdBy?.userId, item.createdBy!])).values()];
      this.applyFilters();
    });

    this.ctrlSearch.valueChanges.subscribe(() => {
      this.applyFilters();
    })
  }

  async loadTenderSourcingEvents() {
    this.isLoading = true;

    // Clear Selected Tender and Opportunity
    this.store.dispatch(TenderManagementActions.clearCurrentTenderWrapperUiDto());

    // Load All Tenders
    await this.tenderManagementService.loadTenderSourcingEvents();
    await this.tenderManagementService.loadAllAdminUsers();

    this.isLoading = false;
  }

  toggleFilterSidebar() {
    this.isShowFilterSidebar = !this.isShowFilterSidebar;
  }

  onFilterChange(filterType: keyof TenderFilters, filterValue: string, event: any) {
    const isChecked = event.target.checked;

    // Add or remove filters
    if (isChecked) {
      if (filterValue === 'ALL') {
        this.filters[filterType] = [];
      } else {
        this.filters[filterType].push(filterValue);
      }
    } else {
      this.filters[filterType] = this.filters[filterType].filter(value => value !== filterValue);

      if (filterValue === 'ALL') {
        this.filters[filterType] = [];
      }
    }

    this.applyFilters();
  }

  applyFilters() {
    this.tenderSourcingEventDtos$.subscribe(events => {
      // Apply filters
      let tempEvents = events.filter(event => {
        const matchesStatus = this.filters.status.length ? this.filters.status.includes(event.status as string) : true;
        const matchesType = this.filters.type.length ? this.filters.type.includes(event.type as string) : true;
        const matchesCreator = this.filters.creator.length ? this.filters.creator.includes(event.createdBy?.userId as string) : true;
    
        return matchesStatus && matchesType && matchesCreator;
      });

      // Apply search
      const searchValue = this.ctrlSearch.value;
      if (searchValue) {
        tempEvents = tempEvents.filter(event => {
          return event.eventId!.toLowerCase().includes(searchValue.toLowerCase()) ||
            event.referenceNo!.toLowerCase().includes(searchValue.toLowerCase()) ||
            event.type!.toLowerCase().includes(searchValue.toLowerCase()) ||
            event.status!.toLowerCase().includes(searchValue.toLowerCase());
        });
      }

      this.filteredEvents = tempEvents; // Update filteredEvents with the final result
    });
  }

  isCreatorSelected(creator: UserInfoUiDto): boolean {
    return this.filters.creator.includes(creator.userId!);
  }

  onCreatorFilterChange(creator: UserInfoUiDto, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      if (!this.filters.creator.includes(creator.userId!)) {
        this.filters.creator.push(creator.userId!);
      }
    } else {
      this.filters.creator = this.filters.creator.filter(id => id !== creator.userId);
    }
    this.applyFilters(); // Apply filters or update your view accordingly
  }

  async navigateTenderOpportunityDetails(tenderSourcingEventDto: TenderSourcingEventDto, index: number) {
    this.currentIndex = index;
    this.isNavLoading = true;

    await this.tenderManagementService.loadTenderWrapperUiDto(tenderSourcingEventDto.eventId!);

    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.ADMIN;
    navigation.pageName = ApplicationConstants.TENDER_OPPORTUNITY_DETAILS_PAGE;
    navigation.mainPageName = ApplicationConstants.TENDER_MANAGEMENT_PAGE;
    navigation.resourceNo = tenderSourcingEventDto.referenceNo;
    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));

    this.isNavLoading = false;
    this.currentIndex = -1;
  }

  addNewTender() {
    this.store.dispatch(TenderManagementActions.clearCurrentTenderWrapperUiDto());
    this.drawerService.openDrawer(ApplicationConstants.NEW_TENDER_PAGE);
  }

  async openEditTender(tenderSourcingEventDto: TenderSourcingEventDto, index: number) {
    this.currentIndex = index;
    this.isEditLoading = true;

    await this.tenderManagementService.loadTenderWrapperUiDto(tenderSourcingEventDto.eventId!);
    this.drawerService.openDrawer(ApplicationConstants.NEW_TENDER_PAGE);

    this.isEditLoading = false;
    this.currentIndex = -1;
  }

  ngOnDestroy(): void {
    if (this.tenderSourcingEventsSubscription$) {
      this.tenderSourcingEventsSubscription$.unsubscribe();
    }
    if(this.currentOrganizationSubscription$){
      this.currentOrganizationSubscription$.unsubscribe();
    }
  }
}
