<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>LOT FEATURES</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
 <div class="model-body-top">
  <div class="m-3">
    <div class="mb-3">
      Please ensure currect and relevant feature is captured as this information will be shown on auction listing as
      is
    </div>
    <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
      <div class="form-floating mb-3">
        <input type="text" class="form-control" placeholder="Key" formControlName="featureKey" id="featureKey"
        [ngClass]="{'is-invalid': (_validationErrorPresent$ | async) && fc.featureKey.invalid || fc.featureKey.invalid && (fc.featureKey.dirty || fc.featureKey.touched)}"
        >
        <label for="featureKey">Feature Name</label>
        <div class="invalid-feedback" *ngIf="(_validationErrorPresent$ | async) && fc.featureKey.invalid || fc.featureKey.invalid && (fc.featureKey.dirty || fc.featureKey.touched)">
          Required
        </div>
      </div>
      <div class="form-floating mb-3">
        <input type="text" class="form-control" placeholder="Value" formControlName="featureValue" id="featureValue"
        [ngClass]="{'is-invalid': (_validationErrorPresent$ | async) && fc.featureValue.invalid || fc.featureValue.invalid && (fc.featureValue.dirty || fc.featureValue.touched)}"
        >
        <label for="featureValue">Attributes Value</label>
        <div class="invalid-feedback" *ngIf="(_validationErrorPresent$ | async) && fc.featureValue.invalid || fc.featureValue.invalid && (fc.featureValue.dirty || fc.featureValue.touched)">
          Required
        </div>
      </div>
      <div class="my-3" >
        <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="'Lot saved successfully.'"></app-message-toast>
      </div>
      <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="handleValidSubmit()" [disabled]="!isAllowToEdit()">SAVE</button>
      </div>
      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner" *ngIf="isLoading" ></div>
      </div>
    </form>
  </div>
 </div>
</div>
