
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { BehaviorSubject, Observable, OperatorFunction, Subscription, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, TemplateRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { HtmlViewComponent } from 'src/app/shared/components/html-view/html-view.component';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileService } from 'src/app/shared/services/file.service';
import { ErrorCodeDto } from 'src/app/shared/models/user/ErrorCodeDto';
import SwiperCore, { Virtual } from 'swiper';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { DeploymentConfigurationDto } from 'src/app/shared/models/user/DeploymentConfigurationDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { AddSubcategoryComponent } from '../rfx-creator/add-subcategory/add-subcategory.component';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { RfxSubcategorySaveWrapperDto } from 'src/app/shared/models/user/RfxSubcategorySaveWrapperDto';
import { RfxEnableWrapperDto } from 'src/app/shared/models/rfx/RfxEnableWrapperDto';
import { RfxValidationErrorCodeDto } from 'src/app/shared/models/user/RfxValidationErrorCodeDto';
import { RfxValidationService } from 'src/app/shared/services/rfx-validation.service';
import { AddRfxBulkSubcategoryVerificationComponent } from '../add-rfx-bulk-subcategory-verification/add-rfx-bulk-subcategory-verification.component';
import { SubcategoryErrorValidationComponent } from 'src/app/shared/components/subcategory-error-validation/subcategory-error-validation.component';

SwiperCore.use([Virtual]);

@Component({
  selector: 'app-rfx-subcategory-information',
  templateUrl: './rfx-subcategory-information.component.html',
  styleUrls: ['./rfx-subcategory-information.component.sass']
})
export class RfxSubcategoryInformationComponent implements OnInit, AfterViewInit, OnDestroy {

  rfxSubcategoryEntityDtos?: RfxSubcategoryUiDto[];
  rfxSubcategoryEntityDto?: RfxSubcategoryUiDto;
  rfxEntityDto?: RfxUiDto;
  rfxValidationErrorCodeDtoList?: RfxValidationErrorCodeDto[];

  deleteIndex?: number;

  isLoading: boolean = false;
  rfxSubcategoryActiveStatus: boolean = false;

  _isOrderActive$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  isShowInspectionDetail$ = new BehaviorSubject<boolean>(false);
  isShowPickUpDetails$ = new BehaviorSubject<boolean>(false);

  IsTable = true;

  excelDocumentTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  fileUploadError: boolean = false;
  selectedBulkUploadFile?: File | null;
  isSubmittedBulkSubcategoriesFile: boolean = false;

  isSubmitted: boolean = true;
  isSingleRfxSubcategory: boolean = true;
  selectedRfxSubcategoryDropdown?: string;
  selectedBulkRfxSubcategoriesFileName: string = "";
  checkStatusBulkRfxSubcategoryInterval: any;

  selectedRfxSubcategorySubscription$?: Subscription;
  selectedRfxSubcategoriesSubscription$?: Subscription;
  selectedSubscription$?: Subscription;
  deploymentConfigurationDto?: DeploymentConfigurationDto;
  deploymentConfigurationDtoSubscription$?: Subscription;


  _isShowTableView$ = new BehaviorSubject<boolean>(false);

  @ViewChild('searchRfxSubcategory') searchRfxSubcategoryField?: ElementRef<HTMLInputElement>;
  @ViewChild('bulkUploadSheetRef') bulkUploadSheetRef?: HTMLInputElement;
  @ViewChild('bulkRfxSubcategoryErrorModel') bulkRfxSubcategoryErrorModel?: TemplateRef<any>;

  bulkRfxSubcategoryList: any[] = [];

  constructor(
    private fileService: FileService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private ngbModal: NgbModal,
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private validationService: RfxValidationService,
    public translate: TranslateService,
    public adminService: AdminDashboardService,
    private changeDetectRef: ChangeDetectorRef,
    private serverDataFetchService: ServerDataFetchService
  ) {
  }

  searchOption: string[] = [];

  public selectedValue: string = "";
  masterCategories: any[] = [];

  public screenWidth: any;


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  search?: OperatorFunction<string, readonly string[]>;

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    this.search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map((term) =>
          term.length < 1 ? [] : this.filterRfxSubcategoryList(term).slice(0, 10),
        ),
      );

    this.deploymentConfigurationDtoSubscription$ = this.adminService.getDeploymentConfiguration$.subscribe(data => {
      if (data) {
        this.deploymentConfigurationDto = data;
      }
    })

    this.selectedRfxSubcategorySubscription$ = this.adminRfxSubcategoryDataHolderService._selectedRfxSubcategory$.subscribe(data => {
      if (data) {
        this.rfxSubcategoryEntityDto = data;

        this.selectedRfxSubcategoryDropdown = this.rfxSubcategoryEntityDto.subcategorySequence + " - " + (this.rfxSubcategoryEntityDto.title ? this.rfxSubcategoryEntityDto.title : ' ');

        if (this.rfxSubcategoryEntityDtos) {
          this.rfxSubcategoryEntityDtos.sort((a, b) => a.subcategorySequence! - b.subcategorySequence!);
        }

        this.changeDetectRef.detectChanges()
      }
    })

    this.selectedRfxSubcategoriesSubscription$ = this.adminRfxSubcategoryDataHolderService._allRfxSubcategoryList$.subscribe(data => {
      this.rfxSubcategoryEntityDtos = [];
      if (data) {
        this.rfxSubcategoryEntityDtos = data;
        this.rfxSubcategoryEntityDtos.sort((a, b) => a.subcategorySequence! - b.subcategorySequence!);
        this.changeDetectRef.detectChanges()
      }
    })
    this.adminService.getMasterCategories$.subscribe((dataList) => {
      if (dataList) {
        this.masterCategories = dataList;
      } else {
        this.masterCategories = [];
      }
    })

    this.selectedSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(data => {
      if (data) {
        this.rfxEntityDto = data!;
        this.isSingleRfxSubcategory = data.noOfSubcategories! == 1 ?? true;

        if (this.isSingleRfxSubcategory) {
          this._isShowTableView$.next(false);
        }
      }
    })
  }

  ngAfterViewInit(): void {
  }

  ngAfterViewChecked(): void {
  }

  getSelectedCategoryData(searchText: string, id: string,) {
    let tempList = [... this.masterCategories ?? []];

    let filterData = tempList.filter(item => (item.category.toString().toLowerCase().includes(searchText.trim().toLowerCase())
      || item.category.toString().toLowerCase().includes(searchText.trim().toLowerCase())) && (item.id == id));

    if (filterData && filterData.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  changeSubcategoryView() {
    this._isShowTableView$.next(!this._isShowTableView$.value);
  }

  filterRfxSubcategoryList(searchText: string) {
    this.searchOption = [];
    if (searchText) {
      let tempRfxSubcategoryEntityDtos = [... this.rfxSubcategoryEntityDtos ?? []];
      let filterList = tempRfxSubcategoryEntityDtos.filter(item => item.subcategorySequence == Number(searchText.trim()) || item.title?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || this.removeHtmlTag(item.description?.trim()).toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || item.preferenceCategory?.categoryName == searchText.trim()
        || item.preferenceCategory?.categoryLevelOneName == searchText.trim()
        || item.preferenceCategory?.categoryLevelTwoName == searchText.trim()
        || item.preferenceCategory?.categoryLevelThreeName == searchText.trim()
        || item.preferenceCategory?.categoryLevelFourName == searchText.trim()
      );

      if (filterList && filterList.length > 0) {
        this.searchOption = filterList.map(item => item.subcategorySequence + " : " + (item.title ?? ''));
      }

    }
    return this.searchOption;
  }

  openCSVFile(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openRfxSubcategoryDiscardModel(content: any) {
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openDisableUserModal(content: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this._errorMsg$.next("");
    this.rfxSubcategoryActiveStatus = this.rfxSubcategoryEntityDto?.active!;
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  getRfxSubcategoryStatus() {
    if (this.rfxSubcategoryEntityDto?.active) {
      if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT) {
        return "UPCOMING"
      } else {
        let endDate = this.rfxSubcategoryEntityDto.endDate + " " + this.rfxSubcategoryEntityDto.endTime;
        if (ApplicationUtils.getRemainingTime(endDate, this.rfxEntityDto?.timeZone!) > 0) {
          return "RUNNING";
        } else {
          return "CLOSED";
        }
      }


    } else {
      return "DRAFT";
    }
  }

  changeCurrentRfxSubcategory(subcategoryId: string) {
    let selectedRfxSubcategory = this.rfxSubcategoryEntityDtos?.find(item => item.subcategoryId == subcategoryId);
    this.adminRfxSubcategoryDataHolderService.setSelectedRfxSubcategory(selectedRfxSubcategory!);
    this.rfxSubcategoryEntityDto = selectedRfxSubcategory;
  }

  changeCurrentRfxSubcategoryById(selectedRfxSubcategoryId: string) {
    let selectedRfxSubcategory = this.rfxSubcategoryEntityDtos?.find((item => item.subcategoryId == selectedRfxSubcategoryId));
    if (selectedRfxSubcategory) {
      this.adminRfxSubcategoryDataHolderService.setSelectedRfxSubcategory(selectedRfxSubcategory);
      this.rfxSubcategoryEntityDto = selectedRfxSubcategory;
      this.selectedRfxSubcategoryDropdown = this.rfxSubcategoryEntityDto.subcategorySequence + " - " + (this.rfxSubcategoryEntityDto.title ? this.rfxSubcategoryEntityDto.title : ' ');
    }
  }

  changeCurrentRfxSubcategoryBySearch() {
    if (this.selectedValue && this.selectedValue.includes(":")) {
      let rfxSubcategorySeq = this.selectedValue.substring(0, this.selectedValue.indexOf(':'));
      let selectedRfxSubcategory = this.rfxSubcategoryEntityDtos?.find((item => item.subcategorySequence == Number(rfxSubcategorySeq)));

      if (selectedRfxSubcategory) {
        this.adminRfxSubcategoryDataHolderService.setSelectedRfxSubcategory(selectedRfxSubcategory);
        this.rfxSubcategoryEntityDto = selectedRfxSubcategory;
      }
    }
  }

  discardRfxSubcategory() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._errorMsg$.next('');
    this._showErrorToast$.next(false);
    this.isLoading = true;

    this.adminService.rfxSubcategoryDiscard(this.rfxSubcategoryEntityDto?.rfxId!, this.rfxSubcategoryEntityDto?.subcategoryId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.serverDataFetchService.loadAllSubcategoriesOfRfxIdForAdminSync(this.rfxSubcategoryEntityDto?.rfxId!);
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)

        } else {
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._errorMsg$.next("Error while discarding Rfx Subcategory. Try again.");
        this._showErrorToast$.next(false);
      }
    })
  }

  markRfxSubcategoryActiveOrInActive(action: string) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next("")
    let errorDtoList: RfxValidationErrorCodeDto[] = []

    this.validationService.doRfxSubcategoryValidation(errorDtoList, this.rfxEntityDto!, this.rfxSubcategoryEntityDto!);

    if (errorDtoList && errorDtoList.length > 0) {
      this.rfxValidationErrorCodeDtoList = errorDtoList;
      this.closeModal();
      this.openErrorModel();
      return;
    }

    let rfxSubcategoryEntityDto = ApplicationUtils.clone(this.rfxSubcategoryEntityDto);
    rfxSubcategoryEntityDto!.active = action == "ENABLE";

    this.isLoading = true;

    this.adminService.markRfxSubcategoryActiveOrInActive(rfxSubcategoryEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let rfxValidationErrorCodeDtoList = apiResponseDto.data as Array<RfxValidationErrorCodeDto>;
          if (!rfxValidationErrorCodeDtoList || rfxValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.rfxSubcategoryActiveStatus = action == "ENABLE";
              this.closeModal();
            }, 2000)
          } else {
            this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
            this.openErrorModel();
            this.isLoading = false;
          }
        } else {
          if (apiResponseDto.code == 'RFX-EXCEPTION-127') {
            this._errorMsg$.next("Error while enabling Rfx Subcategory. Enable Rfx Subcategory and try again.");
          } else {
            this._errorMsg$.next(apiResponseDto?.message!);
          }
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next(`Error while ${action == 'ENABLE' ? 'enabling' : 'disabling'} Rfx Subcategory. Try again.`);

      }
    })
  }

  openAllRfxSubcategoriesActivationModal(content: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");

    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  activateAllRfxSubcategories() {
    this._errorMsg$.next('');
    this._showErrorToast$.next(false);

    let errorDataList = this.validationService.doRfxAndRfxSubcategoryValidation()

    let validationModalRef = this.ngbModal.open(SubcategoryErrorValidationComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
    validationModalRef.componentInstance.errorDtoList = errorDataList;
    validationModalRef.componentInstance.onSubmit.subscribe((rfxSubcategoryIdsList: any) => {
      if (rfxSubcategoryIdsList && rfxSubcategoryIdsList.length > 0) {
        this.activatePendingRfxSubcategories(rfxSubcategoryIdsList);
        validationModalRef.close();
      }
    })
  }

  activatePendingRfxSubcategories(rfxSubcategoryIdsList: string[]) {
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this._errorMsg$.next('');
    this.isLoading = true;

    let rfxEntityDto: RfxUiDto = ApplicationUtils.clone(this.rfxEntityDto);

    let rfxEnableWrapper = new RfxEnableWrapperDto();
    rfxEnableWrapper.rfxUiDto = rfxEntityDto;
    rfxEnableWrapper.subcategoryIds = rfxSubcategoryIdsList;

    this.adminService.markSubcategoriesActive(rfxEnableWrapper).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {

          let rfxValidationErrorCodeDtoList = apiResponseDto.data as RfxValidationErrorCodeDto[];
          if (!rfxValidationErrorCodeDtoList || rfxValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)
          } else {
            this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
            this.openErrorModel();
            this.isLoading = false;
          }
        } else {
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._errorMsg$.next(`Error while enabling Rfx Subcategories. Try again.`);
      }
    })
  }

  openDescriptionModal(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openSubcategoryDetailsModal(mode: string) {
    if (!this.isAllowToEdit()) {
      return;
    }

    let modalRef = this.ngbModal.open(AddSubcategoryComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.mode = mode;
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  getErrorMsg(error: RfxValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.translate)
  }

  removeHtmlTag(input?: string) {
    if (input) {
      let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
      formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
      formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
      formattedInput = formattedInput.replace(/&amp;/g, '&');
      return formattedInput
    }
    return "";
  }

  truncateText(input?: string) {
    if (input) {
      let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
      formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
      formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
      formattedInput = formattedInput.replace(/&amp;/g, '&');
      return ApplicationUtils.truncateString(formattedInput, 101);
    }
    return '';
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubcategorySubscription$) {
      this.selectedRfxSubcategorySubscription$.unsubscribe();
    }
    if (this.selectedRfxSubcategoriesSubscription$) {
      this.selectedRfxSubcategoriesSubscription$.unsubscribe();
    }
    if (this.selectedSubscription$) {
      this.selectedSubscription$.unsubscribe();
    }

    if (this.deploymentConfigurationDtoSubscription$) {
      this.deploymentConfigurationDtoSubscription$.unsubscribe();
    }

    clearInterval(this.checkStatusBulkRfxSubcategoryInterval);
  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.rfxValidationErrorCodeDtoList;
  }

  openShowMoreModal(title: string, description: string) {
    let modalRef = this.ngbModal.open(HtmlViewComponent, { size: 'md', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.content = description;
  }

  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getDisplayDateAdmin(date, time);
  }

  haveRfxSubcategoryDetailsErrors() {
    if (this.rfxEntityDto) {
     return this.validationService.haveRfxSubcategoryDetailsErrors(this.rfxEntityDto, this.rfxSubcategoryEntityDto!);
    }
    return false;
  }

  haveAnyErrorInEndDate() {
   return this.validationService.haveAnyErrorInEndDate(this.rfxEntityDto!, this.rfxSubcategoryEntityDto!);
  }

  haveAnyRfxSubcategoryErrors(subcategoryId: string) {
    let selectedRfxSubcategory = this.rfxSubcategoryEntityDtos?.find(item => item.subcategoryId == subcategoryId);
    if (this.rfxEntityDto) {
     return this.validationService.haveAnyRfxSubcategoryErrors(this.rfxEntityDto!, selectedRfxSubcategory!);
    }
    return false;
  }

  truncateString(val?: string, maxLength?: number) {
    if (!val) {
      return '';
    }

    if (val.length > maxLength!) {
      return ApplicationUtils.truncateString(val, maxLength!);
    } else {
      return val;
    }
  }

  openBulkSubcategoryVerificationModal() {
    this.closeModal();
    this.ngbModal.open(AddRfxBulkSubcategoryVerificationComponent, {
      size: 'xl', backdrop: 'static', keyboard: false, centered: true
    });
  }

  downloadFile(fileInfoDto: FileInfoDto) {
    this.fileService.downloadFile(fileInfoDto?.fileId!).subscribe({
      next: (response) => {
        if (response) {
          this.isLoading = false;
          let file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);;
          link.download = this.rfxEntityDto?.sequenceNo! + ""; // Set the desired filename
          link.click();
        }
      },
      error: (err) => {
        console.error(err);
        console.log("Error while previewing document");
      }
    });

  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT && !this.rfxEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.rfxEntityDto?.status == RfxStatus.LIVE && !this.rfxEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }

}





