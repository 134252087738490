import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"
import { ApplicationUtils } from "../util/ApplicationUtils";

export function ColorValidators(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const validColor =  ApplicationUtils.isValidColor(control.value);
      return !validColor ? { invalidColor: { value: control.value } } : null;
    } else {
      return null;
    }
  };
}
