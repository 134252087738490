import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewBeforeRenderEvent, CalendarView, DateFormatterParams } from 'angular-calendar';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { CalendarService } from '../../services/calendar.service';
import { UserService } from '../../services/user.service';
import { isSameDay, isSameMonth } from 'date-fns';
import { AuctionStatus } from '../../models/user/AuctionStatus';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { EventColor, WeekDay } from 'calendar-utils';
import { AdminSourcingEventsDataHolderService } from '../../services/AdminSourcingEventsDataHolder.service ';

const colors: Record<string, EventColor> = {
  live: {
    primary: '#1488d2f5',
    secondary: '#1488d2f5',
  },
  close: {
    primary: '#e30909db',
    secondary: '#e30909db',
  },
  upcoming: {
    primary: '#ffc107',
    secondary: '#ffc107',
  },
  other: {
    primary: '#495057',
    secondary: '#495057',
  },
};

@Component({
  selector: 'app-auction-calendar-view',
  templateUrl: './auction-calendar-view.component.html',
  styleUrls: ['./auction-calendar-view.component.sass']
})
export class AuctionCalendarViewComponent implements OnInit {
  @Input() auctionList$ = new BehaviorSubject<AuctionEntityDto[]>([]);
  @Input() selectedAuction$ = new BehaviorSubject<AuctionEntityDto|null>(null);
  @Output() onDateSelected = new EventEmitter<Date>()
  @Output() onMonthSelected = new EventEmitter<Date>()

  selectedAuction?: AuctionEntityDto|null

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  selectedDate?: Date;

  refresh = new Subject<void>();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  allAuctionsSubscription$?: Subscription;

  allAuctions: AuctionEntityDto[] = [];

  constructor(
  ) {}

  ngOnInit(): void {
    this.auctionList$.subscribe(data => {
      if (data) {
        this.allAuctions = data;
        this.populateEvents()
      }
    })

    this.selectedAuction$.subscribe(data => {
      if (data) {
        this.selectedAuction = data
        this.selectedDate = undefined
        this.populateEvents()
      } else {
        this.selectedAuction = null
      }
    })
  }

  dayClicked({ date }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.onDateSelected.emit(date);
      this.viewDate = date;
      this.selectedDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
  }

  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getDisplayDateAdmin(date, time);
  }

  closeOpenMonthViewDay(date: Date) {
    this.onDateSelected.emit(undefined);
    this.onMonthSelected.emit(date);
    this.selectedDate = undefined
  }

  populateEvents() {
    this.events =  this.allAuctions.map((item) => {
      return {
        id: item.auctionId,
        title: `#${item.sequenceNo} ${item.auctionName
          } : ${this.getDisplayDate(
            item.startDate!,
            item.startTime!
          )} to ${this.getDisplayDate(item.endDate!, item.endTime!)}`,
        start: ApplicationUtils.convertedDate(
          item.startDate!,
          item.startTime!
        )!,
        end: ApplicationUtils.convertedDate(item.endDate!, item.endTime!)!,
        color:
          item.status == 'LIVE'
            ? { ...colors['live'] }
            : item.status == 'LIVE_WAIT'
              ? { ...colors['upcoming'] }
              : item.status == 'CLOSE'
                ? { ...colors['close'] }
                : { ...colors['other'] },
        draggable: false,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      } as CalendarEvent;
    });
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {

      if (this.selectedAuction && day.inMonth) {
        let event = day.events.find(item => item.id == this.selectedAuction!.auctionId);

        if (event && day.events.indexOf(event) > -1) {
          day.backgroundColor =
            (event.color && event.color.secondary) || '#D1E8FF';
        }
        else {
          delete day.backgroundColor;
        }
      }

      if (this.selectedDate && day.inMonth) {
        let selectedDateIsSameDay = isSameDay(this.selectedDate, day.date);
        if (selectedDateIsSameDay) {
          day.backgroundColor = '#CED4DA'
        }
      }
    });
  }
}
