import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subscription, interval, takeWhile } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { UserPasswordResetComponent } from 'src/app/shared/components/user/user-password-reset/user-password-reset.component';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { PrivacyAndTermsOfUseDto } from 'src/app/shared/models/PrivacyAndTermsOfUseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.sass']
})
export class WelcomePageComponent implements OnInit, OnDestroy {
  privacyAndTermsOfUseDto?: PrivacyAndTermsOfUseDto;
  organizationUiDto?: OrganizationWrapperUiDto
  visible: boolean = false;

  year: number = new Date().getFullYear();
  isPasswordVisible: boolean = false;

  shareUrl: string = ''
  fbUsername?: string = "100087424403714"
  linkedInUsername?: string = "auctionext"
  whatsAppNumber?: string = "13024153184"
  twitterUsername?: string = "apple"

  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);
  _isPasswordFocused$ = new BehaviorSubject<boolean>(false);

  organizationSubscription$?: Subscription;

  @ViewChild('password') password?: ElementRef<HTMLInputElement>
  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;

  noOfResendCount: number = 0;
  countdown: number = 0;
  disableResendButton: boolean = false;
  isCountdownRunning: boolean = false
  isShowOtpVerify: boolean = false;
  otpId?: string;
  teamDialogRef?: DynamicDialogRef;

  formGroupLogin = new FormGroup({
    emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
    password: new FormControl('', [Validators.required, Validators.minLength(9), PasswordStrengthValidator])
  });

  formGroupOtp = new FormGroup({
    emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
    userOtp: new FormControl('', [Validators.required, Validators.pattern(Pattern.onlyNumber)])
  });

  formGroup = new FormGroup({
    emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
    password: new FormControl('', [Validators.required, Validators.minLength(9), PasswordStrengthValidator])
  });

  constructor(
    private ngbModal: NgbModal,
    private onboardingService: OnboardingService,
    public authService: AuthenticationService,
    public userService: UserService,
    private dialogService: DialogService,
  ) { }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }


  showDialog() {
      this.visible = true;
  }

  get fcOtp() { return this.formGroupOtp.controls; }

  ngOnInit(): void {
    this.shareUrl = 'https://' + ApplicationUtils.getSubDomainName();
    this.organizationSubscription$ = this.onboardingService.getOrganizationUiDto$.subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;

        if (this.organizationUiDto) {
          if (this.organizationUiDto.privacyAndTermsOfUseList) {
            this.privacyAndTermsOfUseDto = this.organizationUiDto.privacyAndTermsOfUseList.find(item => item.footerPolicy);
          }
        }
      }
    })

    this.authService.errorMsg = "";
    if (this.formGroup) {
      this.formGroup.get('emailId')?.valueChanges.subscribe(val => {
        this.authService.errorMsg = "";
      });
      this.formGroup.get('password')?.valueChanges.subscribe(val => {
        this.authService.errorMsg = "";
      });
    }
  }

  ngAfterViewInit(): void {
    this.password!.nativeElement.onfocus = (e) => {
      this._isPasswordFocused$.next(true);
    }
    this.password!.nativeElement.onblur = (e) => {
      this._isPasswordFocused$.next(false);
    }
  }

  moveToTop(ref: HTMLElement) {
    ref.scrollIntoView()
  }

  formValidationFailed() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  openLoginModal(content: any) {
    this.ngbModal.open(content, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }
  openForgotPasswordModal() {
    this.teamDialogRef = this.dialogService.open(UserPasswordResetComponent, {
      header: 'Forgot Password',
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false
    });
  }
  
  openPrivacyModal(content: any) {
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openTCModal(content: any) {
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  showOtpVerifyScreen() {
    this.noOfResendCount = 0;
    this.disableResendButton = false;

    if (this.formGroupLogin.controls.emailId.invalid) {
      this.formGroupLogin.controls.emailId.markAsTouched();
      return;
    }

    let emailId = this.formGroupLogin.controls.emailId.value;

    this.formGroupLogin.reset();
    this.formGroupOtp.reset();
    this.isShowOtpVerify = true;

    this.formGroupOtp.controls.emailId.patchValue(emailId);
    this.formGroupOtp.controls.emailId.updateValueAndValidity();

    this.generateOTP();
  }

  generateOTP() {
    if (this.formGroupOtp.controls.emailId.invalid) {
      this.formGroupOtp.controls.emailId.markAsTouched()
      return;
    }

    this.authService.errorMsg = "";
    let email = this.formGroupOtp.controls['emailId'].value as string;

    this.startCountdown();
    this.noOfResendCount++
    this.userService.generateOTP(email, email, 'LOGIN').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
        } else {
          this.authService.errorMsg = apiResponseDto.message!;
        }
      },
      error: (err) => {
        this.authService.errorMsg = "Error While OTP Generate";
      }
    })
  }

  startCountdown(): void {
    this.isCountdownRunning = true;
    this.countdown = 30;
    interval(1000).pipe(
      takeWhile(() => this.countdown > 0),
    ).subscribe(() => {
      this.countdown--;
      if (this.countdown === 0) {
        this.isCountdownRunning = false;
      }
    });
  }

  loginWithPassword() {
    let formValue = this.formGroupLogin.value;

    if (this.formGroupLogin.invalid) {
      this.formGroupLogin.markAllAsTouched()
      return;
    }

    this._isSaveButtonDisable$.next(true);
    this.appLoader?.openLoaderIcon(ApplicationConstants.USER_REGISTRATION_PROGRESS, "Please wait logging in...");
    let emailId = formValue.emailId;
    let password = formValue.password?.trim();
    this.authService.doAuthentication(emailId!, password!, this.appLoader!, this._isSaveButtonDisable$);
  }

  loginWithOtp() {
    let formValue = this.formGroupOtp.value;

    if (this.formGroupOtp.invalid) {
      this.formGroupOtp.markAllAsTouched()
      return;
    }

    this._isSaveButtonDisable$.next(true);
    this.appLoader?.openLoaderIcon(ApplicationConstants.USER_REGISTRATION_PROGRESS, "Please wait logging in...");
    let emailId = formValue.emailId;
    let userOtp = formValue.userOtp;
    this.authService.doAuthentication(emailId!, userOtp!, this.appLoader!, this._isSaveButtonDisable$, this.otpId);
  }

  hideOtpVerifyScreen() {
    let emailId = this.formGroupOtp.controls.emailId.value;

    this.formGroupLogin.reset();
    this.formGroupOtp.reset();
    this.isShowOtpVerify = false;

    this.formGroupLogin.controls.emailId.patchValue(emailId);
    this.formGroupLogin.controls.emailId.updateValueAndValidity();
  }


  resendOtp(): void {
    if (!this.disableResendButton) {
      if (this.noOfResendCount >= 2) {
        this.disableResendButton = true;
      }

      this.resendOTP();
    }
  }


  resendOTP() {
    if (this.formGroupOtp.controls.emailId.invalid) {
      this.formGroupOtp.controls.emailId.markAsTouched()
      return;
    }

    this.authService.errorMsg = "";
    let email = this.formGroupOtp.controls['emailId'].value as string;

    this.startCountdown();
    this.noOfResendCount++
    this.userService.resendOTP(email, "", this.otpId as string).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
        } else {
          this.authService.errorMsg = apiResponseDto.message!;
        }
      },
      error: (err) => {
        this.authService.errorMsg = "Error While OTP Generate";
      }
    })
  }

  handleValidSubmit() {
    let formValue = this.formGroup.value;

    if (this.formValidationFailed()) {
      return;
    }
    this._isSaveButtonDisable$.next(true);
    this.appLoader?.openLoaderIcon(ApplicationConstants.USER_REGISTRATION_PROGRESS, "Please wait logging in...");
    let emailId = formValue.emailId;
    let password = formValue.password?.trim();
    this.authService.doAuthentication(emailId!, password!, this.appLoader!, this._isSaveButtonDisable$);
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription$) {
      this.organizationSubscription$.unsubscribe()
    }
    if (this.teamDialogRef) {
      this.teamDialogRef.close();
    }
  }
}
