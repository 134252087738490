import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { EvaluationQuestionStatus } from 'src/app/shared/enums/rfx/EvaluationQuestionStatus';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';

import { QuestionOptions } from 'src/app/shared/models/questionnaire/QuestionOptions';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { EvaluationUserRfxQuestionsEntityDto } from 'src/app/shared/models/rfx/EvaluationRfxQuestionsEntityDto';
import { RFXEvaluationUserQuestionResponseWrapperDto } from 'src/app/shared/models/rfx/RFXEvaluationUserQuestionResponseWrapperDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { RfxUserQuestionsEvaluationWrapperDto } from 'src/app/shared/models/rfx/RfxUserQuestionsEvaluationWrapperDto';
import { SubcategorySubmissionDto } from 'src/app/shared/models/rfx/SubcategorySubmissionDto';
import { UserRfxQuestionsUiDto } from 'src/app/shared/models/rfx/UserRfxQuestionsEntityDto';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto'; import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { FileService } from 'src/app/shared/services/file.service';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { selectUserUiDto } from 'src/app/shared/state-management/session.features';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-rfx-evaluation-technical-questions',
  templateUrl: './rfx-evaluation-technical-questions.component.html',
  styleUrls: ['./rfx-evaluation-technical-questions.component.sass']
})
export class RfxEvaluationTechnicalQuestionsComponent implements OnInit, OnDestroy {
  @Input() selectedTechnicalQuestion!: TechnicalQuestionTemplate;
  @Input() selectedSubcategorySubmissionDto?: SubcategorySubmissionDto;
  @Input() rfxUserQuestionsEvaluationWrapper!: RfxUserQuestionsEvaluationWrapperDto;
  @Input() maximumScore: number = 0;

  ctrlQuestion: FormControl = new FormControl('');
  ctrlEvaluatorComments: FormControl = new FormControl('');
  ctrlDateStart: FormControl = new FormControl('');
  ctrlDateEnd: FormControl = new FormControl('');
  ctrlAlphaNumeric: FormControl = new FormControl('');

  technicalQuestionsList: TechnicalQuestionTemplate[] = [];

  userEntityDto?: UserUiDto;
  selectedUserRfxQuestionsEntityDto?: UserRfxQuestionsUiDto;
  selectedEvaluationRfxQuestionsEntityDto?: EvaluationUserRfxQuestionsEntityDto;

  questionResponseType: typeof QuestionResponseType = QuestionResponseType;

  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  currentFileIndex: number = -1;
  isFileDownloading: boolean = false;
  isLoading: boolean = false;
  errorMsg: string | undefined;
  currentQuestionIndex: number = 0;
  countIncompleteQuestions: number = 0;
  countCompleteQuestions: number = 0;
  isQuestionEvaluated: boolean = false;
  evaluatorResponse?: any;  //QuestionOptions | QuestionOptions[];
  singleBidderResponse?: QuestionOptions;
  totalEvaluationScore: number = 0
  responseTypeTitle: string = ''
  isNoneSelected: boolean = false;

  modalRefBidderComment?: NgbModalRef

  selectedRfxSubscription$?: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private adminDashboardService: AdminDashboardService,
    private store: Store,
    private fileService: FileService,
    private questionnaireService: QuestionnaireService
  ) { }

  ngOnInit(): void {
    this.getUserUiDto()
    this.technicalQuestionsList = this.rfxUserQuestionsEvaluationWrapper.technicalQuestionsTemplateList ?? []
    this.technicalQuestionsList = this.sortTechnicalQuestions(this.technicalQuestionsList);

    this.populateCurrentQuestionData();
  }

  async getUserUiDto() {
    this.userEntityDto = await firstValueFrom(this.store.select(selectUserUiDto));
  }

  populateCurrentQuestionData() {
    let userRfxQuestionsEntityDtoList = this.rfxUserQuestionsEvaluationWrapper.userRfxQuestionsEntityDtoList ?? [];
    this.selectedUserRfxQuestionsEntityDto = userRfxQuestionsEntityDtoList.find(item => item.questionId == this.selectedTechnicalQuestion.questionId);

    let evaluationRfxQuestionsEntityDtoList = this.rfxUserQuestionsEvaluationWrapper.evaluationUserRfxQuestionsEntityDtoList ?? [];
    this.selectedEvaluationRfxQuestionsEntityDto = evaluationRfxQuestionsEntityDtoList.find(item => item.questionId == this.selectedTechnicalQuestion.questionId);

    this.ctrlQuestion.patchValue(`${this.selectedTechnicalQuestion.preSequenceText}.${this.selectedTechnicalQuestion.sequenceNo} ${this.selectedTechnicalQuestion.questionText}`);
    this.ctrlQuestion.disable();
    this.ctrlQuestion.updateValueAndValidity();

    this.singleBidderResponse = this.selectedTechnicalQuestion.questionOptions![0];
    this.responseTypeTitle = this.getTitleResponseType();
    this.totalEvaluationScore = evaluationRfxQuestionsEntityDtoList.reduce((prev, curr) => prev + Number(curr.evaluatorScore ?? 0), 0)

    this.currentQuestionIndex = this.technicalQuestionsList.findIndex(item => item.questionId == this.selectedTechnicalQuestion.questionId);

    this.populateUserRfxQuestions();
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }

  openBidderCommentModal(content: any) {
    this.modalRefBidderComment = this.ngbModal.open(content, {
      size: 'md', centered: true,
      backdrop: 'static', keyboard: false
    })
  }

  populateUserRfxQuestions() {
    this.isQuestionEvaluated = this.selectedEvaluationRfxQuestionsEntityDto?.evaluatorResponse != null && this.selectedEvaluationRfxQuestionsEntityDto.status == EvaluationQuestionStatus.SUBMITTED;
    this.evaluatorResponse = this.isQuestionEvaluated ? this.selectedEvaluationRfxQuestionsEntityDto?.evaluatorResponse : undefined;

    if (this.isQuestionEvaluated) {
      if (this.selectedTechnicalQuestion.responseType != QuestionResponseType.SINGLE_CHOICE
        && this.selectedTechnicalQuestion.responseType != QuestionResponseType.MULTI_CHOICE) {
        this.evaluatorResponse = this.singleBidderResponse;
      }
    } else {
      this.evaluatorResponse = undefined;
    }

    let evaluationRfxQuestionsEntityDtoList = this.rfxUserQuestionsEvaluationWrapper.evaluationUserRfxQuestionsEntityDtoList ?? [];
    this.countCompleteQuestions = evaluationRfxQuestionsEntityDtoList.filter(item => item.status == EvaluationQuestionStatus.SUBMITTED
      && item.envelopeType == SourcingEnvelopeType.TECHNICAL).length;
    this.countIncompleteQuestions = this.technicalQuestionsList.length - this.countCompleteQuestions;
  }

  selectEvaluationResponse(questionOption: QuestionOptions) {
    if (this.isQuestionEvaluated) {
      return;
    }

    if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
      this.evaluatorResponse = questionOption;
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
      if (!this.evaluatorResponse) {
        this.evaluatorResponse = [];
      }

      let index = (this.evaluatorResponse as QuestionOptions[]).findIndex((item: QuestionOptions) => item.optionSequence == questionOption.optionSequence);
      if (index != -1) {
        (this.evaluatorResponse as QuestionOptions[]).splice(index, 1);
      } else {
        if (questionOption.questionOptionScore == 0) {
          this.evaluatorResponse = [];
        } else {
          let noneIndex = (this.evaluatorResponse as QuestionOptions[]).findIndex((item: QuestionOptions) => item.questionOptionScore == 0);
          if (noneIndex != -1) {
            (this.evaluatorResponse as QuestionOptions[]).splice(index, 1);
          }
        }

        (this.evaluatorResponse as QuestionOptions[]).push(questionOption);
      }
    } else {
      this.evaluatorResponse = questionOption;
    }
  }

  isOptionSelectedByBidder(questionOption?: QuestionOptions) {
    if (questionOption && this.selectedUserRfxQuestionsEntityDto?.bidderResponse) {
      if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
        return this.selectedUserRfxQuestionsEntityDto?.bidderResponse.optionSequence == questionOption.optionSequence;
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
        let option = this.selectedUserRfxQuestionsEntityDto?.bidderResponse.find((item: QuestionOptions) => item.optionSequence == questionOption.optionSequence)
        return option != undefined;
      }
    }
    return false;
  }

  isEvaluatedMultiChoice(sequenceNo: string) {
    return this.evaluatorResponse && (this.evaluatorResponse as QuestionOptions[]).find(item => item.optionSequence == sequenceNo) != undefined;
  }

  resetCurrentQuestion() {
    this.evaluatorResponse = undefined;
    this.populateCurrentQuestionData();
  }

  nextQuestion() {
    if (this.currentQuestionIndex < this.technicalQuestionsList.length) {
      this.currentQuestionIndex++;
      this.selectedTechnicalQuestion = this.technicalQuestionsList[this.currentQuestionIndex];

      this.resetCurrentQuestion();
    }
  }

  prevQuestion() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      this.selectedTechnicalQuestion = this.technicalQuestionsList[this.currentQuestionIndex];
      this.resetCurrentQuestion();
    }
  }

  isDisabledPrev() {
    return this.currentQuestionIndex == 0;
  }

  isDisabledNext() {
    return this.currentQuestionIndex == this.technicalQuestionsList.length - 1;
  }

  formatBytes(size: any) {
    return ApplicationUtils.formatBytes(size);
  }

  getTitleResponseType() {
    if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
      return "Single Choice Response";
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
      return "Multi Choice Response";
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE) {
      return "Date Response";
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE_RANGE) {
      return "Date Range Response";
    } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.ALPHANUMERIC) {
      return "Alphanumeric Response";
    }
    return "";
  }

  getNoneQuestionOption() {
    let sequenceNo = this.selectedTechnicalQuestion.questionOptions?.length ?? 0;

    let questionOption = new QuestionOptions();
    questionOption.optionPreSequence = ApplicationUtils.alphabetsList()[sequenceNo];
    questionOption.optionSequence = `${sequenceNo + 1}`;
    questionOption.optionText = 'None';
    questionOption.questionOptionScore = 0;

    return questionOption;
  }

  mergeUserRfxQuestionsDto() {
    let evaluationRfxQuestionsEntityDto = this.selectedEvaluationRfxQuestionsEntityDto;
    evaluationRfxQuestionsEntityDto!.evaluatorResponse = this.evaluatorResponse;
    evaluationRfxQuestionsEntityDto!.evaluatorComments = this.ctrlEvaluatorComments.value;
    if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
      evaluationRfxQuestionsEntityDto!.evaluatorScore = (this.evaluatorResponse as QuestionOptions[]).reduce((prev, curr) => prev + Number(curr.questionOptionScore), 0);
    } else {
      evaluationRfxQuestionsEntityDto!.evaluatorScore = (this.evaluatorResponse as QuestionOptions).questionOptionScore;
    }

    return evaluationRfxQuestionsEntityDto;
  }

  handleSubmit() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";

    if (!this.evaluatorResponse) {
      return;
    }

    let userRfxQuestionsDto = this.mergeUserRfxQuestionsDto()!
    this.isLoading = true;

    this.questionnaireService.subcategorySubmissionQuestions(userRfxQuestionsDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as RFXEvaluationUserQuestionResponseWrapperDto;

          let evaluationUserRfxQuestionsEntityDto = data.evaluationUserRfxQuestionsEntityDto;

          Object.assign(this.selectedEvaluationRfxQuestionsEntityDto!, evaluationUserRfxQuestionsEntityDto);

          let evaluationRfxQuestionsEntityDtoList = this.rfxUserQuestionsEvaluationWrapper.evaluationUserRfxQuestionsEntityDtoList ?? [];
          let index = evaluationRfxQuestionsEntityDtoList.findIndex(item => item.questionId == this.selectedTechnicalQuestion.questionId);
          Object.assign(evaluationRfxQuestionsEntityDtoList[index], data.evaluationUserRfxQuestionsEntityDto);

          this.adminDashboardService.updateRfxEvaluationUserSubcategoryAndQuestion(data);

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal()
          }, 2000)
        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while saving rfx registration. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;

      }
    })
  }

  previewDoc(fileInfoDto: FileInfoDto, index: number) {
    this.currentFileIndex = index;
    this.isFileDownloading = true;

    this.fileService.downloadFile(fileInfoDto.fileId!).subscribe({
      next: (response) => {
        if (response) {
          this.isLoading = false;
          let file = new Blob([response], { type: fileInfoDto.fileType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        }

        this.currentFileIndex = -1;
        this.isFileDownloading = false;
      },
      error: (err) => {
        console.error(err);
        console.log("Error while previewing document");

        this.currentFileIndex = -1;
        this.isFileDownloading = false;
      }
    });
  }

  sortTechnicalQuestions(data: TechnicalQuestionTemplate[]): TechnicalQuestionTemplate[] {
    return data.sort((a, b) => {
      // Split the strings at the dot (.) to separate letter and number parts
      const [letterA, numberA] = `${a.preSequenceText}.${a.sequenceNo}`.split('.');
      const [letterB, numberB] = `${b.preSequenceText}.${b.sequenceNo}`.split('.');

      // Sort by letter (ascending)
      if (letterA < letterB) return -1;
      if (letterA > letterB) return 1;

      // If letters are the same, sort by number (ascending)
      return Number(numberA) - Number(numberB);
    });
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
  }
}
