import { Injectable } from '@angular/core';
import { AdminRfxSubcategoryDataHolderService } from './AdminRfxSubcategoryDataHolderService.service';
import { AdminSourcingEventsDataHolderService } from './AdminSourcingEventsDataHolder.service ';
import { AuctionHouseDto } from '../models/user/AuctionHouseDto';
import { RfxValidationErrorCodeDto } from '../models/user/RfxValidationErrorCodeDto';
import { RfxUiDto } from '../models/rfx/RfxUiDto';
import { ApplicationUtils } from '../util/ApplicationUtils';
import { RfxSubcategoryUiDto } from '../models/rfx/RfxSubcategoryUiDto';
import { QuestionnaireService } from './questionnaire.service';
import { QuestionnaireValidationService } from './questionnaire-validation.service';

@Injectable({
  providedIn: 'root'
})
export class RfxValidationService {


  constructor(
    private questionnaireValidationService: QuestionnaireValidationService,
    private rfxSubcategoryService: AdminRfxSubcategoryDataHolderService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private questionnaireService: QuestionnaireService
  ) { }


  doAuctionHouseValidation(auctionHouse: AuctionHouseDto) {
    let errorDtoList: RfxValidationErrorCodeDto[] = [];

    if (!auctionHouse.orgCode) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-HOUSE-ERROR-103";
      codeDto.type = "AUCTION_HOUSE";
      codeDto.message = "Client Settings id is missing";
      errorDtoList.push(codeDto);
    }

    if (!auctionHouse.name) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-HOUSE-ERROR-104";
      codeDto.type = "AUCTION_HOUSE";
      codeDto.message = "Client Settings name is missing";
      errorDtoList.push(codeDto);
    }

    if (!auctionHouse.description) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-HOUSE-ERROR-105";
      codeDto.type = "AUCTION_HOUSE";
      codeDto.message = "Client Settings description is missing";
      errorDtoList.push(codeDto);
    }

    if (!auctionHouse.domainName) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-HOUSE-ERROR-106";
      codeDto.type = "AUCTION_HOUSE";
      codeDto.message = "Client Settings domain name is missing";
      errorDtoList.push(codeDto);
    }

    if (!auctionHouse.corporateUrl) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-HOUSE-ERROR-107";
      codeDto.type = "AUCTION_HOUSE";
      codeDto.message = "Client Settings url is missing";
      errorDtoList.push(codeDto);
    }

    if (!auctionHouse.logo) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-HOUSE-ERROR-108";
      codeDto.type = "AUCTION_HOUSE";
      codeDto.message = "Client Settings logo is missing";
      errorDtoList.push(codeDto);
    }

    if (!auctionHouse.active) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-HOUSE-ERROR-109";
      codeDto.type = "AUCTION_HOUSE";
      codeDto.message = "Client Settings is not active";
      errorDtoList.push(codeDto);
    }

    if (!auctionHouse.address) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-HOUSE-ERROR-112";
      codeDto.type = "AUCTION_HOUSE";
      codeDto.message = "Client Settings address is missing";
      errorDtoList.push(codeDto);
    }

    if (!auctionHouse.bannerImage) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-HOUSE-ERROR-113";
      codeDto.type = "AUCTION_HOUSE";
      codeDto.message = "Client Settings banner is missing";
      errorDtoList.push(codeDto);
    }

    if (!auctionHouse.privacyAndTermsOfUseList || auctionHouse.privacyAndTermsOfUseList.filter(item => item.footerPolicy == false).length == 0) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-HOUSE-ERROR-114";
      codeDto.type = "AUCTION_HOUSE";
      codeDto.message = "Client Settings privacy and terms use is missing";
      errorDtoList.push(codeDto);
    }

    return errorDtoList;
  }


  /**
   * check Rfx And Rfx Subcategory validations
   */
  doRfxAndRfxSubcategoryValidation() {
    let errorDtoList: RfxValidationErrorCodeDto[] = [];
    let selectedRfx = this.adminSourcingEventsDataHolderService.getSelectedRfx();
    let allRfxSubcategories = this.rfxSubcategoryService._allRfxSubcategoryList$.value;

    // run validation for rfx
    this.doRfxValidation(errorDtoList!, selectedRfx!);

    //run validation for all subcategories
    if (allRfxSubcategories && allRfxSubcategories.length > 0) {
      allRfxSubcategories.forEach(rfxSubcategory => {
        if (!rfxSubcategory.active) {
          this.doRfxSubcategoryValidation(errorDtoList!, selectedRfx!, rfxSubcategory);
        }
      });
    }
    return errorDtoList!;
  }

  doValidationForActiveRfxAndRfxSubcategories(rfx: RfxUiDto) {
    let errorDtoList: RfxValidationErrorCodeDto[] = [];
    let allRfxSubcategories = this.rfxSubcategoryService._allRfxSubcategoryList$.value;

    // run validation for rfx
    this.doRfxValidation(errorDtoList!, rfx!);

    //run validation for all rfx subcategories
    if (allRfxSubcategories && allRfxSubcategories.length > 0) {
      allRfxSubcategories.forEach(rfxSubcategory => {
        let tempRfxSubcategoryEntityDto: RfxSubcategoryUiDto = ApplicationUtils.clone(rfxSubcategory);
        if (tempRfxSubcategoryEntityDto.active) {
          tempRfxSubcategoryEntityDto.subcategoryType = rfx.rfxType;
          this.doRfxSubcategoryValidation(errorDtoList!, rfx!, tempRfxSubcategoryEntityDto);
        }
      });
    }
    return errorDtoList!;
  }

  /**
   * Rfx Validation
   * @param errorDtoList
   * @param rfx
   */
  doRfxValidation(errorDtoList: RfxValidationErrorCodeDto[], rfx: RfxUiDto,) {

    // check rfx name empty
    if (!rfx.rfxTitle) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "RFX-EXCEPTION-101";
      codeDto.type = "RFX";
      codeDto.message = "Project Title is missing";
      errorDtoList.push(codeDto);
    }

    // check timezone empty
    if (!rfx.timeZone) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-102";
      codeDto.type = "RFX";
      codeDto.message = "Timezone is missing"
      errorDtoList.push(codeDto);
    }

    // check start is less than end date
    if (!rfx.startDate) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-103";
      codeDto.type = "RFX";
      codeDto.message = "Project Start Date is Empty";
      errorDtoList.push(codeDto);
      errorDtoList.push(codeDto);
    }

    // check currency empty
    if (!rfx.currency) {
      //ErrorCode.CURRENCY_NOT_FOUND_EXCEPTION
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-104";
      codeDto.type = "RFX";
      codeDto.message = "Currency is missing.";
      errorDtoList.push(codeDto);
    }

    // check noOfSubcategories
    if (rfx.noOfSubcategories! < 1) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "RFX-EXCEPTION-102";
      codeDto.type = "RFX";
      codeDto.message = "Event not found.";
      errorDtoList.push(codeDto);
    }

    if (!rfx.estSpend) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "COMMON-124";
      codeDto.type = "RFX";
      codeDto.message = "Est Spend is missing.";
      errorDtoList.push(codeDto);
    }

    if (!rfx.estSaving) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "COMMON-125";
      codeDto.type = "RFX";
      codeDto.message = "Est Saving is missing.";
      errorDtoList.push(codeDto);
    }

  }

  /**
   * Rfx Subcategory Validation
   * @param errorDtoList
   * @param rfx
   * @param rfxSubcategory
   */
  doRfxSubcategoryValidation(errorDtoList: RfxValidationErrorCodeDto[], rfx: RfxUiDto, rfxSubcategory: RfxSubcategoryUiDto) {

    // check Subcategory name empty
    if (!rfxSubcategory.title) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "RFX-ERROR-103";
      codeDto.type = "RFX_SUBCATEGORY";
      codeDto.subcategoryId = rfxSubcategory.subcategoryId;
      codeDto.subcategoryName = rfxSubcategory.title;
      codeDto.subcategorySequence = rfxSubcategory.subcategorySequence?.toString();
      codeDto.message = "Event name is missing.";
      errorDtoList.push(codeDto);
    }

    // check end date
    if (!rfxSubcategory.endDate) {
      //ErrorCode.END_DATE_NOT_FOUND_EXCEPTION);
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-123";
      codeDto.subcategoryId = rfxSubcategory.subcategoryId;
      codeDto.subcategoryName = rfxSubcategory.title;
      codeDto.subcategorySequence = rfxSubcategory.subcategorySequence?.toString();
      codeDto.type = "RFX_SUBCATEGORY";
      codeDto.message = "End Date is missing.";
      errorDtoList.push(codeDto);
    }

    // check end time
    if (!rfxSubcategory.endTime) {
      //ErrorCode.END_TIME_NOT_FOUND_EXCEPTION);
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-124";
      codeDto.subcategoryId = rfxSubcategory.subcategoryId;
      codeDto.subcategoryName = rfxSubcategory.title;
      codeDto.subcategorySequence = rfxSubcategory.subcategorySequence?.toString();
      codeDto.type = "RFX_SUBCATEGORY";
      codeDto.message = "End Time is missing.";
      errorDtoList.push(codeDto);
    }

    if (rfxSubcategory.endDate && rfxSubcategory.endTime && this.haveAnyErrorInEndDate(rfx, rfxSubcategory)) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "RFX-ERROR-104";
      codeDto.subcategoryId = rfxSubcategory.subcategoryId;
      codeDto.subcategoryName = rfxSubcategory.title;
      codeDto.subcategorySequence = rfxSubcategory.subcategorySequence?.toString();
      codeDto.type = "RFX_SUBCATEGORY";
      codeDto.message = "The Event end date/time must be after the current date/time or start date/time";
      errorDtoList.push(codeDto);
    }

    // check category empty
    if (!rfxSubcategory.preferenceCategory) {
      //ErrorCode.CATEGORY_NOT_FOUND_EXCEPTION);
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "AUCTION-ERROR-125";
      codeDto.subcategoryId = rfxSubcategory.subcategoryId;
      codeDto.subcategoryName = rfxSubcategory.title;
      codeDto.subcategorySequence = rfxSubcategory.subcategorySequence?.toString();
      codeDto.type = "RFX_SUBCATEGORY";
      codeDto.message = "Category is missing.";
      errorDtoList.push(codeDto);
    }

    let isErrorInQuestionnaire = this.questionnaireValidationService.hasAnyErrorInRfxSubcategoriesQuestionnaireValidation(rfx, rfxSubcategory);

    if (isErrorInQuestionnaire) {
      let codeDto = new RfxValidationErrorCodeDto();
      codeDto.code = "QUESTIONNAIRE-VALIDATION-102";
      codeDto.type = "QUESTIONNAIRE VALIDATION";
      codeDto.message = "Questionnaire validation failed";
      codeDto.subcategoryId = rfxSubcategory.subcategoryId
      codeDto.subcategoryName = rfxSubcategory.title;
      codeDto.subcategorySequence = rfxSubcategory.subcategorySequence?.toString();
      errorDtoList.push(codeDto);
      return;
    }

  }

  haveRfxDetailsErrors(rfx: RfxUiDto) {
    if (!rfx?.rfxTitle || !rfx?.timeZone
      || !rfx?.startDate || !rfx?.startTime
      || !rfx?.currency
    ) {
      return true;
    }
    return false;
  }

  haveRfxSubcategoryDetailsErrors(RfxEntityDto: RfxUiDto, rfxSubcategory: RfxSubcategoryUiDto) {
    if (!rfxSubcategory?.title || !rfxSubcategory?.endDate || !rfxSubcategory?.endTime || this.haveAnyErrorInEndDate(RfxEntityDto, rfxSubcategory)) {
      return true;
    }
    return false;
  }

  haveBidSettingErrors(rfx: RfxUiDto, rfxSubcategory: RfxSubcategoryUiDto) {
    return false;
  }

  haveRfxSubcategoryErrors(rfxSubcategory: RfxSubcategoryUiDto) {
    if (!rfxSubcategory?.preferenceCategory) {
      return true;
    }
    return false;
  }

  haveAnyErrorInEndDate(rfxEntityDto: RfxUiDto, rfxSubcategory: RfxSubcategoryUiDto) {
    if (rfxSubcategory.active) {
      return false;
    }

    if (rfxSubcategory.endDate && rfxSubcategory.endTime) {
      let endDate = rfxSubcategory.endDate;
      let endTime = rfxSubcategory.endTime
      if (endDate && endTime) {
        let _sDate = ApplicationUtils.convertedDate(rfxEntityDto?.startDate!, rfxEntityDto?.startTime!);
        let _eDate = ApplicationUtils.convertedDate(endDate, endTime);

        let currentDate = new Date();
        if (_sDate && _eDate && (_sDate >= _eDate || currentDate >= _eDate)) {
          return true;
        }
      }
      return false;
    }
    return true;
  }


  haveAnyRfxSubcategoryErrors(rfx: RfxUiDto, rfxSubcategory: RfxSubcategoryUiDto) {
    if (this.haveRfxSubcategoryDetailsErrors(rfx, rfxSubcategory) || this.haveBidSettingErrors(rfx, rfxSubcategory)
      || this.haveRfxSubcategoryErrors(rfxSubcategory)
      || this.questionnaireValidationService.hasAnyErrorInRfxSubcategoriesQuestionnaireValidation(rfx, rfxSubcategory)) {
      return true;
    }
    return false;
  }

}
