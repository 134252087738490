<!-- <hr class="hr my-4" /> -->

<div class="m-2">
  <div class="grid grid-cols-1 md:grid-cols-12 gap-4 w-full items-center justify-between">
    <div class="md:col-span-3">
      <h5 class="heading-h5  text-top-margin">Users</h5>
      <button
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex  ms-2 mt-1"
        (click)="openAddModal(true)">
        Add</button>
    </div>
    <div class="md:col-span-6 text-right">
      <span class="me-3 inline-block">
        Organization Chart
      </span>
      <span class="inline-block border border-blue-500 rounded-lg">
        <p-selectButton [unselectable]="true" class="inline-block" [options]="toggleStateOptions" [formControl]="ctrlToggleOrgChart"
          optionLabel="label" optionValue="value"></p-selectButton>
      </span>

    </div>
    <div class="md:col-span-3 text-right">
      <div class="flex w-full">
        <input
          class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0"
          #search id="search" placeholder="Search" [formControl]="ctrlSearchAgent" id="ctrlSearchAgent">
        <div class="input-group-append">
          <span class="input-group-text border-solid border-slate-50 border-l-0 rounded-l-lg h-8 bg-white"
            id="basic-addon2">
            <i-lucide name="search" [size]="16" class="my-icon c-pointer"></i-lucide>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row px-2 align-items-center">
    <div class="col-md-5 mb-3 mb-md-0">
      <h5 class="heading-h5  text-top-margin">USERS</h5>

      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex  ms-2 mt-1" (click)="openAddModal(addAuctioneerModal, true)">
        ADD</button>

    </div>
    <div class="col-md-4 text-md-end mb-3 mb-md-2">
      <div class="row align-items-center">
        <div class="col-7 text-md-end pe-md-0">
         Organization Chart
        </div>
        <div class="col-5 text-md-center ps-md-0">
          <p-selectButton class="d-inline-block" [options]="toggleStateOptions" [formControl]="ctrlToggleOrgChart"
          optionLabel="label" optionValue="value"></p-selectButton>
        </div>

      </div>

    </div>
    <div class="col-md-3 text-md-end mb-3 mb-md-0">
      <div class="mb-2  input-group">
        <input #search placeholder="Search" type="text" class="form-control border-end-0 rounded-0 border bg-white"
          [formControl]="ctrlSearchAgent" id="ctrlSearchAgent" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button"
            (click)="searchAgentData()">
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
    </div>
  </div> -->
</div>

<ng-container [ngTemplateOutlet]="showOrganizationChart ? organizationChart : usersList">
</ng-container>

<ng-template #usersList>
  <div class="col-12 p-2">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0">
        <thead class="table-dark">
          <tr class="text-center">
            <th class=""><span>SNo</span></th>
            <th class=""><span>Profile</span></th>
            <th class=""><span>Name</span></th>
            <th class=""><span>Designation</span></th>
            <th class=""><span>Status</span></th>
            <th class=""><span>Email</span></th>
            <th class=""><span>Registration Time</span></th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr *ngIf="isLoading" class="align-middle">
            <td colspan="9" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>
          <tr *ngIf="!(_filteredUsers$|async) || ((_filteredUsers$|async)!.length == 0)">
            <td colspan="7">
              <h5 class="col-12 text-center">
                No Auctioneer created yet, please create new
              </h5>
            </td>
          </tr>

          <tr class="text-center align-middle" *ngFor="let auctioneer of _filteredUsers$|async ; index as i">
            <td class="">
              {{i+1}}
            </td>
            <td class="">
              <img *ngIf="auctioneer?.profileImage"
                src="/downloadLandingBlob?fileId={{auctioneer?.profileImage?.fileId}}" alt=""
                class="profile-image-md mt-0">
              <img *ngIf="!auctioneer?.profileImage" src="assets/images/avatar05.jpg" alt=""
                class="profile-image-md mt-0">
            </td>
            <td class="">
              {{auctioneer?.firstName}} {{auctioneer?.lastName}}
            </td>
            <td class="">
              {{auctioneer?.designation}}
            </td>
            <td class="">
              <div>
                <!-- <span>
                  <span class="form-switch">
                    <input #toggleRefAdmin class="form-check-input c-pointer" type="checkbox"
                      id="flexSwitchCheckChecked"
                      (click)="openDisableUserModal(disableAdminTemplate, admin, 'ADMIN', toggleRefAdmin)"
                      [checked]="admin!.active" [disabled]="userEntityDto?.userId == admin.userId" />
                  </span>
                </span> -->
                <span class=" p-0">
                  <a class=" ms-2 link-class" (click)="openDisableUserModal(disableAdmin, auctioneer)">
                    {{auctioneer?.active ? "Active" : "InActive"}}</a>
                </span>

              </div>
            </td>
            <td class="">
              {{auctioneer?.primaryEmailId}}
            </td>
            <td class="">
              <!-- Member since {{getMemberSinceYear(auctioneer?.registrationTime!)}} -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #organizationChart>
  <div class="container p-2">
    <p-organizationChart [value]="orgChartData" selectionMode="multiple" [(selection)]="selectedOrgChart">
      <ng-template let-node pTemplate="person">
        <div class="p-2 text-center">
          <img [src]="node.data.image" class="mb-3 w-3rem h-3rem profile-image-class" />
          <div class="font-bold">{{ node.data.name }}</div>
          <div>{{ node.data.title }}</div>
        </div>
        <div class="position-relative">
          <p-speedDial class="speedDial-custom" [model]="organisationMenuItems" [radius]="80" direction="up-left"
            type="quarter-circle" buttonClassName="p-button-success" [buttonStyle]="{'width': '3rem', 'height': '3rem'}"
            (onClick)="selectOrganizationUser(node.data)">
          </p-speedDial>
        </div>
      </ng-template>
    </p-organizationChart>
  </div>
</ng-template>

<p-dialog header="Add User" [(visible)]="isAuctioneerModal" [style]="{width: '50vw'}">
  <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full items-center justify-between">
      <div class="mb-3">
        <label for="firstName" class="text-gray-700  text-sm font-semibold leading-4">First Name</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="firstName" formControlName="firstName"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.firstName.invalid}">
        <div
          *ngIf="fc.firstName.invalid && (_validationErrorPresent$ |async) ||fc.firstName.invalid && (fc.firstName.dirty || fc.firstName.touched)">
          <p class="text-red-500 " *ngIf="fc.firstName.errors?.required">Required
          </p>
        </div>
      </div>
      <div class="mb-3">
        <label for="lastName" class="text-gray-700  text-sm font-semibold leading-4">Last Name</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="lastName" formControlName="lastName"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.lastName.invalid}">

        <div
          *ngIf="fc.lastName.invalid &&  (_validationErrorPresent$ |async) || fc.lastName.invalid && (fc.lastName.dirty || fc.lastName.touched)">
          <p class="text-red-500 " *ngIf="fc.lastName.errors?.required">Required
          </p>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label for="emailId" class="text-gray-700  text-sm font-semibold leading-4">Username(email)</label>
      <input type="email"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
        id="emailId" formControlName="emailId"
        [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.emailId.invalid}">

      <div *ngIf="fc.emailId.invalid && (fc.emailId.dirty || fc.emailId.touched)">
        <p class="text-red-500 " *ngIf="fc.emailId.errors?.required">Required</p>
        <p class="text-red-500 " *ngIf="fc.emailId.errors?.pattern">Invalid email address
        </p>
        <p class="text-red-500 " *ngIf="fc.emailId.value && fc.emailId.value.length > 50">
          Maximum Length
          Exceed</p>
      </div>
    </div>

    <div class="mb-3" *ngIf="!activeUserEntityInfoDto">
      <label for="parentUserId" class="text-gray-700  text-sm font-semibold leading-4">Reporting User</label>
      <select name="Select Designation"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select"
        formControlName="parentUserId" id="parentUserId"
        [ngClass]="{'is-invalid': fc.parentUserId.invalid && (fc.parentUserId.dirty || fc.parentUserId.touched)}">
        <option value="" disabled>Select . . .</option>
        <option [value]="item.userId" *ngFor="let item of agentsList">
          {{item.firstName}} {{item.lastName}} - {{item.designation}}
        </option>
      </select>

      <div *ngIf="fc.parentUserId.invalid && (fc.parentUserId.dirty || fc.parentUserId.touched)">
        <p class="text-red-500 " *ngIf="fc.parentUserId.errors?.required">Required
        </p>
      </div>
    </div>

    <div class="mb-3">
      <label for="designation" class="text-gray-700  text-sm font-semibold leading-4">Designation</label>
      <select name="Select Designation"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select"
        formControlName="designation" id="designation"
        [ngClass]="{'is-invalid': fc.designation.invalid && (fc.designation.dirty || fc.designation.touched)}">
        <option value="" disabled>Select . . .</option>
        <option value="Chief Procurement Officer (CPO)">Chief Procurement Officer (CPO)</option>
        <option value="Head of Direct Procurement">Head of Direct Procurement</option>
        <option value="Head of Indirect Procurement">Head of Indirect Procurement</option>
        <option value="Direct Procurement Manager">Direct Procurement Manager</option>
        <option value="Indirect Procurement Manager">Indirect Procurement Manager</option>
        <option value="Category Managers (Direct)">Category Managers (Direct)</option>
        <option value="Category Managers (Indirect)">Category Managers (Indirect)</option>
        <option value="Procurement Executives">Procurement Executives</option>
        <option value="Procurement Analysts">Procurement Analysts</option>
        <option value="Supplier Relationship Managers">Supplier Relationship Managers</option>
        <option value="Support Staff">Support Staff</option>
      </select>

      <div *ngIf="fc.designation.invalid && (fc.designation.dirty || fc.designation.touched)">
        <p class="text-red-500 " *ngIf="fc.designation.errors?.required">Required
        </p>
      </div>
    </div>
    <div class="mb-3">New user shall receive email confirming account creation on this email</div>
    <label for="confirmPassword" class="text-gray-700  text-sm font-semibold leading-4">Password</label>
    <div class="flex w-full mb-3">
      
        <input [type]="isPasswordVisible ? 'text' : 'password'"
          class="bg-white w-full rounded-l-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm border-r-0"
          id="password" placeholder=" Password" autocomplete="new-password" formControlName="password"
          (keyup)="onChange()" (change)="onChange()"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.password.invalid}">

      <div class="input-group-append">
        <span class="border border-solid border-slate-50 border-l-0 rounded-r-lg h-8 flex pt-2 px-3" id="basic-addon2"  (click)="togglePasswordVisibility()">
          <i-lucide name="eye" [size]="16" class="my-icon c-pointer" *ngIf="!isPasswordVisible"></i-lucide>
          <i-lucide name="eye-off" [size]="16" class="my-icon c-pointer" *ngIf="isPasswordVisible"></i-lucide>
        </span>
      </div>
    </div>
    <div *ngIf="fc.password.invalid && (fc.password.dirty || fc.password.touched)">
      <p class="text-red-500 " *ngIf="fc.password.invalid && (!fc.password.value)">
        Required</p>
      <p class="text-red-500 " *ngIf="fc.password.invalid &&  (fc.password.value)">
        Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
        one number and one special character.</p>
    </div>
    <div class="mt-3">
      <label for="confirmPassword" class="text-gray-700  text-sm font-semibold leading-4">Confirm Password</label>
      <div class="flex w-full mb-3">
        <input [type]="isRepeatPasswordVisible ? 'text' : 'password'" class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0"
          id="confirmPassword" placeholder="Confirm Password" autocomplete="new-password"
          formControlName="confirmPassword" (keyup)="onChange()" (change)="onChange()"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.confirmPassword.invalid}">
          <div class="input-group-append">
            <span class="border border-solid border-slate-50 border-l-0 rounded-r-lg h-8 flex pt-2 px-3" id="basic-addon2"  (click)="toggleRepeatPasswordVisibility()">
              <i-lucide name="eye" [size]="16" class="my-icon c-pointer" *ngIf="!isPasswordVisible"></i-lucide>
              <i-lucide name="eye-off" [size]="16" class="my-icon c-pointer" *ngIf="isPasswordVisible"></i-lucide>
            </span>
          </div>
       
      </div>
    </div>
    <div class="mb-3">
      <p class="text-red-500 "
        *ngIf="fc.confirmPassword.invalid && (fc.confirmPassword.dirty || fc.confirmPassword.touched)">Required
      </p>
      <p class="text-red-500 " *ngIf="!fc.confirmPassword.invalid && !(_isPasswordMatch$ | async)">
        Password Not Match
      </p>
    </div>
    <div class="mb-3">You must provide password separately to user as same is not shared over email</div>

    <div class="mb-3">
      <app-message-toast *ngIf="_showSuccessToast$ | async"
        [message]="'User is added successfully.'"></app-message-toast>
      <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
        [message]="_errorMsg$ |async"></app-message-toast>
    </div>

    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner" *ngIf="isLoading"></div>
    </div>

    <div class="text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
      <button type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 "
        [disabled]="!(_isSaveButtonEnable$ | async)" (click)="handleValidSubmit()">ADD</button>
    </div>
  </form>
</p-dialog>

<!-- <ng-template #addAuctioneerModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>ADD USER</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
          <div class="row">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="firstName" placeholder="First Name"
                  formControlName="firstName"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.firstName.invalid}">
                <label for="firstName">First Name</label>
                <div
                  *ngIf="fc.firstName.invalid && (_validationErrorPresent$ |async) ||fc.firstName.invalid && (fc.firstName.dirty || fc.firstName.touched)">
                  <p class="text-red-500 " *ngIf="fc.firstName.errors?.required">Required
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="lastName" placeholder="Last Name" formControlName="lastName"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.lastName.invalid}">
                <label for="lastName">Last Name</label>
                <div
                  *ngIf="fc.lastName.invalid &&  (_validationErrorPresent$ |async) || fc.lastName.invalid && (fc.lastName.dirty || fc.lastName.touched)">
                  <p class="text-red-500 " *ngIf="fc.lastName.errors?.required">Required
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="emailId" placeholder="name@example.com"
              formControlName="emailId"
              [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.emailId.invalid}">
            <label for="emailId">Username(email)</label>
            <div *ngIf="fc.emailId.invalid && (fc.emailId.dirty || fc.emailId.touched)">
              <p class="text-red-500 " *ngIf="fc.emailId.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="fc.emailId.errors?.pattern">Invalid email address
              </p>
              <p class="text-red-500 " *ngIf="fc.emailId.value && fc.emailId.value.length > 50">
                Maximum Length
                Exceed</p>
            </div>
          </div>

          <div class="form-floating mb-3" *ngIf="!activeUserEntityInfoDto">
            <select name="Select Designation" class="form-control form-select" formControlName="parentUserId"
              id="parentUserId"
              [ngClass]="{'is-invalid': fc.parentUserId.invalid && (fc.parentUserId.dirty || fc.parentUserId.touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="item.userId" *ngFor="let item of agentsList">
                {{item.firstName}} {{item.lastName}} - {{getUserPlantsRoleUiDto(item).designation}}
              </option>
            </select>
            <label for="parentUserId">Reporting User</label>
            <div *ngIf="fc.parentUserId.invalid && (fc.parentUserId.dirty || fc.parentUserId.touched)">
              <p class="text-red-500 " *ngIf="fc.parentUserId.errors?.required">Required
              </p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select name="Select Designation" class="form-control form-select" formControlName="designation"
              id="designation"
              [ngClass]="{'is-invalid': fc.designation.invalid && (fc.designation.dirty || fc.designation.touched)}">
              <option value="" disabled>Select . . .</option>
              <option value="Chief Procurement Officer (CPO)">Chief Procurement Officer (CPO)</option>
              <option value="Head of Direct Procurement">Head of Direct Procurement</option>
              <option value="Head of Indirect Procurement">Head of Indirect Procurement</option>
              <option value="Direct Procurement Manager">Direct Procurement Manager</option>
              <option value="Indirect Procurement Manager">Indirect Procurement Manager</option>
              <option value="Category Managers (Direct)">Category Managers (Direct)</option>
              <option value="Category Managers (Indirect)">Category Managers (Indirect)</option>
              <option value="Procurement Executives">Procurement Executives</option>
              <option value="Procurement Analysts">Procurement Analysts</option>
              <option value="Supplier Relationship Managers">Supplier Relationship Managers</option>
              <option value="Support Staff">Support Staff</option>
            </select>
            <label for="designation">Designation</label>
            <div *ngIf="fc.designation.invalid && (fc.designation.dirty || fc.designation.touched)">
              <p class="text-red-500 " *ngIf="fc.designation.errors?.required">Required
              </p>
            </div>
          </div>
          <div class="mb-3">New user shall receive email confirming account creation on this email</div>
          <div class="input-group">
            <div class="form-floating">
              <input [type]="isPasswordVisible ? 'text' : 'password'" class="form-control border-end-0" id="password"
                placeholder=" Password" autocomplete="new-password" formControlName="password" (keyup)="onChange()"
                (change)="onChange()"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.password.invalid}">
              <label for="password">Password</label>
            </div>
            <span class="input-group-text bg-white border-start-0" (click)="togglePasswordVisibility()">
              <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer" *ngIf="!isPasswordVisible">
              <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer" *ngIf="isPasswordVisible">
            </span>
          </div>
          <div *ngIf="fc.password.invalid && (fc.password.dirty || fc.password.touched)">
            <p class="text-red-500 " *ngIf="fc.password.invalid && (!fc.password.value)">
              Required</p>
            <p class="text-red-500 " *ngIf="fc.password.invalid &&  (fc.password.value)">
              Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
              one number and one special character.</p>
          </div>
          <div class="input-group mt-3">
            <div class="form-floating">
              <input [type]="isRepeatPasswordVisible ? 'text' : 'password'" class="form-control border-end-0"
                id="confirmPassword" placeholder="Confirm Password" autocomplete="new-password"
                formControlName="confirmPassword" (keyup)="onChange()" (change)="onChange()"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.confirmPassword.invalid}">
              <label for="confirmPassword">Confirm Password</label>
            </div>
            <span class="input-group-text  bg-white border-start-0" (click)="toggleRepeatPasswordVisibility()">
              <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer"
                *ngIf="!isRepeatPasswordVisible">
              <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer"
                *ngIf="isRepeatPasswordVisible">
            </span>
          </div>
          <div class="mb-3">
            <p class="text-red-500 "
              *ngIf="fc.confirmPassword.invalid && (fc.confirmPassword.dirty || fc.confirmPassword.touched)">Required
            </p>
            <p class="text-red-500 " *ngIf="!fc.confirmPassword.invalid && !(_isPasswordMatch$ | async)">
              Password Not Match
            </p>
          </div>
          <div class="mb-3">You must provide password separately to user as same is not shared over email</div>

          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async"
              [message]="'User is added successfully.'"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="_errorMsg$ |async"></app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner" *ngIf="isLoading"></div>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " [disabled]="!(_isSaveButtonEnable$ | async)"
              (click)="handleValidSubmit()">ADD</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template> -->

<ng-template #disableAdmin>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>{{auctioneerStatus ? "DISABLE" : "ENABLE"}} / USER</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-2">
        <div class="mb-2" *ngIf="!userActiveFlag">
          <p>Auctioneer will be able to enter in the system</p>
        </div>
        <div class="mb-2" *ngIf="userActiveFlag">
          <p ngbAutoFocus>Auctioneer will not be able to enter in the system</p>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>

        <div class="text-end mb-3" *ngIf="!isLoading">
          <button type="button" class="btn  btn-new-class btn-sm me-3" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="markResourceActiveOrInActive('ENABLE')"
            *ngIf="!userActiveFlag">ENABLE</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="markResourceActiveOrInActive('DISABLE')"
            *ngIf="userActiveFlag">DISABLE</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #changeReportingUserModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>CHANGE REPORTING USER</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-2">

        <div class="form-floating mb-3">
          <select name="Select Designation" class="form-control form-select" [formControl]="ctrlParentUser"
            id="selectDesignation"
            [ngClass]="{'is-invalid': ctrlParentUser.invalid && (ctrlParentUser.dirty || ctrlParentUser.touched)}">
            <option value="" disabled>Select . . .</option>
            <option [value]="item.userId" *ngFor="let item of agentsList">
              {{item.firstName}} {{item.lastName}} - {{item.designation}}
            </option>
          </select>
          <label for="selectDesignation">Reporting User</label>
          <div *ngIf="ctrlParentUser.invalid && (ctrlParentUser.dirty || ctrlParentUser.touched)">
            <p class="text-red-500 " *ngIf="ctrlParentUser.errors?.['required']">Required
            </p>
          </div>
        </div>

        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'User updated successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="text-end mb-3" *ngIf="!isLoading">
          <button type="button" class="btn  btn-new-class btn-sm me-3" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="changeReportingUser()">
            CHANGE
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>