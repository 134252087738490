<div class="border rounded bg-white" [ngClass]="{'border-danger border-2': haveBidSettingErrors()}">
  <header class="p-2">
    <div class="row ">
      <div class="col-md-6">
        <h5 class="heading-h5"> BID SETTING</h5>
      </div>
      <div class="col-md-6 text-md-end">
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 "
          (click)="openRfxSubcategoryBidDetailModal(rfxSubcategoryBidDetailModal)" [disabled]="!isAllowToEdit()">
          <!-- <img src="assets/icons/edit_dark.svg" class=" c-pointer svg-icon-class "> -->
          EDIT
        </button>
      </div>
    </div>
  </header>
  <hr class="hr" />
  <div class="col-12 div-class py-2 px-3">
    Bid Information
  </div>
  <hr class="hr" />
  <div class="row px-3">
    <hr class="hr" />
    <div class="col-4 font-weight-normal py-2">
      Contract Period
    </div>
    <div class="col-8 div-class py-2">
      {{subcategoryEntityDto?.contractPeriod}}
    </div>
    <hr class="hr" />
    <div class="col-4 font-weight-normal py-2">
      Emd Value
    </div>
    <div class="col-8 div-class py-2">
      {{rfxEntityDto?.currency?.symbol}}{{getFormattedPrice(subcategoryEntityDto?.emdValue)}}
    </div>
  </div>
</div>


<ng-template #rfxSubcategoryBidDetailModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>BID SETTING</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">


        <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="saveRfxSubcategory()">


          <div class="input-group mt-3">
            <div class="form-floating">
              <input name="contractPeriod" type="number" placeholder="Contract Period" formControlName="contractPeriod"
                class="form-control"  id="contractPeriod"/>
              <label for="contractPeriod">Contract Period</label>
            </div>
          </div>

          <div class="input-group mt-3">
            <span class="border border-end-0 pt-4 px-1 fa-lg">
              {{rfxEntityDto?.currency?.symbol}}
            </span>
            <div class="form-floating">
              <input name="bucketSize" type="text" placeholder="Emd Value" id="emdValue" formControlName="emdValue"
                (blur)="formatEmdValue()" class="form-control" />
              <label for="emdValue">Emd Value</label>
            </div>
          </div>
          <div *ngIf="isSubmitted || fc.emdValue.invalid && (fc.emdValue.dirty || fc.emdValue.touched)">
            <p class="text-red-500 " *ngIf="fc.emdValue.errors?.required">Required</p>
            <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.emdValue)">Invalid</p>
          </div>

          <div class="input-group mt-3">
            <span class="border border-end-0 pt-4 px-1 fa-lg">
              {{rfxEntityDto?.currency?.symbol}}
            </span>
            <div class="form-floating">
              <input name="estimatedValue" type="text" placeholder="Estimated Value" id="estimatedValue" formControlName="estimatedValue"
                (blur)="formatEstimatedValue()" class="form-control" />
              <label for="estimatedValue">Estimated Value</label>
            </div>
          </div>
          <div
            *ngIf="isSubmitted || fc.estimatedValue.invalid && (fc.estimatedValue.dirty || fc.estimatedValue.touched)">
            <p class="text-red-500 " *ngIf="fc.estimatedValue.errors?.required">Required</p>
            <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.estimatedValue)">Invalid</p>
          </div>

          <!-- targetedBidders -->
          <div class="col-12 mt-3 mb-2">
            <div class="row">
              <div class="col-12 mb-2 position-relative">
                Targeted Bidder
                <span class="ms-2">
                  <input type="checkbox" class="checkbox-class position-absolute" id="targetedBidders" formControlName="targetedBidders">
                </span>
              </div>

            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-6 mb-2">
              Normalization Workflow
            </div>
            <div class="col-md-6 mb-2">
              <span class="me-4">
                <input type="radio" [value]="'SEQUENTIAL'" formControlName="normalizationWorkflow" id="normalizationWorkflow1">
                <label for="normalizationWorkflow1" class="ms-2">Sequential</label>
              </span>
              <span>
                <input type="radio" [value]="'OVERLAP'" formControlName="normalizationWorkflow" id="normalizationWorkflow2">
                <label for="normalizationWorkflow2" class="ms-2">Overlap</label>
              </span>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Event saved successfully.'">
            </app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="_errorMsg$ | async">
            </app-message-toast>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " [disabled]="isLoading || !isAllowToEdit()"
              (click)="saveRfxSubcategory()" ngbAutoFocus>SAVE</button>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner" *ngIf="isLoading"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
