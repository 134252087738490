<div>
  <div class="grid grid-cols-2 gap-2 w-full items-center justify-between py-4 px-6">
    <div class="">
      <span class=""> <img src="/assets/icons/drawer-head-icon-1.svg" class="tr-xl-icon inline-flex"></span>
      <div class="inline-flex ml-2  text-gray-300 font-semibold text-xl leading-4">
        Adding New Profile
      </div>
    </div>
    <div class="text-end ">
      <button class="bg-gray-50 text-gray-700 text-sm px-4 py-2 rounded-md mr-2"
        (click)="closeDrawer()">Cancel</button>
      <button class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md" (click)="saveOrgProfile()">Save as
        draft</button>
    </div>
  </div>
  <div class="block h-11 py-0 px-6 items-center gap-4 items-stretch bg-white border border-slate-50 border-y">
    <div class="mx-6">
      <div class="rounded-full items-center content-center flex w-full h-10 gap-2.5 ">
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full bg-gray-50"
          *ngIf="formGroup.invalid"></div>
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full p-1 bg-green-100"
          *ngIf="formGroup.valid">
          <img src="/assets/icons/onboarding-timeline-check.svg" class="svg-icon-class c-pointer">
        </div>
        <div class="text-sm font-semibold leading-4 tracking-tight" (click)="toggleDetailsTab('BASIC_DETAILS')"
          [ngClass]="currentTab == 'BASIC_DETAILS' ? 'text-blue-100' : 'text-gray-300'">
          Basic Details
        </div>
        <div class="flex py-0 px-6 items-center gap-4 bg-white">
          <img src="/assets/icons/tr-arrow-right.svg" class="tr-md-icon c-pointer">
        </div>
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full bg-gray-50"
          *ngIf="!organizationUiDto?.privacyAndTermsOfUseList || organizationUiDto?.privacyAndTermsOfUseList!.length == 0">
        </div>
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full p-1 bg-green-100"
          *ngIf="organizationUiDto?.privacyAndTermsOfUseList && organizationUiDto?.privacyAndTermsOfUseList!.length > 0">
          <img src="/assets/icons/onboarding-timeline-check.svg" class="svg-icon-class c-pointer">
        </div>
        <div class="text-sm font-semibold leading-4 tracking-tight" (click)="toggleDetailsTab('LEGAL')"
          [ngClass]="currentTab == 'LEGAL' ? 'text-blue-100' : 'text-gray-300'">
          Legal
        </div>
        <div class="flex py-0 px-6 items-center gap-4 bg-white">
          <img src="/assets/icons/tr-arrow-right.svg" class="tr-md-icon c-pointer">
        </div>
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full bg-gray-50"
          *ngIf="formGroupColor.invalid"></div>
        <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full p-1 bg-green-100"
          *ngIf="formGroupColor.valid">
          <img src="/assets/icons/onboarding-timeline-check.svg" class="svg-icon-class c-pointer">
        </div>
        <div class="text-sm font-semibold leading-4 tracking-tight"
          [ngClass]="currentTab == 'OTHER_DETAILS' ? 'text-blue-100' : 'text-gray-300'"
          (click)="toggleDetailsTab('OTHER_DETAILS')">
          Other details
        </div>
      </div>
    </div>
  </div>

  <ng-container [ngTemplateOutlet]="
      currentTab == 'BASIC_DETAILS' ? basicDetails
    : currentTab == 'LEGAL' ? legal
    : currentTab == 'OTHER_DETAILS' ? other
    : empty">
  </ng-container>
</div>


<ng-template #basicDetails>
  <div class="tr-form-space-and-background-drawer tr-overflow-y-drawer">
    <form class="needs-validation w-full" novalidate novalidate [formGroup]="formGroup"
      (keyup.enter)="saveOrgProfile()">
      <div class="">
        <div class="grid grid-cols-1 w-full gap-2">
          <div class="mb-4">
            <label for="name" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['name'].label}}
            </label>
            <div class="">
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                type="text" id="name" formControlName="name" placeholder="---"
                [ngClass]="{'is-invalid': fc['name'].invalid && (fc['name'].dirty || fc['name'].touched)}">
            </div>
            <div *ngIf="fc['name'].invalid && (fc['name'].dirty || fc['name'].touched)">
              <p class="tr-validations" *ngIf="fc['name'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['name'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['name'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['name'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['name'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['name'].validations['minLength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['name'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['name'].validations['maxLength']}}
              </p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 w-full gap-2">
          <div class="mb-4">
            <label for="corporateUrl" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['corporateUrl'].label}}
            </label>
            <div class="">
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                type="text" id="corporateUrl" formControlName="corporateUrl" placeholder="---"
                [ngClass]="{'is-invalid': fc['corporateUrl'].invalid && (fc['corporateUrl'].dirty || fc['corporateUrl'].touched)}">
            </div>
            <div *ngIf="fc['corporateUrl'].invalid && (fc['corporateUrl'].dirty || fc['corporateUrl'].touched)">
              <p class="tr-validations" *ngIf="fc['corporateUrl'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['corporateUrl'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['corporateUrl'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['corporateUrl'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['corporateUrl'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['corporateUrl'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['corporateUrl'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['corporateUrl'].validations['maxlength']}}
              </p>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="grid grid-cols-1 w-full gap-2">
            <div class="col-md-12">
              <label for="descriptieonEditor" class="text-gray-700  text-sm font-semibold leading-4">
                {{formConfig['description'].label}}
              </label>
            </div>
            <div class="form-group">
              <angular-editor #descriptionEditor formControlName="description" class="form-control"
                id="descriptionEditor" [config]="config"></angular-editor>
              <p class="tr-validations" *ngIf="fc['description'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['description'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['description'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['description'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['description'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['description'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['description'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['description'].validations['maxlength']}}
              </p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 w-full gap-2">
          <div class="mb-4">
            <label for="designation" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['selectedLanguages'].label}}
            </label>
            <div class="form-floating">
              <p-multiSelect [options]="languages" formControlName="selectedLanguages" defaultLabel="Select a City"
                optionLabel="languageCode" display="chip">
              </p-multiSelect>
              <p class="tr-validations" *ngIf="fc['selectedLanguages'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['selectedLanguages'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['selectedLanguages'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['selectedLanguages'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['selectedLanguages'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['selectedLanguages'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['selectedLanguages'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['selectedLanguages'].validations['maxlength']}}
              </p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 w-full gap-2">
          <div class="mb-4">
            <label for="designation" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['selectedCurrencies'].label}}
            </label>
            <div class="form-floating ">
              <p-multiSelect [options]="currencies" formControlName="selectedCurrencies" defaultLabel="Select a City"
                optionLabel="name" display="chip">
              </p-multiSelect>
              <p class="tr-validations" *ngIf="fc['selectedCurrencies'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['selectedCurrencies'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['selectedCurrencies'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['selectedCurrencies'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['selectedCurrencies'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['selectedCurrencies'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['selectedCurrencies'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['selectedCurrencies'].validations['maxlength']}}
              </p>


            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 w-full gap-2">
          <div class="mb-4">
            <label for="supportEmail" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['supportEmail'].label}}
            </label>
            <div class="">
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                type="text" id="supportEmail" formControlName="supportEmail" placeholder="---"
                [ngClass]="{'is-invalid': fc['supportEmail'].invalid && (fc['supportEmail'].dirty || fc['supportEmail'].touched)}">
            </div>
            <div *ngIf=" fc['supportEmail'].invalid && (fc['supportEmail'].dirty || fc['supportEmail'].touched)">
              <p class="tr-validations" *ngIf="fc['supportEmail'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['supportEmail'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['supportEmail'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['supportEmail'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['supportEmail'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['supportEmail'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['supportEmail'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['supportEmail'].validations['maxlength']}}
              </p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 w-full gap-2">
          <div class="mb-4">
            <label for="phoneNo" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['phoneNo'].label}}
            </label>
            <div class="flex w-full">
              <span class="">
                <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                  [countryCode$]="countryCode$"></app-country-code-select>
              </span>
              <div class="w-full">
                <input type="text"
                  class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
                  id="phoneNo" placeholder="---" formControlName="phoneNo"
                  [ngClass]="{'is-invalid': fc['phoneNo'].invalid && (fc['phoneNo'].dirty || fc['phoneNo'].touched)}">
                <div *ngIf="fc['phoneNo'].invalid && (fc['phoneNo'].dirty || fc['phoneNo'].touched)">
                  <p class="tr-validations" *ngIf="fc['phoneNo'].errors?.['required']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['phoneNo'].validations['required']}}
                  </p>
                  <p class="tr-validations" *ngIf="fc['phoneNo'].errors?.['pattern']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['phoneNo'].validations['pattern']}}
                  </p>
                  <p class="tr-validations" *ngIf="fc['phoneNo'].errors?.['minlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['phoneNo'].validations['minlength']}}
                  </p>
                  <p class="tr-validations" *ngIf="fc['phoneNo'].errors?.['maxlength']">
                    <span class="mr-2">
                      <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                    </span>
                    {{formConfig['phoneNo'].validations['maxlength']}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 w-full gap-2 mb-4">
          <div class="">
            <label class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['logo'].label}}
            </label>
            <div class="">
              <input type="text"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                (click)="auctionHouseLogo.click()" [value]="logoFileName" />
              <input #auctionHouseLogo name="logo" onclick="this.value=null;" type="file" class="hidden"
                id="auctionHouseLogo" (change)="chooseFile($event, 'LOGO')" />
              <!-- <label for="auctionHouseLogo">{{isEdit ? 'Click to Replace Image' : 'Click to Add Image'}}</label> -->
              <div *ngIf="fc['logo'].invalid && (fc['logo'].dirty || fc['logo'].touched)">
                <p class="tr-validations" *ngIf="fc['logo'].errors?.['required']">
                  <span class="mr-2">
                    <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                  </span>
                  {{formConfig['logo'].validations['required']}}
                </p>
              </div>
            </div>
          </div>
          <div class="">
            <div class="grid grid-cols-4 w-full gap-2 items-center" *ngIf="imageUrlLogo">
              <div class="col-span-2 mt-2">
                <img alt="" [src]="imageUrlLogo"
                  [ngClass]="isLogoActive ? 'modal-logo-image-large' : 'modal-logo-image'" />
              </div>
              <div class=" md:me-2 mt-3">
                <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="openViewImageModal(imageUrlLogo)">
                  <span> <img src="assets/icons/view_dark.svg" class=" c-pointer svg-icon-class"
                      *ngIf="!isLogoActive"></span>
                  <span> <img src="assets/icons/zoomout_dark.svg" class=" c-pointer svg-icon-class"
                      *ngIf="isLogoActive"></span>
                </button>
              </div>
              <div class=" mt-3">
                <span (click)="deleteImage('LOGO')" [ngClass]="{'disabled' : isLoading }">
                  <span> <img src="assets/icons/delete_danger.svg" class=" c-pointer svg-icon-class"></span>
                </span>
              </div>

            </div>
          </div>
        </div>

        <!-- BANNER IMAGE -->
        <div class="grid grid-cols-2 w-full gap-2 mb-4">
          <div class="">
            <label class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['bannerImage'].label}}
            </label>
            <div class="">
              <input type="text"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                (click)="auctionHouseBanner.click()" [value]="bannerFileName" />
              <input #auctionHouseBanner name="banner" onclick="this.value=null;" type="file" class="hidden"
                id="bannerFileName" (change)="chooseFile($event, 'BANNER')" />
              <!-- <label for="bannerFileName">{{isEdit ? 'Click to Replace Image' : 'Click to Add Image'}}</label> -->
              <div *ngIf="fc['bannerImage'].invalid && (fc['bannerImage'].dirty || fc['bannerImage'].touched)">
                <p class="tr-validations" *ngIf="fc['bannerImage'].errors?.['required']">
                  <span class="mr-2">
                    <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                  </span>
                  {{formConfig['bannerImage'].validations['required']}}
                </p>
              </div>
            </div>
          </div>
          <div class="">
            <div class="grid grid-cols-4 w-full gap-2 items-center" *ngIf="imageUrlBanner">
              <div class="col-span-2 mt-2">
                <img alt="" [src]="imageUrlBanner"
                  [ngClass]="isBannerActive ? 'modal-logo-image-large' : 'modal-logo-image'" />
              </div>

              <div class=" md:me-2 mt-3">
                <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="openViewImageModal(imageUrlBanner)">
                  <span> <img src="assets/icons/view_dark.svg" class=" c-pointer svg-icon-class"
                      *ngIf="!isBannerActive"></span>
                  <span> <img src="assets/icons/zoomout_dark.svg" class=" c-pointer svg-icon-class"
                      *ngIf="isBannerActive"></span>
                </button>
              </div>
              <div class=" mt-3">
                <span (click)="deleteImage('BANNER')" [ngClass]="{'disabled' : isLoading }">
                  <span> <img src="assets/icons/delete_danger.svg" class=" c-pointer svg-icon-class"></span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="successMessages['update']"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
          <div class="col-12 text-end mb-5" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md" (click)="saveOrgProfile()">
              Save
            </button>
            <!-- <button type="button" class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md" >Save</button> -->
          </div>
        </div>

      </div>
    </form>
  </div>
</ng-template>

<ng-template #legal>
  <app-add-legal-policy></app-add-legal-policy>
</ng-template>

<ng-template #other>
  <div class="tr-form-space-and-background-drawer">
    <form class="needs-validation w-full" novalidate [formGroup]="formGroupColor" (keyup.enter)="handleValidSubmit()">
      <div class=" mb-3">
        <label for="primaryColor">
          {{formConfig['primaryColor'].label}}
        </label>
        <div class="grid grid-cols-3 w-full  mb-3">
          <div class="col-span-2">
            <input
              class="bg-white border-r-0 w-full flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
              id="primaryColor" formControlName="primaryColor" placeholder="---">
          </div>
          <div
            class="bg-white flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
            [style.background]="fcColor['primaryColor'].value" [(colorPicker)]="primaryColor!"
            (colorPickerChange)="changeColor($event, 'PRIMARY')">

          </div>
        </div>
        <div *ngIf="fcColor['primaryColor'].invalid && (fcColor['primaryColor'].dirty || fcColor['primaryColor'].touched)">
          <p class="tr-validations" *ngIf="fcColor['primaryColor'].errors?.['required']">
            <span class="mr-2">
              <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
            </span>
            {{formConfig['primaryColor'].validations['required']}}
          </p>
          <p class="tr-validations" *ngIf="fcColor['primaryColor'].errors?.['invalidColor']">
            <span class="mr-2">
              <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
            </span>
            {{formConfig['primaryColor'].validations['invalidColor']}}
          </p>
        </div>
        <!-- <input class="tr-input-password input-placeholder border-end-0" id="secondaryColor"
        formControlName="secondaryColor" placeholder="---">
      <div class="input-group-append">
        <span class="input-group-text input-group-text-2  border-start-0"
          [style.background]="fcColor.secondaryColor.value" [(colorPicker)]="secondaryColor!"
          (colorPickerChange)="changeColor($event, 'SECONDARY')"></span>
      </div> -->
        <!-- Secondary Color -->
        <label for="secondaryColor">
          {{formConfig['secondaryColor'].label}}
        </label>
        <div class="grid grid-cols-3 w-full  mb-3">
          <div class="col-span-2">
            <input
              class="bg-white border-r-0 w-full flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
              id="primaryColor" formControlName="secondaryColor" placeholder="---">
          </div>
          <div
            class="bg-white flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
            [style.background]="fcColor['secondaryColor'].value" [(colorPicker)]="secondaryColor!"
            (colorPickerChange)="changeColor($event, 'SECONDARY')">
          </div>
        </div>
        <div *ngIf="fcColor['secondaryColor'].invalid && (fcColor['secondaryColor'].dirty || fcColor['secondaryColor'].touched)">
          <p class="tr-validations" *ngIf="fcColor['secondaryColor'].errors?.['required']">
            <span class="mr-2">
              <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
            </span>
            {{formConfig['secondaryColor'].validations['required']}}
          </p>
          <p class="tr-validations" *ngIf="fcColor['secondaryColor'].errors?.['invalidColor']">
            <span class="mr-2">
              <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
            </span>
            {{formConfig['secondaryColor'].validations['invalidColor']}}
          </p>
        </div>
      </div>
      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
          [message]="_errorMsg$ |async"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
          [message]="successMessages['update']"></app-message-toast>
      </div>
      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class=" text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md" (click)="handleValidSubmit()"
          ngbAutoFocus>Save</button>
      </div>
    </form>
  </div>

</ng-template>

<ng-template #confirmationTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>Confirmation</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-2">
        <div class="mb-4">
          <p>Are you sure you want to delete {{selectedType}} ?</p>
        </div>

        <div class="col-12 text-end">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal()">CANCEL</button>
          <button *ngIf="selectedType == 'Policy'" type="button" class="btn btn-sm btn-outline-primary"
            (click)="deletePrivacyAndTerms()">CONFIRM</button>
        </div>

      </div>
    </div>
  </div>
</ng-template>
<ng-template #empty></ng-template>