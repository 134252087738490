import { Component } from '@angular/core';

@Component({
  selector: 'app-rfx-question-update',
  templateUrl: './rfx-question-update.component.html',
  styleUrls: ['./rfx-question-update.component.sass']
})
export class RfxQuestionUpdateComponent {

}
