import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { Subscription, BehaviorSubject, firstValueFrom } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AdditionalFinancialPrice } from 'src/app/shared/models/questionnaire/AdditionalFinancialPrice';
import { FinancialQuestionTemplate } from 'src/app/shared/models/questionnaire/FinancialQuestionTemplate';
import { QuestionOptions } from 'src/app/shared/models/questionnaire/QuestionOptions';
import { QuestionnaireValidationDto } from 'src/app/shared/models/questionnaire/QuestionnaireValidationDto';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { ResourceSwapDto } from 'src/app/shared/models/questionnaire/ResourceSwapDto';
import { ResourceSwapWrapperDto } from 'src/app/shared/models/questionnaire/ResourceSwapWrapperDto';
import { SectionTemplate } from 'src/app/shared/models/questionnaire/SectionTemplate';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { FileService } from 'src/app/shared/services/file.service';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { BulkQuestionWrapperDto } from 'src/app/shared/models/questionnaire/BulkQuestionWrapperDto';
import { LotsErrorValidationComponent } from 'src/app/shared/components/lots-error-validation/lots-error-validation.component';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';

@Component({
  selector: 'app-rfx-questionnaire-financial-question',
  templateUrl: './rfx-questionnaire-financial-question.component.html',
  styleUrls: ['./rfx-questionnaire-financial-question.component.sass']
})
export class RfxQuestionnaireFinancialQuestionComponent implements OnInit, OnDestroy {
  @Input() selectedQuestionId?: string;

  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  selectedSectionTemplate?: SectionTemplate
  financialQuestions: FinancialQuestionTemplate[] = []
  selectedRfx?: RfxUiDto;
  selectedRfxSubcategory?: RfxSubcategoryUiDto;

  formGroup: FormGroup
  bulkExcelFormControl: FormControl = new FormControl(null, Validators.required);

  newQuestionTemplate?: FinancialQuestionTemplate;
  selectedQuestionTemplate?: FinancialQuestionTemplate;
  questionnaireValidationList: QuestionnaireValidationDto[] = [];

  responseTypesList: QuestionResponseType[] = [
    QuestionResponseType.SINGLE_CHOICE,
    QuestionResponseType.MULTI_CHOICE,
    QuestionResponseType.DATE,
    QuestionResponseType.DATE_RANGE,
    QuestionResponseType.ALPHANUMERIC
  ]

  addQuestionModalRef?: NgbModalRef;
  deleteConfirmModalRef?: NgbModalRef;
  excelUploadConfirmModalRef?: NgbModalRef;

  countDraftQuestions: number = 0
  countPublishedQuestions: number = 0
  isLoading: boolean = false;
  isDownloading: boolean = false;
  isPrevButtonDisabled: boolean = false;
  isNextButtonDisabled: boolean = false;
  questionScoreValidationFailed: boolean = false;
  countFinancialQuestions: number = 0;
  errorMsg: string | undefined;

  validationSubscription$?: Subscription
  questionnaireWrapperDtoSubscription$?: Subscription
  selectedSectionSubscription$?: Subscription
  selectedRfxSubcategorySubscription$?: Subscription

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  @ViewChild('sampleFile') sampleFile?: HTMLInputElement;
  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;


  constructor(
    private ngbModal: NgbModal,
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private fileService: FileService,
    private questionnaireService: QuestionnaireService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
  ) {
    this.formGroup = this.fb.group({
      preSequenceText: ['', Validators.required],
      sequenceNo: ['', Validators.required],
      questionText: ['', Validators.required],
      questionDescription: [''],
      openerComments: [''],
      responseMandatory: [true, Validators.minLength(3)],
      bidderCommentsRequired: [false, Validators.minLength(3)],
      noResponse: [false, Validators.minLength(3)],
      disQualifyForNoResponse: [false, Validators.minLength(3)],
      questionStatus: [''],
      quantity: ['', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
      uom: ['', Validators.required],
      tax: ['', Validators.pattern(Pattern.numberGreaterZero)],
      additionalPrices: this.fb.array([])
    })
  }

  ngOnInit(): void {
    this.selectedRfx = this.adminSourcingEventsDataHolderService.selectedRfx;
    this.selectedRfxSubcategorySubscription$ = this.adminRfxSubcategoryDataHolderService.selectedRfxSubcategory$.subscribe(data => {
      if (data) {
        this.selectedRfxSubcategory = data;
      } else {
        this.selectedRfxSubcategory = undefined;
      }
    });

    // this.countDraftQuestions = this.financialQuestions?.filter(item => item.questionStatus == QuestionnaireStatus.DRAFT).length;
    // this.countPublishedQuestions = this.financialQuestions?.filter(item => item.questionStatus == QuestionnaireStatus.PUBLISHED).length;

    this.validationSubscription$ = this.questionnaireService.getQuestionnaireValidationList$.subscribe(data => {
      if (data && data.length > 0) {
        this.questionnaireValidationList = data;
      } else {
        this.questionnaireValidationList = [];
      }
    })

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        if (this.selectedSectionTemplate) {
          this.financialQuestions = this.questionnaireWrapperDto?.financialQuestionTemplates?.filter(item => item.sectionId == this.selectedSectionTemplate?.sectionId) ?? [];
          this.financialQuestions.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
        }
        this.questionScoreValidationFailed = this.questionnaireService.checkQuestionScoreValidation(this.selectedSectionTemplate?.sectionId!);
      }
    })

    this.selectedSectionSubscription$ = this.questionnaireService.getSelectedSection$.subscribe(data => {
      if (data) {
        this.selectedSectionTemplate = data;
        this.financialQuestions = this.questionnaireWrapperDto?.financialQuestionTemplates?.filter(item => item.sectionId == data.sectionId) ?? [];
        this.financialQuestions.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
        this.questionScoreValidationFailed = this.questionnaireService.checkQuestionScoreValidation(this.selectedSectionTemplate?.sectionId!);

        this.questionnaireService.runTechQuestionnaireValidation();
      }
    })
  }

  get fc() { return this.formGroup.controls; }

  populateQuestionData() {
    this.additionalPrices.clear();
    this.formGroup.reset();

    this.formGroup.controls['sequenceNo'].patchValue(this.selectedQuestionTemplate?.sequenceNo);
    this.formGroup.controls['preSequenceText'].patchValue(this.selectedSectionTemplate?.preSequenceText);

    this.formGroup.controls['questionText'].patchValue(this.selectedQuestionTemplate?.questionText);
    this.formGroup.controls['questionDescription'].patchValue(this.selectedQuestionTemplate?.questionDescription);
    this.formGroup.controls['openerComments'].patchValue(this.selectedQuestionTemplate?.openerComments);
    this.formGroup.controls['questionStatus'].patchValue(this.selectedQuestionTemplate?.questionStatus);
    this.formGroup.controls['quantity'].patchValue(this.selectedQuestionTemplate?.quantity);
    this.formGroup.controls['uom'].patchValue(this.selectedQuestionTemplate?.uom);
    this.formGroup.controls['tax'].patchValue(this.selectedQuestionTemplate?.tax);

    if (this.selectedQuestionTemplate?.additionalPrices && this.selectedQuestionTemplate.additionalPrices.length > 0) {
      this.additionalPrices.clear();
      this.selectedQuestionTemplate.additionalPrices.forEach(additionalPrice => {
        this.additionalPrices.push(this.updateAdditionalPrices(additionalPrice));
      })
    }

    this.formGroup.updateValueAndValidity();

    this.questionScoreValidationFailed = this.questionnaireService.checkQuestionScoreValidation(this.selectedSectionTemplate?.sectionId!);

    this.checkPrevNextNavigationButton();
  }

  openAddQuestionModal(content: any, questionTemplate?: FinancialQuestionTemplate) {
    if (!this.selectedSectionTemplate?.sectionName) {
      return;
    }

    this._showErrorToast$.next(false);
    this.errorMsg = "";

    if (questionTemplate == undefined) {
      this.addNewQuestion();
    } else {
      this.newQuestionTemplate = undefined;
      this.countFinancialQuestions = this.financialQuestions.length;
      this.selectedQuestionTemplate = questionTemplate;
      this.populateQuestionData();
    }

    this.addQuestionModalRef = this.ngbModal.open(content, {
      size: 'xl', backdrop: 'static', keyboard: false,
      centered: true,
    })
  }

  addNewQuestion() {
    this.additionalPrices.clear();
    this.formGroup.reset();

    let sequenceNo = this.financialQuestions.length;

    this.newQuestionTemplate = new FinancialQuestionTemplate();
    this.newQuestionTemplate!.sequenceNo = sequenceNo + 1;
    this.newQuestionTemplate!.preSequenceText = this.selectedSectionTemplate?.preSequenceText;

    this.countFinancialQuestions = this.financialQuestions.length + 1;
    this.selectedQuestionTemplate = this.newQuestionTemplate;
    this.populateQuestionData();
  }

  openDeleteConfirmModal(content: any) {
    this.deleteConfirmModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false,
      centered: true,
    })
  }

  openUploadSampleExcelModal(content: any) {
    this.bulkExcelFormControl.reset();
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.excelUploadConfirmModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false,
      centered: true,
    })
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }

    this.selectedQuestionTemplate = undefined;
  }

  isShowOptionTextField(responseType: QuestionResponseType) {
    return responseType == QuestionResponseType.SINGLE_CHOICE || responseType == QuestionResponseType.MULTI_CHOICE;
  }

  getRemainingScoreOfSection() {
    return this.questionnaireService.getRemainingScoreOfSection(this.selectedSectionTemplate?.sectionId!);
  }

  mergeTechQuestionTemplate() {
    let questionTemplate: FinancialQuestionTemplate = ApplicationUtils.clone(this.selectedQuestionTemplate);

    let formGroupValue = this.formGroup.value;

    if (!questionTemplate.questionId) {
      questionTemplate.questionId = UUID.UUID().toString();
      questionTemplate.orgCode = this.selectedRfxSubcategory?.orgCode;
      questionTemplate.rfxId = this.selectedRfxSubcategory?.rfxId;
      questionTemplate.rfxSubcategoryId = this.selectedRfxSubcategory?.subcategoryId;
      questionTemplate.auctionId = 'NA';
    }

    questionTemplate.sectionId = this.selectedSectionTemplate?.sectionId;
    questionTemplate.questionnaireId = this.selectedSectionTemplate?.questionnaireId;
    questionTemplate.questionText = formGroupValue.questionText ? formGroupValue.questionText.trim() : '';
    questionTemplate.questionDescription = formGroupValue.questionDescription;
    questionTemplate.preSequenceText = this.selectedSectionTemplate?.preSequenceText;
    questionTemplate.sequenceNo = formGroupValue.sequenceNo;
    questionTemplate.questionStatus = formGroupValue.questionStatus;
    questionTemplate.quantity = formGroupValue.quantity;
    questionTemplate.uom = formGroupValue.uom;
    questionTemplate.tax = formGroupValue.tax;
    questionTemplate.additionalPrices = formGroupValue.additionalPrices;


    return questionTemplate;
  }

  saveQuestionTemplate = async () => {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let questionTemplate = this.mergeTechQuestionTemplate();

    this.questionnaireService.saveFinancialQuestionTemplate(questionTemplate).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          this.selectedQuestionTemplate = questionnaireWrapperDto.financialQuestionTemplates?.find(item => item.questionId == questionTemplate.questionId);
          this.populateQuestionData();

          if (questionTemplate.sequenceNo == this.newQuestionTemplate?.sequenceNo) {
            this.newQuestionTemplate = undefined;
          }

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            // this.closeModal(this.addQuestionModalRef);
          }, 2000)

        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving question. Try again.";
      }
    })
  }

  deleteQuestionTemplate() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    this.questionnaireService.deleteQuestion(this.selectedQuestionTemplate?.questionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.deleteConfirmModalRef!);
            this.closeModal(this.addQuestionModalRef!);
          }, 2000)

        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while deleting question. Try again.";
      }
    })
  }

  async downloadBulkSampleExcel() {
    this.isDownloading = true;

    let bulkSampleExcel = await firstValueFrom(this.questionnaireService.downloadSampleExcel());
    if (bulkSampleExcel) {
      let file = new Blob([bulkSampleExcel], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var fileURL = URL.createObjectURL(file);
      setTimeout(() => {
        window.open(fileURL);
      }, 500)
    }

    this.isDownloading = false;
  }

  onBulkExcelChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.bulkExcelFormControl.patchValue(file)
    } else {
      this.bulkExcelFormControl.patchValue(null)
    }
    this.bulkExcelFormControl.updateValueAndValidity();
  }

  uploadBulkSampleExcel() {
    if (this.bulkExcelFormControl.invalid) {
      this.bulkExcelFormControl.markAllAsTouched();
      return;
    }

    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let currentFile = this.bulkExcelFormControl.value;

    let metaData = {
      'mimeType': currentFile.type,
      'version': 0,
      'publicApi': true,
      'dataType': 'Square'
    };

    let questionnaireId = this.selectedSectionTemplate?.questionnaireId!;
    let sectionId = this.selectedSectionTemplate?.sectionId!;

    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append("questionnaireId", questionnaireId);
    formData.append("sectionId", sectionId);
    formData.append('metaData', JSON.stringify(metaData));

    this.questionnaireService.uploadBulkFinancialSampleExcel(formData, questionnaireId!, sectionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false;

          let bulkQuestionWrapperDto = apiResponseDto.data as BulkQuestionWrapperDto;

          let errorCodeDtos = bulkQuestionWrapperDto.errorCodeDtos ?? [];
          if (errorCodeDtos.length > 0) {
            let errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
              size: 'md', backdrop: 'static', keyboard: false , centered: true
            });
            errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = errorCodeDtos;
            return;
          }

          this._showSuccessToast$.next(true);

          let questionnaireWrapperDto = bulkQuestionWrapperDto.questionnaireWrapperDto as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.excelUploadConfirmModalRef);
          }, 2000)

        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving question. Try again.";
      }
    })
  }

  previousQuestion() {
    let currentQuestionSequence = this.formGroup.controls['sequenceNo'].value;

    if (Number(currentQuestionSequence) == 1) {
      return;
    }

    if (currentQuestionSequence == this.newQuestionTemplate?.sequenceNo) {
      this.newQuestionTemplate = this.mergeTechQuestionTemplate();
    }

    if (Number(currentQuestionSequence) > 1) {
      this.selectedQuestionTemplate = this.financialQuestions[Number(currentQuestionSequence) - 2];
      this.populateQuestionData();
    }

    this.checkPrevNextNavigationButton();
  }

  nextQuestion() {
    let currentQuestionSequence = this.formGroup.controls['sequenceNo'].value;

    if (Number(currentQuestionSequence) == this.countFinancialQuestions) {
      return;
    }

    if (Number(currentQuestionSequence) < this.countFinancialQuestions) {
      if (Number(currentQuestionSequence) == Number(this.newQuestionTemplate?.sequenceNo) - 1) {
        this.selectedQuestionTemplate = this.newQuestionTemplate
      } else {
        this.selectedQuestionTemplate = this.financialQuestions[Number(currentQuestionSequence)];
      }
      this.populateQuestionData();
    }

    this.checkPrevNextNavigationButton();
  }

  checkPrevNextNavigationButton() {
    let currentQuestionSequence = this.formGroup.controls['sequenceNo'].value;

    if (this.countFinancialQuestions == 0) {
      this.isPrevButtonDisabled = true;
      this.isNextButtonDisabled = true;
    }

    if (Number(currentQuestionSequence) == 1) {
      this.isPrevButtonDisabled = true;
    } else {
      this.isPrevButtonDisabled = false;
    }

    if (Number(currentQuestionSequence) == this.countFinancialQuestions) {
      this.isNextButtonDisabled = true;
    } else {
      this.isNextButtonDisabled = false;
    }
  }

  handleQuestionsOrders() {
    let resourceSwapDtoList: ResourceSwapDto[] = [];

    this.financialQuestions.forEach((item, index) => {
      let resourceSwapDto = new ResourceSwapDto();
      resourceSwapDto.resourceId = item.questionId;
      resourceSwapDto.sequenceNo = `${index + 1}`;
      resourceSwapDto.pretext = this.selectedSectionTemplate?.preSequenceText;
      resourceSwapDtoList.push(resourceSwapDto);
    })

    let resourceSwapWrapperDto = new ResourceSwapWrapperDto();
    resourceSwapWrapperDto.sourcingEnvelopeType = SourcingEnvelopeType.TECHNICAL;
    resourceSwapWrapperDto.resourceSwapDtoList = resourceSwapDtoList;

    this.appLoader?.openLoaderIcon('QUESTION_REORDER', 'Reordering..');
    this.questionnaireService.updateQuestionOrdering(resourceSwapWrapperDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.appLoader?.closeLoaderIcon('QUESTION_REORDER');

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);
        } else {
          this.appLoader?.closeLoaderIcon('QUESTION_REORDER');
        }
      },
      error: (err) => {
        console.error(err);
        this.appLoader?.closeLoaderIcon('QUESTION_REORDER');
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.financialQuestions, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.handleQuestionsOrders();
    }
  }

  // question Options
  get additionalPrices(): FormArray {
    return this.formGroup.get("additionalPrices") as FormArray
  }

  newAdditionalPrices(): FormGroup {
    return this.fb.group({
      label: ['', Validators.required],
      mandatory: [false],
    })
  }

  updateAdditionalPrices(option: AdditionalFinancialPrice): FormGroup {
    return this.fb.group({
      label: [option.label, Validators.required],
      mandatory: [option.mandatory],
    })
  }

  addAdditionalPrices() {
    this.additionalPrices.push(this.newAdditionalPrices());
  }

  removeAdditionalPrices(i: number) {
    this.additionalPrices.removeAt(i);
  }

  ngOnDestroy(): void {
    if (this.validationSubscription$) {
      this.validationSubscription$.unsubscribe();
    }
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
    if (this.selectedSectionSubscription$) {
      this.selectedSectionSubscription$.unsubscribe();
    }
    if (this.selectedRfxSubcategorySubscription$) {
      this.selectedRfxSubcategorySubscription$.unsubscribe();
    }
  }
}
