<div>
  <div class="flex flex-row gap-4 items-center justify-between h-12 bg-white px-8 py-4 border-b">
    <div class="basis-1/2">
      <div class="inline-block">
        <div class="font-semibold text-black text-xl">
          Creating question
        </div>
      </div>
    </div>
  </div>
  <div class="h-screen">
    <div class=" px-8 py-3 tr-overflow-y-drawer">
      <div class="w-full mb-10">
        <div class="flex flex-row gap-4 justify-between items-center">
          <div class="basis-1/2">
            <div class="text-sm text-black font-semibold mb-3">
              Financial
            </div>
          </div>
          <div class="basis-1/2 text-end">
            <button
              class="inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3">
              Download excel
            </button>
            <button
              class="inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
              Upload excel
            </button>
          </div>
        </div>
        <div class="card rounded-lg">
          <p-table [value]="tenderFinancialQuestionTemplates" dataKey="id" [tableStyle]="{ 'min-width': '50rem' }">
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <th>Item name</th>
                <th>Item description</th>
                <th>Quantity</th>
                <th>UoM</th>
                <th>Tax rate %</th>
                <th>Entity rate</th>
                <th *ngFor="let aItem of [].constructor(totalAdditionalPrices); index as i">
                  Additional Cost {{i+1}}
                  <span (click)="removeAdditionalPrice(i)">
                    <i-lucide name="trash-2" [size]="18" class="my-icon "></i-lucide>
                  </span>
                </th>
                <th>
                  <span class="c-pointer" (click)="addAdditionalPrice()">
                    <i-lucide name="plus" [size]="18" class="my-icon "></i-lucide>
                  </span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-i="rowIndex" let-editing="editing">
              <tr>
                <td class="">
                  {{i + 1}}
                </td>
                <!-- questionText -->
                <td [pEditableColumn]="item.questionText" pEditableColumnField="questionText">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="item.questionText" placeholder="Item name" maxlength="1000" required />
                    </ng-template>
                    <ng-template pTemplate="output">
                      <input pInputText type="text" [(ngModel)]="item.questionText" placeholder="Item name" required />
                    </ng-template>
                  </p-cellEditor>
                </td>
                <!-- questionDescription -->
                <td [pEditableColumn]="item.questionDescription" pEditableColumnField="questionDescription">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="item.questionDescription"
                        placeholder="Item description" maxlength="1000" />
                    </ng-template>
                    <ng-template pTemplate="output">
                      <input pInputText type="text" [(ngModel)]="item.questionDescription"
                        placeholder="Item description" />
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td [pEditableColumn]="item.quantity" pEditableColumnField="quantity">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="number" pKeyFilter="int" [(ngModel)]="item.quantity"
                        placeholder="Quantity" required />
                    </ng-template>
                    <ng-template pTemplate="output">
                      <input pInputText type="number" pKeyFilter="int" [(ngModel)]="item.quantity"
                        placeholder="Quantity" />
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td [pEditableColumn]="item.uom" pEditableColumnField="uom">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="item.uom" placeholder="UoM" required />
                    </ng-template>
                    <ng-template pTemplate="output">
                      <input pInputText type="text" [(ngModel)]="item.uom" placeholder="UoM" required />
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td [pEditableColumn]="item.tax" pEditableColumnField="tax">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <span class="p-input-icon-right">
                        <i-lucide name="percent" [size]="18" class="my-icon "></i-lucide>
                        <input pInputText type="number" pKeyFilter="int" pInputText [(ngModel)]="item.tax" placeholder="Tax rate" required />
                      </span>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <input pInputText type="number" pKeyFilter="int" [(ngModel)]="item.tax" placeholder="Tax rate"
                        required />
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td [pEditableColumn]="item.entityRate" pEditableColumnField="entityRate">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="number" pKeyFilter="int" [(ngModel)]="item.entityRate"
                        placeholder="Entity rate" required />
                    </ng-template>
                    <ng-template pTemplate="output">
                      <input pInputText type="number" pKeyFilter="int" [(ngModel)]="item.entityRate"
                        placeholder="Entity rate" required />
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td [pEditableColumn]="aItem.label" pEditableColumnField="aItem.label"
                  *ngFor="let aItem of item.additionalPrices; index as k">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="aItem.label" placeholder="Additional Cost" required />
                    </ng-template>
                    <ng-template pTemplate="output">
                      <input pInputText type="text" [(ngModel)]="aItem.label" placeholder="Additional Cost" required />
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td>
                  <span (click)="removeQuestion(item.questionId)" *ngIf="tenderFinancialQuestionTemplates.length > 1">
                    <i-lucide name="trash-2" [size]="18" class="my-icon "></i-lucide>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              <span class="c-pointer" (click)="addTenderFinancialQuestion()">
                <i-lucide name="plus" [size]="18" class="my-icon "></i-lucide>
              </span>
            </ng-template>
          </p-table>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Financial questions saved successfully.'">
          </app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg">
          </app-message-toast>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="flex flex-row justify-end h-8 my-2">
      <!-- <div class="basis-1/2 mt-2 ps-6">
        <div class="h-2 w-20 bg-blue-200 inline-block"></div>
        Some information is pending
      </div> -->
      <div class="text-end pe-7">
        <button [disabled]="isLoading"
          class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 me-3"
          (click)="closeQuestionDrawer()">Cancel</button>
        <p-button [disabled]="isLoading" [loading]="isLoading" label="Save"
          class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
          (onClick)="saveFinancialQuestions()"></p-button>
      </div>
    </div>
  </div>
</div>