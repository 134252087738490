import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { TenderOpportunityWrapperDto } from "../../models/user/TenderOpportunityWrapperDto";
import { TenderWrapperUiDto } from "../../models/user/TenderWrapperUiDto";
import { TenderSourcingEventDto } from "../../models/user/TenderSourcingEventDto";
import { UserInfoUiDto } from "../../models/user/UserInfoUiDto";
import { EntityActionDto } from "../../models/EntityActionDto";

export const TenderManagementActions = createActionGroup({
  source: 'TenderManagement',
  events: {
    'Set Tender Sourcing Events': props<{ tenderSourcingEventDtos: TenderSourcingEventDto[] }>(),
    'Get Tender Sourcing Events': emptyProps(),

    'Set Current Tender Wrapper Ui Dto': props<{ tenderWrapperUiDto: TenderWrapperUiDto }>(),
    'Get Current Tender Wrapper Ui Dto': emptyProps(),
    'Clear Current Tender Wrapper Ui Dto': emptyProps(),

    'Set Current Tender Opportunity Wrapper Dto': props<{ tenderOpportunityWrapperDto: TenderOpportunityWrapperDto }>(),
    'Get Current Tender Opportunity Wrapper Dto': emptyProps(),
    'Clear Current Tender Opportunity Wrapper Dto': emptyProps(),

    'Save All Admin Users': props<{ allAdminUsers: UserInfoUiDto[] }>(),
    'Get All Admin Users': emptyProps(),

    'Save Entity Action Dto': props<{ entityActionDto: EntityActionDto }>(),
    'Get Entity Action Dtos': emptyProps(),
    'Remove Entity Action Dto': props<{ id: string }>(),
    'Clear Entity Action Dtos': emptyProps(),
  }
})