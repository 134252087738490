import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap, BehaviorSubject, firstValueFrom } from 'rxjs';
import { ErrorService } from './error.service';
import { NewChatBotDto } from '../models/chat-bot/NewChatBotDto';
import { ChatBotActivity } from '../models/chat-bot/ChatBotActivityDto';
import { UserUiDto } from '../models/user/UserUiDto';
import { Store } from '@ngrx/store';
import { selectUserUiDto } from '../state-management/session.features';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  baseUrl = "https://india.directline.botframework.com";
  conversationUrl = this.baseUrl + "/v3/directline/conversations";

  authSecret: string = "15TV8d3wQGY.CkvJRoJC7LCSQwKG77z9G7yb1NcvgBEj9ItrbU_2_ao";

  httpHeaders = new HttpHeaders().set("Authorization", `Bearer ${this.authSecret}`);

  private newChatBotDto$ = new BehaviorSubject<NewChatBotDto | undefined>(undefined);
  private chatBotMessages$ = new BehaviorSubject<ChatBotActivity[]>([]);

  private chatBotMessages: ChatBotActivity[] = [];
  private socket?: WebSocket
  private userEntityDto?: UserUiDto;

  isRetryConnection: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private store: Store
  ) { }

  get getNewChatBotDto$() { return this.newChatBotDto$.asObservable(); }
  get getChatBotMessages$() { return this.chatBotMessages$.asObservable(); }

  updateNewChatBotDto(newChatBotDto: NewChatBotDto) {
    this.newChatBotDto$.next(newChatBotDto);

    this.getUserUiDto();

    this.listenBotConversations(newChatBotDto);
  }

  async getUserUiDto() {
    this.userEntityDto = await firstValueFrom(this.store.select(selectUserUiDto));
  }

  listenBotConversations(newChatBotDto: NewChatBotDto): void {
    // this.socket = new WebSocket(newChatBotDto.streamUrl!);

    // this.socket.onopen = (event) => {
    //   // console.log('Connected to the server', event);
    //   this.addMemberInConversation();

    //   // let newChatBotMessage: ChatBotActivity =
    //   // {
    //   //   "activities": [
    //   //     {
    //   //       "type": "message",
    //   //       "id": "",
    //   //       "timestamp": new Date().toISOString(),
    //   //       "channelId": "webchat",
    //   //       "from": {
    //   //         "id": "procureextbot",
    //   //         "name": "procureextbot"
    //   //       },
    //   //       "conversation": {
    //   //         "id": "4AuapYnP6qtHgkfxqkaOPp-in"
    //   //       },
    //   //       "locale": "en-EN",
    //   //       "text": "What card would you like to see? You can click or type the card name",
    //   //       "inputHint": "expectingInput",
    //   //       "suggestedActions": {
    //   //         "actions": [
    //   //           {
    //   //             "type": "imBack",
    //   //             "title": "Adaptive Card",
    //   //             "value": "Adaptive Card"
    //   //           },
    //   //           {
    //   //             "type": "imBack",
    //   //             "title": "Animation Card",
    //   //             "value": "Animation Card"
    //   //           },
    //   //           {
    //   //             "type": "imBack",
    //   //             "title": "Audio Card",
    //   //             "value": "Audio Card"
    //   //           },
    //   //           {
    //   //             "type": "imBack",
    //   //             "title": "OAuth Card",
    //   //             "value": "OAuth Card"
    //   //           },
    //   //           {
    //   //             "type": "imBack",
    //   //             "title": "Hero Card",
    //   //             "value": "Hero Card"
    //   //           },
    //   //           {
    //   //             "type": "imBack",
    //   //             "title": "Receipt Card",
    //   //             "value": "Receipt Card"
    //   //           },
    //   //           {
    //   //             "type": "imBack",
    //   //             "title": "Signin Card",
    //   //             "value": "Signin Card"
    //   //           },
    //   //           {
    //   //             "type": "imBack",
    //   //             "title": "Thumbnail Card",
    //   //             "value": "Thumbnail Card"
    //   //           },
    //   //           {
    //   //             "type": "imBack",
    //   //             "title": "Video Card",
    //   //             "value": "Video Card"
    //   //           },
    //   //           {
    //   //             "type": "imBack",
    //   //             "title": "All Cards",
    //   //             "value": "All Cards"
    //   //           }
    //   //         ]
    //   //       },
    //   //       "replyToId": "4AuapYnP6qtHgkfxqkaOPp-in|0002022"
    //   //     }
    //   //   ],
    //   //   "watermark": "2023"
    //   // }
    //   //   ;
    //   // newChatBotMessage.message = `Hello! What would you like to do today, ${this.userEntityDto?.firstName} ${this.userEntityDto?.lastName}?`

    //   // this.chatBotMessages.push();
    //   // this.chatBotMessages$.next(this.chatBotMessages);
    // };

    // this.socket.onmessage = (event) => {
    //   // console.log('Received message:', event.data);

    //   if (event.data && event.data != '') {
    //     let message = JSON.parse(event.data);

    //     (message.activities ?? []).forEach((item: ChatBotActivity) => {
    //       let isMembersAddedEvent = item.membersAdded && item.membersAdded.length > 0;
    //       if (!isMembersAddedEvent) {
    //         this.chatBotMessages.push(item);
    //       }
    //     })

    //     this.chatBotMessages$.next(this.chatBotMessages);
    //   }
    // };

    // this.socket.onclose = (event) => {
    //   // console.log('Disconnected from the server', event);

    //   let newChatBot = this.newChatBotDto$.value;
    //   newChatBot ? this.refreshAndUpdateToken(newChatBot.conversationId!) : this.startNewConversation();
    // };

    // this.socket.onerror = (event) => {
    //   // console.error('WebSocket error:', event);
    // };
  }

  async startNewConversation() {
    try {
      let response = await firstValueFrom(this.startConversation());
      this.updateNewChatBotDto(response);
    } catch (error) {
      console.error(error);
    }
  }

  async addMemberInConversation() {
    let newChatBot = this.newChatBotDto$.value;

    let body = {
      type: 'conversationUpdate',
      membersAdded: [{
        id: this.userEntityDto?.userId,
        name: this.userEntityDto?.firstName + " " + this.userEntityDto?.lastName
      }],
      from: {
        id: this.userEntityDto?.userId
      },
      conversation: {
        id: newChatBot?.conversationId
      },
      locale: 'en-US',
      timestamp: new Date().toISOString()
    }

    try {
      let response = await firstValueFrom(this.sendActivity(newChatBot?.conversationId!, body));
      console.log(response);

    } catch (error) {
      console.error(error);
    }
  }

  async sendMessageInConversation(message: any) {
    let newChatBot = this.newChatBotDto$.value;

    let body = {
      type: "message",
      from: {
        id: this.userEntityDto?.userId
      },
      conversation: {
        id: newChatBot?.conversationId
      },
      text: message.text || message,
      value: message.value || null,
      locale: "en-US",
      timestamp: new Date().toISOString()
    }

    try {
      let response = await firstValueFrom(this.sendActivity(newChatBot?.conversationId!, body));
      console.log('Response from sendActivity:', response);
    } catch (error) {
      console.error('Error in sendMessageInConversation:', error);
    }
  }

  async refreshAndUpdateToken(conversationId: string) {
    try {
      let response = await firstValueFrom(this.refreshToken(conversationId));
      this.updateNewChatBotDto(response);
    } catch (error) {
      console.error(error);
    }
  }

  sendMessage(message: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open. Ready state:', this.socket?.readyState);
    }
  }

  refreshToken(conversationId: string) {
    return this.httpClient.get(this.conversationUrl + `/${conversationId}`, { headers: this.httpHeaders }).pipe(
      tap(_ => console.log("refreshToken conversationId " + conversationId)),
      catchError(this.errorService.handleError<any>("Error while refreshToken " + conversationId)))
  }

  startConversation() {
    return this.httpClient.post(this.conversationUrl, {}, { headers: this.httpHeaders }).pipe(
      tap(_ => console.log("startConversation")),
      catchError(this.errorService.handleError<any>("Error while startConversation")))
  }

  getActivities(conversationId: string) {
    return this.httpClient.get(this.conversationUrl + `/${conversationId}/activities`, { headers: this.httpHeaders }).pipe(
      tap(_ => console.log("getActivities conversationId " + conversationId)),
      catchError(this.errorService.handleError<any>("Error while getActivities " + conversationId)))
  }

  private sendActivity(conversationId: string, reqBody: any) {
    return this.httpClient.post(this.conversationUrl + `/${conversationId}/activities`, reqBody, { headers: this.httpHeaders }).pipe(
      tap(_ => console.log("sendActivity conversationId " + conversationId)),
      catchError(this.errorService.handleError<any>("Error while sendActivity " + conversationId)))
  }
}
