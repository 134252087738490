<div class="model-body">

  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Auction Timeline</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>



  <div class="model-body-top bg-white-new">

    <div class="m-3">
      <div class="col-12 text-end" *ngIf="!isLoading && activityList.length > 0">
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 ms-3 mb-3" (click)="generatePdf()">PDF</button>
      </div>
      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
      </div>

      <div *ngIf="!isLoading && activityList.length == 0">
        <div>
          <h6 class="heading-h6">
            Data Not Available
          </h6>
        </div>

      </div>

      <div id="pdfContent" class="my-2">
      <div class="card bg-white mt-3 col-10 m-auto avoidPageBreak" *ngFor="let activity of activityList">
        <div *ngIf="isShowAuctionDetails(activity.changeType!)">
          <div class="col-12 p-2 avoidPageBreak">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-7">
                    <b class="b-class">{{getAuctionDetailsMsg(activity.changeType!)}}</b>
                  </div>
                  <div class="col-md-5 text-md-end mt-md-1 mt-2">
                    <span [ngClass]="getClass(activity.changeType!)">
                      {{getChangeTypeValue(activity.changeType!)}}
                    </span>
                  </div>
                  <div>
                  <span>{{activity.changeLabel}}</span> </div>
                </div>
                <div class="">
                  <small class="small-class">
                    {{getSubHeading(activity.changeType!)}} by {{activity.updatedByUserName}} on
                    {{getCreatedDate(activity?.updateTimeUtc!)}}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <hr class="hr my-1 ms-2" *ngIf="isAllowToShowAuctionDetailsTable(activity.changeType!)">
          <div class="col-12 p-2" *ngIf="isAllowToShowAuctionDetailsTable(activity.changeType!)">
            <div class="table-responsive">
              <table class="table table-bordered medium-font mb-0">
                <thead class="table-dark">
                  <tr>
                    <th class=" ">#</th>
                    <th class="">Lot Name</th>
                    <th class="">End Date</th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <tr *ngFor="let attribute of activity.attributes">
                    <td class="font-weight-normal" style="max-width: 85px;">{{attribute.type!}}</td>
                    <td class="font-weight-normal" style="max-width: 200px;">{{attribute.previousValue}}</td>
                    <td class="font-weight-normal" style="max-width: 200px;">{{attribute.updatedValue}}</td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-12 p-2" *ngIf="isShowAuctionLotDetails(activity.changeType!)">
          <div class="row">
            <div class="col-12">
              <b class="">{{getAuctionLotDetailsMsg(activity.changeType!)}}</b><br>
              <div class="">#{{activity.lotSequence}} {{activity.lotName}}</div>
              <div class="">
                <small class="small-class">
                  {{getSubHeading(activity.changeType!)}} by {{activity.updatedByUserName}} on
                  {{getCreatedDate(activity?.updateTimeUtc!)}}
                </small>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isAllowToShowAuctionAndLotSetting(activity.changeType!)">
          <div class="col-12 p-2">
            <div class="row">
              <div class="col-12">
                <b class="">{{getAuctionAndLotConfigMsg(activity.changeType!)}}</b><br>
                <div class="" *ngIf="activity.lotChange">#{{activity.lotSequence}} {{activity.lotName}}</div>
                <div class="">
                  <small class="small-class">
                    Updated by {{activity.updatedByUserName}} on
                    {{getCreatedDate(activity?.updateTimeUtc!)}}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <hr class="hr my-1 ms-2">
          <div class="col-12 p-2">
            <div class="table-responsive">
              <table class="table table-bordered medium-font  mb-0">
                <thead class="table-dark">
                  <tr>
                    <th class=" ">Attribute</th>
                    <th class=" ">Previous</th>
                    <th class=" ">Updated</th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <tr *ngFor="let attribute of activity.attributes">
                    <td class="font-weight-normal" style="max-width: 85px;">{{attribute.type!}}</td>
                    <td class="font-weight-normal" style="max-width: 200px;">{{attribute.previousValue}}</td>
                    <td class="font-weight-normal" style="max-width: 200px;">{{attribute.updatedValue}}</td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div class="col-12 p-2" *ngIf="isAllowToShowAddedOrRemovedActivities(activity.changeType!)">
          <div class="row">
            <div class="col-12">
              <b class="">{{activity.attributes![0].type}}</b><br>
              <div class="" *ngIf="activity.attributes![0].previousValue">{{activity.attributes![0].previousValue}}
              </div>
              <div class="" *ngIf="activity.attributes![0].updatedValue">{{activity.attributes![0].updatedValue}}</div>
              <div class="">
                <small class="small-class">
                  Updated by {{activity.updatedByUserName}} on
                  {{getCreatedDate(activity?.updateTimeUtc!)}}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

  </div>
</div>