export enum AdminUserPrivilege {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMINISTRATOR = "ADMINISTRATOR",
  PROCUREMENT_MANAGER = "PROCUREMENT_MANAGER",
  SOURCING_SPECIALIST = "SOURCING_SPECIALIST",
  BUYER = "BUYER",
  CATEGORY_MANAGER = "CATEGORY_MANAGER",
  SUPPLIER_MANAGER = "SUPPLIER_MANAGER",
  FINANCE_OFFICER = "FINANCE_OFFICER",
  IT_SETUP = "IT_SETUP",
  AUDIT = "AUDIT"
}