import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModalRef, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { QuestionOptions } from 'src/app/shared/models/questionnaire/QuestionOptions';
import { QuestionnaireTemplate } from 'src/app/shared/models/questionnaire/QuestionnaireTemplate';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { UserAuctionQuestionsDto } from 'src/app/shared/models/questionnaire/UserAuctionQuestionsDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-bidder-questionnaire-technical-question',
  templateUrl: './bidder-questionnaire-technical-question.component.html',
  styleUrls: ['./bidder-questionnaire-technical-question.component.sass']
})
export class BidderQuestionnaireTechnicalQuestionComponent implements OnInit, OnDestroy {
  @Input() selectedTechnicalQuestion!: TechnicalQuestionTemplate;
  @Input() userId!: string;

  questionResponseType: typeof QuestionResponseType = QuestionResponseType;

  auctionEntityDto?: AuctionEntityDto;
  userEntityDto?: UserUiDto
  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  questionnaireTemplate?: QuestionnaireTemplate;
  technicalQuestionsList: TechnicalQuestionTemplate[] = []
  userAuctionQuestions: UserAuctionQuestionsDto[] = [];

  selectedDocuments: FileInfoDto[] = [];
  bidderResponse: any;

  ctrlQuestion: FormControl = new FormControl('');
  ctrlBidderComments: FormControl = new FormControl('');
  ctrlDateStart: FormControl = new FormControl('', Validators.required);
  ctrlDateEnd: FormControl = new FormControl('', Validators.required);
  ctrlAlphaNumeric: FormControl = new FormControl('', Validators.required);

  isLoading: boolean = false;
  isUploadLoading: boolean = false;
  documentModalRef?: NgbModalRef;

  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription;
  selectedAuctionSubscription$?: Subscription;
  userAuctionQuestionsSubscription$?: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private questionnaireService: QuestionnaireService,
    private auctionDataHolderService: AdminSourcingEventsDataHolderService
  ) { }

  ngOnInit(): void {
    this.auctionEntityDto = this.auctionDataHolderService.selectedAuction;

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        this.questionnaireTemplate = data.questionnaireTemplate;

        this.technicalQuestionsList = this.questionnaireWrapperDto?.technicalQuestionTemplates ?? [];
        this.technicalQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
      }
    })

    this.ctrlQuestion.patchValue(`${this.selectedTechnicalQuestion.preSequenceText}.${this.selectedTechnicalQuestion.sequenceNo} ${this.selectedTechnicalQuestion.questionText}`);
    this.ctrlQuestion.disable();
    this.ctrlQuestion.updateValueAndValidity();

    this.getUserAuctionQuestions();
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }

  async getUserAuctionQuestions() {
    this.questionnaireService.getUserAuctionQuestions(this.auctionEntityDto?.auctionId!, this.userId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.userAuctionQuestions = apiResponseDto.data as UserAuctionQuestionsDto[];
          this.populateUserAuctionQuestions();
        } else {
          console.error("Error while calling loadUserAuctionQuestions : " + JSON.stringify(apiResponseDto));
        }
      },
      error: (error => {
        console.error(error);
      })
    })
  }

  populateUserAuctionQuestions() {
    let currentQuestion = this.userAuctionQuestions.find(item => item.questionId == this.selectedTechnicalQuestion.questionId);
    if (currentQuestion) {
      if (currentQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
        this.bidderResponse = currentQuestion.bidderResponse;
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
        this.bidderResponse = currentQuestion.bidderResponse;
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE) {
        this.ctrlDateStart.patchValue(currentQuestion.bidderResponse);
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.DATE_RANGE) {
        this.ctrlDateStart.patchValue(currentQuestion.bidderResponse);
        this.ctrlDateEnd.patchValue(currentQuestion.bidderResponse);
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.ALPHANUMERIC) {
        this.ctrlAlphaNumeric.patchValue(currentQuestion.bidderResponse);
      }

      this.selectedDocuments = currentQuestion.bidderDocuments ?? [];

      this.ctrlDateStart.disable();
      this.ctrlDateEnd.disable();
      this.ctrlAlphaNumeric.disable();
    }
  }

  resetCurrentQuestion() {
    this.bidderResponse = undefined;
    this.ctrlDateStart.reset();
    this.ctrlDateEnd.reset();
    this.ctrlAlphaNumeric.reset();
    this.ctrlBidderComments.reset();
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this.populateUserAuctionQuestions();
  }

  nextQuestion() {
    let currentSequenceNo = this.selectedTechnicalQuestion.sequenceNo;
    if (Number(currentSequenceNo) < this.technicalQuestionsList.length) {
      let index = this.technicalQuestionsList.findIndex(item => item.sequenceNo == currentSequenceNo);
      this.selectedTechnicalQuestion = this.technicalQuestionsList[index + 1];
      this.resetCurrentQuestion();
    }
  }

  prevQuestion() {
    let currentSequenceNo = this.selectedTechnicalQuestion.sequenceNo;
    if (Number(currentSequenceNo) > 1) {
      let index = this.technicalQuestionsList.findIndex(item => item.sequenceNo == currentSequenceNo);
      this.selectedTechnicalQuestion = this.technicalQuestionsList[index - 1];
      this.resetCurrentQuestion();
    }
  }

  isDisabledPrev() {
    return Number(this.selectedTechnicalQuestion.sequenceNo) == 1;
  }

  isDisabledNext() {
    return Number(this.selectedTechnicalQuestion.sequenceNo) == this.technicalQuestionsList.length;
  }

  formatBytes(size: any) {
    return ApplicationUtils.formatBytes(size);
  }

  isOptionSelected(questionOption?: QuestionOptions) {
    if (questionOption && this.bidderResponse) {
      if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.SINGLE_CHOICE) {
        return this.bidderResponse.optionSequence == questionOption.optionSequence;
      } else if (this.selectedTechnicalQuestion.responseType == QuestionResponseType.MULTI_CHOICE) {
        let option = this.bidderResponse.find((item: QuestionOptions) => item.optionSequence == questionOption.optionSequence)
        return option != undefined;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe()
    }
    if (this.userAuctionQuestionsSubscription$) {
      this.userAuctionQuestionsSubscription$.unsubscribe()
    }
  }
}
