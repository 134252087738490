<ng-container [ngTemplateOutlet]="isShowOtpModal ? otpModal : emailVerify">
</ng-container>

<ng-template #emailVerify>
  <div class="my-2">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Verify Email</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <app-message-toast [isErrorButton]="true" [messageButton]="'Click here to Verify'"
        [message]="'Your Registration is Incomplete. Please Verify your Email ID.'" (output)="showOtpModal()">
      </app-message-toast>
    </div>
  </div>
</ng-template>

<ng-template #otpModal>
  <div class="my-2">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Verify Email</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': (isLoading$ | async)}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="my-3">
      <div class="text-start m-3">
        <p>Enter the One Time Password (OTP) which was just sent to the email address registered with. Be sure to check
          your spam folder.</p>
      </div>
      <div class="m-3">
        <form class="needs-validation" [formGroup]="formGroup" autocomplete="off" class="needs-validation" novalidate
          (keyup.enter)="verifyOTP()">
          <div class="col-12 text-start">
            <div class="form-floating mt-3">
              <input type="text" id="otp" formControlName="otp" class="form-control" id="otp"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && formGroup.controls['otp'].invalid}" />
              <label for="otp">OTP</label>
            </div>
            <div *ngIf="fc.otp.invalid && (fc.otp.dirty || fc.otp.touched)">
              <p class="e-fielderror e-fielderror-required text-red-500 " *ngIf="fc.otp.errors?.required">Required</p>
            </div>
          </div>
          <div class="text-end mt-2">
            <a class="btn-link col-6 text-start c-pointer" (click)="resendOTP()">Resend OTP ?</a>
          </div>

          <div class="e-spinner my-3" *ngIf="isLoading$ | async"></div>

          <div class="my-3">
            <app-message-toast *ngIf="_showSuccessMsg$ | async" [message]="_successMsg$ | async"></app-message-toast>
            <app-message-toast *ngIf="errorMsg" [isError]="true" [message]="errorMsg"></app-message-toast>
          </div>

          <div class="text-start my-1" *ngIf="!(isLoading$ | async) && !(_showSuccessMsg$ | async)">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" [disabled]="isLoading$ | async"
              (click)="closeModal()">CANCEL</button>
            <button type="button" class="btn btn-sm btn-outline-primary" [disabled]="(isLoading$ | async) || fc.otp.invalid "
              (click)="verifyOTP()">VERIFY</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
