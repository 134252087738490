export class RfxValidationErrorCodeDto {
  code?: string;
  type?: string;
  message?: string;
  param1?: string;
  param2?: string;
  subcategorySequence?: string;
  subcategoryId?: string;
  subcategoryName?: string;

}


