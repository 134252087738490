import { ContractTypeEnum } from './../../../shared/enums/rfx/ContractTypeEnum';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { RankPriceConfigEnum } from 'src/app/shared/enums/RankPriceConfigEnum';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { RfxValidationErrorCodeDto } from 'src/app/shared/models/user/RfxValidationErrorCodeDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { ContractAlgoEnum } from 'src/app/shared/enums/rfx/ContractAlgoEnum';
import { RfxSubcategorySaveWrapperDto } from 'src/app/shared/models/user/RfxSubcategorySaveWrapperDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { RfxValidationService } from 'src/app/shared/services/rfx-validation.service';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { TechnicalGradationDto } from 'src/app/shared/models/rfx/TechnicalGradationDto';
import { Pattern } from 'src/app/shared/util/Patterns';
import { RfxTypeEnum } from 'src/app/shared/enums/rfx/RfxTypeEnum';
import { EventEnum } from 'src/app/shared/enums/EventEnum';

@Component({
  selector: 'app-rfx-subcategory-details',
  templateUrl: './rfx-subcategory-details.component.html',
  styleUrls: ['./rfx-subcategory-details.component.sass']
})
export class RfxSubcategoryDetailsComponent implements OnInit, OnDestroy {

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToastForModal$ = new BehaviorSubject<boolean>(false);
  _showErrorToastForModal$ = new BehaviorSubject<boolean>(false);

  _errorMsg$ = new BehaviorSubject<string>("");
  _isMultiLineError$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(false);

  rfxEntityDto?: RfxUiDto;
  subcategoryEntityDto?: RfxSubcategoryUiDto;
  rfxValidationErrorCodeDtoList?: RfxValidationErrorCodeDto[];

  errorMsgForModal: string | undefined;
  isSubmitted: boolean = false;
  isLoading: boolean = false;
  isLoadingForModal: boolean = false;
  maxBidPlaced: number = 0;
  highestBidPrice: number = 0;
  incrementBidValue?: string;
  selectedRfxSubscription$?: Subscription;
  selectedSubcategorySubscription$?: Subscription;
  isShowWarnAndBlock: boolean = false;
  multipleBidIncrementDecrementAllowed?: boolean = false;

  deleteQuestionnaireModalRef?: NgbModalRef

  disqualifiedIndex: number | null = null;

  rankPriceConfig: typeof RankPriceConfigEnum = RankPriceConfigEnum;

  formGroup: FormGroup

  constructor(
    private fb: FormBuilder,
    private ngbModal: NgbModal,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private rfxSubcategoryService: AdminRfxSubcategoryDataHolderService,
    private validationService: RfxValidationService,
    private questionnaireService: QuestionnaireService
  ) {
    this.formGroup = this.fb.group({
      contractPeriod: [0],
      emdValue: [''],
      estimatedValue: [''],
      maximumScore: [''],
      qualifyingScore: [''],
      targetedBidders: [null],
    })
  }

  ngOnInit(): void {
    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe((data) => {
      if (data) {
        this.rfxEntityDto = data;
      }
    })

    this.selectedSubcategorySubscription$ = this.rfxSubcategoryService.selectedRfxSubcategory$.subscribe((data) => {
      if (data) {
        this.subcategoryEntityDto = data;
        this.populateRfxSubcategoryBidDetails();
      }
    })
  }


  get fc(): any { return this.formGroup.controls; }

  openRfxSubcategoryBidDetailModal(content: any) {
    this.formGroup.reset();
    this.populateRfxSubcategoryBidDetails();
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll()
      this.isSubmitted = false;
      this.formGroup.reset();
    }
  }

  formatEmdValue() {
    let emdValue = this.formGroup.controls['emdValue'].value;
    let emdValueFinal = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, emdValue);
    this.fc['emdValue'].patchValue(emdValueFinal);
  }

  formatEstimatedValue() {
    let estimatedValue = this.formGroup.controls['estimatedValue'].value;
    let estimatedValueFinal = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, estimatedValue);
    this.fc['estimatedValue'].patchValue(estimatedValueFinal);
  }


  populateRfxSubcategoryBidDetails() {

    // Populate data in Form
    this.formGroup.patchValue(this.subcategoryEntityDto!);

    // contractPeriod
    if (this.subcategoryEntityDto?.contractPeriod) {
      let contractPeriod = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, this.subcategoryEntityDto?.contractPeriod);
      this.formGroup.get('contractPeriod')?.patchValue(contractPeriod);
    }

    if (this.subcategoryEntityDto?.emdValue) {
      let emdValue = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, this.subcategoryEntityDto?.emdValue);
      this.formGroup.get('emdValue')?.patchValue(emdValue);
    } else {
      this.formGroup.get('emdValue')?.patchValue('0');
    }

    this.formGroup.get('targetedBidders')?.patchValue(this.subcategoryEntityDto?.targetedEvent)

    // this.checkQuestionnaireValidations();

    this.formGroup.updateValueAndValidity();
  }




  getFormattedPrice(price?: string | number) {
    return ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale, price);
  }



  mergeRfxEntityDto(formValue: any): RfxSubcategoryUiDto {

    let rfxSubcategoryEntityDto = ApplicationUtils.clone(this.subcategoryEntityDto) as RfxSubcategoryUiDto;
    rfxSubcategoryEntityDto.contractPeriod = formValue.contractPeriod;
    rfxSubcategoryEntityDto.emdValue = ApplicationUtils.resetPriceFormat(formValue.emdValue);
    rfxSubcategoryEntityDto.targetedEvent = formValue.targetedBidders;

    return rfxSubcategoryEntityDto;
  }

  saveRfxSubcategory() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.formGroup.markAllAsTouched();

    this._showErrorToast$.next(false);
    this.isSubmitted = true;
    this.rfxValidationErrorCodeDtoList = [];

    this._isMultiLineError$.next(false);
    this._errorMsg$.next("");

    if (this.formGroup.invalid) {
      return;
    }

    let formValue = this.formGroup.getRawValue();
    let rfxSubcategoryEntityDto = this.mergeRfxEntityDto(formValue);

    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    this.rfxSubcategoryService.saveRfxSubcategoryDetails(rfxSubcategoryEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let rfxSubcategorySaveWrapperDto = apiResponseDto.data as RfxSubcategorySaveWrapperDto;
          let rfxValidationErrorCodeDtoList = rfxSubcategorySaveWrapperDto.rfxValidationErrorCodeDtos;

          if (!rfxValidationErrorCodeDtoList || rfxValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._isSaveButtonEnable$.next(true);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLoading = false;
            this._isSaveButtonEnable$.next(true);
            this.closeModal();
            this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving subcategory. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);

      }
    })
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
    if (this.selectedSubcategorySubscription$) {
      this.selectedSubcategorySubscription$.unsubscribe();
    }
  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.rfxValidationErrorCodeDtoList;
  }

  invalidPriceValidator(item: AbstractControl | null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }

  haveBidSettingErrors() {
    if (this.rfxEntityDto && this.subcategoryEntityDto) {
      return this.validationService.haveBidSettingErrors(this.rfxEntityDto!, this.subcategoryEntityDto!);
    }
    return false;
  }

  checkQuestionnaireValidations() {
    let questionnairesWrapper = this.questionnaireService.getQuestionnaireWrapper;
    if (questionnairesWrapper) {
      let financialQuestions = questionnairesWrapper.financialQuestionTemplates;

      if (financialQuestions && financialQuestions.length > 0) {
        this.formGroup.controls['contractType'].addValidators(Validators.required);
        this.formGroup.controls['contractAlgo'].addValidators(Validators.required);
      } else {
        this.formGroup.controls['contractType'].clearValidators();
        this.formGroup.controls['contractAlgo'].clearValidators();
      }

      this.formGroup.controls['contractType'].updateValueAndValidity();
      this.formGroup.controls['contractAlgo'].updateValueAndValidity();
      this.formGroup.updateValueAndValidity()
    }
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT && !this.rfxEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.rfxEntityDto?.status == RfxStatus.LIVE && !this.rfxEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }

}

