<div class="bg-white-new">
  <div class="container position-relative">
    <div class="container my-4">
      <div class="col-12">
        <div class="card bg-white border-0 shadow">
          <div class="p-2">
            <div class="row justify-content-between">
              <div class="col-auto me-auto">
                <img src="/downloadLandingBlob?fileId={{
                auctionEntityDto?.auctionImage?.fileId
              }}" [alt]="auctionEntityDto?.auctionName" class="image-auction-header me-3" />
                <h5 class="heading-h5 text-capitalize text-capitalize mt-2">
                  #{{ auctionEntityDto?.sequenceNo }}
                  {{ auctionEntityDto?.auctionName }}
                </h5>
              </div>
              <div class="col-auto mt-2 mt-md-0">
                <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-2 mt-1" (click)="getAuctionDashboardData()">
                  <img src="assets/icons/refresh_dark.svg" class="c-pointer svg-icon-class me-1" />
                  REFRESH
                  <span class="spinner-border spinner-border-sm ms-2 loader-spinner" role="status" aria-hidden="true"
                    *ngIf="isRefreshing"></span>
                </button>
              </div>
            </div>
            <hr class="hr my-2">
            <div class="row my-2">
              <div class="col-md-6 col-xl-4 mt-2">
                <div class="span-class">
                  <small>Auction Type</small>
                </div>

                <span class="span-class">
                  <b>{{ auctionEntityDto?.auctionType }}</b>
                </span>
              </div>
              <div class="col-md-6 col-xl-4 mt-2">
                <div class="span-class">
                  <small>Start Date:</small>
                </div>

                <span class="span-class">
                  <b>{{
                    getDisplayDate(
                    auctionEntityDto?.startDate,
                    auctionEntityDto?.startTime
                    )
                    }}</b>
                </span>
              </div>
              <div class="col-md-6 col-xl-4 mt-2">
                <div class="span-class">
                  <small>End Date:</small>
                </div>

                <span class="span-class">
                  <b>{{
                    getDisplayDate(
                    auctionEntityDto?.endDate,
                    auctionEntityDto?.endTime
                    )
                    }}</b>
                </span>
              </div>
              <div class="col-md-6 col-xl-4 mt-2">
                <div class="span-class">
                  <small> Remaining Time:</small>
                </div>

                <span class="span-class">
                  <div>
                    <div class="" id="countdown{{ counterFunction }}{{
                                    auctionEntityDto?.auctionId
                                  }}" *ngIf="auctionEntityDto?.status != 'CLOSE'">
                      <span class="cust-text danger-clrr fw-bold" id="headline{{ counterFunction }}{{
                                      auctionEntityDto?.auctionId
                                    }}"></span>
                      <span id="lotcountdown{{ counterFunction }}{{
                                      auctionEntityDto?.auctionId
                                    }}">
                        <span class="cust-text danger-clrr fw-bold" id="days{{ counterFunction }}{{
                                        auctionEntityDto?.auctionId
                                      }}"></span>
                        <span class="cust-text danger-clrr fw-bold" id="hours{{ counterFunction }}{{
                                        auctionEntityDto?.auctionId
                                      }}"></span>
                        <span class="cust-text danger-clrr fw-bold" id="minutes{{ counterFunction }}{{
                                        auctionEntityDto?.auctionId
                                      }}"></span>
                        <span class="cust-text danger-clrr fw-bold" id="second{{ counterFunction }}{{
                                        auctionEntityDto?.auctionId
                                      }}"></span>
                      </span>
                    </div>

                    <div *ngIf="auctionEntityDto?.status == 'CLOSE'">
                      <span class="fw-bold">Closed</span>
                    </div>
                  </div>
                </span>
              </div>
              <div class="col-md-6 col-xl-4 mt-2">
                <div class="span-class">
                  <small> Time Zone:</small>
                </div>

                <span class="span-class" *ngIf="auctionEntityDto?.timeZone">
                  <b> {{ getTimeZone(auctionEntityDto?.timeZone) }}</b>
                </span>
              </div>
              <div class="col-md-6 col-xl-4 mt-2">
                <div class="span-class">
                  <small>Soft Close:</small>
                </div>

                <span class="span-class">
                  <b>{{  0 }} minutes</b>
                </span>
              </div>
              <div class="col-md-6 col-xl-4 mt-2">
                <div class="span-class">
                  <small> No of Lots:</small>
                </div>

                <span class="span-class">
                  <b>{{ auctionEntityDto?.notOfLots }}</b>
                </span>
              </div>
              <div class="col-md-6 col-xl-4 mt-2">
                <div class="span-class">
                  <small>Status:</small>
                </div>

                <span class="span-class">
                  <span class="badge"
                    [ngClass]="{'bg-info' : auctionEntityDto?.status == 'LIVE', 'bg-danger' : auctionEntityDto?.status == 'CLOSE'}">
                    {{auctionEntityDto?.status}}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-md-6 mb-4">
            <div class="card bg-white border-0 shadow chart-height">
              <div class="px-3 pt-3 pb-0">
                <div class="row">
                  <div class="col-9">
                    <h5 class="heading-h5 text-capitalize">Registration Chart</h5>
                  </div>
                  <div class="col-3 text-end">
                    <img src="assets/icons/info_light.svg" class="close-icon-class" ngbTooltip="Registration Chart Data">
                  </div>
                </div>
                <hr class="hr my-2">
                <div class="mt-2">
                  <div echarts [options]="chartOptionRegistrations" (chartClick)="filterRegistrations($event)"
                    class="demo-chart" *ngIf="!isRegistrationChartLoading"></div>
                  <div class="d-grid gap-2 mb-3" *ngIf="isRegistrationChartLoading">
                    <div class="e-spinner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card bg-white border-0 shadow chart-height">
              <div class="p-2">
                <div class="row">
                  <div class="col-9">
                    <h5 class="heading-h5 text-capitalize">Registration List</h5>
                  </div>
                  <div class="col-3 text-end">
                    <img src="assets/icons/info_light.svg" class="close-icon-class" ngbTooltip="Registration List Data">
                  </div>
                </div>

                <hr class="hr my-2">
                <div class="mt-2">
                  <app-analytics-registration-list [filterType$]="registrationFilterType$">
                  </app-analytics-registration-list>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card bg-white border-0 shadow chart-height">
              <div class="p-2">

                <div class="row">
                  <div class="col-9">
                    <h5 class="heading-h5 text-capitalize">No of bids</h5>
                  </div>
                  <div class="col-3 text-end">
                    <img src="assets/icons/info_light.svg" class="close-icon-class " ngbTooltip="No Of Bids Data">
                  </div>
                </div>

                <hr class="hr my-2">
                <div class="scroll-height">
                  <div class="mt-2 p-2 ">
                    <div id="chartNoOfBids" echarts [options]="chartOptionNoOfBids" class="demo-chart" *ngIf="!isNoOfBidsChartLoading"></div>
                    <div class="d-grid gap-2 mb-3" *ngIf="isNoOfBidsChartLoading">
                      <div class="e-spinner"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card bg-white border-0 shadow chart-height">
              <div class="p-2">

                <div class="row">
                  <div class="col-9">
                    <h5 class="heading-h5 text-capitalize">Bids Price</h5>
                  </div>
                  <div class="col-3 text-end">
                    <img src="assets/icons/info_light.svg" class="close-icon-class "  ngbTooltip="Bids Price Data" >
                  </div>
                </div>

                <hr class="hr my-2 ">
              <div class="scroll-height">
                <div class="mt-2 p-2">
                  <div id="chartBidsPrice" echarts [options]="chartOptionBidsPrice" class="demo-chart" *ngIf="!isBidPriceChartLoading">
                  </div>
                  <div class="d-grid gap-2 mb-3" *ngIf="isBidPriceChartLoading">
                    <div class="e-spinner"></div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <app-analytics-lot-activity [auctionDashboardDto$]="_auctionDashboardData$"></app-analytics-lot-activity>
          </div>
          <div class="col-md-6 mb-4">
            <app-analytics-revenue-chart [auctionDashboardDto$]="_auctionDashboardData$">
            </app-analytics-revenue-chart>
          </div>
          <!-- <div class="col-12 mb-4">
            <app-analytics-bids-prices [auctionDashboardDto$]="_auctionDashboardData$" *ngIf="auctionEntityDto?.type! = 'REVERSE_AUCTION'">
            </app-analytics-bids-prices>
          </div>
          <app-analytics-award-status [auctionDashboardDto$]="_auctionDashboardData$">
          </app-analytics-award-status>
          <app-analytics-invoice-status [auctionDashboardDto$]="_auctionDashboardData$">
          </app-analytics-invoice-status> -->
        </div>
      </div>
    </div>
  </div>
</div>
