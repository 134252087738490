import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddressDto } from 'src/app/shared/models/Address';
import { CompanyAccountUiDto } from 'src/app/shared/models/CompanyAccountUiDto';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-onboarding-view-company-details',
  templateUrl: './onboarding-view-company-details.component.html',
  styleUrls: ['./onboarding-view-company-details.component.sass']
})
export class OnboardingViewCompanyDetailsComponent implements OnInit, OnDestroy {
  selectedCompanyUiDto?: CompanyUiDto;
  selectedBillingAddress?: AddressDto;
  selectedShippingAddress?: AddressDto;
  selectedBankAccount?: CompanyAccountUiDto;

  selectedCompanySubscription$?: Subscription

  constructor(
    private datePipe: DatePipe,
    private onboardingService: OnboardingService,
    private drawerService: DrawerService,

  ) {
  }
  ngOnInit(): void {
    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;

        if (this.selectedCompanyUiDto?.billingAddress && this.selectedCompanyUiDto.billingAddress.length > 0) {
          this.selectedBillingAddress = this.selectedCompanyUiDto.billingAddress[0];
        }

        if (this.selectedCompanyUiDto?.shippingAddress && this.selectedCompanyUiDto.shippingAddress.length > 0) {
          this.selectedShippingAddress = this.selectedCompanyUiDto.shippingAddress[0];
        }

        if (this.selectedCompanyUiDto?.companyAccountUiDtos && this.selectedCompanyUiDto.companyAccountUiDtos.length > 0) {
          this.selectedBankAccount = this.selectedCompanyUiDto.companyAccountUiDtos[0];
        }
      } else {
        this.selectedCompanyUiDto = undefined;
      }
    })
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  getFormattedFinancialYear(date: string) {
    let convertedDate = ApplicationUtils.convertedOnlyDate(date);
    return this.datePipe.transform(convertedDate, 'dd MMM');
  }

  openEditCompanyDrawer(tab?: string) {
    this.onboardingService.setCurrentPlantUiDto(this.selectedCompanyUiDto);
    this.drawerService.isFormEditMode(true);
    this.drawerService.openDrawer('ONBOARDING_NEW_COMPANY', tab);
  }

  ngOnDestroy(): void {
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
  }

}
