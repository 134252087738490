import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ApprovalWorkflowEnum } from 'src/app/shared/enums/ApprovalWorkflowEnum';
import { ApprovalWorkflowUiDto } from 'src/app/shared/models/approval-workflow/ApprovalWorkflowUiDto';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { selectTenderWrapperUiDto, selectTenderOpportunityWrapperDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-tender-approvals',
  templateUrl: './tender-approvals.component.html',
  styleUrls: ['./tender-approvals.component.sass']
})
export class TenderApprovalsComponent implements OnInit, OnDestroy {
  tenderWrapperUiDto?: TenderWrapperUiDto;
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;
  approvalWorkflowUiDtos: ApprovalWorkflowUiDto[] = [];

  tenderPublisher?: ApprovalWorkflowUiDto;
  technicalApprover?: ApprovalWorkflowUiDto;
  financialApprover?: ApprovalWorkflowUiDto;
  
  applicationConstants: typeof ApplicationConstants = ApplicationConstants;

  tenderWrapperUiDtoSubscription$?: Subscription;
  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  createNewMenuItems = [
    { escape: false },
  ]
  createTechItems= [
    { escape: false },
  ]
  createFinItems= [
    { escape: false },
  ]
  constructor(
    private store: Store,
    private drawerService: DrawerService,
  ) { }

  ngOnInit(): void {
    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
        this.approvalWorkflowUiDtos = this.tenderOpportunityWrapperDto?.approvalWorkflowUiDto || []

        this.tenderPublisher = this.approvalWorkflowUiDtos.find(item => item.awfDefineUiDto?.wfCode == ApprovalWorkflowEnum.TENDER_CREATION);
        this.technicalApprover = this.approvalWorkflowUiDtos.find(item => item.awfDefineUiDto?.wfCode == ApprovalWorkflowEnum.TENDER_TECHNICAL_OPENING);
        this.financialApprover = this.approvalWorkflowUiDtos.find(item => item.awfDefineUiDto?.wfCode == ApprovalWorkflowEnum.TENDER_FINANCIAL_OPENING);
      } else {
        this.tenderOpportunityWrapperDto = undefined;
      }
    })
  }

  openTenderApprovalDrawer(tabName: string) {
    this.drawerService.openDrawer(ApplicationConstants.NEW_TENDER_APPROVAL_PAGE, tabName);
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }

    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}
