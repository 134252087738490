import { AddressDto } from "./Address";
import { PlantCostCenterUiDto } from "./PlantCostCenterUiDto";


export class PlantUiDto {
  orgCode?: string;
  companyCode?: string;
  plantCode ?: string;
  plantName ?: string;
  accountTitle ?: string;
  accNumber ?: string;
  accHolderName?: string;
  accType ?: string;
  accCode ?: string;
  swift ?: string;
  iban ?: string;
  plantCostCenterUiDtos?: Array<PlantCostCenterUiDto>;
  shippingAddress?: Array<AddressDto>;
  billingAddress?: Array<AddressDto>;

}

export interface PlantUiDto {
  plantCode ?: string;
  plantName ?: string;
}