import { Injectable } from '@angular/core';
import { Currency } from '../models/Currency';
import { OrgLanguageDto } from '../models/OrgLanguageDto';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  get getLanguages() { return this.languages; }

  private languages: OrgLanguageDto[] =  [
      {
        "languageCode": "English",
        "direction": "LTR"
      },
      {
        "languageCode": "Portuguese",
        "direction": "LTR"
      },
      {
        "languageCode": "Spanish",
        "direction": "LTR"
      },
      {
        "languageCode": "Arabic",
        "direction": "RTL"
      },
      {
        "languageCode": "French",
        "direction": "LTR"
      },
      {
        "languageCode": "German",
        "direction": "LTR"
      },
      {
        "languageCode": "Hindi",
        "direction": "LTR"
      },
      {
        "languageCode": "Indonesian",
        "direction": "LTR"
      },
      {
        "languageCode": "Malay",
        "direction": "LTR"
      },
      {
        "languageCode": "Dutch",
        "direction": "LTR"
      },
      {
        "languageCode": "Filipino",
        "direction": "LTR"
      },
      {
        "languageCode": "Thai",
        "direction": "LTR"
      }
    ]
  
  
}
