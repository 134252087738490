import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { AdminUserPrivilege } from './../../../shared/enums/AdminUserPrivilege';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import { AuctionStatus } from './../../../shared/models/user/AuctionStatus';
import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { AiDescriptionGeneratorComponent } from 'src/app/shared/components/ai-description-generator/ai-description-generator.component';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Firestore, Timestamp, collection, onSnapshot, where, query } from '@angular/fire/firestore';
import { Unsubscribe } from '@angular/fire/auth';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { EventStages } from 'src/app/shared/enums/EventStages';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { Store } from '@ngrx/store';
import { NavigationEventsDto } from 'src/app/shared/models/NavigationEventsDto';
import { NavigationActions } from 'src/app/shared/state-management/navigation/navigation.actions';

@Component({
  selector: 'app-auction-creator',
  templateUrl: './auction-creator.component.html',
  styleUrls: ['./auction-creator.component.sass']
})
export class AuctionCreatorComponent implements OnInit, OnDestroy {
  @Input() currentEventStage$ = new Observable<EventStages>();
  IsNoImage = true;

  selectedAuctionSubscription$?: Subscription;
  currentTab: number = 1

  _auctionHouseDataLoadedSubscription$?: Subscription;
  _allAuctionDataLoadedSubscription$?: Subscription;
  _allLotsDataLoadedSubscription$?: Subscription;
  _masterDataLoadedSubscription$?: Subscription;
  currentEventStageSubscription$?: Subscription;

  _auctionHouseDataLoaded: boolean = false;
  _allAuctionDataLoaded: boolean = false;
  _allLotsDataLoaded: boolean = false;
  _masterDataLoaded: boolean = false;

  activeStage: EventStages = EventStages.EVENT;

  collectionAuctions: string = "AUCTIONS";
  collectionAuctionLots: string = "AUCTION_LOTS";

  auctionListenerUnsubscribe: Unsubscribe | undefined;
  lotsListenerUnsubscribe: Unsubscribe | undefined;

  isFirestoreListenerAttached: boolean = false;

  _renderView$ = new BehaviorSubject<boolean>(false);
  auctionEntityDto?: AuctionEntityDto;

  constructor(
    public firestore: Firestore,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private dataRedirectionService: DashboardRedirectionService,
    private router: Router,
    private ngbModal: NgbModal,
    public adminService: AdminDashboardService,
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    public adminLotsDataHolderService: AdminLotsDataHolderService,
    private onboardingService: OnboardingService,
    private store: Store
  ) { }

  ngOnInit(): void {

    let selectedTabName = sessionStorage.getItem("TAB_NAME");

    if (selectedTabName == ApplicationConstants.REGISTRATION_TAB) {
      this.currentTab = 2;
      let auction = this.adminSourcingEventsDataHolderService.getSelectedAuction();
      this.adminService.loadBidderRegistrationList(auction!.auctionId!);
    } else if (selectedTabName == ApplicationConstants.BIDDING_TAB) {
      this.currentTab = 3;
    } else if (selectedTabName == ApplicationConstants.QUESTIONS_TAB) {
      this.currentTab = 4;
    } else if (selectedTabName == ApplicationConstants.POST_AUCTION_TAB) {
      this.currentTab = 5;
    } else {
      this.currentTab = 1;
    }

    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(async (data) => {
      if (data) {
        this.auctionEntityDto = data;
        this.adminSourcingEventsDataHolderService.updatePostAuctionList(this.auctionEntityDto);
        this.changeDetectorRef.detectChanges();
        if (!this.isFirestoreListenerAttached) {
          this.attachAuctionChangesListener();
          this.attachLotsChangesListener();
          this.isFirestoreListenerAttached = true;
        }
      }
    })

    this._auctionHouseDataLoadedSubscription$ = this.adminService.getOrganizationDataLoaded$.subscribe((data) => {
      if (data) {
        this._auctionHouseDataLoaded = data;
        this.renderView();
      }
    })

    this._allAuctionDataLoadedSubscription$ = this.adminSourcingEventsDataHolderService.getAllEventsDataLoaded$.subscribe((data) => {
      if (data) {
        this._allAuctionDataLoaded = data;
        this.renderView();
      }
    })

    this._allLotsDataLoadedSubscription$ = this.adminService.getAllLotsDataLoaded$.subscribe((data) => {
      if (data) {
        this._allLotsDataLoaded = data;
        this.renderView();
      }
    })

    this._masterDataLoadedSubscription$ = this.adminService.getMasterDataLoaded$.subscribe((data) => {
      if (data) {
        this._masterDataLoaded = data;
        this.renderView();
      }
    })

    this.currentEventStageSubscription$ = this.currentEventStage$.subscribe(currentEvent => {
      if (currentEvent) {
        this.activeStage = currentEvent;
        if (this.activeStage == EventStages.POST_EVENT) {
          this.toggleDetailsTab(5);
        } else {
          this.toggleDetailsTab(1);
        }
      }
    })
  }

  toggleDetailsTab(tab: number) {
    this.currentTab = tab;
    let auctionEntityDto = this.adminSourcingEventsDataHolderService.getSelectedAuction();

    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.ADMIN;
    navigation.pageName = ApplicationConstants.AUCTION_CREATOR_PAGE;

    if (this.currentTab == 1) {
      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.AUCTION_CREATOR_PAGE,
      //   ApplicationConstants.AUCTION_TAB,
      //   `${auctionEntityDto?.sequenceNo}`
      // );

      navigation.tabName = ApplicationConstants.AUCTION_TAB;
    } else if (this.currentTab == 2) {
      this.adminService.loadBidderRegistrationList(auctionEntityDto?.auctionId!);

      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.AUCTION_CREATOR_PAGE,
      //   ApplicationConstants.REGISTRATION_TAB,
      //   `${auctionEntityDto?.sequenceNo}`
      // );

      navigation.tabName = ApplicationConstants.REGISTRATION_TAB;
    } else if (this.currentTab == 3) {
      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.BIDDING_TAB,
      //   ApplicationConstants.AUCTION_TAB,
      //   `${auctionEntityDto?.sequenceNo}`
      // );

      navigation.tabName = ApplicationConstants.BIDDING_TAB;
    } else if (this.currentTab == 4) {
      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.AUCTION_CREATOR_PAGE,
      //   ApplicationConstants.QUESTIONS_TAB,
      //   `${auctionEntityDto?.sequenceNo}`
      // );

      navigation.tabName = ApplicationConstants.QUESTIONS_TAB;
    } else if (this.currentTab == 5) {
      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.AUCTION_CREATOR_PAGE,
      //   ApplicationConstants.POST_AUCTION_TAB,
      //   `${auctionEntityDto?.sequenceNo}`
      // );

      navigation.tabName = ApplicationConstants.POST_AUCTION_TAB;
    }

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
  }

  renderView() {
    if (this._auctionHouseDataLoaded && this._allLotsDataLoaded && this._masterDataLoaded) {
      this._renderView$.next(true);

      // let auctionId = sessionStorage.getItem("RESOURCE_ID");
      // if (auctionId) {
      //   this.adminAuctionDataHolderService.setSelectedAuctionByAuctionId(auctionId as string);
      // }
    }
  }

  uploadBannerImage(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    }
    );
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }


  attachAuctionChangesListener() {
    let lastFetchTimestamp = Timestamp.now();

    logEvent(getAnalytics(), "attachAuctionChangesListener");

    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let auctionRef = collection(this.firestore, this.collectionAuctions);
      let auctionQuery = query(auctionRef,
        where('orgCode', '==', orgCode),
        where('auctionId', '==', this.auctionEntityDto!.auctionId),
        where('updateTimeUtc', '>', lastFetchTimestamp));

      this.auctionListenerUnsubscribe = onSnapshot(auctionQuery, documentSnapshots => {
        let auctionsList = documentSnapshots.docChanges().map(change => change.doc.data() as AuctionEntityDto);

        logEvent(getAnalytics(), 'attachAuctionChangesListener Data Received size : ' + auctionsList.length);
        console.log("attachAuctionChangesListener Data Received size: " + auctionsList.length);

        if (auctionsList && auctionsList.length > 0) {
          let updatedAuction = auctionsList[0];
          // Changed Firestore timestamp to server timestamp
          let timestamp = updatedAuction.updateTimeUtc as Timestamp;
          updatedAuction.updateTimeUtc = (timestamp.seconds * 1000).toString();

          Object.assign(this.auctionEntityDto!, updatedAuction);
          this.adminSourcingEventsDataHolderService.setSelectedAuction(this.auctionEntityDto!)
        }
        this.changeDetectorRef.detectChanges()
      });
    }
  }

  attachLotsChangesListener() {
    let lastFetchTimestamp = Timestamp.now();

    logEvent(getAnalytics(), "attachLotsChangesListener");

    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let lotRef = collection(this.firestore, this.collectionAuctionLots);
      let lotQuery = query(lotRef,
        where('orgCode', '==', orgCode),
        where('auctionId', '==', this.auctionEntityDto!.auctionId),
        where('updateTimeUtc', '>', lastFetchTimestamp));

      this.lotsListenerUnsubscribe = onSnapshot(lotQuery, documentSnapshots => {
        let lotsList = documentSnapshots.docChanges().map(change => change.doc.data() as AuctionLotEntityDto);

        logEvent(getAnalytics(), 'attachLotsChangesListener Data Received size : ' + lotsList.length);
        console.log("attachLotsChangesListener Data Received size: " + lotsList.length);

        if (lotsList && lotsList.length > 0) {
          this.adminLotsDataHolderService.updateLotChangesData(lotsList)
        }
        this.changeDetectorRef.detectChanges()
      });
    }
  }


  navigateToHomePage() {
    // let user = this.userService.getUserEntity();
    // if (user && user.AdminUserPrivilege == AdminUserPrivilege.AGENT) {
    //   this.router.navigate(['Agent/Dashboard'], { skipLocationChange: true })
    // } else if (user && user.AdminUserPrivilege == AdminUserPrivilege.IT_SETUP) {
    //   this.router.navigate(['Admin/Dashboard'], { skipLocationChange: true })
    //   this.dataRedirectionService.setCurrentPageInSession(AuctionExtConstant.ADMIN_DASHBOARD_AUCTION_TAB);
    // }
    this.dataRedirectionService.navigationToHomePage();
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }

    if (this._auctionHouseDataLoadedSubscription$) {
      this._auctionHouseDataLoadedSubscription$.unsubscribe();
    }

    if (this._allAuctionDataLoadedSubscription$) {
      this._allAuctionDataLoadedSubscription$.unsubscribe();
    }

    if (this._allLotsDataLoadedSubscription$) {
      this._allLotsDataLoadedSubscription$.unsubscribe();
    }

    if (this._masterDataLoadedSubscription$) {
      this._masterDataLoadedSubscription$.unsubscribe();
    }

    this.currentTab = 1;

    if (this.auctionListenerUnsubscribe) {
      this.auctionListenerUnsubscribe();
    }

    if (this.lotsListenerUnsubscribe) {
      this.lotsListenerUnsubscribe();
    }

  }


  openAiDescription() {
    this.ngbModal.open(AiDescriptionGeneratorComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    })
  }

}
