
<div class="tr-form-space-and-background-drawer tr-overflow-y-drawer">
<form class="needs-validation w-full" autocomplete="off" [formGroup]="formGroup" (keyup.enter)="saveLegalPolicy(false)">
  <div class="">
    <div class="mb-4">
      <label for="name" class="text-gray-700  text-sm font-semibold leading-4">Title</label>
      <input type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm" id="name" placeholder="Title" formControlName="name">
    </div>
    <div class="form-group mb-3">
      <input type="checkbox" class="me-1" formControlName="footerPolicy" id="footerPolicy"/>
      <span class="text-gray-700  text-sm font-semibold leading-4">Footer Policy</span>
    </div>
    <label for="footerPolicy" class="text-gray-700  text-sm font-semibold leading-4">Privacy</label>
    <div class="form-group mb-3">
      <angular-editor #privacyEditor formControlName="privacyPolicy"  class="form-control" id="privacyEditor"
        [config]="config"></angular-editor>
      <div
        *ngIf="(_validationErrorPresent$ | async) || fc.privacyPolicy.invalid && (fc.privacyPolicy.dirty || fc.privacyPolicy.touched)">
        <p class="tr-validations" *ngIf="fc.privacyPolicy.errors?.required">
          <span class="mr-2">
            <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
          </span>
          Required
        </p>
      </div>
    </div>
    <label for="privacyEditor" class="text-gray-700  text-sm font-semibold leading-4">Terms of use</label>
    <div class="form-group mb-3">
      <angular-editor #termsOfUseEditor formControlName="termsOfUse" class="form-control" id="termsOfUseEditor"
        [config]="config"></angular-editor>
      <div
        *ngIf="(_validationErrorPresent$ | async) || fc.termsOfUse.invalid && (fc.termsOfUse.dirty || fc.termsOfUse.touched)">
        <p class="tr-validations" *ngIf="fc.termsOfUse.errors?.required">
          <span class="mr-2">
            <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
          </span>
          Required</p>
      </div>
    </div>
  </div>

  <div class="my-3">
    <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Legal Policy saved successfully.'"></app-message-toast>
    <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
  </div>

  <div class="mb-3" *ngIf="isLoading">
    <div class="e-spinner"></div>
  </div>

  <div class=" text-end mb-5 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
    <button type="button" class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md" (click)="saveLegalPolicy(true)">Save</button>
  </div>
</form>
</div>

<!-- <div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>LEGAL</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
 <div class="model-body-top">
  <div class="m-3">
    <form class="needs-validation" autocomplete="off" [formGroup]="formGroup" (keyup.enter)="saveLegalPolicy(false)">
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="name" placeholder="Title" formControlName="name">
        <label for="name">Title</label>
      </div>
      <div class="form-group mb-3">
        <input type="checkbox" class="me-1" formControlName="footerPolicy" id="footerPolicy"/>
        <span>Footer Policy</span>
      </div>
      <label for="footerPolicy" class="text-area-label">PRIVACY</label>
      <div class="form-group mb-3">
        <angular-editor #privacyEditor formControlName="privacyPolicy"  class="form-control" id="privacyEditor"
          [config]="config"></angular-editor>
        <div
          *ngIf="(_validationErrorPresent$ | async) || fc.privacyPolicy.invalid && (fc.privacyPolicy.dirty || fc.privacyPolicy.touched)">
          <p class="text-red-500 " *ngIf="fc.privacyPolicy.errors?.required">Required</p>
        </div>
      </div>
      <label for="privacyEditor" class="text-area-label">TERMS OF USE</label>
      <div class="form-group mb-3">
        <angular-editor #termsOfUseEditor formControlName="termsOfUse" class="form-control" id="termsOfUseEditor"
          [config]="config"></angular-editor>
        <div
          *ngIf="(_validationErrorPresent$ | async) || fc.termsOfUse.invalid && (fc.termsOfUse.dirty || fc.termsOfUse.touched)">
          <p class="text-red-500 " *ngIf="fc.termsOfUse.errors?.required">Required</p>
        </div>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Legal Policy saved successfully.'"></app-message-toast>
        <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
      </div>

      <div class="mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="saveLegalPolicy(true)">SAVE</button>
      </div>
    </form>
  </div>
 </div>
</div> -->
