
import { GlobalBidderEntityDto } from 'src/app/shared/models/GlobalBidderEntityDto';
import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AdminUserPrivilege } from 'src/app/shared/enums/AdminUserPrivilege';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';import { FileService } from 'src/app/shared/services/file.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MobileWrapper } from 'src/app/shared/models/user/MobileWrapper';
import { EmailWrapper } from 'src/app/shared/models/user/EmailWrapper';
import { CategoryDto } from 'src/app/shared/models/CategoryDto';
import { LandingService } from 'src/app/shared/services/LandingAndBidder.service';
import { BidderDto } from 'src/app/shared/models/BidderDto';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';

@Component({
  selector: 'app-bidder-details',
  templateUrl: './bidder-details.component.html',
  styleUrls: ['./bidder-details.component.sass']
})
export class BidderDetailsComponent implements OnInit, OnDestroy {

  @Input() bidderEntityDto!: BidderDto;

  preferenceCategories: PreferenceCategoryDto[] = []

  profileImage?: FileInfoDto;

  isLoading: boolean = false;
  isUserProfileLoading: boolean = false;
  isSubmitted: boolean = false;


  selectedWrapper?: MobileWrapper | EmailWrapper;
  selectedWrapperType?: string;

  latitude?: number;
  longitude?: number;
  countryShortName?: string

  currentFileInfoDto?: FileInfoDto
  imageUrl?: string | ArrayBuffer | SafeUrl | null;


  userEntitySubscription$?: Subscription;
  userByUserIdSubscription$?: Subscription;



  zoom = 12;
  infoContent = ''
  position?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  marker = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private landingService: LandingService
  ) {

  }

  ngOnInit(): void {
    this.populateUserDetails(this.bidderEntityDto);
  }


  populateUserDetails(globalBidderDto: BidderDto) {

    this.profileImage = this.bidderEntityDto?.profileImage;

    if (globalBidderDto.address?.latitude && globalBidderDto.address?.longitude) {
      this.latitude = Number(globalBidderDto.address?.latitude);
      this.longitude = Number(globalBidderDto.address?.longitude);

      this.position = { lat: this.latitude!, lng: this.longitude! }
      this.marker.label.text = globalBidderDto?.address?.searchAddress!;
      this.marker.title = globalBidderDto?.address?.searchAddress!;
      this.marker.info = globalBidderDto?.address?.searchAddress!;
    }

    if (this.profileImage) {
      this.loadImage();
    }


    if (globalBidderDto.preferenceCategories) {
      this.preferenceCategories = globalBidderDto.preferenceCategories;
    }
  }

  loadImage() {
    this.imageUrl = `/downloadLandingBlob?fileId=${this.profileImage?.fileId}`;
  }

  closeUserDetailsModal() {
    this.bidderEntityDto = null!;
    this.imageUrl = null;
    this.profileImage = null!;
    this.modalService.dismissAll();
  }

  closeModal(modalRef?: NgbModalRef) {
    modalRef?.close();
    this.isLoading = false;
  }



  openInfo(marker: MapMarker, content: string) {
    this.infoContent = content;
    this.info.open(marker)
  }

  getCountryCode(countryCode: CountryCodeDto) {
    return ApplicationUtils.getCountryCode(countryCode);
  }



  ngOnDestroy(): void {
    if (this.userEntitySubscription$) {
      this.userEntitySubscription$.unsubscribe()
    }
    if (this.userByUserIdSubscription$) {
      this.userByUserIdSubscription$.unsubscribe()
    }
  }

}
