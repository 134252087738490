import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { AddressDto } from 'src/app/shared/models/Address';
import { CatalogueRequisitionWrapper } from 'src/app/shared/models/CatalogueRequisitionWrapper';
import { CatalogueVendorDto } from 'src/app/shared/models/CatalogueVendorDto';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { Currency } from 'src/app/shared/models/Currency';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { MobileWrapper } from 'src/app/shared/models/user/MobileWrapper';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { selectUserUiDto } from 'src/app/shared/state-management/session.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

declare const require: any;
const html2pdf = require('html2pdf.js');

@Component({
  selector: 'app-catalogue-po',
  templateUrl: './catalogue-po.component.html',
  styleUrls: ['./catalogue-po.component.sass']
})
export class CataloguePoComponent implements OnInit, OnDestroy {
  catalogueRequisitionWrappers?: CatalogueRequisitionWrapper[]
  selectedCatalogueRequisitionWrapper?: CatalogueRequisitionWrapper;
  isLoading: boolean = false;
  isShowPdfView: boolean = false;
  currency?: Currency;
  catalogueVendor?: CatalogueVendorDto
  currentDate?: string;

  organizationUiDto?: OrganizationWrapperUiDto
  companyUiDto?: CompanyUiDto;
  billingAddress?: AddressDto;

  constructor(
    private datePipe: DatePipe,
    private adminService: AdminDashboardService,
    private catalogueService: CatalogueService,
    private onboardingService: OnboardingService,
    private changeDetectRef: ChangeDetectorRef,
    private ngbModal: NgbModal,
    private store: Store
  ) {

  }
  ngOnInit(): void {
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy')!;
    this.organizationUiDto = this.onboardingService.getOrganizationUiDto;

    this.catalogueService.getRequisitionWrapperList$.subscribe(dataList => {
      if (dataList && dataList.length > 0) {
        this.catalogueRequisitionWrappers = dataList;
      } else {
        this.catalogueRequisitionWrappers = [];
      }
    })

    this.loadCompanyUiDto();
  }

  async loadCompanyUiDto() {
    let userEntityInfoDto = await firstValueFrom(this.store.select(selectUserUiDto));
    try {
      let apiResponseDto = await firstValueFrom(this.onboardingService.fetchCompanyUiDto(userEntityInfoDto?.orgCode!, userEntityInfoDto!.companyCode!));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.companyUiDto = apiResponseDto.data as CompanyUiDto;
        if (this.companyUiDto.billingAddress && this.companyUiDto.billingAddress.length > 0) {
          this.billingAddress = this.companyUiDto.billingAddress[0];
        } else {
          this.billingAddress = undefined;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }


  generatePdf() {
    this.isShowPdfView = true;
    this.changeDetectRef.detectChanges();
    // this.isLoading = true;

    const element = document.getElementById('pdfContent');

    let auctionHouseDto = this.adminService.getCurrentAuctionHouse$.value;
    const secondaryColor = auctionHouseDto?.secondaryColor ?? '#1b5887';

    if (element) {
      let opt = {
        // margin: [25, 12, 30, 12],
        filename: 'PO_' + "test" + '.pdf',
        html2canvas: { scale: 6 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };

      html2pdf().from(element).set(opt).set({ margin: [25, 12, 25, 12] }).toPdf().get('pdf').then((pdf: any) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          pdf.setDrawColor(secondaryColor);
          pdf.setLineWidth(1);
          pdf.line(20, pdf.internal.pageSize.getHeight() - 25, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 25);

          pdf.setFontSize(9);
          pdf.setTextColor('#000000');

          let text = `Page ${i}`;
          let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          let textOffset = (pdf.internal.pageSize.getWidth() - textWidth) / 2;

          pdf.text(text, textOffset, pdf.internal.pageSize.getHeight() - 10);
        }

        this.isLoading = false;
        this.isShowPdfView = false;
        this.changeDetectRef.detectChanges();
      }).save();
    }
  }

  getFormattedMobileNo(mobileWrapper?: MobileWrapper) {
    let countryCodeDto = mobileWrapper?.countryCode;
    if (countryCodeDto) {
      let countryCode = countryCodeDto.countryCode?.replace('(', '').replace(')', '');
      return countryCode + ' ' + mobileWrapper?.mobileNo;
    }
    return '+1 ' + mobileWrapper?.mobileNo;
  }


  openAllItemsModal(content: any, selectedCatalogueRequisitionWrapper?: CatalogueRequisitionWrapper) {
    this.selectedCatalogueRequisitionWrapper = selectedCatalogueRequisitionWrapper;
    this.ngbModal.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openArcApprovalModal(catalogueRequisitionWrapper: CatalogueRequisitionWrapper, content: any) {
    this.selectedCatalogueRequisitionWrapper = catalogueRequisitionWrapper;
    this.ngbModal.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false, centered: true
    });
  }

  markToApprove() {
    this.selectedCatalogueRequisitionWrapper!.isApproved = true;
    this.catalogueService.updateCatalogueRequisitionWrapper(this.selectedCatalogueRequisitionWrapper!);
    this.closeModal();
  }

  showDetails(catalogueRequisitionWrapper: CatalogueRequisitionWrapper) {
    this.selectedCatalogueRequisitionWrapper = catalogueRequisitionWrapper;
    this.currency = this.selectedCatalogueRequisitionWrapper.catalogueCartWrapperDtos![0].currency;
    this.catalogueVendor = this.selectedCatalogueRequisitionWrapper.catalogueCartWrapperDtos![0].catalogueVendor;
  }
  backDetails() {
    this.selectedCatalogueRequisitionWrapper = undefined;
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  ngOnDestroy(): void {

  }

}
