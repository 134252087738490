<div>
  <div class="row my-2 justify-content-between align-items-center tr-margin-right-24px tr-margin-left-24px">
    <div class="col-8">
      <span class=""> <img src="/assets/icons/drawer-head-icon-1.svg" class="tr-xl-icon c-pointer"></span>
      <div class="d-inline-block tr-margin-left-10px inline-flex ml-2  text-gray-300 font-semibold text-xl leading-4">
        Adding New Material
      </div>
    </div>
    <div class="col-4 text-end">
      <button class="bg-gray-50 text-gray-700 text-sm px-4 py-2 rounded-md mr-2 tr-margin-right-10px"
        (click)="closeDrawer()">Cancel</button>
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="saveMaterial()">Save as
        draft</button>
    </div>
  </div>
  <div class="tr-form-space-and-background-drawer">
    <div class="tr-overflow-y-drawer">
      <form class="needs-validation w-100" novalidate novalidate [formGroup]="formGroup"
        (keyup.enter)="handleValidSubmit()">

        <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2">

          <div class="mb-2">
            <label for="materialId" class="text-gray-700  text-sm font-semibold leading-4">Material Id</label>
            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              type="text" id="materialId" formControlName="materialId" placeholder="---"
              [ngClass]="{'is-invalid': fc['materialId'].invalid && (fc['materialId'].dirty || fc['materialId'].touched)}">
            <div *ngIf="fc['materialId'].invalid && (fc['materialId'].dirty || fc['materialId'].touched)">
              <p class="tr-validations" *ngIf="fc['materialId'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                Required
              </p>
              <p class="tr-validations" *ngIf="fc['materialId'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>Invalid
              </p>
              <p class="tr-validations" *ngIf="fc['materialId'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>Invalid
              </p>
            </div>
          </div>
          <div class="mb-2">
            <label for="materialName" class="text-gray-700  text-sm font-semibold leading-4">Material Name</label>
            <div class="">
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                type="text" id="materialName" formControlName="materialName" placeholder="---"
                [ngClass]="{'is-invalid': fc['materialName'].invalid && (fc['materialName'].dirty || fc['materialName'].touched)}">
            </div>
            <div *ngIf="fc['materialName'].invalid && (fc['materialName'].dirty || fc['materialName'].touched)">
              <p class="tr-validations" *ngIf="fc['materialName'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                Required
              </p>
            </div>
          </div>

        </div>
        <div class="col-12">
          <label for="description" class="text-gray-700  text-sm font-semibold leading-4">Description</label>
          <div class="mb-3">
            <textarea type="text" class="form-control w-full" id="description" formControlName="description" rows="3"
              [ngClass]="{'is-invalid': fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)}"></textarea>
            <div *ngIf="fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)">
              <p class="text-red-500 " *ngIf="fc['description'].errors?.['required']">Required
              </p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
        <div class="mb-2">
          <label for="qty" class="text-gray-700  text-sm font-semibold leading-4">
            Quantity
          </label>
          <div class="">
            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              type="text" id="qty" formControlName="qty" placeholder="---"
              [ngClass]="{'is-invalid': fc['qty'].invalid && (fc['qty'].dirty || fc['qty'].touched)}">
          </div>
          <div *ngIf="fc['qty'].invalid && (fc['qty'].dirty || fc['qty'].touched)">
            <p class="tr-validations" *ngIf="fc['qty'].errors?.['required']">
              <span class="mr-2">
                <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
              </span>
              Required
            </p>
          </div>
        </div>


        <div class="mb-2">
          <label for="uom" class="text-gray-700  text-sm font-semibold leading-4">UoM</label>
          <div class="">
            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              type="text" id="uom" formControlName="uom" placeholder="---"
              [ngClass]="{'is-invalid': fc['uom'].invalid && (fc['uom'].dirty || fc['uom'].touched)}">
          </div>
          <div *ngIf="fc['uom'].invalid && (fc['uom'].dirty || fc['uom'].touched)">
            <p class="tr-validations" *ngIf="fc['uom'].errors?.['required']">
              <span class="mr-2">
                <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
              </span>
              Required
            </p>

          </div>
        </div>
      </div>

        <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
          <div class="mb-2">
            <label for="image" class="text-gray-700  text-sm font-semibold leading-4">Add Material Image</label>


            <input name="" type="text" id="image" (click)="materialImage.click()" [value]="imageFileName"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm c-pointer "
              [ngClass]="{'is-invalid': fc['image'].invalid && (fc['image'].dirty || fc['image'].touched)}" />
            <input #materialImage type="file" onclick="this.value=null" (change)="chooseFile($event)" class="hidden" />

            <div *ngIf="fc['image'].invalid && (fc['image'].dirty || fc['image'].touched)">
              <p class="text-red-500 " *ngIf="fc['image'].errors?.['image']">Required</p>
            </div>
          </div>


          <div class="mb-2">
            <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2" *ngIf="imageUrl">
              <div class="">
                <img class="modal-logo-image" alt="" [src]="imageUrl" />
              </div>

              <div class=" mt-3 text-end">
                <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-2" (click)="openViewImageModal(imageUrl)">
                  <img src="assets/icons/view_dark.svg" class="svg-icon-class c-pointer">
                </button>

                <span (click)="removeMaterialImage()" [ngClass]="{'disabled' : isLoading }">
                  <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                </span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!selectedPreferenceCategory" class="mb-4">
          <label for="category" class="text-gray-700  text-sm font-semibold leading-4 ">Select UNSPSC Category</label>
          <div class="col-9 mx-auto mb-3 p-2" [ngClass]="{'border border-danger': !selectedPreferenceCategory}">
            <app-categories-tree-view [selectionMode]="TreeSelectionMode.single"
              (onSelectedCategory)="onSelectedCategory($event)"></app-categories-tree-view>
          </div>
        </div>

        <div *ngIf="selectedPreferenceCategory" class="mb-4">
          <label for="selectedCategory" class="text-gray-700  text-sm font-semibold leading-4 ">Selected UNSPSC
            Category</label>
          <div class="table-responsive">
            <table class="table table-bordered medium-font mb-3 align-middle">
              <thead class="table-dark">
                <tr class="text-center">
                  <th class=""><span>Label One</span></th>
                  <th class=""><span>Label Two</span></th>
                  <th class=""><span>Label Three</span></th>
                  <th class=""><span>Label Four</span></th>
                  <th class=""><span>Label Five</span></th>
                  <th class=""><span>Action</span></th>
                </tr>
              </thead>

              <tbody class="bg-white">
                <tr>
                  <td>{{selectedPreferenceCategory.categoryName}}</td>
                  <td>{{selectedPreferenceCategory.categoryLevelOneName}}</td>
                  <td>{{selectedPreferenceCategory.categoryLevelTwoName}}</td>
                  <td>{{selectedPreferenceCategory.categoryLevelThreeName}}</td>
                  <td>{{selectedPreferenceCategory.categoryLevelFourName}}</td>
                  <td><a class="link-class" (click)="toggleEditCategory()">Edit</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


      </form>

      <label class="mb-1 text-gray-700  text-sm font-semibold leading-4" for="safetyLevels">Safety Levels
        {{ngTableDataList.length}}</label>
      <div class="card">
        <p-table [value]="ngTableDataList" dataKey="id" [tableStyle]="{ 'min-width': '50rem' }"
          [loading]="isDataLoading">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:15%">#</th>
              <th style="width:20%">Company</th>
              <th style="width:20%">Plant</th>
              <th style="width:15%">Current Level</th>
              <th style="width:15%">Safety Min</th>
              <th style="width:15%">Safety Max</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-tableData let-editing="editing" let-i="rowIndex">
            <tr>
              <td>{{ i+1 }}</td>
              <td>{{ tableData.companyCode }}</td>
              <td>{{ tableData.plantCode }}</td>
              <td [pEditableColumn]="tableData.currentLevel" pEditableColumnField="currentLevel">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="tableData.currentLevel" />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ tableData.currentLevel }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td [pEditableColumn]="tableData.safetyMin" pEditableColumnField="safetyMin">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="tableData.safetyMin" required />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ tableData.safetyMin }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td [pEditableColumn]="tableData.safetyMax" pEditableColumnField="safetyMax">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText [(ngModel)]="tableData.safetyMax" />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ tableData.safetyMax }}
                  </ng-template>
                </p-cellEditor>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="mb-4">
        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$|async" [isError]="true" [message]="errorMsg">
          </app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'Material saved successfully.'">
          </app-message-toast>
        </div>
        <div class="col-12 text-end mb-5" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md"
            (click)="handleValidSubmit()">
            {{isEditMaterial ? 'EDIT' : 'ADD'}}
          </button>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>
      </div>
    </div>
  </div>
</div>