<div class="model-body">
  <!-- <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>ADD AUCTION </h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
          (click)="closeModal()">
      </div>
    </div>
  </header> -->
  <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleSubmit()">
    <div class="mb-3">
      <label for="addAuctionTitle" class="text-gray-700  text-sm font-semibold leading-4">Auction Title</label>
      <input type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select" id="addAuctionTitle"
        formControlName="auctionName"
        [ngClass]="{'is-invalid': fc.auctionName.invalid && (fc.auctionName.dirty || fc.auctionName.touched)}">
      <div *ngIf="isSubmitted || fc.auctionName.invalid && (fc.auctionName.dirty || fc.auctionName.touched)">
        <p class="text-red-500 " *ngIf="fc.auctionName.errors?.required">Required
        </p>
      </div>
    </div>

    <div class=" mb-3">
      <label for="addAuctionBiddingMethod" class="text-gray-700  text-sm font-semibold leading-4">Auction Bidding
        Method</label>
      <select name="Select Timezone" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select" formControlName="auctionBiddingMethod"
        id="addAuctionBiddingMethod"
        [ngClass]="{'is-invalid': fc.auctionBiddingMethod.invalid && (fc.auctionBiddingMethod.dirty || fc.auctionBiddingMethod.touched)}"
        (change)="onAuctionBiddingMethodChange()">
        <option value="FORWARD_AUCTION">Forward Auction</option>
        <option value="REVERSE_AUCTION">Reverse Auction</option>
      </select>
      <div
        *ngIf="isSubmitted || fc.auctionBiddingMethod.invalid && (fc.auctionBiddingMethod.dirty || fc.auctionBiddingMethod.touched)">
        <p class="text-red-500 " *ngIf="fc.auctionBiddingMethod.errors?.required">Required
        </p>
      </div>
    </div>

    <div class="mb-3">
      <label for="auctionType" class="text-gray-700  text-sm font-semibold leading-4">Auction Type</label>
      <select name="Select Timezone" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select" formControlName="auctionType" id="auctionType"
        [ngClass]="{ 'is-invalid':  fc.auctionType.invalid && (fc.auctionType.dirty || fc.auctionType.touched)}">
        <option value="" disabled>Select . . .</option>
        <option value="Reserve">Reserve</option>
        <option value="Absolute">Absolute</option>
        <option value="Subject to Confirmation">Subject to Confirmation</option>
      </select>
      <div *ngIf="isSubmitted || fc.auctionType.invalid && (fc.auctionType.dirty || fc.auctionType.touched)">
        <p class="text-red-500 " *ngIf="fc.auctionType.errors?.required">Required
        </p>
      </div>
    </div>

    <div class="mb-3">
      <label for="currency" class="text-gray-700  text-sm font-semibold leading-4">Currency
        {{selectedCurrency?.symbol}}</label>
      <select (change)="onChangeCurrency($event)" name="Select Currency" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select"
        formControlName="currency" id="currency"
        [ngClass]="{'is-invalid': fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)}">
        <option value="" disabled>Select . . .</option>
        <option [value]="item.name" [selected]="item.name == selectedCurrency?.name" *ngFor="let item of currencies">
          {{item.name}}</option>
      </select>

      
      <div *ngIf="isSubmitted || fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)">
        <p class="text-red-500 " *ngIf="fc.currency.errors?.required">Required</p>
      </div>
    </div>

    <div class="flex flex-row gap-3">
      <div class="basis-6/12 mb-3">
        <label for="estSpend" class="text-gray-700  text-sm font-semibold leading-4">Est Spend</label>
        
          <input type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select" formControlName="estSpend" (blur)="formatEstSpendValue()"
            id="estSpend" [ngClass]="{'is-invalid': fc.estSpend.invalid && (fc.estSpend.dirty || fc.estSpend.touched)}">
         
          <div *ngIf="isSubmitted || fc.estSpend.invalid && (fc.estSpend.dirty || fc.estSpend.touched)">
            <p class="text-red-500 " *ngIf="fc.estSpend.errors?.required">Required</p>
            <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.estSpend)">Invalid</p>
         
        </div>
      </div>

      <div class="basis-6/12 mb-3">
        <label for="estSaving" class="text-gray-700  text-sm font-semibold leading-4">Est Saving</label>
       
          <input type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select" formControlName="estSaving" id="estSaving"
            (blur)="formatEstSavingValue()"
            [ngClass]="{'is-invalid': fc.estSaving.invalid && (fc.estSaving.dirty || fc.estSaving.touched)}">
          <div *ngIf="isSubmitted || fc.estSaving.invalid && (fc.estSaving.dirty || fc.estSaving.touched)">
            <p class="text-red-500 " *ngIf="fc.estSaving.errors?.required">Required</p>
            <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.estSaving)">Invalid</p>
          </div>
        </div>
      </div>
    
    <div class="mb-3">
      <label for="timeZone" class="text-gray-700  text-sm font-semibold leading-4">Select Timezone</label>
      <select name="Select Timezone" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select" id="timeZone" formControlName="timeZone"
        [ngClass]="{'is-invalid': fc.timeZone.invalid && (fc.timeZone.dirty || fc.timeZone.touched)}">
        <option value="" disabled>Select . . .</option>
        <option [value]="item?.locale" *ngFor="let item of timezones">{{item?.fullName}}</option>
      </select>
      
      <div *ngIf="isSubmitted || fc.timeZone.invalid && (fc.timeZone.dirty || fc.timeZone.touched)">
        <p class="text-red-500 " *ngIf="fc.timeZone.errors?.required">Required</p>
      </div>
    </div>

    <div class="mb-3">
      <label for="region" class="text-gray-700  text-sm font-semibold leading-4">Region</label>
      <select name="Select Region" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select" formControlName="region" id="region"
        [ngClass]="{'is-invalid': fc.region.invalid && (fc.region.dirty || fc.region.touched)}">
        <option value="" disabled>Select . . .</option>
        <option value="North America">North America</option>
        <option value="Latin America (LATAM)">Latin America (LATAM)</option>
        <option value="Europe, Middle East, and Africa (EMEA)">Europe, Middle East, and Africa (EMEA)</option>
        <option value="Asia-Pacific (APAC)">Asia-Pacific (APAC)</option>
        <option value="Commonwealth of Independent States (CIS)">Commonwealth of Independent States (CIS)</option>
        <option value="Sub-Saharan Africa">Sub-Saharan Africa</option>
      </select>

      <div *ngIf="isSubmitted || fc.region.invalid && (fc.region.dirty || fc.region.touched)">
        <p class="text-red-500 " *ngIf="fc.region.errors?.required">Required</p>
      </div>
    </div>

    <div class=" mb-3">
      
      <label for="costCenters" class="text-gray-700  text-sm font-semibold leading-4">Cost Centers</label>
      <select name="Select Cost Centers" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select" formControlName="costCenters" id="costCenters"
        [ngClass]="{'is-invalid': fc.costCenters.invalid && (fc.costCenters.dirty || fc.costCenters.touched)}">
        <option value="" disabled>Select . . .</option>
        <option value="Human Resources (HR)">Human Resources (HR)</option>
        <option value="Finance and Accounting">Finance and Accounting</option>
        <option value="Legal">Legal</option>
        <option value="Executive Management">Executive Management</option>
        <option value="Information Technology (IT)">Information Technology (IT)</option>
        <option value="Facilities Management">Facilities Management</option>
        <option value="Procurement/Purchasing">Procurement/Purchasing</option>
        <option value="Research and Development (R&D)">Research and Development (R&D)</option>
        <option value="Quality Assurance (QA)">Quality Assurance (QA)</option>
        <option value="Marketing">Marketing</option>
        <option value="Sales Support">Sales Support</option>
        <option value="Manufacturing/Production">Manufacturing/Production</option>
        <option value="Logistics and Transportation">Logistics and Transportation</option>
        <option value="Customer Service/Support">Customer Service/Support</option>
        <option value="Training and Development">Training and Development</option>
        <option value="Corporate Communications">Corporate Communications</option>
        <option value="Environmental, Health, and Safety (EHS)">Environmental, Health, and Safety (EHS)</option>
        <option value="Corporate Social Responsibility (CSR)">Corporate Social Responsibility (CSR)</option>
        <option value="Internal Audit">Internal Audit</option>
        <option value="Risk Management">Risk Management</option>
      </select>

      <div *ngIf="isSubmitted || fc.costCenters.invalid && (fc.costCenters.dirty || fc.costCenters.touched)">
        <p class="text-red-500 " *ngIf="fc.costCenters.errors?.required">Required</p>
      </div>
    </div>

    <div class="mb-3">
      
      <label for="floatingInput" class="text-gray-700  text-sm font-semibold leading-4">Bidder Approval</label>
      <select name="Bidder Approval" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select" formControlName="bidderApproval"
        id="bidderApproval"
        [ngClass]="{'is-invalid': fc.bidderApproval.invalid && (fc.bidderApproval.dirty || fc.bidderApproval.touched)}">
        <option value="" disabled>Select . . .</option>
        <option value="Auto Approve">Auto Approve</option>
        <option value="Approve Manually">Approve Manually</option>
      </select>
      <div *ngIf="isSubmitted || fc.bidderApproval.invalid && (fc.bidderApproval.dirty || fc.bidderApproval.touched)">
        <p class="text-red-500 " *ngIf="fc.bidderApproval.errors?.required">
          Required</p>
      </div>
    </div>
    <div class="flex flex-row gap-3 mb-3">
      <div class="basis-1/2">
        <label for="startDate" class="text-gray-700  text-sm font-semibold leading-4">Start Date</label>
        <div class="flex w-full">
          <div class="input-group-append">
            <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
              id="basic-addon2"
              [ngClass]="{'border-danger': fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)}">
              <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
            </span>
          </div>
          <input type="text" ngbDatepicker [minDate]="minDate" #startDate="ngbDatepicker" id="startDate"
            (click)="startDate.toggle()"
            class=" bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
             formControlName="startDate"
            [ngClass]="{'is-invalid': fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)}">

        </div>
        <div *ngIf="isSubmitted || fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)">
          <p class="text-red-500 " *ngIf="fc.startDate.errors?.required">Required
          </p>
        </div>

      </div>
      <div class="basis-1/2 mb-3">
        <label for="startTime" class="text-gray-700  text-sm font-semibold leading-4">Start Time</label>
        <div class="flex w-full">
          <div class="input-group-append">
            <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
              id="basic-addon2"
              [ngClass]="{'border-danger': fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)}">
              <img src="assets/icons/time_light.svg" class="svg-icon-class c-pointer">
            </span>
          </div>

          <input type="text"
            class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
            ngbPopover [autoClose]="'outside'" id="startTime" placement="left" triggers="manual" #p1="ngbPopover"
            (click)="openTimepicker(p1, startTimeModal)" formControlName="startTime"
            [ngClass]="{'is-invalid': fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)}">

        </div>
        <div *ngIf="isSubmitted || fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)">
          <p class="text-red-500 " *ngIf="fc.startTime.errors?.required">Required</p>
          <p class="text-red-500 " *ngIf="fc.startTime.errors?.pattern">Invalid</p>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label for="antiSnipe" class="text-gray-700  text-sm font-semibold leading-4">Soft Close(Number Of
        minutes)</label>
      <select placeholder="Soft Close"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select"
        formControlName="antiSnipe" id="antiSnipe">
        <option value="" disabled>Select . . .</option>
        <option [value]="1">1</option>
        <option [value]="2">2</option>
        <option [value]="3">3</option>
        <option [value]="4">4</option>
        <option [value]="5">5</option>
        <option [value]="6">6</option>
        <option [value]="7">7</option>
        <option [value]="8">8</option>
        <option [value]="9">9</option>
        <option [value]="10">10</option>
        <option [value]="11">11</option>
        <option [value]="12">12</option>
        <option [value]="13">13</option>
        <option [value]="14">14</option>
        <option [value]="15">15</option>
      </select>

    </div>


    <div
      *ngIf="isSubmitted && fc.antiSnipe.invalid || fc.antiSnipe.invalid &&  (fc.antiSnipe.dirty || fc.antiSnipe.touched)">
      <p class="e-fielderror e-fielderror-required text-red-500 " *ngIf="fc.antiSnipe.errors?.min">Invalid</p>
      <p class="e-fielderror e-fielderror-required text-red-500 " *ngIf="fc.antiSnipe.errors?.max">Invalid</p>
    </div>

    <div class="row" hidden>
      <div class="col-5">Premium Auction :</div>
      <div class="col-2">
        <input type="radio" formControlName="premiumAuction" id="t1" [value]="true">
        <label class="ms-3">Yes</label><br>
      </div>
      <div class="col-2">
        <input type="radio" formControlName="premiumAuction" id="t2" [value]="false">
        <label class="ms-3">No</label><br>
      </div>
    </div>

    <div class="row mt-3" *ngIf="fc.auctionBiddingMethod.value == 'FORWARD_AUCTION'">
      <div class="col-5">Buy It Now (Pre Offer) :</div>
      <div class="col-2">
        <input type="radio" formControlName="preOffer" id="t5" [value]="true">
        <label class="ms-3">Yes</label><br>
      </div>
      <div class="col-2">
        <input type="radio" formControlName="preOffer" id="t6" [value]="false">
        <label class="ms-3">No</label><br>
      </div>
    </div>


    <div class="my-3">
      <label for="rankCurrentPriceConfig" class="text-gray-700  text-sm font-semibold leading-4">Display Rank - Market
        Price</label>
        <select placeholder="Display Rank - Market Price"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select"
          id="rankCurrentPriceConfig" formControlName="rankCurrentPriceConfig" (change)="changeRankConfig()"
          [ngClass]="{'is-invalid': fc.rankCurrentPriceConfig.invalid || fc.rankCurrentPriceConfig.invalid && (fc.rankCurrentPriceConfig.dirty || fc.rankCurrentPriceConfig.touched)}">
          <option value="" disabled>Select . . .</option>
          <option *ngFor="let item of rankPriceConfigs" [value]="item">{{getRankPriceConfigName(item)}}
          </option>
        </select>
        
      </div>
    

    <div class=" mt-3" *ngIf="isAllowToShowBidderHistoryConfig">
      <div class="text-gray-700  text-sm font-semibold leading-4">Show Bidder History To Bidder :</div>
      <div class="">
        <div class="row-flex flex mt-2">
          <div class="basis-3/12">
            <input type="radio" formControlName="showBidderHistory" id="m1" [value]="'ALWAYS'">
            <label for="m1" class="ms-3 text-gray-700  text-sm font-semibold leading-4">Always</label><br>
          </div>
          <div class="basis-3/12">
            <input type="radio" formControlName="showBidderHistory" id="m2" [value]="'NEVER'">
            <label for="m2" class="ms-3 text-gray-700  text-sm font-semibold leading-4">Never</label><br>
          </div>
          <div class="basis-6/12">
            <input type="radio" formControlName="showBidderHistory" id="m3" [value]="'AFTER_AUCTION_CLOSE'">
            <label for="m3" class="ms-3 text-gray-700  text-sm font-semibold leading-4 ">After Auction Close
            </label><br>
          </div>
        </div>
      </div>
    </div>

    <div class="row-flex flex gap-3 mt-3 mb-3">
      <div class="basis-7/12 text-gray-700  text-sm font-semibold leading-4">Mask Bidder Details:</div>
      <div class="basis-2/12">
        <input type="radio" formControlName="maskBidderDetails" id="m11" [value]="true">
        <label for="m11" class="ms-3 text-gray-700  text-sm font-semibold leading-4">Yes</label><br>
      </div>
      <div class="basis-2/12">
        <input type="radio" formControlName="maskBidderDetails" id="m22" [value]="false">
        <label for="m22" class="ms-3 text-gray-700  text-sm font-semibold leading-4">No</label><br>
      </div>
    </div>

    <div class="row-flex flex gap-3 mt-3 mb-3">
      <div class="basis-7/12 text-gray-700  text-sm font-semibold leading-4">Allow Edit While Auction is Upcoming:</div>
      <div class="basis-2/12">
        <input type="radio" formControlName="allowEditWhileUpcoming" id="allowEditWhileUpcoming1" [value]="true">
        <label for="allowEditWhileUpcoming1" class="ms-3">Yes</label><br>
      </div>
      <div class="basis-2/12">
        <input type="radio" formControlName="allowEditWhileUpcoming" id="allowEditWhileUpcoming2" [value]="false">
        <label for="allowEditWhileUpcoming2" class="ms-3">No</label><br>
      </div>
    </div>

    <div class="row-flex flex gap-3 mt-3 mb-3">
      <div class="basis-7/12 text-gray-700  text-sm font-semibold leading-4">Allow Edit While Auction is Running:</div>
      <div class="basis-2/12">
        <input type="radio" formControlName="allowEditWhileRunning" id="allowEditWhileRunning1" [value]="true">
        <label for="allowEditWhileRunning1" class="ms-3 text-gray-700  text-sm font-semibold leading-4">Yes</label><br>
      </div>
      <div class="basis-2/12 ">
        <input type="radio" formControlName="allowEditWhileRunning" id="allowEditWhileRunning2" [value]="false">
        <label for="allowEditWhileRunning2" class="ms-3 text-gray-700  text-sm font-semibold leading-4">No</label><br>
      </div>
    </div>

    <div class="row-flex flex gap-3" *ngIf="deploymentConfigurationDto?.enableBlockchain">
      <div class="basis-7/12 text-gray-700  text-sm font-semibold leading-4">Blockchain Enable :</div>
      <div class="basis-2/12">
        <input type="radio" formControlName="enableBlockchain" id="t10" [value]="true">
        <label for="t10" class="ms-3 text-gray-700  text-sm font-semibold leading-4">Yes</label><br>
      </div>
      <div class="basis-2/12">
        <input type="radio" formControlName="enableBlockchain" id="t12" [value]="false">
        <label for="t12" class="ms-3 text-gray-700  text-sm font-semibold leading-4">No</label><br>
      </div>
    </div>

    <div class="row-flex flex gap-3">
      <div class="basis-1/2">
        <div class="mt-3">
          <label for="profilePic1" class="text-gray-700  text-sm font-semibold leading-4">{{currentFile ? 'Replace
            Image' : 'Add Image'}}</label>
          <input type="text"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
            (click)="auctionImg.click()" id="profilePic1" [value]="this._fileDisplayName$ | async"
            [ngClass]="{'is-invalid': isSubmitted && !(this._fileDisplayName$ | async)}">

          <input #auctionImg name="profile_pic" onclick="this.value=null;" type="file" class="hidden"
            (change)="chooseFile($event)" id="profilePic2">


          <p class="text-red-500 " *ngIf="isSubmitted && !(this._fileDisplayName$ | async)">Required</p>
        </div>
      </div>
      <div class="basis-1/2">
        <div class="flex flex-row mt-3" *ngIf="imageUrl">
          <div class="basis-7/12">
            <img alt="" [src]="imageUrl" [ngClass]="isActiveImage ? 'modal-logo-image-large' : 'modal-logo-image'" />
          </div>
          <div class="basis-2/12 me-2 mt-3">
            <button
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              (click)="openViewImageModal(imageUrl)">
              <img src="assets/icons/view_dark.svg" class="svg-icon-class c-pointer" *ngIf="!isActiveImage">
              <img src="assets/icons/zoomout_dark.svg" class="svg-icon-class c-pointer" *ngIf="isActiveImage">
            </button>
          </div>
          <div class="basis-2/12 mt-3">
            <span (click)="deleteImage()" [ngClass]="{'disabled' : isLoading }">
              <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer inline-flex">
            </span>
          </div>
        </div>
      </div>
    </div>


    <div class="my-3">
      <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
        [message]="'Auction created successfully.'"></app-message-toast>
    </div>
    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
    </div>
    <div class="text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
      <button type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 "
        (click)="handleSubmit()">Add</button>
    </div>
  
  </form>
</div>


<!-- NGB TIME MODAL -->
<ng-template #startTimeModal>
  <ngb-timepicker [(ngModel)]="startTime" [meridian]="true"></ngb-timepicker>
  <button
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    (click)="setTime('startTime')">SAVE</button>
</ng-template>