import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { FileService } from 'src/app/shared/services/file.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import heic2any from "src/assets/plugins/heic2any";
import { AuctionLotSaveWrapperDto } from 'src/app/shared/models/user/AuctionLotSaveWrapperDto';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { FormControl, Validators } from '@angular/forms';
import { Pattern } from 'src/app/shared/util/Patterns';
import { DatePipe } from '@angular/common';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { RfxValidationErrorCodeDto } from 'src/app/shared/models/user/RfxValidationErrorCodeDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';

@Component({
  selector: 'app-rfx-subcategory-images',
  templateUrl: './rfx-subcategory-images.component.html',
  styleUrls: ['./rfx-subcategory-images.component.sass']
})
export class RfxSubcategoryImagesComponent implements OnInit, OnDestroy {

  _isOrderActive$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _isImageSelected$ = new BehaviorSubject<boolean>(false);
  _isImageActive$ = new BehaviorSubject<boolean>(false);

  subcategoryEntityDto?: RfxSubcategoryUiDto | null;
  rfxEntityDto?: RfxUiDto | null;


  imageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/heif'];
  videoTypes = ['mp4', 'webm', 'ogg'];
  fileUploadError: boolean = false;
  errorMsg: string | undefined;
  currentFileInfoDto?: FileInfoDto | null;
  imageUrl?: string | ArrayBuffer | SafeUrl | null;
  currentFile?: File | null;
  isDataLoading?: boolean = true;
  isLoading: boolean = false;

  currentIndex?: number

  subcategoryImages: Array<FileInfoDto> = [];
  currentMultipleFiles: Array<File> = [];
  currentMultipleFilesPath: Array<string | ArrayBuffer> = [];
  currentMultipleFilesType: Array<string> = [];
  activeImage?: number;
  imageType?: string;
  rfxValidationErrorCodeDtoList?: RfxValidationErrorCodeDto[];

  @ViewChild('subcategoryImageRef') subcategoryImageRef?: HTMLInputElement;
  @ViewChild('subcategoryImagesListRef') subcategoryImagesListRef?: ElementRef;

  selectedLotSubscription$?: Subscription;

  ctrlFileType: FormControl = new FormControl('IMAGE');
  ctrlVideoUrl: FormControl = new FormControl('', [Validators.pattern(Pattern.url)]);

  constructor(
    private ngbModal: NgbModal,
    private datePipe: DatePipe,
    private fileService: FileService,
    private rfxSubcategoryService: AdminRfxSubcategoryDataHolderService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService
  ) { }

  ngOnInit(): void {
    this.rfxEntityDto = this.adminSourcingEventsDataHolderService._selectedRfx$.value;

    this.selectedLotSubscription$ = this.rfxSubcategoryService.selectedRfxSubcategory$.subscribe((subcategoryEntityDto) => {
      if (subcategoryEntityDto) {
        this.subcategoryEntityDto = subcategoryEntityDto;

        if (this.subcategoryEntityDto.subcategoryImages) {
          this.subcategoryImages = [...this.subcategoryEntityDto.subcategoryImages];
        } else {
          this.subcategoryImages = []
        }
      }
    })
  }

  openAddModal(content: any) {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this.errorMsg = '';

    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openViewImageModal(imageUrl:any) {
    let modalRef = this.ngbModal.open(ImagePreviewComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.imageUrl=imageUrl
  }

  openSortingModal(content: any) {
    if (this.subcategoryEntityDto?.subcategoryImages) {
      this.subcategoryImages = [...this.subcategoryEntityDto.subcategoryImages];
    }

    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  closeModal() {
    this.ngbModal.dismissAll()
    this._isImageSelected$.next(false);
    this.clearMultiImages();
  }

  activeMultiImage(index: number) {
    if (this.activeImage == index) {
      this.activeImage = undefined;
    } else {
      this.activeImage = index;
    }
  }

  removeMultiImage(index: number) {
    this.currentMultipleFiles.splice(index, 1);
    this.currentMultipleFilesPath.splice(index, 1);

    if (this.currentMultipleFiles.length == 0) {
      this._isImageSelected$.next(false);
    }
  }

  clearMultiImages() {
    this.currentMultipleFiles = [];
    this.currentMultipleFilesPath = [];
    this.ctrlFileType.patchValue('IMAGE');
    this.ctrlVideoUrl.reset();
  }

  scrollLeft() {
    this.subcategoryImagesListRef!.nativeElement.scrollLeft -= 100;
  }

  scrollRight() {
    this.subcategoryImagesListRef!.nativeElement.scrollLeft += 100 ;
  }

  async chooseFile(event: any) {
    this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.imageTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this._showErrorToast$.next(true);
      this.currentFile = null;
      this.subcategoryImageRef?.setAttribute('value', '');

      return;
    }

    if (/image\/hei(c|f)/.test(this.currentFile!.type)) {
      let jpgBlob = await heic2any({ blob: this.currentFile!, toType: 'image/jpeg', quality: 0 });

      //Change the name of the file according to the new format
      let newName = this.currentFile?.name.replace(/\.[^/.]+$/, ".jpg");

      //Convert blob back to file
      let newFile: any = jpgBlob;
      newFile.lastModified = new Date();
      newFile.name = newName;
      this.currentFile = <File>newFile;
    }

    let reader = new FileReader();
    reader.readAsDataURL(this.currentFile!);
    reader.onload = (e) => {
      let size = (this.currentFile?.size! / 1024) / 1024;

      // Create New Image
      var newImage = new Image();
      newImage.src = e.target!.result as string;

      newImage.onload = (el) => {
        this.imageType = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
        if (size > 1) {
          var canvas = document.createElement("canvas");
          canvas.width = newImage.width;
          canvas.height = newImage.height;

          var ctx = canvas.getContext("2d");
          ctx?.drawImage(el.target as CanvasImageSource, 0, 0, canvas.width, canvas.height);
          var srcEncoded;
          if (size >= 5) {
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.1);
          } else {
            // size less then 5 MB
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.5);
          }

          ApplicationUtils.base64toFile(srcEncoded, this.currentFile!.name, this.currentFile!.type)
            .then((file: File) => {
              this.currentFile = file;
            })
        }
      }
      this.imageUrl = reader.result?.toString();
    }
  }

  // Upload Multiple Files
  chooseMultipleFiles(event: any) {
    let files = event.target.files;

    if (files.length > 0) {
      this._isImageSelected$.next(true);
    }

    for (const file of files) {
      this.processMultipleFiles(file);
    }
  }

  async processMultipleFiles(file: File) {
    this._showErrorToast$.next(false);
    let currentFile = file;

    if (!this.imageTypes.includes(file.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this._showErrorToast$.next(true);
      this.subcategoryImageRef?.setAttribute('value', '');

      return;
    }

    // Check if Image is HEIC/HEIF format.
    if (/image\/hei(c|f)/.test(currentFile.type)) {
      let jpgBlob = await heic2any({ blob: currentFile, toType: 'image/jpeg', quality: 0 });

      //Change the name of the file according to the new format
      let newName = currentFile.name.replace(/\.[^/.]+$/, ".jpg");

      //Convert blob back to file
      let newFile: any = jpgBlob;
      newFile.lastModified = new Date();
      newFile.name = newName;
      currentFile = <File>newFile;
    }

    let reader = new FileReader();
    reader.readAsDataURL(currentFile);
    reader.onload = (e) => {
      let size = (currentFile.size! / 1024) / 1024;

      // Create New Image
      var newImage = new Image();
      newImage.src = e.target!.result as string;

      newImage.onload = (el) => {
        let imageType = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
        if (size > 1) {
          var canvas = document.createElement("canvas");
          canvas.width = newImage.width;
          canvas.height = newImage.height;

          var ctx = canvas.getContext("2d");
          ctx?.drawImage(el.target as CanvasImageSource, 0, 0, canvas.width, canvas.height);
          var srcEncoded;
          if (size >= 5) {
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.1);
          } else {
            // size less then 5 MB
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.5);
          }

          ApplicationUtils.base64toFile(srcEncoded, currentFile.name, currentFile.type)
            .then((file: File) => {
              currentFile = file;

              this.currentMultipleFiles.push(currentFile);
              this.currentMultipleFilesPath.push(reader.result!);
              this.currentMultipleFilesType.push(imageType);
            })
        } else {
          this.currentMultipleFiles.push(currentFile);
          this.currentMultipleFilesPath.push(reader.result!);
          this.currentMultipleFilesType.push(imageType);
        }
      }
    }
  }

  uploadMultiFiles() {
    if (!this.isAllowToEdit()) {
      return;
    }

    if (this.currentMultipleFiles.length == 0) {
      return;
    }

    this.fileUploadError = false;
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let metaDataList: string[] = [];
    let formData = new FormData();

    for (let i = 0; i < this.currentMultipleFiles.length; i++) {
      let currentFile = this.currentMultipleFiles[i];

      let metaData = {
        'mimeType': currentFile.type,
        'version': 0,
        'publicApi': true,
        'dataType': this.currentMultipleFilesType[i]
      };

      metaDataList.push(JSON.stringify(metaData));
      formData.append("files", currentFile);
    }

    formData.append("metaDataList", JSON.stringify(metaDataList));

    this.fileService.uploadMultipleFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          if (apiResponseDto.data) {
            for (const fileInfoDto of apiResponseDto.data as FileInfoDto[]) {
              fileInfoDto.displayName = fileInfoDto.fileName;
              this.subcategoryImages.push(fileInfoDto!);
            }
          }

          this.saveSubcategoryImages();
        }
      } else {
        this.fileUploadError = true;
      }
    })
  }

  saveSubcategoryImages() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);

    let rfxSubcategoryEntityDto = ApplicationUtils.clone(this.subcategoryEntityDto) as RfxSubcategoryUiDto;

    if(!rfxSubcategoryEntityDto.subcategoryImages) {
       rfxSubcategoryEntityDto.subcategoryImages = [];
    }

    rfxSubcategoryEntityDto.subcategoryImages = this.subcategoryImages;

    this.isLoading = true;
    this.rfxSubcategoryService.saveRfxSubcategoryDetails(rfxSubcategoryEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let rfxSubcategorySaveWrapperDto = apiResponseDto.data  as AuctionLotSaveWrapperDto;
          let rfxValidationErrorCodeDtoList =  rfxSubcategorySaveWrapperDto.auctionValidationErrorCodeDtos;

          if(!rfxValidationErrorCodeDtoList ||  rfxValidationErrorCodeDtoList.length == 0){
            this._showSuccessToast$.next(true);
            this.isLoading = false;
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.isDataLoading = false;
              this._isOrderActive$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLoading = false;
            this.isDataLoading = false;
            this.closeModal();

            this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
            this.isDataLoading = false;
            this._isOrderActive$.next(false);
        }
      },
      error: (err) => {
        console.error(err);
        if (this.ctrlFileType.value == 'VIDEO') {
          this.errorMsg = "Error while linking video. Try again.";
        } else {
          this.errorMsg = this.currentMultipleFiles.length > 1 ? "Error while saving images. Try again." : "Error while saving image. Try again.";
        }

        this._showErrorToast$.next(true);
        this.isLoading = false;
        this.isDataLoading = false;
        this._isOrderActive$.next(false);
      }
    })
  }

  deleteImage(index: number) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.currentIndex = index;
    this.isDataLoading = true;

    let rfxSubcategoryEntityDto = ApplicationUtils.clone(this.subcategoryEntityDto) as RfxSubcategoryUiDto;
    rfxSubcategoryEntityDto?.subcategoryImages?.splice(index, 1);
    this.subcategoryImages?.splice(index, 1);

    this.rfxSubcategoryService.saveRfxSubcategoryDetails(rfxSubcategoryEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto) {
          if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
            this.isDataLoading = false;
          } else {
            this.isDataLoading = false;
          }
        } else {
          this.isDataLoading = false;
        }
        this.currentIndex = undefined;
      },
      error: (err) => {
        this.isDataLoading = false;
        this.currentIndex = undefined;
      }
    })
  }

  uploadVideoFile() {
    if (!this.isAllowToEdit()) {
      return;
    }

    let videoUrl = this.ctrlVideoUrl.value;
    if (videoUrl && this.isValidUrl(videoUrl)) {
      console.log(videoUrl);

      let currentDate = new Date();
      let fileType: string = ApplicationUtils.getUrlExtension(videoUrl);

      let fileInfoDto = new FileInfoDto();
      fileInfoDto.fileId = videoUrl;
      fileInfoDto.dataType = 'Rectangle';
      fileInfoDto.displayName = videoUrl;
      fileInfoDto.fileName = videoUrl;
      fileInfoDto.fileType = this.videoTypes.includes(fileType.toLowerCase()) ? fileType : 'embed';
      fileInfoDto.uploadDate = this.datePipe.transform(currentDate, 'dd/MM/yyyy hh:mm a')!;

      if (!this.subcategoryEntityDto?.subcategoryImages) {
        this.subcategoryEntityDto!.subcategoryImages = [];
      }

      this.subcategoryImages?.push(fileInfoDto);
      this.saveSubcategoryImages();
    }
  }

  isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString));
    }
    catch (e) {
      return false;
    }
  }

  getImageUrl(item: any) {
    if (this.isValidUrl(item.fileId)) {
      return ApplicationUtils.getVideoThumbnailFromUrl(item.fileId);
    }
    return `/downloadLandingBlob?fileId=${item?.fileId}`;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.subcategoryImages!, event.previousIndex, event.currentIndex);
  }

  ngOnDestroy(): void {
    if (this.selectedLotSubscription$) {
      this.selectedLotSubscription$.unsubscribe();
    }
  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.rfxValidationErrorCodeDtoList;
  }

  isAllowToDeleteLastImage() {
    if (this.subcategoryEntityDto?.active && this.subcategoryEntityDto.subcategoryImages?.length == 1) {
      return false;
    }
    return true;
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT && !this.rfxEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.rfxEntityDto?.status == RfxStatus.LIVE && !this.rfxEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}
