import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { EChartsOption, init } from 'echarts';
import { BehaviorSubject } from 'rxjs';
import { AuctionDashboardDto } from 'src/app/shared/models/AuctionDashboardDto';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-analytics-lot-activity',
  templateUrl: './analytics-lot-activity.component.html',
  styleUrls: ['./analytics-lot-activity.component.sass']
})
export class AnalyticsLotActivityComponent implements OnInit {

  @Input() auctionDashboardDto$ = new BehaviorSubject<AuctionDashboardDto|undefined>(undefined);

  auctionDashboardDto?: AuctionDashboardDto;

  chartOptionLotActivity: EChartsOption = {}
  public screenWidth: any;
  isChartLoading: boolean = true

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(private changeDetectRef: ChangeDetectorRef){}


  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    this.auctionDashboardDto$.subscribe(data => {
      if (data) {
        this.auctionDashboardDto = data;
        this.loadLotActivityChart();
      }
    })

  }


  loadLotActivityChart() {
    let lotDashboardDtoList = this.auctionDashboardDto?.lotDashboardDtoList ?? [];
    lotDashboardDtoList.sort((a, b) => Number(a.lotSequence) - Number(b.lotSequence));

    let colors = this.randomColorsList(lotDashboardDtoList.length)

    this.chartOptionLotActivity = {
      xAxis: {
        max: 'dataMax',
        //name: '',
        nameLocation: 'middle',
        nameGap: 40,
        minInterval: 2,

      },
      yAxis: {
        type: 'category',
        data: lotDashboardDtoList.map(item => `#${item.lotSequence!}`),
        inverse: true,
        animationDuration: 300,
        animationDurationUpdate: 300,
        nameLocation: 'middle',
        nameGap: this.screenWidth < 768 ? 22 : 40,

      },
      title: {
        text: 'X Axis: No. of Lot Activity, Y Axis: Lot sequence',
        borderWidth: 2,
        borderColor: '#ccc',
        padding: 10,
        textStyle: {
          fontSize: 12,
          fontWeight: "normal"
        },

      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          let item = lotDashboardDtoList[params[0].dataIndex]
          return `#${item.lotSequence} ${item.lotName}<br>
          No Of Lot Activity: ${item.noOfLotActivity}`;
        }
      },
      series: [
        {
          realtimeSort: true,
          type: 'bar',
          data: lotDashboardDtoList.map(item => item.noOfLotActivity!),
          label: {
            show: true,
            color: 'black',
            offset: [30, 0],
            valueAnimation: true
          },
          barWidth: 30,
          itemStyle: {
            color: function (param) {
              return colors[param.dataIndex] || '#5470c6';
            }
          },
        }
      ],
      legend: {
        show: true
      },
      animationDuration: 0,
      animationDurationUpdate: 3000,
      animationEasing: 'linear',
      animationEasingUpdate: 'linear'
    };

    this.isChartLoading = false
    this.changeDetectRef.detectChanges()
    this.resizeCanvasHeight('chartOptionLotActivity', lotDashboardDtoList.map(item => item.noOfLotActivity!).length)
  }
  
  randomColorsList(length: number): string[] {
    const colors = new Set();
  
    while (colors.size < length) {
      const color = ApplicationUtils.getRandomLightColorHex();
      colors.add(color);
    }
  
    return Array.from(colors) as string[];
  }

  resizeCanvasHeight(chartId: string, dataLength: number) {
    if (dataLength > 8) {
      let myChart = init(document.getElementById(chartId)!);
      let desiredHeight = dataLength * 42;
      document.getElementById(chartId)!.style.height = desiredHeight + 'px';
      myChart.resize();
    }
  }

}


