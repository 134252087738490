<div class="w-full">
  <div class="h-screen">
    <div class=" px-8 py-3 tr-overflow-y-drawer">
      <div class="w-4/5 mb-10">
        <div class="cursor-pointer" (click)="closeQuestionDrawer()">
          <i-lucide name="arrow-left" [size]="18" class="my-icon inline-block "></i-lucide>
          <span class="text-xs font-semibold text-black">back to the sections</span>
        </div>
        <div class="flex flex-row gap-3 items-center justify-between  mb-4">
          <div class="basis-9/12">
            <div class="text-base font-semibold text-black">
              {{tenderSectionTemplateDto.sectionName}}
            </div>
          </div>
          <div class="basis-3/12 text-end">
            <div class="border border-color-200 rounded-lg p-2">
              <div class="flex flex-row items-center">
                <div class="basis-1/2 text-start">
                  <div class="text-xs text-dark font-normal">
                    Score
                  </div>
                  <div class="text-black text-base font-semibold">
                    {{tenderSectionTemplateDto.sectionScore || 0}}
                  </div>
                </div>
                <span class="border-e border-gray-200 h-8 mx-2"></span>
                <div class="basis-1/2 text-start">
                  <div class="text-xs text-dark font-normal">
                    Questions
                  </div>
                  <div class="text-black text-base font-semibold">
                    {{tenderSectionTemplateDto.noOfQuestions || 0}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-3 mb-4">
          <div class="col-span-6 text-dark text-base font-semibold">
            Questions
          </div>
          <div class="col-span-6 text-end">
            <button (click)="toggleShowNewQuestion()"
              class=" py-1.5 px-3 inline-flex text-xs font-medium text-gray-500 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
              <i-lucide name="plus" [size]="16" color="#000" class="my-icon me-1 inline-flex"></i-lucide>
              <span class="text-black text-xs font-medium">Questions</span>
            </button>
          </div>
        </div>
    
        <div class="grid py-4 items-center justify-center"
          *ngIf="!isShowNewQuestion && tenderTechnicalQuestionTemplateDtos.length == 0">
          <img src="assets/images/tender-question-2.svg" class=" h-16 w-16 mx-auto my-0">
          <div class="text-xs font-normal text-gray-800 mt-2">
            No questions added yet.
          </div>
        </div>
    
        <div class="border border-bg-200 rounded-lg shadow-xl mb-4" *ngIf="isShowNewQuestion">
          <div class="bg-gray-100 text-black text-xs font-normal h-6 items-center flex px-3">
            Add multiple sections by pasting titles here (e.g., ‘Section 1: Title’). Use line breaks between sections
          </div>
          <div class="p-4">
            <textarea type="text"
              class="bg-white w-full rounded-md flex-col self-stretch border-none h-16 px-2 py-0 text-gray-700 text-sm"
              id="description" rows="3" pInputTextarea [formControl]="ctrlQuestionsName">
          </textarea>
          </div>
          <div class="flex flex-row justify-end h-8 items-center">
            <div>
              <button type="button" [disabled]="ctrlQuestionsName.invalid" (click)="addNewQuestions()"
              class="inline-flex items-center py-1.5 px-3 text-sm font-medium text-blue-700 focus:outline-none bg-white rounded-lg border border-blue-500 hover:bg-blue-100 hover:text-blue-800 focus:z-10 focus:ring-4 focus:ring-blue-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 me-3 mb-3">
              <i-lucide name="plus" [size]="16" color="#004aad" class="my-icon inline-flex"></i-lucide>    Add Questions
              </button>
              <button type="button" (click)="hideAddNewQuestion()"
              class="inline-flex items-center py-1.5 px-3 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-800 focus:z-10 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 me-3 mb-3">
              <i-lucide name="plus" [size]="16" color="#000" class="my-icon inline-flex"></i-lucide>  Cancel
              </button>
            </div>
          </div>
        </div>
    
        <div [formGroup]="formGroup" *ngIf="!isShowNewQuestion && tenderTechnicalQuestionTemplateDtos.length > 0">
          <div formArrayName="questions">
            <div class="flex flex-row items-center mb-3"
              *ngFor="let item of technicalQuestionsFormArray.controls; index as i">
              <div class="w-6">
                <i-lucide name="grip-vertical" [size]="18" class="my-icon "></i-lucide>
                <div class="w-5 h-6 bg-gray-200 rounded ps-1 mt-1">
                  {{item.get('sequenceNo')?.value}}
                </div>
              </div>
              <div class="w-full" [formGroupName]="i">
                <div class="border border-gray-200 rounded-lg ">
                  <div class="flex flex-row justify-between items-center ps-3 py-1">
                    <div class="basis-9/12">
                      <div class="text-gray-800 font-normal text-sm">
                        {{item.get('responseType')?.value}}
                      </div>
                      <div class="text-balck font-semibold text-sm">
                        {{item.get('questionText')?.value}}
                      </div>
                    </div>
                    <div class="basis-3/12 text-end">
                      <div class="flex flex-row items-center">
                        <div class="basis-2/4 ">
                          <div class="inline-flex items-center me-2">
                            <span class="pt-2 me-2">
                              <p-inputSwitch inputId="responseMandatory"
                                formControlName="responseMandatory"></p-inputSwitch>
                            </span>
                            <label for="responseMandatory"> Required</label>
                          </div>
                        </div>
                        <span class="border-e border-gray-200 h-8"></span>
                        <div class="basis-1/4 text-center">
                          Score
                          <div class="border mx-3 rounded-lg border-gray-200 py-.5 px-2 mt-1">
                            <div class="text-black text-base font-semibold">
                              {{item.get('score')?.value || 0}}
                            </div>
                          </div>
                        </div>
                        <span class="border-e border-gray-200 h-8"></span>
                        <div class="basis-1/4 text-center">
                          <span class="c-pointer" (click)="confirmRemoveQuestion($event, i)">
                            <i-lucide name="trash-2" [size]="18" class="my-icon "></i-lucide>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="border border-gray-200 px-0"></div>
                  <div class="grid grid-cols-1 w-full mb-4 p-3">
                    <label for="materialId" class="text-gray-700 text-xs font-medium leading-4">
                      Question
                    </label>
                    <input
                      class="bg-white w-full rounded-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 text-gray-800 text-xs"
                      type="text" formControlName="questionText" id="questionText" placeholder="Text name">
                      <div
                      *ngIf="item.get('questionText')?.invalid && (item.get('questionText')?.dirty || item.get('questionText')?.touched)">
                      <p class="text-red-500 " *ngIf="item.get('questionText')?.errors?.['required']">Required
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between items-center w-full mb-4 p-3">
                    <div class="basis-4/12">
                      <label for="materialId" class="text-gray-700 text-xs font-medium leading-4">
                        Response type
                      </label>
                      <select #responseType name="Tender type" id="tenderType" formControlName="responseType"
                        (change)="onChangeResponseType(i, responseType.value)"
                        class="bg-white w-full rounded-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 text-gray-800 text-xs form-select">
                        <option value="" selected disabled>Select . . .</option>
                        <option value="SINGLE_CHOICE">Single Choice Response</option>
                        <option value="MULTI_CHOICE">Multi Choice Response</option>
                        <option value="DATE">Date Response</option>
                        <option value="DATE_RANGE">Date Range Response</option>
                        <option value="ALPHANUMERIC">Alphanumeric Response</option>
                      </select>
                    </div>
                    <div class="basis-7/12 text-end">
                      <div class="flex flex-row items-center">
                        <div class="basis-1/2 text-end ">
                          <div class="inline-flex items-center me-2">
                            <span class="pt-2 me-1">
                              <p-inputSwitch inputId="bidderCommentsRequired" formControlName="bidderCommentsRequired">
                              </p-inputSwitch>
                            </span>
                            <label for="bidderCommentsRequired" class="text-xs text-black font-normal">
                              Bidder comments required
                            </label>
                          </div>
                        </div>
                        <span class="border-e border-gray-200 h-8 mx-2"></span>
                        <div class="basis-1/2 text-end">
                          <div class="inline-flex items-center ms-2 cursor-pointer">
                            <span class=" me-1" *ngIf="!item.get('sampleFile')?.value">
                              <i-lucide name="upload" [size]="18" class="my-icon "></i-lucide>
                            </span>
                            <label class="text-xs text-black font-normal"  *ngIf="!item.get('sampleFile')?.value"  (click)="openAddDocumentDialog(i)">
                              {{'Upload sample document'}}
                            </label>
                            <label class="text-xs text-black font-normal"  *ngIf="item.get('sampleFile')?.value">
                              {{item.get('sampleFile')?.value.fileName}}
                            </label>
                            <span class=" me-1" *ngIf="item.get('sampleFile')?.value" (click)="removeSampleDoc(i)">
                              <i-lucide name="trash-2" [size]="18" class="my-icon "></i-lucide>
                            </span>
                          </div>
                        </div>
                        <!-- <div class="basis-1/2 text-end">
                          <div class="inline-flex items-center ms-2">
                            <span class="me-1" *ngIf="!item.get('sampleFile')?.value">
                              <i-lucide name="upload" [size]="18" class="my-icon"></i-lucide>
                            </span>
                            <label class="text-xs text-black font-normal cursor-pointer">
                              <span *ngIf="!item.get('sampleFile')?.value" (click)="openAddDocumentDialog(i)">
                                Upload sample document
                              </span>

                            </label>
                            <span class="me-1" *ngIf="item.get('sampleFile')?.value" (click)="removeSampleDoc(i)">
                              <i-lucide name="trash-2" [size]="18" class="my-icon"></i-lucide>
                            </span>
                          </div>
                        </div> -->
                        
    
                      </div>
                    </div>
                  </div>
    
                  <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-6 text-sm text-gray-600 font-medium p-3">
                      Response Choice
                    </div>
                    <div class="col-span-6 text-end mr-3" *ngIf="item.get('responseType')?.value == 'SINGLE_CHOICE' || item.get('responseType')?.value == 'MULTI_CHOICE'">
                      <button (click)="addNewQuestionOption(i)"
                        class=" py-1.5 px-3 inline-flex text-xs font-medium text-gray-500 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        <i-lucide name="plus" [size]="16" color="#000" class="my-icon me-1 inline-flex"></i-lucide>
                        <span class="text-black text-xs font-medium">Add Option</span>
                      </button>
                    </div>
                  </div>
    
                  <!-- <div class="text-sm text-gray-600 font-medium p-3">
                    Response Choice
                  </div> -->
                  <div class="border border-gray-200 px-3"></div>
    
                  <!-- <div formArrayName="questionOptions">
                    <div class="flex flex-row items-center w-full gap-2 mb-4 p-3"
                      *ngFor="let questionOption of questionOptionsFormArray(i).controls; index as j" [formGroupName]="j">
                      <div class="">
                        <i-lucide name="grip-vertical" [size]="18" class="my-icon "></i-lucide>
                      </div>
                      <div class="basis-7/12">
                        <input
                          *ngIf="item.get('responseType')?.value == 'SINGLE_CHOICE' || item.get('responseType')?.value == 'MULTI_CHOICE'"
                          class="bg-white w-full rounded-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 text-gray-800 text-xs"
                          type="text" formControlName="optionText" id="optionText" placeholder="Text name"
                          [ngClass]="{'border-danger': questionOption.get('optionText')?.invalid && (questionOption.get('optionText')?.dirty || questionOption.get('optionText')?.touched)}">
                        <div
                          *ngIf="questionOption.get('optionText')?.invalid && (questionOption.get('optionText')?.dirty || questionOption.get('optionText')?.touched)">
                          <p class="text-red-500 " *ngIf="questionOption.get('optionText')?.errors?.['required']">Required
                          </p>
                        </div>
                      </div>
                      <div class="basis-2/12">
                        <input
                          class="bg-white w-full rounded-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 text-gray-800 text-xs"
                          type="text" formControlName="questionOptionScore" id="questionOptionScore" placeholder="Score:"
                          [ngClass]="{'border-danger': questionOption.get('questionOptionScore')?.invalid && (questionOption.get('questionOptionScore')?.dirty || questionOption.get('questionOptionScore')?.touched)}">
                        <div
                          *ngIf="questionOption.get('questionOptionScore')?.invalid && (questionOption.get('questionOptionScore')?.dirty || questionOption.get('questionOptionScore')?.touched)">
                          <p class="text-red-500 " *ngIf="questionOption.get('questionOptionScore')?.errors?.['required']">
                            Required
                          </p>
                        </div>
                      </div>
                      <div class="basis-3/12 text-end">
                        <div class="inline-flex items-center me-2">
                          <span class="pt-2 me-1">
                            <p-inputSwitch inputId="docRequired" formControlName="uploadRequired"></p-inputSwitch>
                          </span>
                          <label for="docRequired" class="text-xs text-black font-normal">Documents required</label>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div formArrayName="questionOptions">
                    <div class="flex flex-row items-center w-full gap-2 mb-4 p-3"
                      *ngFor="let questionOption of questionOptionsFormArray(i).controls; index as j" [formGroupName]="j">
                  
                      <!-- Grip Icon -->
                      <div>
                        <i-lucide name="grip-vertical" [size]="18" class="my-icon"></i-lucide>
                      </div>
                  
                      <!-- Option Text Input -->
                      <div class="basis-7/12">
                        <input
                          *ngIf="item.get('responseType')?.value == 'SINGLE_CHOICE' || item.get('responseType')?.value == 'MULTI_CHOICE'"
                          class="bg-white w-full rounded-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 text-gray-800 text-xs"
                          type="text" formControlName="optionText" id="optionText" placeholder="Text name"
                          [ngClass]="{'border-danger': questionOption.get('optionText')?.invalid && (questionOption.get('optionText')?.dirty || questionOption.get('optionText')?.touched)}">
                  
                        <!-- Error Message -->
                        <div
                          *ngIf="questionOption.get('optionText')?.invalid && (questionOption.get('optionText')?.dirty || questionOption.get('optionText')?.touched)">
                          <p class="text-red-500" *ngIf="questionOption.get('optionText')?.errors?.['required']">Required</p>
                        </div>
                      </div>
                  
                      <!-- Option Score Input -->
                      <div class="basis-2/12">
                        <input
                          class="bg-white w-full rounded-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 text-gray-800 text-xs"
                          type="text" formControlName="questionOptionScore" id="questionOptionScore" placeholder="Score:"
                          [ngClass]="{'border-danger': questionOption.get('questionOptionScore')?.invalid && (questionOption.get('questionOptionScore')?.dirty || questionOption.get('questionOptionScore')?.touched)}">
                  
                        <!-- Error Message -->
                        <div
                          *ngIf="questionOption.get('questionOptionScore')?.invalid && (questionOption.get('questionOptionScore')?.dirty || questionOption.get('questionOptionScore')?.touched)">
                          <p class="text-red-500" *ngIf="questionOption.get('questionOptionScore')?.errors?.['required']">Required</p>
                        </div>
                      </div>
                  
                      <!-- Document Required Toggle -->
                      <div class="basis-3/12 text-end">
                        <div class="inline-flex items-center me-2">
                          <span class="pt-2 me-1">
                            <p-inputSwitch inputId="docRequired" formControlName="uploadRequired"></p-inputSwitch>
                          </span>
                          <label for="docRequired" class="text-xs text-black font-normal">Documents required</label>
                        </div>
                      </div>
                  
                      <!-- Delete Icon (Visible only for newly added options) -->
                      <div class="text-end">
                        <i-lucide *ngIf="j > 1" name="trash-2" [size]="18" class="my-icon cursor-pointer"
                          (click)="removeQuestionOption(i, j)"></i-lucide>
                      </div>
                    </div>
                  </div>
    
                </div>
              </div>
    
            </div>
          </div>
        </div>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="showSuccessToast$ | async" [message]="'Technical questions saved successfully.'">
        </app-message-toast>
        <app-message-toast *ngIf="showErrorToast$ | async" [isError]="true" [message]="errorMessage$ | async">
        </app-message-toast>
      </div>
  
      <div class="mb-3" *ngIf="isLoading$ | async">
        <div class="e-spinner"></div>
      </div>

    </div>
    </div>
    <div class="footer">
      <div class="flex flex-row justify-end h-8 my-2">
        <div class=" text-end  pe-7">
          <button [disabled]="isLoading$ | async"
            class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 me-3" (click)="closeQuestionDrawer()">Cancel</button>
          <button [disabled]="isLoading$ | async"
            class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800" (click)="saveQuestion()">Save</button>
        </div>
      </div>
    </div>
</div>

<p-confirmDialog [style]="{width: '20vw'}"></p-confirmDialog>

<p-dialog header="Select Documents" [(visible)]="isShowFeeDocumentDialog" [style]="{width: '50vw'}" [modal]="true" [draggable]="false"
  [autoZIndex]="true">
  <div>
    <div class="grid grid-cols-12 mb-4">
      <div class="col-span-6">
        <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
          <div class="text-gray-700 text-sm font-semibold leading-4">Document Title</div>
        </label>

        <input
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-700 text-sm"
          type="text" [formControl]="docTitle" id="title" placeholder="---"
          [ngClass]="{'is-invalid': docTitle.invalid && (docTitle.dirty || docTitle.touched)}">
        <div *ngIf="docTitle.invalid && (docTitle.dirty || docTitle.touched)">
          <p class="text-red-500 " *ngIf="docTitle.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="docTitle.errors?.['maxlength']">max 250 character</p>
        </div>
      </div>
    </div>

    <!-- <div class="grid grid-cols-12 mb-4">
      <div class="col-span-6">
        <label for="materialId" class="text-gray-700 text-sm font-semibold leading-4">
          <div class="text-gray-700 text-sm font-semibold leading-4">Description</div>
        </label>
        <textarea type="text" type="text" [formControl]="docDescription" id="title" placeholder="---"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400  px-2 py-1 text-gray-700 text-sm"
          id="description" rows="5"
          [ngClass]="{'is-invalid': docDescription.invalid && (docDescription.dirty || docDescription.touched)}"></textarea>
        <div *ngIf="docDescription.invalid && (docDescription.dirty || docDescription.touched)">
          <p class="text-red-500 " *ngIf="docDescription.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="docDescription.errors?.['maxlength']">max 1000 character</p>
        </div>
      </div>
    </div> -->
    <div class="grid grid-cols-2 w-full gap-2 mb-4">
      <div class="">
        <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
          <div class="text-gray-700 text-sm font-semibold leading-4">Select Document</div>
        </label>
        <div class="">
          <input id="documentDoc1" type="text" (click)="documentDoc.click()" [formControl]="docFile"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-700 text-sm"
            readonly />
          <input id="documentDoc2" #documentDoc onclick="this.value=null;" type="file" (change)="chooseFile($event)"
            class="hidden" />
          <div *ngIf="docFile.invalid && (docFile.dirty || docFile.touched)">
            <p class="text-red-500 " *ngIf="docFile.errors?.['required']">Required</p>
          </div>
        </div>
      </div>
      <div class="">
        <div class="grid grid-cols-4 w-full gap-2 items-center">
          <div class="col-span-2 mt-2">
          </div>
          <div class=" md:me-2 mt-3">
            <button
              class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-600 font-medium rounded text-sm p-1 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
              *ngIf="currentFileInfoDto" (click)="previewRfxDoc('SERVER')">
              <span> <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class"></span>
            </button>
            <button
              class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-600 font-medium rounded text-sm p-1 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
              *ngIf="currentFile && !currentFileInfoDto" (click)="previewRfxDoc('LOCAL')">
              <span> <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class"></span>
            </button>
          </div>
          <!-- <div class=" mt-3">
            <span (click)="removeRfxDoc()">
              <span> <img src="assets/icons/delete_danger.svg" class=" c-pointer svg-icon-class" ></span>
            </span>
          </div> -->
          <div class="mt-3" *ngIf="currentFile || !currentFileInfoDto">
            <span (click)="removeDoc()">
              <span>
                <img src="assets/icons/delete_danger.svg" class="c-pointer svg-icon-class"
                  [class.disabled]="!currentFile">
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr class="hr my-3">

    <div class="text-start ">

      <div class="mb-4" *ngIf="isLoadingModal">
        <div class="e-spinner"></div>
      </div>
      <div class="text-end gap-2 " *ngIf="!isLoadingModal && !(_showSuccessToastModal$ | async)">
        <button
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          (click)="uploadFile()">Save</button>
      </div>
    </div>
  </div>
</p-dialog>
