import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { EntityActionLabelEnum } from 'src/app/shared/enums/EntityActionLabelEnum';
import { EntityActionTypeEnum } from 'src/app/shared/enums/EntityActionTypeEnum';
import { EventEnum } from 'src/app/shared/enums/EventEnum';
import { TenderOpeningType } from 'src/app/shared/enums/TenderOpeningType';
import { EntityActionDto } from 'src/app/shared/models/EntityActionDto';
import { TechnicalGradationDto } from 'src/app/shared/models/rfx/TechnicalGradationDto';
import { TenderQuestionnaireTemplateDto } from 'src/app/shared/models/tender/TenderQuestionnaireTemplateDto';
import { TenderQuestionnaireWrapperDto } from 'src/app/shared/models/tender/TenderQuestionnaireWrapperDto';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { TenderManagementService } from 'src/app/shared/services/tender-management.service';
import { TenderManagementActions } from 'src/app/shared/state-management/tender/tender.actions';
import { selectEntityActionDtos, selectTenderOpportunityWrapperDto, selectTenderWrapperUiDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { multipleOfFiveValidator } from 'src/app/shared/validators/multiple-of-five.validator';

@Component({
  selector: 'app-tender-questionnaire-drawer',
  templateUrl: './tender-questionnaire-drawer.component.html',
  styleUrls: ['./tender-questionnaire-drawer.component.sass']
})
export class TenderQuestionnaireDrawerComponent implements OnInit, AfterViewInit, OnDestroy {
  formGroup: FormGroup;

  tenderWrapperUiDto?: TenderWrapperUiDto;
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;
  tenderQuestionnaireWrapperDto?: TenderQuestionnaireWrapperDto;
  tenderQuestionnaireTemplateDto?: TenderQuestionnaireTemplateDto;
  technicalGradationDtos: Array<TechnicalGradationDto> = [];

  TenderOpeningType: typeof TenderOpeningType = TenderOpeningType;
  applicationConstants: typeof ApplicationConstants = ApplicationConstants;

  tenderOpeningType?: TenderOpeningType;
  isLoading: boolean = false;
  errorMsg: string | undefined;

  selectOptionScoring: any[] = [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' }
  ]

  selectOptionGradation: any[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ]

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  tenderWrapperUiDtoSubscription$?: Subscription;
  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private drawerService: DrawerService,
    private tenderManagementService: TenderManagementService,
  ) {
    this.formGroup = this.fb.group({
      scoringTemplate: ['NO'],
      maximumScore: [''],
      // range: [''],
      qualifyingScore: [''],
      technicalWeightage: ['', Validators.pattern(Pattern.percentage)],
      financialWeightage: ['', Validators.pattern(Pattern.percentage)],
      // gradationSystem: [false],
      // technicalGradationDtos: this.fb.array([]),
    }, { validator: this.qualifyingScoreValidator},)
  }

  ngOnInit(): void {
    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
        this.tenderQuestionnaireWrapperDto = this.tenderOpportunityWrapperDto?.tenderQuestionnaireWrapperDto;
        this.tenderOpeningType = this.tenderOpportunityWrapperDto?.tenderOpeningType;

        this.tenderOpeningTypeValidation();

        if (this.tenderQuestionnaireWrapperDto) {
          this.tenderQuestionnaireTemplateDto = this.tenderQuestionnaireWrapperDto.tenderQuestionnaireTemplateDto;
          this.populateFormGroup();
          
        }

      } else {
        this.tenderOpportunityWrapperDto = undefined;
        this.tenderQuestionnaireTemplateDto = undefined;
      }
    })
  }

  ngAfterViewInit(): void {
    // this.formGroup.controls['gradationSystem'].valueChanges.subscribe(() => {
    //   this.runScoringValidation();
    // })

    this.formGroup.controls['scoringTemplate'].valueChanges.subscribe(() => {
      this.runScoringValidation();
    })


  }

  get fc() { return this.formGroup.controls; }

  populateFormGroup() {
    this.formGroup.controls['maximumScore'].patchValue(this.tenderQuestionnaireTemplateDto?.maximumScore);
    this.formGroup.controls['qualifyingScore'].patchValue(this.tenderQuestionnaireTemplateDto?.qualifyingScore);

    if (this.tenderQuestionnaireTemplateDto?.templateId) {
      this.formGroup.controls['scoringTemplate'].patchValue(this.tenderQuestionnaireTemplateDto?.scoringTemplate);
    } else {
      this.formGroup.controls['scoringTemplate'].patchValue('');
    }
     //run Scoring Validation  
     this.runScoringValidation();

    this.formGroup.controls['technicalWeightage'].patchValue(this.tenderQuestionnaireTemplateDto?.technicalWeightage)
    this.formGroup.controls['financialWeightage'].patchValue(this.tenderQuestionnaireTemplateDto?.financialWeightage)
    // this.formGroup.controls['gradationSystem'].patchValue(this.tenderQuestionnaireTemplateDto?.gradationSystem ?? false)

    // if (this.tenderQuestionnaireTemplateDto?.gradationSystem && this.tenderQuestionnaireTemplateDto?.technicalGradationDtos) {
    //   this.formGroup.get('range')?.patchValue(this.tenderQuestionnaireTemplateDto?.range);

    //   let technicalGradationDtos = this.tenderQuestionnaireTemplateDto.technicalGradationDtos;
    //   if (technicalGradationDtos && technicalGradationDtos.length > 0) {
    //     this.technicalGradationConfig.clear();
    //     this.technicalGradationDtos = [];

    //     technicalGradationDtos.sort((a, b) => b.startRange! - a.startRange!);
    //     technicalGradationDtos.forEach(config => {
    //       this.technicalGradationConfig.push(this.updateTechnicalGradationConfig(config));
    //       this.technicalGradationDtos.push(config);
    //     });
    //   }
    // } else {
    //   this.formGroup.controls['technicalGradationDtos'].reset();
    //   this.technicalGradationConfig.clear();
    // }

    this.formGroup.updateValueAndValidity();
  }

  tenderOpeningTypeValidation() {
    if (this.tenderOpeningType == TenderOpeningType.QCBS) {
      this.formGroup.controls['scoringTemplate'].patchValue('YES');
      this.formGroup.controls['scoringTemplate'].setValidators(Validators.required);
      this.formGroup.controls['scoringTemplate'].disable();
      this.formGroup.controls['scoringTemplate'].updateValueAndValidity();

      this.formGroup.controls['technicalWeightage'].setValidators([Validators.required, Validators.pattern(Pattern.percentage)]);
      this.formGroup.controls['financialWeightage'].setValidators([Validators.required, Validators.pattern(Pattern.percentage)]);
    }

    this.formGroup.updateValueAndValidity();
  }

  runScoringValidation() {
    // let gradationSystem = this.formGroup.controls['gradationSystem'].value;
    let scoringTemplate = this.formGroup.controls['scoringTemplate'].value;

    if (scoringTemplate == 'YES') {
      this.formGroup.controls['maximumScore'].setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
      this.formGroup.controls['maximumScore'].updateValueAndValidity();

      this.formGroup.controls['qualifyingScore'].setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
      this.formGroup.controls['qualifyingScore'].updateValueAndValidity();

      // if (gradationSystem == true) {
      //   // this.addTechnicalGradationConfig();
      //   this.generateRangeGrading();

      //   this.formGroup.controls['range'].setValidators([Validators.required, multipleOfFiveValidator()]);
      //   this.formGroup.controls['range'].updateValueAndValidity();
      // } else {
      //   this.formGroup.controls['range'].clearValidators();
      //   this.formGroup.controls['range'].updateValueAndValidity();
      //   this.formGroup.controls['range'].reset();
      //   this.formGroup.controls['technicalGradationDtos'].reset();
      //   this.technicalGradationConfig.clear();
      // }
    } else {
      // this.formGroup.controls['technicalGradationDtos'].reset();
      // this.technicalGradationConfig.clear();

      this.formGroup.controls['maximumScore'].reset();
      this.formGroup.controls['maximumScore'].clearValidators();
      this.formGroup.controls['maximumScore'].updateValueAndValidity();

      this.formGroup.controls['qualifyingScore'].reset();
      this.formGroup.controls['qualifyingScore'].clearValidators();
      this.formGroup.controls['qualifyingScore'].updateValueAndValidity();
    }
  }

  // technicalGradation Config
  // get technicalGradationConfig(): FormArray {
  //   return this.formGroup.get("technicalGradationDtos") as FormArray
  // }

  // newTechnicalGradationConfig(): FormGroup {
  //   return this.fb.group({
  //     startRange: ['', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
  //     endRange: ['', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
  //     marks: ['', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
  //   })
  // }

  // updateTechnicalGradationConfig(config: TechnicalGradationDto): FormGroup {
  //   return this.fb.group({
  //     startRange: [config.startRange, [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
  //     endRange: [config.endRange, [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
  //     marks: [config.marks, [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
  //   })
  // }

  // addTechnicalGradationConfig() {
  //   this.technicalGradationConfig.push(this.newTechnicalGradationConfig());
  //   this.sortTechnicalGradationConfig();
  // }

  // removeTechnicalGradationConfig(i: number) {
  //   this.technicalGradationConfig.removeAt(i);
  // }

  // sortTechnicalGradationConfig() {
  //   this.technicalGradationConfig.controls.sort((a, b) => {
  //     const startRangeA = a.get('startRange')?.value;
  //     const startRangeB = b.get('startRange')?.value;
  //     return Number(startRangeB) - Number(startRangeA);
  //   });
  // }

  // checkTechnicalGradationValidation() {
  //   // Reset error states
  //   this.technicalGradationConfig.controls.forEach(technicalGradation => {
  //     technicalGradation.get('endRange')?.setErrors(null);
  //   });

  //   // Iterate through each technical gradation configuration
  //   for (let i = 0; i < this.technicalGradationConfig.controls.length; i++) {
  //     const currentGradation = this.technicalGradationConfig.controls[i];
  //     const currentStartRange = currentGradation.get('startRange')?.value;
  //     const currentEndRange = currentGradation.get('endRange')?.value;

  //     // Check against other technical gradations
  //     for (let j = 0; j < this.technicalGradationConfig.controls.length; j++) {
  //       if (i !== j) { // Avoid comparing the same gradation with itself
  //         const otherGradation = this.technicalGradationConfig.controls[j];
  //         const otherStartRange = otherGradation.get('startRange')?.value;
  //         const otherEndRange = otherGradation.get('endRange')?.value;

  //         // Check for overlap
  //         if ((Number(currentStartRange) >= Number(otherStartRange) && Number(currentStartRange) <= Number(otherEndRange)) ||
  //           (Number(currentEndRange) >= Number(otherStartRange) && Number(currentEndRange) <= Number(otherEndRange))) {
  //           // Set error for both conflicting gradations
  //           currentGradation.get('endRange')?.setErrors({ rangeOverlap: true });
  //           otherGradation.get('endRange')?.setErrors({ rangeOverlap: true });
  //           currentGradation.get('endRange')?.markAsTouched();
  //           otherGradation.get('endRange')?.markAsTouched();
  //           break; // Exit inner loop if overlap is found
  //         }
  //       }
  //     }
  //   }
  // }

  // generateRangeGrading() {
  //   this.technicalGradationConfig.clear(); // Clear previous entries
  //   this.formGroup.controls['range'].setErrors(null); // Reset errors

  //   const maximumScore = +this.formGroup.controls['maximumScore'].value;
  //   const range = +this.formGroup.controls['range'].value;

  //   // Validate if maximumScore and range are multiples
  //   if (maximumScore && range && maximumScore % range !== 0) {
  //     this.formGroup.controls['range'].setErrors({ multipleOfFive: true });
  //     return;
  //   }

  //   if (maximumScore && range) {
  //     const numberOfRanges = Math.ceil(maximumScore / range);

  //     let startRange = maximumScore - range + 1;
  //     let endRange = maximumScore;
  //     let marks = 100;
  //     const marksDecrement = 100 / numberOfRanges;

  //     for (let i = 0; i < numberOfRanges; i++) {
  //       if (startRange < 1) startRange = 1; // Ensure the range doesn't go below 1

  //       let technicalGradationConfig = new TechnicalGradationDto();
  //       technicalGradationConfig.startRange = startRange;
  //       technicalGradationConfig.endRange = endRange;
  //       technicalGradationConfig.marks = +marks.toFixed(2);

  //       this.technicalGradationConfig.push(this.updateTechnicalGradationConfig(technicalGradationConfig));

  //       endRange = startRange - 1;
  //       startRange = endRange - range + 1;
  //       marks -= marksDecrement;
  //     }
  //   }
  // }

  async margeTenderWrapperUiDto() {
    let tenderWrapperUiDto = ApplicationUtils.clone(this.tenderWrapperUiDto) as TenderWrapperUiDto;
    let tenderOpportunityWrapperDto = ApplicationUtils.clone(this.tenderOpportunityWrapperDto) as TenderOpportunityWrapperDto;

    // TenderQuestionnaireWrapperDto
    let tenderQuestionnaireWrapperDto = new TenderQuestionnaireWrapperDto();
    if (this.tenderQuestionnaireWrapperDto) {
      tenderQuestionnaireWrapperDto = ApplicationUtils.clone(this.tenderQuestionnaireWrapperDto) as TenderQuestionnaireWrapperDto;
    }

    // TenderQuestionnaireTemplateDto
    let tenderQuestionnaireTemplateDto = new TenderQuestionnaireTemplateDto();
    if (this.tenderQuestionnaireTemplateDto) {
      tenderQuestionnaireTemplateDto = ApplicationUtils.clone(this.tenderQuestionnaireTemplateDto) as TenderQuestionnaireTemplateDto;
      this.saveEntityActionDto(tenderQuestionnaireTemplateDto.templateId!, EntityActionLabelEnum.QUESTIONNAIRE, EntityActionTypeEnum.UPDATE);
    } else {
      tenderQuestionnaireTemplateDto.templateId = UUID.UUID().toString();
      this.saveEntityActionDto(tenderQuestionnaireTemplateDto.templateId!, EntityActionLabelEnum.QUESTIONNAIRE, EntityActionTypeEnum.ADD);
    }

    // Merge TenderQuestionnaireWrapperDto
    let formValue = this.formGroup.value;

    tenderQuestionnaireTemplateDto.templateName = 'Template';
    tenderQuestionnaireTemplateDto.templateDescription = 'Template Description ';
    tenderQuestionnaireTemplateDto.orgCode = tenderWrapperUiDto.orgCode;
    tenderQuestionnaireTemplateDto.resourceType = EventEnum.TENDER;
    tenderQuestionnaireTemplateDto.resourceId = tenderWrapperUiDto.tenderId;
    tenderQuestionnaireTemplateDto.subResourceId = tenderOpportunityWrapperDto.tenderOpportunityId;
    tenderQuestionnaireTemplateDto.saveAsTemplate = false;
    tenderQuestionnaireTemplateDto.scoringTemplate = formValue.scoringTemplate;
    tenderQuestionnaireTemplateDto.maximumScore = formValue.maximumScore;
    tenderQuestionnaireTemplateDto.qualifyingScore = formValue.qualifyingScore;
    tenderQuestionnaireTemplateDto.technicalWeightage = formValue.technicalWeightage;
    tenderQuestionnaireTemplateDto.financialWeightage = formValue.financialWeightage;
    // tenderQuestionnaireTemplateDto.gradationSystem = formValue.gradationSystem;

    // if (formValue.gradationSystem) {
    //   tenderQuestionnaireTemplateDto.range = formValue.range;
    //   tenderQuestionnaireTemplateDto.technicalGradationDtos = []

    //   formValue.technicalGradationDtos.forEach((item: any) => {
    //     let technicalGradationDto = new TechnicalGradationDto();
    //     technicalGradationDto.startRange = item.startRange;
    //     technicalGradationDto.endRange = item.endRange;
    //     technicalGradationDto.marks = item.marks;
    //     tenderQuestionnaireTemplateDto.technicalGradationDtos?.push(technicalGradationDto);
    //   });
    // } else {
    //   tenderQuestionnaireTemplateDto.range = undefined;
    //   tenderQuestionnaireTemplateDto.technicalGradationDtos = []
    // }

    tenderQuestionnaireWrapperDto.tenderQuestionnaireTemplateDto = tenderQuestionnaireTemplateDto;

    // Update TenderOpportunityWrapperDto
    tenderOpportunityWrapperDto.tenderQuestionnaireWrapperDto = tenderQuestionnaireWrapperDto;

    let index = tenderWrapperUiDto.tenderOpportunityWrapperDtos!.findIndex(item => item.tenderOpportunityId == tenderOpportunityWrapperDto.tenderOpportunityId);
    if (index != undefined && index > -1) {
      Object.assign(tenderWrapperUiDto.tenderOpportunityWrapperDtos![index], tenderOpportunityWrapperDto);
    } else {
      tenderWrapperUiDto.tenderOpportunityWrapperDtos!.push(tenderOpportunityWrapperDto);
    }

    this.saveEntityActionDto(tenderOpportunityWrapperDto.tenderOpportunityId!, EntityActionLabelEnum.OPPORTUNITY, EntityActionTypeEnum.UPDATE);

    // Final Tender Wrapper Ui Dto
    const entityActionDtos = await firstValueFrom(this.store.pipe(select(selectEntityActionDtos)));
    tenderWrapperUiDto.entityActionDtos = entityActionDtos;
    tenderWrapperUiDto.validationErrorCodeDtoList = [];

    return tenderWrapperUiDto;
  }

  async saveTenderWrapperUiDto() {
    this.errorMsg = '';
    this._showErrorToast$.next(false);

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    let tenderWrapperUiDto = await this.margeTenderWrapperUiDto();
    console.log(tenderWrapperUiDto);

    this.isLoading = true;

    try {
      const response = await firstValueFrom(this.tenderManagementService.saveTenderWrapperUiDto(tenderWrapperUiDto));
      if (response.code === ApplicationConstants.SUCCESS_CODE) {
        this.isLoading = false;
        this._showSuccessToast$.next(true);

        setTimeout(() => {
          this._showSuccessToast$.next(false);

          const tenderWrapperUiDto = response.data as TenderWrapperUiDto;
          this.store.dispatch(TenderManagementActions.setCurrentTenderWrapperUiDto({ tenderWrapperUiDto }));
          this.store.dispatch(TenderManagementActions.clearEntityActionDtos());
        }, 2000);
      } else {
        this.isLoading = false;
        this.errorMsg = response.message;
        this._showErrorToast$.next(true);
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;

      this.errorMsg = 'Error while saving questionnaire. Try again.';
      this._showErrorToast$.next(true);
    }
  }

  closeDrawer() {
    this.drawerService.closeDrawer();
  }

  openExternalDrawer(drawerName: string, tabName?: string) {
    this.drawerService.openDrawer(drawerName, tabName);
  }

  saveEntityActionDto(id: string, label: EntityActionLabelEnum, type: EntityActionTypeEnum) {
    const entityActionDto = new EntityActionDto(id, label, type);
    this.store.dispatch(TenderManagementActions.saveEntityActionDto({ entityActionDto }));
  }

  qualifyingScoreValidator(formGroup: FormGroup) {
    const maximumScoreControl = formGroup.get('maximumScore');
    const qualifyingScoreControl = formGroup.get('qualifyingScore');

    const maximumScore = maximumScoreControl?.value;
    const qualifyingScore = qualifyingScoreControl?.value;

    if (maximumScore !== null && qualifyingScore !== null && qualifyingScore > maximumScore) {
      qualifyingScoreControl?.setErrors({ qualifyScoreGreaterThanMax: true });
    } else {
      if (qualifyingScoreControl?.errors?.['qualifyScoreGreaterThanMax']){
        qualifyingScoreControl?.setErrors(null);
      }
      
    }

    const technicalWeightageControl = formGroup.get('technicalWeightage');
    const financialWeightageControl = formGroup.get('financialWeightage');

    const technicalWeightage = technicalWeightageControl?.value;
    const financialWeightage = financialWeightageControl?.value;

    if (technicalWeightage !== null && financialWeightage !== null) {
      const totalWeightage = parseFloat(technicalWeightage) + parseFloat(financialWeightage);
      
      if(totalWeightage === 100){
        if (financialWeightageControl?.errors?.['weightageSumInvalid']){
          financialWeightageControl?.setErrors(null);
        }
      }else{
        financialWeightageControl?.setErrors({ weightageSumInvalid: true });
      }
    }

    return null;
  }



  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }

    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }

}
