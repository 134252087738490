<div class="container my-5">
  <div class="bg-white py-5">
    <div class="col-9 m-auto text-center">
      <div>
        <img src="/downloadLandingBlob?fileId={{logoId}}" alt="" class="profile-image-xl">
        <h4 class="mt-3">Do you want to Unsubscribe ?</h4>
    
        <p class="mt-3">
          If you unsubscribe, you will stop receiving our upcoming and live auction notifications.
        </p>
        <div class="col-12 text-center mt-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-outline-primary btn-sm  me-3"
            [disabled]="!(_isSaveButtonEnable$ | async)" (click)="openUnsubscribeModal(unsubscribeTemp)">
            UNSUBSCRIBE
          </button>
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="navigateToHomePage()">CANCEL</button>
        </div>
        
      </div>
    </div>
  </div>
</div>

<ng-template #unsubscribeTemp>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Email</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
        </div>
      </div>
    </header>

    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="form-floating mb-3 ">
          <input type="email" class="form-control" id="unsubscribeEmail" placeholder="name@example.com"
            [formControl]="ctrlEmail" />
          <label for="unsubscribeEmail">Email Id</label>
          <div *ngIf="ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)">
            <p class="text-red-500 " *ngIf="ctrlEmail.errors?.['required']">Required</p>
            <p class="text-red-500 " *ngIf="ctrlEmail.errors?.['pattern']">Invalid</p>
          </div>
        </div>
        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'Email Unsubscribed Successfully'"></app-message-toast>
          <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true"
            [message]="_showErrorMsg$ | async"></app-message-toast>
        </div>

        	
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>
        <div class="col-12 text-end mt-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="btn btn-outline-primary btn-sm "
            [disabled]="!(_isSaveButtonEnable$ | async)" (click)="unsubscribe()">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>