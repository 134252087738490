import { Injectable } from '@angular/core';
import { MasterDataDto } from '../models/MasterDataDto';
import { BehaviorSubject, catchError, firstValueFrom, tap } from 'rxjs';
import { FirestoreListenerService } from './firestore-listener.service';
import { DeploymentConfigurationDto } from '../models/user/DeploymentConfigurationDto';
import { HttpClient } from '@angular/common/http';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ErrorService } from './error.service';
import { ApplicationConstants } from '../util/ApplicationConstants';
import { ServerDataFetchService } from './server-data-fetch.service';
import { CategoryDto } from '../models/CategoryDto';
import { Currency } from '../models/Currency';
import { TimeZoneDto } from '../models/TimeZoneDto';
import { CurrencyService } from './currency.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigConsoleService {
  
  collectionDeploymentConfiguration: string = "DEPLOYMENT_CONFIGURATION";
  collectionMasterData: string = "MASTER_DATA";

  initialMasterData: MasterDataDto = new MasterDataDto();
  initialDeploymentConfiguration: DeploymentConfigurationDto = new DeploymentConfigurationDto();

  private _deploymentConfig$ = new BehaviorSubject<DeploymentConfigurationDto | undefined>(undefined);
  private _masterData$ = new BehaviorSubject<MasterDataDto | undefined>(undefined);

  get getDeploymentConfiguration$() { return this._deploymentConfig$.asObservable(); }
  get getMasterData$() { return this._masterData$.asObservable(); }

  constructor(
    private currencyService: CurrencyService,
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private serverDataFetchService: ServerDataFetchService
  ) { }

  initialConfigureData() {
    this.httpClient.get('/assets/config/master_data.json').subscribe((data: any) => {
      if (data) {
        this.initialDeploymentConfiguration = data.deployment_configuration;
        this.initialMasterData.auctionFeatures = data.auction_features;
        this.initialMasterData.currencies = data.currencies;
        this.initialMasterData.timezones = data.timezones;
        // this.initialMasterData.categories = data.categories;
      }
    })
  }

  updateDeploymentConfig(deploymentConfig: DeploymentConfigurationDto) {
    this._deploymentConfig$.next(deploymentConfig);
  }

  updateCurrenciesData(currencies: Currency[]) {
    let masterDataDto = null; 
    if(!this._masterData$.value){
      masterDataDto = new MasterDataDto();
    }
    masterDataDto!.currencies = currencies;
    this._masterData$.next(masterDataDto!);
  }

  updateCategoriesData(categories: CategoryDto[]) {
    let masterDataDto = null; 
    if(!this._masterData$.value){
      masterDataDto = new MasterDataDto();
    }
    masterDataDto!.categories = categories;
    this._masterData$.next(masterDataDto!);
  }

  updateTimezoneData(timezones: TimeZoneDto[]) {
    let masterDataDto = null; 
    if(!this._masterData$.value){
      masterDataDto = new MasterDataDto();
    }
    masterDataDto!.timezones = timezones;
    this._masterData$.next(masterDataDto!);
  }

  async updateInitialMasterCategories() {
    try {
      let apiResponseDto = await firstValueFrom(this.postInitialMasterCategories());
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        console.log('Categories successfully deployed!');
      }
    } catch (error) {
      console.error(error);
    }
  }

  private postInitialMasterCategories() {
    return this.httpClient.post<ServerAPIResponseDto>('masterSubcategory', {}).pipe(
      tap(_ => console.log("Getting master categories")),
      catchError(this.errorService.handleError<any>("Error while getting master categories")));
  }



      /**
  * Fetch the master data from server
  * This method must be call ones
  */
  async loadCurrencyDataFromServerSync() {
    let currencies = this.currencyService.getCurrencies;
    this.updateCurrenciesData(currencies);
  }

    /**
  * Fetch the master data from server
  * This method must be call ones
  */
    async loadTimezoneDataFromServerSync() {
  
      try {
        let apiResponseDto = await firstValueFrom(this.serverDataFetchService.getTimezonesDataFromServer());
  
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let timezones = apiResponseDto.data as any;
          this.updateTimezoneData(timezones);
        }
      } catch (error) {
        console.error(error);
      }
    }

      /**
  * Fetch the master data from server
  * This method must be call ones
  */
  async loadCategoriesDataFromServerSync() {

    try {
      let apiResponseDto = await firstValueFrom(this.serverDataFetchService.getCategoriesDataFromServer());

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let categories = apiResponseDto.data as any;
        this.updateCategoriesData(categories);
      }
    } catch (error) {
      console.error(error);
    }
  }

}
