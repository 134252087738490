import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuctionValidationErrorCodeDto } from '../../models/user/AuctionValidationErrorCodeDto';
import { TranslateService } from '../../services/translate.service';
import { ApplicationUtils } from '../../util/ApplicationUtils';

@Component({
  selector: 'app-errors-list-modal',
  templateUrl: './errors-list-modal.component.html',
  styleUrls: ['./errors-list-modal.component.sass']
})
export class ErrorsListModalComponent implements OnInit {
  @Input() auctionValidationErrorCodeDtoList?: Array<AuctionValidationErrorCodeDto>;

  auctionErrorsList: Array<AuctionValidationErrorCodeDto> = [];
  lotsErrorsList: Array<AuctionValidationErrorCodeDto> = [];

  constructor(
    private translateService: TranslateService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.auctionValidationErrorCodeDtoList?.forEach((item) => {
      if (item.lotSequence) {
        this.lotsErrorsList.push(item);
      }
      if (!item.lotSequence) {
        this.auctionErrorsList.push(item);
      }
    })
  }

  closeModal() {
    this.activeModal.close();
  }

  getErrorMsg(error: AuctionValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.translateService);
  }
}
