import { CompanyDetailsDto } from "./CompanyDetailsDto";
import { CountryCodeDto } from "./CountryCodeDto";
import { FileInfoDto } from "./FileInfoDto";

export class ListingContactDto {
  listingContactId?: string;
  name?: string;
  emailId?: string;
  mobileNo?: string;
  companyId?: string;
  profile?: FileInfoDto;
  countryCode?: CountryCodeDto;
  companyLogo?: FileInfoDto;
  designation?: string;
  description?: string;
}
