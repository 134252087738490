<div class="model-body">
  <!-- <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Reset Password</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModalAndLogout()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div> -->
  <div class="model-body-top">
    <div class="m-2">
      <span>As you have logged in first time, you must change your password to proceed.&nbsp;</span>
      <button type="button" class="btn btn-link" (click)="closeModalAndLogout()">Logout</button>
    </div>
    <div class="col-12">
      <form [formGroup]="formGroup" autocomplete="off" class="needs-validation" novalidate
        (keyup.enter)="changePassword()">
        <div class="col-12">
          <label for="oldPassword" class="text-gray-700  text-sm font-semibold leading-4">Old Password</label>
          <div class="flex w-full mb-3">
            <input #oldPassword [type]="isOldPasswordVisible ? 'text' : 'password'"
              class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
              id="oldPassword" placeholder="Old Password" formControlName="oldPassword" autocomplete="new-password"
              [ngClass]="{'is-invalid': isSubmitted && fc.oldPassword.invalid}">
            <div class="input-group-append">
              <span class="border border-solid border-slate-50 border-l-0 rounded-r-lg h-8 flex pt-2 px-3"
                id="basic-addon2" [ngClass]="{'focus-border': (_isOldPasswordFocused$|async)}"
                (click)="toggleOldPasswordVisibility()">
                <i-lucide name="eye" [size]="16" class="my-icon c-pointer" *ngIf="!isOldPasswordVisible"></i-lucide>
                <i-lucide name="eye-off" [size]="16" class="my-icon c-pointer" *ngIf="isOldPasswordVisible"></i-lucide>
              </span>
            </div>
          </div>
          <div *ngIf="isSubmitted || fc.oldPassword.invalid && (fc.oldPassword.dirty || fc.oldPassword.touched)">
            <p class="text-red-500 " *ngIf="fc.oldPassword.errors?.required">Required</p>
          </div>
        </div>
        <label for="newPassword" class="text-gray-700  text-sm font-semibold leading-4">New Password</label>
        <div class="flex w-full mb-3">
          <input #newPassword [type]="isNewPasswordVisible ? 'text' : 'password'"
            class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
            id="new-password" formControlName="newPassword" id="newPassword" autocomplete="new-password"
            (keyup)="onChange()" (change)="onChange()"
            [ngClass]="{'is-invalid': isSubmitted && fc.newPassword.invalid}" />
          <div class="input-group-append">
            <span class="border border-solid border-slate-50 border-l-0 rounded-r-lg h-8 flex pt-2 px-3"
              id="basic-addon2" [ngClass]="{'focus-border': (_isNewPasswordFocused$|async)}"
              (click)="toggleNewPasswordVisibility()">
              <i-lucide name="eye" [size]="16" class="my-icon c-pointer" *ngIf="!isNewPasswordVisible"></i-lucide>
              <i-lucide name="eye-off" [size]="16" class="my-icon c-pointer" *ngIf="isNewPasswordVisible"></i-lucide>
            </span>
          </div>
        </div>
        <div *ngIf="isSubmitted || fc.newPassword.invalid && (fc.newPassword.dirty || fc.newPassword.touched)">
          <p class="text-red-500 " *ngIf="fc.newPassword.errors?.required">
            Required</p>
          <p class="text-red-500 " *ngIf="fc.newPassword.invalid &&  (fc.newPassword.value)">
            Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
            one number and one special character.</p>
        </div>
        <label for="repeatPassword" class="text-gray-700  text-sm font-semibold leading-4">Retype New
          Password</label>
        <div class="flex w-full mb-3">

          <input #repeatPassword [type]="isRepeatPasswordVisible ? 'text' : 'password'"
            class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
            id="retype-password" formControlName="repeatPassword" autocomplete="new-password" id="repeatPassword"
            [ngClass]="{'is-invalid': isSubmitted && fc.repeatPassword.invalid}" (keyup)="onChange()"
            (change)="onChange()" />
          <div class="input-group-append">
            <span class="border border-solid border-slate-50 border-l-0 rounded-r-lg h-8 flex pt-2 px-3"
              id="basic-addon2" [ngClass]="{'focus-border': (_isRepeatPasswordFocused$|async)}"
              (click)="toggleRepeatPasswordVisibility()">
              <i-lucide name="eye" [size]="16" class="my-icon c-pointer" *ngIf="!isRepeatPasswordVisible"></i-lucide>
              <i-lucide name="eye-off" [size]="16" class="my-icon c-pointer" *ngIf="isRepeatPasswordVisible"></i-lucide>
            </span>
          </div>
        </div>
        <div class="mb-3"
          *ngIf="isSubmitted || fc.repeatPassword.invalid && (fc.repeatPassword.dirty || fc.repeatPassword.touched)">
          <p class="text-red-500 " *ngIf="fc.repeatPassword.errors?.required">Required</p>
        </div>
        <div class="mb-3">
          <p class="text-red-500 " *ngIf="!fc.repeatPassword.invalid && !(_isPasswordMatch$ | async)">
            Password Not Match
          </p>
        </div>

        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'Password changed successfully'"></app-message-toast>
          <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true"
            [message]="errorMsg"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>

        <div class="text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 "
            (click)="changePassword()">Change Password</button>
        </div>
      </form>
    </div>
  </div>

</div>