import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-drawer-main',
  templateUrl: './drawer-main.component.html',
  styleUrls: ['./drawer-main.component.sass']
})
export class DrawerMainComponent implements OnInit, OnDestroy {
  @Input() pageName!: string

  constructor(
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    
  }
}
