<div>
  <div class="border-b flex px-6 py-0 items-center gap-3 bg-white h-20 border-slate-50">
    <div class="flex flex-row gap-4 w-full items-center justify-between">
      <div class="">
        <div class="my-3 text-lg font-semibold leading-6 text-gray-700 tracking-tighter">
          Company
        </div>
      </div>
      <div class="text-right">
        <button class="inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800inline-flex" 
        (click)="openNewCompanyDrawer($event)">
        <i-lucide name="plus" [size]="18" color="#fff" class="my-icon"></i-lucide> Add New Company
        </button>
      </div>
    </div>
  </div>
  <div class="bg-gray-100 gap-2.5 py-4 px-6 item-start flex-col self-stretch flex">
    <div class="flex flex-row gap-4 w-full items-center justify-between">
      <div class="">
        <div class="flex w-full mb-3">
          <input
            class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0"
            #password id="welcomePassword" placeholder="Search" [formControl]="ctrlSearch">
          <div class="input-group-append">
            <span class="input-group-text border-solid border-slate-50 border-l-0 rounded-l-lg h-8 bg-white"
              id="basic-addon2">
              <i-lucide name="search" [size]="16" class="my-icon c-pointer"></i-lucide>
            </span>
          </div>
        </div>
      </div>
      <div class=" text-right flex">
        <span class="mr-2 inline-flex h-6 w-6 border border-blue-500 text-blue-500 rounded items-center justify-center">
          <i-lucide name="grid-2x2" [size]="16" class="my-icon c-pointer"></i-lucide>
        </span>
        <span class="mr-2 inline-flex h-6 w-6 border border-gray-500 text-gray-800 rounded items-center justify-center ">
          <i-lucide name="table" color="#000" [size]="16" class="my-icon c-pointer"></i-lucide>
        </span>
      </div>
    </div>
    <div class="">
      <div *ngIf="!isDataLoading && companiesList.length == 0">
        <div class="text-center">
          <h5 class="heading-new">No Data Found</h5>
        </div>
      </div>
      <div class="text-center" *ngIf="isDataLoading">
        <div class="e-spinner"></div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full mb-2 ">
        <div class=" p-6 flex h-96 items-start rounded-lg border-gray-100 border gap-2.5 bg-white flex-col" *ngFor="let item of _filteredCompanies$ | async; index as i;">
          <div class="text-xl font-semibold leading-4 text-gray-700">
            <div class="overflow-class">
              {{item.companyName}}
            </div>
          </div>
          <div class="text-gray-700 text-xs font-semibold  mb-2">
            Company Code: {{item.companyCode}}
          </div>
          <div class="tr-card-budget-border mb-2 grid grid-cols-2">
            <div class=" flex py-1.5 px-3 flex-col items-start gap-1 rounded-md rounded-l-0">
              <div class="text-gray-700 text-xs font-semibold">
                Allocated Budget
              </div>
              <div class="text-sm mt-2 text-blue-500">
                {{item?.currencyDto?.symbol}}{{item?.allocatedBudgetAmt}}
              </div>
            </div>
            <div class=" border-l flex py-1.5 px-3 flex-col items-start gap-1 rounded-md rounded-l-0">
              <div class="text-gray-700 text-xs font-semibold">
                Unallocated Budget
              </div>
              <div class="text-sm mt-2 text-orange-100">
                $28,000
              </div>
            </div>
          </div>
          <div class=" mb-4 w-full">
            <div class="mb-2 flex flex-row justify-between items-center h-8">
              <div class="basis-3/12 ">
                Plants
              </div>
              <div class="basis-9/12 inline-flex">
                <span class=" flex-col py-1 px-3 items-center flex gap-2 bg-gray-100 rounded-md mr-2" *ngFor="let plant of item.plantUiDtos!.slice(0, 2);">
                  {{plant?.plantName}}
                </span>
                <span class="inline-flex h-8 w-8 gap-2.5 p-1 items-center justify-center bg-gray-100 text-gray-700 rounded-full me-2" *ngIf="item.plantUiDtos && item.plantUiDtos.length > 0">
                  {{item.plantUiDtos.length}}
                </span>
              </div>
            </div>
            <div class="mb-2 flex flex-row justify-between items-center h-8">
              <div class="basis-3/12 ">
                Users
              </div>
              <div class="basis-9/12 inline-flex">
                <!-- <span class="tr-image-bg">
                  <img src="./assets/images/avatar_default.png" class="tr-md-icon">
                </span> -->
                <span class=" tr-image-margin" *ngFor=" let user of item?.userInfoUiDtos">
                  <img src="/downloadLandingBlob?fileId={{user?.profileImage?.fileId}}" *ngIf="user?.profileImage"
                    class=" h-8 w-8 rounded-full border border-gray-700 inline-block">
                  <img src="./assets/images/avatar_default.png" *ngIf="!(user?.profileImage)" class="h-8 w-8 rounded-full border border-gray-700 inline-block">
                </span>
                <span class=" h-8 w-8 inline-block flex-col items-center rounded-full  border-4 border-gray-100 bg-gray-100 text-xs  ms-2"
                  *ngIf="item.userInfoUiDtos && item.userInfoUiDtos.length > 0">
                  + {{item?.userInfoUiDtos?.length}}
                </span>
              </div>
            </div>
          </div>
          <div class="w-full">
            <button class="tr-blue-outline-button border-blue-500 border-2 text-blue-500 text-sm  mr-2"
              (click)="navigateInternalPage('ONBOARDING_COMPANY_DETAILS', item)">
              View Details
              <i-lucide name="move-right" [size]="18" color="#0555F5" class="my-icon"></i-lucide>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>





  <!-- <div class="container">
  <div class="">
    <div class="p-2">
      <div class="row align-items-center">
        <div class="col-md-3  text-start mb-2">
          <div class="mb-2 input-group">
            <input #search placeholder="Search" type="text" id="ctrlSearchPR" [formControl]="ctrlSearch"
              class="form-control border-end-0 rounded-0 border bg-white" />
            <span class="input-group-append">
              <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
              </button>
            </span>
          </div>
        </div>
        <div class="col-md-5 text-start mb-2">
        </div>
        <div class="col-md-4 mb-2 text-md-end">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mt-2 mt-sm-0" *ngIf="isShowCompanyView"
            (click)="backToAllCompaniesList()"> BACK
          </button>
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mt-2 mt-sm-0" *ngIf="!isShowCompanyView"
            (click)="openCompanyDetail(editCompanyDetail)"> ADD
          </button>
        </div>
      </div>
      <div class="col-12">
        <ng-container *ngTemplateOutlet="isShowCompanyView ? companyDetailsTemplate : companiesListTemplate">
        </ng-container>
      </div>
    </div>
  </div>
</div> -->