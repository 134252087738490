import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { UserUiDto } from '../models/user/UserUiDto';

export const SessionActions = createActionGroup({
  source: 'Session',
  events: {
    'Save Session Info': props<{ sessionInfo: SessionInfoDto }>(),
    'Get Session Info': emptyProps(),
    'Update User Ui Dto': props<{ userUiDto: UserUiDto }>(),
    'Clear Session': emptyProps(),
  },
});
