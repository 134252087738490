<div class="border rounded bg-white"
  [ngClass]="{'border-danger border-2': !auctionEntityDto?.listingContactDtos || auctionEntityDto?.listingContactDtos?.length == 0}">
  <header class="p-2">
    <div class="row ">
      <div class="col-md-4 ">
        <h5 class="heading-h5" ngbAutoFocus> CONTACTS</h5>
      </div>
      <div class="col-md-8 text-md-end ">
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 me-3" (click)="openSortingModal(sortingModal)"
          *ngIf="auctionEntityDto?.listingContactDtos && auctionEntityDto?.listingContactDtos!.length > 1" [disabled]="!isAllowToEdit()">
          <!-- <img src="assets/icons/reorder_dark.svg" class=" c-pointer svg-icon-class"> -->
          REORDER
        </button>
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 " (click)="openModal(addModal)" [disabled]="!isAllowToEdit()">
          <!-- <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-small"> -->
          ADD
        </button>
      </div>
    </div>
  </header>
  <hr class="hr" />
  <div class="col-12 mb-3">
    <div class=" div-class p-2">
      List of contacts
    </div>
    <hr class="hr" />
    <div class="col-12 my-3 text-center"
      *ngIf="!auctionEntityDto?.listingContactDtos || auctionEntityDto?.listingContactDtos!.length == 0">
      <h3 class="heading-new text-dark">Contact is not created yet.</h3>
    </div>
   <div class="multiple-list-scroll">
    <div class="c-pointer"
    *ngFor="let item of auctionEntityDto?.listingContactDtos; index as i">
    <div class="col-12 p-3">
      <div class="row ">
        <div class="col-3" (click)="openModal(addModal, item)">
          <img src="/downloadLandingBlob?fileId={{item.profile?.fileId}}" [alt]="item.name"
            class="profile-image-large">
        </div>
        <div class="col-7" (click)="openModal(addModal, item)">
          <h5 class="heading-h5 mb-0 overflow-class-small" ngbTooltip="{{item.name}}">{{item.name}}</h5>
          <div class="div-class-small overflow-class-small" ngbTooltip="{{item.emailId}}">
            <img src="assets/icons/mail-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1 left-padding-icon">
            {{item.emailId}}</div>
          <div class="div-class-small overflow-class-small" ngbTooltip="{{item.mobileNo}}">
            <img src="assets/icons/mobile-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1">
            {{getCountryCode(item.countryCode)}} {{item.mobileNo}}</div>
        </div>
        <div class="col-2 text-end mt-3">
          <img src="assets/icons/delete_danger.svg" (click)="openConfirmationModal(confirmationModal, i)"
            class="svg-icon-class c-pointer" [ngClass]="{'disabled': !isAllowToEdit()}">
        </div>

      </div>
    </div>
    <hr class="hr" *ngIf="i != (auctionEntityDto?.listingContactDtos)!.length - 1" />
  </div>
   </div>
  </div>
</div>

<ng-template #addModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>ADD CONTACT</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup"
          (keyup.enter)="handleValidSubmit()">
          <div class="form-floating mb-3">
            <input type="name" class="form-control" id="name" placeholder="Name" formControlName="name"
              [ngClass]="{'is-invalid': (_validationErrorPresent$ | async) && fc.name.invalid || fc.name.invalid && (fc.name.dirty || fc.name.touched)}">
            <label for="name">Name</label>
            <div class="invalid-feedback"
              *ngIf="(_validationErrorPresent$ | async) && fc.name.invalid || fc.name.invalid && (fc.name.dirty || fc.name.touched)">
              Required
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="emailId" placeholder="Email" formControlName="emailId"
              [ngClass]="{'is-invalid': (_validationErrorPresent$ | async) && fc.emailId.invalid || fc.emailId.invalid&& (fc.emailId.dirty || fc.emailId.touched)}">
            <label for="emailId">Email</label>
            <div>
              <p class="e-fielderror e-fielderror-required text-red-500 "
                *ngIf=" (_validationErrorPresent$ | async) && fc.emailId.invalid && !fc.emailId.value || fc.emailId.invalid && (fc.emailId.dirty || fc.emailId.touched) && !fc.emailId.value">
                Required</p>
              <p class="e-fielderror e-fielderror-required text-red-500 "
                *ngIf="(_validationErrorPresent$ | async) && fc.emailId.invalid && fc.emailId.value|| fc.emailId.invalid &&  fc.emailId.value && (fc.emailId.dirty || fc.emailId.touched)">
                Invalid email address</p>
            </div>
          </div>

          <div class="input-group mb-3">
            <span class="input-group-addon">
              <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                [countryCode$]="countryCode$"></app-country-code-select>
            </span>
            <div class="form-floating">
              <input type="text" class="form-control" id="mobileNo" placeholder="Phone" formControlName="mobileNo"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ | async) && fc.mobileNo.invalid || fc.mobileNo.invalid && (fc.mobileNo.dirty || fc.mobileNo.touched)}">
              <label for="mobileNo">Phone</label>
              <div>
                <p class="e-fielderror e-fielderror-required text-red-500 "
                  *ngIf="(_validationErrorPresent$ | async) && fc.mobileNo.invalid && !fc.mobileNo.value || fc.mobileNo.invalid && (fc.mobileNo.dirty || fc.mobileNo.touched ) && !fc.mobileNo.value">
                  Required</p>
                <p class="e-fielderror e-fielderror-required text-red-500 "
                  *ngIf="(_validationErrorPresent$ | async) && fc.mobileNo.invalid &&   fc.mobileNo.errors?.pattern || fc.mobileNo.invalid && (fc.mobileNo.dirty || fc.mobileNo.touched) && fc.mobileNo.value &&  fc.mobileNo.errors?.pattern">
                  Invalid</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-floating mb-3">
                <input name="" type="text" id="profilePic" (click)="profilePic.click()" [value]="_fileDisplayName$ | async" [disabled]="!(_isSaveButtonHide$ |async)"
                  class="form-control"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)}" />
                <input #profilePic type="file" onclick="this.value=null" id="profilePic1" (change)="chooseFile($event)" class="d-none" />
                <label for="profilePic1">Agent Profile Image</label>
                <div>
                  <p class="e-fielderror e-fielderror-required text-red-500 "
                    *ngIf="(_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)">Required</p>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row">
                <div class="col-6">
                  <div class="">
                    <div class="col-8">
                      <img alt="" [src]="imageUrl" *ngIf="!currentFileInfoDto"
                        [ngClass]="(this._isImageActive$ | async) ? 'modal-logo-image-large' : 'modal-logo-image '" />
                      <img alt="" src="/downloadLandingBlob?fileId={{currentFileInfoDto!.fileId}}"
                        [ngClass]="(this._isImageActive$ | async) ? 'modal-logo-image-large' : 'modal-logo-image '"
                        *ngIf="currentFileInfoDto" />
                    </div>
                  </div>
                </div>

                <div class="col-6 text-md-end mt-2" *ngIf="currentFileInfoDto || currentFile">
                  <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-2" (click)="openViewImageModal()">
                    <img src="assets/icons/view_dark.svg" class="svg-icon-class c-pointer"
                      *ngIf="!(this._isImageActive$ | async)">
                    <img src="assets/icons/zoomout_dark.svg" class="svg-icon-class c-pointer"
                      *ngIf="(this._isImageActive$ | async)">
                  </button>
                  <span *ngIf="_isSaveButtonHide$ |async" (click)="removeProfileImage()" [ngClass]="{'disabled' : isLoading }">
                    <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Contact saved successfully.'"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
          </div>
          <div class="mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
          <div *ngIf="_isSaveButtonHide$ |async">
            <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
              <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " [disabled]="!isAllowToEdit() || (_isSaveButtonDisable$ |async)"
                (click)="handleValidSubmit()">{{isEditContact ? 'EDIT' : 'ADD'}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #sortingModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>CONTACTS</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="col-12 my-3"  style="max-height: 400px; overflow-x: hidden; overflow-y: auto;" cdkDropList (cdkDropListDropped)="drop($event)">
          <div class="card mx-4 mb-2 droplist" *ngFor="let item of listingContacts; index as i" cdkDrag
            [cdkDragDisabled]="_isSaveButtonDisable$ | async">
            <div class="drag-placeholder col-12" *cdkDragPlaceholder></div>
            <div class="col-12 p-1">
              <div class="row mx-1">
                <div class="col-3">
                  <img src="/downloadLandingBlob?fileId={{item?.profile?.fileId}}" [alt]="item?.name"
                    class="profile-image-large">
                </div>
                <div class="col-7 mt-2">
                  <h5 class="text-primary mb-0">{{item?.name}}</h5>
                  <div class="div-class-small overflow-class-small"><img src="assets/icons/mail-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1 left-padding-icon">
                    {{item?.emailId}}</div>
                  <div class="div-class-small overflow-class-small">
                    <img src="assets/icons/mobile-icon.svg" class="svg-icon-class-small  c-pointer mt-0 me-1">

                    {{getCountryCode(item?.countryCode)}} {{item?.mobileNo}}</div>
                </div>
                <div class="col-2 text-end mt-2">
                  <img src="assets/icons/reorder_light.svg" class=" c-pointer svg-icon-class ms-2">
                  <!-- <button class="btn btn-outline-primary btn-sm rounded-circle top-margin-modal c-pointer">
                    <fa-icon [icon]="['fas', 'trash']" class=" fa-lg"></fa-icon>
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Listing Contact saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="'Error while saving listing contact. Try again.'"></app-message-toast>
        </div>

        <div class="mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 "
            (click)="handleValidSubmit()" [disabled]="!isAllowToEdit()">UPDATE</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmationModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Delete Contact</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isRemoveLoading}">
          <img src="assets/icons/close_light.svg" class="svg-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>Do you want to delete contact?</p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Auction contact delete successfully"></app-message-toast>
        </div>

        <div class="text-end mb-3" *ngIf="!isRemoveLoading && !(_showSuccessToast$|async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="deleteAuctionContact()">Confirm</button>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isRemoveLoading">
          <div class="e-spinner"></div>
        </div>
      </div>
    </div>


  </div>
</ng-template>
