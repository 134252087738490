import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { TenderManagementActions } from 'src/app/shared/state-management/tender/tender.actions';
import { selectTenderWrapperUiDto, selectTenderOpportunityWrapperDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-tender-approvals-drawer',
  templateUrl: './tender-approvals-drawer.component.html',
  styleUrls: ['./tender-approvals-drawer.component.sass']
})
export class TenderApprovalsDrawerComponent implements OnInit, OnDestroy {
  currentTab: string = ApplicationConstants.TENDER_PUBLISHERS;

  tenderWrapperUiDto?: TenderWrapperUiDto;
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;
  
  applicationConstants: typeof ApplicationConstants = ApplicationConstants;

  tenderWrapperUiDtoSubscription$?: Subscription;
  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  constructor(
    private store: Store,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);
        
        let tenderOpportunityWrapperDtos = this.tenderWrapperUiDto?.tenderOpportunityWrapperDtos || [];
        if (tenderOpportunityWrapperDtos.length > 0) {
          if (this.tenderOpportunityWrapperDto) {
            let index = tenderOpportunityWrapperDtos.findIndex(item => item.tenderOpportunityId == this.tenderOpportunityWrapperDto?.tenderOpportunityId);
            let tenderOpportunityWrapperDto = tenderOpportunityWrapperDtos[index];
            this.store.dispatch(TenderManagementActions.setCurrentTenderOpportunityWrapperDto({ tenderOpportunityWrapperDto }));
          }
        }
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
      } else {
        this.tenderOpportunityWrapperDto = undefined;
      }
    })

    if (this.drawerService.drawerPageTab) {
      this.toggleDetailsTab(this.drawerService.drawerPageTab);
    }
  }

  toggleDetailsTab(tab: string) {
    this.currentTab = tab;
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }
    
    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}
