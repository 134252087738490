import { Injectable } from '@angular/core';
import { Currency } from '../models/Currency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor() { }

  get getCurrencies() { return this.currencies; }

  private currencies: Currency[] = [
    {
      "code": "CAD",
      "locale": "en-CA",
      "name": "Canadian Dollar (CAD)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "CA$"
    },
    {
      "code": "USD",
      "locale": "en-US",
      "name": "US Dollar (USD)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "$"
    },
    {
      "code": "GBP",
      "locale": "en-GB",
      "name": "British Pounds (GBP)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "£"
    },
    {
      "code": "ZAR",
      "locale": "en-ZA",
      "name": "South African Rand (ZAR)",
      "regex": "^(?:\\d{1,3}(?:\\s\\d{3})*|\\d+)$",
      "symbol": "R"
    },
    {
      "code": "MYR",
      "locale": "en-MY",
      "name": "Malaysian Ringgit (MYR)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "RM"
    },
    {
      "code": "KES",
      "locale": "en-KE",
      "name": "Kenyan Shilling (KES)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "KSh"
    },
    {
      "code": "NGN",
      "locale": "en-NG",
      "name": "Nigerian Naira (NGN)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "₦"
    },
    {
      "code": "INR",
      "locale": "en-IN",
      "name": "Indian Rupee (INR)",
      "regex": "^(?:\\d+|\\d{1,2},(?:\\d{2},)*\\d{3})(?:\\.\\d{2})?$",
      "symbol": "₹"
    },
    {
      "code": "BDT",
      "locale": "en-BD",
      "name": "Bangladeshi Taka (BDT)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "৳"
    },
    {
      "code": "AUD",
      "locale": "en-AU",
      "name": "Australian Dollar (AUD)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "AU$"
    },
    {
      "code": "NZD",
      "locale": "en-NZ",
      "name": "New Zealand Dollar (NZD)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "NZ$"
    },
    {
      "code": "EUR",
      "locale": "en-FR",
      "name": "Euro (EUR)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "€"
    },
    {
      "code": "AED",
      "locale": "en-AE",
      "name": "UAE Dirham (AED)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "AED"
    },
    {
      "code": "SAR",
      "locale": "en-SA",
      "name": "Saudi Riyal (SAR)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "SAR"
    },
    {
      "code": "KWD",
      "locale": "en-KW",
      "name": "Kuwaiti Dinar (KWD)",
      "regex": "^(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d+)?$",
      "symbol": "KD"
    }
  ]
}
