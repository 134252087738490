<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Bidder Details</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeUserDetailsModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top bg-white-new">
    <div class="m-3">
      <!-- <div class="row mt-2">

        <div class="col-lg-2 col-3 div-class  mb-1">
          <img [alt]="userDto?.firstName" src="assets/images/avatar05.jpg" class="profile-image-large border mt-0"
            *ngIf="!userDto?.profileImage">
          <img [alt]="userDto?.firstName" src="/downloadLandingBlob?fileId={{userDto?.profileImage?.fileId}}"
            class="profile-image-class border" *ngIf="userDto?.profileImage">
        </div>
        <div class="col-lg-7 col-6">
          <div class="div-class ">
            <h5 class="heading-h5 mb-0">
              {{userDto?.firstName}} {{userDto?.lastName}}
            </h5>
          </div>
          <div class="div-class">
            <b>{{userDto?.designation}}</b>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3 div-class  my-2 ">
          Company Name
        </div>
     
      <div class="div-class my-2  col-9" >
        <b>{{userDto?.companyName}}</b>
      </div>
      <div class="col-3 div-class  my-2 "  *ngIf="userDto?.companyUrl">
        Company Url
      </div>
      <div class="div-class  col-9" *ngIf="userDto?.companyUrl">
        <a [href]="userDto?.companyUrl"> {{userDto?.companyUrl}}</a>
     </div>
     <div class="col-3 div-class  my-2 ">
     Address
    </div>
    <div class="div-class my-2  col-9">
   
      <span *ngIf="userDto?.address?.addressLine1">
        {{userDto?.address?.addressLine1}}, {{userDto?.address?.addressLine2 ? userDto?.address?.addressLine2 + ', ' : ''}}
        {{userDto?.address?.city}}, {{userDto?.address?.state}}, {{userDto?.address?.country}} - {{userDto?.address?.zipCode}}
      </span>
    </div>
    <div class="col-3 div-class  my-2 " *ngIf="userDto?.faxNo">
      Fax No
     </div>
     <div class="div-class my-2  col-9" *ngIf="userDto?.faxNo">
      {{getCountryCode(userDto?.faxNoCountryCode!)}} {{userDto?.faxNo}}
    </div>
    </div> -->
    <div class="">
      <div class=" ">
        <div class="row">
    
          <div class=" col-3 div-class  mb-1 ms-3" >
            <img [alt]="userDto?.firstName" src="assets/images/avatar05.jpg" class="user-profile-image border mt-0"
              *ngIf="!userDto?.profileImage">
            <img [alt]="userDto?.firstName" src="/downloadLandingBlob?fileId={{userDto?.profileImage?.fileId}}"
              class="user-profile-image border" *ngIf="userDto?.profileImage"
              [ngClass]="{'img-portrait': userDto?.profileImage?.dataType == 'Portrait'}">
          </div>
          <div class="col-lg-6 col-5  me-3 text-start">
            <div class="div-class ">
              <h5 class="heading-h5 mb-0">
                {{userDto?.firstName}} {{userDto?.lastName}}
              </h5>
            </div>
            <div class="div-class">
              <b> {{userDto!.designation}}</b>
            </div>
           <div>
            <small class="small-class">
               {{selectedCompanyName}}
            </small>
           </div>
      
            
        
          </div>
          <!-- <div class="col-3   col-lg-2  text-end mt-3 pe-0">
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="toggleEditMode()">
              EDIT
            </button>
          </div> -->
        </div>
      
      <!-- <div class="m-3">
        <div class="text-center">
         <div class="div-class">
         <b>  {{userDto?.companyName}}</b>

         </div>
         <div class="div-class" *ngIf="userDto?.companyUrl">
          <a target="_blank" [href]="userDto?.companyUrl"> {{userDto?.companyUrl}}</a>
         </div>
         <div class="div-class" >
           <span *ngIf="userDto?.address?.addressLine1">
             {{userDto?.address?.addressLine1}}, {{userDto?.address?.addressLine2 ? userDto?.address?.addressLine2 + ', ' : ''}}
             {{userDto?.address?.city}}, {{userDto?.address?.state}}, {{userDto?.address?.country}} - {{userDto?.address?.zipCode}}
           </span>
          </div>
          <div class="div-class"  *ngIf="userDto?.faxNo">
          <span>Fax No.</span> {{getCountryCode(userDto?.faxNoCountryCode!)}} {{userDto?.faxNo}}
          </div>
     
        </div>
      </div> -->
    
    </div>
      <div class="col-12 mt-3" >
        <div class="row ">
          <div class="col-lg-6">
           <div class="border p-2 bg-white">
            <div class="row">
              <div class="col-6">
                <h6 class="heading-h5 mt-2">Email</h6>
              </div>
            </div>
            <hr class="hr my-2">
            <div class="mt-3 mx-2">
              <!-- <ul style="list-style: none;" class="ps-2">
                <li *ngFor="let item of userDto?.emailList;">
                  <div class="row my-3">
                    <div class="div-class col-8">
                      {{item.email}}
                    </div>
                  </div>
                </li>
              </ul> -->
            </div>
           </div>
          </div>
          <div class="col-lg-6">
           <div class="border p-2 bg-white">
            <div class="row">
              <div class="col-6">
                <h6 class="heading-h5 mt-2">Contact</h6>
              </div>
            </div>
            <hr class="my-2 hr">
            <div class=" mt-3 mx-2">
              <!-- <ul style="list-style: none;" class="ps-2">
                <li *ngFor="let item of userDto?.mobileList;">
                  <div class="row my-3">
                    <div class="div-class col-8">
                      {{getCountryCode(item.countryCode!)}} {{item.mobileNo}}
                    </div>
                  </div>
                </li>
              </ul> -->
            </div>
           </div>
          </div>
        </div>
      </div>

      <hr class="hr my-3"  >

      <div class="col-12 mt-3">
       <div class="border p-2 bg-white">
        <div class="row mb-2">
          <div class="col-8">
            <h6 class="heading-h5 mt-2">Category Preference</h6>
          </div>
        </div>

        <div class="p-3 text-center" *ngIf="preferenceCategories.length == 0">
          <h6>Preference Category Not Available!</h6>
        </div>

        <div class="scroll-category-data">
        <div class="border p-3" *ngFor="let item of preferenceCategories">
          <b>{{item?.categoryName}}</b>
          <div>
            <small>{{item?.categoryLevelOneName}} >> {{item?.categoryLevelTwoName}} >> {{item?.categoryLevelThreeName}} >> {{item?.categoryLevelFourName}} </small>
          </div>
        </div>
      </div>
       </div>
      </div>

    </div>
  </div>
</div>
</div>


