<div class="model-body">

    <div class="" *ngIf="auctionValidationErrorCodeDtoList">
      <table class="table mb-3" *ngIf="auctionErrorsList.length > 0">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Error</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of auctionErrorsList; index as i">
            <td>{{i+1}}</td>
            <td>{{getErrorMsg(item)}}</td>
          </tr>
        </tbody>
      </table>
  
      <table class="table" *ngIf="lotsErrorsList.length > 0">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Lot No.</th>
            <th scope="col">Lot Name</th>
            <th scope="col">Error</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of lotsErrorsList; index as i">
            <td>{{i+1}}</td>
            <td>{{item?.lotSequence}}</td>
            <td>{{item?.lotName}}</td>
            <td>{{item.message}}</td>
            <!-- <td>{{getErrorMsg(item)}}</td> -->
          </tr>
        </tbody>
      </table>
  </div>
 
</div>