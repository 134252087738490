<div class="container mt-5">
  <!-- AUCTIONS -->
  <div class="row justify-content-between align-items-center mb-3">

    <div class="col-md-3 col-sm-5 text-end">
      <div class="input-group" >
        <input type="text" placeholder="Search" id="ctrlSearchAuction" [formControl]="ctrlSearchAuction"
          class="form-control border-end-0 rounded-0 border bg-white" />
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
    </div>
  </div>
  <div >
    <div class="table-responsive">
      <table class="table table-bordered medium-font align-middle">
        <thead class="table-dark">
          <tr>
            <th class=""><span>S No</span></th>
            <th class=""><span>Title</span></th>
            <th class=""><span>End Date</span></th>
            <th class=""> <span>Timezone</span></th>
            <th class=""> <span>Type</span></th>
            <th class=""> <span>Action</span></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr *ngIf="(allSourcingEvents$ | async)?.length == 0">
            <td colspan="9">
              <div class="text-center">
                <h5 class="heading-new text-dark">NO AUCTION AVAILABLE</h5>
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of allSourcingEvents$ | async; index as i" >
            <td class="font-weight-normal">{{item?.sequenceNo}}</td>
            <td class="font-weight-normal">{{item?.eventName}}</td>
            <td class="font-weight-normal">{{getDisplayDate(item?.endDate, item?.endTime)}}</td>
            <td class="font-weight-normal">{{getDisplayTimezone(item?.timeZone!)}}</td>
            <td class="font-weight-normal">{{item?.type}}</td>
            <td>
              <a class="link-class" (click)="navigateToPostAuction(item!.sequenceNo!)">View</a> 
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-between align-items-center" *ngIf="(allSourcingEvents$ | async)!.length > 0">
      <div class="col">
        <div ngbDropdown #menuDropdown="ngbDropdown" container="body" >
          <span class="span-class">Show rows per page </span>
          <span ngbDropdownToggle data-bs-toggle="dropdown">
            <button class="btn btn-sm btn-outline-secondary px-2">{{pageSizeAuctions}}</button>
          </span>
          <ul ngbDropdownMenu class="text-center shadow">
            <li ngbDropdownItem (click)="changePageSize(50, 'AUCTION')">50</li>
            <li>
              <hr class="dropdown-divider " >
            </li>
            <li ngbDropdownItem (click)="changePageSize(100, 'AUCTION')">100</li>
          </ul>
        </div>
      </div>
      <div class="col-auto mt-3">
        <ngb-pagination [collectionSize]="allSourcingEvents.length!" [(page)]="pageAuctions" [pageSize]="pageSizeAuctions"
          (pageChange)="paginateAuctionsList()">
        </ngb-pagination>
      </div>
    </div>
  </div>





