import { ApprovalWorkflowEnum } from "../../enums/ApprovalWorkflowEnum";
import { EventEnum } from "../../enums/EventEnum";
import { UserInfoUiDto } from "../user/UserInfoUiDto";

export class AWFAppliedUiDto {
  orgCode?: string;
  approvalId?: string;
  appliedId?: string;
  resourceId?: string;
  subResourceId?: string;
  resourceType?: EventEnum;
  userId?: string;
  userInfoUiDto?: UserInfoUiDto;
  emailId?: string;
  wfCode?: ApprovalWorkflowEnum;
  sequence?: number;
  updateDateTime?: string;

  version?: number
}