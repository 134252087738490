export enum ActionTypeEnum {
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
  AUCTION_CREATED = "AUCTION_CREATED",
  AUCTION_DISCARD = "AUCTION_DISCARD",
  AUCTION_MARKED_ACTIVE = "AUCTION_MARKED_ACTIVE",
  AUCTION_MARKED_INACTIVE = "AUCTION_MARKED_INACTIVE",
  USER_REGISTRATION_APPROVED = "USER_REGISTRATION_APPROVED",
  USER_REGISTRATION_REJECTED = "USER_REGISTRATION_REJECTED",
  LOT_ADDED = "LOT_ADDED",
  LOT_DISCARD = "LOT_DISCARD",
  LOT_MARKED_ACTIVE = "LOT_MARKED_ACTIVE",
  LOT_MARKED_INACTIVE = "LOT_MARKED_INACTIVE",
  BID_REJECTED = "BID_REJECTED",
  ADMIN_CREATED = "ADMIN_CREATED",
  AUCTIONEER_CREATED = "AUCTIONEER_CREATED"
}
