import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { AwardedBiddersLotsComponent } from 'src/app/shared/components/awarded-bidders-lots/awarded-bidders-lots.component';
import { AwardedBidderDto, AwardedLot } from 'src/app/shared/models/AwardedBidderDto';
import { Currency } from 'src/app/shared/models/Currency';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { NotAwardedLot } from 'src/app/shared/models/user/NotAwardedLot';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-auction-awarded-bidders',
  templateUrl: './auction-awarded-bidders.component.html',
  styleUrls: ['./auction-awarded-bidders.component.sass']
})
export class AuctionAwardedBiddersComponent implements OnInit {

  ctrlSearchBidders: FormControl = new FormControl('');
  ctrlSearchNotAwardedLots: FormControl = new FormControl('');

  currentAuction?: AuctionEntityDto;
  awardedBidders: AwardedBidderDto[] = []
  notAwardedList: NotAwardedLot[] = []

  awardedBiddersLoading: boolean = false;
  notAwardedLotsLoading: boolean = false;
  showGeneratedView: boolean = false;
  pageBidders: number = 1
  pageNotAwarded: number = 1
  pageSizeBidders: number = 50
  pageSizeNotAwarded: number = 50
  currentIndex?: number
  awardUserId?: string;

  awardedBidders$ = new BehaviorSubject<AwardedBidderDto[]>([]);
  notAwardedList$ = new BehaviorSubject<NotAwardedLot[]>([]);

  constructor(
    private adminService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private ngbModal: NgbModal,
    private invoiceService: InvoiceService,
  ) { }

  ngOnInit(): void {
    this.currentAuction = this.adminSourcingEventsDataHolderService.selectedAuction;

    this.loadAwardedBidders()
    this.loadNotAwardedLots();
  }

  // Awarded Bidders
  loadAwardedBidders() {
    this.awardedBidders = [];
    this.paginateBiddersList();
    this.awardedBiddersLoading = true;

    this.adminService.getWinnerBidders(this.currentAuction?.auctionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.awardedBidders = apiResponseDto.data! as Array<AwardedBidderDto>;
        this.awardedBiddersLoading = false;
        this.paginateBiddersList()
      },
      error: (error) => {
        console.error(error);
        this.awardedBiddersLoading = false;
      }
    })
  }


  // Awarded Bidders
  loadNotAwardedLots() {
    this.notAwardedList = [];
    this.paginateNotAwardedLotList();
    this.notAwardedLotsLoading = true;

    this.adminService.getNotAwardedLots(this.currentAuction?.auctionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.notAwardedList = apiResponseDto.data! as Array<NotAwardedLot>;
        this.notAwardedLotsLoading = false;
        this.paginateNotAwardedLotList()
      },
      error: (error) => {
        console.error(error);
        this.notAwardedLotsLoading = false;
      }
    })
  }

  searchBiddersList() {
    let searchText = this.ctrlSearchBidders.value;
    if (searchText.trim() != '') {
      let tempAwardedBidders = [...this.awardedBidders];
      tempAwardedBidders = tempAwardedBidders.filter(item => item.bidderName?.toLowerCase().includes(searchText.toLowerCase()) || item.paddleNo?.toLowerCase().includes(searchText.toLowerCase())
        || item.awardedLots?.some(item1 => item1.lotName?.toLocaleLowerCase().includes(searchText.toLowerCase())) || item.awardedLots?.some(item1 => item1.lotSequenceNo?.includes(searchText))
      )
      this.awardedBidders$.next(tempAwardedBidders);
    } else {
      this.awardedBidders$.next(this.awardedBidders);
    }
  }

  searchNotAwardedLotList() {
    let searchText = this.ctrlSearchNotAwardedLots.value;
    if (searchText.trim() != '') {
      let tempNotAwardedLotList = [...this.notAwardedList];
      tempNotAwardedLotList = tempNotAwardedLotList.filter(item1 => item1.lotName?.toLocaleLowerCase().includes(searchText.toLowerCase()) || item1.lotSequenceNo?.toString()?.includes(searchText)

      )
      this.notAwardedList$.next(tempNotAwardedLotList);
    } else {
      this.notAwardedList$.next(this.notAwardedList);
    }
  }

  paginateBiddersList() {
    if (this.awardedBidders && this.awardedBidders.length > 0) {
      let tempAwardedBidders = [...this.awardedBidders];

      this.awardedBidders$.next(tempAwardedBidders.map((history, i) => ({ id: i + 1, ...history })).slice(
        (this.pageBidders - 1) * this.pageSizeBidders,
        (this.pageBidders - 1) * this.pageSizeBidders + this.pageSizeBidders,
      ));
    } else {
      this.awardedBidders$.next([]);
    }
  }

  paginateNotAwardedLotList() {
    if (this.notAwardedList && this.notAwardedList.length > 0) {
      let tempNotAwardedList = [...this.notAwardedList];

      this.notAwardedList$.next(tempNotAwardedList.map((history, i) => ({ id: i + 1, ...history })).slice(
        (this.pageNotAwarded - 1) * this.pageSizeNotAwarded,
        (this.pageNotAwarded - 1) * this.pageSizeNotAwarded + this.pageSizeNotAwarded,
      ));
    } else {
      this.notAwardedList$.next([]);
    }
  }

  changePageSize(size: number, type: string) {
    if (type == 'BIDDERS') {
      this.pageSizeBidders = size;
    }
    else if (type == 'NOT_AWARDED_LOT') {
      this.pageSizeNotAwarded = size;
    }
  }

  showLotsMore(index: number) {
    this.currentIndex = index;
  }

  getLotsList(awardedLots: AwardedLot[]) {
    let toReturn = awardedLots.sort((a, b) => Number(a.lotSequenceNo!) - Number(b.lotSequenceNo!))
    return toReturn;
  }

  openBiddersAwardedLots(userId?: string) {
    let modalRef = this.ngbModal.open(AwardedBiddersLotsComponent, {
      size: 'xl', keyboard: false, backdrop: 'static', centered: true
    })

    let tempLotAllocationDtos = [...this.awardedBidders];
    let awardedBidderDto = tempLotAllocationDtos.find(item => item.userId == userId)
    this.invoiceService.updateAwardedBidderDto(awardedBidderDto!);
    modalRef.componentInstance.selectedAuction = this.currentAuction;
  }

  openGeneratedInvoiceData(userId: string) {
    this.awardUserId = userId;
    this.showGeneratedView = true;
  }

  getFormattedPrice(price?: string | number, currency?: Currency) {
    return currency?.symbol + ApplicationUtils.getFormattedPrice(currency?.locale, price);
  }

}