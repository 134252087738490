import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { EntityActionLabelEnum } from 'src/app/shared/enums/EntityActionLabelEnum';
import { EntityActionTypeEnum } from 'src/app/shared/enums/EntityActionTypeEnum';
import { EventEnum } from 'src/app/shared/enums/EventEnum';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { EntityActionDto } from 'src/app/shared/models/EntityActionDto';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { QuestionOptions } from 'src/app/shared/models/questionnaire/QuestionOptions';
import { TenderQuestionnaireWrapperDto } from 'src/app/shared/models/tender/TenderQuestionnaireWrapperDto';
import { TenderSectionTemplateDto } from 'src/app/shared/models/tender/TenderSectionTemplateDto';
import { TenderTechnicalQuestionTemplateDto } from 'src/app/shared/models/tender/TenderTechnicalQuestionTemplateDto';
import { TenderDocumentInfoDto } from 'src/app/shared/models/TenderDocumentInfoDto';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { TenderManagementService } from 'src/app/shared/services/tender-management.service';
import { TenderManagementActions } from 'src/app/shared/state-management/tender/tender.actions';
import { selectTenderOpportunityWrapperDto, selectTenderWrapperUiDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-new-tender-technical-question-drawer',
  templateUrl: './new-tender-technical-question-drawer.component.html',
  styleUrls: ['./new-tender-technical-question-drawer.component.sass']
})
export class NewTenderTechnicalQuestionDrawerComponent implements OnInit, OnDestroy {
  @Output() onBackNavigate = new EventEmitter<void>;
  @Output() onSaveQuestion = new EventEmitter<void>;
  @Input() tenderSectionTemplateDto!: TenderSectionTemplateDto;

  @Input() showSuccessToast$ = new Observable<boolean>();
  @Input() showErrorToast$ = new Observable<boolean>();
  @Input() isLoading$ = new Observable<boolean>();
  @Input() errorMessage$ = new Observable<string>();

  documentTypes = [
    'application/pdf', 
    'application/vnd.ms-excel', 
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
    'image/jpeg', 
    'image/png', 
    'application/msword'];
    errorMsg: string | undefined;
    isLoadingModal: boolean = false;


  ctrlQuestionsName: FormControl = new FormControl('', Validators.required);
  ctrlQuestionMandatory: FormControl = new FormControl(false);
  docTitle: FormControl = new FormControl('', [Validators.required, Validators.maxLength(250)]);
  docFile: FormControl = new FormControl('', Validators.required);
  formGroup: FormGroup

  tenderWrapperUiDto?: TenderWrapperUiDto;
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;
  tenderQuestionnaireWrapperDto?: TenderQuestionnaireWrapperDto;
  currentFile?: File | null;
  currentFileInfoDto?: TenderDocumentInfoDto | null;
  technicalquestiondoc:  Array<TenderDocumentInfoDto> = []
  newSubResourceId: string = UUID.UUID().toString();



  tenderTechnicalQuestionTemplateDtos: TenderTechnicalQuestionTemplateDto[] = [];
  selectedTechnicalQuestionTemplateDto?: TenderTechnicalQuestionTemplateDto;

  _showErrorToastModal$ = new BehaviorSubject<boolean>(false);
  _showSuccessToastModal$ = new BehaviorSubject<boolean>(false);

  isShowNewQuestion: boolean = false;
  isShowFeeDocumentDialog: boolean = false;

  tenderWrapperUiDtoSubscription$?: Subscription;
  tenderOpportunityWrapperDtoSubscription$?: Subscription;
  @ViewChild('documentDoc') documentDoc?: ElementRef<HTMLInputElement>;

  private currentIndex: number = -1;


  constructor(
    private store: Store,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private tenderManagementService: TenderManagementService,
  ) {
    this.formGroup = this.fb.group({
      questions: this.fb.array([]),
    })
  }

  ngOnInit(): void {
    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = data;
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
        this.tenderQuestionnaireWrapperDto = this.tenderOpportunityWrapperDto?.tenderQuestionnaireWrapperDto;

        if (this.tenderQuestionnaireWrapperDto) {
          // Technical Questions of Selected Section
          let allTechnicalQuestionTemplateDtos = this.tenderQuestionnaireWrapperDto.tenderTechnicalQuestionTemplateDtos || [];
          this.tenderTechnicalQuestionTemplateDtos = allTechnicalQuestionTemplateDtos.filter(item => item.sectionId == this.tenderSectionTemplateDto.sectionId);

          this.technicalQuestionsFormArray.clear();
          this.tenderTechnicalQuestionTemplateDtos.forEach((techQuestion, index) => {
            this.technicalQuestionsFormArray.push(this.updateQuestionFromGroup(techQuestion));

            // Question Options
            this.questionOptionsFormArray(index).clear();
            (techQuestion.questionOptions || []).forEach(questionOption => {
              this.questionOptionsFormArray(index).push(this.updateQuestionOption(questionOption));
            });

            // Subscribe to changes for this question's options
            this.subscribeToOptionChanges(index);
          })
        }
      } else {
        this.tenderOpportunityWrapperDto = undefined;
        this.tenderQuestionnaireWrapperDto = undefined;
        this.tenderTechnicalQuestionTemplateDtos = [];
      }
    })
  }

  toggleShowNewQuestion() {
    this.isShowNewQuestion = !this.isShowNewQuestion;
    if (!this.isShowNewQuestion) {
      // Reset question names field
    }
  }

  hideAddNewQuestion() {
    this.isShowNewQuestion = false;
  }

  onChangeResponseType(questionIndex: number, responseType: string) {
    this.questionOptionsFormArray(questionIndex).clear();

    if (responseType == QuestionResponseType.SINGLE_CHOICE || responseType == QuestionResponseType.MULTI_CHOICE) {
      this.addNewQuestionOption(questionIndex);
      this.addNewQuestionOption(questionIndex);
    } else {
      this.addNewQuestionOption(questionIndex);
    }
  }

  confirmRemoveQuestion(event: Event, index: number) {
    // this.confirmationService.confirm({
    //   message: 'Are you sure that you want to delete?',
    //   header: 'Delete Question',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
        this.removeQuestionFormGroup(index);
    //   },
    //   reject: () => {
    //     console.log('Cancel Remove Question!');
    //   }
    // });
  }

  addNewQuestions() {
    let questionNames: string = this.ctrlQuestionsName.value.trim();

    if (questionNames) {
      // Split by new lines to get each question name
      let questionNameArray = questionNames.split('\n').filter(name => name.trim() !== '');

      // Create TenderQuestionTemplateDto for each question name
      questionNameArray.forEach((questionName) => {
        let lastSequenceNo = this.tenderTechnicalQuestionTemplateDtos.length;

        let technicalQuestionTemplateDto: TenderTechnicalQuestionTemplateDto = {
          questionId: UUID.UUID().toString(),
          sectionId: this.tenderSectionTemplateDto.sectionId,
          questionnaireId: this.tenderSectionTemplateDto.questionnaireId,
          questionText: questionName.trim(),
          questionDescription: questionName.trim(),
          preSequenceText: this.tenderSectionTemplateDto.preSequenceText,
          sequenceNo: `${lastSequenceNo + 1}`,
          orgCode: this.tenderWrapperUiDto?.orgCode,

          resourceId: this.tenderWrapperUiDto?.tenderId,
          subResourceId: this.tenderOpportunityWrapperDto?.tenderOpportunityId,
          resourceType: EventEnum.TENDER,
          score: 0,
          responseMandatory: true,
          bidderCommentsRequired: false,
          noResponse: false,
          disQualifyForNoResponse: false,
          sampleFile: undefined,
          questionOptions: []
        };

        // Push the newly created section to the tenderSections array
        this.tenderTechnicalQuestionTemplateDtos.push(technicalQuestionTemplateDto);
        this.addNewQuestionFormGroup(technicalQuestionTemplateDto);
        this.saveEntityActionDto(technicalQuestionTemplateDto.questionId!, EntityActionLabelEnum.TECHNICAL, EntityActionTypeEnum.ADD);
      });

      // Optionally, clear the FormControl after adding sections
      this.ctrlQuestionsName.reset();
      this.hideAddNewQuestion();
    }
  }

  margeTenderWrapperUiDto() {
    let tenderWrapperUiDto = ApplicationUtils.clone(this.tenderWrapperUiDto) as TenderWrapperUiDto;
    let tenderOpportunityWrapperDto = ApplicationUtils.clone(this.tenderOpportunityWrapperDto) as TenderOpportunityWrapperDto;
    let tenderQuestionnaireWrapperDto = ApplicationUtils.clone(this.tenderQuestionnaireWrapperDto) as TenderQuestionnaireWrapperDto;

    // Merge FormGroup values in tenderTechnicalQuestionTemplateDtos
    let formValue = this.formGroup.value;
    (formValue.questions as any[]).forEach((question: TenderTechnicalQuestionTemplateDto) => {
      let questionScore = (question.questionOptions || []).reduce((prev, curr) => prev + Number(curr.questionOptionScore || 0), 0);

      let tenderTechnicalQuestionTemplateDto = this.tenderTechnicalQuestionTemplateDtos.find(item => item.questionId == question.questionId)!;
      tenderTechnicalQuestionTemplateDto = {
        ...tenderTechnicalQuestionTemplateDto,
        responseType: question.responseType,
        questionText: question.questionText,
        responseMandatory: question.responseMandatory,
        bidderCommentsRequired: question.bidderCommentsRequired,
        noResponse: question.noResponse,
        disQualifyForNoResponse: question.disQualifyForNoResponse,
        questionOptions: question.questionOptions,
        score: questionScore,
        sampleFile : question.sampleFile,
      }

      let index = this.tenderTechnicalQuestionTemplateDtos.findIndex(item => item.questionId == question.questionId);
      this.tenderTechnicalQuestionTemplateDtos[index] = {
        ...this.tenderTechnicalQuestionTemplateDtos[index],  // Spread the existing properties
        ...tenderTechnicalQuestionTemplateDto  // Override the properties with the updated ones
      };

      this.saveEntityActionDto(tenderTechnicalQuestionTemplateDto.questionId!, EntityActionLabelEnum.TECHNICAL, EntityActionTypeEnum.UPDATE);
    })

    // Update Questions in tenderQuestionnaireWrapperDto
    if (tenderQuestionnaireWrapperDto) {
      tenderQuestionnaireWrapperDto.tenderTechnicalQuestionTemplateDtos = this.tenderTechnicalQuestionTemplateDtos || [];
    }

    // Update Current Section
    this.tenderSectionTemplateDto = {
      ...this.tenderSectionTemplateDto,
      noOfQuestions: this.tenderTechnicalQuestionTemplateDtos.length,
      sectionScore: this.tenderTechnicalQuestionTemplateDtos.reduce((prev, curr) => prev + Number(curr.score || 0), 0)
    }

    let sectionIndex = tenderQuestionnaireWrapperDto.tenderSectionTemplateDtos!.findIndex(item => item.sectionId == this.tenderSectionTemplateDto?.sectionId);
    if (sectionIndex != undefined && sectionIndex > -1) {
      tenderQuestionnaireWrapperDto.tenderSectionTemplateDtos![sectionIndex] = {
        ...tenderQuestionnaireWrapperDto.tenderSectionTemplateDtos![sectionIndex],
        ...this.tenderSectionTemplateDto
      }
    } else {
      tenderQuestionnaireWrapperDto.tenderSectionTemplateDtos!.push(this.tenderSectionTemplateDto);
    }

    this.saveEntityActionDto(this.tenderSectionTemplateDto.sectionId!, EntityActionLabelEnum.SECTION, EntityActionTypeEnum.UPDATE);

    // Update tenderOpportunityWrapperDto
    Object.assign(tenderOpportunityWrapperDto.tenderQuestionnaireWrapperDto!, tenderQuestionnaireWrapperDto);

    let index = tenderWrapperUiDto.tenderOpportunityWrapperDtos!.findIndex(item => item.tenderOpportunityId == tenderOpportunityWrapperDto.tenderOpportunityId);
    if (index != undefined && index > -1) {
      tenderWrapperUiDto.tenderOpportunityWrapperDtos![index] = {
        ...tenderWrapperUiDto.tenderOpportunityWrapperDtos![index],
        ...tenderOpportunityWrapperDto
      }
    } else {
      tenderWrapperUiDto.tenderOpportunityWrapperDtos!.push(tenderOpportunityWrapperDto);
    }

    // Update Tender & Opportunity in Store
    this.store.dispatch(TenderManagementActions.setCurrentTenderOpportunityWrapperDto({ tenderOpportunityWrapperDto }));
    this.store.dispatch(TenderManagementActions.setCurrentTenderWrapperUiDto({ tenderWrapperUiDto }));
  }

  closeQuestionDrawer() {
    this.margeTenderWrapperUiDto();
    this.onBackNavigate.emit();
  }

  saveQuestion() {
    this.margeTenderWrapperUiDto();
    this.onSaveQuestion.emit();
  }
  

  removeSampleDoc(i: number) {
    this.technicalQuestionsFormArray.controls[i].get('sampleFile')?.reset();
    this.technicalQuestionsFormArray.controls[i].get('sampleFile')?.updateValueAndValidity();
  }

  // Questions
  get technicalQuestionsFormArray(): FormArray {
    return this.formGroup.get("questions") as FormArray;
  }

  newQuestionFromGroup(techQuestion: TenderTechnicalQuestionTemplateDto): FormGroup {
    return this.fb.group({
      questionId: [techQuestion.questionId],
      sequenceNo: [techQuestion.sequenceNo],
      responseType: ['', Validators.required],
      questionText: [techQuestion.questionText, Validators.required],
      responseMandatory: [true, Validators.minLength(3)],
      bidderCommentsRequired: [false, Validators.minLength(3)],
      noResponse: [false, Validators.minLength(3)],
      disQualifyForNoResponse: [false, Validators.minLength(3)],
      score: [0],
      sampleFile: [null],
      questionOptions: this.fb.array([]),
    })
  }

  updateQuestionFromGroup(techQuestion: TenderTechnicalQuestionTemplateDto): FormGroup {
    return this.fb.group({
      questionId: [techQuestion.questionId],
      sequenceNo: [techQuestion.sequenceNo],
      responseType: [techQuestion.responseType, Validators.required],
      questionText: [techQuestion.questionText, Validators.required],
      responseMandatory: [techQuestion.responseMandatory, Validators.minLength(3)],
      bidderCommentsRequired: [techQuestion.bidderCommentsRequired, Validators.minLength(3)],
      noResponse: [techQuestion.noResponse, Validators.minLength(3)],
      disQualifyForNoResponse: [techQuestion.disQualifyForNoResponse, Validators.minLength(3)],
      score: [techQuestion.score],
      sampleFile: [techQuestion.sampleFile],
      questionOptions: this.fb.array([]),
    })
  }

  addNewQuestionFormGroup(techQuestion: TenderTechnicalQuestionTemplateDto) {
    this.technicalQuestionsFormArray.push(this.newQuestionFromGroup(techQuestion));

    let questionIndex = this.technicalQuestionsFormArray.controls.length - 1;
    this.subscribeToOptionChanges(questionIndex);
  }

  removeQuestionFormGroup(i: number) {
    let questionId = this.technicalQuestionsFormArray.controls[i].get('questionId')?.value;
    let index = this.tenderTechnicalQuestionTemplateDtos.findIndex(item => item.questionId == questionId);

    this.tenderTechnicalQuestionTemplateDtos.splice(index, 1);
    this.technicalQuestionsFormArray.removeAt(i);

    this.saveEntityActionDto(questionId, EntityActionLabelEnum.TECHNICAL, EntityActionTypeEnum.DELETE);
  }

  // question Options
  questionOptionsFormArray(questionIndex: number): FormArray {
    return this.technicalQuestionsFormArray.controls[questionIndex].get("questionOptions") as FormArray
  }

  newQuestionOption(questionIndex: number): FormGroup {
    let sequenceNo = this.questionOptionsFormArray(questionIndex).controls.length;
    let preSequenceText = ApplicationUtils.alphabetsList()[sequenceNo];
    // let validators = this.isShowOptionTextField(this.selectedTechnicalQuestionTemplateDto?.responseType!) ? Validators.required : null;

    let isScoringTemplate = true//this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES';
    let scoreValidator = isScoringTemplate ? [Validators.required, Validators.pattern(Pattern.onlyNumber)] : null;
    let disqualifyValidator = isScoringTemplate ? Validators.required : null;

    return this.fb.group({
      optionText: ['', Validators.required],
      optionValue: [''],
      optionPreSequence: [preSequenceText, Validators.required],
      optionSequence: [(sequenceNo + 1), Validators.required],
      questionOptionScore: ['', scoreValidator],
      disqualifyChecked: [false, disqualifyValidator],
      uploadRequired: [false, Validators.required],
    })
  }

  updateQuestionOption(questionOptions: QuestionOptions): FormGroup {
    let isScoringTemplate = true//this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES';
    let scoreValidator = isScoringTemplate ? [Validators.required, Validators.pattern(Pattern.onlyNumber)] : null;
    let disqualifyValidator = isScoringTemplate ? Validators.required : null;

    return this.fb.group({
      optionText: [questionOptions.optionText, Validators.required],
      optionValue: [''],
      optionPreSequence: [questionOptions.optionPreSequence, Validators.required],
      optionSequence: [questionOptions.optionSequence, Validators.required],
      questionOptionScore: [questionOptions.questionOptionScore, scoreValidator],
      disqualifyChecked: [questionOptions.disqualifyChecked, disqualifyValidator],
      uploadRequired: [questionOptions.uploadRequired, Validators.required],
    })
  }

  addNewQuestionOption(questionIndex: number) {
    this.questionOptionsFormArray(questionIndex).push(this.newQuestionOption(questionIndex));
  }

  removeQuestionOption(questionIndex: number, optionIndex: number) {
    this.questionOptionsFormArray(questionIndex).removeAt(optionIndex);
  }

  // Calculate Score
  calculateTotalScoreForSection() {
    let questionsArray = this.technicalQuestionsFormArray.controls;
    let totalScore = questionsArray.reduce((totalScore, optionControl) => {
      let optionScore = optionControl.get('score')?.value || 0;
      return totalScore + parseFloat(optionScore);
    }, 0);

    this.tenderSectionTemplateDto = {
      ...this.tenderSectionTemplateDto,  // Copy existing properties
      sectionScore: totalScore  // Update the sectionScore
    };
  }

  calculateTotalScoreForQuestion(questionIndex: number): number {
    let questionOptionsArray = this.questionOptionsFormArray(questionIndex).controls;
    let responseType = this.technicalQuestionsFormArray.controls[questionIndex].get('responseType')?.value;

    if (responseType === QuestionResponseType.MULTI_CHOICE) {
      // Add all option scores for MULTI_CHOICE
      return questionOptionsArray.reduce((totalScore, optionControl) => {
        let optionScore = optionControl.get('questionOptionScore')?.value || 0;
        return totalScore + parseFloat(optionScore);
      }, 0);
    } else {
      // Take only the maximum score for other response types
      return questionOptionsArray.reduce((maxScore, optionControl) => {
        let optionScore = optionControl.get('questionOptionScore')?.value || 0;
        return Math.max(maxScore, parseFloat(optionScore));
      }, 0);
    }
  }

  updateQuestionScore(questionIndex: number): void {
    let totalScore = this.calculateTotalScoreForQuestion(questionIndex);
    this.technicalQuestionsFormArray.controls[questionIndex].get('score')?.setValue(totalScore);
    this.calculateTotalScoreForSection();
  }

  subscribeToOptionChanges(questionIndex: number): void {
    this.questionOptionsFormArray(questionIndex).valueChanges.subscribe(() => {
      this.updateQuestionScore(questionIndex);
    });
  }

  // Save Entity Actions
  saveEntityActionDto(id: string, label: EntityActionLabelEnum, type: EntityActionTypeEnum) {
    let entityActionDto = new EntityActionDto(id, label, type);
    this.store.dispatch(TenderManagementActions.saveEntityActionDto({ entityActionDto }));
  }

  openAddDocumentDialog(index: number) {
    this.currentIndex = index;
    this.docTitle.reset()
    // this.docDescription.reset()
    this.docFile.reset()
    this.isShowFeeDocumentDialog = true
    this.isLoadingModal = false;
    this._showSuccessToastModal$.next(false);
    this.errorMsg = "";
    this._showErrorToastModal$.next(false);
    this.docFile.reset();
    
  }

  chooseFile(event: any) {
    this._showErrorToastModal$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {
      this.errorMsg = "Extension not supported";
      this._showErrorToastModal$.next(true);
      this.currentFile = null;
      this.docFile.reset();
      this.documentDoc!.nativeElement.value = '';
      return;
    }

    this.docFile.patchValue(this.getFileName(this.currentFile?.name!) as string);
  }

  getFileName(fileName: string) {
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0, fileName.lastIndexOf('.'));
    let finalFileName = ApplicationUtils.removeSpecialCharacters(name!) as string
    return finalFileName + "" + extensionName;
  }

  removeDoc() {
    this.currentFileInfoDto = null;
    this.currentFile = null;
    this.docFile.reset();
    this.documentDoc!.nativeElement.value = '';
  }

  deleteNitDocument(index: number) {
    this.technicalquestiondoc.splice(index, 1);
  }

  previewRfxDoc(type: string) {
    if (type == 'LOCAL') {
      var fileURL = URL.createObjectURL(this.currentFile!);
      window.open(fileURL, '_blank');

    } else if (type == 'SERVER') {
      this.isLoadingModal = true;

      this.tenderManagementService.downloadTenderDocument(this.currentFileInfoDto?.fileId!).subscribe({
        next: (response) => {
          if (response) {
            this.isLoadingModal = false;
            let file = new Blob([response], { type: this.currentFileInfoDto?.fileType });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
          }
        },
        error: (err) => {
          console.error(err);
          console.log("Error while previewing document");
        }
      });
    }
  }
  uploadFile() {
    this.isLoadingModal = false;
    this._showSuccessToastModal$.next(false);
    this.errorMsg = "";
    this._showErrorToastModal$.next(false);

    if (this.docTitle.invalid || this.docFile.invalid) {
      this.docTitle.markAllAsTouched();
      this.docFile.markAllAsTouched();
      return;
    }

    let metaData = {
      mimeType: this.currentFile?.type,
      version: 0,
      publicApi: false,
      dataType: 'Square',

      resourceId: this.newSubResourceId,
      subResourceId: undefined,
      resourceType: 'TENDER',
      digitallySigned: undefined,
      validateDownloadTime: undefined,

      title: this.docTitle.value,
    };

    let formData = new FormData();
    formData.append("file", this.currentFile!);
    formData.append('metaData', JSON.stringify(metaData));

    this.isLoadingModal = true;

    this.tenderManagementService.uploadTenderDocument(formData).subscribe(apiResponseDto => {
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let tenderDocumentInfoDto = apiResponseDto.data as TenderDocumentInfoDto;
          this.isLoadingModal = false;
          this.currentFile = null;
          this._showSuccessToastModal$.next(true);
          this.technicalQuestionsFormArray.controls[this.currentIndex!].get('sampleFile')?.patchValue(tenderDocumentInfoDto)
 
          setTimeout(() => {
            this._showSuccessToastModal$.next(false);
            this.closeModal();
          }, 1000);
        } else {
          this.isLoadingModal = false;

          this.errorMsg = "Error while saving Document. Try again.";
          this._showErrorToastModal$.next(true);
        }
      } else {
        this.isLoadingModal = false;

        this.errorMsg = "Error while uploading document. Try again.";
        this._showErrorToastModal$.next(true);
      }
    })
  }

  closeModal(){
    this.isShowFeeDocumentDialog = false
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }

    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }

}
