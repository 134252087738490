<div class="bg-gray-100 gap-2.5 flex py-4 px-6  flex-col self-stretch">
  <div class="flex flex-row justify-between w-full gap-4 items-center">
    <div class="text-xl font-semibold leading-4 text-gray-700">
      Budget
    </div>
    <div class=" text-end">
      <button class="inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800inline-flex" (click)="openNewBudgetDrawer($event)">
        <i-lucide name="plus" [size]="18" color="#fff" class="my-icon"></i-lucide> 
          Add New Budget
      </button>
    </div>
  </div>
  <div class="mt-2.5 w-full">
    <div class="grid grid-cols-1 md:grid-cols-3 w-full gap-4 justify-between items-center mb-4">
      <div class="">
        <div class="flex  flex-col self-stretch gap-4 bg-white rounded-lg p-6 text-center">
          <div class="text-blue-100 text-sm font-semibold">
            Procurement Budget
          </div>
          <div class="text-black text-2xl font-bold">
            {{getFormattedPrice(orgBudgetUiDto?.procurementBudgetAmt)}}
          </div>
        </div>
      </div>
      <div class="">
        <div class="flex  flex-col self-stretch gap-4 bg-white rounded-lg p-6 text-center">
          <div class="text-green-100 text-sm font-semibold">
            Allocated Budget
          </div>
          <div class="text-black text-2xl font-bold">
            {{getFormattedPrice(orgBudgetUiDto?.allocatedAmt)}}
          </div>
        </div>
      </div>
      <div class="">
        <div class="flex  flex-col self-stretch gap-4 bg-white rounded-lg p-6 text-center">
          <div class="text-orange-100 text-sm font-semibold">
            Remaining Budget
          </div>
          <div class="text-black text-2xl font-bold">
            {{getFormattedPrice(orgBudgetUiDto?.unAllocatedAmt)}}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="tr-border">
        <div class="col-12">
          <div class="card ">
            <p-table [value]="ngTableDataList" dataKey="id" [scrollable]="true">
              <ng-template pTemplate="header">
                <tr>
                  <th class="tr-tble-th-font"></th>
                  <th class="tr-tble-th-font tr-th-bg-lightblue" colspan="2">Budget distribution by cost</th>
                  <th class="tr-tble-th-font tr-th-bg-yellow" [colSpan]="plantUiDtos.length">Available Plants</th>
                </tr>
                <tr>
                  <th style="min-width:100px" class="tr-table-th-font-2 tr-th-bg-gray"></th>
                  <th style="min-width:200px" class="tr-table-th-font-2 tr-th-bg-gray">Cost centers</th>
                  <th style="min-width:200px" class="tr-table-th-font-2 tr-th-bg-gray">Budget Amount</th>
                  <th style="min-width:200px" class="tr-table-th-font-2 tr-th-bg-gray"
                    *ngFor="let plant of selectedCompanyUiDto?.plantUiDtos;">
                    {{plant.plantName}} ({{plant.plantCode}})
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-tableData let-i="rowIndex">
                <tr class="bg-white">
                  <td>{{ i+1 }}</td>
                  <td>{{ tableData.costCenterName }}</td>
                  <td>{{ getFormattedPrice(tableData.costCenterBudget) }}</td>
                  <td *ngFor="let plant of tableData.plantsBudget; index as plantIndex;">
                    <span *ngIf="plant.allowBudget">{{ getFormattedPrice(plant.plantBudget) }}</span>
                    <span *ngIf="!plant.allowBudget"> Not Applicable </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>