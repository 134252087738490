<div class="bg-white-new">
  <!-- <div class="col-12 container mt-4 px-0">
    <div class=" nav-bg text-start" *ngIf="activeStage != 'POST_EVENT'">
      <ul class="" style="list-style: none;" class="ps-0">
        <li class="list-class"(click)="toggleDetailsTab(1)" [ngClass]="currentTab == 1 ? 'btn-active' : 'btn-default'">
          Setup
        </li>
        <!-- <li class="list-class" (click)="toggleDetailsTab(2)" [ngClass]="currentTab == 2 ? 'btn-active' : 'btn-default'">
          Registration
        </li>
        <li class="list-class"  (click)="toggleDetailsTab(3)" [ngClass]="currentTab == 3 ? 'btn-active' : 'btn-default'">
         Bidding
        </li>
        <li class="list-class"  (click)="toggleDetailsTab(4)" [ngClass]="currentTab == 4 ? 'btn-active' : 'btn-default'">
          Question
        </li>
      </ul>
    </div>

  </div> -->
  <div class="container">
    <!-- Details -->
    <div *ngIf="_renderView$ | async">
      <app-rfx-creator-setup *ngIf="currentTab == 1 && activeStage != 'POST_EVENT'"></app-rfx-creator-setup>
    
      <!-- Bidder -->
      <app-rfx-creator-registration *ngIf="currentTab == 2 && activeStage != 'POST_EVENT'"></app-rfx-creator-registration>
    
      <!-- Transaction -->
      <app-rfx-creator-bidders *ngIf="currentTab == 3 && activeStage != 'POST_EVENT'"></app-rfx-creator-bidders>
      <app-rfx-question-update *ngIf="currentTab == 4 && activeStage != 'POST_EVENT'"></app-rfx-question-update>
      <app-rfx-evaluation-page *ngIf="activeStage == 'POST_EVENT'"></app-rfx-evaluation-page>
    </div>

    <div class="d-grid gap-2 mb-3" *ngIf="!(_renderView$ | async)">
      <div class="e-spinner center-spinner"></div>
    </div>

  </div>

</div>