import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseOrderItemDto, RfxPurchaseOrderDto } from 'src/app/shared/models/rfx/RfxPurchaseOrderDto';
import { BehaviorSubject, Subscription } from "rxjs";
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { Currency } from 'src/app/shared/models/Currency';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { MobileWrapper } from 'src/app/shared/models/user/MobileWrapper';

declare const require: any;
const html2pdf = require('html2pdf.js');
@Component({
  selector: 'app-admin-purchase-order',
  templateUrl: './admin-purchase-order.component.html',
  styleUrls: ['./admin-purchase-order.component.sass']
})
export class AdminPurchaseOrderComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  isDataLoading: boolean = false;
  isShowPdfView: boolean = false;

  purchaseOrdersList: RfxPurchaseOrderDto[] = [];
  filteredPOsList: RfxPurchaseOrderDto[] = [];

  selectedRfxPurchaseOrderDto?: RfxPurchaseOrderDto;

  filteredPOsList$ = new BehaviorSubject<RfxPurchaseOrderDto[]>([]);

  poSubscription$?: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private changeDetectRef: ChangeDetectorRef,
    private adminDashboardService: AdminDashboardService
  ) { }

  ngOnInit(): void {
    this.isDataLoading = true;
    this.adminDashboardService.getPurchaseOrders().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isDataLoading = false;
          if (apiResponseDto.data) {
            let data = apiResponseDto.data as RfxPurchaseOrderDto[]
            this.purchaseOrdersList = data;

            this.filterAndSortData();
          }
        }
      },
      error: (error) => {
        console.error(error);
        this.isDataLoading = false;
      }
    })
  }

  filterAndSortData() {
    this.filteredPOsList = [...this.purchaseOrdersList];
    this.filteredPOsList$.next(this.filteredPOsList);
  }

  openPurchaseOrder(content: any, rfxPurchaseOrderDto: RfxPurchaseOrderDto) {
    this.selectedRfxPurchaseOrderDto = rfxPurchaseOrderDto;

    this.ngbModal.open(content, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  closeModal() {
    this.ngbModal.dismissAll()
  }

  getFormattedPrice(currency: Currency, price: any) {
    return currency.symbol + ApplicationUtils.getFormattedPrice(currency.locale, price);
  }

  getFormattedMobileNo(mobileWrapper?: MobileWrapper) {
    let countryCodeDto = mobileWrapper?.countryCode;
    if (countryCodeDto) {
      let countryCode = countryCodeDto.countryCode?.replace('(', '').replace(')', '');
      return countryCode + ' ' + mobileWrapper?.mobileNo;
    }
    return '+1 ' + mobileWrapper?.mobileNo;
  }

  getTotalAdditionalPrices(item: PurchaseOrderItemDto) {
    if (item.additionalPrices && item.additionalPrices.length > 0) {
      let additionalPrices = item.additionalPrices.reduce((prev, curr) => prev + Number(curr.additionalPrice), 0);
      return this.getFormattedPrice(this.selectedRfxPurchaseOrderDto?.currency!, additionalPrices);
    }
    return this.getFormattedPrice(this.selectedRfxPurchaseOrderDto?.currency!, 0);
  }

  generatePdf() {
    this.isLoading = true;
    this.isShowPdfView = true;
    this.changeDetectRef.detectChanges();

    const element = document.getElementById('pdfContent');

    let auctionHouseDto = this.adminDashboardService.getCurrentAuctionHouse$.value;
    const secondaryColor = auctionHouseDto?.secondaryColor ?? '#1b5887';

    if (element) {
      let opt = {
        // margin: [25, 12, 30, 12],
        filename: 'PO_' + "test" + '.pdf',
        html2canvas: { scale: 6 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };

      html2pdf().from(element).set(opt).set({ margin: [25, 12, 25, 12] }).toPdf().get('pdf').then((pdf: any) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          pdf.setDrawColor(secondaryColor);
          pdf.setLineWidth(1);
          pdf.line(20, pdf.internal.pageSize.getHeight() - 25, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 25);

          pdf.setFontSize(9);
          pdf.setTextColor('#000000');

          let text = `Page ${i}`;
          let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          let textOffset = (pdf.internal.pageSize.getWidth() - textWidth) / 2;

          pdf.text(text, textOffset, pdf.internal.pageSize.getHeight() - 10);
        }

        this.isLoading = false;
        this.isShowPdfView = false;
        // this.changeDetectRef.detectChanges();
      }).save();
    }
  }
  
  ngOnDestroy(): void {
    if (this.poSubscription$) {
      this.poSubscription$.unsubscribe();
    }
  }
}
