import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDropdown, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { QuestionnaireStatus } from 'src/app/shared/enums/questionnaire/QuestionnaireStatus';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { QuestionOptions } from 'src/app/shared/models/questionnaire/QuestionOptions';
import { QuestionnaireValidationDto } from 'src/app/shared/models/questionnaire/QuestionnaireValidationDto';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { ResourceSwapDto } from 'src/app/shared/models/questionnaire/ResourceSwapDto';
import { ResourceSwapWrapperDto } from 'src/app/shared/models/questionnaire/ResourceSwapWrapperDto';
import { SectionTemplate } from 'src/app/shared/models/questionnaire/SectionTemplate';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { FileService } from 'src/app/shared/services/file.service';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-rfx-questionnaire-technical-question',
  templateUrl: './rfx-questionnaire-technical-question.component.html',
  styleUrls: ['./rfx-questionnaire-technical-question.component.sass']
})
export class RfxQuestionnaireTechnicalQuestionComponent implements OnInit, OnDestroy {
  @Input() selectedQuestionId?: string;

  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  selectedSectionTemplate?: SectionTemplate
  technicalQuestions: TechnicalQuestionTemplate[] = []
  selectedRfx?: RfxUiDto;
  selectedRfxSubcategory?: RfxSubcategoryUiDto;

  formGroup: FormGroup

  newQuestionTemplate?: TechnicalQuestionTemplate;
  selectedQuestionTemplate?: TechnicalQuestionTemplate;
  questionnaireValidationList: QuestionnaireValidationDto[] = [];

  responseTypesList: QuestionResponseType[] = [
    QuestionResponseType.SINGLE_CHOICE,
    QuestionResponseType.MULTI_CHOICE,
    QuestionResponseType.DATE,
    QuestionResponseType.DATE_RANGE,
    QuestionResponseType.ALPHANUMERIC
  ]

  addQuestionModalRef?: NgbModalRef;
  deleteConfirmModalRef?: NgbModalRef;

  countDraftQuestions: number = 0
  countPublishedQuestions: number = 0
  isLoading: boolean = false;
  isPrevButtonDisabled: boolean = false;
  isNextButtonDisabled: boolean = false;
  questionScoreValidationFailed: boolean = false;
  countTechnicalQuestions: number = 0;

  documentTypes = ['application/pdf'];
  fileUploadError: boolean = false;
  errorMsg: string | undefined;
  currentFileInfoDto?: FileInfoDto;
  currentFile?: File | null;
  selectedFileName: string = "";

  validationSubscription$?: Subscription
  questionnaireWrapperDtoSubscription$?: Subscription
  selectedSectionSubscription$?: Subscription
  selectedRfxSubcategorySubscription$?: Subscription

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  @ViewChild('sampleFile') sampleFile?: HTMLInputElement;
  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;

  isTechnicalActive = true;
  isQuestionnaireAvailable = true;
  isSelectOption = true;

  constructor(
    private ngbModal: NgbModal,
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private fileService: FileService,
    private questionnaireService: QuestionnaireService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
  ) {
    this.formGroup = this.fb.group({
      preSequenceText: ['', Validators.required],
      sequenceNo: ['', Validators.required],
      questionText: ['', Validators.required],
      questionDescription: [''],
      openerComments: [''],
      responseMandatory: [true, Validators.minLength(3)],
      bidderCommentsRequired: [false, Validators.minLength(3)],
      noResponse: [false, Validators.minLength(3)],
      disQualifyForNoResponse: [false, Validators.minLength(3)],
      questionStatus: [''],
      questionOptions: this.fb.array([]),
      responseType: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.selectedRfx = this.adminSourcingEventsDataHolderService.selectedRfx;
    this.selectedRfxSubcategorySubscription$ = this.adminRfxSubcategoryDataHolderService.selectedRfxSubcategory$.subscribe(data => {
      if (data) {
        this.selectedRfxSubcategory = data;
      } else {
        this.selectedRfxSubcategory = undefined;
      }
    });

    // this.countDraftQuestions = this.technicalQuestions?.filter(item => item.questionStatus == QuestionnaireStatus.DRAFT).length;
    // this.countPublishedQuestions = this.technicalQuestions?.filter(item => item.questionStatus == QuestionnaireStatus.PUBLISHED).length;

    this.validationSubscription$ = this.questionnaireService.getQuestionnaireValidationList$.subscribe(data => {
      if (data && data.length > 0) {
        this.questionnaireValidationList = data;
      } else {
        this.questionnaireValidationList = [];
      }
    })

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        if (this.selectedSectionTemplate) {
          this.technicalQuestions = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == this.selectedSectionTemplate?.sectionId) ?? [];
          this.technicalQuestions.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
        }
        this.questionScoreValidationFailed = this.questionnaireService.checkQuestionScoreValidation(this.selectedSectionTemplate?.sectionId!);
      }
    })

    this.selectedSectionSubscription$ = this.questionnaireService.getSelectedSection$.subscribe(data => {
      if (data) {
        this.selectedSectionTemplate = data;
        this.technicalQuestions = this.questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == data.sectionId) ?? [];
        this.technicalQuestions.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
        this.questionScoreValidationFailed = this.questionnaireService.checkQuestionScoreValidation(this.selectedSectionTemplate?.sectionId!);

        this.questionnaireService.runTechQuestionnaireValidation();
      }
    })
  }

  get fc() { return this.formGroup.controls; }

  populateQuestionData() {
    this.questionOptions.clear();
    this.formGroup.reset();
    
    this.formGroup.controls['sequenceNo'].patchValue(this.selectedQuestionTemplate?.sequenceNo);
    this.formGroup.controls['preSequenceText'].patchValue(this.selectedSectionTemplate?.preSequenceText);

    this.formGroup.controls['questionText'].patchValue(this.selectedQuestionTemplate?.questionText);
    this.formGroup.controls['questionDescription'].patchValue(this.selectedQuestionTemplate?.questionDescription);
    this.formGroup.controls['openerComments'].patchValue(this.selectedQuestionTemplate?.openerComments);
    this.formGroup.controls['responseMandatory'].patchValue(this.selectedQuestionTemplate?.responseMandatory);
    this.formGroup.controls['bidderCommentsRequired'].patchValue(this.selectedQuestionTemplate?.bidderCommentsRequired);
    this.formGroup.controls['noResponse'].patchValue(this.selectedQuestionTemplate?.noResponse);
    this.formGroup.controls['disQualifyForNoResponse'].patchValue(this.selectedQuestionTemplate?.disQualifyForNoResponse);
    this.formGroup.controls['questionStatus'].patchValue(this.selectedQuestionTemplate?.questionStatus);
    this.formGroup.controls['responseType'].patchValue(this.selectedQuestionTemplate?.responseType);

    if (this.selectedQuestionTemplate?.questionOptions && this.selectedQuestionTemplate?.questionOptions.length > 0) {
      this.questionOptions.clear();
      this.selectedQuestionTemplate.questionOptions.forEach(questionOption => {
        this.questionOptions.push(this.updateQuestionOption(questionOption));
      })
    }

    if (this.selectedQuestionTemplate?.sampleFile) {
      this.currentFileInfoDto = this.selectedQuestionTemplate.sampleFile;
      this.selectedFileName = this.currentFileInfoDto?.displayName!;
    } else {
      this.removeSampleDoc();
    }

    this.formGroup.updateValueAndValidity();

    this.questionScoreValidationFailed = this.questionnaireService.checkQuestionScoreValidation(this.selectedSectionTemplate?.sectionId!);

    this.checkPrevNextNavigationButton();
  }

  openAddQuestionModal(content: any, questionTemplate?: TechnicalQuestionTemplate) {
    if (!this.selectedSectionTemplate?.sectionName) {
      return;
    }

    this._showErrorToast$.next(false);
    this.errorMsg = "";

    if (questionTemplate == undefined) {
      this.addNewQuestion();
    } else {
      this.newQuestionTemplate = undefined;
      this.countTechnicalQuestions = this.technicalQuestions.length;
      this.selectedQuestionTemplate = questionTemplate;
      this.populateQuestionData();
    }

    this.addQuestionModalRef = this.ngbModal.open(content, {
      size: 'xl', backdrop: 'static', keyboard: false,
      centered: true,
    })
  }

  addNewQuestion() {
    this.questionOptions.clear();
    this.formGroup.reset();
    this.removeSampleDoc();

    let sequenceNo = this.technicalQuestions.length;

    this.newQuestionTemplate = new TechnicalQuestionTemplate();
    this.newQuestionTemplate!.sequenceNo = (sequenceNo + 1).toString();
    this.newQuestionTemplate!.preSequenceText = this.selectedSectionTemplate?.preSequenceText;
    this.newQuestionTemplate!.responseMandatory = true;

    this.countTechnicalQuestions = this.technicalQuestions.length + 1;
    this.selectedQuestionTemplate = this.newQuestionTemplate;
    this.populateQuestionData();
  }

  openDeleteConfirmModal(content: any) {
    this.deleteConfirmModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false,
      centered: true,
    })
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }

    this.selectedQuestionTemplate = undefined;
  }

  onChangeResponseType(responseType: QuestionResponseType, ngbDropdown: NgbDropdown) {
    this.formGroup.controls['responseType'].patchValue(responseType);
    this.formGroup.updateValueAndValidity();

    this.selectedQuestionTemplate!.responseType = responseType;

    this.questionOptions.clear()
    if (this.questionOptions.controls.length == 0) {
      if (responseType == QuestionResponseType.SINGLE_CHOICE || responseType == QuestionResponseType.MULTI_CHOICE) {
        this.addQuestionOption();
        this.addQuestionOption();
      } else {
        this.addQuestionOption();
      }
    }

    if (ngbDropdown.isOpen()) {
      ngbDropdown.close();
    }
  }

  getTitleResponseType(responseType: QuestionResponseType) {
    if (responseType == QuestionResponseType.SINGLE_CHOICE) {
      return "Single Choice Response";
    } else if (responseType == QuestionResponseType.MULTI_CHOICE) {
      return "Multi Choice Response";
    } else if (responseType == QuestionResponseType.DATE) {
      return "Date Response";
    } else if (responseType == QuestionResponseType.DATE_RANGE) {
      return "Date Range Response";
    } else if (responseType == QuestionResponseType.ALPHANUMERIC) {
      return "Alphanumeric Response";
    }
    return "Select Response Type";
  }

  getTitleQuestionStatus(status?: string) {
    if (status == QuestionnaireStatus.PUBLISHED) {
      return 'Published';
    }
    return 'Draft';
  }

  isShowOptionTextField(responseType: QuestionResponseType) {
    return responseType == QuestionResponseType.SINGLE_CHOICE || responseType == QuestionResponseType.MULTI_CHOICE;
  }

  getRemainingScoreOfSection() {
    return this.questionnaireService.getRemainingScoreOfSection(this.selectedSectionTemplate?.sectionId!);
  }

  chooseFile(event: any) {
    // this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      // this._showErrorToast$.next(true);
      this.currentFile = null;
      this.sampleFile?.setAttribute('value', '');

      return;
    }

    this.selectedFileName = this.getFileName(this.currentFile?.name!) as string;
  }

  removeSampleDoc() {
    this.currentFileInfoDto = undefined;
    this.currentFile = null;
    this.sampleFile?.setAttribute('value', '');
    this.selectedFileName = "";
  }

  getFileName(fileName: string) {
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0, fileName.lastIndexOf('.'));
    let finalFileName = ApplicationUtils.removeSpecialCharacters(name!) as string
    return finalFileName + "" + extensionName;
  }

  previewSampleDoc(type: string) {
    if (type == 'LOCAL') {
      var fileURL = URL.createObjectURL(this.currentFile!);
      window.open(fileURL, '_blank');

    } else if (type == 'SERVER') {
      this.isLoading = true;

      this.fileService.downloadFile(this.currentFileInfoDto?.fileId!).subscribe({
        next: (response) => {
          if (response) {
            this.isLoading = false;
            let file = new Blob([response], { type: this.currentFileInfoDto?.fileType });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
          }
        },
        error: (err) => {
          console.error(err);
          console.log("Error while previewing document");
        }
      });
    }
  }

  mergeTechQuestionTemplate() {
    let questionTemplate: TechnicalQuestionTemplate = ApplicationUtils.clone(this.selectedQuestionTemplate);

    let formGroupValue = this.formGroup.value;
    let score = formGroupValue.questionOptions &&
      formGroupValue.questionOptions.length > 0
      ? formGroupValue.questionOptions.sort(
        (a: QuestionOptions, b: QuestionOptions) =>
          Number(b.questionOptionScore) - Number(a.questionOptionScore)
      )[0].questionOptionScore
      : 0;

    if (!questionTemplate.questionId) {
      questionTemplate.questionId = UUID.UUID().toString();
      questionTemplate.orgCode = this.selectedRfxSubcategory?.orgCode;
      questionTemplate.rfxId = this.selectedRfxSubcategory?.rfxId;
      questionTemplate.rfxSubcategoryId = this.selectedRfxSubcategory?.subcategoryId;
      questionTemplate.auctionId = 'NA';
    }


    questionTemplate.sectionId = this.selectedSectionTemplate?.sectionId;
    questionTemplate.questionnaireId = this.selectedSectionTemplate?.questionnaireId;
    questionTemplate.questionText = formGroupValue.questionText ? formGroupValue.questionText.trim() : '';
    questionTemplate.questionDescription = formGroupValue.questionDescription;
    questionTemplate.preSequenceText = this.selectedSectionTemplate?.preSequenceText;
    questionTemplate.sequenceNo = formGroupValue.sequenceNo;
    questionTemplate.score = score;
    questionTemplate.openerComments = formGroupValue.openerComments;
    questionTemplate.responseMandatory = formGroupValue.responseMandatory;
    questionTemplate.bidderCommentsRequired = formGroupValue.bidderCommentsRequired;
    questionTemplate.noResponse = formGroupValue.noResponse;
    questionTemplate.disQualifyForNoResponse = formGroupValue.disQualifyForNoResponse;
    questionTemplate.questionStatus = formGroupValue.questionStatus;
    questionTemplate.responseType = formGroupValue.responseType;
    questionTemplate.questionOptions = formGroupValue.questionOptions;


    return questionTemplate;
  }

  async uploadSampleFile() {
    this.fileUploadError = false;
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let formData = new FormData();

    let metaData = {
      'mimeType': this.currentFile?.type,
      'version': 0,
      'publicApi': true,
      'dataType': 'Rectangle'
    };

    formData.append('file', this.currentFile!);
    formData.append('metaData', JSON.stringify(metaData));

    try {
      let apiResponseDto = await firstValueFrom(this.fileService.uploadFile(formData));
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          this.currentFileInfoDto = new FileInfoDto();
          this.currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          this.currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.fileSize = this.currentFile?.size.toString();
          this.currentFileInfoDto.fileType = this.currentFile?.type;
          this.currentFileInfoDto.uploadDate = new Date().toDateString();
        } else {
          this.isLoading = false;
          this.fileUploadError = true;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto.message;
        }
      } else {
        this.isLoading = false;
        this.fileUploadError = true;

        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving question, file upload failed. Try again.";
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      this.fileUploadError = true;

      this._showErrorToast$.next(true);
      this.errorMsg = "Error while saving question, file upload failed. Try again.";
    }
  }

  saveQuestionTemplate = async () => {
    if (this.formGroup.invalid || this.questionOptions.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let questionTemplate = this.mergeTechQuestionTemplate();

    // sampleFile
    if (this.currentFile) {
      await this.uploadSampleFile();
      questionTemplate.sampleFile = this.currentFileInfoDto;
    }

    if (this.fileUploadError) {
      return;
    }

    this.questionnaireService.saveTechQuestionTemplate(questionTemplate).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          this.selectedQuestionTemplate = questionnaireWrapperDto.technicalQuestionTemplates?.find(item => item.questionId == questionTemplate.questionId);
          this.populateQuestionData();

          if (questionTemplate.sequenceNo == this.newQuestionTemplate?.sequenceNo) {
            this.newQuestionTemplate = undefined;
          }

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            // this.closeModal(this.addQuestionModalRef);
          }, 2000)

        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving question. Try again.";
      }
    })
  }

  deleteQuestionTemplate() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    this.questionnaireService.deleteQuestion(this.selectedQuestionTemplate?.questionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.deleteConfirmModalRef!);
            this.closeModal(this.addQuestionModalRef!);
          }, 2000)

        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while deleting question. Try again.";
      }
    })
  }

  previousQuestion() {
    let currentQuestionSequence = this.formGroup.controls['sequenceNo'].value;

    if (Number(currentQuestionSequence) == 1) {
      return;
    }

    if (currentQuestionSequence == this.newQuestionTemplate?.sequenceNo) {
      this.newQuestionTemplate = this.mergeTechQuestionTemplate();
    }

    if (Number(currentQuestionSequence) > 1) {
      this.selectedQuestionTemplate = this.technicalQuestions[Number(currentQuestionSequence) - 2];
      this.populateQuestionData();
    }

    this.checkPrevNextNavigationButton();
  }

  nextQuestion() {
    let currentQuestionSequence = this.formGroup.controls['sequenceNo'].value;

    if (Number(currentQuestionSequence) == this.countTechnicalQuestions) {
      return;
    }

    if (Number(currentQuestionSequence) < this.countTechnicalQuestions) {
      if (Number(currentQuestionSequence) == Number(this.newQuestionTemplate?.sequenceNo) - 1) {
        this.selectedQuestionTemplate = this.newQuestionTemplate
      } else {
        this.selectedQuestionTemplate = this.technicalQuestions[Number(currentQuestionSequence)];
      }
      this.populateQuestionData();
    }

    this.checkPrevNextNavigationButton();
  }

  checkPrevNextNavigationButton() {
    let currentQuestionSequence = this.formGroup.controls['sequenceNo'].value;

    if (this.countTechnicalQuestions == 0) {
      this.isPrevButtonDisabled = true;
      this.isNextButtonDisabled = true;
    }

    if (Number(currentQuestionSequence) == 1) {
      this.isPrevButtonDisabled = true;
    } else {
      this.isPrevButtonDisabled = false;
    }

    if (Number(currentQuestionSequence) == this.countTechnicalQuestions) {
      this.isNextButtonDisabled = true;
    } else {
      this.isNextButtonDisabled = false;
    }
  }

  // question Options
  get questionOptions(): FormArray {
    return this.formGroup.get("questionOptions") as FormArray
  }

  newQuestionOption(): FormGroup {
    let sequenceNo = this.questionOptions.controls.length;
    let preSequenceText = ApplicationUtils.alphabetsList()[sequenceNo];
    let validators = this.isShowOptionTextField(this.selectedQuestionTemplate?.responseType!) ? Validators.required : null;

    let isScoringTemplate = this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES';
    let scoreValidator = isScoringTemplate ? [Validators.required, Validators.pattern(Pattern.onlyNumber)] : null;
    let disqualifyValidator = isScoringTemplate ? Validators.required : null;

    return this.fb.group({
      optionText: ['', validators],
      optionValue: [''],
      optionPreSequence: [preSequenceText, Validators.required],
      optionSequence: [(sequenceNo + 1), Validators.required],
      questionOptionScore: ['', scoreValidator],
      disqualifyChecked: [false, disqualifyValidator],
      uploadRequired: [false, Validators.required],
    })
  }

  updateQuestionOption(option: QuestionOptions): FormGroup {
    let validators = this.isShowOptionTextField(this.selectedQuestionTemplate?.responseType!) ? Validators.required : null;
    let isScoringTemplate = this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES';
    let scoreValidator = isScoringTemplate ? [Validators.required, Validators.pattern(Pattern.onlyNumber)] : null;
    let disqualifyValidator = isScoringTemplate ? Validators.required : null;

    return this.fb.group({
      optionText: [option.optionText, validators],
      optionValue: [option.optionValue],
      optionPreSequence: [option.optionPreSequence, Validators.required],
      optionSequence: [option.optionSequence, Validators.required],
      questionOptionScore: [option.questionOptionScore, scoreValidator],
      disqualifyChecked: [option.disqualifyChecked, disqualifyValidator],
      uploadRequired: [option.uploadRequired, Validators.required],
    })
  }

  addQuestionOption() {
    this.questionOptions.push(this.newQuestionOption());
  }

  removeQuestionOption(i: number) {
    this.questionOptions.removeAt(i);
  }

  handleQuestionsOrders() {
    let resourceSwapDtoList: ResourceSwapDto[] = [];

    this.technicalQuestions.forEach((item, index) => {
      let resourceSwapDto = new ResourceSwapDto();
      resourceSwapDto.resourceId = item.questionId;
      resourceSwapDto.sequenceNo = `${index + 1}`;
      resourceSwapDto.pretext = this.selectedSectionTemplate?.preSequenceText;
      resourceSwapDtoList.push(resourceSwapDto);
    })

    let resourceSwapWrapperDto = new ResourceSwapWrapperDto();
    resourceSwapWrapperDto.sourcingEnvelopeType = SourcingEnvelopeType.TECHNICAL;
    resourceSwapWrapperDto.resourceSwapDtoList = resourceSwapDtoList;

    this.appLoader?.openLoaderIcon('QUESTION_REORDER', 'Reordering..');
    this.questionnaireService.updateQuestionOrdering(resourceSwapWrapperDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.appLoader?.closeLoaderIcon('QUESTION_REORDER');

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);
        } else {
          this.appLoader?.closeLoaderIcon('QUESTION_REORDER');
        }
      },
      error: (err) => {
        console.error(err);
        this.appLoader?.closeLoaderIcon('QUESTION_REORDER');
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.technicalQuestions, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.handleQuestionsOrders();
    }
  }

  ngOnDestroy(): void {
    if (this.validationSubscription$) {
      this.validationSubscription$.unsubscribe();
    }
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
    if (this.selectedSectionSubscription$) {
      this.selectedSectionSubscription$.unsubscribe();
    }
    if (this.selectedRfxSubcategorySubscription$) {
      this.selectedRfxSubcategorySubscription$.unsubscribe();
    }
  }
}
