<div class="bg-gray-100 gap-2.5 py-4 px-6 item-start flex-col self-stretch">
  <div class="my-3 text-lg font-semibold leading-6 text-gray-700 tracking-tighter">
    Basic Information
  </div>
  <div class="mt-2 col-12">
    <div class="bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50 tr-margin-right-24px">
      <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class="col-span-2 text-gray-500 font-semibold leading-4 tracking-tight text-base">
            <span class="">
              <img src="/assets/icons/tr-basic-detail.svg" class="tr-md-icon  inline-flex">
            </span>
            Basic Details
          </div>
          <div class="text-end  text-sm font-medium leading-4 text-blue-100 tracking-tight ">
            <span class="c-pointer" (click)="openEditCompanyDrawer('BASIC_DETAILS')" >
              <i-lucide name="pencil" [size]="15"  class="c-pointer my-icon inline-flex"></i-lucide>
              Edit
            </span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full tr-padding-16px">
        <div class="">
          <div class="grid grid-cols-3 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Company Code
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.companyCode}}
            </div>

            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Registered Region
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.region}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Phone Number
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{getCountryCode(selectedCompanyUiDto?.phoneCountryCode)}}{{selectedCompanyUiDto?.phoneNumber}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Branch
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.branch}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Timezone
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.timeZone}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Currency
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.currencyDto?.name}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Tax Id 1
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.taxId1}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Fiscal Year Start
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{getFormattedFinancialYear(selectedCompanyUiDto?.fiscalYrStart!)}}
            </div>
          </div>
        </div>
        <div class="">
          <div class="grid grid-cols-3 w-full">
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Company Legal Type
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.legalType}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
             Language
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.languageDto?.languageCode}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Industry Sector
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.industrySector}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              FAX Number
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{getCountryCode(selectedCompanyUiDto?.faxCountryCode)}}{{selectedCompanyUiDto?.faxNumber}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Primary Company
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.primaryCompany ? 'Yes' : 'No'}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Registered Country
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.country}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              FAX Number
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.legalType}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Tax Id 2
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedCompanyUiDto?.taxId2}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Fiscal Year End
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{getFormattedFinancialYear(selectedCompanyUiDto?.fiscalYrEnd!)}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
    <div class="">
      <div class="mt-2 col-12">
        <div class="bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
          <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
            <div class="grid grid-cols-3 gap-4 w-full">
              <div class=" col-span-2 text-gray-500 font-semibold leading-4 tracking-tight text-base">
                <span class="">
                  <img src="/assets/icons/tr-bank-detail.svg" class="tr-md-icon inline-flex">
                </span>
                Bank Details
              </div>
             
              <div class="text-end  text-sm font-medium leading-4 text-blue-100 tracking-tight ">
                <span class="c-pointer" (click)="openEditCompanyDrawer('BANK_DETAILS')">
                  <i-lucide name="pencil" [size]="15"  class="c-pointer my-icon inline-flex"></i-lucide>
                  Edit
                </span>
              </div>
              
            </div>
          </div>
          <div class="grid grid-cols-3 w-full p-4">
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Account Title
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedBankAccount?.bankTitle}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Account Holder Name
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedBankAccount?.bankAccHolder}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Account Number
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedBankAccount?.bankAccNo}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Account Type
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedBankAccount?.bankAccType}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Account Code
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedBankAccount?.companyCode}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Swift
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedBankAccount?.bankAccSwift}}
            </div>
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Iban
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              {{selectedBankAccount?.bankAccIban}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="mt-2 col-12">
        <div class="bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
          <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
            <div class="grid grid-cols-3 gap-4 w-full">
              <div class="col-span-2 text-gray-500 font-semibold leading-4 tracking-tight text-base">
                <span class="">
                  <img src="/assets/icons/tr-address.svg" class="tr-md-icon inline-flex">
                </span>
                Address Details
              </div>
             
              <div class="text-end  text-sm font-medium leading-4 text-blue-100 tracking-tight ">
                <span class="c-pointer" (click)="openEditCompanyDrawer('BILLING_AND_SHIPPING')">
                  <i-lucide name="pencil" [size]="15"  class="c-pointer my-icon inline-flex"></i-lucide>
                  Edit
                </span>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 w-full p-4">
            <div class=" text-black font-semibold leading-normal text-sm mb-4">
              Billing Address
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              <div>{{selectedBillingAddress?.addressType}}</div>
              {{selectedBillingAddress?.addressLine1}}, {{selectedBillingAddress?.addressLine2 ?
              selectedBillingAddress?.addressLine2 + ', ' : ''}}
              {{selectedBillingAddress?.city}}, {{selectedBillingAddress?.state}}, {{selectedBillingAddress?.country}} -
              {{selectedBillingAddress?.zipCode}}
            </div>
          </div>
          <div class="grid grid-cols-1 gap-4 w-full p-4">
            <hr class="hr">
          </div>
            <div class="grid grid-cols-3 gap-4 w-full p-4">
            <div class=" text-black font-semibold leading-normal text-sm mb-4 ">
              Shipping Address
            </div>
            <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
              <div>{{selectedShippingAddress?.addressType}}</div>
              {{selectedShippingAddress?.addressLine1}}, {{selectedShippingAddress?.addressLine2 ?
              selectedShippingAddress?.addressLine2 + ', ' : ''}}
              {{selectedShippingAddress?.city}}, {{selectedShippingAddress?.state}},
              {{selectedShippingAddress?.country}} - {{selectedShippingAddress?.zipCode}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>