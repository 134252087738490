import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuctionEntityDto } from '../models/user/AuctionEntityDto';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  allAuctions$ = new BehaviorSubject<AuctionEntityDto[]>([]);
  selectedAuction$ = new BehaviorSubject<AuctionEntityDto|null>(null);

  get getAllAuctions$() { return this.allAuctions$.asObservable(); }
  get getSelectedAuctions$() { return this.selectedAuction$.asObservable(); }
  constructor() { }

  setAllAuctions(allAuctions: AuctionEntityDto[]) {
    this.allAuctions$.next(allAuctions);
  }

  updateSelectAuction(auction: AuctionEntityDto| null){
    this.selectedAuction$.next(auction);
  }
}
