<div class="">
  <div class="flex flex-row gap-4 items-center justify-between h-14  px-6 py-4">
    <div class="basis-1/2">
      <div class="inline-block">
        <div class="font-semibold text-black text-xl" *ngIf="currentView == opportunityPages.BASIC">
          Creating new opportunity
        </div>
        <div *ngIf="currentView == opportunityPages.CONFIGURATION">
          <div class="font-semibold text-black text-xl">
            Add Configuration
          </div>
        </div>
        <div *ngIf="currentView == opportunityPages.FEE">
          <div class="font-semibold text-black text-xl">
            Add Fee
          </div>
        </div>
        <div *ngIf="currentView == opportunityPages.CRITICAL_DATES">
        <div class="font-semibold text-black text-xl">
          Add Critical Dates
        </div>
      </div>
      </div>
    </div>
    <!-- <div class="basis-1/2 text-end ">
      <i-lucide name="info" [size]="18" class="my-icon inline-block"></i-lucide> All fields are mandatory
    </div> -->
  </div>
  <div class="bg-gray-100 h-8 py-2 px-6 flex items-center flex">
    <div class="inline-flex font-light text-base text-gray-600 me-2">Title:</div>
    <div class="inline-flex font-semibold text-base text-gray-800 me-3 overflow-class-small"  [pTooltip]="tooltipProjects" tooltipPosition="top" placeholder="Top">
      {{tenderWrapperUiDto?.title}}
      <ng-template #tooltipProjects>
        <div class="flex items-center p-2">
          {{tenderWrapperUiDto?.title}}
        </div>
      </ng-template>
    </div>
    <span class="font-light text-base text-gray-600 me-2">Ref. number:</span>
    <span class="font-semibold text-base text-gray-800 me-3">
      {{tenderWrapperUiDto?.tenderReferenceNo}}
    </span>
  </div>
  <div class="w-full " *ngIf="currentView == opportunityPages.BASIC">
    <form [formGroup]="formGroup">
    <div class="">
        <div class="p-6 tr-overflow-y-drawer">
          <div class="w-4/5 mb-10">
            <div class="text-black text-xl text-semibold mb-6">
              Basic
            </div>
            <div class="grid grid-cols-12 mb-4">
              <div class="col-span-6">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Title</div>
                  <div class="text-gray-500 text-xs font-normal leading-4">
                    Name of the opportunity
                  </div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-700 text-xs"
                  type="text" formControlName="title" id="title" placeholder="---"
                  [ngClass]="{'is-invalid': fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)}">
                <div class="text-end text-xs font-normal leading-4" [ngClass]="titleLength > 250 ? 'text-red-500': 'text-gray-500'">
                  {{titleLength}}/250
                </div>
                <div *ngIf="fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)">
                  <p class="text-red-500 " *ngIf="fc['title'].errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="fc['title'].errors?.['maxlength']">max 250 character</p>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 mb-6">
              <div class="col-span-6">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Description</div>
                  <div class="text-gray-500 text-xs font-normal leading-4">
                    Describe about the purpose of the opportunity
                  </div>
                </label>
                <textarea type="text" formControlName="description"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400  px-2 py-1 text-gray-800 text-xs"
                  id="description" rows="6"
                  [ngClass]="{'is-invalid': fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)}"></textarea>
                <div class="text-end text-xs font-normal leading-4" [ngClass]="descriptionLength > 1000 ? 'text-red-500': 'text-gray-500'">
                  {{descriptionLength}}/1000
                </div>

                <div *ngIf="fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)">
                  <p class="text-red-500 " *ngIf="fc['description'].errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="fc['description'].errors?.['maxlength']">max 1000 character</p>
                </div>
              </div>
            </div>
            <div class="text-black text-xl text-semibold mb-6">
              Opportunity details
            </div>
            <div class="grid grid-cols-12 mb-4">
              <div class="col-span-6">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Tender type</div>
                  <div class="text-gray-500 text-xs font-normal leading-4">
                    Select the type of opportunity
                  </div>
                </label>
                <select name="Tender type" formControlName="tenderType" id="tenderType"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-xs bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
                  [ngClass]="{'is-invalid': fc['tenderType'].invalid && (fc['tenderType'].dirty || fc['tenderType'].touched)}">
                  <option value="" disabled selected >Select tender type</option>
                  <option value="OPEN">Open Tender</option>
                  <option value="GLOBAL">Global Tender</option>
                  <option value="LIMITED">Limited Tender</option>
                  <option value="SINGLE">Single Tender</option>
                </select>
                <div *ngIf="fc['tenderType'].invalid && (fc['tenderType'].dirty || fc['tenderType'].touched)">
                  <p class="text-red-500 " *ngIf="fc['tenderType'].errors?.['required']">Required</p>
                </div>
              </div>
            </div>
            <!-- When select open tender -->
            <!-- <div class="text-blue-500 text-sm font-medium mb-4">
              For all registered bidders from India.   
            </div> -->
            <!-- When select global tender -->
            <!-- <div class="text-blue-500 text-sm font-medium mb-4">
              For all worldwide registered bidder.   
            </div>
            <div class="text-blue-500 text-sm font-medium mb-4">
              For specific registered/unregistered bidders.   
            </div>

            <div class="grid grid-cols-12  gap-4 w-full mb-4">
              <div class="col-span-6">
                <div class="bg-gray-100 rounded-lg p-3">
                  <div class="text-gray-500 text-sm font-medium leading-4">
                    Select bidders from
                  </div>
                  <div class="grid grid-cols-12  gap-4 w-full mb-4">
                    <div class="col-span-6">
                      <div class="flex flex-row">
                        <div class="">
                          <input
                            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                            type="radio" formControlName="title" id="title" placeholder="Placeholder"
                            [ngClass]="{'is-invalid': fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)}">
                        </div>
                        <div class="basis-11/12">
                          <div class="text-gray-700 text-sm font-semibold leading-4">PQ Bidders</div>
                          <div class="text-gray-500 text-xs font-normal leading-4">Select from prequalified bidders
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-span-6">
                      <div class="flex flex-row">
                        <div class="">
                          <input
                            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                            type="radio" formControlName="title" id="title" placeholder="Placeholder"
                            [ngClass]="{'is-invalid': fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)}">
                        </div>
                        <div class="basis-11/12">
                          <div class="text-gray-700 text-sm font-semibold leading-4">All Bidders</div>
                          <div class="text-gray-500 text-xs font-normal leading-4">Select from all bidders</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-1 mb-1">
                    <div>
                      <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                        <div class="text-gray-700 text-sm font-semibold leading-4">Select PQ</div>
                        <div class="text-gray-500 text-xs font-normal leading-4">
                          Select the pre-qualification
                        </div>
                      </label>
                      <div class="">
                        <p-multiSelect [disabled]="isLoading">
                        </p-multiSelect>
                      </div>

                    </div>
                  </div>
                  <div class="grid grid-cols-1 mb-4">
                    <div>
                      <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                        <div class="flex flex-row items-center justify-between">
                          <div class="text-gray-700 text-sm font-semibold leading-4 basis-1/2">Add Bidders</div>
                          <div class="text-gray-500 text-xs font-normal leading-4 text-end basis-1/2">
                            Type name for list of bidders
                          </div>
                        </div>
                      </label>
                      <textarea type="text"
                        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-16 px-2 py-0 text-gray-800 text-xs"
                        id="description" rows="3"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="grid grid-cols-12 mb-4">
              <div class="col-span-6">
                <label for="tenderCategory" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Tender category</div>
                  <div class="text-gray-500 text-xs font-normal leading-4">
                    Select the opportunity category
                  </div>
                </label>
                <select name="Tender category" formControlName="tenderCategory" id="tenderCategory" placeholder="Select the opportunity category"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-xs bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
                  [ngClass]="{'is-invalid': fc['tenderCategory'].invalid && (fc['tenderCategory'].dirty || fc['tenderCategory'].touched)}">
                  <option value="" selected disabled>Select . . .</option>
                  <option value="GOODS">Goods</option>
                  <option value="SERVICES">Services</option>
                  <option value="WORKS">Works</option>
                </select>
                <div
                  *ngIf="fc['tenderCategory'].invalid && (fc['tenderCategory'].dirty || fc['tenderCategory'].touched)">
                  <p class="text-red-500 " *ngIf="fc['tenderCategory'].errors?.['required']">Required</p>
                </div>

              </div>
            </div>
            <div class="grid grid-cols-12 mb-4">
              <div class="col-span-6">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Account head</div>
                  <div class="text-gray-500 text-xs font-normal leading-4">
                    Select the opportunity account head
                  </div>
                </label>
                <select name="Account head" formControlName="accountHead" id="accountHead"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-xs bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
                  [ngClass]="{'is-invalid': fc['accountHead'].invalid && (fc['accountHead'].dirty || fc['accountHead'].touched)}">
                  <option value="" selected disabled>Select . . .</option>
                  <option value="STATE">State</option>
                  <option value="CENTER">Center</option>
                  <option value="OTHERS">Others</option>
                </select>
                <div *ngIf="fc['accountHead'].invalid && (fc['accountHead'].dirty || fc['accountHead'].touched)">
                  <p class="text-red-500 " *ngIf="fc['accountHead'].errors?.['required']">Required</p>
                </div>

              </div>
            </div>
            <div class="grid grid-cols-12 gap-4 mb-4">
              <div class="col-span-6">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Category</div>
                  <div class="text-gray-500 text-xs font-normal leading-4">
                    Select the opportunity Category
                  </div>
                </label>
                <select name="Category" formControlName="category" id="category"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-xs bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
                  [ngClass]="{'is-invalid': fc['category'].invalid && (fc['category'].dirty || fc['category'].touched)}">
                  <option value="" Selected disable>Select . . .</option>
                  <option value="Construction and Infrastructure">Construction and Infrastructure</option>
                  <option value="Information Technology (IT) and Electronics">Information Technology (IT) and Electronics</option>
                  <option value="Machinery and Equipment">Machinery and Equipment</option>
                  <option value="Energy and Power">Energy and Power</option>
                  <option value="Telecommunications">Telecommunications</option>
                  <option value="Healthcare and Pharmaceuticals">Healthcare and Pharmaceuticals</option>
                  <option value="Defense and Aerospace">Defense and Aerospace</option>
                  <option value="Textiles and Apparel">Textiles and Apparel</option>
                  <option value="Agriculture and Farming">Agriculture and Farming</option>
                  <option value="Automobile and Transport">Automobile and Transport</option>
                  <option value="Office Supplies and Stationery">Office Supplies and Stationery</option>
                  <option value="Food and Catering">Food and Catering</option>
                  <option value="Education and Training">Education and Training</option>
                  <option value="Waste Management and Environment">Waste Management and Environment</option>
                  <option value="Transport and Logistics">Transport and Logistics</option>
                </select>
                <div *ngIf="fc['category'].invalid && (fc['category'].dirty || fc['category'].touched)">
                  <p class="text-red-500 " *ngIf="fc['category'].errors?.['required']">Required</p>
                </div>
              </div>
              <div class="col-span-6">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Subcategory [optional]</div>
                  <div class="text-gray-500 text-xs font-normal leading-4">
                    Select the opportunity subcategory Category
                  </div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-xs bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
                  type="text" formControlName="subcategory" id="subcategory" placeholder="---"
                  [ngClass]="{'is-invalid': fc['subcategory'].invalid && (fc['subcategory'].dirty || fc['subcategory'].touched)}">
                <div *ngIf="fc['subcategory'].invalid && (fc['subcategory'].dirty || fc['subcategory'].touched)">
                  <p class="text-red-500 " *ngIf="fc['subcategory'].errors?.['maxlength']">Max 250 Length</p>
                </div>

              </div>
            </div>
            <div class="grid grid-cols-12 mb-4">
              <div class="col-span-6">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Form of contract</div>
                  <div class="text-gray-500 text-xs font-normal leading-4">
                    Select the opportunity form of contract
                  </div>
                </label>

                <select name="Select Designation" formControlName="formOfContract" id="formOfContract"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-xs bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
                  [ngClass]="{'is-invalid': fc['formOfContract'].invalid && (fc['formOfContract'].dirty || fc['formOfContract'].touched)}">
                  <option value="" selected disabled>Select . . .</option>
                  <option value="TURN_KEY">Turn-Key</option>
                  <option value="PIECE_WORK">Piece-Work</option>
                  <option value="LUMP_SUM">Lump-Sum</option>
                  <option value="SUPPLY">Supply</option>
                  <option value="ITEM_RATE">Item-Rate</option>
                  <option value="ITEM_WISE">Item-Wise</option>
                  <option value="PERCENTAGE">Percentage</option>
                </select>
                <div
                  *ngIf="fc['formOfContract'].invalid && (fc['formOfContract'].dirty || fc['formOfContract'].touched)">
                  <p class="text-red-500 " *ngIf="fc['formOfContract'].errors?.['required']">Required</p>
                </div>

              </div>
            </div>
            <div class="grid grid-cols-12 mb-4">
              <div class="col-span-6">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Tender opening</div>
                  <div class="text-gray-500 text-xs font-normal leading-4">
                    Select the opportunity tender opening
                  </div>
                </label>
                <select name="Select Designation" formControlName="tenderOpeningType" id="tenderOpeningType"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-xs bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
                  [ngClass]="{'is-invalid': fc['tenderOpeningType'].invalid && (fc['tenderOpeningType'].dirty || fc['tenderOpeningType'].touched)}">
                  <option value="" selected disabled>Select . . .</option>
                  <option value="QCBS">QCBS</option>
                  <option value="LCS">LCS</option>
                  <option value="QBS">QBS</option>
                  <option value="HCS">HCS</option>
                </select>
                <div
                  *ngIf="fc['tenderOpeningType'].invalid && (fc['tenderOpeningType'].dirty || fc['tenderOpeningType'].touched)">
                  <p class="text-red-500 " *ngIf="fc['tenderOpeningType'].errors?.['required']">required
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 mb-4">
              <div class="col-span-6">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Contract type</div>
                  <div class="text-gray-500 text-xs font-normal leading-4">
                    Select the opportunity contract type
                  </div>
                </label>
                <select name="Contract type" formControlName="tenderContractType" id="tenderContractType"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-xs bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
                  [ngClass]="{'is-invalid': fc['tenderContractType'].invalid && (fc['tenderContractType'].dirty || fc['tenderContractType'].touched)}">
                  <option value="" selected disabled>Select . . .</option>
                  <option value="TENDER">Tender</option>
                  <option value="RATE_CONTRACT">Rate Contract</option>
                </select>
                <div
                  *ngIf="fc['tenderContractType'].invalid && (fc['tenderContractType'].dirty || fc['tenderContractType'].touched)">
                  <p class="text-red-500 " *ngIf="fc['tenderContractType'].errors?.['required']">required</p>
                </div>
              </div>
            </div>

            <div class="my-3">
              <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Tender saved successfully.'">
              </app-message-toast>
              <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg">
              </app-message-toast>
            </div>

          </div>
        </div>
      </div>
        <div class="footer">
          <div class="flex flex-row items-center h-8 my-2 justify-end">
            <!-- <div class="basis-1/3 mt-2 ps-6">
              <div class="h-2 w-20 bg-blue-200 inline-block rounded-lg"></div>
              Some information is pending
            </div> -->
            <div class=" text-end pe-7">
              <button [disabled]="isLoading"
                class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800 me-3"
                (click)="closeDrawer()">Cancel</button>
              <p-button [disabled]="isLoading" (onClick)="saveTenderWrapperUiDto()" [loading]="isLoading" label="Save"
                class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
                ></p-button>
            </div>
            
            <div class=" text-center text-white bg-blue-500 px-5 py-3" [ngClass]="{'disabled' : isLoading}">
              <span class="text-sm font-normal  cursor-pointer" (click)="openCurrentPage(opportunityPages.CONFIGURATION)">
                Add configuration
                <i-lucide name="arrow-right" [size]="18" class="my-icon inline-block"></i-lucide></span>
            </div>
          </div>
        </div>
     
    </form>
  </div>
  <div class="w-full" *ngIf="currentView == opportunityPages.CONFIGURATION">
    <form [formGroup]="formGroupConfiguration">
      <div class="">

      <div class="p-6 tr-overflow-y-drawer">
        <div class="w-4/5 mb-10">
        <div class="grid grid-cols-12 mb-4">
          <div class="col-span-5">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Should allow Re-bid submission</div>
              <div class="text-gray-500 text-xs font-normal leading-4 mb-1">
                You can allow Re-bid submission of the opportunity
              </div>
            </label>
            <div class="inline-block border border-blue-500 rounded-lg">
            <p-selectButton [unselectable]="true" [options]="stateOptions" optionLabel="label" optionValue="value"
              formControlName="allowReBidSubmission" id="allowReBidSubmission"></p-selectButton>
              </div>
            <div
              *ngIf="fcConfig['allowReBidSubmission'].invalid && (fcConfig['allowReBidSubmission'].dirty || fcConfig['allowReBidSubmission'].touched)">
              <p class="text-red-500 " *ngIf="fcConfig['allowReBidSubmission'].errors?.['required']">required</p>
            </div>
          </div>
        </div>
        <div class="text-xs font-normal text-gray-800 mb-4">
          General conditions of Sale along with any other document annexed. <span
            class="text-gray-700 font-semibold text-xs">Note</span> <span class="text-xs font-normal text-gray-800">
            You can always change opportunity type
          </span>
        </div>
        <div class="grid grid-cols-12 mb-4">
          <div class="col-span-5">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4 ">
              <div class="text-gray-700 text-sm font-semibold leading-4">Should allow withdrawal of bids</div>
              <div class="text-gray-500 text-xs font-normal leading-4 mb-1">
                You can allow withdrawal of bids for opportunity
              </div>
            </label>
            <div class="inline-block border border-blue-500 rounded-lg">
            <p-selectButton [unselectable]="true" [options]="stateOptions" optionLabel="label" optionValue="value"
              formControlName="allowWithdrawalOfBids" id="allowWithdrawalOfBids"></p-selectButton>
              </div>
            <div
              *ngIf="fcConfig['allowWithdrawalOfBids'].invalid && (fcConfig['allowWithdrawalOfBids'].dirty || fcConfig['allowWithdrawalOfBids'].touched)">
              <p class="text-red-500 " *ngIf="fcConfig['allowWithdrawalOfBids'].errors?.['required']">required</p>
            </div>
          </div>
        </div>
        <div class="text-xs font-normal text-gray-800 mb-4">
          General conditions of Sale along with any other document annexed. <span
            class="text-gray-700 font-semibold text-xs">Note</span> <span
            class="text-xs font-normal text-gray-800 underline "> You can always change opportunity type
          </span>
        </div>
        <div class="grid grid-cols-12 mb-4">
          <div class="col-span-5">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Should allow multi-currency</div>
              <div class="text-gray-500 text-xs font-normal leading-4 mb-1">
                You can allow multi-currency for opportunity
              </div>
            </label>
            <div class="inline-block border border-blue-500 rounded-lg">
            <p-selectButton [unselectable]="true" [options]="stateOptions" optionLabel="label" optionValue="value"
              formControlName="allowMultipleCurrency" id="allowMultipleCurrency"></p-selectButton>
              </div>
            <div
              *ngIf="fcConfig['allowMultipleCurrency'].invalid && (fcConfig['allowMultipleCurrency'].dirty || fcConfig['allowMultipleCurrency'].touched)">
              <p class="text-red-500 " *ngIf="fcConfig['allowMultipleCurrency'].errors?.['required']">required</p>
            </div>
          </div>
        </div>
        <div class="text-xs font-normal text-gray-800 mb-4">
          General conditions of Sale along with any other document annexed. <span
            class="text-gray-700 font-semibold text-xs">Note</span> <span
            class="text-xs font-normal text-gray-800 underline "> You can always change opportunity type
          </span>
        </div>
        <div class=" bg-blue-100 rounded-lg py-4 px-2 items-center  grid grid-cols-12 mb-4"
          *ngIf="fcConfig['allowMultipleCurrency'].value">
          <div class="col-span-6">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Select multiple currency</div>
              <div class="text-gray-500 text-xs font-normal leading-4">
                You can select multi-currency for opportunity
              </div>
            </label>
            
            <div  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 text-gray-800 text-sm" [ngClass]="{'is-invalid': fcConfig['currencies'].invalid && (fcConfig['currencies'].dirty || fcConfig['currencies'].touched)}">
            <p-multiSelect [options]="currencies" defaultLabel="Select a currency" optionLabel="name" display="chip"
              formControlName="currencies" id="currencies">
            </p-multiSelect>
          </div>
            <div
              *ngIf="fcConfig['currencies'].invalid && (fcConfig['currencies'].dirty || fcConfig['currencies'].touched)">
              <p class="text-red-500 " *ngIf="fcConfig['currencies'].errors?.['required']">required</p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12 mb-4">
          <div class="col-span-6">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Number of Envelopes</div>
              <div class="text-gray-500 text-xs font-normal leading-4">
                You can select Number of Envelopes for opportunity
              </div>
            </label>

            <select name="Select Designation" formControlName="noOfEnvelopes" id="noOfEnvelopes" placeHolder=""
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-xs bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select"
              [ngClass]="{'is-invalid': fcConfig['noOfEnvelopes'].invalid && (fcConfig['noOfEnvelopes'].dirty || fcConfig['noOfEnvelopes'].touched)}">
              <option value="" selected disabled>Select . . .</option>
              <option [value]="1" *ngIf="fc['tenderOpeningType'].value != 'QCBS'">One Envelopes</option>
              <option [value]="2">Two Envelopes</option>
            </select>
            <div
              *ngIf="fcConfig['noOfEnvelopes'].invalid && (fcConfig['noOfEnvelopes'].dirty || fcConfig['noOfEnvelopes'].touched)">
              <p class="text-red-500 " *ngIf="fcConfig['noOfEnvelopes'].errors?.['required']">required</p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-12 gap-4 mb-4 items-start">
          <div class="col-span-3 mb-3">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Tender value</div>
              <div class="text-gray-500 text-xs font-normal leading-4">
                Tender value for opportunity
              </div>
            </label>
            <div class="flex w-full ">
              <div class="input-group-append">
                <span
                  class="border text-gray-700 text-base border-solid border-gray-400 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
                  id="basic-addon2">
                  ₹
                </span>
              </div>
              <input
                class="bg-white w-full border-l-0 rounded-r-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                type="text" id="materialId" formControlName="tenderValue" id="tenderValue" placeholder="Enter the value"
                [ngClass]="{'is-invalid': fcConfig['tenderValue'].invalid && (fcConfig['tenderValue'].dirty || fcConfig['tenderValue'].touched)}">
            </div>
            <div
                *ngIf="fcConfig['tenderValue'].invalid && (fcConfig['tenderValue'].dirty || fcConfig['tenderValue'].touched)">
                <p class="text-red-500 " *ngIf="fcConfig['tenderValue'].errors?.['required']">required</p>
              </div>
          </div>
          <div class="col-span-6">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4 ">
              <div class="text-gray-700 text-sm font-semibold leading-4 ">Show Tender value to bidder</div>
              <div class="text-gray-500 text-xs font-normal leading-4">
               Choose option for tender value
              </div>
            </label>
            <div class="inline-block border border-blue-500 rounded-lg">
              <p-selectButton [unselectable]="true" [options]="stateOptions" optionLabel="label" optionValue="value"
                formControlName="showTenderValueToBidder"></p-selectButton>
              </div>
              <div
                *ngIf="fcConfig['showTenderValueToBidder'].invalid && (fcConfig['showTenderValueToBidder'].dirty || fcConfig['showTenderValueToBidder'].touched)">
                <p class="text-red-500 " *ngIf="fcConfig['showTenderValueToBidder'].errors?.['required']">required</p>
              </div>
            
          </div>
        </div>
        <div class="grid grid-cols-12 mb-4 gap-4">
          <div class="col-span-3">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Bid validity days</div>
              <div class="text-gray-500 text-xs font-normal leading-4">
                Bid validity days for opportunity
              </div>
            </label>
            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
              type="text" id="materialId" placeholder="Enter the value" formControlName="bidValidityDays" id="bidValidityDays"
              [ngClass]="{'is-invalid': fcConfig['bidValidityDays'].invalid && (fcConfig['bidValidityDays'].dirty || fcConfig['bidValidityDays'].touched)}">
            <div
              *ngIf="fcConfig['bidValidityDays'].invalid && (fcConfig['bidValidityDays'].dirty || fcConfig['bidValidityDays'].touched)">
              <p class="text-red-500 " *ngIf="fcConfig['bidValidityDays'].errors?.['required']">required</p>
            </div>
          </div>

          <div class="col-span-3">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Lead time days</div>
              <div class="text-gray-500 text-xs font-normal leading-4">
                Lead time days for opportunity
              </div>
            </label>
            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 active:border-blue-700 h-8 px-2 py-0 text-gray-800 text-xs"
              type="text" id="materialId" placeholder="Enter the value" formControlName="leadTimeDays" id="leadTimeDays"
              [ngClass]="{'is-invalid': fcConfig['leadTimeDays'].invalid && (fcConfig['leadTimeDays'].dirty || fcConfig['leadTimeDays'].touched)}">
            <div
              *ngIf="fcConfig['leadTimeDays'].invalid && (fcConfig['leadTimeDays'].dirty || fcConfig['leadTimeDays'].touched)">
              <p class="text-red-500 " *ngIf="fcConfig['leadTimeDays'].errors?.['required']">required</p>
            </div>
          </div>
        </div>
        <hr class="hr my-2 text-gray-700">
        <div class="text-sm font-semibold text-black mb-2">
          Add location
        </div>
        <div class="grid grid-cols-12 mb-4" formGroupName="deliveryLocationDetails">
          <div class="col-span-6">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Title of the location</div>
              <div class="text-gray-500 text-xs font-normal leading-4">
                Title of the location for opportunity
              </div>
            </label>
            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
              type="text" id="materialId" placeholder="---" formControlName="addressType" id="addressType"
              [ngClass]="{'is-invalid': fcConfig['deliveryLocationDetails'].get('addressType')?.invalid && (fcConfig['deliveryLocationDetails'].get('addressType')?.dirty || fcConfig['deliveryLocationDetails'].get('addressType')?.touched)}">
            <div
              *ngIf="fcConfig['deliveryLocationDetails'].get('addressType')?.invalid && (fcConfig['deliveryLocationDetails'].get('addressType')?.dirty || fcConfig['deliveryLocationDetails'].get('addressType')?.touched)">
              <p class="text-red-500 "
                *ngIf="fcConfig['deliveryLocationDetails'].get('addressType')?.errors?.['required']">required</p>
            </div>
          </div>

        </div>
        <div class="grid grid-cols-12 mb-4 gap-2" formGroupName="deliveryLocationDetails">
          <div class="col-span-6 mb-4 ">

            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Add location</div>
             
            </label>
            <!-- <input ngx-gp-autocomplete #placesRef="ngx-places"
              (onAddressChange)="handleAddressChange($event, 'deliveryLocationDetails')"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
              type="text" id="materialId" placeholder="---" formControlName="searchAddress"
              [ngClass]="{'is-invalid': fcConfig['deliveryLocationDetails'].get('searchAddress')?.invalid && (fcConfig['deliveryLocationDetails'].get('searchAddress')?.dirty || fcConfig['deliveryLocationDetails'].get('searchAddress')?.touched)}"> -->
              <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 text-gray-800 text-sm">
              <app-places-autocomplete  placeholder="Search location" (onAddressChange)="handleAddressChange($event, 'deliveryLocationDetails')" formControlName="searchAddress"
                [ngClass]="{'is-invalid': fcConfig['deliveryLocationDetails'].get('searchAddress')?.invalid && (fcConfig['deliveryLocationDetails'].get('searchAddress')?.dirty || fcConfig['deliveryLocationDetails'].get('searchAddress')?.touched)}">
              </app-places-autocomplete>
            </div>
            <div
              *ngIf="fcConfig['deliveryLocationDetails'].get('searchAddress')?.invalid && (fcConfig['deliveryLocationDetails'].get('searchAddress')?.dirty || fcConfig['deliveryLocationDetails'].get('searchAddress')?.touched)">
              <p class="text-red-500 "
                *ngIf="fcConfig['deliveryLocationDetails'].get('searchAddress')?.errors?.['required']">required</p>
            </div>
          </div>
          <div class="col-span-6 mb-4 ">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Address line 1</div>
              
            </label>

            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
              type="text" id="materialId" placeholder="---" formControlName="addressLine1"
              [ngClass]="{'is-invalid': fcConfig['deliveryLocationDetails'].get('addressLine1')?.invalid && (fcConfig['deliveryLocationDetails'].get('addressLine1')?.dirty || fcConfig['deliveryLocationDetails'].get('addressLine1')?.touched)}">
            <div
              *ngIf="fcConfig['deliveryLocationDetails'].get('addressLine1')?.invalid && (fcConfig['deliveryLocationDetails'].get('addressLine1')?.dirty || fcConfig['deliveryLocationDetails'].get('addressLine1')?.touched)">
              <p class="text-red-500 "
                *ngIf="fcConfig['deliveryLocationDetails'].get('addressLine1')?.errors?.['required']">required</p>
            </div>
          </div>
          <div class="col-span-6 mb-4 ">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">Address line 2</div>
            </label>
            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
              type="text" id="materialId" placeholder="---" formControlName="addressLine2"
              [ngClass]="{'is-invalid': fcConfig['deliveryLocationDetails'].get('addressLine2')?.invalid && (fcConfig['deliveryLocationDetails'].get('addressLine2')?.dirty || fcConfig['deliveryLocationDetails'].get('addressLine2')?.touched)}">
            <div
              *ngIf="fcConfig['deliveryLocationDetails'].get('addressLine2')?.invalid && (fcConfig['deliveryLocationDetails'].get('addressLine2')?.dirty || fcConfig['deliveryLocationDetails'].get('addressLine2')?.touched)">
              <p class="text-red-500 "
                *ngIf="fcConfig['deliveryLocationDetails'].get('addressLine2')?.errors?.['required']">required</p>
            </div>
          </div>
          <div class="col-span-6 mb-4 ">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">City</div>
            </label>
            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
              type="text" id="materialId" placeholder="---" formControlName="city" id="city"
              [ngClass]="{'is-invalid': fcConfig['deliveryLocationDetails'].get('city')?.invalid && (fcConfig['deliveryLocationDetails'].get('city')?.dirty || fcConfig['deliveryLocationDetails'].get('city')?.touched)}">
            <div
              *ngIf="fcConfig['deliveryLocationDetails'].get('city')?.invalid && (fcConfig['deliveryLocationDetails'].get('city')?.dirty || fcConfig['deliveryLocationDetails'].get('city')?.touched)">
              <p class="text-red-500 " *ngIf="fcConfig['deliveryLocationDetails'].get('city')?.errors?.['required']">
                required</p>
            </div>
          </div>
          <div class="col-span-6 mb-4 ">
            <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
              <div class="text-gray-700 text-sm font-semibold leading-4">State</div>
            </label>
            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
              type="text" id="materialId" placeholder="---" formControlName="state" id="state"
              [ngClass]="{'is-invalid': fcConfig['deliveryLocationDetails'].get('state')?.invalid && (fcConfig['deliveryLocationDetails'].get('state')?.dirty || fcConfig['deliveryLocationDetails'].get('state')?.touched)}">
            <div
              *ngIf="fcConfig['deliveryLocationDetails'].get('state')?.invalid && (fcConfig['deliveryLocationDetails'].get('state')?.dirty || fcConfig['deliveryLocationDetails'].get('state')?.touched)">
              <p class="text-red-500 " *ngIf="fcConfig['deliveryLocationDetails'].get('state')?.errors?.['required']">
                required</p>
            </div>
          </div>
            <div class="col-span-6 mb-4">

              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Country</div>
              </label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                type="text" id="materialId" placeholder="---" formControlName="country" id="country"
                [ngClass]="{'is-invalid': fcConfig['deliveryLocationDetails'].get('country')?.invalid && (fcConfig['deliveryLocationDetails'].get('country')?.dirty || fcConfig['deliveryLocationDetails'].get('country')?.touched)}">
              <div
                *ngIf="fcConfig['deliveryLocationDetails'].get('country')?.invalid && (fcConfig['deliveryLocationDetails'].get('country')?.dirty || fcConfig['deliveryLocationDetails'].get('country')?.touched)">
                <p class="text-red-500 "
                  *ngIf="fcConfig['deliveryLocationDetails'].get('country')?.errors?.['required']">required</p>
              </div>
            </div>
            <div class="col-span-6 mb-4">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Pincode</div>
              </label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                type="text" id="materialId" placeholder="---" formControlName="zipCode" id="zipCode"
                [ngClass]="{'is-invalid': fcConfig['deliveryLocationDetails'].get('zipCode')?.invalid && (fcConfig['deliveryLocationDetails'].get('zipCode')?.dirty || fcConfig['deliveryLocationDetails'].get('zipCode')?.touched)}">
              <div
                *ngIf="fcConfig['deliveryLocationDetails'].get('zipCode')?.invalid && (fcConfig['deliveryLocationDetails'].get('zipCode')?.dirty || fcConfig['deliveryLocationDetails'].get('zipCode')?.touched)">
                <p class="text-red-500 "
                  *ngIf="fcConfig['deliveryLocationDetails'].get('zipCode')?.errors?.['required']">required</p>
              </div>
            </div>
          
        </div>

        <div>
          <div class="text-sm font-semibold text-black mb-2">
            Set Pre-Bid meeting with selected suppliers
          </div>
          <div class="grid grid-cols-12 mb-2">
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4 ">
                <div class="text-gray-700 text-sm font-semibold leading-4">Prebid Meeting</div>
                <div class="text-gray-500 text-xs font-normal leading-4 mb-1">
                  Prebid Meeting for opportunity
                </div>
              </label>
              <div class="select-multi">
                <span class="inline-block border border-blue-500 rounded-lg">
                <p-selectButton [unselectable]="true" [options]="stateOptions" optionLabel="label" optionValue="value"
                  formControlName="allowPreBidMeeting" id="allowPreBidMeeting"></p-selectButton>
                </span>
                <div
                  *ngIf="fcConfig['allowPreBidMeeting'].invalid && (fcConfig['allowPreBidMeeting'].dirty || fcConfig['allowPreBidMeeting'].touched)">
                  <p class="text-red-500 " *ngIf="fcConfig['allowPreBidMeeting'].errors?.['required']">required</p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-xs font-normal text-gray-800 mb-4">
            General conditions of Sale along with any other document annexed. <span
              class="text-gray-700 font-semibold text-xs">Note</span> <span class="text-xs font-normal text-gray-800">
              <a class="link-class">You can always change opportunity type</a>
            </span>
          </div>
          <div class="grid grid-cols-12 mb-4 gap-4" *ngIf="fcConfig['allowPreBidMeeting'].value == true">
            <div class="col-span-4" formGroupName="preBidMeetingLocation">
              <div class="text-base font-normal text-black mb-2">
                Pre-Bid meeting place
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Title of the location</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="addressType"
                  [ngClass]="{'is-invalid': fcConfig['preBidMeetingLocation'].get('addressType')?.invalid && (fcConfig['preBidMeetingLocation'].get('addressType')?.dirty || fcConfig['preBidMeetingLocation'].get('addressType')?.touched)}">

                <div
                  *ngIf="fcConfig['preBidMeetingLocation'].get('addressType')?.invalid && (fcConfig['preBidMeetingLocation'].get('addressType')?.dirty || fcConfig['preBidMeetingLocation'].get('addressType')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['preBidMeetingLocation'].get('addressType')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Add Location</div>
                </label>

                <!-- <input ngx-gp-autocomplete #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event, 'preBidMeetingLocation')"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="searchAddress"
                  [ngClass]="{'is-invalid': fcConfig['preBidMeetingLocation'].get('searchAddress')?.invalid && (fcConfig['preBidMeetingLocation'].get('searchAddress')?.dirty || fcConfig['preBidMeetingLocation'].get('searchAddress')?.touched)}"> -->
                  <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs">
                  <app-places-autocomplete (onAddressChange)="handleAddressChange($event, 'preBidMeetingLocation')"
                  [ngClass]="{'is-invalid': fcConfig['preBidMeetingLocation'].get('searchAddress')?.invalid && (fcConfig['preBidMeetingLocation'].get('searchAddress')?.dirty || fcConfig['preBidMeetingLocation'].get('searchAddress')?.touched)}">
                </app-places-autocomplete>
                </div>
                <div
                  *ngIf="fcConfig['preBidMeetingLocation'].get('searchAddress')?.invalid && (fcConfig['preBidMeetingLocation'].get('searchAddress')?.dirty || fcConfig['preBidMeetingLocation'].get('searchAddress')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['preBidMeetingLocation'].get('searchAddress')?.errors?.['required']">required</p>
                </div>
              </div>

              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Address line 1</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="addressLine1"
                  [ngClass]="{'is-invalid': fcConfig['preBidMeetingLocation'].get('addressLine1')?.invalid && (fcConfig['preBidMeetingLocation'].get('addressLine1')?.dirty || fcConfig['preBidMeetingLocation'].get('addressLine1')?.touched)}">
                <div
                  *ngIf="fcConfig['preBidMeetingLocation'].get('addressLine1')?.invalid && (fcConfig['preBidMeetingLocation'].get('addressLine1')?.dirty || fcConfig['preBidMeetingLocation'].get('addressLine1')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['preBidMeetingLocation'].get('addressLine1')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Address line 2</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="addressLine2"
                  [ngClass]="{'is-invalid': fcConfig['preBidMeetingLocation'].get('addressLine2')?.invalid && (fcConfig['preBidMeetingLocation'].get('addressLine2')?.dirty || fcConfig['preBidMeetingLocation'].get('addressLine2')?.touched)}">
                <div
                  *ngIf="fcConfig['preBidMeetingLocation'].get('addressLine2')?.invalid && (fcConfig['preBidMeetingLocation'].get('addressLine2')?.dirty || fcConfig['preBidMeetingLocation'].get('addressLine2')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['preBidMeetingLocation'].get('addressLine2')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">City</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="city"
                  [ngClass]="{'is-invalid': fcConfig['preBidMeetingLocation'].get('city')?.invalid && (fcConfig['preBidMeetingLocation'].get('city')?.dirty || fcConfig['preBidMeetingLocation'].get('city')?.touched)}">
                <div
                  *ngIf="fcConfig['preBidMeetingLocation'].get('city')?.invalid && (fcConfig['preBidMeetingLocation'].get('city')?.dirty || fcConfig['preBidMeetingLocation'].get('city')?.touched)">
                  <p class="text-red-500 " *ngIf="fcConfig['preBidMeetingLocation'].get('city')?.errors?.['required']">
                    required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">State</div>
                </label>

                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="state"
                  [ngClass]="{'is-invalid': fcConfig['preBidMeetingLocation'].get('state')?.invalid && (fcConfig['preBidMeetingLocation'].get('state')?.dirty || fcConfig['preBidMeetingLocation'].get('state')?.touched)}">
                <div
                  *ngIf="fcConfig['preBidMeetingLocation'].get('state')?.invalid && (fcConfig['preBidMeetingLocation'].get('state')?.dirty || fcConfig['preBidMeetingLocation'].get('state')?.touched)">
                  <p class="text-red-500 " *ngIf="fcConfig['preBidMeetingLocation'].get('state')?.errors?.['required']">
                    required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Country</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="country"
                  [ngClass]="{'is-invalid': fcConfig['preBidMeetingLocation'].get('country')?.invalid && (fcConfig['preBidMeetingLocation'].get('country')?.dirty || fcConfig['preBidMeetingLocation'].get('country')?.touched)}">
                <div
                  *ngIf="fcConfig['preBidMeetingLocation'].get('country')?.invalid && (fcConfig['preBidMeetingLocation'].get('country')?.dirty || fcConfig['preBidMeetingLocation'].get('country')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['preBidMeetingLocation'].get('country')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Pincode</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="zipCode"
                  [ngClass]="{'is-invalid': fcConfig['preBidMeetingLocation'].get('zipCode')?.invalid && (fcConfig['preBidMeetingLocation'].get('zipCode')?.dirty || fcConfig['preBidMeetingLocation'].get('zipCode')?.touched)}">
                <div
                  *ngIf="fcConfig['preBidMeetingLocation'].get('zipCode')?.invalid && (fcConfig['preBidMeetingLocation'].get('zipCode')?.dirty || fcConfig['preBidMeetingLocation'].get('zipCode')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['preBidMeetingLocation'].get('zipCode')?.errors?.['required']">required</p>
                </div>
              </div>
            </div>
            <div class="col-span-4" formGroupName="bidOpeningLocation">
              <div class="text-base font-normal text-black mb-2">
                Bid opening place
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Title of the location</div>
                </label>

                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="addressType"
                  [ngClass]="{'is-invalid': fcConfig['bidOpeningLocation'].get('addressType')?.invalid && (fcConfig['bidOpeningLocation'].get('addressType')?.dirty || fcConfig['bidOpeningLocation'].get('addressType')?.touched)}">

                <div
                  *ngIf="fcConfig['bidOpeningLocation'].get('addressType')?.invalid && (fcConfig['bidOpeningLocation'].get('addressType')?.dirty || fcConfig['bidOpeningLocation'].get('addressType')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['bidOpeningLocation'].get('addressType')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Add Location</div>
                </label>

                <!-- <input ngx-gp-autocomplete #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event, 'bidOpeningLocation')"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="searchAddress"
                  [ngClass]="{'is-invalid': fcConfig['bidOpeningLocation'].get('searchAddress')?.invalid && (fcConfig['bidOpeningLocation'].get('searchAddress')?.dirty || fcConfig['bidOpeningLocation'].get('searchAddress')?.touched)}"> -->
                  <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs">
                  <app-places-autocomplete (onAddressChange)="handleAddressChange($event, 'bidOpeningLocation')"
                  [ngClass]="{'is-invalid': fcConfig['bidOpeningLocation'].get('searchAddress')?.invalid && (fcConfig['bidOpeningLocation'].get('searchAddress')?.dirty || fcConfig['bidOpeningLocation'].get('searchAddress')?.touched)}">
                </app-places-autocomplete>
                </div>
                <div
                  *ngIf="fcConfig['bidOpeningLocation'].get('searchAddress')?.invalid && (fcConfig['bidOpeningLocation'].get('searchAddress')?.dirty || fcConfig['bidOpeningLocation'].get('searchAddress')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['bidOpeningLocation'].get('searchAddress')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Address line 1</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="addressLine1"
                  [ngClass]="{'is-invalid': fcConfig['bidOpeningLocation'].get('addressLine1')?.invalid && (fcConfig['bidOpeningLocation'].get('addressLine1')?.dirty || fcConfig['bidOpeningLocation'].get('addressLine1')?.touched)}">
                <div
                  *ngIf="fcConfig['bidOpeningLocation'].get('addressLine1')?.invalid && (fcConfig['bidOpeningLocation'].get('addressLine1')?.dirty || fcConfig['bidOpeningLocation'].get('addressLine1')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['bidOpeningLocation'].get('addressLine1')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Address line 2</div>
                </label>

                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="addressLine2"
                  [ngClass]="{'is-invalid': fcConfig['bidOpeningLocation'].get('addressLine2')?.invalid && (fcConfig['bidOpeningLocation'].get('addressLine2')?.dirty || fcConfig['bidOpeningLocation'].get('addressLine2')?.touched)}">
                <div
                  *ngIf="fcConfig['bidOpeningLocation'].get('addressLine2')?.invalid && (fcConfig['bidOpeningLocation'].get('addressLine2')?.dirty || fcConfig['bidOpeningLocation'].get('addressLine2')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['bidOpeningLocation'].get('addressLine2')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">City</div>
                </label>

                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="city"
                  [ngClass]="{'is-invalid': fcConfig['bidOpeningLocation'].get('city')?.invalid && (fcConfig['bidOpeningLocation'].get('city')?.dirty || fcConfig['bidOpeningLocation'].get('city')?.touched)}">
                <div
                  *ngIf="fcConfig['bidOpeningLocation'].get('city')?.invalid && (fcConfig['bidOpeningLocation'].get('city')?.dirty || fcConfig['bidOpeningLocation'].get('city')?.touched)">
                  <p class="text-red-500 " *ngIf="fcConfig['bidOpeningLocation'].get('city')?.errors?.['required']">
                    required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">State</div>
                </label>

                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="state"
                  [ngClass]="{'is-invalid': fcConfig['bidOpeningLocation'].get('state')?.invalid && (fcConfig['bidOpeningLocation'].get('state')?.dirty || fcConfig['bidOpeningLocation'].get('state')?.touched)}">
                <div
                  *ngIf="fcConfig['bidOpeningLocation'].get('state')?.invalid && (fcConfig['bidOpeningLocation'].get('state')?.dirty || fcConfig['bidOpeningLocation'].get('state')?.touched)">
                  <p class="text-red-500 " *ngIf="fcConfig['bidOpeningLocation'].get('state')?.errors?.['required']">
                    required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Country</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="country"
                  [ngClass]="{'is-invalid': fcConfig['bidOpeningLocation'].get('country')?.invalid && (fcConfig['bidOpeningLocation'].get('country')?.dirty || fcConfig['bidOpeningLocation'].get('country')?.touched)}">
                <div
                  *ngIf="fcConfig['bidOpeningLocation'].get('country')?.invalid && (fcConfig['bidOpeningLocation'].get('country')?.dirty || fcConfig['bidOpeningLocation'].get('country')?.touched)">
                  <p class="text-red-500 " *ngIf="fcConfig['bidOpeningLocation'].get('country')?.errors?.['required']">
                    required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Pincode</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="zipCode"
                  [ngClass]="{'is-invalid': fcConfig['bidOpeningLocation'].get('zipCode')?.invalid && (fcConfig['bidOpeningLocation'].get('zipCode')?.dirty || fcConfig['bidOpeningLocation'].get('zipCode')?.touched)}">
                <div
                  *ngIf="fcConfig['bidOpeningLocation'].get('zipCode')?.invalid && (fcConfig['bidOpeningLocation'].get('zipCode')?.dirty || fcConfig['bidOpeningLocation'].get('zipCode')?.touched)">
                  <p class="text-red-500 " *ngIf="fcConfig['bidOpeningLocation'].get('zipCode')?.errors?.['required']">
                    required</p>
                </div>
              </div>
            </div>
            <div class="col-span-4" formGroupName="invitingOfficerLocation">
              <div class="text-base font-normal text-black mb-2">
                Inviting officer details
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Title of the location</div>
                </label>

                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-sm"
                  type="text" id="materialId" placeholder="---" formControlName="addressType"
                  [ngClass]="{'is-invalid': fcConfig['invitingOfficerLocation'].get('addressType')?.invalid && (fcConfig['invitingOfficerLocation'].get('addressType')?.dirty || fcConfig['invitingOfficerLocation'].get('addressType')?.touched)}">

                <div
                  *ngIf="fcConfig['invitingOfficerLocation'].get('addressType')?.invalid && (fcConfig['invitingOfficerLocation'].get('addressType')?.dirty || fcConfig['invitingOfficerLocation'].get('addressType')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['invitingOfficerLocation'].get('addressType')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Add Location</div>
                </label>

                <!-- <input ngx-gp-autocomplete #placesRef="ngx-places"
                  (onAddressChange)="handleAddressChange($event, 'invitingOfficerLocation')"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="searchAddress"
                  [ngClass]="{'is-invalid': fcConfig['invitingOfficerLocation'].get('searchAddress')?.invalid && (fcConfig['invitingOfficerLocation'].get('searchAddress')?.dirty || fcConfig['invitingOfficerLocation'].get('searchAddress')?.touched)}"> -->
                  <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 text-gray-800 text-xs">
                <app-places-autocomplete (onAddressChange)="handleAddressChange($event, 'invitingOfficerLocation')"
                [ngClass]="{'is-invalid': fcConfig['invitingOfficerLocation'].get('searchAddress')?.invalid && (fcConfig['invitingOfficerLocation'].get('searchAddress')?.dirty || fcConfig['invitingOfficerLocation'].get('searchAddress')?.touched)}">
              </app-places-autocomplete>
              </div>

                <div
                  *ngIf="fcConfig['invitingOfficerLocation'].get('searchAddress')?.invalid && (fcConfig['invitingOfficerLocation'].get('searchAddress')?.dirty || fcConfig['invitingOfficerLocation'].get('searchAddress')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['invitingOfficerLocation'].get('searchAddress')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Address line 1</div>
                </label>

                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="addressLine1"
                  [ngClass]="{'is-invalid': fcConfig['invitingOfficerLocation'].get('addressLine1')?.invalid && (fcConfig['invitingOfficerLocation'].get('addressLine1')?.dirty || fcConfig['invitingOfficerLocation'].get('addressLine1')?.touched)}">
                <div
                  *ngIf="fcConfig['invitingOfficerLocation'].get('addressLine1')?.invalid && (fcConfig['invitingOfficerLocation'].get('addressLine1')?.dirty || fcConfig['invitingOfficerLocation'].get('addressLine1')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['invitingOfficerLocation'].get('addressLine1')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Address line 2</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="addressLine2"
                  [ngClass]="{'is-invalid': fcConfig['invitingOfficerLocation'].get('addressLine2')?.invalid && (fcConfig['invitingOfficerLocation'].get('addressLine2')?.dirty || fcConfig['invitingOfficerLocation'].get('addressLine2')?.touched)}">
                <div
                  *ngIf="fcConfig['invitingOfficerLocation'].get('addressLine2')?.invalid && (fcConfig['invitingOfficerLocation'].get('addressLine2')?.dirty || fcConfig['invitingOfficerLocation'].get('addressLine2')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['invitingOfficerLocation'].get('addressLine2')?.errors?.['required']">required</p>

                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">City</div>
                </label>

                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="city"
                  [ngClass]="{'is-invalid': fcConfig['invitingOfficerLocation'].get('city')?.invalid && (fcConfig['invitingOfficerLocation'].get('city')?.dirty || fcConfig['invitingOfficerLocation'].get('city')?.touched)}">
                <div
                  *ngIf="fcConfig['invitingOfficerLocation'].get('city')?.invalid && (fcConfig['invitingOfficerLocation'].get('city')?.dirty || fcConfig['invitingOfficerLocation'].get('city')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['invitingOfficerLocation'].get('city')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">State</div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="state"
                  [ngClass]="{'is-invalid': fcConfig['invitingOfficerLocation'].get('state')?.invalid && (fcConfig['invitingOfficerLocation'].get('state')?.dirty || fcConfig['invitingOfficerLocation'].get('state')?.touched)}">
                <div
                  *ngIf="fcConfig['invitingOfficerLocation'].get('state')?.invalid && (fcConfig['invitingOfficerLocation'].get('state')?.dirty || fcConfig['invitingOfficerLocation'].get('state')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['invitingOfficerLocation'].get('state')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Country</div>
                </label>

                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="country"
                  [ngClass]="{'is-invalid': fcConfig['invitingOfficerLocation'].get('country')?.invalid && (fcConfig['invitingOfficerLocation'].get('country')?.dirty || fcConfig['invitingOfficerLocation'].get('country')?.touched)}">
                <div
                  *ngIf="fcConfig['invitingOfficerLocation'].get('country')?.invalid && (fcConfig['invitingOfficerLocation'].get('country')?.dirty || fcConfig['invitingOfficerLocation'].get('country')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['invitingOfficerLocation'].get('country')?.errors?.['required']">required</p>
                </div>
              </div>
              <div class="mb-4">
                <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                  <div class="text-gray-700 text-sm font-semibold leading-4">Pincode</div>
                </label>

                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="materialId" placeholder="---" formControlName="zipCode"
                  [ngClass]="{'is-invalid': fcConfig['invitingOfficerLocation'].get('zipCode')?.invalid && (fcConfig['invitingOfficerLocation'].get('zipCode')?.dirty || fcConfig['invitingOfficerLocation'].get('zipCode')?.touched)}">
                <div
                  *ngIf="fcConfig['invitingOfficerLocation'].get('zipCode')?.invalid && (fcConfig['invitingOfficerLocation'].get('zipCode')?.dirty || fcConfig['invitingOfficerLocation'].get('zipCode')?.touched)">
                  <p class="text-red-500 "
                    *ngIf="fcConfig['invitingOfficerLocation'].get('zipCode')?.errors?.['required']">required</p>
                </div>
              </div>
              <!-- <div class="grid grid-cols-12 mb-4 gap-4">
            <div class="col-span-4">
             
            </div>
            <div class="col-span-4">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="flex flex-row mb-1">
                  <div class="basis-3/4">Add location</div>
                  <div class="basis-1/4 text-end">
                    <i-lucide name="circle-help" [size]="18" class="my-icon inline-block"></i-lucide>
                  </div>
                </div>
              </label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                type="text" id="materialId" placeholder="---">
            </div>
            <div class="col-span-4">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="flex flex-row mb-1">
                  <div class="basis-3/4">add location</div>
                  <div class="basis-1/4 text-end">
                    <i-lucide name="circle-help" [size]="18" class="my-icon inline-block"></i-lucide>
                  </div>
                </div>
              </label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                type="text" id="materialId" placeholder="---">
            </div>
          </div> -->

            </div>
          </div>
          <div class="my-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Tender saved successfully.'">
            </app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg">
            </app-message-toast>
          </div>

          <!-- <div class="mb-4" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
      <div class="footer">
        <div class="flex flex-row items-center justify-end h-8 my-2">
          <!-- <div class="basis-1/3 mt-2 ps-6">
            <div class="h-2 w-20 bg-blue-200 inline-block rounded-lg"></div>
            Completed
          </div> -->
          <div class=" text-end pe-7">
            <button [disabled]="isLoading"
              class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800 me-3"
              (click)="openCurrentPage(opportunityPages.BASIC)">Cancel</button>
            <p-button [disabled]="isLoading" (onClick)="saveTenderWrapperUiDto()" [loading]="isLoading" label="Save"
              class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
            ></p-button>
          </div>
          <div class=" text-center  text-white bg-blue-500 px-5 py-3" [ngClass]="{'disabled' : isLoading}">
            <span class="text-sm font-normal  cursor-pointer" (click)="openCurrentPage(opportunityPages.FEE)">
              Add fee details
              <i-lucide name="arrow-right" [size]="18" class="my-icon inline-block"></i-lucide></span>
          </div>
        </div>
      </div>
    </form>
   
  </div>
  <div class="w-full" *ngIf="currentView == opportunityPages.FEE">
    <form [formGroup]="formGroupFeeDetails">
      <div class="">
      <div class="p-6 tr-overflow-y-drawer">
        <div class="w-4/5 mb-10">
          <div class="grid grid-cols-12 mb-4 gap-4">
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Payment Mode</div>
                <div class="text-gray-500 text-xs font-normal leading-4">
                  Payment Mode of fee opportunity
                </div>
              </label>
  
              <select name="Select Designation" formControlName="tenderFeePaymentMode" id="tenderFeePaymentMode"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-xs bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 text-gray-800 text-sm form-select">
                <option value="OFFLINE">Offline</option>
                <option value="ONLINE">Online</option>
              </select>
            </div>
            <div class="col-span-6" *ngIf="fcFee['tenderFeePaymentMode']?.value == 'OFFLINE' ">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Offline Payment mode</div>
                <div class="text-gray-500 text-xs font-normal leading-4">
                  You can select offline payment mode here
                </div>
              </label>
              
              <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8  text-gray-800 text-sm">
              <p-multiSelect   [options]="offlinePaymentModes" defaultLabel="Select offline payment mode" display="chip"
                formControlName="offlinePaymentOptions" id="offlinePaymentOptions">
              </p-multiSelect>
            </div>
              <div
                *ngIf="fcFee['offlinePaymentOptions'].invalid && (fcFee['offlinePaymentOptions'].dirty || fcFee['offlinePaymentOptions'].touched)">
                <p class="text-red-500 " *ngIf="fcFee['offlinePaymentOptions'].errors?.['required']">required</p>
              </div>
            </div>
          </div>
          <div class="text-sm font-semibold text-black mb-2">
            Tender fee details
          </div>
          <div class="grid grid-cols-12 mb-4 gap-2">
            <div class="col-span-6 mb-4">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Tender Fee (Optional)</div>
              </label>
  
              <div class="flex w-full ">
                <div class="input-group-append">
                  <span
                    class="border text-gray-700 text-base border-solid border-gray-400 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
                    id="basic-addon2">
                    ₹
                  </span>
                </div>
                <input
                  class="bg-white w-full border-l-0 rounded-r-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="tenderFee" formControlName="tenderFee" />
                <div *ngIf="fcFee['tenderFee'].invalid && (fcFee['tenderFee'].dirty || fcFee['tenderFee'].touched)">
                  <p class="text-red-500 " *ngIf="fcFee['tenderFee'].errors?.['pattern']">Invalid</p>
                </div>
              </div>
            </div>
            <div class="col-span-6 mb-4">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Processing Fee (Optional)</div>
              </label>
              <div class="flex w-full">
                <div class="input-group-append">
                  <span
                    class="border text-gray-700 text-base border-solid border-gray-400 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
                    id="basic-addon2">
                    ₹
                  </span>
                </div>
                <input
                  class="bg-white w-full border-l-0 rounded-r-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="processingFee" formControlName="processingFee">
                <div
                  *ngIf="fcFee['processingFee'].invalid && (fcFee['processingFee'].dirty || fcFee['processingFee'].touched)">
                  <p class="text-red-500 " *ngIf="fcFee['processingFee'].errors?.['pattern']">Invalid</p>
                </div>
              </div>
            </div>
            <div class="col-span-6 mb-4">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Surcharges (Optional</div>
              </label>
  
              <div class="flex w-full ">
                <div class="input-group-append">
                  <span
                    class="border text-gray-700 text-base border-solid border-gray-400 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
                    id="basic-addon2">
                    ₹
                  </span>
                </div>
                <input
                  class="bg-white w-full border-l-0 rounded-r-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="surcharges" formControlName="surcharges">
                <div *ngIf="fcFee['surcharges'].invalid && (fcFee['surcharges'].dirty || fcFee['surcharges'].touched)">
                  <p class="text-red-500 " *ngIf="fcFee['surcharges'].errors?.['pattern']">Invalid</p>
                </div>
              </div>
            </div>
            <div class="col-span-6 mb-4">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Any other taxes/charge (Optional)</div>
              </label>
              <div class="flex w-full">
                <div class="input-group-append">
                  <span
                    class="border text-gray-700 text-base border-solid border-gray-400 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
                    id="basic-addon2">
                    ₹
                  </span>
                </div>
                <input
                  class="bg-white w-full border-l-0 rounded-r-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="otherCharges" formControlName="otherCharges">
                <div
                  *ngIf="fcFee['otherCharges'].invalid && (fcFee['otherCharges'].dirty || fcFee['otherCharges'].touched)">
                  <p class="text-red-500 " *ngIf="fcFee['otherCharges'].errors?.['pattern']">Invalid</p>
                </div>
              </div>
            </div>
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Tender Fee payable to</div>
              </label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                type="text" id="tenderFeePayableTo" placeholder="---" formControlName="tenderFeePayableTo"
                [ngClass]="{'is-invalid': fcFee['tenderFeePayableTo'].invalid && (fcFee['tenderFeePayableTo'].dirty || fcFee['tenderFeePayableTo'].touched)}">
              <div
                *ngIf="fcFee['tenderFeePayableTo'].invalid && (fcFee['tenderFeePayableTo'].dirty || fcFee['tenderFeePayableTo'].touched)">
                <p class="text-red-500 " *ngIf="fcFee['tenderFeePayableTo'].errors?.['required']">Required</p>
              </div>
            </div>
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Tender Fee payable at</div>
              </label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                type="text" id="tenderFeePayableAt" formControlName="tenderFeePayableAt" placeholder="---"
                [ngClass]="{'is-invalid': fcFee['tenderFeePayableAt'].invalid && (fcFee['tenderFeePayableAt'].dirty || fcFee['tenderFeePayableAt'].touched)}">
              <div
                *ngIf="fcFee['tenderFeePayableAt'].invalid && (fcFee['tenderFeePayableAt'].dirty || fcFee['tenderFeePayableAt'].touched)">
                <p class="text-red-500 " *ngIf="fcFee['tenderFeePayableAt'].errors?.['required']">Required</p>
              </div>
            </div>
          </div>
          <div class="text-sm font-semibold text-black mb-2">
            Earnest Money Deposit (EMD) Details
          </div>
          <div class="grid grid-cols-12 mb-4 gap-4">
            <div class="col-span-6 mb-4">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">EMD Amount</div>
              </label>
              <div class="flex w-full">
                <div class="input-group-append">
                  <span
                    class="border text-gray-700 text-base border-solid border-gray-400 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
                    id="basic-addon2">
                    ₹
                  </span>
                </div>
                <input
                  class="bg-white w-full border-l-0 rounded-r-lg flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                  type="text" id="emdAmount" formControlName="emdAmount"
                  [ngClass]="{'is-invalid': fcFee['emdAmount'].invalid && (fcFee['emdAmount'].dirty || fcFee['emdAmount'].touched)}">
  
              </div>
              <div *ngIf="fcFee['emdAmount'].invalid && (fcFee['emdAmount'].dirty || fcFee['emdAmount'].touched)">
                <p class="text-red-500 " *ngIf="fcFee['emdAmount'].errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="fcFee['emdAmount'].errors?.['pattern']">Required</p>
              </div>
            </div>
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4 mb-1">EMD Exemption Allowed</div>
              </label>
               <div class="inline-block border border-blue-500 rounded-lg">
              <p-selectButton [unselectable]="true" [options]="stateOptions" optionLabel="label" optionValue="value"
                formControlName="allowEmdExemption" id="allowEmdExemption"></p-selectButton>
                </div>
              <div
                *ngIf="fcFee['allowEmdExemption'].invalid && (fcFee['allowEmdExemption'].dirty || fcFee['allowEmdExemption'].touched)">
                <p class="text-red-500 " *ngIf="fcFee['allowEmdExemption'].errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="fcFee['allowEmdExemption'].errors?.['pattern']">Required</p>
              </div>
  
            </div>
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">EMD Fee payable to</div>
              </label>
  
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                type="text" formControlName="emdFeePayableTo" id="emdFeePayableTo" placeholder="---"
                [ngClass]="{'is-invalid': fcFee['emdFeePayableTo'].invalid && (fcFee['emdFeePayableTo'].dirty || fcFee['emdFeePayableTo'].touched)}">
              <div
                *ngIf="fcFee['emdFeePayableTo'].invalid && (fcFee['emdFeePayableTo'].dirty || fcFee['emdFeePayableTo'].touched)">
                <p class="text-red-500 " *ngIf="fcFee['emdFeePayableTo'].errors?.['required']">Required</p>
              </div>
            </div>
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">EMD Fee payable at</div>
              </label>
  
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-800 text-xs"
                type="text" formControlName="emdFeePayableAt" id="emdFeePayableAt" placeholder="---"
                [ngClass]="{'is-invalid': fcFee['emdFeePayableAt'].invalid && (fcFee['emdFeePayableAt'].dirty || fcFee['emdFeePayableAt'].touched)}">
              <div
                *ngIf="fcFee['emdFeePayableAt'].invalid && (fcFee['emdFeePayableAt'].dirty || fcFee['emdFeePayableAt'].touched)">
                <p class="text-red-500 " *ngIf="fcFee['emdFeePayableAt'].errors?.['required']">Required</p>
              </div>
            </div>
          </div>
          <div class="flex flex-row gap-4 items-center justify-between mb-4">
            <div class=" text-gray-700 font-semibold text-xs">
              Specific Documents
            </div>
            <div class=" text-end">
              <span class="me-3">
                  <button type="button" (click)="openAddDocumentDialog()"
                    class="inline-flex items-center py-1.5 px-3 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-800 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    <i-lucide name="plus" [size]="16" color="#000" class="my-icon inline-flex"></i-lucide> New
                    Document</button>
              </span>
            </div>
          </div>
          <div class="grid bg-blue-100 rounded-lg py-4 items-center justify-center" *ngIf="feeDocuments.length == 0">
            <img src="assets/images/tender-drawer.svg" class=" h-16 w-16 mx-auto my-0">
            <div class="text-xs font-normal text-black mt-2">
              Not Specific documents added yet.
            </div>
          </div>
          <div class="mt-2 border border-gray-400" *ngIf="feeDocuments.length > 0">
            <p-table [value]="feeDocuments" [customSort]="true" [tableStyle]="{'min-width': '60rem'}">
              <ng-template pTemplate="header">
                <tr class="bg-white">
                  <th pSortableColumn="code" class=""></th>
                  <th pSortableColumn="displayName"
                    class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">Document
                    <p-sortIcon field="displayName" class="float-end"></p-sortIcon>
                  </th>
                  <th pSortableColumn="uploadDate"
                    class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">Uploaded date
                    <p-sortIcon field="uploadDate" class="float-end"></p-sortIcon>
                  </th>
                  <th pSortableColumn="category"
                    class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">Verified by
                    <p-sortIcon field="category" class="float-end"></p-sortIcon>
                  </th>
                  <th pSortableColumn="price"
                    class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
                    Status
                    <p-sortIcon field="quantity" class="float-end tr-xs-icon"></p-sortIcon>
                  </th>
                  <th pSortableColumn="quantity"
                    class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item let-i="rowIndex">
                <tr>
                  <td class="border-e">
                    <div class="font-medium text-sm">
                      {{i+1}}
                    </div>
                  </td>
                  <td class="font-medium text-sm  underline ">
                    {{item.displayName}}
                  </td>
  
                  <td class="font-medium text-sm">
                    {{item.uploadDate | ordinalDate}}
                  </td>
                  <td class="font-medium text-sm">
                    <span for="" class="leading-6 text-sm font-medium " *ngIf="item?.verifiedBy">
                      <span
                        class="inline-flex h-6 w-6 gap-2.5 p-1 items-center justify-between bg-blue-100 text-gray-700 rounded-full me-2">
                        <img src="/downloadLandingBlob?fileId={{item?.verifiedBy?.profileImage?.fileId}}"
                          *ngIf="item?.verifiedBy?.profileImage">
                        <span *ngIf="!item?.verifiedBy?.profileImage">
                          {{(item?.verifiedBy?.firstName + ' ' + item?.verifiedBy?.lastName) | textAvatar}}
                        </span>
                      </span>
                      {{item?.verifiedBy?.firstName}} {{item?.verifiedBy?.lastName}}
                    </span>
                  </td>
                  <td class="font-medium text-sm">
                    <span class="me-3 flex items-center">
                      <button type="button"
                        class="inline-flex py-1 px-3 text-sm font-medium text-gray-700 focus:outline-none bg-gray-200 rounded-full border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-100">
                        <i-lucide name="check-check" [size]="20" color="#0555F5" class="my-icon inline-flex "></i-lucide>
                        {{item.status}}</button>
                    </span>
                  </td>
                  <td>
                    <div>
                      <i-lucide name="trash-2" [size]="18" color="#0555F5" class="my-icon"
                        (click)="deleteFeeDocument(i)"></i-lucide>
                    </div>
                  </td>
  
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>


        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Tender saved successfully.'">
          </app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg">
          </app-message-toast>
        </div>
      </div>
      </div>
      <div class="footer">
        <div class="flex flex-row items-center justify-end h-8 my-2">
          <!-- <div class="basis-1/3 mt-2 ps-6">
            <div class="h-2 w-20 bg-blue-200 inline-block rounded-lg"></div>
            Completed
          </div> -->
          
          <div class=" text-end pe-7">
            <button [disabled]="isLoading"
              class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800 me-3"
              (click)="openCurrentPage(opportunityPages.CONFIGURATION)">Cancel</button>
            <p-button [disabled]="isLoading" (onClick)="saveTenderWrapperUiDto()" [loading]="isLoading" label="Save"
              class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
              ></p-button>
          </div>
          <div class=" text-center  text-white bg-blue-500 px-5 py-3" [ngClass]="{'disabled' : isLoading}">
            <span class="text-sm font-normal  cursor-pointer" (click)="openCurrentPage(opportunityPages.CRITICAL_DATES)">
              Add critical dates
              <i-lucide name="arrow-right" [size]="18" class="my-icon inline-block"></i-lucide></span>
          </div>
        </div>
      </div>
    </form>
    

   
  </div>

  <div lass="w-full" *ngIf="currentView == opportunityPages.CRITICAL_DATES">
    <form [formGroup]="formGroupCriticalDates">
      <div class="h-screen">
      <div class="p-6 tr-overflow-y-drawer">
        <div class="w-4/5 mb-10">
          <div class="grid grid-cols-12 items-center mb-4 gap-4">
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="basis-3/4">Publishing Date</div>
              </label>
              <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
              [ngClass]="{'is-invalid': fcDates['publishingDate'].invalid && (fcDates['publishingDate'].dirty || fcDates['publishingDate'].touched)}">
                <p-calendar id="publishingDate" formControlName="publishingDate" dateFormat="dd/mm/yy" [showTime]="true" [minDate]="publishingMinimumDate"
                appendTo="body"
               ></p-calendar>
              </div>
  
              <div *ngIf="fcDates['publishingDate'].invalid && (fcDates['publishingDate'].dirty || fcDates['publishingDate'].touched)">
                <p class="text-red-500" *ngIf="fcDates['publishingDate'].errors?.['required']">Required</p>
              </div>
              <div *ngIf="formGroupCriticalDates.errors?.['publishingDateInvalid'] && (fcDates['publishingDate'].dirty || fcDates['publishingDate'].touched)">
                <p class="text-red-500">Publishing Date must be after the current date.</p>
              </div>
            </div>
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Document download date</div>
              </label>
              <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm" 
              [ngClass]="{'is-invalid': fcDates['documentDownloadDate'].invalid && (fcDates['documentDownloadDate'].dirty || fcDates['documentDownloadDate'].touched)}">
              <p-calendar id="documentDownloadDate" formControlName="documentDownloadDate" dateFormat="dd/mm/yy" [minDate]="documentDownloadMinimumDate"
                [showTime]="true" appendTo="body">
              </p-calendar>
              </div>
              <div *ngIf="fcDates['documentDownloadDate'].invalid && (fcDates['documentDownloadDate'].dirty || fcDates['documentDownloadDate'].touched)">
                <p class="text-red-500" *ngIf="fcDates['documentDownloadDate'].errors?.['required']">Required</p>
              </div>
              <div *ngIf="formGroupCriticalDates.errors?.['documentDownloadInvalid'] && (fcDates['documentDownloadDate'].dirty || fcDates['documentDownloadDate'].touched)">
                <p class="text-red-500">Document Download Date must be on or after Publishing Date.</p>
              </div>
            </div>
          </div>
          <!-- <div class="grid grid-cols-12 mb-1">
            <div class="col-span-5">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Seek Clarification</div>
              </label>
  
              <p-selectButton [options]="stateOptions" optionLabel="label" optionValue="value" id="allowSeekClarification"
                formControlName="allowSeekClarification"></p-selectButton>
              <div
                *ngIf="fcDates['allowSeekClarification'].invalid && (fcDates['allowSeekClarification'].dirty || fcDates['allowSeekClarification'].touched)">
                <p class="text-red-500 " *ngIf="fcDates['allowSeekClarification'].errors?.['required']">Required</p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 mb-4 gap-4">
            <div class="col-span-6">
  
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Start Date</div>
              </label>
              <p-calendar id="seekClarificationStartDate" formControlName="seekClarificationStartDate" appendTo="body"
                dateFormat="dd/mm/yy" [showTime]="true"></p-calendar>
              <div
                *ngIf="fcDates['seekClarificationStartDate'].invalid && (fcDates['seekClarificationStartDate'].dirty || fcDates['seekClarificationStartDate'].touched)">
                <p class="text-red-500 " *ngIf="fcDates['seekClarificationStartDate'].errors?.['required']">Required</p>
              </div>
            </div>
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">End date</div>
              </label>
  
              <p-calendar id="seekClarificationEndDate" formControlName="seekClarificationEndDate" appendTo="body"
                dateFormat="dd/mm/yy" [showTime]="true"></p-calendar>
              <div
                *ngIf="fcDates['seekClarificationEndDate'].invalid && (fcDates['seekClarificationEndDate'].dirty || fcDates['seekClarificationEndDate'].touched)">
                <p class="text-red-500 " *ngIf="fcDates['seekClarificationEndDate'].errors?.['required']">Required</p>
              </div>
            </div>
          </div> -->
          <div class="grid grid-cols-12  items-center mb-4 gap-4">
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Bid Submission Start Date</div>
              </label>
              <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"  [ngClass]="{'is-invalid': fcDates['bidSubmissionStartDate'].invalid && (fcDates['bidSubmissionStartDate'].dirty || fcDates['bidSubmissionStartDate'].touched)}">
              <p-calendar id="bidSubmissionStartDate" formControlName="bidSubmissionStartDate" dateFormat="dd/mm/yy" appendTo="body" [showTime]="true" [minDate]="bidSubmissionStartMinimumDate">
              </p-calendar>
            </div>
            <div *ngIf="fcDates['bidSubmissionStartDate'].invalid && (fcDates['bidSubmissionStartDate'].dirty || fcDates['bidSubmissionStartDate'].touched)">
              <p class="text-red-500" *ngIf="fcDates['bidSubmissionStartDate'].errors?.['required']">Required</p>
            </div>
            <div *ngIf="formGroupCriticalDates.errors?.['bidSubmissionStartInvalid'] && (fcDates['bidSubmissionStartDate'].dirty || fcDates['bidSubmissionStartDate'].touched)">
              <p class="text-red-500">Bid Submission Start Date must be after Publishing Date.</p>
            </div>
            <div *ngIf="formGroupCriticalDates.errors?.['publishingDateBeforeBidStart'] && (fcDates['bidSubmissionStartDate'].dirty || fcDates['bidSubmissionStartDate'].touched)">
              <p class="text-red-500">Publishing Date must be before Bid Submission Start Date.</p>
            </div>
            </div>
            <div class="col-span-6">
  
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Bid Submission End date</div>
              </label>
              <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
              [ngClass]="{'is-invalid': fcDates['bidSubmissionEndDate'].invalid && (fcDates['bidSubmissionEndDate'].dirty || fcDates['bidSubmissionEndDate'].touched)}">
              <p-calendar id="bidSubmissionEndDate" formControlName="bidSubmissionEndDate" dateFormat="dd/mm/yy" [minDate]="bidSubmissionEndMinimumDate"
                appendTo="body" [showTime]="true">
              </p-calendar>
              </div>
              <div
                *ngIf="fcDates['bidSubmissionEndDate'].invalid && (fcDates['bidSubmissionEndDate'].dirty || fcDates['bidSubmissionEndDate'].touched)">
                <p class="text-red-500 " *ngIf="fcDates['bidSubmissionEndDate'].errors?.['required']">Required</p>
              </div>
              <div *ngIf="formGroupCriticalDates.errors?.['bidSubmissionEndInvalid'] && (fcDates['bidSubmissionEndDate'].dirty || fcDates['bidSubmissionEndDate'].touched)">
                <p class="text-red-500">Bid Submission End Date must be after Start Date.</p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12  items-center mb-4 gap-4">
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Technical Open Date</div>
              </label>
              <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
              [ngClass]="{'is-invalid': fcDates['technicalOpeningDate'].invalid && (fcDates['technicalOpeningDate'].dirty || fcDates['technicalOpeningDate'].touched)}">
              <p-calendar id="technicalOpeningDate" formControlName="technicalOpeningDate" dateFormat="dd/mm/yy" [minDate]="technicalOpeningMinimumDate"
                appendTo="body" [showTime]="true">
              </p-calendar>
              </div>
              <div *ngIf="fcDates['technicalOpeningDate'].invalid && (fcDates['technicalOpeningDate'].dirty || fcDates['technicalOpeningDate'].touched)">
                <p class="text-red-500" *ngIf="fcDates['technicalOpeningDate'].errors?.['required']">Required</p>
              </div>
              <div *ngIf="formGroupCriticalDates.errors?.['technicalOpeningInvalid'] && (fcDates['technicalOpeningDate'].dirty || fcDates['technicalOpeningDate'].touched)">
                <p class="text-red-500">Technical Opening Date must be after Bid Submission End Date.</p>
              </div>
            </div>
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Financial Open date</div>
              </label>
              <div class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
              [ngClass]="{'is-invalid': fcDates['financialOpeningDate'].invalid && (fcDates['financialOpeningDate'].dirty || fcDates['financialOpeningDate'].touched)}">
              <p-calendar id="financialOpeningDate" formControlName="financialOpeningDate" dateFormat="dd/mm/yy" [minDate]="financialOpeningMinimumDate"
                appendTo="body" [showTime]="true">
              </p-calendar>
              </div>
              <div *ngIf="fcDates['financialOpeningDate'].invalid && (fcDates['financialOpeningDate'].dirty || fcDates['financialOpeningDate'].touched)">
                <p class="text-red-500" *ngIf="fcDates['financialOpeningDate'].errors?.['required']">Required</p>
              </div>
              <div *ngIf="formGroupCriticalDates.errors?.['financialOpeningInvalid'] && (fcDates['financialOpeningDate'].dirty || fcDates['financialOpeningDate'].touched)">
                <p class="text-red-500">Financial Opening Date must be after Technical Opening Date.</p>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Tender saved successfully.'">
          </app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg">
          </app-message-toast>
        </div>
        <!-- <div class="mb-4" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div> -->
      
    </div>
      <div class="footer">
        <div class="flex flex-row items-center justify-end  h-8 my-2">
          <!-- <div class="basis-1/3 mt-2 ps-6">
            <div class="h-2 w-20 bg-blue-200 inline-block rounded-lg"></div>
            Completed
          </div> -->
          <div class=" text-end pe-7">
            <button [disabled]="isLoading"
              class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800 me-3"
              (click)="openCurrentPage(opportunityPages.FEE)">Cancel</button>
            <p-button [disabled]="isLoading" (onClick)="saveTenderWrapperUiDto()" [loading]="isLoading" label="Save"
              class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
              ></p-button>
          </div>
          <div class=" text-center text-white bg-blue-500 px-5 py-3" [ngClass]="{'disabled' : isLoading}">
            <span class="text-sm font-normal  cursor-pointer" (click)="openExternalDrawer(applicationConstants.NEW_TENDER_APPROVAL_PAGE, applicationConstants.TENDER_PUBLISHERS)">
              Add Approvals
              <i-lucide name="arrow-right" [size]="18" class="my-icon inline-block"></i-lucide></span>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- <div class="w-full" *ngIf="false">
    <div class="p-6 tr-overflow-y-drawer">
      <div class="grid grid-cols-12 mb-4 gap-4">
        <div class="col-span-6">
          <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
            <div class="text-gray-700 text-sm font-semibold leading-4">Publishing date</div>
          </label>

          <p-calendar></p-calendar>
        </div>
        <div class="col-span-6">
          <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
            <div class="text-gray-700 text-sm font-semibold leading-4">Document download date</div>
          </label>

          <p-calendar></p-calendar>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="flex flex-row border-t items-center justify-end border-color-500 h-8 mt-3">
        <div class="text-end mt-2 pe-7">
          <button
            class="text-center basis-1/2 text-gray-700 bg-blue-100 hover:bg-blue-300 focus:ring-4 focus:ring-blue-300 font-medium rounded text-xs px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3">Cancel</button>
          <button
            class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-300 focus:ring-4 focus:ring-blue-300 font-medium rounded text-xs px-5 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Save</button>
        </div>
        <div class="text-center mt-2 justify-end text-white bg-blue-500 p-3">
          <span class="text-sm font-normal  cursor-pointer" (click)="openCurrentPage(opportunityPages.CRITICAL_DATES)">
            Add critical dates
            <i-lucide name="arrow-right" [size]="18" class="my-icon inline-block"></i-lucide></span>
        </div>
      </div>
    </div>

  </div> -->
</div>

<p-dialog header="Select Documents" [(visible)]="isShowFeeDocumentDialog" [style]="{width: '50vw'}" [modal]="true" [draggable]="false"
  [autoZIndex]="true">
  <div>
    <div class="grid grid-cols-12 mb-4">
      <div class="col-span-6">
        <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
          <div class="text-gray-700 text-sm font-semibold leading-4">Document Title</div>
        </label>

        <input
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-700 text-sm"
          type="text" [formControl]="docTitle" id="title" placeholder="---"
          [ngClass]="{'is-invalid': docTitle.invalid && (docTitle.dirty || docTitle.touched)}">
        <div *ngIf="docTitle.invalid && (docTitle.dirty || docTitle.touched)">
          <p class="text-red-500 " *ngIf="docTitle.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="docTitle.errors?.['maxlength']">max 250 character</p>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 mb-4">
      <div class="col-span-6">
        <label for="materialId" class="text-gray-700 text-sm font-semibold leading-4">
          <div class="text-gray-700 text-sm font-semibold leading-4">Description</div>
        </label>
        <textarea type="text" type="text" [formControl]="docDescription" id="title" placeholder="---"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400  px-2 py-1 text-gray-700 text-sm"
          id="description" rows="5"
          [ngClass]="{'is-invalid': docDescription.invalid && (docDescription.dirty || docDescription.touched)}"></textarea>
        <div *ngIf="docDescription.invalid && (docDescription.dirty || docDescription.touched)">
          <p class="text-red-500 " *ngIf="docDescription.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="docDescription.errors?.['maxlength']">max 1000 character</p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 w-full gap-2 mb-4">
      <div class="">
        <label for="materialId" class="text-gray-700 text-xs font-semibold leading-4">
          <div class="text-gray-700 text-sm font-semibold leading-4">Select Document</div>
        </label>
        <div class="">
          <input id="documentDoc1" type="text" (click)="documentDoc.click()" [formControl]="docFile"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-0 text-gray-700 text-sm"
            readonly />
          <input id="documentDoc2" #documentDoc onclick="this.value=null;" type="file" (change)="chooseFile($event)"
            class="hidden" />
          <div *ngIf="docFile.invalid && (docFile.dirty || docFile.touched)">
            <p class="text-red-500 " *ngIf="docFile.errors?.['required']">Required</p>
          </div>
        </div>
      </div>
      <div class="">
        <div class="grid grid-cols-4 w-full gap-2 items-center">
          <div class="col-span-2 mt-2">
          </div>
          <div class=" md:me-2 mt-3">
            <button
              class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-600 font-medium rounded text-sm p-1 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
              *ngIf="currentFileInfoDto" (click)="previewRfxDoc('SERVER')">
              <span> <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class"></span>
            </button>
            <button
              class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-600 font-medium rounded text-sm p-1 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
              *ngIf="currentFile && !currentFileInfoDto" (click)="previewRfxDoc('LOCAL')">
              <span> <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class"></span>
            </button>
          </div>
          <!-- <div class=" mt-3">
            <span (click)="removeRfxDoc()">
              <span> <img src="assets/icons/delete_danger.svg" class=" c-pointer svg-icon-class" ></span>
            </span>
          </div> -->
          <div class="mt-3" *ngIf="currentFile || !currentFileInfoDto">
            <span (click)="removeDoc()">
              <span>
                <img src="assets/icons/delete_danger.svg" class="c-pointer svg-icon-class"
                  [class.disabled]="!currentFile">
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr class="hr my-3">

    <div class="text-end ">
      

      <div class="text-end gap-2 ">
        <p-button [loading]="isLoadingModal" label="Save"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          (onClick)="uploadFile()"></p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-toast></p-toast>