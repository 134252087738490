<div class="p-4">
  <div class="grid px-6 py-12 items-center justify-center" *ngIf="technicalSectionTemplateDtos.length == 0">
    <img src="assets/images/approval-1.svg" class=" h-16 w-16 mx-auto my-0">
    <div class="flex items-center justify-center">
      <div class="text-xl font-medium text-gray-700  mt-6">
        No Technical section
      </div>
    </div>
    <div class="flex items-center justify-center">
      <div class="text-xs font-normal text-gray-500 mt-2 mb-6">
        You have not added any technical questions yet.
      </div>
    </div>
    <div class="flex items-center justify-center">
      <button
        class="inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        (click)="openNewTechSectionDrawer()">
        <i-lucide name="plus" [size]="18" color="#fff" class="my-icon"></i-lucide>
        Add Technical Section
      </button>
    </div>
  </div>

  <div *ngIf="technicalSectionTemplateDtos.length > 0">
    <!-- Score Informations -->
    <div class="flex flex-row items-center justify-between">
      <div class="basis-11/12">
        <div class="flex flex-row p-3">
          <div class="me-2">
            <div class="text-gray-500 text-xs font-medium ">
              Maximum score
            </div>
            <div class="text-base text-gray-800 font-bold">
              {{tenderQuestionnaireTemplateDto?.maximumScore}}
            </div>
          </div>
          <div class="border h-10 border-gray-200 me-2"></div>
          <div class="me-2">
            <div class="text-gray-500 text-xs font-medium">
              Technical weightage
            </div>
            <div class="text-base text-gray-800 font-bold">
              {{tenderQuestionnaireTemplateDto?.technicalWeightage}}
            </div>
          </div>
          <div class="border h-10 border-gray-200 me-2"></div>
          <div class="me-2">
            <div class="text-gray-500 text-xs font-medium">
              Financial weightage
            </div>
            <div class="text-base text-gray-800 font-bold">
              {{tenderQuestionnaireTemplateDto?.financialWeightage}}
            </div>
          </div>
          <div class="border h-10 border-gray-200 me-2"></div>
          <div class="me-2">
            <div class="text-gray-500 text-xs font-medium">
              Qualifying Score
            </div>
            <div class="text-base text-gray-800 font-bold">
              {{tenderQuestionnaireTemplateDto?.qualifyingScore}}
            </div>
          </div>

        </div>
      </div>
     
    </div>

    <!-- Section 1 -->
    <div class="mb-4" *ngFor="let section of technicalSectionTemplateDtos; index as i">
      <div class="border border-gray-200 w-full"></div>
      <div class="flex flex-row items-center justify-between">
        <div class="">
          <div class="me-2">
            <div class="text-gray-500 text-xs font-medium ">
              Section name
            </div>
            <div class="text-sm text-black font-medium">
              {{section.sectionName}}
            </div>
          </div>
        </div>
        <div class=" text-end">
          <div class="inline-flex flex-row p-3 text-end">
            <div class="me-2 text-start">
              <div class="text-gray-500 text-xs font-medium">
                Score
              </div>
              <div class="text-base text-gray-800 font-bold">
                {{section.sectionScore || 0}}
              </div>
            </div>
            <div class="border h-10 border-gray-200 me-2"></div>
            <div class="me-2 text-start">
              <div class="text-gray-500 text-xs font-medium">
                Question
              </div>
              <div class="text-base text-gray-800 font-bold">
                {{section.noOfQuestions || 0}}
              </div>
            </div>
            <div class="border h-10 border-gray-200 me-2"></div>
            <div class="me-2 text-start mt-2">
              <div class="inline-flex text-blue-500 underline text-xs font-medium cursor-pointer"
                (click)="openNewTechSectionDrawer()">
                <i-lucide name="plus" [size]="16" color="#0555F5" class="my-icon inline-flex"></i-lucide>  Add
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center mb-2" *ngFor="let question of getQuestionsOfSection(section.sectionId!);">
        <div class="w-6">
          <div class="w-5 h-6 bg-gray-200 rounded ps-2">
            {{question.sequenceNo}}
          </div>
        </div>
        <div class="w-full">
          <div class="border border-gray-200 rounded-lg p-3">
            <div class="flex flex-row justify-between items-center">
              <div class="basis-9/12">
                <div class="text-gray-800 font-normal text-sm">
                  {{question.responseType}}
                </div>
                <div class="text-balck font-semibold text-sm">
                  {{question.questionText}}
                </div>
              </div>
              <div class="basis-3/12 text-end">
                <div class="flex flex-row items-center">
                  <div class="basis-3/4 ">
                    <div class="inline-flex items-center me-2">
                      <label for="switch1"> {{question.responseMandatory ? 'Required' : 'Optional'}}</label>
                    </div>
                  </div>
                  <div class="border h-10 border-gray-200 me-2"></div>
                  <div class="basis-1/4 text-center">
                    Score
                    <div class="border mx-3 rounded-lg border-gray-200 px-3 py-1 mt-1">
                      <div class="text-black text-base font-semibold">
                        {{question.score}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>