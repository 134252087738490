import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { ApprovalWorkflowEnum } from 'src/app/shared/enums/ApprovalWorkflowEnum';
import { ApprovalWorkflowStatusEnum } from 'src/app/shared/enums/ApprovalWorkflowStatusEnum';
import { EntityActionLabelEnum } from 'src/app/shared/enums/EntityActionLabelEnum';
import { EntityActionTypeEnum } from 'src/app/shared/enums/EntityActionTypeEnum';
import { EventEnum } from 'src/app/shared/enums/EventEnum';
import { ApprovalWorkflowUiDto } from 'src/app/shared/models/approval-workflow/ApprovalWorkflowUiDto';
import { AWFAppliedUiDto } from 'src/app/shared/models/approval-workflow/AWFAppliedUiDto';
import { AWFDefineUiDto } from 'src/app/shared/models/approval-workflow/AWFDefineUiDto';
import { AWFSnapshotUiDto } from 'src/app/shared/models/approval-workflow/AWFSnapshotUiDto';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { UserInfoUiDto } from 'src/app/shared/models/user/UserInfoUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { TenderManagementService } from 'src/app/shared/services/tender-management.service';
import { selectOrganizationUiDto } from 'src/app/shared/state-management/onboarding/onboarding.features';
import { TenderManagementActions } from 'src/app/shared/state-management/tender/tender.actions';
import { selectTenderWrapperUiDto, selectTenderOpportunityWrapperDto, selectAllAdminUsers } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-approvals-technical-bid-openers-drawer',
  templateUrl: './approvals-technical-bid-openers-drawer.component.html',
  styleUrls: ['./approvals-technical-bid-openers-drawer.component.sass']
})
export class ApprovalsTechnicalBidOpenersDrawerComponent implements OnInit {
  approvalWorkflowUiDtos: ApprovalWorkflowUiDto[] = [];
  ctrlSelectedApprovals: FormControl = new FormControl('', Validators.required);
  ctrlMandatoryApprovals: FormControl = new FormControl({ value: '', disabled: true }, Validators.required);
  tenderWrapperUiDto?: TenderWrapperUiDto;
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;
  userInfoUiDtos: UserInfoUiDto[] = [];

  awfAppliedUiDtos: AWFAppliedUiDto[] = [];
  awfSnapshotUiDtos: AWFSnapshotUiDto[] = [];

  noOfApprovals: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  noOfMandatoryApprovals: number[] = [1];
  selectedUserIds: Set<string> = new Set();
  newApprovalId: string = UUID.UUID().toString();
  isSubmitted: boolean = false;
  errorMsg: string | undefined;
  isLoading: boolean = false;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  tenderWrapperUiDtoSubscription$?: Subscription;
  tenderOpportunityWrapperDtoSubscription$?: Subscription;
  userInfoUiDtosSubscription$?: Subscription;

  constructor(
    private store: Store,
    private drawerService: DrawerService,
    private tenderManagementService: TenderManagementService,
    private messageService: MessageService

  ) { }

  
  ngOnInit(): void {
    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);

        let tenderOpportunityWrapperDtos = this.tenderWrapperUiDto?.tenderOpportunityWrapperDtos || [];
        if (tenderOpportunityWrapperDtos.length > 0) {
          if (this.tenderOpportunityWrapperDto) {
            let index = tenderOpportunityWrapperDtos.findIndex(item => item.tenderOpportunityId == this.tenderOpportunityWrapperDto?.tenderOpportunityId);
            let tenderOpportunityWrapperDto = tenderOpportunityWrapperDtos[index];
            this.store.dispatch(TenderManagementActions.setCurrentTenderOpportunityWrapperDto({ tenderOpportunityWrapperDto }));
          }
        }
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
        this.approvalWorkflowUiDtos = this.tenderOpportunityWrapperDto?.approvalWorkflowUiDto || []
        
        this.populatePublishersDetails();
      } else {
        this.tenderOpportunityWrapperDto = undefined;
      }
    })

    this.userInfoUiDtosSubscription$ = this.store.pipe(select(selectAllAdminUsers)).subscribe(data => {
      if (data) {
        this.userInfoUiDtos = ApplicationUtils.deepClone(data);
      } else {
        this.userInfoUiDtos = [];
      }
    })

    this.ctrlSelectedApprovals.statusChanges.subscribe((status) => {
      if (this.ctrlSelectedApprovals.invalid) {
        this.ctrlMandatoryApprovals.disable();
      } else {
        this.ctrlMandatoryApprovals.enable();
      }
    });
  }
  ngAfterViewInit(): void {
    this.ctrlSelectedApprovals.valueChanges.subscribe(val => {
      if (val) {
        this.noOfMandatoryApprovals = Array.from({ length: val }, (v, k) => k + 1);
        this.isSubmitted = false;

        if (this.awfAppliedUiDtos.length > Number(val || 0)) {
          this.awfAppliedUiDtos = this.awfAppliedUiDtos.filter(item => Number(item.sequence) <= Number(val || 0));
          this.awfSnapshotUiDtos = this.awfSnapshotUiDtos.filter(item => Number(item.sequence) <= Number(val || 0));

          this.selectedUserIds = new Set();
          this.awfAppliedUiDtos.forEach(item => {
            this.selectedUserIds.add(item.userId!)
          })
        }
      }
    })
  }

  populatePublishersDetails() {
    let tenderPublisher = this.approvalWorkflowUiDtos.find(item => item.awfDefineUiDto?.wfCode == ApprovalWorkflowEnum.TENDER_TECHNICAL_OPENING);

    if (tenderPublisher) {
      this.awfAppliedUiDtos = tenderPublisher.appliedUiDtos || [];
      this.awfSnapshotUiDtos = tenderPublisher.snapshotUiDtos || [];

      this.ctrlSelectedApprovals.patchValue(tenderPublisher.awfDefineUiDto?.noOfApprovals);
      this.ctrlMandatoryApprovals.patchValue(tenderPublisher.awfDefineUiDto?.mandatoryApprovals);
      this.ctrlMandatoryApprovals.enable();

      this.ctrlSelectedApprovals.updateValueAndValidity();
      this.ctrlMandatoryApprovals.updateValueAndValidity();

      let noOfApprovals = tenderPublisher.awfDefineUiDto?.noOfApprovals || 0;
      this.noOfMandatoryApprovals = Array.from({ length: noOfApprovals }, (v, k) => k + 1);

      this.isSubmitted = true;

      this.awfAppliedUiDtos.forEach(item => {
        this.selectedUserIds.add(item.userId!)
      })
    }
  }

   getSelectedUserId(sequenceNo: number) {
    if (this.awfAppliedUiDtos.length > 0) {
      return this.awfAppliedUiDtos.find(item => item.sequence == sequenceNo)?.userId;
    }
    return "";
   }
  
  async onChangeUserSelect(userId: string, sequence: number) {
    const organization = await firstValueFrom(this.store.pipe(select(selectOrganizationUiDto)));
    const existingUserInSequence = this.awfAppliedUiDtos.find(item => item.sequence == sequence);
    const userInfoUiDto = this.userInfoUiDtos.find(item => item.userId == userId);

    if (existingUserInSequence) {
      const oldSequenceIndexApplied = this.awfAppliedUiDtos.findIndex(item => item.sequence == sequence);
      if (oldSequenceIndexApplied !== -1) {
        this.awfAppliedUiDtos.splice(oldSequenceIndexApplied, 1); // Remove from old sequence
        this.selectedUserIds.delete(existingUserInSequence.userId!); // Remove from selected set
      }

      const oldSequenceIndexSnapshot = this.awfSnapshotUiDtos.findIndex(item => item.sequence == sequence);
      if (oldSequenceIndexSnapshot !== -1) {
        this.awfSnapshotUiDtos.splice(oldSequenceIndexSnapshot, 1); // Remove from old sequence
      }
    }

    // Assign new user to the sequence
    let awfAppliedUiDto = new AWFAppliedUiDto();
    awfAppliedUiDto.orgCode = organization?.orgCode;
    awfAppliedUiDto.approvalId = this.newApprovalId;
    awfAppliedUiDto.appliedId = UUID.UUID().toString();
    awfAppliedUiDto.resourceId = this.tenderWrapperUiDto?.tenderId;
    awfAppliedUiDto.subResourceId = this.tenderOpportunityWrapperDto?.tenderOpportunityId;
    awfAppliedUiDto.resourceType = EventEnum.TENDER;
    awfAppliedUiDto.userId = userInfoUiDto?.userId;
    awfAppliedUiDto.userInfoUiDto = userInfoUiDto;
    awfAppliedUiDto.emailId = userInfoUiDto?.emailId;
    awfAppliedUiDto.wfCode = ApprovalWorkflowEnum.TENDER_TECHNICAL_OPENING;
    awfAppliedUiDto.sequence = sequence;

    let awfSnapshotUiDto = new AWFSnapshotUiDto();
    awfSnapshotUiDto.orgCode = organization?.orgCode;
    awfSnapshotUiDto.snapshotId = UUID.UUID().toString();
    awfSnapshotUiDto.approvalId = this.newApprovalId;
    awfSnapshotUiDto.userId = userInfoUiDto?.userId;
    awfSnapshotUiDto.userInfoUiDto = userInfoUiDto;
    awfSnapshotUiDto.emailId = userInfoUiDto?.emailId;
    awfSnapshotUiDto.resourceId = this.tenderWrapperUiDto?.tenderId;
    awfSnapshotUiDto.subResourceId = this.tenderOpportunityWrapperDto?.tenderOpportunityId;
    awfSnapshotUiDto.resourceType = EventEnum.TENDER;
    awfSnapshotUiDto.comments = undefined;
    awfSnapshotUiDto.status = ApprovalWorkflowStatusEnum.NEW;
    awfSnapshotUiDto.wfCode = ApprovalWorkflowEnum.TENDER_TECHNICAL_OPENING;
    awfSnapshotUiDto.sequence = sequence;

    this.awfAppliedUiDtos.push(awfAppliedUiDto);
    this.awfSnapshotUiDtos.push(awfSnapshotUiDto);

    this.selectedUserIds.add(userId); // Add to selected set
    this.isSubmitted = false;
  }

  resetApprovals() {
    this.awfAppliedUiDtos = [];
    this.awfSnapshotUiDtos = [];
    this.selectedUserIds = new Set();
    this.noOfMandatoryApprovals = [];
    this.ctrlSelectedApprovals.reset();
    this.ctrlMandatoryApprovals.reset();
    this.isSubmitted = false;
  }

  addAndVerifyApprovals() {
    if (this.ctrlSelectedApprovals.invalid) {
      this.ctrlSelectedApprovals.markAllAsTouched();
      return;
    }

    if (this.ctrlMandatoryApprovals.invalid) {
      this.ctrlMandatoryApprovals.markAllAsTouched();
      return;
    }

    if (this.awfAppliedUiDtos.length == Number(this.ctrlSelectedApprovals.value || 0)) {
      this.isSubmitted = true;
    }
  }

  margeTenderWrapperUiDto() {
    let tenderWrapperUiDto = ApplicationUtils.clone(this.tenderWrapperUiDto) as TenderWrapperUiDto;
    let tenderOpportunityWrapperDto = ApplicationUtils.clone(this.tenderOpportunityWrapperDto) as TenderOpportunityWrapperDto;

    //intialze actionDtos
    tenderWrapperUiDto.entityActionDtos = [];

    //add opportunity Action Event
    let opportunityActionDto = ApplicationUtils.addActionEvent(tenderOpportunityWrapperDto.tenderOpportunityId!, EntityActionLabelEnum.OPPORTUNITY, EntityActionTypeEnum.UPDATE);
    tenderWrapperUiDto.entityActionDtos.push(opportunityActionDto);

    //add approval workflow Action Event
    let approvalWorkflowActionDto = ApplicationUtils.addActionEvent(this.newApprovalId!, EntityActionLabelEnum.APPROVAL_WORKFLOW, EntityActionTypeEnum.ADD);
    tenderWrapperUiDto.entityActionDtos.push(approvalWorkflowActionDto);

    if (!tenderOpportunityWrapperDto.approvalWorkflowUiDto) {
      tenderOpportunityWrapperDto.approvalWorkflowUiDto = [];
    }

    let awfDefinedUiDto = new AWFDefineUiDto();
    awfDefinedUiDto.orgCode = tenderWrapperUiDto.orgCode;
    awfDefinedUiDto.themeName = 'GOVT';
    awfDefinedUiDto.approvalId = this.newApprovalId;
    awfDefinedUiDto.resourceId = tenderWrapperUiDto.tenderId;
    awfDefinedUiDto.subResourceId = tenderOpportunityWrapperDto.tenderOpportunityId;
    awfDefinedUiDto.resourceType = EventEnum.TENDER;
    awfDefinedUiDto.name = 'Approval Workflow';
    awfDefinedUiDto.type = 'TENDER_TECHNICAL_OPENING';
    awfDefinedUiDto.wfCode = ApprovalWorkflowEnum.TENDER_TECHNICAL_OPENING;
    awfDefinedUiDto.companyCode = 'ALL';
    awfDefinedUiDto.plantCode = 'ALL';
    awfDefinedUiDto.ruleActive = false;
    awfDefinedUiDto.noOfApprovals = Number(this.ctrlSelectedApprovals.value || 0);
    awfDefinedUiDto.mandatoryApprovals = Number(this.ctrlMandatoryApprovals.value || 0);

    let approvalWorkflowUiDto = new ApprovalWorkflowUiDto();
    approvalWorkflowUiDto.awfDefineUiDto = awfDefinedUiDto;
    approvalWorkflowUiDto.appliedUiDtos = this.awfAppliedUiDtos;
    approvalWorkflowUiDto.snapshotUiDtos = this.awfSnapshotUiDtos;

    let indexApprovalWorkflow = tenderOpportunityWrapperDto.approvalWorkflowUiDto!.findIndex(item => item.awfDefineUiDto?.wfCode == awfDefinedUiDto?.wfCode);
    if (indexApprovalWorkflow != undefined && indexApprovalWorkflow > -1) {
      Object.assign(tenderOpportunityWrapperDto.approvalWorkflowUiDto![indexApprovalWorkflow], approvalWorkflowUiDto);
    } else {
      tenderOpportunityWrapperDto.approvalWorkflowUiDto!.push(approvalWorkflowUiDto);
    }

    tenderWrapperUiDto.validationErrorCodeDtoList = [];

    let index = tenderWrapperUiDto.tenderOpportunityWrapperDtos!.findIndex(item => item.tenderOpportunityId == tenderOpportunityWrapperDto.tenderOpportunityId);
    if (index != undefined && index > -1) {
      Object.assign(tenderWrapperUiDto.tenderOpportunityWrapperDtos![index], tenderOpportunityWrapperDto);
    } else {
      tenderWrapperUiDto.tenderOpportunityWrapperDtos!.push(tenderOpportunityWrapperDto);
    }

    return tenderWrapperUiDto;
  }

  async saveTenderWrapperUiDto() {
    

    console.log(this.isSubmitted);    

    if (!this.isSubmitted || this.awfAppliedUiDtos.length == 0 || this.awfSnapshotUiDtos.length == 0) {
      return;
    }

    let tenderWrapperUiDto = this.margeTenderWrapperUiDto()
    console.log(tenderWrapperUiDto);

    this.isLoading = true;

    try {
      const response = await firstValueFrom(this.tenderManagementService.saveTenderWrapperUiDto(tenderWrapperUiDto));
      if (response.code === ApplicationConstants.SUCCESS_CODE) {
        this.isLoading = false;
        this.messageService.add({ severity:'success', summary: 'Success', detail: 'Approvals saved successfully.' });

        setTimeout(() => {

          const tenderWrapperUiDto = response.data as TenderWrapperUiDto;
          this.store.dispatch(TenderManagementActions.setCurrentTenderWrapperUiDto({ tenderWrapperUiDto }));
        }, 2000);
      } else {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: response.message  });
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;

    
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Error while saving Approvals. Try again.' });
    }
  }

  closeDrawer() {
    this.drawerService.closeDrawer();
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }

    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}

