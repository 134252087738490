import { Timestamp } from '@angular/fire/firestore';


import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, catchError, firstValueFrom, tap } from "rxjs";
import { timeout } from "rxjs/operators";

import { ErrorService } from "./error.service";

import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';

import { ApplicationConstants } from '../util/ApplicationConstants';
import { BulkLotCreationStatus } from '../models/BulkLotCreationStatus';
import { RfxSubcategoryUiDto } from '../models/rfx/RfxSubcategoryUiDto';
import { RfxTargetedBiddersNotificationEntityDto } from '../models/RfxTargetedBiddersNotificationEntityDto';


@Injectable({
  providedIn: 'root'
})
export class AdminRfxSubcategoryDataHolderService implements OnDestroy{
  allRfxSubcategoryList?: RfxSubcategoryUiDto[];

  _allRfxSubcategoryList$ = new BehaviorSubject<RfxSubcategoryUiDto[]>([]);
  _allRfxSubcategoryListOfPostRfx$ = new BehaviorSubject<RfxSubcategoryUiDto[]>([]);
  _selectedRfxSubcategory$ = new BehaviorSubject<RfxSubcategoryUiDto | null>(null);

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) { }

  get selectedRfxSubcategory$() { return this._selectedRfxSubcategory$.asObservable() }
  get getAllRfxSubcategoryList$() { return this._allRfxSubcategoryList$.asObservable() }
  get getRfxSubcategoryListOfPostRfx$() { return this._allRfxSubcategoryListOfPostRfx$.asObservable() }

  updateAllPostRfxSubcategories(subcategory: RfxSubcategoryUiDto[]){
    this._allRfxSubcategoryListOfPostRfx$.next(subcategory);
  }

  // This method to be used only on admin dashboard
  setSelectedRfxSubcategory(subcategory: RfxSubcategoryUiDto) {
    this._selectedRfxSubcategory$.next(subcategory!);
  }

  setSelectedRfxSubcategoryByRfxAndSubcategoryId(rfxId: string, subcategoryId: string ) {
    if(this.allRfxSubcategoryList && this.allRfxSubcategoryList.length > 0){
      let subcategory = this.allRfxSubcategoryList.find(item => item.rfxId == rfxId && item.subcategoryId == subcategoryId);
      this._selectedRfxSubcategory$.next(subcategory!);
    }

  }


  setRfxSubcategoryForSingleRfxSubcategoryRfx(rfxId: string){
    if(this.allRfxSubcategoryList && this.allRfxSubcategoryList.length > 0){
      let rfxSubcategoryList = this.allRfxSubcategoryList.filter(item => item.rfxId == rfxId);
      if(rfxSubcategoryList && rfxSubcategoryList.length > 0){
        rfxSubcategoryList.sort((a, b) => a.subcategorySequence! - b.subcategorySequence!);
        this.setSelectedRfxSubcategory(rfxSubcategoryList[0]);
      }
    }
  }

  getRfxSubcategoryEntityDtoById(subcategoryId: string) {
    return this.allRfxSubcategoryList?.find(item => item.subcategoryId == subcategoryId);
  }

  updateAllRfxSubcategoryData(rfxSubcategoryUiDtos: RfxSubcategoryUiDto[]) {
    this.allRfxSubcategoryList = [];
    this._allRfxSubcategoryList$.next([]);

    if (rfxSubcategoryUiDtos && rfxSubcategoryUiDtos.length > 0) {
      this.allRfxSubcategoryList = rfxSubcategoryUiDtos;
      this._allRfxSubcategoryList$.next(this.allRfxSubcategoryList);
      // this.changeTimeStempTostring();
      if (this._selectedRfxSubcategory$.value) {
        let rfxId = this._selectedRfxSubcategory$.value.rfxId
        let subcategoryId = this._selectedRfxSubcategory$.value.subcategoryId

        let selectedRfxSubcategory = this.allRfxSubcategoryList.find(item => (item.rfxId == rfxId) && (item.subcategoryId == subcategoryId));
        if (selectedRfxSubcategory) {
          this.setSelectedRfxSubcategory(selectedRfxSubcategory!);
        }else{
          this.setRfxSubcategoryForSingleRfxSubcategoryRfx(this.allRfxSubcategoryList[0].rfxId!);
        }
      }else{
        this.setRfxSubcategoryForSingleRfxSubcategoryRfx(this.allRfxSubcategoryList[0].rfxId!);
      }

    }
  }


  removeFirstRfxSubcategoryIfRequired() {
    if (this.allRfxSubcategoryList && this.allRfxSubcategoryList.length > 0) {
      // find rfxSubcategory by rfxSubcategory sequence - 1 , 1
      let rfxSubcategoryList = this.allRfxSubcategoryList.filter(item => item.subcategorySequence == 1) as RfxSubcategoryUiDto[];

      rfxSubcategoryList.forEach(rfxSubcategory => {
        // remove rfxSubcategory where rfxSubcategory name is blank and version = 0
        if (rfxSubcategory && !rfxSubcategory?.title && rfxSubcategory.version == 0) {
          let index = this.allRfxSubcategoryList!.findIndex(item => item.subcategoryId == rfxSubcategory.subcategoryId);
          if (index > -1) {
            this.allRfxSubcategoryList!.splice(index, 1)
            this.setSelectedRfxSubcategory(this.allRfxSubcategoryList![0])
            this._allRfxSubcategoryList$.next(this.allRfxSubcategoryList!);
          }

        }
      });
    }
  }

  async checkUploadStatusOfBulkSubcategories(auctionId: string): Promise<BulkLotCreationStatus|undefined> {
    let bulkLotCreationStatus: BulkLotCreationStatus | undefined;
    try {
      let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.getBulkLotStatus(auctionId));
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          if (apiResponseDto.data) {
            bulkLotCreationStatus = apiResponseDto.data as BulkLotCreationStatus
          }
        }
    } catch (error) {
      console.error(error);
    }
    return bulkLotCreationStatus;
  }

  updateSubcategoriesChangesData(rfxSubcategoryEntityDtos: RfxSubcategoryUiDto[]) {
    rfxSubcategoryEntityDtos.forEach(updatedRfxSubcategory => {

       // Changed Firestore timestamp to server timestamp

       if(updatedRfxSubcategory.endDateUTC){
        let endDateUTC  = updatedRfxSubcategory.endDateUTC as Timestamp;
        updatedRfxSubcategory.endDateUTC = (endDateUTC.seconds * 1000).toString();
       }

       let updateTimeUtc  = updatedRfxSubcategory.updateTimeUtc as Timestamp;
       updatedRfxSubcategory.updateTimeUtc = (updateTimeUtc.seconds * 1000).toString();


      let isSubcategoryExistsInAllSubcategories = this.allRfxSubcategoryList?.find(item => item.subcategoryId == updatedRfxSubcategory.subcategoryId);
      if (isSubcategoryExistsInAllSubcategories) {

        let index = this.allRfxSubcategoryList?.findIndex(item => item.subcategoryId == updatedRfxSubcategory.subcategoryId)!;
        Object.assign(this.allRfxSubcategoryList![index], updatedRfxSubcategory);
      }else{

        this.allRfxSubcategoryList?.push(updatedRfxSubcategory);
      }
    })

    this._allRfxSubcategoryList$.next(this.allRfxSubcategoryList!);

    if (this._selectedRfxSubcategory$.value) {
      let selectedSubcategory = this.allRfxSubcategoryList?.find(item => item.subcategoryId == this._selectedRfxSubcategory$.value?.subcategoryId);
      if (selectedSubcategory) {
        this._selectedRfxSubcategory$.next(selectedSubcategory);
      }
    }
  }

  
  getBulkLotStatus(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('bulkLotStatus', { params }).pipe(
      tap(_ => console.log("Getting bulkLotStatus to DB")),
      catchError(this.errorService.handleError<any>("Error while getting bulkLotStatus from DB")))
  }


  addSubcategory(rfxSubcategoryEntityDto: RfxSubcategoryUiDto) {
    this.allRfxSubcategoryList?.push(rfxSubcategoryEntityDto);
    this._allRfxSubcategoryList$.next(this.allRfxSubcategoryList!);
  }

  downloadBulkRfxSubcategoryExcel() {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
        'responseType'  : 'arraybuffer' as 'json',
         'headers' : headers,
      };
    return this.http.get<ServerAPIResponseDto>('createBulkLots', httpOptions).pipe(timeout(600000),
      tap(_ => console.log("Getting excel sheet for bulk lots of auction ")),
      catchError(this.errorService.handleError<any>("Error while getting excel sheet for bulk lots of auction ")))
  }

  createLotsByExcel(file: any, auctionId : string) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('auctionId', auctionId)
    return this.http.post<ServerAPIResponseDto>('createLotsByExcel', formData).pipe(timeout(1800000),
      tap(_ => console.log("Uploading excel sheet for bulk lots")),
      catchError(this.errorService.handleError<any>("Error while Uploading excel sheet for bulk lots")))
  }

  createRfxSubcategoriesByExcel(file: any, rfxId : string) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('rfxId', rfxId)
    return this.http.post<ServerAPIResponseDto>('createRfxSubcategoriesByExcel', formData).pipe(timeout(1800000),
      tap(_ => console.log("Uploading excel sheet for bulk subcategories ")),
      catchError(this.errorService.handleError<any>("Error while Uploading excel sheet for bulk subcategories")))
  }



  saveRfxSubcategoryDetails(rfxSubcategoryEntityDto: RfxSubcategoryUiDto) {
    return this.http.post<ServerAPIResponseDto>('rfxSubcategories', rfxSubcategoryEntityDto).pipe(
      tap(_ => console.log("Saved rfx Subcategories to DB" + rfxSubcategoryEntityDto.rfxId)),
      catchError(this.errorService.handleError<any>("Error while Saving rfxSubcategory to DB" + rfxSubcategoryEntityDto.rfxId)))
  }

  getRfxTargetedBiddersNotifications(auctionHouseId: string, rfxId: string, rfxSubcategoryId: string) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('rfxId', rfxId).set('rfxSubcategoryId', rfxSubcategoryId);
    return this.http.get<ServerAPIResponseDto>('rfxTargetedBiddersNotifications', { params }).pipe(
      tap(_ => console.log("Get getRfxTargetedBiddersNotifications")),
      catchError(this.errorService.handleError<any>("Error while getting getRfxTargetedBiddersNotifications")));
  }

  
  /// API CALLS
  saveRfxTargetedBiddersNotifications(rfxTargetedBiddersNotificationEntityDto: RfxTargetedBiddersNotificationEntityDto) {
    return this.http.post<ServerAPIResponseDto>('rfxTargetedBiddersNotifications', rfxTargetedBiddersNotificationEntityDto).pipe(
      tap(_ => console.log("Saved RfxTargetedBiddersNotifications to DB" + rfxTargetedBiddersNotificationEntityDto.rfxId)),
      catchError(this.errorService.handleError<any>("Error while Saving RfxTargetedBiddersNotifications to DB" + rfxTargetedBiddersNotificationEntityDto.rfxId)))
  }

  ngOnDestroy(): void {
  }
}
