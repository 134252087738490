import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormArray, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Subscription } from "rxjs";
import { SourcingEnvelopeType } from "src/app/shared/enums/questionnaire/SourcingEnvelopeType";
import { ServerAPIResponseDto } from "src/app/shared/models/ServerAPIResponseDto";
import { QuestionnaireTemplate } from "src/app/shared/models/questionnaire/QuestionnaireTemplate";
import { QuestionnaireWrapperDto } from "src/app/shared/models/questionnaire/QuestionnaireWrapperDto";
import { QuestionnaireService } from "src/app/shared/services/questionnaire.service";
import { ApplicationConstants } from "src/app/shared/util/ApplicationConstants";
import { ApplicationUtils } from "src/app/shared/util/ApplicationUtils";
import { SectionTemplate } from "src/app/shared/models/questionnaire/SectionTemplate";
import { Pattern } from "src/app/shared/util/Patterns";
import { QuestionnaireStatus } from "src/app/shared/enums/questionnaire/QuestionnaireStatus";
import { AdminSourcingEventsDataHolderService } from "src/app/shared/services/AdminSourcingEventsDataHolder.service ";
import { RfxQuestionnaireTechnicalSectionComponent } from "../rfx-questionnaire-technical-section/rfx-questionnaire-technical-section.component";
import { RfxQuestionnaireFinancialSectionComponent } from "../rfx-questionnaire-financial-section/rfx-questionnaire-financial-section.component";
import { RfxUiDto } from "src/app/shared/models/rfx/RfxUiDto";
import { AdminRfxSubcategoryDataHolderService } from "src/app/shared/services/AdminRfxSubcategoryDataHolderService.service";
import { RfxSubcategoryUiDto } from "src/app/shared/models/rfx/RfxSubcategoryUiDto";
import { RfxStatus } from "src/app/shared/enums/rfx/RfxStatus";
import { RfxTypeEnum } from "src/app/shared/enums/rfx/RfxTypeEnum";
import { EventEnum } from "src/app/shared/enums/EventEnum";
import { ContractAlgoEnum } from "src/app/shared/enums/rfx/ContractAlgoEnum";
import { ContractTypeEnum } from "src/app/shared/enums/rfx/ContractTypeEnum";
import { TechnicalGradationDto } from "src/app/shared/models/rfx/TechnicalGradationDto";
import { QualifyingStatus } from "src/app/shared/enums/rfx/QualifyingStatus";
import { multipleOfFiveValidator } from "src/app/shared/validators/multiple-of-five.validator";

@Component({
  selector: 'app-rfx-questionnaire',
  templateUrl: './rfx-questionnaire.component.html',
  styleUrls: ['./rfx-questionnaire.component.sass']
})
export class RfxQuestionnaireComponent implements OnInit, OnDestroy {
  formGroup: FormGroup

  defaultEnvelopesList: string[] = [
    SourcingEnvelopeType.TECHNICAL,
    SourcingEnvelopeType.FINANCIAL
  ]

  ContractAlgoEnum: typeof ContractAlgoEnum = ContractAlgoEnum;
  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  questionnaireTemplate?: QuestionnaireTemplate;
  selectedRfx?: RfxUiDto;
  selectedRfxSubcategory?: RfxSubcategoryUiDto;
  techSectionTemplateList: SectionTemplate[] = []
  finSectionTemplateList: SectionTemplate[] = []
  technicalGradationDtos: Array<TechnicalGradationDto> = [];

  selectedEnvelopesList: string[] = []
  guidelinesList: string[] = []
  errorMsg: string | undefined;
  errorMsgForModal: string | undefined;
  isLoading: boolean = false;
  isLoadingForModal: boolean = false;
  isShowMoreGuideline: boolean = false;
  isShowPublishButton: boolean = false;
  isDataLoading: boolean = false;
  modError: boolean = false;

  selectedContractAlgo?: ContractAlgoEnum;

  guidelineModalRef?: NgbModalRef
  deleteConfirmModalRef?: NgbModalRef;
  publishConfirmModalRef?: NgbModalRef;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToastForModal$ = new BehaviorSubject<boolean>(false);
  _showErrorToastForModal$ = new BehaviorSubject<boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription
  selectedRfxSubcategorySubscription$?: Subscription

  @ViewChild('guidelineRef') guidelineRef?: ElementRef<HTMLParagraphElement>

  contractTypes: string[] = [ContractTypeEnum.ITEM_RATE, ContractTypeEnum.ITEM_WISE, ContractTypeEnum.LUMPSUM, ContractTypeEnum.PERCENTAGE, ContractTypeEnum.SUPPLY_RATE];
  contractAlgos: string[] = [];

  constructor(
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    private questionnaireService: QuestionnaireService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService
  ) {
    this.formGroup = this.formBuilder.group({
      templateName: ['test'],
      templateDescription: ['test'],
      scoringTemplate: [''],
      maximumScore: [''],
      range: [''],
      qualifyingScore: [''],
      envelopesList: [null],
      guideLines: [''],
      contractAlgo: [null],
      technicalWeightage: ['', Validators.pattern(Pattern.numberGreaterZero)],
      financialWeightage: ['', Validators.pattern(Pattern.numberGreaterZero)],
      gradationSystem: [null],
      technicalGradationDtos: this.formBuilder.array([]),
    })
  }

  ngOnInit(): void {
    this.selectedRfx = this.adminSourcingEventsDataHolderService.selectedRfx;

    if (this.selectedRfx?.rfxType == RfxTypeEnum.RFP || this.selectedRfx?.rfxType == RfxTypeEnum.RFQ || this.selectedRfx?.rfxType == RfxTypeEnum.TENDER) {
      this.contractAlgos = [ContractAlgoEnum.LCS, ContractAlgoEnum.QCBS];
    }

    this.selectedRfxSubcategorySubscription$ = this.adminRfxSubcategoryDataHolderService.selectedRfxSubcategory$.subscribe(data => {
      if (data) {
        this.selectedRfxSubcategory = data;
        this.questionnaireService.updateCurrentSubcategoryId(this.selectedRfxSubcategory.subcategoryId!);

        if (this.selectedRfxSubcategory?.questionnaireId) {
          this.isDataLoading = true;
          this.questionnaireService.loadQuestionnaireWrapperDto(this.selectedRfxSubcategory.questionnaireId, EventEnum.RFX);
        } else {
          this.questionnaireService.updateQuestionnaireWrapperDto(undefined);
        }
      } else {
        this.selectedRfxSubcategory = undefined;
        this.questionnaireService.updateQuestionnaireWrapperDto(undefined);
      }
    })

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.isDataLoading = false;
        this.questionnaireWrapperDto = data;
        this.questionnaireTemplate = this.questionnaireWrapperDto.questionnaireTemplate;

        this.techSectionTemplateList = this.questionnaireWrapperDto?.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL) ?? [];
        this.techSectionTemplateList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

        this.finSectionTemplateList = this.questionnaireWrapperDto?.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.FINANCIAL) ?? [];
        this.finSectionTemplateList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

        this.isShowPublishButton = this.selectedRfx?.status == RfxStatus.LIVE && this.questionnaireTemplate?.status == QuestionnaireStatus.DRAFT;

        this.populateFormGroup();
      } else {
        this.isDataLoading = false;
        this.questionnaireWrapperDto = undefined;
        this.questionnaireTemplate = undefined;
        this.techSectionTemplateList = [];
        this.finSectionTemplateList = [];
        this.guidelinesList = [];
        this.formGroup.reset();
      }
    })


    this.formGroup.controls['gradationSystem'].valueChanges.subscribe(() => {
      this.runScoringValidation();
    })

    this.formGroup.controls['contractAlgo'].valueChanges.subscribe((val) => {
      if (val != undefined) {
        this.selectedContractAlgo = val;
        // this.runContractAlgoValidation();
      }
    })

    this.formGroup.controls['scoringTemplate'].valueChanges.subscribe(() => {
      this.runScoringValidation();
    })
  }

  get fc(): any { return this.formGroup.controls; }

  populateFormGroup() {
    this.formGroup.controls['templateName'].patchValue(this.questionnaireTemplate?.templateName);
    this.formGroup.controls['templateDescription'].patchValue(this.questionnaireTemplate?.templateDescription);
    this.formGroup.controls['maximumScore'].patchValue(this.questionnaireTemplate?.maximumScore);
    this.formGroup.controls['qualifyingScore'].patchValue(this.questionnaireTemplate?.qualifyingScore);
    this.formGroup.controls['envelopesList'].patchValue(this.questionnaireTemplate?.envelopesList);

    if (this.questionnaireTemplate?.templateId) {
      this.formGroup.controls['scoringTemplate'].patchValue(this.questionnaireTemplate?.scoringTemplate);
    } else {
      this.formGroup.controls['scoringTemplate'].patchValue('');
    }

    this.selectedEnvelopesList = this.questionnaireTemplate?.envelopesList ?? [];
    if (this.questionnaireTemplate?.guideLines) {
      this.guidelinesList = this.questionnaireTemplate?.guideLines?.split('\n');
      this.formGroup.controls['guideLines'].patchValue(this.questionnaireTemplate?.guideLines);
      this.formGroup.updateValueAndValidity();
    }

    this.formGroup.get('technicalWeightage')?.patchValue(this.questionnaireTemplate?.technicalWeightage)
    this.formGroup.get('financialWeightage')?.patchValue(this.questionnaireTemplate?.financialWeightage)
    this.formGroup.controls['gradationSystem'].patchValue(this.questionnaireTemplate?.gradationSystem ?? false)

    this.selectedContractAlgo = this.questionnaireTemplate?.contractAlgo;
    this.formGroup.get('contractAlgo')?.patchValue(this.questionnaireTemplate?.contractAlgo);

    if (this.questionnaireTemplate?.gradationSystem && this.questionnaireTemplate?.technicalGradationDtos) {
      this.formGroup.get('range')?.patchValue(this.questionnaireTemplate?.range);
      
      let technicalGradationDtos = this.questionnaireTemplate.technicalGradationDtos;
      if (technicalGradationDtos && technicalGradationDtos.length > 0) {
        this.technicalGradationConfig.clear();
        this.technicalGradationDtos = [];

        technicalGradationDtos.sort((a, b) => b.startRange! - a.startRange!);
        technicalGradationDtos.forEach(config => {
          this.technicalGradationConfig.push(this.updateTechnicalGradationConfig(config));
          this.technicalGradationDtos.push(config);
        });
      }
    } else {
      this.formGroup.controls['technicalGradationDtos'].reset();
      this.technicalGradationConfig.clear();
    }

    this.formGroup.updateValueAndValidity();
  }

  changeScoringTemple(val: string) {
    this.formGroup.controls['scoringTemplate'].patchValue(val);
    this.formGroup.updateValueAndValidity();
  }

  onSelectEnvelope(val: string) {
    if (this.selectedEnvelopesList.includes(val)) {
      let index = this.selectedEnvelopesList.findIndex(m => m == val);
      this.selectedEnvelopesList.splice(index, 1);
    } else {
      this.selectedEnvelopesList.push(val);
    }

    this.formGroup.controls['envelopesList'].patchValue(this.selectedEnvelopesList);
    this.formGroup.updateValueAndValidity();

    // this.saveQuestionnaire();
  }

  getQuestionsCountOfSection(sectionId: string) {
    let technicalQuestionTemplates = this.questionnaireWrapperDto?.technicalQuestionTemplates ?? [];
    let financialQuestionTemplates = this.questionnaireWrapperDto?.financialQuestionTemplates ?? [];

    let technicalQuestionsCount = technicalQuestionTemplates.filter(item => item.sectionId == sectionId).length;
    let financialQuestionsCount = financialQuestionTemplates.filter(item => item.sectionId == sectionId).length;

    return technicalQuestionsCount + financialQuestionsCount;
  }

  getTechnicalQuestionsOfSection(sectionId: string) {
    let technicalQuestionTemplates = this.questionnaireWrapperDto?.technicalQuestionTemplates ?? [];
    return technicalQuestionTemplates.filter(item => item.sectionId == sectionId);
  }

  getFinancialQuestionsOfSection(sectionId: string) {
    let financialQuestionTemplates = this.questionnaireWrapperDto?.financialQuestionTemplates ?? [];
    return financialQuestionTemplates.filter(item => item.sectionId == sectionId);
  }

  openAddTechSectionModal() {
    // if (!this.questionnaireTemplate?.templateId) {
    //   return;
    // }
    let modalRef = this.ngbModal.open(RfxQuestionnaireTechnicalSectionComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.isNewQuestion = true;
  }

  openEditTechSectionModal(sectionId: string) {
    let modalRef = this.ngbModal.open(RfxQuestionnaireTechnicalSectionComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.selectedSectionId = sectionId;
    modalRef.componentInstance.isNewQuestion = false;
  }

  openAddFinancialSectionModal() {
    // if (!this.questionnaireTemplate?.templateId) {
    //   return;
    // }
    let modalRef = this.ngbModal.open(RfxQuestionnaireFinancialSectionComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.isNewQuestion = true;
  }

  openEditFinancialSectionModal(sectionId: string) {
    if (!this.questionnaireTemplate?.templateId) {
      return;
    }
    let modalRef = this.ngbModal.open(RfxQuestionnaireFinancialSectionComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.selectedSectionId = sectionId;
    modalRef.componentInstance.isNewQuestion = false;
  }

  openAddQuestionnaireModal(content: any) {
    this.formGroup.reset();
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.errorMsg = '';
    this.isLoading = false;

    this.populateFormGroup();

    this.isShowMoreGuideline = false;

    this.ngbModal.open(content, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });

  }

  openDeleteConfirmModal(content: any) {
    this._showErrorToastForModal$.next(false);
    this._showSuccessToastForModal$.next(false);
    this.errorMsgForModal = '';
    this.isLoadingForModal = false;

    this.deleteConfirmModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false
    })
  }

  openPublishConfirmModal(content: any) {
    this._showErrorToastForModal$.next(false);
    this._showSuccessToastForModal$.next(false);
    this.errorMsgForModal = '';

    this.publishConfirmModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false
    })
  }

  showMoreGuidelineModal() {
    this.isShowMoreGuideline = !this.isShowMoreGuideline;
    if (!this.isShowMoreGuideline) {
      this.guidelineRef!.nativeElement.style.height = '120px'
    } else {
      this.guidelineRef!.nativeElement.style.height = this.guidelineRef!.nativeElement.scrollHeight + 'px'
    }
  }

  haveQuestionnaireValidationIssues() {
    if (this.selectedRfx?.rfxType == RfxTypeEnum.PQ || this.selectedRfx?.rfxType == RfxTypeEnum.RFI) {
      return this.questionnaireService.checkTechnicalQuestionnaireValidations()
    }
    return this.questionnaireService.haveQuestionnaireValidationIssues(EventEnum.RFX);
  }

  runContractAlgoValidation() {
    this.formGroup.controls['scoringTemplate'].clearValidators();
    this.formGroup.controls['gradationSystem'].clearValidators();
    this.formGroup.controls['contractAlgo'].clearValidators();

    if (this.selectedRfx?.rfxType == RfxTypeEnum.RFP || this.selectedRfx?.rfxType == RfxTypeEnum.RFQ) {
      this.formGroup.controls['contractAlgo'].setValidators(Validators.required);
    }

    if (this.selectedRfx?.rfxType == RfxTypeEnum.PQ || this.selectedRfx?.rfxType == RfxTypeEnum.RFI) {
      this.formGroup.controls['scoringTemplate'].setValidators(Validators.required);
    }

    if (this.selectedContractAlgo == ContractAlgoEnum.QBS) {
      this.formGroup.controls['scoringTemplate'].setValidators(Validators.required);
      this.formGroup.controls['gradationSystem'].setValidators(Validators.required);
    }

    if (this.selectedContractAlgo == ContractAlgoEnum.QCBS) {
      this.formGroup.controls['scoringTemplate'].setValidators(Validators.required);
      this.formGroup.controls['gradationSystem'].setValidators(Validators.required);
      this.formGroup.controls['technicalWeightage'].setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
      this.formGroup.controls['financialWeightage'].setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
    }

    if (this.selectedContractAlgo != ContractAlgoEnum.QCBS && this.selectedContractAlgo != ContractAlgoEnum.QBS) {
      this.formGroup.controls['gradationSystem'].patchValue(false);
      this.formGroup.controls['technicalGradationDtos'].reset();
      this.technicalGradationConfig.clear();
    }

    this.formGroup.controls['contractAlgo'].updateValueAndValidity();
    this.formGroup.controls['scoringTemplate'].updateValueAndValidity();
    this.formGroup.controls['gradationSystem'].updateValueAndValidity();
    this.formGroup.controls['technicalWeightage'].updateValueAndValidity();
    this.formGroup.controls['financialWeightage'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  runScoringValidation() {
    let gradationSystem = this.formGroup.controls['gradationSystem'].value;
    let scoringTemplate = this.formGroup.controls['scoringTemplate'].value;

    if (scoringTemplate == 'YES') {
      this.formGroup.controls['maximumScore'].setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
      this.formGroup.controls['maximumScore'].updateValueAndValidity();
      
      this.formGroup.controls['qualifyingScore'].setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
      this.formGroup.controls['qualifyingScore'].updateValueAndValidity();

      if (gradationSystem == true) {
        // this.addTechnicalGradationConfig();
        this.generateRangeGrading();

        this.formGroup.controls['range'].setValidators([Validators.required, multipleOfFiveValidator()]);
        this.formGroup.controls['range'].updateValueAndValidity();
      } else {
        this.formGroup.controls['range'].clearValidators();
        this.formGroup.controls['range'].updateValueAndValidity();
        this.formGroup.controls['range'].reset();
        this.formGroup.controls['technicalGradationDtos'].reset();
        this.technicalGradationConfig.clear();
      }
    } else {
      this.formGroup.controls['technicalGradationDtos'].reset();
      this.technicalGradationConfig.clear();

      this.formGroup.controls['maximumScore'].reset();
      this.formGroup.controls['maximumScore'].clearValidators();
      this.formGroup.controls['maximumScore'].updateValueAndValidity();

      this.formGroup.controls['qualifyingScore'].reset();
      this.formGroup.controls['qualifyingScore'].clearValidators();
      this.formGroup.controls['qualifyingScore'].updateValueAndValidity();
    }
  }

  openTechnicalGradationModal(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  getContractAlgo(contactAlgo?: string) {
    let toReturn = ""
    if (contactAlgo == ContractAlgoEnum.HCS) {
      toReturn = "HCS";
    } else if (contactAlgo == ContractAlgoEnum.LCS) {
      toReturn = "LCS";
    } else if (contactAlgo == ContractAlgoEnum.QBS) {
      toReturn = "QBS";
    } else if (contactAlgo == ContractAlgoEnum.QCBS) {
      toReturn = "QCBS";
    }
    return toReturn;
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll();
    }

    this.isShowMoreGuideline = false;
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.isLoading = false;
    this.errorMsg = '';
  }

  mergeQuestionnaireTemplate() {
    let questionnaireTemplate: QuestionnaireTemplate = ApplicationUtils.clone(this.questionnaireTemplate ?? new QuestionnaireWrapperDto());

    let formValue = this.formGroup.value;
    questionnaireTemplate.templateName = formValue.templateName ?? '';
    questionnaireTemplate.orgCode = this.selectedRfx?.orgCode;
    questionnaireTemplate.auctionId = 'NA';
    questionnaireTemplate.rfxId = this.selectedRfxSubcategory?.rfxId;
    questionnaireTemplate.rfxSubcategoryId = this.selectedRfxSubcategory?.subcategoryId;
    questionnaireTemplate.resourceType = 'RFX';
    questionnaireTemplate.templateDescription = formValue.templateDescription ?? '';
    questionnaireTemplate.scoringTemplate = formValue.scoringTemplate;
    questionnaireTemplate.maximumScore = formValue.maximumScore;
    questionnaireTemplate.qualifyingScore = formValue.qualifyingScore;
    questionnaireTemplate.guideLines = formValue.guideLines ? formValue.guideLines.trim() : '';

    if (!questionnaireTemplate.envelopesList) {
      questionnaireTemplate.envelopesList = [];
    }

    if (!questionnaireTemplate.envelopesList.includes(SourcingEnvelopeType.FINANCIAL)) {
      questionnaireTemplate.envelopesList.push(SourcingEnvelopeType.FINANCIAL);
    }

    if (!questionnaireTemplate.envelopesList.includes(SourcingEnvelopeType.TECHNICAL)) {
      questionnaireTemplate.envelopesList.push(SourcingEnvelopeType.TECHNICAL);
    }

    questionnaireTemplate.contractType = ContractTypeEnum.ITEM_RATE;
    questionnaireTemplate.contractAlgo = formValue.contractAlgo;
    questionnaireTemplate.technicalWeightage = formValue.technicalWeightage;
    questionnaireTemplate.financialWeightage = formValue.financialWeightage;
    questionnaireTemplate.gradationSystem = formValue.gradationSystem;

    if (formValue.gradationSystem) {
      questionnaireTemplate.range = formValue.range;
      questionnaireTemplate.technicalGradationDtos = []

      formValue.technicalGradationDtos.forEach((item: any) => {
        let technicalGradationDto = new TechnicalGradationDto();
        technicalGradationDto.startRange = item.startRange;
        technicalGradationDto.endRange = item.endRange;
        technicalGradationDto.marks = item.marks;
        questionnaireTemplate.technicalGradationDtos?.push(technicalGradationDto);
      });
    } else {
      questionnaireTemplate.range = undefined;
      questionnaireTemplate.technicalGradationDtos = []
    }

    return questionnaireTemplate;
  }

  saveQuestionnaire() {
    this.runContractAlgoValidation();

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    console.log(this.formGroup.value);

    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let questionnaireTemplate = this.mergeQuestionnaireTemplate();

    this.questionnaireService.saveQuestionnaires(questionnaireTemplate).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving questionnaire. Try again.";
      }
    })
  }

  deleteQuestionnaireTemplate() {
    this._showErrorToastForModal$.next(false);
    this.errorMsgForModal = "";
    this.isLoadingForModal = true;

    this.questionnaireService.deleteQuestionnaire(this.questionnaireTemplate?.templateId!, EventEnum.RFX).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToastForModal$.next(true);
          this.isLoadingForModal = false;

          this.questionnaireService.updateQuestionnaireWrapperDto(undefined);

          setTimeout(() => {
            this._showSuccessToastForModal$.next(false);
            this.closeModal();
          }, 2000)

        } else {
          this.isLoadingForModal = false;
          this._showErrorToastForModal$.next(true);
          this.errorMsgForModal = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoadingForModal = false;
        this._showErrorToastForModal$.next(true);
        this.errorMsgForModal = "Error while deleting question. Try again.";
      }
    })
  }

  markQuestionnairePublish() {
    this._showErrorToastForModal$.next(false);
    this.errorMsgForModal = "";
    this.isLoadingForModal = true;

    this._showErrorToastForModal$.next(false);
    this.errorMsgForModal = "";
    this.isLoadingForModal = true;

    this.questionnaireService.markQuestionnairePublish(this.questionnaireTemplate?.templateId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToastForModal$.next(true);
          this.isLoadingForModal = false;

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          setTimeout(() => {
            this._showSuccessToastForModal$.next(false);
            this.closeModal();
          }, 2000)

        } else {
          this.isLoadingForModal = false;
          this._showErrorToastForModal$.next(true);
          this.errorMsgForModal = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoadingForModal = false;
        this._showErrorToastForModal$.next(true);
        this.errorMsgForModal = "Error while deleting question. Try again.";
      }
    })
  }


  // technicalGradation Config
  get technicalGradationConfig(): FormArray {
    return this.formGroup.get("technicalGradationDtos") as FormArray
  }

  newTechnicalGradationConfig(): FormGroup {
    return this.formBuilder.group({
      startRange: ['', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
      endRange: ['', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
      marks: ['', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
    })
  }

  updateTechnicalGradationConfig(config: TechnicalGradationDto): FormGroup {
    return this.formBuilder.group({
      startRange: [config.startRange, [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
      endRange: [config.endRange, [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
      marks: [config.marks, [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
    })
  }

  addTechnicalGradationConfig() {
    this.technicalGradationConfig.push(this.newTechnicalGradationConfig());
    this.sortTechnicalGradationConfig();
  }

  removeTechnicalGradationConfig(i: number) {
    this.technicalGradationConfig.removeAt(i);
  }

  sortTechnicalGradationConfig() {
    this.technicalGradationConfig.controls.sort((a, b) => {
      const startRangeA = a.get('startRange')?.value;
      const startRangeB = b.get('startRange')?.value;
      return Number(startRangeB) - Number(startRangeA);
    });
  }

  checkTechnicalGradationValidation() {
    // Reset error states
    this.technicalGradationConfig.controls.forEach(technicalGradation => {
      technicalGradation.get('endRange')?.setErrors(null);
    });

    // Iterate through each technical gradation configuration
    for (let i = 0; i < this.technicalGradationConfig.controls.length; i++) {
      const currentGradation = this.technicalGradationConfig.controls[i];
      const currentStartRange = currentGradation.get('startRange')?.value;
      const currentEndRange = currentGradation.get('endRange')?.value;

      // Check against other technical gradations
      for (let j = 0; j < this.technicalGradationConfig.controls.length; j++) {
        if (i !== j) { // Avoid comparing the same gradation with itself
          const otherGradation = this.technicalGradationConfig.controls[j];
          const otherStartRange = otherGradation.get('startRange')?.value;
          const otherEndRange = otherGradation.get('endRange')?.value;

          // Check for overlap
          if ((Number(currentStartRange) >= Number(otherStartRange) && Number(currentStartRange) <= Number(otherEndRange)) ||
            (Number(currentEndRange) >= Number(otherStartRange) && Number(currentEndRange) <= Number(otherEndRange))) {
            // Set error for both conflicting gradations
            currentGradation.get('endRange')?.setErrors({ rangeOverlap: true });
            otherGradation.get('endRange')?.setErrors({ rangeOverlap: true });
            currentGradation.get('endRange')?.markAsTouched();
            otherGradation.get('endRange')?.markAsTouched();
            break; // Exit inner loop if overlap is found
          }
        }
      }
    }
  }

  generateRangeGrading() {
    this.technicalGradationConfig.clear(); // Clear previous entries
    this.formGroup.controls['range'].setErrors({ multipleOfFive: false })

    const maximumScore = +this.formGroup.controls['maximumScore'].value;
    const range = +this.formGroup.controls['range'].value;

    if (maximumScore && range && maximumScore % range !== 0) {
      this.formGroup.controls['range'].setErrors({ multipleOfFive: true });
      return;
    }

    if (maximumScore && range) {
      const numberOfRanges = Math.ceil(maximumScore / range);

      let startRange = maximumScore - range + 1;
      let endRange = maximumScore;
      let marks = 100;
      const marksDecrement = 100 / numberOfRanges;

      for (let i = 0; i < numberOfRanges; i++) {
        if (startRange < 1) startRange = 1; // Ensure the range doesn't go below 1

        let technicalGradationConfig = new TechnicalGradationDto();
        technicalGradationConfig.startRange = startRange;
        technicalGradationConfig.endRange = endRange;
        technicalGradationConfig.marks = +marks.toFixed(2);

        this.technicalGradationConfig.push(this.updateTechnicalGradationConfig(technicalGradationConfig));

        endRange = startRange - 1;
        startRange = endRange - range + 1;
        marks -= marksDecrement;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
    if (this.selectedRfxSubcategorySubscription$) {
      this.selectedRfxSubcategorySubscription$.unsubscribe();
    }
  }

}