import { Component } from '@angular/core';

@Component({
  selector: 'app-onboarding-cost-center',
  templateUrl: './onboarding-cost-center.component.html',
  styleUrls: ['./onboarding-cost-center.component.sass']
})
export class OnboardingCostCenterComponent {

}
