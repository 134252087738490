import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { CompanyCostCenterBudgetUiDto } from 'src/app/shared/models/CostCenterBudgetUiDto';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { CompanyProcurementBudgetUiDto } from 'src/app/shared/models/OrgBudgetUiDto';
import { PlantCostCenterBudget } from 'src/app/shared/models/PlantCostCenterBudget';
import { PlantsBudgetWrapperUIDto } from 'src/app/shared/models/PlantsBudgetWrapperUIDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';

interface BudgetTableData {
  costCenterCode: string
  costCenterName: string
  costCenterBudget?: number
  plantsBudget: PlantBudgetTableData[]
}

interface PlantBudgetTableData {
  plantCode: string
  plantBudget?: number
  allowBudget: boolean
}

@Component({
  selector: 'app-onboarding-new-budget',
  templateUrl: './onboarding-new-budget.component.html',
  styleUrls: ['./onboarding-new-budget.component.sass']
})
export class OnboardingNewBudgetComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onCloseEvent = new EventEmitter<string | undefined>();

  formGroup: FormGroup;
  ctrlProcurementBudget = new FormControl('', Validators.pattern(Pattern.onlyNumber));
  ctrlAllotedAmount = new FormControl('', Validators.pattern(Pattern.onlyNumber));
  ctrlUnAllotedAmount = new FormControl('', Validators.pattern(Pattern.onlyNumber));

  isLoading: boolean = false;

  organizationUiDto?: OrganizationWrapperUiDto;
  selectedCompanyUiDto?: CompanyUiDto;
  plantsBudgetWrapperUIDto?: PlantsBudgetWrapperUIDto;

  plantUiDtos: PlantUiDto[] = []
  ngTableDataList: BudgetTableData[] = []

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");

  selectedCompanySubscription$?: Subscription;
  plantsBudgetWrapperSubscription$?: Subscription;

  constructor(
    private fb: FormBuilder,
    private changeDetectRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
  ) {
    this.formGroup = this.fb.group({
      formArray: this.fb.array([]),
    })
  }

  ngOnInit(): void {
    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
        this.plantUiDtos = this.selectedCompanyUiDto?.plantUiDtos ?? []
        this.plantUiDtos.sort((a, b) => a.plantCode!.localeCompare(b.plantCode!));
      } else {
        this.selectedCompanyUiDto = undefined;
      }
    })

    this.plantsBudgetWrapperSubscription$ = this.onboardingService.getPlantsBudgetWrapperUIDto$.subscribe(data => {
      if (data) {
        this.plantsBudgetWrapperUIDto = data;
        this.populatePlantsBudgetWrapper();
      } else {
        this.plantsBudgetWrapperUIDto = undefined;
      }
    })
  }

  ngAfterViewInit(): void {
    this.organizationUiDto = this.onboardingService.getOrganizationUiDto;

    this.ctrlAllotedAmount.disable();
    this.ctrlUnAllotedAmount.disable();
  }

  get fc() { return this.formGroup.controls; }

  closeDrawer() {
    this.onCloseEvent.emit(undefined);
  }

  populatePlantsBudgetWrapper() {
    let companyProcurementBudgetUIDto = this.plantsBudgetWrapperUIDto?.companyProcurementBudgetUIDto;

    this.ctrlProcurementBudget.patchValue(companyProcurementBudgetUIDto?.procurementBudgetAmt ? `${companyProcurementBudgetUIDto?.procurementBudgetAmt}` : '');
    this.ctrlAllotedAmount.patchValue(companyProcurementBudgetUIDto?.allocatedAmt ? `${companyProcurementBudgetUIDto?.allocatedAmt}` : '');
    this.ctrlUnAllotedAmount.patchValue(companyProcurementBudgetUIDto?.unAllocatedAmt ? `${companyProcurementBudgetUIDto?.unAllocatedAmt}` : '');

    this.initCostCenterBudgetTable();
  }

  initCostCenterBudgetTable() {
    this.ngTableDataList = [];

    let costCenterBudgetUiDtos = this.plantsBudgetWrapperUIDto?.companyCostCenterBudgetUiDtos ?? [];
    let plantsCostCenterBudgets = this.plantsBudgetWrapperUIDto?.plantsCostCenterBudgets ?? [];

    plantsCostCenterBudgets = plantsCostCenterBudgets.sort((a, b) => a.plantCode!.localeCompare(b.plantCode!));

    if (costCenterBudgetUiDtos.length > 0) {
      costCenterBudgetUiDtos.forEach(item => {

        let ngTableData: BudgetTableData = {
          costCenterCode: item.costCenterCode!,
          costCenterName: item.costCenterName!,
          costCenterBudget: item.budgetAmt,
          plantsBudget: []
        }

        this.plantUiDtos.forEach(plantUiDto => {
          let plantBudget = plantsCostCenterBudgets.find(plant => plant.plantCode == plantUiDto.plantCode && plant.costCenterCode == item.costCenterCode);
          let plantCostCenters = plantUiDto.plantCostCenterUiDtos ?? [];

          let plantBudgetTableData: PlantBudgetTableData = {
            allowBudget: plantCostCenters.some(pCC => pCC.costCenterCode == item.costCenterCode),
            plantCode: plantUiDto.plantCode!,
            plantBudget: plantBudget != undefined ? Number(plantBudget.budgetAmt) : undefined
          };

          ngTableData.plantsBudget.push(plantBudgetTableData)
        })

        this.ngTableDataList.push(ngTableData);
      })
    }
  }

  refreshOrgBudget() {
    let procurementBudgetAmt = this.ctrlProcurementBudget.getRawValue();

    let allotedAmount = 0;

    this.ngTableDataList.forEach(ngTableData => {
      if (procurementBudgetAmt && Number(procurementBudgetAmt) > 0) {
        let plantsBudgetAmount = ngTableData.plantsBudget.reduce((prev, curr) => prev + (Number(curr.plantBudget) || 0), 0)
        allotedAmount += plantsBudgetAmount;
      }
    })

    if (procurementBudgetAmt && Number(procurementBudgetAmt) > 0) {
      this.ctrlAllotedAmount.patchValue(allotedAmount.toString());
      this.ctrlUnAllotedAmount.patchValue((Number(procurementBudgetAmt) - allotedAmount).toString());
    }
  }

  haveOrgBudgetErrors(): boolean {
    let procurementBudgetAmt = this.ctrlProcurementBudget.getRawValue();
    let allocatedAmt = this.ctrlAllotedAmount.getRawValue();
    let unAllocatedAmt = this.ctrlUnAllotedAmount.getRawValue();

    if (this.ctrlProcurementBudget.invalid && this.ctrlAllotedAmount.invalid && this.ctrlUnAllotedAmount.invalid) {
      return true;
    }

    if (procurementBudgetAmt == '' && allocatedAmt == '' && unAllocatedAmt == '') {
      return true;
    }

    if (Number(procurementBudgetAmt) != (Number(allocatedAmt) + Number(unAllocatedAmt))) {
      return true;
    }

    return false;
  }

  haveBudgetTableErrors(): boolean {
    let allocatedAmt = this.ctrlAllotedAmount.getRawValue() || '0';
    let costCentersBudget = this.ngTableDataList.reduce((prev, curr) => prev + Number(curr.costCenterBudget || 0), 0);

    if (costCentersBudget != Number(allocatedAmt)) {
      return true;
    }

    let isPlantBudgetEqualsCostCenterBudget = this.checkPlantBudgetSumEqualsCostCenterBudgetList();
    return !isPlantBudgetEqualsCostCenterBudget;
  }

  checkPlantBudgetSumEqualsCostCenterBudgetList(): boolean {
    for (const data of this.ngTableDataList) {
      const { costCenterBudget, plantsBudget } = data;
      let sumPlantBudgets: number = 0;

      for (const plantData of plantsBudget) {
        if (plantData.allowBudget) {
          if (plantData.plantBudget === undefined) {
            return false; // If allowBudget is true but plantBudget is undefined, return false
          } else {
            sumPlantBudgets += Number(plantData.plantBudget);
          }
        }
      }

      if (Number(sumPlantBudgets) != Number(costCenterBudget)) {
        return false; // Sum of plantBudgets does not equal costCenterBudget
      }
    }

    return true; // All conditions satisfied for all items in the list
  }

  mergePlantsBudgetWrapper() {
    let plantsBudgetWrapperUIDto = new PlantsBudgetWrapperUIDto();

    if (this.plantsBudgetWrapperUIDto) {
      plantsBudgetWrapperUIDto = ApplicationUtils.clone(this.plantsBudgetWrapperUIDto) as PlantsBudgetWrapperUIDto;
    }

    if (!plantsBudgetWrapperUIDto.companyCostCenterBudgetUiDtos) {
      plantsBudgetWrapperUIDto.companyCostCenterBudgetUiDtos = [];
    }

    if (!plantsBudgetWrapperUIDto.plantsCostCenterBudgets) {
      plantsBudgetWrapperUIDto.plantsCostCenterBudgets = [];
    }

    // CompanyProcurementBudgetUiDto
    let companyProcurementBudgetUIDto = new CompanyProcurementBudgetUiDto();

    if (plantsBudgetWrapperUIDto.companyProcurementBudgetUIDto) {
      companyProcurementBudgetUIDto = ApplicationUtils.clone(plantsBudgetWrapperUIDto.companyProcurementBudgetUIDto) as CompanyProcurementBudgetUiDto;
    }

    companyProcurementBudgetUIDto.orgCode = this.organizationUiDto?.orgCode;
    companyProcurementBudgetUIDto.companyCode = this.selectedCompanyUiDto?.companyCode;
    companyProcurementBudgetUIDto.procurementBudgetAmt = Number(this.ctrlProcurementBudget.getRawValue());
    companyProcurementBudgetUIDto.allocatedAmt = Number(this.ctrlAllotedAmount.getRawValue());
    companyProcurementBudgetUIDto.unAllocatedAmt = Number(this.ctrlUnAllotedAmount.getRawValue());

    plantsBudgetWrapperUIDto.companyProcurementBudgetUIDto = companyProcurementBudgetUIDto;

    this.ngTableDataList.forEach(ngTableData => {
      // CompanyCostCenterBudgetUiDto
      let costCenterBudgetUiDto = new CompanyCostCenterBudgetUiDto();

      let existsCostCenterBudgetUiDto = plantsBudgetWrapperUIDto.companyCostCenterBudgetUiDtos!.find(
        item => item.costCenterCode?.toLowerCase() == ngTableData.costCenterCode.toLowerCase())

      if (existsCostCenterBudgetUiDto) {
        costCenterBudgetUiDto = ApplicationUtils.clone(existsCostCenterBudgetUiDto) as CompanyCostCenterBudgetUiDto;
      }

      costCenterBudgetUiDto.orgCode = this.organizationUiDto?.orgCode;
      costCenterBudgetUiDto.companyCode = this.selectedCompanyUiDto?.companyCode;
      costCenterBudgetUiDto.costCenterCode = ngTableData.costCenterCode;
      costCenterBudgetUiDto.costCenterName = ngTableData.costCenterName;
      costCenterBudgetUiDto.budgetAmt = ngTableData.costCenterBudget;

      let costCenterIndex = plantsBudgetWrapperUIDto.companyCostCenterBudgetUiDtos!.findIndex(item => item.costCenterCode == ngTableData.costCenterCode);
      Object.assign(plantsBudgetWrapperUIDto.companyCostCenterBudgetUiDtos![costCenterIndex], costCenterBudgetUiDto);

      // PlantCostCenterBudget
      this.plantUiDtos.forEach(plantUiDto => {
        let plantCostCenterBudget = new PlantCostCenterBudget();

        let existsPlantCostCenterBudget = plantsBudgetWrapperUIDto.plantsCostCenterBudgets!.find(item => item.costCenterCode == ngTableData.costCenterCode && item.plantCode == plantUiDto.plantCode);

        if (existsPlantCostCenterBudget) {
          plantCostCenterBudget = ApplicationUtils.clone(existsPlantCostCenterBudget) as PlantCostCenterBudget;
        }

        let plantBudget = ngTableData.plantsBudget.find(plantBudget => plantBudget.plantCode == plantUiDto.plantCode);

        if (plantBudget?.allowBudget) {
          plantCostCenterBudget.orgCode = plantUiDto.orgCode;
          plantCostCenterBudget.companyCode = plantUiDto.companyCode;
          plantCostCenterBudget.plantCode = plantUiDto.plantCode;
          plantCostCenterBudget.costCenterCode = ngTableData.costCenterCode;
          plantCostCenterBudget.budgetAmt = plantBudget?.plantBudget;

          let plantCostCenterIndex = plantsBudgetWrapperUIDto.plantsCostCenterBudgets!.findIndex(item => item.costCenterCode == ngTableData.costCenterCode && item.plantCode == plantBudget?.plantCode);
          if (plantCostCenterIndex != undefined && plantCostCenterIndex > -1) {
            Object.assign(plantsBudgetWrapperUIDto.plantsCostCenterBudgets![plantCostCenterIndex], plantCostCenterBudget);
          } else {
            plantsBudgetWrapperUIDto.plantsCostCenterBudgets!.push(plantCostCenterBudget);
          }
        }
      })
    })

    return plantsBudgetWrapperUIDto;
  }

  savePlantsBudget() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    if (this.haveOrgBudgetErrors() || this.haveBudgetTableErrors()) {
      return;
    }

    let plantsBudgetWrapperUIDto = this.mergePlantsBudgetWrapper();

    this.isLoading = true;

    this.onboardingService.saveCompanyPlantsBudget(plantsBudgetWrapperUIDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let data = apiResponseDto.data as PlantsBudgetWrapperUIDto;
          this.onboardingService.setPlantsBudgetWrapperUIDto(data);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
          }, 1000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving Budget");
        this._showErrorToast$.next(true);
        this.isLoading = false;
      }
    })
  }

  // Validation Errors
  haveErrorsInPlantCostCenter(budgetTableData: BudgetTableData): boolean {
    if (budgetTableData.costCenterBudget && budgetTableData.costCenterBudget > 0) {
      let allPlantsBudget = budgetTableData.plantsBudget.reduce((prev, curr) => prev + Number(curr.plantBudget || 0), 0);
      return Number(budgetTableData.costCenterBudget || 0) == allPlantsBudget;
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
  }
}
