import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationStartupDto } from 'src/app/shared/models/ApplicationStartupDto';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { TeamUiDto } from 'src/app/shared/models/user/TeamUiDto';
import { UserPlantsRoleUiDto } from 'src/app/shared/models/user/UserPlantsRoleUiDto';
import { UserTeamUiDto } from 'src/app/shared/models/user/UserTeamUiDto';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';
import { ApplicationStartupCacheService } from 'src/app/shared/services/application-startup-cache.service';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OnboardingNewTeamComponent } from 'src/app/shared/components/onboarding-new-team/onboarding-new-team.component';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-onboarding-new-user',
  templateUrl: './onboarding-new-user.component.html',
  styleUrls: ['./onboarding-new-user.component.sass']
})
export class OnboardingNewUserComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onCloseEvent = new EventEmitter<string | undefined>();

  formGroup: FormGroup;
  formConfig: { [key: string]: any } = {};
  successMessages: { [key: string]: any } = {};
  applicationStartupDto?: ApplicationStartupDto;

  isLoading: boolean = false;
  isEditUser: boolean = false;

  selectedCompanyUiDto?: CompanyUiDto;
  selectedUserUiDto?: UserUiDto;
  teamUiDtos: TeamUiDto[] = [];

  teamDialogRef?: DynamicDialogRef;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");

  selectedCompanySubscription$?: Subscription;
  selectedUserUiDtoSubscription$?: Subscription;
  selectedPlantSubscription$?: Subscription;
  drawerSubscription$?: Subscription;
  teamsSubscription$?: Subscription;

  constructor(
    private fb: FormBuilder,
    private changeDetectRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private drawerService: DrawerService,
    private dialogService: DialogService,
    private appStartupService: ApplicationStartupCacheService,
    private  themeService: ThemeService
  ) {
    this.formGroup = this.fb.group({
      firstName: [''],
      lastName: [''],
      designation: [''],
      empCode: [''],
      primaryEmailId: [''],
      plantCodes: [''],
      teamUiDtos: [[]]
    })
  }

  ngOnInit(): void {
    this.applicationStartupDto = this.appStartupService.getApplicationStartupDto;
    this.setupThemeStructure();
    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
      } else {
        this.selectedCompanyUiDto = undefined;
      }
    })

    this.selectedUserUiDtoSubscription$ = this.onboardingService.getSelectedUserUiDto$.subscribe(data => {
      if (data) {
        this.selectedUserUiDto = data;
        this.populateUserDetails()
      } else {
        this.selectedUserUiDto = undefined;
      }
    })

    this.teamsSubscription$ = this.onboardingService.getTeamUiDtos$.subscribe(data => {
      if (data) {
        this.teamUiDtos = data;
      } else {
        this.teamUiDtos = [];
      }
    })
  }

  ngAfterViewInit(): void {
    this.drawerSubscription$ = this.drawerService.drawerPageName$.subscribe(() => {
      if (this.drawerService.isDrawerOpen) {
        this.resetUserUiForm();

        if (this.drawerService.isEditForm) {
          this.isEditUser = true;
          this.populateUserDetails();
        } else {
          this.isEditUser = false;
        }
      }
    })
  }

  setupThemeStructure() {
    const themeStructure = this.themeService.themeStructure;

    if (themeStructure?.userUiDto) {
      this.successMessages = this.themeService.createSuccessMessage(themeStructure.userUiDto);
      this.formConfig = this.themeService.createFormConfig(themeStructure.userUiDto.model);
      this.themeService.applyValidations(this.formGroup, themeStructure.userUiDto.model);
    }
  }

  get fc() { return this.formGroup.controls; }

  closeDrawer() {
    this.onCloseEvent.emit(undefined);
  }

  openNewTeamDialog() {
    this.teamDialogRef = this.dialogService.open(OnboardingNewTeamComponent, {
      header: 'New Team',
      width: '45%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false
    });
  }

  populateUserDetails() {
    this.formGroup.controls['firstName'].patchValue(this.selectedUserUiDto?.firstName);
    this.formGroup.controls['lastName'].patchValue(this.selectedUserUiDto?.lastName);
    this.formGroup.controls['primaryEmailId'].patchValue(this.selectedUserUiDto?.primaryEmailId);
    this.formGroup.controls['designation'].patchValue(this.selectedUserUiDto?.designation);
    this.formGroup.controls['empCode'].patchValue(this.selectedUserUiDto?.empCode);

    if (this.selectedUserUiDto?.userTeamUiDtos && this.selectedUserUiDto?.userTeamUiDtos!.length > 0) {
      let teamIds = this.selectedUserUiDto?.userTeamUiDtos!.map(item => item.teamId!)!;
      this.formGroup.controls['teamUiDtos'].patchValue(teamIds);
    } else {
      this.formGroup.controls['teamUiDtos'].patchValue([]);
    }

    let plants = this.selectedUserUiDto?.userExtendPrivilegeUiDtos?.filter(item => item.companyCode == this.selectedCompanyUiDto?.companyCode).map(item => item.plantCode);
    this.formGroup.controls['plantCodes'].patchValue(plants);

    this.formGroup.updateValueAndValidity();
    this.changeDetectRef.detectChanges();
  }

  resetUserUiForm() {
    this.formGroup.reset();
    this._showErrorToast$.next(false);
    this._showErrorMsg$.next("");
    this.isLoading = false;
  }

  mergeUserUiDto() {
    let formValue = this.formGroup.getRawValue();

    let organizationUiDto = this.onboardingService.getOrganizationUiDto;

    let userUiDto = new UserUiDto();

    if (this.selectedUserUiDto) {
      userUiDto = ApplicationUtils.clone(this.selectedUserUiDto) as UserUiDto;
    }

    userUiDto.orgCode = organizationUiDto?.orgCode;
    userUiDto.firstName = formValue.firstName;
    userUiDto.lastName = formValue.lastName;
    userUiDto.primaryEmailId = formValue.primaryEmailId;
    userUiDto.empCode = formValue.empCode;
    userUiDto.companyCode =  this.selectedCompanyUiDto?.companyCode;
    userUiDto.plantCode =   formValue.plantCodes;
    userUiDto.designation =  formValue.designation;

    // formValue.plantCodes.forEach((plantCode: string) => {
    //   let plantUiDto = this.selectedCompanyUiDto?.plantUiDtos?.find(plant => plant.plantCode == plantCode);

    //   let userPlantsRoleUiDto = new UserPlantsRoleUiDto();
    //   userPlantsRoleUiDto.companyCode = this.selectedCompanyUiDto?.companyCode;
    //   userPlantsRoleUiDto.companyName = this.selectedCompanyUiDto?.companyName;
    //   userPlantsRoleUiDto.plantName = plantUiDto?.plantName;
    //   userPlantsRoleUiDto.plantCode = plantUiDto?.plantCode;
    //   userPlantsRoleUiDto.designation = formValue.designation;
    //   userPlantsRoleUiDto.primary = true;
    //   userPlantsRoleUiDto.userPrivilegeList = [];

    //   if (!userUiDto.userExtendPrivilegeUiDtos) {
    //     userUiDto.userExtendPrivilegeUiDtos = []
    //   }

    //   let index = userUiDto.userExtendPrivilegeUiDtos.findIndex(item => item.companyCode == userPlantsRoleUiDto.companyCode && item.plantCode == userPlantsRoleUiDto.plantCode);
    //   if (index != undefined && index > -1) {
    //     Object.assign(userUiDto.userExtendPrivilegeUiDtos[index], userPlantsRoleUiDto);
    //   } else {
    //     userUiDto.userExtendPrivilegeUiDtos.push(userPlantsRoleUiDto);
    //   }
    // })

    if (formValue.teamUiDtos) {
      formValue.teamUiDtos.forEach((teamId: string) => {
        let teamUiDto = this.teamUiDtos.find(team => team.teamId == teamId)!;
        let userTeamUiDto = UserTeamUiDto.convertFromTeamUiDto(teamUiDto);

        if (!userUiDto.userTeamUiDtos) {
          userUiDto.userTeamUiDtos = []
        }

        let index = userUiDto.userTeamUiDtos.findIndex(item => item.teamId == userTeamUiDto.teamId);
        if (index != undefined && index > -1) {
          Object.assign(userUiDto.userTeamUiDtos[index], userTeamUiDto);
        } else {
          userUiDto.userTeamUiDtos.push(userTeamUiDto);
        }
      })
    }

    return userUiDto;
  }

  saveUser() {
    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    let userUiDto = this.mergeUserUiDto()
    this.isLoading = true;

    this.onboardingService.saveUserUiDto(userUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let newUserEntityInfoDto = apiResponseDto.data as UserUiDto;
          this.onboardingService.updateUserEntityInfoDto(newUserEntityInfoDto)

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeDrawer();
          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving User");
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
    if (this.selectedUserUiDtoSubscription$) {
      this.selectedUserUiDtoSubscription$.unsubscribe();
    }
    if (this.teamsSubscription$) {
      this.teamsSubscription$.unsubscribe();
    }
    if (this.teamDialogRef) {
      this.teamDialogRef.close();
    }
  }
}
