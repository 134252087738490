import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { FileService } from 'src/app/shared/services/file.service';

@Component({
  selector: 'app-add-auction-house-banner',
  templateUrl: './add-auction-house-banner.component.html',
  styleUrls: ['./add-auction-house-banner.component.sass']
})
export class AddAuctionHouseBannerComponent implements OnInit, OnDestroy {

  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];

  currentFileInfoDto?: FileInfoDto | null
  currentFile?: File | null;
  imageUrl?: string | ArrayBuffer;
  errorMsg: string | undefined;
  fileUploadError: boolean = false;

  isLoading?: boolean = false;
  auctionHouse?: AuctionHouseDto

  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _isImageActive$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _fileDisplayName$ = new BehaviorSubject<string | null>("");
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);

  @ViewChild('bannerImg') bannerImg?: HTMLInputElement;

  currentAuctionHouseSubscription$?: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private fileService: FileService,
    private adminDashboardService: AdminDashboardService,
  ) { }

  ngOnInit(): void {
    this.currentAuctionHouseSubscription$ = this.adminDashboardService.getCurrentAuctionHouse$.subscribe((data) => {
      if (data) {
        this.auctionHouse = data;

        this.currentFileInfoDto = this.auctionHouse.bannerImage;

        if (this.currentFileInfoDto && this.currentFileInfoDto.displayName) {
          this._fileDisplayName$.next(this.currentFileInfoDto.displayName);
        } else {
          this._fileDisplayName$.next("");
        }
      }
    })
  }

  openViewImageModal(imageUrl:any) {
    let modalRef = this.ngbModal.open(ImagePreviewComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.imageUrl=imageUrl
  }

  formValidationFailed(){
    this._validationErrorPresent$.next(false);
    if(!this._fileDisplayName$.value){
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  closeModal() {
    this.ngbModal.dismissAll();
    this.removeProfileImage();
    this.errorMsg = "";

    this._fileDisplayName$.next(null);
    this._isSaveButtonDisable$.next(false);
  }

  imageActive() {
    this._isImageActive$.next(!this._isImageActive$.value);
  }

  chooseFile(event: any) {
    this._showErrorToast$.next(false);
    this.currentFileInfoDto = null;
    this.errorMsg = "";
    this.currentFile = event.target.files[0];

    if (!this.imageTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this.currentFile = null;
      this._showErrorToast$.next(true);
      return;
    }

    this._fileDisplayName$.next(this.currentFile?.name as string);

    let reader = new FileReader();
    reader.readAsDataURL(this.currentFile!);
    reader.onload = () => {
      this.imageUrl = reader.result?.toString();
    }
  }

  removeProfileImage() {
    this.currentFileInfoDto = null;
    this.imageUrl = null!;
    this.currentFile = null;
    this._fileDisplayName$.next("");
    this._isImageActive$.next(false)
    // this.profilePic?.setAttribute('value', '');
  }

  handleValidSubmit() {
    if (this.formValidationFailed()) {
      return;
    }

    if (!this.currentFile) {
      this.closeModal()
    }

    this.isLoading = true;
    this._isSaveButtonDisable$.next(true);

    this.uploadFile(this.currentFile!);
  }

  uploadFile(currentFile: File) {
    this.fileUploadError = false;
    this.errorMsg = "";

    let metaData = {
      'mimeType': currentFile.type,
      'version': 0,
      'publicApi': true,
      'dataType': 'Square'
    };

    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;
          this.currentFileInfoDto = new FileInfoDto();
          this.currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          this.currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.fileSize = currentFile.size.toString();
          this.currentFileInfoDto.fileType = currentFile.type;
          this.currentFileInfoDto.uploadDate = new Date().toDateString();

          this!.auctionHouse!.bannerImage = this.currentFileInfoDto!;

          this.save();
        }
      } else {
        this.fileUploadError = true;
        this._isSaveButtonDisable$.next(false);
      }
    })
  }

  save() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.adminDashboardService.updateAuctionHouse(this.auctionHouse!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this._isSaveButtonDisable$.next(false);
            this.isLoading = false;
            this.closeModal();
          }, 1000)
        } else {
          this._showErrorToast$.next(true);
          this.errorMsg = apiResponseDto.message;
          this._isSaveButtonDisable$.next(false);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this._showErrorToast$.next(true);
        this.errorMsg = "Error while saving landing page hero image. Try again.";
        this._isSaveButtonDisable$.next(false);
        this.isLoading = false;
      }
    })
  }

  ngOnDestroy(): void {
    if (this.currentAuctionHouseSubscription$) {
      this.currentAuctionHouseSubscription$.unsubscribe();
    }
  }

}
