<div class="tr-margin">
  <span (click)="closeModal()">close</span>
  <div class="row">
    <div class="tr-modal-left">
      <img src="/assets/icons/onboarding-begun-first.svg" class="tr-onboading-begun-icon c-pointer">
      <div class="tr-onb-begun-tet-margin-left tr-onb-begun-text-left">
        Hello <span class="tr-onb-begun-text-span-left">{{userEntityInfoDto?.firstName}}</span>, <br>
        Ready to start an exciting journey with ProcurExt?
      </div>
      <div class="tr-onb-begun-second-text-left tr-onb-begun-second-tet-margin-left ">
        To begin, we'll need a few details about your organization, so we can better craft your experience throughout
        ProcurExt
      </div>
    </div>

    <div class="tr-modal-right">
      <div class="tr-onboarding-first-right-text">
        Lets start create your first company
      </div>
      <div class=" tr-onboarding-second-right-text">
        Complete all 3 steps to get started
      </div>
      <div class="card w-100 h-100 tr-onb-begun-tet-margin-left">
        <div class="text-center">
          <div class="spinner-border text-primary" role="status" *ngIf="!isViewLoaded">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <p-timeline [value]="events" *ngIf="isViewLoaded">
          <ng-template pTemplate="marker" let-event>
            <div class="flex h-8 w-8 flex-col items-start gap-2.5 shrink-0 rounded-full bg-gray-50" *ngIf="event.status == 0"></div>
            <span *ngIf="event.status == 25">
              <img src="/assets/icons/tr-timeline-half-progress.svg" class="tr-lg-icon">
            </span>
            <span *ngIf="event.status == 50">
              <img src="/assets/icons/tr-timeline-half-progress.svg" class="tr-lg-icon">
            </span>
            <span *ngIf="event.status == 75">
              <img src="/assets/icons/tr-timeline-half-progress.svg" class="tr-lg-icon">
            </span>
            <span class="custom-marker shadow-2 tr-onboarding-timeline tr-background-green" *ngIf="event.status == 100">
              <img src="/assets/icons/onboarding-timeline-check.svg" class="svg-icon-class">
            </span>
          </ng-template>
          <ng-template pTemplate="content" let-event>
            {{ event.title }}
            <p style="font-size: x-small;">{{event.subtitle}}</p>
          </ng-template>
        </p-timeline>
      </div>
      <div class="onboarding-continue-btn-margin">
        <button class="onboarding-continue-btn" (click)="toggleDrawer()">
          Continue
          <img src="/assets/icons/continue-arrow.svg" class=" svg-icon-class">
        </button>
      </div>
    </div>
  </div>
</div>