import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { Currency } from 'src/app/shared/models/Currency';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { TenderManagementActions } from 'src/app/shared/state-management/tender/tender.actions';
import { selectTenderOpportunityWrapperDto, selectTenderWrapperUiDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { TenderFeeDetails } from 'src/app/shared/models/user/TenderFeeDetails';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { Pattern } from 'src/app/shared/util/Patterns';
import { TenderDocumentInfoDto } from 'src/app/shared/models/TenderDocumentInfoDto';
import { TenderManagementService } from 'src/app/shared/services/tender-management.service';
import { EntityActionLabelEnum } from 'src/app/shared/enums/EntityActionLabelEnum';
import { EntityActionTypeEnum } from 'src/app/shared/enums/EntityActionTypeEnum';
import { MessageService } from 'primeng/api';

enum OpportunityPages {
  BASIC = 'BASIC',
  CONFIGURATION = 'CONFIGURATION',
  FEE = 'FEE',
  CRITICAL_DATES = 'CRITICAL_DATES'
}

@Component({
  selector: 'app-new-opportunity-drawer',
  templateUrl: './new-opportunity-drawer.component.html',
  styleUrls: ['./new-opportunity-drawer.component.sass']
})
export class NewOpportunityDrawerComponent implements OnInit, AfterViewInit, OnDestroy {
  formGroup: FormGroup;
  formGroupConfiguration: FormGroup;
  formGroupFeeDetails: FormGroup;
  formGroupCriticalDates: FormGroup;

  docTitle: FormControl = new FormControl('', [Validators.required, Validators.maxLength(250)]);
  docDescription: FormControl = new FormControl('', [Validators.required, Validators.maxLength(1000)]);
  docFile: FormControl = new FormControl('', Validators.required);

  feeDocuments: Array<TenderDocumentInfoDto> = []

  currencies: Currency[] = [];
  currentView: OpportunityPages = OpportunityPages.BASIC;
  opportunityPages: typeof OpportunityPages = OpportunityPages;

  currentFileInfoDto?: TenderDocumentInfoDto | null;
  currentFile?: File | null;
  tenderWrapperUiDto?: TenderWrapperUiDto;
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;

  newSubResourceId: string = UUID.UUID().toString();
  applicationConstants: typeof ApplicationConstants = ApplicationConstants;

  documentTypes = ['application/pdf'];
  errorMsg: string | undefined;
  isLoading: boolean = false;
  isLoadingModal: boolean = false;
  isShowFeeDocumentDialog: boolean = false;
  titleLength: number = 0
  descriptionLength: number = 0

  _selectedTenderWrapperUiDto$: Observable<TenderWrapperUiDto | null>;
  _selectedTenderOpportunityWrapperDto$: Observable<TenderOpportunityWrapperDto | null>;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToastModal$ = new BehaviorSubject<boolean>(false);
  _showErrorToastModal$ = new BehaviorSubject<boolean>(false);

  tenderWrapperUiDtoSubscription$?: Subscription;
  tenderOpportunityWrapperDtoSubscription$?: Subscription;
  publishingMinimumDate = new Date();
  documentDownloadMinimumDate = new Date();
  bidSubmissionStartMinimumDate = new Date();
  bidSubmissionEndMinimumDate = new Date();
  technicalOpeningMinimumDate = new Date();
  financialOpeningMinimumDate = new Date();
  financialOpeningDateMinimumDate = new Date();

  stateOptions: any[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ]

  offlinePaymentModes =  ['Cheque', 'Demand Draft', 'Bank Guarantee', 'Small Saving Instruments']

  @ViewChild('documentDoc') documentDoc?: ElementRef<HTMLInputElement>;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private tenderManagementService: TenderManagementService,
    private drawerService: DrawerService,
    private currencyService: CurrencyService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService
  ) {
    this.formGroup = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(250)]],
      description: ['', [Validators.required, Validators.maxLength(1000)]],
      tenderType: ['', Validators.required],
      tenderCategory: ['', Validators.required],
      accountHead: ['', Validators.required],
      category: ['', Validators.required],
      subcategory: ['', Validators.maxLength(250)],
      formOfContract: ['', Validators.required],
      tenderOpeningType: ['', Validators.required],
      tenderContractType: ['', Validators.required],
    });

    this.formGroupConfiguration = this.fb.group({
      allowReBidSubmission: [true, Validators.required],
      allowWithdrawalOfBids: [true, Validators.required],
      allowMultipleCurrency: [false, Validators.required],
      currencies: [null],
      noOfEnvelopes: ['', Validators.required],
      tenderValue: ['', Validators.required],
      showTenderValueToBidder: [true, Validators.required],
      bidValidityDays: ['', Validators.required],
      leadTimeDays: ['', Validators.required],
      deliveryLocationDetails: this.fb.group({
        addressType: ['', Validators.required],
        searchAddress: ['', Validators.required],
        addressLine1: ['', Validators.required],
        addressLine2: [''],
        city: ['', Validators.required],
        state: ['', Validators.required],
        country: ['', Validators.required],
        zipCode: ['', Validators.required],
        latitude: [''],
        longitude: [''],
        countryShortName: [''],
      }),
      allowPreBidMeeting: [false, Validators.required],
      preBidMeetingLocation: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: [''],
        latitude: [''],
        longitude: [''],
        countryShortName: [''],
      }),
      bidOpeningLocation: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: [''],
        latitude: [''],
        longitude: [''],
        countryShortName: [''],
      }),
      invitingOfficerLocation: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: [''],
        latitude: [''],
        longitude: [''],
        countryShortName: [''],
      }),
    });

    this.formGroupFeeDetails = this.fb.group({
      tenderFeePaymentMode: ['OFFLINE'],
      tenderFee: ['', Validators.pattern(Pattern.numberGreaterZero)],
      processingFee: ['', Validators.pattern(Pattern.numberGreaterZero)],
      surcharges: ['', Validators.pattern(Pattern.numberGreaterZero)],
      otherCharges: ['', Validators.pattern(Pattern.numberGreaterZero)],
      tenderFeePayableTo: [''],
      tenderFeePayableAt: [''],
      emdAmount: ['', [Validators.required, Validators.pattern(Pattern.numberGreaterZero)]],
      allowEmdExemption: [false, Validators.required],
      emdFeePayableTo: ['', Validators.required],
      emdFeePayableAt: ['', Validators.required],
      offlinePaymentOptions: [''],
    });

    this.formGroupCriticalDates = this.fb.group({
      publishingDate: ['', Validators.required],
      documentDownloadDate: ['', Validators.required],
      allowSeekClarification: [false],
      seekClarificationStartDate: [''],
      seekClarificationEndDate: [''],
      bidSubmissionStartDate: ['', Validators.required],
      bidSubmissionEndDate: ['', Validators.required],
      technicalOpeningDate: ['', Validators.required],
      financialOpeningDate: ['', Validators.required]
    }, {
      validators: [this.validateCriticalDates]
    });

    this._selectedTenderWrapperUiDto$ = this.store.pipe(select(selectTenderWrapperUiDto));
    this._selectedTenderOpportunityWrapperDto$ = this.store.pipe(select(selectTenderOpportunityWrapperDto));
  }

  ngOnInit() {
    this.resetFormGroup()

    this.currencies = this.currencyService.getCurrencies;

    this.tenderWrapperUiDtoSubscription$ = this._selectedTenderWrapperUiDto$.subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);

        let tenderOpportunityWrapperDtos = this.tenderWrapperUiDto?.tenderOpportunityWrapperDtos || [];
        if (tenderOpportunityWrapperDtos.length > 0) {
          if (this.tenderOpportunityWrapperDto) {
            let index = tenderOpportunityWrapperDtos.findIndex(item => item.tenderOpportunityId == this.tenderOpportunityWrapperDto?.tenderOpportunityId);
            let tenderOpportunityWrapperDto = tenderOpportunityWrapperDtos[index];
            this.store.dispatch(TenderManagementActions.setCurrentTenderOpportunityWrapperDto({ tenderOpportunityWrapperDto }));
          } else {
            if (this.drawerService.isEditForm) {
              let tenderOpportunityWrapperDto = tenderOpportunityWrapperDtos[0];
              this.store.dispatch(TenderManagementActions.setCurrentTenderOpportunityWrapperDto({ tenderOpportunityWrapperDto }));
            }
          }
        }
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.tenderOpportunityWrapperDtoSubscription$ = this._selectedTenderOpportunityWrapperDto$.subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
      } else {
        this.tenderOpportunityWrapperDto = undefined;
      }
    })

    this.formGroup.controls['title']?.valueChanges.subscribe(value => {
      this.titleLength = value ? value.length : 0;
      this.changeDetectorRef.detectChanges();
    });

    this.formGroup.controls['description']?.valueChanges.subscribe(value => {
      this.descriptionLength = value ? value.length : 0;
      this.changeDetectorRef.detectChanges();
    });
  }

  // Custom validator for date dependencies
  validateCriticalDates(group: AbstractControl): ValidationErrors | null {
    const publishingDate = new Date(group.get('publishingDate')?.value);
    const documentDownloadDate = new Date(group.get('documentDownloadDate')?.value);
    const bidSubmissionStartDate = new Date(group.get('bidSubmissionStartDate')?.value);
    const bidSubmissionEndDate = new Date(group.get('bidSubmissionEndDate')?.value);
    const technicalOpeningDate = new Date(group.get('technicalOpeningDate')?.value);
    const financialOpeningDate = new Date(group.get('financialOpeningDate')?.value);
    const currentDate = new Date();

    // Publishing Date: after current date and before Bid Submission Start Date
    if (publishingDate <= currentDate) {
      return { publishingDateInvalid: true };
    }

    // Document Download Date: on or after Publishing Date
    if (documentDownloadDate <= publishingDate) {
      return { documentDownloadInvalid: true };
    }

    // Bid Submission Start Date: after Publishing Date
    if (bidSubmissionStartDate <= publishingDate) {
      return { bidSubmissionStartInvalid: true };
    }

    if (publishingDate >= bidSubmissionStartDate) {
      return { publishingDateBeforeBidStart: true };
    }

    // Bid Submission End Date: after Publishing Date and after the current date
    if (bidSubmissionEndDate <= bidSubmissionStartDate || bidSubmissionEndDate <= currentDate) {
      return { bidSubmissionEndInvalid: true };
    }

    // Technical Opening Date: after Bid Submission End Date
    if (technicalOpeningDate <= bidSubmissionEndDate) {
      return { technicalOpeningInvalid: true };
    }

    // Financial Opening Date: after Technical Opening Date
    if (financialOpeningDate <= technicalOpeningDate) {
      return { financialOpeningInvalid: true };
    }

    return null; // All validations pass
  }

  ngAfterViewInit(): void {
    this.populateOpportunityDetails();

    this.formGroupConfiguration.controls['allowMultipleCurrency'].valueChanges.subscribe(val => {
      if (val) {
        this.formGroupConfiguration.controls['currencies'].setValidators(Validators.required);
      } else {
        this.formGroupConfiguration.controls['currencies'].clearValidators();
      }
      this.formGroupConfiguration.controls['currencies'].updateValueAndValidity();
    })

    this.formGroupFeeDetails.controls['tenderFee'].valueChanges.subscribe(val => {
      if (val && val.length > 0) {
        this.formGroupFeeDetails.controls['tenderFeePayableTo'].setValidators(Validators.required);
        this.formGroupFeeDetails.controls['tenderFeePayableAt'].setValidators(Validators.required);
      } else {
        this.formGroupFeeDetails.controls['tenderFeePayableTo'].clearValidators();
        this.formGroupFeeDetails.controls['tenderFeePayableAt'].clearValidators();
      }


      this.formGroupFeeDetails.controls['tenderFeePayableTo'].updateValueAndValidity();
      this.formGroupFeeDetails.controls['tenderFeePayableAt'].updateValueAndValidity();
    })

    this.formGroupCriticalDates.controls['allowSeekClarification'].valueChanges.subscribe(val => {
      if (val) {
        this.formGroupCriticalDates.controls['seekClarificationStartDate'].setValidators(Validators.required);
        this.formGroupCriticalDates.controls['seekClarificationEndDate'].setValidators(Validators.required);
      } else {
        this.formGroupCriticalDates.controls['seekClarificationStartDate'].clearValidators();
        this.formGroupCriticalDates.controls['seekClarificationEndDate'].clearValidators();
      }
      this.formGroupCriticalDates.controls['seekClarificationStartDate'].updateValueAndValidity();
      this.formGroupCriticalDates.controls['seekClarificationEndDate'].updateValueAndValidity();
    })

    this.formGroupConfiguration.get('allowPreBidMeeting')?.valueChanges.subscribe((allowPreBidMeeting: boolean) => {
      this.checkPreBidMeetingValidation(allowPreBidMeeting);
    });

    this.formGroupFeeDetails.controls['tenderFeePaymentMode'].valueChanges.subscribe(val => {
      if (val && val == 'OFFLINE') {
        this.formGroupFeeDetails.controls['offlinePaymentOptions'].setValidators(Validators.required);
      } else {
        this.formGroupFeeDetails.controls['offlinePaymentOptions'].clearValidators();
        this.formGroupFeeDetails.controls['offlinePaymentOptions'].reset();
      }
      this.formGroupFeeDetails.controls['offlinePaymentOptions'].updateValueAndValidity();
    })

    if (this.drawerService.drawerPageTab) {
      this.openCurrentPage(this.drawerService.drawerPageTab);
    }
  }

  get fc() { return this.formGroup.controls; }
  get fcConfig() { return this.formGroupConfiguration.controls; }
  get fcFee() { return this.formGroupFeeDetails.controls; }
  get fcDates() { return this.formGroupCriticalDates.controls; }

  populateOpportunityDetails() {
    if (this.tenderOpportunityWrapperDto) {
      this.formGroup.patchValue(this.tenderOpportunityWrapperDto);
      this.formGroupConfiguration.patchValue(this.tenderOpportunityWrapperDto);

      this.checkPreBidMeetingValidation(this.tenderOpportunityWrapperDto.allowPreBidMeeting || false);

      if (this.tenderOpportunityWrapperDto.tenderFeeDetails) {
        this.formGroupFeeDetails.patchValue(this.tenderOpportunityWrapperDto.tenderFeeDetails);
        this.feeDocuments = this.tenderOpportunityWrapperDto.tenderFeeDetails.feeDocuments || []
      }

      this.formGroupCriticalDates.patchValue({
        publishingDate: this.tenderOpportunityWrapperDto.publishingDate ? new Date(this.tenderOpportunityWrapperDto.publishingDate) : '',
        documentDownloadDate: this.tenderOpportunityWrapperDto.documentDownloadDate ? new Date(this.tenderOpportunityWrapperDto.documentDownloadDate) : '',
        allowSeekClarification: this.tenderOpportunityWrapperDto.allowSeekClarification,
        seekClarificationStartDate: this.tenderOpportunityWrapperDto.seekClarificationStartDate ? new Date(this.tenderOpportunityWrapperDto.seekClarificationStartDate) : '',
        seekClarificationEndDate: this.tenderOpportunityWrapperDto.seekClarificationEndDate ? new Date(this.tenderOpportunityWrapperDto.seekClarificationEndDate) : '',
        bidSubmissionStartDate: this.tenderOpportunityWrapperDto.bidSubmissionStartDate ? new Date(this.tenderOpportunityWrapperDto.bidSubmissionStartDate) : '',
        bidSubmissionEndDate: this.tenderOpportunityWrapperDto.bidSubmissionEndDate ? new Date(this.tenderOpportunityWrapperDto.bidSubmissionEndDate) : '',
        technicalOpeningDate: this.tenderOpportunityWrapperDto.technicalOpeningDate ? new Date(this.tenderOpportunityWrapperDto.technicalOpeningDate) : '',
        financialOpeningDate: this.tenderOpportunityWrapperDto.financialOpeningDate ? new Date(this.tenderOpportunityWrapperDto.financialOpeningDate) : ''
      });

      if (this.tenderOpportunityWrapperDto.allowMultipleCurrency) {
        this.formGroupConfiguration.controls['currencies'].setValidators(Validators.required);
      } else {
        this.formGroupConfiguration.controls['currencies'].clearValidators();
      }

      if (this.tenderOpportunityWrapperDto.tenderFeeDetails && Number(this.tenderOpportunityWrapperDto.tenderFeeDetails?.tenderFee || 0) > 0) {
        this.formGroupFeeDetails.controls['tenderFeePayableTo'].setValidators(Validators.required);
        this.formGroupFeeDetails.controls['tenderFeePayableAt'].setValidators(Validators.required);
      } else {
        this.formGroupFeeDetails.controls['tenderFeePayableTo'].clearValidators();
        this.formGroupFeeDetails.controls['tenderFeePayableAt'].clearValidators();
      }

      if (this.tenderOpportunityWrapperDto.allowSeekClarification) {
        this.formGroupCriticalDates.controls['seekClarificationStartDate'].setValidators(Validators.required);
        this.formGroupCriticalDates.controls['seekClarificationEndDate'].setValidators(Validators.required);
      } else {
        this.formGroupCriticalDates.controls['seekClarificationStartDate'].clearValidators();
        this.formGroupCriticalDates.controls['seekClarificationEndDate'].clearValidators();
      }
    }
  }

  resetFormGroup() {
    this.formGroup.reset();
    this.formGroupConfiguration.reset({
      allowReBidSubmission: true,
      allowWithdrawalOfBids: true,
      allowMultipleCurrency: false,
      showTenderValueToBidder: true,
      allowPreBidMeeting: false
    });
    this.formGroupFeeDetails.reset({
      tenderFeePaymentMode: 'OFFLINE',
      allowEmdExemption: false
    });
    this.formGroupCriticalDates.reset({ allowSeekClarification: false });
  }

  checkPreBidMeetingValidation(allowPreBidMeeting: boolean) {
    const preBidMeetingLocationGroup = this.formGroupConfiguration.get('preBidMeetingLocation') as FormGroup;
    const bidOpeningLocationGroup = this.formGroupConfiguration.get('bidOpeningLocation') as FormGroup;
    const invitingOfficerLocationGroup = this.formGroupConfiguration.get('invitingOfficerLocation') as FormGroup;

    if (allowPreBidMeeting) {
      // If true, set required validators
      preBidMeetingLocationGroup.get('addressType')?.setValidators([Validators.required]);
      preBidMeetingLocationGroup.get('searchAddress')?.setValidators([Validators.required]);
      preBidMeetingLocationGroup.get('addressLine1')?.setValidators([Validators.required]);
      preBidMeetingLocationGroup.get('city')?.setValidators([Validators.required]);
      preBidMeetingLocationGroup.get('state')?.setValidators([Validators.required]);
      preBidMeetingLocationGroup.get('country')?.setValidators([Validators.required]);
      preBidMeetingLocationGroup.get('zipCode')?.setValidators([Validators.required]);

      bidOpeningLocationGroup.get('addressType')?.setValidators([Validators.required]);
      bidOpeningLocationGroup.get('searchAddress')?.setValidators([Validators.required]);
      bidOpeningLocationGroup.get('addressLine1')?.setValidators([Validators.required]);
      bidOpeningLocationGroup.get('city')?.setValidators([Validators.required]);
      bidOpeningLocationGroup.get('state')?.setValidators([Validators.required]);
      bidOpeningLocationGroup.get('country')?.setValidators([Validators.required]);
      bidOpeningLocationGroup.get('zipCode')?.setValidators([Validators.required]);

      invitingOfficerLocationGroup.get('addressType')?.setValidators([Validators.required]);
      invitingOfficerLocationGroup.get('searchAddress')?.setValidators([Validators.required]);
      invitingOfficerLocationGroup.get('addressLine1')?.setValidators([Validators.required]);
      invitingOfficerLocationGroup.get('city')?.setValidators([Validators.required]);
      invitingOfficerLocationGroup.get('state')?.setValidators([Validators.required]);
      invitingOfficerLocationGroup.get('country')?.setValidators([Validators.required]);
      invitingOfficerLocationGroup.get('zipCode')?.setValidators([Validators.required]);
    } else {
      // If false, clear validators
      preBidMeetingLocationGroup.get('addressType')?.clearValidators();
      preBidMeetingLocationGroup.get('searchAddress')?.clearValidators();
      preBidMeetingLocationGroup.get('addressLine1')?.clearValidators();
      preBidMeetingLocationGroup.get('city')?.clearValidators();
      preBidMeetingLocationGroup.get('state')?.clearValidators();
      preBidMeetingLocationGroup.get('country')?.clearValidators();
      preBidMeetingLocationGroup.get('zipCode')?.clearValidators();
      preBidMeetingLocationGroup.reset();

      bidOpeningLocationGroup.get('addressType')?.clearValidators();
      bidOpeningLocationGroup.get('searchAddress')?.clearValidators();
      bidOpeningLocationGroup.get('addressLine1')?.clearValidators();
      bidOpeningLocationGroup.get('city')?.clearValidators();
      bidOpeningLocationGroup.get('state')?.clearValidators();
      bidOpeningLocationGroup.get('country')?.clearValidators();
      bidOpeningLocationGroup.get('zipCode')?.clearValidators();
      bidOpeningLocationGroup.reset();

      invitingOfficerLocationGroup.get('addressType')?.clearValidators();
      invitingOfficerLocationGroup.get('searchAddress')?.clearValidators();
      invitingOfficerLocationGroup.get('addressLine1')?.clearValidators();
      invitingOfficerLocationGroup.get('city')?.clearValidators();
      invitingOfficerLocationGroup.get('state')?.clearValidators();
      invitingOfficerLocationGroup.get('country')?.clearValidators();
      invitingOfficerLocationGroup.get('zipCode')?.clearValidators();
      invitingOfficerLocationGroup.reset();

      // Update value and validity after adding/removing validators
      preBidMeetingLocationGroup.clearValidators();
      bidOpeningLocationGroup.clearValidators();
      invitingOfficerLocationGroup.clearValidators();
    }

    preBidMeetingLocationGroup.updateValueAndValidity();
    bidOpeningLocationGroup.updateValueAndValidity();
    invitingOfficerLocationGroup.updateValueAndValidity(); 

    this.formGroupConfiguration.updateValueAndValidity();
  }

  openAddDocumentDialog() {
    this.docTitle.reset()
    this.docDescription.reset()
    this.docFile.reset()

    this.isShowFeeDocumentDialog = true
  }

  chooseFile(event: any) {
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {

      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Extension not supported' });
      this.currentFile = null;
      this.docFile.reset();
      this.documentDoc!.nativeElement.value = '';
      return;
    }

    this.docFile.patchValue(this.getFileName(this.currentFile?.name!) as string);
  }

  getFileName(fileName: string) {
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0, fileName.lastIndexOf('.'));
    let finalFileName = ApplicationUtils.removeSpecialCharacters(name!) as string
    return finalFileName + "" + extensionName;
  }

  removeDoc() {
    this.currentFileInfoDto = null;
    this.currentFile = null;
    this.docFile.reset();
    this.documentDoc!.nativeElement.value = '';
  }

  deleteFeeDocument(index: number) {
    this.feeDocuments.splice(index, 1);
  }

  previewRfxDoc(type: string) {
    if (type == 'LOCAL') {
      var fileURL = URL.createObjectURL(this.currentFile!);
      window.open(fileURL, '_blank');

    } else if (type == 'SERVER') {
      this.isLoadingModal = true;

      this.tenderManagementService.downloadTenderDocument(this.currentFileInfoDto?.fileId!).subscribe({
        next: (response) => {
          if (response) {
            this.isLoadingModal = false;
            let file = new Blob([response], { type: this.currentFileInfoDto?.fileType });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
          }
        },
        error: (err) => {
          console.error(err);
          console.log("Error while previewing document");
        }
      });
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult, formGroupName: string) {
    let city = ApplicationUtils.getAddressByType(address, 'locality');
    if (!city) {
      city = ApplicationUtils.getAddressByType(address, 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let country = ApplicationUtils.getAddressByType(address, 'country');

    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 = ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 = ApplicationUtils.getAddressByType(address, 'route');

    let latitude = address.geometry?.location?.lat();
    let longitude = address.geometry?.location?.lng();
    let countryShortName = ApplicationUtils.getAddressShortNameByType(address, 'country');
    let addressLine1 = addressPart1 + " " + addressPart2;

    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address?.includes(',')) {
        addressLine1 = address.formatted_address.split(',')[0];
      } else {
        addressLine1 = address.formatted_address ?? '';
      }
    }

    this.formGroupConfiguration.controls[formGroupName].get('addressLine1')?.patchValue(addressLine1);
    this.formGroupConfiguration.controls[formGroupName].get('searchAddress')?.patchValue(address.formatted_address);
    this.formGroupConfiguration.controls[formGroupName].get('city')?.patchValue(city);
    this.formGroupConfiguration.controls[formGroupName].get('state')?.patchValue(state);
    this.formGroupConfiguration.controls[formGroupName].get('country')?.patchValue(country);
    this.formGroupConfiguration.controls[formGroupName].get('zipCode')?.patchValue(zip);
    this.formGroupConfiguration.controls[formGroupName].get('latitude')?.patchValue(latitude);
    this.formGroupConfiguration.controls[formGroupName].get('longitude')?.patchValue(longitude);
    this.formGroupConfiguration.controls[formGroupName].get('countryShortName')?.patchValue(countryShortName);
    this.formGroupConfiguration.updateValueAndValidity();
  }

  uploadFile() {
    this.isLoadingModal = false;


    if (this.docTitle.invalid || this.docDescription.invalid || this.docFile.invalid) {
      this.docTitle.markAllAsTouched();
      this.docDescription.markAllAsTouched();
      this.docFile.markAllAsTouched();
      return;
    }

    let metaData = {
      mimeType: this.currentFile?.type,
      version: 0,
      publicApi: false,
      dataType: 'Square',

      resourceId: this.newSubResourceId,
      subResourceId: undefined,
      resourceType: 'TENDER',
      digitallySigned: undefined,
      validateDownloadTime: undefined,

      title: this.docTitle.value,
      description: this.docDescription.value,
    };

    let formData = new FormData();
    formData.append("file", this.currentFile!);
    formData.append('metaData', JSON.stringify(metaData));

    this.isLoadingModal = true;

    this.tenderManagementService.uploadTenderDocument(formData).subscribe(apiResponseDto => {
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let tenderDocumentInfoDto = apiResponseDto.data as TenderDocumentInfoDto;

          this.isLoadingModal = false;
          this.currentFile = null;

          if (!this.feeDocuments) {
            this.feeDocuments = [];
          }

          this.feeDocuments.push(tenderDocumentInfoDto);

          this.isShowFeeDocumentDialog = false;
        } else {
          this.isLoadingModal = false;


          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while saving Document. Try again.' });
        }
      } else {
        this.isLoadingModal = false;


        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while uploading document. Try again.' });
      }
    })
  }

  mergeTenderWrapperUiDto() {
    let tenderWrapperUiDto = ApplicationUtils.clone(this.tenderWrapperUiDto) as TenderWrapperUiDto;
    let tenderOpportunityWrapperDto = new TenderOpportunityWrapperDto();
    tenderWrapperUiDto.entityActionDtos = [];

    if (this.tenderOpportunityWrapperDto) {
      tenderOpportunityWrapperDto = ApplicationUtils.clone(this.tenderOpportunityWrapperDto) as TenderOpportunityWrapperDto;

      let actionDto = ApplicationUtils.addActionEvent(tenderOpportunityWrapperDto.tenderOpportunityId!, EntityActionLabelEnum.OPPORTUNITY, EntityActionTypeEnum.UPDATE);
      tenderWrapperUiDto.entityActionDtos.push(actionDto)
    } else {
      tenderOpportunityWrapperDto.tenderOpportunityId = this.newSubResourceId;
      tenderOpportunityWrapperDto.sequenceNo = this.getLotSequence();
      let actionDto = ApplicationUtils.addActionEvent(tenderOpportunityWrapperDto.tenderOpportunityId!, EntityActionLabelEnum.OPPORTUNITY, EntityActionTypeEnum.ADD);
      tenderWrapperUiDto.entityActionDtos.push(actionDto)
    }

    if (this.currentView == OpportunityPages.BASIC) {
      let formValue = this.formGroup.getRawValue();
      tenderOpportunityWrapperDto.title = formValue.title;
      tenderOpportunityWrapperDto.description = formValue.description;
      tenderOpportunityWrapperDto.tenderType = formValue.tenderType;
      tenderOpportunityWrapperDto.tenderCategory = formValue.tenderCategory;
      tenderOpportunityWrapperDto.accountHead = formValue.accountHead;
      tenderOpportunityWrapperDto.category = formValue.category;
      tenderOpportunityWrapperDto.subcategory = formValue.subcategory;
      tenderOpportunityWrapperDto.formOfContract = formValue.formOfContract;
      tenderOpportunityWrapperDto.tenderOpeningType = formValue.tenderOpeningType;
      tenderOpportunityWrapperDto.tenderContractType = formValue.tenderContractType;
    }

    if (this.currentView == OpportunityPages.CONFIGURATION) {
      let formValue = this.formGroupConfiguration.getRawValue();
      tenderOpportunityWrapperDto.allowReBidSubmission = formValue.allowReBidSubmission;
      tenderOpportunityWrapperDto.allowWithdrawalOfBids = formValue.allowWithdrawalOfBids;
      tenderOpportunityWrapperDto.allowMultipleCurrency = formValue.allowMultipleCurrency;
      tenderOpportunityWrapperDto.currencies = formValue.currencies;
      tenderOpportunityWrapperDto.noOfEnvelopes = formValue.noOfEnvelopes;
      tenderOpportunityWrapperDto.tenderValue = formValue.tenderValue;
      tenderOpportunityWrapperDto.showTenderValueToBidder = formValue.showTenderValueToBidder;
      tenderOpportunityWrapperDto.bidValidityDays = formValue.bidValidityDays;
      tenderOpportunityWrapperDto.leadTimeDays = formValue.leadTimeDays;
      tenderOpportunityWrapperDto.deliveryLocationDetails = formValue.deliveryLocationDetails;
      tenderOpportunityWrapperDto.allowPreBidMeeting = formValue.allowPreBidMeeting;
      tenderOpportunityWrapperDto.preBidMeetingLocation = formValue.preBidMeetingLocation;
      tenderOpportunityWrapperDto.bidOpeningLocation = formValue.bidOpeningLocation;
      tenderOpportunityWrapperDto.invitingOfficerLocation = formValue.invitingOfficerLocation;
    }

    if (this.currentView == OpportunityPages.FEE) {
      let formValue = this.formGroupFeeDetails.getRawValue();
      if (!tenderOpportunityWrapperDto.tenderFeeDetails) {
        tenderOpportunityWrapperDto.tenderFeeDetails = new TenderFeeDetails();
      }

      tenderOpportunityWrapperDto.tenderFeeDetails.tenderFeePaymentMode = formValue.tenderFeePaymentMode;
      tenderOpportunityWrapperDto.tenderFeeDetails.tenderFee = formValue.tenderFee;
      tenderOpportunityWrapperDto.tenderFeeDetails.processingFee = formValue.processingFee;
      tenderOpportunityWrapperDto.tenderFeeDetails.surcharges = formValue.surcharges;
      tenderOpportunityWrapperDto.tenderFeeDetails.otherCharges = formValue.otherCharges;
      tenderOpportunityWrapperDto.tenderFeeDetails.tenderFeePayableTo = formValue.tenderFeePayableTo;
      tenderOpportunityWrapperDto.tenderFeeDetails.tenderFeePayableAt = formValue.tenderFeePayableAt;
      tenderOpportunityWrapperDto.tenderFeeDetails.emdAmount = formValue.emdAmount;
      tenderOpportunityWrapperDto.tenderFeeDetails.allowEmdExemption = formValue.allowEmdExemption;
      tenderOpportunityWrapperDto.tenderFeeDetails.emdFeePayableTo = formValue.emdFeePayableTo;
      tenderOpportunityWrapperDto.tenderFeeDetails.emdFeePayableAt = formValue.emdFeePayableAt;
      tenderOpportunityWrapperDto.tenderFeeDetails.feeDocuments = this.feeDocuments;
      tenderOpportunityWrapperDto.tenderFeeDetails.offlinePaymentOptions = formValue.offlinePaymentOptions
    }

    if (this.currentView == OpportunityPages.CRITICAL_DATES) {
      let formValue = this.formGroupCriticalDates.getRawValue();
      tenderOpportunityWrapperDto.publishingDate = formValue.publishingDate;
      tenderOpportunityWrapperDto.documentDownloadDate = formValue.documentDownloadDate;
      tenderOpportunityWrapperDto.allowSeekClarification = formValue.allowSeekClarification;
      tenderOpportunityWrapperDto.seekClarificationStartDate = formValue.seekClarificationStartDate;
      tenderOpportunityWrapperDto.seekClarificationEndDate = formValue.seekClarificationEndDate;
      tenderOpportunityWrapperDto.bidSubmissionStartDate = formValue.bidSubmissionStartDate;
      tenderOpportunityWrapperDto.bidSubmissionEndDate = formValue.bidSubmissionEndDate;
      tenderOpportunityWrapperDto.technicalOpeningDate = formValue.technicalOpeningDate;
      tenderOpportunityWrapperDto.financialOpeningDate = formValue.financialOpeningDate;
    }

    if (tenderWrapperUiDto && !tenderWrapperUiDto.tenderOpportunityWrapperDtos) {
      tenderWrapperUiDto.tenderOpportunityWrapperDtos = []
    }

    let index = tenderWrapperUiDto.tenderOpportunityWrapperDtos!.findIndex(item => item.tenderOpportunityId == tenderOpportunityWrapperDto.tenderOpportunityId);
    if (index != undefined && index > -1) {
      Object.assign(tenderWrapperUiDto.tenderOpportunityWrapperDtos![index], tenderOpportunityWrapperDto);
    } else {
      tenderWrapperUiDto.tenderOpportunityWrapperDtos!.push(tenderOpportunityWrapperDto);
    }

    return tenderWrapperUiDto;
  }

  async saveTenderWrapperUiDto() {
    this.errorMsg = '';

    if (this.currentView == OpportunityPages.BASIC) {
      if (this.formGroup.invalid) {
        this.formGroup.markAllAsTouched();
        return;
      }
    } else if (this.currentView == OpportunityPages.CONFIGURATION) {
      if (this.formGroupConfiguration.invalid) {
        this.formGroupConfiguration.markAllAsTouched();
        return;
      }
    } else if (this.currentView == OpportunityPages.FEE) {
      if (this.formGroupFeeDetails.invalid) {
        this.formGroupFeeDetails.markAllAsTouched();
        return;
      }
      if (this.feeDocuments.length == 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload documents' });
        return;
      }
    } else if (this.currentView == OpportunityPages.CRITICAL_DATES) {
      if (this.formGroupCriticalDates.invalid) {
        this.formGroupCriticalDates.markAllAsTouched();
        return;
      }
    }

    let tenderWrapperUiDto = this.mergeTenderWrapperUiDto()
    console.log(tenderWrapperUiDto);

    this.isLoading = true;

    try {
      const response = await firstValueFrom(this.tenderManagementService.saveTenderWrapperUiDto(tenderWrapperUiDto));
      if (response.code === ApplicationConstants.SUCCESS_CODE) {

        this.isLoading = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Document saved successfully.' });

        setTimeout(() => {

          this.drawerService.isFormEditMode(true);
          const tenderWrapperUiDto = response.data as TenderWrapperUiDto;
          this.store.dispatch(TenderManagementActions.setCurrentTenderWrapperUiDto({ tenderWrapperUiDto }));
        }, 2000);
      } else {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message });
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;

      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while saving Tender Opportunity. Try again.' });
    }
  }

  getLotSequence() {
    let toReturn = 1;
    let opportunities = this.tenderWrapperUiDto?.tenderOpportunityWrapperDtos;
    if (opportunities && opportunities.length > 0) {
      opportunities.sort((a, b) => Number(b.sequenceNo || 0) - Number(a.sequenceNo || 0));
      toReturn = Number(opportunities[0].sequenceNo || 0) + 1
    }
    return toReturn;
  }

  openCurrentPage(currentView: any) {
    if (currentView == OpportunityPages.CONFIGURATION) {
      if (this.formGroup.invalid) {
        this.formGroup.markAllAsTouched();
        return;
      }
    } else if (currentView == OpportunityPages.FEE) {
      if (this.formGroupConfiguration.invalid) {
        this.formGroupConfiguration.markAllAsTouched();
        return;
      }
    } else if (currentView == OpportunityPages.CRITICAL_DATES) {
      if (this.formGroupFeeDetails.invalid) {
        this.formGroupFeeDetails.markAllAsTouched();
        return;
      }

      if (this.feeDocuments.length == 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload documents' });
        return;
      }
    }

    this.currentView = currentView;
  }

  openExternalDrawer(drawerName: string, tabName?: string) {
    this.drawerService.openDrawer(drawerName, tabName);
  }

  closeDrawer() {
    if (this.tenderOpportunityWrapperDto) {
      let tenderOpportunityWrapperDto = this.tenderOpportunityWrapperDto;
      this.store.dispatch(TenderManagementActions.setCurrentTenderOpportunityWrapperDto({ tenderOpportunityWrapperDto }));
    } else {
      let tenderOpportunityWrapperDtos = this.tenderWrapperUiDto?.tenderOpportunityWrapperDtos || [];
      if (tenderOpportunityWrapperDtos.length > 0) {
        let tenderOpportunityWrapperDto = tenderOpportunityWrapperDtos[0];
        this.store.dispatch(TenderManagementActions.setCurrentTenderOpportunityWrapperDto({ tenderOpportunityWrapperDto }));
      }
    }

    this.drawerService.closeDrawer();
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }
    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}
