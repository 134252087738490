import { MessageToastComponent } from './components/message-toast/message-toast.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule, NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UserLoginComponent } from './components/user/user-login/user-login.component';
import { UserPasswordChangeComponent } from './components/user/user-password-change/user-password-change.component';
import { MessageDialogComponent } from '../common/message-dialog/message-dialog.component';
import { UserPasswordResetComponent } from './components/user/user-password-reset/user-password-reset.component';
import { UserLogoutComponent } from './components/user/user-logout/user-logout.component';
import { UserVerifyOtpComponent } from './components/user/user-verify-otp/user-verify-otp.component';
import { UserRegisterTobidComponent } from './components/user/user-register-tobid/user-register-tobid.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from 'src/assets/plugins/angular-editor';
import { UserDetailsComponent } from './components/user/user-details/user-details.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HtmlViewComponent } from './components/html-view/html-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ErrorsListModalComponent } from './components/errors-list-modal/errors-list-modal.component';
import { MapViewComponent } from './components/map-view/map-view.component';

import { RouterModule } from '@angular/router';
import { CompanyTermsConditionsComponent } from './components/company-terms-conditions/company-terms-conditions.component';
import { CompanyPrivacyPolicyComponent } from './components/company-privacy-policy/company-privacy-policy.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { AdminAuctionHouseSectionComponent } from '../layouts/admin/admin-auction-house-section/admin-auction-house-section.component';
import { SwiperModule } from 'swiper/angular';
import { EmailVerifyModalComponent } from './components/email-verify-modal/email-verify-modal.component';
import { NewFooterComponent } from './new-footer/new-footer.component';
import { SafePipe } from './pipes/safe.pipe';
import { LongPressDirective } from './directives/long-press.directive';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { CountryCodeSelectComponent } from './components/country-code-select/country-code-select.component';
import { CookiesAlertComponent } from './components/cookies-alert/cookies-alert.component';
import { UserAuctionRegistrationsNotificationComponent } from './components/user-auction-registrations-notification/user-auction-registrations-notification.component';
import { AuctionHouseDataChartsComponent } from './components/auction-house-data-charts/auction-house-data-charts.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { AiDescriptionGeneratorComponent } from './components/ai-description-generator/ai-description-generator.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { InvoicePreviewComponent } from './components/invoice-preview/invoice-preview.component';
import { AwardedBiddersLotsComponent } from './components/awarded-bidders-lots/awarded-bidders-lots.component';
import { GeneratedInvoiceComponent } from './components/generated-invoice/generated-invoice.component';
import { ClosedAuctionCardComponent } from './components/closed-auction-card/closed-auction-card.component';
import { RedirectLoaderComponent } from './components/redirect-loader/redirect-loader.component';
import { PinchZoomModule } from 'src/assets/plugins/ngx-pinch-zoom';
import { AuctionCalendarComponent } from './components/auction-calendar/auction-calendar.component';
import { FlatpickrModule } from 'angularx-flatpickr';
// import { CalendarModule } from 'angular-calendar';
import { CalendarListComponent } from './components/calendar-list/calendar-list.component';
import { AuctionCalendarDetailsComponent } from './components/auction-calendar-details/auction-calendar-details.component';
import { AuctionCalendarViewComponent } from './components/auction-calendar-view/auction-calendar-view.component';
import { SearchCardComponent } from './components/search-card/search-card.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { SupplierFloorNotificationComponent } from './components/supplier-floor-notification/supplier-floor-notification.component';
import { GenerateLotReportComponent } from './components/generate-lot-report/generate-lot-report.component';
import { GenerateLotReportPdfComponent } from './components/generate-lot-report-pdf/generate-lot-report-pdf.component';
import { UserCategorySelectionComponent } from './components/user-category-selection/user-category-selection.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AdminBidderDetailsComponent } from './components/user/admin-bidder-details/admin-bidder-details.component';
import { BidderDetailsComponent } from './components/user/bidder-details/bidder-details.component';
import { UnsubscribeNotificationComponent } from '../app/shared/components/unsubscribe-notification/unsubscribe-notification.component';
import { WelcomePageComponent } from '../app/shared/components/welcome-page/welcome-page.component';
import { LotsErrorValidationComponent } from './components/lots-error-validation/lots-error-validation.component';
import { LotSequenceCarouselComponent } from './components/lot-sequence-carousel/lot-sequence-carousel.component';
import { QuestionnaireProjectComponent } from '../layouts/questionnaire/questionare-project/questionnaire-project.component';
import { TechnicalSectionComponent } from '../layouts/questionnaire/technical-section/technical-section.component';
import { TechnicalQuestionComponent } from '../layouts/questionnaire/technical-question/technical-question.component';
import { FinancialSectionComponent } from '../layouts/questionnaire/financial-section/financial-section.component';
import { FinancialQuestionComponent } from '../layouts/questionnaire/financial-question/financial-question.component';
import { HeaderComponent } from './components/header/header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TagInputModule } from 'ngx-chips';
import { SubcategorySequenceCarouselComponent } from './components/subcategory-sequence-carousel/subcategory-sequence-carousel.component';
import { SubcategoryErrorValidationComponent } from './components/subcategory-error-validation/subcategory-error-validation.component';
import { CatlogueComponent } from './components/catlogue/catlogue.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PickListModule } from 'primeng/picklist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SpeedDialModule } from 'primeng/speeddial';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CardModule } from 'primeng/card';
import { TreeModule } from 'primeng/tree';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { CategoriesTreeViewComponent } from './components/categories-tree-view/categories-tree-view.component';
import { StepsModule } from 'primeng/steps';
import { TreeTableModule } from 'primeng/treetable';
import { TableModule } from 'primeng/table';
import { EditorModule } from 'primeng/editor';
import { ChipModule } from 'primeng/chip';
import { DrawerMainComponent } from './components/drawer-main/drawer-main.component';
import { TimelineModule } from 'primeng/timeline';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { LucideAngularModule } from 'lucide-angular';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { OnboardingNewTeamComponent } from './components/onboarding-new-team/onboarding-new-team.component';
import { MenuModule } from 'primeng/menu';
import { OrdinalDatePipe } from './pipes/ordinal-date.pipe';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { TextAvatarPipe } from './pipes/text-avatar.pipe';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { SidebarModule } from 'primeng/sidebar';
import { PriceFormatPipe } from './pipes/price-format.pipe';
import { TitleCasePipe } from './pipes/title-case.pipe';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { PlacesAutocompleteComponent } from './components/places-autocomplete/places-autocomplete.component';

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  LoaderComponent,
  UserLoginComponent,
  UserPasswordChangeComponent,
  UserPasswordResetComponent,
  UserLogoutComponent,
  MessageDialogComponent,
  UserVerifyOtpComponent,
  UserRegisterTobidComponent,
  MessageToastComponent,
  UserDetailsComponent,
  HtmlViewComponent,
  ErrorsListModalComponent,
  MapViewComponent,
  CompanyTermsConditionsComponent,
  CompanyPrivacyPolicyComponent,
  ComingSoonComponent,
  AdminAuctionHouseSectionComponent,
  EmailVerifyModalComponent,
  SafePipe,
  LongPressDirective,
  ImagePreviewComponent,
  CountryCodeSelectComponent,
  CookiesAlertComponent,
  UserAuctionRegistrationsNotificationComponent,
  AuctionHouseDataChartsComponent,
  InvoicePreviewComponent,
  AwardedBiddersLotsComponent,
  GeneratedInvoiceComponent,
  ClosedAuctionCardComponent,
  RedirectLoaderComponent,
  AuctionCalendarComponent,
  SearchCardComponent,
  SupplierFloorNotificationComponent,
  GenerateLotReportComponent,
  GenerateLotReportPdfComponent,
  UnsubscribeNotificationComponent,
  WelcomePageComponent,
  LotSequenceCarouselComponent,
  QuestionnaireProjectComponent,
  TechnicalSectionComponent,
  TechnicalQuestionComponent,
  FinancialSectionComponent,
  FinancialQuestionComponent,
  BreadcrumbComponent,
  SubcategorySequenceCarouselComponent,
  SubcategoryErrorValidationComponent,
  CatlogueComponent,
  NotificationsComponent,
  CategoriesTreeViewComponent,
  ChatbotComponent,
  DrawerMainComponent,
  OnboardingNewTeamComponent,
  PlacesAutocompleteComponent,

  OrdinalDatePipe,
  TextAvatarPipe,
  PriceFormatPipe,
  TitleCasePipe,
]

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  NgbModule,
  AngularEditorModule,
  GoogleMapsModule,
  NgxGpAutocompleteModule,
  DragDropModule,
  RouterModule,
  NgbCollapseModule,
  NgbAccordionModule,
  SwiperModule,
  NgxEchartsModule,
  ColorPickerModule,
  InputSwitchModule,
  PinchZoomModule,
  FlatpickrModule,
  NgxJsonViewerModule,
  TagInputModule,
  PickListModule,
  OrganizationChartModule,
  SelectButtonModule,
  SpeedDialModule,
  OverlayPanelModule,
  CardModule,
  TreeModule,
  InputTextModule,
  TabViewModule,
  MultiSelectModule,
  StepsModule,
  TreeTableModule,
  TableModule,
  TimelineModule,
  EditorModule,
  ChipModule,
  AutoCompleteModule,
  LucideAngularModule,
  DropdownModule,
  DialogModule,
  MenuModule,
  CalendarModule,
  ConfirmPopupModule,
  SidebarModule,
  ProgressSpinnerModule,
  ConfirmDialogModule,
  ToastModule,
  ButtonModule
]

@NgModule({
  declarations: [
    ...COMPONENTS,
    NewFooterComponent,
    AiDescriptionGeneratorComponent,
    CalendarListComponent,
    AuctionCalendarDetailsComponent,
    AuctionCalendarViewComponent,
    SearchCardComponent,
    UserCategorySelectionComponent,
    UserProfileComponent,
    AdminBidderDetailsComponent,
    BidderDetailsComponent,
    LotsErrorValidationComponent,
  ],
  imports: [
    CommonModule,
    ...MODULES
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES
  ]
})
export class SharedModule { }
