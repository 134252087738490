import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from './../../../shared/services/file.service';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { FileInfoDto } from './../../../shared/models/FileInfoDto';
import { TranslateService } from './../../../shared/services/translate.service';
import { AuctionValidationErrorCodeDto } from './../../../shared/models/user/AuctionValidationErrorCodeDto';
import { AuctionSaveWrapperDto } from './../../../shared/models/user/AuctionSaveWrapperDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Currency } from './../../../shared/models/Currency';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, OnInit, SecurityContext, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';
import { AuctionValidationService } from 'src/app/shared/services/auction-validation.service';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { DeploymentConfigurationDto } from 'src/app/shared/models/user/DeploymentConfigurationDto';
import { ShowBidderHistoryEnum } from 'src/app/shared/enums/ShowBidderHistoryEnum';
import { RankPriceConfigEnum } from 'src/app/shared/enums/RankPriceConfigEnum';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { StringMinValidator } from 'src/app/shared/validators/string-min.directive';
import { TimeZoneDto } from 'src/app/shared/models/TimeZoneDto';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';

@Component({
  selector: 'app-auction-creation-details',
  templateUrl: './auction-creation-details.component.html',
  styleUrls: ['./auction-creation-details.component.sass']
})
export class AuctionCreationDetailsComponent implements OnInit, OnDestroy {

  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  fileUploadError: boolean = false;
  currentFile?: File;

  currentFileInfoDto?: FileInfoDto
  imageUrl?: string | ArrayBuffer | SafeUrl | null;
  _fileDisplayName$ = new BehaviorSubject<string | null>("");
  _fileUploaded$ = new BehaviorSubject<boolean>(false);

  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];
  auctionEntityDto?: AuctionEntityDto;
  nextClosingAuctionLot?: AuctionLotEntityDto;
  currencies: Array<Currency> = [];
  timezones: Array<TimeZoneDto> = [];
  rankPriceConfigs: Array<any> = [
    RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION,
    RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION,
    RankPriceConfigEnum.SHOW_RANK_AND_NO_CURRENT_PRICE_WITH_TRANSITION
  ];

  selectedCurrency?: Currency;
  formGroup: FormGroup;
  ngbPopover?: NgbPopover;
  startTime?: NgbTimeStruct;
  imageType?: string;

  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');

  deploymentConfigurationDtoSubscription$?: Subscription;
  deploymentConfigurationDto?: DeploymentConfigurationDto;

  isAllowToShowBidderHistoryConfig: boolean = false;

  isLoading?: boolean = false;
  isActiveImage?: boolean = false;
  isSubmitted: boolean = false;

  auctionTimeZoneSubscription$?: Subscription;
  selectedAuctionSubscription$?: Subscription;

  @ViewChild('allowEditingConfirmModalTemplate') allowEditingConfirmModalTemplate?: TemplateRef<any>;
  allowEditingConfirmModalRef?: NgbModalRef;
  selectedEditingConfig: boolean = false;
  selectedEditingType?: string;

  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  }

  minTime: NgbTimeStruct = {
    hour: new Date().getHours(),
    minute: new Date().getMinutes(),
    second: new Date().getSeconds()
  }


  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    private transalteService: TranslateService,
    private datePipe: DatePipe,
    public adminService: AdminDashboardService,
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private formBuilder: FormBuilder,
    private ngbModal: NgbModal,
    private validationService: AuctionValidationService,
    private lotService: AdminLotsDataHolderService,
    private auctionValidationService: AuctionValidationService,
    private currencyService: CurrencyService,
    public onboardService: OnboardingService
  ) {
    this.formGroup = this.formBuilder.group({
      auctionName: new FormControl('', Validators.required),
      companyName: new FormControl('', Validators.required),
      timeZone: new FormControl('', Validators.required),
      auctionType: new FormControl('', Validators.required),
      auctionBiddingMethod: new FormControl(ApplicationConstants.FORWARD_AUCTION, Validators.required),
      currency: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      startTime: new FormControl('', Validators.required),
      bidderApproval: new FormControl('', Validators.required),
      rankCurrentPriceConfig: new FormControl('', Validators.required),
      antiSnipe: new FormControl('', [Validators.min(1), Validators.max(15)]),
      premiumAuction: new FormControl(false),
      preOffer: new FormControl(false),
      maskBidderDetails: new FormControl(false),
      showBidderHistory: new FormControl(''),
      auctionImage: new FormControl(null),
      enableBlockchain: new FormControl(false),
      allowEditWhileUpcoming: new FormControl(true),
      allowEditWhileRunning: new FormControl(true),

      region: new FormControl('', Validators.required),
      costCenters: new FormControl('', Validators.required),
      estSpend: new FormControl('', Validators.required),
      estSaving: new FormControl('', Validators.required),
    })
  }

  ngOnInit(): void {
    this.currencies = this.currencyService.getCurrencies;

    this.deploymentConfigurationDtoSubscription$ = this.adminService.getDeploymentConfiguration$.subscribe(data =>{
      if(data){
        this.deploymentConfigurationDto = data;
      }
    })

    this.selectedAuctionSubscription$ =this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(auction => {
      if (auction) {
        this.auctionEntityDto = auction!;
        this.changeDetectorRef.detectChanges();
      }
    })

    this.auctionTimeZoneSubscription$ = this.adminService.getMasterTimezones$.subscribe((data) => {
      if (data) {
        this.timezones = data;
        this.changeDetectorRef.detectChanges();
      }
    })

    this.formGroup.controls['startDate'].valueChanges.subscribe((val) => {
      if (val && !this.isSubmitted) {
        this.checkStartTimeValidation();
      }
    })
  }

  openViewImageModal(imageUrl:any) {
    let modalRef = this.ngbModal.open(ImagePreviewComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.imageUrl=imageUrl
  }

  getShowBidderHistory(showBidderHistory: ShowBidderHistoryEnum) {
    if (showBidderHistory == ShowBidderHistoryEnum.ALWAYS) {
      return "Always"
    } else if (showBidderHistory == ShowBidderHistoryEnum.NEVER) {
      return "Never"
    } else if (showBidderHistory == ShowBidderHistoryEnum.AFTER_AUCTION_CLOSE) {
      return "After Auction Close"
    }
    return "";
  }

  getRankPriceConfigName(rankPriceConfig: string) {
    let toReturn = "";
    if (RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION == rankPriceConfig) {
      toReturn = "Show Market Price Only"
    } else if (RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION == rankPriceConfig) {
      toReturn = "Show Rank & Market Price"
    } else if (RankPriceConfigEnum.SHOW_RANK_AND_NO_CURRENT_PRICE_WITH_TRANSITION == rankPriceConfig) {
      toReturn = "Show Rank Only"
    }
    return toReturn;
  }

  checkAntiSnipeInputIsInteger(){
    if(this.formGroup.controls['antiSnipe'].value ){
      let val = this.formGroup.controls['antiSnipe'].value;
      let updateVal = Math.floor(val)
      if(updateVal){
        this.formGroup.controls['antiSnipe'].patchValue(updateVal);
      }
    }
  }


  populateIntialAuctionEntityDto() {
    let currentDate = new Date();

    this.formGroup.controls['auctionName'].setValue(this.auctionEntityDto?.auctionName);
    this.formGroup.controls['companyName'].setValue(this.auctionEntityDto?.companyId);
    this.formGroup.controls['auctionType'].setValue(this.auctionEntityDto?.auctionType);
    this.formGroup.controls['auctionBiddingMethod'].setValue(this.auctionEntityDto?.auctionBiddingMethod ?? ApplicationConstants.FORWARD_AUCTION);
    this.formGroup.controls['timeZone'].setValue(this.auctionEntityDto?.timeZone);
    this.formGroup.controls['antiSnipe'].setValue(this.auctionEntityDto?.antiSnipe);
    this.formGroup.controls['premiumAuction'].setValue(this.auctionEntityDto?.premiumAuction);
    this.formGroup.controls['preOffer'].setValue(this.auctionEntityDto?.buyItNowEnable);
    this.formGroup.controls['maskBidderDetails'].setValue(this.auctionEntityDto?.maskBidderDetails);
    this.formGroup.controls['showBidderHistory'].setValue(this.auctionEntityDto?.showBidderHistory);

    this.formGroup.controls['enableBlockchain'].setValue(this.auctionEntityDto?.enableBlockchain);
    this.formGroup.controls['bidderApproval'].setValue(this.auctionEntityDto?.bidderApproval);
    this.formGroup.controls['rankCurrentPriceConfig'].setValue(this.auctionEntityDto?.rankCurrentPriceConfig);
    this.selectedCurrency = this.auctionEntityDto?.currency;
    this.formGroup.controls['currency'].setValue(this.auctionEntityDto?.currency?.name);

    this.formGroup.controls['allowEditWhileUpcoming'].setValue(this.auctionEntityDto?.allowEditWhileUpcoming);
    this.formGroup.controls['allowEditWhileRunning'].setValue(this.auctionEntityDto?.allowEditWhileRunning);

    if (this.auctionEntityDto?.startDate) {
      let startDate = ApplicationUtils.getNgbPickerDate(this.auctionEntityDto?.startDate!);
      this.formGroup.get('startDate')?.patchValue(startDate);

      this.setMinimumDateTime();
    }

    if (this.auctionEntityDto?.startTime) {
      this.startTime = ApplicationUtils.getNgbPickerTime(this.auctionEntityDto?.startTime!);
      let startTime12h = ApplicationUtils.convertTime24to12(this.auctionEntityDto?.startTime!, this.datePipe);
      this.formGroup.get('startTime')?.patchValue(startTime12h);
    } else {
      this.startTime = ApplicationUtils.getNgbPickerTime(`${currentDate.getHours()}:${currentDate.getMinutes()}`);
      let startTime12h = ApplicationUtils.convertTime24to12(`${currentDate.getHours()}:${currentDate.getMinutes()}`, this.datePipe);
      this.formGroup.get('startTime')?.patchValue(startTime12h);
    }

    if (this.auctionEntityDto?.auctionImage != null) {

      this.imageUrl = this.sanitizer.sanitize(SecurityContext.URL, `/downloadLandingBlob?fileId=${this.auctionEntityDto?.auctionImage?.fileId}`);
      this.currentFileInfoDto = this.auctionEntityDto?.auctionImage;

      if (this.auctionEntityDto?.auctionImage?.displayName) {
        this._fileDisplayName$.next(this.auctionEntityDto?.auctionImage?.displayName!);
        this._fileUploaded$.next(true);
      } else {
        this._fileDisplayName$.next("");
        this._fileUploaded$.next(false);
      }
    }


    if(this.auctionEntityDto?.auctionBiddingMethod == ApplicationConstants.REVERSE_AUCTION){
      this.formGroup.controls['auctionType'].disable();
    }else{
      this.formGroup.controls['auctionType'].enable();
    }

    this.formGroup.controls['region'].setValue(this.auctionEntityDto?.region);
    this.formGroup.controls['costCenters'].setValue(this.auctionEntityDto?.costCenters);

    let estSpendValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.auctionEntityDto?.estSpend);
    this.formGroup.get('estSpend')?.patchValue(estSpendValue);

    let estSavingValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.auctionEntityDto?.estSaving);
    this.formGroup.get('estSaving')?.patchValue(estSavingValue);

    this.populateBidderHistoryConfig();

    this.formGroup.updateValueAndValidity()
  }
  onAuctionBiddingMethodChange() {
    let auctionBiddingMethod = this.formGroup.controls['auctionBiddingMethod'].value;
    if(auctionBiddingMethod == ApplicationConstants.REVERSE_AUCTION){
      this.formGroup.controls['preOffer'].setValue(false);
      this.formGroup.controls['auctionType'].setValue('Subject to Confirmation');
      this.formGroup.controls['auctionType'].disable();
    }else{
      this.formGroup.controls['auctionType'].setValue('');
      this.formGroup.controls['auctionType'].enable();
    }

  }

  onlyAcceptInteger(event: any){
    if(event.key==='.'){event.preventDefault();}
  }

  setMinimumDateTime() {
    let currentDate = new Date();
    let currentDateNgbPicker = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() }
    let currentTimeNgbPicker = { hour: currentDate.getHours(), minute: currentDate.getMinutes(), second: currentDate.getSeconds() }

    if (this.auctionEntityDto?.startDate && this.auctionEntityDto?.startTime) {
      let sDate = ApplicationUtils.convertedDate(this.auctionEntityDto?.startDate!, this.auctionEntityDto?.startTime!)!;

      let startDateNgbPicker = ApplicationUtils.getNgbPickerDate(this.auctionEntityDto?.startDate!);
      let startTimeNgbPicker = ApplicationUtils.getNgbPickerTime(this.auctionEntityDto?.startTime!);

      // If Start Date is Upcoming Date then minDate is Current Date
      // If Start Date is Past Date then minDate is Start Date (Past Date)
      this.minDate = sDate > currentDate ?  currentDateNgbPicker : startDateNgbPicker;
      this.minTime = sDate > currentDate ?  currentTimeNgbPicker : startTimeNgbPicker;
    }
  }

  checkStartTimeValidation() {
    this.setMinimumDateTime();

    // Selected Date & Time
    let inputDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['startDate'].value);
    let inputTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['startTime'].value);

    // Min Date & Time
    let minimumDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.minDate);
    let minimumTimeIn12h = ApplicationUtils.getTimeFromNgTimePicker(this.datePipe, this.minTime);
    let minimumTime = ApplicationUtils.convertTime12to24(minimumTimeIn12h);

    let tempAllLots = [...this.lotService.allLots ?? []];
    let allLots = tempAllLots.sort((a, b) => this.getRemainingTime(a) - this.getRemainingTime(b));
    if (allLots && allLots.length > 0) {
      this.nextClosingAuctionLot = allLots[0];
    }

    let lotEndDateTime;

    if (inputDate && inputTime) {
      let _inputDateTime = ApplicationUtils.convertedDate(inputDate, inputTime);
      let _minimumDateTime = ApplicationUtils.convertedDate(minimumDate!, minimumTime!);

      if (this.nextClosingAuctionLot) {
        lotEndDateTime = ApplicationUtils.convertedDate(this.nextClosingAuctionLot?.endDate!, this.nextClosingAuctionLot?.endTime!);
      }

      if (_inputDateTime && _minimumDateTime && (_inputDateTime < _minimumDateTime || (lotEndDateTime && _inputDateTime >= lotEndDateTime))) {
        this._showErrorToast$.next(true);

        if (lotEndDateTime && _inputDateTime >= lotEndDateTime) {
          this._errorMsg$.next("It is not possible to set a start date for the action that precedes the end date of its lot.".toUpperCase());
        } else {
          this._errorMsg$.next("AUCTION START DATE/TIME MUST BE AFTER CURRENT TIME");
        }

        return true;
      } else {
        this._showErrorToast$.next(false);
        this._errorMsg$.next("");
      }
    }
    return false;
  }

  getCompanyName(companyId?: string){
    return "";
  }


  populateAuctionEntityDto(): AuctionEntityDto {
    let auctionEntityDto = ApplicationUtils.clone(this.auctionEntityDto);

    auctionEntityDto!.auctionName = this.formGroup.controls['auctionName'].value;
    auctionEntityDto!.companyId = this.formGroup.controls['companyName'].value;
    auctionEntityDto!.auctionType = this.formGroup.controls['auctionType'].value;
    auctionEntityDto!.auctionBiddingMethod = this.formGroup.controls['auctionBiddingMethod'].value;
    auctionEntityDto!.timeZone = this.formGroup.controls['timeZone'].value;
    auctionEntityDto!.antiSnipe = this.formGroup.controls['antiSnipe'].value;
    auctionEntityDto!.bidderApproval = this.formGroup.controls['bidderApproval'].value;
    auctionEntityDto!.rankCurrentPriceConfig = this.formGroup.controls['rankCurrentPriceConfig'].value;
    auctionEntityDto!.premiumAuction = this.formGroup.controls['premiumAuction'].value;
    auctionEntityDto!.buyItNowEnable = this.formGroup.controls['preOffer'].value;
    auctionEntityDto!.maskBidderDetails = this.formGroup.controls['maskBidderDetails'].value;
    auctionEntityDto!.showBidderHistory = this.formGroup.controls['showBidderHistory'].value;
    auctionEntityDto!.enableBlockchain = this.formGroup.controls['enableBlockchain'].value;
    auctionEntityDto!.allowEditWhileUpcoming = this.formGroup.controls['allowEditWhileUpcoming'].value;
    auctionEntityDto!.allowEditWhileRunning = this.formGroup.controls['allowEditWhileRunning'].value;
    auctionEntityDto!.startDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['startDate'].value)!;
    auctionEntityDto!.startTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['startTime'].value);
    auctionEntityDto!.currency = this.selectedCurrency;
    auctionEntityDto!.auctionImage = this.currentFileInfoDto;

    auctionEntityDto!.region = this.formGroup.controls['region'].value;
    auctionEntityDto!.costCenters = this.formGroup.controls['costCenters'].value;
    auctionEntityDto!.estSpend = ApplicationUtils.resetPriceFormat(this.formGroup.controls['estSpend'].value).toString();
    auctionEntityDto!.estSaving = ApplicationUtils.resetPriceFormat(this.formGroup.controls['estSaving'].value).toString();

    return auctionEntityDto;
  }

  handleSubmit() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (!this._fileDisplayName$.value) {
      return
    }

    if (this.checkStartTimeValidation()) {
      return;
    }

    this.auctionValidationErrorCodeDtoList = [];
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    if (this.currentFile) {
      this.uploadFile(this.currentFile!);
    } else {
      this.updateAuction();
    }
  }

  uploadFile(currentFile: File) {
    this.isLoading = true;
    this.fileUploadError = false;
    this._errorMsg$.next("");

    let metaData = {
      'mimeType': currentFile.type,
      'version': 0,
      'publicApi': true,
      'dataType': this.imageType
    };

    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileInfoDto;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          this.currentFileInfoDto = new FileInfoDto();
          this.currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
          this.currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto.fileSize = fileSaveResponseDto.fileSize;
          this.currentFileInfoDto.fileType = fileSaveResponseDto.fileType;
          this.currentFileInfoDto.dataType = fileSaveResponseDto.dataType;
          this.currentFileInfoDto.uploadDate = new Date().toDateString();

          this.formGroup.get('auctionImage')?.setValue(this.currentFileInfoDto.displayName);
          this.formGroup.updateValueAndValidity();
          this.updateAuction();
        }
      } else {
        this.isLoading = false;
        this.fileUploadError = true;
      }
    })
  }

  getFormattedPrice(price: any) {
    return ApplicationUtils.getFormattedPrice(this.selectedCurrency?.locale!, price);
  }

  invalidPriceValidator(item: AbstractControl | null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }

  formatEstSpendValue() {
    if (this.selectedCurrency) {
      let estSpendValue = this.formGroup.controls['estSpend'].value;
      let estSpendValueFinal = ApplicationUtils.getFormattedPrice(this.selectedCurrency?.locale!, estSpendValue);
      this.fc['estSpend'].patchValue(estSpendValueFinal);
    }
  }

  formatEstSavingValue() {
    if (this.selectedCurrency) {
      let estSavingValue = this.formGroup.controls['estSaving'].value;
      let estSavingValueFinal = ApplicationUtils.getFormattedPrice(this.selectedCurrency?.locale!, estSavingValue);
      this.fc['estSaving'].patchValue(estSavingValueFinal);
    }
  }

  // Currency
  onChangeCurrency(event: any) {
    let name = event.target.value;
    let currency = this.currencies.find(m => m.name == name);
    this.selectedCurrency = currency;

    this.fc['estSpend'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.selectedCurrency?.regex!)]);
    this.fc['estSpend'].updateValueAndValidity();
    this.fc['estSaving'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.selectedCurrency?.regex!)]);
    this.fc['estSaving'].updateValueAndValidity();

    this.formatEstSpendValue();
    this.formatEstSavingValue();
  }


  openTimepicker(p: NgbPopover, template: any) {
    if (this.ngbPopover?.isOpen) this.ngbPopover.close();

    if (!p.isOpen()) {
      this.ngbPopover = p;
      p.ngbPopover = template;
      p.open();
    } else {
      p.close();
    }
  }

  setTime(time: string) {
    if (time == 'startTime') {
      if (this.startTime?.hour != null && this.startTime?.minute != null && this.startTime?.second != null) {
        let convertedTime = ApplicationUtils.getTimeFromNgTimePicker(this.datePipe, this.startTime);
        this.formGroup.controls?.['startTime'].patchValue(convertedTime);
      }
    }
    if (this.ngbPopover?.isOpen()) this.ngbPopover?.close();
    this.formGroup.updateValueAndValidity();

    this.checkStartTimeValidation()
  }


  updateAuction() {
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");

    let auctionEntityDto = this.populateAuctionEntityDto();

    if (auctionEntityDto.active) {
      let errorDataList = this.auctionValidationService.doValidationForActiveAuctionAndLots(auctionEntityDto);

      if (errorDataList.length > 0) {
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);

        this.auctionValidationErrorCodeDtoList = errorDataList;
        this.openErrorModel();
        return;
      }
    }

    this.adminService.saveAuctionDetails(auctionEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionSaveWrapperDto = apiResponseDto.data  as AuctionSaveWrapperDto;
          let auctionValidationErrorCodeDtoList =  auctionSaveWrapperDto.auctionValidationErrorCodeDtos;
          if(!auctionValidationErrorCodeDtoList ||  auctionValidationErrorCodeDtoList.length == 0){
            this._showSuccessToast$.next(true);
            this.isLoading = false;
            setTimeout(() => {
              this._isSaveButtonEnable$.next(true);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)
          }else{
            this.isLoading = false;
            this._isSaveButtonEnable$.next(true);
            this.closeModal();
            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
          }
        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving Auction. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })

  }

  openErrorModel() {
    let errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
    size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
    errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }


  get fc(): any { return this.formGroup.controls; }


  openauctionDetailModal(content: any) {
    this.populateIntialAuctionEntityDto();
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  changeRankConfig(){
    this.populateBidderHistoryConfig();
  }

  populateBidderHistoryConfig(){
    let rankCurrentPriceConfigValue  = this.formGroup.controls['rankCurrentPriceConfig'].value;
    if((rankCurrentPriceConfigValue == RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION) || (rankCurrentPriceConfigValue == RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION)){
      this.isAllowToShowBidderHistoryConfig = true ;
      this.formGroup.controls['showBidderHistory'].setValue(this.auctionEntityDto?.showBidderHistory);
    }else{
      this.isAllowToShowBidderHistoryConfig = false;
      this.formGroup.controls['showBidderHistory'].setValue("NEVER");
    }
    this.formGroup.controls['showBidderHistory'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  closeModal() {
    this.isSubmitted = false;
    this.ngbModal.dismissAll();
  }

  getTimeZone(dbTimeZone: string) {
    if(this.timezones &&  this.timezones.length > 0){
      let data = this.timezones.find((item) => item.locale == dbTimeZone);
      if(data){
        return data.fullName;
      }
    }
    return "";
  }

  getErrorMsg(error: AuctionValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.transalteService)
  }

  chooseFile(event: any) {
    this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.imageTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this._errorMsg$.next("Extension not supported");
      this._showErrorToast$.next(true);
      return;
    }

    this._fileDisplayName$.next(this.currentFile?.name as string);
    this._fileUploaded$.next(true);
    this.formGroup.get('auctionImage')?.setValue(this.currentFile?.name);
    let reader = new FileReader();
    reader.readAsDataURL(this.currentFile!);
    reader.onload = (e) => {
      let size = (this.currentFile?.size! / 1024) / 1024;

      // Create New Image
      var newImage = new Image();
      newImage.src = e.target!.result as string;

      newImage.onload = (el) => {
        this.imageType = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
        if (size > 1) {
          var canvas = document.createElement("canvas");
          canvas.width = newImage.width;
          canvas.height = newImage.height;

          var ctx = canvas.getContext("2d");
          ctx?.drawImage(el.target as CanvasImageSource, 0, 0, canvas.width, canvas.height);
          var srcEncoded;
          if (size >= 5) {
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.1);
          } else {
            // size less then 5 MB
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.5);
          }

          ApplicationUtils.base64toFile(srcEncoded, this.currentFile!.name, this.currentFile!.type)
            .then((file: File) => {
              this.currentFile = file;
            })
        }

        this.imageUrl = reader.result?.toString();
      }
    }
  }

  deleteImage() {
    this.currentFileInfoDto = null!;
    this.currentFile = null!;
    this.imageUrl = null!;
    this.formGroup.controls['auctionImage'].reset();
    this._fileDisplayName$.next(null);
    this._fileUploaded$.next(false);
  }

  activeImage() {
    this.isActiveImage = !this.isActiveImage;
  }

  getDay(date?: string) {
    if (date != null) {
      return ApplicationUtils.getDisplayDay(date, this.datePipe);
    }
    return '';
  }

  getDate(date?: string) {
    if (date != null) {
      return ApplicationUtils.getDateInMMMdd(date, this.datePipe);
    }
    return '';
  }

  getYear(date?: string) {
    if (date != null) {
      return ApplicationUtils.getDisplayYear(date);
    }
    return '';
  }

  getTime(time?: string) {
    if (time != null) {
      return ApplicationUtils.getDisplayTimeInHHmm(time, this.datePipe);
    }
    return '';
  }

  getMeridiem(time?: string) {
    if (time != null) {
      return ApplicationUtils.getDisplayMeridiem(time, this.datePipe);
    }
    return '';
  }

  getRemainingTime(item: AuctionLotEntityDto) {
    return ApplicationUtils.getRemainingTime(item.endDate + " " + item.endTime, this.auctionEntityDto?.timeZone!);
  }

  ngOnDestroy(): void {
    if (this.auctionTimeZoneSubscription$) {
      this.auctionTimeZoneSubscription$.unsubscribe();
    }
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
    if( this.deploymentConfigurationDtoSubscription$){
      this.deploymentConfigurationDtoSubscription$.unsubscribe();
    }
  }

  getDisplayDate() {
    return ApplicationUtils.getDisplayDateAdmin(this.auctionEntityDto?.startDate, this.auctionEntityDto?.startTime);
  }

  haveAuctionDetailsErrors() {
    return this.validationService.haveAuctionDetailsErrors(this.auctionEntityDto!);
  }

  updateEditingConfig() {
    this.allowEditingConfirmModalRef?.close();
    if (this.selectedEditingType == AuctionStatus.LIVE) {
      this.formGroup.controls['allowEditWhileRunning'].patchValue(this.selectedEditingConfig);
      this.formGroup.controls['allowEditWhileRunning'].updateValueAndValidity();
    }

    if (this.selectedEditingType == AuctionStatus.LIVE_WAIT) {
      this.formGroup.controls['allowEditWhileUpcoming'].patchValue(this.selectedEditingConfig);
      this.formGroup.controls['allowEditWhileUpcoming'].updateValueAndValidity();
    }
  }

  resetEditingConfig() {
    this.allowEditingConfirmModalRef?.close();
    if (this.selectedEditingType == AuctionStatus.LIVE) {
      this.formGroup.controls['allowEditWhileRunning'].patchValue(!this.selectedEditingConfig);
      this.formGroup.controls['allowEditWhileRunning'].updateValueAndValidity();
    }

    if (this.selectedEditingType == AuctionStatus.LIVE_WAIT) {
      this.formGroup.controls['allowEditWhileUpcoming'].patchValue(!this.selectedEditingConfig);
      this.formGroup.controls['allowEditWhileUpcoming'].updateValueAndValidity();
    }
  }

  openEditingConfirmModal(config: boolean, status: string) {
    this.selectedEditingConfig = config;
    this.selectedEditingType = status;

    if (this.auctionEntityDto && this.auctionEntityDto.active
      && (this.auctionEntityDto.status == AuctionStatus.LIVE || this.auctionEntityDto.status == AuctionStatus.LIVE_WAIT)) {
        this.allowEditingConfirmModalRef = this.ngbModal.open(this.allowEditingConfirmModalTemplate, {
          size: 'md', keyboard: false, backdrop: 'static'
        });
        return;
    }
  }

}
