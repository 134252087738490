import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { number } from 'echarts';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CatalogueCartWrapperDto } from 'src/app/shared/models/CatalogueCartWrapperDto';
import { CatalogueL1EntityDto } from 'src/app/shared/models/CatalogueL1EntityDto';
import { CatalogueL2EntityDto } from 'src/app/shared/models/CatalogueL2EntityDto';
import { CatalogueLineItemsEntityDto } from 'src/app/shared/models/CatalogueLineItemsEntityDto';
import { CatalogueRequisitionWrapper } from 'src/app/shared/models/CatalogueRequisitionWrapper';
import { Currency } from 'src/app/shared/models/Currency';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-catalogue-add-to-cart',
  templateUrl: './catalogue-add-to-cart.component.html',
  styleUrls: ['./catalogue-add-to-cart.component.sass']
})
export class CatalogueAddToCartComponent implements OnInit, OnDestroy {
  cartItems: CatalogueCartWrapperDto[] = []
  catalogueL1Dto?: CatalogueL1EntityDto;
  catalogueL2Dto?: CatalogueL2EntityDto;

  currency: Currency = Currency.default();
  totalAmount: number = 0
  totalTax: number = 0 
  isLoading: boolean = false

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);

  cartItemsSubscription$?: Subscription;
  catalogueL1Subscription$?: Subscription;
  catalogueL2Subscription$?: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private catalogueService: CatalogueService,
    private adminDashboardService: AdminDashboardService,
    private dataRedirectionService: DashboardRedirectionService
  ) {}

  ngOnInit(): void {
    this.cartItemsSubscription$ = this.catalogueService.getCartItems$.subscribe(data => {
      if (data && data.length > 0) {
        this.cartItems = data;
        this.refreshCartPrice();
      } else {
        this.cartItems = [];
        this.refreshCartPrice()
      }
    })

    this.catalogueL1Subscription$ = this.catalogueService.getSelectedCatalogueL1$.subscribe(data => {
      if (data) {
        this.catalogueL1Dto = data;
      } else {
        this.catalogueL1Dto = undefined;
      }
    })

    this.catalogueL2Subscription$ = this.catalogueService.getSelectedCatalogueL2$.subscribe(data => {
      if (data) {
        this.catalogueL2Dto = data;
      } else {
        this.catalogueL2Dto = undefined;
      }
    })
  }

  refreshCartPrice() {
    this.currency = this.cartItems.length > 0 ? this.cartItems[0].currency! : Currency.default();
    this.totalAmount = 0;
    this.totalTax = 0;

    this.cartItems.forEach(item => {
      let amount = item.catalogueVendor?.amount;
      let tax = item.catalogueVendor?.tax;
      
      this.totalAmount = this.totalAmount + (amount! * Number(item.quantity));
      if (tax) {
        this.totalTax = this.totalTax + ApplicationUtils.getPercentage(amount! * Number(item.quantity), tax);
      }
    })
  }

  updateCartItems(item: CatalogueLineItemsEntityDto, quantity: string) {
    this.catalogueService.addToCart(item, Number(quantity));
    this.refreshCartPrice();
  }

  getFormattedPrice(currency: Currency, price: number) {
    return ApplicationUtils.getFormattedPrice(currency.locale, price);
  }

  getSubtotalPriceOfItem(item: CatalogueCartWrapperDto, input: HTMLInputElement) {
    let subtotal = item.catalogueVendor?.amount! * Number(input.value);
    return item.currency?.symbol + this.getFormattedPrice(item.currency!, subtotal);
  }

  quantityItemPlus(item: CatalogueLineItemsEntityDto, input: HTMLInputElement) {
    input.value = (Number(input.value) + 1).toString();
    this.updateCartItems(item, input.value);
  }

  quantityItemMinus(item: CatalogueLineItemsEntityDto, input: HTMLInputElement) {
    if (Number(input.value) == 1) {
      return;
    }
    input.value = (Number(input.value) - 1).toString();
    this.updateCartItems(item, input.value);
  }

  clearCart() {
    this.catalogueService.clearCart();
  }
  
  closeModal() {
    this.ngbModal.dismissAll();
  }

  submitRequisition() {
    let catalogueRequisitionWrapper = new CatalogueRequisitionWrapper();
    catalogueRequisitionWrapper.id = UUID.UUID().toString();
    catalogueRequisitionWrapper.catalogueCartWrapperDtos = this.cartItems;
    catalogueRequisitionWrapper.categoryL1Id = this.catalogueL1Dto?.categoryL1Id;
    catalogueRequisitionWrapper.categoryL1Name = this.catalogueL1Dto?.name;
    catalogueRequisitionWrapper.categoryL2Id = this.catalogueL2Dto?.categoryL2Id;
    catalogueRequisitionWrapper.categoryL2Name = this.catalogueL2Dto?.name;
    catalogueRequisitionWrapper.isApproved = false;
    catalogueRequisitionWrapper.totalPrice = this.totalAmount + this.totalTax;
    catalogueRequisitionWrapper.totalTaxAmount = this.totalTax;

    this.catalogueService.submitRequisition(catalogueRequisitionWrapper);

    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false;
      this._showSuccessToast$.next(true);
      this.clearCart();
    }, 4000);

    setTimeout(() => {
      this._showSuccessToast$.next(false);
      this.closeModal();
    }, 6000);

    // this.dataRedirectionService.storeNavigationContext(
    //   ApplicationConstants.ADMIN,
    //   ApplicationConstants.ADMIN_CATALOGUE_REQUISITION_PAGE
    // );
    // this.dataRedirectionService.setUpdateBreadcrumbs(true);
  }
  
  ngOnDestroy(): void {
    if (this.cartItemsSubscription$) {
      this.cartItemsSubscription$.unsubscribe();
    }
    if (this.catalogueL1Subscription$) {
      this.catalogueL1Subscription$.unsubscribe();
    }
    if (this.catalogueL2Subscription$) {
      this.catalogueL2Subscription$.unsubscribe();
    }
  }

}
