import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
// import { LandingDashboardComponent } from './layouts/landing/landing-dashboard/landing-dashboard.component';
// import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { ElementsComponent } from './common/elements/elements.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthHttpInterceptor } from './shared/interceptors/basic-auth-http.interceptor';
import { environment } from 'src/environments/environment';
import { provideFirebaseApp, initializeApp, FirebaseApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics";
import { getPerformance, initializePerformance, providePerformance } from "@angular/fire/performance";
import { NgxEchartsModule } from 'ngx-echarts';
import { TimeAgoPipe } from './shared/pipes/time-ago.pipe';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarDateFormatter, CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AuctionCalendarDateFormatter } from "./shared/providers/AuctionCalendarDateFormatter";
import { icons, LucideAngularModule } from 'lucide-angular';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StoreModule } from '@ngrx/store';
import { sessionFeature } from './shared/state-management/session.features';
import { navigationFeature } from './shared/state-management/navigation/navigation.features';
import { onboardingFeature } from './shared/state-management/onboarding/onboarding.features';
import { tenderManagementFeature } from './shared/state-management/tender/tender.features';
import { OrdinalDatePipe } from './shared/pipes/ordinal-date.pipe';
import { Loader } from '@googlemaps/js-api-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PriceFormatPipe } from './shared/pipes/price-format.pipe';
import { TitleCasePipe } from './shared/pipes/title-case.pipe';

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    ElementsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    LucideAngularModule.pick(icons),
    StoreModule.forRoot({}),
    StoreModule.forFeature(sessionFeature),
    StoreModule.forFeature(navigationFeature),
    StoreModule.forFeature(onboardingFeature),
    StoreModule.forFeature(tenderManagementFeature),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthHttpInterceptor, multi: true,
    },
    NgbActiveModal, DecimalPipe, DatePipe, TimeAgoPipe, DialogService, DynamicDialogRef, OrdinalDatePipe, PriceFormatPipe,
    ConfirmationService, TitleCasePipe, MessageService,
    { provide: CalendarDateFormatter, useClass: AuctionCalendarDateFormatter },
    { provide: Loader, useValue: new Loader({ apiKey: 'AIzaSyAzL_OKX67nggXcJBEWzvYgSOK_1N4LnlM', libraries: ['places'] }) },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    firebaseApp: FirebaseApp
  ) {
    initializePerformance(firebaseApp);
  }
}
