<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 my-2 div-class d-flex align-items-center">
        <h2>Blockchain Details</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
          (click)="closeModal()" />
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3 class-overflow">
      <div>
        <span class="me-3 c-pointer selectedone" (click)="changeStatus(1)">
          Overview
        </span>
        <!-- <span class="c-pointer" [ngClass]="{'selectedone': selectedTab == 2}" (click)="changeStatus(2)">
          Logs {{transaction?.logs && transaction!.logs.length > 0 ? '('+transaction!.logs.length+')'
          : ''}}
        </span> -->
      </div>
      <hr class="hr mt-2" />
  
      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>
  
      <div *ngIf="!isLoading">
        <div class="text-red-500  my-2">
          [ This is a Polygon PoS Testnet transaction only ]
        </div>
        <hr class="hr my-2" />
        <div class="row">
          <div class="col-4 my-2 div-class">
            <span>
              <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" /></span>
            Transaction Hash:
          </div>
          <div class="col-8 my-2 div-class">
            {{ transaction?.hash }}
            <span>
              <img src="assets/icons/file_light.svg" class="c-pointer svg-icon-class ms-2"
                (click)="copyStringToClipboard(transaction?.hash)" /></span>
          </div>
          <div class="col-4 my-2 div-class">
            <span>
              <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" /></span>
            Status:
          </div>
          <div class="col-8 my-2 div-class">
            <span class="badge bg-success" *ngIf="transaction?.txreceipt_status == '1'"
              ngbTooltip="A Status code indicating if the top-level call succeeded or failed (applicable for Post BYZANTIUM blocks only)"
              placement="top" container="body">&check;
              Success</span>
            <span class="badge bg-danger" *ngIf="transaction?.txreceipt_status == '0'">Fail</span>
          </div>
          <div class="col-4 my-2 div-class">
            <span>
              <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" /></span>
            Block:
          </div>
          <div class="col-8 my-2 div-class">
            {{ hexToInt(transaction?.blockNumber) }}
          </div>
          <div class="col-4 my-2 div-class">
            <span>
              <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" /></span>
            Timestamp:
          </div>
          <div class="col-8 my-2 div-class">
            <span>
              <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" />
              {{ displayTimestamp() }}
            </span>
          </div>
        </div>
        <hr class="hr my-2" />
        <div class="row">
          <div class="col-4 my-2 div-class">
            <span>
              <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" /></span>
            From:
          </div>
          <div class="col-8 my-2 div-class">
            {{ transaction?.from }}
            <span>
              <img src="assets/icons/file_light.svg" class="c-pointer svg-icon-class ms-2"
                (click)="copyStringToClipboard(transaction?.from)" /></span>
          </div>
          <div class="col-4 my-2 div-class">
            <span>
              <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" /></span>
            To:
          </div>
          <div class="col-8 my-2 div-class">
            Contract {{ transaction?.to }}
            <span class="badge badge-pill bg-success rounded" ngbTooltip="Contract Execution Completed" placement="top"
              container="body">&check;</span>
            <span>
              <img src="assets/icons/file_light.svg" class="c-pointer svg-icon-class ms-2"
                (click)="copyStringToClipboard(transaction?.to)" /></span>
          </div>
        </div>
        <hr class="hr my-2" />
  
        <div class="row">
          <div class="col-4 my-2 div-class">
            <span>
              <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" /></span>
              Transaction Fee:
          </div>
          <div class="col-8 my-2 div-class"
            ngbTooltip="Gas Price * Gas Used by Transaction" placement="top"
            container="body">
            {{ getTransactionFees() }}
          </div>
        </div>
  
        <hr class="hr my-2" />
        <div class="row">
          <div class="col-4 my-2 div-class">
            <span>
              <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" /></span>
            Gas Price:
          </div>
          <div class="col-8 my-2 div-class"
            ngbTooltip="The price offered to the miner to purchase this amount of GAS (per GAS)" placement="top"
            container="body">
            {{ gasToMatic(hexToInt(transaction?.gasPrice)) }} ({{gasToGWei(hexToInt(transaction?.gasPrice))}})
          </div>
        </div>
          <div class="row">
            <div class="col-4 my-2 div-class">
              <span>
                <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" /></span>
              Gas Limit & Usage by Txn:
            </div>
            <div class="col-8 my-2 div-class">
              <span ngbTooltip="The amount of GAS supplied for this transaction to happen" placement="top"
                container="body">
                {{ formatPrice(hexToInt(transaction?.gas)) }}
              </span> |
              <span ngbTooltip="The amount of GAS used by this specific transaction alone" placement="top"
                container="body">
                {{ formatPrice(hexToInt(transaction?.gasUsed)) }} ({{getGasUsedPercent()}})
              </span>
            </div>
          </div>
            <hr class="hr my-2" />
            <div class="row">
            <div class="col-4 my-2 div-class">
              <span>
                <img src="assets/icons/info_light.svg" class="c-pointer svg-icon-class me-2" /></span>
              Input Data:
            </div>
            <div class="col-8 my-2 div-class"
              ngbTooltip="The blockchain input data ensures the integrity of the transaction and consists of 7 parameters separated by colons. The first parameter is the auction house ID, the second parameter is the Auction ID, the third one is the lot ID, the fourth one is the bidder's paddle number, the fifth one is the bid price, the sixth one is the bid time in seconds, and the seventh one is the type of bid (AUTO/MANUAL/PROXY BID). The eighth parameter is the hash of all these parameters."
              placement="top" container="body">
              <textarea class="form-control mt-3" style="white-space: normal;" placeholder="Data" rows="8" disabled>
                {{ transaction?.input }}
               </textarea>
            
          </div>
        </div>
      </div>
    </div>
  </div>

</div>