import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FinancialQuestionTemplate } from 'src/app/shared/models/questionnaire/FinancialQuestionTemplate';
import { CompFinancialQuestionWrapperDto } from 'src/app/shared/models/rfx/CompFinancialQuestionWrapperDto';
import { CompFinancialWrapperDto } from 'src/app/shared/models/rfx/CompFinancialWrapperDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { SubcategorySubmissionDto } from 'src/app/shared/models/rfx/SubcategorySubmissionDto';
import { UserRfxQuestionsUiDto } from 'src/app/shared/models/rfx/UserRfxQuestionsEntityDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { ToWords  } from 'to-words';

@Component({
  selector: 'app-rfx-evaluation-financial-questions',
  templateUrl: './rfx-evaluation-financial-questions.component.html',
  styleUrls: ['./rfx-evaluation-financial-questions.component.sass']
})
export class RfxEvaluationFinancialQuestionsComponent implements OnInit, OnDestroy {
  @Input() comFinancialWrapperDto?: CompFinancialWrapperDto;
  @Input() subcategorySubmissionDto?: SubcategorySubmissionDto;

  rfxEntityDto?: RfxUiDto;
  compFinancialQuestionWrapper?: CompFinancialQuestionWrapperDto;
  financialQuestionTemplates: FinancialQuestionTemplate[] = []
  userRfxQuestionsEntityDtos: UserRfxQuestionsUiDto[] = []

  selectedFinancialQuestion?: FinancialQuestionTemplate;
  selectedUserRfxQuestionsEntityDto?: UserRfxQuestionsUiDto;

  currentQuestionIndex: number = 0;
  isLoading: boolean = false;

  totalBidPrice: number = 0
  totalTaxAmount: number = 0
  totalAdditionalAmount: number = 0
  totalFinalAmount: number = 0

  ctrlQuestionText: FormControl = new FormControl('');
  ctrlQuestionDescription: FormControl = new FormControl('');
  ctrlBidderComments: FormControl = new FormControl('');

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  selectedRfxSubscription$?: Subscription;
  compFinancialQuestionWrapperSubscription$?: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private adminDashboardService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
  ) { }

  ngOnInit(): void {
    this.getCompFinancialQuestionWrapperDto();

    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(rfx => {
      if (rfx) {
        this.rfxEntityDto = rfx;
      }
    })

    this.compFinancialQuestionWrapperSubscription$ = this.adminDashboardService.getCompFinancialQuestionWrapperDto$.subscribe(data => {
      if (data) {
        this.compFinancialQuestionWrapper = data;
        this.populateQuestionData();
      } else {
        this.compFinancialQuestionWrapper = undefined;
      }
    })
  }

  populateQuestionData() {
    this.financialQuestionTemplates = this.compFinancialQuestionWrapper?.financialQuestionTemplates ?? [];
    this.financialQuestionTemplates = this.sortFinancialQuestions(this.financialQuestionTemplates);
    this.userRfxQuestionsEntityDtos = this.compFinancialQuestionWrapper?.userRfxQuestionsEntityDtos ?? [];

    if (!this.selectedFinancialQuestion) {
      this.selectedFinancialQuestion = this.financialQuestionTemplates[0];
    }

    this.populateCurrentQuestion();
  }

  populateCurrentQuestion() {
    this.selectedUserRfxQuestionsEntityDto = this.userRfxQuestionsEntityDtos.find(item => item.questionId == this.selectedFinancialQuestion?.questionId);

    this.ctrlQuestionText.patchValue(this.selectedFinancialQuestion?.questionText);
    this.ctrlQuestionDescription.patchValue(this.selectedFinancialQuestion?.questionDescription);
    this.ctrlBidderComments.patchValue(this.selectedUserRfxQuestionsEntityDto?.bidderComments);

    this.ctrlQuestionText.disable()
    this.ctrlQuestionDescription.disable();
    this.ctrlBidderComments.disable();

    this.totalBidPrice = Number(this.selectedUserRfxQuestionsEntityDto?.bidderResponse) * this.selectedUserRfxQuestionsEntityDto?.bidderQuantity!;
    this.totalTaxAmount = this.selectedUserRfxQuestionsEntityDto?.unitPriceQtyTaxTotal! - this.totalBidPrice;
    this.selectedUserRfxQuestionsEntityDto?.additionalPrices?.forEach(item => {
      this.totalAdditionalAmount += Number(item.additionalPrice);
    })
    this.totalFinalAmount = this.selectedUserRfxQuestionsEntityDto?.unitPriceQtyTaxAdditionalPricesTotal!;
  }

  getTotalFinalAmountInWords() {
    const toWords = new ToWords();
    return toWords.convert(this.totalFinalAmount);
  }

  async getCompFinancialQuestionWrapperDto() {
    this.isLoading = true;

    let rfxId = this.subcategorySubmissionDto?.rfxId!;
    let subcategoryId = this.subcategorySubmissionDto?.subcategoryId!;
    let companyId = this.comFinancialWrapperDto?.companyId!;

    await this.adminDashboardService.getAndLoadCompFinancialQuestionWrapperDtoSync(rfxId, subcategoryId, companyId);

    this.isLoading = false;
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }

  nextQuestion() {
    if (this.currentQuestionIndex < this.financialQuestionTemplates.length) {
      this.currentQuestionIndex++;
      this.selectedFinancialQuestion = this.financialQuestionTemplates[this.currentQuestionIndex];
      this.populateCurrentQuestion();
    }
  }

  prevQuestion() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
      this.selectedFinancialQuestion = this.financialQuestionTemplates[this.currentQuestionIndex];
      this.populateCurrentQuestion();
    }
  }

  isDisabledPrev() {
    return this.currentQuestionIndex == 0;
  }

  isDisabledNext() {
    return this.currentQuestionIndex == this.financialQuestionTemplates.length - 1;
  }

  getAmountInWords(amount: number) {
    const toWords = new ToWords();
    return toWords.convert(amount);
  }

  getFormattedPrice(price: any) {
    let currency = this.rfxEntityDto?.currency;
    return (currency?.symbol + ApplicationUtils.getFormattedPrice(currency?.locale, price));
  }

  sortFinancialQuestions(data: FinancialQuestionTemplate[]): FinancialQuestionTemplate[] {
    return data.sort((a, b) => {
      // Split the strings at the dot (.) to separate letter and number parts
      const [letterA, numberA] = `${a.preSequenceText}.${a.sequenceNo}`.split('.');
      const [letterB, numberB] = `${b.preSequenceText}.${b.sequenceNo}`.split('.');

      // Sort by letter (ascending)
      if (letterA < letterB) return -1;
      if (letterA > letterB) return 1;

      // If letters are the same, sort by number (ascending)
      return Number(numberA) - Number(numberB);
    });
  }

  ngOnDestroy(): void {
    if (this.compFinancialQuestionWrapperSubscription$) {
      this.compFinancialQuestionWrapperSubscription$.unsubscribe();
    }
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
  }
}
