import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textAvatar'
})
export class TextAvatarPipe implements PipeTransform {

  transform(fullName: string): string {
    const nameParts = fullName.trim().split(' ');

    // Get the first character of the first name
    const firstNameInitial = nameParts[0]?.charAt(0).toUpperCase() || '';

    // Get the first character of the last name
    const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() : '';

    return firstNameInitial + lastNameInitial;
  }

}
