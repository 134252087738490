import { AfterContentInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { FileService } from 'src/app/shared/services/file.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { AngularEditorComponent, AngularEditorConfig } from 'src/assets/plugins/angular-editor';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { AiDescriptionGeneratorComponent } from 'src/app/shared/components/ai-description-generator/ai-description-generator.component';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { AddLegalPolicyComponent } from '../components/add-legal-policy/add-legal-policy.component';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { Currency } from 'src/app/shared/models/Currency';
import { LanguageService } from 'src/app/shared/services/language.service';
import { OrgLanguageDto } from 'src/app/shared/models/OrgLanguageDto';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-onboarding-org-profile',
  templateUrl: './onboarding-org-profile.component.html',
  styleUrls: ['./onboarding-org-profile.component.sass']
})
export class OnboardingOrgProfileComponent implements OnInit, AfterContentInit {
  organizationUiDto?: OrganizationWrapperUiDto;
  isDataLoading: boolean = false;
  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  fileUploadError: boolean = false;
  errorMsg: string | undefined;
  currentFileLogo?: File;
  currentFileBanner?: File;
  imageTypeBanner?: string;
  imageTypeLogo?: string;
  isShowDescription: boolean = false;
  openPrivacyConfirmationDialog: boolean = false;
  currentIndex?: number;
  isDrawerOpen = false;

  selectedCountryCode = CountryCodeDto.default();

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  countryCode$ = new BehaviorSubject<CountryCodeDto | null>(null);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);

  logoFileName: string = '';
  bannerFileName: string = '';
  isBannerActive: boolean = false;
  isLogoActive: boolean = false;
  isPrivacyLoading: boolean = false;

  imageUrlLogo?: string | ArrayBuffer | SafeUrl | null;
  imageUrlBanner?: string | ArrayBuffer | SafeUrl | null;

  isLoading: boolean = false;
  isSubmitted: boolean = false;
  isUrlFilled: boolean = false;
  isEdit: boolean = false;
  selectedType?: string;
  isShowTermsOfUse$ = new BehaviorSubject<boolean>(false);
  isShowPrivacyPolicy$ = new BehaviorSubject<boolean>(false);
  openPrivacyModal?: DynamicDialogRef;


  @ViewChild('termsOfUseRef') termsOfUseRef?: ElementRef<HTMLParagraphElement>
  @ViewChild('privacyPolicyRef') privacyPolicyRef?: ElementRef<HTMLParagraphElement>
  @ViewChild("descriptionEditor") descriptionEditor?: AngularEditorComponent;
  @ViewChild('descriptionRef') descriptionRef?: ElementRef<HTMLParagraphElement>

  currencies: Currency[] = [];
  languages: OrgLanguageDto[] = [];

  constructor(
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private fileService: FileService,
    private onboardingService: OnboardingService,
    private drawerService: DrawerService,
    private currencyService: CurrencyService,
    private dialogService: DialogService,
    private languageService: LanguageService
  ) { }
 
  ngOnInit(): void {
    this.currencies = this.currencyService.getCurrencies;
    this.languages = this.languageService.getLanguages;

    this.loadOrganizationUiDto();
    this.onboardingService.loadOrganizationUiDto();

    this.onboardingService.getOrganizationUiDto$.subscribe(data => {
      if (data) {
        this.organizationUiDto = data;
      } else {
        this.organizationUiDto = undefined;
      }
    })
  }

  async loadOrganizationUiDto() {
    this.isDataLoading = true;
    await this.onboardingService.loadOrganizationUiDto();
    this.isDataLoading = false;
  }

  openModal(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openViewImageModal(imageUrl: any) {
    let modalRef = this.ngbModal.open(ImagePreviewComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.imageUrl = imageUrl
  }

  closeModal() {
    this.ngbModal.dismissAll()
    this.isSubmitted = false;
    this.bannerFileName = '';
    this.logoFileName = '';

  }

  getDomainName(url: string) {
    if (!url.includes(':4200')) {
      url = url.replace(/(^\w+:|^)\/\//, '');
      url = url.replace('www.', '')
    }
    return url;
  }

  showOrHideDescription() {
    this.isShowDescription = !this.isShowDescription;
    if (!this.isShowDescription) {
      this.descriptionRef!.nativeElement.style.height = '120px'
    } else {
      this.descriptionRef!.nativeElement.style.height = this.descriptionRef!.nativeElement.scrollHeight + 'px';
    }
  }

  toggleImageActive(type: string) {
    if (type == 'BANNER') {
      this.isBannerActive = !this.isBannerActive;
    } else {
      this.isLogoActive = !this.isLogoActive;
    }
  }

  openAiDescription() {
    this.ngbModal.open(AiDescriptionGeneratorComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    })
  }

  // legal

  openPrivacyAndTermsEditModal(id?: string) {
    this.openPrivacyModal = this.dialogService.open(AddLegalPolicyComponent, {
      header: 'Add Rfx',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data: {
        editMode: id != undefined,
        uniqueId: id
      }
     
    });
    // let modalRef = this.ngbModal.open(AddLegalPolicyComponent, {
    //   size: 'lg',
    //   backdrop: 'static',
    //   keyboard: false,
    //   centered: true
    // });

    
  }

  openConfirmationModel(type: string, index: number) {
    this.selectedType = type;
    this.currentIndex = index;
    this.openPrivacyConfirmationDialog = true

    // this.ngbModal.open(modelRef, {
    //   size: 'md', backdrop: 'static', keyboard: false, centered: true
    // })
  }

  showOrHideTermsOfUse() {
    this.isShowTermsOfUse$.next(!this.isShowTermsOfUse$.value)
    if (!this.isShowTermsOfUse$.value) {
      this.termsOfUseRef!.nativeElement.style.height = '120px'
    } else {
      this.termsOfUseRef!.nativeElement.style.height = this.termsOfUseRef!.nativeElement.scrollHeight + 'px';
    }
  }

  showOrHidePrivacyPolicy() {
    this.isShowPrivacyPolicy$.next(!this.isShowPrivacyPolicy$.value)
    if (!this.isShowPrivacyPolicy$.value) {
      this.privacyPolicyRef!.nativeElement.style.height = '120px'
    } else {
      this.privacyPolicyRef!.nativeElement.style.height = this.privacyPolicyRef!.nativeElement.scrollHeight + 'px';
    }
  }

  deletePrivacyAndTerms() {
    this.closeModal();
    this.isPrivacyLoading = true;

    let organizationUiDto: OrganizationWrapperUiDto = ApplicationUtils.clone(this.organizationUiDto);
    organizationUiDto.privacyAndTermsOfUseList!.splice(this.currentIndex!, 1);

    this.onboardingService.saveOrganizationUiDto(organizationUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isPrivacyLoading = false;
        } else {
          this.isPrivacyLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.isPrivacyLoading = false;
      }
    })
  }

  openNewProfileDrawer(tab?: string) {
    this.drawerService.isFormEditMode(true);
    this.drawerService.openDrawer('ONBOARDING_NEW_PROFILE', tab);
    this.isDrawerOpen = true;
  }

  ngAfterContentInit(): void {
    ApplicationUtils.clearTextFormatOnPaste('descriptionEditor');
  }

  ngOnDestroy(){
    if(this.openPrivacyModal){
      this.openPrivacyModal.close();
    }
  }
}

