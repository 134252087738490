import { Injectable } from '@angular/core';
import { OrganizationWrapperUiDto } from '../models/OrganizationWrapperUiDto';
import { ErrorService } from './error.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, firstValueFrom, tap } from 'rxjs';
import { ApplicationConstants } from '../util/ApplicationConstants';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { UserService } from './user.service';
import { UserUiDto } from '../models/user/UserUiDto';
import { CompanyUiDto } from '../models/CompanyUiDto';
import { PlantUiDto } from '../models/PlantUiDto';
import { OnboardingCountry } from '../models/OnboardingCountry';
import { OnboardingWorkflow } from '../models/OnboardingWorkflow';
import { AWFDefineUiDto } from '../models/approval-workflow/AWFDefineUiDto';
import { ApprovalWorkflowUiDto } from '../models/approval-workflow/ApprovalWorkflowUiDto';
import { OrgOrganogramUiDto } from '../models/OrgOrganogramUiDto';
import { PlantsBudgetWrapperUIDto } from '../models/PlantsBudgetWrapperUIDto';
import { UserExtendPrivilegeUiDto } from '../models/user/UserExtendPrivilegeUiDto';
import { TeamUiDto } from '../models/user/TeamUiDto';
import { Store } from '@ngrx/store';
import { OnboardingActions } from '../state-management/onboarding/onboarding.actions';
import { selectUserUiDto } from '../state-management/session.features';
import { ThemeService } from './theme.service';
import { UserInviteDto } from '../models/UserInviteDto';
import { UserInfoUiDto } from '../models/user/UserInfoUiDto';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private organizationUiDto?: OrganizationWrapperUiDto;
  private companyUiDtos: CompanyUiDto[] = [];
  private plantUiDtos: PlantUiDto[] = [];
  private userUiDtos: UserUiDto[] = [];
  private teamUiDtos: TeamUiDto[] = [];
  private userExtendPrivilegeUiDtos: UserExtendPrivilegeUiDto[] = [];
  private approvalWorkflowUiDtos: ApprovalWorkflowUiDto[] = [];
  private selectedUserUiDto?: UserUiDto;

  private _organizationWrapperUiDto$ = new BehaviorSubject<OrganizationWrapperUiDto | undefined>(undefined);
  private _plantUiDtos$ = new BehaviorSubject<PlantUiDto[]>([]);
  private _userUiDtos$ = new BehaviorSubject<UserUiDto[]>([]);
  private _teamUiDtos$ = new BehaviorSubject<TeamUiDto[]>([]);
  private _userExtendPrivilegeUiDtos$ = new BehaviorSubject<UserExtendPrivilegeUiDto[]>([]);
  private _companyUiDtos$ = new BehaviorSubject<CompanyUiDto[]>([]);
  private _selectedCompanyUiDto$ = new BehaviorSubject<CompanyUiDto | undefined>(undefined);
  private _selectedPlantUiDto$ = new BehaviorSubject<PlantUiDto | undefined>(undefined);
  private _selectedUserUiDto$ = new BehaviorSubject<UserUiDto | undefined>(undefined);
  private _approvalWorkflowUiDtos$ = new BehaviorSubject<ApprovalWorkflowUiDto[]>([]);
  private _plantsBudgetWrapperUIDto$ = new BehaviorSubject<PlantsBudgetWrapperUIDto | undefined>(undefined);

  private internalUserInfoUiDtos: UserInfoUiDto[] =[]
  private _internalUserInfoUiDtos$ = new BehaviorSubject<UserInfoUiDto[]>([]);

  private internalUserInfoUiDtosBasedOnPlant: UserInfoUiDto[] =[]
  private _internalUserInfoUiDtosBasedOnPlant$ = new BehaviorSubject<UserInfoUiDto[]>([]);

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private themeService: ThemeService,
    private store: Store
  ) { }


  get getOrganizationUiDto() { return this.organizationUiDto; }
  get getOrganizationUiDto$() { return this._organizationWrapperUiDto$.asObservable(); }
  get getCompanyUiDtos() { return this.companyUiDtos; }
  get getCompanyUiDtos$() { return this._companyUiDtos$.asObservable(); }
  get getSelectedCompanyUiDto$() { return this._selectedCompanyUiDto$.asObservable(); };
  get getPlantUiDtos() { return this.plantUiDtos; }
  get getPlantUiDtos$() { return this._plantUiDtos$.asObservable(); }
  get getSelectedPlantUiDto$() { return this._selectedPlantUiDto$.asObservable(); };
  get getUserUiDtos() { return this.userUiDtos; }
  get getUserUiDtos$() { return this._userUiDtos$.asObservable(); }
  get getTeamUiDtos$() { return this._teamUiDtos$.asObservable(); }
  get getSelectedUserUiDto$() { return this._selectedUserUiDto$.asObservable(); }
  get getApprovalWorkflowUiDtos$() { return this._approvalWorkflowUiDtos$.asObservable(); }
  get getPlantsBudgetWrapperUIDto$() { return this._plantsBudgetWrapperUIDto$.asObservable(); }
  get getOnboardingCountries() { return countries; };
  get getOnboardingWorkflows() { return workflows; };
  get getOnboardingCostCenters() { return costCenters; };
  get getUserExtendPrivileges() { return this.userExtendPrivilegeUiDtos; }
  get getUserExtendPrivileges$() { return this._userExtendPrivilegeUiDtos$.asObservable(); }
  get getSelectedUserEntityInfoDto() { return this.selectedUserUiDto; }
  get getInternalUserinfoUiDtos() { return this.internalUserInfoUiDtos; }
  get getInternalUserinfoUiDtos$() { return this._internalUserInfoUiDtos$.asObservable(); }

  get getInternalUserinfoUiDtosBasedOnPlant() { return this.internalUserInfoUiDtosBasedOnPlant; }
  get getInternalUserinfoUiDtosBasedOnPlant$() { return this._internalUserInfoUiDtosBasedOnPlant$.asObservable(); }



  getCompanyName(companyCode: string) {
    if (this.companyUiDtos && this.companyUiDtos.length > 0) {
      let filterCompany = this.companyUiDtos.filter(item => item.companyCode == companyCode);
      if (filterCompany) {
        return filterCompany[0].companyName;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  getOrgCode() {
    if (this.organizationUiDto) {
      return this.organizationUiDto.orgCode;
    } else {
      return "";
    }
  }

  updateOrganizationUiDto(organizationWrapperUiDto: OrganizationWrapperUiDto) {
    if (organizationWrapperUiDto) {
      this.organizationUiDto = organizationWrapperUiDto;
      this._organizationWrapperUiDto$.next(this.organizationUiDto);

      this.themeService.getThemeStructure(organizationWrapperUiDto);

      this.store.dispatch(OnboardingActions.updateOrganizationUiDto({ organizationWrapperUiDto }))
    }
  }

  updateOrgOrganogramUiDto(orgOrganogramUiDto: OrgOrganogramUiDto) {
    this.store.dispatch(OnboardingActions.saveOrgOrganogram({ orgOrganogramUiDto }))
  }

  setSelectedUserUiDto(userUiDto: UserUiDto) {
    this.selectedUserUiDto = userUiDto;
    this._selectedUserUiDto$.next(userUiDto);
  }

  updateCompanyUiDto(companyUiDto: CompanyUiDto) {
    let index = this.companyUiDtos.findIndex(item => item.companyCode == companyUiDto.companyCode);

    if (index != undefined && index > -1) {
      Object.assign(this.companyUiDtos[index], companyUiDto);
    } else {
      this.companyUiDtos.push(companyUiDto);
    }

    this._companyUiDtos$.next(this.companyUiDtos);
  }

  setCompanyUiDtos(companyUiDtos: CompanyUiDto[]) {
    this.companyUiDtos = companyUiDtos;
    this._companyUiDtos$.next(this.companyUiDtos);
  }

  setCurrentCompanyUiDto(companyUiDto?: CompanyUiDto) {
    this._selectedCompanyUiDto$.next(companyUiDto);
  }

  updatePlantUiDto(plantUiDto: PlantUiDto) {
    let index = this.plantUiDtos.findIndex(item => item.plantCode == plantUiDto.plantCode);

    if (index != undefined && index > -1) {
      Object.assign(this.plantUiDtos[index], plantUiDto);
    } else {
      this.plantUiDtos.push(plantUiDto);
    }

    this._plantUiDtos$.next(this.plantUiDtos);
  }

  setPlantUiDtos(plantUiDtos: PlantUiDto[]) {
    this.plantUiDtos = plantUiDtos;
    this._plantUiDtos$.next(this.plantUiDtos);
  }

  setCurrentPlantUiDto(plantUiDto?: PlantUiDto) {
    this._selectedPlantUiDto$.next(plantUiDto);
  }

  updateUserEntityInfoDto(userUiDto: UserUiDto) {
    let index = this.userUiDtos.findIndex(item => item.userId == userUiDto.userId);

    if (index != undefined && index > -1) {
      Object.assign(this.userUiDtos[index], userUiDto);
    } else {
      this.userUiDtos.push(userUiDto);
    }

    this._userUiDtos$.next(this.userUiDtos);
  }

  updateTeamUiDto(teamUiDto: TeamUiDto) {
    let index = this.teamUiDtos.findIndex(item => item.teamId == teamUiDto.teamId);

    if (index != undefined && index > -1) {
      Object.assign(this.teamUiDtos[index], teamUiDto);
    } else {
      this.teamUiDtos.push(teamUiDto);
    }

    this._teamUiDtos$.next(this.teamUiDtos);
  }


  updateUserExtendPrivilege(userExtendPrivilegeUiDto: UserExtendPrivilegeUiDto, isDelete: boolean = false) {
    let index = this.userExtendPrivilegeUiDtos.findIndex(item => (item.orgCode == userExtendPrivilegeUiDto.orgCode)
      && (item.companyCode == userExtendPrivilegeUiDto.companyCode)
      && (item.userId == userExtendPrivilegeUiDto.userId)
      && (item.designation == userExtendPrivilegeUiDto.designation));


    if (index != undefined && index > -1) {

      if (isDelete) {
        this.userExtendPrivilegeUiDtos.splice(index, 1);
      } else {
        Object.assign(this.userExtendPrivilegeUiDtos[index], userExtendPrivilegeUiDto);
      }

    } else {
      this.userExtendPrivilegeUiDtos.push(userExtendPrivilegeUiDto);
    }


    this._userExtendPrivilegeUiDtos$.next(this.userExtendPrivilegeUiDtos);
  }

  setUserEntityInfoDtos(userEntityInfoDtos: UserUiDto[]) {
    this.userUiDtos = userEntityInfoDtos;
    this._userUiDtos$.next(this.userUiDtos);
  }

  setInternalUserInfoDtos(infoDtos: UserInfoUiDto[]) {
    this.internalUserInfoUiDtos = infoDtos;
    this._internalUserInfoUiDtos$.next(this.internalUserInfoUiDtos);
  }


  setInternalUserInfoDtosBasedOnPlant(infoDtos: UserInfoUiDto[]) {
    this.internalUserInfoUiDtosBasedOnPlant = infoDtos;
    this._internalUserInfoUiDtosBasedOnPlant$.next(this.internalUserInfoUiDtosBasedOnPlant);
  }

  setTeamUiDtosList(teamUiDtos: TeamUiDto[]) {
    this.teamUiDtos = teamUiDtos;
    this._teamUiDtos$.next(this.teamUiDtos);
  }

  setUserExtendPrivileges(userExtendPrivilegeUiDto: UserExtendPrivilegeUiDto[]) {
    this.userExtendPrivilegeUiDtos = userExtendPrivilegeUiDto;
    this._userExtendPrivilegeUiDtos$.next(this.userExtendPrivilegeUiDtos);
  }

  setApprovalWorkflowsList(approvalWorkflowUiDtos: ApprovalWorkflowUiDto[]) {
    this.approvalWorkflowUiDtos = approvalWorkflowUiDtos;
    this._approvalWorkflowUiDtos$.next(this.approvalWorkflowUiDtos);
  }

  setPlantsBudgetWrapperUIDto(plantsBudgetWrapperUIDto: PlantsBudgetWrapperUIDto) {
    this._plantsBudgetWrapperUIDto$.next(plantsBudgetWrapperUIDto);
  }

  async loadOrganizationUiDto() {
    let userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));
    try {
      let apiResponseDto = await firstValueFrom(this.fetchOrganizationUiDto(userUiDto?.orgCode!));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.updateOrganizationUiDto(apiResponseDto.data! as OrganizationWrapperUiDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadOrganogramByOrgCode() {
    let userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));
    if (userUiDto) {
      try {
        let apiResponseDto = await firstValueFrom(this.getOrganogramByOrgCode(userUiDto.orgCode!));
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.updateOrgOrganogramUiDto(apiResponseDto.data!);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async loadCompanyUiDtosByOrgCode() {
    let userEntityDto = await firstValueFrom(this.store.select(selectUserUiDto));
    try {
      let apiResponseDto = await firstValueFrom(this.fetchCompanyUiDtosByOrgCode(userEntityDto?.orgCode!));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setCompanyUiDtos(apiResponseDto.data as CompanyUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadPlantUiDto(orgCode: string, companyCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPlantUiDto(orgCode, companyCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setPlantUiDtos(apiResponseDto.data as PlantUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadPlantUiDtosByOrgCode(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchPlantUiDtosByOrgCode(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setPlantUiDtos(apiResponseDto.data as PlantUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadOnboardingUsersList(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchOnboardingUsers(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setUserEntityInfoDtos(apiResponseDto.data as UserUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadInternalUsersList(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchInternalUsers(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setInternalUserInfoDtos(apiResponseDto.data as UserInfoUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadInternalUsersListBasedOnPlant(orgCode: string, companyCode: string, plantCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchInternalUsersBasedOnPlant(orgCode, companyCode, plantCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setInternalUserInfoDtosBasedOnPlant(apiResponseDto.data as UserInfoUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadOnboardingTeamsList(orgCode: string, companyCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getTeamUiDtos(orgCode, companyCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setTeamUiDtosList(apiResponseDto.data as TeamUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadUserExtendPrivilegeList(orgCode: string, userId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchUserExtendPrivilege(orgCode, userId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setUserExtendPrivileges(apiResponseDto.data as UserExtendPrivilegeUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadOnboardingApprovalWorkflowsList(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getApprovalWorkflowsList(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setApprovalWorkflowsList(apiResponseDto.data as ApprovalWorkflowUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadPlantsBudgetWrapperUIDto(orgCode: string, companyCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getPlantsBudgetWrapper(orgCode, companyCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setPlantsBudgetWrapperUIDto(apiResponseDto.data as PlantsBudgetWrapperUIDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // API CALLS
  fetchLandingOrganizationUiDto(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`landing/orgDetails`).pipe(
      tap(_ => console.log("Get landing organizations")),
      catchError(this.errorService.handleError<any>("Error while getting landing organizations")));
  }

  private fetchOrganizationUiDto(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`organizations/${orgCode}`).pipe(
      tap(_ => console.log("Get organizations")),
      catchError(this.errorService.handleError<any>("Error while getting organizations")));
  }

  private getOrganogramByOrgCode(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`organizations/organogram/${orgCode}`).pipe(
      tap(_ => console.log("Get organogram")),
      catchError(this.errorService.handleError<any>("Error while getting organogram")));
  }

  saveOrganizationUiDto(organizationUiDto: OrganizationWrapperUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`organizations`, organizationUiDto).pipe(
      tap(_ => console.log("Save organizations")),
      catchError(this.errorService.handleError<any>("Error while saving organizations")));
  }

  addCompanyandPlant(userInviteDto: UserInviteDto): Observable<any> {
    return this.http.post<any>('onboard-users-company-plants-mapping', userInviteDto).pipe(
      tap(_ => console.log("Called onboard-users-company-plants-mapping api successfully" + userInviteDto)),
      catchError(this.errorService.handleError<any>('Error while calling onboard-users-company-plants-mapping api' + userInviteDto)))
  }

  private fetchCompanyUiDtosByOrgCode(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`companies/${orgCode}`).pipe(
      tap(_ => console.log("Get companies")),
      catchError(this.errorService.handleError<any>("Error while getting companies")));
  }

  fetchCompanyUiDto(orgCode: string, companyCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`companies/${orgCode}/${companyCode}`).pipe(
      tap(_ => console.log("Get company by companyCode " + companyCode)),
      catchError(this.errorService.handleError<any>("Error while getting company by companyCode " + companyCode)));
  }

  saveCompanyUiDto(companyUiDto: CompanyUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`companies`, companyUiDto).pipe(
      tap(_ => console.log("Save companies")),
      catchError(this.errorService.handleError<any>("Error while saving companies")));
  }

  private fetchPlantUiDto(orgCode: string, companyCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`plants/${orgCode}/${companyCode}`).pipe(
      tap(_ => console.log("Get plants")),
      catchError(this.errorService.handleError<any>("Error while getting plants")));
  }

  private fetchPlantUiDtosByOrgCode(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`plants/${orgCode}`).pipe(
      tap(_ => console.log("Get plants")),
      catchError(this.errorService.handleError<any>("Error while getting plants")));
  }

  savePlantUiDto(plantUiDto: PlantUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`plants`, plantUiDto).pipe(
      tap(_ => console.log("Save plants")),
      catchError(this.errorService.handleError<any>("Error while saving plants")));
  }

  private fetchOnboardingUsers(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`internal-users/${orgCode}`).pipe(
      tap(_ => console.log("Get users")),
      catchError(this.errorService.handleError<any>("Error while getting users")));
  }

  private fetchInternalUsers(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`internal-users/internalUser/${orgCode}`).pipe(
      tap(_ => console.log("Get users")),
      catchError(this.errorService.handleError<any>("Error while getting users")));
  }


  private fetchInternalUsersBasedOnPlant(orgCode: string, companyCode: string, plantCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`internal-users/${orgCode}/${companyCode}/${plantCode}`).pipe(
      tap(_ => console.log("Get users")),
      catchError(this.errorService.handleError<any>("Error while getting users")));
  }

  private fetchUserExtendPrivilege(orgCode: string, userId: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`user-extend-privileges/${orgCode}/${userId}`).pipe(
      tap(_ => console.log("Get extend privileges")),
      catchError(this.errorService.handleError<any>("Error while getting extend privileges")));
  }

  saveUserExtendPrivilege(userExtendPrivilegeUiDto: UserExtendPrivilegeUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`user-extend-privileges`, userExtendPrivilegeUiDto).pipe(
      tap(_ => console.log("Save extend privilege")),
      catchError(this.errorService.handleError<any>("Error while saving extend privilege")));
  }

  deleteUserExtendPrivilege(orgCode: string, companyCode: string, userId: string, designation: string): Observable<ServerAPIResponseDto> {
    return this.http.delete<ServerAPIResponseDto>(`user-extend-privileges/${orgCode}/${companyCode}/${userId}/${designation}`).pipe(
      tap(_ => console.log("delete extend privilege")),
      catchError(this.errorService.handleError<any>("Error while delete extend privilege")));
  }

  saveUserUiDto(userEntityInfoDto: UserUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`internal-users`, userEntityInfoDto).pipe(
      tap(_ => console.log("Save internal-users")),
      catchError(this.errorService.handleError<any>("Error while saving internal-users")));
  }

  getTeamUiDtos(orgCode: string, companyCode: string) {
    return this.http.get<ServerAPIResponseDto>(`teams/${orgCode}/${companyCode}`).pipe(
      tap(_ => console.log("Get teams")),
      catchError(this.errorService.handleError<any>("Error while getting teams")));
  }

  saveTeamUiDto(teamUiDto: TeamUiDto) {
    return this.http.post<ServerAPIResponseDto>(`teams`, teamUiDto).pipe(
      tap(_ => console.log("Save teams")),
      catchError(this.errorService.handleError<any>("Error while saving teams")));
  }

  getApprovalDefinedUsers(awfDefineUiDto: AWFDefineUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`approval-workflows/preview`, awfDefineUiDto).pipe(
      tap(_ => console.log("Get awfDefineUiDto")),
      catchError(this.errorService.handleError<any>("Error while getting awfDefineUiDto")));
  }

  saveAppliedApprovalWorkflow(approvalWorkflowUiDto: ApprovalWorkflowUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`approval-workflows/applied`, approvalWorkflowUiDto).pipe(
      tap(_ => console.log("Save approvalWorkflowUiDto")),
      catchError(this.errorService.handleError<any>("Error while saving approvalWorkflowUiDto")));
  }

  getApprovalWorkflowsList(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`approval-workflows/configured/${orgCode}`).pipe(
      tap(_ => console.log("Get approvalWorkflowsList")),
      catchError(this.errorService.handleError<any>("Error while getting approvalWorkflowsList")));
  }

  // budgets
  getPlantsBudgetWrapper(orgCode: string, companyCode: string): Observable<ServerAPIResponseDto> {
    let params = new HttpParams().set('orgCode', orgCode).set('companyCode', companyCode);
    return this.http.get<ServerAPIResponseDto>(`plantsBudgetWrapper`, { params: params }).pipe(
      tap(_ => console.log("Get plantsBudgetWrapper")),
      catchError(this.errorService.handleError<any>("Error while getting plantsBudgetWrapper")));
  }

  saveCompanyPlantsBudget(plantsBudgetWrapperUIDto: PlantsBudgetWrapperUIDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`manage-plant-budget`, plantsBudgetWrapperUIDto).pipe(
      tap(_ => console.log("Saved manage-plant-budget")),
      catchError(this.errorService.handleError<any>("Error while saving manage-plant-budget")));
  }
}

const countries: OnboardingCountry[] = [
  {
    name: "Australia",
    taxOne: 'Australian Business Number (ABN)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Brazil",
    taxOne: 'Cadastro Nacional da Pessoa Jurídica (CNPJ)',
    taxOneLengthMin: 14,
    taxOneLengthMax: 14,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Argentina",
    taxOne: 'Clave Única de Identificación Tributaria (CUIT)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Bahrain",
    taxOne: 'Commercial Registration Number (CR)',
    taxOneLengthMin: 3,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Canada",
    taxOne: 'Business Number (BN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: 'GST/HST Number',
    taxTwoLengthMin: 15,
    taxTwoLengthMax: 15,
    taxTwoType: 'Alphanumeric'
  },
  {
    name: "France",
    taxOne: 'Numéro SIRET (Système d\'Identification du Répertoire des Etablissements)',
    taxOneLengthMin: 14,
    taxOneLengthMax: 14,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Germany",
    taxOne: 'Umsatzsteuer-Identifikationsnummer (USt-IdNr)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Ghana",
    taxOne: 'Taxpayer Identification Number (TIN)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "India",
    taxOne: 'Permanent Account Number (PAN)',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Alphanumeric',
    taxTwo: 'GSTIN',
    taxTwoLengthMin: 15,
    taxTwoLengthMax: 15,
    taxTwoType: 'Alphanumeric'
  },
  {
    name: "Indonesia",
    taxOne: 'Nomor Pokok Wajib Pajak (NPWP)',
    taxOneLengthMin: 15,
    taxOneLengthMax: 15,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Ireland",
    taxOne: 'VAT Registration Number',
    taxOneLengthMin: 8,
    taxOneLengthMax: 9,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Kenya",
    taxOne: 'Personal Identification Number (PIN)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Kuwait",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 6,
    taxOneLengthMax: 8,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Malaysia",
    taxOne: 'Business Registration Number (BRN)',
    taxOneLengthMin: 8,
    taxOneLengthMax: 12,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Netherlands",
    taxOne: 'BTW-nummer (VAT Number)',
    taxOneLengthMin: 14,
    taxOneLengthMax: 14,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Nigeria",
    taxOne: 'Taxpayer Identification Number (TIN)',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Oman",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 3,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Philippines",
    taxOne: 'Tax Identification Number (TIN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Qatar",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Saudi Arabia",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: 'VAT Registration Number',
    taxTwoLengthMin: 10,
    taxTwoLengthMax: 10,
    taxTwoType: 'Numeric'
  },
  {
    name: "Singapore",
    taxOne: 'Unique Entity Number (UEN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 10,
    taxOneType: 'Alphanumeric',
    taxTwo: 'GST Registration Number (GRN)',
    taxTwoLengthMin: 10,
    taxTwoLengthMax: 10,
    taxTwoType: 'Alphanumeric'
  },
  {
    name: "South Africa",
    taxOne: 'Value-Added Tax (VAT) Number',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Spain",
    taxOne: 'Número de Identificación Fiscal (NIF)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 11,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Thailand",
    taxOne: 'Tax Identification Number (TIN)',
    taxOneLengthMin: 13,
    taxOneLengthMax: 13,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "United Arab Emirates",
    taxOne: 'Tax Registration Number (TRN)',
    taxOneLengthMin: 15,
    taxOneLengthMax: 15,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "United Kingdom",
    taxOne: 'Company Unique Taxpayer Reference (UTR)',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "United States",
    taxOne: 'Employer Identification Number (EIN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  }
];

const workflows: OnboardingWorkflow[] = [
  {
    name: "Approval of PR",
    budgetDependence: false,
    code: "APPROVAL_PR"
  },
  {
    name: "Creation of PQ",
    budgetDependence: false,
    code: "CREATE_PQ"
  },
  {
    name: "Creation of RFQ",
    budgetDependence: false,
    code: "CREATE_RFQ"
  },
  {
    name: "Creation of Auction",
    budgetDependence: false,
    code: "CREATE_AUCTION"
  },
  {
    name: "Opening - Technical of PQ",
    budgetDependence: false,
    code: "TECHNICAL_OPENING_PQ"
  },
  {
    name: "Opening - Technical of RFQ",
    budgetDependence: false,
    code: "TECHNICAL_OPENING_RFQ"
  },
  {
    name: "Opening - Technical of Auction",
    budgetDependence: false,
    code: "TECHNICAL_OPENING_AUCTION"
  },
  {
    name: "Opening of Comparison Sheet of RFQ",
    budgetDependence: false,
    code: "CS_OPENING_RFQ"
  },
  {
    name: "Opening of Comparison Sheet of Auction",
    budgetDependence: false,
    code: "CS_OPENING_AUCTION"
  },
  {
    name: "Creation of PO from Catalogue",
    budgetDependence: true,
    code: "CREATE_PO_CATALOGUE"
  },
  {
    name: "Creation of PO from RFQ",
    budgetDependence: true,
    code: "CREATE_PO_RFQ"
  },
  {
    name: "Creation of PO from Auction",
    budgetDependence: true,
    code: "CREATE_PO_AUCTION"
  },
  {
    name: "Acceptance of Invoice",
    budgetDependence: true,
    code: "ACCEPT_INVOICE"
  },
  {
    name: "Forwarding Invoice to Account Payable",
    budgetDependence: true,
    code: "FW_INVOICE_ACC_PAYABLE"
  }
];

const costCenters = [
  { name: "Administrative", code: "ADMIN" },
  { name: "Human Resources", code: "HR" },
  { name: "Finance", code: "FIN" },
  { name: "Information Technology (IT)", code: "IT" },
  { name: "Sales and Marketing", code: "SALES" },
  { name: "Production", code: "PROD" },
  { name: "Logistics", code: "LOG" },
  { name: "Facilities", code: "FAC" },
  { name: "Procurement", code: "PROC" },
  { name: "Customer Support", code: "CS" },
  { name: "Training and Development", code: "TRNDEV" },
  { name: "Compliance and Audit", code: "COMP" },
  { name: "Health and Safety", code: "HSE" },
  { name: "Research and Development", code: "RND" },
  { name: "Legal", code: "LEGAL" },
  { name: "Marketing", code: "MKTG" },
  { name: "Corporate Social Responsibility (CSR)", code: "CSR" },
  { name: "Project Management", code: "PM" },
  { name: "Quality Management", code: "QM" }
];

