import { ApprovalWorkflowEnum } from "../../enums/ApprovalWorkflowEnum";
import { ApprovalWorkflowStatusEnum } from "../../enums/ApprovalWorkflowStatusEnum";
import { EventEnum } from "../../enums/EventEnum";
import { UserInfoUiDto } from "../user/UserInfoUiDto";

export class AWFSnapshotUiDto {
  orgCode?: string;
  snapshotId?: string;
  approvalId?: string;
  userId?: string;
  userInfoUiDto?: UserInfoUiDto;
  emailId?: string;
  resourceId?: string;
  subResourceId?: string;
  resourceType?: EventEnum;
  comments?: string;
  status?: ApprovalWorkflowStatusEnum;
  wfCode?: ApprovalWorkflowEnum;
  sequence?: number;
  updateDateTime?: string;
  version?: number;
}
