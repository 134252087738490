<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>{{lotEntityDto?.lotSequence}}# {{lotEntityDto?.title}} <span *ngIf="!lotEntityDto?.lotId">(NEW)</span></h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="p-3">
      <form class="needs-validation" novalidate [formGroup]="formGroup">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="title" placeholder="Title" formControlName="title">
          <label for="title">Title</label>
          <div *ngIf="fc.title.invalid && (fc.title.dirty || fc.title.touched)">
            <p class="text-red-500 " *ngIf="fc.title.errors?.required">Required</p>
          </div>
        </div>

        <div class="mb-3">
          <label for="descriptionEditor" class="text-area-label">Description
          </label>
          <div class="form-group">
            <angular-editor #descriptionEditor id="descriptionEditor" formControlName="description" class="form-control"
              [config]="config"></angular-editor>
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-3">
            <div class="input-group">
              <span class="border  border-end-0 pt-3 px-1">
                <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
              </span>
              <div class="form-floating ">

                <input type="text" ngbDatepicker [minDate]="minDate" #endDate="ngbDatepicker" id="datePicker" (click)="endDate.toggle()"
                [ngClass]="{'border-danger': fc.endDate.value && haveAnyErrorInEndDate()}"
                  class="form-control border-start-0" placeholder="End Date" formControlName="endDate">
                <label for="datePicker" class="">End Date</label>
              </div>
            </div>
            <div *ngIf="fc.endDate.invalid && (fc.endDate.dirty || fc.endDate.touched)">
              <p class="text-red-500 " *ngIf="fc.endDate.errors?.required">Required</p>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div class=" input-group">
              <span class="border  border-end-0 pt-3 px-1">
                <img src="assets/icons/time_light.svg" class="svg-icon-class c-pointer">
              </span>
              <div class="form-floating">

                <input type="text" ngbPopover [autoClose]="'outside'" triggers="manual" #p1="ngbPopover"
                [ngClass]="{'border-danger': fc.endDate.value && haveAnyErrorInEndDate()}"
                  (click)="openTimepicker(p1, endTimeModal)" class="form-control border-start-0" placeholder="End Time"
                  formControlName="endTime" id="endTime">
                <label for="endTime" class="">End Time</label>
              </div>
            </div>

            <div *ngIf="fc.endTime.invalid && (fc.endTime.dirty || fc.endTime.touched)">
              <p class="text-red-500 " *ngIf="fc.endTime.errors?.required">Required</p>
            </div>
          </div>
        </div>
        
        <app-categories-tree-view [selectionMode]="TreeSelectionMode.single"
            (onSelectedCategory)="onSelectedCategory($event)"></app-categories-tree-view>

        <!-- HashTags -->
        <div class="mb-3">
          <label for="hashtags" class="text-area-label">HashTags</label>
          <div class="form-group">
            <tag-input formControlName="hashtags" id="hashtags" [modelAsStrings]="true" [placeholder]="inputTagPlaceholder"
              [secondaryPlaceholder]="inputTagPlaceholder" [separatorKeyCodes]="separatorKeyCodes"></tag-input>
          </div>
        </div>

        <!-- Highlights -->
        <div class="mb-3">
          <label for="highlights" class="text-area-label">Highlights</label>
          <div class="form-group">
            <textarea #propertyHighlightEditor formControlName="highlights" id="highlights"
             class="form-control" rows="2"></textarea>
          </div>
        </div>

        <!-- Pickup & Inspection -->
        <textarea class="form-control mt-3" formControlName="pickup" placeholder="pickup"></textarea>
        <textarea class="form-control mt-3" formControlName="inspection" placeholder="inspection"></textarea>

        <!-- Lot Features -->
        <div class="my-3">
          <div class="row">
            <div class="col-6">
              <h5 class="heading-h5">LOT FEATURES</h5>
            </div>
            <div class="col-6 text-end">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 " (click)="openAddLotFeature(addLotFeatureModal)">
                ADD
              </button>
            </div>
          </div>
          <hr class="hr mt-2" />
          <div class="col-12 my-3 text-center"
            *ngIf="!lotEntityDto?.lotFeatures || lotEntityDto?.lotFeatures!.length == 0">
            <b>Features not added yet.</b>
          </div>

          <div class="row p-2" *ngFor="let lotFeature of lotEntityDto?.lotFeatures; index as i">
            <div class="col-md-10">
              <div class="btn-group mb-3">
                <div class="btn-new-landing float-start" container="body" placement="top"
                  ngbTooltip="{{lotFeature?.featureKey}}"
                  [ngClass]="{'overflow-class-small': lotFeature?.featureKey!.length > 30}">{{lotFeature?.featureKey}}
                </div>
                <div class="btn-new-landing-2 me-2  float-start" ngbTooltip="{{lotFeature?.featureValue}}"
                  container="body" placement="top"
                  [ngClass]="{'overflow-class-small': lotFeature?.featureValue!.length > 30}">
                  {{lotFeature?.featureValue}}
                </div>
              </div>
            </div>
            <div class="col-md-2 text-end">
              <img src="assets/icons/delete_danger.svg" (click)="removeLotFeature(i)" class="svg-icon-class c-pointer">
            </div>
            <hr class="hr" *ngIf="i != (lotEntityDto?.lotFeatures)!.length - 1" />
          </div>
        </div>

        <!-- Lot Images -->
        <div class="my-3">
          <div class="row">
            <div class="col-6">
              <h5 class="heading-h5">LOT IMAGES</h5>
            </div>
            <div class="col-6 text-end">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 " (click)="openAddImageModal(addImageModal)">
                ADD
              </button>
            </div>
          </div>

          <hr class="hr mt-2" />
          <div class="col-12 my-3 text-center" [ngClass]="{'border border-danger': isSubmitted}"
            *ngIf="!lotEntityDto?.lotImages || lotEntityDto?.lotImages!.length == 0">
            <b>No image uploaded.</b>
          </div>
          <div class="row p-2" *ngFor="let item of lotEntityDto?.lotImages; index as i">
            <div class="col-10 " #lotImagesListRef>
              <img [src]="getImageUrl(item)" class="creator-image-class" alt="image">
            </div>
            <div class="col-2  text-end mt-3" *ngIf="isAllowToDeleteLastImage(lotEntityDto!)">
              <img src="assets/icons/delete_danger.svg" (click)="deleteImage(i)" class="svg-icon-class c-pointer">
            </div>
            <hr class="hr  mt-2" *ngIf="i != (lotEntityDto?.lotImages)!.length - 1" />
          </div>
        </div>


        <div class="col-12 mt-3 mb-2" *ngIf="showReservedPrice()">
          <div class="row">
            <div class="col-md-6 mb-2">
              Publish Reserve Price
            </div>
            <div class="col-md-6 mb-2">
              <span class="me-2">
                <input type="radio" [value]="true" formControlName="publishReservePrice" id="publishReservePrice1">
                <label for="publishReservePrice1">Yes</label>
              </span>
              <span>
                <input type="radio" [value]="false" formControlName="publishReservePrice" id="publishReservePrice2">
                <label for="publishReservePrice2">No</label>
              </span>
            </div>
          </div>
        </div>

        <!-- Single Bid Increment (Radio Button) -->
        <div class="col-12 mt-3 mb-2" *ngIf="this.multipleBidIncrementDecrementAllowed">
          <div class="row">
            <div class="col-md-6 mb-2">
              Single Bid Increment
            </div>
            <div class="col-md-6 mb-2">
              <span class="me-2">
                <input type="radio" [value]="true" [formControl]="radioBidIncrement" id="radioBidIncrement1">
                <label for="radioBidIncrement1">Yes</label>
              </span>
              <span>
                <input type="radio" [value]="false" [formControl]="radioBidIncrement" id="radioBidIncrement2">
                <label for="radioBidIncrement2">No</label>
              </span>
            </div>
          </div>
        </div>

        <div class="input-group mt-3">
          <span class="border border-end-0 pt-4 px-1 fa-lg">
            {{auctionEntityDto?.currency?.symbol}}
          </span>
          <div class="form-floating">
            <input name="bucketSize" type="text" id="startingBid" placeholder="Starting Bid" formControlName="startingBid"
              (blur)="formatStartingBid()" class="form-control border-start-0" />
            <label for="startingBid">Starting Bid</label>
          </div>
        </div>
        <div *ngIf=" fc.startingBid.invalid && (fc.startingBid.dirty || fc.startingBid.touched)">
          <p class="text-red-500 " *ngIf="fc.startingBid.errors?.required">Required</p>
          <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.startingBid)">Invalid</p>
        </div>


        <div class="input-group mt-3">
          <span class="border border-end-0 pt-4 px-1 fa-lg">
            {{auctionEntityDto?.currency?.symbol}}
          </span>
          <div class="form-floating">
            <input name="bucketSize" type="text" id="reservePrice" (blur)="formatReserveBid()" placeholder="Reserve Price"
              formControlName="reservePrice" class="form-control border-start-0" />
            <label for="reservePrice">Reserve Price</label>
          </div>
        </div>
        <div *ngIf="fc.reservePrice.invalid && (fc.reservePrice.dirty || fc.reservePrice.touched)">
          <p class="text-red-500 " *ngIf="fc.reservePrice.errors?.required">Required</p>
          <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.reservePrice)">Invalid</p>
        </div>


        <div class="row">
          <div class="col-md-6">
            <div class="input-group mt-3">
              <span class="border border-end-0 pt-4 px-1 fa-lg">
                {{auctionEntityDto?.currency?.symbol}}
              </span>
              <div class="form-floating">
                <input type="text" (blur)="formatEstStartingPrice()" placeholder="Est. Starting Price"
                  formControlName="estStartingPrice" class="form-control border-start-0" id="estStartingPrice" />
                <label for="estStartingPrice">Est. Starting Price</label>
              </div>
            </div>
            <div
              *ngIf="isSubmitted || fc.estStartingPrice.invalid && (fc.estStartingPrice.dirty || fc.estStartingPrice.touched)">
              <p class="text-red-500 " *ngIf="fc.estStartingPrice.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.estStartingPrice)">Invalid</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group mt-3">
              <span class="border border-end-0 pt-4 px-1 fa-lg">
                {{auctionEntityDto?.currency?.symbol}}
              </span>
              <div class="form-floating">
                <input type="text" (blur)="formatEstEndingPrice()" placeholder="Est Starting Price"
                  formControlName="estEndingPrice" id="estEndingPrice" class="form-control border-start-0" />
                <label for="estEndingPrice">Est. Ending Price</label>
              </div>
            </div>
            <div
              *ngIf="isSubmitted || fc.estEndingPrice.invalid && (fc.estEndingPrice.dirty || fc.estEndingPrice.touched)">
              <p class="text-red-500 " *ngIf="fc.estEndingPrice.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.estEndingPrice)">Invalid</p>
            </div>
          </div>

        </div>

        <div class="row" *ngIf="isShowWarnAndBlock">
          <div class="col-12 text-start mt-3">
            <span class="span-class me-2">Block if bid changes({{isReverseAuction()? 'lower' : 'greater'}} than)
            </span>
            <span class="form-switch c-pointer">
              <input type="checkbox" id="blockBid" formControlName="blockBid" (change)="enableAndDisableBlockBid()" /></span>
          </div>
          <div class="col-12" *ngIf="fc.blockBid.value">
            <div class="input-group mt-3">
              <span class="border border-end-0 pt-4 px-1 fa-lg">
                {{auctionEntityDto?.currency?.symbol}}
              </span>
              <div class="form-floating">
                <input name="blockBidValue" type="text" id="blockBidValue" placeholder="Block Bid Value" formControlName="blockBidValue"
                  (blur)="formatWarningBidValue()" class="form-control border-start-0" />
                <label for="blockBidValue">Block Bid Value</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.blockBidValue.invalid && (fc.blockBidValue.dirty || fc.blockBidValue.touched)">
              <p class="text-red-500 " *ngIf="fc.blockBidValue.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.blockBidValue)">Invalid</p>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isShowWarnAndBlock">
          <div class="col-12 text-start mt-3">
            <span class="span-class me-2">Warn if bid changes({{isReverseAuction()? 'lower' : 'greater'}} than)
            </span>
            <span class="form-switch c-pointer">
              <input type="checkbox" id="warnBid" formControlName="warnBid" (change)="enableAndDisableWarnBid()" /></span>
          </div>
          <div class="col-12" *ngIf="fc.warnBid.value">
            <div class="input-group mt-3">
              <span class="border border-end-0 pt-4 px-1 fa-lg">
                {{auctionEntityDto?.currency?.symbol}}
              </span>
              <div class="form-floating">
                <input name="warnBidValue" type="text" id="warnBidValue" placeholder="Warning Bid Value" formControlName="warnBidValue"
                  (blur)="formatWarningBidValue()" class="form-control border-start-0" />
                <label for="warnBidValue">Warning Bid Value</label>
              </div>
            </div>
            <div *ngIf="isSubmitted || fc.warnBidValue.invalid && (fc.warnBidValue.dirty || fc.warnBidValue.touched)">
              <p class="text-red-500 " *ngIf="fc.warnBidValue.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.warnBidValue)">Invalid</p>
            </div>
          </div>
        </div>

        <div class="input-group mt-3" *ngIf="radioBidIncrement.value">
          <span class="border border-end-0 pt-4 px-1 fa-lg">
            {{auctionEntityDto?.currency?.symbol}}
          </span>
          <div class="form-floating">
            <input name="bucketSize" type="text" placeholder="Bid Increment" [formControl]="singleBidIncrement" id="singleBidIncrement"
              (blur)="formatSingleIncrement()" class="form-control border-start-0" />
              <label for="singleBidIncrement" *ngIf="!reverseAuction" >Bid Increment</label>
              <label for="singleBidDecrement" *ngIf="reverseAuction" >Bid Decrement</label>
          </div>
        </div>
        <div
          *ngIf="radioBidIncrement.value && (singleBidIncrement.invalid && (singleBidIncrement.dirty || singleBidIncrement.touched))">
          <p class="text-red-500 " *ngIf="singleBidIncrement.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="invalidPriceValidator(singleBidIncrement)">Invalid</p>
        </div>

        <div class="my-3" *ngIf="!radioBidIncrement.value">
          <div class="row">
            <div class="col-6">
              <label for="bidIncrement" >Bid Increment</label>
            </div>
            <div class="col-6 text-end">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 " (click)="addBidIncrementConfig()"
                [disabled]="infinityBidIncrement.disabled">
                ADD
              </button>
            </div>
          </div>

          <hr class="hr mt-2" />
          <div formArrayName="bidIncrementalDtoList">
            <div class="row" *ngFor="let item of incrementalConfig.controls; index as i" [formGroupName]="i">
              <!-- UP TO AMOUNT -->
              <div class="col-5">
                <div class="mt-3 input-group">
                  <span class="border  border-end-0 pt-3 px-2">
                    {{auctionEntityDto?.currency?.symbol}}
                  </span>
                  <div class="form-floating ">

                    <input type="text" class="form-control border-start-0" placeholder="Up To Amount"
                      formControlName="upToAmount" id="upToAmount" (blur)="formatIncrementUpToValue(i)">
                    <label for="upToAmount">Up To Amount</label>
                  </div>
                </div>
                <div
                  *ngIf="item.get('upToAmount')?.invalid && (item.get('upToAmount')?.dirty || item.get('upToAmount')?.touched)">
                  <p class="text-red-500 " *ngIf="item.get('upToAmount')?.errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="invalidPriceValidator(item.get('upToAmount'))">Invalid</p>
                </div>
              </div>
              <!-- BID INCREMENT VALUE -->
              <div class="col-5">
                <div class="mt-3 input-group">
                  <span class="border  border-end-0 pt-4 px-2">
                    {{auctionEntityDto?.currency?.symbol}}
                  </span>

                  <div class="form-floating ">

                    <input type="text" class="form-control border-start-0" placeholder="Bid Increment"
                      formControlName="incrementValue" id="incrementValue" (blur)="formatIncrementValue(i)">
                    <label for="incrementValue">Bid Increment</label>
                  </div>
                </div>
                <div
                  *ngIf="item.get('incrementValue')?.invalid && (item.get('incrementValue')?.dirty || item.get('incrementValue')?.touched)">
                  <p class="text-red-500 " *ngIf="item.get('incrementValue')?.errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="invalidPriceValidator(item.get('incrementValue'))">Invalid</p>
                </div>
              </div>
              <div class="col-2 mt-4">
                <span (click)="removeBidIncrementConfig(i)" *ngIf="!item.disabled"
                  [ngClass]="{'disabled' : isLoading }">
                  <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <div class="form-floating mt-3 input-group">
                UP TO INFINITY
              </div>
            </div>
            <div class="col-5">
              <div class="mt-3 input-group">
                <span class="border  border-end-0 pt-3 px-2">
                  {{auctionEntityDto?.currency?.symbol}}
                </span>

                <div class="form-floating ">

                  <input type="text" class="form-control border-start-0" placeholder="Bid Increment" id="infinityBidIncrement"
                    [formControl]="infinityBidIncrement" (blur)="formatInfinityIncrement()">
                  <label for="infinityBidIncrement">Infinity Increment Value</label>
                </div>
              </div>
              <div
                *ngIf="infinityBidIncrement?.invalid && (infinityBidIncrement?.dirty || infinityBidIncrement?.touched)">
                <p class="text-red-500 " *ngIf="infinityBidIncrement?.errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="invalidPriceValidator(infinityBidIncrement)">Invalid</p>
              </div>
            </div>
          </div>

        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'Lot saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
        </div>

        <div class="mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="handleValidSubmit()" [disabled]="!isAllowToEdit()">SAVE</button>
        </div>

      </form>
    </div>
  </div>
</div>


<ng-template #addLotFeatureModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>LOT FEATURES</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(lotFeatureModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="mb-3">
          Please ensure currect and relevant feature is captured as this information will be shown on auction listing as
          is
        </div>

        <form class="needs-validation" novalidate [formGroup]="fgLotFeature" (keyup.enter)="addLotFeature()">

          <div class="form-floating mb-3">
            <input type="text" class="form-control" placeholder="Key" formControlName="featureKey" id="featureKey"
              [ngClass]="{'is-invalid': fcLotFeatures.featureKey.invalid && (fcLotFeatures.featureKey.dirty || fcLotFeatures.featureKey.touched)}">
            <label for="featureKey">Key</label>
            <div class="invalid-feedback"
              *ngIf="fcLotFeatures.featureKey.invalid && (fcLotFeatures.featureKey.dirty || fcLotFeatures.featureKey.touched)">
              Required
            </div>
          </div>

          <div class="form-floating mb-3">
            <input type="text" class="form-control" placeholder="Value" formControlName="featureValue" id="featureValue"
              [ngClass]="{'is-invalid': fcLotFeatures.featureValue.invalid && (fcLotFeatures.featureValue.dirty || fcLotFeatures.featureValue.touched)}">
            <label for="featureValue">Value</label>
            <div class="invalid-feedback"
              *ngIf="fcLotFeatures.featureValue.invalid && (fcLotFeatures.featureValue.dirty || fcLotFeatures.featureValue.touched)">
              Required
            </div>
          </div>

          <div class="d-grid gap-2 mb-3">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="addLotFeature()">SAVE</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addImageModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>LOT {{ctrlImageModalFileType.value}}S</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isUploading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(imagesModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate>
          <div class="row mb-3">
            <div class="col-6">
              <input type="radio" [formControl]="ctrlImageModalFileType" value="IMAGE" id="ctrlImageModalFileType">
              Images
            </div>
            <div class="col-6">
              <input type="radio" [formControl]="ctrlImageModalFileType" value="VIDEO" id="ctrlImageModalFileType">
              Videos
            </div>
          </div>

          <div class="form-floating mb-3" *ngIf="ctrlImageModalFileType.value == 'IMAGE'">
            <input readonly type="text" (click)="lotImageRef.click()"
              value="{{currentMultipleFilesPath.length}} files selected" class="form-control" />
            <input #lotImageRef multiple type="file" onclick="this.value=null" id="uploadImage" (change)="chooseMultipleImages($event)"
              class="d-none form-control" />
            <label for="uploadImage">Upload Image</label>
          </div>

          <div class="col-md-12" style="overflow: auto; white-space: nowrap;"
            *ngIf="ctrlImageModalFileType.value == 'IMAGE'">
            <div class="e-formimage me-2" [ngClass]="{'ee-active': activeImage == i}"
              *ngFor="let item of currentMultipleFilesPath; index as i">
              <span class="ee-image">
                <img alt="" [src]="item" />
              </span>
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mx-2 mt-3" [disabled]="isLoading" (click)="openViewImageModal(item)">
                <img src="assets/icons/view_dark.svg" class="svg-icon-class c-pointer" *ngIf="!(activeImage == i)">
                <img src="assets/icons/zoomout_dark.svg" class="svg-icon-class c-pointer" *ngIf="activeImage == i">
              </button>
              <span [ngClass]="{'disabled' : isLoading }" (click)="removeMultiImage(i)">
                <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer mt-3">
              </span>
            </div>
          </div>

          <div class="form-floating mb-3" *ngIf="ctrlImageModalFileType.value == 'VIDEO'">
            <input type="text" class="form-control" id="ctrlVideoUrl" placeholder="Video Url" [formControl]="ctrlVideoUrl" />
            <label for="ctrlVideoUrl">Upload Video</label>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_showImagesErrorToast$ | async" [isError]="true"
              [message]="_errorMsg$ |async"></app-message-toast>
          </div>

          <div class="mb-3" *ngIf="isUploading">
            <div class="e-spinner"></div>
          </div>

          <div class="d-grid gap-2 my-3" *ngIf="!isUploading">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " [disabled]="!isImageSelected" (click)="uploadMultiImages()"
              *ngIf="ctrlImageModalFileType.value == 'IMAGE'">ADD</button>
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " [disabled]="ctrlVideoUrl.invalid"
              (click)="uploadVideoFile()" *ngIf="ctrlImageModalFileType.value == 'VIDEO'">ADD</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>


<!-- NgbTimePicker -->
<ng-template #endTimeModal>
  <ngb-timepicker [(ngModel)]="endTime" [meridian]="true"></ngb-timepicker>
  <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="setTime('endTime')">SAVE</button>
  <!-- <a class="e-buttontext ee-size-s mt-2 w-100 ee-vis-l ea-insert" >Save</a> -->
</ng-template>
