import { QuestionResponseType } from "../../enums/questionnaire/QuestionResponseType";
import { QuestionnaireStatus } from "../../enums/questionnaire/QuestionnaireStatus";
import { FileInfoDto } from "../FileInfoDto";
import { QuestionOptions } from "./QuestionOptions";

export class TechnicalQuestionTemplate {
  questionId?: string;
  sectionId?: string;
  questionnaireId?: string;
  questionText?: string;
  questionDescription?: string;
  orgCode?: string;
  auctionId?: string;
  rfxId?: string;
  rfxSubcategoryId?: string;
  preSequenceText?: string;
  sequenceNo?: string;
  score?: number;
  openerComments?: string;
  responseMandatory?: boolean;
  bidderCommentsRequired?: boolean;
  noResponse?: boolean;
  disQualifyForNoResponse?: boolean;
  sampleFile?: FileInfoDto;
  questionStatus?: QuestionnaireStatus;
  responseType?: QuestionResponseType;
  questionOptions?: QuestionOptions[];

  updateTimeUtc?: string;
  updateTimeUtcMillis?: number;
  version?: number
}
