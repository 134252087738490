import { DatePipe } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbPopover, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { EChartsOption } from 'echarts';
import { BehaviorSubject } from 'rxjs';
import { GraphCharEnum } from 'src/app/shared/enums/GraphCharEnum';
import { AdminUserPrivilege } from 'src/app/shared/enums/AdminUserPrivilege';
import { DynamicGraphConfig } from 'src/app/shared/models/DynamicGraphConfig';
import { GraphChartWrapper } from 'src/app/shared/models/GraphChartWrapper';
import { LotChartData } from 'src/app/shared/models/LotChartData';
import { RequestGraphChart } from 'src/app/shared/models/RequestGraphChart';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { SourcingEventDto } from 'src/app/shared/models/user/SourcingEventDto';

@Component({
  selector: 'app-admin-generate-graph',
  templateUrl: './admin-generate-graph.component.html',
  styleUrls: ['./admin-generate-graph.component.sass']
})
export class AdminGenerateGraphComponent implements OnInit, OnDestroy {
  selectedAuction?: SourcingEventDto
  selectedLot?: AuctionLotEntityDto

  selectedGraphConfig?: DynamicGraphConfig;
  selectedGraphType?: string
  selectedStartDate?: string;
  selectedEndDate?: string;
  selectedLotIds: Array<string> = []
  startTime?: NgbTimeStruct;
  endTime?: NgbTimeStruct;
  ngbPopover?: NgbPopover;
  hotBidCounts?: number;

  allAuctions: AuctionEntityDto[] = [];
  allLots: AuctionLotEntityDto[] = [];
  graphTypesList: string[] = [];
  graphChartWrapper?: GraphChartWrapper;

  isLoading: boolean = false
  isDataNotAvailable: boolean = false

  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');

  ctrlStartDate: FormControl = new FormControl('', Validators.required);
  ctrlStartTime: FormControl = new FormControl('', Validators.required);
  ctrlEndDate: FormControl = new FormControl('', Validators.required);
  ctrlEndTime: FormControl = new FormControl('', Validators.required);

  // Chart Data
  chartOption?: EChartsOption;
  xAxisDataList: string[] = [];
  yAxisDataList: any[] = [];
  yAxisLabel: string = ''
  compareSeriesData: any[] = []

  getGraphCharEnum() { return GraphCharEnum; }

  public screenWidth: any = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private adminService: AdminDashboardService,
    private auctionService: AdminSourcingEventsDataHolderService,
    private lotService: AdminLotsDataHolderService,
    private userService: UserService,
    private dataRedirectionService: DashboardRedirectionService,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.auctionService.selectedEvent$.subscribe(data => {
      if (data) {
        this.selectedAuction = data;
      }
    })

    this.lotService.getAllLots$.subscribe(data => {
      if (data) {
        this.allLots = data;
        this.allLots.sort((a, b) => a.lotSequence! - b.lotSequence!);
      }
    })

    this.ctrlStartDate.valueChanges.subscribe(() => {
      this.setDateAndTime()
    })

    this.ctrlStartTime.valueChanges.subscribe(() => {
      this.setDateAndTime()
    })

    this.ctrlEndDate.valueChanges.subscribe(() => {
      this.setDateAndTime()
    })

    this.ctrlEndTime.valueChanges.subscribe(() => {
      this.setDateAndTime()
    })
  }

  initGraphConfig(graphConfig: DynamicGraphConfig) {
    this.selectedGraphConfig = graphConfig;

    // clear data
    this.graphTypesList = []
    this.selectedGraphType = undefined
    this.selectedLot = undefined
    this.chartOption = undefined
    this.selectedStartDate = undefined
    this.selectedEndDate = undefined
    this.hotBidCounts = undefined
    this.isDataNotAvailable = false;
    this.selectedLotIds = []

    this.ctrlStartDate.reset();
    this.ctrlStartTime.reset();
    this.ctrlEndDate.reset();
    this.ctrlEndTime.reset();

    this.graphTypesList = this.graphConfigItems.find(item => item.id == graphConfig.id)?.graph!

    if (this.selectedGraphConfig?.id == GraphCharEnum.DATE_RANGE) {
      this.startTime = ApplicationUtils.getNgbPickerTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
      this.endTime = ApplicationUtils.getNgbPickerTime(`${new Date().getHours()}:${new Date().getMinutes()}`);
    }
  }

  changeGraphType(graphType: string) {
    this.selectedGraphType = graphType;
    this.chartOption = undefined

    this.graphChartWrapper = undefined;
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isDataNotAvailable = false;

    this.yAxisLabel = this.selectedGraphConfig?.yAxisLabel?.find(item => item?.lotExist! == (this.selectedLot != undefined))?.value!;

    // Get Data From Server
    if (!this.isGenerateButtonDisabled()) {
      this.getChartDataFromServer();
    }
  }

  changeLot(auctionLot: AuctionLotEntityDto) {
    this.selectedLot = auctionLot;
    this.selectedGraphType = undefined;
    this.chartOption = undefined

    this.selectedLotIds = []
    this.selectedLotIds.push(auctionLot.lotId!)
  }

  changeCompareLot(auctionLot: AuctionLotEntityDto) {
    if (this.selectedLotIds.includes(auctionLot.lotId!)) {
      let index = this.selectedLotIds.findIndex(item => item == auctionLot.lotId!);
      this.selectedLotIds.splice(index, 1);
    } else {
      this.selectedLotIds.push(auctionLot.lotId!);
    }

    this.selectedGraphType = undefined;
    this.chartOption = undefined
  }

  changeHotBidsCount(hotBids: number) {
    this.hotBidCounts = hotBids;

    this.selectedGraphType = undefined;
    this.chartOption = undefined
  }

  navigateToHomePage() {
    this.router.navigate(['Admin/Dashboard'], { skipLocationChange: true })
  }

  populateChartsData() {
    this.xAxisDataList = [];
    this.yAxisDataList = [];
    this.chartOption = undefined;
    this.isDataNotAvailable = false;

    let lotChartData = this.graphChartWrapper?.lotChartData;
    let registrationChartData = this.graphChartWrapper?.registrationChartData;

    if (!lotChartData && !registrationChartData) {
      this.isDataNotAvailable = true;
      return;
    }

    if (lotChartData) {
      lotChartData?.sort((a, b) => a.lotSequence! - b.lotSequence!);

      if (this.selectedLot) {
        let bidPlaceItem = lotChartData.find(item => item.lotSequence == this.selectedLot?.lotSequence)
        this.xAxisDataList = bidPlaceItem?.bids?.map(item => item.createdAt!)!;
        this.yAxisDataList = bidPlaceItem?.bids?.map(item => item.bidPrice!)!;
      } else {
        if (this.isLotCompare()) {
          this.compareSeriesData = [];

          lotChartData.forEach(lotData => {
            var seriesData = lotData.bids?.map(item => [new Date(item.createdAt!).getTime(), item.bidPrice!]);

            this.compareSeriesData.push({
              id: lotData.lotId,
              data: seriesData,
              type: this.selectedGraphType == 'Scatter Chart' ? 'scatter' : 'line'
            });
          })

        } else {
          this.xAxisDataList = lotChartData.map(item => item.lotTitle!);

          if (this.selectedGraphConfig?.id == GraphCharEnum.HOT_BIDS) {
            this.yAxisDataList = lotChartData.map(item => item.noOfHotBids);
          } else if (this.selectedGraphConfig?.id == GraphCharEnum.INVOICE_SETTLEMENT) {
            this.yAxisDataList = []
            if(lotChartData && lotChartData.length > 0){
            const pendingLotsCount = lotChartData.filter(_lot => _lot.status === 'PENDING').length;
            const paidLotsCount = lotChartData.filter(_lot => _lot.status === 'PAID').length;
            const partiallyPaidLotsCount = lotChartData.filter(_lot => _lot.status === 'PARTIALLY_PAID').length;
            const noShowLotsCount = lotChartData.filter(_lot => _lot.status === 'NO_SHOW').length;

            this.yAxisDataList.push( { value: pendingLotsCount, name: 'PENDING' })
            this.yAxisDataList.push( { value: paidLotsCount, name: 'PAID' })
            this.yAxisDataList.push( { value: partiallyPaidLotsCount, name: 'PARTIALLY PAID' })
            this.yAxisDataList.push( { value: noShowLotsCount, name: 'NO SHOW' })
            }

          } else {
            this.yAxisDataList = lotChartData.map(item => item.bids!.length);
          }
        }
      }
    } else {
      let monthRegistrations: string[] = []

      registrationChartData?.forEach(item => {
        let month = this.datePipe.transform(item.createdAt, 'MMMM')!;
        monthRegistrations.push(month);

        if (!this.xAxisDataList.includes(month)) {
          this.xAxisDataList.push(month);
        }
      });

      this.yAxisDataList = this.countMonthsData(monthRegistrations);
    }

    if (this.isLotCompare()) {
      this.generateBidsCompareChart()
    } else {
      if (this.selectedGraphType == 'Line Chart') {
        this.generateLineChart()
      } else if (this.selectedGraphType == 'Pie Chart') {
        this.generatePieChart()
      } else if (this.selectedGraphType == 'Scatter Chart') {
        this.generateScatterChart()
      } else {
        this.generateBarChart()
      }
    }
  }

  generateBarChart() {
    let lotChartData = this.graphChartWrapper?.lotChartData;

    this.chartOption = {
      grid: {
        containLabel: true
      },
      tooltip: {
        formatter: (params: any) => {
          let index = params.dataIndex;

          if (lotChartData) {
            if (this.selectedLot) {
              let selectedLotData = lotChartData[0];
              let currentBid = selectedLotData?.bids![index];

              return `Bid Price: ${this.selectedAuction?.currency?.symbol}${currentBid?.bidPrice}<br>
              Company Name: ${currentBid.companyName}<br>
              Bid Date: ${currentBid?.createdAt}<br>`;
            } else {
              let selectedLotData = lotChartData[index];
              return `Lot: ${selectedLotData?.lotSequence} # ${selectedLotData?.lotTitle}<br>
              No. of Bids: ${this.getNoOfBids(selectedLotData)}`;
            }
          }

          return params;
        }
      },
      dataZoom: [{
        type: 'inside',
        id: 'insideX',
        xAxisIndex: 0,
        start: 0,
        end: 100,
        zoomOnMouseWheel: true,
        moveOnMouseMove: true,
        moveOnMouseWheel: false
      }],
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            name: this.selectedGraphConfig?.title
          }
        }
      },
      xAxis: {
        type: 'category',
        data: this.xAxisDataList,
        axisLabel: {
          interval: 0,
          rotate: 30
        }
      },
      yAxis: {
        type: 'value',
        name: this.yAxisLabel,
        nameLocation: 'middle',
        nameGap: this.screenWidth < 768 ? 40 : 50,
        minInterval: 2,
        axisLabel: {
          formatter: this.selectedLot ? this.selectedAuction?.currency?.symbol + '{value}' : '{value}'
        }
      },
      series: [
        {
          data: this.yAxisDataList,
          type: 'bar',
        }
      ]
    }
  }

  generateLineChart() {
    let lotChartData = this.graphChartWrapper?.lotChartData;

    this.chartOption = {
      grid: {
        containLabel: true
      },
      tooltip: {
        formatter: (params: any) => {
          let index = params.dataIndex;

          if (lotChartData) {
            if (this.selectedLot) {
              let selectedLotData = lotChartData[0];
              let currentBid = selectedLotData?.bids![index];

              return `Bid Price: ${this.selectedAuction?.currency?.symbol}${currentBid?.bidPrice}<br>
              Company Name: ${currentBid.companyName}<br>
              Bid Date: ${currentBid?.createdAt}<br>`;
            } else {
              let selectedLotData = lotChartData[index];
              return `Lot: ${selectedLotData?.lotSequence} # ${selectedLotData?.lotTitle}<br>
              No. of Bids: ${this.getNoOfBids(selectedLotData)}`;
            }
          }

          return params;
        }
      },
      dataZoom: [{
        type: 'inside',
        id: 'insideX',
        xAxisIndex: 0,
        start: 0,
        end: 100,
        zoomOnMouseWheel: true,
        moveOnMouseMove: true,
        moveOnMouseWheel: false
      }],
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            name: this.selectedGraphConfig?.title
          }
        }
      },
      xAxis: {
        type: 'category',
        data: this.xAxisDataList,
        axisLabel: {
          interval: 0,
          rotate: 30
        }
      },
      yAxis: {
        type: 'value',
        name: this.yAxisLabel,
        nameLocation: 'middle',
        nameGap: this.screenWidth < 768 ? 40 : 50,
        minInterval: 2,
        axisLabel: {
          formatter: this.selectedLot ? this.selectedAuction?.currency?.symbol + '{value}' : '{value}'
        }
      },
      series: [
        {
          data: this.yAxisDataList,
          type: 'line',
        }
      ]
    }
  }

  generatePieChart() {
    let graphConfig = this.selectedGraphConfig!;



    this.chartOption = {
      tooltip: {
        trigger: 'item'
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            name: graphConfig.title
          }
        }
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: graphConfig.title,
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 18,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: this.yAxisDataList
        }
      ]
    };

  }

  generateScatterChart() {
    let lotChartData = this.graphChartWrapper?.lotChartData;

    this.chartOption = {
      grid: {
        containLabel: true
      },
      tooltip: {
        formatter: (params: any) => {
          let index = params.dataIndex;

          if (lotChartData) {
            if (this.selectedLot) {
              let selectedLotData = lotChartData[0];
              let currentBid = selectedLotData?.bids![index];

              return `Bid Price: ${this.selectedAuction?.currency?.symbol}${currentBid?.bidPrice}<br>
              Company Name: ${currentBid.companyName}<br>
              Bid Date: ${currentBid?.createdAt}<br>`;
            } else {
              let selectedLotData = lotChartData[index];
              return `Lot: ${selectedLotData?.lotSequence} # ${selectedLotData?.lotTitle}<br>
              No. of Bids: ${this.getNoOfBids(selectedLotData)}`;
            }
          }

          return params;
        }
      },
      dataZoom: [{
        type: 'inside',
        id: 'insideX',
        xAxisIndex: 0,
        start: 0,
        end: 100,
        zoomOnMouseWheel: true,
        moveOnMouseMove: true,
        moveOnMouseWheel: false
      }],
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            name: this.selectedGraphConfig?.title
          }
        }
      },
      xAxis: {
        type: 'category',
        data: this.xAxisDataList,
        axisLabel: {
          interval: 0,
          rotate: 30
        }
      },
      yAxis: {
        type: 'value',
        name: this.yAxisLabel,
        nameLocation: 'middle',
        nameGap: this.screenWidth < 768 ? 40 : 50,
        minInterval: 2,
        axisLabel: {
          formatter: this.selectedLot ? this.selectedAuction?.currency?.symbol + '{value}' : '{value}'
        }
      },
      series: [
        {
          data: this.yAxisDataList,
          type: 'scatter',
        }
      ]
    }
  }

  generateBidsCompareChart() {
    let lotChartData = this.graphChartWrapper?.lotChartData;

    this.chartOption = {
      grid: {
        containLabel: true
      },
      tooltip: {
        formatter: (params: any) => {
          let index = params.dataIndex;
          let seriesId = params.seriesId;

          let selectedLotData = lotChartData?.find(item => item.lotId == seriesId);
          let currentBid = selectedLotData?.bids![index];

          return `Lot: ${selectedLotData?.lotSequence} # ${selectedLotData?.lotTitle}<br>
              Company Name: ${currentBid?.companyName}<br>
              Bid Price: ${this.selectedAuction?.currency?.symbol}${currentBid?.bidPrice}<br>
              Bid Date: ${currentBid?.createdAt}<br>`;
        }
      },
      dataZoom: [{
        type: 'inside',
        id: 'insideX',
        xAxisIndex: 0,
        start: 0,
        end: 100,
        zoomOnMouseWheel: true,
        moveOnMouseMove: true,
        moveOnMouseWheel: false
      }],
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            name: this.selectedGraphConfig?.title
          }
        }
      },
      xAxis: {
        type: 'time',
        splitLine: {
          show: false
        },
        axisLabel: {
          rotate: 30,
          formatter: (value: any) => {
            var date = new Date(value);
            return this.datePipe.transform(date, 'EEE d MMM yyyy h:mm:ss a')!;
          }
        }
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false
        }
      },
      series: this.compareSeriesData
    };
  }

  getNoOfBids(lotChartData: LotChartData) {
    if (this.selectedGraphConfig?.id == GraphCharEnum.HOT_BIDS) {
      return lotChartData.noOfHotBids;
    } else {
      return lotChartData.bids?.length;
    }
  }

  getFormattedPrice(price?: string | number) {
    let currency = this.selectedAuction?.currency;
    return currency?.symbol + ApplicationUtils.getFormattedPrice(currency?.locale, price);
  }

  isLotCompare(): boolean {
    if (this.selectedGraphConfig) {
      return this.selectedGraphConfig.id == GraphCharEnum.COMPARE_BIDS_FREQUENCY;
    }
    return false;
  }

  isGenerateButtonDisabled() {
    if (this.selectedGraphConfig?.id == GraphCharEnum.COMPARE_BIDS_FREQUENCY) {
      return this.selectedLotIds.length < 2;
    }

    if (this.selectedGraphConfig?.id == GraphCharEnum.DATE_RANGE) {
      return !this.selectedStartDate || !this.selectedEndDate;
    }

    if (this.selectedGraphConfig?.id == GraphCharEnum.HOT_BIDS) {
      return !this.hotBidCounts;
    }

    return !this.selectedGraphConfig;
  }

  openTimepicker(p: NgbPopover, template: any) {
    if (this.ngbPopover?.isOpen) this.ngbPopover.close();

    if (!p.isOpen()) {
      this.ngbPopover = p;
      p.ngbPopover = template;
      p.open();
    } else {
      p.close();
    }
  }

  setTime(time: string) {
    if (time == 'startTime') {
      if (this.startTime?.hour != null && this.startTime?.minute != null && this.startTime?.second != null) {
        let convertedTime = ApplicationUtils.getTimeFromNgTimePicker(this.datePipe, this.startTime);
        this.ctrlStartTime.patchValue(convertedTime);
        this.ctrlStartTime.updateValueAndValidity();
      }
    } else {
      if (this.endTime?.hour != null && this.endTime?.minute != null && this.endTime?.second != null) {
        let convertedTime = ApplicationUtils.getTimeFromNgTimePicker(this.datePipe, this.endTime);
        this.ctrlEndTime.patchValue(convertedTime);
        this.ctrlEndTime.updateValueAndValidity();
      }
    }

    if (this.ngbPopover?.isOpen()) this.ngbPopover?.close();
  }

  setDateAndTime() {
    if (this.ctrlStartDate.valid && this.ctrlStartTime.valid) {
      let dateFromNgbDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.ctrlStartDate.value);
      let time24h = ApplicationUtils.convertTime12to24(this.ctrlStartTime.value);
      this.selectedStartDate = dateFromNgbDate + ' ' + time24h;
    }
    if (this.ctrlEndDate.valid && this.ctrlEndTime.valid) {
      let dateFromNgbDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.ctrlEndDate.value);
      let time24h = ApplicationUtils.convertTime12to24(this.ctrlEndTime.value);
      this.selectedEndDate = dateFromNgbDate + ' ' + time24h;
    }
  }

  refreshData() {
    if (!this.isGenerateButtonDisabled()) {
      this.getChartDataFromServer();
    }
  }

  getChartDataFromServer() {
    this.graphChartWrapper = undefined;
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isLoading = true;

    let requestGraphChart = new RequestGraphChart();
    requestGraphChart.auctionId = this.selectedAuction?.eventId;
    requestGraphChart.id = this.selectedGraphConfig?.id;
    requestGraphChart.startDate = this.selectedStartDate;
    requestGraphChart.endDate = this.selectedEndDate;
    requestGraphChart.lotIds = this.selectedLotIds;
    requestGraphChart.hotBid = this.hotBidCounts;

    this.adminService.charData(requestGraphChart).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false;

          this.graphChartWrapper = apiResponseDto.data as GraphChartWrapper;
          this.populateChartsData();
        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message ?? "Error while getting graph data. Try again.");
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this._errorMsg$.next("Error while getting graph data. Try again.");
        this.isLoading = false;
      }
    })
  }

  ngOnDestroy(): void {

  }

  graphConfigItems: Array<DynamicGraphConfig> = [
    {
      id: GraphCharEnum.BIDS_PLACED_IN_LAST_5_MINTS,
      title: "Bids placed in last 5 mins",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.BIDS_PLACED_IN_LAST_10_MINTS,
      title: "Bids placed in last 10 mins",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.BIDS_PLACED_IN_LAST_15_MINTS,
      title: "Bids placed in last 15 mins",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.BIDS_PLACED_IN_LAST_30_MINTS,
      title: "Bids placed in last 30 mins",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.BIDS_PLACED_IN_LAST_1_HOURS,
      title: "Bids placed in last 1 hr",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.BIDS_PLACED_IN_LAST_24_HOURS,
      title: "Bids placed in last 24 hrs",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.BIDS_PLACED_IN_LAST_WEEK,
      title: "Bids placed in last week",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.BIDS_PLACED_IN_LAST_MONTH,
      title: "Bids placed in last month",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.DATE_RANGE,
      title: "Date Range",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.ALL_BIDS,
      title: "All Bids",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.HOT_BIDS,
      title: "Hot Bids",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: true,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Hot Bids' },
      ]
    },
    {
      id: GraphCharEnum.COMPARE_BIDS_FREQUENCY,
      title: "Compare Bids Frequency",
      graph: ["Scatter Chart", "Line Chart"],
      auctionSpecific: false,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Bid Placed' },
        { lotExist: true, value: 'Bids Price' },
      ]
    },
    {
      id: GraphCharEnum.REGISTRATION_FREQUENCY,
      title: "Registration Frequency",
      graph: ["Bar Chart", "Line Chart", "Scatter Chart"],
      auctionSpecific: true,
      yAxisLabel: [
        { lotExist: false, value: 'No. of Registrations' },
      ]
    },
    {
      id: GraphCharEnum.INVOICE_SETTLEMENT,
      title: "Invoice Settlement",
      graph: ["Pie Chart"],
      auctionSpecific: true,
      yAxisLabel: []
    }
  ]

  // Dummy Chart Data
  // bidPlacedList = [
  //   {
  //     auctionId: '1001',
  //     sequenceNo: 1001,
  //     auctionName: 'Auction 1001',
  //     lotId: '1',
  //     lotSequence: 1,
  //     title: 'Lot 1',
  //     bids: [
  //       {
  //         createdAt: '01/07/2023 08:35',
  //         paddleNo: "#1101",
  //         bidPrice: 1000
  //       },
  //       {
  //         createdAt: '01/07/2023 10:35',
  //         bidPrice: 2000
  //       },
  //       {
  //         createdAt: '02/07/2023 12:35',
  //         bidPrice: 5000
  //       },
  //       {
  //         createdAt: '02/07/2023 08:00',
  //         bidPrice: 10000
  //       },
  //       {
  //         createdAt: '03/07/2023 12:15',
  //         bidPrice: 20000
  //       },
  //     ]
  //   },
  //   {
  //     auctionId: '1001',
  //     sequenceNo: 1001,
  //     auctionName: 'Auction 1001',
  //     lotId: '2',
  //     lotSequence: 2,
  //     title: 'Lot 2',
  //     bids: [
  //       {
  //         createdAt: '01/07/2023 08:35',
  //         bidPrice: 1000
  //       },
  //       {
  //         createdAt: '01/07/2023 10:35',
  //         bidPrice: 2000
  //       },
  //     ]
  //   },
  //   {
  //     auctionId: '1001',
  //     sequenceNo: 1001,
  //     auctionName: 'Auction 1001',
  //     lotId: '3',
  //     lotSequence: 3,
  //     title: 'Lot 3',
  //     bids: [
  //       {
  //         createdAt: '01/07/2023 08:35',
  //         bidPrice: 1000
  //       },
  //       {
  //         createdAt: '01/07/2023 10:35',
  //         bidPrice: 2000
  //       },
  //       {
  //         createdAt: '02/07/2023 12:35',
  //         bidPrice: 5000
  //       },
  //     ]
  //   },
  //   {
  //     auctionId: '1001',
  //     sequenceNo: 1001,
  //     auctionName: 'Auction 1001',
  //     lotId: '4',
  //     lotSequence: 4,
  //     title: 'Lot 4',
  //     bids: [
  //       {
  //         createdAt: '01/07/2023 08:35',
  //         bidPrice: 1000
  //       },
  //       {
  //         createdAt: '01/07/2023 10:35',
  //         bidPrice: 2000
  //       },
  //       {
  //         createdAt: '02/07/2023 12:35',
  //         bidPrice: 5000
  //       },
  //       {
  //         createdAt: '02/07/2023 08:00',
  //         bidPrice: 10000
  //       },
  //     ]
  //   }
  // ]

  countMonthsData(array: string[]) {
    const result = [];
    let count = 1;

    for (let i = 1; i < array.length; i++) {
      if (array[i] === array[i - 1]) {
        count++;
      } else {
        result.push(count);
        count = 1;
      }
    }

    result.push(count);
    return result;
  }
}
