import { createFeature, createReducer, on } from "@ngrx/store";
import { TenderSourcingEventDto } from "../../models/user/TenderSourcingEventDto";
import { TenderManagementActions } from "./tender.actions";
import { TenderOpportunityWrapperDto } from "../../models/user/TenderOpportunityWrapperDto";
import { TenderWrapperUiDto } from "../../models/user/TenderWrapperUiDto";
import { UserInfoUiDto } from "../../models/user/UserInfoUiDto";
import { EntityActionDto } from "../../models/EntityActionDto";

interface State {
  tenderSourcingEventDtos: TenderSourcingEventDto[];
  tenderWrapperUiDto: TenderWrapperUiDto | null;
  tenderOpportunityWrapperDto: TenderOpportunityWrapperDto | null;
  allAdminUsers: UserInfoUiDto[];
  entityActionDtos: EntityActionDto[];
}

const initialState: State = {
  tenderSourcingEventDtos: [],
  tenderWrapperUiDto: null,
  tenderOpportunityWrapperDto: null,
  allAdminUsers: [],
  entityActionDtos: [],
};

export const tenderManagementFeature = createFeature({
  name: 'TenderManagementFeature',
  reducer: createReducer(
    initialState,

    // Tender Sourcing Event Dtos
    on(TenderManagementActions.setTenderSourcingEvents, (state, { tenderSourcingEventDtos }) => ({
      ...state,
      tenderSourcingEventDtos
    })),
    on(TenderManagementActions.getTenderSourcingEvents, state => ({
      ...state
    })),

    // Current Tender Wrapper Ui Dto
    on(TenderManagementActions.setCurrentTenderWrapperUiDto, (state, { tenderWrapperUiDto }) => {
      // Check if tenderWrapperUiDto contains tenderOpportunityWrapperDtos and state's tenderOpportunityWrapperDto is not null
      const tenderOpportunityWrapperDtos = tenderWrapperUiDto?.tenderOpportunityWrapperDtos;
      const existingTenderOpportunityWrapperDto = state.tenderOpportunityWrapperDto;

      if (tenderOpportunityWrapperDtos && existingTenderOpportunityWrapperDto) {
        // Find the matching item by id
        const updatedTenderOpportunityWrapperDto = tenderOpportunityWrapperDtos.find(item =>
          item.tenderOpportunityId === existingTenderOpportunityWrapperDto.tenderOpportunityId
        );

        // If found, update it
        if (updatedTenderOpportunityWrapperDto) {
          return {
            ...state,
            tenderWrapperUiDto: tenderWrapperUiDto,
            tenderOpportunityWrapperDto: updatedTenderOpportunityWrapperDto
          };
        }
      }

      // If no match is found, return the state without changes to tenderOpportunityWrapperDto
      return {
        ...state,
        tenderWrapperUiDto,
      };

    }),
    on(TenderManagementActions.getCurrentTenderWrapperUiDto, (state) => ({
      ...state
    })),
    on(TenderManagementActions.clearCurrentTenderWrapperUiDto, (state) => ({
      ...state,
      tenderWrapperUiDto: null,
      tenderOpportunityWrapperDto: null
    })),

    // Current Opportunity Wrapper Dto
    on(TenderManagementActions.setCurrentTenderOpportunityWrapperDto, (state, { tenderOpportunityWrapperDto }) => ({
      ...state,
      tenderOpportunityWrapperDto
    })),
    on(TenderManagementActions.getCurrentTenderOpportunityWrapperDto, (state) => ({
      ...state
    })),
    on(TenderManagementActions.clearCurrentTenderOpportunityWrapperDto, (state) => ({
      ...state,
      tenderOpportunityWrapperDto: null
    })),

    // User Ui Info Dtos
    on(TenderManagementActions.saveAllAdminUsers, (state, { allAdminUsers }) => ({
      ...state,
      allAdminUsers
    })),
    on(TenderManagementActions.getAllAdminUsers, (state) => ({
      ...state
    })),

    // EntityActionDto
    on(TenderManagementActions.saveEntityActionDto, (state, { entityActionDto }) => ({
      ...state,
      entityActionDtos: state.entityActionDtos.some(dto => dto.id === entityActionDto.id)
        ? state.entityActionDtos
        : [...state.entityActionDtos, entityActionDto]
    })),
    on(TenderManagementActions.getEntityActionDtos, (state) => ({
      ...state
    })),
    on(TenderManagementActions.removeEntityActionDto, (state, { id }) => ({
      ...state,
      entityActionDtos: state.entityActionDtos.filter(dto => dto.id !== id)  // Remove the item with the matching id
    })),
    on(TenderManagementActions.clearEntityActionDtos, (state) => ({
      ...state,
      entityActionDtos: []
    })),
  ),
});

export const {
  name,
  reducer,
  selectTenderManagementFeatureState,
  selectTenderSourcingEventDtos,
  selectTenderWrapperUiDto,
  selectTenderOpportunityWrapperDto,
  selectAllAdminUsers,
  selectEntityActionDtos
} = tenderManagementFeature