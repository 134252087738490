import { Component } from '@angular/core';
import { EChartsOption } from 'echarts';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-spend-analytics',
  templateUrl: './spend-analytics.component.html',
  styleUrls: ['./spend-analytics.component.sass']
})

export class SpendAnalyticsComponent {

  chartOptionCategory: EChartsOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'horizontal',
      left: 10,
      top: 'top',
      data: ['Information Technology', 'Electronics', 'Abrasives', 'Cutting Tools', 'Material Handling Equipments']
    },
    series: [
      {
        name: 'Pie chart',
        type: 'pie',
        top: '10%',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '15',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 335, name: 'Information Technology' },
          { value: 310, name: 'Electronics' },
          { value: 234, name: 'Abrasives' },
          { value: 135, name: 'Cutting Tools' },
          { value: 1548, name: 'Material Handling Equipments' }
        ]
      }
    ]
  };
  chartOptionGeography: EChartsOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'horizontal',
      left: 10,
      top: 'top',
      data: ['Plant 1', 'Plant 2', 'Plant 3', 'Plant 4', 'Plant 5']
    },
    series: [
      {
        name: 'Pie chart',
        type: 'pie',
        top: '10%',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '15',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 335, name: 'Plant 1' },
          { value: 310, name: 'Plant 2' },
          { value: 234, name: 'Plant 3' },
          { value: 1305, name: 'Plant 4' },
          { value: 158, name: 'Plant 5' }
          
        ]
      }
    ]
  };

  chartOptionSetGoal: EChartsOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      orient: 'horizontal',
      left: 10,
      top: 'top',
      data: ['Information Technology', 'Electronics']
    },
    series: [
      {
        name: 'Pie chart',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '15',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 70, name: 'Information Technology', itemStyle: { color: '#0a9d48' } }, // Green sector
          { value: 30, name: 'Electronics', itemStyle: { color: '#4D90D2' } }     // Blue sector
        ]
      }
    ]
  };
  isRegistrationChartLoading: boolean = true;

  registrationFilterType$ = new BehaviorSubject<string>('ALL')


  filterRegistrations(ev: any) {
    console.log(ev.name);
    let filterType = ev.name == 'Awaiting Approval' ? 'AWAITING_APPROVAL'
      : ev.name == 'Rejected' ? 'REJECTED_BIDDERS'
        : ev.name == 'Approval' ? 'APPROVED_BIDDERS' : 'ALL';
    this.registrationFilterType$.next(filterType)
  }
}