import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { AuctionLotEntityDto } from '../../models/user/AuctionLotEntityDto';
import { AdminLotsDataHolderService } from '../../services/adminLotsDataHolderService.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SwiperComponent } from 'swiper/angular';
import { DeploymentConfigurationDto } from '../../models/user/DeploymentConfigurationDto';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { AuctionValidationService } from '../../services/auction-validation.service';
import SwiperCore, { SwiperOptions, Navigation, Pagination, Virtual } from 'swiper';
import { AdminSourcingEventsDataHolderService } from '../../services/AdminSourcingEventsDataHolder.service ';
import { RfxSubcategoryUiDto } from '../../models/rfx/RfxSubcategoryUiDto';
import { AdminRfxSubcategoryDataHolderService } from '../../services/AdminRfxSubcategoryDataHolderService.service';
import { RfxUiDto } from '../../models/rfx/RfxUiDto';
import { RfxValidationService } from '../../services/rfx-validation.service';

SwiperCore.use([Virtual, Navigation, Pagination]);

@Component({
  selector: 'app-subcategory-sequence-carousel',
  templateUrl: './subcategory-sequence-carousel.component.html',
  styleUrls: ['./subcategory-sequence-carousel.component.sass']
})
export class SubcategorySequenceCarouselComponent implements OnInit, OnDestroy {
  @Input() isShowValidation: boolean = false
  @Input() isShowActiveLotsOnly: boolean = false
  @Output() selectedSubcategoryEvent: EventEmitter<RfxSubcategoryUiDto> = new EventEmitter();

  rfxSubcategoryEntityDtos?: RfxSubcategoryUiDto[];
  rfxSubcategoryEntityDto?: RfxSubcategoryUiDto;
  rfxEntityDto?: RfxUiDto;
  deploymentConfigurationDto?: DeploymentConfigurationDto;

  selectedLotIndex: number = 0;

  isSubcategoryLoaded$ = new BehaviorSubject<boolean>(false);

  selectedRfxSubscription$?: Subscription;
  selectedSubcategorySubscription$?: Subscription;
  allSubcategoriesSubscription$?: Subscription;
  deploymentConfigurationDtoSubscription$?: Subscription;

  @ViewChild('swiperLots') swiperLots?: SwiperComponent;

  swiperConfig: SwiperOptions = {
    slidesPerView: 15,
    spaceBetween: 10,
    navigation: false,
    pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 4 },
    scrollbar: { draggable: true },
    modules: [Pagination, Navigation],
    updateOnWindowResize: true,
    centerInsufficientSlides: true,
    centeredSlides: false,
    breakpoints: {
      320: {
        slidesPerView: 3
      },
      480: {
        slidesPerView: 6
      },
      768: {
        slidesPerView: 10
      },
      1024: {
        slidesPerView: 15
      },
    }
  };

  constructor(
    public adminService: AdminDashboardService,
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private validationService: RfxValidationService,
    private changeDetectRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.deploymentConfigurationDtoSubscription$ = this.adminService.getDeploymentConfiguration$.subscribe(data =>{
      if(data){
        this.deploymentConfigurationDto = data;
      }
    })

    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(data => {
      if (data) {
        this.rfxEntityDto = data;
      }
    })

    this.selectedSubcategorySubscription$ = this.adminRfxSubcategoryDataHolderService._selectedRfxSubcategory$.subscribe(data => {
      if (data) {
        this.rfxSubcategoryEntityDto = data;

        this.changeDetectRef.detectChanges()

        if (this.rfxSubcategoryEntityDtos) {
          this.rfxSubcategoryEntityDtos.sort((a, b) => a.subcategorySequence! - b.subcategorySequence!);
          this.selectedLotIndex = this.rfxSubcategoryEntityDtos!.findIndex(item => item.subcategoryId == this.rfxSubcategoryEntityDto?.subcategoryId);

          if (this.swiperLots && this.selectedLotIndex > -1) {
            this.swiperLots?.swiperRef.slideTo(this.selectedLotIndex);
          }
        }
      }
    })

    this.allSubcategoriesSubscription$ = this.adminRfxSubcategoryDataHolderService._allRfxSubcategoryList$.subscribe(data => {
      if (data) {
        this.rfxSubcategoryEntityDtos = [];
        this.isSubcategoryLoaded$.next(false);

        setTimeout(() => {
          this.rfxSubcategoryEntityDtos = this.isShowActiveLotsOnly ? data.filter(item => item.active) : data;
          this.rfxSubcategoryEntityDtos.sort((a, b) => a.subcategorySequence! - b.subcategorySequence!);

          this.isSubcategoryLoaded$.next(true);
          this.changeDetectRef.detectChanges()

          if (this.rfxSubcategoryEntityDto) {
            this.selectedLotIndex = this.rfxSubcategoryEntityDtos!.findIndex(item => item.subcategoryId == this.rfxSubcategoryEntityDto?.subcategoryId);
            if (this.selectedLotIndex > -1) {
              this.swiperLots?.swiperRef.slideTo(this.selectedLotIndex);
            }
          }

        }, 1000);
      } else {
        this.rfxSubcategoryEntityDtos = [];
        this.isSubcategoryLoaded$.next(true);
      }
    })
  }

  changeCurrentSubcategory(subcategoryId: string, selectedLotIndex: number) {
    let selectedSubcategory = this.rfxSubcategoryEntityDtos?.find(item => item.subcategoryId == subcategoryId );

    if (selectedSubcategory) {
      this.adminRfxSubcategoryDataHolderService.setSelectedRfxSubcategory(selectedSubcategory!);
      this.rfxSubcategoryEntityDto = selectedSubcategory;
      this.selectedLotIndex = selectedLotIndex;

      // Emit Selected Lot & Selected Index
      this.selectedSubcategoryEvent?.emit(selectedSubcategory);
    }
  }

  haveAnyLotErrors(subcategoryId: string) {
    let selectedSubcategory = this.rfxSubcategoryEntityDtos?.find(item => item.subcategoryId == subcategoryId );
    if (this.rfxEntityDto && selectedSubcategory && this.isShowValidation) {
      return this.validationService.haveAnyRfxSubcategoryErrors(this.rfxEntityDto!, selectedSubcategory!);
    }
    return false;
  }

  onSlideChange() {
    console.log('slide change');
  }

  nextSubcategory() {
    let totalLots = this.rfxSubcategoryEntityDtos!.length;
    if (this.selectedLotIndex < (totalLots - 1)) {
      let index = this.selectedLotIndex + 1;
      let selectedLot = this.rfxSubcategoryEntityDtos![index];
      this.changeCurrentSubcategory(selectedLot.subcategoryId!, index);
    }
  }

  previousSubcategory() {
    if (this.selectedLotIndex > 0) {
      let index = this.selectedLotIndex - 1;
      let selectedLot = this.rfxSubcategoryEntityDtos![index];
      this.changeCurrentSubcategory(selectedLot.subcategoryId!, index);
    }
  }

  // updateSwiperConfig() {
  //   if (this.swiperLots && this.swiperLots.swiperRef) {
  //     this.swiperLots.swiperRef.wrapperEl.style.alignItems = 'baseline'
  //     if (this.swiperLots.paginationElRef) {
  //       this.swiperLots.paginationElRef.nativeElement.style.bottom = 0;
  //     }

  //     this.swiperLots.swiperRef.update()
  //     this.changeDetectRef.detectChanges()
  //   }
  // }

  ngOnDestroy(): void {
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }

    if (this.selectedSubcategorySubscription$) {
      this.selectedSubcategorySubscription$.unsubscribe();
    }

    if (this.allSubcategoriesSubscription$) {
      this.allSubcategoriesSubscription$.unsubscribe();
    }

    if(this.deploymentConfigurationDtoSubscription$){
      this.deploymentConfigurationDtoSubscription$.unsubscribe();
    }
  }
}
