
<div *ngIf="isShowNotification">
<!--Notification For Pending Bidder -->
<div class="notification border border-2 bg-white border-danger rounded p-2 mb-2 medium-font"
*ngIf="(countPendingRegistration > 0) && isShowRegistration">
<div class="row">
  <div class="col-11">
    <span class="me-4"><img src="assets/icons/notifications.gif" width="32"></span>
    <span class="font-weight-bold me-2">{{countPendingRegistration}}</span>
    <span class="medium-font">registration(s) pending for approval.</span>
    <a class="link-class c-pointer ms-2" (click)="openPendingRegistrationsModal(Registration)">View Details</a>
  </div>
  <div class="col-1 text-end">
    <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
      (click)="closeRegistrationNotification()">
  </div>
</div>
</div>


<!--Notification For Not Award Bidder -->
<div class="notification border border-2 bg-white border-danger rounded p-2 medium-font"
  *ngIf="(notAwardLotsCount > 0) && isShowNotAwardAuction">
  <div class="row">
    <div class="col-11">
      <span class="me-4"><img src="assets/icons/notifications.gif" width="32"></span>
      <span class=" font-weight-bold me-2">{{notAwardLotsCount}}</span>
      <span class="medium-font">Lot(s) pending for award.</span>
      <a class="link-class c-pointer ms-2" (click)="openNotAwardAuctionModal(NotAwardAuction)">View
        Details</a>
    </div>
    <div class="col-1 text-end">
      <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeNotAwardNotification()">
    </div>
  </div>
</div>
</div>
<ng-template #Registration>
<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>NEW AUCTION REGISTRATION</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-class">
    <div class="m-3">
      <table class="table table-bordered mb-0">
        <thead class="table-dark">
          <tr>
            <th scope="col" ngbAutoFocus>#</th>
            <th scope="col">Auction No.</th>
            <th scope="col">Auction Name</th>
            <th scope="col">Pending Registrations</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of auctionIdsList; index as i">
            <td>{{i + 1}}</td>
            <td>{{getAuction(item).sequenceNo}}</td>
            <td>{{getAuction(item).eventName}}</td>
            <td>{{getRegistrationCount(item)}}</td>
            <td>
              <button class="btn  btn-new-class" (click)="navigateToRegistration(getAuction(item)!.sequenceNo!)">VIEW</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</ng-template>

<ng-template #NotAwardAuction>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>NOT AWARD AUCTIONS</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-class">
      <div class="m-3">
        <table class="table table-bordered mb-0">
          <thead class="table-dark">
            <tr>
              <th scope="col" ngbAutoFocus>#</th>
              <th scope="col">Auction No.</th>
              <th scope="col">Auction Name</th>
              <th class="text-center" scope="col">No. of Lots</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of notAwardAuctionDtos; index as i">
              <td>{{i + 1}}</td>
              <td>{{item?.auctionSequenceNo}}</td>
              <td>{{item?.auctionName}}</td>
              <td class="text-center">{{item?.noOfLots}}</td>
              <td>
                <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="navigateToPostAuction(item?.auctionId!)">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>
