<div class="container my-5">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-6">
            <button class="btn btn-sm d-flex mb-2 rounded-0"
              [ngClass]="selectedGraphConfig?.id == item?.id ? 'btn-new-class' : 'btn-outline-secondary'"
              *ngFor="let item of graphConfigItems" (click)="initGraphConfig(item)">
              {{item?.title}}
            </button>
          </div>
          <div class="col-6">
            <div *ngIf="selectedGraphConfig?.id == getGraphCharEnum().DATE_RANGE">
              <ng-container *ngTemplateOutlet="dateRangeTemplate"></ng-container>
            </div>
            <div *ngIf="selectedGraphConfig?.id == getGraphCharEnum().HOT_BIDS">
              <ng-container *ngTemplateOutlet="hotBidsTemplate"></ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 border-start">
        <!-- Lot Select -->
        <div *ngIf="selectedGraphConfig && !selectedGraphConfig?.auctionSpecific && !isLotCompare()">
          <label>Select Lot if you want specific lot data.</label>
          <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-left">
            <span ngbDropdownToggle data-bs-toggle="dropdown">
              <span class="c-pointer">
                <button class="btn btn-sm btn-outline-secondary rounded-0">
                  {{selectedLot ? selectedLot.lotSequence + ' # ' + selectedLot.title : 'Choose Lot'}}
                </button>
              </span>
            </span>
            <ul ngbDropdownMenu class="shadow scroll-list">
              <li ngbDropdownItem class="c-pointer" *ngFor="let item of allLots;" (click)="changeLot(item)">
                <a>{{item?.lotSequence}} # {{item?.title}}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="mt-2" *ngIf="isLotCompare()">
          <label>Select Lots for compare.</label>
          <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-left" autoClose="outside">
            <span ngbDropdownToggle data-bs-toggle="dropdown">
              <span class="c-pointer">
                <button class="btn btn-sm btn-outline-secondary rounded-0">
                  {{selectedLotIds.length ? selectedLotIds.length + ' selected' : 'Choose Lots'}}
                </button>
              </span>
            </span>
            <ul ngbDropdownMenu class="shadow scroll-list" >
              <li ngbDropdownItem *ngFor="let item of allLots;">
                <div class="form-check">
                  <input class="form-check-input c-pointer" type="checkbox" id="check1" (click)="changeCompareLot(item)"
                    [checked]="selectedLotIds.includes(item.lotId!)">
                  <label class="form-check-label">{{item?.lotSequence}} # {{item?.title}}</label>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- Graph Type -->
        <div class="row mt-3 align-items-end" *ngIf="selectedGraphConfig">
          <div class="col-8">
            <label>Graph Type</label>
            <div>
              <button id="graphChip" class="btn btn-sm btn-rounded me-2 mt-1" *ngFor="let item of graphTypesList;" (click)="changeGraphType(item)"
                [ngClass]="selectedGraphType == item ? 'btn-new-class' : 'btn-outline-secondary'"
                [disabled]="isGenerateButtonDisabled()">
                {{item}}
              </button>
            </div>
          </div>

          <div class="col-4 text-end" *ngIf="isDataNotAvailable || chartOption">
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="refreshData()" [disabled]="isGenerateButtonDisabled()">
              <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
            </button>
          </div>
        </div>

        <div class="mt-3">

          <div class="border p-3" *ngIf="isDataNotAvailable || chartOption || isLoading">
            <div class="my-3 text-center" *ngIf="isDataNotAvailable">
              <h6>Data Not Available</h6>
            </div>
            <div class="my-3">
              <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
            </div>
            <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
              <div class="e-spinner"></div>
            </div>

            <div echarts [options]="chartOption" class="demo-chart" *ngIf="chartOption && !isLoading"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #hotBidsTemplate>
  <label>No. Of Hot Bids</label>
  <div ngbDropdown class="d-block">
    <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" ngbDropdownToggle>
      {{hotBidCounts ? 'Hot Bids: ' + hotBidCounts + '+' : 'Select No. of Hot Bids'}}
    </button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem (click)="changeHotBidsCount(10)">10+</button>
      <button ngbDropdownItem (click)="changeHotBidsCount(20)">20+</button>
      <button ngbDropdownItem (click)="changeHotBidsCount(30)">30+</button>
      <button ngbDropdownItem (click)="changeHotBidsCount(40)">40+</button>
      <button ngbDropdownItem (click)="changeHotBidsCount(50)">50+</button>
    </div>
  </div>
</ng-template>

<ng-template #dateRangeTemplate>
  <!-- Start Date & Time -->
  <label>Start Date & Time</label>
  <div class="row mb-3">
    <div class="col-6">
      <div class="input-group mb-3">
        <span class="border  border-end-0 pt-3 px-1">
          <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
        </span>

        <div class="form-floating  ">
          <input type="text" ngbDatepicker #startDate="ngbDatepicker" id="ctrlStartDate" (click)="startDate.toggle()"
            class="form-control border-start-0" placeholder="Start Date" [formControl]="ctrlStartDate"
            [ngClass]="{'is-invalid': ctrlStartDate.invalid && (ctrlStartDate.dirty || ctrlStartDate.touched)}">
          <label for="ctrlStartDate" class="">Start Date</label>
        </div>
      </div>

      <div *ngIf="ctrlStartDate.invalid && (ctrlStartDate.dirty || ctrlStartDate.touched)">
        <p class="text-red-500 " *ngIf="ctrlStartDate.errors?.['required']">Required</p>
      </div>

    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <span class="border  border-end-0 pt-3 px-1">
          <img src="assets/icons/time_light.svg" class="svg-icon-class c-pointer">
        </span>

        <div class="form-floating ">
          <input type="text" class="form-control border-start-0" ngbPopover [autoClose]="'outside'" placement="left"
            triggers="manual" #p1="ngbPopover" id="ctrlStartTime" (click)="openTimepicker(p1, startTimeModal)" placeholder="Start Time"
            [formControl]="ctrlStartTime"
            [ngClass]="{'is-invalid': ctrlStartTime.invalid && (ctrlStartTime.dirty || ctrlStartTime.touched)}">
          <label for="ctrlStartTime">Start Time</label>
        </div>
      </div>
      <div *ngIf="ctrlStartTime.invalid && (ctrlStartTime.dirty || ctrlStartTime.touched)">
        <p class="text-red-500 " *ngIf="ctrlStartTime.errors?.['required']">Required</p>
      </div>
    </div>
  </div>

  <!-- End Date & Time -->
  <label>End Date & Time</label>
  <div class="row mb-3">
    <div class="col-6">
      <div class="input-group mb-3">
        <span class="border  border-end-0 pt-3 px-1">
          <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
        </span>

        <div class="form-floating  ">
          <input type="text" ngbDatepicker #endDate="ngbDatepicker" (click)="endDate.toggle()"
            class="form-control border-start-0" placeholder="End Date" id="ctrlEndDate" [formControl]="ctrlEndDate"
            [ngClass]="{'is-invalid': ctrlEndDate.invalid && (ctrlEndDate.dirty || ctrlEndDate.touched)}">
          <label for="ctrlEndDate" class="">End Date</label>
        </div>
      </div>

      <div *ngIf="ctrlEndDate.invalid && (ctrlEndDate.dirty || ctrlEndDate.touched)">
        <p class="text-red-500 " *ngIf="ctrlEndDate.errors?.['required']">Required</p>
      </div>

    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <span class="border  border-end-0 pt-3 px-1">
          <img src="assets/icons/time_light.svg" class="svg-icon-class c-pointer">
        </span>

        <div class="form-floating ">
          <input type="text" class="form-control border-start-0" ngbPopover [autoClose]="'outside'" placement="left"
            triggers="manual" #p2="ngbPopover" id="ctrlEndTime" (click)="openTimepicker(p2, endTimeModal)" placeholder="End Time"
            [formControl]="ctrlEndTime"
            [ngClass]="{'is-invalid': ctrlEndTime.invalid && (ctrlEndTime.dirty || ctrlEndTime.touched)}">
          <label for="ctrlEndTime">End Time</label>
        </div>
      </div>
      <div *ngIf="ctrlEndTime.invalid && (ctrlEndTime.dirty || ctrlEndTime.touched)">
        <p class="text-red-500 " *ngIf="ctrlEndTime.errors?.['required']">Required</p>
      </div>
    </div>
  </div>
</ng-template>

<!-- NGB TIME MODAL -->
<ng-template #startTimeModal>
  <ngb-timepicker [(ngModel)]="startTime" [meridian]="true"></ngb-timepicker>
  <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="setTime('startTime')">SAVE</button>
</ng-template>

<ng-template #endTimeModal>
  <ngb-timepicker [(ngModel)]="endTime" [meridian]="true"></ngb-timepicker>
  <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="setTime('endTime')">SAVE</button>
</ng-template>
