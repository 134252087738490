<div>
  <div class="col-12 my-2">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5 class="heading-h5">REGISTRATION</h5>
      </div>
      <div class="col-auto">
        <div class="border border-2 border-success rounded p-1 fs-6" *ngIf="isShowNewRegistrationAlert">
          Registration Alert! {{newRegistrations}} new bidders onboarded. Please <button class="btn btn-link" (click)="refreshRegistrationData()">Refresh</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 text-center">
    <div class="">
      <app-auction-registration-summary></app-auction-registration-summary>
    </div>
  </div>
  <div class="col-12 mt-2">
    <app-auction-registration-list></app-auction-registration-list>
  </div>
</div>
