<div class="p-2 mt-5">
  <div class="grid grid-cols-1 md:grid-cols-12 gap-4 w-full items-center justify-between">
    <div class="md:col-span-3">
      <div class="flex w-full mb-3">
        <input
          class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0"
          #search id="search" placeholder="Search">
        <div class="input-group-append">
          <span class="input-group-text border-solid border-slate-50 border-l-0 rounded-l-lg h-8 bg-white"
            id="basic-addon2">
            <i-lucide name="search" [size]="16" class="my-icon c-pointer"></i-lucide>
          </span>
        </div>
      </div>
    </div>
    <div class="md:col-span-9 ">
      <span class="inline-block me-3" (click)="openFilterDialog()"
        [ngClass]="{'border border-dark border-2': isFilterApplied()}">
        <img src="assets/icons/Filter-Icon.svg" class="svg-icon-small c-pointer">
      </span>

      <span class="inline-block">
        <p-menu #menu [model]="sortItems" [popup]="true"></p-menu>
        <span (click)="menu.toggle($event)" class="c-pointer">
          <img src="assets/icons/Sorting-Icon.svg" class="svg-icon-small">
        </span>
      </span>
    </div>
  </div>
  <!-- <div class="row align-items-center">

    <div class="col-md-3  text-start mb-2">

      <div class="mb-2 input-group">
        <input #search placeholder="Search" type="text" [formControl]="ctrlSearchAuction"
          class="form-control border-end-0 rounded-0 border bg-white"  id="ctrlSearchAuction"/>
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
    </div>
    <div class="col-md-5 text-start mb-2">
      <div class="row justify-content-md-start">
        <div class="col-xl-1 px-0 c-pointer col-md-2 col-3 text-center mb-2 " (click)="openFilterModal(filterModal)"
          [ngClass]="{'border border-dark border-2': isFilterApplied()}">
          <div class="small-font" *ngIf="!isFilterApplied()">
            Filter
          </div>
          <div class="small-font" *ngIf="isFilterApplied()">
            Filter Applied
          </div>
          <img src="assets/icons/Filter-Icon.svg" class="svg-icon-small">
        </div>
        <div class="col-xl-1 px-0 col-md-2 col-3 text-center mb-2" [ngClass]="{'border-end' : selectedEvent}">
          <div class="small-font">
            Sort
          </div>
          <div class="">
            <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="false">
              <span data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
                <img src="assets/icons/Sorting-Icon.svg" class="svg-icon-small">
              </span>
              <ul class="dropdown-menu text-start shadow mt-3 dropdown-width1 " ngbDropdownMenu>
                <li>
                  <div class="col-12 text-end px-2">

                    <img src="assets/icons/close_light.svg" class="svg-icon-class c-pointer"
                      (click)="statusNgbDropdown1.close()">
                  </div>
                </li>
                <li (click)="sortSourcingData('AUCTION_END_DATE_DESC')" class="dropdown-item mb-1">
                  <input type="checkbox" [checked]="selectedSortBy == 'AUCTION_END_DATE_DESC'" id="check1">
                  <span class="ms-2">{{getSortByDropdownTitle('AUCTION_END_DATE_DESC')}} </span>
                </li>

                <li (click)="sortSourcingData('AUCTION_SEQUENCE_NO_ASC')" class="dropdown-item mb-1" >
                  <input type="checkbox" [checked]="selectedSortBy == 'AUCTION_SEQUENCE_NO_ASC'" id="check2">
                  <span class="ms-2">{{getSortByDropdownTitle('AUCTION_SEQUENCE_NO_ASC')}}</span>
                </li>

                <li (click)="sortSourcingData('AUCTION_CREATED_DATE_DESC')" class="dropdown-item mb-1">
                  <input type="checkbox" [checked]="selectedSortBy == 'AUCTION_CREATED_DATE_DESC'" id="check3">
                  <span class="ms-2">{{getSortByDropdownTitle('AUCTION_CREATED_DATE_DESC')}}</span>
                </li>
              </ul>
            </div>
            <div>
            </div>
          </div>

        </div>

        <div class="col-xl-1 px-0 col-md-2 col-3 text-center mb-2" *ngIf="selectedEvent && isShowEventMenu">
          <div class="small-font">Analytics</div>
          <img src="assets/icons/analytic-icon.svg " class=" c-pointer svg-icon-class"
            (click)="navigateToAnalyticsDashboardPage(selectedEvent.eventId!, selectedEvent.type!)">
        </div>
        <div class="col-xl-1 px-0 col-md-2 col-3 text-center mb-2" *ngIf="selectedEvent && isShowEventMenu">
          <div class="small-font">Graph</div>
          <img src="assets/icons/graph-icon.svg" class=" c-pointer svg-icon-small "
            (click)="navigateToGraphGeneratePage(selectedEvent.eventId!, selectedEvent.type!)">
        </div>
        <div class="col-xl-1 px-0 col-md-2 col-3 text-center mb-2 " *ngIf="selectedEvent">
          <div class="small-font">TimeLine</div>
          <img src="assets/icons/timeline-icon.svg" class=" c-pointer svg-icon-class "
            (click)="openAuctionTimeline(selectedEvent.eventId!)">
        </div>
        <div class="col-xl-1 px-0 col-md-2 col-3 text-center mb-2"
          *ngIf="selectedEvent && selectedEvent.status == 'CLOSE'">
          <div class="small-font">Report</div>
          <img src="assets/icons/report-icon.svg" class=" c-pointer svg-icon-small "
            (click)="openReportGenerateModal()">
        </div>
        <div class="col-xl-1 px-0 col-md-2 col-3 text-center  mb-2" *ngIf="selectedEvent && isAllowToDiscard() ">
          <div class="small-font">Discard</div>
          <img src="assets/icons/close_red.svg" class=" c-pointer svg-icon-class-md"
            (click)="selectedEvent.type == 'AUCTION' ? openAuctionDiscardModal(discardAuctionModal) : openRfxDiscardModal(discardRfxModal)">
        </div>
      </div>
    </div>
  </div> -->

  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0 align-middle">
        <thead class="table-dark">
          <tr class="text-center">
            <th class="text-center"><span>#</span></th>
            <th class=""><span>SNo</span></th>
            <th class=""><span>Type</span></th>
            <th class=""><span>Event Id</span></th>
            <th class=""><span>Name</span></th>
            <th class=""><span>Created</span></th>
            <th class=""><span>Starts</span></th>
            <th class=""><span>Ends</span></th>
            <th class=""><span>Timezone</span></th>
            <th class=""><span>Status</span></th>
            <th class=""><span>Action</span></th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr *ngIf="isEventsDataLoading" class="align-middle">
            <td colspan="11" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>

          <tr *ngIf="(filteredSourceEventList$ | async)!.length == 0 && !isEventsDataLoading">
            <td colspan="11" class="text-center">
              <h5 class="text-center">No Data Available</h5>
            </td>
          </tr>

          <tr class="text-center align-middle" *ngFor="let eventDto of (filteredSourceEventList$ | async); index as i">
            <td class=" text-center">
              <input #radioEvent type="radio" name="radioEvent" id="radioEvent"
                (click)="selectedEventData(eventDto, radioEvent)">
            </td>
            <td class="">
              {{i + 1 + (pageSize * (page - 1))}}
            </td>
            <td class="">
              {{eventDto?.type}}
            </td>
            <td> #{{eventDto?.sequenceNo}}</td>
            <td class="">
              {{eventDto?.eventName}}
            </td>
            <td class="">
              {{getCreatedDate(eventDto?.createdDate)}}
            </td>
            <td class="">
              {{getDisplayDate(eventDto?.startDate, eventDto?.startTime)}}
            </td>
            <td class="">
              {{getDisplayDate(eventDto?.endDate, eventDto?.endTime)}}
            </td>
            <td class="">
              {{getTimeZone(eventDto.timeZone!)}}

            </td>
            <td class="ps-0 ">
              <div class="" [ngClass]="{'border-left-success' : eventDto?.status! == 'LIVE', 'border-left-grey' : eventDto?.status! == 'DRAFT' , 'border-left-danger' : eventDto?.status! == 'CLOSE'
            , 'border-left-primary' : eventDto?.status! == 'LIVE_WAIT'}">
                <span class="ms-2">{{getEventStatus(eventDto?.status!)}}</span>
              </div>
            </td>
            <td>
              <a class="link-class" (click)="openEventStagePage(eventDto?.eventId!, eventDto?.type!, i)"
                *ngIf="currentIndex != i">Edit</a>
              <div class="spinner-border text-primary" role="status" *ngIf="currentIndex == i && isNavLoading">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-between mt-3" *ngIf="tempSourcingEvents.length > 0">
      <div class="col">
        <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
          <span class="span-class">Show rows per page </span>
          <span ngbDropdownToggle data-bs-toggle="dropdown">
            <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
          </span>
          <ul ngbDropdownMenu class="text-center shadow">
            <li ngbDropdownItem class="c-pointer" (click)="changePageSize(50)">50</li>
            <li>
              <hr class="dropdown-divider ">
            </li>
            <li ngbDropdownItem class="c-pointer" (click)="changePageSize(100)">100</li>
          </ul>
        </div>
      </div>
      <div class="col-auto">
        <ngb-pagination [collectionSize]="tempSourcingEvents.length!" [(page)]="page" [pageSize]="pageSize"
          (pageChange)="doPaginate()">
        </ngb-pagination>
      </div>
    </div>
  </div>

</div>



<!-- <div class=" bg-white-new" *ngIf="renderView$ | async">

  <div class="container">
    <div class="border p-2 col mb-3 bg-white"  *ngIf="isAuctioneer()">
      <div class="row">
        <div class="col-auto mt-1">
          <img src="assets/icons/home_light.svg" class="svg-icon-class c-pointer">
          <span><img src="assets/icons/forward-icon.svg" class="svg-icon-class-small opacity-svg-icon c-pointer mt-1  ps-2"></span>
        </div>
        <div class="col-auto mt-2 ps-0">
          <span>
            <span class="text-red-500  span-class">
              DASHBOARD
            </span>
          </span>
        </div>


      </div>
    </div>
    <div class="col-12 ">
      <div class="row justify-content-between align-items-center">
        <!-- <div class="col-md-6 mb-2">
          <span class=" text-end mt-2">
            <span class="animate-dropdown" ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom">
              <button class="btn btn-sm dark-btn-class mt-2 mt-sm-0" ngbDropdownToggle data-bs-toggle="dropdown">
                CREATE EVENT  <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-class">
              </button>
              <ul ngbDropdownMenu class="shadow mt-1 ">
                <li class="" *ngFor="let item of eventList"  ngbDropdownItem style="width: 100%; white-space: normal;"
                (click)="openAddAuctionModal(addAuctionModal, auctionRequiredErrorModal)">
                {{item}}
                </li>
              </ul>
            </span>
          </span>
        </div>

        <div class="col-md-6 text-md-end mb-2">
          <div >
            <span class="span-class me-2">{{(gridView$ | async) ? 'Grid View' : 'List View'}}	</span>
            <span class="form-switch c-pointer">
            <input  class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" [checked]="(gridView$ | async)"
            (change)="changeView()"
             /></span>
          </div>
        </div>
        <hr class="hr mb-2" /> -->
<!-- <div class="col-9 m-auto text-center py-2 mb-3 bg-white btn-border">
          <div class="btn btn-sm  btn-border c-pointer button-width-change me-md-3 zoom p-0"
          [ngClass]="selectedStatus == 'LIVE' ? 'selected-tiles' : 'btn-outline-secondary'" (click)="onAuctionTypeTabChange('LIVE')">
        <div class="">
          <div class="row">
            <div class="text-center col-12  ">
              <h5 class="heading-h5 mb-0"  [ngClass]="{'text-white': selectedStatus == 'LIVE'}">
               <b>{{runningAuctionsCount}}</b>
              </h5>
            </div>
            <div class="text-center col-12">
              <h6 class="heading-h6 position-relative text-capitalize"  [ngClass]="{'text-white': selectedStatus == 'LIVE'}">Running</h6>
            </div>
          </div>
        </div>
      </div>
          <div class="btn btn-sm  btn-border c-pointer button-width-change me-md-3 zoom p-0"
              [ngClass]="selectedStatus == 'DRAFT' ? 'selected-tiles' : 'btn-outline-secondary'" (click)="onAuctionTypeTabChange('DRAFT')">
            <div class="">
              <div class="row">
                <div class="text-center col-12  ">
                  <h5 class="heading-h5 mb-0"  [ngClass]="{'text-white': selectedStatus == 'DRAFT'}">
                   <b>{{draftAuctionsCount}}</b>
                  </h5>
                </div>
                <div class="text-center col-12">
                  <h6 class="heading-h6 position-relative text-capitalize"  [ngClass]="{'text-white': selectedStatus == 'DRAFT'}">Draft</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="btn  btn-border c-pointer button-width-change mt-2 mt-md-0 me-md-3 zoom p-0"
          [ngClass]="selectedStatus == 'LIVE_WAIT' ? 'selected-tiles' : 'btn-outline-secondary'" (click)="onAuctionTypeTabChange('LIVE_WAIT')">
            <div class="">
              <div class="row">

                <div class=" col-10 m-auto text-center" >
                  <h5 class="heading-h5 mb-0"  [ngClass]="{'text-white': selectedStatus == 'LIVE_WAIT'}">
                    <b>{{upcomingAuctionsCount}}</b>
                  </h5></div>
                <div class="text-center  col-12">
                  <h6 class="heading-h6 position-relative text-capitalize " [ngClass]="{'text-white': selectedStatus == 'LIVE_WAIT'}">Upcoming</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="btn  btn-border c-pointer button-width-change mt-2 mt-md-0 zoom p-0"
          [ngClass]="selectedStatus == 'CLOSE' ? 'selected-tiles' : 'btn-outline-secondary'" (click)="onAuctionTypeTabChange('CLOSE')">
            <div class="">
              <div class="row">
                <div class=" col-10 m-auto text-center" >
                  <h5 class="heading-h5 mb-0"  [ngClass]="{'text-white': selectedStatus == 'CLOSE'}">
                    <b>{{closedAuctionsCount}}</b></h5></div>
                <div class="text-center  col-12">
                  <h6 class="heading-h6 position-relative text-capitalize" [ngClass]="{'text-white': selectedStatus == 'CLOSE'}">Closed</h6>
                </div>
              </div>
            </div>
          </div>
        </div> -->
<!-- <hr class="hr mb-2" />
          <div class="row mt-3 align-items-center justify-content-end">
            <div class="col-md-3 text-md-center">
              <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="false">
                <button class="btn select-box-btn-class text-start dropdown-toggle mb-2 rounded-0" type="button" data-bs-toggle="dropdown" ngbDropdownToggle
                  aria-expanded="false">
              <div class="row">
                <div class="col-9">
                  <div class="overflow-class-sort">
                    {{getSortByDropdownTitle(selectedSortBy!)}}
                  </div>

                </div>
                <div class="col-3 text-end">
                   <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-small">
                </div>
              </div>
                </button>
                <ul class="dropdown-menu text-start shadow mt-5 dropdown-width1 " ngbDropdownMenu>
                  <li>
                    <div class="col-12 text-end px-2">

                      <img src="assets/icons/close_light.svg" class="svg-icon-class c-pointer" (click)="statusNgbDropdown1.close()">
                    </div>
                  </li>
                  <li  (click)="sortAuctionsData('AUCTION_END_DATE_DESC')"   class="dropdown-item mb-1" >
                  <input type="checkbox" [checked]="selectedSortBy == 'AUCTION_END_DATE_DESC'">
                   <span class="ms-2">{{getSortByDropdownTitle('AUCTION_END_DATE_DESC')}} </span></li>

                   <li  (click)="sortAuctionsData('AUCTION_SEQUENCE_NO_ASC')"  class="dropdown-item mb-1">
                    <input type="checkbox" [checked]="selectedSortBy == 'AUCTION_SEQUENCE_NO_ASC'">
                    <span class="ms-2">{{getSortByDropdownTitle('AUCTION_SEQUENCE_NO_ASC')}}</span>
                    </li>

                    <li  (click)="sortAuctionsData('AUCTION_CREATED_DATE_DESC')"  class="dropdown-item mb-1">
                      <input type="checkbox" [checked]="selectedSortBy == 'AUCTION_CREATED_DATE_DESC'">
                      <span class="ms-2">{{getSortByDropdownTitle('AUCTION_CREATED_DATE_DESC')}}</span>
                      </li>
                </ul>
              </div>
          <div>
          </div>
            </div>
            <div class="col-md-3 text-md-end pe-4 pe-md-0">
              <div class="mb-2  input-group">
                <input #search placeholder="Search" type="text" [formControl]="ctrlSearchAuction"
                  class="form-control border-end-0 rounded-0 border bg-white"  />
                <span class="input-group-append">
                  <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                    <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
                  </button>
                </span>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <div class=" bg-white-new">
  <div class="container">
    <hr class="hr mb-2" />
    <div>
      <div class="col-12 p-2">
        <div *ngIf="gridView$ | async">
          <div class="row justify-content-center" *ngIf="isEventsDataLoading">
            <div class="col-12 text-center">
              <div class="e-spinner"></div>
            </div>
          </div>
          <div class="row"
            *ngIf="(filteredSourceEventList$ | async) && (filteredSourceEventList$ | async)!.length > 0 && !isEventsDataLoading">
            <div class="col-lg-4 mb-4" *ngFor="let eventDto of filteredSourceEventList$ | async">
              <div class="card c-pointer shadow zoom border-0 card-hover  card-ripple" (click)="navigateToCreatorDashboard(eventDto?.eventId!)">

                <div class="" [ngClass]="getTopBorderClass(eventDto.type!)"></div>
                <div class="row p-2">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-10">
                        <h5 class="heading-h5 overflow-class">#{{eventDto?.sequenceNo}} {{eventDto?.eventName}}</h5>
                      </div>
                      <div class="col-2 text-end z-index-class">
                        <div class="animate-dropdown" ngbDropdown #menuDropdown="ngbDropdown" container="body"
                          placement="bottom-right">
                          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800-small" ngbDropdownToggle data-bs-toggle="dropdown"
                            (click)="openAuctionCardMenu($event, menuDropdown)">
                            <img src="assets/icons/elipsis_vertical_dark.svg" class=" c-pointer svg-icon-class-small">
                          </button>
                          <ul ngbDropdownMenu class="shadow mt-1 ">
                            <li ngbDropdownItem class="c-pointer" (click)="navigateToCreatorDashboard(eventDto.eventId!)">
                              <a>
                                <img src="assets/icons/edit_light.svg" class=" c-pointer svg-icon-class-md"> AUCTION DETAILS
                              </a>
                            </li>
                            <hr class="hr my-1" *ngIf="isShowButton(eventDto.type!)" />
                            <li ngbDropdownItem class="c-pointer" *ngIf="isShowButton(eventDto.type!)"
                              (click)="navigateToRegistrationDashboard(eventDto.eventId!)">
                              <a>
                                <img src="assets/icons/bidder_light.svg" class=" c-pointer svg-icon-class-md">
                                REGISTRATION
                              </a>
                            </li>
                            <hr class="hr my-1" *ngIf="isShowButton(eventDto?.type!)" />
                            <li ngbDropdownItem class="c-pointer" *ngIf="isShowButton(eventDto?.type!)"
                              (click)="navigateToBidingDashboard(eventDto.eventId!)">
                              <a>
                                <img src="assets/icons/gavel_light.svg" class=" c-pointer svg-icon-class-md"> BIDDING
                              </a>
                            </li>
                            <hr class="hr my-1" *ngIf="isShowButton(eventDto.type!)" />
                            <li ngbDropdownItem class="c-pointer" (click)="navigateToQuestionDashboard(eventDto.eventId!)"
                              *ngIf="isShowButton(eventDto.type!)">
                              <a>
                                <img src="assets/icons/question.svg" class=" c-pointer svg-icon-class-md"> QUESTIONS
                              </a>
                            </li>
                            <hr class="hr my-1" />
                            <li ngbDropdownItem class="c-pointer" (click)="navigateAuctionView(eventDto.eventId!)">
                              <a>
                                <img src="assets/icons/view_light.svg" class=" c-pointer svg-icon-class"> LISTING
                              </a>
                            </li>
                            <hr class="hr my-1" />
                            <li ngbDropdownItem class="c-pointer" (click)="navigateToGraphGeneratePage(eventDto.eventId!)">
                              <a>
                                <img src="assets/icons/graph-icon.svg" class=" c-pointer svg-icon-class"> GRAPH STATS
                              </a>
                            </li>
                            <hr class="hr my-1" *ngIf="isShowAuctionDashboardMenu(eventDto.noOfActiveActivity!)" />
                            <li ngbDropdownItem class="c-pointer" (click)="navigateToAnalyticsDashboardPage(eventDto.eventId!)"
                              *ngIf="isShowAuctionDashboardMenu(eventDto.noOfActiveActivity!)">
                              <a>
                                <img src="assets/icons/dashboard-icon.svg" class=" c-pointer svg-icon-class"> ANALYTICS
                                DASHBOARD
                              </a>
                            </li>
                            <hr class="hr my-1 d-none d-md-block" *ngIf="deploymentConfigurationDto?.enableClerkDashboard" />
                            <li ngbDropdownItem class="c-pointer d-none d-md-block" (click)="navigateToClerkDashboardPage(eventDto.eventId!)"
                              *ngIf="deploymentConfigurationDto?.enableClerkDashboard">
                            <a>
                              <img src="assets/icons/clerk.svg" class=" c-pointer svg-icon-class"> CLERK
                              DASHBOARD
                            </a>
                          </li>
                          <hr class="hr my-1"/>
                          <li ngbDropdownItem class="c-pointer" (click)="openAuctionTimeline(eventDto.eventId!)">
                            <a>
                              <img src="assets/icons/timeline.svg" class=" c-pointer svg-icon-class"> AUCTION TIMELINE
                            </a>
                          </li>
                            <!-- <hr class="hr my-1" />
                            <li ngbDropdownItem class="c-pointer" *ngIf="auction.status == 'LIVE' && auction?.liveStreaming"
                              (click)="resumeAuctionStreaming(auction, streamingMetadataTemplate)">
                              <a>
                                <img src="assets/icons/video-icon.svg" class=" c-pointer svg-icon-class">RESUME STREAMING
                              </a>
                            </li>
                            <li ngbDropdownItem class="c-pointer" *ngIf="auction.status == 'LIVE' && !auction?.liveStreaming"
                              (click)="openAuctionStreaming(auction, streamingConfirmationModal)">
                              <a>
                                <img src="assets/icons/video-icon.svg" class=" c-pointer svg-icon-class">START STREAMING
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center " >
                      <div class="div-class mt-1 col-md-6">
                        <span *ngIf="!eventDto?.singleActivity"> {{eventDto?.noOfActiveActivity}} / {{eventDto?.notOfActivity}} Activities</span>
                        <span *ngIf="eventDto?.singleActivity">Single lot</span>
                      </div>
                      <div class="col-xxl-6  my-2 text-xxl-end col-lg-12 text-lg-start text-center  col-md-6 text-md-end">
                        <span class="button-class me-2"
                          [ngClass]="{'badge-warning-class' : eventDto?.type == 'AUCTION'}">
                          {{eventDto?.type == 'AUCTION'? 'AUCTION' : ''}}
                        </span>

                      </div>


                    </div>


                    <div class="row justify-content-center ">
                      <div class="col-md-4  div-class mt-2 px-0">
                        <b class="b-class">CREATED</b>
                        <div class="div-class-small">
                          {{getCreatedDate(eventDto?.createdDate)}}
                        </div>
                      </div>
                      <div class="col-md-4  div-class mt-2 px-1">
                        <b class="b-class">START</b>


                        <div class="div-class-small">
                          {{getDisplayDate(eventDto?.startDate, eventDto?.startTime)}}
                        </div>
                      </div>
                      <div class="col-md-4  div-class mt-2 px-1" *ngIf="eventDto?.endDate">
                        <b class="b-class">END</b>

                        <div class="div-class-small">
                          {{getDisplayDate(eventDto?.endDate, eventDto?.endTime)}}
                        </div>
                      </div>
                    </div>
                    <hr class="hr my-2" />
                    <div class="div-class-small mt-2 text-center ">
                      {{getTimeZone(eventDto.timeZone!)}}
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="mt-5 text-center" style="margin-left: 6rem !important" *ngIf="((filteredSourceEventList$ | async)?.length == showMoreButtonSize)">
            <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5 " type="button"
                      (click)="showMore()">
                      Show More
                    </button>
              </div>
          </div>
        </div>

        <div class="table-responsive bg-white" *ngIf="!(gridView$ | async)">
          <table class="table table-bordered mb-0 align-middle"
            *ngIf="(filteredSourceEventList$ | async) && (filteredSourceEventList$ | async)!.length > 0">
            <thead class="table-dark">
              <tr>
                <th class="text-center"><span>#</span></th>
                <th class=""><span >Name</span></th>
                <th class=""><span >Created</span></th>
                <th class=""><span >Start</span></th>
                <th class=""><span >End</span></th>
                <th class=""><span>Timezone</span></th>
                <th class=" text-center"><span >Status</span></th>
                <th class=" text-center"><span>Action</span></th>
              </tr>
            </thead>
            <tbody *ngFor="let auction of filteredSourceEventList$ | async index as i">

              <tr>
                <td class="">
                  {{i+1}}
                </td>
                <td class="">

                  #{{auction?.sequenceNo}} {{auction?.eventName}}
                </td>
                <td class="" (click)="navigateToCreatorDashboard(auction.eventId!)">
                  {{getCreatedDate(auction?.createdDate)}}
                </td>
                <td class="" (click)="navigateToCreatorDashboard(auction.eventId!)">
                  {{getDisplayDate(auction?.startDate, auction?.startTime)}}
                </td>
                <td class="" (click)="navigateToCreatorDashboard(auction.eventId!)">
                  {{getDisplayDate(auction?.endDate, auction?.endTime)}}
                </td>
                <td class="" (click)="navigateToCreatorDashboard(auction.eventId!)">
                  Timezone : {{getTimeZone(auction.timeZone!)}}
                </td>
                <td class=" text-center mt-2">
                  <span class="button-class me-2" [ngClass]="{'badge-warning-class' : auction?.type == 'AUCTION'}">
                    {{auction?.type == 'AUCTION' ? 'AUCTION' : ''}}
                  </span>

                </td>
                <td class=" text-center">
                  <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                    <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800-small" ngbDropdownToggle data-bs-toggle="dropdown">
                      <img src="assets/icons/elipsis_vertical_dark.svg" class=" c-pointer svg-icon-class-small">
                    </button>
                    <ul ngbDropdownMenu class="shadow mt-1">
                      <li ngbDropdownItem>
                        <span class="badge multilot-badge rounded-pill"
                          [ngClass]="auction?.active ? 'bg-primary' : 'bg-danger'">
                          {{auction?.active ? 'Active' : 'Inactive'}}
                        </span>
                      </li>
                      <hr class="hr my-1" />
                      <li ngbDropdownItem class="c-pointer ngbDropdownItem-hover"
                        (click)="navigateToCreatorDashboard(auction.eventId!)">
                        <a>
                          <img src="assets/icons/edit_light.svg" class=" c-pointer svg-icon-class-md"> AUCTION DETAILS
                        </a>
                      </li>
                      <hr class="hr my-1" *ngIf="isShowButton(auction.type!)" />
                      <li ngbDropdownItem class="c-pointer ngbDropdownItem-hover" *ngIf="isShowButton(auction.type!)"
                        (click)="navigateToRegistrationDashboard(auction.eventId!)">
                        <a>
                          <img src="assets/icons/bidder_light.svg" class=" c-pointer svg-icon-class-md">
                          REGISTRATION
                        </a>
                      </li>
                      <hr class="hr my-1" *ngIf="isShowButton(auction.type!)" />
                      <li ngbDropdownItem class="c-pointer" *ngIf="isShowButton(auction.type!)"
                        (click)="navigateToBidingDashboard(auction.eventId!)">
                        <a>
                          <img src="assets/icons/gavel_light.svg" class=" c-pointer svg-icon-class-md"> BIDDING
                        </a>
                      </li>
                      <hr class="hr my-1" *ngIf="isShowButton(auction.type!)" />
                      <li ngbDropdownItem class="c-pointer" (click)="navigateToQuestionDashboard(auction.eventId!)"
                        *ngIf="isShowButton(auction.type!)">
                        <a>
                          <img src="assets/icons/question.svg" class=" c-pointer svg-icon-class-md"> QUESTIONS
                        </a>
                      </li>
                      <hr class="hr my-1" />
                      <li ngbDropdownItem class="c-pointer" (click)="navigateAuctionView(auction.eventId!)">
                        <a>
                          <img src="assets/icons/view_light.svg" class=" c-pointer svg-icon-class"> LISTING
                        </a>
                      </li>
                      <hr class="hr my-1" />
                      <li ngbDropdownItem class="c-pointer" (click)="navigateToGraphGeneratePage(auction.eventId!)">
                        <a>
                          <img src="assets/icons/graph-icon.svg" class=" c-pointer svg-icon-class"> GRAPH STATS
                        </a>
                      </li>
                      <hr class="hr my-1" *ngIf="isShowAuctionDashboardMenu(auction.noOfActiveActivity!)" />
                      <li ngbDropdownItem class="c-pointer" (click)="navigateToAnalyticsDashboardPage(auction.eventId!)"
                        *ngIf="isShowAuctionDashboardMenu(auction.noOfActiveActivity!)">
                        <a>
                          <img src="assets/icons/dashboard-icon.svg" class=" c-pointer svg-icon-class"> ANALYTICS DASHBOARD
                        </a>
                      </li>
                      <hr class="hr my-1 d-none d-md-block" *ngIf="deploymentConfigurationDto?.enableClerkDashboard" />
                      <li ngbDropdownItem class="c-pointer d-none d-md-block" (click)="navigateToClerkDashboardPage(auction.eventId!)"
                        *ngIf="deploymentConfigurationDto?.enableClerkDashboard">
                        <a>
                          <img src="assets/icons/clerk.svg" class=" c-pointer svg-icon-class"> CLERK
                          DASHBOARD
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-12 p-2 text-center"
          *ngIf="!(filteredSourceEventList$ | async) || (filteredSourceEventList$ | async) && (filteredSourceEventList$ | async)!.length == 0 && !isEventsDataLoading">
          <h5 class="heading-new text-dark">No auction created yet, please create new.</h5>
        </div>
      </div>
    </div>
  </div>
</div> -->


<ng-template #discardAuctionModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Auction Discard</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(auctionDiscardModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>Are you sure you want to discard the selected auction? you will not be able to work on this auction once
            discarded and all lots associated with auction will also get discarded.</p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Auction discarded successfully."></app-message-toast>
        </div>

        <div class="text-end mb-3" *ngIf="selectedEvent && !isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal(auctionDiscardModalRef)"
            *ngIf="!isShowCloseModalButton">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="discardAuction()"
            *ngIf="!isShowCloseModalButton" [disabled]="!isAllowToDiscard()">DISCARD</button>

          <button type="button" class="btn btn-sm btn-outline-primary" (click)="closeModal(auctionDiscardModalRef)"
            *ngIf="isShowCloseModalButton">Close</button>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #discardRfxModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Rfx Discard</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(rfxDiscardModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>Are you sure you want to discard the selected rfx? you will not be able to work on this rfx once
            discarded and all lots associated with rfx will also get discarded.</p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Rfx discarded successfully."></app-message-toast>
        </div>

        <div class="text-end mb-3" *ngIf="selectedEvent && !isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal(rfxDiscardModalRef)"
            *ngIf="!isShowCloseModalButton">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="discardRfx()"
            *ngIf="!isShowCloseModalButton" [disabled]="!isAllowToDiscard()">DISCARD</button>

          <button type="button" class="btn btn-sm btn-outline-primary" (click)="closeModal(rfxDiscardModalRef)"
            *ngIf="isShowCloseModalButton">Close</button>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>



<!-- AUCTION ACTIVATION MODAL -->
<!-- <ng-template #activeAuction>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>{{selectedEvent?.active ? 'DISABLE' : 'ENABLE'}} AUCTION </h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>

    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Do you Want to {{selectedEvent?.active ? 'Disable' : 'Enable' }} Auction</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
          message="Auction {{selectedEvent?.buyItNowEnable ? 'Enable' : 'Disable'}}  Successfully'"></app-message-toast>
      </div>

      <div class="text-end mb-3" *ngIf="selectedEvent && !isLoading">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-2" (click)="closeModal()">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary"
          (click)="markAuctionActiveOrInActive('ENABLE', auctionValidationsModal)" *ngIf="!selectedEvent?.active"
          [disabled]="!(_isSaveButtonEnable$ | async)">ENABLE</button>
        <button type="button" class="btn btn-sm btn-outline-primary"
          (click)="markAuctionActiveOrInActive('DISABLE', auctionValidationsModal)" *ngIf="selectedEvent?.active"
          [disabled]="!(_isSaveButtonEnable$ | async)">DISABLE</button>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
      </div>
    </div>
  </div>
</ng-template> -->


<p-dialog header="Select Filter" [(visible)]="isFiter" [style]="{width: '50vw'}">
  <hr class="hr">
  <div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="col-12 mb-3">
          <div>
            <div>
              Event Type:
            </div>
            <div class="col-12 mt-3">
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventTypeFilterSelected('AUCTION') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventType('AUCTION')">
                Auction
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventTypeFilterSelected('RFI') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventType('RFI')">
                RFI
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventTypeFilterSelected('RFQ') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventType('RFQ')">
                RFQ
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventTypeFilterSelected('RFP') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventType('RFP')">
                RFP
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventTypeFilterSelected('TENDER') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventType('TENDER')">
                Tender
              </span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div>
            <div>
              Status Type:
            </div>
            <div class="col-12 mt-3">
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('DRAFT') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('DRAFT')">
                Draft
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('LIVE_WAIT') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('LIVE_WAIT')">
                Upcoming
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('LIVE') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('LIVE')">
                Live
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('CLOSE') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('CLOSE')">
                Closed
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('POST_EVENT') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('POST_EVENT')">
                Post Event
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('CONTRACT') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('CONTRACT')">
                Contract
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('PO') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('PO')">
                PO
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('INVOICED') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('INVOICED')">
                Invoiced
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('PAID') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('PAID')">
                Paid
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="hr my-3">
    <div class="text-end ">
      <button class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 me-3" (click)="clearAllFilters()">Clear All Filters</button>
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        [disabled]="selectedEventTypeFilter.length == 0 && selectedEventStatusFilter.length == 0"
        (click)="handleFilterAndSortData()">Save</button>
    </div>
  </div>
</p-dialog>
<!-- <ng-template #filterModal>

  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>SELECT FILTER</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="col-12 mb-3">
          <div>
            <div>
              Event Type:
            </div>
            <div class="col-12 mt-3">
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventTypeFilterSelected('AUCTION') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventType('AUCTION')">
                Auction
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventTypeFilterSelected('RFI') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventType('RFI')">
                RFI
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventTypeFilterSelected('RFQ') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventType('RFQ')">
                RFQ
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventTypeFilterSelected('RFP') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventType('RFP')">
                RFP
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventTypeFilterSelected('TENDER') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventType('TENDER')">
                Tender
              </span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div>
            <div>
              Status Type:
            </div>
            <div class="col-12 mt-3">
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('DRAFT') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('DRAFT')">
                Draft
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('LIVE_WAIT') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('LIVE_WAIT')">
                Upcoming
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('LIVE') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('LIVE')">
                Live
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('CLOSE') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('CLOSE')">
                Closed
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('POST_EVENT') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('POST_EVENT')">
                Post Event
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('CONTRACT') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('CONTRACT')">
                Contract
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('PO') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('PO')">
                PO
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('INVOICED') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('INVOICED')">
                Invoiced
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="isEventStatusFilterSelected('PAID') ? 'selected-event' : 'select-event'"
                (click)="selectFilterEventStatus('PAID')">
                Paid
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 text-end mt-3">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="clearAllFilters()">Clear All Filters</button>
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            [disabled]="selectedEventTypeFilter.length == 0 && selectedEventStatusFilter.length == 0"
            (click)="handleFilterAndSortData()">Save</button>
        </div>

      </div>
    </div>


  </div>
</ng-template> -->