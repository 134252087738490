import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { Firestore, Timestamp, collection, onSnapshot, where, query } from '@angular/fire/firestore';
import { Unsubscribe } from '@angular/fire/auth';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { EventStages } from 'src/app/shared/enums/EventStages';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { Store } from '@ngrx/store';
import { NavigationEventsDto } from 'src/app/shared/models/NavigationEventsDto';
import { NavigationActions } from 'src/app/shared/state-management/navigation/navigation.actions';

@Component({
  selector: 'app-rfx-creator',
  templateUrl: './rfx-creator.component.html',
  styleUrls: ['./rfx-creator.component.sass']
})
export class RfxCreatorComponent implements OnInit, OnDestroy {
  @Input() currentEventStage$ = new Observable<EventStages>();
  IsNoImage = true;

  selectedRfxSubscription$?: Subscription;
  currentTab: number = 1

  _auctionHouseDataLoadedSubscription$?: Subscription;
  _allRfxDataLoadedSubscription$?: Subscription;
  _allRfxSubcategoriesDataLoadedSubscription$?: Subscription;
  _masterDataLoadedSubscription$?: Subscription;
  currentEventStageSubscription$?: Subscription;



  _organizationDataLoaded: boolean = false;
  _allEventsDataLoaded: boolean = false;
  _allRfxSubcategoryDataLoaded: boolean = false;
  _masterDataLoaded: boolean = false;

  activeStage: EventStages = EventStages.EVENT;

  collectionRfx: string = "RFX";
  rfxListenerUnsubscribe: Unsubscribe | undefined;
  collectionRfxSubcategory: string = "RFX_SUBCATEGORY";

  rfxSubcategoryListenerUnsubscribe: Unsubscribe | undefined;

  isFirestoreListenerAttached: boolean = false;

  _renderView$ = new BehaviorSubject<boolean>(false);
  rfxEntityDto?: RfxUiDto;

  constructor(
    public firestore: Firestore,
    private changeDetectorRef: ChangeDetectorRef,
    private dataRedirectionService: DashboardRedirectionService,
    private ngbModal: NgbModal,
    private onboardingService: OnboardingService,
    public adminService: AdminDashboardService,
    public adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    public adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private store: Store
  ) { }

  ngOnInit(): void {

    let selectedTabName = sessionStorage.getItem("TAB_NAME");

    if (selectedTabName == ApplicationConstants.RFX_REGISTRATION_TAB) {
      this.currentTab = 2;
      let rfx = this.adminSourcingEventsDataHolderService.getSelectedRfx();
      this.adminService.loadBidderRegistrationList(rfx!.rfxId!);
    } else if (selectedTabName == ApplicationConstants.RFX_BIDDING_TAB) {
      this.currentTab = 3;
    } else if (selectedTabName == ApplicationConstants.RFX_QUESTIONS_TAB) {
      this.currentTab = 4;
    } else if (selectedTabName == ApplicationConstants.RFX_POST_AUCTION_TAB) {
      this.currentTab = 5;
    } else {
      this.currentTab = 1;
    }

    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(async (data) => {
      if (data) {
        this.rfxEntityDto = data;
        //this.adminSourcingEventsDataHolderService.updatePostAuctionList(this.rfxEntityDto);
        this.changeDetectorRef.detectChanges();
        if (!this.isFirestoreListenerAttached) {
          this.attachRfxSubcategoryChangesListener();
          this.isFirestoreListenerAttached = true;
        }
      }
    })

    this._auctionHouseDataLoadedSubscription$ = this.adminService.getOrganizationDataLoaded$.subscribe((data) => {
      if (data) {
        this._organizationDataLoaded = data;
        this.renderView();
      }
    })

    this._allRfxDataLoadedSubscription$ = this.adminSourcingEventsDataHolderService.getAllEventsDataLoaded$.subscribe((data) => {
      if (data) {
        this._allEventsDataLoaded = data;
        this.renderView();
      }
    })

    this._allRfxSubcategoriesDataLoadedSubscription$ = this.adminService.getAllRfxSubcategoryDataLoaded$.subscribe((data) => {
      if (data) {
        this._allRfxSubcategoryDataLoaded = data;
        this.renderView();
      }
    })

    this._masterDataLoadedSubscription$ = this.adminService.getMasterDataLoaded$.subscribe((data) => {
      if (data) {
        this._masterDataLoaded = data;
        this.renderView();
      }
    })

    this.currentEventStageSubscription$ = this.currentEventStage$.subscribe(currentEvent => {
      if (currentEvent) {
        this.activeStage = currentEvent;
        this.toggleDetailsTab(1);
      }
    })
  }

  toggleDetailsTab(tab: number) {
    this.currentTab = tab;
    let rfxEntityDto = this.adminSourcingEventsDataHolderService.getSelectedRfx();
    
    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.ADMIN;
    navigation.pageName = ApplicationConstants.RFX_CREATOR_PAGE;

    if (this.currentTab == 1) {
      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.RFX_CREATOR_PAGE,
      //   ApplicationConstants.RFX_TAB,
      //   `${rfxEntityDto?.sequenceNo}`
      // );

      navigation.tabName = ApplicationConstants.RFX_TAB;
    } else if (this.currentTab == 2) {
      this.adminService.loadBidderRegistrationList(rfxEntityDto?.rfxId!);

      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.RFX_CREATOR_PAGE,
      //   ApplicationConstants.REGISTRATION_TAB,
      //   `${rfxEntityDto?.sequenceNo}`
      // );
      
      navigation.tabName = ApplicationConstants.REGISTRATION_TAB;
    } else if (this.currentTab == 3) {
      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.RFX_CREATOR_PAGE,
      //   ApplicationConstants.RFX_BIDDING_TAB,
      //   `${rfxEntityDto?.sequenceNo}`
      // );
      
      navigation.tabName = ApplicationConstants.RFX_BIDDING_TAB;
    } else if (this.currentTab == 4) {
      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.RFX_CREATOR_PAGE,
      //   ApplicationConstants.RFX_QUESTIONS_TAB,
      //   `${rfxEntityDto?.sequenceNo}`
      // );
      
      navigation.tabName = ApplicationConstants.RFX_QUESTIONS_TAB;
    } else if (this.currentTab == 5) {
      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.RFX_CREATOR_PAGE,
      //   ApplicationConstants.RFX_POST_AUCTION_TAB,
      //   `${rfxEntityDto?.sequenceNo}`
      // );
      
      navigation.tabName = ApplicationConstants.RFX_POST_AUCTION_TAB;
    }

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
  }

  renderView() {
    if (this._organizationDataLoaded && this._allRfxSubcategoryDataLoaded && this._masterDataLoaded) {
      this._renderView$.next(true);
    }
  }

  uploadBannerImage(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    }
    );
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  attachRfxSubcategoryChangesListener() {
    let lastFetchTimestamp = Timestamp.now();

    logEvent(getAnalytics(), "attachRfxSubcategoryChangesListener");

    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let rfxSubcategoryRef = collection(this.firestore, this.collectionRfxSubcategory);
      let rfxSubcategoryQuery = query(rfxSubcategoryRef,
        where('orgCode', '==', orgCode),
        where('rfxId', '==', this.rfxEntityDto!.rfxId),
        where('updateTimeUtc', '>', lastFetchTimestamp));

      this.rfxSubcategoryListenerUnsubscribe = onSnapshot(rfxSubcategoryQuery, documentSnapshots => {
        let rfxSubcategoryList = documentSnapshots.docChanges().map(change => change.doc.data() as RfxSubcategoryUiDto);

        logEvent(getAnalytics(), 'attachRfxSubcategoryChangesListener Data Received size : ' + rfxSubcategoryList.length);
        console.log("attachRfxSubcategoryChangesListener Data Received size: " + rfxSubcategoryList.length);

        if (rfxSubcategoryList && rfxSubcategoryList.length > 0) {
          this.adminRfxSubcategoryDataHolderService.updateSubcategoriesChangesData(rfxSubcategoryList)
        }
        this.changeDetectorRef.detectChanges()
      });
    }
  }


  navigateToHomePage() {
    this.dataRedirectionService.navigationToHomePage();
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }

    if (this._auctionHouseDataLoadedSubscription$) {
      this._auctionHouseDataLoadedSubscription$.unsubscribe();
    }

    if (this._allRfxDataLoadedSubscription$) {
      this._allRfxDataLoadedSubscription$.unsubscribe();
    }

    if (this._allRfxSubcategoriesDataLoadedSubscription$) {
      this._allRfxSubcategoriesDataLoadedSubscription$.unsubscribe();
    }

    if (this._masterDataLoadedSubscription$) {
      this._masterDataLoadedSubscription$.unsubscribe();
    }

    this.currentTab = 1;

    if (this.rfxListenerUnsubscribe) {
      this.rfxListenerUnsubscribe();
    }

    if (this.rfxSubcategoryListenerUnsubscribe) {
      this.rfxSubcategoryListenerUnsubscribe();
    }

  }

}

