import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { AdminUserPrivilege } from 'src/app/shared/enums/AdminUserPrivilege';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';

import { AddUserDto } from 'src/app/shared/models/user/AddAdminDto';
import { AdminDataUIWrapperDto } from 'src/app/shared/models/user/AdminDataUIWrapperDto';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';
import { AdminUserActivityComponent } from '../admin-user-activity/admin-user-activity.component';
import { Store } from '@ngrx/store';
import { selectUserUiDto } from 'src/app/shared/state-management/session.features';


@Component({
  selector: 'app-admin-admin-list-section',
  templateUrl: './admin-admin-list-section.component.html',
  styleUrls: ['./admin-admin-list-section.component.sass']
})
export class AdminAdminListSectionComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  ctrlSearchAdmin: FormControl = new FormControl('');

  _filteredUsers$ = new BehaviorSubject<Array<UserUiDto>>([]);
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _isPasswordMatch$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _showErrorMsg$ = new BehaviorSubject<string>("");

  adminStatus: boolean = false;
  isLoading: boolean = false;
  actionName: string = '';
  isPasswordVisible: boolean = false;
  isRepeatPasswordVisible: boolean = false;
  userEntityDto?: UserUiDto;

  adminUIWrapper?: AdminDataUIWrapperDto;
  activeUserEntityInfoDto?: UserUiDto;
  adminDashboardDataSubscription$?: Subscription;

  userActiveFlag: boolean = false;

  constructor(
    private adminDashboardService: AdminDashboardService,
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    public datePipe: DatePipe,
    private store: Store
  ) {
    this.formGroup = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      emailId: new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]),
      password: new FormControl('', [Validators.required, Validators.minLength(9), PasswordStrengthValidator]),
      confirmPassword: new FormControl('', [Validators.required])
    })
  }

  ngOnInit(): void {
    this.loadCurrentUser();

    this.adminDashboardDataSubscription$ = this.adminDashboardService.getAdminDashboardData$.subscribe((data) => {
      if (data) {
        this.adminUIWrapper = data;
        this.searchAdminData();
      }
    })

    this.ctrlSearchAdmin.valueChanges.subscribe((value) => {
      if (value) {
        this.searchAdminData()
      }
    });
  }

  get fc(): any { return this.formGroup.controls; }

  async loadCurrentUser() {
    this.userEntityDto = await firstValueFrom(this.store.select(selectUserUiDto));
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleRepeatPasswordVisibility() {
    this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
  }

  openAddModal(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }
  openUserActivityModal(userId: string) {
    let modelRef = this.ngbModal.open(AdminUserActivityComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
    modelRef.componentInstance.userId = userId;
  }

  openDisableUserModal(content: any, activeUserEntityInfoDto: UserUiDto, actionName: string) {
    this.userActiveFlag = activeUserEntityInfoDto.active;
    if (this.userEntityDto && this.userEntityDto.userId == activeUserEntityInfoDto.userId) {
      // toggleRef.checked = activeUserEntityInfoDto.active;
      return;
    }

    this.activeUserEntityInfoDto = activeUserEntityInfoDto;
    this.adminStatus = activeUserEntityInfoDto.active;
    this.actionName = actionName;
    let modalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });

    modalRef.result.then((result) => {
      // toggleRef.checked = this.adminStatus;
    }, (reason) => {
      // toggleRef.checked = this.adminStatus;
    });
  }

  getMemberSinceYear(year: string) {
    if (year) {
      return ApplicationUtils.getDateInMMMddyyyy(year, this.datePipe)
    }
    return '';
  }

  validateFormGroupData() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  onChange() {
    // this.authService.errorMsg = null!;
    if (this.formGroup.controls['password'].value == this.formGroup.controls['confirmPassword'].value) {
      this._isPasswordMatch$.next(true);
    } else {
      this._isPasswordMatch$.next(false);
    }
  }

  populateSuperAdminData() {
    let addSuperAdminDto = new AddUserDto();
    addSuperAdminDto.firstName = this.formGroup?.controls['firstName'].value!;
    addSuperAdminDto.lastName = this.formGroup?.controls['lastName'].value!;
    addSuperAdminDto.emailId = this.formGroup?.controls['emailId'].value!;
    addSuperAdminDto.password = window.btoa(this.formGroup?.controls['password'].value!);
    addSuperAdminDto.userType = 'AUCTION_HOUSE_ADMIN';
    addSuperAdminDto.auctionHouseId = this.adminUIWrapper?.auctionHouseDto?.orgCode;
    return addSuperAdminDto;
  }

  handleValidSubmit() {
    this._showErrorMsg$.next("");
    this._showErrorToast$.next(false);

    if (this.validateFormGroupData() || this._isPasswordMatch$.value == false) {
      return;
    }
    if (this.formGroup?.dirty) {
      this.isLoading = true;
      this._isSaveButtonEnable$.next(false);
      let superAdminDto = this.populateSuperAdminData();
      this.adminDashboardService.addAdmin(superAdminDto).subscribe({
        next: (apiResponseDto: ServerAPIResponseDto) => {
          if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
            this.updateUserData(apiResponseDto.data as UserUiDto)
            this._showSuccessToast$.next(true);
            this._isSaveButtonEnable$.next(true);
            this.isLoading = false;
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this._isSaveButtonEnable$.next(true);
              this.closeModal();
            }, 2000)
          } else {
            if (apiResponseDto.code == "USER-103") {
              this._showErrorMsg$.next("Error while adding user. Email already exists.");
            } else {
              this._showErrorMsg$.next(apiResponseDto.message as string);
            }
            this._showErrorToast$.next(true);
            this._isSaveButtonEnable$.next(true);
            this.isLoading = false;
          }
        },
        error: (err) => {
          console.error(err);
          this._showErrorMsg$.next("Error while adding user. Try again.");
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);
        }
      })
    }
  }

  markResourceActiveOrInActive(action: string) {
    this._showErrorToast$.next(false);
    let active = action == "ENABLE";
    this.isLoading = true;

    this.adminDashboardService.markResourceActiveOrInActive(this.activeUserEntityInfoDto?.userId!, active, 'ADMIN').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.updateUserData(apiResponseDto.data as UserUiDto);
          this._showSuccessToast$.next(true);
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.isLoading = false;
            this.adminStatus = active;
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err: any) => {
        console.error(err);
        this.isLoading = false;
      }
    })
  }

  updateUserData(userUiDto: UserUiDto) {
    let data = null;
    // 1. company - selectecd company

    if (ApplicationUtils.checkIfRoleIsAssigned(userUiDto?.userPrivileges!, AdminUserPrivilege.IT_SETUP)) {
      if (this.adminUIWrapper && this.adminUIWrapper?.adminList) {
        data = this.adminUIWrapper?.adminList?.find(item => item.userId == userUiDto.userId);
      } else {
        this.adminUIWrapper!.adminList = [];
      }

    } else {
      if (this.adminUIWrapper && this.adminUIWrapper?.agentList) {
        data = this.adminUIWrapper?.agentList?.find(item => item.userId == userUiDto.userId);
      } else {
        this.adminUIWrapper!.agentList = [];
      }

    }

    if (data) {
      Object.assign(data!, userUiDto);
    } else {
      if (ApplicationUtils.checkIfRoleIsAssigned(userUiDto?.userPrivileges!, AdminUserPrivilege.IT_SETUP)) {
        this.adminUIWrapper?.adminList?.push(userUiDto);
      } else {
        this.adminUIWrapper?.agentList?.push(userUiDto);
      }

    }

    this.adminDashboardService._adminDashboardData$.next(this.adminUIWrapper!);
  }

  searchAdminData() {
    if (this.ctrlSearchAdmin.value.trim() != '') {
      this._filteredUsers$.next([]);

      let searchValue = this.ctrlSearchAdmin.value.trim().toLowerCase();
      let adminList = [...this.adminUIWrapper?.adminList ?? []]

      let tempUsersList = adminList.filter(
        (item) => item.firstName?.toLowerCase().includes(searchValue)
          || item.lastName?.toLowerCase().includes(searchValue)
          || item.primaryEmailId?.toLowerCase().includes(searchValue));

      this._filteredUsers$.next(tempUsersList)
    } else {
      let adminList = [...this.adminUIWrapper?.adminList ?? []]
      this._filteredUsers$.next(adminList)
    }
  }

  closeModal() {
    this.ngbModal.dismissAll();
    this.formGroup.reset();
  }

  ngOnDestroy(): void {
    if (this.adminDashboardDataSubscription$) {
      this.adminDashboardDataSubscription$.unsubscribe();
    }
  }
}
