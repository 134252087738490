import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EChartsOption, init } from 'echarts';
import { Subscription, BehaviorSubject } from 'rxjs';
import { UserAuctionEligibleStatus } from 'src/app/shared/enums/UserAuctionEligibleStatus';
import { AdminUserPrivilege } from 'src/app/shared/enums/AdminUserPrivilege';
import { AuctionDashboardDto } from 'src/app/shared/models/AuctionDashboardDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { TimeZoneDto } from 'src/app/shared/models/TimeZoneDto';
import { SourcingEventDto } from 'src/app/shared/models/user/SourcingEventDto';
import { UserAuctionRegistrationDto } from 'src/app/shared/models/user/UserAuctionRegistrationDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';

@Component({
  selector: 'app-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.sass']
})
export class AnalyticsDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  auctionEntityDto?: AuctionEntityDto
  auctionDashboardData?: AuctionDashboardDto;
  userRegistrationsOfAuction: UserAuctionRegistrationDto[] = [];
  allUserAuctionRegistrations: UserAuctionRegistrationDto[] = [];
  timezones: Array<TimeZoneDto> = [];

  isRegistrationChartLoading: boolean = true;
  isNoOfBidsChartLoading: boolean = true;
  isBidPriceChartLoading: boolean = true;
  isRefreshing: boolean = false;

  public screenWidth: any;
  counterFunction: any;
  timer: boolean = false;

  selectedAuctionSubscription$?: Subscription
  auctionBidderRegistrationSubscription$?: Subscription;
  auctionTimeZoneSubscription$?: Subscription;
  allAuctionsDataLoadedSubscription$? : Subscription;

  registrationFilterType$ = new BehaviorSubject<string>('ALL')
  _auctionDashboardData$ = new BehaviorSubject<AuctionDashboardDto | undefined>(undefined)

  chartOptionRegistrations: EChartsOption = {}
  chartOptionNoOfBids: EChartsOption = {}
  chartOptionBidsPrice: EChartsOption = {}

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private auctionService: AdminSourcingEventsDataHolderService,
    private adminService: AdminDashboardService,
    private changeDetectRef: ChangeDetectorRef,
    private userService: UserService,
    private router: Router,
    private dataRedirectionService: DashboardRedirectionService
  ) { }

  ngOnInit(): void {

    this.screenWidth = window.innerWidth;

    this.auctionTimeZoneSubscription$ = this.adminService.getMasterTimezones$.subscribe((data) => {
      if (data) {
        this.timezones = data;
        this.populateData()
      }
    })

    this.selectedAuctionSubscription$ = this.auctionService.selectedAuction$.subscribe((data) => {
      if (data) {
        this.auctionEntityDto = data;
        this.populateData()
      }
    })

    this.allAuctionsDataLoadedSubscription$ = this.auctionService.getAllEventsDataLoaded$.subscribe(data => {
      if (data == true) {
        // set the selected auction from session storage.
        let auctionId = sessionStorage.getItem("RESOURCE_ID");
        if (auctionId) {
          this.auctionService.setSelectedEventByEventId(auctionId as string);
        }
      }
    })
  }

  ngAfterViewInit(): void {
    this.adminService.getAllUserAuctionRegistrationsList$.subscribe((data) => {
      if (data && data.length > 0) {
        this.allUserAuctionRegistrations = data;
        this.populateData();
      }
    })
  }

  ngOnDestroy(): void {
    this.clearCountdown();

    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }

    if (this.auctionTimeZoneSubscription$) {
      this.auctionTimeZoneSubscription$.unsubscribe();
    }

    if(this.allAuctionsDataLoadedSubscription$){
      this.allAuctionsDataLoadedSubscription$.unsubscribe();
    }
  }

  populateData() {
    if (this.allUserAuctionRegistrations && this.auctionEntityDto && this.timezones) {
      // Filter Registration of this auction
      let userRegistrationsOfAuction = this.allUserAuctionRegistrations.filter(item => item.auctionId == this.auctionEntityDto?.auctionId);
      this.userRegistrationsOfAuction = userRegistrationsOfAuction
      this.loadRegistrationChart()

      this.getAuctionDashboardData();

      this.clearCountdown();
      this.countdown(this.auctionEntityDto!);
      this.changeDetectRef.detectChanges()
    }
  }

  countdown(sourcingEventDto: SourcingEventDto) {
    let _this = this;
    let timeZone = sourcingEventDto.timeZone!;

    let date = sourcingEventDto?.status == 'LIVE' ? sourcingEventDto?.endDate : sourcingEventDto?.startDate;
    let time = sourcingEventDto?.status == 'LIVE' ? sourcingEventDto?.endTime : sourcingEventDto?.startTime;
    let remainingDurations = ApplicationUtils.getRemainingTime(date + " " + time, timeZone!);

    if (this.timer == false) {
      console.log("Creating a new countdown function in supplier small card component - " + sourcingEventDto?.eventName);

      this.timer = true;
      this.counterFunction = setInterval(function () {

        let element = document.getElementById("days" + _this.counterFunction + sourcingEventDto.eventId);
        if (element) {
          let remainingDays = ApplicationUtils.getCountdownTimerDays(date + " " + time, timeZone);
          let remainingHours = ApplicationUtils.getCountdownTimerHours(date + " " + time, timeZone);
          let remainingMinutes = ApplicationUtils.getCountdownTimerMinutes(date + " " + time, timeZone);
          let remainingSeconds = ApplicationUtils.getCountdownTimerSeconds(date + " " + time, timeZone);

          if (remainingDays) {
            document.getElementById("days" + _this.counterFunction + sourcingEventDto.eventId)!.hidden = false;
            document.getElementById("hours" + _this.counterFunction + sourcingEventDto.eventId)!.hidden = false;
            document.getElementById("minutes" + _this.counterFunction + sourcingEventDto.eventId)!.hidden = true;
            document.getElementById("second" + _this.counterFunction + sourcingEventDto.eventId)!.hidden = true;

            document.getElementById("days" + _this.counterFunction + sourcingEventDto.eventId)!.innerText = remainingDays + 'd ';
            document.getElementById("hours" + _this.counterFunction + sourcingEventDto.eventId)!.innerText = remainingHours + 'h';
          } else {
            document.getElementById("days" + _this.counterFunction + sourcingEventDto.eventId)!.hidden = true;
            document.getElementById("hours" + _this.counterFunction + sourcingEventDto.eventId)!.hidden = false;
            document.getElementById("minutes" + _this.counterFunction + sourcingEventDto.eventId)!.hidden = false;
            document.getElementById("second" + _this.counterFunction + sourcingEventDto.eventId)!.hidden = false;

            document.getElementById("hours" + _this.counterFunction + sourcingEventDto.eventId)!.innerText = ('0' + remainingHours).slice(-2) + ' : ';
            document.getElementById("minutes" + _this.counterFunction + sourcingEventDto.eventId)!.innerText = ('0' + remainingMinutes).slice(-2) + ' : ';
            document.getElementById("second" + _this.counterFunction + sourcingEventDto.eventId)!.innerText = ('0' + remainingSeconds).slice(-2);
          }

          //do something later when date is reached
          if (remainingDurations < 0) {
            let headline = document.getElementById("headline" + _this.counterFunction + sourcingEventDto.eventId);
            let countdown = document.getElementById("countdown" + _this.counterFunction + sourcingEventDto.eventId);
            if (headline) {
              headline.innerText = "Bidding Closed!";
            }
            if (countdown) {
              countdown.style.display = "none";
            }
            clearInterval(_this.counterFunction);
          }
        }

      }, 1000)

      console.log("New count down function created in supplier small card component - " + sourcingEventDto.eventName + "   : " + this.counterFunction)
    }
  }

  clearCountdown() {
    if (this.timer == true) {
      console.log("For Auction :" + this.auctionEntityDto?.auctionName + " Remaining time clear");
      clearInterval(this.counterFunction);
      this.timer = false;
    }
  }

  getAuctionDashboardData() {
    this.isRefreshing = true;
    this.adminService.loadBidderRegistrationList(this.auctionEntityDto?.auctionId!);

    this.adminService.getAuctionDashboardData(this.auctionEntityDto?.auctionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isRefreshing = false;
          this.auctionDashboardData = apiResponseDto.data as AuctionDashboardDto;
          this._auctionDashboardData$.next(this.auctionDashboardData);
          this.loadNoOfBidsChart()
          this.loadBidsPriceChart()
        }
      },
      error: (err) => {
        this.isRefreshing = false;
        console.error(err);
      }
    })
  }

  filterRegistrations(ev: any) {
    console.log(ev.name);
    let filterType = ev.name == 'Awaiting Approval' ? 'AWAITING_APPROVAL'
      : ev.name == 'Rejected' ? 'REJECTED_BIDDERS'
        : ev.name == 'Approval' ? 'APPROVED_BIDDERS' : 'ALL';
    this.registrationFilterType$.next(filterType)
  }

  loadRegistrationChart() {
    let awaitingApprovals = this.userRegistrationsOfAuction.filter(item => item.userAuctionRegistrationStatus == UserAuctionEligibleStatus.AWAITING_APPROVAL).length;
    let rejectedApprovals = this.userRegistrationsOfAuction.filter(item => item.userAuctionRegistrationStatus == UserAuctionEligibleStatus.REJECTED_BIDDERS).length;
    let approvals = this.userRegistrationsOfAuction.filter(item => item.userAuctionRegistrationStatus == UserAuctionEligibleStatus.APPROVED_BIDDERS).length;

    this.chartOptionRegistrations = {
      tooltip: {
        trigger: 'item',
      },
      title: {
        text: 'Total Registrations: ' + this.userRegistrationsOfAuction.length,
        textStyle: {
          fontSize: 12
        }
      },
      height: '400px',
      legend: {
        orient: 'horizontal',
        left: 'center',
        bottom: 0,
        textStyle: {
          fontSize: 11
        },
        data: [
          'Awaiting Approval',
          'Rejected',
          'Approval',
        ]
      },
      series: [
        {
          name: 'Registration',
          type: 'pie',
          radius: [20, 140],
          roseType: 'radius',
          itemStyle: {
            borderRadius: 5
          },
          label: {
            formatter: '{b} ({c})',
            position: 'outside'
          },
          emphasis: {
            label: {
              show: true
            }
          },
          data: [
            { value: awaitingApprovals, name: 'Awaiting Approval', itemStyle: { color: '#facc15' } },
            { value: rejectedApprovals, name: 'Rejected', itemStyle: { color: '#dc2626' } },
            { value: approvals, name: 'Approval', itemStyle: { color: '#22c55e' } },
          ]
        },
      ]
    }

    this.isRegistrationChartLoading = false;
  }

  loadNoOfBidsChart() {
    let lotDashboardDtoList = this.auctionDashboardData?.lotDashboardDtoList ?? [];
    lotDashboardDtoList.sort((a, b) => Number(a.lotSequence) - Number(b.lotSequence));

    let colors = this.randomColorsList(lotDashboardDtoList.length)

    this.chartOptionNoOfBids = {
      xAxis: {
        max: 'dataMax',
        nameLocation: 'middle',
        nameGap: 40,
        minInterval: 2
      },
      yAxis: {
        type: 'category',
        data: lotDashboardDtoList.map(item => `#${item.lotSequence!}`),
        inverse: true,
        animationDuration: 300,
        animationDurationUpdate: 300,
        nameLocation: 'middle',
        nameGap: this.screenWidth < 768 ? 22 : 40,
      },
      title: {
        text: 'X Axis: No of bids, Y Axis: Lot sequence',
        borderWidth: 2,
        borderColor: '#ccc',
        padding: 10,
        textStyle: {
          fontSize: 12,
          fontWeight: "normal"
        },

      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          let item = lotDashboardDtoList[params[0].dataIndex]
          return `#${item.lotSequence} ${item.lotName}<br>
          No Of Bids: ${item.noOfBids}`;
        }
      },
      height: lotDashboardDtoList.length > 8 ? lotDashboardDtoList.length * 40 : 300,
      series: [
        {
          realtimeSort: true,
          type: 'bar',
          data: lotDashboardDtoList.map(item => item.noOfBids!),
          label: {
            show: true,
            color: 'black',
            offset: [30, 0],
            valueAnimation: true
          },
          barWidth: 30,
          itemStyle: {
            color: function (param) {
              return colors[param.dataIndex] || '#5470c6';
            }
          },
        }
      ],
      legend: {
        show: true
      },
      animationDuration: 0,
      animationDurationUpdate: 3000,
      animationEasing: 'linear',
      animationEasingUpdate: 'linear'
    };

    this.isNoOfBidsChartLoading = false;
    this.changeDetectRef.detectChanges()

    this.resizeCanvasHeight('chartNoOfBids', lotDashboardDtoList.length)
  }

  loadBidsPriceChart() {
    let lotDashboardDtoList = this.auctionDashboardData?.lotDashboardDtoList ?? [];
    lotDashboardDtoList.sort((a, b) => Number(a.lotSequence) - Number(b.lotSequence));

    let colors = this.randomColorsList(lotDashboardDtoList.length)

    this.chartOptionBidsPrice = {
      xAxis: {
        max: 'dataMax',
        nameLocation: 'middle',
        nameGap: 40,
        axisLabel: {
          formatter: (params: any) => `${this.getFormattedPrice(params)}`
        }
      },
      yAxis: {
        type: 'category',
        data: lotDashboardDtoList.map(item => `#${item.lotSequence!}`),
        inverse: true,
        animationDuration: 300,
        animationDurationUpdate: 300,

        nameLocation: 'middle',
        nameGap: this.screenWidth < 768 ? 22 : 40,
      },
      title: {
        text: 'X Axis: Bids Price, Y Axis: Lot sequence',
        borderWidth: 2,
        borderColor: '#ccc',
        padding: 10,
        textStyle: {
          fontSize: 12,
          fontWeight: "normal"
        },

      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          let item = lotDashboardDtoList[params[0].dataIndex]
          return `#${item.lotSequence} ${item.lotName}<br>
          Current Bid Price: ${this.getFormattedPrice(item.currentBidPrice)}`;
        }
      },
      height: lotDashboardDtoList.length > 8 ? lotDashboardDtoList.length * 40 : 300,
      series: [
        {
          realtimeSort: true,
          type: 'bar',
          data: lotDashboardDtoList.map(item => item.currentBidPrice!),
          label: {
            show: true,
            color: 'black',
            offset: [30, 0],
            valueAnimation: true,
            formatter: (params) => {
              return `${this.getFormattedPrice(params.value)}`;
            }
          },
          barWidth: 30,
          itemStyle: {
            color: function (param) {
              return colors[param.dataIndex] || '#5470c6';
            }
          },
        }
      ],
      legend: {
        show: true
      },
      animationDuration: 0,
      animationDurationUpdate: 3000,
      animationEasing: 'linear',
      animationEasingUpdate: 'linear'
    };

    this.isBidPriceChartLoading = false;
    this.changeDetectRef.detectChanges()

    this.resizeCanvasHeight('chartBidsPrice', lotDashboardDtoList.length)
  }

  getFormattedPrice(price: any) {
    let currency = this.auctionDashboardData?.currencyDto;
    return currency?.symbol + ApplicationUtils.getFormattedPrice(currency?.locale, price);
  }

  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getDisplayDateAdmin(date, time);
  }

  getTimeZone(dbTimeZone?: string) {
    if (this.timezones && this.timezones.length > 0) {
      let data = this.timezones.find((item) => item.locale == dbTimeZone);
      if (data) {
        return data.fullName;
      }
    }
    return "";
  }

  randomColorsList(length: number): string[] {
    const colors = new Set();

    while (colors.size < length) {
      const color = ApplicationUtils.getRandomLightColorHex();
      colors.add(color);
    }

    return Array.from(colors) as string[];
  }

  navigateToHome() {
    this.router.navigate(['Admin/Dashboard'], { skipLocationChange: true })
  }

  resizeCanvasHeight(chartId: string, dataLength: number) {
    if (dataLength > 8) {
      let myChart = init(document.getElementById(chartId)!);
      let desiredHeight = dataLength * 42;
      document.getElementById(chartId)!.style.height = desiredHeight + 'px';
      myChart.resize();
    }
  }
}
