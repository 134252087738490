 <div class="border rounded bg-white">
  <header class="p-2">
    <div class="row ">
      <div class="col-md-4">
        <h5 class="heading-h5" ngbAutoFocus> ADDRESS</h5>
      </div>
      <div class="col-md-8 text-md-end ">
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 me-3" *ngIf="auctionEntityDto?.addresses && auctionEntityDto?.addresses!.length > 1"
           (click)="openSortingModal(addressSortingModal)">
           <!-- <img src="assets/icons/reorder_dark.svg" class=" c-pointer svg-icon-class"> -->
           REORDER
        </button>
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 " (click)="openModal(addressModal)" [disabled]="!isAllowToEdit()">
          <!-- <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-small"> -->
          ADD
        </button>
      </div>
    </div>
  </header>
  <hr class="hr" />
  <div class="col-12 mb-3">
    <div class=" div-class p-2">
      List of address (to be selected in listing)
    </div>
    <hr class="hr" />
    <div class="col-12 my-3 text-center" *ngIf="!auctionEntityDto?.addresses || auctionEntityDto?.addresses!.length == 0">
      <h3 class="heading-new text-dark">Address is not added yet.</h3>
    </div>
    <div class="multiple-list-scroll" >
      <div class="col-12 p-1" *ngFor="let item of auctionEntityDto?.addresses; index as i">
        <div class="row mx-1">
          <div class="col-6 mt-2" (click)="openModal(addressModal, item)">
            <h5 class="heading-h5">{{item?.addressType}}</h5>
          </div>
          <div class="col-6 text-end">

            <img src="assets/icons/map_light.svg" class=" c-pointer svg-icon-class ms-2" (click)="openGoogleMap('map', item)">
            <img src="assets/icons/streetview_light.svg" class=" c-pointer svg-icon-class ms-2" (click)="openGoogleMap('street-view', item)">
          </div>
          <div class="col-9 div-class" (click)="openModal(addressModal, item)">
            {{item?.addressLine1}}, {{item?.addressLine2 ? item?.addressLine2 + ',' : ''}}<br>
            {{item?.city}} <br>
            {{item?.state}} {{item?.zipCode}} <br>
            {{getCountryCode(item?.countryCode)}}{{item?.mobileNo}}
          </div>
          <div class="col-3 text-end mt-5">
            <img src="assets/icons/delete_danger.svg" (click)="deleteAddress(i)"
            class="svg-icon-class c-pointer" [ngClass]="{'disabled' : !isAllowToEdit()}">
          </div>
          <div class="e-spinner" *ngIf="isDataLoading && currentIndex == i"
            [ngClass]="{'inline-spinner' : isDataLoading}">
          </div>
          <hr class="hr" *ngIf = "i != (auctionEntityDto?.addresses)!.length - 1"/>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addressModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>ADDRESS</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleSaveAddress()">
        <div class="row">
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="Title" placeholder="Title"
                [ngClass]="{'is-invalid': fc.addressType.invalid && (fc.addressType.dirty || fc.addressType.touched)}"
                formControlName="addressType">
              <label for="Title">Title</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.addressType.invalid && (fc.addressType.dirty || fc.addressType.touched)">
                <p class="text-red-500 " *ngIf="fc.addressType.errors?.required">Required</p>
              </div>
            </div>

            <div class="form-floating mb-3">
              <input ngx-gp-autocomplete #placesRef="ngx-places" type="text" class="form-control"
                [ngClass]="{'is-invalid': fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)}"
                id="searchAddress" placeholder="Search" formControlName="searchAddress"
                (onAddressChange)="handleAddressChange($event)">
              <label for="searchAddress">Search Address</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.searchAddress.invalid && (fc.searchAddress.dirty || fc.searchAddress.touched)">
                <p class="text-red-500 " *ngIf="fc.searchAddress.errors?.required">Required</p>
                <p class="text-red-500 " *ngIf="fc.searchAddress.errors?.address">Invalid</p>
              </div>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="addressLine1" placeholder="Address Line 1"
                formControlName="addressLine1"
                [ngClass]="{'is-invalid': fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)}">
              <label for="addressLine1"> Address Line 1</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.addressLine1.invalid && (fc.addressLine1.dirty || fc.addressLine1.touched)">
                <p class="text-red-500 " *ngIf="fc.addressLine1.errors?.required">Required</p>
              </div>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="addressLine2" placeholder="Address Line 2"
                formControlName="addressLine2">
              <label for="addressLine2">Address Line 2</label>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="city" placeholder="City" formControlName="city"
                [ngClass]="{'is-invalid': fc.city.invalid && (fc.city.dirty || fc.city.touched)}">
              <label for="city">City</label>
              <div *ngIf="(_validationErrorPresent$ |async) || fc.city.invalid && (fc.city.dirty || fc.city.touched)">
                <p class="text-red-500 " *ngIf="fc.city.errors?.required">Required</p>
              </div>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="state" placeholder="State" formControlName="state"
                [ngClass]="{'is-invalid': fc.state.invalid && (fc.state.dirty || fc.state.touched)}">
              <label for="state">State</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.state.invalid && (fc.state.dirty || fc.state.touched)">
                <p class="text-red-500 " *ngIf="fc.state.errors?.required">Required</p>
              </div>
            </div>
           
          </div>
          <div class="col-md-6">
          
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="country" placeholder="Country" formControlName="country"
                [ngClass]="{'is-invalid': fc.country.invalid && (fc.country.dirty || fc.country.touched)}">
              <label for="country">Country</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.country.invalid && (fc.country.dirty || fc.country.touched)">
                <p class="text-red-500 " *ngIf="fc.country.errors?.required">Required</p>
              </div>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="zipCode" placeholder="Zip" formControlName="zipCode"
                [ngClass]="{'is-invalid': fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)}">
              <label for="zipCode">Zip</label>
              <div
                *ngIf="(_validationErrorPresent$ |async) || fc.zipCode.invalid && (fc.zipCode.dirty || fc.zipCode.touched)">
                <p class="text-red-500 " *ngIf="fc.zipCode.errors?.required">Required</p>
                <p class="text-red-500 " *ngIf="fc.zipCode.errors?.pattern">Invalid</p>
              </div>
            </div>
            <div class="input-group mb-3">
              <span class="input-group-addon">
                <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                  [countryCode$]="countryCode$"></app-country-code-select>
              </span>
              <div class="form-floating">
                <input type="text" class="form-control" id="mobileNo" placeholder="Phone" formControlName="mobileNo"
                  [ngClass]="{'is-invalid': fc.mobileNo.invalid && (fc.mobileNo.dirty || fc.mobileNo.touched)}">
                <label for="mobileNo">Phone</label>
                <div *ngIf="(_validationErrorPresent$ |async) || fc.mobileNo.invalid && (fc.mobileNo.dirty || fc.mobileNo.touched)">
                  <p class="text-red-500 " *ngIf="fc.mobileNo.errors?.required">Required</p>
                  <p class="text-red-500 " *ngIf="fc.mobileNo.errors?.pattern">Invalid</p>
                </div>
              </div>
            </div>
            <div class="form-floating mb-3">
              <div class="bg-secondary text-center border rounded"
                [ngClass]="{'py-5': latitude == undefined}">
                <google-map height="32vh" width="100%" [options]="options" [center]="position!" [zoom]="zoom"
                  *ngIf="latitude != undefined">
                  <map-marker #markerElem="mapMarker" [position]="position!" [title]="marker.title"
                    [options]="marker.options" (mapClick)="openInfo(markerElem, marker.info)">
                  </map-marker>
                  <map-info-window>{{ infoContent }}</map-info-window>
                </google-map>
              </div>
            </div>

          </div>
        </div>

        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Address saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="'Error while saving address. Try again.'"></app-message-toast>
        </div>

        <div class="mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800px-5" (click)="handleSaveAddress()" [disabled]="!isAllowToEdit()">ADD</button>
        </div>
      </form>
    </div>
   </div>
  </div>
</ng-template>

<ng-template #addressSortingModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>ADDRESS</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isDataLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-2 mx-3">
        <div>
          <div class="col-12 my-3" style="max-height: 400px; overflow-x: hidden; overflow-y: auto;" cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="(_isOrderActive$ | async)">
            <div class="card mx-4 mb-2 droplist" *ngFor="let item of addressList; index as i" cdkDrag
              [cdkDragDisabled]="_isSaveButtonDisable$ | async">
              <div class="drag-placeholder col-12" *cdkDragPlaceholder></div>
              <div class="col-12 p-1">
                <div class="row mx-1">
                  <div class="col-8 mt-2">
                    <h5 class="text-primary">{{item?.addressType}}</h5>
                    <div>
                      {{item?.addressLine1}}, {{item?.addressLine2 ? item?.addressLine2 + ',' : ''}}<br>
                      {{item?.city}} <br>
                      {{item?.state}} {{item?.zipCode}} <br>
                      {{getCountryCode(item?.countryCode)}} {{item?.mobileNo}}
                    </div>
                  </div>
                  <div class="col-4 text-end mt-2 ">
                    <img src="assets/icons/reorder_light.svg" class=" c-pointer svg-icon-class ms-2">
                    <!-- <button class="btn btn-outline-primary btn-sm rounded-circle c-pointer  top-margin-modal">
                      <fa-icon [icon]="['fas', 'trash']" class=" fa-lg"></fa-icon>
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Address Saved'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="'Error while saving address!'"></app-message-toast>
        </div>
        <div class="mb-3" *ngIf="isDataLoading">
          <div class="e-spinner"></div>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="!isDataLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800px-5" (click)="handleSaveAddress()" [disabled]="!isAllowToEdit()">UPDATE</button>
        </div>

      </div>
    </div>
  </div>
</ng-template>
