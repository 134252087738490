import { AfterViewInit, Component, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserAuctionEligibleStatus } from 'src/app/shared/enums/UserAuctionEligibleStatus';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { AdminAuctionBidderRegistrationDto } from 'src/app/shared/models/user/AdminAuctionBidderRegistrationDto ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';

@Component({
  selector: 'app-analytics-registration-list',
  templateUrl: './analytics-registration-list.component.html',
  styleUrls: ['./analytics-registration-list.component.sass']
})
export class AnalyticsRegistrationListComponent implements AfterViewInit, OnDestroy {
  @Input() filterType$ = new BehaviorSubject<string>('ALL');

  bidderRegistrationList: Array<AdminAuctionBidderRegistrationDto> = [];
  bidderRegistrationList$ = new BehaviorSubject<Array<AdminAuctionBidderRegistrationDto>>([]);

  auctionBidderRegistrationSubscription$?: Subscription;

  isLoading: boolean = true;
  page: number = 1;
  pageSize: number = 50;

  constructor(
    private adminService: AdminDashboardService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.auctionBidderRegistrationSubscription$ = this.adminService.getAuctionBidderRegistrationDtos$.subscribe(data => {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
      
      if (data && data.length > 0) {
        this.bidderRegistrationList = data;
        this.bidderRegistrationList$.next(this.bidderRegistrationList);
        this.paginateBidderHistoryList();
      }
    })

    this.filterType$.subscribe(filterType => {
      let registrations = [...this.bidderRegistrationList];
      let filteredRegistrations = registrations.filter(item => filterType == 'AWAITING_APPROVAL' ? item.auctionRegistrationStatus == UserAuctionEligibleStatus.AWAITING_APPROVAL
        : filterType == 'REJECTED_BIDDERS' ? item.auctionRegistrationStatus == UserAuctionEligibleStatus.REJECTED_BIDDERS
          : filterType == 'APPROVED_BIDDERS' ? item.auctionRegistrationStatus == UserAuctionEligibleStatus.APPROVED_BIDDERS : item);
      this.bidderRegistrationList$.next(filteredRegistrations);
      this.paginateBidderHistoryList();
    })
  }

  ngOnDestroy(): void {
    if (this.auctionBidderRegistrationSubscription$) {
      this.auctionBidderRegistrationSubscription$.unsubscribe();
    }
  }

  changePageSize(pageSize: number){
    this.pageSize = pageSize;
    this.paginateBidderHistoryList()
  }

  paginateBidderHistoryList() {
    let data = [...this.bidderRegistrationList$.value];
    this.bidderRegistrationList$.next(data.map((history: any, i: number) => ({ id: i + 1, ...history })).slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize,
    ));
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }
}
