import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { SectionTemplate } from '../models/questionnaire/SectionTemplate';
import { QuestionnaireWrapperDto } from '../models/questionnaire/QuestionnaireWrapperDto';
import { SourcingEnvelopeType } from '../enums/questionnaire/SourcingEnvelopeType';
import { QuestionnaireValidationDto } from '../models/questionnaire/QuestionnaireValidationDto';
import { BehaviorSubject } from 'rxjs';
import { RfxSubcategoryUiDto } from '../models/rfx/RfxSubcategoryUiDto';
import { RfxValidationErrorCodeDto } from '../models/user/RfxValidationErrorCodeDto';
import { ContractAlgoEnum } from '../enums/rfx/ContractAlgoEnum';
import { RfxUiDto } from '../models/rfx/RfxUiDto';
import { RfxTypeEnum } from '../enums/rfx/RfxTypeEnum';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireValidationService {


  constructor(
    private questionnaireService: QuestionnaireService,
    private http: HttpClient,
    private errorService: ErrorService
  ) { }



  getRemainingScoreOfSection(questionnaireWrapperDto: QuestionnaireWrapperDto, sectionId: string) {
    let sectionTemplate = (questionnaireWrapperDto?.sectionTemplateList ?? []).find(item => item.sectionId == sectionId);
    let totalQuestionScore = this.getTotalScoreOfQuestions(questionnaireWrapperDto, sectionId!);
    return Number(sectionTemplate?.sectionScore) - Number(totalQuestionScore);
  }

  getTotalScoreOfSections(questionnaireWrapperDto: QuestionnaireWrapperDto, envelopeType: SourcingEnvelopeType): number {
    let sectionTemplateList = (questionnaireWrapperDto?.sectionTemplateList ?? []).filter(item => item.envelopeType == envelopeType);
    let sectionCalculatedScore = sectionTemplateList.reduce((prev, cur) => Number(prev) + Number(cur.sectionScore ?? 0), 0)
    return sectionCalculatedScore ?? 0;
  }

  checkSectionScoreValidation(questionnaireWrapperDto: QuestionnaireWrapperDto, envelopeType: SourcingEnvelopeType) {
    if (questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES' && questionnaireWrapperDto.sectionTemplateList && questionnaireWrapperDto.sectionTemplateList?.length > 0) {
      let sectionTemplateList = (questionnaireWrapperDto.sectionTemplateList ?? []).filter(item => item.envelopeType == envelopeType);

      let maximumScore = questionnaireWrapperDto?.questionnaireTemplate?.maximumScore;
      let sectionCalculatedScore = sectionTemplateList.reduce((prev, cur) => Number(prev) + Number(cur.sectionScore ?? 0), 0)

      return sectionCalculatedScore != Number(maximumScore);
    } else {
      return false;
    }
  }

  checkQuestionScoreValidation(questionnaireWrapperDto: QuestionnaireWrapperDto, sectionId: string) {
    if (questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES' && questionnaireWrapperDto.technicalQuestionTemplates && questionnaireWrapperDto.technicalQuestionTemplates?.length > 0) {
      let technicalQuestions = questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == sectionId) ?? [];
      let calculatedScore = technicalQuestions.reduce((prev, cur) => Number(prev) + Number(cur.score ?? 0), 0);
      let sectionTemplate = questionnaireWrapperDto?.sectionTemplateList?.filter((item : SectionTemplate)  => item.sectionId == sectionId!)[0];

      return Number(sectionTemplate?.sectionScore) != calculatedScore;
    } else {
      return false;
    }
  }

  getTotalScoreOfQuestions(questionnaireWrapperDto: QuestionnaireWrapperDto , sectionId: string) {
    let technicalQuestions = questionnaireWrapperDto?.technicalQuestionTemplates?.filter(item => item.sectionId == sectionId) ?? [];
    let calculatedScore = technicalQuestions.reduce((prev, cur) => Number(prev) + Number(cur.score ?? 0), 0);
    return calculatedScore;
  }



  runTechQuestionnaireValidation(questionnaireWrapperDto: QuestionnaireWrapperDto, selectedSectionTemplate: SectionTemplate) {


    if (questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES') {
      let isScoreMismatch = this.checkSectionScoreValidation(questionnaireWrapperDto, SourcingEnvelopeType.TECHNICAL);

      if (isScoreMismatch) {
        let totalScoreOfSections = this.getTotalScoreOfSections(questionnaireWrapperDto, SourcingEnvelopeType.TECHNICAL);
        let maximumScore = questionnaireWrapperDto?.questionnaireTemplate?.maximumScore;

        let questionnaireValidationDto = new QuestionnaireValidationDto();
        questionnaireValidationDto.message = `The sum of section scores (${totalScoreOfSections}) does not match the questionnaire total score (${maximumScore})`;

        return true;
      }

      if (selectedSectionTemplate && (selectedSectionTemplate.questionIds ?? []).length > 0) {
        let totalScoreOfQuestions = this.getTotalScoreOfQuestions(questionnaireWrapperDto, selectedSectionTemplate.sectionId!);

        if (totalScoreOfQuestions != selectedSectionTemplate.sectionScore) {
          let questionnaireValidationDto = new QuestionnaireValidationDto();
          questionnaireValidationDto.message = `The sum of questions scores (${totalScoreOfQuestions}) does not match the section score (${selectedSectionTemplate.sectionScore})`;

          return true;
        }
      }
    }
    return false;
  }

  checkFinancialSectionsValidation(questionnaireWrapperDto: QuestionnaireWrapperDto) {
    let financialSections = questionnaireWrapperDto?.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.FINANCIAL) ?? [];
    let financialQuestions = questionnaireWrapperDto?.financialQuestionTemplates ?? [];

    if (financialSections.length > 0) {
      let haveFinancialQuestionsInAllSections = financialSections.every(section => financialQuestions.find(item => item.sectionId == section.sectionId));
      return !haveFinancialQuestionsInAllSections;
    }
    return false;
  }

  haveQuestionnaireValidationIssues(questionnaireWrapperDto: QuestionnaireWrapperDto): boolean {
    if (questionnaireWrapperDto) {
      if (questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES') {
        let sectionTemplateList = questionnaireWrapperDto.sectionTemplateList;

        if (!sectionTemplateList || sectionTemplateList.length == 0) {
          return true;
        }

        let isScoreMismatch = this.checkSectionScoreValidation(questionnaireWrapperDto, SourcingEnvelopeType.TECHNICAL);
        if (isScoreMismatch) {
          return true;
        }

        for (let i = 0; i < sectionTemplateList.length; i++) {
          const sectionTemplate = sectionTemplateList[i];

          if (!sectionTemplate.questionIds || sectionTemplate.questionIds.length == 0) {
            return true;
          }

          let totalScoreOfQuestions = this.getTotalScoreOfQuestions(questionnaireWrapperDto, sectionTemplate.sectionId!);
          if (totalScoreOfQuestions != Number(sectionTemplate.sectionScore)) {
            return true
          }
        }
        return false;
      } else {
        let sectionTemplateList = questionnaireWrapperDto.sectionTemplateList;

        if (!sectionTemplateList || sectionTemplateList.length == 0) {
          return true;
        }

        for (let i = 0; i < sectionTemplateList.length; i++) {
          const sectionTemplate = sectionTemplateList[i];
          if (!sectionTemplate.questionIds || sectionTemplate.questionIds.length == 0) {
            return true;
          }
        }
        return false;
      }
    } else {
      return true;
    }

  }



  hasAnyErrorInRfxSubcategoriesQuestionnaireValidation(rfx: RfxUiDto, subcategory: RfxSubcategoryUiDto){
    let questionnaireWrapperDtos = this.questionnaireService._questionnaireWrapperDtoList$.value;
    // let isQuestionnaireValidationFailed = false;

      if(questionnaireWrapperDtos && questionnaireWrapperDtos.length > 0){
        let questionnaireWrapperDto = questionnaireWrapperDtos.find(item => item.questionnaireTemplate?.templateId == subcategory.questionnaireId)!;

        if(questionnaireWrapperDto && questionnaireWrapperDto.sectionTemplateList && questionnaireWrapperDto.sectionTemplateList.length > 0){

          let questionnaireTemplate = questionnaireWrapperDto.questionnaireTemplate;
            let financialSections = questionnaireWrapperDto.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.FINANCIAL) ?? [];
            let technicalSections = questionnaireWrapperDto.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL) ?? [];
      
            if (questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS || questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QBS) {
              if (financialSections.length == 0) {
                return true;
              }
              if (technicalSections.length == 0) {
                return true;
              }
              if (this.haveQuestionnaireValidationIssues(questionnaireWrapperDto)) {
                return true;
              }
              if (this.checkFinancialSectionsValidation(questionnaireWrapperDto)) {
                return true;
              }
              return false;
            }
      
            if (questionnaireTemplate?.contractAlgo == ContractAlgoEnum.LCS || questionnaireTemplate?.contractAlgo == ContractAlgoEnum.HCS) {
              if (financialSections.length == 0) {
                return true;
              }
              if (this.checkFinancialSectionsValidation(questionnaireWrapperDto)) {
                return true;
              }
              return false;
            }

            if (rfx.rfxType == RfxTypeEnum.PQ || rfx.rfxType == RfxTypeEnum.RFI) {
              return this.haveQuestionnaireValidationIssues(questionnaireWrapperDto)
            }
      
            return false;

          // for(let i = 0; i < questionnaireWrapperDto.sectionTemplateList.length; i++){
          //   let section = questionnaireWrapperDto.sectionTemplateList[i];

          //   if(section.envelopeType == SourcingEnvelopeType.TECHNICAL){
          //     let isErrorInTechnical = this.runTechQuestionnaireValidation(questionnaireWrapperDto, section);

          //     if(isErrorInTechnical){
          //       isQuestionnaireValidationFailed = true;
          //       break;
          //     }
          //   }
          // }

          // if(isQuestionnaireValidationFailed){
          //   return isQuestionnaireValidationFailed;
          // }

          //check financial
          // let isErrorInFinancial = this.checkFinancialSectionsValidation(questionnaireWrapperDto);

          // if(isErrorInFinancial){
          //   isQuestionnaireValidationFailed  = true;
          // }
        } else {
          // isQuestionnaireValidationFailed  = true;
          return true;
        }
      }
      return true;
      // return isQuestionnaireValidationFailed;
  }

}
