export class ApplicationConstants {
  static USER_REGISTRATION_PROGRESS = "USER_REGISTRATION_PROGRESS";
  static SIGNIN_IN = "SIGNIN_IN";
  static LOADER_NOTIFICATION = "LOADER_NOTIFICATION";
  static SAVING_DATA = "SAVING_DATA";
  static SUCCESS_CODE = "200";
  static VERIFING_OTP = "VERIFING_OTP";
  static FETCHING_DATA = "FETCHING_DATA";
  // static ADMIN_DASHBOARD_AUCTION_TAB = "ADMIN_DASHBOARD_AUCTION_TAB";
  // static ADMIN_DASHBOARD_ADMIN_TAB = "ADMIN_DASHBOARD_ADMIN_TAB";
  // static ADMIN_DASHBOARD_AUCTIONEER_TAB = "ADMIN_DASHBOARD_AUCTIONEER_TAB";
  // static ADMIN_DASHBOARD_IN_HOUSE_ADMIN_TAB = "ADMIN_DASHBOARD_IN_HOUSE_ADMIN_TAB";
  // static ADMIN_DASHBOARD_BIDDER_TAB = "ADMIN_DASHBOARD_BIDDER_TAB";
  // static ADMIN_DASHBOARD_POST_AUCTION_TAB = "ADMIN_DASHBOARD_POST_AUCTION_TAB";
  // static ADMIN_DASHBOARD_GLOBAL_AUCTION_TAB = "ADMIN_DASHBOARD_GLOBAL_BIDDERS_TAB";
  // static ADMIN_DASHBOARD_AUCTION_HOUSE_SETTING = "ADMIN_DASHBOARD_AUCTION_HOUSE_SETTING";
  // static ADMIN_DASHBOARD_AUCTION_CREATOR = "ADMIN_DASHBOARD_AUCTION_CREATOR";
  // static ADMIN_DASHBOARD_REGISTRATION = "ADMIN_DASHBOARD_REGISTRATION";
  // static ADMIN_DASHBOARD_BIDDING = "ADMIN_DASHBOARD_BIDDING";
  // static ADMIN_DASHBOARD_QUESTIONS = "ADMIN_DASHBOARD_QUESTIONS";
  // static ADMIN_DASHBOARD_SINGLE_LOT_DETAILS = "ADMIN_DASHBOARD_SINGLE_LOT_DETAILS";
  // static ADMIN_DASHBOARD_MULTI_LOT_DETAILS = "ADMIN_DASHBOARD_MULTI_LOT_DETAILS";
  // static ADMIN_AUCTION_ANALYTICS_DASHBOARD = "ADMIN_AUCTION_ANALYTICS_DASHBOARD";
  // static ADMIN_AUCTION_CLERK_DASHBOARD = "ADMIN_AUCTION_CLERK_DASHBOARD";

  static AUCTION_CALENDAR = "AUCTION_CALENDAR";
  static AUCTION_CATALOGUE = "AUCTION_CATALOGUE";
  static ALL_AUCTIONS = "ALL_AUCTIONS";
  static CLOSED_AUCTIONS = "CLOSED_AUCTIONS";
  static MY_DASHBOARD = "MY_DASHBOARD";
  static MY_DASHBOARD_BIDS = "MY_DASHBOARD_BIDS";
  static MY_REGISTRATION = "MY_REGISTRATION";
  static MY_INVOICES = "MY_INVOICES";
  static UNSUBSCRIBE_NOTIFICATION = "UNSUBSCRIBE_NOTIFICATION";

  static FOLLOW = "FOLLOW";
  static SEEN = "SEEN";
  static SHARE = "SHARE";

  static PAGE_SIZE = 10;
  static SHOW_MORE_SIZE = 100;

  /// Console
  static DEBUG_CONSOLE = "DEBUG_CONSOLE";
  static LOG_CONSOLE = "LOG_CONSOLE";
  static CONFIG_CONSOLE = "CONFIG_CONSOLE";
  static FORWARD_AUCTION = "FORWARD_AUCTION";
  static REVERSE_AUCTION = "REVERSE_AUCTION";
  static ADMIN_DASHBOARD_BIDDERS_TAB = 'ADMIN_DASHBOARD_GLOBAL_BIDDERS_TAB';

  // Pages
  static SINGLE_LOT = "SINGLE_LOT";
  static MULTI_LOT = "MULTI_LOT";

  static DASHBOARD_PAGE = "DASHBOARD_PAGE";
  static SOURCING_PAGE = "SOURCING_PAGE";
  static ANALYTICS_PAGE = "ANALYTICS_PAGE";
  static BIDDER_NETWORK_PAGE = "BIDDER_NETWORK_PAGE";
  static IN_HOUSE_USER_PAGE = "IN_HOUSE_USER_PAGE";
  static SETTING_PAGE = "SETTING_PAGE";
  static ONBOARDING_PAGE = "ONBOARDING_PAGE";
  static CATALOGUE_PAGE = "CATALOGUE_PAGE";
  static APPROVED_PAGE = "APPROVED_PAGE";
  static PROJECTS_PAGE = "PROJECTS_PAGE";
  static ORGANIZATION_PAGE = "ORGANIZATION_PAGE";
  static PURCHASE_REQUEST_PAGE = "PURCHASE_REQUEST_PAGE";
  static SUPER_ADMIN_PAGE = "SUPER_ADMIN_PAGE";
  static AUCTION_CREATOR_PAGE = "AUCTION_CREATOR_PAGE";
  static AUCTION_ANALYTICS_DASHBOARD_PAGE = "AUCTION_ANALYTICS_DASHBOARD_PAGE";
  static AUCTION_GRAPH_PAGE = "AUCTION_GRAPH_PAGE";
  static ADMIN_CATALOGUE_PAGE = "ADMIN_CATALOGUE_PAGE";
  static ADMIN_PURCHASE_ORDER_PAGE = "ADMIN_PURCHASE_ORDER_PAGE";
  static ADMIN_INVOICE_PAGE = "ADMIN_INVOICE_PAGE";
  static ADMIN_LINE_CATALOGUE_PAGE = "ADMIN_LINE_CATALOGUE_PAGE";
  static ADMIN_CATALOGUE_REQUISITION_PAGE = "ADMIN_CATALOGUE_REQUISITION_PAGE";
  static RFX_CREATOR_PAGE = "RFX_CREATOR_PAGE";
  static ONBOARDING_ORG_PROFILE = "ONBOARDING_ORG_PROFILE";
  static ONBOARDING_COMPANIES = "ONBOARDING_COMPANIES";
  static ONBOARDING_NEW_COMPANY = "ONBOARDING_NEW_COMPANY";
  static ONBOARDING_PLANT = "ONBOARDING_PLANT";
  static ONBOARDING_NEW_PLANT = "ONBOARDING_NEW_PLANT";
  static ONBOARDING_NEW_BUDGET = "ONBOARDING_NEW_BUDGET";
  static ONBOARDING_NEW_USER = "ONBOARDING_NEW_USER";
  static ONBOARDING_NEW_PROFILE = "ONBOARDING_NEW_PROFILE"
  static USER_EXTEND_PROFILE = 'USER_EXTEND_PROFILE'
  static NEW_MATERIAL_PAGE = 'NEW_MATERIAL_PAGE'
  static ONBOARDING_USERS = "ONBOARDING_USERS";
  static RULE_MATA_DATA = "RULE_MATA_DATA";
  static MATERIAL_PAGE = "MATERIAL_PAGE";
  static NEW_TENDER_PAGE = "NEW_TENDER_PAGE";
  static NEW_OPPORTUNITY_PAGE = "NEW_OPPORTUNITY_PAGE";
  static ONBOARDING_COMPANY_DETAILS = "ONBOARDING_COMPANY_DETAILS";
  static NIT_DOCS_PAGE = "NIT_DOCS_PAGE";
  static ONBOARDING_EMAIL = "ONBOARDING_EMAIL";

  static TENDER_MANAGEMENT_PAGE = "TENDER_MANAGEMENT_PAGE";
  static TENDER_OPPORTUNITY_DETAILS_PAGE = "TENDER_OPPORTUNITY_DETAILS_PAGE";
  static NEW_TENDER_APPROVAL_PAGE = "NEW_TENDER_APPROVAL_PAGE";
  static NEW_TENDER_TECH_SECTION = "NEW_TENDER_TECH_SECTION";
  static NEW_TENDER_TECH_QUES = "NEW_TENDER_TECH_QUES";
  static NEW_TENDER_QUESTIONNAIRE = "NEW_TENDER_QUESTIONNAIRE";
  static NEW_TENDER_FINANCIAL_QUES = "NEW_TENDER_FINANCIAL_QUES";


  // Tabs AUCTION
  static AUCTION_TAB = "AUCTION_TAB";
  static REGISTRATION_TAB = "REGISTRATION_TAB";
  static BIDDING_TAB = "BIDDING_TAB";
  static QUESTIONS_TAB = "QUESTIONS_TAB";
  static POST_AUCTION_TAB = "POST_AUCTION_TAB";

  static TENDER_PUBLISHERS = "TENDER_PUBLISHERS";
  static TECHNICAL_BID_OPENERS = "TECHNICAL_BID_OPENERS";
  static FINANCIAL_BID_OPENERS = "FINANCIAL_BID_OPENERS";

  // Tabs AUCTION
  static RFX_TAB = "RFX_TAB";
  static RFX_REGISTRATION_TAB = "RFX_REGISTRATION_TAB";
  static RFX_BIDDING_TAB = "RFX_BIDDING_TAB";
  static RFX_QUESTIONS_TAB = "RFX_QUESTIONS_TAB";
  static RFX_POST_AUCTION_TAB = "RFX_POST_AUCTION_TAB";


  // Context
  static LANDING = "LANDING";
  static SUPPLIER = "SUPPLIER";
  static ADMIN = "ADMIN";
  static SUPERADMIN = "SUPERADMIN";
}

