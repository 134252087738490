<div class=" col-12 p-2">
  <div class=" bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
    <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
      <div class="grid grid-cols-3 gap-4 w-full">
        <div class="col-span-2 text-gray-500 font-semibold leading-4 tracking-tight text-base">
          <span class="">
            <img src="/assets/icons/tr-basic-detail.svg" class="tr-md-icon inline-flex">
          </span>
          Opportunity Fee
        </div>

        <div class="text-end  text-sm font-medium leading-4 text-blue-100 tracking-tight ">
          <span class="c-pointer" (click)="openEditOpportunityDrawer()">
            <i-lucide name="pencil" [size]="15" class="c-pointer my-icon inline-flex"></i-lucide>
            Edit
          </span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full p-4">
      <div class="">
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
           Publishing Date
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.publishingDate! | ordinalDate}}
          </div>
        </div>
      
        <!-- <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
          Seek clarification 
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.allowSeekClarification ? 'Yes' : 'No'}}
          </div>
        </div> -->
        <!-- <div class="grid grid-cols-3 gap-4 w-full" *ngIf="tenderOpportunityWrapperDto?.allowSeekClarification">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Seek clarification end date
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.seekClarificationEndDate! | ordinalDate}}
          </div>
        </div> -->
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Bid submission end date
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.bidSubmissionEndDate! | ordinalDate}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Financial Opening Date
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.financialOpeningDate! | ordinalDate}}
          </div>
        </div>
       
      </div>
      <div class="">
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
          Document download date
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.documentDownloadDate! | ordinalDate}}
          </div>
        </div>
        
        <!-- <div class="grid grid-cols-3 gap-4 w-full" *ngIf="tenderOpportunityWrapperDto?.allowSeekClarification">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Seek clarification start date
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.seekClarificationStartDate! | ordinalDate}}
          </div>
        </div> -->
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Bid submission start date
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.bidSubmissionStartDate! | ordinalDate}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Technical opening date
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.technicalOpeningDate! | ordinalDate}}
          </div>
        </div>
        
      </div>
    </div>
   
  </div>
</div>


