<div class="p-2 mt-5">
  <div class="grid grid-cols-1 md:grid-cols-12 gap-4 w-full items-center justify-between">
    <div class="md:col-span-3">
      <div class="flex w-full mb-3">
        <input
          class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0"
          #search id="search" placeholder="Search">
        <div class="input-group-append">
          <span class="input-group-text border-solid border-slate-50 border-l-0 rounded-l-lg h-8 bg-white"
            id="basic-addon2">
            <i-lucide name="search" [size]="16" class="my-icon c-pointer"></i-lucide>
          </span>
        </div>
      </div>
    </div>
    <div class="md:col-span-9 text-right">
      <div class="" role="group" aria-label="Basic outlined example">
        <button type="button" class="btn medium-font btn-sm p-1 " (click)="toggleDetailsTab('RAISED')"
          [ngClass]="currentTab == 'RAISED' ? 'active-btn-dashboard' : 'btn-outline-blue'">Raised</button>
        <!-- <button type="button" class="btn medium-font btn-sm"  (click)="toggleDetailsTab('ACCEPTED')"
        [ngClass]="currentTab == 'ACCEPTED' ? 'active-btn-dashboard' : 'btn-outline-blue'">Accepted</button> -->
        <button type="button" class="btn medium-font btn-sm p-1" (click)="toggleDetailsTab('PAID')"
          [ngClass]="currentTab == 'PAID' ? 'active-btn-dashboard' : 'btn-outline-blue'">Paid</button>
      </div>

    </div>
  </div>

  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0 align-middle">
        <thead class="table-dark">
          <tr class="text-center">
            <th class=" "><span>SNo</span></th>
            <th class=" "><span>Invoice Number</span></th>
            <th class=" "><span>PO Number</span></th>
            <th class=" "><span>Number of items</span></th>
            <th class=" "><span>Vendor</span></th>
            <th class=" "><span> Delivery Due</span></th>
            <th class=" "><span>Delivery Date</span></th>
            <th class=" "><span>Invoice Amount</span></th>
            <th class=" "><span>Payment Status</span></th>
            <th class=" "><span>Action</span></th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr *ngIf="isDataLoading" class="align-middle">
            <td colspan="10" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>

          <tr *ngIf="!isDataLoading && filteredInvoicesList.length == 0">
            <td class="text-center" colspan="10">
              <h5 class=" text-center block"> No Data Available</h5>
            </td>
          </tr>

          <tr class="text-center align-middle" *ngFor="let item of filteredInvoicesList$| async; index as i">

            <td class="">
              {{i + 1}}
            </td>
            <td class="">
              {{item.invoiceNumber}}
            </td>
            <td class="">
              {{item.purchaseOrderNumber}}
            </td>
            <td class="">
              {{item.invoiceOrderItems?.length || 0}}
            </td>
            <td>
              {{item.vendorCompanyName}}
            </td>
            <td class="">
              {{item.estimatedDeliveryDate}}
            </td>

            <td class="">
              {{item.deliveryDate}}
            </td>
            <td class="">
              {{getFormattedPrice(item.currency!, item.invoiceAmount)}}
            </td>
            <td class="">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex" *ngIf="!item.paymentStatus"
                (click)="openConfirmStatusModal(confirmationStatusModalTemplate, item)">
                Submit to Pay
              </button>
              <span *ngIf="item.paymentStatus">
                {{item.paymentStatus}}
              </span>
            </td>
            <td>
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex"
                (click)="openInvoiceModal(invoiceModalTemplate, item)">
                View
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>

<ng-template #invoiceModalTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Invoice</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div *ngIf="isLoading">
          <div class="d-grid gap-2 mb-3">
            <div class="e-spinner"></div>
          </div>
        </div>

        <div class="container">

          <div class="col-12 text-end" *ngIf="!isLoading">
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class my-3" (click)="generatePdf()">PDF</button>
          </div>

          <div class="col-12" id="pdfContent">
            <div class="row align-items-center">
              <div class="col-6">
                <h4 class="heading-h4">
                  Invoice
                </h4>
              </div>
              <div class="col-6 text-end">
                <img src="/downloadLandingBlob?fileId={{selectedRfxPurchaseInvoiceDto?.vendorLogo?.fileId}}"
                  [alt]="selectedRfxPurchaseInvoiceDto?.vendorCompanyName"
                  class="application-logo | img-fluid my-1 ms-md-5" />
              </div>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <div class="card p-2 bg-white ro-height ">
                    <div class=" row">
                      <div class="col-12 text-center">
                        <h5 class="heading-h5 mt-1">Vendor Details</h5>
                      </div>

                    </div>
                    <hr class="hr my-2" />

                    <div class="mb-2">
                      <b>{{selectedRfxPurchaseInvoiceDto?.vendorCompanyName}}</b>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">Vendor Code</div>
                      <div class=" col-7">{{selectedRfxPurchaseInvoiceDto?.vendorCode}}</div>
                    </div>
                    <div class="div-class">
                      {{selectedRfxPurchaseInvoiceDto?.vendorAddress?.addressLine1}},
                      {{selectedRfxPurchaseInvoiceDto?.vendorAddress?.addressLine2 ?
                      selectedRfxPurchaseInvoiceDto?.vendorAddress?.addressLine2 + ',' : ''}}
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">City</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseInvoiceDto?.vendorAddress?.city}}
                      </div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">State</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseInvoiceDto?.vendorAddress?.state}}
                      </div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">Pincode</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseInvoiceDto?.vendorAddress?.zipCode}}
                      </div>
                    </div>
                    <!-- <div class="div-class row mb-2">
                      <div class=" col-5">Gst No.</div>
                      <div class=" col-7">07AADCI9220N1ZA</div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">PAN</div>
                      <div class=" col-5">AADCI9220N1</div>
                    </div> -->


                  </div>
                </div>
                <div class="col-md-6 mb-3 ">
                  <div class="card p-2 bg-white ro-height ">
                    <div class=" row">
                      <div class="col-12 text-center">
                        <h5 class="heading-h5 mt-1">Buyer Details</h5>
                      </div>

                    </div>
                    <hr class="hr my-2" />
                    <div class="mb-2">
                      <b>{{selectedRfxPurchaseInvoiceDto?.buyerCompanyName}}</b>
                    </div>
                    <div class="div-class mb-2">
                      {{selectedRfxPurchaseInvoiceDto?.buyerAddress?.addressLine1}},
                      {{selectedRfxPurchaseInvoiceDto?.buyerAddress?.addressLine2 ?
                      selectedRfxPurchaseInvoiceDto?.buyerAddress?.addressLine2 + ',' : ''}}
                      {{selectedRfxPurchaseInvoiceDto?.buyerAddress?.city}}
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">State</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseInvoiceDto?.buyerAddress?.state}}
                      </div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">Pincode</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseInvoiceDto?.buyerAddress?.zipCode}}
                      </div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">Mobile No.</div>
                      <div class=" col-7">
                        {{getFormattedMobileNo(selectedRfxPurchaseInvoiceDto?.buyerMobileNo)}}
                      </div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">Email</div>
                      <div class=" col-7">
                        {{selectedRfxPurchaseInvoiceDto?.buyerEmailId}}
                      </div>
                    </div>
                    <!-- <div class="div-class row mb-2">
                      <div class=" col-5">Gst No.</div>
                      <div class=" col-7">17AAGCA9080R1ZW</div>
                    </div>
                    <div class="div-class row mb-2">
                      <div class=" col-5">PAN</div>
                      <div class=" col-7">AAGCA9080R</div>
                    </div> -->


                  </div>
                </div>

              </div>
            </div>
            <div class="mt-3">
              <div class="table-responsive">
                <table class="table table-bordered medium-font mb-0">
                  <thead class="table-dark">
                    <tr class="text-center">
                      <th class="" colspan="4"><span>PO Details</span></th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr class="text-center align-middle">
                      <td class="">
                        <b>PO Number</b>
                      </td>
                      <td class="">
                        {{selectedRfxPurchaseInvoiceDto?.purchaseOrderNumber}}
                      </td>

                      <td class="">
                        <b>Invoice Number</b>
                      </td>
                      <td class="">
                        {{selectedRfxPurchaseInvoiceDto?.invoiceNumber}}
                      </td>
                    </tr>
                    <tr class="text-center align-middle">
                      <td class="">
                        <b>PO Date</b>
                      </td>
                      <td class="">
                        {{selectedRfxPurchaseInvoiceDto?.purchaseOrderDate | date: 'dd/MM/yyyy'}}
                      </td>

                      <td class="">
                        <b>Invoice Date</b>
                      </td>
                      <td class="">
                        {{selectedRfxPurchaseInvoiceDto?.invoiceDate | date: 'dd/MM/yyyy'}}
                      </td>
                    </tr>
                    <tr class="text-center align-middle">
                      <td class="">
                        <b>Expected Delivery</b>
                      </td>
                      <td class="">
                        {{selectedRfxPurchaseInvoiceDto?.estimatedDeliveryDate}}
                      </td>

                      <td class="">
                        <b>Delivery Date</b>
                      </td>
                      <td class="">
                        {{selectedRfxPurchaseInvoiceDto?.deliveryDate}}
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div class="table-responsive mt-3">
                <table class="table table-bordered medium-font mb-0">
                  <thead class="table-dark">
                    <tr class="text-center">
                      <th class=""><span>SNo</span></th>
                      <th class=""><span>Item</span></th>
                      <th class=""><span>UoM</span></th>
                      <th class=""><span>Qty</span></th>
                      <th class=""><span>Tax</span></th>
                      <th class=""><span>Unit Price</span></th>
                      <th class=""><span>Subtotal</span></th>
                      <th class=""><span>Tax Amount</span></th>
                      <th class=""><span>Additional</span></th>
                      <th class=""><span>Total Bid</span></th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr class="text-center align-middle"
                      *ngFor="let invoiceItem of selectedRfxPurchaseInvoiceDto?.invoiceOrderItems; index as i">
                      <td class="">
                        {{i+1}}
                      </td>
                      <td class="">
                        {{invoiceItem.itemName}}
                      </td>
                      <td class="">
                        {{invoiceItem.uom}}
                      </td>
                      <td class="">
                        {{invoiceItem.quantity}}
                      </td>
                      <td class="">
                        {{invoiceItem.tax ? invoiceItem.tax + '%' : ''}}
                      </td>
                      <td class="">
                        {{getFormattedPrice(selectedRfxPurchaseInvoiceDto?.currency!, invoiceItem.unitPrice)}}
                      </td>
                      <td class="">
                        {{getFormattedPrice(selectedRfxPurchaseInvoiceDto?.currency!, invoiceItem.unitPriceQtyTotal)}}
                      </td>
                      <td class="">
                        {{getFormattedPrice(selectedRfxPurchaseInvoiceDto?.currency!, (invoiceItem.unitPriceQtyTaxTotal!
                        -
                        invoiceItem.unitPriceQtyTotal!))}}
                      </td>
                      <td class="">
                        {{getFormattedPrice(selectedRfxPurchaseInvoiceDto?.currency!, invoiceItem.additionalPrice)}}
                      </td>
                      <td class="">
                        {{getFormattedPrice(selectedRfxPurchaseInvoiceDto?.currency!,
                        invoiceItem.unitPriceQtyTaxAdditionalPricesTotal)}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-6 ">
                  <div class="table-responsive mt-3">
                    <table class="table table-bordered medium-font mb-0">
                      <thead class="table-dark">
                        <tr class="text-center">
                          <th class="" colspan="2">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody class="">

                        <tr class="text-center align-middle bg-highlight font-weight-bold">
                          <td class="">
                            <b>Total</b>
                          </td>
                          <td>
                            <b>
                              {{getFormattedPrice(selectedRfxPurchaseInvoiceDto?.currency!,
                              selectedRfxPurchaseInvoiceDto?.invoiceAmount)}}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmationStatusModalTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Confirmation</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p class="p-class">
          Are You sure you want to pay for this invoice
        </p>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'Purchase Order approved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>

        <div class="my-2 text-end" *ngIf="!(_showSuccessToast$ | async) && !isLoading">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="markToAccountPayableInvoice()">OK</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>