<div class="container p-3">
  <div class="col-12">
    <div class="row align-items-center">
      <div class="col-5">
        <span class="me-3">
          <a class="c-pointer" [ngClass]="{'disabled' : isDisabledPrev()}" (click)="prevQuestion()">Previous</a>
        </span>
        <span class="me-3">Q {{selectedTechnicalQuestion.sequenceNo}} to {{technicalQuestionsList.length}}</span>
        <span class="me-3">
          <a class="c-pointer" [ngClass]="{'disabled' : isDisabledNext()}" (click)="nextQuestion()">Next</a>
        </span>
      </div>
      <div class="col-6 text-end">
        <span class="badge-gray-class badge me-3">Incomplete-2</span>
        <span class="badge-blue-class badge">Complete-12</span>

      </div>
      <div class="col-1 text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
    <div class="mt-3 col-11">

      <div class="input-group">
        <div class="form-floating">
          <input class="form-control" id="ctrlQuestion" [formControl]="ctrlQuestion">
          <label for="ctrlQuestion">Question Criteria
            <span class="ms-2 badge badge-white-class">Mandatory</span>
          </label>
        </div>
      </div>
      <div class="row mt-3">
        <!-- SINGLE_CHOICE -->
        <div class="col-md-4 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.SINGLE_CHOICE">
          <div class="border p-2 mb-2 c-pointer" [ngClass]="{'selected' : isOptionSelected(item)}"
            *ngFor="let item of selectedTechnicalQuestion.questionOptions; index as i">
            <span>
              {{item.optionPreSequence}}.{{item.optionSequence}} {{item.optionText}}
            </span>
          </div>
        </div>

        <!-- MULTI_CHOICE -->
        <div class="col-md-4 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.MULTI_CHOICE">
          <div class="border p-2 mb-2 c-pointer" [ngClass]="{'selected' : isOptionSelected(item)}"
            *ngFor="let item of selectedTechnicalQuestion.questionOptions; index as i">
            <span>
              {{item.optionPreSequence}}.{{item.optionSequence}} {{item.optionText}}
            </span>
          </div>
        </div>

        <!-- DATE -->
        <div class="col-md-4 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.DATE">
          <div class="form-floating mb-3 ">
            <input ngbDatepicker #d1="ngbDatepicker" id="ctrlDateStart" (click)="d1.toggle()" type="text" class="form-control"
              [formControl]="ctrlDateStart">
            <label for="ctrlDateStart">Date</label>
            <div *ngIf="ctrlDateStart.invalid && (ctrlDateStart.dirty || ctrlDateStart.touched)">
              <p class="text-red-500 " *ngIf="ctrlDateStart.errors?.['required']">Required</p>
            </div>
          </div>
        </div>

        <!-- DATE_RANGE -->
        <div class="col-md-4 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.DATE_RANGE">
          <div class="form-floating mb-3 ">
            <input ngbDatepicker #d1="ngbDatepicker" id="ctrlDateStart1" (click)="d1.toggle()" type="text" class="form-control"
              [formControl]="ctrlDateStart">
            <label for="ctrlDateStart1">Start Date</label>
            <div *ngIf="ctrlDateStart.invalid && (ctrlDateStart.dirty || ctrlDateStart.touched)">
              <p class="text-red-500 " *ngIf="ctrlDateStart.errors?.['required']">Required</p>
            </div>
          </div>
          <div class="form-floating mb-3 ">
            <input ngbDatepicker #d2="ngbDatepicker" id="ctrlDateEnd" (click)="d2.toggle()" type="text" class="form-control"
              [formControl]="ctrlDateEnd">
            <label for="ctrlDateEnd">End Date</label>
            <div *ngIf="ctrlDateEnd.invalid && (ctrlDateEnd.dirty || ctrlDateEnd.touched)">
              <p class="text-red-500 " *ngIf="ctrlDateEnd.errors?.['required']">Required</p>
            </div>
          </div>
        </div>

        <!-- ALPHANUMERIC -->
        <div class="col-md-4 mb-3" *ngIf="selectedTechnicalQuestion.responseType == questionResponseType.ALPHANUMERIC">
          <div class="form-floating mb-3 ">
            <input type="text" class="form-control" id="ctrlAlphaNumeric" [formControl]="ctrlAlphaNumeric">
            <label for="ctrlAlphaNumeric">Response</label>
            <div *ngIf="ctrlAlphaNumeric.invalid && (ctrlAlphaNumeric.dirty || ctrlAlphaNumeric.touched)">
              <p class="text-red-500 " *ngIf="ctrlAlphaNumeric.errors?.['required']">Required</p>
            </div>
          </div>
        </div>

        <div class="col-md-8 mb-3">
          <div class="col-12 mb-3">
            <div class="row medium-grey-font" *ngFor="let documentArchive of selectedDocuments; index as i">
              <div class="col-6">
                <img src="assets/icons/file_light.svg" class="svg-icon-class c-pointer">
                {{documentArchive.displayName}}
              </div>
              <div class="col-2 text-end">
                {{formatBytes(documentArchive.fileSize)}}
              </div>
              <div class="col-3 text-end">
                <!-- <span> Expiry</span>
                <span *ngIf="!documentArchive.expiryDate">NA</span>
                <span *ngIf="documentArchive.expiryDate"> <span>{{documentArchive.expiryDate}}</span></span> -->
              </div>
            </div>

            <div class="mb-3" *ngIf="isUploadLoading">
              <div class="e-spinner"></div>
            </div>
          </div>
        </div>
        <div class="mt-1 mb-3">
        </div>
        <div class="mb-3">
          <div class="input-group">
            <div class="form-floating">
              <input class=" form-control" id="ctrlBidderComments" [formControl]="ctrlBidderComments">
              <label for="ctrlBidderComments">Comments
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>