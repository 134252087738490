<div class="card bg-white border-0 shadow chart-height">
  <div class="p-2">
    <div class="row">
      <div class="col-9">
        <h5 class="heading-h5 text-capitalize "> Revenue Chart </h5>
      </div>
      <div class="col-3 text-end">
        <img src="assets/icons/info_light.svg" class="close-icon-class" ngbTooltip="Revenue Chart">
      </div>
    </div>
    <hr class="hr my-2">
    <div class="mt-2">
      <div id="chart-revenue" echarts [options]="option" class="demo-chart" *ngIf="!isChartLoading"></div>
      <div class="d-grid gap-2 mb-3" *ngIf="isChartLoading">
        <div class="e-spinner"></div>
      </div>
    </div>
  </div>
</div>
