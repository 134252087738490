<div class="m-2">
  <div class=" nav-bg text-start">

    <ul class="" style="list-style: none;" class="ps-0">
      <li class="list-class" (click)="toggleDetailsTab('PR')"
        [ngClass]="currentTab == 'PR' ? 'btn-active' : 'btn-default'">
        Purchase Request
      </li>
      <li class="list-class" (click)="toggleDetailsTab('PO')"
        [ngClass]="currentTab == 'PO' ? 'btn-active' : 'btn-default'">
        Purchase Order
      </li>
      <li class="list-class" (click)="toggleDetailsTab('cataloguePO')"
      [ngClass]="currentTab == 'cataloguePO' ? 'btn-active' : 'btn-default'">
        Catalogue PO
      </li>
      <li class="list-class" (click)="toggleDetailsTab('INVOICE')"
      [ngClass]="currentTab == 'INVOICE' ? 'btn-active' : 'btn-default'">
      Account Payable
    </li>
     
    </ul>
  </div>
</div>

<div *ngIf="currentTab == 'PR'">
  <app-admin-purchase-requests type="APPROVAL"></app-admin-purchase-requests>
</div>

<div *ngIf="currentTab == 'PO'">
  <app-rfx-purchase-order></app-rfx-purchase-order>
</div>
<div *ngIf="currentTab == 'cataloguePO'">
<app-catalogue-po></app-catalogue-po>
</div>
<div *ngIf="currentTab == 'INVOICE'">
  <app-admin-invoice-workflow></app-admin-invoice-workflow>
</div>

<ng-template #allItems>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>REQUISITION</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <table class="table table-bordered text-center medium-font">
          <thead class="font-weight-bold">
            <tr>
              <th scope="col" class="">#</th>
              <th scope="col" class="">Items</th>
              <th scope="col" class="">Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of selectedCatalogueRequisitionWrapper?.catalogueCartWrapperDtos; index as i">
              <th scope="row" class="">{{i+1}}</th>
              <td class="">{{item.catalogueLineItemsEntityDto?.title}}</td>
              <td class="">
                {{selectedCatalogueRequisitionWrapper?.catalogueCartWrapperDtos![0].currency?.symbol}}{{item.quantity! *
                item.catalogueVendor?.amount!}}</td>
            </tr>

          </tbody>
        </table>
        <div class="col-10 mx-auto">
          <div class="mt-2 row">
            <div class="col-5">
              <b>Total</b>
            </div>
            <div class="col-7 text-end">
              <b>{{selectedCatalogueRequisitionWrapper?.catalogueCartWrapperDtos![0].currency?.symbol}}{{selectedCatalogueRequisitionWrapper?.totalPrice}}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #arcApprove>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Requisition Approval</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>Are you sure you want to approve</p>
        <div class="col-12 text-end mt-3">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class" (click)="markToApprove()">OK</button>
        </div>
      </div>

    </div>
  </div>
</ng-template>

 
