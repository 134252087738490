import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { TenderDocumentInfoDto } from 'src/app/shared/models/TenderDocumentInfoDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { TenderManagementService } from 'src/app/shared/services/tender-management.service';
import { selectTenderWrapperUiDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-nit-document-drawer',
  templateUrl: './nit-document-drawer.component.html',
  styleUrls: ['./nit-document-drawer.component.sass']
})
export class NitDocumentDrawerComponent implements OnInit, OnDestroy {
  currentTab: string = 'DETAILS';
  isShowDescription: boolean = false
  isDocLoading: boolean = true;

  docPdfUrl?: string

  tenderWrapperUiDto?: TenderWrapperUiDto;
  selectedNitDocument?: TenderDocumentInfoDto;

  tenderWrapperUiDtoSubscription$?: Subscription;

  constructor(
    private store: Store,
    private drawerService: DrawerService,
    private tenderManagementService: TenderManagementService
  ) { }

  ngOnInit() {
    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);

        if (this.tenderWrapperUiDto?.nitDocuments && this.tenderWrapperUiDto.nitDocuments.length > 0) {
          this.selectedNitDocument = this.tenderWrapperUiDto.nitDocuments[0];
        }
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })
  }

  toggleDetailsTab(tab: string) {
    this.currentTab = tab;

    if (tab == 'PREVIEW') {
      setTimeout(() => {
        this.downloadDocument();
      }, 1500);
    }
  }

  selectNitDoc(item: TenderDocumentInfoDto) {
    this.selectedNitDocument = item
  }

  showOrHideDescription() {

  }

  downloadDocument() {
    this.isDocLoading = true;

    this.tenderManagementService.downloadTenderDocument(this.selectedNitDocument?.fileId!).subscribe({
      next: (response) => {
        if (response) {
          let file = new Blob([response], { type: this.selectedNitDocument?.fileType });
          this.docPdfUrl = URL.createObjectURL(file);
          this.isDocLoading = false;
        }
      },
      error: (err) => {
        this.isDocLoading = false;
        console.error(err);
        console.log("Error while previewing document");
      }
    });
  }
  

  closeDrawer() {
    this.drawerService.closeDrawer();
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }
  }

}
