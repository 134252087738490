import { EventEnum } from "../../enums/EventEnum";
import { Currency } from "../Currency";
import { FileInfoDto } from "../FileInfoDto";

export class SourcingEventDto {
  type?: string;
  sequenceNo?: number;
  eventName?: string;
  currency?: Currency;
  sourcingEventImage?: FileInfoDto;
  antiSnipe?: number;
  auctionType?: string;
  singleActivity?: boolean;
  noOfActiveActivity?:number;
  notOfActivity?:number;
  createdDate?: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?:string;
  active?: boolean = false;
  timeZone?: string;
  eventId?: string;
  status?: string;

  allowEditWhileUpcoming: boolean = true;
  allowEditWhileRunning: boolean = true;


  constructor(
    type?: string,
    sequenceNo?: number,
    eventName?: string,
    singleActivity?: boolean,
    noOfActiveActivity?: number,
    notOfActivity?: number,
    createdDate?: string,
    startDate?: string,
    startTime?: string,
    endDate?: string,
    endTime?: string,
    active: boolean = false,
    timeZone?: string,
    eventId?: string
  ) {
    this.type = type;
    this.sequenceNo = sequenceNo;
    this.eventName = eventName;
    this.singleActivity = singleActivity;
    this.noOfActiveActivity = noOfActiveActivity;
    this.notOfActivity = notOfActivity;
    this.createdDate = createdDate;
    this.startDate = startDate;
    this.startTime = startTime;
    this.endDate = endDate;
    this.endTime = endTime;
    this.active = active;
    this.timeZone = timeZone;
    this.eventId = eventId;
  }

}
