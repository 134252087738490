<div class="mt-3">
  <!-- <div class="col-md-12 mb-4 text-md-end ">
    <div class="btn-group rounded-0" role="group" aria-label="Basic outlined example">
      <button type="button" class="btn medium-font btn-sm " (click)="toggleCurrentStage(eventStages.EVENT)"
      [ngClass]="activeStage == eventStages.EVENT ? 'active-btn-dashboard' : 'btn-outline-blue'">Event</button>

      <button type="button" class="btn medium-font btn-sm" (click)="toggleCurrentStage(eventStages.POST_EVENT)"
        [ngClass]="currentStageIndex > 2 ? 'active-btn-dashboard' : 'btn-outline-blue'">Evaluation</button>
      
    </div>
  </div> -->
  <div id="stepProgressBar">
    <div class="step c-pointer" [ngClass]="{'step-active' : activeStage == eventStages.EVENT}"
      (click)="toggleCurrentStage(eventStages.EVENT)">
      <p class="step-text">01 Event</p>
      <div class="bullet step-progress" [ngClass]="{'completed' : currentStageIndex > 1}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 1}"></div>
      </div>
    </div>
    <div class="step c-pointer" [ngClass]="{'step-active' : activeStage == eventStages.POST_EVENT}"
      (click)="toggleCurrentStage(eventStages.POST_EVENT)">
      <p class="step-text">02 Evaluation</p>
      <div class="bullet step-progress" [ngClass]="{'completed' : currentStageIndex > 2}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 2}"></div>
      </div>
    </div>
    <!-- <div class="step c-pointer" [ngClass]="{'step-active' : activeStage == eventStages.CONTRACT}"
      (click)="toggleCurrentStage(eventStages.CONTRACT)">
      <p class="step-text">03 Contract</p>
      <div class="bullet step-progress" [ngClass]="{'completed' : currentStageIndex > 3}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 3}"></div>
      </div>
    </div>
    <div class="step c-pointer" [ngClass]="{'step-active' : activeStage == eventStages.PO}"
      (click)="toggleCurrentStage(eventStages.PO)">
      <p class="step-text">04 PO</p>
      <div class="bullet step-progress" [ngClass]="{'completed' : currentStageIndex > 4}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 4}"></div>
      </div>
    </div>
    <div class="step c-pointer" [ngClass]="{'step-active' : activeStage == eventStages.INVOICE}"
      (click)="toggleCurrentStage(eventStages.INVOICE)">
      <p class="step-text">05 Invoice</p>
      <div class="bullet step-progress" [ngClass]="{'completed' : currentStageIndex > 5}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 5}"></div>
      </div>
    </div>
    <div class="step c-pointer" [ngClass]="{'step-active' : activeStage == eventStages.PAID}"
      (click)="toggleCurrentStage(eventStages.PAID)">
      <p class="step-text">06 Paid</p>
      <div class="bullet" [ngClass]="{'completed' : currentStageIndex > 6}">
        <div class="bullet-child" [ngClass]="{'half-filled' : currentStageIndex == 6}"></div>
      </div>
    </div> -->
  </div>
</div>



<ng-container [ngTemplateOutlet]="eventType == 'RFX' ? rfxCreatorPage : auctionCreatorPage"> </ng-container>

<ng-template #auctionCreatorPage>
  <app-auction-creator [currentEventStage$]="currentEventStage$"></app-auction-creator>
</ng-template>

<ng-template #rfxCreatorPage>
  <app-rfx-creator [currentEventStage$]="currentEventStage$"></app-rfx-creator>
</ng-template>