import { Component, ViewChild, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { EmailTemplateDto } from 'src/app/shared/models/user/EmailTemplateDto';
import { FirestoreListenerService } from 'src/app/shared/services/firestore-listener.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AngularEditorComponent } from 'src/assets/plugins/angular-editor/public-api';
import { SuperAdminDashboardService } from 'src/app/shared/services/super-admin-dashboard.service';
import { EmailTemplateService } from 'src/app/shared/services/email-template.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { Router } from '@angular/router';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { Editor } from 'primeng/editor';
import { Store } from '@ngrx/store';
import { onboardingFeature } from 'src/app/shared/state-management/onboarding/onboarding.features';
import { NavigationEventsDto } from 'src/app/shared/models/NavigationEventsDto';
import { NavigationActions } from 'src/app/shared/state-management/navigation/navigation.actions';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

interface EmailPreDefinedChip {
  id: string
  label: string
}

@Component({
  selector: 'app-email-template-config',
  templateUrl: './email-template-config.component.html',
  styleUrls: ['./email-template-config.component.sass']
})
export class EmailTemplateConfigComponent implements OnInit, AfterViewInit {
  @ViewChild("bodyEditor") bodyEditor?: Editor;

  isEditMode: boolean = false;
  isLoading: boolean = false;
  templateStatus: boolean = false;
  cursorPosition: number = 0;

  formGroup: FormGroup;
  ctrlEmailTest: FormControl = new FormControl('', Validators.required);
  ctrlToggleTemplateStatus: FormControl = new FormControl(true, Validators.required);

  emailTemplates: Array<EmailTemplateDto> = [];
  selectedEmailTemplate?: EmailTemplateDto;
  selectedOrganizationUiDto?: OrganizationWrapperUiDto;

  _errorMsg$ = new BehaviorSubject<string>('');
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  activeTemplateId$ = new BehaviorSubject<string>('');

  defaultChips: EmailPreDefinedChip[] = [
    { id: '#AUCTION_NAME', label: 'Auction Name' },
    { id: '#BIDDER_NAME', label: 'Bidder Name' },
    { id: '#AUCTION_HOUSE_NAME', label: 'Auction House Name' },
    { id: '#URL', label: 'Url' },
  ]
  constructor(
    private superAdminService: SuperAdminDashboardService,
    private firestoreService: FirestoreListenerService,
    private emailTemplateService: EmailTemplateService,
    private changeDetectDef: ChangeDetectorRef,
    private fb: FormBuilder,
    private ngbModal: NgbModal,
    private router: Router,
    private store: Store
  ) {
    this.formGroup = this.fb.group({
      subject: new FormControl('', [Validators.required]),
      body: new FormControl('', Validators.required),
      enable: new FormControl(false)
    })
  }

  ngOnInit(): void {
    this.loadOrganization();

    this.superAdminService.getEmailTemplatesList$.subscribe(data => {
      if (data && data.length > 0) {
        this.emailTemplates = data;
        this.emailTemplates.sort((a, b) => a.templateName!.localeCompare(b.templateName!));
        if (this.selectedEmailTemplate) {
          this.selectedEmailTemplate = this.emailTemplates.filter(item => item.templateId == this.selectedEmailTemplate?.templateId)[0];
        } else {
          this.selectedEmailTemplate = this.emailTemplates.find(item => item.templateId == "BIDDER_APPLICATION_REGISTRATION_INCOMPLETE");
        }

        this.activeTemplateId$.next(this.selectedEmailTemplate?.templateId!)
        this.ctrlToggleTemplateStatus.patchValue(this.selectedEmailTemplate?.enable);
      } else {
        this.emailTemplates = [];
      }
    })
  }

  dropIntoEditor(event: any) {
    const draggedItem = event.item.data;
    const editorInstance = document.getElementById('bodyEditor')?.querySelector('.p-editor-content');

    if (editorInstance) {
      const editor = editorInstance.firstChild;

      if (editor) {
        
        const dropX = event.dropPoint.x;
        const dropY = event.dropPoint.y;

        const editorRect = editorInstance.getBoundingClientRect();

        if (dropX >= editorRect.left && dropX <= editorRect.right && dropY >= editorRect.top && dropY <= editorRect.bottom) {
          const range = document.caretRangeFromPoint(dropX, dropY);

          if (range) {
            range.deleteContents();

            const node = document.createTextNode(draggedItem.id);
            range.insertNode(node);

            range.setStartAfter(node);
            range.setEndAfter(node);
            const selection = window.getSelection();
            if (selection) {
              selection.removeAllRanges();
              selection.addRange(range);
            }
          }
        } else {
          
          console.warn('Dropped outside the editor');
        }
      }
    }
  }

  ngAfterViewInit(): void {
    ApplicationUtils.clearTextFormatOnPaste('bodyEditor');
  }

  async loadOrganization() {
    let organization = await firstValueFrom(this.store.select(onboardingFeature.selectOrgOrganogramUiDto));
    if (organization) {
      this.selectedOrganizationUiDto = organization.organizationWrapperUiDto;
      this.firestoreService.listenEmailTemplate(this.selectedOrganizationUiDto?.orgCode!);
      this.superAdminService.loadEmailTemplateData(this.selectedOrganizationUiDto?.orgCode!)
    }
  }

  navigateToHome() {
    // this.router.navigate(['SuperAdmin/Dashboard'], { skipLocationChange: true })
    const navigation = new NavigationEventsDto();
    navigation.pageName = ApplicationConstants.DASHBOARD_PAGE;
    navigation.contextName = ApplicationConstants.ADMIN;
    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
  }



  populateData() {
    this.changeDetectDef.detectChanges();
    this.formGroup.reset();
    this.formGroup.controls['subject'].patchValue(this.selectedEmailTemplate?.subject);
    this.formGroup.controls['body'].patchValue(this.selectedEmailTemplate?.body);
    this.formGroup.controls['enable'].patchValue(this.selectedEmailTemplate?.enable);

    setTimeout(() => {
      if (this.bodyEditor) {
        this.bodyEditor.writeValue(this.selectedEmailTemplate?.body);
      }
      this.formGroup.updateValueAndValidity();
    }, 100);
  }

  changeTemplate(template: EmailTemplateDto) {
    this.selectedEmailTemplate = template;
    this.activeTemplateId$.next(this.selectedEmailTemplate?.templateId!);
    this.ctrlToggleTemplateStatus.patchValue(this.selectedEmailTemplate?.enable);
    this.populateData();
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;

    if (this.isEditMode) {
      this.populateData();

      this.changeDetectDef.detectChanges();

      if (this.bodyEditor) {
        const quillEditor = this.bodyEditor.getQuill();
        quillEditor.on('selection-change', (range: any) => {
          if (range) {
            this.cursorPosition = range.index;
          }
        });

        quillEditor.on('text-change', () => {
          const range = quillEditor.getSelection();
          if (range) {
            this.cursorPosition = range.index;
          }
        });
      }
    }
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  openModal(content: any) {
    this.ctrlEmailTest.reset();
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    })
  }

  openChangeStatusModal(content: any) {
    let emailTemplate: EmailTemplateDto = ApplicationUtils.clone(this.selectedEmailTemplate);
    this.templateStatus = emailTemplate.enable ?? false;

    let modalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });

    modalRef.result.then(() => {
      this.ctrlToggleTemplateStatus.patchValue(this.selectedEmailTemplate?.enable);
    })
  }

  resetTemplate() {
    let defaultTemplate = this.emailTemplateService.emailTemplates.find(item => item.templateId == this.selectedEmailTemplate?.templateId);
    this.formGroup.controls['subject'].patchValue(defaultTemplate?.subject);
    this.formGroup.controls['body'].patchValue(defaultTemplate?.body);
    this.formGroup.controls['enable'].patchValue(false);
    this.saveEmailTemplate();
  }

  resetAllTemplates() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.isLoading = true;

    if (this.emailTemplates.length > 0) {
      let countUpdatedTemplate = 0;

      this.emailTemplateService.emailTemplates.forEach(defaultTemplate => {
        let oldTemplate = this.emailTemplates.find(item => item.templateId == defaultTemplate?.templateId);
        let updatedTemplate: EmailTemplateDto;

        if (oldTemplate) {
          updatedTemplate = ApplicationUtils.clone(oldTemplate);
        } else {
          updatedTemplate = new EmailTemplateDto();
        }

        updatedTemplate.documentId = defaultTemplate.templateId + " : " + this.selectedOrganizationUiDto!.orgCode;
        updatedTemplate.orgCode = this.selectedOrganizationUiDto?.orgCode;
        updatedTemplate.templateId = defaultTemplate.templateId;
        updatedTemplate.templateName = defaultTemplate.templateName;
        updatedTemplate.subject = defaultTemplate.subject;
        updatedTemplate.body = defaultTemplate.body;
        updatedTemplate.description = defaultTemplate.description;
        updatedTemplate.enable = defaultTemplate.enable;
        updatedTemplate.applicationSpecific = defaultTemplate?.applicationSpecific;

        this.firestoreService.saveEmailTemplate(updatedTemplate!)
          .then(() => {
            console.log("Email Template Updated: " + updatedTemplate.templateName);
            countUpdatedTemplate += 1;

            if (this.emailTemplates.length == countUpdatedTemplate) {
              this._showSuccessToast$.next(true);
              this.isLoading = false;
              setTimeout(() => {
                this._showSuccessToast$.next(false);
                this.closeModal();
              }, 2000);
            }
          }).catch((err) => {
            console.error(err);
            this.isLoading = false;
            this._showErrorToast$.next(true);
            this._errorMsg$.next("Error while resetting all templates.")
          });
      })
    } else {
      this.emailTemplateService.saveEmailTemplatesToFirestore(this.selectedOrganizationUiDto?.orgCode!);

      this._showSuccessToast$.next(true);
      this.isLoading = false;
      setTimeout(() => {
        this._showSuccessToast$.next(false);
        this.closeModal();
      }, 2000);
    }
  }

  saveEmailTemplate() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    if (this.formGroup.invalid) {
      this.formGroup.markAsTouched();
      return;
    }

    let emailTemplate: EmailTemplateDto = ApplicationUtils.clone(this.selectedEmailTemplate);
    emailTemplate.subject = this.formGroup.controls['subject'].value;
    emailTemplate.body = this.formGroup.controls['body'].value;
    emailTemplate.enable = this.formGroup.controls['enable'].value;

    this.isLoading = true;

    this.superAdminService.saveEmailTemplate(emailTemplate)
      .subscribe({
        next: (serverApiResponse) => {
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          this.superAdminService.updateEmailTemplate(serverApiResponse.data as EmailTemplateDto)
          setTimeout(() => {
            this.isEditMode = false;
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000);
        },
        error: (err) => {
          console.error(err);
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this._errorMsg$.next("Error while updating template.")
        }
      });

  }

  changeEmailTemplateStatus(action: string) {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    this.isLoading = true;

    let emailTemplate: EmailTemplateDto = ApplicationUtils.clone(this.selectedEmailTemplate);
    emailTemplate.enable = action == 'ENABLE';

    this.firestoreService.saveEmailTemplate(emailTemplate)
      .then(() => {
        this._showSuccessToast$.next(true);
        this.isLoading = false;

        setTimeout(() => {
          this._showSuccessToast$.next(false);
          this.closeModal();
        }, 2000);

      }).catch((err) => {
        console.error(err);
        this.isLoading = false;
        this._errorMsg$.next("Error while changing email status. Try again.");
        this._showErrorToast$.next(true);
      });
  }

  sendTestEmail() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);

    if (this.ctrlEmailTest.invalid) {
      this.ctrlEmailTest.markAsTouched();
      return;
    }

    this.isLoading = true;

    let emailId = this.ctrlEmailTest.value;
    let orgCode = this.selectedOrganizationUiDto?.orgCode;

    this.emailTemplateService.sendTestEmail(emailId, orgCode!, this.selectedEmailTemplate?.templateId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)

        } else {
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._errorMsg$.next("Error while sending test email. Try again.");
        this._showErrorToast$.next(false);
      }
    })
  }

  onSelectChip(chip: EmailPreDefinedChip) {
    const editor = this.bodyEditor?.getQuill();
    if (editor) {
      const cursorPosition = editor.getSelection()?.index ?? this.cursorPosition;
      editor.insertText(cursorPosition, chip.id);
      editor.setSelection(cursorPosition + chip.id.length); // Move cursor after inserted text
    }
  }

}
