<div class="model-body">
  <!-- <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Select Category</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header> -->

      <div class="d-grid gap-2 mb-3" *ngIf="loadingData">
        <div class="e-spinner"></div>
      </div>

      <p class="mb-2">
        Please choose the categories for which you'd like to receive email notifications when auctions within those
        categories commence
      </p>

      <app-categories-tree-view [selectionMode]="TreeSelectionMode.single"
        (onSelectedCategory)="onCategorySelectedChange($event)"></app-categories-tree-view>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="'Preference categories saved successfully.'"></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="col-12 text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 me-3" (click)="closeModal()">Skip</button>
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" *ngIf="!isGlobalBidder" (click)="saveCategories()">Save</button>
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" *ngIf="isGlobalBidder" (click)="emitSelectedCategories()">Save</button>
      </div>
    
</div>
