<!-- <div class="col-12 text-end my-5">
<button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class">
  View
</button>
<button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class">
  Back
</button>
</div> -->
<div class="col-12">
  <div class="bg-white p-2 mb-3">
    <div class="flex flex-row align-items-center">
      <div class="md:basis-1/2 mb-2">
        <h5 class="heading-h5">PR/PO/Invoices</h5>
      </div>
      <div class="md:basis-1/2 mb-4 md:text-end ">
        <div class="btn-group rounded-0" role="group" aria-label="Basic outlined example">
          <button type="button" class="btn medium-font btn-sm " (click)="toggleDetailsTab('TODAY')"
            [ngClass]="currentTab == 'TODAY' ? 'active-btn-dashboard' : 'btn-outline-blue'">Today</button>
          <!-- <button type="button" class="btn p-2 "  (click)="toggleDetailsTab('LASTWEEK')"
        [ngClass]="currentTab == 'LASTWEEK' ? 'active-btn-dashboard' : 'btn-outline-blue'">Last Week</button> -->
          <!-- <button type="button" class="btn p-2"  (click)="toggleDetailsTab('MONTH')"
        [ngClass]="currentTab == 'MONTH' ? 'active-btn-dashboard' : 'btn-outline-blue'">Month</button> -->
          <button type="button" class="btn medium-font btn-sm" (click)="toggleDetailsTab('LASTMONTH')"
            [ngClass]="currentTab == 'LASTMONTH' ? 'active-btn-dashboard' : 'btn-outline-blue'">Last Month</button>
          <button type="button" class="btn medium-font btn-sm" (click)="toggleDetailsTab('LASTYEAR')"
            [ngClass]="currentTab == 'LASTYEAR' ? 'active-btn-dashboard' : 'btn-outline-blue'">Last Year</button>
        </div>
      </div>
    </div>
   <div class="">
    <div *ngIf="currentTab == 'TODAY'">
      <div class="flex flex-row gap-3 mb-3">
        <div class="md:basis-1/3 text-center">
          <div class="card px-2 py-4 bg-purple ">
            <div class=" fs-4 text-white">
              <b> 22</b>
            </div>
            <div class="medium-font text-white">
              PR Received
            </div>
          </div>
        </div>
        <div class="md:basis-1/3 text-center">
          <div class="card px-2 py-4 bg-prime ">
            <div class=" fs-4 text-white">
              <b>8</b>
            </div>
            <div class=" text-white medium-font">
              PO Generated
            </div>
          </div>
        </div>
        <div class="md:basis-1/3 text-center">
          <div class="card px-2 py-4 bg-green ">
            <div class="fs-4 text-white">
              <b> ₹40,000</b>
            </div>
            <div class="medium-font text-white">
              Total PO Amount
            </div>
          </div>
        </div>
      </div>
    
    <div class="flex flex-row gap-3 mb-3">
      <div class="md:basis-1/3 text-center">
        <div class="card px-2 py-4 bg-purple ">
          <div class=" fs-4 text-white">
            <b>22</b>
          </div>
          <div class="medium-font text-white">
            Approved PR
          </div>
        </div>
      </div>
      <div class="md:basis-1/3 text-center">
        <div class="card px-2 py-4 bg-prime ">
          <div class=" fs-4 text-white">
            <b>16</b>
          </div>
          <div class="medium-font text-white">
            Invoice Received
          </div>
        </div>
      </div>
      <div class="md:basis-1/3 text-center">
        <div class="card px-2 py-4 bg-green ">
          <div class=" fs-4 text-white">
            <b> ₹36,000</b>
          </div>
          <div class="medium-font text-white">
            Total Invoice Amount
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="currentTab == 'LASTMONTH'">
    <div class="row mb-3">
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-purple ">
          <div class=" fs-4 text-white">
            <b>440</b>
          </div>
          <div class="medium-font text-white">
            PR Received
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-prime ">
          <div class=" fs-4 text-white">
            <b>160</b>
          </div>
          <div class="medium-font text-white">
            PO Generated
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-green ">
          <div class=" fs-4 text-white">
            <b>₹8,00,000</b>
          </div>
          <div class="medium-font text-white">
            Total PO Amount
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-purple ">
          <div class=" fs-4 text-white">
            <b> 320</b>
          </div>
          <div class="medium-font text-white">
            Approved PR
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-prime ">
          <div class=" fs-4 text-white">
            <b> 240</b>
          </div>
          <div class="medium-font text-white">
            Invoice Received
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-green ">
          <div class=" fs-4 text-white">
            <b> ₹7,20,000</b>
          </div>
          <div class="medium-font text-white">
            Total Invoice Amount
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="currentTab == 'LASTYEAR'">
    <div class="row mb-3">
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-purple ">
          <div class=" fs-4 text-white">
            <b>5280</b>
          </div>
          <div class="medium-font text-white">
            PR Received
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-prime ">
          <div class=" fs-4 text-white">
            <b> 1920</b>
          </div>
          <div class="medium-font text-white">
            PO Generated
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-green ">
          <div class=" fs-4 text-white">
            ₹96,00,000
          </div>
          <div class="medium-font text-white">
            Total PO Amount
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-purple ">
          <div class=" fs-4 text-white">
            <b> 3840</b>
          </div>
          <div class="medium text-white">
            Approved PR
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-prime ">
          <div class=" fs-4 text-white">
            <b> 2880</b>
          </div>
          <div class="medium-font text-white">
            Invoice Received
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center">
        <div class="card px-2 py-4 bg-green ">
          <div class=" fs-4 text-white">
            <b> ₹86,40,000</b>
          </div>
          <div class="medium-font text-white">
            Total Invoice Amount
          </div>
        </div>
      </div>
    </div>
  </div>
   </div>

  <app-spend-analytics></app-spend-analytics>
</div>
</div>

  <!-- <div class="col-12" *ngIf="currentTab == 'TODAY'">
    <div class="row">
      <div class="col-md-6 mb-2">
        <div class="my-2">
          <b>Enquiries</b>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
               <b> 50</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                INPROCESS
              </div>
              <div class="font-weight-bold">
               <b> 20</b>
              </div>
            </div>
          </div>
       
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                QUOTED
              </div>
              <div class="font-weight-bold">
               <b> 15</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-danger">
              <div>
                REGRET
              </div>
              <div class="font-weight-bold">
               <b> 5</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                ENQUIRY LINES
              </div>
              <div class="font-weight-bold">
               <b>300</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                QUOTES LINE
              </div>
              <div class="font-weight-bold">
               <b>150</b>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                QUOTED LINES AMOUNT
              </div>
              <div class="font-weight-bold">
               <b>₹5,00,000</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="my-2">
          <b>Purchase Order</b>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
               <b>30</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                INPROCESS
              </div>
              <div class="font-weight-bold">
               <b>10</b>
              </div>
            </div>
          </div>
       
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                DISPATCHED
              </div>
              <div class="font-weight-bold">
               <b>15</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-danger">
              <div>
                CANCELLED
              </div>
              <div class="font-weight-bold">
               <b>5</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                PO LINES
              </div>
              <div class="font-weight-bold">
               <b>200</b>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                PO AMOUNT
              </div>
              <div class="font-weight-bold">
               <b>₹7,00,000</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      </div>
      <div class="row">
        <div class="col-md-6 mb-2">
          <h5 class="heading-h5">IU PERFORMANCE</h5>
        </div>
        <div class="col-md-6">
    
        </div>
        
        <div class="col-md-6 mb-2">
          <div class="my-2">
            <b>RFQS TO QUOTE</b>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <div class="card p-4 text-white text-center m-auto bg-blue">
                <div>
                  AVERAGE TAT(DAYS)
                </div>
                <div class="font-weight-bold">
                 <b>0</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="my-2">
            <b>POs TO DISPATCHED</b>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <div class="card p-4 text-white text-center m-auto bg-blue">
                <div>
                  AVERAGE DELIVERY(DAYS)
                </div>
                <div class="font-weight-bold">
                 <b> 8</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        </div>
   </div>
   <div class="col-12" *ngIf="currentTab == 'LASTWEEK'">
    <div class="row">
      <div class="col-md-6 mb-2">
        <div class="my-2">
          <b>Enquiries</b>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
               <b>200</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                INPROCESS
              </div>
              <div class="font-weight-bold">
               <b>100</b>
              </div>
            </div>
          </div>
       
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                QUOTED
              </div>
              <div class="font-weight-bold">
               <b>80</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-danger">
              <div>
                REGRET
              </div>
              <div class="font-weight-bold">
               <b>20</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                ENQUIRY LINES
              </div>
              <div class="font-weight-bold">
               <b>1200</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                QUOTES LINE
              </div>
              <div class="font-weight-bold">
               <b>600</b>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                QUOTED LINES AMOUNT
              </div>
              <div class="font-weight-bold">
               <b>₹20,00,000</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="my-2">
          <b>Purchase Order</b>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
               <b>150</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                INPROCESS
              </div>
              <div class="font-weight-bold">
               <b> 50</b>
              </div>
            </div>
          </div>
       
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                DISPATCHED
              </div>
              <div class="font-weight-bold">
               <b>70</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-danger">
              <div>
                CANCELLED
              </div>
              <div class="font-weight-bold">
               <b>30</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                PO LINES
              </div>
              <div class="font-weight-bold">
               <b>1000</b>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                PO AMOUNT
              </div>
              <div class="font-weight-bold">
               <b>₹35,00,000</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      </div>
      <div class="row">
        <div class="col-md-6 mb-2">
          <h5 class="heading-h5">IU PERFORMANCE</h5>
        </div>
        <div class="col-md-6">
    
        </div>
        
        <div class="col-md-6 mb-2">
          <div class="my-2">
            <b>RFQS TO QUOTE</b>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <div class="card p-4 text-white text-center m-auto bg-blue">
                <div>
                  AVERAGE TAT(DAYS)
                </div>
                <div class="font-weight-bold">
                 <b> 0</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="my-2">
            <b>POs TO DISPATCHED</b>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <div class="card p-4 text-white text-center m-auto bg-blue">
                <div>
                  AVERAGE DELIVERY(DAYS)
                </div>
                <div class="font-weight-bold">
                 <b>8</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        </div>
   </div>
   <div class="col-12" *ngIf="currentTab == 'MONTH'">
    <div class="row">
      <div class="col-md-6 mb-2">
        <div class="my-2">
          <b>Enquiries</b>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
               <b>800</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                INPROCESS
              </div>
              <div class="font-weight-bold">
               <b>400</b>
              </div>
            </div>
          </div>
       
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                QUOTED
              </div>
              <div class="font-weight-bold">
               <b>300</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-danger">
              <div>
                REGRET
              </div>
              <div class="font-weight-bold">
               <b>50</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                ENQUIRY LINES
              </div>
              <div class="font-weight-bold">
               <b>4800</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                QUOTES LINE
              </div>
              <div class="font-weight-bold">
               <b>2400</b>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                QUOTED LINES AMOUNT
              </div>
              <div class="font-weight-bold">
               <b>₹80,00,000</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="my-2">
          <b>Purchase Order</b>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
               <b>600</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                INPROCESS
              </div>
              <div class="font-weight-bold">
               <b>200</b>
              </div>
            </div>
          </div>
       
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                DISPATCHED
              </div>
              <div class="font-weight-bold">
               <b>300</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-danger">
              <div>
                CANCELLED
              </div>
              <div class="font-weight-bold">
               <b>100</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                PO LINES
              </div>
              <div class="font-weight-bold">
               <b>4000</b>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                PO AMOUNT
              </div>
              <div class="font-weight-bold">
               <b>₹1,40,00,000</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      </div>
      <div class="row">
        <div class="col-md-6 mb-2">
          <h5 class="heading-h5">IU PERFORMANCE</h5>
        </div>
        <div class="col-md-6">
    
        </div>
        
        <div class="col-md-6 mb-2">
          <div class="my-2">
            <b>RFQS TO QUOTE</b>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <div class="card p-4 text-white text-center m-auto bg-blue">
                <div>
                  AVERAGE TAT(DAYS)
                </div>
                <div class="font-weight-bold">
                 <b>0</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="my-2">
            <b>POs TO DISPATCHED</b>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <div class="card p-4 text-white text-center m-auto bg-blue">
                <div>
                  AVERAGE DELIVERY(DAYS)
                </div>
                <div class="font-weight-bold">
                 <b>8</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        </div>
   </div>
   <div class="col-12" *ngIf="currentTab == 'LASTMONTH'">
    <div class="row">
      <div class="col-md-6 mb-2">
        <div class="my-2">
          <b>Enquiries</b>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
               <b>600</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                INPROCESS
              </div>
              <div class="font-weight-bold">
               <b>300</b>
              </div>
            </div>
          </div>
       
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                QUOTED
              </div>
              <div class="font-weight-bold">
               <b>200</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-danger">
              <div>
                REGRET
              </div>
              <div class="font-weight-bold">
               <b>40</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                ENQUIRY LINES
              </div>
              <div class="font-weight-bold">
               <b>3600</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                QUOTES LINE
              </div>
              <div class="font-weight-bold">
               <b>1800</b>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                QUOTED LINES AMOUNT
              </div>
              <div class="font-weight-bold">
               <b>₹60,00,000</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="my-2">
          <b>Purchase Order</b>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
               <b>450</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                INPROCESS
              </div>
              <div class="font-weight-bold">
               <b>150</b>
              </div>
            </div>
          </div>
       
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                DISPATCHED
              </div>
              <div class="font-weight-bold">
               <b>200</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-danger">
              <div>
                CANCELLED
              </div>
              <div class="font-weight-bold">
               <b>100</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                PO LINES
              </div>
              <div class="font-weight-bold">
               <b>3000 </b>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                PO AMOUNT
              </div>
              <div class="font-weight-bold">
               <b>₹1,05,00,000</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      </div>
      <div class="row">
        <div class="col-md-6 mb-2">
          <h5 class="heading-h5">IU PERFORMANCE</h5>
        </div>
        <div class="col-md-6">
          
        </div>
        
        <div class="col-md-6 mb-2">
          <div class="my-2">
            <b>RFQS TO QUOTE</b>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <div class="card p-4 text-white text-center m-auto bg-blue">
                <div>
                  AVERAGE TAT(DAYS)
                </div>
                <div class="font-weight-bold">
                 <b>0</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="my-2">
            <b>POs TO DISPATCHED</b>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <div class="card p-4 text-white text-center m-auto bg-blue">
                <div>
                  AVERAGE DELIVERY(DAYS)
                </div>
                <div class="font-weight-bold">
                 <b>8</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        </div>
   </div>
   <div class="col-12" *ngIf="currentTab == 'LASTYEAR'">
    <div class="row">
      <div class="col-md-6 mb-2">
        <div class="my-2">
          <b>Enquiries</b>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
               <b>5000</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                INPROCESS
              </div>
              <div class="font-weight-bold">
               <b>3000</b>
              </div>
            </div>
          </div>
       
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                QUOTED
              </div>
              <div class="font-weight-bold">
               <b> 2500</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-danger">
              <div>
                REGRET
              </div>
              <div class="font-weight-bold">
               <b>500</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                ENQUIRY LINES
              </div>
              <div class="font-weight-bold">
               <b>30000</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                QUOTES LINE
              </div>
              <div class="font-weight-bold">
               <b>15000</b>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                QUOTED LINES AMOUNT
              </div>
              <div class="font-weight-bold">
               <b>₹5,00,00,000</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="my-2">
          <b>Purchase Order</b>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
               <b>4000</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-info">
              <div>
                INPROCESS
              </div>
              <div class="font-weight-bold">
               <b>1500</b>
              </div>
            </div>
          </div>
       
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                DISPATCHED
              </div>
              <div class="font-weight-bold">
               <b>2000</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-danger">
              <div>
                CANCELLED
              </div>
              <div class="font-weight-bold">
               <b>500</b>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-blue">
              <div>
                PO LINES
              </div>
              <div class="font-weight-bold">
               <b>25000 </b>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="card p-4 text-white text-center m-auto bg-success">
              <div>
                PO AMOUNT
              </div>
              <div class="font-weight-bold">
               <b>₹9,00,00,000</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      </div>
      <div class="row">
        <div class="col-md-6 mb-2">
          <h5 class="heading-h5">IU PERFORMANCE</h5>
        </div>
        <div class="col-md-6">
    
        </div>
        
        <div class="col-md-6 mb-2">
          <div class="my-2">
            <b>RFQS TO QUOTE</b>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <div class="card p-4 text-white text-center m-auto bg-blue">
                <div>
                  AVERAGE TAT(DAYS)
                </div>
                <div class="font-weight-bold">
                 <b> 0</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="my-2">
            <b>POs TO DISPATCHED</b>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-3">
              <div class="card p-4 text-white text-center m-auto bg-blue">
                <div>
                  AVERAGE DELIVERY(DAYS)
                </div>
                <div class="font-weight-bold">
                 <b> 8</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        </div>
   </div> -->

<!-- <div class="col-12 my-5">
<div>
  <div class="card bg-white p-3">
      <div class="col-12">
        <div class="div-class">
         Your Procurement State
        </div>
        <hr class="hr my-2">
        <div class="row mt-3">
          <div class="col-md-4">
            <div class="div-class row">
              <div class="col-2 mt-3">
              <span class="icon-yellow-backgroud-dashboard">
                <img src="assets/icons/computer.svg" class="svg-icon-class">
              </span>
              </div>
              <div class="col-9">
             Requisitions Made this Month
            <div class="fs-4 font-weight-bold">
                <b>23,208</b>
            </div>
            <div>
              <span class="span-class text-red-500  me-2">14%</span>
              <span class="span-class medium-font">Less than previous month</span>
            </div>
          </div>
        </div>
          </div>
          <div class="col-md-4">
            <div class="div-class row">
              <div class="col-2 mt-3">
                <span class="icon-red-backgroud-dashboard">
                  <img src="assets/icons/cap.svg" class="svg-icon-class">
                </span>
              </div>
              <div class="col-9">
             Procurement Completed this Month
         
            <div class="fs-4 font-weight-bold">
               <b>51,854</b>
            </div>
            <div>
              <span class="medium-font me-2">Increased from previous month</span>
              <span class="span-class text-blue">38%</span>
            </div>
            </div>
          </div>
          </div>
          <div class="col-md-4">
            <div class="div-class row">
              <div class="col-2 mt-3">
                <span class="icon-green-backgroud-dashboard">
                  <img src="assets/icons/building.svg" class="svg-icon-class">
                </span> 
              </div>
              <div class="col-9">
            Procurement Amount in Month
         
            <div class="fs-4 font-weight-bold text-green-500 ">
               <b>₹185,00,000</b>
            </div>
            <div>
              <span class="medium-font me-2">Increased from previous month by</span>
              <span class="span-class text-orange-500 ">24%</span>
            </div>
            </div>
          </div>
          </div>
        </div>
        <hr class="hr my-2">
       
      </div>

  </div>
  <div class="col-12 mt-3">
    <div class="row">
      <div class="col-md-6 mb-2">
        <div class="card bg-white p-3">
          <div class="div-class">
          Ongoing Events
          </div>
          <hr class="hr my-2">
          <div class="mt-2">
            <div class="font-weight-normal">
             Floated RFQs (this week)
            </div>
            <div class="font-weight-bold fs-5">
              <b>
                350
              </b>
            </div>
          </div>
          <div class="mt-2">
            <div class="font-weight-normal">
              PQ raised (this week)
            </div>
            <div class="font-weight-bold fs-5">
              <b>
                185
              </b>
            </div>
          </div>
          <hr class="hr my-2">
          <div class="row">
            <div class="col-6">
              <b>Overall Budget Available (this quarter) </b>
              <div class="medium-font">
                Across all Cost centres
              </div>
            </div>
            <div class="col-6 text-end fs-4 text-green-500  font-weight-bold">
              <b>₹850,00,000</b>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2">
        <div class="card bg-white p-3">
          <div class="div-class">
         Procurement  Timeline 
          </div>
          <hr class="hr my-2">
          <div class="mt-2">
            <ul class="timeline overflow-card-dashboard" >
              <li class="yellow-color c-default">
                <h6 class="heading-h6 mb-0 text-info text-capitalize" >RFQ for Purchase of Fasteners With Non ARC Vendors</h6>
                <div>
                  <span class="span-class">Event Open for all Suppliers</span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 POsition-relative" style="margin-bottom: 2px;">
                    19 May,2024 10AM to 20 May,2024 05PM</small>
                </div>
              </li>
              <li class="yellow-color c-default">
                <h6 class="heading-h6 mb-0 text-info text-capitalize" >Direct Purchase Vendor Prequalification For 2024-25 <span class="badge bg-success ms-2 p-1">Running</span></h6>
                <div>
                  <span class="span-class">Event Open for all Suppliers</span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 POsition-relative" style="margin-bottom: 2px;">
                    19 May,2024 10AM to 22 May,2024 05PM</small>
                </div>
              </li>

              <!-- <li class="yellow-color c-default">
                <h6 class="heading-h6 mb-0 text-info text-capitalize" >Registration  <span class="badge bg-success ms-2 p-1">Approved</span></h6>
                <div>
                  <span class="span-class">Amtag</span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 POsition-relative" style="margin-bottom: 2px;">
                    21 Feb, 2024 10AM</small>
                </div>
              </li>
              <li class="yellow-color c-default">
                <h6 class="heading-h6 mb-0 text-info text-capitalize">bid  <span class="badge bg-danger">Rejected</span></h6>
                <div>
                  <span class="span-class">Amtag</span>
                </div>
                <div>
                  <small>
                    <img src="assets/icons/time_light.svg" class="svg-icon-class-Xsmall me-1 POsition-relative" style="margin-bottom: 2px;">
                    21 Feb, 2024 10AM</small>
                </div>
              </li> 
             
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="row">
      <div class="col-md-3 mb-2">
        <div class="card bg-white p-4">
        <div>
          <span class="fs-5 font-weight-bold">21</span>
        </div>
        <div class="medium-font">
         PR Received today
        </div>
        </div>
      </div>
      <div class="col-md-3 mb-2">
        <div class="card bg-white p-4">
        <div>
          <span class="fs-5 font-weight-bold">45</span>
        </div>
        <div class="medium-font">
         RFQ floated today
        </div>
        </div>
      </div>
      <div class="col-md-3 mb-2">
        <div class="card bg-white p-4">
        <div>
          <span class="fs-5 font-weight-bold">18</span>
        </div>
        <div class="medium-font">
         PO generated today
        </div>
        </div>
      </div>
      <div class="col-md-3 mb-2">
        <div class="card bg-white p-4">
        <div>
          <span class="fs-5 font-weight-bold">7</span>
        </div>
        <div class="medium-font">
         Invoice received today
        </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</div> -->