import { Timestamp } from "@angular/fire/firestore";

export class UserMaxBidsDto {
  userId?: string;
  userName?: string;
  auctionId?: string;
  auctionHouseId?: string;
  lotId?: string;
  bucketSize?: number;
  emailId?: string;
  updateTimeUtc?: string;
  updateTimeUtcMillis?: number
  bucketPercentage?: number;
}
