<div>
  <div class="grid grid-cols-2 content-between items-center my-6">
    <div class="">
      <span class=""> <img src="/assets/icons/drawer-head-icon-1.svg" class="tr-xl-icon inline-flex"></span>

      <div class="inline-flex tr-margin-left-10px inline-flex ml-2  text-gray-300 font-semibold text-xl leading-4">
        Extend Profile
      </div>
    </div>
    <div class=" text-end">
      <button class="bg-gray-50 text-gray-700 text-sm px-4 py-2 rounded-md mr-2" (click)="closeDrawer()">Cancel</button>
      <button class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md" (click)="saveExtendProfile()">Save as draft</button>
    </div>
  </div>

  <div>
    <div class="tr-form-space-and-background-drawer">
      <form class="needs-validation w-100" novalidate novalidate [formGroup]="formGroup"
        (keyup.enter)="saveExtendProfile()">
        <div class=" mb-4">
          <label for="legalType" class="text-gray-700  text-sm font-semibold leading-4">Company</label>
          <select name="Select Company" class="form-control form-select" formControlName="companyCode"
            id="designation"
            [ngClass]="{'is-invalid': fc['companyCode'].invalid && (fc['companyCode'].dirty || fc['companyCode'].touched)}">
            <option value="" disabled>Select . . .</option>
            <option [value]="company?.companyCode"
              *ngFor="let company of companiesList">
              {{company?.companyName}}
            </option>
          </select>
          <div *ngIf="fc['companyCode'].invalid && (fc['companyCode'].dirty || fc['companyCode'].touched)">
            <p class="tr-validations" *ngIf="fc['companyCode'].errors?.['required']">
              <span class="mr-2">
                <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
              </span>
              Required
            </p>
          </div>
        </div>


        <div class=" mb-4">
          <label for="legalType" class="text-gray-700  text-sm font-semibold leading-4">Plant</label>
          <select name="Select Company" class="form-control form-select" formControlName="plantCode"
            id="plantCode"
            [ngClass]="{'is-invalid': fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)}">
            <option value="" disabled>Select . . .</option>
            <option [value]="plant?.plantCode"
              *ngFor="let plant of plantUiDtos">
              {{plant?.plantName}}
            </option>
          </select>
          <div *ngIf="fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)">
            <p class="tr-validations" *ngIf="fc['plantCode'].errors?.['required']">
              <span class="mr-2">
                <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
              </span>
              Required
            </p>
          </div>
        </div>

        <div class=" mb-4">
          <label for="legalType" class="text-gray-700  text-sm font-semibold leading-4">Designation</label>
          <select name="Select Designation" class="form-control form-select" formControlName="designation"
            id="designation"
            [ngClass]="{'is-invalid': fc['designation'].invalid && (fc['designation'].dirty || fc['designation'].touched)}">
            <option value="" disabled>Select . . .</option>
            <option [value]="designation?.code"
              *ngFor="let designation of applicationStartupDto?.cacheDesignationsDtos">
              {{designation?.name}}
            </option>
          </select>
          <div *ngIf="fc['designation'].invalid && (fc['designation'].dirty || fc['designation'].touched)">
            <p class="tr-validations" *ngIf="fc['designation'].errors?.['required']">
              <span class="mr-2">
                <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
              </span>
              Required
            </p>
          </div>
        </div>

      </form>
      <div class="mb-4">
        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" message="Extend Privilege Saved"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="(this._showErrorMsg$ | async)">
          </app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isDataLoading">
          <div class="e-spinner position-absolute"></div>
        </div>

        <div class="col-12 text-end mb-5" *ngIf="!isDataLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md" (click)="saveExtendProfile()">Save</button>
        </div>

      </div>
      <div class="grid grid-cols-2 w-full gap-4">
        <div class="">
          <div class="grid grid-cols-3 w-full gap-2">
            <div class="text-gray-700  text-sm font-semibold leading-4">
              Name
            </div>
            <div class="col-span-2 col-span-2 text-black font-normal leading-normal text-sm">
              {{userEntityInfoDto?.firstName}} {{userEntityInfoDto?.lastName}}
            </div>
          </div>
        </div>
        <div class=" md:text-end">
          <div class="grid grid-cols-3 w-full gap-2">
            <div class=" text-gray-700  text-sm font-semibold leading-4">
              Email
            </div>
            <div class="col-span-2 col-span-2 text-black font-normal leading-normal text-sm">
              {{userEntityInfoDto?.primaryEmailId}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-bordered mb-0 medium-font">
            <thead class="table-dark">
              <tr class="text-center">
                <th class="text-center"><span>#</span></th>
                <th class=""><span>Company Code</span></th>
                <th class=""><span>Plant Code</span></th>
                <th class=""><span>Designation</span></th>
                <th class=""><span>Action</span></th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr *ngIf="isLoading" class="align-middle">
                <td colspan="9" class="text-center">
                  <div class="e-spinner"></div>
                </td>
              </tr>
              <tr>
                <td class="text-center" colspan="9"
                  *ngIf="isLoading && (!userExtendPrivilegeUiDtos || userExtendPrivilegeUiDtos.length == 0)">
                  <h5 class="heading-new"> No Data Available</h5>
                </td>
              </tr>
              <tr class="align-middle text-center" *ngFor="let userExtendPrivilegeUiDto of userExtendPrivilegeUiDtos; index as i">
                <td class=" text-center">
                  {{i+1}}
                </td>
                <td class="">
                  {{userExtendPrivilegeUiDto.companyCode}}
                </td>
                <td class="">
                  {{userExtendPrivilegeUiDto.plantCode}}
                </td>
                <td class=" text-lowercase">
                  {{formattedDesignation(userExtendPrivilegeUiDto.designation!)?.name}}
                </td>
               
                <td class="">
                  <img src="/assets/icons/tr-delete-black.svg" class="tr-lg-icon mr-2 c-pointer" (click)="openConfirmStatusModal(confirmationModalTemplate, userExtendPrivilegeUiDto)">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>

</div>

<ng-template #confirmationModalTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Confirmation</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div >
          <p>Are You sure you want to delete.</p>
          <p>{{selectedUserExtendPrivilegeUiDto?.companyCode}} - {{formattedDesignation(selectedUserExtendPrivilegeUiDto?.designation!)!.name}} - {{selectedUserExtendPrivilegeUiDto?.emailId}}</p>
        </div>

        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessModelToast$ | async" message="Extend Privilege Deleted successfully"></app-message-toast>
          <app-message-toast *ngIf="_showErrorModelToast$ | async" [isError]="true"
            [message]="(this._showErrorModelMsg$ | async)">
          </app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLodingModel">
          <div class="e-spinner" *ngIf="isLodingModel"></div>
        </div>

        <div class="my-2 text-end" *ngIf="!(_showSuccessModelToast$ | async) && !isLodingModel">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="deletePrivilege()">OK</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>