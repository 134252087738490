import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { BidIncrementalWrapperDto } from 'src/app/shared/models/user/BidIncrementalWrapperDto';
import { LotFeatures } from 'src/app/shared/models/user/LotFeatures';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { AuctionValidationService } from 'src/app/shared/services/auction-validation.service';
import { BulkLotsUploadComponent } from '../../bulk-lots-upload/bulk-lots-upload.component';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserService } from 'src/app/shared/services/user.service';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { RankPriceConfigEnum } from 'src/app/shared/enums/RankPriceConfigEnum';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { Store } from '@ngrx/store';
import { selectUserUiDto } from 'src/app/shared/state-management/session.features';

@Component({
  selector: 'app-bulk-lots-verification',
  templateUrl: './bulk-lots-verification.component.html',
  styleUrls: ['./bulk-lots-verification.component.sass']
})
export class BulkLotsVerificationComponent implements OnInit {
  auctionEntityDto?: AuctionEntityDto
  allLotsList: Array<AuctionLotEntityDto> = []

  auctionCategoriesSubscription$?: Subscription;
  masterCategories: any[] = [];

  isDiscardLoading: boolean = false;
  isEnableOrDisableLoading: boolean = false;
  lotActiveStatus: boolean = false;
  isShowWarnAndBlock: boolean = false;

  selectedLot$ = new BehaviorSubject<AuctionLotEntityDto | null>(null)
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _filterLotList$ = new BehaviorSubject<AuctionLotEntityDto[]>([]);

  selectLotForDiscard?: AuctionLotEntityDto
  selectedLotForEnableDisable?: AuctionLotEntityDto;

  discardModalRef?: NgbModalRef
  enableDisabledModalRef?: NgbModalRef

  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];

  constructor(
    private ngbModal: NgbModal,
    private activeModal: NgbActiveModal,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private lotService: AdminLotsDataHolderService,
    private validationService: AuctionValidationService,
    private adminDashboardService: AdminDashboardService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe(data => {
      if (data) {
        this.auctionEntityDto = data
        this.populateShowWarnAndBlock();
      }
    })

    this.auctionCategoriesSubscription$ = this.adminDashboardService.getMasterCategories$.subscribe((dataList) => {
      if (dataList) {
        this.masterCategories = dataList;
      } else {
        this.masterCategories = [];
      }
    })

    this.lotService.getAllLots$.subscribe(data => {
      if (data) {
        this.allLotsList = data
        this.allLotsList.sort((a, b) => a.lotSequence! - b.lotSequence!);
        this.filterLotList("");

        if (this.selectedLot$.value) {
          let selectedLot = this.allLotsList.find(item => item.lotId == this.selectedLot$.value?.lotId)
          if (selectedLot) {
            this.selectedLot$.next(selectedLot)
          }
        }
      }
    })
  }

  openEditLotDetailsModal(lot: AuctionLotEntityDto, event?: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    if (event) {
      event.stopPropagation();
    }

    this.selectedLot$.next(lot)

    let modalRef = this.ngbModal.open(BulkLotsUploadComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    })

    modalRef.componentInstance.selectedAuction$ = this.adminSourcingEventsDataHolderService.selectedAuction$
    modalRef.componentInstance.selectedLot$ = this.selectedLot$

    modalRef.result.then(() => {
      this.selectedLot$.next(null);
    })
  }
  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getDisplayDateAdmin(date, time);
  }

  getFormattedPrice(price?: string | number) {
    return ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale, price);
  }

  populateShowWarnAndBlock() {
    let rankConfig = this.auctionEntityDto?.rankCurrentPriceConfig;
    if ((rankConfig == RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION) || (rankConfig == RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION)) {
      this.isShowWarnAndBlock = true
    } else {
      this.isShowWarnAndBlock = false;
    }
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }


  getLotValidation(lot: AuctionLotEntityDto) {
    if (this.auctionEntityDto && lot) {
      return this.validationService.haveAnyLotErrors(this.auctionEntityDto!, lot);
    } else {
      return true
    }
  }

  getHashtagsValidation(hashtags?: string[]) {
    return hashtags && hashtags.length > 0 ? hashtags.length : 0;
  }

  getHighlightsValidation(highlights?: string[]) {
    return highlights && highlights.length > 0 ? highlights.length : 0;
  }

  getLotFeaturesValidation(lotFeatures?: LotFeatures[]) {
    return lotFeatures && lotFeatures.length > 0 ? lotFeatures.length : 0;
  }

  getLotImageCount(lotImages?: FileInfoDto[]) {
    return lotImages && lotImages.filter(item => !this.isValidUrl(item?.fileId!)).length > 0 ? lotImages.length : 0;
  }

  isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString));
    }
    catch (e) {
      return false;
    }
  }


  getLotVideoCount(lotImages?: FileInfoDto[]) {
    return lotImages && lotImages.filter(item => this.isValidUrl(item?.fileId!)).length > 0 ? lotImages.length : 0;
  }

  getBidIncrementValidation(bidIncrementalWrapper?: BidIncrementalWrapperDto) {
    if (bidIncrementalWrapper) {
      if (bidIncrementalWrapper.incrementalBidType == 'SINGLE') {
        return bidIncrementalWrapper.constantIncrement ? 'SINGLE' : '';
      } else {
        return bidIncrementalWrapper.bidIncrementalDtoList
          && bidIncrementalWrapper.bidIncrementalDtoList.length > 0 ? 'MULTIPLE' : '';
      }
    }
    return 0
  }

  getSingleDate(val?: string) {
    if (val) {
      return this.getTruncate(val);
    }
    return "";
  }


  getCategoryName(id?: string) {
    if (id) {
      let categoryData = this.masterCategories.find(item => item.id == id);
      if (categoryData) {
        return categoryData.category;
      }
    }
    return "";
  }

  getSubCategoryName(id?: string) {
    if (id) {
      let categoryData = this.masterCategories.find(item => item.id == id);
      if (categoryData) {
        return categoryData.subcategory;
      }
    }
    return "";
  }

  getSubCategoryLevelOneName(id?: string) {
    if (id) {
      let categoryData = this.masterCategories.find(item => item.id == id);
      if (categoryData) {
        return categoryData.subcategoryLevelOne;
      }
    }
    return "";
  }

  getTruncate(formattedInput?: string) {
    if (formattedInput) {
      return ApplicationUtils.truncateString(formattedInput, 20);
    }
    return "";
  }

  async addNewLotRow() {
    if (!this.isAllowToEdit()) {
      return;
    }

    const userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));

    let newLotEntityDto = new AuctionLotEntityDto();
    newLotEntityDto.lotSequence = this.getLotSequence();
    newLotEntityDto.auctionHouseId = userUiDto?.orgCode;
    newLotEntityDto.auctionId = this.auctionEntityDto!.auctionId;
    newLotEntityDto.timeZone = this.auctionEntityDto!.timeZone;
    newLotEntityDto.domainName = this.auctionEntityDto?.domainName;
    newLotEntityDto.lotType = this.auctionEntityDto?.auctionType;

    this.openEditLotDetailsModal(newLotEntityDto);

    this.allLotsList.sort((a, b) => a.lotSequence! - b.lotSequence!);
  }

  copyLotRow(item: AuctionLotEntityDto, event: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    event.stopPropagation();

    let newLotEntityDto = new AuctionLotEntityDto();
    newLotEntityDto = ApplicationUtils.clone(item);
    newLotEntityDto.lotId = undefined;
    newLotEntityDto.lotImages = [];
    newLotEntityDto.lotSequence = this.getLotSequence();

    this.openEditLotDetailsModal(newLotEntityDto);

    this.allLotsList.sort((a, b) => a.lotSequence! - b.lotSequence!);
  }

  openAuctionLotEnableAndDisableModel(item: AuctionLotEntityDto, content: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.selectedLotForEnableDisable = item;
    this.lotActiveStatus = item?.active!;
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.enableDisabledModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  discardLotRow() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._errorMsg$.next('');
    this._showErrorToast$.next(false);
    this.isDiscardLoading = true;

    this.adminDashboardService.auctionLotDiscard(this.selectLotForDiscard?.auctionId!, this.selectLotForDiscard?.lotId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isDiscardLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.discardModalRef);
          }, 2000)

        } else {
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isDiscardLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.isDiscardLoading = false;
        this._errorMsg$.next("Error while discarding Lot. Try again.");
        this._showErrorToast$.next(false);
      }
    })
  }

  openAuctionLotDiscardModel(content: any, event: any, item: AuctionLotEntityDto) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._errorMsg$.next('');
    this._showErrorToast$.next(false);
    this.isDiscardLoading = false;

    event.stopPropagation();

    this.selectLotForDiscard = item;

    this.discardModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  markAuctionLotActiveOrInActive(action: string) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next("")
    let errorDtoList: AuctionValidationErrorCodeDto[] = []

    this.validationService.doAuctionLotValidation(errorDtoList, this.auctionEntityDto!, this.selectedLotForEnableDisable!);

    if (errorDtoList && errorDtoList.length > 0) {
      this.auctionValidationErrorCodeDtoList = errorDtoList;
      this.closeModal(this.enableDisabledModalRef);
      this.openErrorModel();
      return;
    }

    let lotEntityDto = ApplicationUtils.clone(this.selectedLotForEnableDisable);
    lotEntityDto!.active = action == "ENABLE";

    this.isEnableOrDisableLoading = true;

    this.adminDashboardService.markAuctionLotActiveOrInActive(lotEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionValidationErrorCodeDtoList = apiResponseDto.data as Array<AuctionValidationErrorCodeDto>;
          if (!auctionValidationErrorCodeDtoList || auctionValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isEnableOrDisableLoading = false;
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.lotActiveStatus = action == "ENABLE";
              this.closeModal(this.enableDisabledModalRef);
            }, 2000)
          } else {
            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
            this.isEnableOrDisableLoading = false;
          }
        } else {
          if (apiResponseDto.code == 'AUCTION-EXCEPTION-127') {
            this._errorMsg$.next("Error while enabling Lot. Enable Auction and try again.");
          } else {
            this._errorMsg$.next(apiResponseDto?.message!);
          }
          this._showErrorToast$.next(true);
          this.isEnableOrDisableLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
        this.isEnableOrDisableLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next(`Error while ${action == 'ENABLE' ? 'enabling' : 'disabling'} Lot. Try again.`);

      }
    })
  }



  filterLotList(searchText: string) {

    if (searchText) {
      let tempAuctionLotEntityDtos = [... this.allLotsList ?? []];
      let filterList = tempAuctionLotEntityDtos.filter(item => item.lotSequence == Number(searchText.trim) || item.title?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || item.description?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || item.preferenceCategory?.categoryName == searchText.trim()
        || item.preferenceCategory?.categoryLevelOneName == searchText.trim()
        || item.preferenceCategory?.categoryLevelTwoName == searchText.trim()
        || item.preferenceCategory?.categoryLevelThreeName == searchText.trim()
        || item.preferenceCategory?.categoryLevelFourName == searchText.trim()
        || item.hashtags && item.hashtags?.filter(item => item.trim().toLowerCase().includes(searchText.trim().toLowerCase())).length > 0
        || item.lotFeatures && item.lotFeatures?.filter(item => item.featureValue?.trim().toLowerCase().includes(searchText.trim().toLowerCase()) || item.featureKey?.trim().toLowerCase().includes(searchText.trim().toLowerCase())).length > 0
        || item.startingBid?.toString().includes(searchText) || item.reservePrice?.toString().includes(searchText)

      );

      this._filterLotList$.next(filterList);

    } else {
      this._filterLotList$.next(this.allLotsList);
    }

  }

  getSelectedCategoryData(searchText: string, id: string,) {
    let tempList = [... this.masterCategories ?? []];

    let filterData = tempList.filter(item => (item.category.toString().toLowerCase().includes(searchText.trim().toLowerCase())
      || item.category.toString().toLowerCase().includes(searchText.trim().toLowerCase())) && (item.id == id));

    if (filterData && filterData.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getSelectedSubcategoryData(searchText: string, id: string,) {
    let tempList = [... this.masterCategories ?? []];

    let filterData = tempList.filter(item => (item.subcategory.toString().toLowerCase().includes(searchText.trim().toLowerCase())
      || item.subcategory.toString().toLowerCase().includes(searchText.trim().toLowerCase())) && (item.id == id));

    if (filterData && filterData.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }


  getLotSequence() {
    let toReturn = 1;
    let allLots = this.lotService._allLots$.value;
    if (allLots && allLots.length > 0) {
      allLots.sort((a, b) => b.lotSequence! - a.lotSequence!);
      toReturn = allLots[0].lotSequence! + 1
    }
    return toReturn;
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}


