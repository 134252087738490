import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct, NgbDropdown, NgbModal, NgbModalRef, NgbPopover, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AuctionLotSaveWrapperDto } from 'src/app/shared/models/user/AuctionLotSaveWrapperDto';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { LotFeatures } from 'src/app/shared/models/user/LotFeatures';
import { Pattern } from 'src/app/shared/util/Patterns';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import heic2any from "src/assets/plugins/heic2any";
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { DatePipe } from '@angular/common';
import { FileService } from 'src/app/shared/services/file.service';
import { BidIncrementalDto } from 'src/app/shared/models/user/BidIncrementalDto';
import { StringMinValidator } from 'src/app/shared/validators/string-min.directive';
import { BidIncrementalWrapperDto } from 'src/app/shared/models/user/BidIncrementalWrapperDto';
import { AngularEditorConfig } from 'src/assets/plugins/angular-editor/public-api';
import { AuctionValidationService } from 'src/app/shared/services/auction-validation.service';
import { CategoryDto } from 'src/app/shared/models/CategoryDto';
import { RankPriceConfigEnum } from 'src/app/shared/enums/RankPriceConfigEnum';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { TreeSelectionMode } from 'src/app/shared/enums/TreeSelectionMode';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';

@Component({
  selector: 'app-bulk-lots-upload',
  templateUrl: './bulk-lots-upload.component.html',
  styleUrls: ['./bulk-lots-upload.component.sass']
})
export class BulkLotsUploadComponent implements OnInit, AfterContentInit,  OnDestroy {
  @Input() selectedAuction$ = new Observable<AuctionEntityDto | null>()
  @Input() selectedLot$ = new Observable<AuctionLotEntityDto | null>()

  auctionEntityDto?: AuctionEntityDto
  lotEntityDto?: AuctionLotEntityDto
  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];

  imageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/heif'];
  videoTypes = ['mp4', 'webm', 'ogg'];
  fileUploadError: boolean = false;
  isLoading: boolean = false
  isUploading: boolean = false

  multipleBidIncrementDecrementAllowed?: boolean = false;

  TreeSelectionMode: typeof TreeSelectionMode = TreeSelectionMode;
  selectedPreferenceCategory?: PreferenceCategoryDto;

  separatorKeyCodes = [32, 13, 188, 51]
  inputTagPlaceholder = "Add tag and click enter"
  currentMultipleFiles: Array<File> = [];
  currentMultipleFilesPath: Array<string | ArrayBuffer> = [];
  currentMultipleFilesType: Array<string> = [];
  bidIncrementalList?: Array<BidIncrementalDto> = [];
  isImageSelected: boolean = false
  lastImageModalFileType: string = 'IMAGE'
  selectedCategoryName?: string
  selectedSubCategoryName?: string
  activeImage?: number;
  incrementBidValue?: string;

  lotFeatureModalRef?: NgbModalRef
  imagesModalRef?: NgbModalRef
  bidDetailsModalRef?: NgbModalRef
  reverseAuction?: boolean;
  isShowWranAndBlock: boolean = false;

  formGroup: FormGroup
  fgLotFeature: FormGroup
  ctrlImageModalFileType: FormControl = new FormControl('IMAGE');
  ctrlVideoUrl: FormControl = new FormControl('', [Validators.pattern(Pattern.url)]);
  radioBidIncrement: FormControl = new FormControl(true, Validators.required);
  singleBidIncrement: FormControl = new FormControl('', Validators.required);
  infinityBidIncrement: FormControl = new FormControl('', Validators.required);

  _errorMsg$ = new BehaviorSubject<string>("")
  _showErrorToast$ = new BehaviorSubject<boolean>(false)
  _showSuccessToast$ = new BehaviorSubject<boolean>(false)
  _showImagesErrorToast$ = new BehaviorSubject<boolean>(false)

  endTime?: NgbTimeStruct;
  ngbPopover?: NgbPopover;
  isSubmitted?: boolean = false;
  isShowWarnAndBlock: boolean = false; 



  @ViewChild('lotImageRef') lotImageRef?: HTMLInputElement;
  @ViewChild('lotImagesListRef') lotImagesListRef?: ElementRef;

  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  }


  constructor(
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private adminService: AdminDashboardService,
    private lotService: AdminLotsDataHolderService,
    private fileService: FileService,
    private validationService: AuctionValidationService,
  ) {
    this.formGroup = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      endDate: ['', Validators.required],
      endTime: ['', Validators.required],
      category: ['', Validators.required],
      subCategory: ['', Validators.required],
      subcategoryLevelOne: ['', Validators.required],
      hashtags: [[]],
      highlights: [''],
      pickup: [''],
      inspection: [''],
      bidIncrementalDtoList: this.fb.array([]),
      startingBid: [''],
      publishReservePrice: [false],
      reservePrice: [''],
      estStartingPrice: [''],
      estEndingPrice: [''],
      warnBid: [false],
      warnBidValue: [''],
      blockBid: [false],
      blockBidValue: [''],
    })

    this.fgLotFeature = this.fb.group({
      featureKey: ['', [Validators.required]],
      featureValue: ['', [Validators.required]]
    })
  }


  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(() => {
      let value = this.formGroup.getRawValue();

      if (value != null) {

        if (this.lotEntityDto?.lotType == 'Reserve') {
          if (value?.startingBid != null && value?.reservePrice != null) {
            let startingBid = ApplicationUtils.resetPriceFormat(value?.startingBid);
            let reservePrice = ApplicationUtils.resetPriceFormat(value?.reservePrice);

            if (Number(startingBid) >= Number(reservePrice) && Number(startingBid) > 0 && Number(startingBid) > 0) {
              this._showErrorToast$.next(true);
              this._errorMsg$.next("Reserve Price should be greater than Starting Bid value!");
              return;
            } else {
              this._showErrorToast$.next(false);
            }
          }
        }
      }
    });

    this.ctrlImageModalFileType.valueChanges.subscribe(val => {
      if (val != this.lastImageModalFileType) {
        this.lastImageModalFileType = val;
        this.clearMultiImages();
      }
    })

    this.radioBidIncrement.valueChanges.subscribe((val) => {
      if (val && val == true) {

        this.formGroup.controls['bidIncrementalDtoList'].reset();
        this.incrementalConfig.clear();
        this.infinityBidIncrement.reset();
      } else {

        this.singleBidIncrement.reset();
      }

    })
  }

  populateShowWranAndBlock(){
    let rankConfig = this.auctionEntityDto?.rankCurrentPriceConfig;
    if(rankConfig == RankPriceConfigEnum.SHOW_RANK_AND_NO_CURRENT_PRICE_WITH_TRANSITION){
      this.isShowWranAndBlock = false
    }else{
      this.isShowWranAndBlock = true;
    }
  }

  ngAfterContentInit(): void {
    this.selectedAuction$.subscribe(data => {
      if (data) {
        this.auctionEntityDto = data
        this.reverseAuction = this.auctionEntityDto.auctionBiddingMethod == ApplicationConstants.REVERSE_AUCTION ? true : false;
        this.updateCurrencyValidator();
        this.populateShowWarnAndBlock();
        this.isMultipleBidIncrementDecrementAllowed();

      }
    })

    this.selectedLot$.subscribe(data => {
      if (data) {
        this.lotEntityDto = ApplicationUtils.clone(data);
        this.populateInitialData();
      }
    })

    this.formGroup.controls['endDate'].valueChanges.subscribe((val) => {
      if (val) {
        this.checkEndTimeValidation();
      }
    })
  }

  isReverseAuction(){
    let toReturn = false;
    if(this.auctionEntityDto?.auctionBiddingMethod == ApplicationConstants.REVERSE_AUCTION){
      toReturn = true;
    }
    return toReturn;
  }

  populateShowWarnAndBlock(){
    let rankConfig = this.auctionEntityDto?.rankCurrentPriceConfig;
    if((rankConfig == RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION) || (rankConfig == RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION) ){
      this.isShowWarnAndBlock = true
    }else{
      this.isShowWarnAndBlock = false;
    }
  }

  enableAndDisableWarnBid(){
    let warnBid = this.formGroup.controls['warnBid'].value;
    if(warnBid){
      this.formGroup.controls['warnBidValue'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    }else{
      this.formGroup.controls['warnBidValue'].clearValidators();
      this.formGroup.controls['warnBidValue'].reset();
    }
    this.formGroup.controls['warnBidValue'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  enableAndDisableBlockBid(){
    let blockBid = this.formGroup.controls['blockBid'].value;
    if(blockBid){
      this.formGroup.controls['blockBidValue'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    }else{
      this.formGroup.controls['blockBidValue'].clearValidators();
      this.formGroup.controls['blockBidValue'].reset();
    }
    this.formGroup.controls['blockBidValue'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  updateCurrencyValidator() {
    this.infinityBidIncrement.setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    this.singleBidIncrement.setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    this.fc['startingBid'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);

    // Bid Increment
    // this.incrementalConfig.controls.forEach((item) => {
    //   item.get('upToAmount')?.setValidators([Validators.required, Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    //   item.get('incrementValue')?.setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    //   item.updateValueAndValidity();
    // })

    this.formGroup.updateValueAndValidity();
    this.infinityBidIncrement.updateValueAndValidity();
    this.singleBidIncrement.updateValueAndValidity();
  }

  get fc(): any { return this.formGroup.controls; }
  get fcLotFeatures(): any { return this.fgLotFeature.controls; }

  getImageUrl(item: any) {
    if (this.isValidUrl(item.fileId)) {
      return ApplicationUtils.getVideoThumbnailFromUrl(item.fileId);
    }
    return `/downloadLandingBlob?fileId=${item?.fileId}`;
  }

  isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString));
    }
    catch (e) {
      return false;
    }
  }

  updateReservePriceValidator() {
    if (this.lotEntityDto?.lotType == 'Reserve') {
      this.formGroup.controls['reservePrice'].setValidators([Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
      this.formGroup.controls['publishReservePrice'].setValidators(Validators.required);
    } else {
      this.formGroup.controls['reservePrice'].clearValidators();
      this.formGroup.controls['publishReservePrice'].clearValidators();
    }

    this.formGroup.controls['reservePrice'].updateValueAndValidity();
    this.formGroup.controls['publishReservePrice'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  formatReserveBid() {
    let reservePrice = this.formGroup.controls['reservePrice'].value;
    let formattedReservePrice = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, reservePrice);
    this.fc['reservePrice'].patchValue(formattedReservePrice);
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close()
    } else {
      this.activeModal.close()
    }
  }

  clearMultiImages() {
    this.currentMultipleFiles = [];
    this.currentMultipleFilesPath = [];
    this.ctrlImageModalFileType.patchValue('IMAGE');
    this.ctrlVideoUrl.reset();
  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }

  openAddLotFeature(content: any) {
    this.fgLotFeature.reset();
    this.lotFeatureModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openAddImageModal(content: any) {
    this._showImagesErrorToast$.next(false);
    this.clearMultiImages()

    this.imagesModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openViewImageModal(imageUrl: any) {
    let modalRef = this.ngbModal.open(ImagePreviewComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.imageUrl = imageUrl
  }

  openTimepicker(p: NgbPopover, template: any) {
    if (this.ngbPopover?.isOpen) this.ngbPopover.close();
    if (!p.isOpen()) {
      this.ngbPopover = p;
      p.ngbPopover = template;
      p.open();
    } else {
      p.close();
    }
  }

  setTime(time: string) {
    if (time == 'endTime') {
      if (this.endTime?.hour != null && this.endTime?.minute != null && this.endTime?.second != null) {
        let convertedTime = ApplicationUtils.getTimeFromNgTimePicker(this.datePipe, this.endTime);
        this.formGroup.controls?.['endTime'].patchValue(convertedTime);
      }
    }
    if (this.ngbPopover?.isOpen()) this.ngbPopover?.close();
    this.formGroup.updateValueAndValidity();

    this.checkEndTimeValidation()
  }

  checkEndTimeHTMLValidation() {
    if (this.formGroup.controls['endDate'].value && this.formGroup.controls['endTime'].value) {
      let endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value);
      let endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);
      if (endDate && endTime) {
        let _sDate = ApplicationUtils.convertedDate(this.auctionEntityDto?.startDate!, this.auctionEntityDto?.startTime!);
        let _eDate = ApplicationUtils.convertedDate(endDate, endTime);

        let currentDate = new Date();

        if (_sDate && _eDate && (_sDate >= _eDate || currentDate >= _eDate)) {
          return true;
        }
      }
      return false;
    }
    return true;
  }


  haveAnyErrorInEndDate(){
    if(this.checkEndTimeHTMLValidation() && !this.lotEntityDto?.active){
      return true
    }
    return false
  }

  checkEndTimeValidation() {
    if (this.formGroup.controls['endDate'].value && this.formGroup.controls['endTime'].value) {
      let endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value);
      let endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);
      if (endDate && endTime) {
        let _sDate = ApplicationUtils.convertedDate(this.auctionEntityDto?.startDate!, this.auctionEntityDto?.startTime!);
        let _eDate = ApplicationUtils.convertedDate(endDate, endTime);

        let currentDate = new Date();

        if (_sDate && _eDate && (_sDate >= _eDate || currentDate >= _eDate)) {
          this._showErrorToast$.next(true);
          if (currentDate >= _eDate) {
            this._errorMsg$.next("THE LOT END DATE/TIME MUST BE AFTER THE CURRENT DATE/TIME");
          } else {
            this._errorMsg$.next("THE LOT END DATE/TIME MUST BE AFTER THE START DATE/TIME");
          }
          return true;
        } else {
          this._showErrorToast$.next(false);
          this._errorMsg$.next("");
        }
      }
      return false;
    }
    return true;
  }

  formatEstStartingPrice() {
    let estStartingPrice = this.formGroup.controls['estStartingPrice'].value;
    let formattedEstStartingPrice = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, estStartingPrice);
    this.fc['estStartingPrice'].patchValue(formattedEstStartingPrice);
    this.updateEstPriceValidator();
  }

  
  formatWarningBidValue() {
    let warnBidValue = this.formGroup.controls['warnBidValue'].value;
    let formattedWarnBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, warnBidValue);
    this.fc['warnBidValue'].patchValue(formattedWarnBidValue);
  }

  formatBlockBidValue() {
    let blockBidValue = this.formGroup.controls['blockBidValue'].value;
    let formattedBlockBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, blockBidValue);
    this.fc['blockBidValue'].patchValue(formattedBlockBidValue);
  }

  formatEstEndingPrice() {
    let estEndingPrice = this.formGroup.controls['estEndingPrice'].value;
    let formattedEstEndingPrice = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, estEndingPrice);
    this.fc['estEndingPrice'].patchValue(formattedEstEndingPrice);
    this.updateEstPriceValidator();
  }

  populateInitialData() {
    this.isSubmitted = false;
    this.formGroup.reset()
    this.formGroup.controls['title'].setValue(this.lotEntityDto?.title);
    this.formGroup.controls['description'].setValue(this.lotEntityDto?.description);

    this.populateLotBidDetails();

    if(this.lotEntityDto?.endTime){
      let endDate = ApplicationUtils.getNgbPickerDate(this.lotEntityDto?.endDate!);
      this.formGroup.controls['endDate']?.patchValue(endDate);
    }
    let currentDate = new Date();

    if (this.lotEntityDto?.endTime) {
      this.endTime = ApplicationUtils.getNgbPickerTime(this.lotEntityDto?.endTime!);
      let endTime12h = ApplicationUtils.convertTime24to12(this.lotEntityDto?.endTime!, this.datePipe);
      this.formGroup.controls['endTime']?.patchValue(endTime12h);
    } else {
      this.endTime = ApplicationUtils.getNgbPickerTime(`${currentDate.getHours()}:${currentDate.getMinutes()}`);
    }

    this.formGroup.controls['hashtags'].patchValue(this.lotEntityDto?.hashtags);

    if (this.lotEntityDto?.highlights && this.lotEntityDto?.highlights!.length > 0) {
      if(this.lotEntityDto?.highlights!.length == 1){
        this.formGroup.controls['highlights'].patchValue(this.lotEntityDto.highlights.toString());
      }else{
        let highlights = this.lotEntityDto.highlights.toString().replaceAll(',', '\n')
        this.formGroup.controls['highlights'].patchValue(highlights);
      }

    } else {
      this.formGroup.controls['highlights'].patchValue(null);
    }

    this.formGroup.controls['pickup'].setValue(this.lotEntityDto?.pickUpDetails);
    this.formGroup.controls['inspection'].setValue(this.lotEntityDto?.inspectionDetails);
    this.formGroup.controls['publishReservePrice'].setValue(this.lotEntityDto?.publishReservePrice);

    this.updateReservePriceValidator();
    this.formGroup.updateValueAndValidity()
  }

  showReservedPrice(): boolean {
    return this.lotEntityDto?.lotType == "Reserve";
  }

  updateEstPriceValidator(){
    let formValue = this.formGroup.getRawValue();
    let startingBid = ApplicationUtils.resetPriceFormat(formValue.startingBid);
    let estStartingPrice = ApplicationUtils.resetPriceFormat(formValue.estStartingPrice);
    let estEndingPrice = ApplicationUtils.resetPriceFormat(formValue.estEndingPrice);


    if(!estStartingPrice &&  !estEndingPrice){
      this.formGroup.controls['estStartingPrice'].clearValidators();
      this.formGroup.controls['estEndingPrice'].clearValidators();
    }else{
      this.formGroup.controls['estStartingPrice'].setValidators([Validators.required, StringMinValidator(startingBid), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
      this.formGroup.controls['estEndingPrice'].setValidators([Validators.required, StringMinValidator(estStartingPrice + 1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]);
    }

    this.formGroup.controls['estStartingPrice'].updateValueAndValidity();
    this.formGroup.controls['estEndingPrice'].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  formatStartingBid() {
    let startingBid = this.formGroup.controls['startingBid'].value;
    let formattedStartingBid = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, startingBid);
    this.fc['startingBid'].patchValue(formattedStartingBid);
    this.updateEstPriceValidator();
  }

  populateLotBidDetails() {
    // Populate data in Form
    this.formGroup.patchValue(this.lotEntityDto!);

    // Bid Incremental Data
    if (this.lotEntityDto?.incrementalWrapperDto) {
      this.radioBidIncrement.patchValue(this.lotEntityDto?.incrementalWrapperDto?.incrementalBidType == 'SINGLE')
      if (this.lotEntityDto?.incrementalWrapperDto?.incrementalBidType == 'SINGLE') {
        if (this.lotEntityDto?.incrementalWrapperDto?.constantIncrement) {
          this.incrementBidValue = this.lotEntityDto?.incrementalWrapperDto?.constantIncrement!.toString();
          this.singleBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.incrementBidValue));
        } else {
          this.incrementBidValue = '0';
          this.singleBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.incrementBidValue));
        }
      } else {
        let bidIncrementalDtoList = this.lotEntityDto.incrementalWrapperDto.bidIncrementalDtoList;
        if (bidIncrementalDtoList && bidIncrementalDtoList.length > 0) {
          this.incrementalConfig.clear();
          this.bidIncrementalList = [];
          bidIncrementalDtoList.sort((a, b) => a.upToAmount! - b.upToAmount!);
          bidIncrementalDtoList.forEach(config => {
            if (config.upToAmount != 999999999999999) {
              this.incrementalConfig.push(this.updateIncrementalConfig(config));
              let bidIncrementalDto = new BidIncrementalDto();
              bidIncrementalDto.upToAmount = config.upToAmount;
              bidIncrementalDto.incrementValue = config.incrementValue;
              this.bidIncrementalList?.push(bidIncrementalDto);
            } else {
              this.infinityBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, config.incrementValue!));
              this.incrementBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, config.incrementValue!);
            }
          })
        } else {
          this.incrementBidValue = '0';
        }
      }
    } else {
      this.incrementBidValue = '0';
    }

        // startingBidValue
        if (this.lotEntityDto?.startingBid) {
          let startingBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.startingBid);
          this.formGroup.get('startingBid')?.patchValue(startingBidValue);
        }

        // reserved Bid Value
        if (this.lotEntityDto?.reservePrice) {
          let reserveBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.reservePrice);
          this.formGroup.get('reservePrice')?.patchValue(reserveBidValue);
        }

        if (this.lotEntityDto?.estStartingPrice) {
          let estStartingPriceValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.estStartingPrice);
          this.formGroup.get('estStartingPrice')?.patchValue(estStartingPriceValue);
        }else{
          this.formGroup.get('estStartingPrice')?.patchValue('0');
        }


    //warnBid
    this.formGroup.get('warnBid')?.patchValue(this.lotEntityDto?.warnBid);
    this.formGroup.updateValueAndValidity();
    this.enableAndDisableWarnBid();
    if (this.lotEntityDto?.warnBid) {
      let warnBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.warnBidValue);
      this.formGroup.get('warnBidValue')?.patchValue(warnBidValue);
    } else {
      this.formGroup.get('warnBidValue')?.patchValue('0');
    }

    //block bid
    this.formGroup.get('blockBid')?.patchValue(this.lotEntityDto?.blockBid);
    this.formGroup.updateValueAndValidity();
    this.enableAndDisableBlockBid();
    if (this.lotEntityDto?.blockBid) {
      let blockBidValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.blockBidValue);
      this.formGroup.get('blockBidValue')?.patchValue(blockBidValue);
    } else {
      this.formGroup.get('blockBidValue')?.patchValue('0');
    }

        if (this.lotEntityDto?.estEndingPrice) {
          let estEndingPriceValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, this.lotEntityDto?.estEndingPrice);
          this.formGroup.get('estEndingPrice')?.patchValue(estEndingPriceValue);
        }else{
          this.formGroup.get('estEndingPrice')?.patchValue('0');
        }

    this.formGroup.updateValueAndValidity();
  }

  checkIfBlockValidationInvalid(auctionLotDto: AuctionLotEntityDto) {
    //block bid value validation
    if (auctionLotDto?.blockBid) {

      if (auctionLotDto?.warnBid && auctionLotDto?.warnBidValue) {
        let warnBidValue = auctionLotDto?.warnBidValue;
        if (Number(auctionLotDto?.blockBidValue!) <= Number(warnBidValue!)) {
          return true;
        }

      } else {

        let incrementType = auctionLotDto?.incrementalWrapperDto?.incrementalBidType;
        let constantIncrement = auctionLotDto?.incrementalWrapperDto?.constantIncrement;
        let multiHighestIncrementValue = this.getMultiHighestIncrementValue(auctionLotDto?.incrementalWrapperDto?.bidIncrementalDtoList!);

        if (incrementType == 'SINGLE' && Number(auctionLotDto?.blockBidValue!) <= Number(constantIncrement!)) {
          //Blocking Bid Value is grater than increment Bid Value
          return true;

        } else if (Number(auctionLotDto?.blockBidValue) <= Number(multiHighestIncrementValue!)) {
          //Blocking Bid Value is grater than highest increment Bid Value
          return true;
        }
      }

    }
    return false;
  }
  checkIfWarnValidationInvalid(auctionLotDto: AuctionLotEntityDto){
      //warn bid value validation
      if (auctionLotDto?.warnBid) {

        let incrementType = auctionLotDto?.incrementalWrapperDto?.incrementalBidType;
        let constantIncrement = auctionLotDto?.incrementalWrapperDto?.constantIncrement;
        let multiHighestIncrementValue = this.getMultiHighestIncrementValue(auctionLotDto?.incrementalWrapperDto?.bidIncrementalDtoList!);

        if (incrementType == 'SINGLE' && Number(auctionLotDto?.warnBidValue!) <= Number(constantIncrement!)) {
          //Warning Bid Value is grater than increment Bid Value
          return true;

        } else if (Number(auctionLotDto?.warnBidValue) <= Number(multiHighestIncrementValue!)) {
          //Warning Bid Value is grater than highest increment Bid Value
          return true;
        }

      }
      return false;
  }

  getMultiHighestIncrementValue(bidIncrementalDtoList: BidIncrementalDto[]){
    if(bidIncrementalDtoList && bidIncrementalDtoList.length > 0){
      let incrementList = bidIncrementalDtoList.map(item => item.incrementValue).sort((a, b) => b! - a!);
      return incrementList[0];
    }
    return 0;
  }


  addLotFeature() {
    if (this.fgLotFeature.invalid) {
      this.fgLotFeature.markAllAsTouched()
      return
    }

    if (!this.lotEntityDto?.lotFeatures) {
      this.lotEntityDto!.lotFeatures = [];
    }

    let lotFeatures = new LotFeatures();
    lotFeatures.featureKey = this.fgLotFeature.controls['featureKey'].value;
    lotFeatures.featureValue = this.fgLotFeature.controls['featureValue'].value;
    this.lotEntityDto?.lotFeatures.push(lotFeatures);

    this.closeModal(this.lotFeatureModalRef)
  }

  removeLotFeature(index: number) {
    this.lotEntityDto?.lotFeatures?.splice(index, 1);
  }

  chooseMultipleImages(event: any) {
    let files = event.target.files;

    if (files.length > 0) {
      this.isImageSelected = true;
    }

    for (const file of files) {
      this.processMultipleFiles(file);
    }
  }

  uploadMultiImages() {
    if (this.currentMultipleFiles.length == 0) {
      return;
    }

    this.fileUploadError = false;
    this._showImagesErrorToast$.next(false);
    this._errorMsg$.next("");
    this._showErrorToast$.next(false);
    this.isUploading = true;

    let metaDataList: string[] = [];
    let formData = new FormData();

    for (let i = 0; i < this.currentMultipleFiles.length; i++) {
      let currentFile = this.currentMultipleFiles[i];

      let metaData = {
        'mimeType': currentFile.type,
        'version': 0,
        'publicApi': true,
        'dataType': this.currentMultipleFilesType[i]
      };

      metaDataList.push(JSON.stringify(metaData));
      formData.append("files", currentFile);
    }

    formData.append("metaDataList", JSON.stringify(metaDataList));

    this.fileService.uploadMultipleFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          if (!this.lotEntityDto?.lotImages) {
            this.lotEntityDto!.lotImages = [];
          }

          if (apiResponseDto.data) {
            for (const fileInfoDto of apiResponseDto.data as FileInfoDto[]) {
              fileInfoDto.displayName = fileInfoDto.fileName;
              this.lotEntityDto?.lotImages.push(fileInfoDto!);
            }
          }

          this.isUploading = false;

          this.closeModal(this.imagesModalRef);
        }
      } else {
        this.fileUploadError = true;
        this.isUploading = false;
      }
    })
  }

  uploadVideoFile() {
    let videoUrl = this.ctrlVideoUrl.value;
    if (videoUrl && this.isValidUrl(videoUrl)) {
      console.log(videoUrl);

      let currentDate = new Date();
      let fileType: string = ApplicationUtils.getUrlExtension(videoUrl);

      let fileInfoDto = new FileInfoDto();
      fileInfoDto.fileId = videoUrl;
      fileInfoDto.dataType = 'Rectangle';
      fileInfoDto.displayName = videoUrl;
      fileInfoDto.fileName = videoUrl;
      fileInfoDto.fileType = this.videoTypes.includes(fileType.toLowerCase()) ? fileType : 'embed';
      fileInfoDto.uploadDate = this.datePipe.transform(currentDate, 'dd/MM/yyyy hh:mm a')!;

      if (!this.lotEntityDto?.lotImages) {
        this.lotEntityDto!.lotImages = [];
      }
      this.lotEntityDto?.lotImages?.push(fileInfoDto);
      this.closeModal(this.imagesModalRef);
    }
  }

  removeMultiImage(index: number) {
    this.currentMultipleFiles.splice(index, 1);
    this.currentMultipleFilesPath.splice(index, 1);

    if (this.currentMultipleFiles.length == 0) {
      this.isImageSelected = false;
    }
  }

  deleteImage(index: number) {
    this.lotEntityDto?.lotImages?.splice(index, 1);
  }

  async processMultipleFiles(file: File) {
    this._showImagesErrorToast$.next(false);
    let currentFile = file;

    if (!this.imageTypes.includes(file.type)) {
      this.fileUploadError = true;
      this._errorMsg$.next("Extension not supported");
      this._showImagesErrorToast$.next(true);
      this.lotImageRef?.setAttribute('value', '');

      return;
    }

    // Check if Image is HEIC/HEIF format.
    if (/image\/hei(c|f)/.test(currentFile.type)) {
      let jpgBlob = await heic2any({ blob: currentFile, toType: 'image/jpeg', quality: 0 });

      //Change the name of the file according to the new format
      let newName = currentFile.name.replace(/\.[^/.]+$/, ".jpg");

      //Convert blob back to file
      let newFile: any = jpgBlob;
      newFile.lastModified = new Date();
      newFile.name = newName;
      currentFile = <File>newFile;
    }

    let reader = new FileReader();
    reader.readAsDataURL(currentFile);
    reader.onload = (e) => {
      let size = (currentFile.size! / 1024) / 1024;

      // Create New Image
      var newImage = new Image();
      newImage.src = e.target!.result as string;

      newImage.onload = (el) => {
        let imageType = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
        if (size > 1) {
          var canvas = document.createElement("canvas");
          canvas.width = newImage.width;
          canvas.height = newImage.height;

          var ctx = canvas.getContext("2d");
          ctx?.drawImage(el.target as CanvasImageSource, 0, 0, canvas.width, canvas.height);
          var srcEncoded;
          if (size >= 5) {
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.1);
          } else {
            // size less then 5 MB
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.5);
          }

          ApplicationUtils.base64toFile(srcEncoded, currentFile.name, currentFile.type)
            .then((file: File) => {
              currentFile = file;

              this.currentMultipleFiles.push(currentFile);
              this.currentMultipleFilesPath.push(reader.result!);
              this.currentMultipleFilesType.push(imageType);
            })
        } else {
          this.currentMultipleFiles.push(currentFile);
          this.currentMultipleFilesPath.push(reader.result!);
          this.currentMultipleFilesType.push(imageType);
        }
      }
    }
  }

  // Bid Incremental Config
  get incrementalConfig(): FormArray {
    return this.formGroup.get("bidIncrementalDtoList") as FormArray
  }

  newIncrementalConfig(): FormGroup {
    return this.fb.group({
      upToAmount: ['', [Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]],
      incrementValue: ['', [Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]]
    })
  }

  updateIncrementalConfig(config: BidIncrementalDto): FormGroup {
    return this.fb.group({
      upToAmount: [ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, config.upToAmount!), [Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]],
      incrementValue: [ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, config.incrementValue!), [Validators.required, StringMinValidator(1), Validators.pattern(this.auctionEntityDto?.currency?.regex!)]]
    })
  }

  addBidIncrementConfig() {
    this.incrementalConfig.push(this.newIncrementalConfig());
  }

  infinityBidIncrementConfig() {
    let bidIncrementalDto = new BidIncrementalDto();
    bidIncrementalDto.upToAmount = 999999999999999;
    bidIncrementalDto.incrementValue = ApplicationUtils.resetPriceFormat(this.infinityBidIncrement.value);
    return bidIncrementalDto;
  }

  removeBidIncrementConfig(i: number) {
    this.incrementalConfig.removeAt(i);
  }

  onSelectedCategory(preferenceCategory: PreferenceCategoryDto) {
    this.selectedPreferenceCategory = preferenceCategory;
  }

  margeAuctionLotEntityDto(): AuctionLotEntityDto {
    let formValue = this.formGroup.getRawValue();

    let auctionLotEntityDto = ApplicationUtils.clone(this.lotEntityDto) as AuctionLotEntityDto;
    auctionLotEntityDto.title = this.formGroup.controls['title'].value;
    auctionLotEntityDto.description = this.formGroup.controls['description'].value;
    auctionLotEntityDto.endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value)!;
    auctionLotEntityDto.endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);

    auctionLotEntityDto.preferenceCategory = this.selectedPreferenceCategory;
    if (this.formGroup.controls['hashtags'].value) {
      auctionLotEntityDto!.hashtags = this.formGroup.controls['hashtags'].value;
    } else {
      auctionLotEntityDto!.hashtags = [];
    }

    if (this.formGroup.controls['highlights'].value && this.formGroup.controls['highlights'].value.length > 0) {
      if(this.formGroup.controls['highlights'].value.length == 1){
        auctionLotEntityDto!.highlights = [];
        auctionLotEntityDto!.highlights.push(this.formGroup.controls['highlights'].value.toString());
      }else{
        auctionLotEntityDto!.highlights = (this.formGroup.controls['highlights'].value as string).split('\n');
      }

    } else {
      auctionLotEntityDto!.highlights = [];
    }

    auctionLotEntityDto.inspectionDetails = this.formGroup.controls['inspection'].value;
    auctionLotEntityDto.pickUpDetails = this.formGroup.controls['pickup'].value;

    let incrementalWrapperDto = new BidIncrementalWrapperDto();
    if (this.radioBidIncrement.value == true) {
      incrementalWrapperDto.incrementalBidType = 'SINGLE';
      incrementalWrapperDto.constantIncrement = ApplicationUtils.resetPriceFormat(this.singleBidIncrement.value)
      incrementalWrapperDto.bidIncrementalDtoList = undefined;
    } else {
      incrementalWrapperDto.incrementalBidType = 'MULTIPLE';
      incrementalWrapperDto.constantIncrement = undefined;
      incrementalWrapperDto.bidIncrementalDtoList = [];
      incrementalWrapperDto.bidIncrementalDtoList?.push(this.infinityBidIncrementConfig());
    }

    if (this.radioBidIncrement.value == false && formValue.bidIncrementalDtoList) {
      formValue.bidIncrementalDtoList.forEach((item: any) => {
        let bidIncrement = new BidIncrementalDto();
        bidIncrement.upToAmount = ApplicationUtils.resetPriceFormat(item.upToAmount);
        bidIncrement.incrementValue = ApplicationUtils.resetPriceFormat(item.incrementValue);
        incrementalWrapperDto.bidIncrementalDtoList?.push(bidIncrement);
      });
    }
    auctionLotEntityDto.publishReservePrice = this.formGroup.controls['publishReservePrice'].value;
    auctionLotEntityDto!.incrementalWrapperDto = incrementalWrapperDto;
    auctionLotEntityDto.startingBid = ApplicationUtils.resetPriceFormat(this.formGroup.controls['startingBid'].value);
    auctionLotEntityDto.reservePrice = ApplicationUtils.resetPriceFormat(this.formGroup.controls['reservePrice'].value);
    auctionLotEntityDto.estStartingPrice = ApplicationUtils.resetPriceFormat(this.formGroup.controls['estStartingPrice'].value);
    auctionLotEntityDto.estEndingPrice = ApplicationUtils.resetPriceFormat(this.formGroup.controls['estEndingPrice'].value);
    
    auctionLotEntityDto.warnBid = this.formGroup.controls['warnBid'].value;
    auctionLotEntityDto.warnBidValue = ApplicationUtils.resetPriceFormat(this.formGroup.controls['warnBidValue'].value);

    auctionLotEntityDto.blockBid = this.formGroup.controls['blockBid'].value;
    auctionLotEntityDto.blockBidValue = ApplicationUtils.resetPriceFormat(this.formGroup.controls['blockBidValue'].value);
    return auctionLotEntityDto;
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '3rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '2',
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode'
      ]
    ],
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Page Divider',
        class: 'break',
        tag: 'div',
      },
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'hr',
      },
    ],
  };

  handleValidSubmit() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.isSubmitted = true;
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.auctionValidationErrorCodeDtoList = [];
    this.formatWarningBidValue();
    this.formatBlockBidValue();

    if (this.formGroup.invalid) {
      this._errorMsg$.next("Please Fill Required Fields");
      this._showErrorToast$.next(true);
      this.formGroup.markAllAsTouched();
      if (this.radioBidIncrement.value) {
        this.singleBidIncrement.markAllAsTouched();
      } else {
        this.incrementalConfig.markAllAsTouched()
        this.infinityBidIncrement.markAllAsTouched()
      }
      return;
    } else {
      if (this.singleBidIncrement.invalid && this.infinityBidIncrement.invalid) {
        return;
      }
    }

    if(!this.lotEntityDto?.lotImages || (this.lotEntityDto?.lotImages && this.lotEntityDto?.lotImages.length == 0 )){
      this._showErrorToast$.next(true);
      this._errorMsg$.next("Image is Missing!");
      return;
    }

    if (this.checkEndTimeValidation()) {
      return;
    };

    let formValue = this.formGroup.getRawValue();

    // Validation Check For Reserve Price
    let startingBid = ApplicationUtils.resetPriceFormat(formValue?.startingBid);
    let reservePrice = ApplicationUtils.resetPriceFormat(formValue?.reservePrice);

    if (this.lotEntityDto?.lotType == 'Reserve' || reservePrice > 0) {
      if (Number(startingBid) >= Number(reservePrice) && Number(startingBid) > 0 && Number(startingBid) > 0) {
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Reserve Price should be greater than Starting Bid value!");
        return;
      } else {
        this._showErrorToast$.next(false);
      }
    }
    
    let auctionLotDto = this.margeAuctionLotEntityDto();


    if(this.checkIfWarnValidationInvalid(auctionLotDto)){
      this._showErrorToast$.next(true);
      this._errorMsg$.next("Warning bid value must be greater than incremental bid value ");
      return;
    }

    if(this.checkIfBlockValidationInvalid(auctionLotDto)){
      this._showErrorToast$.next(true);
      this._errorMsg$.next("Block bid value must be greater than incremental bid value and warn bid value");
      return;
    }

    this.isLoading = true;

    this.lotService.saveAuctionLotDetails(auctionLotDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionLotSaveWrapperDto = apiResponseDto.data as AuctionLotSaveWrapperDto;
          let auctionValidationErrorCodeDtoList = auctionLotSaveWrapperDto.auctionValidationErrorCodeDtos;

          if (!auctionValidationErrorCodeDtoList || auctionValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLoading = false;
            this.closeModal();

            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving lot. Try again.");
        this.isLoading = false;
      }
    })
  }

  formatInfinityIncrement() {
    let infinityIncrementValue = this.infinityBidIncrement.value;
    this.infinityBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, infinityIncrementValue));
  }

  formatSingleIncrement() {
    let singleBidIncrementValue = this.singleBidIncrement.value;
    this.singleBidIncrement.patchValue(ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, singleBidIncrementValue));
  }

  formatIncrementUpToValue(index: number) {
    let upToAmount = this.incrementalConfig.controls[index].get('upToAmount')?.value;
    let formattedUpToAmount = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, upToAmount);
    this.incrementalConfig.controls[index].get('upToAmount')?.patchValue(formattedUpToAmount);
  }

  formatIncrementValue(index: number) {
    let incrementValue = this.incrementalConfig.controls[index].get('incrementValue')?.value;
    let formattedIncrementValue = ApplicationUtils.getFormattedPrice(this.auctionEntityDto?.currency?.locale!, incrementValue);
    this.incrementalConfig.controls[index].get('incrementValue')?.patchValue(formattedIncrementValue);
  }

  invalidPriceValidator(item: AbstractControl | null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }

  isAllowToDeleteLastImage(auctionLot: AuctionLotEntityDto) {
    if (auctionLot?.active && auctionLot.lotImages?.length == 1) {
      return false;
    }
    return true;
  }

  isMultipleBidIncrementDecrementAllowed() {
    this.multipleBidIncrementDecrementAllowed = false;

    if (this.isReverseAuction()) {
      this.multipleBidIncrementDecrementAllowed = false;
      return;
    }
    if (this.auctionEntityDto?.rankCurrentPriceConfig == RankPriceConfigEnum.SHOW_CURRENT_PRICE_ONLY_WITH_TRANSITION) {
      this.multipleBidIncrementDecrementAllowed = true;
      return;
    }

  }

  ngOnDestroy(): void {
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}
