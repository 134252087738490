import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ForgotPasswordDto } from 'src/app/shared/models/ForgotPasswordDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { Pattern } from 'src/app/shared/util/Patterns';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';

@Component({
  selector: 'app-user-password-reset',
  templateUrl: './user-password-reset.component.html',
  styleUrls: ['./user-password-reset.component.sass']
})
export class UserPasswordResetComponent implements OnInit, OnDestroy {
  formGroup: FormGroup

  otpId?: string;
  emailId: string = ""
  isPasswordVisible: boolean = false;
  isRepeatPasswordVisible: boolean = false;
  isShowOtpScreen: boolean = false;
  isLoading: boolean = false;

  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _isPasswordMatch$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _successMsg$ = new BehaviorSubject<string>("");
  _errorMsg$ = new BehaviorSubject<string>("");

  ctrlEmail: FormControl = new FormControl('', [Validators.required, Validators.pattern(Pattern.email)])

  constructor(
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthenticationService
  ) {
    this.formGroup = this.fb.group({
      otp: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(9), PasswordStrengthValidator]),
      confirmPassword: new FormControl('', [Validators.required]),
    })
  }

  ngOnInit(): void {
    this.formGroup.reset();
  }

  get fc(): any { return this.formGroup.controls; }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleRepeatPasswordVisibility() {
    this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  onChange() {
    this._errorMsg$.next('')
    this._showErrorToast$.next(false);
    if (this.formGroup.controls['password'].value == this.formGroup.controls['confirmPassword'].value) {
      this._isPasswordMatch$.next(true);
    } else {
      this._isPasswordMatch$.next(false);
    }
  }

  hideToastMessage() {
    this._errorMsg$.next('')
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
  }

  formValidationFailed() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  generateOTP() {
    this._errorMsg$.next("");
    this._showErrorToast$.next(false);

    if (this.ctrlEmail.invalid || this.ctrlEmail.value.length > 50) {
      this.ctrlEmail.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    this.emailId = this.ctrlEmail.value;

    this.userService.generateOTP(this.emailId, "", 'FORGOT_PASSWORD').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;

          this.isLoading = false;
          this.isShowOtpScreen = true;
        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
        }
      },
      error: (err) => {
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error While OTP Generate");
      }
    })
  }

  resendOTP() {
    this._errorMsg$.next("");
    this._showErrorToast$.next(false);

    this.isLoading = true;

    this.userService.resendOTP(this.emailId, "", this.otpId as string).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
          this._showSuccessToast$.next(true);
          this._successMsg$.next("OTP resent");
          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this._successMsg$.next("")
          }, 2000);

        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this._errorMsg$.next("Error While sending OTP");
        }
      },
      error: (err) => {
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error While sending OTP");
      }
    })
  }

  forgotPassword() {
    this._showErrorToast$.next(false);
    this._errorMsg$.next("")

    if (this.formValidationFailed()) {
      return;
    }
    
    if (this.formGroup.controls['password'].value != this.formGroup.controls['confirmPassword'].value) {
      this._errorMsg$.next("Password Mismatch");
      this._showErrorToast$.next(true);
      return;
    }

    this.isLoading = true;

    let forgotPasswordDto = new ForgotPasswordDto();
    forgotPasswordDto.otpId = this.otpId;
    forgotPasswordDto.userEnteredOtp = this.formGroup.controls['otp'].value;

    let password = this.formGroup.controls['password'].value;

    this.userService.forgotPassword(forgotPasswordDto, this.emailId, password).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false;
          this._showSuccessToast$.next(true);
          this._successMsg$.next("Password changed successfully.");

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)

        } else {
          this.isLoading = false;
          this._errorMsg$.next(apiResponseDto.message!);
          this._showErrorToast$.next(true);

        }
      },
      error: (err: any) => {
        this.isLoading = false;
        this._errorMsg$.next("Error while changing password");
        this._showErrorToast$.next(true);
      }
    })
  }

  ngOnDestroy(): void {
    this.isLoading = false;
    this.isShowOtpScreen = false;
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
  }

}
