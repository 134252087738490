import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { Currency } from 'src/app/shared/models/Currency';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { CompanyProcurementBudgetUiDto } from 'src/app/shared/models/OrgBudgetUiDto';
import { PlantsBudgetWrapperUIDto } from 'src/app/shared/models/PlantsBudgetWrapperUIDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

interface BudgetTableData {
  costCenterCode: string
  costCenterName: string
  costCenterBudget?: number
  plantsBudget: PlantBudgetTableData[]
}

interface PlantBudgetTableData {
  plantCode: string
  plantBudget?: number
  allowBudget: boolean
}

@Component({
  selector: 'app-onboarding-budgets',
  templateUrl: './onboarding-budgets.component.html',
  styleUrls: ['./onboarding-budgets.component.sass']
})
export class OnboardingBudgetsComponent implements OnInit, OnDestroy {
  organizationUiDto?: OrganizationWrapperUiDto;
  selectedCompanyUiDto?: CompanyUiDto;
  plantsBudgetWrapperUIDto?: PlantsBudgetWrapperUIDto;
  orgBudgetUiDto?: CompanyProcurementBudgetUiDto;

  ngTableDataList: BudgetTableData[] = []
  plantUiDtos: PlantUiDto[] = []

  selectedCompanySubscription$?: Subscription;
  plantsBudgetWrapperSubscription$?: Subscription;

  constructor(
    private drawerService: DrawerService,
    private onboardingService: OnboardingService
  ) { }

  ngOnInit(): void {
    this.organizationUiDto = this.onboardingService.getOrganizationUiDto;

    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;

        this.plantUiDtos = this.selectedCompanyUiDto?.plantUiDtos ?? []
        this.plantUiDtos.sort((a, b) => a.plantCode!.localeCompare(b.plantCode!));

        this.loadPlantsBudgetWrappers();
      } else {
        this.selectedCompanyUiDto = undefined;
        this.plantUiDtos = [];
      }
    })

    this.plantsBudgetWrapperSubscription$ = this.onboardingService.getPlantsBudgetWrapperUIDto$.subscribe(data => {
      if (data) {
        this.plantsBudgetWrapperUIDto = data;
        this.initCostCenterBudgetTable();
      } else {
        this.orgBudgetUiDto = undefined;
        this.plantsBudgetWrapperUIDto = undefined;
      }
    })
  }

  openNewBudgetDrawer(event: any) {
    event.stopPropagation();
    this.drawerService.openDrawer('ONBOARDING_NEW_BUDGET');
  }

  async loadPlantsBudgetWrappers() {
    await this.onboardingService.loadPlantsBudgetWrapperUIDto(this.organizationUiDto?.orgCode!, this.selectedCompanyUiDto?.companyCode!);
  }

  initCostCenterBudgetTable() {
    this.ngTableDataList = [];

    this.orgBudgetUiDto = this.plantsBudgetWrapperUIDto?.companyProcurementBudgetUIDto;
    let costCenterBudgetUiDtos = this.plantsBudgetWrapperUIDto?.companyCostCenterBudgetUiDtos ?? [];
    let plantsCostCenterBudgets = this.plantsBudgetWrapperUIDto?.plantsCostCenterBudgets ?? [];

    plantsCostCenterBudgets = plantsCostCenterBudgets.sort((a, b) => a.plantCode!.localeCompare(b.plantCode!));

    if (costCenterBudgetUiDtos.length > 0) {
      costCenterBudgetUiDtos.forEach(item => {

        let ngTableData: BudgetTableData = {
          costCenterCode: item.costCenterCode!,
          costCenterName: item.costCenterName!,
          costCenterBudget: item.budgetAmt,
          plantsBudget: []
        }

        this.plantUiDtos.forEach(plantUiDto => {
          let plantBudget = plantsCostCenterBudgets.find(plant => plant.plantCode == plantUiDto.plantCode && plant.costCenterCode == item.costCenterCode);
          let plantCostCenters = plantUiDto.plantCostCenterUiDtos ?? [];

          let plantBudgetTableData: PlantBudgetTableData = {
            allowBudget: plantCostCenters.some(pCC => pCC.costCenterCode == item.costCenterCode),
            plantCode: plantUiDto.plantCode!,
            plantBudget: plantBudget != undefined ? Number(plantBudget.budgetAmt) : undefined
          };

          ngTableData.plantsBudget.push(plantBudgetTableData)
        })

        this.ngTableDataList.push(ngTableData);
      })
    }
  }

  getFormattedPrice(price: any) {
    let currency = this.selectedCompanyUiDto?.currencyDto ?? Currency.default();
    return currency.symbol + ApplicationUtils.getFormattedPrice(currency.locale, price);
  }

  ngOnDestroy(): void {

  }
}
