import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AddressDto } from 'src/app/shared/models/Address';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-onboarding-new-plant',
  templateUrl: './onboarding-new-plant.component.html',
  styleUrls: ['./onboarding-new-plant.component.sass']
})
export class OnboardingNewPlantComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onCloseEvent = new EventEmitter<string | undefined>();

  formGroup: FormGroup;
  formGroupAddress: FormGroup;
  formGroupBank: FormGroup;
  currentTab: string = 'BASIC_DETAILS';
  formConfig: { [key: string]: any } = {};
  successMessages: { [key: string]: any } = {};
  isDataLoading: boolean = true;
  isLoading: boolean = false;
  newPlantCode?: string;

  shippingLatitude?: number;
  shippingLongitude?: number;
  billingLatitude?: number;
  billingLongitude?: number;
  shippingCountryShortName?: string
  billingCountryShortName?: string

  selectedPlantUiDto?: PlantUiDto
  selectedCompanyUiDto?: CompanyUiDto;
  selectedBillingAddress?: AddressDto;
  selectedShippingAddress?: AddressDto;
  plantUiDtos: PlantUiDto[] = [];
  filteredPlantEntityDtos: PlantUiDto[] = [];

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _filteredPlantEntityDtos$ = new BehaviorSubject<PlantUiDto[]>([]);

  plantsListSubscription$?: Subscription;
  selectedCompanySubscription$?: Subscription;
  selectedPlantSubscription$?: Subscription;
  drawerSubscription$?: Subscription;

  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  zoom = 12;
  positionBilling?: google.maps.LatLngLiteral;
  positionShipping?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  markerBilling = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }
  markerShipping = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  constructor(
    private fb: FormBuilder,
    private changeDetectRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private datePipe: DatePipe,
    private drawerService: DrawerService,
    private  themeService: ThemeService
  ) {
    this.formGroup = this.fb.group({
      plantName: [''],
      plantCode: [''],
    })
    this.formGroupAddress = this.fb.group({
      shippingAddress: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: [''],
      }),
      billingAddress: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: [''],
      }),
    });
    this.formGroupBank = this.fb.group({
      accountTitle: [''],
      accNumber: [''],
      accHolderName: [''],
      accType: [''],
      accCode: [''],
      swift: [''],
      iban: [''],
    });
  }

  ngOnInit(): void {
    this.loadPlantUiDto();
    this.setupThemeStructure();
    this.plantsListSubscription$ = this.onboardingService.getPlantUiDtos$.subscribe(data => {
      if (data) {
        this.plantUiDtos = data;
        this.refreshPlantsList();

        if (this.selectedPlantUiDto) {
          this.selectedPlantUiDto = this.plantUiDtos.find(item => item.plantCode == this.selectedPlantUiDto?.plantCode);
        }
      } else {
        this.plantUiDtos = [];
        this.filteredPlantEntityDtos = this.plantUiDtos;
        this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
      }
    })

    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
        this.refreshPlantsList();
      } else {
        this.selectedCompanyUiDto = undefined;
      }
    })

    this.selectedPlantSubscription$ = this.onboardingService.getSelectedPlantUiDto$.subscribe(data => {
      if (data) {
        this.selectedPlantUiDto = data;
        this.populatePlantDetails()
      } else {
        this.selectedPlantUiDto = undefined;
      }
    })
  }
  setupThemeStructure() {
    const themeStructure = this.themeService.themeStructure;

    if (themeStructure?.plantUiDto) {
      this.successMessages = this.themeService.createSuccessMessage(themeStructure.plantUiDto);
      this.formConfig = this.themeService.createFormConfig(themeStructure.plantUiDto.model);
      this.themeService.applyValidations(this.formGroup, themeStructure.plantUiDto.model);
      this.themeService.applyValidations(this.formGroupAddress, themeStructure.plantUiDto.model);
      this.themeService.applyValidations(this.formGroupBank, themeStructure.plantUiDto.model);
    }
  }
  ngAfterViewInit(): void {
    this.drawerSubscription$ = this.drawerService.drawerPageName$.subscribe(() => {
      if (this.drawerService.drawerPageTab) {
        this.currentTab = this.drawerService.drawerPageTab;
      } else {
        this.currentTab = 'BASIC_DETAILS';
      }

      if (!this.drawerService.isEditForm) {
        this.formGroup.reset();
        this.formGroupAddress.reset();
        this.formGroupBank.reset();

        this.newPlantCode = 'P' + (this.plantUiDtos.length + 1).toString().padStart(2, "0");
        this.fc['plantCode'].patchValue(this.newPlantCode);
        this.fc['plantCode'].updateValueAndValidity();
      }
    })
  }

  get fc(): any { return this.formGroup.controls; }
  get fcAddress() { return this.formGroupAddress.controls; }
  get fcBank() { return this.formGroupBank.controls; }

  async loadPlantUiDto() {
    let orgCode = this.onboardingService.getOrgCode();
    this.isDataLoading = true;
    await this.onboardingService.loadPlantUiDtosByOrgCode(orgCode!);
    this.isDataLoading = false;
  }

  toggleDetailsTab(tab: string) {
    this.currentTab = tab;
  }

  refreshPlantsList() {
    if (this.selectedCompanyUiDto) {
      this.filteredPlantEntityDtos = this.plantUiDtos.filter(item => item.companyCode == this.selectedCompanyUiDto?.companyCode);
      this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
    } else {
      this.filteredPlantEntityDtos = this.plantUiDtos;
      this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
    }
  }

  validShippingLocation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidLocation = this.shippingLatitude != undefined;
      return control.value && !isValidLocation ? { address: { value: control.value } } : null;
    }
  }

  validBillingLocation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidLocation = this.billingLatitude != undefined;
      return control.value && !isValidLocation ? { address: { value: control.value } } : null;
    }
  }

  populatePlantDetails() {
    this.formGroup.controls['plantCode'].patchValue(this.selectedPlantUiDto?.plantCode);
    this.formGroup.controls['plantName'].patchValue(this.selectedPlantUiDto?.plantName);

    if (this.selectedPlantUiDto?.billingAddress && this.selectedPlantUiDto.billingAddress.length > 0) {
      this.selectedBillingAddress = this.selectedPlantUiDto.billingAddress[0];

      this.billingLatitude = parseFloat(this.selectedBillingAddress.latitude!);
      this.billingLongitude = parseFloat(this.selectedBillingAddress.longitude!);
      this.billingCountryShortName = this.selectedBillingAddress.countryShortName;

      this.positionBilling = { lat: this.billingLatitude, lng: this.billingLongitude }
      this.markerBilling.label.text = this.selectedBillingAddress.searchAddress!;
      this.markerBilling.title = this.selectedBillingAddress.searchAddress!;
      this.markerBilling.info = this.selectedBillingAddress.searchAddress!;

      this.formGroupAddress.controls['billingAddress'].patchValue(this.selectedBillingAddress);
    }

    if (this.selectedPlantUiDto?.shippingAddress && this.selectedPlantUiDto.shippingAddress.length > 0) {
      this.selectedShippingAddress = this.selectedPlantUiDto.shippingAddress[0];

      this.shippingLatitude = parseFloat(this.selectedShippingAddress.latitude!);
      this.shippingLongitude = parseFloat(this.selectedShippingAddress.longitude!);
      this.shippingCountryShortName = this.selectedShippingAddress.countryShortName;

      this.positionShipping = { lat: this.shippingLatitude, lng: this.shippingLongitude }
      this.markerShipping.label.text = this.selectedShippingAddress.searchAddress!;
      this.markerShipping.title = this.selectedShippingAddress.searchAddress!;
      this.markerShipping.info = this.selectedShippingAddress.searchAddress!;

      this.formGroupAddress.controls['shippingAddress'].patchValue(this.selectedShippingAddress);
    }

    this.formGroupBank.controls['accountTitle'].patchValue(this.selectedPlantUiDto?.accountTitle);
    this.formGroupBank.controls['accNumber'].patchValue(this.selectedPlantUiDto?.accNumber);
    this.formGroupBank.controls['accHolderName'].patchValue(this.selectedPlantUiDto?.accHolderName);
    this.formGroupBank.controls['accType'].patchValue(this.selectedPlantUiDto?.accType);
    this.formGroupBank.controls['accCode'].patchValue(this.selectedPlantUiDto?.accCode);
    this.formGroupBank.controls['swift'].patchValue(this.selectedPlantUiDto?.swift);
    this.formGroupBank.controls['iban'].patchValue(this.selectedPlantUiDto?.iban);

    this.formGroup.updateValueAndValidity();
    this.changeDetectRef.detectChanges();
  }

  closeDrawer() {
    this.onCloseEvent.emit(undefined);
  }

  handleBillingAddressChange(address: google.maps.places.PlaceResult) {
    this.billingLatitude = address.geometry?.location?.lat();
    this.billingLongitude = address.geometry?.location?.lng();

    let city = ApplicationUtils.getAddressByType(address, 'locality');
    if (!city) {
      city = ApplicationUtils.getAddressByType(address, 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let country = ApplicationUtils.getAddressByType(address, 'country');
    this.billingCountryShortName = ApplicationUtils.getAddressShortNameByType(address, 'country');

    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 = ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 = ApplicationUtils.getAddressByType(address, 'route');

    let addressLine1 = address.formatted_address;
    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address?.includes(',')) {
        addressLine1 = address.formatted_address.split(',')[0];
      }
    } else {
      addressLine1 = addressPart1 + " " + addressPart2;
    }


    this.formGroupAddress.controls['billingAddress'].get('addressLine1')?.patchValue(addressLine1);
    this.formGroupAddress.controls['billingAddress'].get('searchAddress')?.patchValue(address.formatted_address);
    this.formGroupAddress.controls['billingAddress'].get('city')?.patchValue(city);
    this.formGroupAddress.controls['billingAddress'].get('state')?.patchValue(state);
    this.formGroupAddress.controls['billingAddress'].get('country')?.patchValue(country);
    this.formGroupAddress.controls['billingAddress'].get('zipCode')?.patchValue(zip);
    this.formGroupAddress.updateValueAndValidity();


    this.changeDetectRef.detectChanges();
  }

  handleShippingAddressChange(address: google.maps.places.PlaceResult) {
    this.shippingLatitude = address.geometry?.location?.lat();
    this.shippingLongitude = address.geometry?.location?.lng();

    let city = ApplicationUtils.getAddressByType(address, 'locality');
    if (!city) {
      city = ApplicationUtils.getAddressByType(address, 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let country = ApplicationUtils.getAddressByType(address, 'country');
    this.shippingCountryShortName = ApplicationUtils.getAddressShortNameByType(address, 'country');

    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 = ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 = ApplicationUtils.getAddressByType(address, 'route');

    let addressLine1 = address.formatted_address;
    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address?.includes(',')) {
        addressLine1 = address.formatted_address.split(',')[0];
      }
    } else {
      addressLine1 = addressPart1 + " " + addressPart2;
    }

    this.formGroupAddress.controls['shippingAddress'].get('addressLine1')?.patchValue(addressLine1);
    this.formGroupAddress.controls['shippingAddress'].get('searchAddress')?.patchValue(address.formatted_address);
    this.formGroupAddress.controls['shippingAddress'].get('city')?.patchValue(city);
    this.formGroupAddress.controls['shippingAddress'].get('state')?.patchValue(state);
    this.formGroupAddress.controls['shippingAddress'].get('country')?.patchValue(country);
    this.formGroupAddress.controls['shippingAddress'].get('zipCode')?.patchValue(zip);
    this.formGroupAddress.updateValueAndValidity();

    this.changeDetectRef.detectChanges();
  }

  mergeBasicDetails() {
    let formValue = this.formGroup.getRawValue();
    let plantUiDto = new PlantUiDto();

    if (this.selectedPlantUiDto) {
      plantUiDto = ApplicationUtils.clone(this.selectedPlantUiDto) as PlantUiDto;
    }

    let organizationUiDto = this.onboardingService.getOrganizationUiDto;

    plantUiDto.orgCode = organizationUiDto?.orgCode;
    plantUiDto.companyCode = this.selectedCompanyUiDto?.companyCode;
    plantUiDto.plantName = formValue.plantName;
    plantUiDto.plantCode = formValue.plantCode;

    return plantUiDto;
  }

  mergeAddressDetails() {
    let formValue = this.formGroupAddress.getRawValue();
    let plantUiDto = new PlantUiDto();

    if (this.selectedPlantUiDto) {
      plantUiDto = ApplicationUtils.clone(this.selectedPlantUiDto) as PlantUiDto;
    }

    if (!plantUiDto.shippingAddress) {
      plantUiDto.shippingAddress = [];
    }

    if (!plantUiDto.billingAddress) {
      plantUiDto.billingAddress = [];
    }

    let billingAddress = new AddressDto();
    billingAddress.addressType = formValue.billingAddress.addressType;
    billingAddress.searchAddress = formValue.billingAddress.searchAddress;
    billingAddress.addressLine1 = formValue.billingAddress.addressLine1;
    billingAddress.addressLine2 = formValue.billingAddress.addressLine2;
    billingAddress.city = formValue.billingAddress.city;
    billingAddress.state = formValue.billingAddress.state;
    billingAddress.country = formValue.billingAddress.country;
    billingAddress.zipCode = formValue.billingAddress.zipCode;
    billingAddress.mobileNo = formValue.billingAddress.mobileNo;
    billingAddress.latitude = this.billingLatitude?.toString();
    billingAddress.longitude = this.billingLongitude?.toString();
    billingAddress.countryShortName = this.billingCountryShortName;

    let shippingAddress = new AddressDto();
    shippingAddress.addressType = formValue.shippingAddress.addressType;
    shippingAddress.searchAddress = formValue.shippingAddress.searchAddress;
    shippingAddress.addressLine1 = formValue.shippingAddress.addressLine1;
    shippingAddress.addressLine2 = formValue.shippingAddress.addressLine2;
    shippingAddress.city = formValue.shippingAddress.city;
    shippingAddress.state = formValue.shippingAddress.state;
    shippingAddress.country = formValue.shippingAddress.country;
    shippingAddress.zipCode = formValue.shippingAddress.zipCode;
    shippingAddress.mobileNo = formValue.shippingAddress.mobileNo;
    shippingAddress.latitude = this.shippingLatitude?.toString();
    shippingAddress.longitude = this.shippingLongitude?.toString();
    shippingAddress.countryShortName = this.shippingCountryShortName;

    if (plantUiDto.billingAddress.length > 0) {
      billingAddress.id = plantUiDto.billingAddress[0].id;
      shippingAddress.id = plantUiDto.shippingAddress[0].id;

      Object.assign(plantUiDto.billingAddress[0], billingAddress);
      Object.assign(plantUiDto.shippingAddress[0], shippingAddress);
    } else {
      billingAddress.id = UUID.UUID().toString();
      shippingAddress.id = UUID.UUID().toString();

      plantUiDto.shippingAddress.push(shippingAddress);
      plantUiDto.billingAddress.push(billingAddress);
    }

    return plantUiDto;
  }

  mergeBankDetails() {
    let formValue = this.formGroupBank.getRawValue();
    let plantUiDto = new PlantUiDto();

    if (this.selectedPlantUiDto) {
      plantUiDto = ApplicationUtils.clone(this.selectedPlantUiDto) as PlantUiDto;
    }

    plantUiDto.accountTitle = formValue.accountTitle;
    plantUiDto.accNumber = formValue.accNumber;
    plantUiDto.accHolderName = formValue.accHolderName;
    plantUiDto.accType = formValue.accType;
    plantUiDto.accCode = formValue.accCode;
    plantUiDto.swift = formValue.swift;
    plantUiDto.iban = formValue.iban;

    return plantUiDto;
  }

  saveOnboardingPlants(isAddBudget?: boolean) {
    this.isLoading = false;
    this._isSaveButtonEnable$.next(false);
    this._showErrorMsg$.next("");
    this._showErrorToast$.next(false);

    if (this.currentTab == 'BASIC_DETAILS' && this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (this.currentTab == 'BILLING_AND_SHIPPING' && this.formGroupAddress.invalid) {
      this.formGroupAddress.markAllAsTouched();
      return;
    }

    if (this.currentTab == 'BANK_DETAILS' && this.formGroupBank.invalid) {
      this.formGroupBank.markAllAsTouched();
      return;
    }

    let plantUiDto = this.currentTab == 'BILLING_AND_SHIPPING' ? this.mergeAddressDetails()
      : this.currentTab == 'BANK_DETAILS' ? this.mergeBankDetails()
        : this.mergeBasicDetails()

    this.isLoading = true;

    this.onboardingService.savePlantUiDto(plantUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this._isSaveButtonEnable$.next(true);

          let newPlantDto = apiResponseDto.data as PlantUiDto;
          this.onboardingService.updatePlantUiDto(newPlantDto);
          this.onboardingService.setCurrentPlantUiDto(newPlantDto);

          this.onboardingService.loadCompanyUiDtosByOrgCode();

          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this._isSaveButtonEnable$.next(true);

            if (this.currentTab == 'BASIC_DETAILS') {
              this.toggleDetailsTab('BILLING_AND_SHIPPING');
            } else if (this.currentTab == 'BILLING_AND_SHIPPING') {
              this.toggleDetailsTab('BANK_DETAILS');
            } else if (this.currentTab == 'BANK_DETAILS') {
              if (isAddBudget) {
                this.onCloseEvent.emit('ONBOARDING_NEW_BUDGET');
              } else {
                this.onCloseEvent.emit(undefined);
              }

              this.toggleDetailsTab('BASIC_DETAILS');
            }

          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving Plant");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  openMarkerInfo(marker: MapMarker) {
    this.info.open(marker)
  }

  ngOnDestroy(): void {
    if (this.plantsListSubscription$) {
      this.plantsListSubscription$.unsubscribe();
    }
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
    if (this.selectedPlantSubscription$) {
      this.selectedPlantSubscription$.unsubscribe();
    }
    if (this.drawerSubscription$) {
      this.drawerSubscription$.unsubscribe();
    }
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  getFormattedFinancialYear(date: string) {
    let convertedDate = ApplicationUtils.convertedOnlyDate(date);
    return this.datePipe.transform(convertedDate, 'dd MMM');
  }
}

