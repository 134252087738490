import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CatalogueL1WrapperDto } from 'src/app/shared/models/CatalogueL1WrapperDto';
import { CatalogueL2EntityDto } from 'src/app/shared/models/CatalogueL2EntityDto';
import { CatalogueRequisitionWrapper } from 'src/app/shared/models/CatalogueRequisitionWrapper';
import { CatalogueVendorDto } from 'src/app/shared/models/CatalogueVendorDto';
import { Currency } from 'src/app/shared/models/Currency';
import { SourcingEventDto } from 'src/app/shared/models/user/SourcingEventDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { UserService } from 'src/app/shared/services/user.service';

declare const require: any;
const html2pdf = require('html2pdf.js');
@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.sass']
})
export class PurchaseOrderComponent implements OnInit, OnDestroy {
  @Input() catalogueL1?: CatalogueL1WrapperDto;
  @Input() catalogueL2?: CatalogueL2EntityDto;
  isLoading: boolean = false;
  isShowPdfView: boolean = false;
  @Input() selectedAuction!: SourcingEventDto;

  catalogueRequisitionWrappers?: CatalogueRequisitionWrapper[]
  selectedCatalogueRequisitionWrapper?: CatalogueRequisitionWrapper;
  currentDate?: string;
  currentTab: string = 'PR';

  currency?: Currency
  catalogueVendor?: CatalogueVendorDto

  constructor(
    public userService: UserService,
    private datePipe: DatePipe,
    private ngbModal: NgbModal,
    private changeDetectRef: ChangeDetectorRef,
    private adminService: AdminDashboardService,
    private catalogueService: CatalogueService
  ) { }

  ngOnInit(): void {
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy')!;
    this.catalogueService.getRequisitionWrapperList$.subscribe(dataList => {

      if (dataList && dataList.length > 0) {
        this.catalogueRequisitionWrappers = dataList;
      } else {
        this.catalogueRequisitionWrappers = [];
      }

    })
  }

  showDetails(catalogueRequisitionWrapper: CatalogueRequisitionWrapper) {
    this.selectedCatalogueRequisitionWrapper = catalogueRequisitionWrapper;
    this.currency = this.selectedCatalogueRequisitionWrapper.catalogueCartWrapperDtos![0].currency;
    this.catalogueVendor = this.selectedCatalogueRequisitionWrapper.catalogueCartWrapperDtos![0].catalogueVendor;
  }

  generatePdf() {
    this.isShowPdfView = true;
    this.changeDetectRef.detectChanges();
    // this.isLoading = true;

    const element = document.getElementById('pdfContent');

    let auctionHouseDto = this.adminService.getCurrentAuctionHouse$.value;
    const secondaryColor = auctionHouseDto?.secondaryColor ?? '#1b5887';

    if (element) {
      let opt = {
        // margin: [25, 12, 30, 12],
        filename: 'PO_' + "test" + '.pdf',
        html2canvas: { scale: 6 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };

      html2pdf().from(element).set(opt).set({ margin: [25, 12, 25, 12] }).toPdf().get('pdf').then((pdf: any) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          pdf.setDrawColor(secondaryColor);
          pdf.setLineWidth(1);
          pdf.line(20, pdf.internal.pageSize.getHeight() - 25, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 25);

          pdf.setFontSize(9);
          pdf.setTextColor('#000000');

          let text = `Page ${i}`;
          let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          let textOffset = (pdf.internal.pageSize.getWidth() - textWidth) / 2;

          pdf.text(text, textOffset, pdf.internal.pageSize.getHeight() - 10);
        }

        this.isLoading = false;
        this.isShowPdfView = false;
        this.changeDetectRef.detectChanges();
      }).save();
    }
  }



  openAllItemsModal(content: any, selectedCatalogueRequisitionWrapper?: CatalogueRequisitionWrapper) {
    this.selectedCatalogueRequisitionWrapper = selectedCatalogueRequisitionWrapper;
    this.ngbModal.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openArcApprovalModal(catalogueRequisitionWrapper: CatalogueRequisitionWrapper, content: any) {
    this.selectedCatalogueRequisitionWrapper = catalogueRequisitionWrapper;
    this.ngbModal.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false, centered: true
    });
  }

  markToApprove() {
    this.selectedCatalogueRequisitionWrapper!.isApproved = true;
    this.catalogueService.updateCatalogueRequisitionWrapper(this.selectedCatalogueRequisitionWrapper!);
    this.closeModal();
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  toggleDetailsTab(tab: string) {
    this.currentTab = tab;
  }

  ngOnDestroy(): void {

  }
}
