import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BasicAuthHttpInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let isAuthenticateRequest = request.url == 'authenticate' || request.url == 'changePassword';
    let isChatBotRequest = request.url.includes('botframework');

    if (!isChatBotRequest) {
      const baseUrl = request.url.startsWith('assets')
      ? request.url
      : `${environment.baseUrl.replace(/\/$/, '')}/${request.url.replace(/^\//, '')}`;

      let auctionSessionId = localStorage.getItem('AUC_SESSION_ID');

      if (auctionSessionId && auctionSessionId.length > 7 && !isAuthenticateRequest) {
        request = request.clone({ url: baseUrl,
          headers: request.headers
            .set('Authorization', "JSESSIONID=" + auctionSessionId)
            .set("Cache-Control", 'no-cache')
            .set("Cache-Control", 'no-store')
            .set("Pragma", 'no-cache')
            .set("Expires", '0')
        })
      } else {
        request = request.clone({ url: baseUrl,
          headers: request.headers
            .set("Cache-Control", 'no-cache')
            .set("Cache-Control", 'no-store')
            .set("Pragma", 'no-cache')
            .set("Expires", '0')
        })
      }
    }

    return next.handle(request);
  }
}
