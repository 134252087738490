import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AwardedBidderDto } from '../models/AwardedBidderDto';
import { AdminDashboardService } from './admin-dashboard.service';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';



@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
   isLoading: boolean = false;
   awardedBidderDto?: AwardedBidderDto;
  _awardedBidderDto$ = new BehaviorSubject<AwardedBidderDto|undefined>(undefined);


  constructor(private adminService: AdminDashboardService) {}


  get getAwardedBidderDto$() { return this._awardedBidderDto$.asObservable() }

  updateAwardedBidderDto(awardedBidderDto: AwardedBidderDto){
    this.awardedBidderDto = awardedBidderDto;
    this._awardedBidderDto$.next(this.awardedBidderDto);
  }

  loadAwardedBidderData(auctionId: string, userId: string){
    this.isLoading = true;
    this.adminService.getWinnerBidder(auctionId, userId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;
        this.awardedBidderDto = apiResponseDto.data! as AwardedBidderDto;
        this._awardedBidderDto$.next(this.awardedBidderDto);
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      }
    })
  }


}
