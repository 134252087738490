<div class="py-6 px-12">
  <div class="grid px-6 py-12 items-center justify-center" *ngIf="tenderFinancialQuestionTemplates.length == 0">
    <img src="assets/images/approval-1.svg" class=" h-16 w-16 mx-auto my-0">
    <div class="flex items-center justify-center">
      <div class="text-xl font-medium text-gray-700  mt-6">
        No financial question
      </div>
    </div>
    <div class="flex items-center justify-center">
      <div class="text-xs font-normal text-gray-500 mt-2 mb-6">
        You have not added any financial questions yet.
      </div>
    </div>
    <div class="flex items-center justify-center">
      <button
        class="inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        (click)="openAddFinancialDrawer()">
        <i-lucide name="plus" [size]="18" color="#fff" class="my-icon"></i-lucide>
        Add financial questions
      </button>
    </div>
  </div>

  <div class="block flex-row items-center" *ngIf="tenderFinancialQuestionTemplates.length > 0">
    <div>
      <p-table [value]="tenderFinancialQuestionTemplates" styleClass="p-datatable-gridlines"
        [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th>Item name</th>
            <th>Item description</th>
            <th>Quantity</th>
            <th>UoM</th>
            <th>Tax rate %</th>
            <th>Entity rate</th>
            <th *ngFor="let aItem of [].constructor(countAdditionalPrices); index as i">Additional Cost {{i+1}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr class="align-middle">
            <td class="">
              {{item.sequenceNo}}
            </td>
            <td class="">
              {{item.questionText}}
            </td>
            <td class="">
              {{item.questionDescription}}
            </td>
            <td class="">
              {{item.quantity}}
            </td>
            <td class="">
              {{item.uom}}
            </td>
            <td class="">
              <span *ngIf="item.tax">{{item.tax}} %</span>
            </td>
            <td class="">
              {{item.entityRate | priceFormat}}
            </td>
            <td class="" *ngFor="let aItem of item.additionalPrices; index as k">
              {{aItem.label}}
            </td>
            <td class="">
              <p-menu #menu [model]="createNewMenuItems" [popup]="true">
                <ng-template pTemplate="item" let-item>
                  <div class="p-menuitem-link p-3 grid">
                    <div class="flex-row flex">
                      <div class="basis-10/12">
                        <div class="text-black font-semibold text-sm">
                          {{item.title}}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-menu>
              <span class="cursor-pointer" (click)="menu.toggle($event)">
                <i-lucide name="ellipsis" color="#000" [size]="18" class="my-icon"></i-lucide>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>