import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { ContractTypeEnum } from 'src/app/shared/enums/rfx/ContractTypeEnum';
import { CompFinancialWrapperDto } from 'src/app/shared/models/rfx/CompFinancialWrapperDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { SubcategorySubmissionDto } from 'src/app/shared/models/rfx/SubcategorySubmissionDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { RfxEvaluationNormaliseItemsComponent } from '../rfx-evaluation-normalise-items/rfx-evaluation-normalise-items.component';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { NormalizeStatus } from 'src/app/shared/enums/rfx/NormalizeStatus';

@Component({
  selector: 'app-rfx-evaluation-normalise',
  templateUrl: './rfx-evaluation-normalise.component.html',
  styleUrls: ['./rfx-evaluation-normalise.component.sass']
})
export class RfxEvaluationNormaliseComponent implements OnInit, OnDestroy {
  @Input() selectedSubcategoryId!: string;

  rfxEntityDto?: RfxUiDto;
  selectedRfxSubcategoryDto?: RfxSubcategoryUiDto;
  selectedSubcategorySubmissionDto?: SubcategorySubmissionDto;
  selectedCompFinancialWrapperDto?: CompFinancialWrapperDto;

  subcategorySubmissionList: SubcategorySubmissionDto[] = [];

  isShowRequestLink: boolean = true;
  requestStatus?: string;
  isDataLoading: boolean = false;
  isLoading: boolean = false;
  errorMsg: string | undefined;

  normalizePrice: number = 0;
  noOfItemsRequiredNormalization: number = 0;

  isTechnicalEvaluationPending: boolean = false;
  purchaseOrderGenerated: boolean = false;

  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);
  comNormaliseWrapperDtos$ = new BehaviorSubject<CompFinancialWrapperDto[]>([]);

  selectedRfxSubscription$?: Subscription;
  subcategorySubmissionsSubscription$?: Subscription;
  userSubcategoryEvaluationWrapperSubscription$?: Subscription;
  userSubmissionQuestionsWrapperSubscription$?: Subscription;
  comFinancialWrappersSubscription$?: Subscription;
  technicalEvaluationStatusSubscription$?: Subscription;

  constructor(
    private adminDashboardService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private ngbModal: NgbModal,
  ) { }

  ngOnInit(): void {
    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(rfx => {
      if (rfx) {
        this.rfxEntityDto = rfx;
      }
    })

    this.subcategorySubmissionsSubscription$ = this.adminDashboardService.getSubcategorySubmissionList$.subscribe(data => {
      if (data) {
        this.subcategorySubmissionList = data;
        this.selectedSubcategorySubmissionDto = this.subcategorySubmissionList.find(item => item.subcategoryId == this.selectedSubcategoryId);

        this.loadComFinancialWrapperDto();
      } else {
        this.subcategorySubmissionList = [];
      }
    })

    this.comFinancialWrappersSubscription$ = this.adminDashboardService.getComFinancialWrapperDtos$.subscribe(data => {
      if (data && data.length > 0) {
        this.purchaseOrderGenerated = data[0].purchaseOrderGenerated ??  false;
        // data.sort((a, b) => Number(a.compSequence) - Number(b.compSequence));
        let disqualifiedBidders = data.filter(item => Number(item.compSequence) == -1);
        let qualifiedBidders = data.filter(item => Number(item.compSequence) > 0);

        qualifiedBidders.sort((a, b) => Number(a.compSequence) - Number(b.compSequence));
        disqualifiedBidders.sort((a, b) => Number(b.technicalScore) - Number(a.technicalScore));
        let compNormaliseWrappers = qualifiedBidders.concat(disqualifiedBidders);

        this.comNormaliseWrapperDtos$.next(compNormaliseWrappers);

        this.selectedCompFinancialWrapperDto = compNormaliseWrappers.find(item => item.normalizeStatus != NormalizeStatus.REJECTED
          && item.normalizeStatus != NormalizeStatus.REVOKED && Number(item.compSequence) > 0);

        if (this.selectedCompFinancialWrapperDto) {
          if (this.selectedCompFinancialWrapperDto.normalizeStatus == NormalizeStatus.REQUESTED) {
            this.isShowRequestLink = false;
          } else {
            this.isShowRequestLink = true;
          }
        }

        this.normalizePrice = qualifiedBidders[0].normalizePrice ?? 0;
        this.noOfItemsRequiredNormalization = qualifiedBidders[0].noOfItemsRequiredNormalization ?? 0;
      } else {
        this.comNormaliseWrapperDtos$.next([]);
        this.normalizePrice = 0;
        this.noOfItemsRequiredNormalization = 0;
      }
    })

    this.technicalEvaluationStatusSubscription$ = this.adminDashboardService.getTechnicalEvaluationStatus$.subscribe(data => {
      this.isTechnicalEvaluationPending = data;
    });
  }

  async loadComFinancialWrapperDto() {
    this.isDataLoading = true;
    await this.adminDashboardService.getAndLoadComFinancialWrapperDtosSync(this.selectedSubcategorySubmissionDto?.rfxId!, this.selectedSubcategoryId);
    this.isDataLoading = false;
  }

  selectComFinancialWrapper(comFinancialWrapperDto: CompFinancialWrapperDto, event: HTMLInputElement) {
    if (this.selectedCompFinancialWrapperDto?.companyId == comFinancialWrapperDto.companyId) {
      this.selectedCompFinancialWrapperDto = undefined;
      event.checked = false;
      return;
    }

    this.selectedCompFinancialWrapperDto = comFinancialWrapperDto;

    if (this.selectedCompFinancialWrapperDto) {
      if (this.selectedCompFinancialWrapperDto.normalizeStatus == NormalizeStatus.REQUESTED) {
        this.isShowRequestLink = false;
      } else {
        this.isShowRequestLink = true;
      }
    }
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll();
    }
  }

  openConfirmRequestModal(content: any, requestStatus: string) {
    this._showErrorToast$.next(false);
    this.errorMsg = "";

    this.requestStatus = requestStatus;

    this.ngbModal.open(content, {
      centered: true, size: 'md',
      backdrop: 'static', keyboard: false
    });
  }

  openEvaluationNormaliseModal(item: CompFinancialWrapperDto) {
    let modalRef = this.ngbModal.open(RfxEvaluationNormaliseItemsComponent, {
      centered: true, size: 'xl',
      backdrop: 'static', keyboard: false
    });
    modalRef.componentInstance.comFinancialWrapperDto = item;
    modalRef.componentInstance.subcategorySubmissionDto = this.selectedSubcategorySubmissionDto;
  }

  async requestNormalizePrice() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";

    let rfxId = this.selectedSubcategorySubmissionDto?.rfxId!;
    let rfxSubcategoryId = this.selectedSubcategorySubmissionDto?.subcategoryId!;
    let userId = this.selectedCompFinancialWrapperDto?.companyId!;

    this.isLoading = true;
    this.adminDashboardService.requestNormalizePrice(rfxId, rfxSubcategoryId, userId, this.requestStatus!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as CompFinancialWrapperDto[]
          this.adminDashboardService.updateComFinancialWrapperDtos(data);

          this._showSuccessToast$.next(true);
          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal()
          }, 2000)
        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this.errorMsg = "Error while requesting normalize price. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;
      }
    });
  }

  getFormattedPrice(price: any) {
    let currency = this.rfxEntityDto?.currency;
    return (currency?.symbol + ApplicationUtils.getFormattedPrice(currency?.locale, price));
  }

  getFormattedContractType(type: ContractTypeEnum) {
    let toReturn = '';

    if (type == 'ITEM_WISE') {
      toReturn = 'Item Wise'
    } else if (type == 'ITEM_RATE') {
      toReturn = 'Item Rate'
    } else if (type == 'PERCENTAGE') {
      toReturn = 'Percentage'
    } else if (type == 'LUMPSUM') {
      toReturn = 'Lumpsum'
    } else if (type == 'SUPPLY_RATE') {
      toReturn = 'Supply Rate'
    }

    return toReturn;
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
    if (this.subcategorySubmissionsSubscription$) {
      this.subcategorySubmissionsSubscription$.unsubscribe();
    }
    if (this.comFinancialWrappersSubscription$) {
      this.comFinancialWrappersSubscription$.unsubscribe();
    }
    if (this.technicalEvaluationStatusSubscription$) {
      this.technicalEvaluationStatusSubscription$.unsubscribe();
    }
  }
}
