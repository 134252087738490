import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationStartupDto } from 'src/app/shared/models/ApplicationStartupDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';
import { UserExtendPrivilegeUiDto } from 'src/app/shared/models/user/UserExtendPrivilegeUiDto';
import { ApplicationStartupCacheService } from 'src/app/shared/services/application-startup-cache.service';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';

@Component({
  selector: 'app-user-extand-profile',
  templateUrl: './user-extand-profile.component.html',
  styleUrls: ['./user-extand-profile.component.sass']
})
export class UserExtandProfileComponent implements AfterViewInit, OnDestroy{
  
  @Output() onCloseEvent = new EventEmitter<string | undefined>();
  formGroup: FormGroup;
  applicationStartupDto?: ApplicationStartupDto;
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  isLoading: boolean = false;
  isDataLoading: boolean = false;
  userExtendPrivilegeUiDtos?: UserExtendPrivilegeUiDto[] = []; 

  userExtendPrivilegeListSubscription$?: Subscription;
  userEntityInfoDto?: UserUiDto;
  drawerSubscription$?: Subscription;
  selectedUserExtendPrivilegeUiDto?: UserExtendPrivilegeUiDto;

  _showSuccessModelToast$ = new BehaviorSubject<boolean>(false);
  _showErrorModelToast$ = new BehaviorSubject<boolean>(false);
  _showErrorModelMsg$ = new BehaviorSubject<string>("");
  isLodingModel: boolean = false;
  companiesSubscription$?: Subscription;
  companiesList: CompanyUiDto[]=[];
  plantUiDtos: PlantUiDto[]=[];

  constructor(
    private ngbModal: NgbModal,
    private drawerService: DrawerService,
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
    private appStartupService: ApplicationStartupCacheService,
  ) {
    this.formGroup = this.fb.group({
      companyCode: ['', Validators.required],
      plantCode: ['', Validators.required],
      designation: ['', Validators.required],
    });
  }


  get fc() { return this.formGroup.controls; }

  closeDrawer() {
    this.onCloseEvent.emit(undefined);
  }

 async ngOnInit() {
    await this.onboardingService.loadCompanyUiDtosByOrgCode();

    this.userExtendPrivilegeListSubscription$ = this.onboardingService.getUserExtendPrivileges$.subscribe(data => {
      if (data) {
        this.userExtendPrivilegeUiDtos = data;
      } else {
        this.userExtendPrivilegeUiDtos = [];
      }
    })

    this.companiesSubscription$ = this.onboardingService.getCompanyUiDtos$.subscribe(data => {
      if (data) {
        this.companiesList = data;
     
      } else {
        this.companiesList = [];
      }
    })


  }

  
  ngAfterViewInit(): void {
    this.drawerSubscription$ = this.drawerService.drawerPageName$.subscribe(() => {
      
      this.applicationStartupDto = this.appStartupService.getApplicationStartupDto
      this.userEntityInfoDto = this.onboardingService.getSelectedUserEntityInfoDto;
      this.loadUserExtendPrivilegeData();
    })

    this.formGroup.controls['companyCode'].valueChanges.subscribe(item => {
      if(item){
        let companyCode = this.formGroup.controls['companyCode'].value;
        let selectedCompanyUiDto = this.companiesList.find(item => item.companyCode == companyCode);
        this.plantUiDtos = selectedCompanyUiDto!.plantUiDtos!;
      }else{
        this.plantUiDtos = [];
      }
    })
  }


  saveExtendProfile() {
    this.isDataLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    let userExtendPrivilegeUiDto = this.mergeUserExtendPrivilegeUiDto()
    this.isDataLoading = true;

    this.onboardingService.saveUserExtendPrivilege(userExtendPrivilegeUiDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isDataLoading = false;

          let userExtendPrivilegeUiDto = apiResponseDto.data as UserExtendPrivilegeUiDto;
          this.onboardingService.updateUserExtendPrivilege(userExtendPrivilegeUiDto)

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.formGroup.reset();
          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.isDataLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving Extend Privilege");
        this.isDataLoading = false;
      }
    });
  }
  

  mergeUserExtendPrivilegeUiDto(){
    let formValue = this.formGroup.getRawValue();
    let userExtendPrivilegeUiDto = new  UserExtendPrivilegeUiDto(); 
    userExtendPrivilegeUiDto.orgCode = this.userEntityInfoDto?.orgCode;
    userExtendPrivilegeUiDto.companyCode = formValue.companyCode;
    userExtendPrivilegeUiDto.plantCode = formValue.plantCode;
    userExtendPrivilegeUiDto.emailId = this.userEntityInfoDto?.primaryEmailId;
    userExtendPrivilegeUiDto.userId = this.userEntityInfoDto?.userId;
    userExtendPrivilegeUiDto.designation = formValue.designation

    return userExtendPrivilegeUiDto;
  }

  deletePrivilege(){
    this._showErrorModelMsg$.next("")
    this._showErrorModelToast$.next(false);
    this.isLodingModel = true;

    this.onboardingService.deleteUserExtendPrivilege(this.selectedUserExtendPrivilegeUiDto!.orgCode!, this.selectedUserExtendPrivilegeUiDto!.companyCode!,
      this.selectedUserExtendPrivilegeUiDto!.userId!, this.selectedUserExtendPrivilegeUiDto!.designation!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessModelToast$.next(true);
          this.isLodingModel = false;

          this.onboardingService.updateUserExtendPrivilege(this.selectedUserExtendPrivilegeUiDto!, true);
          setTimeout(() => {
            this._showSuccessModelToast$.next(false);
            this.closeModal
            this.formGroup.reset();
          }, 2000)
        } else {
          this._showErrorModelMsg$.next(apiResponseDto.message as string);
          this._showErrorModelToast$.next(true);
          this.isLodingModel = false;
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorModelMsg$.next("Error deleting Extend Privilege");
        this.isLodingModel = false;
      }
    });
  }

  async loadUserExtendPrivilegeData() {
    this.isDataLoading = true;
    await this.onboardingService.loadUserExtendPrivilegeList(this.userEntityInfoDto?.orgCode!, this.userEntityInfoDto?.userId!);
    this.isDataLoading = false;
  }

  formattedDesignation(code: string){
    return this.applicationStartupDto?.cacheDesignationsDtos?.find(item => item.code == code);
  }

  openConfirmStatusModal(content: any, userExtendPrivilegeUiDto: UserExtendPrivilegeUiDto) {
    this.selectedUserExtendPrivilegeUiDto = userExtendPrivilegeUiDto;

    this.ngbModal.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  closeModal(){
    this.ngbModal.dismissAll();
  }

  ngOnDestroy(): void {
    if(this.userExtendPrivilegeListSubscription$){
      this.userExtendPrivilegeListSubscription$.unsubscribe();
    }
  }


}
