import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { ResourceSwapDto } from 'src/app/shared/models/questionnaire/ResourceSwapDto';
import { ResourceSwapWrapperDto } from 'src/app/shared/models/questionnaire/ResourceSwapWrapperDto';
import { SectionTemplate } from 'src/app/shared/models/questionnaire/SectionTemplate';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-rfx-questionnaire-technical-section',
  templateUrl: './rfx-questionnaire-technical-section.component.html',
  styleUrls: ['./rfx-questionnaire-technical-section.component.sass']
})
export class RfxQuestionnaireTechnicalSectionComponent implements OnInit, OnDestroy {
  @Input() selectedSectionId?: string;
  @Input() isNewQuestion: boolean = false;

  ctrlSectionTitle: FormControl = new FormControl('', Validators.required)
  ctrlSectionScore: FormControl = new FormControl('')

  sectionTemplateList: SectionTemplate[] = []
  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  selectedSectionTemplate?: SectionTemplate
  selectedRfx?: RfxUiDto;
  selectedRfxSubcategory?: RfxSubcategoryUiDto;
  errorMsg: string | undefined;
  errorMsgForModal: string | undefined;
  isLoading: boolean = false;
  isLoadingForModal: boolean = false;
  sectionScoreValidationFailed: boolean = false;

  technicalModalRef?: NgbModalRef
  deleteConfirmModalRef?: NgbModalRef;
  addSectionModalRef?: NgbModalRef;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToastForModal$ = new BehaviorSubject<boolean>(false);
  _showErrorToastForModal$ = new BehaviorSubject<boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription
  selectedRfxSubcategorySubscription$?: Subscription

  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;
  @ViewChild('newSectionModalTemplate') newSectionModalTemplate?: TemplateRef<any>;

  isTechnicalActive = true;
  isQuestionnaireAvailable = true;
  isSelectOption = true;

  constructor(
    private ngbModal: NgbModal,
    private activeModal: NgbActiveModal,
    private questionnaireService: QuestionnaireService,
    private changeDetectRef: ChangeDetectorRef,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
  ) { }

  ngOnInit(): void {
    this.selectedRfx = this.adminSourcingEventsDataHolderService.selectedRfx;
    this.selectedRfxSubcategorySubscription$ = this.adminRfxSubcategoryDataHolderService.selectedRfxSubcategory$.subscribe(data => {
      if (data) {
        this.selectedRfxSubcategory = data;
      } else {
        this.selectedRfxSubcategory = undefined;
      }
    });

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        this.sectionTemplateList = this.questionnaireWrapperDto?.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL) ?? [];
        this.sectionTemplateList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

        if (this.sectionTemplateList.length == 0) {
          this.isNewQuestion = true;
        }

        if (this.isNewQuestion) {
          this.openNewSectionModal();
        } else {
          this.populateData();
        }

        this.refreshValueChanges();
      }
    })
  }

  populateData() {
    if (this.selectedSectionId) {
      this.selectedSectionTemplate = this.sectionTemplateList.find(m => m.sectionId == this.selectedSectionId);
      this.selectedSectionTemplate = this.selectedSectionTemplate ?? this.sectionTemplateList[0];
    } else {
      this.selectedSectionTemplate = this.sectionTemplateList[0];
      this.selectedSectionId = this.selectedSectionTemplate.sectionId;
    }

    this.isNewQuestion = false;
    this.questionnaireService.setSelectedSection(this.selectedSectionTemplate);

    this.refreshValueChanges();
  }

  openDeleteConfirmModal(content: any) {
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.errorMsg = '';

    this.deleteConfirmModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    })
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }

  refreshValueChanges() {
    this.questionnaireService.runTechQuestionnaireValidation();

    this.sectionScoreValidationFailed = this.questionnaireService.checkSectionScoreValidation(SourcingEnvelopeType.TECHNICAL);

    this.changeDetectRef.detectChanges();
  }

  resetSectionTemplate() {
    this.ctrlSectionTitle.reset();
    this.ctrlSectionScore.reset();
  }

  changeSection(sectionTemplate: SectionTemplate) {
    this.resetSectionTemplate();
    this.selectedSectionTemplate = sectionTemplate;
    this.selectedSectionId = sectionTemplate.sectionId;
    this.populateData();
  }

  openNewSectionModal(isEdit?: boolean) {
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    this.errorMsg = '';

    this.changeDetectRef.detectChanges();
    this.resetSectionTemplate();

    if (isEdit && this.selectedSectionTemplate) {
      this.ctrlSectionTitle.patchValue(this.selectedSectionTemplate.sectionName);
      this.ctrlSectionTitle.updateValueAndValidity();

      if (this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES') {
        this.ctrlSectionScore.setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
        this.ctrlSectionScore.patchValue(this.selectedSectionTemplate.sectionScore);
      } else {
        this.ctrlSectionScore.clearValidators();
      }
      this.ctrlSectionScore.updateValueAndValidity();
    } else {
      this.isNewQuestion = true;
    }

    if (this.questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate == 'YES') {
      this.ctrlSectionScore.setValidators([Validators.required, Validators.pattern(Pattern.numberGreaterZero)]);
      this.ctrlSectionScore.updateValueAndValidity();
    }

    this.addSectionModalRef = this.ngbModal.open(this.newSectionModalTemplate, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    })

    this.addSectionModalRef.result.then(() => {
      this.isNewQuestion = false;
      this.populateData();
    }).catch(() => {
      this.isNewQuestion = false;
      this.populateData();
    })
  }

  mergeSectionTemplate() {
    let sectionTemplate = new SectionTemplate();

    if (this.isNewQuestion) {
      let sequenceNo = this.sectionTemplateList.length;
      let preSequenceText = ApplicationUtils.alphabetsList()[sequenceNo];

      sectionTemplate.sectionId = UUID.UUID().toString();
      sectionTemplate.sequenceNo = `${sequenceNo + 1}`;
      sectionTemplate.preSequenceText = preSequenceText;
    } else {
      let tempSectionTemplate = this.sectionTemplateList.find(item => item.sectionId == this.selectedSectionTemplate?.sectionId);
      sectionTemplate = ApplicationUtils.clone(tempSectionTemplate) as SectionTemplate;
    }

    sectionTemplate.orgCode = this.selectedRfxSubcategory?.orgCode;
    sectionTemplate.rfxId = this.selectedRfxSubcategory?.rfxId;
    sectionTemplate.rfxSubcategoryId = this.selectedRfxSubcategory?.subcategoryId;
    sectionTemplate.auctionId = 'NA';

    sectionTemplate.envelopeType = SourcingEnvelopeType.TECHNICAL;
    sectionTemplate.questionnaireId = this.questionnaireWrapperDto?.questionnaireTemplate?.templateId;
    sectionTemplate.templateDescription = '';

    sectionTemplate.sectionName = this.ctrlSectionTitle.value.trim();
    sectionTemplate.sectionScore = this.ctrlSectionScore.value;

    return sectionTemplate;
  }

  saveSectionTemplate() {
    if (this.ctrlSectionTitle.invalid || this.ctrlSectionScore.invalid) {
      return;
    }

    console.log(this.ctrlSectionTitle.value);

    this._showErrorToastForModal$.next(false);
    this.errorMsg = "";
    this.isLoadingForModal = true;

    let sectionTemplate = this.mergeSectionTemplate();

    this.questionnaireService.saveSectionTemplate(sectionTemplate).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToastForModal$.next(true);
          this.isLoadingForModal = false;
          this.isNewQuestion = false;

          this.selectedSectionTemplate = sectionTemplate;
          this.selectedSectionId = sectionTemplate.sectionId;          

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          setTimeout(() => {
            this._showSuccessToastForModal$.next(false);
            this.closeModal(this.addSectionModalRef);
            this.resetSectionTemplate();
          }, 2000)

        } else {
          this.isLoadingForModal = false;
          this._showErrorToastForModal$.next(true);
          this.errorMsg = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToastForModal$.next(true);
        this.errorMsg = "Error while saving sections. Try again.";
      }
    })
  }

  deleteSectionTemplate() {
    this._showErrorToastForModal$.next(false);
    this.errorMsgForModal = "";
    this.isLoadingForModal = true;

    this.questionnaireService.deleteSection(this.selectedSectionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToastForModal$.next(true);
          this.isLoadingForModal = false;

          this.resetSectionTemplate();
          this.selectedSectionId = undefined;

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);

          setTimeout(() => {
            this._showSuccessToastForModal$.next(false);
            this.closeModal(this.deleteConfirmModalRef!);
          }, 2000)

        } else {
          this.isLoadingForModal = false;
          this._showErrorToastForModal$.next(true);
          this.errorMsgForModal = apiResponseDto?.message;
        }
      },
      error: (err) => {
        console.error(err);
        this.isLoadingForModal = false;
        this._showErrorToastForModal$.next(true);
        this.errorMsgForModal = "Error while deleting question. Try again.";
      }
    })
  }

  handleSectionOrders() {
    let resourceSwapDtoList: ResourceSwapDto[] = [];

    this.sectionTemplateList.forEach((item, index) => {
      let resourceSwapDto = new ResourceSwapDto();
      resourceSwapDto.resourceId = item.sectionId;
      resourceSwapDto.sequenceNo = `${index + 1}`;
      resourceSwapDto.pretext = ApplicationUtils.alphabetsList()[index];
      resourceSwapDtoList.push(resourceSwapDto);
    })

    let resourceSwapWrapperDto = new ResourceSwapWrapperDto();
    resourceSwapWrapperDto.sourcingEnvelopeType = SourcingEnvelopeType.TECHNICAL;
    resourceSwapWrapperDto.resourceSwapDtoList = resourceSwapDtoList;

    this.appLoader?.openLoaderIcon('SECTION_REORDER', 'Reordering..');
    this.questionnaireService.updateSectionOrdering(resourceSwapWrapperDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.appLoader?.closeLoaderIcon('SECTION_REORDER');

          let questionnaireWrapperDto = apiResponseDto.data as QuestionnaireWrapperDto;
          this.questionnaireService.updateQuestionnaireWrapperDto(questionnaireWrapperDto);
        } else {
          this.appLoader?.closeLoaderIcon('SECTION_REORDER');
        }
      },
      error: (err) => {
        console.error(err);
        this.appLoader?.closeLoaderIcon('SECTION_REORDER');
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    let previousItem = this.sectionTemplateList[event.previousIndex];
    let currentItem = this.sectionTemplateList[event.currentIndex];

    if (!previousItem.sectionName || !currentItem.sectionName) {
      return;
    }

    moveItemInArray(this.sectionTemplateList, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.handleSectionOrders();
    }
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
    if (this.selectedRfxSubcategorySubscription$) {
      this.selectedRfxSubcategorySubscription$.unsubscribe();
    }
  }
}
