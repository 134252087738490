import { Component } from '@angular/core';

@Component({
  selector: 'app-creator-multi-lot',
  templateUrl: './creator-multi-lot.component.html',
  styleUrls: ['./creator-multi-lot.component.sass']
})
export class CreatorMultiLotComponent {

}
