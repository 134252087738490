import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AdminLotsDataHolderService } from '../../../shared/services/adminLotsDataHolderService.service';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuctionValidationErrorCodeDto } from 'src/app/shared/models/user/AuctionValidationErrorCodeDto';
import { AuctionLotSaveWrapperDto } from 'src/app/shared/models/user/AuctionLotSaveWrapperDto';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { AuctionValidationService } from 'src/app/shared/services/auction-validation.service';
import { CategoryDto } from 'src/app/shared/models/CategoryDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { TreeSelectionMode } from 'src/app/shared/enums/TreeSelectionMode';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';

@Component({
  selector: 'app-auction-lot-category',
  templateUrl: './auction-lot-category.component.html',
  styleUrls: ['./auction-lot-category.component.sass']
})
export class AuctionLotCategoryComponent implements OnInit, OnDestroy {

  auctionLotEntityDto?: AuctionLotEntityDto;
  auctionEntityDto?: AuctionEntityDto;

  highlights?: Array<string>;
  isLoading?: boolean;
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);

  auctionValidationErrorCodeDtoList?: AuctionValidationErrorCodeDto[];

  TreeSelectionMode: typeof TreeSelectionMode = TreeSelectionMode;
  selectedPreferenceCategory?: PreferenceCategoryDto;

  formGroup: FormGroup;
  selectedLotSubscription$?: Subscription;

  separatorKeyCodes = [32, 13, 188, 51]
  inputTagPlaceholder = "Add tag and click enter"
  pasteSplitPattern = /#| #| | ,|,/g

  isShowMoreHighlights: boolean = false;
  isShowMoreHashtag: boolean = false;

  @ViewChild('highlightRef') highlightRef?: ElementRef<HTMLParagraphElement>

  @ViewChild('hashtagRef') hashtagRef?: ElementRef<HTMLParagraphElement>

  constructor(
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    private auctionLotService: AdminLotsDataHolderService,
    private adminDashboardService: AdminDashboardService,
    private validationService: AuctionValidationService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
  ) {
    this.formGroup = this.formBuilder.group({
      hashtags: new FormControl([]),
      highlights: new FormControl('')

    })
  }

  hashtag = [
    "hashtag1", "hashtag1"
  ]


  get fc(): any { return this.formGroup.controls; }

  ngOnInit(): void {
    this.auctionEntityDto = this.adminSourcingEventsDataHolderService._selectedAuction$.value!;

    this.selectedLotSubscription$ = this.auctionLotService._selectedAuctionLot$.subscribe(lot => {
      this.auctionLotEntityDto = lot!;

      if (this.auctionLotEntityDto) {
        this.populateInitialData();
      }

    })
  }


  populateInitialData() {
    this.formGroup.reset();

    this.formGroup.controls['hashtags'].patchValue(this.auctionLotEntityDto?.hashtags);

    if (this.auctionLotEntityDto?.highlights && this.auctionLotEntityDto?.highlights!.length > 0) {
      let highlights = this.auctionLotEntityDto.highlights.toString().replaceAll(',', '\n')
      this.formGroup.controls['highlights'].patchValue(highlights);
    } else {
      this.formGroup.controls['highlights'].patchValue(null);
    }

    this.formGroup.updateValueAndValidity()
  }

  showMoreHighlights() {
    this.isShowMoreHighlights = !this.isShowMoreHighlights;
    if (!this.isShowMoreHighlights) {
      this.highlightRef!.nativeElement.style.height = '120px'
    } else {
      this.highlightRef!.nativeElement.style.height = this.highlightRef!.nativeElement.scrollHeight + 'px'
    }
  }

  ShowMoreHashtag() {
    this.isShowMoreHashtag = !this.isShowMoreHashtag;
    if (!this.isShowMoreHashtag) {
      this.hashtagRef!.nativeElement.style.height = '120px'
    } else {
      this.hashtagRef!.nativeElement.style.height = this.hashtagRef!.nativeElement.scrollHeight + 'px'
    }
  }

  onSelectedCategory(preferenceCategory: PreferenceCategoryDto) {
    this.selectedPreferenceCategory = preferenceCategory;
  }

  margeAuctionLotEntityDto() {
    let auctionLotEntityDto = ApplicationUtils.clone(this.auctionLotEntityDto) as AuctionLotEntityDto;
    auctionLotEntityDto.preferenceCategory = this.selectedPreferenceCategory;
    if (this.formGroup.controls['hashtags'].value) {
      auctionLotEntityDto!.hashtags = this.formGroup.controls['hashtags'].value;
    } else {
      auctionLotEntityDto!.hashtags = [];
    }

    if (this.formGroup.controls['highlights'].value) {
      auctionLotEntityDto!.highlights = (this.formGroup.controls['highlights'].value as string).split('\n');
    } else {
      auctionLotEntityDto!.highlights = [];
    }

    return auctionLotEntityDto;
  }


  openlotDetailsTwo(content: any) {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this._errorMsg$.next('');

    this.populateInitialData();
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
  }

  closeModal() {
    this.formGroup.reset();
    this.ngbModal.dismissAll()
  }


  handleValidSubmit() {
    if (!this.isAllowToEdit()) {
      return;
    }

    let hashtag = this.formGroup.controls['hashtags'].value
    console.log(hashtag);

    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.auctionValidationErrorCodeDtoList = [];

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }


    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    let auctionLotDto = this.margeAuctionLotEntityDto();
    this.auctionLotService.saveAuctionLotDetails(auctionLotDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let auctionLotSaveWrapperDto = apiResponseDto.data as AuctionLotSaveWrapperDto;
          let auctionValidationErrorCodeDtoList = auctionLotSaveWrapperDto.auctionValidationErrorCodeDtos;

          if (!auctionValidationErrorCodeDtoList || auctionValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._isSaveButtonEnable$.next(true);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLoading = false;
            this._isSaveButtonEnable$.next(true);
            this.closeModal();

            this.auctionValidationErrorCodeDtoList = auctionValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving lot. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);

      }
    })
  }

  ngOnDestroy(): void {
    if (this.selectedLotSubscription$) {
      this.selectedLotSubscription$.unsubscribe();
    }
  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.auctionValidationErrorCodeDtoList;
  }

  haveLotCategoryErrors() {
    if (this.auctionLotEntityDto) {
      return this.validationService.haveLotCategoryErrors(this.auctionLotEntityDto!)
    }
    return false;
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT && !this.auctionEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.auctionEntityDto?.status == AuctionStatus.LIVE && !this.auctionEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}
