<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>Login</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3">
      <div class="d-flex align-items-center mb-3">
        <span>New User?&nbsp;</span>
      </div>
      <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
        <div class="form-floating mb-3">
          <input type="email" class="form-control"  id="emailId" formControlName="emailId"  placeholder="name@example.com"
          [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && formGroup.controls['emailId'].invalid}" />

          <label for="emailId">Email address</label>
          <div >
            <p class="text-red-500 "
            *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['emailId'].invalid  && (!this.formGroup.controls['emailId'].value)">
            Required</p>
          <p class="text-red-500 "
            *ngIf="(_validationErrorPresent$ |async) && this.formGroup.controls['emailId'].invalid  && (this.formGroup.controls['emailId'].value)">
            Invalid email address</p>
          </div>
        </div>
        <div class="div-class my-2 text-start">
          <a class="link-class" (click)="showOtpVerifyScreen()">Login with one time password (OTP)</a>
        </div>
        <div class="input-group">
          <div class="form-floating">
            <input #password [type]="isPasswordVisible ? 'text' : 'password'" class="form-control border-end-0" id="password" formControlName="password" placeholder="name@example.com"
            [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && formGroup.controls['password'].invalid}">
            <label for="password">Password</label>
          </div>
          <span class="input-group-text bg-white border-start-0" [ngClass]="{'focus-border': (_isPasswordFocused$|async)}" (click)="togglePasswordVisibility()">
            <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer" *ngIf="!isPasswordVisible">
            <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer" *ngIf="isPasswordVisible">
          </span>
        </div>
        <div class="mb-3">
          <p class="text-red-500 "
            *ngIf="(_validationErrorPresent$ |async) &&  this.formGroup.controls['password'].invalid &&  (!this.formGroup.controls['password'].value)">
            Required</p>
          <p class="text-red-500 "
            *ngIf="(_validationErrorPresent$ |async) &&  this.formGroup.controls['password'].invalid &&  (this.formGroup.controls['password'].value)">
            Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
            one number and one special character.</p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="authService.errorMsg" [isError]="true" [message]="authService.errorMsg"></app-message-toast>
        </div>

        <div class="d-flex align-items-center mb-0 justify-content-end mb-3">
          <button type="button" class="btn btn-link" (click)="openForgotPasswordModal(forgotPassword)">Forgot Password</button>
        </div>
        <div class="d-grid gap-2 mb-3">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="handleValidSubmit()">LOGIN</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- <div class="my-3">
  <header class="border-bottom">
   <div class="col-12 px-3">
    <div class="row bg-primary"></div>
    <div class="row">
      <div class="col-9">
        <h6>LOGIN</h6>
      </div>
      <div class="col-3 text-end">
        <button class="btn btn-dark btn-sm border-0">
          <span class="material-symbols-outlined" (click)="closeModal()">
            close</span>
        </button>
      </div>
    </div>
   </div>
  </header>
  <div class="e-popupmain">
    <div class="e-maintext my-3">
      <p>Not registered? Please <a class="ea-popupremove ea-popupload">register
          to bid</a>.</p>
      <div class="e-field ea-valid-required ea-valid-email"><label>Email</label>
        <div class="e-fieldtext"><input name="" type="text" /></div>
      </div>
      <div class="e-field ea-valid-required ea-valid-password"><label>Password</label>
        <div class="e-fieldtext"><input name="" type="password" />
        </div>
      </div>
      <div class=" w-100 text-end my-3">
        <button class="btn btn-sm btn-outline-primary me-2" (click)="closeModal()">CANCEL</button>
        <a class="btn btn-sm btn-dark">Login</a>
      </div>
      <p><a class="" (click)="openModal(forgotPassword)">Forgotten Password</a></p>
    </div>
  </div>
</div>-->
<ng-template #forgotPassword>
  <app-user-password-reset></app-user-password-reset>
</ng-template>

<!-- Templates -->
<ng-template #messageDialog>
  <app-message-dialog></app-message-dialog>
</ng-template>

<app-loader #appLoader></app-loader>
