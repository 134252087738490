import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';

@Component({
  selector: 'app-auction-creator-registration',
  templateUrl: './auction-creator-registration.component.html',
  styleUrls: ['./auction-creator-registration.component.sass']
})
export class AuctionCreatorRegistrationComponent implements OnInit, OnDestroy {
  allRegistrationsFromFirebase: number = 0;
  allRegistrationsFromServer: number = 0;
  newRegistrations: number = 0;

  isShowNewRegistrationAlert: boolean = false;

  auctionEntityDto?: AuctionEntityDto;

  selectedAuctionSubscription$?: Subscription
  auctionBidderRegistrationSubscription$?: Subscription;

  constructor(
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminService: AdminDashboardService
  ) { }

  ngOnInit(): void {
    this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe((data) => {
      if (data) {
        this.auctionEntityDto = data;
      }
    })

    this.auctionBidderRegistrationSubscription$ = this.adminService.getAuctionBidderRegistrationDtos$.subscribe(data => {
      if (data && data.length > 0) {
        this.allRegistrationsFromServer = data.length;
        this.checkNewRegistration();
      }
    })

    this.adminService.getAllUserAuctionRegistrationsList$.subscribe((data) => {
      if (data && data.length > 0) {
        let allRegistrationsOfAuction = data.filter(item => item.auctionId == this.auctionEntityDto?.auctionId);
        this.allRegistrationsFromFirebase = allRegistrationsOfAuction.length;
        this.checkNewRegistration();
      }
    })
  }

  refreshRegistrationData() {
    this.isShowNewRegistrationAlert = false;
    this.adminService.loadBidderRegistrationList(this.auctionEntityDto?.auctionId!);
  }

  checkNewRegistration() {
    if (this.allRegistrationsFromServer && this.allRegistrationsFromFirebase) {
      if (this.allRegistrationsFromServer != this.allRegistrationsFromFirebase) {
        this.newRegistrations = this.allRegistrationsFromFirebase - this.allRegistrationsFromServer;
        this.isShowNewRegistrationAlert = true;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
    if (this.auctionBidderRegistrationSubscription$) {
      this.auctionBidderRegistrationSubscription$.unsubscribe();
    }

    this.allRegistrationsFromFirebase = 0;
    this.allRegistrationsFromServer = 0;
    this.newRegistrations = 0;
  }
}
