export class EmailTemplateDto {
  documentId?: string;
  templateId?: string;
  templateName?: string;
  orgCode?: string;
  subject?: string;
  body?: string;
  enable: boolean = true;
  description?: string;
  showEnableToggle: boolean = false;
  applicationSpecific?: boolean;
}
