import { Timestamp } from "@angular/fire/firestore";
import { FileInfoDto } from "./FileInfoDto";

export class CatalogueL2EntityDto {
  categoryL1Id?: string;
  categoryL2Id?: string;
  name?: string;
  fileInfoDto?: FileInfoDto;
  createdDate?: string;
}
