import { CategoryDto } from "./CategoryDto";
import { Currency } from "./Currency";
import { FeaturesDto } from "./FeaturesDto";
import { TimeZoneDto } from "./TimeZoneDto";

export class MasterDataDto {
  auctionFeatures: Array<FeaturesDto> = [];
  currencies: Array<Currency> = [];
  timezones: Array<TimeZoneDto> = [];
  categories: Array<CategoryDto> = [];
}