import { RfxSubcategorySaveWrapperDto } from 'src/app/shared/models/user/RfxSubcategorySaveWrapperDto';
import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct, NgbDropdown, NgbModal, NgbModalRef, NgbPopover, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';

import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { DatePipe } from '@angular/common';
import { FileService } from 'src/app/shared/services/file.service';
import { AngularEditorConfig } from 'src/assets/plugins/angular-editor/public-api';
import { AuctionValidationService } from 'src/app/shared/services/auction-validation.service';
import { CategoryDto } from 'src/app/shared/models/CategoryDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { RfxValidationErrorCodeDto } from 'src/app/shared/models/user/RfxValidationErrorCodeDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { TreeSelectionMode } from 'src/app/shared/enums/TreeSelectionMode';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';
@Component({
  selector: 'app-add-rfx-bulk-subcategory-upload',
  templateUrl: './add-rfx-bulk-subcategory-upload.component.html',
  styleUrls: ['./add-rfx-bulk-subcategory-upload.component.sass']
})
export class AddRfxBulkSubcategoryUploadComponent implements OnInit, AfterContentInit,  OnDestroy {
  @Input() selectedRfx$ = new Observable<RfxUiDto | null>()
  @Input() selectedRfxSubcategory$ = new Observable<RfxSubcategoryUiDto | null>()

  rfxEntityDto?: RfxUiDto
  rfxSubcategoryEntityDto?: RfxSubcategoryUiDto
  rfxValidationErrorCodeDtoList?: RfxValidationErrorCodeDto[];

  imageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/heif'];
  videoTypes = ['mp4', 'webm', 'ogg'];
  fileUploadError: boolean = false;
  isLoading: boolean = false
  isUploading: boolean = false

  separatorKeyCodes = [32, 13, 188, 51]
  inputTagPlaceholder = "Add tag and click enter"
  selectedCategoryName?: string
  selectedSubCategoryName?: string
  activeImage?: number;
  incrementBidValue?: string;

  bidDetailsModalRef?: NgbModalRef

  formGroup: FormGroup
  ctrlImageModalFileType: FormControl = new FormControl('IMAGE');

  TreeSelectionMode: typeof TreeSelectionMode = TreeSelectionMode;
  selectedPreferenceCategory?: PreferenceCategoryDto;

  _errorMsg$ = new BehaviorSubject<string>("")
  _showErrorToast$ = new BehaviorSubject<boolean>(false)
  _showSuccessToast$ = new BehaviorSubject<boolean>(false)
  _showImagesErrorToast$ = new BehaviorSubject<boolean>(false)

  endTime?: NgbTimeStruct;
  ngbPopover?: NgbPopover;
  isSubmitted?: boolean = false;


  minDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  }


  constructor(
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private adminService: AdminDashboardService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private fileService: FileService,
    private validationService: AuctionValidationService,
  ) {
    this.formGroup = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      endDate: ['', Validators.required],
      endTime: ['', Validators.required],
      category: ['', Validators.required],
      subCategory: ['', Validators.required],
      subcategoryLevelOne: ['', Validators.required],
      hashtags: [[]],
      highlights: [''],
      contractPeriod: [0],
      emdValue: [''],
      estimatedValue: [''],
      targetedBidders: [null],
    })

  }


  ngOnInit(): void {

  }


  ngAfterContentInit(): void {
    this.selectedRfx$.subscribe(data => {
      if (data) {
        this.rfxEntityDto = data
      }
    })

    this.selectedRfxSubcategory$.subscribe(data => {
      if (data) {
        this.rfxSubcategoryEntityDto = ApplicationUtils.clone(data);
        this.populateInitialData();
      }
    })

    this.formGroup.controls['endDate'].valueChanges.subscribe((val) => {
      if (val) {
        this.checkEndTimeValidation();
      }
    })
  }

  formatEmdValue() {
    let emdValue = this.formGroup.controls['emdValue'].value;
    let emdValueFinal = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, emdValue);
    this.fc['emdValue'].patchValue(emdValueFinal);
  }

  formatEstimatedValue() {
    let estimatedValue = this.formGroup.controls['estimatedValue'].value;
    let estimatedValueFinal = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, estimatedValue);
    this.fc['estimatedValue'].patchValue(estimatedValueFinal);
  }


  invalidPriceValidator(item: AbstractControl | null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }

  get fc(): any { return this.formGroup.controls; }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close()
    } else {
      this.activeModal.close()
    }
  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.rfxValidationErrorCodeDtoList;
  }


  openTimepicker(p: NgbPopover, template: any) {
    if (this.ngbPopover?.isOpen) this.ngbPopover.close();
    if (!p.isOpen()) {
      this.ngbPopover = p;
      p.ngbPopover = template;
      p.open();
    } else {
      p.close();
    }
  }

  setTime(time: string) {
    if (time == 'endTime') {
      if (this.endTime?.hour != null && this.endTime?.minute != null && this.endTime?.second != null) {
        let convertedTime = ApplicationUtils.getTimeFromNgTimePicker(this.datePipe, this.endTime);
        this.formGroup.controls?.['endTime'].patchValue(convertedTime);
      }
    }
    if (this.ngbPopover?.isOpen()) this.ngbPopover?.close();
    this.formGroup.updateValueAndValidity();

    this.checkEndTimeValidation()
  }

  checkEndTimeHTMLValidation() {
    if (this.formGroup.controls['endDate'].value && this.formGroup.controls['endTime'].value) {
      let endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value);
      let endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);
      if (endDate && endTime) {
        let _sDate = ApplicationUtils.convertedDate(this.rfxEntityDto?.startDate!, this.rfxEntityDto?.startTime!);
        let _eDate = ApplicationUtils.convertedDate(endDate, endTime);

        let currentDate = new Date();

        if (_sDate && _eDate && (_sDate >= _eDate || currentDate >= _eDate)) {
          return true;
        }
      }
      return false;
    }
    return true;
  }


  haveAnyErrorInEndDate(){
    if(this.checkEndTimeHTMLValidation() && !this.rfxSubcategoryEntityDto?.active){
      return true
    }
    return false
  }

  checkEndTimeValidation() {
    if (this.formGroup.controls['endDate'].value && this.formGroup.controls['endTime'].value) {
      let endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value);
      let endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);
      if (endDate && endTime) {
        let _sDate = ApplicationUtils.convertedDate(this.rfxEntityDto?.startDate!, this.rfxEntityDto?.startTime!);
        let _eDate = ApplicationUtils.convertedDate(endDate, endTime);

        let currentDate = new Date();

        if (_sDate && _eDate && (_sDate >= _eDate || currentDate >= _eDate)) {
          this._showErrorToast$.next(true);
          if (currentDate >= _eDate) {
            this._errorMsg$.next("THE Subcategory END DATE/TIME MUST BE AFTER THE CURRENT DATE/TIME");
          } else {
            this._errorMsg$.next("THE Subcategory END DATE/TIME MUST BE AFTER THE START DATE/TIME");
          }
          return true;
        } else {
          this._showErrorToast$.next(false);
          this._errorMsg$.next("");
        }
      }
      return false;
    }
    return true;
  }

  populateInitialData() {
    this.isSubmitted = false;
    this.formGroup.reset()
    this.formGroup.controls['title'].setValue(this.rfxSubcategoryEntityDto?.title);
    this.formGroup.controls['description'].setValue(this.rfxSubcategoryEntityDto?.description);

    this.populateSubcategoryBidDetails();

    if(this.rfxSubcategoryEntityDto?.endTime){
      let endDate = ApplicationUtils.getNgbPickerDate(this.rfxSubcategoryEntityDto?.endDate!);
      this.formGroup.controls['endDate']?.patchValue(endDate);
    }
    let currentDate = new Date();

    if (this.rfxSubcategoryEntityDto?.endTime) {
      this.endTime = ApplicationUtils.getNgbPickerTime(this.rfxSubcategoryEntityDto?.endTime!);
      let endTime12h = ApplicationUtils.convertTime24to12(this.rfxSubcategoryEntityDto?.endTime!, this.datePipe);
      this.formGroup.controls['endTime']?.patchValue(endTime12h);
    } else {
      this.endTime = ApplicationUtils.getNgbPickerTime(`${currentDate.getHours()}:${currentDate.getMinutes()}`);
    }

    this.formGroup.updateValueAndValidity()
  }

  showReservedPrice(): boolean {
    return this.rfxSubcategoryEntityDto?.subcategoryType == "Reserve";
  }

  populateSubcategoryBidDetails() {
  // Populate data in Form
  this.formGroup.patchValue(this.rfxSubcategoryEntityDto!);

  // contractPeriod
  if (this.rfxSubcategoryEntityDto?.contractPeriod) {
    let contractPeriod = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, this.rfxSubcategoryEntityDto?.contractPeriod);
    this.formGroup.get('contractPeriod')?.patchValue(contractPeriod);
  }

  if (this.rfxSubcategoryEntityDto?.emdValue) {
    let emdValue = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, this.rfxSubcategoryEntityDto?.emdValue);
    this.formGroup.get('emdValue')?.patchValue(emdValue);
  }else{
    this.formGroup.get('emdValue')?.patchValue('0');
  }

  this.formGroup.get('targetedBidders')?.patchValue(this.rfxSubcategoryEntityDto?.targetedEvent)

  this.formGroup.updateValueAndValidity();
  }

  onSelectedCategory(preferenceCategory: PreferenceCategoryDto) {
    this.selectedPreferenceCategory = preferenceCategory;
  }

  margeRfxSubcategoryEntityDto(): RfxSubcategoryUiDto {
    let formValue = this.formGroup.getRawValue();

    let rfxSubcategoryEntityDto = ApplicationUtils.clone(this.rfxSubcategoryEntityDto) as RfxSubcategoryUiDto;
    rfxSubcategoryEntityDto.title = this.formGroup.controls['title'].value;
    rfxSubcategoryEntityDto.description = this.formGroup.controls['description'].value;
    rfxSubcategoryEntityDto.endDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, this.formGroup.controls['endDate'].value)!;
    rfxSubcategoryEntityDto.endTime = ApplicationUtils.convertTime12to24(this.formGroup.controls['endTime'].value);

    rfxSubcategoryEntityDto.preferenceCategory = this.selectedPreferenceCategory;

    rfxSubcategoryEntityDto.contractPeriod = this.formGroup.controls['contractPeriod'].value;
    rfxSubcategoryEntityDto.emdValue = ApplicationUtils.resetPriceFormat(this.formGroup.controls['emdValue'].value);
    // rfxSubcategoryEntityDto.targetedBidders = this.formGroup.controls['targetedBidders'].value;

    return rfxSubcategoryEntityDto;
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '3rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '2',
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode'
      ]
    ],
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Page Divider',
        class: 'break',
        tag: 'div',
      },
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'hr',
      },
    ],
  };

  handleValidSubmit() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.isSubmitted = true;
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.rfxValidationErrorCodeDtoList = [];


    if (this.formGroup.invalid) {
      this._errorMsg$.next("Please Fill Required Fields");
      this._showErrorToast$.next(true);
      this.formGroup.markAllAsTouched();

      return;
    }

    if (this.checkEndTimeValidation()) {
      return;
    };

    let rfxSubcategoryEntityDto = this.margeRfxSubcategoryEntityDto();

    this.isLoading = true;

    this.adminRfxSubcategoryDataHolderService.saveRfxSubcategoryDetails(rfxSubcategoryEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let rfxSubcategorySaveWrapperDto = apiResponseDto.data as RfxSubcategorySaveWrapperDto;

          if (rfxSubcategorySaveWrapperDto && (!(rfxSubcategorySaveWrapperDto?.rfxValidationErrorCodeDtos) || rfxSubcategorySaveWrapperDto?.rfxValidationErrorCodeDtos!.length == 0)) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLoading = false;
            this.closeModal();
            this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving Subcategory. Try again.");
        this.isLoading = false;
      }
    })
  }


  ngOnDestroy(): void {
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT && !this.rfxEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.rfxEntityDto?.status == RfxStatus.LIVE && !this.rfxEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}

