<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>AWARDED LOTS</h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="d-grid gap-2 mb-3" *ngIf="invoiceService.isLoading">
    <div class="e-spinner"></div>
  </div>
    <div class="model-body-top">
      <div class="m-3" *ngIf="!invoiceService.isLoading">
        <div class="row">
          <div class="col-md-4 border-end" [formGroup]="formGroup">
            <div class="form-floating mb-3">
              <input ngbDatepicker type="text" class="form-control" placeholder="Invoice Date" formControlName="invoiceDate" id="invoiceDate"
                #invoiceDate="ngbDatepicker" (click)="invoiceDate.toggle()">
              <label for="invoiceDate">Invoice Date</label>
              <div *ngIf="fc['invoiceDate'].invalid && (fc['invoiceDate'].dirty || fc['invoiceDate'].touched)">
                <p class="text-red-500 " *ngIf="fc['invoiceDate'].errors?.['required']">Required</p>
              </div>
            </div>

            <hr class="hr" />

            <p class="text-area-label my-2 fw-bold">Winning Lot(s)</p>
            <span class="fs-small mb-1" *ngIf="awardedBidderDto.awardedLots!.length > 1">
              (Uncheck lot(s) if you do not wish to generate an invoice for them.)
            </span>
            <div class="scroll-list mb-2 border rounded p-2 mt-1">
              <div class="form-check me-3 mb-2 ps-0" *ngFor="let item of awardedBidderDto?.awardedLots; index as i;">
                <input class="form-check-input checkbox-class" type="checkbox" [checked]="isLotSelected(item?.lotId)"
                  (change)="toggleLotChecked(item?.lotId)" *ngIf="awardedBidderDto.awardedLots!.length > 1" id="checkbox1">
                <label class="form-check-label ms-2 span-class ellipsis">
                  <span class="span-class"> #{{item?.lotSequenceNo}} {{item?.lotName}}</span>
                </label>
              </div>
            </div>

            <div class="form-floating mb-2">
              <input type="text" class="form-control" placeholder="Invoice To" formControlName="invoiceTo" id="invoiceTo">
              <label for="invoiceTo">Invoice To</label>
              <div *ngIf="fc['invoiceTo'].invalid && (fc['invoiceTo'].dirty || fc['invoiceTo'].touched)">
                <p class="text-red-500 " *ngIf="fc['invoiceTo'].errors?.['required']">Required</p>
              </div>
            </div>

            <div class="mb-3">
              <label for="additionDetails" class="text-area-label">Additional Details
              </label>
              <div class="form-group">
                <textarea class="form-control" rows="2" formControlName="additionDetails" id="additionDetails"></textarea>
              </div>
            </div>
            <div class="mb-3">
              <label for="termsAndCondition" class="text-area-label">Terms & Conditions
              </label>
              <div class="form-group">
                <textarea class="form-control" rows="2" formControlName="termsAndCondition" id="termsAndCondition"></textarea>
              </div>
            </div>
            <div class="form-check me-3">
              <input class="form-check-input checkbox-class" type="checkbox" formControlName="qrCode" id="qrCode">
              <label class="form-check-label ms-2 span-class" for="qrCode">
                <span class="span-class"> Generate QR</span>
              </label>
            </div>
          </div>
          <div class="col-md-8 ">
            <div class="scroll-pdf">
              <app-invoice-preview [invoiceRequestDto$]="invoiceRequestDto$"
                [awardedBidder]="awardedBidderDto" [selectedAuction]="selectedAuction"></app-invoice-preview>
            </div>

            <div class="row">
              <div class="col-6 mt-3" style="margin: auto;">
                <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
                  <div class="e-spinner"></div>
                </div>

                <div class="my-3" >
                  <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
                  <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="_errorMsg$ |async"></app-message-toast>
                </div>
              </div>

              <div class="col-12 mb-2 text-end pe-5" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
                <button type="button" class="btn btn-outline-primary btn-sm  me-3 button-class " (click)="downloadInvoice()">DOWNLOAD</button>
                <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 button-class  " (click)="sendInvoice()">SEND MAIL</button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

</div>
