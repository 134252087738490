import { AdminUserPrivilege } from "../../enums/AdminUserPrivilege";

export class UserExtendPrivilegeUiDto {
  orgCode?: string;
  companyCode?: string;
  companyName?: string;
  plantName?: string;
  plantCode?: string;
  primary?: boolean;
  userPrivilegeList?: Array<AdminUserPrivilege>;
  userId?: string;
  emailId?: string;
  designation?: string;
}
