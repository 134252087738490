import { BidCalculationMethodEnum } from "../../enums/BidCalculationMethodEnum";
import { BidIncrementalDto } from "./BidIncrementalDto";

export class BidIncrementalWrapperDto {
  incrementalBidType?: string = 'SINGLE';   // 'SINGLE', 'MULTIPLE'
  constantIncrement?: number;
  bidIncrementalDtoList?: Array<BidIncrementalDto>;
  bidCalculationMethodEnum?: BidCalculationMethodEnum;   // Absolute/Percentage
  constantPercentage?: number

  static parseBidIncrementalWrapperDto(data: any): BidIncrementalWrapperDto {
    let bidIncrementalWrapperDto = new BidIncrementalWrapperDto();
    
    if (data) {
      bidIncrementalWrapperDto.incrementalBidType = data.incrementalBidType ?? 'SINGLE';
      bidIncrementalWrapperDto.bidCalculationMethodEnum = data.bidCalculationMethodEnum;
      bidIncrementalWrapperDto.constantPercentage = data.constantPercentage;

      if (data.incrementalBidType == 'SINGLE') {
        bidIncrementalWrapperDto.constantIncrement = Number(data.constantIncrement);
        bidIncrementalWrapperDto.bidIncrementalDtoList = undefined;
      } else {
        bidIncrementalWrapperDto.constantIncrement = undefined;
        bidIncrementalWrapperDto.bidIncrementalDtoList = data.bidIncrementalDtoList.map((item: any) => BidIncrementalDto.parseBidIncrementalDto(item))
      }
    }

    return bidIncrementalWrapperDto;
  }
}