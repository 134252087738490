<div class="">
  <div class="">
    <div class="row ">
      <div class="col-md-7 mb-2">
        <h5 class="heading-h5">BIDDER LIST</h5>
      </div>
      <div class="col-md-5 mb-3 text-end">
        <div class="row justify-content-md-end align-items-center">
          <div class="col-2">
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="refreshData()">
              <img src="assets/icons/refresh_dark.svg" class=" c-pointer svg-icon-class">
            </button>
          </div>

          <div class="col-md-3 col-6 text-start ps-3"
            *ngIf="this.auctionBidderRegistrationDtos && this.auctionBidderRegistrationDtos.length > 0">
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="downloadBiderOfferData()">
              <img src="assets/icons/filecsv_dark.svg" class="c-pointer svg-icon-class">
              <span class="span-class-live">Export</span>
              <span class="spinner-border spinner-border-sm ms-2 loader-spinner" role="status" aria-hidden="true"
                *ngIf="isExportLoading"></span>
            </button>
          </div>

          <div class="text-md-end col-md-6 mt-md-0 mt-2 ps-2 ps-md-0">
            <div class=" input-group">
              <input #search placeholder="Search" type="text" (keyup)="filterBidderRegistrationDto(search.value)"
                class="form-control border-end-0 rounded-0 border bg-white" id="search" />
              <span class="input-group-append">
                <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                  <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered medium-font  mb-0">
        <thead class="table-dark">
          <tr>
            <th class="text-center "><span>Status</span></th>
            <th class=" "><span >Name</span></th>
            <th class=" "><span >Email</span></th>
            <th class=" "><span >Phone</span></th>
            <th class=" "><span >Registered On</span></th>
            <th class=" "><span >Action</span></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr *ngIf="isLoading">
            <td colspan="6">
              <div class="e-spinner" *ngIf="isLoading"></div>
            </td>

          </tr>
          <tr *ngIf="(filteredBidderRegistrationList$ | async)!.length == 0">
            <td colspan="6" class="text-center">
              <h5 class="heading-new text-dark">No Registrations Available</h5>
            </td>
          </tr>
          <tr *ngFor="let registrationDto of filteredBidderRegistrationList$ | async ; index as i">
            <td class="text-center">
              <img src="assets/icons/question_light.svg" class=" c-pointer svg-icon-class ms-2" ngbTooltip="Email Verification Pending"
                *ngIf="registrationDto.auctionRegistrationStatus!.toString() == 'AWAITING_APPROVAL'">
              <img src="assets/icons/approved_green.svg" class=" c-pointer svg-icon-class ms-2" ngbTooltip="Email Verified"
                *ngIf="registrationDto.auctionRegistrationStatus!.toString() == 'APPROVED_BIDDERS'">
              <img src="assets/icons/rejected_red.svg" class=" c-pointer svg-icon-class ms-2"
                *ngIf="registrationDto.auctionRegistrationStatus!.toString() == 'REJECTED_BIDDERS'" ngbTooltip="Rejected">
              <button class="btn btn-warning btn-sm rounded-circle"
                *ngIf="registrationDto.auctionRegistrationStatus!.toString() == 'DISABLED_BIDDERS'">
                <img src="assets/icons/edit_dark.svg" class=" c-pointer svg-icon-class ms-2">
              </button>

            </td>
            <!-- <td class="text-start">
              <div class="e-buttonmenu ee-left" ngbDropdown container="body" placement="bottom-start">
                <a class="e-formstatus ee-button"  [ngClass]="getStatusClass(item?.auctionRegistartionStatus!.toString())" ngbDropdownToggle>
                  {{getStatusIcon(item?.auctionRegistartionStatus!.toString())}}
                </a>
                <ul class="ee-menu" ngbDropdownMenu>
                  <li >
                    <span class="ms-3">
                      <small>STATUS: </small>
                      <span class="e-formstatus" [ngClass]="getStatusClass(item?.auctionRegistartionStatus!.toString())">
                        {{getStatusText(item?.auctionRegistartionStatus!.toString())}}
                      </span>
                    </span>
                  </li>
                  <li ngbDropdownItem *ngIf="isShowApproveButton(item?.auctionRegistartionStatus!.toString())" >
                    <a (click)="approveBidder(item)">{{getApproveButtonText(item?.auctionRegistartionStatus!.toString())}}</a>
                  </li>
                  <li ngbDropdownItem *ngIf="isShowRejectButton(item?.auctionRegistartionStatus!.toString())" >
                    <a (click)="rejectBidder(item)">{{getRejectButtonText(item?.auctionRegistartionStatus!.toString())}}</a>
                  </li>
                </ul>
              </div>
            </td> -->
            <td class="font-weight-normal">{{registrationDto?.name}}</td>
            <td class="font-weight-normal btn-link text-lowercase"><a
                href="mailto:{{registrationDto?.emailId}}">{{registrationDto?.emailId}}</a></td>
            <td class="font-weight-normal btn-link"><a
                href="tel:{{registrationDto?.mobileNo}}">{{getCountryCode(registrationDto?.countryCode)}} {{registrationDto?.mobileNo}}</a></td>
            <td class="font-weight-normal">{{registrationDto?.registrationDate}}</td>
            <td>
              <div class="row">

                <div class="col-12 text-center">
                  <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                    <span ngbDropdownToggle data-bs-toggle="dropdown">
                      <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class">

                    </span>
                    <ul ngbDropdownMenu class="shadow">
                      <!-- <li   *ngIf="item?.auctionRegistartionStatus!.toString() != 'INCOMPLETE'">
                        <span class="ms-3" >
                          <small>STATUS:</small>
                          <span class="e-formstatus" [ngClass]="getStatusClass(item?.auctionRegistartionStatus!.toString())">
                            {{getStatusText(item?.auctionRegistartionStatus!.toString())}}</span>
                        </span>
                      </li>-->
                      <li ngbDropdownItem class="c-pointer"
                        *ngIf="isShowApproveButton(registrationDto?.auctionRegistrationStatus!.toString())">
                        <a
                          (click)="approveBidder(registrationDto)">{{getApproveButtonText(registrationDto?.auctionRegistrationStatus!.toString())}}</a>
                      </li>
                      <li>
                        <hr class="dropdown-divider " *ngIf="isShowApproveButton(registrationDto?.auctionRegistrationStatus!.toString())">
                      </li>
                      <li ngbDropdownItem class="c-pointer"
                        *ngIf="isShowRejectButton(registrationDto?.auctionRegistrationStatus!.toString())">
                        <a
                          (click)="rejectBidder(registrationDto)">{{getRejectButtonText(registrationDto?.auctionRegistrationStatus!.toString())}}</a>
                      </li>
                      <li>
                        <hr class="dropdown-divider " *ngIf="isShowRejectButton(registrationDto?.auctionRegistrationStatus!.toString())">
                      </li>
                      <li ngbDropdownItem class="c-pointer">
                        <a (click)="openBidderDetailsModal(registrationDto.userId!)">BIDDER DETAILS</a>
                      </li>
                      <li>
                        <hr class="dropdown-divider " *ngIf="isShowRejectButton(registrationDto?.auctionRegistrationStatus!.toString())">
                      </li>
                      <li ngbDropdownItem class="c-pointer">
                        <a >REGISTRATION DETAILS</a>
                      </li>
                      <!-- <li ngbDropdownItem *ngIf="auctionEntityDto?.bidderRequirementsDtos">
                        <a class="ea-popupload" data-id="#e-popupbidder" (click)="openBidderRequirmentDetailsModal(item.userId!)">BIDDER REQUIRMENT DETAILS</a>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 mt-2" *ngIf="(filteredBidderRegistrationList$ | async)!.length > 0">
      <div class="row justify-content-between">
        <div class="col-md-6 mb-2">
          <div class="row">
            <div class="col-md-4 col-sm-5 col-6 pe-0">
              <span class="span-class">Show rows per page </span>
            </div>
            <div class="col-6 ps-0">
              <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                <span ngbDropdownToggle data-bs-toggle="dropdown">
                  <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
                </span>
                <ul ngbDropdownMenu class="text-center shadow">

                  <li ngbDropdownItem (click)="changePageSize(50)">
                    50
                  </li>
                  <hr class="hr" />
                  <li ngbDropdownItem (click)="changePageSize(100)">
                    100
                  </li>

                </ul>
              </div>

            </div>
          </div>

        </div>
        <div class="col-auto text-md-end mb-2">
          <ngb-pagination [collectionSize]="(filteredBidderRegistrationList$ | async)?.length!" [(page)]="page"
            [pageSize]="pageSize" (pageChange)="paginateBidderHistoryList()">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>



</div>
