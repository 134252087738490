export enum AuctionAuditActivityEnum {
  AUCTION_CREATED = 'AUCTION_CREATED',
  AUCTION_MARKED_INACTIVE = 'AUCTION_MARKED_INACTIVE',
  AUCTION_LOT_MARKED_ACTIVE = 'AUCTION_LOT_MARKED_ACTIVE',
  AUCTION_LOT_MARKED_INACTIVE = 'AUCTION_LOT_MARKED_INACTIVE',
  AUCTION_MARKED_DRAFT = 'AUCTION_MARKED_DRAFT',
  AUCTION_MARKED_UPCOMING = 'AUCTION_MARKED_UPCOMING',
  AUCTION_MARKED_LIVE = 'AUCTION_MARKED_LIVE',
  AUCTION_MARKED_CLOSE = 'AUCTION_MARKED_CLOSE',
  STATUS_CHANGE = 'STATUS_CHANGE',
  NEW_LOT = 'NEW_LOT',
  AUCTION_LOT_CONFIG_CHANGE = "AUCTION_LOT_CONFIG_CHANGE",
  AUCTION_CONFIG_CHANGE = 'AUCTION_CONFIG_CHANGE',
  DOCUMENT_ADDED = 'DOCUMENT_ADDED',
  DOCUMENT_REMOVED = 'DOCUMENT_REMOVED',
  QUESTION_ADDED = 'QUESTION_ADDED',
  QUESTION_REMOVED = 'QUESTION_REMOVED',
  ADDRESS_ADDED = 'ADDRESS_ADDED',
  ADDRESS_REMOVED = 'ADDRESS_REMOVED',
}
