import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { CountryCodeDto } from '../../models/CountryCodeDto';

@Component({
  selector: 'app-country-code-select',
  templateUrl: './country-code-select.component.html',
  styleUrls: ['./country-code-select.component.sass']
})
export class CountryCodeSelectComponent implements OnInit, OnDestroy {
  @Input() countryCode$: Observable<CountryCodeDto | null> = new Observable();
  @Output() countryCodeEvent = new EventEmitter<CountryCodeDto>();

  selectedCountry?: CountryCodeDto;

  countries: CountryCodeDto[] = [];

  constructor(
    private changeDetectRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.countries = countries;

    this.countryCode$.subscribe((data) => {
      if (data) {
        this.selectedCountry = data;
      } else {
        this.selectedCountry = undefined;
      }
    })
  }

  handleCountySelection(event: any) {
    this.selectedCountry = event.value;
    this.countryCodeEvent.emit(this.selectedCountry);
    this.changeDetectRef.detectChanges();
  }

  ngOnDestroy(): void {
  }
}

const countries = [
  {
    countryCode: '(+973)',
    countryName: 'Bahrain',
    countryFlag: 'bh',
    mobileNoLength: '8'
  },
  {
    countryCode: '(+61)',
    countryName: 'Australia',
    countryFlag: 'au',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+43)',
    countryName: 'Austria',
    countryFlag: 'at',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+880)',
    countryName: 'Bangladesh',
    countryFlag: 'bd',
    mobileNoLength: '11'
  },
  {
    countryCode: '(+32)',
    countryName: 'Belgium',
    countryFlag: 'be',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+1)',
    countryName: 'Canada',
    countryFlag: 'ca',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+385)',
    countryName: 'Croatia',
    countryFlag: 'hr',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+357)',
    countryName: 'Cyprus',
    countryFlag: 'cy',
    mobileNoLength: '8'
  },
  {
    countryCode: '(+372)',
    countryName: 'Estonia',
    countryFlag: 'ee',
    mobileNoLength: '8'
  },
  {
    countryCode: '(+358)',
    countryName: 'Finland',
    countryFlag: 'fi',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+33)',
    countryName: 'France',
    countryFlag: 'fr',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+49)',
    countryName: 'Germany',
    countryFlag: 'de',
    mobileNoLength: '12'
  },
  {
    countryCode: '(+30)',
    countryName: 'Greece',
    countryFlag: 'gr',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+91)',
    countryName: 'India',
    countryFlag: 'in',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+353)',
    countryName: 'Ireland',
    countryFlag: 'ie',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+39)',
    countryName: 'Italy',
    countryFlag: 'it',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+254)',
    countryName: 'Kenya',
    countryFlag: 'ke',
    mobileNoLength: '9'
  },
  {
    countryCode: '(+965)',
    countryName: 'Kuwait',
    countryFlag: 'kw',
    mobileNoLength: '8'
  },
  {
    countryCode: '(+371)',
    countryName: 'Latvia',
    countryFlag: 'lv',
    mobileNoLength: '8'
  },
  {
    countryCode: '(+370)',
    countryName: 'Lithuania',
    countryFlag: 'lt',
    mobileNoLength: '9'
  },
  {
    countryCode: '(+352)',
    countryName: 'Luxembourg',
    countryFlag: 'lu',
    mobileNoLength: '9'
  },
  {
    countryCode: '(+60)',
    countryName: 'Malaysia',
    countryFlag: 'my',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+356)',
    countryName: 'Malta',
    countryFlag: 'mt',
    mobileNoLength: '8'
  },
  {
    countryCode: '(+31)',
    countryName: 'Netherlands',
    countryFlag: 'nl',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+64)',
    countryName: 'New Zealand',
    countryFlag: 'nz',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+234)',
    countryName: 'Nigeria',
    countryFlag: 'ng',
    mobileNoLength: '11'
  },
  {
    countryCode: '(+351)',
    countryName: 'Portugal',
    countryFlag: 'pt',
    mobileNoLength: '9'
  },
  {
    countryCode: '(+966)',
    countryName: 'Saudi Arabia',
    countryFlag: 'sa',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+421)',
    countryName: 'Slovakia',
    countryFlag: 'sk',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+386)',
    countryName: 'Slovenia',
    countryFlag: 'si',
    mobileNoLength: '9'
  },
  {
    countryCode: '(+27)',
    countryName: 'South Africa',
    countryFlag: 'za',
    mobileNoLength: '9'
  },
  {
    countryCode: '(+34)',
    countryName: 'Spain',
    countryFlag: 'es',
    mobileNoLength: '9'
  },
  {
    countryCode: '(+971)',
    countryName: 'United Arab Emirates',
    countryFlag: 'ae',
    mobileNoLength: '9'
  },
  {
    countryCode: '(+44)',
    countryName: 'United Kingdom',
    countryFlag: 'gb',
    mobileNoLength: '10'
  },
  {
    countryCode: '(+1)',
    countryName: 'United States',
    countryFlag: 'us',
    mobileNoLength: '10'
  }
];