<p-dropdown [options]="countries" [(ngModel)]="selectedCountry" optionLabel="countryName" [filter]="true"
  filterBy="countryName" [showClear]="true" (onChange)="handleCountySelection($event)">
  <ng-template pTemplate="selectedItem">
    <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
      <img src="/assets/images/flag_placeholder.png" [class]="'fi fi-' + selectedCountry.countryFlag"
        style="width: 18px" />
      <div>{{ selectedCountry.countryName }} {{ selectedCountry.countryCode }}</div>
    </div>
  </ng-template>
  <ng-template let-country pTemplate="item">
    <div class="flex align-items-center gap-2">
      <img src="/assets/images/flag_placeholder.png" [class]="'fi fi-' + country.countryFlag" style="width: 18px" />
      <div>{{ country.countryName }}</div>
    </div>
  </ng-template>
</p-dropdown>