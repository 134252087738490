<div class=" p-2 my-3">
  <div class="col-12 mt-2">
    <div class="row">
      <div class="col-md-5 col-12 text-start ">
        <h5 class="heading-h5">SETUP
          <span class="" *ngIf="!(_isRfxInfoHide$ | async)"><a href="#bottom"><span
                class="btn-link heading-h5">(Total Events - {{rfxEntityDto?.noOfSubcategories}})</span></a></span>
          <span *ngIf="(_isRfxInfoHide$ | async)">({{rfxEntityDto?.sequenceNo}} #
            {{rfxEntityDto?.rfxTitle}})</span>

        </h5>
        <span class="ms-3"
            [ngClass]="getClassBasedOnStatus(rfxEntityDto?.status!)">{{getStatus(rfxEntityDto?.status!)}}</span>
      </div>
      <div class="col-md-7 col-12 text-md-end text-start mt-2 mt-md-0">
        <span class="span-class me-2">{{(_isRfxInfoHide$ | async)? 'Show Project Details' : 'Hide Project Details'
          }}</span>
        <span class="form-switch">
          <input class="form-check-input c-pointer" type="checkbox" id="flexSwitchCheckChecked"
            (click)="changeRfxInfoHideOrShow()" [checked]="_isRfxInfoHide$ |async" /></span>
            <button class="btn-new-class btn-sm btn mt-2 ms-3 mt-md-0"
            (click)="openActivateModal(enableDisabledRfx, 'RFX')">
            {{rfxEntityDto?.active ? 'ACTIVE' : 'INACTIVE'}}
          </button>
      </div>
    </div>
  </div>
  <hr class="hr mt-1 mb-3" />
  <div *ngIf="!(_isRfxInfoHide$ | async)">
    <!-- <div class="col-10 m-auto my-2">
      <div class="row">
        <div class="col-md-5 text-start mb-2">
          <span class=""
            [ngClass]="getClassBasedOnStatus(rfxEntityDto?.status!)">{{getStatus(rfxEntityDto?.status!)}}</span>
        </div>
        <div class="col-md-7 text-md-end text-start mb-2">
          <span class="span-class me-2">
            Bidders can see/ search only active project
          </span>
          <span class="form-switch ">
            <input #toggleRefRfx class="form-check-input switch-toggle-margin-top c-pointer" type="checkbox"
              id="flexSwitchCheckChecked" [disabled]="!isAllowToEdit()"
              (click)="openActivateModal(enableDisabledRfx, toggleRefRfx, 'RFX')"
              [checked]="rfxEntityDto?.active" />
          </span>
          <span class="span-class ms-2 me-3">
            <b>{{rfxEntityDto?.active ? 'Active' : 'Inactive'}}</b>
          </span>
          </div>
      </div>
    </div> -->
    <div class="col-lg-11 m-auto">
      <div class="row">
        <div class="col-lg-9 mb-2">
          <app-rfx-details></app-rfx-details>
        </div>
        <div class="col-lg-3 mb-2 ">
          <app-rfx-manage-document></app-rfx-manage-document>
          <!-- <app-bidder-registration-question></app-bidder-registration-question> -->
        </div>  
      </div>
    </div>
  </div>
</div>
<div>
  <app-rfx-subcategory-information></app-rfx-subcategory-information>
</div>

<ng-template #discardRfxModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Project Discard</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(rfxDiscardModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>Are you sure you want to discard the selected project? you will not be able to work on this project once
            discarded and all events associated with project will also get discarded.</p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Project discarded successfully."></app-message-toast>
        </div>

        <div class="text-end mb-3" *ngIf="rfxEntityDto && !isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal(rfxDiscardModalRef)"
            *ngIf="!isShowCloseModalButton">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="discardRfx()"
            *ngIf="!isShowCloseModalButton" [disabled]="!isAllowToEdit()">DISCARD</button>

          <button type="button" class="btn btn-sm btn-outline-primary" (click)="closeModal(rfxDiscardModalRef)"
            *ngIf="isShowCloseModalButton">Close</button>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #enableDisabledRfx>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center" ngbAutoFocus>
          <h2>{{this.rfxEnableStatus ? 'DISABLE' : 'ENABLE'}} PROJECT </h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(activateModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p *ngIf="!rfxEnableStatus">Do you Want to Enable Project</p>
          <p *ngIf="rfxEnableStatus">
            Would you like to deactivate the project?<br>
            Currently, there {{noOfActiveSubcategories == 1 ?
            'is' : 'are'}} <b>{{convertNumberToWords(noOfActiveSubcategories!)}}</b> active
            {{noOfActiveSubcategories == 1 ?
            'project event' : 'project events'}}, and deactivating the project will
            also deactivate {{noOfActiveSubcategories == 1 ?
            'it' : 'them'}}
          </p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Project {{rfxEntityDto?.active ? 'enabled' : 'disabled'}} successfully."></app-message-toast>
        </div>

        <div class="text-end mb-3" *ngIf="rfxEntityDto && !isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3"
            (click)="closeModal(activateModalRef)">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary"
            (click)="checkRfxSubcategoryValidationAndMarkActive('ENABLE')" *ngIf="!rfxEntityDto?.active"
            [disabled]="!(_isSaveButtonEnable$ | async)">ENABLE</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="markRfxActiveOrInActive('DISABLE')"
            *ngIf="rfxEntityDto?.active" [disabled]="!(_isSaveButtonEnable$ | async)">DISABLE</button>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>