import { Timestamp } from "@angular/fire/firestore";
import { CatalogueVendorDto } from "./CatalogueVendorDto";
import { FileInfoDto } from "./FileInfoDto";

export class CatalogueLineItemsEntityDto {
  categoryL1Id?: string;
  categoryL2Id?: string;
  lineItemId?: string;
  title?: string;
  fileInfoDto?: FileInfoDto;
  description?: string;
  catalogueVendors?: CatalogueVendorDto[]
  createdDate?: string;
}
