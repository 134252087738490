<div class="model-body">
  <form [formGroup]="formGroup" class="needs-validation" novalidate (keyup.enter)="saveOrganization()">
    <div class="mb-3">
      <label for="theme" class="text-gray-700  text-sm font-semibold leading-4">
        {{formConfig['theme'].label}}
      </label>
      <select class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select" id="theme" formControlName="theme"
        [ngClass]="{'is-invalid': fc.theme.invalid && (fc.theme.dirty || fc.theme.touched)}">
        <option value="" disabled>{{formConfig['theme'].label}}</option>
        <option value="MATERIAL_AND_SERVICES">Material and services</option>
        <option value="WAREHOUSES">Warehouses</option>
        <option value="CUSTOM">Custom</option>
      </select>
      <div class="invalid-feedback" *ngIf="fc.theme.invalid && (fc.theme.dirty || fc.theme.touched)">
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.theme.errors?.required">
          {{formConfig['theme'].validations['required']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.theme.errors?.pattern">
          {{formConfig['theme'].validations['pattern']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.theme.errors?.minlength">
          {{formConfig['theme'].validations['minLength']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.theme.errors?.maxlength">
          {{formConfig['theme'].validations['maxLength']}}</p>
      </div>
    </div>
    <div class=" mb-3">
      <label for="name" class="text-gray-700  text-sm font-semibold leading-4">{{formConfig['name'].label}}</label>
      <input type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm" id="name" placeholder="name" formControlName="name"
        [ngClass]="{'is-invalid': fc.name.invalid && (fc.name.dirty || fc.name.touched)}">
      <div class="invalid-feedback" *ngIf="fc.name.invalid && (fc.name.dirty || fc.name.touched)">
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.name.errors?.required">
          {{formConfig['name'].validations['required']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.name.errors?.pattern">
          {{formConfig['name'].validations['pattern']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.name.errors?.minlength">
          {{formConfig['name'].validations['minLength']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.name.errors?.maxlength">
          {{formConfig['name'].validations['maxLength']}}</p>
      </div>
    </div>

    <div class=" mb-3">
      <label for="url" class="text-gray-700  text-sm font-semibold leading-4">{{formConfig['orgCode'].label}}</label>
      <input type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm" id="orgCode" placeholder="Org Code" formControlName="orgCode"
        [ngClass]="{'is-invalid': fc.orgCode.invalid && (fc.orgCode.dirty || fc.orgCode.touched)}">
      <div class="invalid-feedback" *ngIf="fc.orgCode.invalid && (fc.orgCode.dirty || fc.orgCode.touched)">
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.orgCode.errors?.required">
          {{formConfig['orgCode'].validations['required']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.orgCode.errors?.pattern">
          {{formConfig['orgCode'].validations['pattern']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.orgCode.errors?.minlength">
          {{formConfig['orgCode'].validations['minLength']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.orgCode.errors?.maxlength">
          {{formConfig['orgCode'].validations['maxLength']}}</p>
      </div>
    </div>

    <div class=" mb-3">
      <label for="adminDomainName" class="text-gray-700  text-sm font-semibold leading-4">{{formConfig['adminDomainName'].label}}</label>
      <input type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm" id="adminDomainName" placeholder="adminDomainName"
        formControlName="adminDomainName" (change)="onChangeAdminDomainName()"
        [ngClass]="{'is-invalid': fc.adminDomainName.invalid && (fc.adminDomainName.dirty || fc.adminDomainName.touched)}">
    
      <div class="invalid-feedback"
        *ngIf="fc.adminDomainName.invalid && (fc.adminDomainName.dirty || fc.adminDomainName.touched)">
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.adminDomainName.errors?.required">
          {{formConfig['adminDomainName'].validations['required']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.adminDomainName.errors?.pattern">
          {{formConfig['adminDomainName'].validations['pattern']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.adminDomainName.errors?.minlength">
          {{formConfig['adminDomainName'].validations['minLength']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.adminDomainName.errors?.maxlength">
          {{formConfig['adminDomainName'].validations['maxLength']}}</p>
      </div>
    </div>

    <div class=" mb-3">
      <label for="bidderDomainName" class="text-gray-700  text-sm font-semibold leading-4">{{formConfig['bidderDomainName'].label}}</label>
      <input type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm" id="bidderDomainName" placeholder="bidderDomainName"
        formControlName="bidderDomainName" (change)="onChangeBidderDomainName()"
        [ngClass]="{'is-invalid': fc.bidderDomainName.invalid && (fc.bidderDomainName.dirty || fc.bidderDomainName.touched)}">
      <div class="invalid-feedback"
        *ngIf="fc.bidderDomainName.invalid && (fc.bidderDomainName.dirty || fc.bidderDomainName.touched)">
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.bidderDomainName.errors?.required">
          {{formConfig['bidderDomainName'].validations['required']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.bidderDomainName.errors?.pattern">
          {{formConfig['bidderDomainName'].validations['pattern']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.bidderDomainName.errors?.minlength">
          {{formConfig['bidderDomainName'].validations['minLength']}}</p>
        <p class="e-fielderror e-fielderror-required" *ngIf="fc.bidderDomainName.errors?.maxlength">
          {{formConfig['bidderDomainName'].validations['maxLength']}}</p>
      </div>
    </div>

    <div class="mb-3">
      <app-message-toast *ngIf="_showSuccessToast$ | async"
        [message]="isEdit ? successMessages['update'] : successMessages['create']">
      </app-message-toast>
      <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="(this._showErrorMsg$ | async)">
      </app-message-toast>
    </div>

    <div class="grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner" *ngIf="isLoading"></div>
    </div>

    <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
      <button type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800c-pointer"
        (click)="saveOrganization()">
        {{organizationUiDto ? 'Update' : 'Add'}}
      </button>
    </div>
  </form>
</div>