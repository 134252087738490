<div>
  <div class="col-12">
    <div class="row">
      <div class="col-md-7 my-3">
        <!-- <span class="me-2">
          <a class="link-class-small">Previous</a>
        </span>
        <span class="me-2 medium-font">
          Question 1 to 12
        </span>
        <span class="me-2">
          <a class="link-class-small">Next</a>
        </span>
        <span>
          <span class="me-1 badge small-badge-grey">
            Draft
          </span>
          <span class="medium-font me-2">
            {{countDraftQuestions}}
          </span>
          <span class="me-1 badge small-badge-primary">
            Published
          </span>
          <span class="medium-font">
            {{countPublishedQuestions}}
          </span>
        </span> -->
      </div>
      <div class="col-md-5 text-md-end my-3">
        <!-- <button class="btn btn-sm btn-outline-primary me-3">
          Import Question
        </button> -->
        <a class="link-class" [ngClass]="{'disabled' : !selectedSectionTemplate?.sectionName}"
          (click)="openAddQuestionModal(addQuestionTemplate)">
          Add Question
        </a>
      </div>
    </div>
  </div>

  <div class="border p-2" [ngClass]="{'border-danger': questionScoreValidationFailed}">
    <div class="col-12 table-responsive">
      <table class="table table-bordered medium-font">
        <thead class="table-dark">
          <tr>
            <th scope="col">Q No</th>
            <th scope="col">Q Criteria</th>
            <th scope="col">Response Type</th>
            <th scope="col" *ngIf="questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate">Score</th>
            <th scope="col">Action</th>
            <th scope="col">Re-order</th>
          </tr>
        </thead>
        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
          <tr class="text-center mb-1" *ngIf="technicalQuestions.length == 0">
            <td colspan="6">
              <div class="small-font text-red-500 ">
                No Question is Available
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of technicalQuestions;" cdkDrag cdkDragLockAxis="y">
            <th scope="row">{{selectedSectionTemplate?.preSequenceText}}.{{item.sequenceNo}}</th>
            <td>{{item.questionText}}</td>
            <td>{{getTitleResponseType(item.responseType!)}}</td>
            <td *ngIf="questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate">{{item.score}}</td>
            <td>
              <a class="link-class" (click)="openAddQuestionModal(addQuestionTemplate, item)">Edit</a>
            </td>
            <td class="text-center">
              <img src="assets/icons/reorder_light.svg" class="svg-icon-class-small c-pointer" cdkDragHandle>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div class="col-12 text-end">
    <!-- <button class="btn btn-sm btn-dark-clr-class me-3">
      Save
    </button>
    <button class="btn btn-sm btn-dark-clr-class me-3">
      Save as draft
    </button> -->
    <!-- <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
      Save
    </button> -->
  </div>

  <div class="col-12 mt-3">
    <ol>
      <li class="text-red-500 " *ngFor="let item of questionnaireValidationList; index as i;">
        {{item?.message}}
      </li>
    </ol>
  </div>
</div>


<ng-template #addQuestionTemplate>
  <div class="model-body ">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>ADD QUESTION</h2>

        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(addQuestionModalRef)">
        </div>
      </div>
    </header>
    <div class="header-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="col-12">
          <div class="row">
            <div class="col-md-5">
              <div class="row">
                <div class="col-4 font-weight-normal py-2 pe-md-0">
                  Section Name
                </div>
                <div class="col-8 div-class py-2 ps-md-0">
                  {{selectedSectionTemplate?.sectionName}}
                </div>
              </div>
            </div>
            <div class="col-md-3" *ngIf="questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate">
              <div class="row">
                <div class="col-md-3 col-4 font-weight-normal py-2">
                  Score
                </div>
                <div class="col-md-9 col-8 div-class py-2">
                  {{selectedSectionTemplate?.sectionScore}}
                </div>
              </div>
            </div>
            <div class="col-md-4" *ngIf="questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate">
              <div class="row">
                <div class="col-md-5 col-4 font-weight-normal py-2">
                  Remaining Score
                </div>
                <div class="col-8 col-md-7 div-class py-2">
                  {{getRemainingScoreOfSection()}}
                </div>
              </div>
            </div>

            <hr class="hr my-2">

            <div class="row align-items-center">
              <div class="col-md-7 text-md-end my-3">
                <span class="me-3">
                  <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="previousQuestion()"
                    [disabled]="isPrevButtonDisabled">
                    <img src="assets/icons/leftarrow2_dark.svg" class="svg-icon-class-small mar-btm c-pointer" />
                    Prev
                  </button>
                </span>
                <span class="me-3">
                  Q. <b>{{fc['sequenceNo'].value}}</b> of {{countTechnicalQuestions}}
                </span>
                <span class="me-3">
                  <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="nextQuestion()" [disabled]="isNextButtonDisabled">
                    Next
                    <img src="assets/icons/rightarrow2_dark.svg" class="svg-icon-class-small mar-btm c-pointer" />
                  </button>
                </span>
              </div>
              <div class="col-md-5 text-md-end">
                <a class="link-class" (click)="addNewQuestion()" [ngClass]="{'disabled': newQuestionTemplate}">Add More Question</a>
              </div>

            </div>

            <hr class="hr my-2">

          </div>
        </div>
        <form class="" [formGroup]="formGroup">
          <div class="row mb-3">
            <div class="col-11 pe-0">
              <div class="input-group">
                <span class="input-group-text border-end-0">
                  {{fc['preSequenceText'].value | uppercase}}.{{fc['sequenceNo'].value}}
                </span>
                <div class="form-floating">
                  <input class="form-control border-start-0" formControlName="questionText" id="questionText"
                    [ngClass]="{'border-danger': fc['questionText'].invalid && (fc['questionText'].dirty || fc['questionText'].touched)}">
                  <label for="questionText">Question Criteria
                    <!-- <span class="me-1 badge small-badge-grey">
                      {{getTitleQuestionStatus(selectedQuestionTemplate?.questionStatus)}}
                    </span> -->
                  </label>
                </div>

              </div>
            </div>
            <div class="col-1 mt-2" *ngIf="selectedQuestionTemplate?.questionId">
              <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
                (click)="openDeleteConfirmModal(deleteConfirmModal)">
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <div div class="row">
                      <div class="medium-font col-6">Response Mandatory?</div>
                      <div class="col-4">
                        <span class="form-switch c-pointer">
                          <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked"
                            formControlName="responseMandatory" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="row">
                      <div class="medium-font col-6">Bidder Comments Required?</div>
                      <div class="col-4">
                        <span class="form-switch c-pointer">
                          <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked"
                            formControlName="bidderCommentsRequired" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="mb-3">
                  <input #sampleFile onclick="this.value=null;" type="file" (change)="chooseFile($event)"
                    class="d-none" />
                  <a class="link-class" (click)="sampleFile.click()">Click to upload Sample</a>
                </div>
                <div class="row" *ngIf="currentFileInfoDto || currentFile">
                  <div class="col-10">
                    <a class="link-class" *ngIf="currentFileInfoDto" (click)="previewSampleDoc('SERVER')">
                      {{selectedFileName}}
                    </a>
                    <a class="link-class" *ngIf="currentFile && !currentFileInfoDto"
                      (click)="previewSampleDoc('LOCAL')">
                      {{selectedFileName}}
                    </a>
                  </div>
                  <div class="col-2">
                    <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
                      (click)="removeSampleDoc()">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">

            <!-- For Scoring Template -->
            <table class="table medium-font mb-0"
              *ngIf="questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate">
              <thead>
                <tr class="align-middle">
                  <th scope="col">
                    <div ngbDropdown #dropdownResponseType="ngbDropdown" class="dropdown" [autoClose]="true"
                      container="body">
                      <button class="btn select-box-btn-class border text-start dropdown-toggle mb-2 rounded-0"
                        type="button" data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
                        <div class="row">
                          <div class="col-9">
                            <span class="medium-grey-font">
                              {{getTitleResponseType(this.selectedQuestionTemplate?.responseType!)}}
                            </span>
                          </div>
                          <div class="col-3 text-end">
                            <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-small">
                          </div>
                        </div>
                      </button>
                      <ul class="dropdown-menu text-start shadow dropdown-width-class " ngbDropdownMenu>
                        <li ngbDropdownItem (click)="onChangeResponseType(item, dropdownResponseType)"
                          *ngFor="let item of responseTypesList">
                          {{getTitleResponseType(item)}}
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th scope="col" class="text-center" *ngIf="selectedQuestionTemplate?.responseType">
                    <div class="medium-font">Score</div>
                  </th>
                  <th scope="col" class="text-center" *ngIf="selectedQuestionTemplate?.responseType">
                    Disqualify
                  </th>
                  <th scope="col" class="text-center" *ngIf="selectedQuestionTemplate?.responseType">
                    Upload Required
                  </th>
                </tr>
              </thead>
              <tbody formArrayName="questionOptions">
                <tr class="align-middle" *ngFor="let item of questionOptions.controls; index as i" [formGroupName]="i">
                  <td style="width: 55%;">
                    <div class="row" *ngIf="isShowOptionTextField(selectedQuestionTemplate?.responseType!)">
                      <div class="col-10">
                        <div class="input-group">
                          <span class="input-group-text border-end-0"
                            [ngClass]="{'border-danger': item.get('optionText')?.invalid && (item.get('optionText')?.dirty || item.get('optionText')?.touched)}">
                            {{item.get('optionPreSequence')?.value | lowercase}}
                          </span>
                          <input class="form-control border-start-0" formControlName="optionText" id="optionText"
                            [ngClass]="{'border-danger': item.get('optionText')?.invalid && (item.get('optionText')?.dirty || item.get('optionText')?.touched)}">
                        </div>
                        <div
                          *ngIf="item.get('optionText')?.invalid && (item.get('optionText')?.dirty || item.get('optionText')?.touched)">
                          <p class="text-red-500 " *ngIf="item.get('optionText')?.errors?.['required']">Required</p>
                        </div>
                      </div>
                      <div class="col-2">
                        <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer" *ngIf="i > 1"
                          (click)="removeQuestionOption(i)">
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="input-group">
                      <input class="form-control" formControlName="questionOptionScore" id="questionOptionScore"
                        [ngClass]="{'border-danger': item.get('questionOptionScore')?.invalid && (item.get('questionOptionScore')?.dirty || item.get('questionOptionScore')?.touched)}">
                    </div>
                    <div
                      *ngIf="item.get('questionOptionScore')?.invalid && (item.get('questionOptionScore')?.dirty || item.get('questionOptionScore')?.touched)">
                      <p class="text-red-500 " *ngIf="item.get('questionOptionScore')?.errors?.['required']">Required</p>
                      <p class="text-red-500 " *ngIf="item.get('questionOptionScore')?.errors?.['pattern']">Invalid</p>
                    </div>
                  </td>
                  <td class="text-center">
                    <input type="checkbox" class="checkbox-class" id="disqualifyChecked" formControlName="disqualifyChecked">
                  </td>
                  <td class="text-center">
                    <input type="checkbox" class="checkbox-class" id="uploadRequired" formControlName="uploadRequired">
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- For Non Scoring Template -->
            <table class="table medium-font mb-0"
              *ngIf="!questionnaireWrapperDto?.questionnaireTemplate?.scoringTemplate">
              <thead>
                <tr class="align-middle">
                  <th scope="col">
                    <div ngbDropdown #dropdownResponseType="ngbDropdown" class="dropdown" [autoClose]="true"
                      container="body">
                      <button class="btn select-box-btn-class border text-start dropdown-toggle mb-2 rounded-0"
                        type="button" data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
                        <div class="row">
                          <div class="col-9">
                            <span class="medium-grey-font">
                              {{getTitleResponseType(this.selectedQuestionTemplate?.responseType!)}}
                            </span>
                          </div>
                          <div class="col-3 text-end">
                            <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-small">
                          </div>
                        </div>
                      </button>
                      <ul class="dropdown-menu text-start shadow dropdown-width-class " ngbDropdownMenu>
                        <li ngbDropdownItem (click)="onChangeResponseType(item, dropdownResponseType)"
                          *ngFor="let item of responseTypesList">
                          {{getTitleResponseType(item)}}
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th scope="col" class="text-center" *ngIf="selectedQuestionTemplate?.responseType">
                    <div class="row">
                      <div class="col">Upload Required</div>
                      <div class="col text-start ms-0" formArrayName="questionOptions"
                        *ngIf="!isShowOptionTextField(selectedQuestionTemplate?.responseType!)">
                        <span formGroupName="0">
                          <input type="checkbox" class="checkbox-class" id="uploadRequired" formControlName="uploadRequired">
                        </span>
                      </div>
                    </div>
                  </th>

                </tr>
              </thead>
              <tbody formArrayName="questionOptions"
                *ngIf="isShowOptionTextField(selectedQuestionTemplate?.responseType!)">
                <tr class="align-middle" *ngFor="let item of questionOptions.controls; index as i" [formGroupName]="i">
                  <td style="width: 55%;">
                    <div class="row">
                      <div class="col-10">
                        <div class="input-group">
                          <span class="input-group-text border-end-0"
                            [ngClass]="{'border-danger': item.get('optionText')?.invalid && (item.get('optionText')?.dirty || item.get('optionText')?.touched)}">
                            {{item.get('optionPreSequence')?.value | lowercase}}
                          </span>
                          <input class="form-control border-start-0" formControlName="optionText" id="optionText"
                            [ngClass]="{'border-danger': item.get('optionText')?.invalid && (item.get('optionText')?.dirty || item.get('optionText')?.touched)}">
                        </div>
                        <div
                          *ngIf="item.get('optionText')?.invalid && (item.get('optionText')?.dirty || item.get('optionText')?.touched)">
                          <p class="text-red-500 " *ngIf="item.get('optionText')?.errors?.['required']">Required</p>
                        </div>
                      </div>
                      <div class="col-2">
                        <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer" *ngIf="i > 1"
                          (click)="removeQuestionOption(i)">
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <input type="checkbox" class="checkbox-class" formControlName="uploadRequired" id="uploadRequired">
                  </td>
                </tr>
              </tbody>
            </table>

          </div>


          <!-- <div class="col-12 w-50">
          <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="true">
            <button class="btn select-box-btn-tech-ques  text-start dropdown-toggle mb-2 rounded-0"
              type="button" data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
              <div class="row">
                <div class="col-9">
                  <span class="medium-grey-font">Select response type</span>
                </div>
                <div class="col-3 text-end">
                  <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-small">
                </div>
              </div>
            </button>
            <ul class="dropdown-menu text-start shadow mt-5 dropdown-width-class " ngbDropdownMenu>
              <li>
                test
              </li>
            </ul>
          </div>
        </div> -->
          <!-- <div class="row" *ngIf="isSelectOption">
          <div class="col-md-6">
            <div class="row">
              <div class="col-10">
                <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="true">
                  <button class="btn select-box-btn-tech-ques  text-start dropdown-toggle mb-2 rounded-0"
                    type="button" data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
                    <div class="row">
                      <div class="col-9">
                        <span class="medium-grey-font">Single choice response</span>
                      </div>
                      <div class="col-3 text-end">
                        <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-small">
                      </div>
                    </div>
                  </button>
                  <ul class="dropdown-menu text-start shadow mt-5 dropdown-width-class " ngbDropdownMenu>
                    <li>
                      test
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-2">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-6 text-center">
                <div class="medium-font">
                  Score
                </div>
                <div class="small-grey-font">
                  Available 50
                </div>
              </div>
              <div class="col-3 small-font text-center mt-2">
                Disqualify
              </div>
              <div class="col-3 small-font text-center mt-2">
                Upload Required
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isSelectOption">
          <div class="col-md-6">
            <div class="row">
              <div class="col-10">
                <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="true">
                  <button class="btn select-box-btn-tech-ques  text-start dropdown-toggle mb-2 rounded-0"
                    type="button" data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
                    <div class="row">
                      <div class="col-12">
                        <span class="medium-grey-font">Enter option 1</span>
                      </div>
                    </div>
                  </button>
                  <ul class="dropdown-menu text-start shadow mt-5 dropdown-width-class " ngbDropdownMenu>
                    <li>
                      test
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-2">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-6">
                <div class="input-group">
                  <div class="form-floating">
                    <input class="select-box-btn-tech-ques1 ">
                  </div>
                </div>
              </div>
              <div class="col-3 text-center mt-2">
                <input type="checkbox" class="checkbox-class" name="" id="">
              </div>
              <div class="col-3 text-center mt-2">
                <input type="checkbox" class="checkbox-class" name="" id="">
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isSelectOption">
          <div class="col-md-6">
            <div class="row">
              <div class="col-10">
                <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="true">
                  <button class="btn select-box-btn-tech-ques  text-start dropdown-toggle mb-2 rounded-0"
                    type="button" data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
                    <div class="row">
                      <div class="col-12">
                        <span class="medium-grey-font">Enter option 2</span>
                      </div>
                    </div>
                  </button>
                  <ul class="dropdown-menu text-start shadow mt-5 dropdown-width-class " ngbDropdownMenu>
                    <li>
                      test
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-2">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-6">
                <div class="input-group">
                  <div class="form-floating">
                    <input class="select-box-btn-tech-ques1 ">
                  </div>
                </div>
              </div>
              <div class="col-3 text-center mt-2">
                <input type="checkbox" class="checkbox-class" name="" id="">
              </div>
              <div class="col-3 text-center mt-2">
                <input type="checkbox" class="checkbox-class" name="" id="">
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isSelectOption">
          <div class="col-md-6">
            <div class="row">
              <div class="col-10">
                <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="true">
                  <button class="btn select-box-btn-tech-ques  text-start dropdown-toggle mb-2 rounded-0"
                    type="button" data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
                    <div class="row">
                      <div class="col-12">
                        <span class="medium-grey-font">Enter option 3</span>
                      </div>
                    </div>
                  </button>
                  <ul class="dropdown-menu text-start shadow mt-5 dropdown-width-class " ngbDropdownMenu>
                    <li>
                      test
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-2 mt-2">
                <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-6">
                <div class="input-group">
                  <div class="form-floating">
                    <input class="select-box-btn-tech-ques1 ">
                  </div>
                </div>
              </div>
              <div class="col-3 text-center mt-2">
                <input type="checkbox" class="checkbox-class" name="" id="">
              </div>
              <div class="col-3 text-center mt-2">
                <input type="checkbox" class="checkbox-class" name="" id="">
              </div>
            </div>
          </div>
        </div> -->
          <div class="col-12 text-end mb-3" *ngIf="isShowOptionTextField(selectedQuestionTemplate?.responseType!)">
            <a class="link-class" (click)="addQuestionOption()">
              Add more options
            </a>
          </div>
          <div class="row mb-3 mt-2">
            <div class="col-11 pe-0">
              <div class="input-group">
                <div class="form-floating">
                  <input class="form-control" formControlName="openerComments" id="openerComments">
                  <label for="openerComments">
                    Comments for opener (Enter a new line for next line)
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
              [message]="errorMsg"></app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
              [message]="'Question saved successfully.'"></app-message-toast>
          </div>

          <div class="d-grid gap-2 my-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="col-12 text-end">
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              [disabled]="selectedAuction?.status == 'LIVE' || selectedAuction?.status == 'LIVE_WAIT'"
              (click)="saveQuestionTemplate()" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>DELETE QUESTION</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(deleteConfirmModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Are you sure you want to delete this question?</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
          message="Question deleted successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3"
          (click)="closeModal(deleteConfirmModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="deleteQuestionTemplate()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<app-loader #appLoader></app-loader>