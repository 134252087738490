<div class="row mx-0 mt-2 align-items-start justify-content-center" *ngIf="
    this.deploymentConfigurationDto?.multiLotAllowed &&
    !(rfxEntityDto?.noOfSubcategories == 1)
  ">
  <div class="col-md-1 col-2 p-0 text-md-end">
    <button class="scroll-left-icon-bidder" (click)="previousSubcategory()">
      <img src="assets/icons/leftarrow2_dark.svg" class="svg-icon-class c-pointer" />
    </button>
  </div>

  <div class="col-8 col-md-9 mt-1 ms-md-5">
    <swiper #swiperLots class="lot-swiper" [virtual]="true" [config]="swiperConfig" (slideChange)="onSlideChange()"
      *ngIf="isSubcategoryLoaded$ | async">
      <ng-template swiperSlide *ngFor="let item of rfxSubcategoryEntityDtos; index as i">
        <button class="btn px-3 py-2 btn-sm me-2" [ngClass]="{
            selected: rfxSubcategoryEntityDto?.subcategoryId== item?.subcategoryId,
            'danger-border': haveAnyLotErrors(item.subcategoryId!),
            'secondary-border': !haveAnyLotErrors(item.subcategoryId!)
          }" (click)="changeCurrentSubcategory(item.subcategoryId!, i)">
          <span [ngClass]="haveAnyLotErrors(item.subcategoryId!) ? '' : ''">
            {{ item?.subcategorySequence }}</span>
        </button>
      </ng-template>
    </swiper>
  </div>

  <div class="col-md-1 col-2 p-0 text-md-end">
    <button class="scroll-right-icon-bidder" (click)="nextSubcategory()">
      <img src="assets/icons/rightarrow2_dark.svg" class="svg-icon-class c-pointer" />
    </button>
  </div>
</div>