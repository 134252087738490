import { TenderFinancialQuestionTemplateDto } from "./TenderFinancialQuestionTemplateDto";
import { TenderQuestionnaireTemplateDto } from "./TenderQuestionnaireTemplateDto";
import { TenderSectionTemplateDto } from "./TenderSectionTemplateDto";
import { TenderTechnicalQuestionTemplateDto } from "./TenderTechnicalQuestionTemplateDto";

export class TenderQuestionnaireWrapperDto {
  tenderQuestionnaireTemplateDto?: TenderQuestionnaireTemplateDto;
  tenderSectionTemplateDtos?: Array<TenderSectionTemplateDto>;
  tenderTechnicalQuestionTemplateDtos?: Array<TenderTechnicalQuestionTemplateDto>;
  tenderFinancialQuestionTemplateDtos?: Array<TenderFinancialQuestionTemplateDto>;
}