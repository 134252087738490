import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { OrgOrganogramUiDto } from 'src/app/shared/models/OrgOrganogramUiDto';
import { UserUiDto } from 'src/app/shared/models/user/UserUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { UserService } from 'src/app/shared/services/user.service';
import { selectOrgOrganogramUiDto } from 'src/app/shared/state-management/onboarding/onboarding.features';
import { selectUserUiDto } from 'src/app/shared/state-management/session.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';

@Component({
  selector: 'app-onboarding-begin',
  templateUrl: './onboarding-begin.component.html',
  styleUrls: ['./onboarding-begin.component.sass']
})
export class OnboardingBeginComponent implements OnInit, AfterViewInit {
  events: any[] = [];
  isViewLoaded: boolean = false;

  userEntityInfoDto?: UserUiDto;
  orgOrganogramUiDto?: OrgOrganogramUiDto | null;

  constructor(
    private ngbModal: NgbModal,
    private userService: UserService,
    private onboardingService: OnboardingService,
    private drawerService: DrawerService,
    private changeDetectRef: ChangeDetectorRef,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.getUserUiDto()
  }

  ngAfterViewInit(): void {

    let companyUiDtos = this.orgOrganogramUiDto?.companyUiDtos ?? [];

    let companyStatus = 0;
    let plantStatus = 0;
    let budgetStatus = 0;

    if (companyUiDtos.length > 0) {
      let billingAddress = companyUiDtos[0].billingAddress ?? [];
      let shippingAddress = companyUiDtos[0].shippingAddress ?? [];

      if (billingAddress.length > 0 && shippingAddress.length == 0) {
        companyStatus = 25;
      }

      if (billingAddress.length > 0 && shippingAddress.length > 0) {
        companyStatus = 50;
      }

      if (companyUiDtos[0].companyAccountUiDtos && companyUiDtos[0].companyAccountUiDtos.length > 0) {
        companyStatus = 100;
      }

      // Plants
      let plantUiDtos = companyUiDtos[0].plantUiDtos ?? [];

      if (plantUiDtos.length > 0) {
        plantStatus = 25;

        let billingAddressPlants = plantUiDtos[0].billingAddress ?? [];
        let shippingAddressPlants = plantUiDtos[0].shippingAddress ?? [];
        let costCenterUiDtos = plantUiDtos[0].plantCostCenterUiDtos ?? [];

        if (billingAddressPlants.length > 0 && shippingAddressPlants.length == 0) {
          plantStatus = 50;
        }

        if (billingAddressPlants.length > 0 && shippingAddressPlants.length > 0) {
          plantStatus = 75;
        }

        // Plant budget
        if (costCenterUiDtos.length > 0) {
          budgetStatus = 100;
        }
      }
    }

    this.events = [
      { title: 'Create Company', subtitle: 'Provide your company basic/address and bank details', status: companyStatus },
      { title: 'Add Company Plants', subtitle: 'Provide your company basic/address and bank details', status: plantStatus },
      { title: 'Set/Manage budget', subtitle: 'Provide your company basic/address and bank details', status: budgetStatus },
    ];

    setTimeout(() => {
      this.isViewLoaded = true;
      this.changeDetectRef.detectChanges();
    }, 1000)
  }

  async getUserUiDto() {
    this.userEntityInfoDto = await firstValueFrom(this.store.select(selectUserUiDto));
  }


  async getOrgOrganogramUiDto() {
    this.orgOrganogramUiDto = await firstValueFrom(this.store.select(selectOrgOrganogramUiDto));
  }

  toggleDrawer() {
    if (this.orgOrganogramUiDto && this.orgOrganogramUiDto.companyUiDtos && this.orgOrganogramUiDto.companyUiDtos.length > 0) {
      let plantUiDtos = this.orgOrganogramUiDto.companyUiDtos[0].plantUiDtos;
      if (plantUiDtos && plantUiDtos.length > 0) {
        if (plantUiDtos[0].plantCostCenterUiDtos && plantUiDtos[0].plantCostCenterUiDtos.length > 0) {
          let costCenterBudget = plantUiDtos[0].plantCostCenterUiDtos[0].allocatedBudget;
          if (costCenterBudget == undefined) {
            this.openOnboardingDrawer(ApplicationConstants.ONBOARDING_NEW_BUDGET);
          }
        }
      } else {
        this.openOnboardingDrawer(ApplicationConstants.ONBOARDING_NEW_PLANT);
      }
    } else {
      this.openOnboardingDrawer(ApplicationConstants.ONBOARDING_NEW_COMPANY);
    }
  }

  openOnboardingDrawer(drawerPageName: string) {
    this.drawerService.openDrawer(drawerPageName);
    this.closeModal();
  }

  closeModal() {
    this.ngbModal.dismissAll()
  }
}
