import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvoiceRequestDto } from '../../models/InvoiceRequestDto';
import { BehaviorSubject } from 'rxjs';
import { AwardedBidderDto } from '../../models/AwardedBidderDto';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { DatePipe } from '@angular/common';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { AuctionLotSaveWrapperDto } from '../../models/user/AuctionLotSaveWrapperDto';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'app-awarded-bidders-lots',
  templateUrl: './awarded-bidders-lots.component.html',
  styleUrls: ['./awarded-bidders-lots.component.sass']
})
export class AwardedBiddersLotsComponent implements OnInit, AfterViewInit {
  awardedBidderDto!: AwardedBidderDto
  @Input() selectedAuction!: AuctionEntityDto

  selectedLotAllocationIds: string[] = []
  invoiceRequestDto$ = new BehaviorSubject<InvoiceRequestDto | null>(null);
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);

  formGroup: FormGroup


   isLoading: boolean = false;

  constructor(
    public invoiceService: InvoiceService,
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private adminService: AdminDashboardService
  ) {
    this.formGroup = this.fb.group({
      additionDetails: [''],
      termsAndCondition: [''],
      qrCode: [false],
      invoiceDate: ['', Validators.required],
      invoiceTo: ['', Validators.required],
    })
  }

  ngOnInit(): void {

    this.invoiceService.getAwardedBidderDto$.subscribe(data => {
      if(data){
        this.awardedBidderDto = data;
        this.awardedBidderDto.awardedLots?.sort((a, b) => Number(a.lotSequenceNo!) - Number(b.lotSequenceNo!))
        this.selectedLotAllocationIds = this.awardedBidderDto.awardedLots!.map(item => item.lotId!);
        
        this.populateData();

        this.formGroup.valueChanges.subscribe(() => {
          this.refreshPreview();
        })
      }
    })
  }

  ngAfterViewInit(): void {
    this.populateData()
  }

  get fc() { return this.formGroup.controls; }

  closeModal() {
    this.activeModal.close();
  }

  populateData() {
    if (this.awardedBidderDto) {
      let invoiceDate = ApplicationUtils.getNgbPickerDate(this.datePipe.transform(new Date(), 'dd/MM/yyyy')!);
      this.fc['invoiceDate'].patchValue(invoiceDate);
      this.fc['invoiceTo'].patchValue(this.awardedBidderDto.bidderName)

      this.formGroup.updateValueAndValidity()

      this.refreshPreview();
    }
  }

  isLotSelected(lotId?: string): boolean {
    if (lotId) {
      return this.selectedLotAllocationIds.includes(lotId!);
    }
    return false;
  }

  toggleLotChecked(lotId?: string) {
    if (this.isLotSelected(lotId)) {
      let index = this.selectedLotAllocationIds.findIndex(item => item == lotId);
      this.selectedLotAllocationIds.splice(index, 1);
    } else {
      this.selectedLotAllocationIds.push(lotId!);
    }

    this.refreshPreview();
  }

  refreshPreview() {
    let formValue = this.formGroup.value;

    let invoiceRequestDto = new InvoiceRequestDto();
    invoiceRequestDto.auctionId = this.awardedBidderDto.auctionId
    invoiceRequestDto.lotIds = this.selectedLotAllocationIds;
    invoiceRequestDto.qrCode = formValue.qrCode;
    invoiceRequestDto.additionDetails = formValue.additionDetails;

    let termsAndCondition = (formValue.termsAndCondition as string).trim();

    if (termsAndCondition != '') {
      invoiceRequestDto.termsAndCondition = termsAndCondition.split('\n');
    } else {
      invoiceRequestDto.termsAndCondition = []
    }

    invoiceRequestDto.userId = this.awardedBidderDto.userId;
    invoiceRequestDto.invoiceDate = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, formValue.invoiceDate)!;
    invoiceRequestDto.invoiceTo = formValue.invoiceTo;

    this.invoiceRequestDto$.next(invoiceRequestDto)
  }

  downloadInvoice() {
    this._showSuccessToast$.next(false)
    this._showErrorToast$.next(false)
    this._errorMsg$.next("")

    if (this.formGroup.invalid || this.selectedLotAllocationIds.length == 0) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    let invoiceRequest = this.invoiceRequestDto$.value;
    this.adminService.downloadInvoice(invoiceRequest!).subscribe({
      next: (data) => {
        this.isLoading = false;

        if (data) {
          this._showSuccessToast$.next(true);
          this._errorMsg$.next("Invoice downloaded successfully.")

          let file = new Blob([data], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

          setTimeout(() => {
            this._showSuccessToast$.next(false);
          }, 1000)
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = true;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while downloading invoice. Try again.");
      }
    })
  }

  sendInvoice() {
    if (this.formGroup.invalid || this.selectedLotAllocationIds.length == 0) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    let invoiceRequest = this.invoiceRequestDto$.value;
    this.adminService.emailInvoice(invoiceRequest!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false;

          this._showSuccessToast$.next(true);
          this._errorMsg$.next("Invoice sent successfully.")

          setTimeout(() => {
            this._showSuccessToast$.next(false);
          }, 2000)
        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this._errorMsg$.next("Error while sending invoice. Try again.");
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = true;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while sending invoice. Try again.");
      }
    })
  }

}
