import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import { Pattern } from 'src/app/shared/util/Patterns';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { RfxTargetedBiddersNotificationEntityDto } from 'src/app/shared/models/RfxTargetedBiddersNotificationEntityDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { BidderDto } from 'src/app/shared/models/BidderDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { VendorEmailNotificationDto } from 'src/app/shared/models/rfx/VendorEmailNotificationDto';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.sass']
})
export class VendorDetailsComponent implements OnInit {

  rfxTargetedBiddersNotificationEntityDto?: RfxTargetedBiddersNotificationEntityDto;
  isLoading: boolean = false;
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");

  rfxSubcategoryEntityDto?: RfxSubcategoryUiDto
  rfxEntityDto?: RfxUiDto;
  selectedIndex: number | undefined = undefined;

  selectedRfxSubcategorySubscription$?: Subscription;

  organizationUiDto?: OrganizationWrapperUiDto;
  isEdit: boolean = false;

  bidderDtos: BidderDto[] = [];
  sourceBidderDtos: BidderDto[] = [];
  targetBidderDtos: BidderDto[] = [];


  constructor(private onboardingService: OnboardingService, private adminDashboardService: AdminDashboardService, private cdr: ChangeDetectorRef, private ngbModal: NgbModal, private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService) {

  }

  ngOnInit(): void {
    this.rfxEntityDto = this.adminSourcingEventsDataHolderService._selectedRfx$.value!;
    this.rfxSubcategoryEntityDto = this.adminRfxSubcategoryDataHolderService._selectedRfxSubcategory$.value!;

    this.selectedRfxSubcategorySubscription$ = this.adminRfxSubcategoryDataHolderService._selectedRfxSubcategory$.subscribe(rfxSubcategoryEntityDto => {
      this.rfxSubcategoryEntityDto = rfxSubcategoryEntityDto!;
      this.loadRfxTargetedBidders();
    })
    this.adminDashboardService._bidderLoadDataEvent$.next(true);
    this.adminDashboardService._bidderLoadDataEvent$.subscribe(data => {
      if (data == true) {
        this.loadBidders();
      }
    })
    this.onboardingService.getOrganizationUiDto$.subscribe(org => {
      this.organizationUiDto = org!;
      if (org) {
        this.loadBidders();
      }
    })

  }



  async openRfxTargetedBiddersModal(content: any, type: boolean) {
    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);



    this.ngbModal.open(content, { size: 'xl', backdrop: 'static', keyboard: false, centered: true });
  }

  loadRfxTargetedBidders() {
    this.isLoading = true
    this.adminRfxSubcategoryDataHolderService.getRfxTargetedBiddersNotifications(this.rfxSubcategoryEntityDto?.orgCode!, this.rfxSubcategoryEntityDto?.rfxId!, this.rfxSubcategoryEntityDto?.subcategoryId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.rfxTargetedBiddersNotificationEntityDto = apiResponseDto.data as RfxTargetedBiddersNotificationEntityDto;

          this.refreshBiddersDataList();
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      }
    })
  }

  deleteEmailId(emailId: string) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);
    let tempRfxPremiumSuppliersDto = ApplicationUtils.clone(this.rfxTargetedBiddersNotificationEntityDto) as RfxTargetedBiddersNotificationEntityDto;

    if (this.rfxTargetedBiddersNotificationEntityDto && this.rfxTargetedBiddersNotificationEntityDto?.vendorList && this.rfxTargetedBiddersNotificationEntityDto?.vendorList.length > 0) {
      let index = tempRfxPremiumSuppliersDto?.vendorList!.findIndex(item => item.emailId!.trim().toLowerCase() == emailId.trim().toLowerCase());
      this.selectedIndex = index;
      tempRfxPremiumSuppliersDto?.vendorList?.splice(index, 1);
    }

    this.isLoading = true;

    this.adminRfxSubcategoryDataHolderService.saveRfxTargetedBiddersNotifications(tempRfxPremiumSuppliersDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {

        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.loadRfxTargetedBidders();
          this._showSuccessToast$.next(true);
          this.selectedIndex = undefined;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this.isLoading = false;
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.selectedIndex = undefined;
        }
      },
      error: (error) => {

        this.isLoading = false;
        console.error(error);
        this._showErrorMsg$.next("Error while deleting email");
        this._showErrorToast$.next(true);
        this.selectedIndex = undefined;
      }
    })
  }


  saveRfxPremiumSuppliers() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    let tempRfxPremiumSuppliersDto = ApplicationUtils.clone(this.rfxTargetedBiddersNotificationEntityDto) as RfxTargetedBiddersNotificationEntityDto;

    if (!tempRfxPremiumSuppliersDto) {
      tempRfxPremiumSuppliersDto = new RfxTargetedBiddersNotificationEntityDto();
    }
    tempRfxPremiumSuppliersDto!.auctionHouseId = this.rfxSubcategoryEntityDto?.orgCode
    tempRfxPremiumSuppliersDto!.rfxId = this.rfxSubcategoryEntityDto?.rfxId
    tempRfxPremiumSuppliersDto!.rfxSubcategoryId = this.rfxSubcategoryEntityDto?.subcategoryId
    tempRfxPremiumSuppliersDto!.vendorList = this.convertVenderList(this.targetBidderDtos!);

    this.isLoading = true;

    this.adminRfxSubcategoryDataHolderService.saveRfxTargetedBiddersNotifications(tempRfxPremiumSuppliersDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {

          this.loadRfxTargetedBidders();
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this.isLoading = false;
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
        this._showErrorMsg$.next("Error Saving EmailIds");
        this._showErrorToast$.next(true);
      }
    })
  }

  convertVenderList(bidderDtos: BidderDto[]) {
    let vendorList: VendorEmailNotificationDto[] = [];
    if (bidderDtos && bidderDtos.length > 0) {
      bidderDtos.forEach(item => {
        let vendorEmailNotificationDto = new VendorEmailNotificationDto();
        vendorEmailNotificationDto.companyName = item.companyName;
        vendorEmailNotificationDto.emailId = item.primaryEmailId;
        vendorEmailNotificationDto.userId = item.userId;
        if (item.mobileList && item.mobileList.length > 0) {
          vendorEmailNotificationDto.mobileWrapper = item.mobileList![0];
        }

        vendorList.push(vendorEmailNotificationDto);
      })
    }
    return vendorList;
  }

  removeSpaces(emailString: string): string {
    return emailString.replace(/\s/g, '');
  }


  async loadBidders() {
    try {
      this.isLoading = true;
      if (!this.organizationUiDto?.orgCode) {
        return;
      }
      let apiResponseDto = await firstValueFrom(this.adminDashboardService.getBidderListData(this.organizationUiDto?.orgCode!));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.bidderDtos = apiResponseDto.data as BidderDto[];
        this.populateBidderList();
      }
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  }


  private refreshBiddersDataList() {
    if (this.rfxTargetedBiddersNotificationEntityDto && this.rfxTargetedBiddersNotificationEntityDto.vendorList?.length! > 0) {

      let userIds = this.rfxTargetedBiddersNotificationEntityDto.vendorList!.map(item => item.emailId);
      console.log(this.bidderDtos.map(item => item.primaryEmailId))
      this.targetBidderDtos = this.bidderDtos.filter(item => userIds.includes(item.primaryEmailId!));

      if (this.sourceBidderDtos && this.sourceBidderDtos.length > 0) {
        let userIds = this.targetBidderDtos.map(item => item.userId);
        this.sourceBidderDtos = this.sourceBidderDtos.filter(item => !userIds.includes(item.userId));
      } else {
        this.sourceBidderDtos = [];
      }

    } else {
      this.targetBidderDtos = []
    }
  }

  private populateBidderList() {
    this.sourceBidderDtos = this.bidderDtos;
    this.refreshBiddersDataList()
  }

  //   removeDuplicateEmails(vendorList: VendorEmailNotificationDto[]) {
  //     // Use a Set to automatically remove duplicates
  //     const uniqueValidEmails: Set<VendorEmailNotificationDto> = new Set();
  //
  //     vendorList.forEach((vendorData) => {
  //       // Check if the email is valid
  //       let isValidEmailId = this.isValidEmail(vendorData.emailId)
  //       if (isValidEmailId) {
  //         // Add the valid email to the set
  //         uniqueValidEmails.add(vendorData);
  //       }
  //     });
  //
  //       // Convert the set back to an array
  //    const result: VendorEmailNotificationDto[] = [...uniqueValidEmails];
  //
  //     return result;
  //   }
  //
  //    isValidEmail(email: string): boolean {
  //     // Regular expression to check if the email is valid
  //     const emailRegex = new RegExp(Pattern.email);
  //     return emailRegex.test(email);
  //   }



  closeModal() {
    this.ngbModal.dismissAll();
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT && !this.rfxEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.rfxEntityDto?.status == RfxStatus.LIVE && !this.rfxEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }

}



