import { TranslateService } from './../../../shared/services/translate.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { FileService } from 'src/app/shared/services/file.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxValidationErrorCodeDto } from 'src/app/shared/models/user/RfxValidationErrorCodeDto';
import { RfxSaveWrapperDto } from 'src/app/shared/models/user/RfxSaveWrapperDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';


@Component({
  selector: 'app-rfx-manage-document',
  templateUrl: './rfx-manage-document.component.html',
  styleUrls: ['./rfx-manage-document.component.sass']
})
export class RfxManageDocumentComponent implements OnInit {

  rfxEntityDto?: RfxUiDto;
  rfxValidationErrorCodeDtoList?: RfxValidationErrorCodeDto[];

  documentTypes = ['application/pdf'];
  fileUploadError: boolean = false;
  errorMsg: string | undefined;
  currentFileInfoDto?: FileInfoDto | null;
  currentFile?: File | null;

  isDataLoading?: boolean = true;
  isLoading?: boolean = false;
  isEditDocument?: boolean = false;
  currentIndex?: number;
  selectedListingContactId?: string;

  selectedFileInfoDto?: FileInfoDto | null;
  rfxDocuments: Array<FileInfoDto> = [];

  _isOrderActive$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _fileDisplayName$ = new BehaviorSubject<string | null>("");
  _isSaveButtonDisable$ = new BehaviorSubject<boolean>(false);
  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  selectedRfxSubscription$?: Subscription;

  rfxDocTitle: FormControl = new FormControl('', Validators.required);

  @ViewChild('rfxDoc') rfxDoc?: HTMLInputElement;

  constructor(
    private transalteService: TranslateService,
    private ngbModal: NgbModal,
    private fileService: FileService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminDashboardService: AdminDashboardService,
  ) { }

  ngOnInit(): void {
    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe((data) => {
      if (data) {
        this.rfxEntityDto = data;

        if (this.rfxEntityDto.rfxDocs) {
          this.rfxDocuments = [...this.rfxEntityDto.rfxDocs!];
        }
      }
    });
  }

  populateDocumentData() {
    this.selectedFileInfoDto = this.currentFileInfoDto;
    this.rfxDocTitle.setValue(this.currentFileInfoDto?.displayName);
    this._fileDisplayName$.next(this.currentFileInfoDto?.fileName!);
  }

  formValidationFailed() {
    this._validationErrorPresent$.next(false);
    if (!this._fileDisplayName$.value) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  openSortingModal(content: any) {
    this.rfxDocuments = [...this.rfxEntityDto?.rfxDocs!]
    this._isOrderActive$.next(true);
    this.ngbModal.open(content, { size: 'md', backdrop: 'static', keyboard: false , centered: true });
  }

  openAddModal(content: any, document?: FileInfoDto) {
    this._isOrderActive$.next(false);

    if (document) {
      this.currentFileInfoDto = document;
      this.isEditDocument = true;
      this.populateDocumentData();
    }else{
      this.rfxDocTitle.reset();
      this.currentFileInfoDto = null;
      this.isEditDocument = false;
      this.selectedFileInfoDto =null;
      this._fileDisplayName$.next(null);
      this._validationErrorPresent$.next(false);

    }

    this.ngbModal.open(content, { size: 'md', backdrop: 'static', keyboard: false , centered: true });
  }

  closeModal() {
    this.ngbModal.dismissAll();
    this.currentIndex = undefined;
  }

  hideLoader() {
    this.isLoading = false;
    this.isDataLoading = false;
    this._isOrderActive$.next(false);
    this._isSaveButtonDisable$.next(false);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rfxDocuments, event.previousIndex, event.currentIndex);
  }

  previewRfxDoc(type: string) {
    if (type == 'LOCAL') {
      var fileURL = URL.createObjectURL(this.currentFile!);
      window.open(fileURL, '_blank');

    } else if (type == 'SERVER') {
      this.isLoading = true;

      this.fileService.downloadFile(this.currentFileInfoDto?.fileId!).subscribe({
        next: (response) => {
          if (response) {
            this.isLoading = false;
            let file = new Blob([response], { type: this.currentFileInfoDto?.fileType });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
          }
        },
        error: (err) => {
          console.error(err);
          console.log("Error while previewing document");
        }
      });
    }
  }

  chooseFile(event: any) {
    this._showErrorToast$.next(false);
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this._showErrorToast$.next(true);
      this.currentFile = null;
      this.rfxDoc?.setAttribute('value', '');
      this._fileDisplayName$.next(null);


      return;
    }

    this._fileDisplayName$.next(this.getFileName(this.currentFile?.name!) as string);
  }

  getFileName(fileName: string){
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0 , fileName.lastIndexOf('.'));
    let finalFileName = ApplicationUtils.removeSpecialCharacters(name!) as string
    return finalFileName+""+extensionName;
  }

  removeRfxDoc() {
    this.currentFileInfoDto = null;
    this.currentFile = null;
    this.rfxDoc?.setAttribute('value', '');
    this._fileDisplayName$.next("");
  }

  handleValidSubmit() {
    if (!this.isAllowToEdit()) {
      return;
    }

    let isUploadAction = false;
    if (this._isOrderActive$.value == false) {
      if (this.formValidationFailed()) {
        return;
      }
      if (this.currentFile) {
        isUploadAction = true;
        this.isLoading = true;
      } else {
        this.isLoading = true;
      }

    } else {
      this.isLoading = true;
    }

    this._isSaveButtonDisable$.next(true);
    if (isUploadAction == true) {
      this.uploadFile(this.currentFile!);
    } else {
      let rfxEntityDto: RfxUiDto = ApplicationUtils.clone(this.rfxEntityDto);

      if (this._isOrderActive$.value) {
        rfxEntityDto.rfxDocs = this.rfxDocuments;
      }

      if (this.currentFileInfoDto && this.isEditDocument) {
        if (this.rfxDocTitle.value) {
          let rfxDocDto = rfxEntityDto?.rfxDocs?.find(item => item?.fileId == this.currentFileInfoDto?.fileId);
          this.currentFileInfoDto!.displayName = this.rfxDocTitle.value;
          Object.assign(rfxDocDto!,this.currentFileInfoDto);
        }
      }

      this.saveRfxDocument(rfxEntityDto);
    }
  }

  uploadFile(currentFile: File) {
    this.fileUploadError = false;
    this.errorMsg = "";

    let metaData = {
      'mimeType': currentFile.type,
      'version': 0,
      'publicApi': true,
      'dataType': 'Square'
    };

    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append('metaData', JSON.stringify(metaData));

    this.fileService.uploadFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        let fileSaveResponseDto = apiResponseDto.data as FileSaveResponseDto;
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          let rfxEntityDto = ApplicationUtils.clone(this.rfxEntityDto) as RfxUiDto;

          if (!rfxEntityDto?.rfxDocs) {
            rfxEntityDto!.rfxDocs = [];
          }

          if (!this.isEditDocument) {
            this.currentFileInfoDto = new FileInfoDto();
            rfxEntityDto?.rfxDocs!.push(this.currentFileInfoDto!);
          } else {
            this.currentFileInfoDto = rfxEntityDto?.rfxDocs!.find(item => item.fileId == this.selectedFileInfoDto?.fileId);
          }

          this.currentFileInfoDto!.fileId = fileSaveResponseDto.fileId;
          this.currentFileInfoDto!.fileName = fileSaveResponseDto.fileName;
          this.currentFileInfoDto!.fileSize = currentFile.size.toString();
          this.currentFileInfoDto!.fileType = currentFile.type;
          this.currentFileInfoDto!.uploadDate = new Date().toDateString();
          this.currentFile = null;

          if (this.rfxDocTitle.value) {
            this.currentFileInfoDto!.displayName = this.rfxDocTitle.value;
          } else {
            this.currentFileInfoDto!.displayName = fileSaveResponseDto.fileName;
          }

          this.saveRfxDocument(rfxEntityDto);
        }
      } else {
        this.fileUploadError = true;
        this._isSaveButtonDisable$.next(false);
      }
    })
  }

  saveRfxDocument(rfxEntityDto: RfxUiDto) {
    this._showErrorToast$.next(false);
    this.errorMsg = "";

    this.adminDashboardService.saveRfxDetails(rfxEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let rfxSaveWrapperDto = apiResponseDto.data as RfxSaveWrapperDto;
          let rfxValidationErrorCodeDtoList = rfxSaveWrapperDto.rfxValidationErrorCodeDtos;
          if (!rfxValidationErrorCodeDtoList || rfxValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.isDataLoading = false;
              this._isOrderActive$.next(false);
              this._isSaveButtonDisable$.next(false);
              this.closeModal();;
            }, 2000)
          } else {
            this.isLoading = false;
            this.hideLoader();
            this.closeModal();
            this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.hideLoader();

        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while saving Document. Try again.";
        this._showErrorToast$.next(true);
      }
    })
  }

  openErrorModel() {
    let errorModalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
      });
    errorModalRef.componentInstance.auctionValidationErrorCodeDtoList = this.rfxValidationErrorCodeDtoList;
  }

  getErrorMsg(error: RfxValidationErrorCodeDto) {
    return ApplicationUtils.populateMessage(error, this.transalteService)
  }

  deleteRfxDocument(index: number) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.currentIndex = index;
    this.isDataLoading = true;
    this._isSaveButtonDisable$.next(true);

    let rfxEntityDto = ApplicationUtils.clone(this.rfxEntityDto);
    rfxEntityDto?.rfxDocs?.splice(index, 1);

    this.adminDashboardService.saveRfxDetails(rfxEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto) {
          if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
            let rfxSaveWrapperDto = apiResponseDto.data as RfxSaveWrapperDto;
            let rfxValidationErrorCodeDtoList = rfxSaveWrapperDto.rfxValidationErrorCodeDtos;
            if (!rfxValidationErrorCodeDtoList || rfxValidationErrorCodeDtoList.length == 0) {
              this._isSaveButtonDisable$.next(false);
              this.isDataLoading = false;
            } else {
              this.isDataLoading = false;
              this._isSaveButtonDisable$.next(false);
              this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
              this.openErrorModel();
            }

          } else {
            this.isDataLoading = false;
            this._isSaveButtonDisable$.next(false);
          }
        } else {
          this.isDataLoading = false;
          this._isSaveButtonDisable$.next(false);
        }
        this.currentIndex = undefined;
      },
      error: (err) => {
        this.isDataLoading = false;
        this.currentIndex = undefined;
        this._isSaveButtonDisable$.next(false);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT && !this.rfxEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.rfxEntityDto?.status == RfxStatus.LIVE && !this.rfxEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }

}
