<div class="mb-2">
    <div class="p-input-icon-left mb-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText  class="" id="onSearchInput" placeholder="Search Category" (input)="onSearchInput($event)" />
    </div>

<div class="scroll-card-multilot my-2">
    <p-tree [value]="nodes" [selectionMode]="selectionMode" [(selection)]="selectedFiles" [loading]="loading"
    (onNodeSelect)="onNodeSelect($event)" (onNodeUnselect)="onNodeUnselect($event)"></p-tree>
</div>
</div>