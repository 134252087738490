import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function StringMinValidator(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      if (typeof(control.value) === 'string') {
        let val = control.value ? Number(control.value.replace(/[^0-9.-]+/g, "")) : 0;
        const forbidden = val < min;
        return forbidden ? { forbiddenMin: { value: control.value } } : null;
      } else {
        const forbidden = control.value < min;
        return forbidden ? { forbiddenMin: { value: control.value } } : null;
      }
    } else {
      return null;
    }
  };
}
