import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MaterialUiDto } from 'src/app/shared/models/MaterialUiDto';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';

@Component({
  selector: 'app-admin-material',
  templateUrl: './admin-material.component.html',
  styleUrls: ['./admin-material.component.sass']
})
export class AdminMaterialComponent implements OnInit, OnDestroy {
  organizationUiDto?: OrganizationWrapperUiDto;
  materialUiDtos: MaterialUiDto[] = [];

  isDataLoading: boolean = false;

  materialsListSubscription$?: Subscription;

  constructor(
    private onboardingService: OnboardingService,
    private drawerService: DrawerService,
    private adminDashboardService: AdminDashboardService
  ) { }

  ngOnInit(): void {
    this.organizationUiDto = this.onboardingService.getOrganizationUiDto;
    this.loadInitData();

    this.materialsListSubscription$ = this.adminDashboardService.getMaterialUiDtos$.subscribe(data => {
      if (data) {
        this.materialUiDtos = data;
      } else {
        this.materialUiDtos = [];
      }
    })
  }

  async loadInitData() {
    this.isDataLoading = true;
    await this.adminDashboardService.loadMaterialUiDtosByOrgCode(this.organizationUiDto?.orgCode!);
    await this.onboardingService.loadCompanyUiDtosByOrgCode();
    await this.onboardingService.loadPlantUiDtosByOrgCode(this.organizationUiDto?.orgCode!);
    this.isDataLoading = false;
  }

  openNewMaterialDrawer(selectedMaterialUiDto?: MaterialUiDto) {
    if (selectedMaterialUiDto) {
      this.adminDashboardService.setCurrentMaterialUiDto(selectedMaterialUiDto);
      this.drawerService.isFormEditMode(true);
    } else {
      this.drawerService.isFormEditMode(false);
    }

    this.drawerService.openDrawer('NEW_MATERIAL_PAGE');
  }

  ngOnDestroy(): void {
    if (this.materialsListSubscription$) {
      this.materialsListSubscription$.unsubscribe();
    }
  }
}
