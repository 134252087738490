<div class=" col-12 p-2">
  <div class=" bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
    <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
      <div class="grid grid-cols-3 gap-4 w-full">
        <div class="col-span-2 text-gray-500 font-semibold leading-4 tracking-tight text-base">
          <span class="">
            <img src="/assets/icons/tr-basic-detail.svg" class="tr-md-icon inline-flex">
          </span>
          Opportunity Fee
        </div>

        <div class="text-end  text-sm font-medium leading-4 text-blue-100 tracking-tight ">
          <span class="c-pointer" (click)="openEditOpportunityDrawer()">
            <i-lucide name="pencil" [size]="15" class="c-pointer my-icon inline-flex"></i-lucide>
            Edit
          </span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full p-4">
      <div class="">
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Payment Mode
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
           {{tenderOpportunityWrapperDto?.tenderFeeDetails?.tenderFeePaymentMode | titleCase}}
          </div>
        </div>

        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Tender Fee (Optional)
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
           {{tenderOpportunityWrapperDto?.tenderFeeDetails?.tenderFee | priceFormat }}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Surcharges (Optional)
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
           {{tenderOpportunityWrapperDto?.tenderFeeDetails?.surcharges | priceFormat }}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Tender Fee payable to
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.tenderFeeDetails?.tenderFeePayableTo}}
          </div>
        </div>

      </div>
      <div class="">
        <div class="grid grid-cols-3 gap-4 w-full" *ngIf="tenderOpportunityWrapperDto?.tenderFeeDetails?.tenderFeePaymentMode == 'OFFLINE'">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Payment Mode offline option
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4" *ngIf="tenderOpportunityWrapperDto?.tenderFeeDetails?.offlinePaymentOptions">
           {{tenderOpportunityWrapperDto?.tenderFeeDetails?.offlinePaymentOptions?.join(',')}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Processing Fee (Optional)
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
           {{tenderOpportunityWrapperDto?.tenderFeeDetails?.processingFee | priceFormat}}
          </div>
        </div>

        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Any other taxes/charges(Optional)
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
           {{tenderOpportunityWrapperDto?.tenderFeeDetails?.otherCharges | priceFormat}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            Tender Fee payable at
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.tenderFeeDetails?.tenderFeePayableAt}}
          </div>
        </div>

      </div>
    </div>
    <div class="text-black font-semibold leading-normal text-base px-4">
      Earnest Money Deposit (EMD) Details
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full p-4">
      <div class="">
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            EMD Amount
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
           {{tenderOpportunityWrapperDto?.tenderFeeDetails?.emdAmount | priceFormat}}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            EMD Fee payable to
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.tenderFeeDetails?.emdFeePayableTo}}
          </div>
        </div>
      </div>
      <div class="">
        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            EMD Exemption Allowed
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.tenderFeeDetails?.allowEmdExemption? 'Yes' : 'No'}}
          </div>
        </div>

        <div class="grid grid-cols-3 gap-4 w-full">
          <div class=" text-black font-semibold leading-normal text-sm mb-4">
            EMD Fee payable at
          </div>
          <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
            {{tenderOpportunityWrapperDto?.tenderFeeDetails?.emdFeePayableAt}}
          </div>
        </div>


      </div>
    </div>

    <div class="mt-2 border border-gray-500" *ngIf="feeDocuments.length > 0">
      <div class=" text-black font-semibold leading-normal text-sm mb-4">
        Specific Documents
      </div>
      <p-table [value]="feeDocuments" [customSort]="true" [tableStyle]="{'min-width': '60rem'}">
        <ng-template pTemplate="header">
          <tr class="bg-white">
            <th pSortableColumn="code" class=""></th>
            <th pSortableColumn="code" class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
              Document Name
              <p-sortIcon field="code" class="float-end"></p-sortIcon>
            </th>
            <th pSortableColumn="name" class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
              Uploaded date
              <p-sortIcon field="name" class="float-end"></p-sortIcon>
            </th>
            <th pSortableColumn="quantity"
              class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td class="border-e">
              <div class="font-medium text-sm">
                {{i+1}}
              </div>
            </td>
            <td class="font-medium text-sm  underline ">
              {{item.displayName}}
            </td>
            <td class="font-medium text-sm">
              {{item.uploadDate | ordinalDate}}
            </td>
            <td>
              <div>
                <p-progressSpinner styleClass="w-8 h-8" *ngIf="currentIndex == i && isNavLoading"></p-progressSpinner>
                <span (click)="previewDocument(item, i)" *ngIf="currentIndex != i">
                  <i-lucide name="eye" [size]="18" color="#0555F5" class="my-icon"></i-lucide>
                </span>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</div>