<div class="tr-new-team-card">
  <form class="needs-validation w-full" novalidate novalidate [formGroup]="formGroup" (keyup.enter)="saveTeamUiDto()">
    <div class="grid grid-cols-1 w-full gap-2 ">
      <div class="">
        <div class="mb-2">
          <label for="name" class="text-gray-700  text-sm font-semibold leading-4">Team Name</label>
          <div class="">
            <input
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
              type="text" id="name" placeholder="---" formControlName="name"
              [ngClass]="{'is-invalid': fc['name'].invalid && (fc['name'].dirty || fc['name'].touched)}">
          </div>
          <div *ngIf="fc['name'].invalid && (fc['name'].dirty || fc['name'].touched)">
            <p class="tr-validations" *ngIf="fc['name'].errors?.['required']">
              <span class="mr-2">
                <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
              </span>
              Required
            </p>
          </div>
        </div>
      </div>
      <div class="">
        <div class="mb-2">
          <label for="description" class="text-gray-700  text-sm font-semibold leading-4">
            Team Description
          </label>
          <div class="">
            <textarea type="text" class="w-full border rounded-md" id="description" rows="3" formControlName="description">
        </textarea>
            <!-- <div *ngIf="fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)">
        <p class="tr-validations" *ngIf="fc['firstName'].errors?.['required']">
          <span class="mr-2">
            <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
          </span>
          Required
        </p>
      </div> -->
          </div>
        </div>
      </div>

      <div class="mb-4">
        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" message="Team Saved"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="(this._showErrorMsg$ | async)">
          </app-message-toast>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>
        <div class="col-12 text-end mb-5" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            (click)="saveTeamUiDto()">Save</button>
        </div>
      </div>
    </div>
  </form>
</div>