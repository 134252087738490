import { TeamUiDto } from "./TeamUiDto";

export class UserTeamUiDto {
  companyCode?: string;
  teamId?: string;
  teamName?: string;
  description?: string;

  static convertFromTeamUiDto(teamUiDto: TeamUiDto): UserTeamUiDto {
    let userTeamUiDto = new UserTeamUiDto();
    userTeamUiDto.companyCode = teamUiDto.companyCode;
    userTeamUiDto.teamId = teamUiDto.teamId;
    userTeamUiDto.teamName = teamUiDto.name;
    userTeamUiDto.description = teamUiDto.description;
    return userTeamUiDto;
  }
}