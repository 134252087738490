<div class="col-12 col-lg-10 m-auto position-relative" *ngIf="_renderView$ | async">
<div class=" card card-ripple border-0 mb-3 shadow zoom rounded-20 bg-white h-358 c-pointer" (click)="navigateAuctionView()">
  <div class="row p-2">
    <div class="col-lg-6">
      <img *ngIf="selectedAuction?.auctionImage"
      src="/downloadLandingBlob?fileId={{selectedAuction?.auctionImage?.fileId}}"
      [alt]="selectedAuction?.auctionName" class="image-premium-auction"
      [ngClass]="{'img-portrait': selectedAuction?.auctionImage?.dataType == 'Portrait'}">
    <img *ngIf="!selectedAuction?.auctionImage" src="assets/images/quote01.png" alt="" class="image-premium-auction"
    >
    </div>
    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-4 text-start my-3 my-lg-0">
          <span class="button-class"
            [ngClass]="{'badge-warning-class' : selectedAuction?.status == 'LIVE_WAIT', 'badge-primary-class': selectedAuction?.status == 'LIVE'}">
            {{selectedAuction?.status == 'LIVE_WAIT' ? 'UPCOMING' : 'RUNNING'}}
          </span>
        </div>
        <div class="col-lg-8 text-lg-end text-start ps-3 ps-lg-0 mt-0 mt-3 mt-lg-0">
          <span class="btn-new-class badge me-3">
              <span class="" id="countdown{{counterFunction}}{{selectedAuction?.auctionId}}"
                *ngIf="selectedAuction?.status != 'CLOSE'">
                <span class="cust-text danger-clrr" id="headline{{counterFunction}}{{selectedAuction?.auctionId}}"></span>
                <span class=""
                  id="lotcountdown{{counterFunction}}{{selectedAuction?.auctionId}}">
                  <span class="cust-text danger-clrr "
                    id="days{{counterFunction}}{{selectedAuction?.auctionId}}"></span>
                  <span class="cust-text danger-clrr "
                    id="hours{{counterFunction}}{{selectedAuction?.auctionId}}"></span>
                  <span class="cust-text danger-clrr "
                    id="minutes{{counterFunction}}{{selectedAuction?.auctionId}}"></span>
                  <span class="cust-text danger-clrr "
                    id="second{{counterFunction}}{{selectedAuction?.auctionId}}"></span>
                </span>
              </span>

              <div class="badge  p-2 rounded dark-btn-position-premium-auction "
                *ngIf="selectedAuction?.status == 'CLOSE'">
                <span class="">Closed</span>
              </div>

          </span>


          <span class="btn-new-class badge me-3" *ngIf="selectedAuction!.noOfActiveLots! > 1 "> {{ selectedAuction?.noOfActiveLots+" Lots"}}</span>
          <span class="btn-new-class badge ">

            <!-- <img src="assets/icons/hoticon.svg" class="svg-icon-class-small c-pointer" *ngIf="auctionCardWrapperDto?.totalBids && auctionCardWrapperDto?.totalBids! > 10">
             {{auctionCardWrapperDto?.totalBids}} Bids -->

          <!-- <span class="premium-lots-position p-2 badge text-white rounded-0" *ngIf="selectedAuction!.noOfActiveLots! > 1 ">
            {{ selectedAuction?.noOfActiveLots+" Lots"}}
          </span> -->
          </span>
        </div>
        <div class="col-lg-6 text-start  mt-3 mt-lg-2">
         <div class="div-class overflow-class">
          {{selectedAuction?.sequenceNo}} # {{selectedAuction?.auctionName}}
         </div>
        </div>
        <div class="col-lg-6 text-lg-end text-start mt-2">
            <div class="div-class"  *ngIf="selectedAuction?.addresses && selectedAuction?.addresses!.length > 0">
              <div class=" " ngbTooltip=" {{selectedAuction?.addresses![0].city}}, {{selectedAuction?.addresses![0].countryShortName}}">
                {{selectedAuction?.addresses![0].city}}, {{selectedAuction?.addresses![0].countryShortName}}</div>
          </div>
        </div>
        <div>

        </div>
      </div>
      <div class="row mt-2" >
        <div class="col-3 ps-2 ps-md-0" >

            <img src="/downloadLandingBlob?fileId={{selectedAuction?.listingContactDtos![0].profile?.fileId}}"
            alt="{{selectedAuction?.listingContactDtos![0].name}}"
            [ngClass]="screenWidth > 991 ? 'profile-image-xl' : 'profile-image-large'" >


        </div>
        <div class="col-6 mt-1 text-start ps-sm-4">
      <div class="overflow-class-small">
        <h4 class="heading-h6 text-capitalize mb-0" ngbTooltip="{{selectedAuction?.listingContactDtos![0].name}}">
          {{selectedAuction?.listingContactDtos![0].name}}</h4>
      </div>
      <div class="div-class-small overflow-class-small" ngbTooltip=" {{selectedAuction?.listingContactDtos![0].emailId}}">
        <img src="assets/icons/mail-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1 left-padding-icon">
         {{selectedAuction?.listingContactDtos![0].emailId}}
      </div>
      <div class="div-class-small overflow-class-small" ngbTooltip="  {{getCountryCode(selectedAuction?.listingContactDtos![0].countryCode)}} {{selectedAuction?.listingContactDtos![0].mobileNo}}">
        <img src="assets/icons/mobile-icon.svg" class="svg-icon-class-small c-pointer mt-0 me-1">
        {{getCountryCode(selectedAuction?.listingContactDtos![0].countryCode)}} {{selectedAuction?.listingContactDtos![0].mobileNo}}
      </div>
        </div>
        <div class="mt-2 col-lg-6 text-start ">
          <span class=" span-class">Start Date: </span>
          <span class=" span-class">{{convertDate(selectedAuction?.startDate!, selectedAuction?.startTime!)}}</span>
        </div>
        <div class="mt-2 col-lg-6 text-lg-end text-start">
          <span class=" span-class">End Date: </span>
          <span class=" span-class">{{convertDate(selectedAuction?.endDate!, selectedAuction?.endTime!)}}</span>
        </div>
      </div>
    </div>
  </div>

</div>
</div>
<div *ngIf="!(_renderView$ | async)"></div>
