export class NavigationEventsDto {
  sessionId?: string;
  contextName?: string;
  mainPageName?: string;
  pageName?: string;
  tabName?: string;
  resourceNo?: string
  subResourceNo?: string
  dashboardUrl?: string;
}
