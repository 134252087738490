import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CatalogueL1EntityDto } from 'src/app/shared/models/CatalogueL1EntityDto';
import { CatalogueL1WrapperDto } from 'src/app/shared/models/CatalogueL1WrapperDto';
import { CatalogueL2EntityDto } from 'src/app/shared/models/CatalogueL2EntityDto';
import { CatalogueLineItemsEntityDto } from 'src/app/shared/models/CatalogueLineItemsEntityDto';
import { CatalogueVendorDto } from 'src/app/shared/models/CatalogueVendorDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { CatalogueAddToCartComponent } from '../catalogue-add-to-cart/catalogue-add-to-cart.component';

@Component({
  selector: 'app-catalogue-details',
  templateUrl: './catalogue-details.component.html',
  styleUrls: ['./catalogue-details.component.sass']
})
export class CatalogueDetailsComponent implements OnInit, OnDestroy{
  isDataLoading: boolean = false;
  isLoading: boolean = false;
  errorMsg: string = '';

  catalogueL1Dto?: CatalogueL1EntityDto;
  catalogueL2Dto?: CatalogueL2EntityDto;
  selectedCatalogueLineItemDto?: CatalogueLineItemsEntityDto;
  catalogueLineItems: CatalogueLineItemsEntityDto[] = []

  addToCartModalRef?: NgbModalRef
  cartModalRef?: NgbModalRef
  vendorsModalRef?: NgbModalRef

  filteredCatalogueLineItems$ = new BehaviorSubject<CatalogueLineItemsEntityDto[]>([]);
  showSuccessToast$ = new BehaviorSubject<boolean>(false);
  showErrorToast$ = new BehaviorSubject<boolean>(false);
  
  catalogueL1Subscription$?: Subscription;
  catalogueL2Subscription$?: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private adminDashboardService: AdminDashboardService,
    private catalogueService: CatalogueService
  ) {}

  ngOnInit(): void {
    this.catalogueL1Subscription$ = this.catalogueService.getSelectedCatalogueL1$.subscribe(data => {
      if (data) {
        this.catalogueL1Dto = data;

        if (this.catalogueL1Dto && this.catalogueL2Dto) {
          this.loadCatalogueLineItems();
        }
      } else {
        this.catalogueL1Dto = undefined;
      }
    })

    this.catalogueL2Subscription$ = this.catalogueService.getSelectedCatalogueL2$.subscribe(data => {
      if (data) {
        this.catalogueL2Dto = data;

        if (this.catalogueL1Dto && this.catalogueL2Dto) {
          this.loadCatalogueLineItems();
        }
      } else {
        this.catalogueL2Dto = undefined;
      }
    })
  }

  getCartItemsCount() {
    return this.catalogueService.cartItems.length;
  }

  loadCatalogueLineItems() {
    this.isDataLoading = true;
    this.adminDashboardService.getCatalogueLineItemsList(this.catalogueL1Dto?.categoryL1Id!, this.catalogueL2Dto?.categoryL2Id!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as CatalogueLineItemsEntityDto[];

          this.isDataLoading = false;
          this.catalogueLineItems = data;
          this.filteredCatalogueLineItems$.next(data);
        } else {
          this.isDataLoading = false;
          this.catalogueLineItems = [];
          this.filteredCatalogueLineItems$.next([]);
        }
      },
      error: (error) => {
        console.error(error);
        this.isDataLoading = false;
        this.catalogueLineItems = [];
        this.filteredCatalogueLineItems$.next([]);
      }
    })
  }

  openAddToCartModal(item: CatalogueLineItemsEntityDto, quantity: string, content: any) {
    this.selectedCatalogueLineItemDto = item;
    this.catalogueService.addToCart(item, Number(quantity));

    this.addToCartModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close()
    } else {
      this.ngbModal.dismissAll();
    }
  }

  openCatalogueVendorsModal(content: any, catalogueLineItemDto: CatalogueLineItemsEntityDto) {
    this.selectedCatalogueLineItemDto = catalogueLineItemDto;

    this.vendorsModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openViewCartModal() {
    this.cartModalRef = this.ngbModal.open(CatalogueAddToCartComponent, {
      size: 'xl', backdrop: 'static', keyboard: false , centered: true
    });
  }

  getLowestVendor(item: CatalogueLineItemsEntityDto): CatalogueVendorDto {
    if (item.catalogueVendors!.length > 1) {
      let sortedVendors = item.catalogueVendors!.sort((a, b) => Number(a.amount!) - Number(b.amount!));
      return sortedVendors[0];
    }
    return item.catalogueVendors![0];
  }

  quantityItemPlus(input: HTMLInputElement) {
    input.value = (Number(input.value) + 1).toString();
  }

  quantityItemMinus(input: HTMLInputElement) {
    if (Number(input.value) == 1) {
      return;
    }
    input.value = (Number(input.value) - 1).toString();
  }
  
  ngOnDestroy(): void {
    if (this.catalogueL1Subscription$) {
      this.catalogueL1Subscription$.unsubscribe();
    }
    if (this.catalogueL2Subscription$) {
      this.catalogueL2Subscription$.unsubscribe();
    }
  }
}
