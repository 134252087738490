<div>
  <div class="col-12">
    <div class="row justify-content-end">
      <div class="col-auto my-3">
        <div class="dropdown me-3" ngbDropdown>
          <a ngbDropdownToggle class="link-class" container="body" [ngClass]="{'disabled' : isDownloading}">
            Bulk Upload
          </a>
          <ul class="scroll-area-lg mt-4" ngbDropdownMenu>
            <li class="c-pointer" (click)="downloadBulkSampleExcel()">
              <a class="dropdown-item" ngbDropdownItem>
                Download Sample Excel
              </a>
            </li>
            <hr class="hr">
            <li class="c-pointer" (click)="openUploadSampleExcelModal(uploadSampleExcelModal)">
              <a class="dropdown-item" ngbDropdownItem>
                Upload Sample Excel
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-auto my-3">
        <a class="link-class" [ngClass]="{'disabled' : !selectedSectionTemplate?.sectionName}"
          (click)="openAddQuestionModal(addQuestionTemplate)">
          Add Items
        </a>
      </div>
    </div>
  </div>

  <div class="border p-2">
    <div class="col-12 table-responsive">
      <table class="table table-bordered medium-font">
        <thead class="table-secondary">
          <tr>
            <th scope="col">Item No</th>
            <th scope="col">Item Name</th>
            <th scope="col">Item Description</th>
            <th scope="col">Qty</th>
            <th scope="col">Uom</th>
            <th scope="col">Tax Rate</th>
            <th scope="col">Action</th>
            <th scope="col">Re-order</th>
          </tr>
        </thead>
        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
          <tr class="text-center mb-1" *ngIf="financialQuestions.length == 0">
            <td colspan="8">
              <div class="small-font text-red-500 ">
                No Items is Available
              </div>
            </td>
          </tr>
          <tr *ngFor="let item of financialQuestions;" cdkDrag cdkDragLockAxis="y">
            <th scope="row">{{selectedSectionTemplate?.preSequenceText}}.{{item.sequenceNo}}</th>
            <td>{{item.questionText}}</td>
            <td>{{item.questionDescription}}</td>
            <td>{{item.quantity}}</td>
            <td>{{item.uom}}</td>
            <td>{{item.tax}}</td>
            <td>
              <a class="link-class" (click)="openAddQuestionModal(addQuestionTemplate, item)">Edit</a>
            </td>
            <td class="text-center">
              <img src="assets/icons/reorder_light.svg" class="svg-icon-class-small c-pointer" cdkDragHandle>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-12 text-center">
    <!-- <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3">
      Save as draft
    </button>
    <button class="btn btn-sm btn-outline-primary">
      Publish
    </button> -->
  </div>

  <!-- <div class="col-12 mt-3">
    <ol>
      <li class="text-red-500 " *ngFor="let item of questionnaireValidationList; index as i;">
        {{item?.message}}
      </li>
    </ol>
  </div> -->
</div>

<ng-template #addQuestionTemplate>
  <div class="model-body ">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>ADD ITEMS</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(addQuestionModalRef)">
        </div>
      </div>
    </header>
    <div class="header-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="col-12">
          <div class="row">
            <div class="col-md-5">
              <div class="row">
                <div class="col-4 font-weight-normal py-2 pe-md-0">
                  Section Name
                </div>
                <div class="col-8 div-class py-2 ps-md-0">
                  {{selectedSectionTemplate?.sectionName}}
                </div>
              </div>
            </div>

            <hr class="hr my-2">

            <div class="row align-items-center">
              <div class="col-md-7 text-md-end my-3">
                <span class="me-3">
                  <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="previousQuestion()"
                    [disabled]="isPrevButtonDisabled">
                    <img src="assets/icons/leftarrow2_dark.svg" class="svg-icon-class-small mar-btm c-pointer" />
                    Prev
                  </button>
                </span>
                <span class="me-3">
                  Q. <b>{{fc['sequenceNo'].value}}</b> of {{countFinancialQuestions}}
                </span>
                <span class="me-3">
                  <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="nextQuestion()" [disabled]="isNextButtonDisabled">
                    Next
                    <img src="assets/icons/rightarrow2_dark.svg" class="svg-icon-class-small mar-btm c-pointer" />
                  </button>
                </span>
              </div>
              <div class="col-md-5 text-md-end">
                <a class="link-class" (click)="addNewQuestion()" [ngClass]="{'disabled': newQuestionTemplate}">Add More
                  Items</a>
              </div>
            </div>

            <hr class="hr my-2">
          </div>
        </div>

        <form class="" [formGroup]="formGroup">
          <div class="row mb-3">
            <div class="col-11 pe-0">
              <div class="input-group">
                <span class="input-group-text border-end-0">
                  {{fc['preSequenceText'].value | uppercase}}.{{fc['sequenceNo'].value}}
                </span>
                <div class="form-floating">
                  <input class="form-control border-start-0" formControlName="questionText" id="questionText"
                    [ngClass]="{'border-danger': fc['questionText'].invalid && (fc['questionText'].dirty || fc['questionText'].touched)}">
                  <label for="questionText">Item Name</label>
                </div>
              </div>
            </div>
            <div class="col-1 mt-2" *ngIf="selectedQuestionTemplate?.questionId">
              <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
                (click)="openDeleteConfirmModal(deleteConfirmModal)">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-11 pe-0">
              <div class="input-group">
                <div class="form-floating">
                  <input class="form-control" formControlName="questionDescription" id="questionDescription"
                    [ngClass]="{'border-danger': fc['questionDescription'].invalid && (fc['questionDescription'].dirty || fc['questionDescription'].touched)}">
                  <label for="questionDescription">Item description</label>
                </div>
              </div>
            </div>
            <div class="col-1 mt-2">
            </div>
          </div>

          <div class="col-12 table-responsive">
            <table class="table medium-font">
              <thead>
                <tr>
                  <th scope="col">Quantity</th>
                  <th scope="col"> Unit of Measurement(Uom)</th>
                  <th scope="col">Tax Rate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="input-group">
                      <div class="form-floating">
                        <input class="form-control" formControlName="quantity" id="quantity"
                          [ngClass]="{'border-danger': fc['questionText'].invalid && (fc['questionText'].dirty || fc['questionText'].touched)}">
                      </div>
                    </div>
                    <div *ngIf="fc['quantity'].invalid && (fc['quantity']?.dirty || fc['quantity']?.touched)">
                      <p class="text-red-500 " *ngIf="fc['quantity']?.errors?.['required']">Required</p>
                      <p class="text-red-500 " *ngIf="fc['quantity']?.errors?.['pattern']">Invalid</p>
                    </div>
                  </td>
                  <td>
                    <div class="input-group">
                      <div class="form-floating">
                        <input class="form-control" formControlName="uom" id="uom"
                          [ngClass]="{'border-danger': fc['questionText'].invalid && (fc['questionText'].dirty || fc['questionText'].touched)}">
                      </div>
                    </div>
                    <div *ngIf="fc['uom'].invalid && (fc['uom']?.dirty || fc['uom']?.touched)">
                      <p class="text-red-500 " *ngIf="fc['uom']?.errors?.['required']">Required</p>
                    </div>
                  </td>
                  <td>
                    <div class="input-group">
                      <div class="form-floating">
                        <input class="form-control" formControlName="tax" id="tax">
                      </div>
                    </div>
                    <div *ngIf="fc['tax'].invalid && (fc['tax']?.dirty || fc['tax']?.touched)">
                      <p class="text-red-500 " *ngIf="fc['tax']?.errors?.['pattern']">Invalid</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div formArrayName="additionalPrices">
            <div class="row mb-2 align-items-center" *ngFor="let item of additionalPrices.controls; index as i"
              [formGroupName]="i">
              <div class="col-md-4">
                <div class="form-floating">
                  <input type="text" class="form-control" id="label" formControlName="label"
                    [ngClass]="{'is-invalid': item.get('label')?.invalid && (item.get('label')?.dirty || item.get('label')?.touched)}">
                  <label for="label">Label</label>
                  <div *ngIf="item.get('label')?.invalid && (item.get('label')?.dirty || item.get('label')?.touched)">
                    <p class="text-red-500 " *ngIf="item.get('label')?.errors?.['required']">Required</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div div class="row">
                  <div class="medium-font col-8">Mandatory?</div>
                  <div class="col-4">
                    <span class="form-switch c-pointer">
                      <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked"
                        formControlName="mandatory" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-4 text-end">
                <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
                  (click)="removeAdditionalPrices(i)">
              </div>
            </div>
            <div class="text-end">
              <a class="link-class" (click)="addAdditionalPrices()">
                Add more additional prices
              </a>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
              [message]="errorMsg"></app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
              [message]="'Items saved successfully.'"></app-message-toast>
          </div>

          <div class="d-grid gap-2 my-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="col-12 text-end">
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              [disabled]="selectedRfx?.status == 'LIVE' || selectedRfx?.status == 'LIVE_WAIT'"
              (click)="saveQuestionTemplate()" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
              Save
            </button>
          </div>

        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>DELETE ITEMS</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(deleteConfirmModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Are you sure you want to delete this item?</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
          message="Question deleted successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3"
          (click)="closeModal(deleteConfirmModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="deleteQuestionTemplate()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadSampleExcelModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>UPLOAD SAMPLE EXCEL</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(excelUploadConfirmModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="form-floating mb-3">
        <input type="file" class="form-control" id="bulkExcelFormControl" formControlName="bulkExcelFormControl"
          accept=".xlsx, .xls" (change)="onBulkExcelChange($event)"
          [ngClass]="{'is-invalid': bulkExcelFormControl.invalid && (bulkExcelFormControl.dirty || bulkExcelFormControl.touched)}">
        <label for="rfxTitle">Choose Excel File</label>
        <div *ngIf="bulkExcelFormControl.invalid && (bulkExcelFormControl.dirty || bulkExcelFormControl.touched)">
          <p class="text-red-500 " *ngIf="bulkExcelFormControl.errors?.['required']">Required</p>
        </div>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
          message="Excel uploaded successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3"
          (click)="closeModal(excelUploadConfirmModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="uploadBulkSampleExcel()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<app-loader #appLoader></app-loader>