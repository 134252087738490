<div class="bg-gray-100 gap-2.5 flex py-4 px-6  flex-col self-stretch">
  <div class="flex flex-row  w-full gap-4 justify-between items-center">
    <div class="my-3 text-xl font-semibold leading-4 text-gray-700">
      <div class=" nav-bg text-start">
        <ul class="" style="list-style: none;" class="ps-0">
          <li class="tr-list-class text-sm" (click)="toggleDetailsTab('MANAGE_USERS')"
            [ngClass]="currentTab == 'MANAGE_USERS' ? 'tr-tab-active' : 'tr-tab-default'">
            Manage Users
          </li>
          <li class="tr-list-class text-sm" (click)="toggleDetailsTab('ORGANIZATION_CHART')"
            [ngClass]="currentTab == 'ORGANIZATION_CHART' ? 'tr-tab-active' : 'tr-tab-default'">
            Organization Chart
          </li>
        </ul>
      </div>
    </div>
    <div class=" text-end">
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex" 
      (click)="openNewUserDrawer($event)">
      <i-lucide name="plus" [size]="18" color="#fff" class="my-icon"></i-lucide> 
        Add New User
      </button>
    </div>
  </div>
  <!-- (click)="openNewUserDrawer($event)" -->

  <div *ngIf="currentTab == 'MANAGE_USERS'">
    <div class="mt-2.5">
      <div class="grid md:grid-cols-3 grid-cols-1 w-full gap-4 justify-between items-center mb-4">
        <div class="">
          <div class="flex  flex-col self-stretch gap-4 bg-white rounded-lg p-6 text-center">
            <div class="text-blue-100 text-sm font-semibold">
              Active Users
            </div>
            <div class="text-black text-2xl font-bold">
              {{activeUsersCount}}
            </div>
          </div>
        </div>
        <div class="">
          <div class="flex  flex-col self-stretch gap-4 bg-white rounded-lg p-6 text-center">
            <div class="text-green-100 text-sm font-semibold">
              Admin users
            </div>
            <div class="text-black text-2xl font-bold">
              {{totalUsersCount}}
            </div>
          </div>
        </div>
        <div class="">
          <div class="flex  flex-col self-stretch gap-4 bg-white rounded-lg p-6 text-center">
            <div class="text-orange-100 text-sm font-semibold">
              Inactive users
            </div>
            <div class="text-black text-2xl font-bold">
              {{inActiveUsersCount}}
            </div>
          </div>
        </div>
      </div>
      <div>
        <p-table [value]="(_filteredUserUiDtos$|async)!" [customSort]="true"
          [tableStyle]="{'min-width': '60rem'}" [loading]="isDataLoading">
          <ng-template pTemplate="header">
            <tr class="bg-white">
              <th pSortableColumn="code" class="border-end"></th>
              <th pSortableColumn="code" class="border-end col-span-2 text-black font-normal leading-normal text-sm mb-4">S No <p-sortIcon field="code"
                  class="float-end"></p-sortIcon></th>
              <th pSortableColumn="name" class="border-end col-span-2 text-black font-normal leading-normal text-sm mb-4">Name <p-sortIcon
                  field="name"  class="float-end"></p-sortIcon></th>
              <th pSortableColumn="category" class="border-end col-span-2 text-black font-normal leading-normal text-sm mb-4">Designation<p-sortIcon
                  field="category"  class="float-end"></p-sortIcon></th>
              <th pSortableColumn="price" class="border-end col-span-2 text-black font-normal leading-normal text-sm mb-4">
                Status
                <p-sortIcon
                  field="quantity"  class="float-end tr-xs-icon"></p-sortIcon>
              </th>
              <!-- <th pSortableColumn="quantity" class="border-end col-span-2 text-black font-normal leading-normal text-sm mb-4">Action
                <p-sortIcon
                  field="quantity"  class="float-end"></p-sortIcon></th> -->
              <th pSortableColumn="quantity"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr>
              <td class="border-end"></td>
              <td>
                <div class="text-black text-base font-semibold ">
                  {{i+1}}
                </div>
               </td>
              <td> 
                <div class="text-black text-base font-semibold ">
                  {{item.firstName}} {{item.lastName}}
                </div>
                <div class="text-gray-900 text-sm font-normal">
                  {{item.primaryEmailId}}
                </div>
              </td>
              <td>
                <div class="text-black text-base font-semibold ">
                  {{formattedDesignation(item)}}
                </div>
                </td>
              <td>
                <div class="text-black text-base font-semibold ">
                  {{item.active ? 'Active' : 'Inactive'}}
                </div>
              </td>
              <td>
                <span>
                  <p-menu #menu [model]="createNewMenuItems" [popup]="true">
                    <ng-template pTemplate="item" let-item>
                      <div class="p-menuitem-link p-3 grid"  (click)="viewUserDetails(item)">
                        <div class="text-gray-900 font-semibold text-sm">
                          View
                        </div>
                      </div>
                      <div class="p-menuitem-link p-3 grid"  (click)="viewUserDetails(item)">
                        <div class="text-gray-900 font-semibold text-sm">
                          <span (click)="openConfirmationModal(enableAndDisableModel, item)">
                            {{item?.active ? "InActive" : "Active"}}</span>
                        </div>
                      </div>
                      <div class="p-menuitem-link p-3 grid" (click)="openExtendProfileDrawer($event, item)">
                        <div class="text-gray-900 font-semibold text-sm">
                          ExtendRole
                        </div>
                      </div>
                    </ng-template>
                  </p-menu>
                  <span (click)="menu.toggle($event)">
                    <img class="tr-s-icon" src="/assets/icons/elipsis_horizontal_light.svg" />
                  </span>
                 
                </span>
                <!-- <div>
                  <p-menu #menu [model]="tableActionItems" [popup]="true"></p-menu>
                  <button class="btn-class" (click)="menu.toggle($event)">
                    <img class="tr-s-icon" src="/assets/icons/elipsis_horizontal_light.svg" /> A
                  </button>
                </div> -->
                <!-- <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                  <button ngbDropdownToggle data-bs-toggle="dropdown" class="btn-class "><img class="tr-s-icon"
                      src="/assets/icons/elipsis_horizontal_light.svg" /></button>
                  <ul ngbDropdownMenu class="shadow mt-4">
                    <li ngbDropdownItem class="c-pointer" (click)="viewUserDetails(item)">
                      <span>
                        View
                      </span>
                    </li>
                    <hr class="hr my-2">
                    <li ngbDropdownItem class="c-pointer" (click)="viewUserDetails(item)">
                      <span (click)="openConfirmationModal(enableAndDisableModel, item)">
                        {{item?.active ? "InActive" : "Active"}}</span>
                    </li>
                    <hr class="hr my-2">
                    <li ngbDropdownItem class="c-pointer" (click)="openExtendProfileDrawer($event, item)">
                      <span>
                        ExtendRole
                      </span>
                    </li>

                  </ul>
                </div> -->


              </td>
              <!-- <td class="border-end"></td> -->
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div *ngIf="currentTab == 'ORGANIZATION_CHART'">
  </div>
</div>

<ng-template #enableAndDisableModel>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>{{userActiveFlag ? "DISABLE" : "ENABLE"}} USER</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-2">
        <div class="mb-2" *ngIf="!userActiveFlag">
          <p>Are you sure you want to enable <a
              class="link-class">{{this.selectedUserUiDto?.primaryEmailId}}</a> </p>
        </div>
        <div class="mb-2" *ngIf="userActiveFlag">
          <p ngbAutoFocus>Are you sure you want to disable <a
              class="link-class">{{this.selectedUserUiDto?.primaryEmailId}}</a></p>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>

        <div class="text-end mb-3" *ngIf="!isLoading">
          <button type="button" class="btn  btn-new-class btn-sm me-3" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="markResourceActiveOrInActive('ENABLE')"
            *ngIf="!userActiveFlag">ENABLE</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="markResourceActiveOrInActive('DISABLE')"
            *ngIf="userActiveFlag">DISABLE</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!-- <div class="container">
  <div class="">
    <div class="p-2">
      <div class="row align-items-center">
        <div class="col-md-3  text-start mb-2">
          <div class="mb-2 input-group">
            <input #search placeholder="Search" type="text" id="ctrlSearchPR" [formControl]="ctrlSearch"
              class="form-control border-end-0 rounded-0 border bg-white" />
            <span class="input-group-append">
              <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
                <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
              </button>
            </span>
          </div>
        </div>
        <div class="col-md-5 text-start mb-2">
        </div>
        <div class="col-md-4 mb-2 text-md-end">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mt-2 mt-sm-0" *ngIf="isShowUserView" (click)="backToAllUsersList()">
            BACK
          </button>
         
        </div>
      </div>
      <div class="col-12">
        <ng-container *ngTemplateOutlet="isShowUserView ? userDetailsTemplate : usersListTemplate">
        </ng-container>
      </div>
    </div>
  </div>
</div> -->

<ng-template #usersListTemplate>
  <div class="table-responsive">
    <table class="table table-bordered medium-font mb-0 align-middle">
      <thead class="table-dark">
        <tr class="text-center">
          <th class="text-center"><span>SNo</span></th>
          <th class=""><span>User Name</span></th>
          <th class=""><span>Designation</span></th>
          <th class=""><span>Email Id</span></th>
          <th>Status</th>
          <th class=""><span>Notification</span></th>
          <th class=""><span>Action</span></th>
        </tr>
      </thead>

      <tbody class="bg-white">
        <tr *ngIf="isDataLoading" class="align-middle">
          <td colspan="10" class="text-center">
            <div class="e-spinner"></div>
          </td>
        </tr>
        <tr class="text-center align-middle" *ngFor="let item of _filteredUserUiDtos$|async; index as i">
          <td class="">
            {{i+1}}
          </td>
          <td class="">
            {{item.firstName}} {{item.lastName}}
          </td>
          <td class="">
            {{item.designation}}
          </td>
          <td class="">
            {{item.primaryEmailId}}
          </td>
          <td class="">
            {{item.active ? 'Active' : 'Inactive'}}
          </td>
          <td class="">
            {{item.notificationActive ? 'Yes' : 'No'}}
          </td>
          <td class="">
            <a class="link-class" (click)="viewUserDetails(item)">
              View
            </a>
          </td>
        </tr>
        <tr *ngIf="!isDataLoading && userUiDtos.length == 0">
          <td colspan="10" class="text-center">
            <h5 class="heading-new">No Data Found</h5>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #userDetailsTemplate>
  <div class="container  p-3">
    <div class="col-12">
      <div class="border rounded bg-white mb-3">
        <header class="p-2">
          <div class="row ">
            <div class="col-md-6">
              <h5 class="heading-h5">USER DETAILS</h5>
            </div>
            <div class="col-md-6 text-md-end">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 " (click)="openUserDetail(editUserDetailTemplate)">
                EDIT
              </button>
            </div>
          </div>
        </header>
        <hr class="hr" />
        <div class="col-12">
          <div class="row mx-0">
            <div class="col-12 div-class py-2">
              User Information
            </div>
            <hr class="hr" />
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-4 font-weight-normal py-2">
                    Employee Code
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserUiDto?.empCode}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    User Name
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserUiDto?.firstName}} {{selectedUserUiDto?.lastName}}
                  </div>
                  <hr class="hr" />

                  <div class="col-4 font-weight-normal py-2">
                    Email Id
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserUiDto?.primaryEmailId}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Designation
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserUiDto!.designation}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Company Code
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserUiDto!.companyCode}}
                  </div>
                  <hr class="hr" />
                  <div class="col-4 font-weight-normal py-2">
                    Notification Active
                  </div>
                  <div class="col-8 div-class py-2">
                    {{selectedUserUiDto?.notificationActive ? 'Yes' : 'No'}}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-12 text-center">
                    <img src="/downloadLandingBlob?fileId=${{selectedUserUiDto?.profileImage?.fileId}}"
                      [alt]="selectedUserUiDto?.firstName" class="profile-image-class"
                      *ngIf="selectedUserUiDto?.profileImage">
                    <img alt="" src="assets/images/avatar05.jpg" class="profile-image-class"
                      *ngIf="!selectedUserUiDto?.profileImage">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editUserDetailTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>User Detail</h2>
        </div>
        <div class="col-auto text-end">
          <span [ngClass]="{'disabled' : isLoading }">
            <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
              (click)="closeModal()"></span>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup">
          <div class="row">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="firstName" placeholder="First Name"
                  formControlName="firstName"
                  [ngClass]="{'is-invalid': fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)}">
                <label for="firstName">First Name</label>
                <div *ngIf="fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)">
                  <p class="text-red-500 " *ngIf="fc['firstName'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="lastName" placeholder="Last Name" formControlName="lastName"
                  [ngClass]="{'is-invalid': fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)}">
                <label for="lastName">Last Name</label>
                <div *ngIf="fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)">
                  <p class="text-red-500 " *ngIf="fc['lastName'].errors?.['required']">Required
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="email" class="form-control" id="primaryEmailId" placeholder="name@example.com"
                  formControlName="primaryEmailId"
                  [ngClass]="{'is-invalid': fc['primaryEmailId'].invalid && (fc['primaryEmailId'].dirty || fc['primaryEmailId'].touched)}">
                <label for="primaryEmailId">Email</label>
                <div
                  *ngIf="fc['primaryEmailId'].invalid && (fc['primaryEmailId'].dirty || fc['primaryEmailId'].touched)">
                  <p class="text-red-500 " *ngIf="fc['primaryEmailId'].errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="fc['primaryEmailId'].errors?.['pattern']">
                    Invalid email address
                  </p>
                  <p class="text-red-500 " *ngIf="fc['primaryEmailId'].value && fc['primaryEmailId'].value.length > 50">
                    Maximum Length Exceed
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" id="empCode" formControlName="empCode"
                  placeholder="Employee Code">
                <label for="empCode">Employee Code</label>
              </div>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select name="Select Designation" class="form-control form-select" formControlName="designation"
              id="designation"
              [ngClass]="{'is-invalid': fc['designation'].invalid && (fc['designation'].dirty || fc['designation'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="designation?.name" *ngFor="let designation of applicationStartupDto?.cacheDesignationsDtos;">
                {{designation?.name}}
              </option>
            </select>
            <label for="designation">Designation</label>
            <div *ngIf="fc['designation'].invalid && (fc['designation'].dirty || fc['designation'].touched)">
              <p class="text-red-500 " *ngIf="fc['designation'].errors?.['required']">Required
              </p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <p-multiSelect [options]="(selectedCompanyUiDto?.plantUiDtos ?? [])" formControlName="plantCodes"
              optionLabel="plantName" optionValue="plantCode"></p-multiSelect>
          </div>

          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Onboarding User Saved'">
            </app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="col-12 text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800c-pointer">Update</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</ng-template>