import { Injectable } from '@angular/core';
import { ApplicationStartupDto } from '../models/ApplicationStartupDto';
import { Observable, tap, catchError, firstValueFrom } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ErrorService } from './error.service';
import { HttpClient } from '@angular/common/http';
import { ApplicationConstants } from '../util/ApplicationConstants';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStartupCacheService {
  private applicationStartupDto?: ApplicationStartupDto;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) { }

  get getApplicationStartupDto() { return this.applicationStartupDto; }

  updateApplicationStartupDto(applicationStartupDto: ApplicationStartupDto) {
    this.applicationStartupDto = applicationStartupDto;
  }

  async loadApplicationStartupDto() {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchApplicationStartup());
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.updateApplicationStartupDto(apiResponseDto.data!);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private fetchApplicationStartup(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>('startupCacheData').pipe(
      tap(_ => console.log("Get startupCacheData")),
      catchError(this.errorService.handleError<any>("Error while getting startupCacheData")));
  }
}
