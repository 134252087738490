import { AdminUserPrivilege } from "../../enums/AdminUserPrivilege";
import { FileInfoDto } from "../FileInfoDto";
import { MobileWrapper } from "./MobileWrapper";
import { UserPlantsRoleUiDto } from "./UserPlantsRoleUiDto";
import { UserTeamUiDto } from "./UserTeamUiDto";

export class UserUiDto {
  mobileWrapper?: MobileWrapper;
  orgCode?: string;
  theme?:string;
  companyCode?: string;
  plantCode?: string;
  designation?: string;
  userPrivileges?: AdminUserPrivilege[];
  appAdminCode?: number;
  firstName?: string;
  lastName?: string;
  userId?: string;
  empCode?: string;
  primaryEmailId?: string;
  active: boolean = true;
  notificationActive?: boolean;
  profileImage?: FileInfoDto;
  forcePasswordChange?: boolean;
  userTeamUiDtos?: UserTeamUiDto[];
  userExtendPrivilegeUiDtos?: UserPlantsRoleUiDto[];
  version?: number;
}
