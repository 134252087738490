import { Component } from '@angular/core';
import { EChartsOption } from 'echarts';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-new-admin-dashboard',
  templateUrl: './new-admin-dashboard.component.html',
  styleUrls: ['./new-admin-dashboard.component.sass']
})
export class NewAdminDashboardComponent {
  currentTab: string = 'TODAY';


  toggleDetailsTab(tab: string) {
    this.currentTab = tab;
  }

}
