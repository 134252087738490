<footer class="position-relative">
  <div class="footer-start-2">
    <div class="footer-class-one-2">
      <div class="position-realtive">
        <div class="row align-items-center">
          <div class="col-md-5 border-md-end text-center rotate-class bg-color">
            <div class="height-100-2">
              <div class="pt-5 text-white row justify-content-center  rotate-text">
                <div class="col-1">
                  <img src="assets/icons/share_danger.svg" class="svg-icon-large me-2 c-pointer">
                </div>
                <div class=" col-md-5">
                  <span class="span-class-live">
                    <small>
                      Call Us:
                    </small>

                  </span> <br>
                  <span class="span-class-live">
                    <b>+1 302 415 3184</b></span>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-7 ps-5">
            <div class="text-red-500 ">
              Auction as Per your Requirement
            </div>
            <div class="text-white">

              A Platform for All - Auctioneers, Realtors, Auction<br> House, Asset Managers, Financial Institutions,
              etc.
            </div>
            <div class="mt-2">
              <button class="btn-sm btn-outline-primary btn">Book For Free Demo</button>
            </div>

          </div>
        </div>
        <!-- <div class="whatsapp-icon-position cta-icon-wrap ">
        <img src="assets/icons/whatsapp.svg" class="svg-icon-xlarge c-pointer">
      </div> -->
        <a href="" class="btn-whatsapp-pulse-2">
          <!-- <fa-icon [icon]="['fas', 'phone']" class=" fa-lg"></fa-icon> -->
        </a>


      </div>
    </div>
    <div class="footer-class-two-2">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-4 border-md-end text-center">
          <div class="my-4 text-white row justify-content-center">
            <div class="col-md-1">
              <img src="assets/icons/clock.svg" class="svg-icon-large me-2 c-pointer">
            </div>
            <div class="col-md-3">
              <span class="span-class-live">Availability</span> <br>
              <span class="span-class-live">24 x 7</span>
            </div>

          </div>
        </div>
        <div class="col-md-3 text-center border-md-end">
          <div class="my-4 text-white row ">
            <div class="col-md-1">
              <img src="assets/icons/mail_primary.svg" class="svg-icon-large me-2 c-pointer">
            </div>
            <div class="col-md-6">
              <span class="span-class-live">Support Mail</span> <br>
              <span class="span-class-live">neha@gmail.com</span>
            </div>

          </div>
        </div>
        <div class="col-md-5">
          <div class="my-4 text-white row ">
            <div class="col-md-1">
              <img src="assets/icons/location.svg" class="svg-icon-large me-2 c-pointer">
            </div>
            <div class="col-md-6">
              <span class="span-class-live">255 Lime Road</span> <br>
              <span class="span-class-live">Wilmington, DE, US</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-realtive">
    <img src="assets/images/footer.png" class="img-height-2" alt="">
  </div>
</footer>
