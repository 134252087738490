<div>
  <div class="border-b px-6 py-0 items-center gap-3 bg-white h-20 border-slate-50">
    <div class="flex flex-row gap-4 w-full items-center">
      <div class=" mt-3">
        <img src="/assets/icons/tr-onboarding-detail-arrow.svg" class="tr-lg-icon" (click)="backToCompanies()">
      </div>
      <div class="  p-2">
        <div class="text-gray-900 text-xl font-semibold">
          {{selectedCompanyUiDto?.companyName}}
        </div>
        <div class="">
          <div class="text-sm nav-bg text-start">
            <ul class="" style="list-style: none;" class="ps-0">
              <li class="inline-block mr-6" (click)="toggleDetailsTab('BASIC_INFO')"
                [ngClass]="currentTab == 'BASIC_INFO' ? 'tr-tab-active' : 'tr-tab-default'">
                Basic Information
              </li>
              <li class="inline-block mr-6 " (click)="toggleDetailsTab('PLANTS')"
                [ngClass]="currentTab == 'PLANTS' ? 'tr-tab-active' : 'tr-tab-default'">
                <span class="mr-2">Plants</span> 
                <span *ngIf="selectedCompanyUiDto?.plantUiDtos && selectedCompanyUiDto?.plantUiDtos!.length > 0">
                  ({{selectedCompanyUiDto?.plantUiDtos!.length}})
                </span>
                <img src="/assets/icons/tr-plus-bage.svg" class="tr-s-icon inline-flex" (click)="openNewPlantDrawer($event)">
              </li>
              <li class="inline-block mr-6 " (click)="toggleDetailsTab('BUDGET')"
                [ngClass]="currentTab == 'BUDGET' ? 'tr-tab-active' : 'tr-tab-default'">
                Budget
              </li>
              <li class="inline-block mr-6  tr-vertical-line"></li>
              <li class="inline-block mr-6 " (click)="toggleDetailsTab('USERS')"
                [ngClass]="currentTab == 'USERS' ? 'tr-tab-active' : 'tr-tab-default'">
                Users
                <img src="/assets/icons/tr-plus-bage.svg" class="tr-s-icon inline-flex" (click)="openNewUserDrawer($event)">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="currentTab == 'BASIC_INFO'">
  <app-onboarding-view-company-details></app-onboarding-view-company-details>
</div>

<div *ngIf="currentTab == 'PLANTS'">
  <app-onboarding-plants></app-onboarding-plants>
</div>
<div *ngIf="currentTab == 'COST_CENTERS'">
  <app-onboarding-cost-center></app-onboarding-cost-center>
</div>
<div *ngIf="currentTab == 'BUDGET'">
  <app-onboarding-budgets></app-onboarding-budgets>
</div>
<div *ngIf="currentTab == 'USERS'">
  <app-onboarding-users></app-onboarding-users>
</div>