<div>
  <div class="flex flex-row gap-4 items-center justify-between h-12 bg-white px-8 py-4 border-b">
    <div class="basis-1/2">
      <div class="inline-block ms-2">
        <div class="font-semibold text-black text-xl">
          Creating question
        </div>
      </div>
    </div>
    <!-- <div class="basis-1/2 text-end text-blue-500 ">
      <i-lucide name="info" [size]="18" class="my-icon inline-block"></i-lucide> Help
    </div> -->
  </div>
  <div class="flex flex-row">
    <ng-container [ngTemplateOutlet]="selectedTenderSectionTemplate ? technicalQuestionTemplate : sectionTemplate">
    </ng-container>
  </div>
</div>

<ng-template #sectionTemplate>
  <div class="h-screen w-full">
  <div class="py-6 px-8 tr-overflow-y-drawer ">
    <div class="w-4/5">
      <div class="grid grid-cols-12 gap-3 mb-4">
        <div class="col-span-6 text-dark text-base font-semibold">
          Sections
        </div>
        <div class="col-span-6 text-end">
          <button (click)="toggleShowNewSection()"
            class=" py-1.5 px-3 inline-flex text-xs font-medium text-gray-500 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
            <i-lucide name="plus" [size]="16" color="#0555F5" class="my-icon me-1 inline-flex"></i-lucide>
            <span class="text-black text-xs font-medium">
              Section
            </span>
          </button>
        </div>
      </div>

      <div class="grid py-4 items-center justify-center"
        *ngIf="!isShowNewSection && technicalSectionTemplateDtos.length == 0">
        <img src="assets/images/tender-question-1.svg" class=" h-16 w-16 mx-auto my-0">
        <div class="text-xs font-normal text-gray-800 mt-2">
          No sections added yet.
        </div>
      </div>

      <div class="border border-bg-200 rounded-lg shadow-xl mb-4" *ngIf="isShowNewSection">
        <div class="bg-gray-100 text-black text-xs font-normal h-6 items-center flex px-3">
          Add multiple sections by pasting titles here (e.g., ‘Section 1: Title’). Use line breaks between sections
        </div>
        <div class="p-4">
          <textarea
            class="bg-white w-full rounded-md flex-col self-stretch border-none h-16 px-2 py-0 text-gray-700 text-sm"
            id="description" rows="3" pInputTextarea [formControl]="ctrlSectionsName"></textarea>
        </div>
        <div class="flex flex-row justify-end h-8 items-center">
          <div>
            <button type="button" (click)="addNewSections()"
                  class="inline-flex items-center py-1.5 px-3 text-sm font-medium text-blue-700 focus:outline-none bg-white rounded-lg border border-blue-500 hover:bg-blue-100 hover:text-blue-800 focus:z-10 focus:ring-4 focus:ring-blue-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 me-3 mb-3">
                  <i-lucide name="plus" [size]="16" color="#004aad" class="my-icon inline-flex"></i-lucide>   Add Section
                  </button>
                  <button type="button" (click)="hideAddNewSection()"
                  class="inline-flex items-center py-1.5 px-3 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-800 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 mb-3 me-3">
                  <i-lucide name="plus" [size]="16" color="#000" class="my-icon inline-flex"></i-lucide>  Cancel
                  </button>
           
          </div>
        </div>
      </div>

      <div *ngIf="!isShowNewSection && technicalSectionTemplateDtos.length > 0">
        <div class="border border-gray-200 rounded-lg py-3 px-4 mb-4"
          *ngFor="let item of technicalSectionTemplateDtos;">
          <div class="flex flex-row justify-between items-center">
            <div class="">
              <div class="text-balck font-semibold text-sm">
                {{item.sectionName}}
              </div>
            </div>
            <div class=" text-end">
              <div class="flex flex-row items-center">
                <div class="text-start">
                  <div class="text-sm text-dark font-normal">
                    Score
                  </div>
                  <div class="text-black text-base font-semibold">
                    {{item.sectionScore}}
                  </div>
                </div>
                <span class="border-e border-gray-200 h-8 mx-2"></span>
                <div class="text-start">
                  <div class="text-sm text-dark font-normal">
                    Questions
                  </div>
                  <div class="text-black text-base font-semibold">
                    {{item.noOfQuestions || 0}}
                  </div>
                </div>
                <span class="border-e border-gray-200 h-8 mx-2"></span>
                <div class="tet-start">
                  <div class="text-sm text-blue-100 font-medium underline c-pointer inline-flex"
                    (click)="openTechnicalQuestionDrawer(item)">
                    <i-lucide name="plus" [size]="16" color="#0555F5" class="my-icon inline-flex"></i-lucide> Add
                  </div>
                </div>
                <span class="border-e border-gray-200 h-8 mx-2"></span>
                <div class="text-start">
                  <span (click)="confirmRemoveSection($event, item.sectionId!)">
                    <i-lucide name="trash-2" [size]="18" class="my-icon "></i-lucide>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="my-3">
      <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Questionnaire saved successfully.'">
      </app-message-toast>
      <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMessage$ | async">
      </app-message-toast>
    </div>

  </div>
  <div class="footer">
    <div class="flex flex-row justify-end items-center h-8 my-2">
      <!-- <div class="basis-1/2 mt-2 ps-6">
        <div class="h-2 w-20 bg-blue-200 inline-block"></div>
        Some information is pending
      </div> -->

      <div class=" text-end  pe-7">
        <button  [disabled]="isLoading$ | async"
          class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 me-3"
          (click)="closeDrawer()">Cancel</button>
        <p-button [disabled]="(isLoading$ | async) || false"  [loading]="(isLoading$ | async)|| false " label="Save"
          class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
          (onClick)="saveTenderWrapperUiDto()"></p-button>
        </div>
          <div class=" text-center text-white bg-blue-500 px-5 py-3" [ngClass]="{'disabled' : isLoading$ | async}">
            <span class="text-sm font-normal  cursor-pointer" (click)="openExternalDrawer(applicationConstants.NEW_TENDER_FINANCIAL_QUES)">
              Add Financials
              <i-lucide name="arrow-right" [size]="18" class="my-icon inline-block"></i-lucide></span>
          </div>
    </div>
  </div>
</ng-template>

<ng-template #technicalQuestionTemplate>
  <app-new-tender-technical-question-drawer style="display: contents;" (onBackNavigate)="closeTechnicalQuestionDrawer()"
    (onSaveQuestion)="saveTenderWrapperUiDto()" [tenderSectionTemplateDto]="selectedTenderSectionTemplate!"
    [isLoading$]="isLoading$" [errorMessage$]="errorMessage$" [showSuccessToast$]="_showSuccessToast$"
    [showErrorToast$]="_showErrorToast$">
  </app-new-tender-technical-question-drawer>
</ng-template>

<p-confirmDialog [style]="{width: '20vw'}"></p-confirmDialog>