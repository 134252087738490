import { style } from '@angular/animations';
import { Component, OnInit, Input, ChangeDetectorRef, HostListener } from '@angular/core';
import { EChartsOption, init } from 'echarts';
import { BehaviorSubject } from 'rxjs';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { AuctionDashboardDto } from 'src/app/shared/models/AuctionDashboardDto';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-analytics-revenue-chart',
  templateUrl: './analytics-revenue-chart.component.html',
  styleUrls: ['./analytics-revenue-chart.component.sass']
})
export class AnalyticsRevenueChartComponent implements OnInit {

  app: any = {};

  public screenWidth: any;

  option: any;
  isChartLoading: boolean = true;

  @Input() auctionDashboardDto$ = new BehaviorSubject<AuctionDashboardDto|undefined>(undefined);

  auctionDashboardDto?: AuctionDashboardDto;

  lotNameList: (string | undefined)[] = [];
  lotSequenceList: (string | undefined)[] = [];
  currentBidPriceList: (number | undefined)[] = []
  estBidPriceList: (number | undefined)[] = []






  constructor(private changeDetectRef: ChangeDetectorRef){}



  ngOnInit(): void {
    this.auctionDashboardDto$.subscribe(data => {
      if (data) {
        this.auctionDashboardDto = data;
        this.loadChartData();
      }
    })
  }

  loadChartData() {
    this.screenWidth = window.innerWidth;
    console.log(this.screenWidth)
    let lotNameList = this.auctionDashboardDto?.lotDashboardDtoList?.map(item => item.lotName)
    let lotSequence = this.auctionDashboardDto?.lotDashboardDtoList?.map(item => '#'+item.lotSequence)
    let currentBidPriceList = this.auctionDashboardDto?.lotDashboardDtoList?.map(item => item.currentBidPrice)
    let estBidPriceList = this.auctionDashboardDto?.lotDashboardDtoList?.map(item => item.estBidPrice)

    if(lotSequence && lotSequence.length > 0){
      this.lotSequenceList = lotSequence;
    }

    if(lotNameList && lotNameList.length > 0){
      this.lotNameList = lotNameList;

    }


    if(currentBidPriceList && currentBidPriceList.length > 0){
      this.currentBidPriceList = currentBidPriceList
    }

    if(estBidPriceList && estBidPriceList.length > 0){
      this.estBidPriceList = estBidPriceList
    }



    this.option = {

      legend: {
        data: ['EST BID PRICE', 'CURRENT BID PRICE'],
        right: this.screenWidth < 768 ? '' : '0%',
        top: '0%',
        orient: this.screenWidth < 768 ? 'horizontal' : 'vertical',
      },
      grid:{
        top: 80
      },

      title: {
        text: 'TOTAL EST REVENUE: '+this.getFormattedPrice(this.auctionDashboardDto?.estRevenue)+'\n \n'+'TOTAL CURRENT REVENUE: '+this.getFormattedPrice(this.auctionDashboardDto?.currentRevenue)+' ',
        borderWidth: 2,
        borderColor: '#ccc',

        textStyle: {
          fontSize: 12,
          fontWeight: "normal"
        },
        bottom:  this.screenWidth < 768 ? '0%' :'',
        left: this.screenWidth < 768 ? 'center' : 'left',

      },
      dataZoom: [{
        type: 'inside',
        id: 'insideX',
        xAxisIndex: 0,
        start:0,
        end: 100,
        maxValueSpan: this.screenWidth < 768 ? 4 : 10,
        zoomLock: true,
        zoomOnMouseWheel: false,
        moveOnMouseMove: true,
        moveOnMouseWheel: true
      }],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          let item1 = this.currentBidPriceList[params[0].dataIndex]
          let item2 = this.estBidPriceList[params[0].dataIndex]
          let lotName = this.lotNameList[params[0].dataIndex]
          return  'Lot Name: ' +lotName + '<br>'+
          'Current Bid Price: ' +this.getFormattedPrice(item1) + '<br>'+
          'EST Bid Price: ' +this.getFormattedPrice(item2);
        }

      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          data: this.lotSequenceList,
          nameTextStyle: {
            align: "center"
          }
        }
      ],
      yAxis: [
        {
          padding: 10,
          type: 'value',

          axisLabel: {
            formatter: (value: any) => {
              if (this.screenWidth < 768) {
                return "";
              }
              return this.getFormattedPrice(value)
            }
          }
        }
      ],

      series: [
        {
          name: 'EST BID PRICE',
          type: 'bar',
          barWidth: 20,
          barGap: 0,
          emphasis: {
            focus: 'series'
          },
          data: this.estBidPriceList
        },
        {
          name: 'CURRENT BID PRICE',
          type: 'bar',
          barWidth: 20,
          emphasis: {
            focus: 'series'
          },
          data: this.currentBidPriceList
        },


      ]
    };
    this.isChartLoading = false;

  }

  getFormattedPrice(price: any) {
    let currency = this.auctionDashboardDto?.currencyDto;
    return currency?.symbol + ApplicationUtils.getFormattedPrice(currency?.locale, price);
  }




  resizeCanvasWidth(chartId: string, dataLength: number) {
    if (dataLength > 8) {
      let myChart = init(document.getElementById(chartId)!);
      let desiredWidth = dataLength * 50;
      document.getElementById(chartId)!.style.width = desiredWidth + 'px';
      myChart.resize();
    }
  }


}
