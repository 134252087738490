import { Timestamp } from "@angular/fire/firestore";
import { PurchaseRequestStatus } from "../../enums/rfx/PurchaseRequestStatus";
import { FileInfoDto } from "../FileInfoDto";
import { MaterialUiDto } from "../MaterialUiDto";

export class PurchaseRequestUiDto {
  orgCode?: string;
  projectCode?: string;
  companyCode?: string;
  plantCode?: string;
  costCenterCode?: string;
  prId?: string;
  prCode?: string;
  title?: string;
  description?: string;
  status?: PurchaseRequestStatus;
  fileInfoDtos?: FileInfoDto[];
  materialUiDtos?: MaterialUiDto[]
  estimatedDeliveryDate?: string;
  leadTime?: number;
  expectedPrice?: number;
  updateTimeUtc?: number | Timestamp;
}
