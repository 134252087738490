import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { EvaluationQuestionStatus } from 'src/app/shared/enums/rfx/EvaluationQuestionStatus';
import { EvaluationRfxSubcategoryStatus } from 'src/app/shared/enums/rfx/EvaluationRfxSubcategoryStatus';
import { QualifiedStatus } from 'src/app/shared/enums/rfx/QualifiedStatus';
import { FinancialQuestionTemplate } from 'src/app/shared/models/questionnaire/FinancialQuestionTemplate';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { EvaluationUserRfxSubcategoryEntityDto } from 'src/app/shared/models/rfx/EvaluationRfxSubcategoryEntityDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxUserQuestionsEvaluationWrapperDto } from 'src/app/shared/models/rfx/RfxUserQuestionsEvaluationWrapperDto';
import { RfxEvaluationTechnicalScoresWrapperDto } from 'src/app/shared/models/rfx/RfxEvaluationTechnicalScoresWrapperDto';
import { SubcategorySubmissionDto } from 'src/app/shared/models/rfx/SubcategorySubmissionDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { RfxEvaluationTechnicalQuestionsComponent } from '../rfx-evaluation-technical-questions/rfx-evaluation-technical-questions.component';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { CompFinancialWrapperDto } from 'src/app/shared/models/rfx/CompFinancialWrapperDto';
import { RfxEvaluationFinancialQuestionsComponent } from '../rfx-evaluation-financial-questions/rfx-evaluation-financial-questions.component';
import { ContractTypeEnum } from 'src/app/shared/enums/rfx/ContractTypeEnum';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { QuestionnaireTemplate } from 'src/app/shared/models/questionnaire/QuestionnaireTemplate';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';

@Component({
  selector: 'app-rfx-evaluation-page',
  templateUrl: './rfx-evaluation-page.component.html',
  styleUrls: ['./rfx-evaluation-page.component.sass']
})
export class RfxEvaluationPageComponent implements OnInit, OnDestroy {
  rfxEntityDto?: RfxUiDto;
  subcategorySubmissionList: SubcategorySubmissionDto[] = [];
  rfxUserSubcategoryEvaluationWrapper?: RfxEvaluationTechnicalScoresWrapperDto;
  rfxUserQuestionsEvaluationWrapper?: RfxUserQuestionsEvaluationWrapperDto;

  selectedSubcategorySubmissionDto?: SubcategorySubmissionDto;
  selectedEvaluationUserRfxSubcategory?: EvaluationUserRfxSubcategoryEntityDto;
  questionnaireTemplate?: QuestionnaireTemplate;

  currentTab: string = 'EVENTS';
  currentPage: string = '';

  isDataLoading: boolean = false;
  isLoading: boolean = false;
  errorMsg: string | undefined;

  totalNotQualifiedUsers: number = 0;
  totalQualifiedUsers: number = 0;
  totalEvaluatedUsers: number = 0;
  totalNotEvaluatedUsers: number = 0;
  totalPendingQuestions: number = 0;
  totalVerifiedQuestions: number = 0;

  isTechnicalEvaluationPending: boolean = false;
  isShowOpenFinancialEnvelope: boolean = false;
  isShowOpenTechnicalEnvelope: boolean = false;
  isFinancialEnvelopeNotOpened: boolean = false;

  normalizePrice: number = 0;
  noOfItemsRequiredNormalization: number = 0;

  submitSubmissionQuestionsModalRef?: NgbModalRef
  submitSubcategorySubmissionModalRef?: NgbModalRef
  openFinancialConfirmModalRef?: NgbModalRef
  openTechnicalConfirmModalRef?: NgbModalRef

  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  subcategorySubmissionsList$ = new BehaviorSubject<SubcategorySubmissionDto[]>([]);
  evaluationRfxSubcategoryList$ = new BehaviorSubject<EvaluationUserRfxSubcategoryEntityDto[]>([]);
  userSubmissionTechQuestions$ = new BehaviorSubject<TechnicalQuestionTemplate[]>([]);

  comFinancialWrapperDtos$ = new BehaviorSubject<CompFinancialWrapperDto[]>([]);

  selectedRfxSubscription$?: Subscription;
  subcategorySubmissionsSubscription$?: Subscription;
  userSubcategoryEvaluationWrapperSubscription$?: Subscription;
  userSubmissionQuestionsWrapperSubscription$?: Subscription;
  comFinancialWrappersSubscription$?: Subscription;
  technicalEvaluationStatusSubscription$?: Subscription;

  constructor(
    private adminDashboardService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private questionnaireService: QuestionnaireService,
    private datePipe: DatePipe,
    private ngbModal: NgbModal
  ) { }

  ngOnInit(): void {
    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(rfx => {
      if (rfx) {
        this.rfxEntityDto = rfx;
        this.loadSubcategorySubmissions()
      }
    })

    this.subcategorySubmissionsSubscription$ = this.adminDashboardService.getSubcategorySubmissionList$.subscribe(data => {
      if (data) {
        this.subcategorySubmissionList = data;
        this.refreshData()
      } else {
        this.subcategorySubmissionList = [];
        this.subcategorySubmissionsList$.next([])
      }
    })

    this.userSubcategoryEvaluationWrapperSubscription$ = this.adminDashboardService.getUserSubcategorySubmissionWrapper$.subscribe(data => {
      this.handleTechnicalEvaluationData(data);
    })

    this.userSubmissionQuestionsWrapperSubscription$ = this.adminDashboardService.getUserSubmissionQuestionsWrapper$.subscribe(data => {
      if (data) {
        this.rfxUserQuestionsEvaluationWrapper = data;
        this.refreshData();
      } else {
        this.rfxUserQuestionsEvaluationWrapper = undefined;
      }
    })

    this.comFinancialWrappersSubscription$ = this.adminDashboardService.getComFinancialWrapperDtos$.subscribe(data => {
      if (data && data.length > 0) {
        // data = data.filter(item => Number(item.compSequence) > 0);
        let disqualifiedBidders = data.filter(item => Number(item.compSequence) == -1);
        let qualifiedBidders = data.filter(item => Number(item.compSequence) > 0);

        qualifiedBidders.sort((a, b) => Number(a.compSequence) - Number(b.compSequence));
        disqualifiedBidders.sort((a, b) => Number(b.technicalScore) - Number(a.technicalScore));
        let compFinancialWrappers = qualifiedBidders.concat(disqualifiedBidders);

        this.comFinancialWrapperDtos$.next(compFinancialWrappers);

        this.normalizePrice = qualifiedBidders[0].normalizePrice ?? 0;
        this.noOfItemsRequiredNormalization = qualifiedBidders[0].noOfItemsRequiredNormalization ?? 0;
      } else {
        this.comFinancialWrapperDtos$.next([]);
      }
    })

    this.technicalEvaluationStatusSubscription$ = this.adminDashboardService.getTechnicalEvaluationStatus$.subscribe(data => {
      this.isTechnicalEvaluationPending = data;
    });
  }

  private populateFinancialEnvelopeOpened() {
    // check with submission dto  - finaiclaOpened == false -- > true
    if (this.rfxUserSubcategoryEvaluationWrapper) {
      this.isFinancialEnvelopeNotOpened = this.rfxUserSubcategoryEvaluationWrapper.subcategorySubmissionDto?.financialEnvelopeOpened == false;
    } else {
      this.isFinancialEnvelopeNotOpened = false;
    }
  }

  private populateOpenFinancialEnvelopeCondition(data: RfxEvaluationTechnicalScoresWrapperDto) {
    let notEvaluatedList = data.evaluationUserRfxSubcategoryEntityDtoList?.filter(
      item => item.evaluationStatus != EvaluationRfxSubcategoryStatus.SUBMITTED) ?? [];
    let financialEnvelopePresent = data.subcategorySubmissionDto?.noOfEnvelopes?.includes(SourcingEnvelopeType.FINANCIAL);

    this.isShowOpenFinancialEnvelope = !data.subcategorySubmissionDto?.financialEnvelopeOpened && data.subcategorySubmissionDto?.technicalEnvelopeOpened!
      && notEvaluatedList.length == 0 && financialEnvelopePresent!;
  }

  private populateOpenTechnicalEnvelopeCondition(data: RfxEvaluationTechnicalScoresWrapperDto) {
    let isRfxClosed = this.rfxEntityDto?.status == RfxStatus.CLOSE;
    this.isShowOpenTechnicalEnvelope = !data.subcategorySubmissionDto?.technicalEnvelopeOpened && isRfxClosed;

  }

  async loadSubcategorySubmissions() {
    this.isDataLoading = true;
    await this.adminDashboardService.getAndLoadSubcategorySubmissions(this.rfxEntityDto?.rfxId!)
    this.isDataLoading = false;
  }

  selectSubcategorySubmission(subcategorySubmissionDto: SubcategorySubmissionDto, event: HTMLInputElement) {
    if (this.selectedSubcategorySubmissionDto?.subcategoryId == subcategorySubmissionDto.subcategoryId) {
      this.selectedSubcategorySubmissionDto = undefined;
      event.checked = false;
      return;
    }

    this.selectedSubcategorySubmissionDto = subcategorySubmissionDto;
  }

  async loadTechnicalUserSubcategorySubmissions() {
    this.isDataLoading = true;
    await this.adminDashboardService.getAndLoadUserSubcategorySubmissions(this.rfxEntityDto?.rfxId!, this.selectedSubcategorySubmissionDto!.subcategoryId!);
    this.isDataLoading = false;
  }

  async loadUserSubmissionQuestions(evaluationUserRfxSubcategory: EvaluationUserRfxSubcategoryEntityDto) {
    this.currentPage = 'TECHNICAL_SUBMISSION_QUESTIONS';
    this.selectedEvaluationUserRfxSubcategory = evaluationUserRfxSubcategory;

    this.isDataLoading = true;
    await this.adminDashboardService.getAndLoadSubcategorySubmissionQuestions(
      this.rfxEntityDto?.rfxId!, evaluationUserRfxSubcategory.subcategoryId!, evaluationUserRfxSubcategory.companyId!);
    this.isDataLoading = false;
  }

  async loadComFinancialWrapperDto() {
    this.isDataLoading = true;
    await this.adminDashboardService.getAndLoadComFinancialWrapperDtosSync(this.selectedSubcategorySubmissionDto?.rfxId!, this.selectedSubcategorySubmissionDto?.subcategoryId!);
    this.isDataLoading = false;
  }

  refreshData() {
    if (this.currentTab == 'EVENTS') {
      this.subcategorySubmissionList.sort((a, b) => Number(a.subcategorySequenceNo) - Number(b.subcategorySequenceNo));
      this.subcategorySubmissionsList$.next(this.subcategorySubmissionList);

      if (!this.selectedSubcategorySubmissionDto) {
        this.selectedSubcategorySubmissionDto = this.subcategorySubmissionList[0];
      }
    }

    if (this.currentTab == 'TECHNICAL') {
      if (this.currentPage == 'TECHNICAL_SUBMISSION_USERS') {
        let data = this.rfxUserSubcategoryEvaluationWrapper?.evaluationUserRfxSubcategoryEntityDtoList?.filter(item => item.noOfEnvelopes?.includes(SourcingEnvelopeType.TECHNICAL));

        if (data) {
          let qualifiedUserRfxSubcategories = data.filter(item => item.status == QualifiedStatus.QUALIFIED);
          qualifiedUserRfxSubcategories = qualifiedUserRfxSubcategories.sort((a, b) => Number(b.score) - Number(a.score));

          let disqualifiedUserRfxSubcategories = data.filter(item => item.status == QualifiedStatus.NOT_QUALIFIED);
          disqualifiedUserRfxSubcategories = disqualifiedUserRfxSubcategories.sort((a, b) => Number(b.score) - Number(a.score));

          let evaluationRfxSubcategoryList = qualifiedUserRfxSubcategories.concat(disqualifiedUserRfxSubcategories);

          this.evaluationRfxSubcategoryList$.next(evaluationRfxSubcategoryList);
          this.totalNotQualifiedUsers = disqualifiedUserRfxSubcategories.length;
          this.totalQualifiedUsers = qualifiedUserRfxSubcategories.length;
          this.totalEvaluatedUsers = data.filter(item => item.evaluationStatus == EvaluationRfxSubcategoryStatus.SUBMITTED).length;
          this.totalNotEvaluatedUsers = data.filter(item => item.evaluationStatus != EvaluationRfxSubcategoryStatus.SUBMITTED).length;
        } else {
          this.evaluationRfxSubcategoryList$.next([]);
          this.totalNotQualifiedUsers = 0;
          this.totalQualifiedUsers = 0;
        }
      }

      if (this.currentPage == 'TECHNICAL_SUBMISSION_QUESTIONS') {
        let technicalQuestions = this.rfxUserQuestionsEvaluationWrapper?.technicalQuestionsTemplateList;
        let evaluationRfxQuestions = this.rfxUserQuestionsEvaluationWrapper?.evaluationUserRfxQuestionsEntityDtoList?.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL);;

        if (technicalQuestions) {
          technicalQuestions = this.sortTechnicalQuestions(technicalQuestions);
          this.userSubmissionTechQuestions$.next(technicalQuestions);
        } else {
          this.userSubmissionTechQuestions$.next([]);
        }

        if (evaluationRfxQuestions) {
          this.totalVerifiedQuestions = evaluationRfxQuestions.filter(item => item.status == EvaluationQuestionStatus.SUBMITTED).length;
          this.totalPendingQuestions = evaluationRfxQuestions.filter(item => item.status == EvaluationQuestionStatus.NEW || item.status == EvaluationQuestionStatus.IN_PROGRESS).length;
        } else {
          this.totalVerifiedQuestions = 0;
          this.totalPendingQuestions = 0;
        }
      }
    }
  }

  toggleDetailsTab(tab: string) {
    if (tab != 'EVENTS' && !this.selectedSubcategorySubmissionDto) {
      return;
    }

    this.currentTab = tab;

    if (this.currentTab == 'TECHNICAL') {
      this.currentPage = 'TECHNICAL_SUBMISSION_USERS';
      this.loadTechnicalUserSubcategorySubmissions();
    }

    if (this.currentTab == 'FINANCIAL') {
      this.loadComFinancialWrapperDto();
    }

    this.refreshData();
  }

  changeDetailsPage(currentPage: string) {
    this.currentPage = currentPage;
    this.refreshData();
  }

  getEvaluationScore(questionId: string) {
    let evaluationQuestion = this.rfxUserQuestionsEvaluationWrapper?.evaluationUserRfxQuestionsEntityDtoList?.find(item => item.questionId == questionId);
    if (evaluationQuestion) {
      return evaluationQuestion.evaluatorScore;
    }
    return '0';
  }

  getSubmissionEvaluationStatus(evaluationStatus: string) {
    if (evaluationStatus == EvaluationRfxSubcategoryStatus.SUBMITTED) {
      return 'Submitted';
    }
    if (evaluationStatus == EvaluationRfxSubcategoryStatus.IN_PROGRESS) {
      return 'In Progress';
    }
    return 'Pending';
  }

  getQuestionEvaluationStatus(questionId: string) {
    let evaluationQuestion = this.rfxUserQuestionsEvaluationWrapper?.evaluationUserRfxQuestionsEntityDtoList?.find(item => item.questionId == questionId);
    if (evaluationQuestion) {
      if (evaluationQuestion.status == EvaluationQuestionStatus.SUBMITTED) {
        return 'Verified';
      } else {
        return 'Pending';
      }
    } else {
      return 'Not Submitted';
    }
  }

  getUserEvaluationStatus(companyId: string) {
    let evaluationUserRfxSubcategory = this.rfxUserSubcategoryEvaluationWrapper?.evaluationUserRfxSubcategoryEntityDtoList?.find(item => item.companyId == companyId);
    if (evaluationUserRfxSubcategory) {
      if (evaluationUserRfxSubcategory.evaluationStatus == EvaluationRfxSubcategoryStatus.SUBMITTED) {
        return 'Submitted';
      }
      if (evaluationUserRfxSubcategory.evaluationStatus == EvaluationRfxSubcategoryStatus.IN_PROGRESS) {
        return 'In Progress';
      }
    }
    return 'Pending';
  }

  isQuestionAutoResponse(questionId: string) {
    let evaluationQuestion = this.rfxUserQuestionsEvaluationWrapper?.evaluationUserRfxQuestionsEntityDtoList?.find(item => item.questionId == questionId);
    return evaluationQuestion?.autoResponse ?? false;
  }

  getTitleResponseType(responseType: QuestionResponseType) {
    if (responseType == QuestionResponseType.SINGLE_CHOICE) {
      return "Single Choice Response";
    } else if (responseType == QuestionResponseType.MULTI_CHOICE) {
      return "Multi Choice Response";
    } else if (responseType == QuestionResponseType.DATE) {
      return "Date Response";
    } else if (responseType == QuestionResponseType.DATE_RANGE) {
      return "Date Range Response";
    } else if (responseType == QuestionResponseType.ALPHANUMERIC) {
      return "Alphanumeric Response";
    }
    return "Select Response Type";
  }

  sortTechnicalQuestions(data: TechnicalQuestionTemplate[]): TechnicalQuestionTemplate[] {
    return data.sort((a, b) => {
      // Split the strings at the dot (.) to separate letter and number parts
      const [letterA, numberA] = `${a.preSequenceText}.${a.sequenceNo}`.split('.');
      const [letterB, numberB] = `${b.preSequenceText}.${b.sequenceNo}`.split('.');

      // Sort by letter (ascending)
      if (letterA < letterB) return -1;
      if (letterA > letterB) return 1;

      // If letters are the same, sort by number (ascending)
      return Number(numberA) - Number(numberB);
    });
  }

  sortFinancialQuestions(data: FinancialQuestionTemplate[]): FinancialQuestionTemplate[] {
    return data.sort((a, b) => {
      // Split the strings at the dot (.) to separate letter and number parts
      const [letterA, numberA] = `${a.preSequenceText}.${a.sequenceNo}`.split('.');
      const [letterB, numberB] = `${b.preSequenceText}.${b.sequenceNo}`.split('.');

      // Sort by letter (ascending)
      if (letterA < letterB) return -1;
      if (letterA > letterB) return 1;

      // If letters are the same, sort by number (ascending)
      return Number(numberA) - Number(numberB);
    });
  }

  getDisplayDate(date: string, time: string) {
    let convertedDate = ApplicationUtils.convertedDate(date, time);
    return this.datePipe.transform(convertedDate, 'dd MMM yyyy');
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll();
    }
  }

  openEvaluationQuestionModal(item: TechnicalQuestionTemplate) {
    let modalRef = this.ngbModal.open(RfxEvaluationTechnicalQuestionsComponent, {
      centered: true, size: 'xl',
      backdrop: 'static', keyboard: false
    });
    modalRef.componentInstance.selectedTechnicalQuestion = item;
    modalRef.componentInstance.selectedSubcategorySubmissionDto = this.selectedSubcategorySubmissionDto;
    modalRef.componentInstance.rfxUserQuestionsEvaluationWrapper = this.rfxUserQuestionsEvaluationWrapper;
    modalRef.componentInstance.maximumScore = this.rfxUserSubcategoryEvaluationWrapper?.maximumScore;
  }

  openEvaluationFinancialQuestionModal(item: CompFinancialWrapperDto) {
    let modalRef = this.ngbModal.open(RfxEvaluationFinancialQuestionsComponent, {
      centered: true, size: 'xl',
      backdrop: 'static', keyboard: false
    });
    modalRef.componentInstance.comFinancialWrapperDto = item;
    modalRef.componentInstance.subcategorySubmissionDto = this.selectedSubcategorySubmissionDto;
  }

  openSubmitSubmissionQuestionsModal(content: any) {
    this.submitSubmissionQuestionsModalRef = this.ngbModal.open(content, {
      centered: true, size: 'md',
      backdrop: 'static', keyboard: false
    });
  }

  openSubmitUserSubmissionsModal(content: any) {
    this.submitSubcategorySubmissionModalRef = this.ngbModal.open(content, {
      centered: true, size: 'md',
      backdrop: 'static', keyboard: false
    });
  }

  openConfirmFinancialEnvelopeModal(content: any) {
    this.openFinancialConfirmModalRef = this.ngbModal.open(content, {
      centered: true, size: 'md',
      backdrop: 'static', keyboard: false
    });
  }

  openConfirmTechnicalEnvelopeModal(content: any) {
    this.openTechnicalConfirmModalRef = this.ngbModal.open(content, {
      centered: true, size: 'md',
      backdrop: 'static', keyboard: false
    });
  }

  submitAllSubcategorySubmissionQuestions() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let userRfxQuestionsEntityDto = this.rfxUserQuestionsEvaluationWrapper?.userRfxQuestionsEntityDtoList![0];

    let rfxId = userRfxQuestionsEntityDto?.rfxId!;
    let rfxSubcategoryId = userRfxQuestionsEntityDto?.rfxSubcategoryId!;
    let userId = userRfxQuestionsEntityDto?.companyId!;
    let envelopeType = this.currentTab;

    this.adminDashboardService.bulkSubcategorySubmissionQuestions(rfxId, rfxSubcategoryId, userId, envelopeType).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as RfxUserQuestionsEvaluationWrapperDto;
          this.adminDashboardService.updateRfxUserQuestionsEvaluationWrapper(data);

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.submitSubmissionQuestionsModalRef);
          }, 2000)
        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while submitting rfx bulk subcategories submission questions. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;
      }
    })
  }

  submitSubcategorySubmissions() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let evaluationUserRfxSubcategory = this.rfxUserSubcategoryEvaluationWrapper?.evaluationUserRfxSubcategoryEntityDtoList![0];

    let rfxId = evaluationUserRfxSubcategory?.rfxId!;
    let rfxSubcategoryId = evaluationUserRfxSubcategory?.subcategoryId!;

    this.adminDashboardService.bulkSubcategoryUserSubmission(rfxId, rfxSubcategoryId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as RfxEvaluationTechnicalScoresWrapperDto;
          this.adminDashboardService.updateRfxUserSubcategoryEvaluationWrapper(data);

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.submitSubcategorySubmissionModalRef);
          }, 2000)
        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while submitting rfx bulk subcategories submission questions. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;
      }
    })
  }

  openFinancialEnvelopeRequest() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let rfxId = this.selectedSubcategorySubmissionDto?.rfxId!;
    let rfxSubcategoryId = this.selectedSubcategorySubmissionDto?.subcategoryId!;

    this.adminDashboardService.openFinancialEnvelope(rfxId, rfxSubcategoryId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.openFinancialConfirmModalRef);
          }, 2000)

        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while submitting request for open financial envelope. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;
      }
    })
  }

  handleTechnicalEvaluationData(data: any) {
    if (data) {
      this.rfxUserSubcategoryEvaluationWrapper = data;

      this.populateOpenFinancialEnvelopeCondition(data);
      this.populateOpenTechnicalEnvelopeCondition(data);
      this.populateFinancialEnvelopeOpened();

      this.refreshData();
    } else {
      this.rfxUserSubcategoryEvaluationWrapper = undefined;
      this.evaluationRfxSubcategoryList$.next([])
    }
  }

  openTechnicalEnvelopeRequest() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let rfxId = this.selectedSubcategorySubmissionDto?.rfxId!;
    let rfxSubcategoryId = this.selectedSubcategorySubmissionDto?.subcategoryId!;

    this.adminDashboardService.openTechnicalEnvelope(rfxId, rfxSubcategoryId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {

          this.handleTechnicalEvaluationData(apiResponseDto.data);
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.openTechnicalConfirmModalRef);
          }, 2000)

        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while submitting request for open technical envelope. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;
      }
    })
  }

  getFormattedPrice(price: any) {
    let currency = this.rfxEntityDto?.currency;
    return (currency?.symbol + ApplicationUtils.getFormattedPrice(currency?.locale, price));
  }

  getFormattedContractType(type: ContractTypeEnum) {
    let toReturn = '';

    if (type == 'ITEM_WISE') {
      toReturn = 'Item Wise'
    } else if (type == 'ITEM_RATE') {
      toReturn = 'Item Rate'
    } else if (type == 'PERCENTAGE') {
      toReturn = 'Percentage'
    } else if (type == 'LUMPSUM') {
      toReturn = 'Lumpsum'
    } else if (type == 'SUPPLY_RATE') {
      toReturn = 'Supply Rate'
    }

    return toReturn;
  }

  getBidderRankingText(contractAlgo: string, compSequence: number, index: number) {
    let temp = contractAlgo == 'HCS' ? 'H' : 'L';
    if (compSequence == -1) {
      return temp + (index + 1)
    } else {
      return temp + compSequence;
    }
  }

  ngOnDestroy(): void {
    this.currentTab = 'EVENTS';

    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
    if (this.subcategorySubmissionsSubscription$) {
      this.subcategorySubmissionsSubscription$.unsubscribe();
    }
    if (this.userSubcategoryEvaluationWrapperSubscription$) {
      this.userSubcategoryEvaluationWrapperSubscription$.unsubscribe();
    }
    if (this.userSubmissionQuestionsWrapperSubscription$) {
      this.userSubmissionQuestionsWrapperSubscription$.unsubscribe();
    }
    if (this.comFinancialWrappersSubscription$) {
      this.comFinancialWrappersSubscription$.unsubscribe();
    }
    if (this.technicalEvaluationStatusSubscription$) {
      this.technicalEvaluationStatusSubscription$.unsubscribe();
    }
  }
}
