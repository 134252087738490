import { EventEnum } from "../../enums/EventEnum";
import { FileInfoDto } from "../FileInfoDto";
import { AdditionalFinancialPrice } from "../questionnaire/AdditionalFinancialPrice";

export class TenderFinancialQuestionTemplateDto {
  questionId?: string;
  sectionId?: string;
  questionnaireId?: string;
  questionText?: string;
  questionDescription?: string;
  preSequenceText?: string;
  sequenceNo?: number;
  orgCode?: string;
  resourceId?: string;
  subResourceId?: string;
  resourceType?: EventEnum;
  openerComments?: string;
  responseMandatory?: boolean;
  bidderCommentsRequired?: boolean;
  noResponse?: boolean;
  sampleFile?: FileInfoDto;
  quantity?: number;
  uom?: string;
  tax?: number;
  entityRate?: number;
  additionalPrices?: AdditionalFinancialPrice[]

  updateTimeUtc?: string;
  updateTimeUtcMillis?: number;
}