import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AddressDto } from 'src/app/shared/models/Address';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { CostCenterUiDto } from 'src/app/shared/models/CostCenterUiDto';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { PlantCostCenterUiDto } from 'src/app/shared/models/PlantCostCenterUiDto';
import { PlantUiDto } from 'src/app/shared/models/PlantUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

interface CostCenter {
  name: string
  code: string
}

@Component({
  selector: 'app-onboarding-plants',
  templateUrl: './onboarding-plants.component.html',
  styleUrls: ['./onboarding-plants.component.sass']
})
export class OnboardingPlantsComponent implements OnInit, OnDestroy {
  ctrlSearch: FormControl = new FormControl('')

  isLoading: boolean = false;
  isDataLoading: boolean = true;
  isEditCostCenter: boolean = false;
  costCenters: CostCenter[] = [];
  selectedCostCenters: CostCenter[] = [];

  selectedCompanyUiDto?: CompanyUiDto;
  selectedPlantUiDto?: PlantUiDto
  selectedBillingAddress?: AddressDto;
  selectedShippingAddress?: AddressDto;

  plantUiDtos: PlantUiDto[] = [];
  filteredPlantEntityDtos: PlantUiDto[] = [];

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  _filteredPlantEntityDtos$ = new BehaviorSubject<PlantUiDto[]>([]);

  plantsListSubscription$?: Subscription;
  selectedCompanySubscription$?: Subscription;

  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  zoom = 12;
  positionBilling?: google.maps.LatLngLiteral;
  positionShipping?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  markerBilling = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }
  markerShipping = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }

  constructor(
    private changeDetectRef: ChangeDetectorRef,
    private onboardingService: OnboardingService,
    private datePipe: DatePipe,
    private drawerService: DrawerService
  ) {
  }

  ngOnInit(): void {
    this.costCenters = this.onboardingService.getOnboardingCostCenters;

    this.loadPlantUiDto();

    this.ctrlSearch.valueChanges.subscribe((val) => {
      this.search(val);
    })

    this.plantsListSubscription$ = this.onboardingService.getPlantUiDtos$.subscribe(data => {
      if (data) {
        this.plantUiDtos = data;

        if (this.plantUiDtos.length > 0) {
          this.plantUiDtos = this.plantUiDtos.sort((a, b) => a.plantCode!.localeCompare(b.plantCode!));
          this.refreshPlantsList();
        }
      } else {
        this.plantUiDtos = [];
        this.filteredPlantEntityDtos = this.plantUiDtos;
        this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
      }
    })

    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
        this.refreshPlantsList();

      } else {
        this.selectedCompanyUiDto = undefined;
      }
    })
  }

  async loadPlantUiDto() {
    let orgCode = this.onboardingService.getOrgCode();
    this.isDataLoading = true;
    await this.onboardingService.loadPlantUiDtosByOrgCode(orgCode!);
    this.isDataLoading = false;
  }

  openNewPlantDrawer(event: any) {
    event.stopPropagation();
    this.drawerService.isFormEditMode(false);
    this.drawerService.openDrawer('ONBOARDING_NEW_PLANT');
  }

  openEditPlantDrawer(tab?: string) {
    this.onboardingService.setCurrentPlantUiDto(this.selectedPlantUiDto);
    this.drawerService.isFormEditMode(true);
    this.drawerService.openDrawer('ONBOARDING_NEW_PLANT', tab);
  }

  toggleAddCostCenter() {
    this.isEditCostCenter = !this.isEditCostCenter;
  }

  refreshPlantsList() {
    if (this.selectedCompanyUiDto) {
      this.filteredPlantEntityDtos = this.plantUiDtos.filter(item => item.companyCode == this.selectedCompanyUiDto?.companyCode);
      this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
    } else {
      this.filteredPlantEntityDtos = this.plantUiDtos;
      this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
    }

    if (this.plantUiDtos.length > 0) {
      if (this.selectedPlantUiDto) {
        let selectedPlantUiDto = this.plantUiDtos.find(item => item.plantCode == this.selectedPlantUiDto?.plantCode)!;
        this.viewPlantDetails(selectedPlantUiDto);
      } else {
        let selectedPlantUiDto = this.plantUiDtos[0];
        this.viewPlantDetails(selectedPlantUiDto);
      }
    } else {
      this.selectedPlantUiDto = undefined;
    }
  }

  search(searchText: string) {
    let plantUiDtos = [...this.plantUiDtos ?? []];

    if (searchText.trim() != '') {
      let filteredPlantEntityDtos = plantUiDtos.filter((item) => item.plantName?.toLowerCase().includes(searchText.trim().toLowerCase()) || item.plantCode?.toLowerCase()?.includes(searchText.trim()));
      this.filteredPlantEntityDtos = filteredPlantEntityDtos;
    } else {
      this.filteredPlantEntityDtos = plantUiDtos;
    }

    this._filteredPlantEntityDtos$.next(this.filteredPlantEntityDtos);
  }

  viewPlantDetails(plantUiDto: PlantUiDto) {
    this.selectedPlantUiDto = plantUiDto;

    if (this.selectedPlantUiDto.plantCostCenterUiDtos && this.selectedPlantUiDto.plantCostCenterUiDtos.length > 0) {
      this.selectedCostCenters = this.selectedPlantUiDto.plantCostCenterUiDtos.map(item => {
        return { name: item.costCenterName!, code: item.costCenterCode! };
      })
    } else {
      this.selectedCostCenters = []
    }

    if (this.selectedPlantUiDto.billingAddress && this.selectedPlantUiDto.billingAddress.length > 0) {
      this.selectedBillingAddress = this.selectedPlantUiDto.billingAddress[0]
    } else {
      this.selectedBillingAddress = undefined;
    }

    if (this.selectedPlantUiDto.shippingAddress && this.selectedPlantUiDto.shippingAddress.length > 0) {
      this.selectedShippingAddress = this.selectedPlantUiDto.shippingAddress[0]
    } else {
      this.selectedShippingAddress = undefined;
    }
  }

  mergePlantUiDto() {
    let plantUiDto = ApplicationUtils.clone(this.selectedPlantUiDto) as PlantUiDto;

    plantUiDto.plantCostCenterUiDtos = [];

    this.selectedCostCenters.forEach(costCenter => {
      let plantCostCenterUiDto = new PlantCostCenterUiDto();
      plantCostCenterUiDto.orgCode = plantUiDto.orgCode;
      plantCostCenterUiDto.costCenterCode = costCenter.code;
      plantCostCenterUiDto.costCenterName = costCenter.name;

      plantUiDto.plantCostCenterUiDtos!.push(plantCostCenterUiDto);
    })

    return plantUiDto;
  }

  saveCostCenters() {
    if (this.selectedCostCenters.length == 0) {
      return;
    }

    let plantUiDto = this.mergePlantUiDto();

    this.isLoading = true;

    this.onboardingService.savePlantUiDto(plantUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);

          let newPlantDto = apiResponseDto.data as PlantUiDto;
          this.onboardingService.updatePlantUiDto(newPlantDto);
          this.onboardingService.setCurrentPlantUiDto(newPlantDto);

          this.onboardingService.loadCompanyUiDtosByOrgCode();

          this.isLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.isEditCostCenter = false;
          }, 2000)
        } else {
          this.isLoading = false;
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
        this._showErrorMsg$.next("Error Saving Cost Centers");
        this._showErrorToast$.next(true);
      }
    })
  }

  openMarkerInfo(marker: MapMarker) {
    this.info.open(marker)
  }

  ngOnDestroy(): void {
    if (this.plantsListSubscription$) {
      this.plantsListSubscription$.unsubscribe();
    }
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  getFormattedFinancialYear(date: string) {
    let convertedDate = ApplicationUtils.convertedOnlyDate(date);
    return this.datePipe.transform(convertedDate, 'dd MMM');
  }
}
