<div class="container my-5">
  <div class="mt-3 ">
    <div class="border bg-grey1 rounded">
      <div class="text-center col-12 py-2">
        <div class="row align-items-center">
          <div class="col-12 text-end pe-4">
            <div class="medium-font">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class text-white me-3 position-relative" (click)="openViewCartModal()">
                View Cart
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" *ngIf="getCartItemsCount() > 0">
                  {{getCartItemsCount()}}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="mt-3">
      <div class="border bg-white p-4 mb-2" *ngFor="let item of filteredCatalogueLineItems$|async; index as i">
        <div class="col-12">
          <div class="row align-items-center">
            <div class="col-md-3 text-center position-relative">
              <img src="/downloadLandingBlob?fileId={{item.fileInfoDto!.fileId}}" class="catalogue-detail-image">
              <!-- <div class="catalogue-percent-text">
            <div class="percent-badge">12%</div>
          </div> -->
            </div>
            <div class="col-md-6 border-end">
              <div class="mb-2"><b>{{item.title}}</b></div>
              <div class="div-class mb-2">
                <div>
                  {{item?.description}}
                </div>
                <!-- <div class="medium-font">
              <span class="me-2">Type:</span>
              <span>Radiation Meter</span>
            </div>
            <div class="medium-font">
              <span class="me-2">Modal number:</span>
              <span>0.mm</span>
            </div>
            <div class="medium-font">
              <span class="me-2">Depth of cut:</span>
              <span>0.mm</span>
            </div>
            <div class="medium-font">
              <span class="me-2">Grade:</span>
              <span>Radiation Meter</span>
            </div>
            <div class="medium-font">
              <span class="me-2">Material:</span>
              <span>Radiation Meter</span>
            </div> -->
              </div>
              <!-- <div class="text-red-500  mb-2">
           View Details
          </div> -->
            </div>
            <div class="col-md-3 text-center">


              <div class="mt-4">
                <b class="fs-5">{{getLowestVendor(item).currency?.symbol}}{{getLowestVendor(item).amount}}</b>
                <!-- <span class="fs-6 text-decoration-line-through">
              Rs 567
            </span> -->
              </div>
              <div class="mt-2 div-class">
                <span class="me-2">Vendor:</span>
                <span>{{getLowestVendor(item).companyName}}</span>
              </div>
              <div class="mt-4">
                <div class="col-6 mx-auto">
                  <div class="input-group mb-3">
                    <span class="input-group-text bg-white prevent-select c-pointer" (click)="quantityItemMinus(quantityInput)">-</span>
                    <input #quantityInput type="number" id="quantityInput" class="form-control text-center" value="1" >
                    <span class="input-group-text bg-white prevent-select c-pointer" (click)="quantityItemPlus(quantityInput)">+</span>
                  </div>
                </div>
                <button class=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class btn btn-sm"
                  (click)="openAddToCartModal(item, quantityInput.value, addToCart)">
                  Add To Cart
                </button>
              </div>
              <div class="mt-3 div-class" *ngIf="item.catalogueVendors!.length > 1">
                <a class="link-class"
                  (click)="openCatalogueVendorsModal(allVendors, item)">+{{item.catalogueVendors!.length - 1}} ARI
                  Vendors</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</div>

<ng-template #addToCart>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">

        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(addToCartModalRef)">
        </div>
      </div>
    </header>
    <div class="model-body-top">
      <div class="m-3">
        <div class="div-class text-center">
          <b>You added {{selectedCatalogueLineItemDto?.title}} to your shopping cart.</b>
        </div>
        <div class="mt-2 text-center">
          <img src="/downloadLandingBlob?fileId={{selectedCatalogueLineItemDto?.fileInfoDto?.fileId}}" class="catalogue-detail-image-rounded">
        </div>
        <div class="mt-3 text-center">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class text-white me-3" (click)="openViewCartModal()">
            View Cart
          </button>
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class" (click)="closeModal(addToCartModalRef)">
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #allVendors>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>All Vendors</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(vendorsModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <table class="table table-bordered text-center medium-font">
          <thead class="font-weight-bold">
            <tr>
              <th scope="col" class="">#</th>
              <th scope="col" class="">Name</th>
              <th scope="col" class="">Price</th>
              <th scope="col" class="">Tax</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of selectedCatalogueLineItemDto?.catalogueVendors!; index as i">
              <th scope="row" class="">{{i+1}}</th>
              <td class="">{{item.companyName}}</td>
              <td class="">{{item.currency?.symbol}}{{item.amount}}</td>
              <td class="">{{item.tax}}%</td>    </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-template>