<div>
  <div class="row mb-3">
    <div class="col-11 pe-0">
      <div class="input-group">
        <div class="form-floating">
          <input class="form-control " id="itemName">
          <label for="itemName">Item Name
          </label>
        </div>
      </div>
    </div>
    <div class="col-1 mt-2">
      <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-11 pe-0">
      <div class="input-group">
        <div class="form-floating">
          <input class="form-control " id="description">
          <label for="description">Item description
          </label>
        </div>
      </div>
    </div>
    <div class="col-1 mt-2">
      <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
    </div>
  </div>
  <div class="mb-3">
    <p class="medium-grey-font">
      Set Parameters
    </p>
    <div class="row">
      <div class="col-md-9">
        <div class="row">
          <div class="col-6">
            <div div class="row">
              <div class="medium-font col-8">Response Mandatory?</div>
              <div class="col-4">
                <span class="form-switch c-pointer">
                  <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="medium-font col-8">No Response</div>
              <div class="col-4">
                <span class="form-switch c-pointer">
                  <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="medium-font col-8">Bidder Comments Required?</div>
              <div class="col-4">
                <span class="form-switch c-pointer">
                  <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
                </span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="medium-font col-8">Disqualify for no response</div>
              <div class="col-4">
                <span class="form-switch c-pointer">
                  <input class="form-check-input " type="checkbox" id="flexSwitchCheckChecked" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div>
          <a class="link-class">Click to upload Sample</a>
        </div>
        <div>
          <a class="link-class">newdoc.pdf</a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 w-50" *ngIf="!isSelectOption">
    <div ngbDropdown class="dropdown " #statusNgbDropdown1="ngbDropdown" [autoClose]="true">
      <button class="btn select-box-btn-class  text-start dropdown-toggle mb-2 rounded-0" type="button"
        data-bs-toggle="dropdown" ngbDropdownToggle aria-expanded="false">
        <div class="row">
          <div class="col-9">
            <span class="medium-grey-font">Select response type</span>
          </div>
          <div class="col-3 text-end">
            <img src="assets/icons/dropdown_black.svg" class=" c-pointer svg-icon-class-small">
          </div>
        </div>
      </button>
      <ul class="dropdown-menu text-start shadow mt-5 dropdown-width-class " ngbDropdownMenu>
        <li>
          test
        </li>
      </ul>
    </div>
  </div>
  <div>
    <div class="col-12 table-responsive">
      <table class="table medium-font">
        <thead>
          <tr>
            <th scope="col">Quantity</th>
            <th scope="col"> Unit of Measurement(Uom)</th>
            <th scope="col">Tax Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="input-group">
                <div class="form-floating">
                  <input class="form-control">
                </div>
              </div>
            </td>
            <td>
              <div class="input-group">
                <div class="form-floating">
                  <input class="form-control ">
                </div>
              </div>
            </td>
            <td>
              <div class="input-group">
                <div class="form-floating">
                  <input class="form-control">
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
 
  <div>
    <a class="link-class">
      Add more options
    </a>
  </div>
  <div class="col-12 table-responsive">
    <table class="table table-bordered medium-font">
      <thead class="table-dark">
        <tr>
          <th scope="col">Item No</th>
          <th scope="col">Item Name</th>
          <th scope="col">Item Description</th>
          <th scope="col">Qty</th>
          <th scope="col">Uom</th>
          <th scope="col">Tax Rate</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody class="table-info">
        <tr>
          <th scope="row">1.1</th>
          <td>Laptop</td>
          <td>Laptop</td>
          <td>
            20
          </td>
          <td>
            Nos
          </td>
          <td>
            18%
          </td>
          <td class="text-center">
            Draft
          </td>
          <td>
            <a class="link-class">Edit</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="small-font text-red-500  text-center mb-1">
    New changes found. Save as draft to avid data loss
  </div>
  <div class="col-12 text-center">
    <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3">
      Save as draft
    </button>
    <button class="btn btn-sm btn-outline-primary">
      Publish
    </button>
  </div>
</div>