<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>FLOOR NOTIFICATION</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
          (click)="closeModal()" />
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-2">
      <div class="mb-2 overflow-class">
        Auction : #{{ lastMessage?.auctionSequence }} {{ lastMessage?.auctionName }}
      </div>
      <div class="overflow-class">
        Lot : #{{ lastMessage?.lotSequence }} {{ lastMessage?.lotName }}
      </div>

      <div class="card-text font-weight-bold my-2 text-center">
        {{ lastMessage?.message }}
      </div>

      <div class="col-12 text-end">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="closeModal()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>