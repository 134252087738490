<div>
  <div class="flex flex-row gap-4 items-center justify-between h-12 bg-white px-4 py-4 border-b">
    <div class="basis-1/2">
      <div class="inline-block">
        <div class="font-semibold text-black text-xl">
          Viewing NIT documents
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-100 h-8 py-2 px-6 flex items-center flex">
    <div class="inline-flex font-light text-base text-gray-600 me-2">Title:</div>
    <div class="inline-flex font-semibold text-base text-gray-800 me-3 overflow-class-small"  [pTooltip]="tooltipProjects" tooltipPosition="top" placeholder="Top">
      {{tenderWrapperUiDto?.title}}
      <ng-template #tooltipProjects>
        <div class="flex items-center p-2">
          {{tenderWrapperUiDto?.title}}
        </div>
      </ng-template>
    </div>
    <span class="font-light text-base text-gray-600 me-2">Ref. number:</span>
    <span class="font-semibold text-base text-gray-800 me-3">
      {{tenderWrapperUiDto?.tenderReferenceNo}}
    </span>
  </div>
  <div class="h-screen">
    <div class=" px-3 py-3 tr-overflow-y-drawer w-full">
      <div class="flex flex-row">
        <div class="w-80 py-6 px-4 border-e bg-gray-100 h-full rounded-lg m-2">
          <div class="grid grid-cols-12 items-center justfy-between">
            <div class="col-span-10">
              <div class=" text-black font-semibold text-xs">
                Number of document added
              </div>
            </div>
            <div class="col-span-2 text-end">
              <button class="bg-gray-100 rounded h-6 w-6 p-1 items-center inline-flex">
                {{tenderWrapperUiDto?.nitDocuments?.length}}
              </button>
            </div>
          </div>
          <div class="mt-3">
            <div class="border border-gray-100 bg-white rounded-lg py-2 ps-2 gap-28 mb-3"
              *ngFor="let item of tenderWrapperUiDto?.nitDocuments;" (click)="selectNitDoc(item)">
              <div class="flex flex-row justify-between">
                <div class="basis-1/12 mt-3 me-2">
                  <i-lucide name="badge-check" [size]="16" color="#10B981" class="my-icon"></i-lucide>
                </div>
                <div class="basis-10/12">
                  <div class=" text-gray-800 font-medium text-sm overflow-class-xsmall">
                    {{item?.displayName}}
                  </div>
                  <div class=" text-gray-600 font-normal text-xs ">
                    {{item?.uploadedDate! | ordinalDate}}
                  </div>
                </div>
                <div class="basis-1/12 mt-3">
                  <i-lucide name="chevron-right" [size]="22" color="#9CA3AF" class="my-icon"></i-lucide>
                </div>
              </div>
            </div>
            <!-- <div class="border border-gray-100 bg-white rounded-lg py-2 ps-2 gap-28 mb-3">
              <div class="flex flex-row justify-between">
                <div class="basis-1/12 mt-3 me-2">
                  <i-lucide name="badge-check" [size]="16" color="#10B981" class="my-icon"></i-lucide>
                </div>
                <div class="basis-10/12">
                  <div class=" text-gray-800 font-medium text-sm overflow-class-xsmall">
                    abc
                  </div>
                  <div class=" text-gray-600 font-normal text-xs ">
                    Start date - End date
                  </div>
                </div>
                <div class="basis-1/12 mt-3">
                  <i-lucide name="chevron-right" [size]="22" color="#9CA3AF" class="my-icon"></i-lucide>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="w-full">
          <div class="border-b border-color-100 mt-3">
            <div class=" nav-bg text-start ps-3">
              <ul class="" style="list-style: none;" class="ps-0">
                <li class="list-class items-center inline-flex" (click)="toggleDetailsTab('DETAILS')"
                  [ngClass]="currentTab == 'DETAILS' ? 'btn-active' : 'btn-default'">
                  <i-lucide name="receipt-text" [size]="18" [color]="currentTab == 'DETAILS' ? '#0555F5' : '#475569'"
                    class="my-icon me-1"></i-lucide>
                  Details
                </li>
                <li class="list-class" (click)="toggleDetailsTab('PREVIEW')"
                  [ngClass]="currentTab == 'PREVIEW' ? 'btn-active' : 'btn-default'">
                  <i-lucide name="settings" [size]="18" [color]="currentTab == 'PREVIEW' ? '#0555F5' : '#475569'"
                    class="my-icon me-1"></i-lucide>
                  Preview
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="currentTab == 'DETAILS'">
            <div class=" col-12 p-2">
              <div class=" bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
                <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
                  <div class="grid grid-cols-3 gap-4 w-full">
                    <div class="col-span-2 text-gray-500 font-semibold leading-4 tracking-tight text-base">
                      <span class="">
                        <img src="/assets/icons/tr-basic-detail.svg" class="tr-md-icon inline-flex">
                      </span>
                      Details
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-1  gap-4 w-full p-4">
                  <div class="">
                    <div class="grid grid-cols-3 gap-4 w-full">
                      <div class=" text-black font-semibold leading-normal text-sm mb-4">
                        Title:
                      </div>
                      <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
                        {{selectedNitDocument?.displayName}}
                      </div>
                    </div>
                    <div class="grid grid-cols-3 gap-4 w-full">
                      <div class=" text-black font-semibold leading-normal text-sm mb-4">
                        Description:
                      </div>
                      <div class="col-span-2 text-black font-normal leading-normal text-sm mb-4">
                        <!-- [innerHTML]="tenderOpportunityWrapperDto?.description ?? '' | safe: 'html'" -->
                        <p class="p-class animate-show-more" #descriptionRef
                          [ngClass]="{'hide-description': !isShowDescription}">{{selectedNitDocument?.description}} </p>
                        <div class="bold-border my-2" *ngIf="descriptionRef.scrollHeight > 120"></div>
                        <div class="col-12 text-end ">
                          <span class="btn-link" (click)="showOrHideDescription()"
                            *ngIf="descriptionRef.scrollHeight > 120">
                            {{isShowDescription ? 'HIDE MORE' : 'SHOW MORE'}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="currentTab == 'PREVIEW'">
            <div class=" col-12 p-2">
              <div class=" bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
                <div class="flex py-0 px-4 h-full items-center self-stretch gap-2.5 bg-gray-200 rounded-lg">
                  <div class="mb-3 flex flex-row mx-auto justify-center items-center" *ngIf="isDocLoading">
                    <p-progressSpinner></p-progressSpinner>
                  </div>
                  <iframe *ngIf="docPdfUrl && !isDocLoading" [src]="docPdfUrl | safe: 'url'" width="100%"
                    height="1000px">
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="flex flex-row border-t border-color-500 h-8 mt-3">
      <div class="basis-1/2 mt-2 ps-6">
        <div class="h-2 w-20 bg-blue-200 inline-block"></div>
        <!-- <iframe 
        *ngIf="selectedNitDocument?.fileId"
        [src]="'downloadBlob?fileId='+selectedNitDocument?.fileId | safe: 'url'">
      </iframe> -->
      </div>
      <div class="basis-1/2 text-end mt-2 pe-7">
        <button
          class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeDrawer()">Cancel</button>
    
      </div>
    </div>
  </div>
</div>