import { BidderRequirementsDto } from './BidderRequirementsDto';
import { AddressDto } from "../Address";
import { Currency } from "../Currency";
import { AuctionStatus } from "./AuctionStatus";
import { FileInfoDto } from '../FileInfoDto';
import { ListingContactDto } from '../ListingContactDto';
import { Timestamp } from '@angular/fire/firestore';
import { ShowBidderHistoryEnum } from '../../enums/ShowBidderHistoryEnum';

export class AuctionEntityDto {
  sequenceNo?: number;
  parentId?: string;
  auctionId?: string;
  auctionType?: string;
  title?: string;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  timeZone?: string;
  publishReserve?: boolean;
  auctionDocs?: Array<FileInfoDto>;
  status?: AuctionStatus;
  antiSnipe?: number;
  domainName?: string;
  companyId?: string;
  projectCode?: string;

  questionnaireId?: string;

  // Currency
  currency?: Currency;

  // termsConditions?: string;
  // confidentialAgreement?: string;
  stateAbbreviate?: string;

  //bidder related
  listingContactDtos?: Array<ListingContactDto>;
  bidderRequirementsDtos?: Array<BidderRequirementsDto>;
  bidderApproval?: string;
  rankCurrentPriceConfig?: string;

  maskBidderDetails?: boolean;

  active?: boolean = false;
  singleLot?: boolean ;
  buyItNowEnable?: boolean;
  auctionBiddingMethod?: string

  auctionName?: string;
  orgCode?: string;
  auctionHouseName?: string;

  addresses?: Array<AddressDto>
  notOfLots?: number;
  noOfActiveLots?: number;
  version?: number;
  premiumAuction?: boolean;
  auctionImage?: FileInfoDto;
  createdBy?: string
  createdDate?: string

  liveStreaming?: boolean;
  enableBlockchain?: boolean


  showBidderHistory?: ShowBidderHistoryEnum
  accountNo?: string
  bankName?: string
  updateTimeUtc?: string|Timestamp
  updateTimeUtcMillis?: number;

  allowEditWhileUpcoming: boolean = true;
  allowEditWhileRunning: boolean = true;

  region?: string;
  costCenters?: string;
  estSpend?: string;
  estSaving?: string;
}
