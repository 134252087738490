<div class="flex relative ">
  <div class="w-8 absolute left-0 top-0 bottom-0 pl-2  bg-gray-900">
    <div class=" mt-2" (click)="toggleDrawer()">
      <i-lucide name="x" color="#fff" [size]="20" class="my-icon cursor-pointer"></i-lucide>
    </div>
    <div class="tr-vertical-text mt-6">
      Back to Tender Management
    </div>
  </div>
  <!-- <div class="tr-close-bg" (click)="toggleDrawer()">
    <div class="svg-icon-class-md">
      <img src="/assets/icons/drawer-close.svg" class="tr-md-icon c-pointer" />
    </div>
    <div class="tr-vertical-text tr-margin-top-vrtical-txt">
      Back to Organization Management
    </div>
  </div> -->
  <div class=" ms-8 w-full">
    <ng-container *ngTemplateOutlet="
        pageName == 'ONBOARDING_NEW_COMPANY' ? onboardingNewCompanyPage
      : pageName == 'ONBOARDING_NEW_PLANT' ? onboardingNewPlantPage
      : pageName == 'ONBOARDING_NEW_BUDGET' ? onboardingNewBudgetPage
      : pageName == 'ONBOARDING_NEW_USER' ? onboardingNewUserPage
      : pageName == 'NEW_MATERIAL_PAGE' ? onboardingNewMaterialPage
      : pageName == 'USER_EXTEND_PROFILE' ? extendProfilePage
      : pageName == 'ONBOARDING_NEW_PROFILE' ? onboardingNewProfilePage
      : pageName == 'NEW_TENDER_PAGE' ? newTenderPage
      : pageName == 'NEW_TENDER_TECH_SECTION'? newTechSection
      : pageName == 'NEW_TENDER_TECH_QUES' ? newTechQues
      : pageName == 'NEW_TENDER_FINANCIAL_QUES' ? newFinancialQues
      : pageName == 'NIT_DOCS_PAGE' ? nitDocsPage
      : pageName == 'NEW_TENDER_APPROVAL_PAGE' ? newTenderApprovalPage
      : pageName == 'NEW_OPPORTUNITY_PAGE' ? newOpportunityPage
      : pageName == 'NEW_TENDER_QUESTIONNAIRE' ? newQuestionnaire
      : emptyTemplate">
    </ng-container>
  </div>
</div>

<ng-template #newQuestionnaire>
  <app-tender-questionnaire-drawer></app-tender-questionnaire-drawer>
</ng-template>
<ng-template #onboardingNewCompanyPage>
  <app-onboarding-new-company (onCloseEvent)="updatePageName($event)"></app-onboarding-new-company>
</ng-template>
<ng-template #newTenderApprovalPage>
  <app-tender-approvals-drawer></app-tender-approvals-drawer>
</ng-template>
<ng-template #nitDocsPage>
  <app-nit-document-drawer></app-nit-document-drawer>
  </ng-template>
<ng-template #onboardingNewMaterialPage>
<app-new-material></app-new-material>
</ng-template>
<ng-template #newFinancialQues>
  <app-new-tender-financial-question-drawer></app-new-tender-financial-question-drawer>
</ng-template>
<ng-template #newTenderPage>
 <app-new-tender-drawer></app-new-tender-drawer>
</ng-template>
<ng-template #newTechSection>
  <app-new-tender-technical-section-drawer></app-new-tender-technical-section-drawer>
</ng-template>
<ng-template #newTechQues>
  <app-new-tender-technical-question-drawer></app-new-tender-technical-question-drawer>
</ng-template>
<ng-template #newOpportunityPage>
  <app-new-opportunity-drawer></app-new-opportunity-drawer>
 </ng-template>
<ng-template #onboardingNewPlantPage>
  <app-onboarding-new-plant (onCloseEvent)="updatePageName($event)"></app-onboarding-new-plant>
</ng-template>

<ng-template #onboardingNewBudgetPage>
  <app-onboarding-new-budget (onCloseEvent)="updatePageName($event)"></app-onboarding-new-budget>
</ng-template>

<ng-template #onboardingNewUserPage>
  <app-onboarding-new-user (onCloseEvent)="updatePageName($event)"></app-onboarding-new-user>
</ng-template>

<ng-template #onboardingNewProfilePage>
  <app-onboarding-new-profile (onCloseEvent)="updatePageName($event)"></app-onboarding-new-profile>
</ng-template>

<ng-template #extendProfilePage>
  <app-user-extand-profile (onCloseEvent)="updatePageName($event)"></app-user-extand-profile>
</ng-template>

<ng-template #emptyTemplate></ng-template>