<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>{{rfxSubcategoryEntityDto?.subcategorySequence}}# {{rfxSubcategoryEntityDto?.title}} <span *ngIf="!rfxSubcategoryEntityDto?.subcategoryId">(NEW)</span></h2>
      </div>
      <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="p-3">
      <form class="needs-validation" novalidate [formGroup]="formGroup">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" placeholder="Title" id="title" formControlName="title">
          <label for="title">Title</label>
          <div *ngIf="fc.title.invalid && (fc.title.dirty || fc.title.touched)">
            <p class="text-red-500 " *ngIf="fc.title.errors?.required">Required</p>
          </div>
        </div>

        <div class="mb-3">
          <label for="descriptionEditor" class="text-area-label">Description
          </label>
          <div class="form-group">
            <angular-editor #descriptionEditor id="descriptionEditor" formControlName="description" class="form-control"
              [config]="config"></angular-editor>
          </div>
        </div>

        <div class="row">
          <div class="col-6 mb-3">
            <div class="input-group">
              <span class="border  border-end-0 pt-3 px-1">
                <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
              </span>
              <div class="form-floating ">

                <input type="text" ngbDatepicker [minDate]="minDate" #endDate="ngbDatepicker" (click)="endDate.toggle()"
                [ngClass]="{'border-danger': fc.endDate.value && haveAnyErrorInEndDate()}" id="endDate"
                  class="form-control border-start-0" placeholder="End Date" formControlName="endDate">
                <label for="endDate" class="">End Date</label>
              </div>
            </div>
            <div *ngIf="fc.endDate.invalid && (fc.endDate.dirty || fc.endDate.touched)">
              <p class="text-red-500 " *ngIf="fc.endDate.errors?.required">Required</p>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div class=" input-group">
              <span class="border  border-end-0 pt-3 px-1">
                <img src="assets/icons/time_light.svg" class="svg-icon-class c-pointer">
              </span>
              <div class="form-floating">

                <input type="text" ngbPopover [autoClose]="'outside'" triggers="manual" #p1="ngbPopover"
                [ngClass]="{'border-danger': fc.endDate.value && haveAnyErrorInEndDate()}"
                  (click)="openTimepicker(p1, endTimeModal)" class="form-control border-start-0" placeholder="End Time"
                  formControlName="endTime" id="endTime">
                <label for="endTime" class="">End Time</label>
              </div>
            </div>

            <div *ngIf="fc.endTime.invalid && (fc.endTime.dirty || fc.endTime.touched)">
              <p class="text-red-500 " *ngIf="fc.endTime.errors?.required">Required</p>
            </div>
          </div>
        </div>
        
        <app-categories-tree-view [selectionMode]="TreeSelectionMode.single"
            (onSelectedCategory)="onSelectedCategory($event)"></app-categories-tree-view>

        <!-- HashTags -->
        <div class="mb-3">
          <label for="hashtags" class="text-area-label">HashTags</label>
          <div class="form-group">
            <tag-input formControlName="hashtags" id="hashtags" [modelAsStrings]="true" [placeholder]="inputTagPlaceholder"
              [secondaryPlaceholder]="inputTagPlaceholder" [separatorKeyCodes]="separatorKeyCodes"></tag-input>
          </div>
        </div>

        <!-- Highlights -->
        <div class="mb-3">
          <label for="highlights" class="text-area-label">Highlights</label>
          <div class="form-group">
            <textarea #propertyHighlightEditor id="highlights" formControlName="highlights" class="form-control" rows="2"></textarea>
          </div>
        </div>


        <div class="input-group mt-3">
          <div class="form-floating">
            <input name="contractPeriod" id="contractPeriod" type="number" placeholder="Contract Period" formControlName="contractPeriod" class="form-control border-start-0" />
            <label for="contractPeriod">Contract Period</label>
          </div>
        </div>

        <div class="input-group mt-3">
          <span class="border border-end-0 pt-4 px-1 fa-lg">
            {{rfxEntityDto?.currency?.symbol}}
          </span>
          <div class="form-floating">
            <input name="bucketSize" type="text" placeholder="Emd Value" formControlName="emdValue" id="emdValue"
              (blur)="formatEmdValue()" class="form-control border-start-0" />
            <label for="emdValue">Emd Value</label>
          </div>
        </div>
        <div *ngIf="isSubmitted || fc.emdValue.invalid && (fc.emdValue.dirty || fc.emdValue.touched)">
          <p class="text-red-500 " *ngIf="fc.emdValue.errors?.required">Required</p>
          <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.emdValue)">Invalid</p>
        </div>

        <div class="input-group mt-3">
          <span class="border border-end-0 pt-4 px-1 fa-lg">
            {{rfxEntityDto?.currency?.symbol}}
          </span>
          <div class="form-floating">
            <input name="estimatedValue" type="text" placeholder="Estimated Value" formControlName="estimatedValue" id="estimatedValue"
              (blur)="formatEstimatedValue()" class="form-control border-start-0" />
            <label for="estimatedValue">Estimated Value</label>
          </div>
        </div>
        <div *ngIf="isSubmitted || fc.estimatedValue.invalid && (fc.estimatedValue.dirty || fc.estimatedValue.touched)">
          <p class="text-red-500 " *ngIf="fc.estimatedValue.errors?.required">Required</p>
          <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.estimatedValue)">Invalid</p>
        </div>
    
        <!-- targetedBidders -->
        <div class="col-12 mt-3 mb-2" >
          <div class="row">
            <div class="col-md-6 mb-2">
              Targeted Bidder
            </div>
            <div class="col-md-6 mb-2">
              <span class="me-2">
                <input type="checkbox"  formControlName="targetedBidders" id="targetedBidders">
              </span>
            </div>
          </div>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'Subcategory saved successfully.'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
        </div>

        <div class="mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="handleValidSubmit()" [disabled]="!isAllowToEdit()">SAVE</button>
        </div>

      </form>
    </div>
  </div>
</div>

<!-- NgbTimePicker -->
<ng-template #endTimeModal>
  <ngb-timepicker [(ngModel)]="endTime" [meridian]="true"></ngb-timepicker>
  <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="setTime('endTime')">SAVE</button>
  <!-- <a class="e-buttontext ee-size-s mt-2 w-100 ee-vis-l ea-insert" >Save</a> -->
</ng-template>

