import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { CatalogueLineItemsEntityDto } from '../models/CatalogueLineItemsEntityDto';
import { CatalogueCartWrapperDto } from '../models/CatalogueCartWrapperDto';
import { CatalogueRequisitionWrapper } from '../models/CatalogueRequisitionWrapper';
import { CatalogueL2EntityDto } from '../models/CatalogueL2EntityDto';
import { CatalogueL1EntityDto } from '../models/CatalogueL1EntityDto';

@Injectable({
  providedIn: 'root'
})
export class CatalogueService {

  cartItems: CatalogueCartWrapperDto[] = []
  selectedCatalogueL1?: CatalogueL1EntityDto
  selectedCatalogueL2?: CatalogueL2EntityDto

  private _selectedCatalogueL1$ = new BehaviorSubject<CatalogueL1EntityDto | undefined>(undefined);
  private _selectedCatalogueL2$ = new BehaviorSubject<CatalogueL2EntityDto | undefined>(undefined);
  _cartItems$ = new BehaviorSubject<CatalogueCartWrapperDto[]>([]);

  catalogueRequisitionWrapperList: CatalogueRequisitionWrapper[] = []
  _catalogueRequisitionWrapperList$ = new BehaviorSubject<CatalogueRequisitionWrapper[]>([]);

  constructor() { }

  get getCartItems$() { return this._cartItems$.asObservable() };
  get getRequisitionWrapperList$() { return this._catalogueRequisitionWrapperList$.asObservable() };
  get getSelectedCatalogueL1$() { return this._selectedCatalogueL1$; };
  get getSelectedCatalogueL2$() { return this._selectedCatalogueL2$; };

  updateSelectedCatalogueL1EntityDto(catalogueL1EntityDto?: CatalogueL1EntityDto) {
    this.selectedCatalogueL1 = catalogueL1EntityDto;
    this._selectedCatalogueL1$.next(catalogueL1EntityDto);
  }

  updateSelectedCatalogueL2EntityDto(catalogueL2EntityDto?: CatalogueL2EntityDto) {
    this.selectedCatalogueL2 = catalogueL2EntityDto;
    this._selectedCatalogueL2$.next(catalogueL2EntityDto);
  }
  
  addToCart(catalogueLineItemsEntityDto: CatalogueLineItemsEntityDto, quantity: number) {
    let catalogueCartWrapper = this.cartItems.find(item => item.catalogueLineItemsEntityDto?.lineItemId == catalogueLineItemsEntityDto.lineItemId);
    let sortedVendors = catalogueLineItemsEntityDto.catalogueVendors!.sort((a, b) => Number(a.amount!) - Number(b.amount!));
    let catalogueVendor = sortedVendors[0];

    if (catalogueCartWrapper) {
      let index = this.cartItems.findIndex(item => item.catalogueLineItemsEntityDto?.lineItemId == catalogueLineItemsEntityDto.lineItemId);
      catalogueCartWrapper.quantity = quantity;
      catalogueCartWrapper.currency = catalogueVendor.currency;
      catalogueCartWrapper.catalogueVendor = catalogueVendor;
      Object.assign(this.cartItems[index], catalogueCartWrapper);
    } else {
      catalogueCartWrapper = new CatalogueCartWrapperDto();
      catalogueCartWrapper.catalogueLineItemsEntityDto = catalogueLineItemsEntityDto;
      catalogueCartWrapper.quantity = quantity;
      catalogueCartWrapper.currency = catalogueVendor.currency;
      catalogueCartWrapper.catalogueVendor = catalogueVendor;
      this.cartItems.push(catalogueCartWrapper);
    }
    this._cartItems$.next(this.cartItems);
  }

  updateCatalogueRequisitionWrapper(catalogueRequisitionWrapper: CatalogueRequisitionWrapper) {
    let data = this.catalogueRequisitionWrapperList.find(item => item.id == catalogueRequisitionWrapper.id);
    if (data) {
      Object.assign(catalogueRequisitionWrapper, data);
      this._catalogueRequisitionWrapperList$.next(this.catalogueRequisitionWrapperList);
    }


  }

  removeFromCart(lineItemId: string) {
    let index = this.cartItems.findIndex(item => item.catalogueLineItemsEntityDto?.lineItemId == lineItemId);
    this.cartItems.splice(index, 1);
    this._cartItems$.next(this.cartItems);
  }

  clearCart() {
    this.cartItems = [];
    this._cartItems$.next(this.cartItems);
  }

  submitRequisition(catalogueRequisitionWrapper: CatalogueRequisitionWrapper) {
    let data = this.catalogueRequisitionWrapperList.find(item => item.id == catalogueRequisitionWrapper.id);
    if (!data) {
      this.catalogueRequisitionWrapperList.push(catalogueRequisitionWrapper);
      this._catalogueRequisitionWrapperList$.next(this.catalogueRequisitionWrapperList);
    }
  }
}
