import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { TenderQuestionnaireTemplateDto } from 'src/app/shared/models/tender/TenderQuestionnaireTemplateDto';
import { TenderQuestionnaireWrapperDto } from 'src/app/shared/models/tender/TenderQuestionnaireWrapperDto';
import { TenderSectionTemplateDto } from 'src/app/shared/models/tender/TenderSectionTemplateDto';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { selectTenderOpportunityWrapperDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-new-tender-technical-question',
  templateUrl: './new-tender-technical-question.component.html',
  styleUrls: ['./new-tender-technical-question.component.sass']
})
export class NewTenderTechnicalQuestionComponent implements OnInit, OnDestroy {
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;
  tenderQuestionnaireWrapperDto?: TenderQuestionnaireWrapperDto;
  tenderQuestionnaireTemplateDto?: TenderQuestionnaireTemplateDto;

  technicalSectionTemplateDtos: TenderSectionTemplateDto[] = []

  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  constructor(
    private store: Store,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
        this.tenderQuestionnaireWrapperDto = this.tenderOpportunityWrapperDto?.tenderQuestionnaireWrapperDto;

        if (this.tenderQuestionnaireWrapperDto) {
          this.tenderQuestionnaireTemplateDto = this.tenderQuestionnaireWrapperDto.tenderQuestionnaireTemplateDto;
          let tenderSectionTemplateDtos = this.tenderQuestionnaireWrapperDto.tenderSectionTemplateDtos || [];
          this.technicalSectionTemplateDtos = tenderSectionTemplateDtos.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL);
        }
      } else {
        this.tenderOpportunityWrapperDto = undefined;
        this.tenderQuestionnaireWrapperDto = undefined;
        this.technicalSectionTemplateDtos = [];
      }
    })
  }

  openNewTechSectionDrawer() {
    this.drawerService.openDrawer(ApplicationConstants.NEW_TENDER_TECH_SECTION);
  }

  getQuestionsOfSection(sectionId: string) {
    let tenderTechnicalQuestionTemplateDtos = this.tenderQuestionnaireWrapperDto?.tenderTechnicalQuestionTemplateDtos || [];
    return tenderTechnicalQuestionTemplateDtos.filter(item => item.sectionId == sectionId);
  }

  ngOnDestroy(): void {
    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}
