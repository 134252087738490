<div class="chatbot">
  <p-button #fabBot (click)="toggle(op, $event)" styleClass="fabMessageButton">
    <img src="assets/icons/chat.svg" class="svg-icon-class c-pointer">
  </p-button>

  <p-overlayPanel #op [dismissable]="false" [showCloseIcon]="true" [appendTo]="fabBot" styleClass="chatbot-panel">
    <ng-template pTemplate="content">
      <div class="m-0">
        <!-- <p-tabView> -->
        <!-- <p-tabPanel header="Bot Chat" sty> -->
          <div class="bg-blue py-3 px-2">
          <div class="row">
         <div class="col-2 pe-0">
          <img src="assets/images/Background Image.png" alt="" class="loading-gif-small ">
         </div>
            <div class="col-9 text-white ps-0 mt-2"><h6>
              PROCUREXT.AI
            </h6></div>
          </div>
          </div>
        <div class="chat-container p-3">
          <div class="chat-messages">
            <div #messagesContainer class="message" *ngFor="let message of chatBotMessages; index as i">
              <div class="message-content"
                [ngClass]="{'user-message': message.from?.id == userEntityDto?.userId, 'bot-message': message.from?.id != userEntityDto?.userId}">
                <div class="w-100 text-start" *ngIf="message.from?.id != userEntityDto?.userId">
                  <div class="row mb-2">
                    <div class="col-2 text-center me-3">
                      <img src="assets/images/Background Image.png" alt="" class="loading-gif-small mt-2">
                      <div class="small-font">{{message.from?.name}}</div>
                      <div class="small-font">12/6/24</div>
                    </div>
                    <div class="col-9 text-start">
                      <span class="me-2 medium-font">
                        {{message.text}}</span>
                    </div>
                  </div>
                </div>
                <div class="w-100 text-end" *ngIf="message.from?.id == userEntityDto?.userId">
                  <div class="row mb-2">
                    <div class="col-9 me-3 text-start">
                      <span class="me-2 medium-font">
                        {{message.text}}</span>
                    </div>
                    <div class="col-2 text-center">
                      <span>
                        <img src="assets/images/Background Image.png" alt="" class="loading-gif-small mt-2">
                      </span>
                    </div>
                    <div class=" small-font">
                      {{userEntityDto?.firstName}} {{userEntityDto?.lastName}}
                    </div>
                    <div class=" small-font">
                      12/6/24
                    </div>
                  </div>
                </div>
                <div *ngIf="message.attachments && message.attachments.length > 0">
                  <ng-container *ngFor="let attachment of message.attachments; index as j">
                    <div *ngIf="attachment.contentType === 'application/vnd.microsoft.card.adaptive'">
                      <div #adaptiveCardContainer [id]="'adaptiveCard' + i + '-' + j"></div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="chat-input">
            <input type="text" placeholder="Type your message here..." [(ngModel)]="newMessage"
              (keyup.enter)="sendMessage()">
            <button (click)="sendMessage()">Send</button>
          </div>
        </div>

        <!-- </p-tabPanel> -->

        <!-- <p-tabPanel header="Team Chat">
        <div class="chat-container">
          <div class="chat-messages">
            <div class="message" *ngFor="let message of teamMessages">
              <div class="message-content"
                [ngClass]="{'user-message': message.isUser, 'bot-message': !message.isUser}">
                <div class="w-100 bg-color">
                  <span> <img src="assets/images/Background Image.png" alt="" class="loading-gif-small"></span>
                  <span> abc</span>
                </div>
              </div>
            </div>
          </div>
          <div class="chat-input">
            <input type="text" placeholder="Type your message here..." [(ngModel)]="newMessage"
              (keyup.enter)="sendMessage()">
            <button (click)="sendMessage()">Send</button>
          </div>
        </div>
      </p-tabPanel> -->
        <!-- </p-tabView> -->
      </div>
    </ng-template>
  </p-overlayPanel>
</div>