<div class="model-body ">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>
          Financial Questionnaire
        </h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="header-divider"></div>
  <div class="model-body-top">
    <div class="m-3 height-of-modal">
      <div class="row">
        <div class="col-md-4 border-end mb-3">
          <div>
            <div class="col-12 text-end">
              <a class="link-class text-end" (click)="openNewSectionModal()">Add Section</a>
            </div>
            <hr class="hr my-2">
            <div class="border p-2" [ngClass]="{'border-danger' : sectionValidationFailed}">
              <div class=" row">
                <div class="medium-font col-6 ">
                  Name
                </div>
                <div class="col-2 text-center medium-font">
                </div>
              </div>
              <div cdkDropList (cdkDropListDropped)="drop($event)">
                <div class="col-12 border mb-2 c-pointer" *ngFor="let item of sectionTemplateList"
                  [ngClass]="item.sectionId == selectedSectionTemplate?.sectionId ? 'active-border': ''"
                  (click)="changeSection(item)" cdkDrag cdkDragLockAxis="y">
                  <div class="row p-2 align-items-center">
                    <div class="col-10 medium-font">
                      {{item.preSequenceText}}. {{item.sectionName}}
                    </div>
                    <div class="col-2 text-center" cdkDragHandle>
                      <img src="assets/icons/reorder_light.svg" class="svg-icon-class-small c-pointer">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="mt-2 text-end">
            <button class="btn btn-sm btn-outline-primary">
              Add Section
            </button>
          </div>
          <div class=" text-center mt-5">
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
              <span class="small-font text-white">
                Save as template
              </span>
            </button>
          </div> -->
        </div>
        <div class="col-md-8 mb-1">
          <div class="row mb-2">
            <div class="col-md-6 mb-2">
              <div class="row">
                <div class="col-2 font-weight-normal py-2">
                  Name:
                </div>
                <div class="col-10 div-class py-2">
                  {{selectedSectionTemplate?.sectionName}}
                </div>
              </div>
            </div>
            <div class="col-md-6 text-md-end mb-2" *ngIf="selectedSectionTemplate?.sectionName">
              <span class="me-3 mt-2">
                <img src="assets/icons/edit_light.svg" class="svg-icon-class c-pointer"
                  (click)="openNewSectionModal(true)">
              </span>
              <span class="mt-2">
                <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer" (click)="openDeleteConfirmModal(deleteConfirmModal)">
              </span>
            </div>
          </div>
          <div class="hr-class my-2"></div>
          <app-rfx-questionnaire-financial-question></app-rfx-questionnaire-financial-question>

          <!-- <div class="row mb-3">
            <div class="col-12">
              <span class="me-2">
                <a class="link-class-small">Previous</a>
              </span>
              <span class="me-2 medium-font">
                Question 1 to 12
              </span>
              <span class="me-2">
                <a class="link-class-small">Next</a>
              </span>
              <span>
                <span class="me-1 badge small-badge-grey">
                  Draft
                </span>
                <span class="medium-font me-2">
                  10
                </span>
                <span class="me-1 badge small-badge-primary">
                  Published
                </span>
                <span class="medium-font">
                  2
                </span>
              </span>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-7 mb-3">
              <span class="medium-font me-2">
                10
              </span>
              <span class="me-1 badge small-badge-grey">
                Draft
              </span>
            </div>
            <div class="col-md-5 text-md-end mb-3">
              <button class="btn btn-sm btn-outline-primary me-3">
                Import Items
              </button>
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                Add New Item
              </button>
            </div>
          </div>
          <app-financial-question></app-financial-question> -->
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #deleteConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>DELETE SECTION</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoadingForModal}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal(deleteConfirmModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Are you sure you want to delete this section?</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToastForModal$ |async" [isError]="true" [message]="errorMsgForModal"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToastForModal$ |async" [isError]="false" message="Section deleted successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoadingForModal">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoadingForModal && !(_showSuccessToastForModal$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal(deleteConfirmModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="deleteSectionTemplate()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<app-loader #appLoader></app-loader>

<ng-template #newSectionModalTemplate>
  <div class="model-body ">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>
            Financial Questionnaire
          </h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(addSectionModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3 height-of-modal">
        <div class="input-group">
          <div class="form-floating">
            <input #title class=" form-control" [formControl]="ctrlSectionTitle" id="ctrlSectionTitle"
              [ngClass]="{'border-danger': ctrlSectionTitle.invalid || (ctrlSectionTitle.invalid && title.focus)}">
            <label for="ctrlSectionTitle">Title</label>
          </div>
        </div>
        <div *ngIf="ctrlSectionTitle.invalid && (ctrlSectionTitle.dirty || ctrlSectionTitle.touched)">
          <p class="text-red-500 " *ngIf="ctrlSectionTitle.errors?.['required']">Required</p>
        </div>

        <div class="my-1">
          <app-message-toast *ngIf="_showErrorToastForModal$ |async" [isError]="true"
            [message]="errorMsg"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToastForModal$ |async" [isError]="false"
            [message]="'Section saved successfully.'"></app-message-toast>
        </div>

        <div class="d-grid gap-2 my-1" *ngIf="isLoadingForModal">
          <div class="e-spinner"></div>
        </div>

        <div class="col-12 mt-2 text-end">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            [ngClass]="{'disabled': selectedRfx?.status == 'LIVE' || selectedRfx?.status == 'LIVE_WAIT' || ctrlSectionTitle.invalid}"
            (click)="saveSectionTemplate()" *ngIf="!isLoadingForModal && !(_showSuccessToastForModal$ | async)">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>