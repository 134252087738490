export class Currency {
  name?: string;
  symbol?: string;
  locale?: string;
  code?: string;
  regex?: string;

  static default(): Currency {
    let currency = new Currency();
    currency.name = 'Indian Rupee (INR)';
    currency.symbol = '₹';
    currency.locale = 'en-IN';
    currency.code = 'INR';
    currency.regex = '^(?:\\d+|\\d{1,2},(?:\\d{2},)*\\d{3})(?:\\.\\d{2})?$';
    return currency;
  }
}