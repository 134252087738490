import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { AuctionAuditActivityEnum } from 'src/app/shared/enums/AuctionAuditActivityEnum';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionAuditActivityDto } from 'src/app/shared/models/user/AuctionAuditActivityDto';
import { SourcingEventDto } from 'src/app/shared/models/user/SourcingEventDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

declare const require: any;
const html2pdf = require('html2pdf.js');

@Component({
  selector: 'app-auction-timeline',
  templateUrl: './auction-timeline.component.html',
  styleUrls: ['./auction-timeline.component.sass']
})
export class AuctionTimelineComponent implements OnInit{

  @Input() selectedAuction!: SourcingEventDto;

  isShowPdfView: boolean = false;
  isLoading: boolean = true;

  activityList:  Array<AuctionAuditActivityDto> = [];



  constructor(
    private auctionService: AdminSourcingEventsDataHolderService,
    private adminService: AdminDashboardService,
    private ngbModal: NgbModal,
    private changeDetectRef: ChangeDetectorRef
  ){}

  ngOnInit(): void {
    this.loadAuctionActivity();
  }

  getAuctionDetailsMsg(type: string){
    let toReturn = "";
    if(type == AuctionAuditActivityEnum.AUCTION_CREATED){
      toReturn = "Auction Created";
    }else if(type == AuctionAuditActivityEnum.AUCTION_MARKED_INACTIVE){
      toReturn = "Auction Disabled";
    }else {
      toReturn = "Auction Status Changed";
    }
    return toReturn;
  }

  getSubHeading(type: string){
    let toReturn = "";
    if(type == AuctionAuditActivityEnum.AUCTION_CREATED || type == AuctionAuditActivityEnum.NEW_LOT){
      toReturn = "Created";
    }else{
      toReturn = "Updated";
    }
    return toReturn;
  }


  isAllowToShowAddedOrRemovedActivities(type: string){
    let toReturn = false;
    if(type == AuctionAuditActivityEnum.ADDRESS_ADDED || type == AuctionAuditActivityEnum.ADDRESS_REMOVED || type == AuctionAuditActivityEnum.DOCUMENT_ADDED
       || type == AuctionAuditActivityEnum.DOCUMENT_REMOVED || type == AuctionAuditActivityEnum.QUESTION_ADDED || type == AuctionAuditActivityEnum.QUESTION_REMOVED){
      toReturn = true;
    }
    return toReturn;
  }



  isAllowToShowAuctionDetailsTable(type: string){
    let toReturn = false;
    if(type == AuctionAuditActivityEnum.AUCTION_MARKED_UPCOMING){
      toReturn = true;
    }
    return toReturn;
  }

  getCreatedDate(timestamp?: string) {
    if (timestamp) {
      var stillUtc = moment.utc(timestamp).toDate();
      var localDate = moment(stillUtc).local()

      let date = localDate.toDate().toDateString();
      let time = localDate.toDate().toTimeString();
      return ApplicationUtils.getDisplayDateAdmin(date, time);
    }
    return '';
  }

  getClass(type: string){
    let  toReturn = "badge-info-class";
    if(type == AuctionAuditActivityEnum.AUCTION_MARKED_DRAFT){
      toReturn = "badge-draft-class";
    }else if(type == AuctionAuditActivityEnum.AUCTION_MARKED_UPCOMING){
      toReturn = "badge-warning-class";
    }else if(type == AuctionAuditActivityEnum.AUCTION_MARKED_LIVE){
      toReturn = "badge-primary-class";
    }else if(type == AuctionAuditActivityEnum.AUCTION_MARKED_CLOSE){
      toReturn = "badge-closed-class";
    }else if(type == AuctionAuditActivityEnum.AUCTION_MARKED_INACTIVE){
      toReturn = "badge-closed-class";
    }
    return toReturn;
  }

  getChangeTypeValue(type: string){
    let  toReturn = "";
    if(type == AuctionAuditActivityEnum.AUCTION_CREATED){
      toReturn = "AUCTION CREATED";
    }else if(type == AuctionAuditActivityEnum.AUCTION_MARKED_DRAFT){
      toReturn = "DRAFT";
    }else if(type == AuctionAuditActivityEnum.AUCTION_MARKED_UPCOMING){
      toReturn = "UPCOMING";
    }else if(type == AuctionAuditActivityEnum.AUCTION_MARKED_LIVE){
      toReturn = "LIVE";
    }else if(type == AuctionAuditActivityEnum.AUCTION_MARKED_CLOSE){
      toReturn = "CLOSE";
    }else if(type == AuctionAuditActivityEnum.AUCTION_MARKED_INACTIVE){
      toReturn = "DISABLED";
    }
    return toReturn;
  }

  getAuctionLotDetailsMsg(type: string){
    let toReturn = "";
    if(type == AuctionAuditActivityEnum.NEW_LOT){
      toReturn = "New Lot Created";
    }else if(type == AuctionAuditActivityEnum.AUCTION_LOT_MARKED_ACTIVE){
      toReturn = "Lot Marked Active";
    }
    else if(type == AuctionAuditActivityEnum.AUCTION_LOT_MARKED_INACTIVE){
      toReturn = "Lot Marked InActive";
    }
    return toReturn;
  }

  isShowAuctionDetails(type: string){
    let  toReturn = false;

    if(type == AuctionAuditActivityEnum.AUCTION_CREATED || type == AuctionAuditActivityEnum.AUCTION_MARKED_LIVE || type == AuctionAuditActivityEnum.AUCTION_MARKED_UPCOMING || type == AuctionAuditActivityEnum.AUCTION_MARKED_CLOSE
      || type == AuctionAuditActivityEnum.AUCTION_MARKED_DRAFT || type == AuctionAuditActivityEnum.AUCTION_MARKED_INACTIVE){
      toReturn = true;
    }

    return toReturn;
  }

  isAllowToShowAuctionAndLotSetting(type: string){
    let  toReturn = false;

    if(type == AuctionAuditActivityEnum.AUCTION_CONFIG_CHANGE || type == AuctionAuditActivityEnum.AUCTION_LOT_CONFIG_CHANGE){
      toReturn = true;
    }

    return toReturn;
  }

  isShowAuctionLotDetails(type: string){
    let  toReturn = false;

    if(type == AuctionAuditActivityEnum.NEW_LOT || type == AuctionAuditActivityEnum.AUCTION_LOT_MARKED_ACTIVE || type == AuctionAuditActivityEnum.AUCTION_LOT_MARKED_INACTIVE){
      toReturn = true;
    }

    return toReturn;
  }

  getAuctionAndLotConfigMsg(type: string){
    if(type == AuctionAuditActivityEnum.AUCTION_CONFIG_CHANGE){
      return "Auction Settings Changed";
    }else{
      return "Lot Setting Changed";
    }
  }

  loadAuctionActivity() {
    this.isLoading = true
    this.auctionService.getAuctionActivity(this.selectedAuction.eventId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.activityList = apiResponseDto.data as AuctionAuditActivityDto[];
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      }
    })
  }


  closeModal() {
    this.ngbModal.dismissAll();
  }

  generatePdf() {
    this.isShowPdfView = true;
    this.changeDetectRef.detectChanges();
    this.isLoading = true;

    const element = document.getElementById('pdfContent');

    let auctionHouseDto = this.adminService.getCurrentAuctionHouse$.value;
    const secondaryColor = auctionHouseDto?.secondaryColor ?? '#1b5887';

    if (element) {
      let opt = {
        // margin: [25, 12, 30, 12],
        filename: 'Timeline_'+ this.selectedAuction?.sequenceNo+'.pdf',
        html2canvas: { scale: 6 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };

      html2pdf().from(element).set(opt).set({margin: [25, 12, 25, 12]}).toPdf().get('pdf').then((pdf: any) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          pdf.setDrawColor(secondaryColor);
          pdf.setLineWidth(1);
          pdf.line(20, pdf.internal.pageSize.getHeight() - 25, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 25);

          pdf.setFontSize(9);
          pdf.setTextColor('#000000');

          let text = `Page ${i}`;
          let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          let textOffset = (pdf.internal.pageSize.getWidth() - textWidth) / 2;

          pdf.text(text, textOffset, pdf.internal.pageSize.getHeight() - 10);
        }

        this.isLoading = false;
        this.isShowPdfView = false;
        this.changeDetectRef.detectChanges();
      }).save();
    }
  }
}
