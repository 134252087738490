export enum GraphCharEnum {
  BIDS_PLACED_IN_LAST_5_MINTS = "BIDS_PLACED_IN_LAST_5_MINTS",
  BIDS_PLACED_IN_LAST_10_MINTS = "BIDS_PLACED_IN_LAST_10_MINTS",
  BIDS_PLACED_IN_LAST_15_MINTS = "BIDS_PLACED_IN_LAST_15_MINTS",
  BIDS_PLACED_IN_LAST_30_MINTS = "BIDS_PLACED_IN_LAST_30_MINTS",
  BIDS_PLACED_IN_LAST_1_HOURS = "BIDS_PLACED_IN_LAST_1_HOURS",
  BIDS_PLACED_IN_LAST_24_HOURS = "BIDS_PLACED_IN_LAST_24_HOURS",
  BIDS_PLACED_IN_LAST_WEEK = "BIDS_PLACED_IN_LAST_WEEK",
  BIDS_PLACED_IN_LAST_MONTH = "BIDS_PLACED_IN_LAST_MONTH",
  DATE_RANGE = "DATE_RANGE",
  ALL_BIDS = "ALL_BIDS",
  HOT_BIDS = "HOT_BIDS",
  COMPARE_BIDS_FREQUENCY = "COMPARE_BIDS_FREQUENCY",
  REGISTRATION_FREQUENCY = "REGISTRATION_FREQUENCY",
  INVOICE_SETTLEMENT = "INVOICE_SETTLEMENT"
}