<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>BIDDER Details</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
  <div class="model-body-top">
    <div class="m-3" *ngIf="bidderDetailsInfoDto">
      <form class="needs-validation" novalidate>
        <div class="row">
          <div class="col-5 mb-1">
            <b>Email</b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.emailId}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
          <b>First Name</b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.firstName ?? '-'}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
            <b>
              Last Name
            </b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.lastName ?? '-'}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
            <b>
              Phone
            </b>
          </div>
          <div class="col-7 mb-1 div-class">{{getCountryCode(bidderDetailsInfoDto.countryCode)}} {{bidderDetailsInfoDto.mobileNo ?? '-'}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
            <b>
              Company Name
            </b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.officeName ?? '-'}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
            <b>
              Address Line 1
            </b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.addressLine1 ?? '-'}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
            <b>
              Address Line 2
            </b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.addressLine2 ?? '-'}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
            <b>
              City
            </b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.city ?? '-'}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
            <b>
              State
            </b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.state ?? '-'}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
            <b>
              Country
            </b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.country ?? '-'}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
            <b>
              Zip
            </b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.zipCode ?? '-'}}</div>
          <hr class="hr my-1" />
          <div class="col-5 mb-1">
            <b>
              Paddle #
            </b>
          </div>
          <div class="col-7 mb-1 div-class">{{bidderDetailsInfoDto.paddleNo ?? '-'}}</div>
          <hr class="hr my-1" />
          <!-- Last Bid Amount -->
          <div class="col-5 mb-1">
            <b>
              Last Bid Amount
            </b>
          </div>
          <div class="col-7 mb-1 div-class" *ngIf="bidderDetailsInfoDto?.lastBidAmount">
            {{userAuctionBidHistoryDto?.currency!.symbol}}{{getFormattedPrice(bidderDetailsInfoDto.lastBidAmount!)}}
            {{getDisplayDate(bidderDetailsInfoDto.bidDate) ?? '-'}} {{bidderDetailsInfoDto.bidTime ?? '-'}}
          </div>
          <div class="col-7 mb-1 div-class" *ngIf="!bidderDetailsInfoDto?.lastBidAmount">
            -
          </div>
          <hr class="hr my-1"  *ngIf="!isReverseAuction()" />

          <!-- Last Proxy Bid Amount -->
          <div class="col-5 mb-1" *ngIf="!isReverseAuction()">
          <b>
            Last Proxy Bid Amount
          </b>
        </div>
          <div class="col-7 mb-1 div-class" *ngIf="!isReverseAuction()">
            <span *ngIf="bidderDetailsInfoDto?.lastMaxBidAmount">
              {{userAuctionBidHistoryDto?.currency!.symbol}}{{getFormattedPrice(bidderDetailsInfoDto.lastMaxBidAmount!)}}
              {{getDisplayDate(bidderDetailsInfoDto.maxBidDate) ?? '-'}} {{bidderDetailsInfoDto.maxBidTime ?? '-'}}
            </span>
            <span *ngIf="!bidderDetailsInfoDto?.lastMaxBidAmount">-</span>

            <span class="ms-4">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mt-2" (click)="openMaxBidModal(maxBidEdit)"
                [disabled]="!(getRemainingTime() > 0)">EDIT</button>
            </span>
          </div>
        </div>
      </form>
    </div>
    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner" *ngIf="isLoading"></div>
    </div>
  </div>
</div>

<ng-template #maxBidEdit>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>PROXY BID</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeMaxModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <form class="needs-validation" autocomplete="off" (keyup.enter)="doMaxBid()">
      <div class="m-3">
        <!-- <div class="my-3">
          <div class="input-group">
            <span class="border border-end-0 pt-3 px-1 fa-lg">
              {{userAuctionBidHistoryDto?.currency?.symbol}}
            </span>
          </div>
          <div class=" form-floating mb-3 w-75 m-auto">

            <input type="text" class="form-control" placeholder="add value" (keyup)="formatCurrentMaxBid()"
              [formControl]="userInputMaxBid">
            <label for="floatingInput">Proxy Bid</label>
          </div>
        </div> -->
        <div class="input-group ">
          <span class="border border-end-0 pt-3 px-1 fa-lg">
            {{userAuctionBidHistoryDto?.currency?.symbol}}
          </span>
        <div class="form-floating">
          <input name="bucketSize" type="text" (keyup)="formatCurrentMaxBid()"
            [formControl]="userInputMaxBid" class="form-control border-start-0" id="userInputMaxBid" />
          <label  for="userInputMaxBid">Proxy Bid</label>
        </div>
      </div>

        <div class="e-spinner" *ngIf="isLoading$ | async"></div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="responseMessage"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="responseMessage"></app-message-toast>
          <app-message-toast *ngIf="_showErrorButtonToast$ | async" [isErrorButton]="true"
            (output)="updateMaxBidPrice()" [message]="responseMessage"
            [messageButton]="userAuctionBidHistoryDto?.currency?.symbol! + responseValue">
          </app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!(isLoading$ | async) && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="doMaxBid()"
            [disabled]="_disableBidButton$ |async">SAVE</button>
        </div>
      </div>
    </form>
   </div>
  </div>
</ng-template>
