import { DashboardRedirectionService } from '../../../shared/services/dashboard-redirection.service';
import { LandingService } from '../../../shared/services/LandingAndBidder.service';
import { Router } from '@angular/router';
import { AuctionStatus } from './../../../shared/models/user/AuctionStatus';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormControl,
} from '@angular/forms';
import {
  NgbDropdown,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';

import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { UserService } from 'src/app/shared/services/user.service';
import { Timestamp } from '@angular/fire/firestore';
import { AuctionSorting } from 'src/app/shared/enums/AuctionSorting';
import { ServerDataFetchService } from 'src/app/shared/services/server-data-fetch.service';
import * as moment from 'moment-timezone';
import { AuctionTimelineComponent } from '../auction-timeline/auction-timeline.component';
import { SourcingEventDto } from 'src/app/shared/models/user/SourcingEventDto';
import { EventEnum } from 'src/app/shared/enums/EventEnum';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { GenerateLotReportComponent } from 'src/app/shared/components/generate-lot-report/generate-lot-report.component';
import { TimeZoneDto } from 'src/app/shared/models/TimeZoneDto';
import { Store } from '@ngrx/store';
import { NavigationEventsDto } from 'src/app/shared/models/NavigationEventsDto';
import { NavigationActions } from 'src/app/shared/state-management/navigation/navigation.actions';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-admin-auctions-section',
  templateUrl: './admin-auctions-section.component.html',
  styleUrls: ['./admin-auctions-section.component.sass'],
})
export class AdminAuctionsSectionComponent implements OnInit, OnDestroy, AfterViewInit {
  selectedSortBy: string = AuctionSorting.AUCTION_CREATED_DATE_DESC;
  selectedStatus: string = 'LIVE';

  isLoading: boolean = false;
  isEventsDataLoading: boolean = true;

  currentIndex: number = -1;
  isNavLoading: boolean = false;
  auctionDiscardModalRef!: NgbModalRef;
  rfxDiscardModalRef!: NgbModalRef;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');

  isShowCloseModalButton: boolean = false;

  filterModalRef?: NgbModalRef;

  selectedEvent?: SourcingEventDto;
  timezones: Array<TimeZoneDto> = [];
  tempAuctionIdsList: Array<string> = [];
  isShowEventMenu: boolean = false;

  sortItems: MenuItem[] = [];
  eventList: string[] = ['AUCTION'];
  allEventsList: SourcingEventDto[] = [];
  filteredSourceEventList$ = new BehaviorSubject<SourcingEventDto[]>([]);

  auctionTimeZoneSubscription$?: Subscription;

  gridView$ = new BehaviorSubject<boolean>(true);
  selectedEventStatusFilter: string[] = [];
  selectedEventTypeFilter: string[] = [];
  statusList = [
    { name: 'ALL', value: 'ALL' },
    { name: 'DRAFT', value: 'DRAFT' },
    { name: 'UPCOMING', value: 'LIVE_WAIT' },
    { name: 'RUNNING', value: 'LIVE' },
    { name: 'CLOSED', value: 'CLOSE' },
  ];

  @ViewChild('search') searchField?: ElementRef<HTMLInputElement>;

  isFiter: boolean = false;
  page: number = 1;
  pageSize: number = 50;

  ctrlSearchAuction: FormControl = new FormControl('');

  tempSourcingEvents: SourcingEventDto[] = [];

  constructor(
    private dataRedirectionService: DashboardRedirectionService,
    private landingService: LandingService,
    private serverDataFetchService: ServerDataFetchService,
    private router: Router,
    private ngbModal: NgbModal,
    private adminDashboardService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private userService: UserService,
    private store: Store
  ) { }

  openAuctionTimeline(eventId: string) {

    this.adminSourcingEventsDataHolderService.setSelectedEventByEventId(eventId);
    let modalRef = this.ngbModal.open(AuctionTimelineComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.selectedAuction = this.selectedEvent;
  }

  ngOnInit(): void {
    this.ctrlSearchAuction.valueChanges.subscribe(() => {
      this.handleFilterAndSortData()

    });

    this.auctionTimeZoneSubscription$ = this.adminDashboardService.getMasterTimezones$.subscribe((data) => {
      if (data) {
        this.timezones = data;
      }
    });

    this.adminSourcingEventsDataHolderService.getAllEventsList$.subscribe((data) => {
      if (data) {
        this.allEventsList = data;
        this.handleFilterAndSortData()
      } else {
        this.allEventsList = [];
      }
    });

    this.adminSourcingEventsDataHolderService.getAllEventsDataLoaded$.subscribe((data) => {
      if (data) {
        this.isEventsDataLoading = false;
      }
    });
  }

  getSortByDropdownTitle(sortType: string) {
    if (sortType == AuctionSorting.AUCTION_END_DATE_DESC) {
      return 'Auction End Date - Decreasing';
    } else if (sortType == AuctionSorting.AUCTION_SEQUENCE_NO_ASC) {
      return 'Auction Sequence No - Increasing';
    } else if (sortType == AuctionSorting.AUCTION_CREATED_DATE_DESC) {
      return 'Auction Created Date- Decreasing';
    }
    return '';
  }

  // openFilterModal(content: any) {
  //   this.filterModalRef = this.ngbModal.open(content, {
  //     size: 'lg',
  //     backdrop: 'static',
  //     keyboard: false,
  //     centered: true,
  //   });
  // }

  selectedEventData(selectedEvent: SourcingEventDto, event: HTMLInputElement) {
    if (this.selectedEvent?.eventId == selectedEvent.eventId) {
      this.selectedEvent = undefined;
      event.checked = false;
      return;
    }

    this.selectedEvent = selectedEvent;
    if (selectedEvent.type == EventEnum.AUCTION
      && (selectedEvent.status == AuctionStatus.LIVE || selectedEvent.status == AuctionStatus.LIVE_WAIT || selectedEvent.status == AuctionStatus.CLOSE)) {
      this.isShowEventMenu = true;
    } else {
      this.isShowEventMenu = false;
    }
  }

  openReportGenerateModal() {
    let modalRef = this.ngbModal.open(GenerateLotReportComponent, {
      size: 'xl', keyboard: false, backdrop: 'static', centered: true
    });
    modalRef.componentInstance.auctionId = this.selectedEvent?.eventId;
  }

  isAllowToDiscard() {
    let toReturn = true;

    if (this.selectedEvent?.status == AuctionStatus.LIVE_WAIT && !this.selectedEvent?.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.selectedEvent?.status == AuctionStatus.LIVE && !this.selectedEvent?.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }

  openAuctionDiscardModal(content: any) {
    this.isLoading = false;
    this._errorMsg$.next('');
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    if (!this.isAllowToDiscard()) {
      return;
    }

    this.auctionDiscardModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openRfxDiscardModal(content: any) {
    this.isLoading = false;
    this._errorMsg$.next('');
    this._showErrorToast$.next(false);
    this._showSuccessToast$.next(false);

    if (!this.isAllowToDiscard()) {
      return;
    }

    this.rfxDiscardModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  navigateToHomePage() {
    this.router.navigate(['Admin/Dashboard'], { skipLocationChange: true })
  }


  discardAuction() {
    if (!this.isAllowToDiscard()) {
      return;
    }

    this._errorMsg$.next('');
    this.isLoading = true;
    this._showErrorToast$.next(false);
    this.isShowCloseModalButton = false;

    this.adminDashboardService.auctionDiscard(this.selectedEvent?.eventId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          this.selectedEvent = undefined;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.auctionDiscardModalRef);
          }, 2000)

        } else {
          if (apiResponseDto.code == 'AUCTION-ERROR-115') {
            this.isShowCloseModalButton = true;
          }
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while discarding Auction. Try again.");
      }
    })
  }

  discardRfx() {
    if (!this.isAllowToDiscard()) {
      return;
    }

    this._errorMsg$.next('');
    this.isLoading = true;
    this._showErrorToast$.next(false);
    this.isShowCloseModalButton = false;

    this.adminDashboardService.rfxDiscard(this.selectedEvent?.eventId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          this.selectedEvent = undefined;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.auctionDiscardModalRef);
          }, 2000)

        } else {
          if (apiResponseDto.code == 'AUCTION-ERROR-115') {
            this.isShowCloseModalButton = true;
          }
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while discarding Auction. Try again.");
      }
    })
  }

  async navigateToRegistrationDashboard(eventId: string) {
    await this.serverDataFetchService.loadAuctionForAdminSync(eventId);
    let auction = this.adminSourcingEventsDataHolderService.getSelectedAuction();
    await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(auction!.auctionId!);

    // this.dataRedirectionService.storeNavigationContext(
    //   ApplicationConstants.ADMIN,
    //   ApplicationConstants.AUCTION_CREATOR_PAGE,
    //   ApplicationConstants.REGISTRATION_TAB,
    //   eventId.toString(),
    //   undefined
    // );

    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.ADMIN;
    navigation.pageName = ApplicationConstants.AUCTION_CREATOR_PAGE;
    navigation.tabName = ApplicationConstants.REGISTRATION_TAB;
    navigation.resourceNo = eventId.toString();

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));

    this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['/creator'], { skipLocationChange: true }));
  }

  async navigateToBidingDashboard(eventId: string) {
    await this.serverDataFetchService.loadAuctionForAdminSync(eventId);
    let auction = this.adminSourcingEventsDataHolderService.getSelectedAuction();
    await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(auction!.auctionId!);

    // this.dataRedirectionService.storeNavigationContext(
    //   ApplicationConstants.ADMIN,
    //   ApplicationConstants.AUCTION_CREATOR_PAGE,
    //   ApplicationConstants.BIDDING_TAB,
    //   eventId.toString(),
    //   undefined
    // );

    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.ADMIN;
    navigation.pageName = ApplicationConstants.AUCTION_CREATOR_PAGE;
    navigation.tabName = ApplicationConstants.BIDDING_TAB;
    navigation.resourceNo = eventId.toString();

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));

    this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['/creator'], { skipLocationChange: true }));
  }

  async navigateToQuestionDashboard(eventId: string) {
    await this.serverDataFetchService.loadAuctionForAdminSync(eventId);
    let auction = this.adminSourcingEventsDataHolderService.getSelectedAuction();
    await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(auction!.auctionId!);

    // this.dataRedirectionService.storeNavigationContext(
    //   ApplicationConstants.ADMIN,
    //   ApplicationConstants.AUCTION_CREATOR_PAGE,
    //   ApplicationConstants.QUESTIONS_TAB,
    //   eventId.toString(),
    //   undefined
    // );

    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.ADMIN;
    navigation.pageName = ApplicationConstants.AUCTION_CREATOR_PAGE;
    navigation.tabName = ApplicationConstants.QUESTIONS_TAB;
    navigation.resourceNo = eventId.toString();

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));

    this.router.navigateByUrl('/redirect-loader', { skipLocationChange: true }).then(() => this.router.navigate(['/creator'], { skipLocationChange: true }));
  }

  isShowButton(eventType: EventEnum) {
    if (eventType == EventEnum.AUCTION) {
      return true;
    }
    return false;
  }

  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getSortDisplayDateAdmin(date, time);
  }

  getCreatedDate(timestamp?: string) {
    if (timestamp) {
      var stillUtc = moment.utc(timestamp).toDate();
      var localDate = moment(stillUtc).local()

      let date = localDate.toDate().toDateString();
      let time = localDate.toDate().toTimeString();
      return ApplicationUtils.getSortDisplayDateAdmin(date, time);
    }
    return '';
  }

  getMetadataDate(timestamp?: Timestamp | null) {
    if (timestamp) {
      let createdDate = timestamp.toDate();
      let date = createdDate.toDateString();
      let time = createdDate.toTimeString();
      return ApplicationUtils.getSortDisplayDateAdmin(date, time);
    }
    return '';
  }

  getTimeZone(dbTimeZone: string) {
    if (dbTimeZone && this.timezones.length > 0) {
      let utcCode = this.timezones.find((item) => item.locale == dbTimeZone)?.utcCode;
      return utcCode?.replaceAll('UTC', '');
    }
    return ''
  }


  openFilterDialog() {
    this.isFiter = true;
  }

  getTopBorderClass(status: string) {
    if (status == AuctionStatus.DRAFT) {
      return 'top-border-secondary';
    } else if (status == AuctionStatus.LIVE_WAIT) {
      return 'top-border-warning';
    } else if (status == AuctionStatus.LIVE) {
      return 'top-border-primary';
    } else if (status == AuctionStatus.CLOSE) {
      return 'top-border-danger';
    }
    return '';
  }

  getEventStatus(status: string) {
    return this.statusList.find(item => item.value == status)?.name;
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.ngbModal.dismissAll();
    }
  }

  ngAfterViewInit(): void {
    this.sortItems = [
      {
        label: 'View',
      },
      {
        label: 'item?.active ? "InActive" : "Active"',
        // command: () => {
        //   this.openConfirmationModal(item);
        // }

      },
      {
        label: 'ExtendRole',
        // command: () => {
        //   this.openExtendProfileDrawer($event, item);
        // }

      }
   
    ]
  }

  ngOnDestroy(): void {
    if (this.auctionTimeZoneSubscription$) {
      this.auctionTimeZoneSubscription$.unsubscribe();
    }

    this.currentIndex = -1;
  }

  //   filterAuctionList() {
  //     this.searchField!.nativeElement.value = '';
  //     if(this.ctrlRunning.value == false && this.ctrlUpcoming.value == false && this.ctrlClosed.value == false && this.ctrlDraft.value == false) {
  //       this.ctrlRunning.patchValue(true);
  //       return;
  //     }
  //
  //     this.auctionService.filterMultipleAuctionData(this.ctrlRunning.value, this.ctrlUpcoming.value, this.ctrlClosed.value, this.ctrlDraft.value);
  //   }

  openAuctionCardMenu(event: any, dropdown: NgbDropdown) {
    event.stopPropagation();
    dropdown.isOpen() ? dropdown.open() : dropdown.close();
  }

  // // action => START, STOP, RESUME
  // openAuctionStreaming(auction: AuctionEntityDto, content: any) {
  //   this.streamingService.setSelectedStreamingAuction(auction);

  //   this.streamConfirmModalRef = this.ngbModal.open(content, {
  //     size: 'md',
  //     backdrop: 'static',
  //     keyboard: false,
  //     centered: true,
  //   });
  // }


  // async resumeAuctionStreaming(
  //   auction: AuctionEntityDto,
  //   streamingMetadataTemplate: TemplateRef<any>
  // ) {
  //   let userEntityDto = this.userService.getUserEntity();
  //   let documentSnapshot = await this.firestoreListenerService.getStreamingMetadata(auction.auctionId!);

  //   if (documentSnapshot.exists()) {
  //     let streamingMetadata = documentSnapshot.data() as StreamingMetadata;
  //     if (userEntityDto.primaryEmailId != streamingMetadata.emailId) {
  //       this.streamingMetadata = streamingMetadata;
  //       this.openStreamingMetadataModal(streamingMetadataTemplate);
  //     } else {
  //       this.streamingService.setSelectedStreamingAuction(auction);
  //       this.streamingService.setShowStreamViewModal(true);
  //     }
  //   } else {
  //     this.streamingService.setSelectedStreamingAuction(auction);
  //     this.streamingService.setShowStreamViewModal(true);
  //   }
  // }

  // openStreamingMetadataModal(modalTemplate: TemplateRef<any>) {
  //   this.ngbModal.open(modalTemplate, {
  //     size: 'md',
  //     backdrop: 'static',
  //     keyboard: false,
  //     centered: true,
  //   });
  // }

  async navigateToGraphGeneratePage(eventId: string, eventType: string) {
    if (eventType == EventEnum.AUCTION) {
      await this.serverDataFetchService.loadAuctionForAdminSync(eventId);
      await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(eventId);

      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.AUCTION_GRAPH_PAGE
      // );
      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.AUCTION_GRAPH_PAGE,
      // );
      // this.dataRedirectionService.setUpdateBreadcrumbs(true);

      const navigation = new NavigationEventsDto();
      navigation.contextName = ApplicationConstants.ADMIN;
      navigation.pageName = ApplicationConstants.AUCTION_GRAPH_PAGE;

      this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
    }
  }

  async navigateToAnalyticsDashboardPage(eventId: string, eventType: string) {
    if (eventType == EventEnum.AUCTION) {
      await this.serverDataFetchService.loadAuctionForAdminSync(eventId);
      await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(eventId);

      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.AUCTION_ANALYTICS_DASHBOARD_PAGE
      // );

      // this.dataRedirectionService.setUpdateBreadcrumbs(true);

      const navigation = new NavigationEventsDto();
      navigation.contextName = ApplicationConstants.ADMIN;
      navigation.pageName = ApplicationConstants.AUCTION_ANALYTICS_DASHBOARD_PAGE;

      this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
    }
  }

  isShowAuctionDashboardMenu(noOfActiveLots: number) {
    if (noOfActiveLots! > 1) {
      return true;
    }
    return false;
  }

  // filterMultipleAuctionData(searchValue?: string) {
  //   this.filteredAuctionsList$.next([]);
  //   this.isAuctionDataLoading = true;

  //   setTimeout(() => {
  //     let tempAuctionList: AuctionEntityDto[] = [];
  //     if (this.selectedStatus == AuctionStatus.LIVE) {
  //       tempAuctionList = this.runningAuctionsList;
  //     } else if (this.selectedStatus == AuctionStatus.LIVE_WAIT) {
  //       tempAuctionList = this.upcomingAuctionsList;
  //     } else if (this.selectedStatus == AuctionStatus.DRAFT) {
  //       tempAuctionList = this.draftAuctionsList;
  //     } else if (this.selectedStatus == AuctionStatus.CLOSE) {
  //       tempAuctionList = this.closedAuctionsList;
  //     }
  //     this.isAuctionDataLoading = false;
  //     if (searchValue && tempAuctionList && tempAuctionList.length > 0) {
  //       this.filterBySearchText(searchValue, tempAuctionList);
  //     } else {
  //       this.filteredAuctionsList$.next(tempAuctionList);
  //     }
  //   }, 1000);
  // }

  // private filterBySearchText(searchValue: string) {
  //   let filteredAuctionsList = this.filteredAuctionsList$.value;
  //   let tempAuctionList = filteredAuctionsList.filter(
  //     (item) =>
  //       item.auctionName?.toLowerCase().includes(searchValue) ||
  //       this.getCompanyName(item.companyId)?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
  //       item.sequenceNo?.toString().includes(searchValue) ||
  //       item.auctionType?.toLowerCase().includes(searchValue) ||
  //       item.listingContactDtos?.filter((m) =>
  //         m.name?.toLowerCase().includes(searchValue)
  //       ).length! > 0 ||
  //       item.notOfLots?.toString().includes(searchValue) ||
  //       item.noOfActiveLots?.toString().includes(searchValue) ||
  //       item.addresses?.filter(
  //         (m) =>
  //           m.addressLine1?.toLowerCase().includes(searchValue) ||
  //           m.addressLine2?.toLowerCase().includes(searchValue) ||
  //           m.city?.toLowerCase().includes(searchValue) ||
  //           m.country?.toLowerCase().includes(searchValue) ||
  //           m.landmark?.toLowerCase().includes(searchValue)
  //       ).length! > 0
  //   );

  //   this.filteredAuctionsList$.next(tempAuctionList);
  // }

  sortSourcingData(sortType: string) {
    this.selectedSortBy = sortType;

    this.handleFilterAndSortData();
  }

  convertCreatedDate(createdDate: string) {
    return moment(createdDate).toDate();
  }

  selectFilterEventType(eventType: string) {
    if (this.selectedEventTypeFilter.includes(eventType)) {
      let index = this.selectedEventTypeFilter.findIndex(item => item == eventType);
      this.selectedEventTypeFilter.splice(index, 1);
    } else {
      this.selectedEventTypeFilter.push(eventType);
    }
  }

  selectFilterEventStatus(status: string) {
    if (this.selectedEventStatusFilter.includes(status)) {
      let index = this.selectedEventStatusFilter.findIndex(item => item == status);
      this.selectedEventStatusFilter.splice(index, 1);
    } else {
      this.selectedEventStatusFilter.push(status);
    }
  }

  isEventTypeFilterSelected(eventType: string) {
    return this.selectedEventTypeFilter.includes(eventType);
  }

  isEventStatusFilterSelected(eventType: string) {
    return this.selectedEventStatusFilter.includes(eventType);
  }

  // Open Event Stage Page
  async openEventStagePage(eventId: string, eventType: string, index: number) {
    this.currentIndex = index;
    this.isNavLoading = true;

    if (eventType == EventEnum.AUCTION) {
      await this.serverDataFetchService.loadAuctionForAdminSync(eventId);
      await this.serverDataFetchService.loadAllLotsOfAuctionForAdminSync(eventId);

      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.AUCTION_CREATOR_PAGE,
      //   ApplicationConstants.AUCTION_TAB
      // );
      // this.dataRedirectionService.setUpdateBreadcrumbs(true);

      const navigation = new NavigationEventsDto();
      navigation.contextName = ApplicationConstants.ADMIN;
      navigation.pageName = ApplicationConstants.AUCTION_CREATOR_PAGE;
      navigation.tabName = ApplicationConstants.AUCTION_TAB;

      this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));

    } else {
      await this.serverDataFetchService.loadRfxForAdminSync(eventId);
      await this.serverDataFetchService.loadRfxQuestionnaireWrapperDtoOfRfxIdForAdminSync(eventId);

      // this.dataRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.RFX_CREATOR_PAGE,
      //   ApplicationConstants.RFX_TAB
      // );
      // this.dataRedirectionService.setUpdateBreadcrumbs(true);

      const navigation = new NavigationEventsDto();
      navigation.contextName = ApplicationConstants.ADMIN;
      navigation.pageName = ApplicationConstants.RFX_CREATOR_PAGE;
      navigation.tabName = ApplicationConstants.RFX_TAB;

      this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
    }

    this.isNavLoading = false;
    this.currentIndex = -1;
  }

  changePageSize(size: number) {
    this.pageSize = size;
  }

  isFilterApplied() {
    return this.selectedEventStatusFilter.length > 0 || this.selectedEventTypeFilter.length > 0;
  }

  clearAllFilters() {
    this.selectedEventStatusFilter = [];
    this.selectedEventTypeFilter = [];
    this.ctrlSearchAuction.patchValue('');

    this.ctrlSearchAuction.reset();

    this.handleFilterAndSortData();
  }

  handleFilterAndSortData() {
    this.tempSourcingEvents = this.allEventsList;

    // Do Filter
    this.doFilter();

    // Do Search
    this.doSearch();

    // Sorting
    this.doSorting()

    this.doPaginate();

    if (this.filterModalRef) {
      this.filterModalRef.close();
    }
  }

  doFilter() {
    if (this.selectedEventTypeFilter.length > 0) {
      this.tempSourcingEvents = this.tempSourcingEvents.filter(item => this.selectedEventTypeFilter.includes(item.type!));
    }
    if (this.selectedEventStatusFilter.length > 0) {
      this.tempSourcingEvents = this.tempSourcingEvents.filter(item => this.selectedEventStatusFilter.includes(item.status!));
    }
  }

  doSearch() {
    if (this.ctrlSearchAuction.value != null && this.ctrlSearchAuction.value != '') {
      let searchValue = this.ctrlSearchAuction.value.trim().toLowerCase();

      let tempAuctionList = this.tempSourcingEvents.filter(
        (item) => item.eventName?.toLowerCase().includes(searchValue)
          // ||  this.getCompanyName(item.companyId)?.toLowerCase()?.includes(searchValue.toLowerCase())
          || item.sequenceNo?.toString().includes(searchValue)
          || item.type?.toLowerCase().includes(searchValue)
          // ||item.listingContactDtos?.filter((m) =>  m.name?.toLowerCase().includes(searchValue)).length! > 0
          || item.notOfActivity?.toString().includes(searchValue)
          || item.noOfActiveActivity?.toString().includes(searchValue)
        // item.addresses?.filter(
        //   (m) => m.addressLine1?.toLowerCase().includes(searchValue)
        //   ||m.addressLine2?.toLowerCase().includes(searchValue)
        //   ||m.city?.toLowerCase().includes(searchValue)
        //   ||m.country?.toLowerCase().includes(searchValue)
        //   ||m.landmark?.toLowerCase().includes(searchValue)
        // ).length! > 0
      );

      this.tempSourcingEvents = tempAuctionList;
    }
  }

  doSorting() {
    let sourcingEventsList: SourcingEventDto[] = [...this.tempSourcingEvents];

    if (this.selectedSortBy == AuctionSorting.AUCTION_END_DATE_DESC) {
      let closeAuction = sourcingEventsList?.filter((item) => item.type == EventEnum.AUCTION)
        .sort((a, b) => +ApplicationUtils.convertedDate(b.endDate!, b.endTime!)! - +ApplicationUtils.convertedDate(a.endDate!, a.endTime!)!);
      let remainingAuction = sourcingEventsList?.filter((item) => item.type != EventEnum.AUCTION)
        .sort((a, b) => +ApplicationUtils.convertedDate(b.endDate!, b.endTime!)! - +ApplicationUtils.convertedDate(a.endDate!, a.endTime!)!);
      let combineAuction = remainingAuction.concat(closeAuction);
      this.tempSourcingEvents = combineAuction;
    } else if (this.selectedSortBy == AuctionSorting.AUCTION_SEQUENCE_NO_ASC) {
      let auctions = sourcingEventsList?.sort((a, b) => a.sequenceNo! - b.sequenceNo!);
      this.tempSourcingEvents = auctions;
    } else if (this.selectedSortBy == AuctionSorting.AUCTION_CREATED_DATE_DESC) {
      let auctions = sourcingEventsList?.sort(
        (a, b) => Date.parse(b.createdDate!) - Date.parse(a.createdDate!)
      );
      this.tempSourcingEvents = auctions;
    }
  }

  doPaginate() {
    if (this.tempSourcingEvents && this.tempSourcingEvents.length > 0) {
      this.filteredSourceEventList$.next(this.tempSourcingEvents.map((history, i) => ({ id: i + 1, ...history })).slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      ));
    } else {
      this.filteredSourceEventList$.next([]);
    }
  }
}
