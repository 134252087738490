import { AddressDto } from "./Address";

export class CompanyAccountUiDto {
  companyCode?: string;
  orgCode?: string;
  bankTitle?: string;
  bankAccNo?: string;
  bankAccHolder?: string;
  bankAccType?: string;
  bankAccCode?: string;
  bankAccSwift?: string;
  bankAccIban?: string;
  address?: AddressDto;
}