<div class="">
  <div class="">
    <div class="row ">
      <div class="col-md-6 mb-2">
        <h5 class="heading-h5">QUESTIONS LIST</h5>
      </div>
      <div class="col-md-6 mb-2 text-end">
        <div class="row justify-content-md-end align-items-center">
          <div class="col-auto text-end mb-2 ps-3">
            <div ngbDropdown>
              <button ngbDropdownToggle class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " type="button" ngbTooltip="ALL" container="body">
                {{getStatusText(selectedFilterType)}}
                <img src="assets/icons/arrowdown.svg" class="svg-icon-class-small c-pointer">
              </button>
              <ul ngbDropdownMenu class="mt-4">
                <li (click)="updateFilterType('ALL')">
                  <a ngbDropdownItem>All</a>
                </li>
                <hr class="dropdown-divider ">
                <li (click)="updateFilterType('ACKNOWLEDGE')">
                  <a ngbDropdownItem>Accept</a>
                </li>
                <hr class="dropdown-divider ">
                <li (click)="updateFilterType('ANSWERED')">
                  <a ngbDropdownItem>Replied</a>
                </li>
                <hr class="dropdown-divider ">
                <li (click)="updateFilterType('PASS')">
                  <a ngbDropdownItem>Pass</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="text-end col-md-5">
            <div class="mb-2  input-group">
              <input placeholder="Search" type="text" class="form-control border-end-0 rounded-0 border bg-white" [formControl]="ctrlSearch" id="ctrlSearch" />
              <span class="input-group-append">
                <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button" (click)="filterQuestions(ctrlSearch.value)">
                  <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered medium-font  mb-0">
        <thead class="table-dark">
          <tr>
            <th class="text-center "><span >Lot Number</span></th>
            <th class=" "><span >Lot Name</span></th>
            <th class=" "><span >Bidder Name</span></th>
            <th class=" "><span >Question</span></th>
            <th class=" "><span >Answer</span></th>
            <th class=" "><span >Timestamp</span></th>
            <th class=" "><span >Status</span></th>
            <th class="  text-center"><span >Action</span></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr *ngFor="let item of filteredAuctionQuestionsList$ | async; index as i" (click)="openAnswerModal(answerQuestionModal, item)">
            <td class="font-weight-normal text-center">{{getLot(item?.lotId!)?.lotSequence}}</td>
            <td class="font-weight-normal" placement="top" container="body" ngbTooltip="{{getLot(item?.lotId!)?.title}}">
              {{truncateString(getLot(item?.lotId!)?.title)}}
            </td>
            <td class="font-weight-normal">{{item?.bidderName}}</td>
            <td class="font-weight-normal">{{truncateString(item?.question)}}</td>
            <td class="font-weight-normal" placement="top" container="body" ngbTooltip="{{truncateString(item?.answer)}}">{{truncateString(item?.answer)}}</td>
            <td class="font-weight-normal">{{getDisplayDate(item?.createdTimestamp!)}}</td>
            <td class="font-weight-normal">{{getStatusText(item?.status)}}</td>
            <td class="text-center">
              <div class="" (click)="$event.stopPropagation()">
                <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                  <span ngbDropdownToggle data-bs-toggle="dropdown">
                    <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class">
                  </span>
                  <ul ngbDropdownMenu class="text-center shadow">
                    <li ngbDropdownItem class="c-pointer" (click)="saveAnswer('PASS', item)">
                      <a>PASS</a>
                    </li>
                    <li>
                      <hr class="dropdown-divider ">
                    </li>
                    <li ngbDropdownItem class="c-pointer" (click)="saveAnswer('ACKNOWLEDGE', item)">
                      <a>ACCEPT</a>
                    </li>
                    <li>
                      <hr class="dropdown-divider " >
                    </li>
                    <li ngbDropdownItem class="c-pointer" (click)="openAnswerModal(answerQuestionModal, item)" *ngIf="item?.status != 'ANSWERED'">
                      <a><span>REPLY</span></a>
                    </li>
                    <li ngbDropdownItem class="c-pointer" (click)="openAnswerModal(answerQuestionModal, item)" *ngIf="item?.status == 'ANSWERED'">
                      <a><span>EDIT</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
          <tr *ngIf="!isLoading && !((filteredAuctionQuestionsList$ | async) && (filteredAuctionQuestionsList$ | async)!.length > 0)">
              <td colspan="8" class="text-center">
                <h3 class="heading-new text-dark">No Questions Available</h3>
            </td>

          </tr>
        </tbody>
      </table>
    </div>

  </div>
</div>


<ng-template #answerQuestionModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Answer Question</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()" />
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <form class="needs-validation" novalidate>
        <div class="my-3 div-class">
          {{selectedAuctionQuestion?.question}}
        </div>
        
        <div class="form-floating mb-3">

          <input type="text" class="form-control" id="ctrlAnswerQuestion" [formControl]="ctrlAnswerQuestion"
            [ngClass]="{ 'is-invalid': ctrlAnswerQuestion.invalid && (ctrlAnswerQuestion.dirty || ctrlAnswerQuestion.touched) }" />
            <!-- <textarea type="text" class="form-control" rows="3" formControlName="ctrlAnswerQuestion"
            [ngClass]="{ 'is-invalid': ctrlAnswerQuestion.invalid && (ctrlAnswerQuestion.dirty || ctrlAnswerQuestion.touched) }">


            </textarea> -->
            <label for="ctrlAnswerQuestion">Answer</label>
          <div *ngIf="ctrlAnswerQuestion.invalid && (ctrlAnswerQuestion.dirty || ctrlAnswerQuestion.touched)">
            <p class="text-red-500 " *ngIf="ctrlAnswerQuestion.errors?.['required']">
              Required
            </p>
          </div>
        </div>

        <div class="mb-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async"
            [message]="'Answer submitted successfully'"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="'Error while submitting answer!'"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="handleValidSubmit()">
            SAVE
          </button>
        </div>
      </form>
    </div>
   </div>
  </div>
</ng-template>
