import { Timestamp } from "@angular/fire/firestore";
import { RfxStatus } from "../../enums/rfx/RfxStatus";
import { RfxTypeEnum } from "../../enums/rfx/RfxTypeEnum";
import { Currency } from "../Currency";
import { FileInfoDto } from "../FileInfoDto";

export class RfxUiDto {
    orgCode?: string;
    companyCode?: string;
    projectCode?: string;
    plantCode?: string;
    costCenterCode?: string;

    sequenceNo?: number;
    rfxId?: string;
    rfxType?: RfxTypeEnum;
    rfxTitle?: string;
    description?: string;
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
    timeZone?: string;
    status?: RfxStatus;

    rfxDocs?: FileInfoDto[];

    // Currency
    currency?: Currency;

    active?: boolean;
    noOfSubcategories?: number;
    noOfActiveSubcategories?: number;
    ownerId?: string;
    createdBy?: string;
    createdDate?: string;
    enableBlockchain?: boolean;
    updateTimeUtc?: string | Timestamp;

    allowEditWhileUpcoming?: boolean;
    allowEditWhileRunning?: boolean;

    estSpend?: string;
    estSaving?: string;
    estimatedDeliveryDate?: string;
    leadTime?: number;

    version?: number;
}

