import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { AuctionWrapperReportDto } from '../../models/reports/AuctionWrapperReportDto';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { Currency } from '../../models/Currency';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { LotWrapperReportDto } from '../../models/reports/LotWrapperReportDto';

@Component({
  selector: 'app-generate-lot-report-pdf',
  templateUrl: './generate-lot-report-pdf.component.html',
  styleUrls: ['./generate-lot-report-pdf.component.sass']
})
export class GenerateLotReportPdfComponent implements OnInit, AfterViewInit {
  @Input() auctionReportWrapper?: AuctionWrapperReportDto
  @Output() pdfExport = new EventEmitter<void>()
  
  chartOption: any = {};
  readCount: number = 0;

  @ViewChild('eCharts') eCharts?: ElementRef<HTMLDivElement>;

  constructor(
    private changeDetectRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.auctionReportWrapper?.lotWrapperReportDtoList?.sort((a, b) => Number(a.lotIndex) - Number(b.lotIndex));
    this.auctionReportWrapper?.lotWrapperReportDtoList?.forEach(element => {
      this.loadChartData(element);
    });
  }

  getFormattedPrice(currency: Currency, price: any) {
    return currency.symbol + ApplicationUtils.getFormattedPrice(currency.locale, price);
  }

  getReductionOrIncreaseMsg(auctionMethodType: string) {
    if (auctionMethodType == 'REVERSE_AUCTION') {
      return 'Reduction';
    } else { return 'Increase'; }
  }

  loadChartData(lotWrapperReportDto: LotWrapperReportDto) {
    let biddingData = lotWrapperReportDto.biddingWrapperDtoList ?? []
    biddingData.sort((a, b) => new Date(a.bidDateAndTime || '').getTime() - new Date(b.bidDateAndTime || '').getTime());

    // Group data by company Name
    const groupedData = biddingData.reduce((acc, entry) => {
      const companyName = entry.companyName || '';
      if (!acc[companyName]) {
        acc[companyName] = { bidDateAndTime: [], bidValue: [] };
      }
      acc[companyName].bidDateAndTime.push(entry.bidDateAndTime || '');
      acc[companyName].bidValue.push(entry.bidValue || 0);
      return acc;
    }, {} as { [companyName: string]: { bidDateAndTime: string[]; bidValue: number[] } });

    const companyNames = Object.keys(groupedData);

    // Create series dynamically based on paddleNo
    const series = companyNames.map((companyName) => ({
      name: `${companyName}`,
      type: 'line',
      data: groupedData[companyName].bidValue.map((value, index) => [groupedData[companyName].bidDateAndTime[index], value]),
    }));

    this.chartOption[lotWrapperReportDto.lotIndex!] = {
      legend: {},
      backgroundColor: '#fff',
      xAxis: {
        type: 'category',
        data: biddingData.map(item => item.bidDateAndTime),
      },
      yAxis: {
        type: 'value',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
      },
      series: series,
    }

    this.changeDetectRef.detectChanges();

    this.readCount++;

    if (this.auctionReportWrapper?.lotWrapperReportDtoList?.length == this.readCount) {
      setTimeout(() => {
        this.pdfExport.emit();
      }, 3000);
    }
  }
}
