import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AngularEditorComponent, AngularEditorConfig } from 'src/assets/plugins/angular-editor';
import { PrivacyAndTermsOfUseDto } from 'src/app/shared/models/PrivacyAndTermsOfUseDto';
import { UUID } from 'angular2-uuid';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';

@Component({
  selector: 'app-add-legal-policy',
  templateUrl: './add-legal-policy.component.html',
  styleUrls: ['./add-legal-policy.component.sass']
})
export class AddLegalPolicyComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() editMode: boolean = false;
  @Input() uniqueId?: string;

  @ViewChild("privacyEditor") privacyEditor?: AngularEditorComponent;
  @ViewChild("termsOfUseEditor") termsOfUseEditor?: AngularEditorComponent;

  _validationErrorPresent$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  formGroup: FormGroup;
  organizationUiDto?: OrganizationWrapperUiDto;

  errorMsg: string = '';
  isLoading: boolean = false;
  currentModal?: string;
  currentAuctionHouseSubscription$?: Subscription;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '3rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '2',
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode'
      ]
    ],
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Page Divider',
        class: 'break',
        tag: 'div',
      },
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'hr',
      },
    ],
  };

  constructor(
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private onboardingService: OnboardingService,
  ) {
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      privacyPolicy: ['', Validators.required],
      termsOfUse: ['', Validators.required],
      footerPolicy: [false]
    })
  }

  ngOnInit(): void {
    this.currentAuctionHouseSubscription$ = this.onboardingService.getOrganizationUiDto$.subscribe(data => {
      if (data) {
        this.organizationUiDto = data;

        if (this.editMode) {
          let privacyAndTermsOfUseDto = this.organizationUiDto?.privacyAndTermsOfUseList?.find(item => item.uniqueId == this.uniqueId);
          if (privacyAndTermsOfUseDto) {
            this.formGroup.controls['name'].patchValue(privacyAndTermsOfUseDto.name);
            this.formGroup.controls['privacyPolicy'].patchValue(privacyAndTermsOfUseDto.privacyPolicy);
            this.formGroup.controls['termsOfUse'].patchValue(privacyAndTermsOfUseDto.termsOfUse);
            this.formGroup.controls['footerPolicy'].patchValue(privacyAndTermsOfUseDto.footerPolicy);
            this.formGroup.updateValueAndValidity();
          }
        }
      }
    })
  }

  ngAfterViewInit(): void {
    ApplicationUtils.clearTextFormatOnPaste('privacyEditor')
    ApplicationUtils.clearTextFormatOnPaste('termsOfUseEditor')
  }

  get fc(): any { return this.formGroup.controls; }

  closeModal() {
    this.ngbModal.dismissAll();
    this._validationErrorPresent$.next(false);
    this.currentModal = undefined;
  }

  formValidationFailed() {
    this._validationErrorPresent$.next(false);
    if (this.formGroup.invalid) {
      this._validationErrorPresent$.next(true);
      return true;
    }
    return false;
  }

  mergeOrganizationUiDto(formValue: any) {
    if (!this.organizationUiDto?.privacyAndTermsOfUseList) {
      this.organizationUiDto!.privacyAndTermsOfUseList = [];
    }

    let privacyAndTermsOfUseDto = new PrivacyAndTermsOfUseDto();

    if (this.editMode) {
      let index = this.organizationUiDto?.privacyAndTermsOfUseList?.findIndex(item => item.uniqueId == this.uniqueId);
      if (index != undefined && index > -1) {
        privacyAndTermsOfUseDto = this.organizationUiDto!.privacyAndTermsOfUseList![index];
        this.organizationUiDto?.privacyAndTermsOfUseList?.splice(index, 1);
      }
    } else {
      privacyAndTermsOfUseDto.uniqueId = UUID.UUID().toString();
    }

    privacyAndTermsOfUseDto.name = formValue.name;
    privacyAndTermsOfUseDto.privacyPolicy = formValue.privacyPolicy;
    privacyAndTermsOfUseDto.termsOfUse = formValue.termsOfUse;
    privacyAndTermsOfUseDto.footerPolicy = formValue.footerPolicy;

    this.organizationUiDto?.privacyAndTermsOfUseList?.push(privacyAndTermsOfUseDto);
  }

  isFooterPolicyExists(): boolean {
    let existsFooterPolicy = this.organizationUiDto?.privacyAndTermsOfUseList?.find(item => item.footerPolicy);
    let isFooterPolicySelected = this.formGroup.controls['footerPolicy'].value;
    if (existsFooterPolicy && isFooterPolicySelected) {
      return true;
    }
    return false;
  }

  saveLegalPolicy(saveForcefully: boolean) {
    this._showErrorToast$.next(false);
    this.errorMsg = '';

    if (!saveForcefully) {
      if (this.privacyEditor?.focused || this.termsOfUseEditor?.focused) {
        return;
      }
    }

    if (this.formValidationFailed()) {
      return;
    }

    if (this.isFooterPolicyExists()) {
      this._showErrorToast$.next(true);
      this.errorMsg = 'The footer policy is already selected. Please discard the previous selection to continue.';
      return;
    }

    this.isLoading = true;
    let formValue = this.formGroup.value;

    this.mergeOrganizationUiDto(formValue);

    this.onboardingService.saveOrganizationUiDto(this.organizationUiDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let newOrganizationUiDto = apiResponseDto.data as OrganizationWrapperUiDto;
          this.onboardingService.updateOrganizationUiDto(newOrganizationUiDto)
          
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorToast$.next(true);
          this.isLoading = false;
          this.errorMsg = apiResponseDto.message ?? 'Error while saving legal policy. Try again.';
        }
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
        this.errorMsg = 'Error while saving legal policy. Try again.';
      }
    })
  }

  ngOnDestroy(): void {
    if (this.currentAuctionHouseSubscription$) {
      this.currentAuctionHouseSubscription$.unsubscribe();
    }
  }

}
