import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  isSameDay,
  isSameMonth,
  isWithinInterval,
} from 'date-fns';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { DashboardRedirectionService } from '../../services/dashboard-redirection.service';
import { AuctionStatus } from '../../models/user/AuctionStatus';
import { CalendarService } from '../../services/calendar.service';
import SwiperCore, { SwiperOptions, Navigation, Pagination, Virtual, Autoplay } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

// install Swiper modules
SwiperCore.use([Virtual, Autoplay]);

@Component({
  selector: 'app-auction-calendar',
  templateUrl: './auction-calendar.component.html',
  styleUrls: ['./auction-calendar.component.sass'],
  providers: [],
})
export class AuctionCalendarComponent implements OnInit, AfterViewInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  filterBy: string = 'ALL_AUCTION_THIS_MONTH';
  selectedAuctionList: AuctionEntityDto [] = []
  selectedAuction?: AuctionEntityDto | null = null ;
  selectedAuction$ = new BehaviorSubject<AuctionEntityDto|null>(null)
  selectedMonth?: Date;

  allAuctionsSubscription$?: Subscription;

  allAuctions: AuctionEntityDto[] = [];
  liveAuctionList$ = new BehaviorSubject<AuctionEntityDto[]>([]);
  allAuctionForMonth$ = new BehaviorSubject<number>(0);

  liveAuctionForMonth$ = new BehaviorSubject<number>(0);
  liveWaitAuctionForMonth$ = new BehaviorSubject<number>(0);
  closingInAuctionForMonth$ = new BehaviorSubject<number>(0);

  liveAuctionForSelectedDate$ = new BehaviorSubject<number>(0);
  liveWaitAuctionForSelectedDate$ = new BehaviorSubject<number>(0);
  closingInAuctionForSelectedDate$ = new BehaviorSubject<number>(0);

  filterAuctionList$ = new BehaviorSubject<AuctionEntityDto[]>([]);

  constructor(
    private calendarService: CalendarService,
    private dataRedirectionService: DashboardRedirectionService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.allAuctionsSubscription$ =
      this.calendarService.getAllAuctions$.subscribe((data) => {
        if (data && data.length > 0) {
          this.allAuctions = data;

          if(this.allAuctions && this.allAuctions.length > 0){
            this.liveAuctionList$.next(this.allAuctions.filter(item => item.status == AuctionStatus.LIVE || item.status == AuctionStatus.LIVE_WAIT));
            this.populateAuctionSummaryData();
          }
        } else {
          this.allAuctions = [];
        }
      });
  }

  isDateInSelectedMonth(startDate: Date, endDate: Date,  selectedDate: Date): boolean {
    return isSameMonth(startDate, selectedDate) ||  isSameMonth(endDate, selectedDate)
  }

  isAuctionDateValidForShow(startDate: Date, endDate: Date, selectedDate: Date): boolean {
    return isWithinInterval(selectedDate, { start: startDate, end: endDate })
  }

  onDateSelected(selectedDate : Date) {
    this.selectedAuction = null;
    this.selectedAuction$.next(this.selectedAuction);

    let temp = this.liveAuctionList$.value;
    this.selectedAuctionList = [];
    if(temp && temp.length > 0){
      let  data  = temp.filter(item => this.isAuctionDateValidForShow(ApplicationUtils.convertedDate(item.startDate!, '00:00')!,ApplicationUtils.convertedDate(item.endDate!, '23:59')!, selectedDate));
      this.selectedAuctionList = data;
    }

    if(temp && temp.length > 0){
      let  liveWait  = temp.filter(item => item.status == "LIVE_WAIT" && this.isAuctionDateValidForShow(ApplicationUtils.convertedDate(item.startDate!, '00:00')!,ApplicationUtils.convertedDate(item.endDate!, '23:59')!, selectedDate));
      if(liveWait && liveWait.length > 0){
        this.liveWaitAuctionForSelectedDate$.next(liveWait.length);
      }else{
        this.liveWaitAuctionForSelectedDate$.next(0);
      }

    }

    if(temp && temp.length > 0){
      let  live  = temp.filter(item => item.status == "LIVE" &&  this.isAuctionDateValidForShow(ApplicationUtils.convertedDate(item.startDate!, '00:00')!,ApplicationUtils.convertedDate(item.endDate!, '23:59')!, selectedDate));
      if(live && live.length > 0){
        this.liveAuctionForSelectedDate$.next(live.length);
      }else{
        this.liveAuctionForSelectedDate$.next(0);
      }
    }

    if(temp && temp.length > 0){
      let  closingIn  = temp.filter(item => (item.status == "LIVE" || item.status == "LIVE_WAIT") && isSameDay(ApplicationUtils.convertedDate(item.endDate!, '00:00')!, selectedDate));
      if(closingIn && closingIn.length > 0){
        this.closingInAuctionForSelectedDate$.next(closingIn.length);
      }else{
        this.closingInAuctionForSelectedDate$.next(0);
      }
    }

    this.changeDetectorRef.detectChanges();

    if (this.swiper && this.swiper.swiperRef) {
      this.swiper?.swiperRef.update()
    }
  }

  onMonthSelected(date: Date){
    this.dataRedirectionService.clearSessionCookies();
    this.selectedMonth = date;
    this.populateAuctionSummaryData();
  }

  populateAuctionSummaryData(){
    if(!this.selectedMonth){
      this.selectedMonth = new Date();
    }
    let temp = this.liveAuctionList$.value;

    if(temp && temp.length > 0){

      let  allData  = temp.filter(item => this.isDateInSelectedMonth(ApplicationUtils.convertedDate(item.startDate!, item.startTime!)!, ApplicationUtils.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
      if(allData){
        this.allAuctionForMonth$.next(allData.length);
      }else{
        this.allAuctionForMonth$.next(0);
      }

      let  liveWaitData  = temp.filter(item => item.status == "LIVE_WAIT" && this.isDateInSelectedMonth(ApplicationUtils.convertedDate(item.startDate!, item.startTime!)!, ApplicationUtils.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
      if(liveWaitData){
        this.liveWaitAuctionForMonth$.next(liveWaitData.length);
      }else{
        this.liveWaitAuctionForMonth$.next(0);
      }

      let  liveData  = temp.filter(item => item.status == "LIVE" && this.isDateInSelectedMonth(ApplicationUtils.convertedDate(item.startDate!, item.startTime!)!, ApplicationUtils.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
      if(liveData){
        this.liveAuctionForMonth$.next(liveData.length);
      }else{
        this.liveAuctionForMonth$.next(0);
      }

      let  closingInData  = temp.filter(item => (item.status == "LIVE" || item.status == "LIVE_WAIT") && isSameMonth(ApplicationUtils.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
      if(closingInData){
        this.closingInAuctionForMonth$.next(closingInData.length);
      }else{
        this.closingInAuctionForMonth$.next(0);
      }

      let allAuctionOfSelectMonth =  temp.filter(item => this.isDateInSelectedMonth(ApplicationUtils.convertedDate(item.startDate!, item.startTime!)!, ApplicationUtils.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
      if(allAuctionOfSelectMonth && allAuctionOfSelectMonth.length > 0){
        this.filterAuctionList$.next(allAuctionOfSelectMonth);
      }else{
        this.filterAuctionList$.next([]);
      }

    }

  }

  filterAuctionData(filterBy : string){
    this.dataRedirectionService.clearSessionCookies()

    this.filterBy = filterBy;
    let toReturn: AuctionEntityDto[]  = [];
    let temp = this.liveAuctionList$.value;


    if(temp && temp.length > 0){
        if(filterBy == "ALL_AUCTION_THIS_MONTH"){
          toReturn = temp.filter(item => this.isDateInSelectedMonth(ApplicationUtils.convertedDate(item.startDate!, item.startTime!)!, ApplicationUtils.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
        }
        else if(filterBy == "LIVE_AUCTION_THIS_MONTH"){
          toReturn = temp.filter(item => item.status == "LIVE" && this.isDateInSelectedMonth(ApplicationUtils.convertedDate(item.startDate!, item.startTime!)!, ApplicationUtils.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
        }else if(filterBy == "LIVE_WAIT_AUCTION_THIS_MONTH"){
          toReturn = temp.filter(item => item.status == "LIVE_WAIT" && this.isDateInSelectedMonth(ApplicationUtils.convertedDate(item.startDate!, item.startTime!)!, ApplicationUtils.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
        }else if(filterBy == "CLOSING_AUCTION_THIS_MONTH"){
          toReturn = temp.filter(item => (item.status == "LIVE" || item.status == "LIVE_WAIT") && isSameMonth(ApplicationUtils.convertedDate(item.endDate!, item.endTime!)!, this.selectedMonth!));
        }
    }
    this.filterAuctionList$.next(toReturn);
  }

  ngAfterViewInit(): void { }

  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getDisplayDateAdmin(date, time);
  }

  navigateToHome() {
    // this.dataRedirectionService.handleDashboardAppNavigation()
  }

  swiperConfig: SwiperOptions = {
    spaceBetween: 50,
    pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 3 },
    scrollbar: { draggable: true },
    modules: [Pagination, Navigation],
    centerInsufficientSlides: true,
    slidesPerView: 1,
    autoplay: {
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
  };


  onNavigationNext() {
    this.swiper?.swiperRef.slideNext(1000);
  }

  onNavigationPrev() {
    this.swiper?.swiperRef.slidePrev(1000);
  }

  clearData(){
    this.selectedAuction = null;
    this.selectedAuction$.next(this.selectedAuction);
    this.liveWaitAuctionForSelectedDate$.next(0);
    this.liveAuctionForSelectedDate$.next(0);
    this.closingInAuctionForSelectedDate$.next(0);
    this.selectedAuctionList = [];
  }

  onAuctionSelected(auction: AuctionEntityDto) {
    this.dataRedirectionService.clearSessionCookies()

    setTimeout(() => {
      this.selectedAuction = auction;
      this.selectedAuction$.next(auction);
      this.changeDetectorRef.detectChanges()
    }, 200);
  }

}
