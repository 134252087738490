import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationUtils } from '../util/ApplicationUtils';

@Injectable()
export class BasicAuthHttpInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let isAuthenticateRequest = request.url == 'authenticate';
    let isChatBotRequest = request.url.includes('botframework');

    if (!isChatBotRequest) {
      let auctionSessionId = localStorage.getItem('AUC_SESSION_ID');
      if (auctionSessionId && auctionSessionId.length > 7 && !isAuthenticateRequest) {
        request = request.clone({
          headers: request.headers
            .set('Authorization', "JSESSIONID=" + auctionSessionId)
            .set("Cache-Control", 'no-cache')
            .set("Cache-control", 'no-store')
            .set("Pragma", 'no-cache')
            .set("Expires", '0')
        })
      } else {
        request = request.clone({
          headers: request.headers
            .set("Cache-Control", 'no-cache')
            .set("Cache-control", 'no-store')
            .set("Pragma", 'no-cache')
            .set("Expires", '0')
        })
      }
    }

    return next.handle(request);
  }
}
