import { Injectable } from "@angular/core";
import { tap, catchError, Observable, firstValueFrom } from "rxjs";
import { ServerAPIResponseDto } from "../models/ServerAPIResponseDto";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ErrorService } from "./error.service";
import { TenderWrapperUiDto } from "../models/user/TenderWrapperUiDto";
import { select, Store } from "@ngrx/store";
import { selectOrganizationUiDto } from "../state-management/onboarding/onboarding.features";
import { ApplicationConstants } from "../util/ApplicationConstants";
import { TenderSourcingEventDto } from "../models/user/TenderSourcingEventDto";
import { TenderManagementActions } from "../state-management/tender/tender.actions";
import { UserInfoUiDto } from "../models/user/UserInfoUiDto";

@Injectable({
  providedIn: 'root'
})
export class TenderManagementService {

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private store: Store
  ) { }

  // load data
  async loadTenderSourcingEvents() {
    let organizationUiDto = await firstValueFrom(this.store.pipe(select(selectOrganizationUiDto)));
    try {
      const response: ServerAPIResponseDto = await firstValueFrom(this.getTenderSourcingEvents(organizationUiDto?.orgCode!));
      if (response.code === ApplicationConstants.SUCCESS_CODE) {
        let tenderSourcingEventDtos = response.data as TenderSourcingEventDto[];
        this.store.dispatch(TenderManagementActions.setTenderSourcingEvents({ tenderSourcingEventDtos }));
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadTenderWrapperUiDto(tenderId: string) {
    let organizationUiDto = await firstValueFrom(this.store.pipe(select(selectOrganizationUiDto)));
    try {
      const response: ServerAPIResponseDto = await firstValueFrom(this.getTenderWrapperUiDto(organizationUiDto?.orgCode!, tenderId));
      if (response.code === ApplicationConstants.SUCCESS_CODE) {
        let tenderWrapperUiDto = response.data as TenderWrapperUiDto;
        this.store.dispatch(TenderManagementActions.setCurrentTenderWrapperUiDto({ tenderWrapperUiDto }));
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadAllAdminUsers() {
    let organizationUiDto = await firstValueFrom(this.store.pipe(select(selectOrganizationUiDto)));
    try {
      const response: ServerAPIResponseDto = await firstValueFrom(this.getAllAdminUsers(organizationUiDto?.orgCode!));
      if (response.code === ApplicationConstants.SUCCESS_CODE) {
        let userInfoUiDtos = response.data as UserInfoUiDto[];
        this.store.dispatch(TenderManagementActions.saveAllAdminUsers({ allAdminUsers: userInfoUiDtos }));
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Api Calls
  private getAllAdminUsers(orgCode: string) {
    let params = new HttpParams().set('orgCode', orgCode);
    return this.http.get<ServerAPIResponseDto>('adminUserInfo', { params }).pipe(
      tap(_ => console.log("Get adminUserInfo")),
      catchError(this.errorService.handleError<any>("Error while getting adminUserInfo")));
  }

  private getTenderSourcingEvents(orgCode: string) {
    let params = new HttpParams().set('orgCode', orgCode);
    return this.http.get<ServerAPIResponseDto>('tenderSourcingEventDto', { params }).pipe(
      tap(_ => console.log("Get tenderSourcingEventDto")),
      catchError(this.errorService.handleError<any>("Error while getting tenderSourcingEventDto")));
  }

  saveTenderWrapperUiDto(tenderWrapperUiDto: TenderWrapperUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>('tenders', tenderWrapperUiDto).pipe(
      tap(_ => console.log("Get tenderWrapperUiDto")),
      catchError(this.errorService.handleError<any>("Error while getting tenderWrapperUiDto")));
  }

  getTenderWrapperUiDto(orgCode: string, tenderId: string) {
    let params = new HttpParams().set('orgCode', orgCode).set('tenderId', tenderId);
    return this.http.get<ServerAPIResponseDto>('tenders', { params }).pipe(
      tap(_ => console.log("Get tenderWrapperUiDto")),
      catchError(this.errorService.handleError<any>("Error while getting tenderWrapperUiDto")));
  }

  uploadTenderDocument(fileSave: FormData): Observable<ServerAPIResponseDto> {
    return this.http.post<any>("tenderDocuments", fileSave);
  }

  downloadTenderDocument(fileId: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers
    };
    return this.http.get<any>('downloadBlob?fileId=' + fileId, httpOptions);
  }

}