<div class="border rounded bg-white">
  <header class="p-2">
    <div class="row ">
      <div class="col-md-6 ">
        <h5 class="heading-h5" ngbAutoFocus> VENDOR DETAILS</h5>
      </div>
      <div class="col-md-6 text-md-end ">
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          (click)="openRfxTargetedBiddersModal(addPremiumSupplierModal, false)" [disabled]="!isAllowToEdit()">
         MANAGE VENDORS
        </button>
      </div>
    </div>
  </header>
  <hr class="hr" />
  <div class="col-12 mb-3">
    <div class=" div-class p-2">
      List of Vendors to be notified
    </div>
    <hr class="hr" />
    <div class="col-12 my-3 text-center"
      *ngIf="!rfxTargetedBiddersNotificationEntityDto ||  rfxTargetedBiddersNotificationEntityDto.vendorList!.length == 0">
      <h3 class="heading-new text-dark">No vendor list yet.</h3>
    </div>
    <div class="multiple-list-scroll"
      *ngIf="rfxTargetedBiddersNotificationEntityDto && rfxTargetedBiddersNotificationEntityDto.vendorList!.length > 0">
      <div class="c-pointer">
        <div class="col-12 p-3">
          <div>

            <div class="row m-2"
              *ngFor="let bidderDto of rfxTargetedBiddersNotificationEntityDto.vendorList; index as i">
              <div class="col-10 card p-2">
                <div class="row">
                  <div class="col-4 font-weight-normal my-1">
                    Name
                  </div>
                  <div class="col-8 div-class">
                    {{ bidderDto.companyName }}
                  </div>
                  <hr class="hr my-1">
                  <div class="col-4 font-weight-normal my-1">
                    Email
                  </div>
                  <div class="col-8 div-class">
                    {{ bidderDto.emailId }}
                  </div>
                  <hr class="hr my-1">
                  <div class="col-4 font-weight-normal my-1">
                    Contact No
                  </div>
                  <div class="col-8 div-class">
                    <span class="font-bold text-900" *ngIf="bidderDto.mobileWrapper">
                      {{ bidderDto.mobileWrapper.countryCode!.countryCode }} {{ bidderDto.mobileWrapper.mobileNo }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-2 text-end mt-5">
                <img src="assets/icons/delete_danger.svg" class=" c-pointer svg-icon-class"
                  (click)="deleteEmailId(bidderDto.emailId!)" [ngClass]="{'disabled': !isAllowToEdit()}">
              </div>
              <div class="d-grid gap-2 mb-3" *ngIf="isLoading &&  selectedIndex == i">
                <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
              </div>
            </div>
            <hr />
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addPremiumSupplierModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2> Vendor Email Notifications</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutofocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div>
          <p-pickList class="bg-white" [source]="sourceBidderDtos" [target]="targetBidderDtos" sourceHeader="Available"
            targetHeader="Selected" [dragdrop]="true" [responsive]="true" [sourceStyle]="{ height: '20rem' }"
            [targetStyle]="{ height: '20rem' }" filterBy="name" sourceFilterPlaceholder="Search by name"
            targetFilterPlaceholder="Search by name" breakpoint="1400px">
            <ng-template let-bidderDto pTemplate="item">
              <div class="flex flex-wrap p-2 align-items-center gap-2 border">

                <div class="flex-1 flex flex-column gap-2">
                  <span class="font-bold">
                    {{ bidderDto.companyName }}
                  </span>
                  <div class="flex align-products-center gap-2">
                    <i class="pi pi-tag text-sm"></i>
                    <span>
                      {{ bidderDto.primaryEmailId }}
                    </span>
                  </div>
                </div>
                <span class="font-bold text-900" *ngIf="bidderDto.mobileList && bidderDto.mobileList.length > 0">
                  {{ bidderDto.mobileList[0].countryCode.countryCode }} {{ bidderDto.mobileList[0].mobileNo }}
                </span>
              </div>
            </ng-template>
          </p-pickList>
        </div>
        <!-- <div class="form-floating mb-3">
            <input type="email" class="form-control" [formControl]="emailIdsFormGroup"  id="floatingInput"  placeholder="name@example.com" />
            <label for="floatingInput">Email Id's</label>
          </div> -->
        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_showErrorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="{{isEdit? ' Vendor List Edited Successfully' : 'Vendor List Added Successfully.'}}"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>

        <div class=" col-12 text-end mb-3" *ngIf=" !isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="saveRfxPremiumSuppliers()"
            [disabled]="!isAllowToEdit()">{{isEdit? 'EDIT' : 'ADD'}}</button>
        </div>
      </div>
    </div>

  </div>
</ng-template>