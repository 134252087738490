import { Component } from '@angular/core';

@Component({
  selector: 'app-rfx-creator-registration',
  templateUrl: './rfx-creator-registration.component.html',
  styleUrls: ['./rfx-creator-registration.component.sass']
})
export class RfxCreatorRegistrationComponent {

}
