<div>
  <div class="flex flex-row gap-4 items-center justify-between h-12 px-6 py-4 border-b border-gray-300">
    <div class="basis-1/2">
      <div class="inline-block ms-2">
        <div class="font-semibold text-black text-xl">
          Questionnaire
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-100 h-8 py-2 px-6 flex items-center">
    <span class="font-light text-base text-gray-600 me-2">Title:</span>
    <span class="font-semibold text-base text-gray-800 me-3">
      {{tenderOpportunityWrapperDto?.title}}
    </span>
    <span class="font-light text-base text-gray-600 me-2">Ref. number:</span>
    <span class="font-semibold text-base text-gray-800 me-3">
      {{tenderOpportunityWrapperDto?.sequenceNo}}
    </span>
  </div>
  <form class="needs-validation w-full" [formGroup]="formGroup" novalidate novalidate>
       <div class="h-screen">
        <div class="p-6 tr-overflow-y-drawer">
          <div class="w-4/5">
            <div class="flex flex-row justify-between mb-4 gap-4">
              <div>
                <label class="text-gray-700 text-sm font-semibold leading-4">
                  <div class="mb-1">Scoring</div>
                </label>
                <div class="inline-block border border-blue-500 rounded-lg">
                <p-selectButton [unselectable]="true" [options]="selectOptionScoring" formControlName="scoringTemplate" optionLabel="label" optionValue="value">
                </p-selectButton>
                </div>
              </div>
              <div
                *ngIf="fc['scoringTemplate'].invalid && (fc['scoringTemplate'].dirty || fc['scoringTemplate'].touched)">
                <p class="text-red-500 " *ngIf="fc['scoringTemplate'].errors?.['required']">Required</p>
              </div>
            </div>

            <!-- <div class="flex flex-row justify-between mb-4"
              *ngIf="fc['scoringTemplate'].value == 'YES' && tenderOpeningType == TenderOpeningType.QCBS">
              <div>
                <label class="text-gray-700 text-sm font-semibold leading-4">
                  <div> Gradation system</div>
                </label>
                <p-selectButton [options]="selectOptionGradation" optionLabel="label" optionValue="value"
                  formControlName="gradationSystem">
                </p-selectButton>
              </div>
              <div *ngIf="fc['scoringTemplate'].value == 'YES' && fc['gradationSystem'].value">
                <label for="tenderReferenceNo" class="text-gray-700 text-sm font-semibold leading-4">
                  <div>Grade Range</div>
                </label>
                <input type="text" placeholder="---" formControlName="range" (blur)="generateRangeGrading()"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-100 text-sm">
                <div *ngIf="fc['range'].invalid && (fc['range'].dirty || fc['range'].touched)">
                  <p class="text-red-500 " *ngIf="fc['range'].errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="fc['range'].errors?.['multipleOfFive']">Invalid</p>
                </div>
              </div>
            </div> -->

            <div class="grid grid-cols-2 gap-4 justify-between items-start mb-4" *ngIf="fc['scoringTemplate'].value == 'YES'">
              <div>
                <label for="tenderReferenceNo" class="text-gray-700 text-sm font-semibold leading-4">
                  <div>Maximum Score</div>
                </label>
                <!-- <input formControlName="maximumScore" (blur)="generateRangeGrading()" -->
                <input formControlName="maximumScore"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
                  type="number" placeholder="---">
                <div *ngIf="fc['maximumScore'].invalid && (fc['maximumScore'].dirty || fc['maximumScore'].touched)">
                  <p class="text-red-500 " *ngIf="fc['maximumScore'].errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="fc['maximumScore'].errors?.['pattern']">Invalid</p>
                </div>
              </div>
              <div>
                <label for="tenderReferenceNo" class="text-gray-700 text-sm font-semibold leading-4">
                  <div>Qualifying Score</div>
                </label>
                <input formControlName="qualifyingScore"
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
                  type="number" placeholder="---">
                <div
                  *ngIf="fc['qualifyingScore'].invalid && (fc['qualifyingScore'].dirty || fc['qualifyingScore'].touched)">
                  <p class="text-red-500 " *ngIf="fc['qualifyingScore'].errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="fc['qualifyingScore'].errors?.['pattern']">Invalid</p>
                  <p class="text-red-500 " *ngIf="fc['qualifyingScore'].errors?.['qualifyScoreGreaterThanMax']">
                    Qualifying score should not be greater than maximum score.
                  </p>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2 gap-4 justify-between items-start mb-4" *ngIf="tenderOpeningType == TenderOpeningType.QCBS">
             
              <div>
                <label for="tenderReferenceNo" class="text-gray-700 text-sm font-semibold leading-4">
                  <div>Technical weightage</div>
                </label>
                <div class="flex w-full ">
                  <div class="input-group-append">
                    <span
                      class="border text-gray-700 text-base border-solid border-gray-400 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
                      id="basic-addon2">
                      %
                    </span>
                  </div>
                  <input formControlName="technicalWeightage"
                  class="bg-white w-full border-s-0 rounded-r-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
                  type="text" placeholder="---">
                </div>
                <div
                *ngIf="fc['technicalWeightage'].invalid && (fc['technicalWeightage'].dirty || fc['technicalWeightage'].touched)">
                <p class="text-red-500 " *ngIf="fc['technicalWeightage'].errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="fc['technicalWeightage'].errors?.['pattern']">Invalid</p>
              </div>
              </div>
              
              <div>
                <label for="tenderReferenceNo" class="text-gray-700 text-sm font-semibold leading-4">
                  <div>Financial weightage</div>
                </label>
                <div class="flex w-full ">
                  <div class="input-group-append">
                    <span
                      class="border text-gray-700 text-base border-solid border-gray-400 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
                      id="basic-addon2">
                      %
                    </span>
                  </div>
                  <input formControlName="financialWeightage"
                  class="bg-white w-full border-s-0 rounded-r-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
                  type="text" placeholder="---">
                </div>
               
                <div
                  *ngIf="fc['financialWeightage'].invalid && (fc['financialWeightage'].dirty || fc['financialWeightage'].touched)">
                  <p class="text-red-500 " *ngIf="fc['financialWeightage'].errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="fc['financialWeightage'].errors?.['pattern']">Invalid</p>
                  <p class="text-red-500 " *ngIf="fc['financialWeightage'].errors?.['weightageSumInvalid']">
                    The sum of technical and financial weightage must be equal to 100%.
                  </p>
                </div>
              </div>
            </div>

            <!-- <div class="block flex-row items-center"
              *ngIf="fc['gradationSystem'].value && technicalGradationConfig.controls.length > 0 && tenderOpeningType == TenderOpeningType.QCBS">
              <p-table [value]="technicalGradationConfig.controls" styleClass="p-datatable-gridlines"
                [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th>#</th>
                    <th>Range</th>
                    <th>Marks</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-i="rowIndex">
                  <tr class="align-middle">
                    <td class="">
                      {{i+1}}
                    </td>
                    <td class="">
                      {{item.get('startRange')?.value}} to {{item.get('endRange')?.value}}
                    </td>
                    <td class="">
                      {{item.get('marks')?.value}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div> -->
         

          <div class="my-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Questionnaire saved successfully.'">
            </app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg">
            </app-message-toast>
          </div>

        </div>
      </div>
       </div>
        <div class="footer">
          <div class="flex flex-row justify-end items-center h-8 my-2">
            <!-- <div class="basis-1/2 mt-2 ps-6">
              <div class="h-2 w-20 bg-gray-100 inline-block"></div>
              Some information is pending
            </div> -->
            <div class="text-end pe-7">
              <button [disabled]="isLoading"
                class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 me-3" (click)="closeDrawer()">Cancel</button>
              <p-button [disabled]="isLoading" label="Save"
                class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800" (onClick)="saveTenderWrapperUiDto()" [loading]="isLoading"></p-button>
              </div>
                <div class="  text-center text-white bg-blue-500 px-5 py-3" [ngClass]="{'disabled' : isLoading}">
                  <span class="text-sm font-normal  cursor-pointer" (click)="openExternalDrawer(applicationConstants.NEW_TENDER_TECH_SECTION)">
                    Add Technicals
                    <i-lucide name="arrow-right" [size]="18" class="my-icon inline-block"></i-lucide></span>
                </div>
          
          </div>
        </div>
      
  </form>
</div>