<div class="scroll" id="main">
  <app-header></app-header>
  <router-outlet></router-outlet>
  <!-- <app-footer></app-footer> -->
</div>

<ng-template #ErrorTemp>
  <div class="my-2">
    <header class="m-2">
      <div class="grid grid-cols-2 content-between">
        <div class=" flex items-center">
          <h2>
            Navigation Alert
            </h2>
        </div>
        <div class=" text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
      <div class="m-3">
       <div class="text-start text-red-500  border-danger p-2">
        <p style="margin-top: 0 !important;">
          The application strongly discourages the use of the browser's back and forward buttons, as they may cause significant data disparity. Therefore, it is highly recommended that you use the application's reliable and user-friendly navigation buttons to move seamlessly between pages.
        </p>
       </div>
       <div class="text-end my-3">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-3"
        (click)="closeModal()">OK</button>
      </div>
    </div>
  </div>
</ng-template>
<app-loader #appLoader></app-loader>
