import { RfxPaymentStatusEnum } from "../../enums/rfx/RfxPaymentStatusEnum";
import { RfxPurchaseOrderEnum } from "../../enums/rfx/RfxPurchaseOrderEnum";
import { AddressDto } from "../Address";
import { Currency } from "../Currency";
import { FileInfoDto } from "../FileInfoDto";
import { AdditionalFinancialPrice } from "../questionnaire/AdditionalFinancialPrice";
import { MobileWrapper } from "../user/MobileWrapper";

export class RfxPurchaseOrderDto {
  purchaseOrderId?: string;
  auctionHouseId?: string;
  rfxId?: string;
  subcategoryId?: string;
  
  vendorCode?: number
  vendorCompanyId?: string;
  vendorEmailId?: string;
  vendorMobileNo?: MobileWrapper;
  vendorCompanyName?: string;
  vendorAddress?: AddressDto;

  buyerCompanyId?: string;
  buyerEmailId?: string;
  buyerMobileNo?: MobileWrapper;
  buyerCompanyName?: string;
  buyerAddress?: AddressDto;
  buyerLogo?: FileInfoDto;
  vendorLogo?: FileInfoDto;

  compSequence?: number;
  questionnaireId?: string;

  purchaseOrderNumber?: string
  purchaseOrderDate?: string
  estimatedDeliveryDate?: string;
  purchaseOrderAmount?: number

  invoiceAmount?: number
  invoiceStatus?: string

  enquiryDate?: string

  currency?: Currency;

  paymentStatus?: RfxPaymentStatusEnum;

  purchaseOrderItems?: PurchaseOrderItemDto[]
  
  status?: RfxPurchaseOrderEnum;
  updateTimeUtc?: string;
  updateTimeUtcMillis?: number;
}

export class PurchaseOrderItemDto {
  sectionId?: string;

  questionId?: string;
  itemName?: string;
  itemDescription?: string;
  preSequenceText?: string;
  sequenceNo?: number;

  quantity?: number;
  uom?: string;
  tax?: number;

  unitPrice?: number;
  unitPriceQtyTotal?: number;
  unitPriceQtyTaxTotal?: number;
  unitPriceQtyTaxAdditionalPricesTotal?: number;

  additionalPrices?: AdditionalFinancialPrice[]
}