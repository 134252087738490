import { ApplicationConstants } from '../../../../shared/util/ApplicationConstants';
import { UserBidsData } from './../../../../shared/models/user/UserBidsData';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AdminLotsDataHolderService } from 'src/app/shared/services/adminLotsDataHolderService.service';
import { BehaviorSubject, Subscription } from "rxjs";
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionLotEntityDto } from 'src/app/shared/models/user/AuctionLotEntityDto';
import { AdminBidderHistoryWrapper } from 'src/app/shared/models/AdminBidderHistoryWrapper';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { DatePipe } from '@angular/common';
import { Currency } from 'src/app/shared/models/Currency';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAuctionBidsHistoryDto } from 'src/app/shared/models/UserAuctionBidsHistoryDto';
import { BidderDetailsComponent } from '../../bidder-details/bidder-details.component';
import { UserOfferEntityDto } from 'src/app/shared/models/user/UserOfferEntityDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { LotAllocationDto } from 'src/app/shared/models/user/LotAllocationDto';
import { FormControl } from '@angular/forms';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { EChartsOption } from 'echarts';
import { BlockchainTransactionDetailsComponent } from '../blockchain-transaction-details/blockchain-transaction-details.component';
import { BlockchainService } from 'src/app/shared/services/blockchain.service';
import SwiperCore, { SwiperOptions, Navigation, Pagination, Virtual } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { DeploymentConfigurationDto } from 'src/app/shared/models/user/DeploymentConfigurationDto';
import { PolygonTransaction } from 'src/app/shared/models/blockchain/PolygonTransaction';
import { TimeAgoPipe } from 'src/app/shared/pipes/time-ago.pipe';
import { AwardedBiddersLotsComponent } from 'src/app/shared/components/awarded-bidders-lots/awarded-bidders-lots.component';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { RankPriceConfigEnum } from 'src/app/shared/enums/RankPriceConfigEnum';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';

SwiperCore.use([Virtual]);


@Component({
  selector: 'app-auction-creator-bidder',
  templateUrl: './auction-creator-bidder.component.html',
  styleUrls: ['./auction-creator-bidder.component.sass']
})
export class AuctionCreatorBidderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() fromPostAuction: boolean = false;
  @Input() postAuction$?: BehaviorSubject<AuctionEntityDto | undefined>;
  @Input() postAuctionLot$?: BehaviorSubject<AuctionLotEntityDto | undefined>;

  IsUpcomingDisable = false;
  IsUpcomingEnable = true;
  page: number = 1;
  pageSize: number = 50;
  isExportLoading?: boolean;
  isOfferExportLoading?: boolean;
  isLoading?: boolean;
  showMaxBidModel?: boolean = false;
  showMaxBid?: boolean = false;
  isShowChartView: boolean = false;
  isShowBlockchainView: boolean = false;

  auctionEntityDto?: AuctionEntityDto;
  lotEntityDtoList: Array<AuctionLotEntityDto> = [];
  // bidderHistoryWrapperList: Array<AdminBidderHistoryWrapper> = [];
  bidderHistoryWrapper?: AdminBidderHistoryWrapper;
  bidderHistoryWrapper$ = new BehaviorSubject<AdminBidderHistoryWrapper | null>(null);

  selectedUserAuctionBidsHistoryDto?: UserAuctionBidsHistoryDto;
  selectedUserLotBidsData: UserBidsData[] = [];
  _filteredUserLotBidsData$ = new BehaviorSubject<UserBidsData[]>([]);

   showGeneratedView: boolean =  false;
  awardUserId?: string;

  _filteredUserBidsHistory: UserAuctionBidsHistoryDto[] = [];
  _filteredUserBidsHistory$ = new BehaviorSubject<UserAuctionBidsHistoryDto[]>([]);

  _filteredPolygonTxnList: PolygonTransaction[] = []
  _filteredPolygonTxnList$ = new BehaviorSubject<PolygonTransaction[]>([])

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _errorMsg$ = new BehaviorSubject<string>('');
  userOfferEntityDtoList: Array<UserOfferEntityDto> = [];
  currentOfferEntityDto?: UserOfferEntityDto;
  selectedUserOffer?: UserOfferEntityDto;
  selectedBidsHistoryForAllocate?: UserAuctionBidsHistoryDto;
  lotAllocationEntity?: LotAllocationDto;
  isOfferLoading?: boolean = false;
  isLotAllocationLoading?: boolean = false;
  isBidDetailsLoading?: boolean = false;
  isRejectBidLoading: boolean = false;

  selectedAuctionSubscription$?: Subscription;
  getAllLotSubscription$?: Subscription;
  selectedLot?: AuctionLotEntityDto
  isLotAllocated?: boolean;

  ctrlBidHistorySearch: FormControl = new FormControl('')
  reverseAuction?: boolean;


  @ViewChild('search') searchField?: ElementRef<HTMLInputElement>;

  chartOption?: EChartsOption;
  isMaskBidderDetails: boolean = false;
  isShowBiddersRank: boolean = false;

  deploymentConfigurationDtoSubscription$?: Subscription;
  deploymentConfigurationDto?: DeploymentConfigurationDto;

  public screenWidth: any;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private invoiceService: InvoiceService,
    private datePipe: DatePipe,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private lotService: AdminLotsDataHolderService,
    private ngbModal: NgbModal,
    public adminService: AdminDashboardService,
    private blockchainService: BlockchainService,
    private timeAgoPipe: TimeAgoPipe
  ) { }


  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    if (!this.fromPostAuction) {
      this.selectedAuctionSubscription$ = this.adminSourcingEventsDataHolderService.selectedAuction$.subscribe((data) => {
        if (data) {
          this.auctionEntityDto = data;
          this.reverseAuction = this.auctionEntityDto.auctionBiddingMethod == ApplicationConstants.REVERSE_AUCTION ? true : false;
          this.checkMaskBidderDetails();
          if (this.auctionEntityDto.enableBlockchain) {
            this.blockchainService.loadBlockchainTransactions()
          }

          this.isShowBiddersRank = this.auctionEntityDto.rankCurrentPriceConfig == RankPriceConfigEnum.SHOW_RANK_AND_CURRENT_PRICE_WITH_TRANSITION
            || this.auctionEntityDto.rankCurrentPriceConfig == RankPriceConfigEnum.SHOW_RANK_AND_NO_CURRENT_PRICE_WITH_TRANSITION;
        }
      })


      this.getAllLotSubscription$ = this.lotService.getAllLots$.subscribe((data) => {
        if (data && data.length > 0) {
          this.lotEntityDtoList = data;
          this.lotEntityDtoList.sort((a, b) => a.lotSequence! - b.lotSequence!);

          if (this.selectedLot) {
            let lotData = this.lotEntityDtoList.filter(item => item.lotId == this.selectedLot?.lotId)[0];
            this.selectedLot = lotData;
            this.getLotBidsHistoryData(this.selectedLot);
          } else {
            this.selectedLot = this.lotEntityDtoList[0];
            this.getLotBidsHistoryData(this.lotEntityDtoList[0]);
          }
        }
      })
    } else {
      this.postAuction$?.subscribe((data) => {
        this.auctionEntityDto = data;
      })

      this.postAuctionLot$?.subscribe((data) => {
        this.selectedLot = data;
        if(this.selectedLot && this.selectedLot.auctionId && this.selectedLot.lotId){
          this.getLotBidsHistoryData(this.selectedLot!)
        }
      })
    }

    this.ctrlBidHistorySearch.valueChanges.subscribe((val) => {
      this.searchBidsHistory(val);
    })

    this.deploymentConfigurationDtoSubscription$ = this.adminService.getDeploymentConfiguration$.subscribe(data =>{
      if(data){
        this.deploymentConfigurationDto = data;
      }
    })
  }


  ngAfterViewInit(): void {
    // this.updateSwiperConfig();
  }

  selectedLotEvent(event: any) {
    this.getLotBidsHistoryData(event);
  }

  toggleBidding(lotId: string) {
    let selectedLot = this.lotEntityDtoList.find((item) => item?.lotId == lotId);
    if (selectedLot) {
      selectedLot.active = !selectedLot.active;
      this.saveLotDetails(selectedLot);
    }
  }



  changeShowBid() {
    this.showMaxBidModel = !this.showMaxBidModel;
    this._filteredUserLotBidsData$.next([]);

    if (this.showMaxBidModel) {
      let temp = this.selectedUserLotBidsData.filter(item => item.bidType == "MAX BID");
      this._filteredUserLotBidsData$.next(temp);
    } else {
      this._filteredUserLotBidsData$.next(this.selectedUserLotBidsData);
    }

  }

  // bidderHistoryWrapper(lotId?: string): AdminBidderHistoryWrapper | undefined {
  //   let toReturn;

  //   if (this.bidderHistoryWrapperList.length > 0) {
  //     toReturn = this.bidderHistoryWrapperList.find((item) => item.lotId == lotId);
  //   }

  //   return toReturn;
  // }

  populateUserOfferData() {
    let tempUserOfferEntityDtoList = [...this.userOfferEntityDtoList];

    if (tempUserOfferEntityDtoList.length > 0) {
      tempUserOfferEntityDtoList.sort((a, b) => Number(b?.offerPrice) - Number(a?.offerPrice));
      this.currentOfferEntityDto = tempUserOfferEntityDtoList[0];
    } else {
      this.currentOfferEntityDto = undefined;
    }
  }

  openGeneratedInvoiceData(userId: string){
    this.awardUserId = userId;
    this.showGeneratedView = true;
  }


  openBiddersAwardedLots(userId?: string) {
    let modalRef = this.ngbModal.open(AwardedBiddersLotsComponent, {
      size: 'xl', keyboard: false, backdrop: 'static'
    })
    this.invoiceService.loadAwardedBidderData(this.auctionEntityDto?.auctionId!, userId!);
    modalRef.componentInstance.selectedAuction = this.auctionEntityDto;
  }

  getLotBidsHistoryData(selectedLot: AuctionLotEntityDto) {
    this.selectedLot = selectedLot;
    this.lotService.setSelectedAuctionLot(selectedLot);

    this.isLoading = true;
    this.lotService.getLotBidsHistoryData(this.auctionEntityDto?.auctionId!, selectedLot.lotId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {

          if (apiResponseDto.data) {
            this.showMaxBid = false;
            this.bidderHistoryWrapper = apiResponseDto.data as AdminBidderHistoryWrapper;
            this.userOfferEntityDtoList = this.bidderHistoryWrapper.offerEntityDtoList ?? [];
            this.bidderHistoryWrapper$.next(this.bidderHistoryWrapper);
            this.lotAllocationEntity = this.bidderHistoryWrapper.lotAllocationDto;
            this._filteredUserBidsHistory = this.bidderHistoryWrapper.userAuctionBidsHistoryDtos ?? [];
            this.populateUserOfferData();
            this.paginateBidderHistoryList();
            this.paginateUserOfferDataList();
          }
        } else {
          this.isLoading = false;
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      }
    });
  }


  clearData() {
    this.bidderHistoryWrapper = undefined;
    this.bidderHistoryWrapper$.next(null);
    this.userOfferEntityDtoList = [];
    this._filteredUserBidsHistory = [];
    this._filteredUserBidsHistory$.next([]);

  }

  refreshData() {
    this.searchField!.nativeElement.value = '';
    this.getLotBidsHistoryData(this.selectedLot!);
  }


  checkMaskBidderDetails(){
    let isAuctionLive = this.auctionEntityDto?.status == AuctionStatus.LIVE;

    if(this.auctionEntityDto?.maskBidderDetails && isAuctionLive){
      this.isMaskBidderDetails =  true;
    }else{
     this.isMaskBidderDetails =  false;
    }
  }



  openRejectBidModal(userAuctionBidsHistoryDto: UserAuctionBidsHistoryDto, errorTemp: any) {
    this._showErrorToast$.next(false)
    this._showSuccessToast$.next(false)
    this._errorMsg$.next("");

    this.selectedUserAuctionBidsHistoryDto = userAuctionBidsHistoryDto;
    this.ngbModal.open(errorTemp, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openOfferConfirmModal(content: any, offerWrapper: UserOfferEntityDto) {
    this.isLotAllocated = this.selectedLot?.lotAllocated;
    this.selectedUserOffer = offerWrapper;
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }
  openBlockChainDetailsModal(blockHash: string){
    let modalRef = this.ngbModal.open(BlockchainTransactionDetailsComponent, {
      size: 'xl', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.transactionHash = blockHash
  }

  // filterByName(value: string) {
  //   if (value) {
  //     let data = this.bidderHistoryWrapper?.userAuctionBidsHistoryDtos?.filter(item => item.name?.includes(value));
  //     this._currentUserAuctionBidsHistoryDtos$.next(data!);
  //   } else {
  //     this._currentUserAuctionBidsHistoryDtos$.next(this.bidderHistoryWrapper?.userAuctionBidsHistoryDtos!);
  //   }
  // }

  filterUserOfferByName(value: string) {
    if (value) {
      let data = this.bidderHistoryWrapper?.offerEntityDtoList?.filter(item => item?.bidderName?.includes(value));
      this.userOfferEntityDtoList = data!;
    } else {
      this.userOfferEntityDtoList = this.bidderHistoryWrapper?.offerEntityDtoList!;
    }
  }

  isHighestBidEligibleToReject(item: UserAuctionBidsHistoryDto) {
    let toReturn = false;
    if (this.bidderHistoryWrapper?.userAuctionBidsHistoryDtos && this.bidderHistoryWrapper?.userAuctionBidsHistoryDtos.length > 0 && item) {

      /**
       * All bid to reject when
       * 1. bid user Id is highest bid user Id ->  item.userId == this.bidderHistoryWrapper?.highestBidBidderUserId
       * 2. bid price match with highest bid price -> item.bidPrice == this.bidderHistoryWrapper?.highestBidPrice
       * 3. bid is not system bid -> item.systemBid == false
       * 4. bid is active -> item.activeBid == true
       * 5. bid type is manual -> item.bidType?.toLocaleLowerCase() == "MANUAL".toLocaleLowerCase()
       * 6. lot is not allocated -> !lot.lotAllocated
       */

      if (item.userId == this.bidderHistoryWrapper?.highestBidBidderUserId && item.bidPrice == this.bidderHistoryWrapper?.highestBidPrice && item.systemBid == false && item.activeBid == true && item.bidType?.toLocaleLowerCase() == "MANUAL".toLocaleLowerCase() &&  !item.lotAllocated) {
        toReturn = true;
      }
    }
    return toReturn;
  }

  rejectBid() {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.isRejectBidLoading = true;

    this.lotService.rejectBid(
      this.selectedUserAuctionBidsHistoryDto?.auctionId!, this.selectedUserAuctionBidsHistoryDto?.lotId!,
      this.selectedUserAuctionBidsHistoryDto?.bidPrice!, this.selectedUserAuctionBidsHistoryDto?.userId!
    ).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isRejectBidLoading = false;
          this.refreshData();

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isRejectBidLoading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while rejecting bid. Try again.");
        this.isRejectBidLoading = false;

      }
    })
  }

  // changeCurrentLotById(selectedLot: AuctionLotEntityDto) {
  //   this.selectedLotIndex = selectedLot.lotSequence!;
  //   this.getLotBidsHistoryData(selectedLot, selectedLot.lotSequence!);

  // }

  getBidderHistoryDataForAuctionAndLotIdAndUserId(auctionId: string, lotId: string, userId: string) {
    this.isBidDetailsLoading = true;
    this.lotService.getBidderHistoryDataAuctionIdLotIdAndUserId(auctionId, lotId, userId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isBidDetailsLoading = false;
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.selectedUserLotBidsData = apiResponseDto.data as UserBidsData[];
          this._filteredUserLotBidsData$.next(this.selectedUserLotBidsData);
        }
      },
      error: (error) => {
        this.isBidDetailsLoading = false;
        console.error(error);
      }
    });
  }

  getDisplayDate(date?: string, time?: string) {
    if (date != null && time != null) {
      return ApplicationUtils.getDisplayDateAdminNew(date!, time!);
    } else {
      return '';
    }
  }

  getFormattedPrice(currency: Currency, price: any) {
    return currency.symbol + ApplicationUtils.getFormattedPrice(currency.locale, price);
  }

  openBidderDetailsModal(userAuctionBidHistoryDto: UserAuctionBidsHistoryDto) {

    let modalRef = this.ngbModal.open(BidderDetailsComponent, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
    modalRef.componentInstance.userAuctionBidHistoryDto = userAuctionBidHistoryDto;
    modalRef.result.then((result) => {
      if (result) {
        this.refreshData();
      }
    })
  }

  openShowBidderDetailsModal(content: any, userAuctionBidsHistoryDto: UserAuctionBidsHistoryDto) {

    this.selectedUserLotBidsData = [];
    this._filteredUserLotBidsData$.next([]);
    this.selectedUserAuctionBidsHistoryDto = userAuctionBidsHistoryDto;
    this.getBidderHistoryDataForAuctionAndLotIdAndUserId(userAuctionBidsHistoryDto?.auctionId!, userAuctionBidsHistoryDto?.lotId!, userAuctionBidsHistoryDto?.userId!);
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openUserOfferAllocationDetailsModal(content: any, offerWrapper: UserOfferEntityDto) {
    this._showErrorToast$.next(false)
    this._showSuccessToast$.next(false)
    this._errorMsg$.next("");

    this.selectedUserOffer = offerWrapper;

    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openAllocationDetailsModal(content: any, bidTransactionHistory: UserAuctionBidsHistoryDto) {
    this._showErrorToast$.next(false)
    this._showSuccessToast$.next(false)
    this._errorMsg$.next("");

    this.selectedUserAuctionBidsHistoryDto = bidTransactionHistory;
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false , centered: true
    });
  }

  openLotAllocatedConfirmationModal(content: any, userAuctionBidsHistoryDto: UserAuctionBidsHistoryDto) {
    this._showErrorToast$.next(false)
    this._showSuccessToast$.next(false)
    this._errorMsg$.next("");

    this.isLotAllocated = this.selectedLot?.lotAllocated;
    this.selectedBidsHistoryForAllocate = userAuctionBidsHistoryDto;
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false , centered: true
    });
  }


  getDisplayDateFromISO(timestamp?: any) {
    if (timestamp) {
      return this.datePipe.transform(timestamp, 'MMM dd, yyyy hh:mm:ss a ');
    }
    return '';
  }

  closeModal() {
    this.ngbModal.dismissAll()
  }

  saveLotDetails(lotEntityDto: AuctionLotEntityDto) {
    this.lotService.saveAuctionLotDetails(lotEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          // this._showSuccessToast$.next(true);
          // this.isLoading = false;
          // this._isSaveButtonEnable$.next(true);
          // setTimeout(() => {
          //   this._showSuccessToast$.next(false);
          //   this.closeModal();
          // }, 2000)
        } else {
          // this._showErrorToast$.next(true);
          // this._errorMsg$.next(apiResponseDto.message!);
          // this.isLoading = false;
          // this._isSaveButtonEnable$.next(true);
          // setTimeout(() => {
          //   this._showErrorToast$.next(false);
          //   this._errorMsg$.next("");
          // }, 2000)
        }
      },
      error: (error) => {
        console.error(error);
        // this._showErrorToast$.next(true);
        // this._errorMsg$.next("Error Saving Auction");
        // this.isLoading = false;
        // this._isSaveButtonEnable$.next(true);
        // setTimeout(() => {
        //   this._showErrorToast$.next(false);
        //   this._errorMsg$.next("");
        // }, 2000)
      }
    })
  }

  searchBidsHistory(searchText: string) {
    let tempBidsHistory = [];

    let allUserBidsHistory = [...this.bidderHistoryWrapper?.userAuctionBidsHistoryDtos ?? []];
    let maxBidsHistory = allUserBidsHistory.filter(item => item.bidType == "Max Bid");

    tempBidsHistory = this.showMaxBid ? maxBidsHistory : allUserBidsHistory;

    if (searchText.trim() != '') {
      let filteredUserBidsHistory = tempBidsHistory.filter((item) => item.name?.toLowerCase().includes(searchText.trim().toLowerCase()) || item.paddleNo?.includes(searchText.trim())
        || item.maxBidPrice?.toString().includes(searchText) || item.bidPrice?.toString().includes(searchText));
      this._filteredUserBidsHistory = filteredUserBidsHistory;
    } else {
      this._filteredUserBidsHistory = tempBidsHistory;
    }

    this.paginateBidderHistoryList();
  }

  searchUserOffer(searchText: string) {
    let allUserOffer = [...this.bidderHistoryWrapper?.offerEntityDtoList ?? []];

    if (searchText.trim() != '') {
      let tempUserOffer = allUserOffer.filter((item) => item.bidderName?.toLowerCase().includes(searchText.trim().toLowerCase()) || item.bidderEmailId?.toLowerCase()?.includes(searchText.trim().toLowerCase()) || item.bidderContactNo?.includes(searchText.trim()) || item.offerPrice?.toString().includes(searchText.trim()));
      this.userOfferEntityDtoList = tempUserOffer;
    } else {
      this.userOfferEntityDtoList = allUserOffer;
    }
  }

  changeShowMaxBid() {
    this.showMaxBid = !this.showMaxBid;
    this.page = 1;
    this._filteredUserBidsHistory = [];
    this._filteredUserBidsHistory$.next([]);

    let allUserBidsHistory = [...this.bidderHistoryWrapper?.userAuctionBidsHistoryDtos ?? []];
    if (this.showMaxBid) {
      let tempUserBidsHistory = allUserBidsHistory.filter(item => item.bidType == "Max Bid");
      this._filteredUserBidsHistory = tempUserBidsHistory;
    } else {
      this._filteredUserBidsHistory = allUserBidsHistory;
    }

    this.paginateBidderHistoryList()
  }

  // Filter
  paginateBidderHistoryList() {
    let tempAllUserBidsHistory = [...this._filteredUserBidsHistory];

    if (tempAllUserBidsHistory) {
      this._filteredUserBidsHistory$.next(tempAllUserBidsHistory.map((history, i) => ({ id: i + 1, ...history })).slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      ));

      if (tempAllUserBidsHistory.length > 0) {
        tempAllUserBidsHistory = tempAllUserBidsHistory.filter(item => item.activeBid);
        this.populateChartsData(tempAllUserBidsHistory);
      }
    }
  }

  // Filter
  paginateUserOfferDataList() {
    if (this.userOfferEntityDtoList && this.userOfferEntityDtoList.length > 0) {
      this.userOfferEntityDtoList = this.userOfferEntityDtoList.map((history, i) => ({ id: i + 1, ...history })).slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      );
    }
  }

  downloadBiderOfferData() {
    this.isLoading = true;
    this.adminService.downloadListBidderOfferData(this.auctionEntityDto?.auctionId!, this.userOfferEntityDtoList[0].lotId!).subscribe(data => {
      this.isLoading = false;
      if (data) {
        let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
  }

  isMaxOfferAlloted() {
    if (this.selectedLot?.lotAllocated && this.currentOfferEntityDto && this.lotAllocationEntity
      && this.currentOfferEntityDto.offerPrice == this.lotAllocationEntity.bidPrice
      && (!this.bidderHistoryWrapper?.userAuctionBidsHistoryDtos || this.bidderHistoryWrapper?.userAuctionBidsHistoryDtos?.length == 0)) {
      return true;
    }
    return false;
  }

  isShowDownloadBidderOfferButton() {
    if (this.userOfferEntityDtoList && this.userOfferEntityDtoList.length > 0) {
      return true
    } else {
      return false;
    }
  }

  isShowDownloadBidHistoryButton() {
    if (this.bidderHistoryWrapper && this.bidderHistoryWrapper.userAuctionBidsHistoryDtos && this.bidderHistoryWrapper.userAuctionBidsHistoryDtos.length > 0) {
      return true
    } else {
      return false;
    }
  }

  acceptOffer() {
    this.isOfferLoading = true;
    this._showErrorToast$.next(false)

    let auctionId = this.selectedUserOffer?.auctionId!;
    let lotId = this.selectedUserOffer?.lotId!;
    let userId = this.selectedUserOffer?.userId!;
    let offerPrice = this.selectedUserOffer?.offerPrice!;

    this.adminService.acceptOffer(auctionId, lotId, userId, offerPrice).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true)
          this.isOfferLoading = false;
          this.refreshData();
          setTimeout(() => {

            this._showSuccessToast$.next(false)
            this.closeModal();
          }, 2000);
        } else {
          this._errorMsg$.next("Error while accepting Offer. Try again.");
          this.isOfferLoading = false;
        }
      },
      error: (err) => {
        console.log(err);
        this._errorMsg$.next("Error while accepting Offer. Try again.");
        this.isOfferLoading = false;
      }
    })
  }

  lotAllocate() {
    this.isLotAllocationLoading = true;
    this._showErrorToast$.next(false)
    this._showSuccessToast$.next(false)
    this._errorMsg$.next("");

    let auctionId = this.selectedBidsHistoryForAllocate?.auctionId!;
    let lotId = this.selectedBidsHistoryForAllocate?.lotId!;
    let userId = this.selectedBidsHistoryForAllocate?.userId!;
    let bidPrice = this.selectedBidsHistoryForAllocate?.bidPrice!;

    this.adminService.userLotAllocate(auctionId, lotId, userId, bidPrice).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as AdminBidderHistoryWrapper;
          // this.bidderHistoryWrapper = data;
          // this.bidderHistoryWrapper$.next(data);
          this._showSuccessToast$.next(true)
          this.isLotAllocationLoading = false;
          this.refreshData();
          setTimeout(() => {
            this._showSuccessToast$.next(false)
            this.closeModal();
          }, 2000);
        } else {
          this._showErrorToast$.next(true)
          this.isLotAllocationLoading = false;
          if(apiResponseDto){
            this._errorMsg$.next(apiResponseDto.message!);
          }else{
            this._errorMsg$.next("Error while awarding Lot. Try again.");
          }
        }
      },
      error: (err) => {
        console.log(err);
        this._showErrorToast$.next(true)
        this._errorMsg$.next("Error while awarding Lot. Try again.");
        this.isLotAllocationLoading = false;
      }
    })
  }

  rejectLotAllocation() {
    this.isLotAllocationLoading = true;
    this._showErrorToast$.next(false)
    this._showSuccessToast$.next(false)

    let auctionId = this.selectedBidsHistoryForAllocate?.auctionId!;
    let lotId = this.selectedBidsHistoryForAllocate?.lotId!;
    let userId = this.selectedBidsHistoryForAllocate?.userId!;
    let bidPrice = this.selectedBidsHistoryForAllocate?.bidPrice!;



    this.adminService.rejectLotAllocate(auctionId, lotId, userId, bidPrice, false).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as AdminBidderHistoryWrapper;
          // this.bidderHistoryWrapper = data;
          // this.bidderHistoryWrapper$.next(data);
          this._showSuccessToast$.next(true)
          this.isLotAllocationLoading = false;
          this.refreshData();
          setTimeout(() => {
            this._showSuccessToast$.next(false)
            this.closeModal();
          }, 2000);
        } else {
          this._showErrorToast$.next(true)
          this._errorMsg$.next("Error while rejecting award. Try again.");
          this.isLotAllocationLoading = false;
        }
      },
      error: (err) => {
        console.log(err);
        this._showErrorToast$.next(true)
        this._errorMsg$.next("Error while rejecting award. Try again.");
        this.isLotAllocationLoading = false;
      }
    })
  }

  rejectOfferLotAllocation() {
    this.isOfferLoading = true;
    this._showErrorToast$.next(false)

    let auctionId = this.selectedUserOffer?.auctionId!;
    let lotId = this.selectedUserOffer?.lotId!;
    let userId = this.selectedUserOffer?.userId!;
    let bidPrice = this.selectedUserOffer?.offerPrice!;


    this.adminService.rejectLotAllocate(auctionId, lotId, userId, bidPrice, true).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true)
          this.isOfferLoading = false;
          this.refreshData();
          setTimeout(() => {
            this._showSuccessToast$.next(false)
            this.closeModal();
          }, 2000);
        } else {
          this._errorMsg$.next("Error while rejecting Offer. Try again.");
          this.isOfferLoading = false;
        }
      },
      error: (err) => {
        console.log(err);
        this._errorMsg$.next("Error while rejecting Offer. Try again.");
        this.isLotAllocationLoading = false;
      }
    })
  }



  downloadBidHistoryData() {
    this.isExportLoading = true;
    this.adminService.downloadListBidHistoryData(this.auctionEntityDto?.auctionId!, this.selectedLot?.lotId!).subscribe(data => {
      this.isExportLoading = false;
      if (data) {
        let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
  }

  downloadUserOfferData() {
    this.isOfferExportLoading = true;
    this.adminService.downloadListBidderOfferData(this.auctionEntityDto?.auctionId!, this.selectedLot?.lotId!).subscribe(data => {
      this.isOfferExportLoading = false;
      if (data) {
        let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
  }

  ngOnDestroy(): void {
    if (this.selectedAuctionSubscription$) {
      this.selectedAuctionSubscription$.unsubscribe();
    }
    if (this.getAllLotSubscription$) {
      this.getAllLotSubscription$.unsubscribe();
    }
    if(this.deploymentConfigurationDtoSubscription$){
      this.deploymentConfigurationDtoSubscription$.unsubscribe();
    }
  }

  changePageSizeOfBidderHistory(pageSize: number) {
    this.pageSize = pageSize;
    this.paginateBidderHistoryList();
  }

  changePageSizeOfOfferDataList(pageSize: number) {
    this.pageSize = pageSize;
    this.paginateUserOfferDataList();
  }

  truncateString(val?: string, maxLength?: number) {
    if (!val) {
      return '';
    }

    if (val.length > maxLength!) {
      return ApplicationUtils.truncateString(val, maxLength!);
    } else {
      return val;
    }
  }

  isShowOfferData(): boolean {
    if (this.selectedLot?.buyItNowEnable && this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT) {
      return true;
    }
    return false;
  }

  isAllowToAllocate(item: UserAuctionBidsHistoryDto, index: number): boolean {

    if (this.bidderHistoryWrapper?.userAuctionBidsHistoryDtos && this.bidderHistoryWrapper?.userAuctionBidsHistoryDtos.length > 0 && item) {
      if (item.activeBid == true && index < 10 && !this.selectedLot?.lotAllocated) {
        return true;
      }
    }

    return false;
  }

  getCountryCode(countryCodeDto?: CountryCodeDto) {
    if (countryCodeDto) {
      return countryCodeDto.countryCode?.replace('(', '').replace(')', '');
    }
    return '+1';
  }

  // Chart View
  toggleChartView() {
    this.isShowChartView = !this.isShowChartView;
    this.isShowBlockchainView = false;
  }

  populateChartsData(bidHistoryList: UserAuctionBidsHistoryDto[]) {
    bidHistoryList.sort((a, b) => +ApplicationUtils.convertedDateFrom12h(a.bidDate!, a.bidTime!)! - +ApplicationUtils.convertedDateFrom12h(b.bidDate!, b.bidTime!)!);

    let xAxisDataList = bidHistoryList.map(item => this.datePipe.transform(ApplicationUtils.convertedDate(item.bidDate!, item.bidTime!), 'MMM dd H:mm')!);
    let bidsList = bidHistoryList.map(item => item?.bidPrice!).filter(Boolean);
    let maxBidsList = bidHistoryList.map(item => item?.maxBidPrice!).filter(Boolean);
    let visualMapList = [];
    let scatterDataList = bidHistoryList.map(item => item.bidType == 'Max Bid' ? item?.maxBidPrice! : item?.bidPrice!)

    for (let i = 0; i < bidHistoryList.length; i++) {
      let color = bidHistoryList[i].bidType == 'Max Bid' ? 'green' : 'blue';
      visualMapList.push({ value: i, color: color });
    }

    this.chartOption = {
      grid: {
        containLabel: true
      },
      dataZoom: [{
        type: 'inside',
        id: 'insideX',
        xAxisIndex: 0,
        start: 0,
        end: 100,
        zoomOnMouseWheel: true,
        moveOnMouseMove: true,
        moveOnMouseWheel: false
      }],
      tooltip: {
        formatter: (params: any) => {
          let index = params.dataIndex;
          let item = bidHistoryList[index];
          let dateTime = this.getDisplayDate(item.bidDate!, item.bidTime!);
          let paddleNo = item.paddleNo;
          let bidPrice = this.getFormattedPrice(item.currency!, item.bidPrice);
          let maxBidPrice = this.getFormattedPrice(item.currency!, item.maxBidPrice);
          let isMaxBid = item.bidType == 'Max Bid';

          if (isMaxBid) {
            return `Date: ${dateTime}<br>
            Bidder No: #${paddleNo}<br>
            Bidder Name: ${item.name}<br>
            Proxy Bid: ${maxBidPrice}<br>
            Bid Type: ${item.bidType == 'Max Bid'? 'Proxy Bid' : 'Manual'}`;
          } else {
            return `Date: ${dateTime}<br>
            Bidder No: #${paddleNo}<br>
            Bidder Name: ${item.name}<br>
            Bid: ${bidPrice}<br>
            Bid Type: ${item.bidType}`;
          }
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisDataList
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: bidHistoryList[0].currency?.symbol + '{value}'
        },
        axisPointer: {
          snap: true
        }
      },
      visualMap: {
        show: false,
        dimension: 0,
        pieces: visualMapList
      },
      series: [
        {
          symbolSize: 15,
          data: scatterDataList,
          type: 'scatter'
        }
      ]
    }
  }

  // Blockchain
  getBlockchainTransactions() {
    this.blockchainService.getPolygonTxnList$.subscribe((data) => {
      if (data) {
        this.isShowBlockchainView = !this.isShowBlockchainView
        this.isShowChartView = false

        this._filteredPolygonTxnList = data;
        this._filteredPolygonTxnList$.next(data);

        this.paginateBlockchainTransactions()
      }
    })
  }

  changePageSizeOfBlockchainTransactions(pageSize: number) {
    this.pageSize = pageSize;
    this.paginateBlockchainTransactions();
  }

  paginateBlockchainTransactions() {
    let tempPolygonTxnList = [...this._filteredPolygonTxnList];

    if (tempPolygonTxnList) {
      this._filteredPolygonTxnList$.next(tempPolygonTxnList.map((txn, i) => ({ id: i + 1, ...txn })).slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize,
      ));
    }
  }

  getTransactionFees(transaction: PolygonTransaction) {
    let transactionFee = Number(transaction.gasPrice) * Number(transaction.gasUsed);
    return this.gasToMatic(transactionFee);
  }

  gasToMatic(val: any) {
    return (val * 0.000000000000000001).toFixed(10) + ' MATIC'
  }

  timeAgoDate(date: any) {
    return this.timeAgoPipe.transform(new Date(date * 1000))
  }

  displayTimestamp(timeStamp: any) {
    return this.datePipe.transform(Number(timeStamp)*1000, 'MMM-dd-yyyy hh:mm:ss a Z')
  }
}
