<div>
  <div class="grid px-6 py-12 items-center justify-center" *ngIf="!tenderQuestionnaireTemplateDto">
    <img src="assets/images/approval-1.svg" class=" h-16 w-16 mx-auto my-0">
    <div class="flex items-center justify-center">
      <div class="text-xl font-medium text-gray-700  mt-6">
        No questionnaire.
      </div>
    </div>
    <div class="flex items-center justify-center">
      <div class="text-xs font-normal text-gray-500 mt-2 mb-6">
        You have not added any questionnaire yet.
      </div>
    </div>
    <div class="flex items-center justify-center">
      <button
        class="inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        (click)="openTenderQuestionnaireDrawer()">
        <i-lucide name="plus" [size]="18" color="#fff" class="my-icon"></i-lucide>
        Add Approval Workflow
      </button>
    </div>
  </div>

  <div *ngIf="tenderQuestionnaireTemplateDto">
    <div class=" col-12 p-4">
      <div class=" bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
        <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
          <div class="grid grid-cols-3 gap-4 w-full">
            <div class="col-span-2 text-gray-500 font-semibold leading-4 tracking-tight text-base">
              <span class="">
                <img src="/assets/icons/tr-basic-detail.svg" class="tr-md-icon inline-flex">
              </span>
              Questionnaire
            </div>
            <div class="text-end  text-sm font-medium leading-4 text-blue-100 tracking-tight ">
              <span class="c-pointer" (click)="openTenderQuestionnaireDrawer()">
                <i-lucide name="pencil" [size]="15" class="c-pointer my-icon inline-flex"></i-lucide>
                Edit
              </span>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full p-4">
          <div class="">
            <div class="grid grid-cols-3 gap-4 w-full">
              <div class=" text-black font-semibold leading-normal text-sm mb-4">
                Scoring
              </div>
              <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
                {{tenderQuestionnaireTemplateDto.scoringTemplate ? 'Yes' : 'No'}}
              </div>
            </div>

            <!-- <div class="grid grid-cols-3 gap-4 w-full">
              <div class=" text-black font-semibold leading-normal text-sm mb-4">
                Gradation system
              </div>
              <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
                {{tenderQuestionnaireTemplateDto.gradationSystem ? 'Yes' : 'No'}}
              </div>
            </div> -->

            <!-- <div class="grid grid-cols-3 gap-4 w-full" *ngIf="tenderQuestionnaireTemplateDto.gradationSystem">
              <div class=" text-black font-semibold leading-normal text-sm mb-4">
                Range
              </div>
              <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
                {{tenderQuestionnaireTemplateDto.range || 0}}
              </div>
            </div> -->

          </div>
          <div class="" *ngIf="this.tenderOpportunityWrapperDto?.tenderOpeningType == 'QCBS'">
            <div class="grid grid-cols-3 gap-4 w-full">
              <div class=" text-black font-semibold leading-normal text-sm mb-4">
                Technical weightage
              </div>
              <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
                {{tenderQuestionnaireTemplateDto.technicalWeightage || 0}}
              </div>
            </div>

            <div class="grid grid-cols-3 gap-4 w-full">
              <div class=" text-black font-semibold leading-normal text-sm mb-4">
                Financial weightage
              </div>
              <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
                {{tenderQuestionnaireTemplateDto.financialWeightage || 0}}
              </div>
            </div>
          </div>

          <div class="" *ngIf="tenderQuestionnaireTemplateDto.scoringTemplate == 'YES'">
            <div class="grid grid-cols-3 gap-4 w-full">
              <div class=" text-black font-semibold leading-normal text-sm mb-4">
                Maximum Score
              </div>
              <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
                {{tenderQuestionnaireTemplateDto.maximumScore || 0}}
              </div>
            </div>

            <div class="grid grid-cols-3 gap-4 w-full">
              <div class=" text-black font-semibold leading-normal text-sm mb-4">
                Qualifying Score
              </div>
              <div class="cols-span-2 text-black font-normal leading-normal text-sm mb-4">
                {{tenderQuestionnaireTemplateDto.qualifyingScore || 0}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="block flex-row items-center p-4" *ngIf="tenderQuestionnaireTemplateDto.gradationSystem">
      <p-table [value]="tenderQuestionnaireTemplateDto.technicalGradationDtos!" styleClass="p-datatable-gridlines"
        [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="header">
          <tr>
            <th>#</th>
            <th>Range</th>
            <th>Marks</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr class="align-middle">
            <td class="">
              {{i+1}}
            </td>
            <td class="">
              {{item.startRange}} - {{item.endRange}}
            </td>
            <td class="">
              {{item.marks}}
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div> -->
  </div>
</div>