<div class="p-2 mt-5">
  <div class="flex flex-row gap-4 w-full items-center justify-between mb-3">
    <div class="">
      <div class="flex w-full">
        <input
          class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0"
          #search id="search" placeholder="Search" [formControl]="ctrlSearchPR">
        <div class="input-group-append">
          <span class="input-group-text border-solid border-slate-50 border-l-0 rounded-l-lg h-8 bg-white"
            id="basic-addon2">
            <i-lucide name="search" [size]="16" class="my-icon c-pointer"></i-lucide>
          </span>
        </div>
      </div>
    </div>
    <div class=" text-right">
      <span *ngIf="isAllowEventCreation">
        <button
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0"
          (click)="openAddRFXModal('PQ')">
          <span class="ms-2">PQ</span>
        </button>
        <button
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0"
          (click)="openAddRFXModal('RFQ')">
          <span class="ms-2">RFQ</span>
        </button>
        <button
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0"
          (click)="openAddAuctionModal()">
          <span class="ms-2">Auction</span>
        </button>
      </span>
      <span *ngIf="type == 'PR'">
        <button
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0"
          (click)="openCreatePrModal()">
          <span class="ms-2">PR Request</span>
        </button>
      </span>
    </div>
  </div>
  <!-- <div class="row align-items-center">
    <div class="col-md-7 text-start mb-2" *ngIf="!isAllowEventCreation"></div>
    <div class="col-md-7 text-start mb-2" *ngIf="isAllowEventCreation">
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0" (click)="openAddRFXModal('PQ')">
        <span class="ms-2">PQ</span>
      </button>
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0" (click)="openAddRFXModal('RFQ')">
        <span class="ms-2">RFQ</span>
      </button>
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0" (click)="openAddAuctionModal()">
        <span class="ms-2">Auction</span>
      </button>
    </div>
    <div class="col-md-2 mb-2 text-md-end" *ngIf="type == 'PR'">
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0" (click)="openCreatePrModal(addPrModal)">
        <span class="ms-2">PR Request</span>
      </button>
    </div>
  </div> -->

  <div class="col-12">
    <div class="table-responsive">
      <table class="table table-bordered medium-font mb-0 align-middle">
        <thead class="table-dark">
          <tr class="text-center">
            <th class=""></th>
            <th class="text-center"><span>#</span></th>
            <th class=""><span>Name</span></th>
            <th class=""><span>Company</span></th>
            <th class=""><span>Plant</span></th>
            <!-- <th>Qty</th> -->
            <th class=""><span>Project Code</span></th>
            <th class=""><span>Status</span></th>
            <th class=""><span>Action</span></th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr *ngIf="isDataLoading" class="align-middle">
            <td colspan="10" class="text-center">
              <div class="e-spinner"></div>
            </td>
          </tr>

          <tr *ngIf="(filteredPurchaseRequestsList$ | async)!.length == 0 && !isDataLoading">
            <td colspan="10" class="text-center">
              <h5 class="heading-new">No Data Found</h5>
            </td>
          </tr>

          <tr class="text-center align-middle"
            *ngFor="let purchaseRequest of (filteredPurchaseRequestsList$ | async); index as i">
            <td class=" text-center">
              <input #checkboxEvent type="checkbox" name="checkboxEvent" id="checkboxEvent"
                (change)="onSelectPurchaseRequest(purchaseRequest, checkboxEvent)">
            </td>
            <td class="">
              {{i + 1 + (pageSize * (page - 1))}}
            </td>
            <td class="">
              <a class="link-class" (click)="openCreatePrModal(purchaseRequest)">
                {{purchaseRequest?.title}}</a>
            </td>
            <td class="">
              {{purchaseRequest.companyCode}}
            </td>
            <td class="">
              {{getPlantName(purchaseRequest.companyCode!, purchaseRequest.plantCode!)}}
            </td>
            <!-- <td class="">
              {{purchaseRequest?.qty}}
            </td> -->
            <td class="">
              {{purchaseRequest?.projectCode}}
            </td>
            <td class="ps-0 ">
              <div class="">
                <span class="ms-2">{{formatPrStatus(purchaseRequest?.status!)}}</span>
              </div>
            </td>
            <td *ngIf="type == 'APPROVAL'">
              <div>
                <button
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex"
                  *ngIf="purchaseRequest.status == 'APPROVAL_PENDING'"
                  (click)="openApprovalWorkflowModal(purchaseRequest)">Approve</button>
                <button
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex"
                  *ngIf="purchaseRequest?.status == 'APPROVAL_APPROVED'"
                  (click)="openApprovalWorkflowModal(purchaseRequest)">
                  View
                </button>
              </div>
            </td>

            <td *ngIf="type != 'APPROVAL'">
              <span>
                <p-menu #menu [model]="tableActionItems" [popup]="true">
                  <ng-template pTemplate="item" let-item>
                    <div class="p-menuitem-link p-3 grid" (click)="approvePurchaseRequest()"
                    *ngIf="purchaseRequest?.status == 'APPROVAL_APPROVED'">
                      <div class="text-gray-900 font-semibold text-sm text-start">
                        Approved
                      </div>
                    </div>
                  </ng-template>
                </p-menu>
                <span class="inline-flex cursor-pointer"  (click)="menu.toggle($event)">
                  <i-lucide name="ellipsis" [size]="18" color="#000" class="my-icon"></i-lucide>
                </span>
              </span>
            
              <!-- <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                <button ngbDropdownToggle data-bs-toggle="dropdown" class="btn-class "><img class="tr-s-icon"
                    src="/assets/icons/elipsis_horizontal_light.svg" /></button>
                <ul ngbDropdownMenu class="shadow mt-4">
                  <li ngbDropdownItem class="c-pointer" (click)="approvePurchaseRequest()" *ngIf="purchaseRequest?.status == 'APPROVAL_APPROVED'">
                    <span>
                      Approved
                    </span>
                  </li>
               

                </ul>
              </div> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-between mt-3" *ngIf="tempPurchaseRequests.length > 0">
      <div class="col">
        <div>
          <p-menu #menuPagination [model]="paginationItems" [popup]="true"></p-menu>
          <button class="btn-class" (click)="menuPagination.toggle($event)">
            {{pageSize}}
          </button>
        </div>
        <!-- <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
          <span class="span-class">Show rows per page </span>
          <span ngbDropdownToggle data-bs-toggle="dropdown">
            <button class="btn btn-sm btn-outline-secondary px-2">{{pageSize}}</button>
          </span>
          <ul ngbDropdownMenu class="text-center shadow">
            <li ngbDropdownItem class="c-pointer" (click)="changePageSize(50)">50</li>
            <li>
              <hr class="dropdown-divider ">
            </li>
            <li ngbDropdownItem class="c-pointer" (click)="changePageSize(100)">100</li>
          </ul>
        </div> -->
      </div>
      <!-- <div class="col-auto">
        <ngb-pagination [collectionSize]="tempPurchaseRequests.length!" [(page)]="page" [pageSize]="pageSize"
          (pageChange)="doPaginate()">
        </ngb-pagination>
      </div> -->
    </div>
  </div>
</div>
<p-dialog header="{{isEdit ? 'Edit' : 'Add'}} Purchase Request" [(visible)]="isCreatePr" [style]="{width: '50vw'}">
  <hr class="hr">
  <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit('DRAFT')">
    <div class=" mb-3">
      <label for="title" class="text-gray-700  text-sm font-semibold leading-4">Title</label>
      <input type="text"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
        id="title" formControlName="title"
        [ngClass]="{'is-invalid': fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)}">

      <div *ngIf="fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)">
        <p class="text-red-500 " *ngIf="fc['title'].errors?.['required']">Required
        </p>
      </div>
    </div>
    <div class=" mb-3">
      <label for="plantCode" class="text-gray-700  text-sm font-semibold leading-4">Plant</label>
      <select id="plantCode"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select"
        formControlName="plantCode"
        [ngClass]="{'is-invalid': fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)}">
        <option value="" disabled>Select . . .</option>
        <option [value]="item.plantCode" *ngFor="let item of plantUiDtosForForm">
          {{item.plantName}}-({{item.plantCode}})
        </option>
      </select>
      <div *ngIf="fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)">
        <p class="text-red-500 " *ngIf="fc['plantCode'].errors?.['required']">Required</p>
      </div>
    </div>
    <div class=" mb-3">
      <label for="costCenterCode" class="text-gray-700  text-sm font-semibold leading-4">Cost Center</label>
      <select
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select"
        formControlName="costCenterCode" id="costCenterCode"
        [ngClass]="{'is-invalid': fc['costCenterCode'].invalid && (fc['costCenterCode'].dirty || fc['costCenterCode'].touched)}">
        <option value="" disabled>Select . . .</option>
        <option [value]="item.code" *ngFor="let item of costCenters">{{item.name}}</option>
      </select>
      <div *ngIf="fc['costCenterCode'].invalid && (fc['costCenterCode'].dirty || fc['costCenterCode'].touched)">
        <p class="text-red-500 " *ngIf="fc['costCenterCode'].errors?.['required']">Required</p>
      </div>
    </div>
    <div class="flex flex-row gap-4 w-full items-center justify-between">
      <div class=" mb-3">
        <label for="expectedPrice" class="text-gray-700  text-sm font-semibold leading-4">Expected Price</label>
        <div class="">
          <input type="text"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select"
            id="expectedPrice" formControlName="expectedPrice"
            [ngClass]="{'is-invalid': fc['expectedPrice'].invalid && (fc['expectedPrice'].dirty || fc['expectedPrice'].touched)}">
          <div *ngIf="fc['expectedPrice'].invalid && (fc['expectedPrice'].dirty || fc['expectedPrice'].touched)">
            <p class="text-red-500 " *ngIf="invalidPriceValidator(fc['expectedPrice'])">Invalid</p>
          </div>
        </div>
      </div>
      <div class=" mb-3">
        <label for="leadTime" class="text-gray-700  text-sm font-semibold leading-4">Lead Time(in days)</label>
        <div class="">
          <input type="text"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select"
            id="leadTime" formControlName="leadTime"
            [ngClass]="{'is-invalid': fc['leadTime'].invalid && (fc['leadTime'].dirty || fc['leadTime'].touched)}">
          <div *ngIf="fc['leadTime'].invalid && (fc['leadTime'].dirty || fc['leadTime'].touched)">
            <p class="text-red-500 " *ngIf="fc['leadTime'].errors?.['required']">Required</p>
            <p class="text-red-500 " *ngIf="invalidPriceValidator(fc['leadTime'])">Invalid</p>
          </div>
        </div>
      </div>
      <div class=" mb-3 ">
        <label for="estimatedDeliveryDate" class="text-gray-700  text-sm font-semibold leading-4">Estimated Delivery
          Date</label>
        <div class="flex w-full">
          <div class="input-group-append">
            <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
              id="basic-addon2"
              [ngClass]="{'border-danger': fc['estimatedDeliveryDate'].invalid && (fc['estimatedDeliveryDate'].dirty || fc['estimatedDeliveryDate'].touched)}">
              <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
            </span>
          </div>
          <input type="text" ngbDatepicker [minDate]="minDate" #estimatedDeliveryDate="ngbDatepicker"
            (click)="estimatedDeliveryDate.toggle()"
            class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-start-0"
            formControlName="estimatedDeliveryDate" id="estimatedDeliveryDate"
            [ngClass]="{'is-invalid': fc['estimatedDeliveryDate'].invalid && (fc['estimatedDeliveryDate'].dirty || fc['estimatedDeliveryDate'].touched)}">
        </div>


        <div
          *ngIf="fc['estimatedDeliveryDate'].invalid && (fc['estimatedDeliveryDate'].dirty || fc['estimatedDeliveryDate'].touched)">
          <p class="text-red-500 " *ngIf="fc['estimatedDeliveryDate'].errors?.['required']">Required
          </p>
        </div>
      </div>
    </div>
    <label for="description" class="text-gray-700  text-sm font-semibold leading-4">Description</label>
    <div class="mb-3">
      <textarea type="text"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-16 px-2 py-0 text-gray-100 text-sm form-select form-select"
        id="description" formControlName="description" rows="6"
        [ngClass]="{'is-invalid': fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)}"></textarea>
      <div *ngIf="fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)">
        <p class="text-red-500 " *ngIf="fc['description'].errors?.['required']">Required
        </p>
      </div>
    </div>
    <div class="flex flex-row gap-4 w-full items-center justify-between mb-3">
      <div class="">
        <label for="addExternalPr" class="text-gray-700  text-sm font-semibold leading-4"
          *ngIf="!(currentFile || currentFileInfoDto)">Additional Document</label>
        <label for="addExternalPr" class="text-gray-700  text-sm font-semibold leading-4"
          *ngIf="currentFile || currentFileInfoDto">Additional Document</label>
        <div class="">
          <input type="text" (click)="documentDoc.click()" [value]="_fileDisplayName$ |async"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select"
            id="addExternalPr"
            [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)}" />
          <input #documentDoc onclick="this.value=null;" type="file" (change)="chooseFile($event)" class="hidden" />
          <div>
            <p class="text-red-500 " *ngIf="(_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)">
              Required
            </p>
          </div>
        </div>
      </div>
      <div class="" *ngIf="currentFileInfoDto || currentFile">
        <span class="ms-3 inline-flex">
          <button
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-2"
            *ngIf="currentFileInfoDto" (click)="previewAuctionDoc('SERVER')"
            ngbTooltip="{{currentFileInfoDto.displayName}}">
            <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class">
          </button>
          <button
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-2"
            *ngIf="currentFile && !currentFileInfoDto" (click)="previewAuctionDoc('LOCAL')">
            <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class ">
          </button>
        </span>
        <span class="inline-flex" [ngClass]="{'disabled':_isSaveButtonDisable$ |async}" (click)="removeAuctionDoc()">
          <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
        </span>
      </div>
    </div>
    <hr class="hr my-2">
    <div class="col-12 mb-3">
      <label for="description" class="text-gray-700  text-sm font-semibold leading-4">Select Material</label>
      <div  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select form-select">
        <p-multiSelect [options]="materialUiDtos" formControlName="materialUiDtos"
          (onChange)="onSelectMaterialUiDtos($event)" defaultLabel="Select Materials" optionLabel="materialName"
          optionValue="materialId" display="chip" [disabled]="isLoading">
        </p-multiSelect>
      </div>
    </div>
  </form>
  <div class="col-12 mb-3">
    <label class="mb-1" for="safetyLevels" class="text-gray-700  text-sm font-semibold leading-4">Selected
      Materials</label>
    <div class="card">
      <p-table [value]="selectedMaterialUiDtos" dataKey="id" [tableStyle]="{ 'min-width': '50rem' }"
        [loading]="isLoading">
        <ng-template pTemplate="header">
          <tr>
            <th style="width:15%">#</th>
            <th style="width:20%">Material Id</th>
            <th style="width:20%">Material Name</th>
            <th style="width:20%">UoM</th>
            <th style="width:15%">Quantity</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-material let-editing="editing" let-i="rowIndex">
          <tr>
            <td>{{ i+1 }}</td>
            <td>{{ material.materialId }}</td>
            <td>{{ material.materialName }}</td>
            <td>{{ material.uom }}</td>
            <td [pEditableColumn]="material.qty" pEditableColumnField="qty">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText pKeyFilter="int" [(ngModel)]="material.qty" />
                </ng-template>
                <ng-template pTemplate="output">
                  {{ material.qty }}
                </ng-template>
              </p-cellEditor>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="my-3">
    <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async">
    </app-message-toast>
    <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="'Project created successfully.'">
    </app-message-toast>
  </div>
  <div class="grid gap-2 mb-3" *ngIf="isLoading">
    <div class="e-spinner"></div>
  </div>
  <div class="col-12 text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
    <button [disabled]="isDraftAndSubmitButtonDisable" *ngIf="isEdit" type="button"
      class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 me-3"
      (click)="handleValidSubmit('DRAFT')">
      Save as Draft
    </button>
    <button [disabled]="isDraftAndSubmitButtonDisable" type="button"
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      (click)="handleValidSubmit('SUBMITTED')">
      {{isEdit ? 'Submit' : 'Add'}}
    </button>
  </div>
</p-dialog>

<!-- <ng-template #addPrModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>{{isEdit ? 'EDIT' : 'ADD'}} PURCHASE REQUEST</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit('DRAFT')">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="title" formControlName="title"
              [ngClass]="{'is-invalid': fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)}">
            <label for="title">Title</label>
            <div *ngIf="fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)">
              <p class="text-red-500 " *ngIf="fc['title'].errors?.['required']">Required
              </p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select id="plantCode" class="form-control form-select" formControlName="plantCode"
              [ngClass]="{'is-invalid': fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="item.plantCode" *ngFor="let item of plantUiDtosForForm">
                {{item.plantName}}-({{item.plantCode}})
              </option>
            </select>

            <label for="plantCode">Plant</label>
            <div *ngIf="fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)">
              <p class="text-red-500 " *ngIf="fc['plantCode'].errors?.['required']">Required</p>
            </div>
          </div>

          <div class="form-floating mb-3">
            <select class="form-control form-select" formControlName="costCenterCode" id="costCenterCode"
              [ngClass]="{'is-invalid': fc['costCenterCode'].invalid && (fc['costCenterCode'].dirty || fc['costCenterCode'].touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="item.code" *ngFor="let item of costCenters">{{item.name}}</option>
            </select>

            <label for="costCenterCode">Cost Center</label>
            <div *ngIf="fc['costCenterCode'].invalid && (fc['costCenterCode'].dirty || fc['costCenterCode'].touched)">
              <p class="text-red-500 " *ngIf="fc['costCenterCode'].errors?.['required']">Required</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" id="expectedPrice" formControlName="expectedPrice"
                  [ngClass]="{'is-invalid': fc['expectedPrice'].invalid && (fc['expectedPrice'].dirty || fc['expectedPrice'].touched)}">
                <label for="expectedPrice">Expected Price</label>
                <div *ngIf="fc['expectedPrice'].invalid && (fc['expectedPrice'].dirty || fc['expectedPrice'].touched)">
                  <p class="text-red-500 " *ngIf="invalidPriceValidator(fc['expectedPrice'])">Invalid</p>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" id="leadTime" formControlName="leadTime"
                  [ngClass]="{'is-invalid': fc['leadTime'].invalid && (fc['leadTime'].dirty || fc['leadTime'].touched)}">
                <label for="leadTime">Lead Time(in days)</label>
                <div *ngIf="fc['leadTime'].invalid && (fc['leadTime'].dirty || fc['leadTime'].touched)">
                  <p class="text-red-500 " *ngIf="fc['leadTime'].errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="invalidPriceValidator(fc['leadTime'])">Invalid</p>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3 ">
              <div class="input-group mb-md-0">
                <span class="border border-end-0 pt-3 px-1"
                  [ngClass]="{'border-danger': fc['estimatedDeliveryDate'].invalid && (fc['estimatedDeliveryDate'].dirty || fc['estimatedDeliveryDate'].touched)}">
                  <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
                </span>

                <div class="form-floating">
                  <input type="text" ngbDatepicker [minDate]="minDate" #estimatedDeliveryDate="ngbDatepicker"
                    (click)="estimatedDeliveryDate.toggle()" class="form-control border-start-0"
                    placeholder="Start Date" formControlName="estimatedDeliveryDate" id="estimatedDeliveryDate"
                    [ngClass]="{'is-invalid': fc['estimatedDeliveryDate'].invalid && (fc['estimatedDeliveryDate'].dirty || fc['estimatedDeliveryDate'].touched)}">
                  <label for="estimatedDeliveryDate" class="">Estimated Delivery Date</label>
                </div>
              </div>
              <div
                *ngIf="isSubmitted || fc['estimatedDeliveryDate'].invalid && (fc['estimatedDeliveryDate'].dirty || fc['estimatedDeliveryDate'].touched)">
                <p class="text-red-500 " *ngIf="fc['estimatedDeliveryDate'].errors?.['required']">Required
                </p>
              </div>
            </div>
          </div>

          <label for="description">Description</label>
          <div class="mb-3">
            <textarea type="text" class="form-control" id="description" formControlName="description" rows="3"
              [ngClass]="{'is-invalid': fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)}"></textarea>
            <div *ngIf="fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)">
              <p class="text-red-500 " *ngIf="fc['description'].errors?.['required']">Required
              </p>
            </div>
          </div>

          <div class="row align-items-center mb-3">
            <div class="col-6">
              <div class="form-floating">
                <input type="text" (click)="documentDoc.click()" [value]="_fileDisplayName$ |async" class="form-control"
                  id="addExternalPr"
                  [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)}" />
                <input #documentDoc onclick="this.value=null;" type="file" (change)="chooseFile($event)"
                  class="d-none" />
                <label for="addExternalPr" *ngIf="!(currentFile || currentFileInfoDto)">Additional Document</label>
                <label for="addExternalPr" *ngIf="currentFile || currentFileInfoDto">Additional Document</label>
                <div>
                  <p class="text-red-500 " *ngIf="(_validationErrorPresent$ |async) && !(_fileDisplayName$ |async)">
                    Required
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6" *ngIf="currentFileInfoDto || currentFile">
              <div class="ms-3">
                <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-2" *ngIf="currentFileInfoDto"
                  (click)="previewAuctionDoc('SERVER')" ngbTooltip="{{currentFileInfoDto.displayName}}">
                  <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class">
                </button>
                <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-2" *ngIf="currentFile && !currentFileInfoDto"
                  (click)="previewAuctionDoc('LOCAL')">
                  <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class ">
                </button>
                <span [ngClass]="{'disabled':_isSaveButtonDisable$ |async}" (click)="removeAuctionDoc()">
                  <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer">
                </span>
              </div>
            </div>
          </div>

          <hr class="hr my-2">

          <div class="col-12 mb-3">
            <label for="description">Select Material</label>
            <div class="form-floating">
              <p-multiSelect [options]="materialUiDtos" formControlName="materialUiDtos"
                (onChange)="onSelectMaterialUiDtos($event)" defaultLabel="Select Materials" optionLabel="materialName"
                optionValue="materialId" display="chip" [disabled]="isLoading">
              </p-multiSelect>
            </div>
          </div>
        </form>

        <div class="col-12 mb-3">
          <label class="mb-1" for="safetyLevels">Selected Materials</label>
          <div class="card">
            <p-table [value]="selectedMaterialUiDtos" dataKey="id" [tableStyle]="{ 'min-width': '50rem' }"
              [loading]="isLoading">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width:15%">#</th>
                  <th style="width:20%">Material Id</th>
                  <th style="width:20%">Material Name</th>
                  <th style="width:20%">UoM</th>
                  <th style="width:15%">Quantity</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-material let-editing="editing" let-i="rowIndex">
                <tr>
                  <td>{{ i+1 }}</td>
                  <td>{{ material.materialId }}</td>
                  <td>{{ material.materialName }}</td>
                  <td>{{ material.uom }}</td>
                  <td [pEditableColumn]="material.qty" pEditableColumnField="qty">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText pKeyFilter="int" [(ngModel)]="material.qty" />
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ material.qty }}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async">
          </app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'Project created successfully.'">
          </app-message-toast>
        </div>

        <div class="col-12 text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button [disabled]="isDraftAndSubmitButtonDisable" *ngIf="isEdit" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800me-3"
            (click)="handleValidSubmit('DRAFT')">
            SAVE AS DRAFT
          </button>
          <button [disabled]="isDraftAndSubmitButtonDisable" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800me-3"
            (click)="handleValidSubmit('SUBMITTED')">
            {{isEdit ? 'SUBMIT' : 'ADD'}}
          </button>

        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

      </div>
    </div>
  </div>
</ng-template> -->

<p-dialog header="Purchase Request Approval Confirmation" [(visible)]="isPurchaseRequestApproval"
  [style]="{width: '50vw'}">
  <div class="m-3">
    <p>Are you sure you want to {{approveRequest == 'APPROVE' ? 'approve' : 'reject' }}?</p>
    <div class="d-grid gap-2 mb-3" *ngIf="isApprovalLoading">
      <div class="e-spinner"></div>
    </div>
    <div class="my-3">
      <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async">
      </app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false" [message]="'PR approved successfully.'">
      </app-message-toast>
    </div>

    <div class="col-12 text-end mt-3" *ngIf="!isApprovalLoading && !(_showSuccessToast$ | async)">
      <button
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class"
        (click)="approvePurchaseRequest()">OK</button>
    </div>
  </div>
</p-dialog>

<!-- <ng-template #approveConfirmModal>
  <div class="model-body">
    
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>Are you sure you want to {{approveRequest == 'APPROVE' ? 'approve' : 'reject' }}?</p>
        <div class="d-grid gap-2 mb-3" *ngIf="isApprovalLoading">
          <div class="e-spinner"></div>
        </div>
        <div class="my-3">
          <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async">
          </app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'PR approved successfully.'">
          </app-message-toast>
        </div>

        <div class="col-12 text-end mt-3" *ngIf="!isApprovalLoading && !(_showSuccessToast$ | async)">
          <button
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class"
            (click)="approvePurchaseRequest()">OK</button>
        </div>
      </div>

    </div>
  </div>
</ng-template> -->


<p-dialog header="Purchase Request Approval" [(visible)]="isApprovalWorkflow" [style]="{width: '50vw'}">
  <div class="m-3">
    <div class="grid grid-cols-2 md:grid-cols-4 gap-4 w-full items-center justify-between">
      <div class=" mb-2">
        {{selectedPurchaseRequestUiDto?.orgCode}}
        <div class="text-xs font-normal">
          Organization Code
        </div>
      </div>
      <div class=" mb-2">
        <span *ngIf="!selectedPurchaseRequestUiDto?.projectCode">NA</span>
        <span *ngIf="selectedPurchaseRequestUiDto?.projectCode">{{selectedPurchaseRequestUiDto?.projectCode}}</span>
        <div class="text-xs font-normal">
          Project Code
        </div>
      </div>
      <div class=" mb-2">
        {{selectedPurchaseRequestUiDto?.companyCode}}
        <div class="text-xs font-normal">
          Company Code
        </div>
      </div>
      <div class=" mb-2">
        {{selectedPurchaseRequestUiDto?.plantCode}}
        <div class="text-xs font-normal">
          Plant Code
        </div>
      </div>
      <div class=" mb-2">
        {{selectedPurchaseRequestUiDto?.costCenterCode}}
        <div class="text-xs font-normal">
          Cost Center Code
        </div>
      </div>
      <div class=" mb-2">
        {{selectedPurchaseRequestUiDto?.prCode}}
        <div class="text-xs font-normal">
          PR Code
        </div>
      </div>
      <div class=" mb-2">
        {{selectedPurchaseRequestUiDto?.title}}
        <div class="text-xs font-normal">
          Title
        </div>
      </div>
      <div class=" mb-2 overflow-class-small">
        {{selectedPurchaseRequestUiDto?.description}}
        <div class="text-xs font-normal">
          Description
        </div>
      </div>
      <div class=" mb-2">
        {{selectedPurchaseRequestUiDto?.expectedPrice}}
        <div class="text-xs font-normal">
          Expected Price
        </div>
      </div>
      <div class=" mb-2">
        {{selectedPurchaseRequestUiDto?.leadTime}}
        <div class="text-xs font-normal">
          Lead Time (in days)
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-3 gap-4 w-full items-center justify-between"
        *ngIf="!isLoading && appWorkflowUiDtoList.length > 0">
        <div class="">
          <div class="card bg-white ">
            <div class="py-2 bg-grey1 outline-border">
              <div class=" px-2">
            
                  <b>
                    Project Executive - Sourcing
                  </b>
                
              </div>
              <hr class="hr my-1">
            </div>
            <div class="p-2">
              <div class="card p-2 bg-white">
                <div class="grid grid-cols-2 md:grid-cols-12 gap-4 w-full items-center justify-between">
                  <div class="md:col-span-8 div-class">
                    {{appWorkflowUiDtoList[0].name}}<br>
                    {{appWorkflowUiDtoList[0].emailId}}
                  </div>
                  <div class="md:col-span-4 text-end">
                    <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[0].status)">
                      {{appWorkflowUiDtoList[0].status | titlecase}}
                    </span>
                    <div class="text-xs font-normal">
                      {{appWorkflowUiDtoList[0].approvedDate}}
                    </div>
                  </div>
                  <div class="overflow-class-small text-xs font-normal">
                    {{appWorkflowUiDtoList[0].comment}}
                  </div>
                </div>

                <div class="col-12 mt-3" *ngIf="isShowApprovedButton(0)">
                  <label for="ctrlComments">Comments</label>
                  <div class="mb-3">
                    <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments" rows="3">
                    </textarea>
                  </div>
                </div>

                <div class="col-12 text-end" *ngIf="isShowApprovedButton(0)">
                  <a class="text-red-500  me-3 c-pointer" (click)="openPrApprovalConfirmModal('REJECT')">Reject</a>
                  <a class="text-green-500  c-pointer" (click)="openPrApprovalConfirmModal('APPROVE')">Approve</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="card bg-white ">
            <div class="py-2 bg-grey1 outline-border">
              <div class="row px-2">
                <div class="">
                  <b> Project Manager</b>
                </div>
              </div>
              <hr class="hr my-1">
            </div>
            <div class="p-2">
              <div class="card p-2 bg-white">
                <div class="row">
                  <div class="col-md-8 div-class">
                    {{appWorkflowUiDtoList[1].name}}<br>
                    {{appWorkflowUiDtoList[1].emailId}}
                  </div>
                  <div class="col-md-4 text-end">
                    <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[1].status)">
                      {{appWorkflowUiDtoList[1].status | titlecase}}
                    </span>
                    <div class="text-xs font-normal">
                      {{appWorkflowUiDtoList[1].approvedDate}}
                    </div>
                  </div>
                  <div class="overflow-class-small text-xs font-normal" *ngIf="appWorkflowUiDtoList[1].comment">
                    {{appWorkflowUiDtoList[1].comment}}
                  </div>
                </div>
                <div class="col-12 mt-3" *ngIf="isShowApprovedButton(1)">
                  <label for="ctrlComments">Comments</label>
                  <div class="mb-3">
                    <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments" rows="3">
                    </textarea>
                  </div>
                </div>

                <div class="col-12 text-end" *ngIf="isShowApprovedButton(1)">
                  <a class="text-red-500  me-3 c-pointer" (click)="openPrApprovalConfirmModal('REJECT')">Reject</a>
                  <a class="text-green-500  c-pointer" (click)="openPrApprovalConfirmModal('APPROVE')">Approve</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="card bg-white ">
            <div class="py-2 bg-grey1">
              <div class="px-2">
                
                  <b>
                    Chief Procurement Officer
                  </b>
                
              </div>
              <hr class="hr my-1">
            </div>
            <div class="p-2">
              <div class="card p-2 bg-white">
                <div class="grid grid-cols-2 md:grid-cols-12 gap-4 w-full items-center justify-between">
                  <div class="md:col-span-8 div-class">
                    {{appWorkflowUiDtoList[2].name}}<br>
                    {{appWorkflowUiDtoList[2].emailId}}
                  </div>
                  <div class="md:col-span-4 text-end">
                    <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[2].status)">
                      {{appWorkflowUiDtoList[2].status | titlecase}}
                    </span>
                    <div class="text-xs font-normal">
                      {{appWorkflowUiDtoList[2].approvedDate}}
                    </div>
                  </div>
                  <div class="overflow-class-small text-xs font-normal" *ngIf="appWorkflowUiDtoList[2].comment">
                    {{appWorkflowUiDtoList[2].comment}}
                  </div>
                </div>
                <div class="col-12 mt-3" *ngIf="isShowApprovedButton(2)">
                  <label for="ctrlComments">Comments</label>
                  <div class="mb-3">
                    <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments" rows="3">
                    </textarea>
                  </div>
                </div>

                <div class="col-12 text-end" *ngIf="isShowApprovedButton(2)">
                  <a class="text-red-500  me-3 c-pointer" (click)="openPrApprovalConfirmModal('REJECT')">Reject</a>
                  <a class="text-green-500  c-pointer" (click)="openPrApprovalConfirmModal('APPROVE')">Approve</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
<!-- <ng-template #approvalWorkflowTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Purchase Request Approval</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="row">
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.orgCode}}
            <div class="text-xs font-normal">
              Organization Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            <span *ngIf="!selectedPurchaseRequestUiDto?.projectCode">NA</span>
            <span *ngIf="selectedPurchaseRequestUiDto?.projectCode">{{selectedPurchaseRequestUiDto?.projectCode}}</span>
            <div class="text-xs font-normal">
              Project Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.companyCode}}
            <div class="text-xs font-normal">
              Company Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.plantCode}}
            <div class="text-xs font-normal">
              Plant Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.costCenterCode}}
            <div class="text-xs font-normal">
              Cost Center Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.prCode}}
            <div class="text-xs font-normal">
              PR Code
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.title}}
            <div class="text-xs font-normal">
              title
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.description}}
            <div class="text-xs font-normal">
              description
            </div>
          </div>
          <!-- <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.preferenceCategoryDto?.categoryLevelFourName}}
            <div class="text-xs font-normal">
              Material Name
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.uom}}
            <div class="text-xs font-normal">
              UoM
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.qty}}
            <div class="text-xs font-normal">
              Quantity
            </div>
          </div> 
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.expectedPrice}}
            <div class="text-xs font-normal">
              Expected Price
            </div>
          </div>
          <div class="col-6 col-md-3 mb-2">
            {{selectedPurchaseRequestUiDto?.leadTime}}
            <div class="text-xs font-normal">
              Lead Time (in days)
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
          <div class="row" *ngIf="!isLoading && appWorkflowUiDtoList.length > 0">
            <div class="col-md-4">
              <div class="card bg-white ">
                <div class="py-2 bg-grey1 outline-border">
                  <div class="row px-2">
                    <div class="">
                      <b>
                        Project Executive - Sourcing
                      </b>
                    </div>
                  </div>
                  <hr class="hr my-1">
                  <!-- <div class="row px-2">
                    <div class="col-8">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                    </div>
                    <div class="col-4 text-end">
                      <img src="assets/icons/addnew_light.svg" class=" c-pointer svg-icon-small ">
                    </div>
                  </div> 
                </div>
                <div class="p-2">
                  <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-8 div-class">
                        {{appWorkflowUiDtoList[0].name}}<br>
                        {{appWorkflowUiDtoList[0].emailId}}
                      </div>
                      <div class="col-md-4 text-end">
                        <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[0].status)">
                          {{appWorkflowUiDtoList[0].status | titlecase}}
                        </span>
                        <div class="text-xs font-normal">
                          {{appWorkflowUiDtoList[0].approvedDate}}
                        </div>
                      </div>
                      <div class="overflow-class-small text-xs font-normal">
                        {{appWorkflowUiDtoList[0].comment}}
                      </div>
                    </div>

                    <div class="col-12 mt-3" *ngIf="isShowApprovedButton(0)">
                      <label for="ctrlComments">Comments</label>
                      <div class="mb-3">
                        <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments"
                          rows="3">
                        </textarea>
                      </div>
                    </div>

                    <div class="col-12 text-end" *ngIf="isShowApprovedButton(0)">
                      <a class="text-red-500  me-3 c-pointer"
                        (click)="openPrApprovalConfirmModal('REJECT')">Reject</a>
                      <a class="text-green-500  c-pointer"
                        (click)="openPrApprovalConfirmModal('APPROVE')">Approve</a>
                    </div>
                  </div>
                  <!-- <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-6 div-class">
                        Akshay Raj
                      </div>
                      <div class="col-md-6 text-end">
                        <span class="text-orange-500 ">Pending</span>
                        <div class="text-xs font-normal">
                          12/11/24
                        </div>
                      </div>
                      <div class="overflow-class-small text-xs font-normal">
                        Comments
                      </div>
                    </div>
                  </div> -->
<!-- <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-6 div-class">
                        Prashant Gupta
                      </div>
                      <div class="col-md-6 text-end">
                        <span class="text-red-500 "> Rejected</span>
                        <div class="text-xs font-normal">
                          12/11/24
                        </div>
                      </div>
                      <div class="overflow-class-small text-xs font-normal">
                        Comments
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card bg-white ">
                <div class="py-2 bg-grey1 outline-border">
                  <div class="row px-2">
                    <div class="">
                      <b> Project Manager</b>
                    </div>
                  </div>
                  <hr class="hr my-1">
                  <!-- <div class="row px-2">
                    <div class="col-8">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                    </div>
                    <div class="col-4 text-end">
                      <img src="assets/icons/addnew_light.svg" class=" c-pointer svg-icon-small ">

                    </div>
                  </div> 
                </div>
                <div class="p-2">
                  <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-8 div-class">
                        {{appWorkflowUiDtoList[1].name}}<br>
                        {{appWorkflowUiDtoList[1].emailId}}
                      </div>
                      <div class="col-md-4 text-end">
                        <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[1].status)">
                          {{appWorkflowUiDtoList[1].status | titlecase}}
                        </span>
                        <div class="text-xs font-normal">
                          {{appWorkflowUiDtoList[1].approvedDate}}
                        </div>
                      </div>
                      <div class="overflow-class-small text-xs font-normal" *ngIf="appWorkflowUiDtoList[1].comment">
                        {{appWorkflowUiDtoList[1].comment}}
                      </div>
                    </div>
                    <div class="col-12 mt-3" *ngIf="isShowApprovedButton(1)">
                      <label for="ctrlComments">Comments</label>
                      <div class="mb-3">
                        <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments"
                          rows="3">
                        </textarea>
                      </div>
                    </div>

                    <div class="col-12 text-end" *ngIf="isShowApprovedButton(1)">
                      <a class="text-red-500  me-3 c-pointer"
                        (click)="openPrApprovalConfirmModal('REJECT')">Reject</a>
                      <a class="text-green-500  c-pointer"
                        (click)="openPrApprovalConfirmModal('APPROVE')">Approve</a>
                    </div>
                  </div>
                  <!-- <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-6 div-class">
                        Akshay Raj
                      </div>
                      <div class="col-md-6 text-end">
                        <span class="text-orange-500 ">Pending</span>
                        <div class="text-xs font-normal">
                          12/11/24
                        </div>
                      </div>
                      <div class="overflow-class-small text-xs font-normal">
                        Comments
                      </div>
                    </div>
                  </div> -->
<!-- <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-6 div-class">
                        Prashant Gupta
                      </div>
                      <div class="col-md-6 text-end">
                        <span class="text-red-500 "> Rejected</span>
                        <div class="text-xs font-normal">
                          12/11/24
                        </div>
                      </div>
                      <div class="overflow-class-small text-xs font-normal">
                        Comments
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card bg-white ">
                <div class="py-2 bg-grey1">
                  <div class="row px-2">
                    <div class="">
                      <b>
                        Chief Procurement Officer
                      </b>
                    </div>
                  </div>
                  <hr class="hr my-1">
                  <!-- <div class="row px-2">
                    <div class="col-8">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                      <img src="assets/images/Background Image.png" class="profile-image-xsm  mt-0">
                    </div>
                    <div class="col-4 text-end">
                      <img src="assets/icons/addnew_light.svg" class=" c-pointer svg-icon-small ">
                      <img src="assets/icons/edit_light.svg" class=" c-pointer svg-icon-small">
                    </div>
                  </div> 
                </div>
                <div class="p-2">
                  <div class="card p-2 bg-white">
                    <div class="row">
                      <div class="col-md-8 div-class">
                        {{appWorkflowUiDtoList[2].name}}<br>
                        {{appWorkflowUiDtoList[2].emailId}}
                      </div>
                      <div class="col-md-4 text-end">
                        <span [ngClass]="getApprovalWorkflowStatusClass(appWorkflowUiDtoList[2].status)">
                          {{appWorkflowUiDtoList[2].status | titlecase}}
                        </span>
                        <div class="text-xs font-normal">
                          {{appWorkflowUiDtoList[2].approvedDate}}
                        </div>
                      </div>
                      <div class="overflow-class-small text-xs font-normal" *ngIf="appWorkflowUiDtoList[2].comment">
                        {{appWorkflowUiDtoList[2].comment}}
                      </div>
                    </div>
                    <div class="col-12 mt-3" *ngIf="isShowApprovedButton(2)">
                      <label for="ctrlComments">Comments</label>
                      <div class="mb-3">
                        <textarea type="text" class="form-control" id="ctrlComments" [formControl]="ctrlComments"
                          rows="3">
                        </textarea>
                      </div>
                    </div>

                    <div class="col-12 text-end" *ngIf="isShowApprovedButton(2)">
                      <a class="text-red-500  me-3 c-pointer"
                        (click)="openPrApprovalConfirmModal('REJECT')">Reject</a>
                      <a class="text-green-500  c-pointer"
                        (click)="openPrApprovalConfirmModal('APPROVE')">Approve</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->