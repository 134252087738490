<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between align-items-center">

      <div class="col-auto text-end mt-2">
        <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex  me-2" (click)="addNewLotRow()">
          <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-small me-1">ADD
        </button>
      </div>
      <div class="col-auto text-end mt-2">
        <!-- <input #searchValue type="text" class="form-control" (change)="filterLotList(searchValue.value)"
          (keyup)="filterLotList(searchValue.value)" placeholder="Search by keywords" /> -->

        <div class=" input-group mb-2 mb-md-0">
          <input #searchValue type="text" class="form-control font-weight-normal" (change)="filterLotList(searchValue.value)"
            (keyup)="filterLotList(searchValue.value)" placeholder="Search by keywords" id="search" />
          <span class="input-group-append">
            <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button">
              <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
            </button>
          </span>
        </div>
      </div>
    </div>
  </header>
  <!-- <div class="page-divider"></div> -->
  <div class="model-body-top">
    <div class="p-3">
      <div class="col-12">
        <div class="table-responsive" style="height: 500px;">
          <table class="table table-bordered medium-font  mb-0">
            <thead class="table-dark">
              <tr>
                <th class="text-center "><span >#</span></th>
                <th class=" "><span >Lot Details</span></th>
                <th class=" "><span >Bid Settings</span></th>
                <th class=" "><span >Lot Attributes</span></th>
                <th class="text-center "><span >Status</span></th>
                <th class="  text-center"><span >Action</span></th>
              </tr>
            </thead>
            <tbody class="bg-white">

              <tr *ngFor="let item of (_filterLotList$ | async); index as i;" class="c-pointer">
                <td class="mt-2" (click)="openEditLotDetailsModal(item, $event)">{{i+1}}</td>
                <td class=" p-3" (click)="openEditLotDetailsModal(item, $event)">
                  LotName <span class="font-weight-normal float-end" ngbTooltip="{{item?.title}}" container="body"
                    placement="bottom">{{item?.lotSequence}}# {{getTruncate(item?.title!)}}
                  </span><br>
                  <hr class="hr my-1" />
                  Description <ng-container [ngTemplateOutlet]="templatePop"
                    [ngTemplateOutletContext]="{item:item, title: 'Description'}"></ng-container><br>
                  <hr class="hr my-1" />
                  Ending On <span class="font-weight-normal float-end">{{getDisplayDate(item?.endDate,
                    item?.endTime)}}</span>
                </td>
                <td class=" p-3" (click)="openEditLotDetailsModal(item, $event)">
                  Starting bid <span
                    class="font-weight-normal float-end">{{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(item?.startingBid)}}
                  </span> <br>
                  <hr class="hr my-1" />
                  Reserve price <span
                    class="font-weight-normal float-end">{{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(item?.reservePrice)}}
                  </span><br>
                  <hr class="hr my-1" />
                  Est. <span
                    class="font-weight-normal float-end">{{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(item?.estStartingPrice)}}
                    - {{auctionEntityDto?.currency?.symbol}}{{getFormattedPrice(item?.estEndingPrice)}}
                  </span><br>
                  <hr class="hr my-1" />
                  Incremental table <span
                    class="font-weight-normal float-end">{{getBidIncrementValidation(item?.incrementalWrapperDto)}}</span>

                  <div *ngIf="isShowWarnAndBlock">
                    <div *ngIf="item.warnBid">
                      <hr class="hr my-1" />
                      Warning Bid Value <span class="font-weight-normal float-end">{{item?.warnBidValue}}</span>
                    </div>

                    <div *ngIf="item.blockBid">
                      <hr class="hr my-1" />
                      Block Bid Value <span class="font-weight-normal float-end">{{item?.blockBidValue}}</span>
                    </div>
                  </div>
                </td>
                <td class=" p-3" (click)="openEditLotDetailsModal(item, $event)">
                  Category
                  <span class="font-weight-normal float-end" container="body" placement="bottom"
                  ngbTooltip="{{item.preferenceCategory?.categoryName}}">{{getTruncate(item.preferenceCategory?.categoryName)}}

                  </span>
                  <br>
                  <hr class="hr my-1" />
                  Sub Category 1
                  <span class="font-weight-normal float-end" container="body" placement="bottom"
                    ngbTooltip="{{item.preferenceCategory?.categoryLevelOneName}}">{{getTruncate(item.preferenceCategory?.categoryLevelOneName)}}</span><br>

                  <hr class="hr my-1" />
                  Sub Category 2
                  <span class="font-weight-normal float-end" container="body" placement="bottom"
                    ngbTooltip="{{item.preferenceCategory?.categoryLevelTwoName}}">{{getTruncate(item.preferenceCategory?.categoryLevelTwoName)}}</span><br>

                  <hr class="hr my-1" />
                  Sub Category 3
                  <span class="font-weight-normal float-end" container="body" placement="bottom"
                    ngbTooltip="{{item.preferenceCategory?.categoryLevelThreeName}}">{{getTruncate(item.preferenceCategory?.categoryLevelThreeName)}}</span><br>

                  <hr class="hr my-1" />
                  Sub Category 4
                  <span class="font-weight-normal float-end" container="body" placement="bottom"
                    ngbTooltip="{{item.preferenceCategory?.categoryLevelFourName}}">{{getTruncate(item.preferenceCategory?.categoryLevelFourName)}}</span><br>

                  <hr class="hr my-1" />
                  HashTags <span class="font-weight-normal float-end">
                    <ng-container [ngTemplateOutlet]="templatePop"
                      [ngTemplateOutletContext]="{item:item, title: 'Hash Tags'}"></ng-container>
                  </span><br>
                  <hr class="hr my-1" />
                  Highlights <span class="font-weight-normal float-end">
                    <ng-container [ngTemplateOutlet]="templatePop"
                      [ngTemplateOutletContext]="{item:item, title: 'Lot Highlights'}"></ng-container>
                  </span><br>
                  <hr class="hr my-1" />
                  Features <span class="font-weight-normal float-end">
                    <ng-container [ngTemplateOutlet]="templatePop"
                      [ngTemplateOutletContext]="{item:item, title: 'Lot Features'}"></ng-container>
                  </span><br>
                  <hr class="hr my-1" />
                  <span [ngClass]="{'text-red-500 ' : !item?.lotImages}">Images <span
                      class="font-weight-normal float-end">{{getLotImageCount(item?.lotImages)}}</span></span><br>
                  <hr class="hr my-1" />
                  <span [ngClass]="{'text-red-500 ' : !item?.lotImages}">
                    Videos <span class="font-weight-normal float-end">{{getLotVideoCount(item?.lotImages)}}</span>
                  </span><br>
                  <hr class="hr my-1" />
                  Pickup Details <span class="font-weight-normal float-end" container="body" placement="bottom"
                    ngbTooltip="{{item?.pickUpDetails}}">{{item?.pickUpDetails? 'Provided' : 'Not Provided'}}
                  </span><br>
                  <hr class="hr my-1" />
                  Inspection Details <span class="font-weight-normal float-end" container="body" placement="bottom"
                    ngbTooltip="{{item?.inspectionDetails}}">{{item?.inspectionDetails? 'Provided' : 'Not Provided'}}
                  </span>
                </td>
                <td class=" text-center" (click)="openEditLotDetailsModal(item, $event)"
                  [ngClass]="getLotValidation(item) ? 'text-orange-500 ' : 'text-green-500 '">
                  {{getLotValidation(item) ? 'INCOMPLETE' : 'COMPLETE'}}
                </td>
                <td class="text-center mt-2">
                  <div ngbDropdown #menuDropdown="ngbDropdown" container="body" placement="bottom-right">
                    <span ngbDropdownToggle data-bs-toggle="dropdown">
                      <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class">
                    </span>

                    <ul ngbDropdownMenu class="shadow">
                      <li ngbDropdownItem class="c-pointer" (click)="openEditLotDetailsModal(item, $event)"
                        [disabled]="!isAllowToEdit()">
                        <img src="assets/icons/edit_light.svg" height="24" width="24"> Edit
                      </li>

                      <li>
                        <hr class="dropdown-divider ">
                      </li>

                      <li ngbDropdownItem class="c-pointer"
                        (click)="openAuctionLotEnableAndDisableModel(item, enableDisabledAuctionLot)"
                        [disabled]="!isAllowToEdit()">
                        <span *ngIf="!item?.active">
                          <img src="assets/icons/active.svg" class="svg-icon-class-small me-2">
                          Active
                        </span>
                        <span *ngIf="item?.active">
                          <img src="assets/icons/inactive.svg" class="svg-icon-class-small me-2"> Inactive
                        </span>
                      </li>

                      <li>
                        <hr class="dropdown-divider ">
                      </li>

                      <li ngbDropdownItem class="c-pointer" (click)="copyLotRow(item, $event)"
                        ngbTooltip="Please use this button to create a new lot by copying the attributes of this lot."
                        [disabled]="!isAllowToEdit()">
                        <img src="assets/icons/copy.svg" height="24" width="24"> Copy
                      </li>

                      <li>
                        <hr class="dropdown-divider ">
                      </li>

                      <li ngbDropdownItem class="c-pointer" *ngIf="(_filterLotList$ | async)!.length > 1"
                        (click)="openAuctionLotDiscardModel(discardAuctionLotModal, $event, item)"
                        [disabled]="!isAllowToEdit()">
                        <img src="assets/icons/delete_danger.svg" height="24" width="24"> Delete
                      </li>
                    </ul>
                  </div>
                </td>
                <!-- <td class="text-center align-middle p-3">
                    <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mb-2" (click)="openAuctionLotEnableAndDisableModel(item, enableDisabledAuctionLot)">
                      {{item?.active ? 'Inactive' : 'Active'}}
                      <img src="assets/icons/active.svg" *ngIf="!item?.active" ngbTooltip="active" height="24" width="24">
                      <img src="assets/icons/inactive.svg" *ngIf="item?.active" ngbTooltip="inactive" height="24" width="24">
                    </button><br>
                    <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mb-2" (click)="openEditLotDetailsModal(item, $event)">
                      <img src="assets/icons/edit_dark.svg" height="24" width="24">
                    </button><br>
                    <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mb-2" (click)="copyLotRow(item, $event)" ngbTooltip="Mark Lot Active/ Mark lot in-Active">
                      <img src="assets/icons/copy_dark.png" height="24" width="24">
                    </button><br>
                    <span class="c-pointer" (click)="openAuctionLotDiscardModel(discardAuctionLotModal, $event, item)">
                      <img src="assets/icons/delete_danger.svg" height="24" width="24">
                    </span>

                  </td> -->
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</div>


<ng-template #templatePop let-item="item" let-title="title">
  <span *ngIf="title != 'Description'">

    <span class="float-end" *ngIf="title == 'Hash Tags'">{{getHashtagsValidation(item?.hashtags)}}
      <img src="assets/icons/info_light.svg" height="18px" width="18px" triggers="mouseenter:mouseleave"
        container="body" placement="top" [ngbPopover]="popContent" [popoverTitle]="title"
        [disablePopover]="!item?.hashtags || !item?.hashtags?.length">
    </span>
    <span class="float-end" *ngIf="title == 'Lot Highlights'">{{getHighlightsValidation(item?.highlights)}}
      <img src="assets/icons/info_light.svg" triggers="mouseenter:mouseleave" height="18px" width="18px"
        container="body" placement="top" [ngbPopover]="popContent" [popoverTitle]="title"
        [disablePopover]="!item?.highlights || !item?.highlights?.length">
    </span>
    <span class="float-end" *ngIf="title == 'Lot Features'">{{getLotFeaturesValidation(item?.lotFeatures)}}
      <img src="assets/icons/info_light.svg" triggers="mouseenter:mouseleave" height="18px" width="18px"
        container="body" placement="top" [ngbPopover]="popContent" [popoverTitle]="title"
        [disablePopover]="!item?.lotFeatures || !item?.lotFeatures?.length">
    </span>
  </span>

  <span *ngIf="title == 'Description'">
    <span class="font-weight-normal float-end">{{item?.description ? 'Provided' : 'Not Provided'}} <img
        src="assets/icons/info_light.svg" height="18px" width="18px" [ngbTooltip]="tipContent" container="body"
        placement="bottom"></span>
  </span>

  <ng-template #popContent>
    <ul *ngIf="title == 'Hash Tags'">
      <li *ngFor="let hashtag of item?.hashtags">
        {{hashtag}}
      </li>
    </ul>
    <ul *ngIf="title == 'Lot Highlights'">
      <li *ngFor="let data of item?.highlights">
        {{data}}
      </li>
    </ul>
    <ul *ngIf="title == 'Lot Features'">
      <li *ngFor="let data of item?.lotFeatures">
        {{data.featureKey}} : {{data.featureValue}}
      </li>
    </ul>

  </ng-template>

  <ng-template #tipContent>
    <p [innerHTML]="item?.description"></p>
  </ng-template>

</ng-template>

<ng-template #discardAuctionLotModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Auction Lot Discard</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(discardModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>Are you sure you want to discard the selected lot? you will not be able to work on this lot once discarded.
          </p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Lot discarded successfully."></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isDiscardLoading">
          <div class="e-spinner" *ngIf="isDiscardLoading"></div>
        </div>

        <div class="text-end mb-3" *ngIf="auctionEntityDto && !isDiscardLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-2"
            (click)="closeModal(discardModalRef)">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="discardLotRow()" [disabled]="!isAllowToEdit()">OK</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #enableDisabledAuctionLot>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>{{lotActiveStatus ? 'DISABLE' : 'ENABLE'}} LOT </h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(enableDisabledModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="">
          <p>Do you want to {{lotActiveStatus ? 'disable' : 'enable' }} Lot</p>
        </div>
        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
            [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
            message="Lot {{lotActiveStatus ? 'disabled' : 'enabled'}} successfully.">
          </app-message-toast>
        </div>
        <div class="text-end mb-3"
          *ngIf="selectedLotForEnableDisable && !isEnableOrDisableLoading&& !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3"
            (click)="closeModal(enableDisabledModalRef)">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary"
            (click)="markAuctionLotActiveOrInActive('ENABLE')"
            *ngIf="!selectedLotForEnableDisable?.active" [disabled]="!isAllowToEdit()">ENABLE</button>
          <button type="button" class="btn btn-sm btn-outline-primary"
            (click)="markAuctionLotActiveOrInActive('DISABLE')"
            *ngIf="selectedLotForEnableDisable?.active" [disabled]="!isAllowToEdit()">DISABLE</button>
        </div>
        <div class="d-grid gap-2 mb-3" *ngIf="isEnableOrDisableLoading">
          <div class="e-spinner" *ngIf="isEnableOrDisableLoading"
            [ngClass]="{'center-spinner' : isEnableOrDisableLoading}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>