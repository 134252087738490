import { FormOfContract } from "../../enums/FormOfContract";
import { TenderStatus } from "../../enums/TenderStatus";
import { TenderFeeDetails } from "./TenderFeeDetails";
import { TenderOpeningType } from "../../enums/TenderOpeningType";
import { TenderType } from "../../enums/TenderType";
import { RateContractDto } from "./RateContractDto";
import { TenderContractType } from "../../enums/TenderContractType";
import { UserInfoUiDto } from "./UserInfoUiDto";
import { AddressDto } from "../Address";
import { Currency } from "../Currency";
import { ApprovalWorkflowUiDto } from "../approval-workflow/ApprovalWorkflowUiDto";
import { TenderQuestionnaireWrapperDto } from "../tender/TenderQuestionnaireWrapperDto";

export class TenderOpportunityWrapperDto {
    tenderOpportunityId?: string;
    sequenceNo?:number;
    status?: TenderStatus;
    title?: string;
    description?: string;
    tenderType?: TenderType;
    tenderCategory?: string;
    accountHead?: string;
    category?: string;
    subcategory?: string;
    formOfContract?: FormOfContract;
    tenderOpeningType?: TenderOpeningType;
    tenderContractType?: TenderContractType;
    rateContractDto?: RateContractDto;
    allowReBidSubmission?: boolean;
    allowWithdrawalOfBids?: boolean;
    allowMultipleBids?: boolean;
    allowMultipleCurrency?: boolean;
    currencies?: Array<Currency>;
    noOfEnvelopes?: number;
    tenderValue?: number;
    showTenderValueToBidder?: boolean;
    bidValidityDays?: number;
    leadTimeDays?: number;
    deliveryLocationDetails?: AddressDto;
    allowPreBidMeeting?: boolean;
    preBidMeetingLocation?: AddressDto;
    bidOpeningLocation?: AddressDto;
    invitingOfficerLocation?: AddressDto;
    preBidInvitedOfficers?: Array<UserInfoUiDto>;
    tenderFeeDetails?: TenderFeeDetails;
    publishingDate?: string;
    documentDownloadDate?: string;
    allowSeekClarification?: boolean;
    seekClarificationStartDate?: string;
    seekClarificationEndDate?: string;
    bidSubmissionStartDate?: string;
    bidSubmissionEndDate?: string;
    technicalOpeningDate?: string;
    financialOpeningDate?: string;
    
    approvalWorkflowUiDto?: ApprovalWorkflowUiDto[] ;
    tenderQuestionnaireWrapperDto?: TenderQuestionnaireWrapperDto;

    version?: number;
}