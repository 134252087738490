<div>
  <div class="grid grid-cols-2 gap-2 w-full items-center justify-between py-4 px-6">
    <div class="">
      <span class=""> <img src="/assets/icons/drawer-head-icon-1.svg" class="tr-xl-icon inline-flex"></span>
      <div class="inline-flex tr-margin-left-10px inline-flex ml-2  text-gray-300 font-semibold text-xl leading-4">
        Adding New User
      </div>
    </div>
    <div class="text-end">
      <button class="bg-gray-50 text-gray-700 text-sm px-4 py-2 rounded-md mr-2 tr-margin-right-10px"
        (click)="closeDrawer()">Cancel</button>
      <button class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md" (click)="saveUser()">Save as draft</button>
    </div>
  </div>
  <div class="tr-form-space-and-background-drawer tr-overflow-y-drawer">
    <form class="needs-validation w-full" novalidate novalidate [formGroup]="formGroup" (keyup.enter)="saveUser()">
      <div class="">
        <div class="grid grid-cols-2 w-full gap-2 content-between items-center">
          <div class="">
            <div class="mb-2">
              <label for="firstName" class="text-gray-700  text-sm font-semibold leading-4">
                {{formConfig['firstName'].label}}
              </label>
              <div class="">
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                  type="text" id="firstName" formControlName="firstName" placeholder="---"
                  [ngClass]="{'is-invalid': fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)}">
              </div>
              <div *ngIf="fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)">
                <p class="tr-validations" *ngIf="fc['firstName'].errors?.['required']">
                  <span class="mr-2">
                    <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                  </span>
                  {{formConfig['firstName'].validations['required']}}
                </p>
                <p class="tr-validations" *ngIf="fc['firstName'].errors?.['pattern']">
                  <span class="mr-2">
                    <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                  </span>
                  {{formConfig['firstName'].validations['pattern']}}
                </p>
                <p class="tr-validations" *ngIf="fc['firstName'].errors?.['minlength']">
                  <span class="mr-2">
                    <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                  </span>
                  {{formConfig['firstName'].validations['minlength']}}
                </p>
                <p class="tr-validations" *ngIf="fc['firstName'].errors?.['maxlength']">
                  <span class="mr-2">
                    <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                  </span>
                  {{formConfig['firstName'].validations['maxlength']}}
                </p>
              </div>
            </div>
          </div>
          <div class="">
            <div class="mb-2">
              <label for="lastName" class="text-gray-700  text-sm font-semibold leading-4">
                {{formConfig['lastName'].label}}
              </label>
              <div class="">
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                  type="text" id="lastName" formControlName="lastName" placeholder="---"
                  [ngClass]="{'is-invalid': fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)}">
              </div>
              <div *ngIf="fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)">
                <p class="tr-validations" *ngIf="fc['lastName'].errors?.['required']">
                  <span class="mr-2">
                    <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                  </span>
                  {{formConfig['lastName'].validations['required']}}
                </p>
                <p class="tr-validations" *ngIf="fc['lastName'].errors?.['pattern']">
                  <span class="mr-2">
                    <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                  </span>
                  {{formConfig['lastName'].validations['pattern']}}
                </p>
                <p class="tr-validations" *ngIf="fc['lastName'].errors?.['minlength']">
                  <span class="mr-2">
                    <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                  </span>
                  {{formConfig['lastName'].validations['minlength']}}
                </p>
                <p class="tr-validations" *ngIf="fc['lastName'].errors?.['maxlength']">
                  <span class="mr-2">
                    <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                  </span>
                  {{formConfig['lastName'].validations['maxlength']}}
                </p>
              </div>
            </div>
          </div>

          <div class="mb-2">
            <label for="primaryEmailId" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['primaryEmailId'].label}}
            </label>
            <div class="">
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                type="text" id="primaryEmailId" formControlName="primaryEmailId" placeholder="---"
                [ngClass]="{'is-invalid': fc['primaryEmailId'].invalid && (fc['primaryEmailId'].dirty || fc['primaryEmailId'].touched)}">
            </div>
            <div *ngIf="fc['primaryEmailId'].invalid && (fc['primaryEmailId'].dirty || fc['primaryEmailId'].touched)">
              <p class="tr-validations" *ngIf="fc['primaryEmailId'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['primaryEmailId'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['primaryEmailId'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['primaryEmailId'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['primaryEmailId'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['primaryEmailId'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['primaryEmailId'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['primaryEmailId'].validations['maxlength']}}
              </p>
            </div>
          </div>

          <div class="mb-2">
            <label for="empCode" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['empCode'].label}}
            </label>
            <div class="">
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
                type="text" id="empCode" formControlName="empCode" placeholder="---"
                [ngClass]="{'is-invalid': fc['empCode'].invalid && (fc['empCode'].dirty || fc['empCode'].touched)}">
            </div>
            <div *ngIf="fc['empCode'].invalid && (fc['empCode'].dirty || fc['empCode'].touched)">
              <p class="tr-validations" *ngIf="fc['empCode'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['empCode'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['empCode'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['empCode'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['empCode'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['empCode'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['empCode'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['empCode'].validations['maxlength']}}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 w-full gap-2">
          <div class="">
            <label for="designation" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['designation'].label}}
            </label>
            <div class="">
              <select name="Select Designation"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
                formControlName="designation" id="designation"
                [ngClass]="{'is-invalid': fc['designation'].invalid && (fc['designation'].dirty || fc['designation'].touched)}">
                <option value="" disabled>Select . . .</option>
                <option [value]="designation?.code"
                  *ngFor="let designation of applicationStartupDto?.cacheDesignationsDtos;">
                  {{designation?.name}}
                </option>
              </select>
            </div>
            <div *ngIf="fc['designation'].invalid && (fc['designation'].dirty || fc['designation'].touched)">
              <p class="tr-validations" *ngIf="fc['designation'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['designation'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['designation'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['designation'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['designation'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['designation'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['designation'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['designation'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="">
            <label for="designation" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['plantCodes'].label}}
            </label>
            <div class="form-floating mb-3">
                <select name="Select Plant"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-1 pt-2 text-gray-100 text-sm"
                formControlName="plantCodes" id="plantCodes"
                [ngClass]="{'is-invalid': fc['plantCodes'].invalid && (fc['plantCodes'].dirty || fc['plantCodes'].touched)}">
                <option value="" disabled>Select . . .</option>
                <option [value]="plantCode?.plantCode"
                  *ngFor="let plantCode of selectedCompanyUiDto?.plantUiDtos;">
                  {{plantCode?.plantName}}
                </option>
              </select>

            </div>
            <div *ngIf="fc['plantCodes'].invalid && (fc['plantCodes'].dirty || fc['plantCodes'].touched)">
              <p class="tr-validations" *ngIf="fc['plantCodes'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['plantCodes'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['plantCodes'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['plantCodes'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['plantCodes'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['plantCodes'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['plantCodes'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['plantCodes'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class="">
            <label for="designation" class="text-gray-700  text-sm font-semibold leading-4">
              {{formConfig['teamUiDtos'].label}}
            </label>
            <div class="form-floating mb-3">
              <p-multiSelect [options]="teamUiDtos" formControlName="teamUiDtos" defaultLabel="Select a Team"
                optionLabel="name" optionValue="teamId" display="chip">
              </p-multiSelect>
            </div>
            <div *ngIf="fc['teamUiDtos'].invalid && (fc['teamUiDtos'].dirty || fc['teamUiDtos'].touched)">
              <p class="tr-validations" *ngIf="fc['teamUiDtos'].errors?.['required']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['teamUiDtos'].validations['required']}}
              </p>
              <p class="tr-validations" *ngIf="fc['teamUiDtos'].errors?.['pattern']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['teamUiDtos'].validations['pattern']}}
              </p>
              <p class="tr-validations" *ngIf="fc['teamUiDtos'].errors?.['minlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['teamUiDtos'].validations['minlength']}}
              </p>
              <p class="tr-validations" *ngIf="fc['teamUiDtos'].errors?.['maxlength']">
                <span class="mr-2">
                  <img src="/assets/icons/tr-validation.svg" class="tr-s-icon">
                </span>
                {{formConfig['teamUiDtos'].validations['maxlength']}}
              </p>
            </div>
          </div>
          <div class=" tr-link text-end c-pointer" (click)="openNewTeamDialog()">
            Create New Team
          </div>

        </div>
        <div class="mb-4">
          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="successMessages['update']"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="(this._showErrorMsg$ | async)">
            </app-message-toast>
          </div>
          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
          <div class="col-12 text-end mb-5" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md"
              (click)="saveUser()">Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>