import { ClientSubscriptionService } from './client-subscription.service';
import * as CryptoJS from 'crypto-js';


import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ErrorService } from "./error.service";


@Injectable({ providedIn: "root" })
export class EncryptionAndDecryptionService {
  privateKey?: string;

  constructor(private clientSubscriptionService: ClientSubscriptionService, private http: HttpClient, private errorService: ErrorService) {}


  public  encryptObject(data: any){
    this.privateKey = this.clientSubscriptionService.clientSubscriptionDto?.privateKey;
    let dataInString = JSON.stringify(data);
    let password = CryptoJS.enc.Utf8.parse(this.privateKey!);
    let iv = CryptoJS.enc.Utf8.parse(this.privateKey!.substring(0,16));
    return CryptoJS.AES.encrypt(dataInString, password, {iv: iv}).toString();
  }


  public decryptObject( encryptedData: string){
    this.privateKey = this.clientSubscriptionService.clientSubscriptionDto?.privateKey;
    let password = CryptoJS.enc.Utf8.parse(this.privateKey!);
    let iv = CryptoJS.enc.Utf8.parse(this.privateKey!.substring(0,16));

    let decryptedData = CryptoJS.AES.decrypt(encryptedData, password, {
      iv: iv
    });

    //convert to string
    let dataInString =  decryptedData.toString( CryptoJS.enc.Utf8 );

    //convert data string to any
    return JSON.parse(dataInString);
  }


  setCustomEncryptionHeader(headers: HttpHeaders) {
      return headers.set("X-PROCUREXT-ENCRYPTION", this.privateKey!).set("Content-Type", "text/plain");
  }

}

