import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalWorkflowComponent } from '../approval-workflow/approval-workflow.component';
import { MataDataViewComponent } from '../mata-data-view/mata-data-view.component';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApprovalWorkflowUiDto } from 'src/app/shared/models/approval-workflow/ApprovalWorkflowUiDto';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { Subscription } from 'rxjs';
import { OnboardingWorkflow } from 'src/app/shared/models/OnboardingWorkflow';

@Component({
  selector: 'app-rule-mata-data',
  templateUrl: './rule-mata-data.component.html',
  styleUrls: ['./rule-mata-data.component.sass']
})
export class RuleMataDataComponent implements OnInit, OnDestroy {
  isDataLoading: boolean = false;

  organizationUiDto?: OrganizationWrapperUiDto;
  onboardingWorkflows: OnboardingWorkflow[] = [];
  approvalWorkflowUiDtos: ApprovalWorkflowUiDto[] = [];
  selectedApprovalWorkflowUiDto?: ApprovalWorkflowUiDto;

  currentOrganizationSubscription$?: Subscription;
  approvalWorkflowsSubscription$?: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private onboardingService: OnboardingService
  ) { }

  ngOnInit(): void {
    this.onboardingWorkflows = this.onboardingService.getOnboardingWorkflows;

    this.currentOrganizationSubscription$ = this.onboardingService.getOrganizationUiDto$.subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;
        this.loadApprovalWorkflows();
      }
    });

    this.approvalWorkflowsSubscription$ = this.onboardingService.getApprovalWorkflowUiDtos$.subscribe(data => {
      if (data) {
        this.approvalWorkflowUiDtos = data;
      } else {
        this.approvalWorkflowUiDtos = [];
      }
    })
  }

  async loadApprovalWorkflows() {
    this.isDataLoading = true;
    await this.onboardingService.loadOnboardingApprovalWorkflowsList(this.organizationUiDto?.orgCode!);
    this.isDataLoading = false;
  }

  openPurchaseRequestModal() {
    this.ngbModal.open(ApprovalWorkflowComponent, { size: 'xl', backdrop: 'static', keyboard: false, centered: true });
  }

  openMetaDataView(approvalWorkflowUiDto: ApprovalWorkflowUiDto) {
    this.selectedApprovalWorkflowUiDto = approvalWorkflowUiDto;
    let modalRef = this.ngbModal.open(MataDataViewComponent, { size: 'xl', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.approvalWorkflowUiDto = approvalWorkflowUiDto;
  }

  getOnboardingWorkflow(workflowCode: string) {
    return this.onboardingWorkflows.find(item => item.code == workflowCode)?.name;
  }

  ngOnDestroy(): void {
    if (this.currentOrganizationSubscription$) {
      this.currentOrganizationSubscription$.unsubscribe();
    }
    if (this.approvalWorkflowsSubscription$) {
      this.approvalWorkflowsSubscription$.unsubscribe();
    }
  }
}
