export class AWFChainDefineUiDto {
  orgCode?: string;
  approvalCode?: string;
  chainId?: string;
  name?: string;
  description?: string;
  /**
   * In sequential workflow we will always use role code1.
   * role code 2 and 3 are defined for a parallel scenario where we can have multiple users in each approval sequence.
   */
  roleCode1?: string;
  roleCode2?: string;
  roleCode3?: string;
  roleCode1Mandatory?: boolean;
  roleCode2Mandatory?: boolean;
  roleCode3Mandatory?: boolean;
  sequence?: number;

}