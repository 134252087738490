import { CatalogueCartWrapperDto } from "./CatalogueCartWrapperDto";
import { CatalogueVendorDto } from "./CatalogueVendorDto";
import { Currency } from "./Currency";

export class CatalogueRequisitionWrapper {
  id?: string;
  catalogueCartWrapperDtos?: CatalogueCartWrapperDto[]
  categoryL1Id?: string
  categoryL1Name?: string
  categoryL2Id?: string
  categoryL2Name?: string
  isApproved: boolean = false;
  totalPrice?: number
  totalTaxAmount?: number
}
