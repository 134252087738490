import { Component } from '@angular/core';

@Component({
  selector: 'app-rfx-creator-bidders',
  templateUrl: './rfx-creator-bidders.component.html',
  styleUrls: ['./rfx-creator-bidders.component.sass']
})
export class RfxCreatorBiddersComponent {

}
