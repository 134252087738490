import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrgOrganogramUiDto } from 'src/app/shared/models/OrgOrganogramUiDto';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { OnboardingBeginComponent } from '../onboarding-begin/onboarding-begin.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { selectOrgOrganogramUiDto } from 'src/app/shared/state-management/onboarding/onboarding.features';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-onboarding-workflow',
  templateUrl: './onboarding-workflow.component.html',
  styleUrls: ['./onboarding-workflow.component.sass']
})
export class OnboardingWorkflowComponent implements OnInit, OnDestroy {
  selectedSidebarView: string = "ONBOARDING_ORG_PROFILE";
  isOpen: boolean = false;

  orgOrganogramUiDto?: OrgOrganogramUiDto;
  
  orgOrganogramSubscription$?: Subscription;

  constructor(
    private ngbModal: NgbModal,
    private onboardingService: OnboardingService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.orgOrganogramSubscription$ = this.store.select(selectOrgOrganogramUiDto).subscribe((data) => {
      if (data) {
        this.orgOrganogramUiDto = ApplicationUtils.deepClone(data) as OrgOrganogramUiDto;
        
        if (this.orgOrganogramUiDto.companyUiDtos && this.orgOrganogramUiDto.companyUiDtos.length > 0) {
          let plantUiDtos = this.orgOrganogramUiDto.companyUiDtos[0].plantUiDtos;
          if (plantUiDtos && plantUiDtos.length > 0) {
            if (plantUiDtos[0].plantCostCenterUiDtos && plantUiDtos[0].plantCostCenterUiDtos.length > 0) {
              let costCenterBudget = plantUiDtos[0].plantCostCenterUiDtos[0].allocatedBudget;
              if (costCenterBudget == undefined) {
                this.openOnboardingModal();
              }
            }
          } else {
            this.openOnboardingModal();
          }
        } else {
          this.openOnboardingModal();
        }
      }
    })
  }

  toggleDrawer(status?: boolean) {
    if (status) {
      this.isOpen = status;
    } else {
      this.isOpen = !this.isOpen;
    }
  }

  openOnboardingModal() {
    this.ngbModal.open(OnboardingBeginComponent, {
      size: 'lg', backdrop: 'static', keyboard: false
    });
  }

  toggleSidebarView(tabView: string) {
    this.selectedSidebarView = tabView;

    // if (this.selectedSidebarView == ApplicationConstants.ONBOARDING_ORG_PROFILE) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.ONBOARDING_ORG_PROFILE
    //   );
    // } else if (this.selectedSidebarView == ApplicationConstants.ONBOARDING_COMPANIES) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.ONBOARDING_COMPANIES
    //   );
    // } else if (this.selectedSidebarView == ApplicationConstants.ONBOARDING_PLANT) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.ONBOARDING_PLANT
    //   );
    // }else if (this.selectedSidebarView == ApplicationConstants.ONBOARDING_USERS) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.ONBOARDING_USERS
    //   );
    // }
    // else if (this.selectedSidebarView == ApplicationConstants.SETTING_PAGE) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.SETTING_PAGE
    //   );
    // }
    // else if (this.selectedSidebarView == ApplicationConstants.RULE_MATA_DATA) {
    //   this.dataRedirectionService.storeNavigationContext(
    //     ApplicationConstants.ADMIN,
    //     ApplicationConstants.RULE_MATA_DATA
    //   );
    // }
  }

  ngOnDestroy(): void {
    if (this.orgOrganogramSubscription$) {
      this.orgOrganogramSubscription$.unsubscribe();
    }
  }
}
