import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { SuperAdminDashboardService } from 'src/app/shared/services/super-admin-dashboard.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { EmailTemplateService } from 'src/app/shared/services/email-template.service';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.sass']
})
export class AddOrganizationComponent implements OnInit, AfterContentInit {
  organizationUiDto?: OrganizationWrapperUiDto;

  formGroup: FormGroup;
  formConfig: { [key: string]: any } = {};
  successMessages: { [key: string]: any } = {};

  errorMsg: string | undefined;
  isLoading: boolean = false;
  isEdit: boolean = false;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private superAdminService: SuperAdminDashboardService,
    private emailTemplateService: EmailTemplateService,
    private themeService: ThemeService,
    private dialogConfig: DynamicDialogConfig,
  ) {
    this.formGroup = this.fb.group({
      orgCode: [''],
      name: [''],
      adminDomainName: [''],
      bidderDomainName: [''],
      theme: ['']
    })
  }

  ngOnInit(): void {    
    this.organizationUiDto = this.dialogConfig.data['organizationUiDto'];
    this.populateFormValues();
  }

  ngAfterContentInit(): void {
    ApplicationUtils.clearTextFormatOnPaste('descriptionEditor');
    this.setupThemeStructure();
  }

  get fc(): any { return this.formGroup.controls; }

  populateFormValues() {
    if (this.organizationUiDto != null) {
      this.isEdit = true;

      this.formGroup.controls['adminDomainName'].disable();
      this.formGroup.controls['bidderDomainName'].disable();

      this.formGroup.patchValue(this.organizationUiDto);
      this.formGroup.updateValueAndValidity();
    }
  }

  async setupThemeStructure() {
    await this.themeService.loadDefaultThemeStructure();
    const themeStructure = this.themeService.themeStructure;

    if (themeStructure?.organizationUiDto) {
      this.successMessages = this.themeService.createSuccessMessage(themeStructure.organizationUiDto);
      this.formConfig = this.themeService.createFormConfig(themeStructure.organizationUiDto.model);
      this.themeService.applyValidations(this.formGroup, themeStructure.organizationUiDto.model);
    }
  }

  saveOrganization() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this._isSaveButtonEnable$.next(false);

    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);

    let formValue = this.formGroup.getRawValue();

    let newOrganizationCreation = false
    if (this.organizationUiDto == null || (this.organizationUiDto && !this.organizationUiDto.orgCode)) {
      this.organizationUiDto = new OrganizationWrapperUiDto();
      newOrganizationCreation = true;
    }

    this.organizationUiDto!.theme = formValue.theme;
    this.organizationUiDto!.adminDomainName = formValue.adminDomainName;
    this.organizationUiDto!.bidderDomainName = formValue.bidderDomainName;
    this.organizationUiDto!.orgCode = formValue.orgCode;
    this.organizationUiDto!.name = formValue.name;

    this.superAdminService.saveOrganizationUiDto(this.organizationUiDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;

          let organizationUiDto = apiResponseDto.data as OrganizationWrapperUiDto;

          if (newOrganizationCreation) {
            console.log("Creating new Email Templates for new Organization");
            this.emailTemplateService.saveEmailTemplatesToFirestore(organizationUiDto.orgCode!);
          }

          // Update Organization List
          this.superAdminService.updateOrganizationList(organizationUiDto, this.isEdit)

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this._isSaveButtonEnable$.next(true);
            this.closeModal();
          }, 2000)
        } else {
          if (apiResponseDto.code == "AUCTION-ERROR-113") {
            this._showErrorMsg$.next("Organization already existing with " + this.organizationUiDto?.orgCode)
          } else {
            this._showErrorMsg$.next(apiResponseDto.message as string);
          }
          this._showErrorToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving Organization");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  closeModal() {
    this.populateFormValues();
    this.modalService.dismissAll()

  }

  onChangeAdminDomainName() {
    var domainName = this.fc['adminDomainName'].value;

    if (domainName && (domainName.includes('localhost'))) {
      this.formGroup.controls['adminDomainName'].setValidators(Validators.required);
      this.formGroup.controls['adminDomainName'].updateValueAndValidity();
    } else {
      this.formGroup.controls['adminDomainName'].setValidators([Validators.required, Validators.pattern(Pattern.url2)]);
      this.formGroup.controls['adminDomainName'].updateValueAndValidity();
    }
  }

  onChangeBidderDomainName() {
    var domainName = this.fc['bidderDomainName'].value;

    if (domainName && (domainName.includes('localhost'))) {
      this.formGroup.controls['bidderDomainName'].setValidators(Validators.required);
      this.formGroup.controls['bidderDomainName'].updateValueAndValidity();
    } else {
      this.formGroup.controls['bidderDomainName'].setValidators([Validators.required, Validators.pattern(Pattern.url2)]);
      this.formGroup.controls['bidderDomainName'].updateValueAndValidity();
    }
  }
}
