<div class="container p-3">
  <div class="col-12">
    <div class="row ">
      <div class="col-11">
        <div class="row">
          <div class="col-3 mt-3 col-md-2">
            <span class="">Company:</span>
          </div>
          <div class="col-9 mt-3 col-md-10">{{comFinancialWrapperDto?.companyName}}</div>


          <div class="col-3 mt-3 col-md-2">
            <span class="">Project:</span>
          </div>
          <div class="col-9 mt-3 col-md-10">{{rfxEntityDto?.rfxTitle}}</div>


          <div class="col-3 mt-3 col-md-2">
            <span class="">Event:</span>
          </div>
          <div class="col-9 mt-3 col-md-10">{{subcategorySubmissionDto?.subcategoryName}}</div>


          <div class="col-3 mt-3 col-md-2">
            <span class="me-3">Contract Type:</span>
          </div>
          <div class="col-9 mt-3 col-md-10">{{subcategorySubmissionDto?.contractAlgo}}
            {{getFormattedContractType(subcategorySubmissionDto?.contractType!)}}</div>


          <div class="col-3 mt-3 col-md-2">
            <span class="me-3">{{subcategorySubmissionDto?.contractAlgo == 'HCS' ? 'H1' : 'L1'}} Price:</span>
          </div>
          <div class="col-9 mt-3 col-md-10">{{getFormattedPrice(normalizePrice)}}</div>

        </div>


        <div class="my-2">
          <span class="me-3">
            Normalised Price is {{getFormattedPrice(normalizePrice)}}
          </span>
        </div>
      </div>
      <div class="col-1 text-end" [ngClass]="{'disabled': isLoading}">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus (click)="closeModal()">
      </div>
    </div>

    <div class="d-grid gap-2 my-3" *ngIf="isLoading">
      <div class="e-spinner"></div>
    </div>

    <div class="table-responsive">
      <table class="table medium-font mb-0 table-bordered">
        <thead class="table-dark">
          <tr class="align-middle">
            <th scope="col" class="text-center">
              Item No
            </th>
            <th scope="col" class="text-center">
              Item Name
            </th>
            <th scope="col" class="text-center">
              Item Description
            </th>
            <th scope="col" class="text-center">
              Qty
            </th>
            <th scope="col" class="text-center">
              UoM
            </th>
            <th scope="col" class="text-center">
              Tax Rate
            </th>
            <th scope="col" class="text-center">
              Bid Amount
            </th>
            <th scope="col" class="text-center">
              Normalize Amount
            </th>
            <th scope="col" class="text-center">
              Bidder
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="align-middle" *ngFor="let item of (compNormalizeItemsList$|async); index as i">
            <td class="text-center">{{item.preSequenceText}}.{{item.sequenceNo}}</td>
            <td class="text-center">{{item.itemName}}</td>
            <td class="text-center">{{item.description}}</td>
            <td class="text-center">{{item.qty}}</td>
            <td class="text-center">{{item.uom}}</td>
            <td class="text-center">{{item.taxRate}}</td>
            <td class="text-center">{{getFormattedPrice(item.bidAmount)}}</td>
            <td class="text-center">{{getFormattedPrice(item.normalizeAmount)}}</td>
            <td class="text-center">{{subcategorySubmissionDto?.contractAlgo == 'HCS' ? 'H' : 'L'}}{{item.compSeq}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-end mt-3" *ngIf="!isLoading">
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0"
          (click)="generatePO()">Generate PO</button>
    </div>
  </div>
</div>
