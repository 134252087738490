import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';

@Component({
  selector: 'app-onboarding-view-company-tabs',
  templateUrl: './onboarding-view-company-tabs.component.html',
  styleUrls: ['./onboarding-view-company-tabs.component.sass']
})
export class OnboardingViewCompanyTabsComponent implements OnInit, OnDestroy {
  @Output() onNavigateInternalPage = new EventEmitter<string>;
  @Output() sidebarClose = new EventEmitter<boolean>(false);
  
  currentTab: string = 'BASIC_INFO';
  
  selectedCompanyUiDto?: CompanyUiDto;

  selectedCompanySubscription$?: Subscription
  
  constructor(
    private onboardingService: OnboardingService,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
      } else {
        this.selectedCompanyUiDto = undefined;
      }
    })
  }

 


  toggleDetailsTab(tab: string) {
    this.currentTab = tab;
    if(this.currentTab == 'BUDGET' || this.currentTab == 'USERS')
    {
      this.sidebarClose.emit(true);
    }
  }

  backToCompanies() {
    this.onboardingService.setCurrentCompanyUiDto(undefined);
    this.onNavigateInternalPage.emit('ONBOARDING_COMPANIES');
  }

  openNewPlantDrawer(event: any) {
    event.stopPropagation();
    this.drawerService.openDrawer('ONBOARDING_NEW_PLANT');
  }
  openNewUserDrawer(event: any) {
    event.stopPropagation();
    this.drawerService.openDrawer('ONBOARDING_NEW_USER');
  }

  ngOnDestroy(): void {
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }    
  }
}
