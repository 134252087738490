import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbModalRef, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HtmlViewComponent } from 'src/app/shared/components/html-view/html-view.component';
import { QuestionResponseType } from 'src/app/shared/enums/questionnaire/QuestionResponseType';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { PrivacyAndTermsOfUseDto } from 'src/app/shared/models/PrivacyAndTermsOfUseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { QuestionnaireTemplate } from 'src/app/shared/models/questionnaire/QuestionnaireTemplate';
import { QuestionnaireWrapperDto } from 'src/app/shared/models/questionnaire/QuestionnaireWrapperDto';
import { SectionTemplate } from 'src/app/shared/models/questionnaire/SectionTemplate';
import { TechnicalQuestionTemplate } from 'src/app/shared/models/questionnaire/TechnicalQuestionTemplate';
import { UserAuctionQuestionsDto } from 'src/app/shared/models/questionnaire/UserAuctionQuestionsDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { QuestionnaireService } from 'src/app/shared/services/questionnaire.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { BidderQuestionnaireTechnicalQuestionComponent } from '../bidder-questionnaire-technical-question/bidder-questionnaire-technical-question.component';
import { EventEnum } from 'src/app/shared/enums/EventEnum';

@Component({
  selector: 'app-bidder-questionnaire',
  templateUrl: './bidder-questionnaire.component.html',
  styleUrls: ['./bidder-questionnaire.component.sass']
})
export class BidderQuestionnaireComponent implements OnInit, OnDestroy {
  @Input() userId!: string;

  ctrlTermsCondition: FormControl = new FormControl(false, Validators.requiredTrue);

  selectedAuction?: AuctionEntityDto;
  questionnaireWrapperDto?: QuestionnaireWrapperDto;
  questionnaireTemplate?: QuestionnaireTemplate;
  sectionTemplateList: SectionTemplate[] = []
  technicalQuestionsList: TechnicalQuestionTemplate[] = []

  selectedSectionTemplate?: SectionTemplate;

  isLoading: boolean = false;
  isEnableTermsConditions: boolean = false;
  questionModalRef?: NgbModalRef;
  errorMsg: string | undefined;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  questionnaireWrapperDtoSubscription$?: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private ngbModal: NgbModal,
    private questionnaireService: QuestionnaireService,
    private adminDashboardService: AdminDashboardService,
    private auctionDataHolderService: AdminSourcingEventsDataHolderService
  ) {}

  ngOnInit(): void {
    this.selectedAuction = this.auctionDataHolderService.selectedAuction;
    let auctionHouseDto = this.adminDashboardService.getCurrentAuctionHouse$.value;

    if (this.selectedAuction?.questionnaireId) {
      this.questionnaireService.loadQuestionnaireWrapperDto(this.selectedAuction.questionnaireId!, EventEnum.AUCTION);
    }

    this.questionnaireWrapperDtoSubscription$ = this.questionnaireService.getQuestionnaireWrapper$.subscribe(data => {
      if (data) {
        this.questionnaireWrapperDto = data;
        this.questionnaireTemplate = data.questionnaireTemplate;

        this.sectionTemplateList = this.questionnaireWrapperDto?.sectionTemplateList?.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL) ?? [];
        this.sectionTemplateList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));

        this.technicalQuestionsList = this.questionnaireWrapperDto?.technicalQuestionTemplates ?? [];
        this.technicalQuestionsList.sort((a, b) => Number(a.sequenceNo) - Number(b.sequenceNo));
      }
    })
  }

  closeModal() {
    this.activeModal.close();
  }

  changeSection(sectionTemplate: SectionTemplate) {
    this.selectedSectionTemplate = sectionTemplate;
  }

  openTechnicalQuestion(technicalQuestion: TechnicalQuestionTemplate) {
    this.questionModalRef = this.ngbModal.open(BidderQuestionnaireTechnicalQuestionComponent, {
      size: 'lg', centered: true,
      backdrop: 'static', keyboard: false,
    })
    this.questionModalRef.componentInstance.selectedTechnicalQuestion = technicalQuestion;
    this.questionModalRef.componentInstance.userId = this.userId;
  }

  getTitleResponseType(responseType: QuestionResponseType) {
    if (responseType == QuestionResponseType.SINGLE_CHOICE) {
      return "Single Choice Response";
    } else if (responseType == QuestionResponseType.MULTI_CHOICE) {
      return "Multi Choice Response";
    } else if (responseType == QuestionResponseType.DATE) {
      return "Date Response";
    } else if (responseType == QuestionResponseType.DATE_RANGE) {
      return "Date Range Response";
    } else if (responseType == QuestionResponseType.ALPHANUMERIC) {
      return "Alphanumeric Response";
    }
    return "Select Response Type";
  }

  ngOnDestroy(): void {
    if (this.questionnaireWrapperDtoSubscription$) {
      this.questionnaireWrapperDtoSubscription$.unsubscribe();
    }
  }
}
