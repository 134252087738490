import { Timestamp } from "@angular/fire/firestore";
import { Currency } from "../Currency";
import { FileInfoDto } from "../FileInfoDto";
import { PreferenceCategoryDto } from "../user/PreferenceCategoryDto";

export class RfxSubcategoryUiDto {
    orgCode?: string;

    rfxId?: string;
    subcategorySequence?: number;
    subcategoryId?: string;
    subcategoryType?: string;
    title?: string;
    description?: string;
    endDate?: string;
    endTime?: string;
    endDateUTC?: string | Timestamp;
    preferenceCategory?: PreferenceCategoryDto
    materialId?: string;
    questionnaireId?: string;
    sealedBid?: string;

    uom?: string;
    qty?: number;
    
    contractPeriod?: number;
    emdValue?: number;
    currency?: Currency;
    barcodeImageDto?: FileInfoDto;
    subcategoryImages?: FileInfoDto[];
    timeZone?: string;
    targetedEvent?: boolean;
    active?: boolean;
    discardedSubcategory?: boolean;
    contractType?: string;
    updateTimeUtc?: string | Timestamp
    
    maximumScore?: number;

    version?: number;
}

