<div>
  <div class="tr-onboarding-sidear-small fixed-sidebar-class" *ngIf="isOpen">
    <div>
      <i-lucide name="square-chevron-right" [size]="16" class="c-pointer my-icon inline-flex"
        (click)="toggleDrawer()"></i-lucide>

      <!-- <img src="/assets/icons/tr-onboarding-sidebar-arrow.svg" class="tr-s-icon c-pointer" (click)="toggleDrawer()"> -->
    </div>
    <div class="tr-onboarding-vertical-text tr-margin-top-8px">
      Back to Organization Management

    </div>
  </div>
  <div class="tr-onboarding-sidear-large fixed-sidebar-class" *ngIf="!isOpen">
    <div class="grid grid-cols-2 items-center justify-between w-full px-3">
      <div class="">
        <div class="tr-onboarding-sidebar-text-heading">
          Organization
        </div>

      </div>
      <div class=" text-end">
        <i-lucide name="square-chevron-left" [size]="16" class="c-pointer my-icon inline-flex"
          (click)="toggleDrawer()"></i-lucide>
        <!-- <img src="/assets/icons/tr-onboarding-sidebar-arrow-left.svg" class="tr-s-icon c-pointer"
          (click)="toggleDrawer()"> -->
      </div>
    </div>
    <div class="tr-onboarding-header"></div>
    <div class="w-full">
      <ul class="tr-margin-top-26px w-100" style="list-style: none; padding-left: 0px">
        <li>
          <div class="tr-margin-left-12px">
            <span>
              <i-lucide name="user" class="my-icon inline-flex" [size]="16"></i-lucide>
              <!-- <img src="/assets/icons/tr-onboarding-personal.svg" class="tr-s-icon c-pointer"> -->
            </span>
            <span class="tr-margin-left-8px tr-onboarding-sidebar-txt">Personal</span>
          </div>
        </li>
        <li (click)="toggleSidebarView('ONBOARDING_ORG_PROFILE')">
          <div class="mt-4 tr-onboarding-sidebarmd-text c-pointer"
            [ngClass]="selectedSidebarView == 'ONBOARDING_ORG_PROFILE' ? 'tr-sidebar-tab-active mt-4' : 'ml-3 mt-4'">
            Profile
          </div>
        </li>
        <li>
          <div class="mt-4 tr-onboarding-sidebarmd-text ml-3">
            <span>
              <!-- <i-lucide name="user" class="my-icon" [size]="16"></i-lucide> -->
              <img src="/assets/icons/tr-organization-icon.svg" class="tr-s-icon inline-flex">
            </span>
            <span class="tr-margin-left-8px tr-onboarding-sidebar-txt">Organisation</span>
          </div>
        </li>
        <li (click)="toggleSidebarView('ONBOARDING_COMPANIES')">
          <div class=""
            [ngClass]="selectedSidebarView == 'ONBOARDING_COMPANIES' ? 'tr-sidebar-tab-active mt-4' : 'ml-3 mt-4'">
            <div class="grid grid-cols-2 items-center justify-between w-full">
              <div class="">
                <div class="tr-onboarding-sidebar-text-heading">
                  Company
                </div>
              </div>
              <div class="text-end">
                <i-lucide name="chevron-right" [size]="16" class="c-pointer my-icon inline-flex"></i-lucide>
              </div>
            </div>
          </div>
        </li>
        <li (click)="toggleSidebarView('RULE_MATA_DATA')">
          <div class=" tr-onboarding-sidebarmd-text c-pointer"
            [ngClass]="selectedSidebarView == 'RULE_MATA_DATA' ? 'tr-sidebar-tab-active mt-4' : 'ml-3 mt-4'">
            Approval Workflow
          </div>
        </li>
        <li (click)="toggleSidebarView('ONBOARDING_EMAIL')">
          <div class=" tr-onboarding-sidebarmd-text c-pointer"
            [ngClass]="selectedSidebarView == 'ONBOARDING_EMAIL' ? 'tr-sidebar-tab-active mt-4' : 'ml-3 mt-4'">
            Email
          </div>
        </li>
      </ul>
    </div>
    <!-- <div class="tr-margin-top-26px w-100">
       <div class="tr-margin-left-12px">
          <span>
            <img src="/assets/icons/tr-onboarding-personal.svg" class="tr-s-icon c-pointer">
          </span>
          <span class="tr-margin-left-8px tr-onboarding-sidebar-txt">Personal</span>
        </div>

      <div class="mt-4 tr-onboarding-sidebarmd-text c-pointer"
        (click)="toggleSidebarView('ONBOARDING_ORG_PROFILE')"
        [ngClass]="selectedSidebarView == 'ONBOARDING_ORG_PROFILE' ? 'tr-sidebar-tab-active' : 'tr-margin-left-12px'">
        Profile
      </div>
      <div class="mt-4 tr-onboarding-sidebarmd-text tr-margin-left-12px">
        <span>
          <img src="/assets/icons/tr-organization-icon.svg" class="tr-s-icon c-pointer">
        </span>
        <span class="tr-margin-left-8px tr-onboarding-sidebar-txt">Organisation</span>
      </div>
      <div class="mt-4 ">
        <div class="d-flex tr-padding-left-12px tr-padding-right-8px w-100"
          [ngClass]="selectedSidebarView == 'ONBOARDING_COMPANIES' ? 'tr-sidebar-tab-active' : ''">
          <div class="col-9 tr-onboarding-sidebarmd-text c-pointer" (click)="toggleSidebarView('ONBOARDING_COMPANIES')">
            Company
          </div>
          <div class="col-3 text-end ">
            <img src="/assets/icons/tr-onboarding-sidebar-arrow.svg" class="tr-s-icon c-pointer">
          </div>
        </div>
      </div>
      <div class="mt-4 tr-onboarding-sidebarmd-text c-pointer"
        (click)="toggleSidebarView('ONBOARDING_USERS')"
        [ngClass]="selectedSidebarView == 'ONBOARDING_USERS' ? 'tr-sidebar-tab-active' : 'tr-margin-left-12px'">
        Users
      </div> 
      <div class="mt-4 tr-onboarding-sidebarmd-text c-pointer"
        (click)="toggleSidebarView('RULE_MATA_DATA')"
        [ngClass]="selectedSidebarView == 'RULE_MATA_DATA' ? 'tr-sidebar-tab-active' : 'tr-margin-left-12px'">
        Approval Workflow
      </div>
    </div> -->
  </div>
</div>

<div class="bg-white-new" [ngClass]="!isOpen? 'tr-margin-left ': 'md:ml-6'">
  <div class="position-relative mx-2">
    <div class="col-12 container  px-0">
      <!-- <div class=" nav-bg text-start" >
        <ul class="" style="list-style: none;" class="ps-0">
          <li class="list-class" (click)="toggleSidebarView('ONBOARDING_ORG_PROFILE')" [ngClass]="this.selectedSidebarView == 'ONBOARDING_ORG_PROFILE' ? 'btn-active' : 'btn-default'">
            Org Profile
          </li>
          <li class="list-class" (click)="toggleSidebarView('ONBOARDING_COMPANIES')" [ngClass]="this.selectedSidebarView == 'ONBOARDING_COMPANIES' ? 'btn-active' : 'btn-default'">
            Companies
          </li>
          <li class="list-class"  (click)="toggleSidebarView('ONBOARDING_PLANT')" [ngClass]="this.selectedSidebarView == 'ONBOARDING_PLANT' ? 'btn-active' : 'btn-default'">
           Plant
          </li>
          <li class="list-class"  (click)="toggleSidebarView('ONBOARDING_USERS')" [ngClass]="this.selectedSidebarView == 'ONBOARDING_USERS' ? 'btn-active' : 'btn-default'">
            Users
          </li>
          <li class="list-class"  (click)="toggleSidebarView('RULE_MATA_DATA')" [ngClass]="this.selectedSidebarView == 'RULE_MATA_DATA' ? 'btn-active' : 'btn-default'">
            Rule 
          </li>
         
        </ul>
      </div> -->

    </div>

    <!-- <div class="col-12">
      <div class="row">
        <div class="col-md-1 bg-white border px-0 d-none d-md-inline-block fixed-sidebar-class ">
          <ul style="list-style: none;" class="ps-1 text-center h-100">
            <li class="" (click)="toggleSidebarView('ONBOARDING_ORG_PROFILE')"
              [ngClass]="this.selectedSidebarView == 'ONBOARDING_ORG_PROFILE' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/InHouse-Users-Icon.svg" class="svg-icon-class-small c-pointer mb-1">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Org Profile
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('ONBOARDING_COMPANIES')"
              [ngClass]="this.selectedSidebarView == 'ONBOARDING_COMPANIES' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Home-Icon.svg" class="svg-icon-class-small c-pointer mb-1">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Companies
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('ONBOARDING_PLANT')"
              [ngClass]="this.selectedSidebarView == 'ONBOARDING_PLANT' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Catalogue.svg" class="svg-icon-class-small c-pointer mb-1">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Plant
              </span>
            </li>
            <hr class="hr-light">
            <li class="" (click)="toggleSidebarView('ONBOARDING_USERS')"
              [ngClass]="this.selectedSidebarView == 'ONBOARDING_USERS' ? 'list-active' : 'my-1'">
              <div>
                <img src="assets/icons/Catalogue.svg" class="svg-icon-class-small c-pointer mb-1">
              </div>
              <span class="span-class-new d-none d-md-inline-block">
                Plant
              </span>
            </li>
            <hr class="hr-light">
          </ul>
        </div>

        <div class="col-md-11 col-12  bg-white-new margin-left-class">
          <app-breadcrumb></app-breadcrumb> 
          <div class="row d-block w-100 d-md-none border p-2 bg-white-new shadow sidebar-position">
            <div class=" col-12">
              <ul style="list-style: none;" class="ps-1 text-center mb-0">
                <li class="list-style-class list-padding-mobile" (click)="toggleSidebarView('ONBOARDING_ORG_PROFILE')"
                  [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ONBOARDING_ORG_PROFILE'}">
                  <div>
                    <img src="assets/icons/InHouse-Users-Icon.svg" class="svg-icon-class-md c-pointer mb-1">
                  </div>
                  <span class="small-grey-font">
                    Org Profile
                  </span>
                </li>
                <li class="list-style-class list-padding-mobile" (click)="toggleSidebarView('ONBOARDING_COMPANIES')"
                  [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ONBOARDING_COMPANIES'}">
                  <div>
                    <img src="assets/icons/Home-Icon.svg" class="svg-icon-class c-pointer mb-1">
                  </div>
                  <span class="small-grey-font">
                   Companies
                  </span>
                </li>
                <li class="list-style-class list-padding-mobile" (click)="toggleSidebarView('ONBOARDING_PLANT')"
                [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ONBOARDING_PLANT'}">
                  <div>
                    <img src="assets/icons/Catalogue.svg" class="svg-icon-class c-pointer mb-1">
                  </div>
                  <span class="small-grey-font">
                    Plant
                  </span>
                </li>
                <li class="list-style-class list-padding-mobile" (click)="toggleSidebarView('ONBOARDING_USERS')"
                [ngClass]="{'list-active-mobile' : this.selectedSidebarView == 'ONBOARDING_USERS'}">
                  <div>
                    <img src="assets/icons/Catalogue.svg" class="svg-icon-class c-pointer mb-1">
                  </div>
                  <span class="small-grey-font">
                    Users
                  </span>
                </li>
              </ul>
            </div>
          </div>

      

        </div>
      </div>

    </div> -->

  </div>

  <div>
    <ng-container [ngTemplateOutlet]="selectedSidebarView == 'ONBOARDING_ORG_PROFILE' ? orgProfile
    : selectedSidebarView == 'ONBOARDING_COMPANIES' ? companies
    : selectedSidebarView == 'ONBOARDING_USERS' ? users
    : selectedSidebarView == 'RULE_MATA_DATA' ? rule
    : selectedSidebarView == 'ONBOARDING_COMPANY_DETAILS' ? companyDetails
    : selectedSidebarView == 'ONBOARDING_EMAIL' ? email
    : selectedSidebarView == 'ONBOARDING_PLANT' ? plant: empty">
    </ng-container>
  </div>
</div>

<ng-template #orgProfile>
  <app-onboarding-org-profile></app-onboarding-org-profile>
</ng-template>
<ng-template #companies>
  <app-onboarding-companies (onNavigateInternalPage)="toggleSidebarView($event)"></app-onboarding-companies>
</ng-template>
<ng-template #companyDetails>
  <!-- (sidebarClose)="toggleDrawer($event)" -->
  <app-onboarding-view-company-tabs
    (onNavigateInternalPage)="toggleSidebarView($event)"></app-onboarding-view-company-tabs>
</ng-template>
<ng-template #plant>
  <app-onboarding-plants></app-onboarding-plants>
</ng-template>
<ng-template #users>
  <app-onboarding-users></app-onboarding-users>
</ng-template>

<ng-template #rule>
  <app-rule-mata-data></app-rule-mata-data>
</ng-template>
<ng-template #email>
  <app-email-template-config></app-email-template-config>
</ng-template>
<!-- Empty Template -->
<ng-template #empty></ng-template>