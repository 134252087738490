import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppWorkflowUiDto } from 'src/app/shared/models/AppWorkflowUiDto';
import { CacheDesignationsDto } from 'src/app/shared/models/CacheDesignationsDto';
import { OnboardingWorkflow } from 'src/app/shared/models/OnboardingWorkflow';
import { AWFAppliedUiDto } from 'src/app/shared/models/approval-workflow/AWFAppliedUiDto';
import { ApprovalWorkflowUiDto } from 'src/app/shared/models/approval-workflow/ApprovalWorkflowUiDto';
import { ApplicationStartupCacheService } from 'src/app/shared/services/application-startup-cache.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';

@Component({
  selector: 'app-mata-data-view',
  templateUrl: './mata-data-view.component.html',
  styleUrls: ['./mata-data-view.component.sass']
})
export class MataDataViewComponent implements OnInit {
  @Input() approvalWorkflowUiDto!: ApprovalWorkflowUiDto;

  designationList: CacheDesignationsDto[] = [];
  workflowsList: AppWorkflowUiDto[] = [];
  appliedUiDtos: AWFAppliedUiDto[] = [];
  onboardingWorkflows: OnboardingWorkflow[] = [];
  selectedDesignationOne?: CacheDesignationsDto;
  selectedDesignationTwo?: CacheDesignationsDto;
  selectedDesignationThree?: CacheDesignationsDto;

  isLoading: boolean = false;

  constructor(
    private ngbModal: NgbModal,
    private onboardingService: OnboardingService,
    private appStartupService: ApplicationStartupCacheService,
  ) { }

  ngOnInit(): void {

    let applicationStartupDto = this.appStartupService.getApplicationStartupDto;
    this.designationList = applicationStartupDto?.cacheDesignationsDtos ?? []
    this.onboardingWorkflows = this.onboardingService.getOnboardingWorkflows;

    this.appliedUiDtos = this.approvalWorkflowUiDto.appliedUiDtos ?? [];
    this.appliedUiDtos = this.appliedUiDtos.sort((a, b) => Number(a.sequence) - Number(b.sequence));


    // let chainDefineUiDto1 = this.approvalWorkflowUiDto.awfDefineUiDto?.chainDefineUiDtos?.find(item => item.sequence == 1);
    // this.selectedDesignationOne = this.designationList.find(item => item.code == chainDefineUiDto1?.roleCode1);

    // if (this.appliedUiDtos.length > 1) {
    //   let chainDefineUiDto2 = this.approvalWorkflowUiDto.awfDefineUiDto?.chainDefineUiDtos?.find(item => item.sequence == 2);
    //   this.selectedDesignationTwo = this.designationList.find(item => item.code == chainDefineUiDto2?.roleCode1);
    // }

    // if (this.appliedUiDtos.length > 2) {
    //   let chainDefineUiDto3 = this.approvalWorkflowUiDto.awfDefineUiDto?.chainDefineUiDtos?.find(item => item.sequence == 3);
    //   this.selectedDesignationThree = this.designationList.find(item => item.code == chainDefineUiDto3?.roleCode1);
    // }
  }

  getOnboardingWorkflow(workflowCode: string) {
    return this.onboardingWorkflows.find(item => item.code == workflowCode)?.name;
  }

  closeModal() {
    this.ngbModal.dismissAll()
  }
}
