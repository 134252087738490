import { AuctionTargetedBiddersNotificationEntityDto } from '../models/AuctionPremiumSuppliersEntityDto';


import { AuctionEntityDto } from '../models/user/AuctionEntityDto';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, firstValueFrom, tap, timeout } from "rxjs";

import { ErrorService } from "./error.service";
import { AuctionStatus } from '../models/user/AuctionStatus';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ApplicationUtils } from '../util/ApplicationUtils';
import { ApplicationConstants } from '../util/ApplicationConstants';
import { SourcingEventWrapper } from '../models/user/SourcingEventWrapper';
import { SourcingEventDto } from '../models/user/SourcingEventDto';
import { EventEnum } from '../enums/EventEnum';
import { AuctionCardWrapperDto } from '../models/AuctionCardWrapperDto';
import { RfxUiDto } from '../models/rfx/RfxUiDto';
import { PurchaseRequestUiDto } from '../models/rfx/PurchaseRequestUiDto';
import { ProjectUiDto } from '../models/ProjectUiDto';

@Injectable({
  providedIn: 'root'
})
export class AdminSourcingEventsDataHolderService {

  private allEvents: SourcingEventDto[] = [];
  private projectsUiDtosList: ProjectUiDto[] = [];
  allPurchaseRequests: PurchaseRequestUiDto[] = [];

  filteredEvents$ = new BehaviorSubject<SourcingEventDto[]>([]);
  allEventsList$ = new BehaviorSubject<SourcingEventDto[]>([]);
  private postEventsList$ = new BehaviorSubject<SourcingEventDto[]>([]);
  private projectsUiDtosList$ = new BehaviorSubject<ProjectUiDto[]>([]);

  private purchaseRequestsList$ = new BehaviorSubject<PurchaseRequestUiDto[]>([]);
  private runningEventsList$ = new BehaviorSubject<SourcingEventDto[]>([]);
  private upcomingEventsList$ = new BehaviorSubject<SourcingEventDto[]>([]);
  private draftEventsList$ = new BehaviorSubject<SourcingEventDto[]>([]);

  isNotificationAvailable$ = new BehaviorSubject<boolean>(false);
  isShowNotification$ = new BehaviorSubject<boolean>(false);

  sortBy?: string = 'Recent';
  currentEventType?: string = '';

  _selectedEvent$ = new BehaviorSubject<SourcingEventDto | null>(null);
  selectedEvent?: SourcingEventDto;

  _selectedAuction$ = new BehaviorSubject<AuctionEntityDto | null>(null);
  selectedAuction?: AuctionEntityDto;

  _selectedRfx$ = new BehaviorSubject<RfxUiDto | null>(null);
  selectedRfx?: RfxUiDto;

  _allEventsDataLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private errorService: ErrorService) { }


  get getFilteredAuctions$() { return this.filteredEvents$.asObservable() }
  getSelectedSourcingEvent() { return this.selectedEvent; }
  getSelectedAuction() { return this.selectedAuction; }
  getSelectedRfx() { return this.selectedRfx; }
  getSort() { return this.sortBy }
  getCurrentAuctionType() { return this.currentEventType }
  get selectedEvent$() { return this._selectedEvent$.asObservable() }
  get selectedAuction$() { return this._selectedAuction$.asObservable() }
  get getAllEventsList$() { return this.allEventsList$.asObservable() }
  get getPostEventsList$() { return this.postEventsList$.asObservable() }
  get getAllEventsDataLoaded$() { return this._allEventsDataLoaded$.asObservable() }
  get getNotificationAvailable$() { return this.isNotificationAvailable$.asObservable() }
  get getShowNotification$() { return this.isShowNotification$.asObservable() }

  get getPurchaseRequestsList$() { return this.purchaseRequestsList$; };
  get getProjectUiDtosList$() { return this.projectsUiDtosList$.asObservable(); };
  get getRunningEventsList$() { return this.runningEventsList$; };
  get getUpcomingEventsList$() { return this.upcomingEventsList$; };
  get getDraftEventsList$() { return this.draftEventsList$; };

  get selectedRfx$() { return this._selectedRfx$.asObservable() }

  setSort(sort: string) { this.sortBy = sort }
  setCurrentAuctionType(filter: string) { this.currentEventType = filter }


  setNotificationAvailable(notificationAvailable: boolean){
    this.isNotificationAvailable$.next(notificationAvailable);
  }

  setShowNotification(showNotification: boolean){
    this.isShowNotification$.next(showNotification);
  }

  setSelectedSourcingEvent(auction: SourcingEventDto) {
    this.selectedEvent = auction;
    this._selectedEvent$.next(this.selectedEvent!);
  }

  setSelectedRfx(rfxEntityDto: RfxUiDto){
    this.selectedRfx = rfxEntityDto;
    this._selectedRfx$.next(this.selectedRfx);
  }


  // handle auction aggregate
  updateSourcingEventsWithAggregationData(updatedAuctionCardWrapperDtos: AuctionCardWrapperDto[]) {

  }

  setSelectedEventByEventId(eventId: string) {
    if (this.allEvents && this.allEvents.length > 0) {
      let event = this.allEvents?.find(item => item.eventId == eventId);
      this.selectedEvent = event;
      this._selectedEvent$.next(this.selectedEvent!);
    } else {
      console.error("All events data not available, setSelectedAuctionByAuctionId() will fail");
    }
  }

  setSelectedAuction(auctionEntityDto: AuctionEntityDto){
    this.selectedAuction = auctionEntityDto;
    this._selectedAuction$.next(this.selectedAuction);
  }

  addNewAuctionToSourcingEvent(auctionEntityDto: AuctionEntityDto) {
    this.allEvents?.push(this.convertAuctionDtoToSourcingEvent(auctionEntityDto));
  }

  convertAuctionDtoToSourcingEvent(auctionEntityDto: AuctionEntityDto): SourcingEventDto{
    let sourcingEvent = new SourcingEventDto();
    sourcingEvent.type = EventEnum.AUCTION;
    sourcingEvent.sequenceNo = auctionEntityDto.sequenceNo;
    sourcingEvent.eventName = auctionEntityDto.auctionName;
    sourcingEvent.currency = auctionEntityDto.currency;
    sourcingEvent.sourcingEventImage = auctionEntityDto.auctionImage;
    sourcingEvent.antiSnipe = auctionEntityDto.antiSnipe;
    sourcingEvent.auctionType = auctionEntityDto.auctionType;
    sourcingEvent.singleActivity = auctionEntityDto.singleLot;
    sourcingEvent.noOfActiveActivity = auctionEntityDto.noOfActiveLots;
    sourcingEvent.notOfActivity = auctionEntityDto.notOfLots;
    sourcingEvent.createdDate = auctionEntityDto.createdDate;
    sourcingEvent.startDate = auctionEntityDto.startDate;
    sourcingEvent.startTime = auctionEntityDto.startTime;
    sourcingEvent.endDate = auctionEntityDto.endDate;
    sourcingEvent.endTime = auctionEntityDto.endTime
    sourcingEvent.active = auctionEntityDto.active;
    sourcingEvent.timeZone = auctionEntityDto.timeZone;
    sourcingEvent.eventId = auctionEntityDto.auctionId;
    sourcingEvent.status = auctionEntityDto.status;

    return sourcingEvent;
  }

  convertRFXDtoToSourcingEvent(rfxEntityDto: RfxUiDto): SourcingEventDto{
    let sourcingEvent = new SourcingEventDto();
    sourcingEvent.type = rfxEntityDto.rfxType;
    sourcingEvent.sequenceNo = rfxEntityDto.sequenceNo;
    sourcingEvent.eventName = rfxEntityDto.rfxTitle;
    sourcingEvent.currency = rfxEntityDto.currency;

    sourcingEvent.auctionType = rfxEntityDto.rfxType;
    sourcingEvent.noOfActiveActivity = rfxEntityDto.noOfActiveSubcategories;
    sourcingEvent.notOfActivity = rfxEntityDto.noOfActiveSubcategories;
    sourcingEvent.createdDate = rfxEntityDto.createdDate;
    sourcingEvent.startDate = rfxEntityDto.startDate;
    sourcingEvent.startTime = rfxEntityDto.startTime;
    sourcingEvent.endDate = rfxEntityDto.endDate;
    sourcingEvent.endTime = rfxEntityDto.endTime
    sourcingEvent.active = rfxEntityDto.active;
    sourcingEvent.timeZone = rfxEntityDto.timeZone;
    sourcingEvent.eventId = rfxEntityDto.rfxId;
    sourcingEvent.status = rfxEntityDto.status;

    return sourcingEvent;
  }

  setAllProjectsUiDtos(projectUiDtos: ProjectUiDto[]) {
    this.projectsUiDtosList = projectUiDtos;
    this.projectsUiDtosList$.next(projectUiDtos);
  }

  updateProjectsUiDtosList(projectUiDto: ProjectUiDto) {
    let index = this.projectsUiDtosList.findIndex(item => item.projectCode == projectUiDto.projectCode);
    if (index > -1) {
      Object.assign(this.projectsUiDtosList[index], projectUiDto);
    } else {
      this.projectsUiDtosList.push(projectUiDto);
    }
    this.projectsUiDtosList$.next(this.projectsUiDtosList);
  }

  loadSourcingEventDtos() {
    this.fetchSourcingEvents().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let sourcingEventWrapper = apiResponseDto.data as SourcingEventWrapper;
          this.updateSourcingEventWrapper(sourcingEventWrapper);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  async loadPurchaseRequests(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getPurchaseRequests(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let purchaseRequestEntityDtos = apiResponseDto.data as PurchaseRequestUiDto[];
        this.updatePurchaseRequestsList(purchaseRequestEntityDtos);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadAllProjects(orgCode: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.getProjects(orgCode));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let projectUiDtos = apiResponseDto.data as ProjectUiDto[];
        this.setAllProjectsUiDtos(projectUiDtos);
      }
    } catch (error) {
      console.error(error);
    }
  }

  updatePurchaseRequestsList(purchaseRequestEntityDtos: PurchaseRequestUiDto[]) {
    this.allPurchaseRequests = purchaseRequestEntityDtos;
    this.purchaseRequestsList$.next(purchaseRequestEntityDtos);
  }

  updatePurchaseRequest(purchaseRequestEntityDto: PurchaseRequestUiDto) {
    let index = this.allPurchaseRequests.findIndex(item => item.prId == purchaseRequestEntityDto.prId);

    if (index > -1) {
      Object.assign(this.allPurchaseRequests[index], purchaseRequestEntityDto);
    } else {
      this.allPurchaseRequests.push(purchaseRequestEntityDto)
    }
    
    this.purchaseRequestsList$.next(this.allPurchaseRequests);
  }

  updateRfxChangesFromFirestore(rfxList: RfxUiDto[]){
    let allEventsList = this.allEventsList$.value;
    let updatesSourcingEvents = rfxList.map(item => this.convertRFXDtoToSourcingEvent(item));
    updatesSourcingEvents.forEach(updateDto => {
      let isEventExistsInAllList = allEventsList.find(m => m.eventId == updateDto.eventId);

      if (isEventExistsInAllList) {
        let index = allEventsList.findIndex(m => m.eventId == updateDto.eventId);
        if (updateDto.status == AuctionStatus.DISCARD) {
          allEventsList.splice(index, 1)
        } else {
          Object.assign(allEventsList[index], updateDto);
        }
        this.allEventsList$.next(allEventsList);
      } else {
        if (updateDto?.status != AuctionStatus.DISCARD) {
          allEventsList.push(updateDto);
        this.allEventsList$.next(allEventsList);
        }
      }


      if (this.selectedRfx && this.selectedRfx.rfxId == updateDto.eventId) {
        let rfx = rfxList.find(m => m.rfxId == updateDto.eventId);
        Object.assign(this.selectedRfx, rfx);
        this.setSelectedRfx(this.selectedRfx);
      }

    })

  }

  updateAuctionsChangesFromFirestore(auctionsList: AuctionEntityDto[]) {
    let runningAuctionsList = this.runningEventsList$.value;
    let upcomingAuctionsList = this.upcomingEventsList$.value;
    let closedAuctionsList = this.postEventsList$.value;
    let allAuctionsList = this.allEventsList$.value;
    let draftAuctionsList = this.draftEventsList$.value;

    let updatesSourcingEvents = auctionsList.map(item => this.convertAuctionDtoToSourcingEvent(item));
    updatesSourcingEvents.forEach(updateDto => {
      let isAuctionExistsInRunningList = runningAuctionsList.find(m => m.eventId == updateDto.eventId);
      let isAuctionExistsInUpcomingList = upcomingAuctionsList.find(m => m.eventId == updateDto.eventId);
      let isAuctionExistsInClosedList = closedAuctionsList.find(m => m.eventId == updateDto.eventId);
      let isAuctionExistsInAllList = allAuctionsList.find(m => m.eventId == updateDto.eventId);
      let isAuctionExistsInDraftList = draftAuctionsList.find(m => m.eventId == updateDto.eventId);

      if (isAuctionExistsInAllList) {
        let index = allAuctionsList.findIndex(m => m.eventId == updateDto.eventId);
        if (updateDto.status == AuctionStatus.DISCARD) {
          allAuctionsList.splice(index, 1)
        } else {
          Object.assign(allAuctionsList[index], updateDto);
        }
        this.allEventsList$.next(allAuctionsList);
      } else {
        if (updateDto?.status != AuctionStatus.DISCARD) {
          allAuctionsList.push(updateDto);
          this.allEventsList$.next(allAuctionsList);
        }
      }

      if (isAuctionExistsInDraftList) {
        let index = draftAuctionsList.findIndex(m => m.eventId == updateDto.eventId);
        if ((updateDto.status == AuctionStatus.DISCARD) ||
          (updateDto.status == AuctionStatus.LIVE_WAIT) ||
          (updateDto.status == AuctionStatus.LIVE) ||
          (updateDto.status == AuctionStatus.CLOSE)) {
          draftAuctionsList.splice(index, 1)
        } else if (updateDto.status == AuctionStatus.DRAFT) {
          Object.assign(draftAuctionsList[index], updateDto);
        }
        this.draftEventsList$.next(draftAuctionsList);
      } else {
        // add item to draft list
        if (updateDto?.status! == AuctionStatus.DRAFT) {
          draftAuctionsList.push(updateDto);
          this.draftEventsList$.next(draftAuctionsList);
        }
      }

      if (isAuctionExistsInUpcomingList) {
        let index = upcomingAuctionsList.findIndex(m => m.eventId == updateDto.eventId);
        if ((updateDto.status == AuctionStatus.DISCARD) ||
          (updateDto.status == AuctionStatus.DRAFT) ||
          (updateDto.status == AuctionStatus.LIVE) ||
          (updateDto.status == AuctionStatus.CLOSE)) {
          upcomingAuctionsList.splice(index, 1)
        } else {
          Object.assign(upcomingAuctionsList[index], updateDto);
        }
        this.upcomingEventsList$.next(upcomingAuctionsList);
      } else {
        if (updateDto?.status == AuctionStatus.LIVE_WAIT) {
          upcomingAuctionsList.push(updateDto);
          this.upcomingEventsList$.next(upcomingAuctionsList);
        }
      }

      if (isAuctionExistsInRunningList) {
        let index = runningAuctionsList.findIndex(m => m.eventId == updateDto.eventId);
        if ((updateDto.status == AuctionStatus.DISCARD) ||
          (updateDto.status == AuctionStatus.DRAFT) ||
          (updateDto.status == AuctionStatus.LIVE_WAIT) ||
          (updateDto.status == AuctionStatus.CLOSE)) {
          runningAuctionsList.splice(index, 1)
        } else {
          Object.assign(runningAuctionsList[index], updateDto);
        }
        this.runningEventsList$.next(runningAuctionsList);
      } else {
        if (updateDto?.status == AuctionStatus.LIVE) {
          runningAuctionsList.push(updateDto);
          this.runningEventsList$.next(runningAuctionsList);
        }
      }

      if (isAuctionExistsInClosedList) {
        let index = closedAuctionsList.findIndex(m => m.eventId == updateDto.eventId);
        if ((updateDto.status == AuctionStatus.DISCARD) ||
          (updateDto.status == AuctionStatus.DRAFT) ||
          (updateDto.status == AuctionStatus.LIVE_WAIT) ||
          (updateDto.status == AuctionStatus.LIVE)) {
          closedAuctionsList.splice(index, 1)
        } else {
          Object.assign(closedAuctionsList[index], updateDto);
        }
        this.postEventsList$.next(closedAuctionsList);
      } else {
        if (updateDto?.status == AuctionStatus.CLOSE) {
          closedAuctionsList.push(updateDto);
          this.postEventsList$.next(closedAuctionsList);
        }
      }

      if (this.selectedEvent && this.selectedEvent.eventId == updateDto.eventId) {
        Object.assign(this.selectedEvent, updateDto);
        this.setSelectedSourcingEvent(this.selectedEvent);
      }

    })
  }

  clearSourcingEventsData() {
    this.allEventsList$.next([]);
    this.runningEventsList$.next([]);
    this.upcomingEventsList$.next([]);
    this.draftEventsList$.next([]);

    this.filteredEvents$.next([]);
    this.postEventsList$.next([]);
  }

  getAuctionSearchData(auctionHouseId: string, statusList: string[]) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('statusString', JSON.stringify(statusList));
    return this.http.get<ServerAPIResponseDto>('auctionSearchData', { params }).pipe(timeout(600000),
      tap(_ => console.log("Search data fetched successfully for auctionHouseId: " + auctionHouseId)),
      catchError(this.errorService.handleError<any>("Error while fetching search data for auctionHouseId: " + auctionHouseId)));
  }

  updateSourcingEventWrapper(sourcingEventWrapper: SourcingEventWrapper) {
    this.allEvents = [];
    // this.clearAuctionData();
    this.draftEventsList$.next([]);
    this.upcomingEventsList$.next([]);
    this.runningEventsList$.next([]);
    this.postEventsList$.next([]);

    if (sourcingEventWrapper.draftAuctionWrapperDtos && sourcingEventWrapper.draftAuctionWrapperDtos.length > 0) {
      let draftList = sourcingEventWrapper.draftAuctionWrapperDtos.map(item => item!);
      this.allEvents = this.allEvents.concat(draftList);
      this.draftEventsList$.next(draftList);
    }

    if (sourcingEventWrapper.liveAuctionWrapperDtos && sourcingEventWrapper.liveAuctionWrapperDtos.length > 0) {
      let liveList = sourcingEventWrapper.liveAuctionWrapperDtos.map(item => item!);
      this.allEvents = this.allEvents.concat(liveList);
      this.runningEventsList$.next(liveList)
    }

    if (sourcingEventWrapper.upcomingAuctionWrapperDtos && sourcingEventWrapper.upcomingAuctionWrapperDtos.length > 0) {
      let upcomingList = sourcingEventWrapper.upcomingAuctionWrapperDtos.map(item => item!);
      this.allEvents = this.allEvents.concat(upcomingList);
      this.upcomingEventsList$.next(upcomingList);
    }

    if (sourcingEventWrapper.closeAuctionWrapperDtos && sourcingEventWrapper.closeAuctionWrapperDtos.length > 0) {
      let closeList = sourcingEventWrapper.closeAuctionWrapperDtos.map(item => item!);
      this.allEvents = this.allEvents.concat(closeList)
      this.postEventsList$.next(closeList);
    }


    let sortedAuctions = this.allEvents.sort((a, b) => ApplicationUtils.getRemainingTime(a.endDate!, a.timeZone!) - ApplicationUtils.getRemainingTime(b.endDate!, b.timeZone!));
    this.allEventsList$.next(sortedAuctions);

    if (sortedAuctions && sortedAuctions.length > 0) {
      this.allEvents = sortedAuctions;
      // this.calendarService.setAllAuctions(sortedAuctions);

      if (this._selectedEvent$.value && this.allEvents && this.allEvents.length > 0) {
        let auctionId = this._selectedEvent$.value.eventId;
        let auctionEntityDto = this.allEvents.find(item => item.eventId == auctionId);
        if (auctionEntityDto) {
          this.setSelectedSourcingEvent(auctionEntityDto);
        }

      }
    }

    this._allEventsDataLoaded$.next(true);
  }

  updatePostAuctionList(auction: AuctionEntityDto){
    this.postEventsList$.next([this.convertAuctionDtoToSourcingEvent(auction)]);
  }


  // API CALLS

  //TODO rename API call
  fetchSourcingEvents() {
    return this.http.get<ServerAPIResponseDto>('auctionCardWrapperDtosRemainingForAdmin').pipe(
      tap(_ => console.log("Get auctionCardWrapperDtosRemainingForAdmin")),
      catchError(this.errorService.handleError<any>("Error while getting auctionCardWrapperDtosRemainingForAdmin")));
  }

  getAuctionPremiumSuppliers(auctionHouseId: string, auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionHouseId', auctionHouseId).set('auctionId', auctionId).set('lotId', lotId);;
    return this.http.get<ServerAPIResponseDto>('auctionTargetedBiddersNotifications', { params }).pipe(
      tap(_ => console.log("Get auctionPremiumSuppliers")),
      catchError(this.errorService.handleError<any>("Error while getting auctionPremiumSuppliers")));
  }

  getGlobalBidder() {
    // let params = new HttpParams().set('auctionHouseId', auctionHouseId);
    return this.http.get<ServerAPIResponseDto>('onboardUsers').pipe(
      tap(_ => console.log("Get onboardUsers")),
      catchError(this.errorService.handleError<any>("Error while getting onboardUsers")));
  }


  getAuctionActivity(auctionId: string) {
    let params = new HttpParams().set('auctionId', auctionId);
    return this.http.get<ServerAPIResponseDto>('auctionActivities', { params }).pipe(
      tap(_ => console.log("Get auctionActivities")),
      catchError(this.errorService.handleError<any>("Error while getting auctionPremiumSuppliers")));
  }

  /// API CALLS
  saveAuctionPremiumSuppliers(auctionPremiumSuppliersDto: AuctionTargetedBiddersNotificationEntityDto) {
    return this.http.post<ServerAPIResponseDto>('auctionTargetedBiddersNotifications', auctionPremiumSuppliersDto).pipe(
      tap(_ => console.log("Saved auctionPremiumSuppliersDto to DB" + auctionPremiumSuppliersDto.auctionId)),
      catchError(this.errorService.handleError<any>("Error while Saving Auction House to DB" + auctionPremiumSuppliersDto.auctionId)))
  }

  savePurchaseRequestDetails(purchaseRequestEntityDto: PurchaseRequestUiDto) {
    return this.http.post<ServerAPIResponseDto>('purchaseRequests', purchaseRequestEntityDto).pipe(
      tap(_ => console.log("Saved PR to DB" + purchaseRequestEntityDto.title)),
      catchError(this.errorService.handleError<any>("Error while Saving PR to DB" + purchaseRequestEntityDto.title)))
  }

  getPurchaseRequests(orgCode: string): Observable<any> {
    let params = new HttpParams().set('orgCode', orgCode);
    return this.http.get<ServerAPIResponseDto>('purchaseRequests', { params }).pipe(
      tap(_ => console.log("Getting purchaseRequests Successfully")),
      catchError(this.errorService.handleError("Error while getting purchaseRequests")))
  }

  getProjects(orgCode: string): Observable<any> {
    return this.http.get<ServerAPIResponseDto>(`projects/${orgCode}`).pipe(
      tap(_ => console.log("Getting projects Successfully")),
      catchError(this.errorService.handleError("Error while getting projects")))
  }

  saveProjectDetails(projectUiDto: ProjectUiDto) {
    return this.http.post<ServerAPIResponseDto>('projects', projectUiDto).pipe(
      tap(_ => console.log("Saved Project to DB" + projectUiDto.name)),
      catchError(this.errorService.handleError<any>("Error while Saving Project to DB" + projectUiDto.name)))
  }
}
