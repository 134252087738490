import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError, BehaviorSubject, Observable } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { UserAuctionRegistrationDto } from '../models/user/UserAuctionRegistrationDto';
import { ErrorService } from './error.service';
import { AuctionHouseDto } from '../models/user/AuctionHouseDto';
import { DocumentData } from '@angular/fire/firestore';
import { CalendarService } from './calendar.service';
import { MasterDataDto } from '../models/MasterDataDto';
import { CategoryDto, CategoryParentWrapperDto } from '../models/CategoryDto';

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  _currentAuctionHouse$ = new BehaviorSubject<AuctionHouseDto | null>(null);

  masterCategories: CategoryParentWrapperDto[] = []

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private calendarService: CalendarService
  ) { }


  get getCurrentAuctionHouse$() { return this._currentAuctionHouse$; };


  clearData() {

  }

  updateMasterData(activeCategories: any[]) {
    this.masterCategories= activeCategories.filter(item => Boolean(item.active) == true);

  }

  updateCurrentAuctionHouse(doc: DocumentData) {
    let auctionHouse = doc as AuctionHouseDto
    this._currentAuctionHouse$.next(auctionHouse);
  }


  auctionHouseChartData(): Observable<any> {

    return this.httpClient.get<ServerAPIResponseDto>('auctionHouseChartData').pipe(

      tap(_ => console.log("Auction House Chart Data")),

      catchError(this.errorService.handleError("Error while getting auction house chart data")))

  }

  // API CALLS
  saveUserAuctionRegistration(userAuctionRegistration: UserAuctionRegistrationDto) {
    return this.httpClient.post<ServerAPIResponseDto>('userAuctionRegistration', userAuctionRegistration).pipe(
      tap(_ => console.log("Save user auction registration" + userAuctionRegistration.userId)),
      catchError(this.errorService.handleError<any>("Error while user auction registration" + userAuctionRegistration.userId)));
  }


  emailUnsubscribe(emailId: string) {
    let params = { emailId: emailId };
    return this.httpClient.get<ServerAPIResponseDto>('emails/unsubscribe', { params: params }).pipe(
      tap(_ => console.log("unsubscribe email" + emailId)),
      catchError(this.errorService.handleError<any>("Error while unsubscribe email" + emailId)));
  }

}
