import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { EntityActionLabelEnum } from 'src/app/shared/enums/EntityActionLabelEnum';
import { EntityActionTypeEnum } from 'src/app/shared/enums/EntityActionTypeEnum';
import { EventEnum } from 'src/app/shared/enums/EventEnum';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { EntityActionDto } from 'src/app/shared/models/EntityActionDto';
import { TenderQuestionnaireTemplateDto } from 'src/app/shared/models/tender/TenderQuestionnaireTemplateDto';
import { TenderQuestionnaireWrapperDto } from 'src/app/shared/models/tender/TenderQuestionnaireWrapperDto';
import { TenderSectionTemplateDto } from 'src/app/shared/models/tender/TenderSectionTemplateDto';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { TenderManagementService } from 'src/app/shared/services/tender-management.service';
import { TenderManagementActions } from 'src/app/shared/state-management/tender/tender.actions';
import { selectTenderOpportunityWrapperDto, selectTenderWrapperUiDto, selectEntityActionDtos } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-new-tender-technical-section-drawer',
  templateUrl: './new-tender-technical-section-drawer.component.html',
  styleUrls: ['./new-tender-technical-section-drawer.component.sass']
})
export class NewTenderTechnicalSectionDrawerComponent implements OnInit, AfterViewInit, OnDestroy {
  ctrlGuideLines: FormControl = new FormControl('');
  ctrlSectionsName: FormControl = new FormControl('', Validators.required);

  tenderWrapperUiDto?: TenderWrapperUiDto;
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;
  tenderQuestionnaireWrapperDto?: TenderQuestionnaireWrapperDto;

  tenderQuestionnaireTemplateDto?: TenderQuestionnaireTemplateDto;
  technicalSectionTemplateDtos: TenderSectionTemplateDto[] = [];

  selectedTenderSectionTemplate?: TenderSectionTemplateDto;

  applicationConstants: typeof ApplicationConstants = ApplicationConstants;
  isShowNewSection: boolean = false;
  sectionScoreValidationFailed: boolean = false;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(false);
  errorMessage$ = new BehaviorSubject<string>('');

  tenderWrapperUiDtoSubscription$?: Subscription;
  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  constructor(
    private store: Store,
    private drawerService: DrawerService,
    private tenderManagementService: TenderManagementService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
        this.tenderQuestionnaireWrapperDto = this.tenderOpportunityWrapperDto?.tenderQuestionnaireWrapperDto;

        if (this.tenderQuestionnaireWrapperDto) {
          this.tenderQuestionnaireTemplateDto = this.tenderQuestionnaireWrapperDto.tenderQuestionnaireTemplateDto;

          let tenderSectionTemplateDtos = this.tenderQuestionnaireWrapperDto.tenderSectionTemplateDtos || [];
          this.technicalSectionTemplateDtos = tenderSectionTemplateDtos.filter(item => item.envelopeType == SourcingEnvelopeType.TECHNICAL);

          if (this.selectedTenderSectionTemplate) {
            this.selectedTenderSectionTemplate = this.technicalSectionTemplateDtos.find(item => item.sectionId == this.selectedTenderSectionTemplate?.sectionId);
          }

          this.populateData();
        }
      } else {
        this.tenderOpportunityWrapperDto = undefined;
        this.tenderQuestionnaireWrapperDto = undefined;
        this.technicalSectionTemplateDtos = [];
      }
    })
  }

  ngAfterViewInit(): void {
  }

  populateData() {
    this.ctrlGuideLines.patchValue(this.tenderQuestionnaireTemplateDto?.guideLines);
  }

  toggleShowNewSection() {
    this.isShowNewSection = !this.isShowNewSection;
    if (!this.isShowNewSection) {
      // Reset section names field
    }
  }

  hideAddNewSection() {
    this.isShowNewSection = false;
  }

  addNewSections() {
    const sectionNames: string = this.ctrlSectionsName.value.trim();

    if (sectionNames) {
      // Split by new lines to get each section name
      const sectionNameArray = sectionNames.split('\n').filter(name => name.trim() !== '');

      // Create TenderSectionTemplateDto for each section name
      sectionNameArray.forEach((sectionName) => {
        let lastSequenceNo = this.technicalSectionTemplateDtos.length;
        let preSequenceText = ApplicationUtils.alphabetsList()[lastSequenceNo];

        const sectionDto: TenderSectionTemplateDto = {
          sectionId: UUID.UUID().toString(),
          sectionName: sectionName.trim(),
          questionnaireId: this.tenderQuestionnaireTemplateDto?.templateId!,
          orgCode: this.tenderWrapperUiDto?.orgCode,
          resourceId: this.tenderWrapperUiDto?.tenderId,
          subResourceId: this.tenderOpportunityWrapperDto?.tenderOpportunityId,
          resourceType: EventEnum.TENDER,
          preSequenceText: preSequenceText,
          sequenceNo: `${lastSequenceNo + 1}`,
          sectionScore: 0,
          envelopeType: SourcingEnvelopeType.TECHNICAL,
          noOfQuestions: 0
        };

        // Push the newly created section to the tenderSections array
        this.technicalSectionTemplateDtos.push(sectionDto);
        this.saveEntityActionDto(sectionDto.sectionId!, EntityActionLabelEnum.SECTION, EntityActionTypeEnum.ADD);
      });

      // Optionally, clear the FormControl after adding sections
      this.ctrlSectionsName.reset();
      this.hideAddNewSection();
    }
  }

  confirmRemoveSection(event: any, sectionId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Delete Section',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removeSection(sectionId);
      },
      reject: () => {
        console.log('Cancel Remove Section!');
      }
    });
  }

  removeSection(sectionId: string) {
    let index = this.technicalSectionTemplateDtos.findIndex(item => item.sectionId == sectionId);
    if (index != undefined && index > -1) {
      this.technicalSectionTemplateDtos.splice(index, 1);
      this.saveEntityActionDto(sectionId, EntityActionLabelEnum.SECTION, EntityActionTypeEnum.DELETE);
    }
  }

  async margeTenderWrapperUiDto() {
    let tenderWrapperUiDto = ApplicationUtils.clone(this.tenderWrapperUiDto) as TenderWrapperUiDto;
    let tenderOpportunityWrapperDto = ApplicationUtils.clone(this.tenderOpportunityWrapperDto) as TenderOpportunityWrapperDto;

    // TenderQuestionnaireWrapperDto
    let tenderQuestionnaireWrapperDto = new TenderQuestionnaireWrapperDto();
    if (this.tenderQuestionnaireWrapperDto) {
      tenderQuestionnaireWrapperDto = ApplicationUtils.clone(this.tenderQuestionnaireWrapperDto) as TenderQuestionnaireWrapperDto;
    }

    // TenderQuestionnaireTemplateDto
    let tenderQuestionnaireTemplateDto = ApplicationUtils.clone(this.tenderQuestionnaireTemplateDto) as TenderQuestionnaireTemplateDto;
    tenderQuestionnaireTemplateDto.guideLines = this.ctrlGuideLines.value;

    this.saveEntityActionDto(tenderQuestionnaireTemplateDto.templateId!, EntityActionLabelEnum.QUESTIONNAIRE, EntityActionTypeEnum.UPDATE);

    // Merge TenderQuestionnaireWrapperDto
    tenderQuestionnaireWrapperDto.tenderQuestionnaireTemplateDto = tenderQuestionnaireTemplateDto;
    tenderQuestionnaireWrapperDto.tenderSectionTemplateDtos = [...this.technicalSectionTemplateDtos];

    // Update TenderOpportunityWrapperDto
    tenderOpportunityWrapperDto.tenderQuestionnaireWrapperDto = tenderQuestionnaireWrapperDto;

    let index = tenderWrapperUiDto.tenderOpportunityWrapperDtos!.findIndex(item => item.tenderOpportunityId == tenderOpportunityWrapperDto.tenderOpportunityId);
    if (index != undefined && index > -1) {
      Object.assign(tenderWrapperUiDto.tenderOpportunityWrapperDtos![index], tenderOpportunityWrapperDto);
    } else {
      tenderWrapperUiDto.tenderOpportunityWrapperDtos!.push(tenderOpportunityWrapperDto);
    }

    this.saveEntityActionDto(tenderOpportunityWrapperDto.tenderOpportunityId!, EntityActionLabelEnum.OPPORTUNITY, EntityActionTypeEnum.UPDATE);

    // Final Tender Wrapper Ui Dto
    const entityActionDtos = await firstValueFrom(this.store.pipe(select(selectEntityActionDtos)));
    tenderWrapperUiDto.entityActionDtos = entityActionDtos;
    tenderWrapperUiDto.validationErrorCodeDtoList = [];

    return tenderWrapperUiDto;
  }

  async saveTenderWrapperUiDto() {
    this.errorMessage$.next('');
    this._showErrorToast$.next(false);

    if (this.technicalSectionTemplateDtos.length == 0 && !this.ctrlGuideLines.value) {
      return;
    }

    let tenderWrapperUiDto = await this.margeTenderWrapperUiDto();
    console.log(tenderWrapperUiDto);

    this.isLoading$.next(true);

    try {
      const response = await firstValueFrom(this.tenderManagementService.saveTenderWrapperUiDto(tenderWrapperUiDto));
      if (response.code === ApplicationConstants.SUCCESS_CODE) {
        this.isLoading$.next(false);
        this._showSuccessToast$.next(true);

        setTimeout(() => {
          this._showSuccessToast$.next(false);

          const tenderWrapperUiDto = response.data as TenderWrapperUiDto;
          this.store.dispatch(TenderManagementActions.setCurrentTenderWrapperUiDto({ tenderWrapperUiDto }));
          this.store.dispatch(TenderManagementActions.clearEntityActionDtos());
        }, 2000);
      } else {
        this.isLoading$.next(false);
        this.errorMessage$.next(response.message || 'Error while saving questionnaire. Try again.')
        this._showErrorToast$.next(true);
      }
    } catch (error) {
      console.error(error);
      this.isLoading$.next(false);

      this.errorMessage$.next('Error while saving questionnaire. Try again.');
      this._showErrorToast$.next(true);
    }
  }

  // handleSectionOrders() {
  //   let resourceSwapDtoList: ResourceSwapDto[] = [];

  //   this.technicalSectionTemplateDtos.forEach((item, index) => {
  //     let resourceSwapDto = new ResourceSwapDto();
  //     resourceSwapDto.resourceId = item.sectionId;
  //     resourceSwapDto.sequenceNo = `${index + 1}`;
  //     resourceSwapDto.pretext = ApplicationUtils.alphabetsList()[index];
  //     resourceSwapDtoList.push(resourceSwapDto);
  //   })

  //   let resourceSwapWrapperDto = new ResourceSwapWrapperDto();
  //   resourceSwapWrapperDto.sourcingEnvelopeType = SourcingEnvelopeType.TECHNICAL;
  //   resourceSwapWrapperDto.resourceSwapDtoList = resourceSwapDtoList;

  //   this.appLoader?.openLoaderIcon('SECTION_REORDER', 'Reordering..');
  //   this.tenderQuestionnaireService.updateSectionOrdering(resourceSwapWrapperDto).subscribe({
  //     next: (apiResponseDto: ServerAPIResponseDto) => {
  //       if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
  //         this.appLoader?.closeLoaderIcon('SECTION_REORDER');

  //         let questionnaireWrapperDto = apiResponseDto.data as TenderQuestionnaireWrapperDto;
  //         this.tenderQuestionnaireService.updatetenderQuestionnaireWrapperDto(questionnaireWrapperDto);
  //       } else {
  //         this.appLoader?.closeLoaderIcon('SECTION_REORDER');
  //       }
  //     },
  //     error: (err) => {
  //       console.error(err);
  //       this.appLoader?.closeLoaderIcon('SECTION_REORDER');
  //     }
  //   })
  // }

  drop(event: CdkDragDrop<string[]>) {
    let previousItem = this.technicalSectionTemplateDtos[event.previousIndex];
    let currentItem = this.technicalSectionTemplateDtos[event.currentIndex];

    if (!previousItem.sectionName || !currentItem.sectionName) {
      return;
    }

    moveItemInArray(this.technicalSectionTemplateDtos, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      // this.handleSectionOrders();
    }
  }

  async openTechnicalQuestionDrawer(tenderSectionTemplate: TenderSectionTemplateDto) {
    this.selectedTenderSectionTemplate = tenderSectionTemplate;

    const tenderWrapperUiDto = await this.margeTenderWrapperUiDto();
    const tenderOpportunityWrapperDtos = tenderWrapperUiDto.tenderOpportunityWrapperDtos || [];
    const tenderOpportunityWrapperDto = tenderOpportunityWrapperDtos.find(item => item.tenderOpportunityId == this.tenderOpportunityWrapperDto!.tenderOpportunityId)!;

    // Update Tender & Opportunity in Store
    this.store.dispatch(TenderManagementActions.setCurrentTenderOpportunityWrapperDto({ tenderOpportunityWrapperDto }));
    this.store.dispatch(TenderManagementActions.setCurrentTenderWrapperUiDto({ tenderWrapperUiDto }));
  }

  closeTechnicalQuestionDrawer() {
    this.selectedTenderSectionTemplate = undefined;
  }

  closeDrawer() {
    this.drawerService.closeDrawer();
  }

  openExternalDrawer(drawerName: string, tabName?: string) {
    this.drawerService.openDrawer(drawerName, tabName);
  }

  saveEntityActionDto(id: string, label: EntityActionLabelEnum, type: EntityActionTypeEnum) {
    const entityActionDto = new EntityActionDto(id, label, type);
    this.store.dispatch(TenderManagementActions.saveEntityActionDto({ entityActionDto }));
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }

    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}
