import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { logEvent, getAnalytics } from '@angular/fire/analytics';
import { Timestamp, collection, query, where, onSnapshot, Unsubscribe, Firestore } from '@angular/fire/firestore';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { EventEnum } from 'src/app/shared/enums/EventEnum';
import { UserAuctionEligibleStatus } from 'src/app/shared/enums/UserAuctionEligibleStatus';
import { NavigationEventsDto } from 'src/app/shared/models/NavigationEventsDto';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { NavigationActions } from 'src/app/shared/state-management/navigation/navigation.actions';
import { selectNavigation } from 'src/app/shared/state-management/navigation/navigation.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-new-admin-data',
  templateUrl: './new-admin-data.component.html',
  styleUrls: ['./new-admin-data.component.sass']
})
export class NewAdminDataComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedSidebarView: string = "DASHBOARD_PAGE";

  isShowInternalPage: boolean = false;
  internalPageView: string = ''
  countPendingRegistration: number = 0;

  organizationUiDto?: OrganizationWrapperUiDto;

  eventType: string = "AUCTION";

  isDrawerOpen: boolean = false;

  collectionAuctions: string = "AUCTIONS";
  auctionListenerUnsubscribe: Unsubscribe | undefined;

  collectionRfx: string = "RFX";
  rfxListenerUnsubscribe: Unsubscribe | undefined;

  userRegistrationsSubscription$?: Subscription;
  updateBreadcrumbsSubscription$?: Subscription
  allAuctionsLoadedSubscription$?: Subscription;
  currentOrganizationSubscription$?: Subscription;
  checkDrawerSubscription$?: Subscription;

  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;

  sidebarMenus = [
    { title: '', pageName: ApplicationConstants.TENDER_MANAGEMENT_PAGE }
  ]

  constructor(
    // private dataRedirectionService: DashboardRedirectionService,
    private adminSourcingEventService: AdminSourcingEventsDataHolderService,
    private ngbModal: NgbModal,
    public adminDashboardService: AdminDashboardService,
    private onboardingService: OnboardingService,
    private catalogueService: CatalogueService,
    private firestore: Firestore,
    public drawerService: DrawerService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.adminDashboardService.loadGlobalBiddersCount();

    this.userRegistrationsSubscription$ = this.adminDashboardService.getAllUserAuctionRegistrationsList$.subscribe((data) => {
      if (data) {
        let awaitingApprovals = data.filter(item => item.userAuctionRegistrationStatus == UserAuctionEligibleStatus.AWAITING_APPROVAL);
        this.countPendingRegistration = awaitingApprovals.length;
      }
    })

    this.allAuctionsLoadedSubscription$ = this.adminSourcingEventService.getAllEventsDataLoaded$.subscribe(data => {
      if (data) {
        // this.dataRedirectionService.handleDashboardAppNavigation();
      }
    })

    this.updateBreadcrumbsSubscription$ = this.store.select(selectNavigation).subscribe(data => {
      
      let navigation = ApplicationUtils.deepClone(data);
      if (navigation) {

        if (navigation.mainPageName && navigation.pageName) {
          if (navigation.pageName == ApplicationConstants.AUCTION_CREATOR_PAGE) {
            this.eventType = EventEnum.AUCTION;
          } else if (navigation.pageName == ApplicationConstants.RFX_CREATOR_PAGE) {
            this.eventType = EventEnum.RFX;
          }

          this.isShowInternalPage = true;
          this.internalPageView = navigation.pageName;
          this.selectedSidebarView = navigation.mainPageName;

        } else if (navigation.pageName == ApplicationConstants.TENDER_MANAGEMENT_PAGE ||
          navigation.pageName == ApplicationConstants.ANALYTICS_PAGE ||
          navigation.pageName == ApplicationConstants.DASHBOARD_PAGE ||
          navigation.pageName == ApplicationConstants.IN_HOUSE_USER_PAGE ||
          navigation.pageName == ApplicationConstants.PURCHASE_REQUEST_PAGE ||
          navigation.pageName == ApplicationConstants.BIDDER_NETWORK_PAGE ||
          navigation.pageName == ApplicationConstants.ADMIN_CATALOGUE_PAGE ||
          navigation.pageName == ApplicationConstants.APPROVED_PAGE ||
          navigation.pageName == ApplicationConstants.PROJECTS_PAGE ||
          navigation.pageName == ApplicationConstants.ADMIN_PURCHASE_ORDER_PAGE ||
          navigation.pageName == ApplicationConstants.ADMIN_INVOICE_PAGE ||
          navigation.pageName == ApplicationConstants.ONBOARDING_PAGE ||
          navigation.pageName == ApplicationConstants.MATERIAL_PAGE
        ) {
          this.isShowInternalPage = false;
          this.internalPageView = '';
          this.selectedSidebarView = navigation.pageName;
        } else {
          this.isShowInternalPage = false;
          this.selectedSidebarView = ApplicationConstants.DASHBOARD_PAGE;
          // sessionStorage.setItem('PAGE_NAME', 'DASHBOARD_PAGE');
        }
      } else {
        this.isShowInternalPage = false;
        this.selectedSidebarView = ApplicationConstants.DASHBOARD_PAGE;
        // sessionStorage.setItem('PAGE_NAME', 'DASHBOARD_PAGE');
      }
    })

    this.updateBreadcrumbsSubscription$ = this.drawerService.checkDrawerOpened$.subscribe(data => {
      this.isDrawerOpen = data;
    })
  }

  ngAfterViewInit(): void {
    this.currentOrganizationSubscription$ = this.onboardingService.getOrganizationUiDto$.subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;
        this.attachAuctionChangesListener();
        this.attachRfxChangesListener();

        if (this.organizationUiDto) {
          this.adminSourcingEventService.loadSourcingEventDtos();
          this.appLoader?.closeLoaderIcon(ApplicationConstants.FETCHING_DATA);

          // if (!this.auctionHouse?.privacyAndTermsOfUseList || this.auctionHouse?.privacyAndTermsOfUseList.length == 0) {
          //   this.isShowAuctionHouseSetting$.next(true);
          // }
        }
      }
    })

    // this.adminDashboardDataSubscription$ = this.adminDashboardService.getAdminDashboardData$.subscribe((data) => {
    //   if (data) {
    //     this.adminUIWrapper = data;
    //     this.countAdmins = this.adminUIWrapper.adminList?.length ?? 0
    //     this.countAgents = this.adminUIWrapper.agentList?.length ?? 0
    //   }
    // })

    this.drawerService.loadDrawer();
  }

  toggleSidebarView(pageName: string, dropdown?: NgbDropdown) {
    this.selectedSidebarView = pageName;
    if (dropdown) {
      dropdown.close();
    }

    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.ADMIN;
    navigation.pageName = pageName;

    switch (navigation.pageName) {
      case ApplicationConstants.TENDER_MANAGEMENT_PAGE:
        this.adminSourcingEventService.loadSourcingEventDtos();
        break;

      case ApplicationConstants.ADMIN_CATALOGUE_PAGE:
        this.catalogueService.updateSelectedCatalogueL1EntityDto(undefined);
        this.catalogueService.updateSelectedCatalogueL2EntityDto(undefined);
        break;

      default:
        break;
    }

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }))

    // else if (this.currentViewTabName == AuctionExtConstant.POST_AUCTION) {
    //   let selectedAuction = this.adminSourcingEventService.getSelectedSourcingEvent();
    //   this.dataRedirectionService.storeNavigationContext(
    //     AuctionExtConstant.ADMIN,
    //     AuctionExtConstant.AUCTION_CREATOR_PAGE,
    //     AuctionExtConstant.POST_AUCTION_TAB,
    //     `${selectedAuction?.sequenceNo}`
    //   );
    // }


    // this.dataRedirectionService.handleDashboardAppNavigation();
    // this.dataRedirectionService.setUpdateBreadcrumbs(true);
  }

  closeModal() {
    this.ngbModal.dismissAll()
  }

  attachAuctionChangesListener() {
    let lastFetchTimestamp = Timestamp.now();

    logEvent(getAnalytics(), "attachAuctionChangesListener");

    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let auctionRef = collection(this.firestore, this.collectionAuctions);
      let auctionQuery = query(auctionRef,
        where('orgCode', '==', orgCode),
        where('updateTimeUtc', '>', lastFetchTimestamp));

      this.auctionListenerUnsubscribe = onSnapshot(auctionQuery, documentSnapshots => {
        let auctionsList = documentSnapshots.docChanges().map(change => change.doc.data() as AuctionEntityDto);

        logEvent(getAnalytics(), 'attachAuctionChangesListener Data Received size : ' + auctionsList.length);
        console.log("attachAuctionChangesListener Data Received size: " + auctionsList.length);

        if (auctionsList && auctionsList.length > 0) {

          auctionsList.forEach(updatedAuctionDto => {
            // Changed Firestore timestamp to server timestamp
            let timestamp = updatedAuctionDto.updateTimeUtc as Timestamp;
            updatedAuctionDto.updateTimeUtc = (timestamp.seconds * 1000).toString();

          })
          this.adminSourcingEventService.updateAuctionsChangesFromFirestore(auctionsList!)
        }
      });
    }
  }

  attachRfxChangesListener() {
    let lastFetchTimestamp = Timestamp.now();

    logEvent(getAnalytics(), "attachRfxChangesListener");

    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let rfxRef = collection(this.firestore, this.collectionRfx);
      let rfxQuery = query(rfxRef,
        where('orgCode', '==', orgCode),
        where('updateTimeUtc', '>', lastFetchTimestamp));

      this.rfxListenerUnsubscribe = onSnapshot(rfxQuery, documentSnapshots => {
        let rfxList = documentSnapshots.docChanges().map(change => change.doc.data() as RfxUiDto);

        logEvent(getAnalytics(), 'attachRfxChangesListener Data Received size : ' + rfxList.length);
        console.log("attachRfxChangesListener Data Received size: " + rfxList.length);

        if (rfxList && rfxList.length > 0) {

          rfxList.forEach(updatedAuctionDto => {
            // Changed Firestore timestamp to server timestamp
            let timestamp = updatedAuctionDto.updateTimeUtc as Timestamp;
            updatedAuctionDto.updateTimeUtc = (timestamp.seconds * 1000).toString();

          })
          this.adminSourcingEventService.updateRfxChangesFromFirestore(rfxList!)
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.userRegistrationsSubscription$) {
      this.userRegistrationsSubscription$.unsubscribe();
    }
    if (this.updateBreadcrumbsSubscription$) {
      this.updateBreadcrumbsSubscription$.unsubscribe();
    }
    if (this.allAuctionsLoadedSubscription$) {
      this.allAuctionsLoadedSubscription$.unsubscribe();
    }
    if (this.currentOrganizationSubscription$) {
      this.currentOrganizationSubscription$.unsubscribe();
    }
    if (this.auctionListenerUnsubscribe) {
      this.auctionListenerUnsubscribe();
    }
    if (this.checkDrawerSubscription$) {
      this.checkDrawerSubscription$.unsubscribe();
    }
    // if (this.rfxListenerUnsubscribe) {
    //   this.rfxListenerUnsubscribe();
    // }
  }
}
