import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { NavigationEventsDto } from "../../models/NavigationEventsDto";

export const NavigationActions = createActionGroup({
  source: 'Navigation',
  events: {
    'Store Navigation Context': props<{ navigation: NavigationEventsDto }>(),
    'Get Navigation Context': emptyProps(),
    'Clear Session Cookies': emptyProps(),
    'Get Breadcrumbs': emptyProps(),
  }
})
