<div class="model-body">
  <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleSubmit()">
    <div class=" mb-3">
      <label for="rfxTitle" class="text-gray-700  text-sm font-semibold leading-4">Project Title</label>
      <input type="text"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
        formControlName="rfxTitle" id="rfxTitle"
        [ngClass]="{'is-invalid': fc.rfxTitle.invalid && (fc.rfxTitle.dirty || fc.rfxTitle.touched)}">

      <div *ngIf="isSubmitted || fc.rfxTitle.invalid && (fc.rfxTitle.dirty || fc.rfxTitle.touched)">
        <p class="text-red-500 " *ngIf="fc.rfxTitle.errors?.required">Required
        </p>
      </div>
    </div>

    <div class=" mb-3">
      <label for="plantCode" class="text-gray-700  text-sm font-semibold leading-4">Plant</label>
      <select id="plantCode"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select"
        formControlName="plantCode"
        [ngClass]="{'is-invalid': fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)}">
        <option value="" disabled>Select . . .</option>
        <option [value]="item.plantCode" *ngFor="let item of plantUiDtos">{{item.plantName}}-({{item.plantCode}})
        </option>
      </select>
      <div *ngIf="fc['plantCode'].invalid && (fc['plantCode'].dirty || fc['plantCode'].touched)">
        <p class="text-red-500 " *ngIf="fc['plantCode'].errors?.['required']">Required</p>
      </div>
    </div>

    <div class=" mb-3">
      <label for="costCenterCode" class="text-gray-700  text-sm font-semibold leading-4">Cost Center</label>
      <select
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select"
        formControlName="costCenterCode" id="costCenterCode"
        [ngClass]="{'is-invalid': fc['costCenterCode'].invalid && (fc['costCenterCode'].dirty || fc['costCenterCode'].touched)}">
        <option value="" disabled>Select . . .</option>
        <option value="ADMIN">Administrative</option>
        <option value="HR">Human Resources</option>
        <option value="FIN">Finance</option>
        <option value="IT">Information Technology (IT)</option>
        <option value="SALES">Sales and Marketing</option>
        <option value="PROD">Production</option>
        <option value="LOG">Logistics</option>
        <option value="FAC">Facilities</option>
        <option value="PROC">Procurement</option>
        <option value="CS">Customer Support</option>
        <option value="TRNDEV">Training and Development</option>
        <option value="COMP">Compliance and Audit</option>
        <option value="HSE">Health and Safety</option>
        <option value="RND">Research and Development</option>
        <option value="LEGAL">Legal</option>
        <option value="MKTG">Marketing</option>
        <option value="CSR">Corporate Social Responsibility (CSR)</option>
        <option value="PM">Project Management</option>
        <option value="QM">Quality Management</option>
      </select>
      <div *ngIf="fc['costCenterCode'].invalid && (fc['costCenterCode'].dirty || fc['costCenterCode'].touched)">
        <p class="text-red-500 " *ngIf="fc['costCenterCode'].errors?.['required']">Required</p>
      </div>
    </div>

    <div class=" mb-3">
      <label for="currency" class="text-gray-700  text-sm font-semibold leading-4">Currency
        {{selectedCurrency?.symbol}}</label>
      <select (change)="onChangeCurrency($event)" name="Select Timezone"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select"
        formControlName="currency" id="currency"
        [ngClass]="{'is-invalid': fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)}">
        <option value="" disabled>Select . . .</option>
        <option [value]="item.name" [selected]="item.name == selectedCurrency?.name" *ngFor="let item of currencies">
          {{item.name}}</option>
      </select>

    
      <div *ngIf="isSubmitted || fc.currency.invalid && (fc.currency.dirty || fc.currency.touched)">
        <p class="text-red-500 " *ngIf="fc.currency.errors?.required">Required</p>
      </div>
    </div>

    <div class="row-flex flex gap-3">
      <div class="basis-1/2 mb-3">
        <label for="estSpend" class="text-gray-700  text-sm font-semibold leading-4">Est Spend</label>
          <input type="text"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
            formControlName="estSpend" id="estSpend" (blur)="formatEstSpendValue()"
            [ngClass]="{'is-invalid': fc.estSpend.invalid && (fc.estSpend.dirty || fc.estSpend.touched)}">
          <div *ngIf="isSubmitted || fc.estSpend.invalid && (fc.estSpend.dirty || fc.estSpend.touched)">
            <p class="text-red-500 " *ngIf="fc.estSpend.errors?.required">Required</p>
            <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.estSpend)">Invalid</p>
        </div>
      </div>

      <div class="basis-1/2  mb-3">
          <label for="estSaving" class="text-gray-700  text-sm font-semibold leading-4">Est Saving</label>
          <input type="text"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
            formControlName="estSaving" id="estSaving" (blur)="formatEstSavingValue()"
            [ngClass]="{'is-invalid': fc.estSaving.invalid && (fc.estSaving.dirty || fc.estSaving.touched)}">
          <div *ngIf="isSubmitted || fc.estSaving.invalid && (fc.estSaving.dirty || fc.estSaving.touched)">
            <p class="text-red-500 " *ngIf="fc.estSaving.errors?.required">Required</p>
            <p class="text-red-500 " *ngIf="invalidPriceValidator(fc.estSaving)">Invalid</p>
          </div>
      </div>
    </div>

    <div class="mb-3">
      <label for="timeZone" class="text-gray-700  text-sm font-semibold leading-4">Select Timezone</label>
      <select name="Select Timezone"
        class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm form-select"
        id="timeZone" formControlName="timeZone"
        [ngClass]="{'is-invalid': fc.timeZone.invalid && (fc.timeZone.dirty || fc.timeZone.touched)}">
        <option value="" disabled>Select . . .</option>
        <option [value]="item?.locale" *ngFor="let item of timezones">{{item?.fullName}}</option>
      </select>
      
      <div *ngIf="isSubmitted || fc.timeZone.invalid && (fc.timeZone.dirty || fc.timeZone.touched)">
        <p class="text-red-500 " *ngIf="fc.timeZone.errors?.required">Required</p>
      </div>
    </div>

    <!-- <div class="row mt-3 mb-3" >
          <div class="col-md-8">Allow Edit While Project is Upcoming:</div>
          <div class="col-md-2 col-md-6">
            <input type="radio" formControlName="allowEditWhileUpcoming" id="allowEditWhileUpcoming1" [value]="true" (click)="openEditingConfirmModal(true, 'LIVE_WAIT')">
            <label class="ms-3" for="allowEditWhileUpcoming1">Yes</label><br>
          </div>
          <div class="col-md-2 col-md-6">
            <input type="radio" formControlName="allowEditWhileUpcoming" id="allowEditWhileUpcoming2" [value]="false" (click)="openEditingConfirmModal(false, 'LIVE_WAIT')">
            <label class="ms-3" for="allowEditWhileUpcoming2">No</label><br>
          </div>
        </div>

        <div class="row mt-3 mb-3" >
          <div class="col-md-8">Allow Edit While Project is Running:</div>
          <div class="col-md-2 col-md-6">
            <input type="radio" formControlName="allowEditWhileRunning" id="allowEditWhileRunning1" [value]="true" (click)="openEditingConfirmModal(true, 'LIVE')">
            <label class="ms-3" for="allowEditWhileRunning1">Yes</label><br>
          </div>
          <div class="col-md-2 col-md-6">
            <input type="radio" formControlName="allowEditWhileRunning" id="allowEditWhileRunning2" [value]="false" (click)="openEditingConfirmModal(false, 'LIVE')">
            <label class="ms-3" for="allowEditWhileRunning2">No</label><br>
          </div>
        </div> -->

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full items-center justify-between mb-3">
      <div class=" mb-3">
        <label for="startDate" class="text-gray-700  text-sm font-semibold leading-4">Start Date</label>
        <div class="flex w-full">
          <div class="input-group-append">
            <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
              id="basic-addon2"
              [ngClass]="{'border-danger': fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)}">
              <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
            </span>
          </div>
          <input type="text" ngbDatepicker [minDate]="minDate" #startDate="ngbDatepicker" (click)="startDate.toggle()"
            class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
            placeholder="Start Date" formControlName="startDate" id="startDate"
            [ngClass]="{'is-invalid': fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)}">


        </div>
        <div *ngIf="isSubmitted || fc.startDate.invalid && (fc.startDate.dirty || fc.startDate.touched)">
          <p class="text-red-500 " *ngIf="fc.startDate.errors?.required">Required
          </p>
        </div>
      </div>

      <div class=" mb-3">
        <label for="startTime" class="text-gray-700  text-sm font-semibold leading-4">Start Time</label>
        <div class="flex w-full">
          <div class="input-group-append">
            <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
              id="basic-addon2"
              [ngClass]="{'border-danger': fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)}">
              <img src="assets/icons/time_light.svg" class="svg-icon-class c-pointer">
            </span>
          </div>
          <input type="text"
            class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
            ngbPopover [autoClose]="'outside'" placement="left" triggers="manual" #p1="ngbPopover"
            (click)="openTimepicker(p1, startTimeModal)" placeholder="Start Time" formControlName="startTime"
            id="startTime"
            [ngClass]="{'is-invalid': fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)}">
        </div>
        <div *ngIf="isSubmitted || fc.startTime.invalid && (fc.startTime.dirty || fc.startTime.touched)">
          <p class="text-red-500 " *ngIf="fc.startTime.errors?.required">Required</p>
          <p class="text-red-500 " *ngIf="fc.startTime.errors?.pattern">Invalid</p>
        </div>

      </div>
      <div class=" mb-3">
        <label for="leadTime" class="text-gray-700  text-sm font-semibold leading-4">Lead Time (in days)</label>
        <input type="text"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm"
          id="leadTime" formControlName="leadTime"
          [ngClass]="{'is-invalid': fc['leadTime'].invalid && (fc['leadTime'].dirty || fc['leadTime'].touched)}">
        <div *ngIf="fc['leadTime'].invalid && (fc['leadTime'].dirty || fc['leadTime'].touched)">
          <p class="text-red-500 " *ngIf="fc['leadTime'].errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="invalidPriceValidator(fc['leadTime'])">Invalid</p>
        </div>
      </div>

      <div class=" mb-3 ">
        <label for="estimatedDeliveryDate" class="text-gray-700  text-sm font-semibold leading-4">Estimated Delivery
          Date</label>
        <div class="flex w-full">
          <div class="input-group-append">
            <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg h-8 flex pt-1 px-3"
              id="basic-addon2"
              [ngClass]="{'border-danger': fc.estimatedDeliveryDate.invalid && (fc.estimatedDeliveryDate.dirty || fc.estimatedDeliveryDate.touched)}">
              <img src="assets/icons/date_light.svg" class="svg-icon-class c-pointer">
            </span>
          </div>
          <input type="text" ngbDatepicker [minDate]="minDate" #estimatedDeliveryDate="ngbDatepicker"
            (click)="estimatedDeliveryDate.toggle()"
            class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder"
            placeholder="Start Date" formControlName="estimatedDeliveryDate" id="estimatedDeliveryDate"
            [ngClass]="{'is-invalid': fc.estimatedDeliveryDate.invalid && (fc.estimatedDeliveryDate.dirty || fc.estimatedDeliveryDate.touched)}">

        </div>
        <div
          *ngIf="isSubmitted || fc.estimatedDeliveryDate.invalid && (fc.estimatedDeliveryDate.dirty || fc.estimatedDeliveryDate.touched)">
          <p class="text-red-500 " *ngIf="fc.estimatedDeliveryDate.errors?.required">Required
          </p>
        </div>
      </div>
    </div>
    <div class="my-3">
      <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
        [message]="'Project created successfully.'"></app-message-toast>
    </div>
    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
    </div>

    <div class="text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
      <button type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 "
        (click)="handleSubmit()">Add</button>
    </div>

  </form>

</div>


<!-- NGB TIME MODAL -->
<ng-template #startTimeModal>
  <ngb-timepicker [(ngModel)]="startTime" [meridian]="true"></ngb-timepicker>
  <button
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
    (click)="setTime('startTime')">SAVE</button>
</ng-template>