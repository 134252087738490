import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { RfxPaymentStatusEnum } from 'src/app/shared/enums/rfx/RfxPaymentStatusEnum';
import { Currency } from 'src/app/shared/models/Currency';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { RfxPurchaseInvoiceDto } from 'src/app/shared/models/rfx/RfxPurchaseInvoiceDto';
import { RfxPurchaseInvoiceEnum } from 'src/app/shared/models/rfx/RfxPurchaseInvoiceEnum';
import { MobileWrapper } from 'src/app/shared/models/user/MobileWrapper';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

declare const require: any;
const html2pdf = require('html2pdf.js');

@Component({
  selector: 'app-admin-invoice-page',
  templateUrl: './admin-invoice-page.component.html',
  styleUrls: ['./admin-invoice-page.component.sass']
})
export class AdminInvoicePageComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  isDataLoading: boolean = false;
  isShowPdfView: boolean = false;
  currentTab: string = 'RAISED';
  errorMsg: string = ''

  purchaseInvoicesList: RfxPurchaseInvoiceDto[] = [];
  filteredInvoicesList: RfxPurchaseInvoiceDto[] = [];

  selectedRfxPurchaseInvoiceDto?: RfxPurchaseInvoiceDto;

  filteredInvoicesList$ = new BehaviorSubject<RfxPurchaseInvoiceDto[]>([]);
  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  constructor(
    private ngbModal: NgbModal,
    private changeDetectRef: ChangeDetectorRef,
    private adminDashboardService: AdminDashboardService,
    private onboardingService: OnboardingService
  ) { }

  ngOnInit(): void {
    this.isDataLoading = true;
    let organizationUiDto = this.onboardingService.getOrganizationUiDto;

    this.adminDashboardService.getPurchaseInvoices(organizationUiDto?.orgCode!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isDataLoading = false;
          if (apiResponseDto.data) {
            let data = apiResponseDto.data as RfxPurchaseInvoiceDto[]
            this.purchaseInvoicesList = data;

            this.filterAndSortData();
          }
        }
      },
      error: (error: any) => {
        console.error(error);
        this.isDataLoading = false;
      }
    })
  }

  filterAndSortData() {
    this.filteredInvoicesList = [...this.purchaseInvoicesList];

    if (this.currentTab == 'PAID') {
      this.filteredInvoicesList = this.purchaseInvoicesList.filter(item => item.paymentStatus == RfxPaymentStatusEnum.PAID);
    } else {
      this.filteredInvoicesList = this.purchaseInvoicesList.filter(item => item.paymentStatus != RfxPaymentStatusEnum.PAID);
    }

    this.filteredInvoicesList$.next(this.filteredInvoicesList);
  }

  openInvoiceModal(content: any, rfxPurchaseInvoiceDto: RfxPurchaseInvoiceDto) {
    this.selectedRfxPurchaseInvoiceDto = rfxPurchaseInvoiceDto;

    this.ngbModal.open(content, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  openConfirmStatusModal(content: any, rfxPurchaseInvoiceDto: RfxPurchaseInvoiceDto) {
    this.selectedRfxPurchaseInvoiceDto = rfxPurchaseInvoiceDto;

    this.ngbModal.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
  }

  toggleDetailsTab(tab: string) {
    this.currentTab = tab;
    this.filterAndSortData();
  }

  closeModal() {
    this.ngbModal.dismissAll()
  }

  markToAccountPayableInvoice() {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = false;

    let rfxPurchaseInvoiceDto = ApplicationUtils.clone(this.selectedRfxPurchaseInvoiceDto) as RfxPurchaseInvoiceDto
    rfxPurchaseInvoiceDto.paymentStatus = RfxPaymentStatusEnum.PENDING;
    

    this.isLoading = true;

    this.adminDashboardService.updatePurchaseInvoice(rfxPurchaseInvoiceDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as RfxPurchaseInvoiceDto;
          this.selectedRfxPurchaseInvoiceDto = data;

          let index = this.purchaseInvoicesList.findIndex(item => item.invoiceId == this.selectedRfxPurchaseInvoiceDto?.invoiceId);
          if (index != undefined && index > -1) {
            Object.assign(this.purchaseInvoicesList[index], this.selectedRfxPurchaseInvoiceDto);
            this.filterAndSortData();
          }

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this.errorMsg = apiResponseDto.message ?? 'Error while updating invoice. Try again.';
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while updating invoice. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;

      }
    })
  }

  generatePdf() {
    this.isLoading = true;
    this.isShowPdfView = true;
    this.changeDetectRef.detectChanges();

    const element = document.getElementById('pdfContent');

    let auctionHouseDto = this.adminDashboardService.getCurrentAuctionHouse$.value;
    const secondaryColor = auctionHouseDto?.secondaryColor ?? '#1b5887';

    if (element) {
      let opt = {
        // margin: [25, 12, 30, 12],
        filename: 'PO_' + "test" + '.pdf',
        html2canvas: { scale: 6 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };

      html2pdf().from(element).set(opt).set({ margin: [25, 12, 25, 12] }).toPdf().get('pdf').then((pdf: any) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          pdf.setDrawColor(secondaryColor);
          pdf.setLineWidth(1);
          pdf.line(20, pdf.internal.pageSize.getHeight() - 25, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 25);

          pdf.setFontSize(9);
          pdf.setTextColor('#000000');

          let text = `Page ${i}`;
          let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          let textOffset = (pdf.internal.pageSize.getWidth() - textWidth) / 2;

          pdf.text(text, textOffset, pdf.internal.pageSize.getHeight() - 10);
        }

        this.isLoading = false;
        this.isShowPdfView = false;
        this.changeDetectRef.detectChanges();
      }).save();
    }
  }

  getFormattedPrice(currency: Currency, price: any) {
    return currency.symbol + ApplicationUtils.getFormattedPrice(currency.locale, price);
  }

  getFormattedMobileNo(mobileWrapper?: MobileWrapper) {
    let countryCodeDto = mobileWrapper?.countryCode;
    if (countryCodeDto) {
      let countryCode = countryCodeDto.countryCode?.replace('(', '').replace(')', '');
      return countryCode + ' ' + mobileWrapper?.mobileNo;
    }
    return '+1 ' + mobileWrapper?.mobileNo;
  }

  ngOnDestroy(): void {

  }
}
