import { Injectable } from '@angular/core';
import { ErrorService } from './error.service';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, BehaviorSubject } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { PolygonTransaction, PolygonTransactionResponse } from '../models/blockchain/PolygonTransaction';

@Injectable({
  providedIn: 'root'
})
export class BlockchainService {
  polygonTxnList$ = new BehaviorSubject<PolygonTransaction[]>([])

  get getPolygonTxnList$() { return this.polygonTxnList$.asObservable(); }

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
  ) { }

  getTransactionDataByTxnHash(txhash: string): PolygonTransaction | undefined {
    let polygonTxnList = [...this.polygonTxnList$.value]
    let txn = polygonTxnList.find(item => item.hash == txhash);
    return txn;
  }

  loadBlockchainTransactions() {
    this.getTransactionsList().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let polygonTxnListResponse = apiResponseDto.data as PolygonTransactionResponse;
          let polygonTxnList = polygonTxnListResponse.result;
          polygonTxnList.sort((a, b) => Number(b.timeStamp) - Number(a.timeStamp));

          this.polygonTxnList$.next(polygonTxnList);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  getTransactionByHash(txhash: string) {
    let params = { txhash: txhash };
    return this.httpClient.get('/ethGetTransactionByHash', { params: { txhash: txhash } }).pipe(
      tap(_ => console.log("Getting transaction by hash: " + txhash)),
      catchError(this.errorService.handleError<any>("Error while getting transaction by hash: " + txhash)));
  }

  getTransactionReceipt(txhash: string) {
    return this.httpClient.get('/ethGetTransactionReceipt', { params: { txhash: txhash } }).pipe(
      tap(_ => console.log("Getting transaction receipt by hash: " + txhash)),
      catchError(this.errorService.handleError<any>("Error while getting transaction receipt by hash: " + txhash)));
  }

  getTransactionsList() {
    return this.httpClient.get('/txList').pipe(
      tap(_ => console.log("Getting all transactions")),
      catchError(this.errorService.handleError<any>("Error while getting all transactions")));
  }
}
