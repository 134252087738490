import { CommentsDto } from "./CommentsDto";
import { FileInfoDto } from "./FileInfoDto";
import { ProjectEventsUiDto } from "./ProjectEventsUiDto";
import { PurchaseRequestUiDto } from "./rfx/PurchaseRequestUiDto";

export class ProjectUiDto {
  orgCode?: string;
  projectCode?: string;
  name?: string;
  description?: string;
  createdDate?: string;
  fileInfoDtos?: FileInfoDto[];
  commentsDtos?: CommentsDto[];
  eventsUiDtos?: ProjectEventsUiDto[]
  purchaseRequestUiDto?: PurchaseRequestUiDto;
}