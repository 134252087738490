<div class=" p-2" id="bottom">
  <div class="col-12 ">
    <div class="row justify-content-between">
      <div class="col-md-5">
       
            <h5 class="heading-h5 text-behind-svg-icon me-2 ">OPPORTUNITY SETUP</h5>
            <h5 class="heading-h5 text-behind-svg-icon" ngbTooltip="{{rfxSubcategoryEntityDto?.title}}"
              container="body">
              <span *ngIf="rfxSubcategoryEntityDto?.title">
                ({{truncateString(rfxSubcategoryEntityDto?.title, 90)}})
              </span>
            </h5>
            <span class="ms-3" ngbAutoFocus
            [ngClass]="{ 'badge-draft-class' : getRfxSubcategoryStatus() == 'DRAFT', 'badge-warning-class' : getRfxSubcategoryStatus() == 'UPCOMING', 'badge-primary-class' : getRfxSubcategoryStatus() == 'RUNNING', 'badge-danger-class' : getRfxSubcategoryStatus() == 'CLOSED'}">
            {{getRfxSubcategoryStatus()}}
          </span>
      </div>
      <div class="col-md-7 text-md-end">
        <span class="me-3" *ngIf=" (screenWidth > 768) && !isSingleRfxSubcategory">
          <span class="span-class me-2">
            Show Table View
          </span>
          <span class="form-switch ">
            <input class="form-check-input c-pointer" type="checkbox" id="tableView" (click)="changeSubcategoryView()"
              [checked]="_isShowTableView$ |async" />
          </span>
        </span>
        <button class="btn-outline-primary btn btn-sm me-3 " (click)="openSubcategoryDetailsModal('ADD')"
        *ngIf="!(_isShowTableView$ |async)" [disabled]="!isAllowToEdit()">
        ADD
      </button>
      <span class="me-3" *ngIf="!isSingleRfxSubcategory">
        <button class="btn-outline-primary btn btn-sm "
          (click)="openAllRfxSubcategoriesActivationModal(enableAllRfxSubcategoriesModal)"
          [disabled]="!isAllowToEdit()">
          ACTIVATE ALL EVENT
        </button>
      </span>
        
            <span>
              <button class="btn-outline-primary btn-sm btn mt-2 mt-md-0"
              *ngIf="!(rfxEntityDto?.status == 'DISCARD') && rfxSubcategoryEntityDtos && rfxSubcategoryEntityDtos!.length > 1"
              (click)="openRfxSubcategoryDiscardModel(discardRfxSubcategoryModal)" [disabled]="!isAllowToEdit()">
              DISCARD
            </button>
              <button class="btn-new-class btn-sm btn mt-2 ms-3 mt-md-0"
                (click)="openDisableUserModal(enableDisabledRfxSubcategory)">
                {{rfxSubcategoryEntityDto?.active ? 'ACTIVE' : 'INACTIVE'}}
              </button>
            </span>
      </div>
    </div>
  </div>
  <hr class="hr mt-2" />
  <div>
    <div *ngIf="!(_isShowTableView$ | async)">
      <div class="col-12  my-3">
        <app-subcategory-sequence-carousel [isShowValidation]="true"></app-subcategory-sequence-carousel>
        <div class="col-md-11 m-auto">
          <div class="row my-3 justify-content-end align-items-center"
            *ngIf="this.deploymentConfigurationDto?.multiLotAllowed">
            <div class="col-md-auto text-md-end mb-2 mb-md-0 ps-3 ps-md-0" *ngIf="!isSingleRfxSubcategory">
              <div class="dropdown" ngbDropdown>
                <button ngbDropdownToggle class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800dropdown-toggle" type="button"
                  data-bs-toggle="dropdown" aria-expanded="false" ngbTooltip="{{selectedRfxSubcategoryDropdown}}"
                  placement="top" container="body">
                  <div class="">
                    <img src="assets/icons/sort_dark.svg" class="svg-icon-class c-pointer">
                    {{truncateString(selectedRfxSubcategoryDropdown, 50)}}
                  </div>
                </button>
                <ul class="dropdown-menu scroll-area-lg mt-4" ngbDropdownMenu>
                  <li class="c-pointer" *ngFor="let item of rfxSubcategoryEntityDtos"
                    (click)="changeCurrentRfxSubcategoryById(item?.subcategoryId!)">
                    <a class="dropdown-item" ngbDropdownItem>{{item?.subcategorySequence}} - {{item?.title? item?.title
                      : '
                      '}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr class="hr" *ngIf="!isSingleRfxSubcategory" />
        <!-- <div class="col-10 m-auto">
          <div class="row my-2">
            <div class="col-md-5 text-start mt-2 mb-2 mb-md-0" *ngIf="rfxSubcategoryEntityDto">
            
            </div>
          </div>
        </div> -->
      </div>
      <div class="col-lg-11 m-auto mt-2" *ngIf="rfxSubcategoryEntityDtos && rfxSubcategoryEntityDtos!.length > 0">
        <div class="row">
          <div class=" col-md-6 ">
            <div class="border  rounded bg-white"
              [ngClass]="{'border-danger border-2' : haveRfxSubcategoryDetailsErrors()}">
              <header class="p-2">
                <div class="row ">
                  <div class="col-md-6">
                    <h5 class="heading-h5"> DETAILS</h5>
                  </div>
                  <div class="col-md-6 text-md-end">
                    <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 " (click)="openSubcategoryDetailsModal('EDIT')"
                      [disabled]="!isAllowToEdit()">
                      <!-- <img src="assets/icons/edit_dark.svg" class="svg-icon-class c-pointer"> -->
                      EDIT
                    </button>
                  </div>
                </div>
              </header>
              <hr class="hr" />

              <div class="col-12 div-class py-2 px-3">
                Event Information
              </div>
              <div class="row px-3">
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  #
                </div>
                <div class="col-8 div-class py-2">
                  {{rfxSubcategoryEntityDto?.subcategorySequence}}
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2" [ngClass]="{'text-red-500 ':!rfxSubcategoryEntityDto?.title}">
                  Title
                </div>
                <div class="col-8 div-class py-2">
                  {{rfxSubcategoryEntityDto?.title}}
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2">
                  Description
                </div>
                <div class="col-8 div-class py-2">
                  {{truncateText(rfxSubcategoryEntityDto?.description)}}
                  <div class="text-end btn-link ms-3 c-pointer"
                    (click)="openShowMoreModal(rfxSubcategoryEntityDto?.title!, rfxSubcategoryEntityDto?.description!)"
                    *ngIf="rfxSubcategoryEntityDto?.description && rfxSubcategoryEntityDto?.description!.length > 101">
                    SHOW MORE
                  </div>
                </div>
                <hr class="hr" />
                <div class="col-4 font-weight-normal py-2"
                  [ngClass]="{'text-red-500 ':!rfxSubcategoryEntityDto?.endDate || !rfxSubcategoryEntityDto?.endTime || haveAnyErrorInEndDate()}">
                  Ending on
                </div>
                <div class="col-8 div-class py-2">
                  {{getDisplayDate(rfxSubcategoryEntityDto?.endDate, rfxSubcategoryEntityDto?.endTime)}}
                </div>
              </div>
            </div>
          </div>
          <div class=" col-md-6 mt-2 mt-md-0">
            <app-rfx-subcategory-categories-data></app-rfx-subcategory-categories-data>
          </div>
          <div class=" col-md-6 mt-2">
            <app-rfx-subcategory-details></app-rfx-subcategory-details>
          </div>
          <!-- <div class=" col-md-6 mt-2">
            <app-rfx-email-notification></app-rfx-email-notification>
          </div> -->
          <div class="col-md-6 mt-2 ">
            <app-vendor-details></app-vendor-details>
          </div>
          <div class=" col-md-6 mt-2">
            <app-rfx-questionnaire></app-rfx-questionnaire>
          </div>
          <div class=" col-md-6 mt-2">
            <app-rfx-subcategory-images></app-rfx-subcategory-images>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="(_isShowTableView$ | async)">
      <app-add-rfx-bulk-subcategory-verification></app-add-rfx-bulk-subcategory-verification>
    </div>
  </div>
</div>

<ng-template #enableDisabledRfxSubcategory>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>{{rfxSubcategoryActiveStatus ? 'DISABLE' : 'ENABLE'}} EVENT </h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Do you want to {{rfxSubcategoryActiveStatus ? 'disable' : 'enable' }} Event</p>
      </div>
      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
          [message]="_errorMsg$ |async"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
          message="Event {{rfxSubcategoryEntityDto?.active ? 'enabled' : 'disabled'}} successfully.">
        </app-message-toast>
      </div>
      <div class="text-end mb-3" *ngIf="rfxSubcategoryEntityDto && !isLoading&& !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal()">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary"
          (click)="markRfxSubcategoryActiveOrInActive('ENABLE')" *ngIf="!rfxSubcategoryEntityDto?.active"
          [disabled]="!(_isSaveButtonEnable$ | async) || !isAllowToEdit()">ENABLE</button>
        <button type="button" class="btn btn-sm btn-outline-primary"
          (click)="markRfxSubcategoryActiveOrInActive('DISABLE')" *ngIf="rfxSubcategoryEntityDto?.active"
          [disabled]="!(_isSaveButtonEnable$ | async) || !isAllowToEdit()">DISABLE</button>
      </div>
      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #discardRfxSubcategoryModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>Event Discard</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Are you sure you want to discard the selected event? you will not be able to work on this event once
          discarded.
        </p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
          [message]="_errorMsg$ |async"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
          message="Event discarded successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
      </div>

      <div class="text-end mb-3" *ngIf="rfxEntityDto && !isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal()">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="discardRfxSubcategory()"
          [disabled]="!isAllowToEdit()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #enableAllRfxSubcategoriesModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>ENABLE EVENT</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Do you want to enable all events?</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
          [message]="_errorMsg$ |async"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToast$ | async" [isError]="false"
          message="Events enabled successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal()">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary"
          (click)="activateAllRfxSubcategories()">ENABLE</button>
      </div>
    </div>
  </div>
</ng-template>