import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BidIncrementalWrapperDto } from 'src/app/shared/models/user/BidIncrementalWrapperDto';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserService } from 'src/app/shared/services/user.service';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AddRfxBulkSubcategoryUploadComponent } from '../add-rfx-bulk-subcategory-upload/add-rfx-bulk-subcategory-upload.component';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { RfxValidationErrorCodeDto } from 'src/app/shared/models/user/RfxValidationErrorCodeDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { AbstractControl } from '@angular/forms';
import { RfxValidationService } from 'src/app/shared/services/rfx-validation.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';

@Component({
  selector: 'app-add-rfx-bulk-subcategory-verification',
  templateUrl: './add-rfx-bulk-subcategory-verification.component.html',
  styleUrls: ['./add-rfx-bulk-subcategory-verification.component.sass']
})
export class AddRfxBulkSubcategoryVerificationComponent implements OnInit {
  rfxEntityDto?: RfxUiDto
  allRfxSubcategoryList: Array<RfxSubcategoryUiDto> = []

  rfxCategoriesSubscription$?: Subscription;
  masterCategories: any[] = [];

  isDiscardLoading: boolean = false;
  isEnableOrDisableLoading: boolean = false;
  subcategoryActiveStatus: boolean = false;

  selectedRfxSubcategory$ = new BehaviorSubject<RfxSubcategoryUiDto | null>(null)
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _filterRfxSubcategoryList$ = new BehaviorSubject<RfxSubcategoryUiDto[]>([]);

  selectedRfxSubcategoryForDiscard?: RfxSubcategoryUiDto
  selectedRfxSubcategoryForEnableDisable?: RfxSubcategoryUiDto;

  discardModalRef?: NgbModalRef
  enableDisabledModalRef?: NgbModalRef

  rfxValidationErrorCodeDtoList?: RfxValidationErrorCodeDto[];

  constructor(
    private ngbModal: NgbModal,
    private activeModal: NgbActiveModal,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private onboardingService: OnboardingService,
    private validationService: RfxValidationService,
    private adminDashboardService: AdminDashboardService
  ) { }

  ngOnInit(): void {
    this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(data => {
      if (data) {
        this.rfxEntityDto = data
      }
    })

    this.rfxCategoriesSubscription$ = this.adminDashboardService.getMasterCategories$.subscribe((dataList) => {
      if (dataList) {
        this.masterCategories = dataList;
      } else {
        this.masterCategories = [];
      }
    })

    this.adminRfxSubcategoryDataHolderService._allRfxSubcategoryList$.subscribe(data => {
      if (data) {
        this.allRfxSubcategoryList = data
        this.allRfxSubcategoryList.sort((a, b) => a.subcategorySequence! - b.subcategorySequence!);
        this.filterSubcategoryList("");

        if (this.selectedRfxSubcategory$.value) {
          let selectedSubcategory = this.allRfxSubcategoryList.find(item => item.subcategoryId == this.selectedRfxSubcategory$.value?.subcategoryId)
          if (selectedSubcategory) {
            this.selectedRfxSubcategory$.next(selectedSubcategory)
          }
        }
      }
    })
  }

  formatEmdValue(emdValue: number) {
    let emdValueFinal = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, emdValue);
    return emdValueFinal;
  }

  formatEstimatedValue(estimatedValue: number) {

    let estimatedValueFinal = ApplicationUtils.getFormattedPrice(this.rfxEntityDto?.currency?.locale!, estimatedValue);
    return estimatedValueFinal;
  }

  openEditSubcategoryDetailsModal(subcategory: RfxSubcategoryUiDto, event?: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    if (event) {
      event.stopPropagation();
    }

    this.selectedRfxSubcategory$.next(subcategory)

    let modalRef = this.ngbModal.open(AddRfxBulkSubcategoryUploadComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    })

    modalRef.componentInstance.selectedRfx$ = this.adminSourcingEventsDataHolderService.selectedRfx$
    modalRef.componentInstance.selectedRfxSubcategory$ = this.selectedRfxSubcategory$

    modalRef.result.then(() => {
      this.selectedRfxSubcategory$.next(null);
    })
  }
  getDisplayDate(date?: string, time?: string) {
    return ApplicationUtils.getDisplayDateAdmin(date, time);
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }
  invalidPriceValidator(item: AbstractControl | null) {
    if (item?.errors?.['pattern'] || item?.errors?.['forbiddenMin']) {
      return true;
    }
    return false;
  }

  getSubcategoryValidation(subcategory: RfxSubcategoryUiDto) {
    if (this.rfxEntityDto && subcategory) {
      return this.validationService.haveAnyRfxSubcategoryErrors(this.rfxEntityDto!, subcategory);
    } else {
      return true
    }
  }

  getHashtagsValidation(hashtags?: string[]) {
    return hashtags && hashtags.length > 0 ? hashtags.length : 0;
  }

  getHighlightsValidation(highlights?: string[]) {
    return highlights && highlights.length > 0 ? highlights.length : 0;
  }



  isValidUrl = (urlString: string) => {
    try {
      return Boolean(new URL(urlString));
    }
    catch (e) {
      return false;
    }
  }


  getBidIncrementValidation(bidIncrementalWrapper?: BidIncrementalWrapperDto) {
    if (bidIncrementalWrapper) {
      if (bidIncrementalWrapper.incrementalBidType == 'SINGLE') {
        return bidIncrementalWrapper.constantIncrement ? 'SINGLE' : '';
      } else {
        return bidIncrementalWrapper.bidIncrementalDtoList
          && bidIncrementalWrapper.bidIncrementalDtoList.length > 0 ? 'MULTIPLE' : '';
      }
    }
    return 0
  }

  getSingleDate(val?: string) {
    if (val) {
      return this.getTruncate(val);
    }
    return "";
  }


  getCategoryName(id?: string) {
    if (id) {
      let categoryData = this.masterCategories.find(item => item.id == id);
      if (categoryData) {
        return categoryData.category;
      }
    }
    return "";
  }

  getSubCategoryName(id?: string) {
    if (id) {
      let categoryData = this.masterCategories.find(item => item.id == id);
      if (categoryData) {
        return categoryData.subcategory;
      }
    }
    return "";
  }

  getSubCategoryLevelOneName(id?: string) {
    if (id) {
      let categoryData = this.masterCategories.find(item => item.id == id);
      if (categoryData) {
        return categoryData.subcategoryLevelOne;
      }
    }
    return "";
  }

  getTruncate(formattedInput?: string) {
    if (formattedInput) {
      return ApplicationUtils.truncateString(formattedInput, 20);
    }
    return "";
  }

  addNewSubcategoryRow() {
    if (!this.isAllowToEdit()) {
      return;
    }

    let organizationUiDto = this.onboardingService.getOrganizationUiDto;

    let newRfxSubcategoryEntityDto = new RfxSubcategoryUiDto();
    newRfxSubcategoryEntityDto.subcategorySequence = this.getSubcategorySequence();
    newRfxSubcategoryEntityDto.orgCode = organizationUiDto?.orgCode;
    newRfxSubcategoryEntityDto.rfxId = this.rfxEntityDto!.rfxId;
    newRfxSubcategoryEntityDto.timeZone = this.rfxEntityDto!.timeZone;
    newRfxSubcategoryEntityDto.subcategoryType = this.rfxEntityDto?.rfxType;

    this.openEditSubcategoryDetailsModal(newRfxSubcategoryEntityDto);

    this.allRfxSubcategoryList.sort((a, b) => a.subcategorySequence! - b.subcategorySequence!);
  }

  copySubcategoryRow(item: RfxSubcategoryUiDto, event: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    event.stopPropagation();

    let newRfxSubcategoryEntityDto = new RfxSubcategoryUiDto();
    newRfxSubcategoryEntityDto = ApplicationUtils.clone(item);
    newRfxSubcategoryEntityDto.subcategoryId = undefined;
    newRfxSubcategoryEntityDto.subcategorySequence = this.getSubcategorySequence();

    this.openEditSubcategoryDetailsModal(newRfxSubcategoryEntityDto);

    this.allRfxSubcategoryList.sort((a, b) => a.subcategorySequence! - b.subcategorySequence!);
  }

  openRfxSubcategoryEnableAndDisableModel(item: RfxSubcategoryUiDto, content: any) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this.selectedRfxSubcategoryForEnableDisable = item;
    this.subcategoryActiveStatus = item?.active!;
    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.enableDisabledModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  discardSubcategoryRow() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._errorMsg$.next('');
    this._showErrorToast$.next(false);
    this.isDiscardLoading = true;

    this.adminDashboardService.rfxSubcategoryDiscard(this.selectedRfxSubcategoryForDiscard?.rfxId!, this.selectedRfxSubcategoryForDiscard?.subcategoryId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isDiscardLoading = false;

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal(this.discardModalRef);
          }, 2000)

        } else {
          this._errorMsg$.next(apiResponseDto?.message!);
          this._showErrorToast$.next(true);
          this.isDiscardLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.isDiscardLoading = false;
        this._errorMsg$.next("Error while discarding Subcategory. Try again.");
        this._showErrorToast$.next(false);
      }
    })
  }

  openRfxSubcategoryDiscardModel(content: any, event: any, item: RfxSubcategoryUiDto) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._errorMsg$.next('');
    this._showErrorToast$.next(false);
    this.isDiscardLoading = false;

    event.stopPropagation();

    this.selectedRfxSubcategoryForDiscard = item;

    this.discardModalRef = this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  markRfxSubcategoryActiveOrInActive(action: string) {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next("")
    let errorDtoList: RfxValidationErrorCodeDto[] = []

    this.validationService.doRfxSubcategoryValidation(errorDtoList, this.rfxEntityDto!, this.selectedRfxSubcategoryForEnableDisable!);

    if (errorDtoList && errorDtoList.length > 0) {
      this.rfxValidationErrorCodeDtoList = errorDtoList;
      this.closeModal(this.enableDisabledModalRef);
      this.openErrorModel();
      return;
    }

    let subcategoryEntityDto = ApplicationUtils.clone(this.selectedRfxSubcategoryForEnableDisable);
    subcategoryEntityDto!.active = action == "ENABLE";

    this.isEnableOrDisableLoading = true;

    this.adminDashboardService.markRfxSubcategoryActiveOrInActive(subcategoryEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let rfxValidationErrorCodeDtoList = apiResponseDto.data as Array<RfxValidationErrorCodeDto>;
          if (!rfxValidationErrorCodeDtoList || rfxValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isEnableOrDisableLoading = false;
            setTimeout(() => {
              this._showSuccessToast$.next(false);
              this.subcategoryActiveStatus = action == "ENABLE";
              this.closeModal(this.enableDisabledModalRef);
            }, 2000)
          } else {
            this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
            this.openErrorModel();
            this.isEnableOrDisableLoading = false;
          }
        } else {
          if (apiResponseDto.code == 'RFX-EXCEPTION-127') {
            this._errorMsg$.next("Error while enabling Subcategory . Enable Rfx and try again.");
          } else {
            this._errorMsg$.next(apiResponseDto?.message!);
          }
          this._showErrorToast$.next(true);
          this.isEnableOrDisableLoading = false;

        }
      },
      error: (err) => {
        console.error(err);
        this.isEnableOrDisableLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next(`Error while ${action == 'ENABLE' ? 'enabling' : 'disabling'} Subcategory. Try again.`);

      }
    })
  }



  filterSubcategoryList(searchText: string) {

    if (searchText) {
      let tempRfxSubcategoryEntityDtos = [... this.allRfxSubcategoryList ?? []];
      let filterList = tempRfxSubcategoryEntityDtos.filter(item => item.subcategorySequence == Number(searchText.trim) || item.title?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || item.description?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || item.preferenceCategory?.categoryName?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || item.preferenceCategory?.categoryLevelOneName?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || item.preferenceCategory?.categoryLevelTwoName?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || item.preferenceCategory?.categoryLevelThreeName?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())
        || item.preferenceCategory?.categoryLevelFourName?.trim().toLocaleLowerCase().includes(searchText.trim().toLowerCase())

      );

      this._filterRfxSubcategoryList$.next(filterList);

    } else {
      this._filterRfxSubcategoryList$.next(this.allRfxSubcategoryList);
    }

  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.rfxValidationErrorCodeDtoList;
  }


  getSubcategorySequence() {
    let toReturn = 1;
    let allSubcategoryList = this.adminRfxSubcategoryDataHolderService._allRfxSubcategoryList$.value;
    if (allSubcategoryList && allSubcategoryList.length > 0) {
      allSubcategoryList.sort((a, b) => b.subcategorySequence! - a.subcategorySequence!);
      toReturn = allSubcategoryList[0].subcategorySequence! + 1
    }
    return toReturn;
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT && !this.rfxEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.rfxEntityDto?.status == RfxStatus.LIVE && !this.rfxEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}



