import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UserUiDto } from '../../models/user/UserUiDto';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { CountryCodeDto } from '../../models/CountryCodeDto';
import { firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectOrgOrganogramUiDto } from '../../state-management/onboarding/onboarding.features';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit {
  @Input() userDto?: UserUiDto | UserUiDto;
  @Output() onEditProfile = new EventEmitter<boolean>(false);
  selectedCompanyName: string | undefined;

  constructor(private store : Store ) {}

  ngOnInit(): void {
     this.populateCompanyName(this.userDto?.companyCode!);

  }

  toggleEditMode() {
    this.onEditProfile.emit(true);
  }

  async populateCompanyName(companyCode: string){
    let orgOrganogramUiDto = await firstValueFrom(this.store.select(selectOrgOrganogramUiDto));
    let companies = orgOrganogramUiDto?.companyUiDtos || [];
    let company = companies.filter(item => item.companyCode == companyCode)[0];
    
    if(company){
      this.selectedCompanyName =  company.companyName;
    }
  }


  getCountryCode(countryCode: CountryCodeDto) {
    return ApplicationUtils.getCountryCode(countryCode);
  }
}
