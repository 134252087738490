<div>
  <div class="grid grid-cols-2 gap-2 w-full items-center justify-between py-4 px-6">
    <div class="">
      <span class=""> <img src="/assets/icons/drawer-head-icon-1.svg" class="tr-xl-icon c-pointer"></span>

      <div class="d-inline-block tr-margin-left-10px inline-flex ml-2  text-gray-300 font-semibold text-xl leading-4">
        Adding New Budget
      </div>
    </div>
    <div class=" text-end">
      <button class="bg-gray-50 text-gray-700 text-sm px-4 py-2 rounded-md mr-2 mr-2" (click)="closeDrawer()">Cancel</button>
      <button class="bg-blue-100 text-white text-sm px-4 py-2 rounded-md" (click)="savePlantsBudget()">Save as draft</button>
    </div>
  </div>
  <div class="tr-form-space-and-background-drawer tr-overflow-y-drawer">

    <div class="">
      <div class="grid md:grid-cols-3 grid-cols-1 w-full gap-3 mb-3">
        <div class="">
          <label for="budget" class="text-gray-700  text-sm font-semibold leading-4">Set Procurement Budget</label>
          <div class="flex w-full mb-3">
            <div class="input-group-prepend">
              <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg bg-white h-8 flex py-1 px-3" id="basic-addon2">
                {{selectedCompanyUiDto?.currencyDto?.symbol}}
              </span>
            </div>
            <input pInputText type="text" class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0" 
              placeholder="---"  [formControl]="ctrlProcurementBudget" (blur)="refreshOrgBudget()">
          </div>
        </div>
        <div class="">
          <label for="accountTitle" class="text-gray-700  text-sm font-semibold leading-4">Alloted Amount</label>
          <div class="flex w-full mb-3">
            <div class="input-group-prepend">
              <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg bg-white h-8 flex py-1 px-3">
                {{selectedCompanyUiDto?.currencyDto?.symbol}}
              </span>
            </div>
              <input pInputText type="text" class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0"
                [formControl]="ctrlAllotedAmount" [disabled]="true" placeholder="---"  >
            
          </div>
        </div>
        <div class="">
          <label for="accountTitle" class="text-gray-700  text-sm font-semibold leading-4">Unallocated Amount</label>
          <div class="flex w-full mb-3">
            <div class="input-group-prepend">
              <span class="border border-solid border-slate-50 border-r-0 rounded-l-lg bg-white h-8 flex py-1 px-3">
                {{selectedCompanyUiDto?.currencyDto?.symbol}}
              </span>
            </div>
            
              <input pInputText type="text" class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0"
                [formControl]="ctrlUnAllotedAmount" [disabled]="true" placeholder="---">
           
          </div>
        </div>
      </div>
  
      <div class="needs-validation w-100 budget-table">
        <label class="text-gray-700  text-sm font-semibold leading-4" for="safetyLevels">Set Budget by cost center</label>
        <div class="tr-border">
          <div class="col-12">
            <div class="card ">
              <p-table [value]="ngTableDataList" dataKey="id" [scrollable]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="tr-tble-th-font" pFrozenColumn></th>
                    <th class="tr-tble-th-font tr-th-bg-lightblue" colspan="2" pFrozenColumn>Budget distribution by cost
                    </th>
                    <th class="tr-tble-th-font tr-th-bg-yellow" [colSpan]="plantUiDtos.length">Available Plants</th>
                  </tr>
                  <tr>
                    <th style="min-width:100px" class="tr-table-th-font-2 tr-th-bg-gray" pFrozenColumn></th>
                    <th style="min-width:200px" class="tr-table-th-font-2 tr-th-bg-gray" pFrozenColumn>Cost centers</th>
                    <th style="min-width:200px" class="tr-table-th-font-2 tr-th-bg-gray" pFrozenColumn>Budget Amount
                    </th>
                    <th style="min-width:200px" class="tr-table-th-font-2 tr-th-bg-gray"
                      *ngFor="let plant of selectedCompanyUiDto?.plantUiDtos;">
                      {{plant.plantName}} ({{plant.plantCode}})
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-tableData let-editing="editing" let-i="rowIndex">
                  <tr class="bg-white">
                    <td style="min-width:100px" pFrozenColumn>{{ i+1 }}</td>
                    <td style="min-width:100px" pFrozenColumn>{{ tableData.costCenterName }}</td>
                    <td style="min-width:200px" [pEditableColumn]="tableData.costCenterBudget"
                      pEditableColumnField="costCenterBudget" pFrozenColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText pKeyFilter="int" [(ngModel)]="tableData.costCenterBudget" placeholder="-"
                            [ngClass]="{'ng-invalid ng-dirty' : haveErrorsInPlantCostCenter(tableData)}"
                            (blur)="refreshOrgBudget()" />
                        </ng-template>
                        <ng-template pTemplate="output">
                          <input pInputText pKeyFilter="int" [(ngModel)]="tableData.costCenterBudget" placeholder="-"
                            [ngClass]="{'ng-invalid ng-dirty' : haveErrorsInPlantCostCenter(tableData)}" />
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td style="min-width:200px" [pEditableColumn]="plant.plantBudget"
                      pEditableColumnField="plantsBudget"
                      *ngFor="let plant of tableData.plantsBudget; index as plantIndex;">
                      <p-cellEditor *ngIf="plant.allowBudget">
                        <ng-template pTemplate="input">
                          <input pInputText pKeyFilter="int" [(ngModel)]="plant.plantBudget" placeholder="-"
                            (blur)="refreshOrgBudget()" />
                        </ng-template>
                        <ng-template pTemplate="output">
                          <input pInputText pKeyFilter="int" [(ngModel)]="plant.plantBudget" placeholder="-" />
                        </ng-template>
                      </p-cellEditor>
                      <span *ngIf="!plant.allowBudget"> Not Applicable </span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <app-message-toast *ngIf="_showSuccessToast$ | async" message="Budget Saved"></app-message-toast>
      <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="(this._showErrorMsg$ | async)">
      </app-message-toast>
    </div>

    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner"></div>
    </div>
  </div>

</div>