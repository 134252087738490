import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { RfxValidationErrorCodeDto } from '../../models/user/RfxValidationErrorCodeDto';
import { AdminRfxSubcategoryDataHolderService } from '../../services/AdminRfxSubcategoryDataHolderService.service';
import { RfxSubcategoryUiDto } from '../../models/rfx/RfxSubcategoryUiDto';

@Component({
  selector: 'app-subcategory-error-validation',
  templateUrl: './subcategory-error-validation.component.html',
  styleUrls: ['./subcategory-error-validation.component.sass']
})
export class SubcategoryErrorValidationComponent implements OnInit {
  @Input() errorDtoList!: RfxValidationErrorCodeDto[];
  @Output() onSubmit = new EventEmitter<string[]> ();
  @ViewChild('termsHeader') termsHeader?: ElementRef<HTMLElement>
  
  subcategoryIdsList: string[] = []
  selectedSubcategoryIdsList: string[] = []
  allRfxSubcategories: RfxSubcategoryUiDto[] = []

  ctrlAllSelect: FormControl = new FormControl(false);

  constructor(
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.adminRfxSubcategoryDataHolderService.getAllRfxSubcategoryList$.subscribe((data) => {
      if (data && data.length > 0) {
        this.allRfxSubcategories = data;

        this.allRfxSubcategories.forEach(item => {
          if (!item.active && !this.subcategoryIdsList.includes(item.subcategoryId!)) {
            this.subcategoryIdsList.push(item.subcategoryId!)

            // Select Already Passed Items
            if (!this.haveAnyError(item.subcategoryId!)) {
              this.selectSubcategoryForActive(item.subcategoryId!);
            }
          }
        })
      }
    })

    this.ctrlAllSelect.valueChanges.subscribe((value) => {
      if (value) {
        this.subcategoryIdsList.forEach(subcategoryId => {
          if (!this.haveAnyError(subcategoryId)) {
            if (!this.selectedSubcategoryIdsList.includes(subcategoryId)) {
              this.selectedSubcategoryIdsList.push(subcategoryId);
            }
          }
        })
      } else {
        this.selectedSubcategoryIdsList = [];
      }
    })
  }

  getSubcategoryById(subcategoryId: string): RfxSubcategoryUiDto | undefined {
    if (this.allRfxSubcategories && this.allRfxSubcategories.length > 0) {
      return this.allRfxSubcategories.find(item => item.subcategoryId == subcategoryId);
    }
    return undefined;
  }

  haveAnyError(subcategoryId: string): boolean {
    if (this.allRfxSubcategories && this.allRfxSubcategories.length > 0) {
      let error = this.errorDtoList.find(item => item.subcategoryId == subcategoryId);
      if (error) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  selectSubcategoryForActive(subcategoryId: string) {
    if (!this.selectedSubcategoryIdsList.includes(subcategoryId)) {
      this.selectedSubcategoryIdsList.push(subcategoryId)
    } else {
      let index = this.selectedSubcategoryIdsList.findIndex(item => item == subcategoryId);
      this.selectedSubcategoryIdsList.splice(index, 1);
    }
  }

  isSubcategorySelected(subcategoryId: string) {
    if (this.selectedSubcategoryIdsList.includes(subcategoryId)) {
      return true;
    } else {
      return false;
    }
  }



  onButtonClick() {
    this.onSubmit.emit(this.selectedSubcategoryIdsList);
  }

  closeModal() {
    this.activeModal.close();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.termsHeader?.nativeElement.scrollIntoView()
    })
  }
}
