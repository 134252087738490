import { ApprovalWorkflowEnum } from "../../enums/ApprovalWorkflowEnum";
import { EventEnum } from "../../enums/EventEnum";

export class AWFDefineUiDto {
  orgCode?: string;
  themeName?: string;
  approvalId?: string;
  resourceId?: string;
  subResourceId?: string;
  resourceType?: EventEnum;
  name?: string;
  type?: string;
  wfCode?: ApprovalWorkflowEnum;
  companyCode?: string;
  plantCode?: string;
  conditionType?: string;
  ruleActive?: boolean;

  noOfApprovals?: number;
  mandatoryApprovals?: number;

  version?: number;
}