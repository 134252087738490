import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CompFinancialWrapperDto } from 'src/app/shared/models/rfx/CompFinancialWrapperDto';
import { CompNormalizeItemsWrapperDto } from 'src/app/shared/models/rfx/CompNormalizeItemsWrapperDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { SubcategorySubmissionDto } from 'src/app/shared/models/rfx/SubcategorySubmissionDto';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CompNormalizeItemData } from 'src/app/shared/models/rfx/CompNormalizeItemData';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { ContractTypeEnum } from 'src/app/shared/enums/rfx/ContractTypeEnum';
import { CatalogueRequisitionWrapper } from 'src/app/shared/models/CatalogueRequisitionWrapper';
import { UUID } from 'angular2-uuid';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { CatalogueCartWrapperDto } from 'src/app/shared/models/CatalogueCartWrapperDto';
import { CatalogueLineItemsEntityDto } from 'src/app/shared/models/CatalogueLineItemsEntityDto';
import { CatalogueVendorDto } from 'src/app/shared/models/CatalogueVendorDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { CategoryDto } from 'src/app/shared/models/CategoryDto';
import { MasterDataDto } from 'src/app/shared/models/MasterDataDto';
import { Store } from '@ngrx/store';
import { NavigationEventsDto } from 'src/app/shared/models/NavigationEventsDto';
import { NavigationActions } from 'src/app/shared/state-management/navigation/navigation.actions';

@Component({
  selector: 'app-rfx-evaluation-normalise-items',
  templateUrl: './rfx-evaluation-normalise-items.component.html',
  styleUrls: ['./rfx-evaluation-normalise-items.component.sass']
})
export class RfxEvaluationNormaliseItemsComponent implements OnInit, OnDestroy {
  @Input() comFinancialWrapperDto?: CompFinancialWrapperDto;
  @Input() subcategorySubmissionDto?: SubcategorySubmissionDto;

  rfxEntityDto?: RfxUiDto;
  rfxSubcategoryEntityDto?: RfxSubcategoryUiDto;
  compNormalizeItemsWrapper?: CompNormalizeItemsWrapperDto;

  isLoading: boolean = false;
  normalizePrice: number = 0;
  noOfItemsRequiredNormalization: number = 0;

  compNormalizeItemsList$ = new BehaviorSubject<CompNormalizeItemData[]>([]);
  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  selectedRfxSubscription$?: Subscription;
  compNormalizeItemsWrapperSubscription$?: Subscription;

  constructor(
    private activeModal: NgbActiveModal,
    private adminDashboardService: AdminDashboardService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private catalogueService: CatalogueService,
    private dataRedirectionService: DashboardRedirectionService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.getCompNormalizeItemsData();

    this.normalizePrice = this.comFinancialWrapperDto?.normalizePrice ?? 0;
    this.noOfItemsRequiredNormalization = this.comFinancialWrapperDto?.noOfItemsRequiredNormalization ?? 0;

    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(rfx => {
      if (rfx) {
        this.rfxEntityDto = rfx;

        this.rfxSubcategoryEntityDto = this.adminRfxSubcategoryDataHolderService.allRfxSubcategoryList?.find(item => item.subcategoryId == this.subcategorySubmissionDto?.subcategoryId);
      }
    })

    this.compNormalizeItemsWrapperSubscription$ = this.adminDashboardService.getCompNormalizeItemsWrapperDto$.subscribe(data => {
      if (data) {
        this.compNormalizeItemsWrapper = data;

        let compNormalizeItemsList = this.compNormalizeItemsWrapper.compNormalizeItemData ?? [];
        compNormalizeItemsList = this.sortTechnicalQuestions(compNormalizeItemsList);
        this.compNormalizeItemsList$.next(compNormalizeItemsList);
      } else {
        this.compNormalizeItemsWrapper = undefined;
        this.compNormalizeItemsList$.next([]);
      }
    })
  }

  async getCompNormalizeItemsData() {
    this.isLoading = true;

    let rfxId = this.subcategorySubmissionDto?.rfxId!;
    let subcategoryId = this.subcategorySubmissionDto?.subcategoryId!;
    let companyId = this.comFinancialWrapperDto?.companyId!;

    await this.adminDashboardService.getAndLoadCompNormalizeItemsWrapperDtoSync(rfxId, subcategoryId, companyId);

    this.isLoading = false;
  }

  closeModal(modalRef?: NgbModalRef) {
    if (modalRef) {
      modalRef.close();
    } else {
      this.activeModal.close();
    }
  }

  getFormattedContractType(type: ContractTypeEnum) {
    let toReturn = '';

    if (type == 'ITEM_WISE') {
      toReturn = 'Item Wise'
    } else if (type == 'ITEM_RATE') {
      toReturn = 'Item Rate'
    } else if (type == 'PERCENTAGE') {
      toReturn = 'Percentage'
    } else if (type == 'LUMPSUM') {
      toReturn = 'Lumpsum'
    } else if (type == 'SUPPLY_RATE') {
      toReturn = 'Supply Rate'
    }

    return toReturn;
  }

  getFormattedPrice(price: any) {
    let currency = this.rfxEntityDto?.currency;
    return (currency?.symbol + ApplicationUtils.getFormattedPrice(currency?.locale, price));
  }

  sortTechnicalQuestions(data: CompNormalizeItemData[]): CompNormalizeItemData[] {
    return data.sort((a, b) => {
      // Split the strings at the dot (.) to separate letter and number parts
      const [letterA, numberA] = `${a.preSequenceText}.${a.sequenceNo}`.split('.');
      const [letterB, numberB] = `${b.preSequenceText}.${b.sequenceNo}`.split('.');

      // Sort by letter (ascending)
      if (letterA < letterB) return -1;
      if (letterA > letterB) return 1;

      // If letters are the same, sort by number (ascending)
      return Number(numberA) - Number(numberB);
    });
  }

  generatePO() {
    let items: CatalogueCartWrapperDto[] = [];
    let compNormalizeItemsList = this.compNormalizeItemsList$.value;
    let totalPrice = 0;
    let totalTax = 0;

    let categoryL1Id = UUID.UUID().toString();
    let categoryL2Id = UUID.UUID().toString();
    let lineItemId = UUID.UUID().toString();

    compNormalizeItemsList.forEach(item => {
      let catalogueVendor = new CatalogueVendorDto();
      catalogueVendor.amount = Number(item.bidAmount);
      catalogueVendor.companyName = this.comFinancialWrapperDto?.companyName;
      catalogueVendor.currency = this.rfxEntityDto?.currency;
      catalogueVendor.emailId = this.comFinancialWrapperDto?.emailId;
      catalogueVendor.tax = item.taxRate;

      let lineItem = new CatalogueLineItemsEntityDto();
      lineItem.categoryL1Id = categoryL1Id;
      lineItem.categoryL2Id = categoryL2Id;
      lineItem.lineItemId = lineItemId;
      lineItem.title = item.itemName;
      lineItem.description = item.description;
      lineItem.catalogueVendors = [catalogueVendor]
      
      let cartWrapper = new CatalogueCartWrapperDto();
      cartWrapper.catalogueLineItemsEntityDto = lineItem;
      cartWrapper.quantity = 1;
      cartWrapper.currency = this.rfxEntityDto?.currency;
      cartWrapper.catalogueVendor = catalogueVendor;

      totalPrice = totalPrice + Number(item.bidAmount);
      if (item.taxRate) {
        totalTax = totalTax + (Number(item.bidAmount) * Number(item.taxRate)) / 100;
      }

      items.push(cartWrapper);
    })

    let catalogueRequisitionWrapper = new CatalogueRequisitionWrapper();
    catalogueRequisitionWrapper.id = UUID.UUID().toString();
    catalogueRequisitionWrapper.catalogueCartWrapperDtos = items;
    catalogueRequisitionWrapper.categoryL1Id = categoryL1Id;
    catalogueRequisitionWrapper.categoryL1Name = this.rfxSubcategoryEntityDto?.preferenceCategory?.categoryName;
    catalogueRequisitionWrapper.categoryL2Id = categoryL2Id;
    catalogueRequisitionWrapper.categoryL2Name = this.rfxSubcategoryEntityDto?.preferenceCategory?.categoryLevelOneName;
    catalogueRequisitionWrapper.isApproved = false;
    catalogueRequisitionWrapper.totalPrice = totalPrice + totalTax;
    catalogueRequisitionWrapper.totalTaxAmount = totalTax;

    this.catalogueService.submitRequisition(catalogueRequisitionWrapper);

    this.closeModal();

    // this.dataRedirectionService.storeNavigationContext(
    //   ApplicationConstants.ADMIN,
    //   ApplicationConstants.ADMIN_CATALOGUE_REQUISITION_PAGE
    // );
    // this.dataRedirectionService.setUpdateBreadcrumbs(true);

    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.ADMIN;
    navigation.pageName = ApplicationConstants.ADMIN_CATALOGUE_REQUISITION_PAGE;

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
  }

  ngOnDestroy(): void {
    if (this.compNormalizeItemsWrapperSubscription$) {
      this.compNormalizeItemsWrapperSubscription$.unsubscribe();
    }
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
  }
}
