<div>
  <ng-container [ngTemplateOutlet]="isShowOtpScreen ? resetPasswordScreen : generateOtpScreen"></ng-container>
</div>

<ng-template #generateOtpScreen>
  <div class="m-3">
    <div class="text-start mb-2">
      Enter your email to receive one time password.
    </div>
    <form class="needs-validation" novalidate>
      <label class="text-gray-700  text-sm font-semibold leading-4">
        Email address
      </label>
      <div class="">
        <input class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-slate-50 h-8 px-2 py-0 text-gray-100 text-sm" type="email" id="welcomeEmail" formControlName="emailId"
          placeholder="email@company.com" [formControl]="ctrlEmail" (keyup)="hideToastMessage()"
          [ngClass]="{'is-invalid': ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)}">
          <div *ngIf="ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)">
            <p class="text-red-500 " *ngIf="ctrlEmail.errors?.['required']">Required</p>
            <p class="text-red-500 " *ngIf="ctrlEmail.errors?.['pattern']">Invalid email address</p>
            <p class="text-red-500 " *ngIf="ctrlEmail.value && ctrlEmail.value.length > 50">Maximum Length Exceed</p>
          </div>
      </div>
      <!-- <div class="form-floating mb-3">
        <input type="email" class="form-control" id="ctrlEmail" placeholder="name@example.com"
          [formControl]="ctrlEmail" (keyup)="hideToastMessage()"
          [ngClass]="{'is-invalid': ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)}">
        <label for="ctrlEmail">Email address</label>
        <div *ngIf="ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)">
          <p class="text-red-500 " *ngIf="ctrlEmail.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="ctrlEmail.errors?.['pattern']">Invalid email address</p>
          <p class="text-red-500 " *ngIf="ctrlEmail.value && ctrlEmail.value.length > 50">Maximum Length Exceed</p>
        </div>
      </div> -->

      <div class="my-3">
        <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="_successMsg$ | async"></app-message-toast>
        <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
          [message]="_errorMsg$ | async"></app-message-toast>
      </div>

      <div class="text-center  mb-3" *ngIf="isLoading">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" (click)="generateOTP()" ngbAutoFocus>SEND OTP</button>
      </div>
    </form>

  </div>
  <!-- <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Reset Password</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <div class="text-start mb-2">
          Enter your email to receive one time password.
        </div>
        <form class="needs-validation" novalidate>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="ctrlEmail" placeholder="name@example.com"
              [formControl]="ctrlEmail" (keyup)="hideToastMessage()"
              [ngClass]="{'is-invalid': ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)}">
            <label for="ctrlEmail">Email address</label>
            <div *ngIf="ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)">
              <p class="text-red-500 " *ngIf="ctrlEmail.errors?.['required']">Required</p>
              <p class="text-red-500 " *ngIf="ctrlEmail.errors?.['pattern']">Invalid email address</p>
              <p class="text-red-500 " *ngIf="ctrlEmail.value && ctrlEmail.value.length > 50">Maximum Length Exceed</p>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="_successMsg$ | async"></app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
              [message]="_errorMsg$ | async"></app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="generateOTP()" ngbAutoFocus>SEND OTP</button>
          </div>
        </form>

      </div>
    </div>
  </div> -->
</ng-template>

<ng-template #resetPasswordScreen>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Reset Password</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
   <div class="model-body-top">
    <div class="m-3">
      <div class="text-start mb-2">
        Enter the One Time Password (OTP) which was just sent to the email address you registered with. Be sure to check your spam folder.
      </div>
      <form class="needs-validation" novalidate [formGroup]="formGroup">
        <div class="form-floating mb-3">
          <input type="number" class="form-control" id="otp" placeholder="Enter OTP" formControlName="otp"
            (keyup)="hideToastMessage()" [ngClass]="{'is-invalid': fc.otp.invalid && (fc.otp.dirty || fc.otp.touched)}">
          <label for="otp">Enter OTP</label>
          <div *ngIf="(_validationErrorPresent$ |async) || fc.otp.invalid && (fc.otp.dirty || fc.otp.touched)">
            <p class="text-red-500 " *ngIf="fc.otp.errors?.required">Required</p>
          </div>
        </div>

        <div class="text-end mb-3">
          <button type="button" class="btn btn-link" (click)="resendOTP()">Resend OTP</button>
        </div>
        <div class="input-group">
          <div class="form-floating">
            <input [type]="isPasswordVisible ? 'text' : 'password'" class="form-control border-end-0" id="password"
              placeholder="Password" formControlName="password" (keyup)="hideToastMessage()" (keyup)="onChange()" (change)="onChange()"
              [ngClass]="{'is-invalid': fc.password.invalid && (fc.password.dirty || fc.password.touched)}">
            <label for="password">Password</label>
          </div>
          <span class="input-group-text bg-white border-start-0" (click)="togglePasswordVisibility()">
            <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer" *ngIf="!isPasswordVisible">
            <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer" *ngIf="isPasswordVisible">
          </span>
        </div>
        <div
          *ngIf="(_validationErrorPresent$ |async) && fc.password.invalid || fc.password.invalid && (fc.password.dirty || fc.password.touched)">
          <p class="text-red-500 " *ngIf="fc.password.invalid && (!fc.password.value)">
            Required</p>
          <p class="text-red-500 " *ngIf="fc.password.invalid &&  (fc.password.value)">
            Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
            one number and one special character.</p>
        </div>

        <div class="input-group mt-3">
          <div class="form-floating">
            <input [type]="isRepeatPasswordVisible ? 'text' : 'password'" class="form-control border-end-0" id="confirmPassword"
              placeholder="Re-enter Password" formControlName="confirmPassword" (keyup)="onChange()" (change)="onChange()"
              [ngClass]="{'is-invalid': fc.confirmPassword.invalid && (fc.confirmPassword.dirty || fc.confirmPassword.touched)}">
            <label for="confirmPassword">Re-enter Password</label>
          </div>
          <span class="input-group-text bg-white border-start-0" (click)="toggleRepeatPasswordVisibility()">
            <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer"
              *ngIf="!isRepeatPasswordVisible">
            <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer"
              *ngIf="isRepeatPasswordVisible">
          </span>
        </div>
        <div class="mb-3">
          <p class="text-red-500 "
            *ngIf="(_validationErrorPresent$ |async) && fc.confirmPassword.invalid || fc.confirmPassword.invalid && (fc.confirmPassword.dirty || fc.confirmPassword.touched)">
            Required
          </p>
          <p class="text-red-500 " *ngIf="!fc.confirmPassword.invalid && !(_isPasswordMatch$ | async)">
            Password Not Match
          </p>
        </div>

        <div class="my-3">
          <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="_successMsg$ | async"></app-message-toast>
          <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true"
            [message]="_errorMsg$ | async"></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " ngbAutoFocus (click)="forgotPassword()">RESET
            PASSWORD</button>
        </div>
      </form>
    </div>
   </div>
  </div>
</ng-template>
