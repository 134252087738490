import { QuestionnaireStatus } from "../../enums/questionnaire/QuestionnaireStatus";
import { AdditionalFinancialPrice } from "./AdditionalFinancialPrice";

export class FinancialQuestionTemplate {
  questionId?: string;
  sectionId?: string;
  questionnaireId?: string;
  questionText?: string;
  questionDescription?: string;
  preSequenceText?: string;
  sequenceNo?: number;
  orgCode?: string;
  auctionId?: string;
  rfxId?: string;
  rfxSubcategoryId?: string;
  openerComments?: string;
  questionStatus?: QuestionnaireStatus;
  quantity?: number;
  uom?: string;
  tax?: number;
  additionalPrices?: AdditionalFinancialPrice[]

  updateTimeUtc?: string;
  updateTimeUtcMillis?: number;
}
