import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventEnum } from 'src/app/shared/enums/EventEnum';
import { EventStages } from 'src/app/shared/enums/EventStages';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { AuctionEntityDto } from 'src/app/shared/models/user/AuctionEntityDto';
import { AuctionStatus } from 'src/app/shared/models/user/AuctionStatus';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';

@Component({
  selector: 'app-event-stages',
  templateUrl: './event-stages.component.html',
  styleUrls: ['./event-stages.component.sass']
})
export class EventStagesComponent implements OnInit, OnDestroy {
  @Input()  eventType: string = "AUCTION";
  
  auctionEntityDto?: AuctionEntityDto
  rfxEntityDto?: RfxUiDto

  eventStages: typeof EventStages = EventStages;

  activeStage: EventStages = EventStages.EVENT;
  currentStageIndex: number = 1;

  currentEventStage$ = new BehaviorSubject<EventStages>(EventStages.EVENT);

  constructor(
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService
  ) {}

  ngOnInit(): void {
    if (this.eventType == EventEnum.AUCTION) {
      this.auctionEntityDto = this.adminSourcingEventsDataHolderService.getSelectedAuction();
    } else {
      this.rfxEntityDto = this.adminSourcingEventsDataHolderService.getSelectedRfx();
    }

    this.checkCurrentStage();
  }

  checkCurrentStage() {
    if (this.eventType == EventEnum.AUCTION) {
      if (this.auctionEntityDto?.status == AuctionStatus.DRAFT || this.auctionEntityDto?.status == AuctionStatus.LIVE
        || this.auctionEntityDto?.status == AuctionStatus.LIVE_WAIT) {
        this.currentStageIndex = 1;
        this.toggleCurrentStage(EventStages.EVENT);
      } else if (this.auctionEntityDto?.status == AuctionStatus.CLOSE) {
        this.currentStageIndex = 2;
        this.toggleCurrentStage(EventStages.POST_EVENT);
      }
    } else {
      if (this.rfxEntityDto?.status == RfxStatus.DRAFT || this.rfxEntityDto?.status == RfxStatus.LIVE
        || this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT) {
        this.currentStageIndex = 1;
        this.toggleCurrentStage(EventStages.EVENT);
      } else if (this.rfxEntityDto?.status == RfxStatus.CLOSE) {
        this.currentStageIndex = 2;
        this.toggleCurrentStage(EventStages.POST_EVENT);
      }
    }
  }

  toggleCurrentStage(newStage: EventStages) {
    if (newStage == EventStages.POST_EVENT) {
      if (this.eventType == EventEnum.AUCTION) {
        if (this.auctionEntityDto?.status != AuctionStatus.CLOSE) {
          return;
        }
      } else {
        if (this.rfxEntityDto?.status != RfxStatus.CLOSE) {
          return;
        }
      }
    }
    
    this.activeStage = newStage;
    this.currentEventStage$.next(this.activeStage);
  }

  ngOnDestroy(): void {
    this.currentStageIndex = 1;
  }
}
