<div class="container">
  <div class="">
    <div class="row margin-top-class">
      <div class="col">
        <img src="/downloadLandingBlob?fileId={{
          getCompanyFileId()
          }}" [alt]="organizationUiDto?.name" class="image-size" />
      </div>
      <div class="col text-align-right">
        <h2>INVOICE</h2>
      </div>
    </div>
    <div class="row margin-top-class">
      <div class="col">
        <b>Invoice Date:</b>
        <div>
          <small>
            {{ invoiceRequest?.invoiceDate }}
          </small>
        </div>
      </div>
      <div class="col text-align-right">
        <b>Invoice To:</b>
        <div>
          <small>
            {{ invoiceRequest?.invoiceTo }}
          </small><br>
          <small>
            {{ awardedBidder.companyName }}
          </small>
        </div>
      </div>
    </div>
    <div class="table-complete margin-top-class">
      <hr class="hr" />
      <p class="ms-1 my-2"><b>Auction: </b> {{selectedAuction.auctionName}}</p>
      <table class="green-header-table">
        <thead>
          <tr>
            <th>Lot Name</th>
            <th>Bid Price</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of awardedLots">
            <td style="width: 50%;">#{{ item?.lotSequenceNo }} {{ item?.lotName }}</td>
            <td class="amount">{{currency.symbol}}{{ formattedPrice(item?.bidPrice) }}</td>
          </tr>
          <tr>
            <td>
              <b>Total</b>
            </td>
            <td class="amount">
              <b>{{currency.symbol}}{{ formattedPrice(totalPrice) }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row margin-top-class">
      <div class="col" *ngIf="selectedAuction?.accountNo && selectedAuction?.bankName">
        <b>Send Payment To:</b>
        <div>
          <span class="me-2">Account Number:</span>
          <span>{{ awardedBidder.accountNo }}</span>
        </div>
        <div>
          <span class="me-2">Bank Name:</span>
          <span>{{ awardedBidder.bankName }}</span>
        </div>
      </div>

      <div class="col text-align-right">
        <b>Contact</b>
        <div>
          <img src="assets/icons/bidder-black.svg" class="svg-icon-class-small me-1">
          <span>{{awardedBidder.listingContact?.name}}</span>
        </div>
        <div>
          <img src="assets/icons/email-envelope.svg" class="svg-icon-class-small  me-1">
          <span>{{awardedBidder.listingContact?.emailId}}</span>
        </div>
        <div>
          <img src="assets/icons/cell-phone.svg" class="svg-icon-class-small me-1">
          <span>{{formattedMobileNo(awardedBidder.listingContact?.mobileNo,
            awardedBidder.listingContact?.countryCode)}}</span>
        </div>
      </div>
    </div>
    <div class="row margin-top-class" *ngIf="invoiceRequest?.additionDetails">
      <div class="col">
        <b>Additional details</b>
      </div>
    </div>
    <div class="row margin-top-class" *ngIf="invoiceRequest?.additionDetails">
      <div class="col">
        <p [innerHTML]="invoiceRequest?.additionDetails"></p>
      </div>
    </div>
    <div class="row margin-top-class">
      <div class="col" *ngIf="invoiceRequest?.qrCode">
        <img src="/assets/images/temp_qr_code.png" alt="" class="image-size" />
      </div>
    </div>
    <div class="row margin-top-class" *ngIf="invoiceRequest?.termsAndCondition && invoiceRequest?.termsAndCondition!.length > 0">
      <div class="col">
        <b>Terms And Conditions</b>
      </div>
    </div>
    <div class="row" *ngIf="invoiceRequest?.termsAndCondition && invoiceRequest?.termsAndCondition!.length > 0">
      <div class="col">
        <ul>
          <li *ngFor="let item of invoiceRequest?.termsAndCondition">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="header-green-class margin-top-class"></div>
</div>
