import { AddressDto } from "./Address";
import { CompanyAccountUiDto } from "./CompanyAccountUiDto";
import { CountryCodeDto } from "./CountryCodeDto";
import { Currency } from "./Currency";
import { OrgLanguageDto } from "./OrgLanguageDto";
import { PlantUiDto } from "./PlantUiDto";
import { UserUiDto } from "./user/UserUiDto";

export class CompanyUiDto {
    orgCode?: string;
    companyCode?: string;
    companyName?: string;
    primaryCompany?: boolean;
    parentCode?: string;
    legalType?: string;
    industrySector?: string;
    region?: string;
    country?: string;
    phoneCountryCode?: CountryCodeDto;
    phoneNumber?: string;
    faxCountryCode?: CountryCodeDto;
    faxNumber?: string;
    taxId1?: string;
    taxId2?: string;
    branch?: boolean;
    timeZone?: string;
    fiscalYrStart?: string;
    fiscalYrEnd?: string;
    usersCount?: number;
    currencyCodes?: Array<string>;
    orgLanguageDtos?: Array<OrgLanguageDto>;
    companyAccountUiDtos?: Array<CompanyAccountUiDto>;
    shippingAddress?: Array<AddressDto>;
    billingAddress?: Array<AddressDto>;
    plantUiDtos?: Array<PlantUiDto>;
    userInfoUiDtos?: Array<UserUiDto>;;
    allocatedBudgetAmt?: number;
    currencyDto?: Currency;
    languageDto?: OrgLanguageDto;

    version?: number
}
