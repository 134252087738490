<div class="tr-detail-page-bg">
  <div class="flex flex-row justify-between gap-4 w-full items-center">
    <div class=" text-lg font-semibold leading-6 text-gray-700 tracking-tighter">
      Plants
    </div>
    <div class=" text-end">
      <button class="inline-flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800inline-flex" (click)="openNewPlantDrawer($event)">
        <i-lucide name="plus" [size]="18" color="#fff" class="my-icon"></i-lucide> Add New Plant
      </button>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-4 w-full items-center mt-3">
    <div
      class="col-span-3 bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50 tr-padding-12px h-100">
      <div class="w-full mb-2">
        <div class="flex w-full">
          <input
            class="bg-white w-full border-r-0 flex-col self-stretch rounded-l-lg border border-solid border-slate-50 h-8 px-2 py-0 input-placeholder border-end-0"
            #password id="welcomePassword" placeholder="Search" [formControl]="ctrlSearch">
          <div class="input-group-append">
            <span class="input-group-text border-solid border-slate-50 border-l-0 rounded-l-lg h-8 bg-white"
              id="basic-addon2">
              <i-lucide name="search" [size]="16" class="my-icon c-pointer"></i-lucide>
            </span>
          </div>
        </div>
      </div>

      <div class="mb-3" *ngIf="isDataLoading">
        <div class="e-spinner"></div>
      </div>

      <ul style="list-style: none;" class="ps-0 w-100" *ngIf="!isDataLoading">
        <li class=" mb-2"
          [ngClass]="selectedPlantUiDto?.plantCode == item.plantCode ? 'tr-selected-plant': 'tr-select-plant'"
          *ngFor="let item of _filteredPlantEntityDtos$ | async" (click)="viewPlantDetails(item)">
          <div class="col-span-2 text-black font-normal leading-normal text-sm mb-2">
            {{item.plantName}}
          </div>
          <div class="text-xs font-semibold text-black opacity-50">
            {{item.plantCode}}
          </div>
        </li>
      </ul>
    </div>
    <div class="col-span-9">
      <div class="col-12">
        <div
          class="bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50 tr-plant-card-top-border">
          <div class="flex flex-row items-center justify-between w-full items-center p-3">
            <div class="">
              <div class="text-xs font-semibold text-black opacity-50">
                <i-lucide name="plus" [size]="18" color="#fff" class="my-icon"></i-lucide> Plant Name
              </div>
              <div class="text-black text-base font-semibold ">
                {{selectedPlantUiDto?.plantName}}
              </div>
            </div>
            <div class=" text-end">
              <img src="/assets/icons/tr-delete-black.svg" class="tr-lg-icon mr-2 c-pointer inline-flex">
              <span class="c-pointer" (click)="openEditPlantDrawer('BASIC_DETAILS')">
                <img src="/assets/icons/tr-edit-black.svg" class="tr-lg-icon c-pointer inline-flex">
              </span>
            </div>
          </div>
          <div class="w-full px-6  py-2">
            <div class="bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50 p-3  ">
              <div class="grid grid-cols-3 w-full items-center ">
                <div class="col-span-2" *ngIf="!isEditCostCenter">
                  <span class=" text-black font-normal leading-normal text-sm mb-2 mr-2">
                    Cost centers:
                  </span>
                  <span class="tr-plant-name-chips mr-2"
                    *ngFor="let item of selectedPlantUiDto?.plantCostCenterUiDtos">
                    {{item?.costCenterName}}
                  </span>
                </div>
                <div class=" text-end" *ngIf="!isEditCostCenter">
                  <span class="c-pointer" (click)="toggleAddCostCenter()">
                    <img src="/assets/icons/tr-plus-bage.svg" class="tr-md-icon inline-flex">
                  </span>
                </div>
                <div class="col-12 mt-2" *ngIf="isEditCostCenter">
                  <div class="form-floating mb-3">
                    <p-multiSelect [options]="costCenters" [(ngModel)]="selectedCostCenters"
                      defaultLabel="Select Cost Center" optionLabel="name" display="chip" [disabled]="isLoading">
                    </p-multiSelect>
                  </div>
                  <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex  me-2" (click)="saveCostCenters()"
                    *ngIf="!isLoading">Save</button>
                  <button class="btn btn-sm btn-secondary" (click)="toggleAddCostCenter()"
                    *ngIf="!isLoading">Cancel</button>
                  <div class="spinner-border text-primary" role="status" *ngIf="isLoading">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 w-full gap-4 items-center p-4 mt-4">
          <div class="bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
            <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
              <div class="grid grid-cols-3 w-full gap-4 items-center">
                <div class="col-span-2 text-gray-500 font-semibold leading-4 tracking-tight text-base">
                  <span class="">
                    <img src="/assets/icons/tr-bank-detail.svg" class="tr-md-icon inline-flex">
                  </span>
                  Bank Details
                </div>

                <div class="text-end  text-sm font-medium leading-4 text-blue-100 tracking-tight ">
                  <span class="c-pointer" (click)="openEditPlantDrawer('BANK_DETAILS')">
                    <i-lucide name="pencil" [size]="15" class="c-pointer my-icon inline-flex"></i-lucide>
                    Edit
                  </span>
                </div>
              </div>
            </div>
            <div class="mb-4 grid grid-cols-2 w-full gap-2 items-center p-4">
              <div class=" text-black font-semibold leading-normal text-sm mb-2 ">
                Account Title
              </div>
              <div class=" text-black font-normal leading-normal text-sm mb-2">
                {{selectedPlantUiDto?.accountTitle}}
              </div>
              <div class=" text-black font-semibold leading-normal text-sm mb-2">
                Account Holder Name
              </div>
              <div class=" text-black font-normal leading-normal text-sm mb-2">
                {{selectedPlantUiDto?.accHolderName}}
              </div>
              <div class=" text-black font-semibold leading-normal text-sm mb-2 ">
                Account Number
              </div>
              <div class=" text-black font-normal leading-normal text-sm mb-2">
                {{selectedPlantUiDto?.accNumber}}
              </div>
              <div class=" text-black font-semibold leading-normal text-sm mb-2 ">
                Account Type
              </div>
              <div class=" text-black font-normal leading-normal text-sm mb-2">
                {{selectedPlantUiDto?.accType}}
              </div>
              <div class=" text-black font-semibold leading-normal text-sm mb-2 ">
                Account Code
              </div>
              <div class=" text-black font-normal leading-normal text-sm mb-2">
                {{selectedPlantUiDto?.accCode}}
              </div>
              <div class=" text-black font-semibold leading-normal text-sm mb-2 ">
                Swift
              </div>
              <div class=" text-black font-normal leading-normal text-sm mb-2">
                {{selectedPlantUiDto?.swift}}
              </div>
              <div class=" text-black font-semibold leading-normal text-sm mb-2 ">
                Iban
              </div>
              <div class=" text-black font-normal leading-normal text-sm mb-2">
                {{selectedPlantUiDto?.iban}}
              </div>
            </div>
          </div>
          <div class="bg-white flex-col self-stretch rounded-xl border border-solid border-slate-50">
            <div class="flex py-0 px-4 h-11 items-center self-stretch gap-2.5 bg-gray-200 rounded-t-xl">
              <div class="grid grid-cols-2 gap-4 w-full">
                <div class=" text-gray-500 font-semibold leading-4 tracking-tight text-base">
                  <span class="">
                    <img src="/assets/icons/tr-address.svg" class="tr-md-icon inline-flex">
                  </span>
                  Address Details
                </div>

                <div class="text-end  text-sm font-medium leading-4 text-blue-100 tracking-tight ">
                  <span class="c-pointer" (click)="openEditPlantDrawer('BILLING_AND_SHIPPING')">
                    <i-lucide name="pencil" [size]="15" class="c-pointer my-icon inline-flex"></i-lucide>
                    Edit
                  </span>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 gap-2 p-4 w-full">
              <div class=" text-black font-semibold leading-normal text-sm mb-2 ">
                Billing Address
              </div>
              <div class=" text-black font-normal leading-normal text-sm mb-2">
                <div>{{selectedBillingAddress?.addressType}}</div>
                {{selectedBillingAddress?.addressLine1}}, {{selectedBillingAddress?.addressLine2 ?
                selectedBillingAddress?.addressLine2 + ', ' : ''}}
                {{selectedBillingAddress?.city}}, {{selectedBillingAddress?.state}},
                {{selectedBillingAddress?.country}} -
                {{selectedBillingAddress?.zipCode}}
              </div>
              <hr class="hr my-2" />
              <div class=" text-black font-semibold leading-normal text-sm mb-2 ">
                Shipping Address
              </div>
              <div class=" text-black font-normal leading-normal text-sm mb-2">
                <div>{{selectedShippingAddress?.addressType}}</div>
                {{selectedShippingAddress?.addressLine1}}, {{selectedShippingAddress?.addressLine2 ?
                selectedShippingAddress?.addressLine2 + ', ' : ''}}
                {{selectedShippingAddress?.city}}, {{selectedShippingAddress?.state}},
                {{selectedShippingAddress?.country}} - {{selectedShippingAddress?.zipCode}}
              </div>
            </div>
          </div>
      
      </div>
    </div>
  </div>
</div>