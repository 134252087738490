import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SourcingEnvelopeType } from 'src/app/shared/enums/questionnaire/SourcingEnvelopeType';
import { CatalogueCartWrapperDto } from 'src/app/shared/models/CatalogueCartWrapperDto';
import { CatalogueLineItemsEntityDto } from 'src/app/shared/models/CatalogueLineItemsEntityDto';
import { CatalogueRequisitionWrapper } from 'src/app/shared/models/CatalogueRequisitionWrapper';
import { CatalogueVendorDto } from 'src/app/shared/models/CatalogueVendorDto';
import { FinancialQuestionTemplate } from 'src/app/shared/models/questionnaire/FinancialQuestionTemplate';
import { ComNormalizeItemsEntityDto } from 'src/app/shared/models/rfx/ComNormalizeItemsEntityDto';
import { CompFinancialWrapperDto } from 'src/app/shared/models/rfx/CompFinancialWrapperDto';
import { ComparisonSheetWrapperDto } from 'src/app/shared/models/rfx/ComparisonSheetWrapperDto';
import { PurchaseOrderItemDto, RfxPurchaseOrderDto } from 'src/app/shared/models/rfx/RfxPurchaseOrderDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { SubcategorySubmissionDto } from 'src/app/shared/models/rfx/SubcategorySubmissionDto';
import { UserRfxQuestionsUiDto } from 'src/app/shared/models/rfx/UserRfxQuestionsEntityDto';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { DashboardRedirectionService } from 'src/app/shared/services/dashboard-redirection.service';
import { ExcelExportService } from 'src/app/shared/services/excel-export.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';

@Component({
  selector: 'app-rfx-evaluation-comparison-sheet',
  templateUrl: './rfx-evaluation-comparison-sheet.component.html',
  styleUrls: ['./rfx-evaluation-comparison-sheet.component.sass']
})
export class RfxEvaluationComparisonSheetComponent implements OnInit, OnDestroy {
  @Input() subcategorySubmissionDto?: SubcategorySubmissionDto;

  ctrlCompFinancialCompany: FormControl = new FormControl('');

  rfxEntityDto?: RfxUiDto;
  rfxSubcategoryEntityDto?: RfxSubcategoryUiDto;
  comparisonSheetWrapperDto?: ComparisonSheetWrapperDto;
  financialQuestionTemplates: FinancialQuestionTemplate[] = [];
  compFinancialWrapperDtos: Array<CompFinancialWrapperDto> = [];
  comNormalizeItemsEntityDtos: Array<ComNormalizeItemsEntityDto> = [];
  userRfxQuestionsEntityDtos: Array<UserRfxQuestionsUiDto> = [];

  isPurchaseOrderGenerated: boolean = false;
  isDataLoading: boolean = false;
  isDownloading: boolean = false;
  excelData: Array<any> = [];
  isLoading: boolean = false;
  errorMsg: string | undefined;

  normalizePrice: number = 0;
  noOfItemsRequiredNormalization: number = 0;

  _showErrorToast$ = new BehaviorSubject<Boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<Boolean>(false);

  selectedRfxSubscription$?: Subscription;
  comparisonSheetWrapperSubscription$?: Subscription;

  constructor(
    private adminDashboardService: AdminDashboardService,
    private ngbModal: NgbModal,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private excelExportService: ExcelExportService,
    private catalogueService: CatalogueService,
    private dataRedirectionService: DashboardRedirectionService
  ) { }

  ngOnInit(): void {
    this.selectedRfxSubscription$ = this.adminSourcingEventsDataHolderService.selectedRfx$.subscribe(rfx => {
      if (rfx) {
        this.rfxEntityDto = rfx;
        this.rfxSubcategoryEntityDto = this.adminRfxSubcategoryDataHolderService.allRfxSubcategoryList?.find(item => item.subcategoryId == this.subcategorySubmissionDto?.subcategoryId);
        this.loadSubcategorySubmissions();
      }
    })

    this.comparisonSheetWrapperSubscription$ = this.adminDashboardService.getComparisonSheetWrapperDto$.subscribe(data => {
      if (data) {
        this.comparisonSheetWrapperDto = data;
        this.financialQuestionTemplates = data.financialQuestionTemplates ?? [];
        this.comNormalizeItemsEntityDtos = data.comNormalizeItemsEntityDtos ?? [];
        this.userRfxQuestionsEntityDtos = data.userRfxQuestionsEntityDtos ?? [];

        this.isPurchaseOrderGenerated = this.comparisonSheetWrapperDto.purchaseOrderEntityDto != undefined;

        this.financialQuestionTemplates = this.sortFinancialQuestions(this.financialQuestionTemplates);

        let compFinancialWrapperDtos = data.compFinancialWrapperDtos ?? [];
        let disqualifiedBidders = compFinancialWrapperDtos.filter(item => Number(item.compSequence) == -1);
        let qualifiedBidders = compFinancialWrapperDtos.filter(item => Number(item.compSequence) > 0);

        qualifiedBidders.sort((a, b) => Number(a.compSequence) - Number(b.compSequence));
        disqualifiedBidders.sort((a, b) => Number(b.technicalScore) - Number(a.technicalScore));
        this.compFinancialWrapperDtos = qualifiedBidders.concat(disqualifiedBidders);

        this.normalizePrice = qualifiedBidders[0].normalizePrice ?? 0;
        this.noOfItemsRequiredNormalization = qualifiedBidders[0].noOfItemsRequiredNormalization ?? 0;

      } else {
        this.comparisonSheetWrapperDto = undefined;
        this.isPurchaseOrderGenerated = false;
      }
    })
  }

  isEvenOddValue(num: number): boolean { return num % 2 !== 0; }

  async loadSubcategorySubmissions() {
    this.isDataLoading = true;
    await this.adminDashboardService.getAndLoadComparisonSheets(this.rfxEntityDto?.rfxId!, this.subcategorySubmissionDto?.subcategoryId!)
    this.isDataLoading = false;
  }

  openGeneratePoModalModal(content: any) {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = false;

    this.ctrlCompFinancialCompany.reset();

    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openDownloadExcelModal(content: any) {
    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = false;

    this.exportToExcel();

    this.ngbModal.open(content, {
      size: 'sm', backdrop: 'static', keyboard: false, centered: true
    });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  getNormalizePriceOfItem(questionId: string) {
    let toReturn = 0;

    let comNormalizeItemsEntityDto = this.comNormalizeItemsEntityDtos.find(item => item.questionId == questionId);
    if (comNormalizeItemsEntityDto) {
      toReturn = comNormalizeItemsEntityDto.normalizePrice ?? 0;
    }

    return this.getFormattedPrice(toReturn);
  }


  getUserRfxQuestionDto(questionId: string, companyId: string) {
    let userRfxQuestionsEntityDto = this.userRfxQuestionsEntityDtos.find(item => item.questionId == questionId && item.companyId == companyId);
    return userRfxQuestionsEntityDto;
  }

  getTaxAmountOfItem(questionId: string, companyId: string) {
    let toReturn = 0;

    let userRfxQuestionsEntityDto = this.userRfxQuestionsEntityDtos.find(item => item.questionId == questionId && item.companyId == companyId);
    if (userRfxQuestionsEntityDto) {
      let taxAmount = userRfxQuestionsEntityDto.bidderTax ?? 0;
      let singleUnitTaxAmount = (Number(userRfxQuestionsEntityDto.bidderResponse) * taxAmount) / 100;
      toReturn = singleUnitTaxAmount * userRfxQuestionsEntityDto.bidderQuantity!;
    }

    return this.getFormattedPrice(toReturn);
  }

  getAdditionalAmountOfItem(questionId: string, companyId: string) {
    let toReturn = 0;

    let userRfxQuestionsEntityDto = this.userRfxQuestionsEntityDtos.find(item => item.questionId == questionId && item.companyId == companyId);
    if (userRfxQuestionsEntityDto && userRfxQuestionsEntityDto.additionalPrices) {
      toReturn = userRfxQuestionsEntityDto.additionalPrices.reduce((curr, prev) => curr + prev.additionalPrice!, 0)
    }

    return this.getFormattedPrice(toReturn);
  }

  getTotalPricesOfAllItems(companyId: string) {
    let toReturn = 0;

    let userRfxQuestionsEntityDtos = this.userRfxQuestionsEntityDtos.filter(item => item.envelopeType == SourcingEnvelopeType.FINANCIAL && item.companyId == companyId);
    toReturn = userRfxQuestionsEntityDtos.reduce((curr, prev) => curr + prev.unitPriceQtyTaxAdditionalPricesTotal!, 0);

    return this.getFormattedPrice(toReturn);
  }

  getFormattedPrice(price?: any) {
    if (price) {
      let currency = this.rfxEntityDto?.currency;
      return currency?.symbol + ApplicationUtils.getFormattedPrice(currency?.locale!, price);
    }
    return '';
  }

  generatePurchaseRequest() {
    let companyId = this.ctrlCompFinancialCompany.value;

    if (companyId == '') {
      return;
    }

    this._showErrorToast$.next(false);
    this.errorMsg = "";
    this.isLoading = true;

    let compFinancialWrapperDto = this.compFinancialWrapperDtos.find(item => item.companyId == companyId);

    let rfxPurchaseOrderDto = new RfxPurchaseOrderDto();
    rfxPurchaseOrderDto.auctionHouseId = compFinancialWrapperDto?.auctionHouseId;
    rfxPurchaseOrderDto.rfxId = compFinancialWrapperDto?.rfxId;
    rfxPurchaseOrderDto.subcategoryId = compFinancialWrapperDto?.subcategoryId;
    rfxPurchaseOrderDto.vendorCompanyId = compFinancialWrapperDto?.companyId;
    rfxPurchaseOrderDto.vendorEmailId = compFinancialWrapperDto?.emailId;
    rfxPurchaseOrderDto.vendorCompanyName = compFinancialWrapperDto?.companyName;
    rfxPurchaseOrderDto.compSequence = compFinancialWrapperDto?.compSequence;
    rfxPurchaseOrderDto.questionnaireId = this.financialQuestionTemplates[0].questionnaireId;
    rfxPurchaseOrderDto.currency = this.rfxEntityDto?.currency;
    rfxPurchaseOrderDto.estimatedDeliveryDate = this.rfxEntityDto?.estimatedDeliveryDate;

    if (!rfxPurchaseOrderDto.purchaseOrderItems) {
      rfxPurchaseOrderDto.purchaseOrderItems = [];
    }

    this.financialQuestionTemplates.forEach(item => {
      let userRfxQuestionsEntityDto = this.userRfxQuestionsEntityDtos.find(userRfxQuestion => userRfxQuestion.questionId == item.questionId && userRfxQuestion.companyId == compFinancialWrapperDto?.companyId);

      let purchaseOrderItemDto = new PurchaseOrderItemDto();
      purchaseOrderItemDto.sectionId = item.sectionId;
      purchaseOrderItemDto.questionId = item.questionId;
      purchaseOrderItemDto.itemName = item.questionText;
      purchaseOrderItemDto.itemDescription = item.questionDescription;
      purchaseOrderItemDto.preSequenceText = item.preSequenceText;
      purchaseOrderItemDto.sequenceNo = item.sequenceNo;
      purchaseOrderItemDto.quantity = item.quantity;
      purchaseOrderItemDto.uom = item.uom;
      purchaseOrderItemDto.tax = item.tax;
      purchaseOrderItemDto.unitPrice = userRfxQuestionsEntityDto?.bidderResponse;
      purchaseOrderItemDto.unitPriceQtyTotal = userRfxQuestionsEntityDto?.unitPriceQtyTotal;
      purchaseOrderItemDto.unitPriceQtyTaxTotal = userRfxQuestionsEntityDto?.unitPriceQtyTaxTotal;
      purchaseOrderItemDto.unitPriceQtyTaxAdditionalPricesTotal = userRfxQuestionsEntityDto?.unitPriceQtyTaxAdditionalPricesTotal;
      purchaseOrderItemDto.additionalPrices = userRfxQuestionsEntityDto?.additionalPrices;

      rfxPurchaseOrderDto.purchaseOrderItems!.push(purchaseOrderItemDto);
    })

    rfxPurchaseOrderDto.purchaseOrderAmount = rfxPurchaseOrderDto.purchaseOrderItems!.reduce((prev, curr) => prev + Number(curr.unitPriceQtyTaxAdditionalPricesTotal), 0)

    this.adminDashboardService.generatePurchaseOrder(rfxPurchaseOrderDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {

          this.isPurchaseOrderGenerated = true;
          let purchaseOrderEntityDto = apiResponseDto.data as RfxPurchaseOrderDto;

          if (!this.comparisonSheetWrapperDto?.purchaseOrderEntityDto) {
            this.comparisonSheetWrapperDto!.purchaseOrderEntityDto = new RfxPurchaseOrderDto();
            Object.assign(this.comparisonSheetWrapperDto!.purchaseOrderEntityDto, purchaseOrderEntityDto);
          }

          this._showSuccessToast$.next(true);
          this.isLoading = false;
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this.errorMsg = apiResponseDto.message;
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorMsg = "Error while generating purchase order. Try again.";
        this._showErrorToast$.next(true);
        this.isLoading = false;
      }
    })
  }

  exportToExcel() {
    this.isDownloading = true;
    const aoaData = this.prepareExcelData();

    setTimeout(() => {
      const excelFileName = 'ComparisonSheet';
      this.excelExportService.exportAsExcelFileByAoA(aoaData, excelFileName);
      this.isDownloading = false;
      this.closeModal();
    }, 5000);
  }

  prepareExcelData(): any[][] {
    const data: any[][] = [];

    // Headers for the first table
    const table1Header = ['Item', 'UoM', 'Qty', 'Tax', 'Lowest Cost by Price'];
    const styledHeader = table1Header.map(header => ({
      v: header,
      s: { font: { bold: true } }
    }));

    // Prepare the first table's data
    const table1Data = this.financialQuestionTemplates.map(item => [
      item.questionText,
      item.uom,
      item.quantity,
      item.tax ? item.tax + '%' : '',
      this.getNormalizePriceOfItem(item.questionId!),
    ]);

    // Add two empty rows at the top
    data.push(['', '', '', '', '', '', '', '']); // Empty row 1
    data.push(['', '', '', '', '', '', '', '']); // Empty row 2

    // Add the first table's header row with styles
    data.push([...styledHeader, '', '', '', '', '', '', '']); // Spacer for alignment with the second table

    // Add the first table's data rows
    table1Data.forEach((row, index) => {
      if (!data[index + 3]) {
        data[index + 3] = [];
      }
      data[index + 3].push(...row);
    });

    // Prepare the second table's data for each company
    this.compFinancialWrapperDtos.forEach((table, tableIndex) => {
      const startColumn = tableIndex * 5 + table1Header.length; // Calculate starting column for each company table

      // Company name and rank (placed in the first empty row) with styles
      if (!data[0]) {
        data[0] = [];
      }

      data[0][startColumn] = { v: '', s: { border: { left: { style: 'medium' } }, fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } } } }
      data[0][startColumn + 1] = { v: '', s: { fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } } } }
      data[0][startColumn + 3] = { v: '', s: { fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } } } }
      data[0][startColumn + 4] = { v: '', s: { border: { right: { style: 'medium' } }, fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } } } }

      const companyNameWithRank = `${table.companyName}(Rank-L${table.compSequence})`;
      data[0][startColumn + 2] = {
        v: companyNameWithRank,
        s: {
          alignment: { horizontal: 'center' },
          font: { bold: true },
          fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } }
        }
      };

      // Total Bid row (placed in the second empty row) with styles
      if (!data[1]) {
        data[1] = [];
      }

      data[1][startColumn] = { v: '', s: { border: { left: { style: 'medium' }, top: { style: 'thin' } }, fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } } } }
      data[1][startColumn + 1] = { v: '', s: { border: { top: { style: 'thin' } }, fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } } } }
      data[1][startColumn + 2] = { v: '', s: { border: { top: { style: 'thin' } }, fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } } } }
      data[1][startColumn + 3] = { v: '', s: { border: { top: { style: 'thin' } }, fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } } } }

      const totalBidAmount = `Total Bid: ${this.getTotalPricesOfAllItems(table.companyId!)}`;
      data[1][startColumn + 4] = {
        v: totalBidAmount,
        s: {
          alignment: { horizontal: 'right' },
          font: { bold: true },
          border: { right: { style: 'medium' }, top: { style: 'thin' } },
          fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } }
        }
      };

      // Headers for the second table with styles
      const table2Header = ['Unit Price', 'Subtotal', 'Tax Amount', 'Additional', 'Total Bid'];
      table2Header.forEach((header, headerIndex) => {
        if (!data[2]) {
          data[2] = [];
        }

        data[2][startColumn + headerIndex] = {
          v: header,
          s: {
            font: { bold: true },
            border: {
              bottom: { style: 'thin' },
              left: { style: headerIndex == 0 ? 'medium' : 'thin' },
              top: { style: 'thin' },
              right: { style: headerIndex == 4 ? 'medium' : 'thin' }
            },
            fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } }
          }
        }
      });

      // Prepare the second table's data rows for the company with styles
      this.financialQuestionTemplates.forEach((item, itemIndex) => {
        const rowIndex = itemIndex + 3; // Data rows start after the headers
        const isLastRow = itemIndex == this.financialQuestionTemplates.length - 1;

        if (!data[rowIndex]) {
          data[rowIndex] = [];
        }

        data[rowIndex][startColumn] = {
          v: this.getFormattedPrice(this.getUserRfxQuestionDto(item.questionId!, table.companyId!)?.bidderResponse),
          s: {
            border: {
              bottom: { style: isLastRow ? 'medium' : 'thin' },
              left: { style: 'medium' },
              top: { style: 'thin' },
              right: { style: 'thin' }
            },
            fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } }
          }
        }

        data[rowIndex][startColumn + 1] = {
          v: this.getFormattedPrice(this.getUserRfxQuestionDto(item.questionId!, table.companyId!)?.unitPriceQtyTotal),
          s: {
            border: {
              bottom: { style: isLastRow ? 'medium' : 'thin' },
              left: { style: 'thin' },
              top: { style: 'thin' },
              right: { style: 'thin' }
            },
            fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } }
          }
        }

        data[rowIndex][startColumn + 2] = {
          v: this.getTaxAmountOfItem(item.questionId!, table.companyId!),
          s: {
            border: {
              bottom: { style: isLastRow ? 'medium' : 'thin' },
              left: { style: 'thin' },
              top: { style: 'thin' },
              right: { style: 'thin' }
            },
            fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } }
          }
        }

        data[rowIndex][startColumn + 3] = {
          v: this.getAdditionalAmountOfItem(item.questionId!, table.companyId!),
          s: {
            border: {
              bottom: { style: isLastRow ? 'medium' : 'thin' },
              left: { style: 'thin' },
              top: { style: 'thin' },
              right: { style: 'thin' }
            },
            fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } }
          }
        }

        data[rowIndex][startColumn + 4] = {
          v: this.getFormattedPrice(this.getUserRfxQuestionDto(item.questionId!, table.companyId!)?.unitPriceQtyTaxAdditionalPricesTotal),
          s: {
            border: {
              bottom: { style: isLastRow ? 'medium' : 'thin' },
              left: { style: 'thin' },
              top: { style: 'thin' },
              right: { style: 'medium' }
            },
            fill: { fgColor: { rgb: tableIndex % 2 !== 0 ? 'FFFFFF' : "DAE9F8" } }
          }
        }
      });
    });

    return data;
  }

  sortFinancialQuestions(data: FinancialQuestionTemplate[]): FinancialQuestionTemplate[] {
    return data.sort((a, b) => {
      // Split the strings at the dot (.) to separate letter and number parts
      const [letterA, numberA] = `${a.preSequenceText}.${a.sequenceNo}`.split('.');
      const [letterB, numberB] = `${b.preSequenceText}.${b.sequenceNo}`.split('.');

      // Sort by letter (ascending)
      if (letterA < letterB) return -1;
      if (letterA > letterB) return 1;

      // If letters are the same, sort by number (ascending)
      return Number(numberA) - Number(numberB);
    });
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubscription$) {
      this.selectedRfxSubscription$.unsubscribe();
    }
    if (this.comparisonSheetWrapperSubscription$) {
      this.comparisonSheetWrapperSubscription$.unsubscribe();
    }
  }
}
