import { EntityActionLabelEnum } from "../enums/EntityActionLabelEnum";
import { EntityActionTypeEnum } from "../enums/EntityActionTypeEnum";

export class EntityActionDto {
  id?: string;
  label?: EntityActionLabelEnum;
  type?: EntityActionTypeEnum;

  constructor(id?: string, label?: EntityActionLabelEnum, type?: EntityActionTypeEnum) {
    this.id = id;
    this.label = label;
    this.type = type;
  }
}
