<div class="model-body">
  <header class="m-2">
    <div class="row justify-content-between">
      <div class="col-9 d-flex align-items-center">
        <h2>STATUS VALIDATION</h2>
      </div>
      <div class="col-auto text-end">
        <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
      </div>
    </div>
  </header>
  <div class="page-divider"></div>
 <div class="model-body-top">
  <div #termsHeader></div>
  <div class="m-3">
    <h6>Only the Selected and Complete lots will go LIVE.</h6>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Lot Name</th>
          <th scope="col">
            <input type="checkbox" id="ctrlAllSelect" [formControl]="ctrlAllSelect">
          </th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of lotIdsList; index as i">
          <td>{{getLotById(item)?.lotSequence}}</td>
          <td>{{getLotById(item)?.title}}</td>
          <td>
            <input type="checkbox" (change)="selectLotForActive(item)" id="selectLotForActive" [checked]="isLotSelected(item)"
              [disabled]="haveAnyError(item)" />
          </td>
          <td class="font-weight-bold" [ngClass]="haveAnyError(item) ? 'text-red-500 ' : 'text-green-500 '">
            {{haveAnyError(item) ? 'Incomplete' :
            'Complete'}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- <div class="my-3">
          <app-message-toast *ngIf="_errorMsg$ |async" [isError]="true" [message]="_errorMsg$ |async"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="Auction Discard Successfully"></app-message-toast>
        </div> -->

  <!-- <div class="mx-3 mb-3 text-end" *ngIf="isLoading">
    <div class="e-spinner"></div>
  </div>
 -->
  <div class="mx-3 mb-3 text-end">
    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="onButtonClick()" ngbAutoFocus>OK</button>
  </div>
 </div>
</div>