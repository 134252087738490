import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { EntityActionLabelEnum } from 'src/app/shared/enums/EntityActionLabelEnum';
import { EntityActionTypeEnum } from 'src/app/shared/enums/EntityActionTypeEnum';
import { EventEnum } from 'src/app/shared/enums/EventEnum';
import { EntityActionDto } from 'src/app/shared/models/EntityActionDto';
import { AdditionalFinancialPrice } from 'src/app/shared/models/questionnaire/AdditionalFinancialPrice';
import { TenderFinancialQuestionTemplateDto } from 'src/app/shared/models/tender/TenderFinancialQuestionTemplateDto';
import { TenderQuestionnaireTemplateDto } from 'src/app/shared/models/tender/TenderQuestionnaireTemplateDto';
import { TenderQuestionnaireWrapperDto } from 'src/app/shared/models/tender/TenderQuestionnaireWrapperDto';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { TenderManagementService } from 'src/app/shared/services/tender-management.service';
import { TenderManagementActions } from 'src/app/shared/state-management/tender/tender.actions';
import { selectTenderWrapperUiDto, selectTenderOpportunityWrapperDto, selectEntityActionDtos } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-new-tender-financial-question-drawer',
  templateUrl: './new-tender-financial-question-drawer.component.html',
  styleUrls: ['./new-tender-financial-question-drawer.component.sass']
})
export class NewTenderFinancialQuestionDrawerComponent implements OnInit, OnDestroy {
  tenderWrapperUiDto?: TenderWrapperUiDto;
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto;
  tenderQuestionnaireWrapperDto?: TenderQuestionnaireWrapperDto;
  tenderQuestionnaireTemplateDto?: TenderQuestionnaireTemplateDto;

  tenderFinancialQuestionTemplates: TenderFinancialQuestionTemplateDto[] = []
  totalAdditionalPrices: number = 0

  isLoading: boolean = false;
  errorMsg: string | undefined;

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);

  tenderWrapperUiDtoSubscription$?: Subscription;
  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  constructor(
    private store: Store,
    private drawerService: DrawerService,
    private tenderManagementService: TenderManagementService,
  ) { }

  ngOnInit(): void {
    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
        this.tenderQuestionnaireWrapperDto = this.tenderOpportunityWrapperDto?.tenderQuestionnaireWrapperDto;

        if (this.tenderQuestionnaireWrapperDto) {
          this.tenderQuestionnaireTemplateDto = this.tenderQuestionnaireWrapperDto.tenderQuestionnaireTemplateDto;
          this.tenderFinancialQuestionTemplates = this.tenderQuestionnaireWrapperDto.tenderFinancialQuestionTemplateDtos || [];

          this.populateFinancialQuestions();
        }
      } else {
        this.tenderOpportunityWrapperDto = undefined;
        this.tenderQuestionnaireWrapperDto = undefined;
        this.tenderFinancialQuestionTemplates = [];
      }
    })
  }

  populateFinancialQuestions() {
    if (this.tenderFinancialQuestionTemplates.length == 0) {
      this.totalAdditionalPrices = 0;
      this.addTenderFinancialQuestion();
    } else {
      let additionalPrices = this.tenderFinancialQuestionTemplates[0].additionalPrices || []
      this.totalAdditionalPrices = additionalPrices.length;
    }
  }

  newTenderFinancialQuestionTemplate() {
    let lastSequenceNo = this.tenderFinancialQuestionTemplates.length;

    let tenderFinancialQuestionTemplate = new TenderFinancialQuestionTemplateDto();
    tenderFinancialQuestionTemplate.questionId = UUID.UUID().toString();

    tenderFinancialQuestionTemplate.orgCode = this.tenderQuestionnaireTemplateDto?.orgCode;
    tenderFinancialQuestionTemplate.resourceId = this.tenderQuestionnaireTemplateDto?.resourceId;
    tenderFinancialQuestionTemplate.subResourceId = this.tenderQuestionnaireTemplateDto?.subResourceId;
    tenderFinancialQuestionTemplate.questionnaireId = this.tenderQuestionnaireTemplateDto?.templateId;
    tenderFinancialQuestionTemplate.resourceType = EventEnum.TENDER;

    tenderFinancialQuestionTemplate.preSequenceText = 'A';
    tenderFinancialQuestionTemplate.sequenceNo = lastSequenceNo + 1;

    tenderFinancialQuestionTemplate.additionalPrices = Array.from({ length: this.totalAdditionalPrices }, (_, i) => {
      return new AdditionalFinancialPrice()
    });

    return tenderFinancialQuestionTemplate;
  }

  addTenderFinancialQuestion() {
    let tenderFinancialQuestionTemplate = this.newTenderFinancialQuestionTemplate();
    this.tenderFinancialQuestionTemplates = [
      ...this.tenderFinancialQuestionTemplates,
      tenderFinancialQuestionTemplate
    ];
    
    // Save Entity Action
    this.saveEntityActionDto(tenderFinancialQuestionTemplate.questionId!, EntityActionLabelEnum.FINANCIAL, EntityActionTypeEnum.ADD);
  }

  addAdditionalPrice() {
    this.tenderFinancialQuestionTemplates = this.tenderFinancialQuestionTemplates.map(item => {
      let clonedItem = ApplicationUtils.clone(item) as TenderFinancialQuestionTemplateDto;
  
      if (!clonedItem.additionalPrices) {
        clonedItem.additionalPrices = [];
      }

      clonedItem.additionalPrices.push(new AdditionalFinancialPrice());
      return clonedItem;
    });

    this.totalAdditionalPrices++;
  }

  removeAdditionalPrice(index: number) {
    this.tenderFinancialQuestionTemplates = this.tenderFinancialQuestionTemplates.map(item => {
      let clonedItem = ApplicationUtils.clone(item) as TenderFinancialQuestionTemplateDto;

      if (clonedItem.additionalPrices) {
        clonedItem.additionalPrices.splice(index, 1);
      }
      return clonedItem;
    });

    this.totalAdditionalPrices--;
  }

  removeQuestion(questionId: string) {
    let index = this.tenderFinancialQuestionTemplates.findIndex(item => item.questionId == questionId)

    if (index >= 0 && index < this.tenderFinancialQuestionTemplates.length) {
      this.tenderFinancialQuestionTemplates = this.tenderFinancialQuestionTemplates.filter((_, i) => i !== index);
      this.saveEntityActionDto(questionId, EntityActionLabelEnum.FINANCIAL, EntityActionTypeEnum.DELETE);
    } else {
      console.error('Index out of bounds');
    }
  }

  async margeTenderWrapperUiDto() {
    let tenderWrapperUiDto = ApplicationUtils.clone(this.tenderWrapperUiDto) as TenderWrapperUiDto;
    let tenderOpportunityWrapperDto = ApplicationUtils.clone(this.tenderOpportunityWrapperDto) as TenderOpportunityWrapperDto;

    // Merge TenderQuestionnaireWrapperDto
    let tenderQuestionnaireWrapperDto = ApplicationUtils.clone(this.tenderQuestionnaireWrapperDto) as TenderQuestionnaireWrapperDto;
    tenderQuestionnaireWrapperDto.tenderFinancialQuestionTemplateDtos = [...this.tenderFinancialQuestionTemplates];

    this.tenderFinancialQuestionTemplates.forEach(item => {
      this.saveEntityActionDto(item.questionId!, EntityActionLabelEnum.FINANCIAL, EntityActionTypeEnum.UPDATE);
    })

    // Update TenderOpportunityWrapperDto
    tenderOpportunityWrapperDto.tenderQuestionnaireWrapperDto = tenderQuestionnaireWrapperDto;

    let index = tenderWrapperUiDto.tenderOpportunityWrapperDtos!.findIndex(item => item.tenderOpportunityId == tenderOpportunityWrapperDto.tenderOpportunityId);
    if (index != undefined && index > -1) {
      Object.assign(tenderWrapperUiDto.tenderOpportunityWrapperDtos![index], tenderOpportunityWrapperDto);
    } else {
      tenderWrapperUiDto.tenderOpportunityWrapperDtos!.push(tenderOpportunityWrapperDto);
    }

    this.saveEntityActionDto(tenderOpportunityWrapperDto.tenderOpportunityId!, EntityActionLabelEnum.OPPORTUNITY, EntityActionTypeEnum.UPDATE);

    // Final Tender Wrapper Ui Dto
    const entityActionDtos = await firstValueFrom(this.store.pipe(select(selectEntityActionDtos)));
    tenderWrapperUiDto.entityActionDtos = entityActionDtos;
    tenderWrapperUiDto.validationErrorCodeDtoList = [];

    return tenderWrapperUiDto;
  }

  async saveFinancialQuestions() {
    this.errorMsg = '';
    this._showErrorToast$.next(false);

    if (this.tenderFinancialQuestionTemplates.length == 0) {
      return;
    }

    let tenderWrapperUiDto = await this.margeTenderWrapperUiDto();
    console.log(tenderWrapperUiDto);

    this.isLoading = true;

    try {
      const response = await firstValueFrom(this.tenderManagementService.saveTenderWrapperUiDto(tenderWrapperUiDto));
      if (response.code === ApplicationConstants.SUCCESS_CODE) {
        this.isLoading = false;
        this._showSuccessToast$.next(true);

        setTimeout(() => {
          this._showSuccessToast$.next(false);

          const tenderWrapperUiDto = response.data as TenderWrapperUiDto;
          this.store.dispatch(TenderManagementActions.setCurrentTenderWrapperUiDto({ tenderWrapperUiDto }));
          this.store.dispatch(TenderManagementActions.clearEntityActionDtos());
        }, 2000);
      } else {
        this.isLoading = false;
        this.errorMsg = response.message;
        this._showErrorToast$.next(true);
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;

      this.errorMsg = 'Error while saving financial question. Try again.';
      this._showErrorToast$.next(true);
    }
  }

  closeQuestionDrawer() {
    this.drawerService.closeDrawer();
  }

  // Save Entity Actions
  saveEntityActionDto(id: string, label: EntityActionLabelEnum, type: EntityActionTypeEnum) {
    let entityActionDto = new EntityActionDto(id, label, type);
    this.store.dispatch(TenderManagementActions.saveEntityActionDto({ entityActionDto }));
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }

    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}
