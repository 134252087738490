import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ErrorsListModalComponent } from 'src/app/shared/components/errors-list-modal/errors-list-modal.component';
import { AdminSourcingEventsDataHolderService } from 'src/app/shared/services/AdminSourcingEventsDataHolder.service ';
import { AdminRfxSubcategoryDataHolderService } from 'src/app/shared/services/AdminRfxSubcategoryDataHolderService.service';
import { RfxSubcategoryUiDto } from 'src/app/shared/models/rfx/RfxSubcategoryUiDto';
import { RfxUiDto } from 'src/app/shared/models/rfx/RfxUiDto';
import { RfxStatus } from 'src/app/shared/enums/rfx/RfxStatus';
import { RfxValidationErrorCodeDto } from 'src/app/shared/models/user/RfxValidationErrorCodeDto';
import { RfxValidationService } from 'src/app/shared/services/rfx-validation.service';
import { RfxSubcategorySaveWrapperDto } from 'src/app/shared/models/user/RfxSubcategorySaveWrapperDto';
import { TreeSelectionMode } from 'src/app/shared/enums/TreeSelectionMode';
import { PreferenceCategoryDto } from 'src/app/shared/models/user/PreferenceCategoryDto';

@Component({
  selector: 'app-rfx-subcategory-categories-data',
  templateUrl: './rfx-subcategory-categories-data.component.html',
  styleUrls: ['./rfx-subcategory-categories-data.component.sass']
})
export class RfxSubcategoryCategoriesDataComponent implements OnInit, OnDestroy {

  rfxSubcategoryEntityDto?: RfxSubcategoryUiDto;
  rfxEntityDto?: RfxUiDto;

  highlights?: Array<string>;
  isLoading?: boolean;
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);
  _errorMsg$ = new BehaviorSubject<string>("");
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);

  rfxValidationErrorCodeDtoList?: RfxValidationErrorCodeDto[];

  TreeSelectionMode: typeof TreeSelectionMode = TreeSelectionMode;
  selectedPreferenceCategory?: PreferenceCategoryDto;

  formGroup: FormGroup;
  selectedRfxSubcategorySubscription$?: Subscription;

  separatorKeyCodes = [32, 13, 188, 51]
  inputTagPlaceholder = "Add tag and click enter"
  pasteSplitPattern = /#| #| | ,|,/g

  isShowMoreHighlights: boolean = false;
  isShowMoreHashtag: boolean = false;

 

  constructor(
    private ngbModal: NgbModal,
    private formBuilder: FormBuilder,
    private adminRfxSubcategoryDataHolderService: AdminRfxSubcategoryDataHolderService,
    private adminDashboardService: AdminDashboardService,
    private validationService: RfxValidationService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
  ) {
    this.formGroup = this.formBuilder.group({
      uom: new FormControl([]),
      qty: new FormControl('')
    })
  }



  get fc(): any { return this.formGroup.controls; }

  ngOnInit(): void {
    this.rfxEntityDto = this.adminSourcingEventsDataHolderService._selectedRfx$.value!;

    this.selectedRfxSubcategorySubscription$ = this.adminRfxSubcategoryDataHolderService._selectedRfxSubcategory$.subscribe(subcategory => {
      this.rfxSubcategoryEntityDto = subcategory!;

      if (this.rfxSubcategoryEntityDto) {
        this.populateInitialData();
      }

    })
  }

  populateInitialData() {
    this.formGroup.reset();
    this.formGroup.get('uom')?.patchValue(this.rfxSubcategoryEntityDto?.uom);
    this.formGroup.get('qty')?.patchValue(this.rfxSubcategoryEntityDto?.qty)
    this.formGroup.updateValueAndValidity()

  }



  onSelectedCategory(preferenceCategory: PreferenceCategoryDto) {
    this.selectedPreferenceCategory = preferenceCategory;
  }

  margeRfxSubcategoryEntityDto() {
    let formValue = this.formGroup.value
    let rfxSubcategoryEntityDto: RfxSubcategoryUiDto = ApplicationUtils.clone(this.rfxSubcategoryEntityDto);
    rfxSubcategoryEntityDto.uom = formValue.uom;
    rfxSubcategoryEntityDto.qty = formValue.qty;
    rfxSubcategoryEntityDto.preferenceCategory = this.selectedPreferenceCategory;
    return rfxSubcategoryEntityDto;
  }


  openMetaDataModal(content: any) {
    this._showSuccessToast$.next(false);
    this._showErrorToast$.next(false);
    this._errorMsg$.next('');

    this.populateInitialData();
    this.ngbModal.open(content, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
  }

  closeModal() {
    this.formGroup.reset();
    this.ngbModal.dismissAll()
  }


  handleValidSubmit() {
    if (!this.isAllowToEdit()) {
      return;
    }

    this._showErrorToast$.next(false);
    this._errorMsg$.next("");
    this.rfxValidationErrorCodeDtoList = [];

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }


    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    let rfxSubcategoryEntityDto = this.margeRfxSubcategoryEntityDto();
    this.adminRfxSubcategoryDataHolderService.saveRfxSubcategoryDetails(rfxSubcategoryEntityDto!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let rfxSaveWrapperDto = apiResponseDto.data as RfxSubcategorySaveWrapperDto;
          let rfxValidationErrorCodeDtoList = rfxSaveWrapperDto.rfxValidationErrorCodeDtos;

          if (!rfxValidationErrorCodeDtoList || rfxValidationErrorCodeDtoList.length == 0) {
            this._showSuccessToast$.next(true);
            this.isLoading = false;

            setTimeout(() => {
              this._isSaveButtonEnable$.next(true);
              this._showSuccessToast$.next(false);
              this.closeModal();
            }, 2000)

          } else {
            this.isLoading = false;
            this._isSaveButtonEnable$.next(true);
            this.closeModal();

            this.rfxValidationErrorCodeDtoList = rfxValidationErrorCodeDtoList;
            this.openErrorModel();
          }

        } else {
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto.message!);
          this.isLoading = false;
          this._isSaveButtonEnable$.next(true);

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving subcategory. Try again.");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);

      }
    })
  }

  ngOnDestroy(): void {
    if (this.selectedRfxSubcategorySubscription$) {
      this.selectedRfxSubcategorySubscription$.unsubscribe();
    }
  }

  openErrorModel() {
    let modalRef = this.ngbModal.open(ErrorsListModalComponent, {
      size: 'lg', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.auctionValidationErrorCodeDtoList = this.rfxValidationErrorCodeDtoList;
  }

  haveRfxSubcategoryErrors() {
    if (this.rfxSubcategoryEntityDto) {
      return this.validationService.haveRfxSubcategoryErrors(this.rfxSubcategoryEntityDto!)
    }
    return false;
  }

  isAllowToEdit() {
    let toReturn = true;

    if (this.rfxEntityDto?.status == RfxStatus.LIVE_WAIT && !this.rfxEntityDto.allowEditWhileUpcoming) {
      toReturn = false;
    }

    if (this.rfxEntityDto?.status == RfxStatus.LIVE && !this.rfxEntityDto.allowEditWhileRunning) {
      toReturn = false;
    }

    return toReturn;
  }
}

