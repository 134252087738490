import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SecurityContext, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { AuctionHouseDto } from 'src/app/shared/models/user/AuctionHouseDto';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { FileSaveResponseDto } from 'src/app/shared/models/FileSaveResponseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { SuperAdminDashboardService } from 'src/app/shared/services/super-admin-dashboard.service';
import { FileService } from 'src/app/shared/services/file.service';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { AngularEditorComponent, AngularEditorConfig } from 'src/assets/plugins/angular-editor';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { EmailTemplateService } from 'src/app/shared/services/email-template.service';
import { AiDescriptionGeneratorComponent } from 'src/app/shared/components/ai-description-generator/ai-description-generator.component';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { AddLegalPolicyComponent } from '../components/add-legal-policy/add-legal-policy.component';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { Currency } from 'src/app/shared/models/Currency';
import { LanguageService } from 'src/app/shared/services/language.service';
import { OrgLanguageDto } from 'src/app/shared/models/OrgLanguageDto';
import { ColorValidators } from 'src/app/shared/validators/color.validators';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-onboarding-new-profile',
  templateUrl: './onboarding-new-profile.component.html',
  styleUrls: ['./onboarding-new-profile.component.sass']
})
export class OnboardingNewProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onCloseEvent = new EventEmitter<string | undefined>();
  formGroup: FormGroup;
  formConfig: { [key: string]: any } = {};
  successMessages: { [key: string]: any } = {};
  formGroupColor: FormGroup;

  organizationUiDto?: OrganizationWrapperUiDto;
  currentTab: string = 'BASIC_DETAILS';
  isDataLoading: boolean = false;
  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  fileUploadError: boolean = false;
  errorMsg: string | undefined;
  currentFileLogo?: File;
  currentFileBanner?: File;
  imageTypeBanner?: string;
  imageTypeLogo?: string;
  isShowDescription: boolean = false;
  currentIndex?: number;
  primaryColor?: string
  secondaryColor?: string

  selectedCountryCode = CountryCodeDto.default();

  _errorMsg$ = new BehaviorSubject<string>("");
  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  countryCode$ = new BehaviorSubject<CountryCodeDto | null>(null);
  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);

  logoFileName: string = '';
  bannerFileName: string = '';
  isBannerActive: boolean = false;
  isLogoActive: boolean = false;
  isPrivacyLoading: boolean = false;

  imageUrlLogo?: string | ArrayBuffer | SafeUrl | null;
  imageUrlBanner?: string | ArrayBuffer | SafeUrl | null;

  isLoading: boolean = false;
  isSubmitted: boolean = false;
  isUrlFilled: boolean = false;
  isEdit: boolean = false;
  selectedType?: string;
  isShowTermsOfUse$ = new BehaviorSubject<boolean>(false);
  isShowPrivacyPolicy$ = new BehaviorSubject<boolean>(false);

  organizationUiDtoSubscription$?: Subscription;
  drawerSubscription$?: Subscription;

  @ViewChild('termsOfUseRef') termsOfUseRef?: ElementRef<HTMLParagraphElement>
  @ViewChild('privacyPolicyRef') privacyPolicyRef?: ElementRef<HTMLParagraphElement>
  @ViewChild("descriptionEditor") descriptionEditor?: AngularEditorComponent;
  @ViewChild('descriptionRef') descriptionRef?: ElementRef<HTMLParagraphElement>

  currencies: Currency[] = [];
  languages: OrgLanguageDto[] = [];

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '2',
    sanitize: false,
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode'
      ]
    ],
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'Page Divider',
        class: 'break',
        tag: 'div',
      },
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'hr',
      },
    ],
  };

  constructor(
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private fileService: FileService,
    private onboardingService: OnboardingService,
    private drawerService: DrawerService,
    private currencyService: CurrencyService,
    private languageService: LanguageService,
    private themeService: ThemeService
  ) {
    this.formGroup = this.fb.group({
      name: [''],
      corporateUrl: [''],
      description: [''],
      supportEmail: [''],
      phoneNo: [''],
      logo: [null],
      bannerImage: [null],
      selectedCurrencies: [[]],
      selectedLanguages: [[]]
    })
    this.formGroupColor = this.fb.group({
      primaryColor: [''],
      secondaryColor: [''],
 
    })
  }
 
  ngOnInit(): void {
    this.onboardingService.getOrganizationUiDto$.subscribe(data => {
      if (data) {
        this.organizationUiDto = data;
        this.populateData();
      }
    })
    this.currencies = this.currencyService.getCurrencies;
    this.languages = this.languageService.getLanguages;

    this.loadOrganizationUiDto();
    this.onboardingService.loadOrganizationUiDto();
    this.setupThemeStructure()

    this.onboardingService.getOrganizationUiDto$.subscribe(data => {
      if (data) {
        this.organizationUiDto = data;
        this.populateFormValues();
      } else {
        this.organizationUiDto = undefined;
      }
    })

    // this.formGroup.controls['corporateUrl'].valueChanges.subscribe((value) => {
    //   let isUrlFilled = value != undefined && value != '';

    //   if (isUrlFilled != this.isUrlFilled) {
    //     this.isUrlFilled = isUrlFilled;
    //     if (isUrlFilled) {
    //       this.formGroup.controls['corporateUrl'].addValidators([Validators.required, Validators.pattern(Pattern.url)]);
    //       this.formGroup.controls['corporateUrl'].updateValueAndValidity();
    //     } else {
    //       this.formGroup.controls['corporateUrl'].clearValidators();
    //       this.formGroup.controls['corporateUrl'].updateValueAndValidity();
    //     }
    //   }
    // })
  }

  setupThemeStructure() {
    const themeStructure = this.themeService.themeStructure;

    if (themeStructure?.organizationUiDto) {
      this.successMessages = this.themeService.createSuccessMessage(themeStructure.organizationUiDto);
      this.formConfig = this.themeService.createFormConfig(themeStructure.organizationUiDto.model);
      this.themeService.applyValidations(this.formGroup, themeStructure.organizationUiDto.model);
      this.themeService.applyValidations(this.formGroupColor, themeStructure.organizationUiDto.model);
    }
  }

  ngAfterViewInit(): void {
    this.drawerSubscription$ = this.drawerService.drawerPageName$.subscribe(() => {
      if (this.drawerService.drawerPageTab) {
        this.currentTab = this.drawerService.drawerPageTab;
      } else {
        this.currentTab = 'BASIC_DETAILS';
      }
    })
  }
  
  toggleDetailsTab(tab: string) {
    this.currentTab = tab;
  }

  async loadOrganizationUiDto() {
    this.isDataLoading = true;
    await this.onboardingService.loadOrganizationUiDto();
    this.isDataLoading = false;
  }

  get fc() { return this.formGroup.controls; }
  get fcColor() { return this.formGroupColor.controls; }

  populateFormValues() {
    if (this.organizationUiDto != null) {
      this.selectedCountryCode = this.organizationUiDto?.countryCode ?? CountryCodeDto.default();
      this.countryCode$.next(this.selectedCountryCode);
      this.formGroup.patchValue(this.organizationUiDto);
      
      if (this.organizationUiDto.bannerImage) {
        this.imageUrlBanner = `/downloadLandingBlob?fileId=${this.organizationUiDto.bannerImage?.fileId}`;
        this.bannerFileName = this.organizationUiDto.bannerImage?.displayName as string;
        this.formGroup.get('bannerImage')?.setValue(this.organizationUiDto.bannerImage);
      }

      if (this.organizationUiDto.logo) {
        this.imageUrlLogo = `/downloadLandingBlob?fileId=${this.organizationUiDto.logo?.fileId}`;
        this.logoFileName = this.organizationUiDto.logo?.displayName as string;
        this.formGroup.get('logo')?.setValue(this.organizationUiDto.logo);
      }

      this.populateSelectedCurrencies(this.organizationUiDto)
      
      if(this.organizationUiDto.orgLanguageDtos){
        this.formGroup.get('selectedLanguages')?.setValue(this.organizationUiDto.orgLanguageDtos);
      }
      
      this.formGroup.updateValueAndValidity();
    } else {
      // this.formGroup.controls['domainName'].addValidators([Validators.required, Validators.pattern(Pattern.url2)]);
      // this.formGroup.controls['domainName'].updateValueAndValidity();
    }
  }

  
  closeDrawer() {
    this.onCloseEvent.emit(undefined);
  }


  populateSelectedCurrencies(organizationUiDto: OrganizationWrapperUiDto){
    if(organizationUiDto.currencyCodes){
      let selectedCurrencies = this.currencies.filter(item => organizationUiDto.currencyCodes?.includes(item.code!));
      this.formGroup.get('selectedCurrencies')?.setValue(selectedCurrencies);
    }
  }

  chooseFile(event: any, type: string) {
    this._showErrorToast$.next(false);
    let currentFile = event.target.files[0];

    if (!this.imageTypes.includes(currentFile!.type)) {
      this.fileUploadError = true;
      this.errorMsg = "Extension not supported";
      this._showErrorToast$.next(true);
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(currentFile);
    reader.onload = (e) => {
      let size = (currentFile?.size! / 1024) / 1024;

      // Create New Image
      var newImage = new Image();
      newImage.src = e.target!.result as string;

      newImage.onload = (el) => {
        if (type == 'BANNER') {
          this.imageTypeBanner = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
        } else {
          this.imageTypeLogo = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
        }

        if (size > 1) {
          var canvas = document.createElement("canvas");
          canvas.width = newImage.width;
          canvas.height = newImage.height;

          var ctx = canvas.getContext("2d");
          ctx?.drawImage(el.target as CanvasImageSource, 0, 0, canvas.width, canvas.height);
          var srcEncoded;
          if (size >= 5) {
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.1);
          } else {
            // size less then 5 MB
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.5);
          }

          ApplicationUtils.base64toFile(srcEncoded, currentFile!.name, currentFile!.type)
            .then((file: File) => {
              if (type == 'BANNER') {
                this.currentFileBanner = file;
                this.bannerFileName = file?.name as string;
                this.formGroup.controls['bannerImage'].patchValue(this.currentFileBanner);
              } else {
                this.currentFileLogo = file;
                this.logoFileName = file?.name as string;
                this.formGroup.controls['logo'].patchValue(this.currentFileLogo);
              }
            })
        } else {
          if (type == 'BANNER') {
            this.currentFileBanner = currentFile;
            this.bannerFileName = currentFile?.name as string;
            this.formGroup.controls['bannerImage'].patchValue(this.currentFileBanner);
          } else {
            this.currentFileLogo = currentFile;
            this.logoFileName = currentFile?.name as string;
            this.formGroup.controls['logo'].patchValue(this.currentFileLogo);
          }
        }

        if (type == 'BANNER') {
          this.imageUrlBanner = reader.result?.toString();
        } else {
          this.imageUrlLogo = reader.result?.toString();
        }
      }
    }
  }

  mergeOrganizationUiDto() {
    let formValue = this.formGroup.getRawValue();

    let organizationUiDto = ApplicationUtils.clone(this.organizationUiDto) as OrganizationWrapperUiDto;

    organizationUiDto.name = formValue.name;
    organizationUiDto.corporateUrl = formValue.corporateUrl;
    organizationUiDto.description = formValue.description;
    organizationUiDto.supportEmail = formValue.supportEmail;
    organizationUiDto.phoneNo = formValue.phoneNo;
    organizationUiDto.logo = formValue.logo;
    organizationUiDto.bannerImage = formValue.bannerImage;
    organizationUiDto.countryCode = this.selectedCountryCode;
    organizationUiDto.orgLanguageDtos = formValue.selectedLanguages;
    let selectedCurrencies : Currency[] = formValue.selectedCurrencies
    organizationUiDto.currencyCodes = selectedCurrencies.map(item => item.code!); 

    return organizationUiDto;
  }

  save() {
    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);

    let organizationUiDto = this.mergeOrganizationUiDto()

    this.onboardingService.saveOrganizationUiDto(organizationUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;

          let newOrganizationUiDto = apiResponseDto.data as OrganizationWrapperUiDto;
          this.onboardingService.updateOrganizationUiDto(newOrganizationUiDto)

          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this._isSaveButtonEnable$.next(true);

            if (this.currentTab == 'BASIC_DETAILS') {
              this.toggleDetailsTab('LEGAL');
            } else if (this.currentTab == 'LEGAL') {
              this.toggleDetailsTab('OTHER_DETAILS');
            } else if (this.currentTab == 'OTHER_DETAILS') {
              this.onCloseEvent.emit(undefined);
              this.toggleDetailsTab('BASIC_DETAILS');
            }

          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;

        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving Auction House");
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })
  }

  saveOrgProfile() {
    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (!this.logoFileName || !this.bannerFileName || this.logoFileName == '' || this.bannerFileName == '') {
      return;
    }

    this._isSaveButtonEnable$.next(false);
    if (this.currentFileLogo || this.currentFileBanner) {
      this.uploadFiles();
    } else {
      this.save();
    }
  }

  uploadFiles() {
    this.isLoading = true;
    this.fileUploadError = false;
    this.errorMsg = "";

    let metaDataList: string[] = [];
    let formData = new FormData();

    if (this.currentFileBanner) {
      let metaData = {
        'mimeType': this.currentFileBanner.type,
        'version': 0,
        'publicApi': true,
        'dataType': this.imageTypeBanner
      };

      metaDataList.push(JSON.stringify(metaData));
      formData.append("files", this.currentFileBanner);
    }

    if (this.currentFileLogo) {
      let metaData = {
        'mimeType': this.currentFileLogo.type,
        'version': 0,
        'publicApi': true,
        'dataType': this.imageTypeLogo
      };

      metaDataList.push(JSON.stringify(metaData));
      formData.append("files", this.currentFileLogo);
    }

    formData.append("metaDataList", JSON.stringify(metaDataList));

    this.fileService.uploadMultipleFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.fileUploadError = false;

          let fileSaveResponseDtoList = apiResponseDto.data as FileSaveResponseDto[];

          for (let fileSaveResponseDto of fileSaveResponseDtoList) {
            let currentFileInfoDto = new FileInfoDto();
            currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
            currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
            currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
            currentFileInfoDto.dataType = fileSaveResponseDto.dataType;
            currentFileInfoDto.uploadDate = new Date().toDateString();


            if (this.bannerFileName == fileSaveResponseDto.fileName) {
              currentFileInfoDto.fileSize = this.currentFileBanner?.size.toString();
              currentFileInfoDto.fileType = this.currentFileBanner?.type;
              this.formGroup.get('bannerImage')?.setValue(currentFileInfoDto);
            }

            if (this.logoFileName == fileSaveResponseDto.fileName) {
              currentFileInfoDto.fileSize = this.currentFileLogo?.size.toString();
              currentFileInfoDto.fileType = this.currentFileLogo?.type;
              this.formGroup.get('logo')?.setValue(currentFileInfoDto);
            }
          }

          this.formGroup.updateValueAndValidity();
          this.save();
        }
      } else {
        this.isLoading = false;
        this.fileUploadError = true;
      }
    })
  }

  deleteImage(type: string) {
    if (type == 'BANNER') {
      this.bannerFileName = '';
      this.imageUrlBanner = '';
      this.currentFileBanner = undefined;
      this.formGroup.controls['bannerImage'].reset();
      this.formGroup.controls['bannerImage'].markAsTouched();
    } else {
      this.logoFileName = '';
      this.imageUrlLogo = '';
      this.currentFileLogo = undefined;
      this.formGroup.controls['logo'].reset();
      this.formGroup.controls['logo'].markAsTouched();
    }
  }

  openModal(content: any) {
    this.ngbModal.open(content, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
  }

  openViewImageModal(imageUrl: any) {
    let modalRef = this.ngbModal.open(ImagePreviewComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    });
    modalRef.componentInstance.imageUrl = imageUrl
  }

  closeModal() {
    this.ngbModal.dismissAll()
    this.isSubmitted = false;
    this.bannerFileName = '';
    this.logoFileName = '';

  }

  onChangeDomainName() {
    var domainName = this.fc['domainName'].value;

    if (domainName && domainName.includes(':4200')) {
      this.formGroup.controls['domainName'].setValidators(Validators.required);
      this.formGroup.controls['domainName'].updateValueAndValidity();
    } else {
      this.formGroup.controls['domainName'].setValidators([Validators.required, Validators.pattern(Pattern.url2)]);
      this.formGroup.controls['domainName'].updateValueAndValidity();
    }
  }

  getDomainName(url: string) {
    if (!url.includes(':4200')) {
      url = url.replace(/(^\w+:|^)\/\//, '');
      url = url.replace('www.', '')
    }
    return url;
  }

  showOrHideDescription() {
    this.isShowDescription = !this.isShowDescription;
    if (!this.isShowDescription) {
      this.descriptionRef!.nativeElement.style.height = '120px'
    } else {
      this.descriptionRef!.nativeElement.style.height = this.descriptionRef!.nativeElement.scrollHeight + 'px';
    }
  }

  toggleImageActive(type: string) {
    if (type == 'BANNER') {
      this.isBannerActive = !this.isBannerActive;
    } else {
      this.isLogoActive = !this.isLogoActive;
    }
  }

  handleCountryCodeEvent(countryCodeDto: CountryCodeDto) {
    this.selectedCountryCode = countryCodeDto;

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength + "}"

    this.formGroup.controls['phoneNo'].clearValidators();
    this.formGroup.controls['phoneNo'].setValidators([Validators.required, Validators.pattern(pattern)]);
    this.formGroup.controls['phoneNo'].updateValueAndValidity();
  }
  
  openAiDescription() {
    this.ngbModal.open(AiDescriptionGeneratorComponent, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    })
  }

  // legal

  openPrivacyAndTermsEditModal(id?: string) {
    let modalRef = this.ngbModal.open(AddLegalPolicyComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true
    });

    modalRef.componentInstance.editMode = id != undefined;
    modalRef.componentInstance.uniqueId = id;
  }

  openConfirmationModel(modelRef: any, type: string, index: number) {
    this.selectedType = type;
    this.currentIndex = index;

    this.ngbModal.open(modelRef, {
      size: 'md', backdrop: 'static', keyboard: false, centered: true
    })
  }

  showOrHideTermsOfUse() {
    this.isShowTermsOfUse$.next(!this.isShowTermsOfUse$.value)
    if (!this.isShowTermsOfUse$.value) {
      this.termsOfUseRef!.nativeElement.style.height = '120px'
    } else {
      this.termsOfUseRef!.nativeElement.style.height = this.termsOfUseRef!.nativeElement.scrollHeight + 'px';
    }
  }

  showOrHidePrivacyPolicy() {
    this.isShowPrivacyPolicy$.next(!this.isShowPrivacyPolicy$.value)
    if (!this.isShowPrivacyPolicy$.value) {
      this.privacyPolicyRef!.nativeElement.style.height = '120px'
    } else {
      this.privacyPolicyRef!.nativeElement.style.height = this.privacyPolicyRef!.nativeElement.scrollHeight + 'px';
    }
  }

  deletePrivacyAndTerms() {
    this.closeModal();
    this.isPrivacyLoading = true;

    let organizationUiDto: OrganizationWrapperUiDto = ApplicationUtils.clone(this.organizationUiDto);
    organizationUiDto.privacyAndTermsOfUseList!.splice(this.currentIndex!, 1);

    this.onboardingService.saveOrganizationUiDto(organizationUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isPrivacyLoading = false;
        } else {
          this.isPrivacyLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.isPrivacyLoading = false;
      }
    })
  }


  populateData() {
    this.primaryColor = this.organizationUiDto?.primaryColor
    this.secondaryColor = this.organizationUiDto?.secondaryColor

    this.formGroupColor.patchValue(this.organizationUiDto!);
    this.formGroupColor.updateValueAndValidity();
  }

  handleValidSubmit() {
    this._showErrorToast$.next(false);

    if (this.formGroupColor.invalid) {
      this.formGroupColor.markAllAsTouched()
      return;
    }

    this.isLoading = true;
    let formValue = this.formGroupColor.value;

    let organizationUiDto: OrganizationWrapperUiDto = ApplicationUtils.clone(this.organizationUiDto);
    organizationUiDto.primaryColor = formValue.primaryColor;
    organizationUiDto.secondaryColor = formValue.secondaryColor;

    this.onboardingService.saveOrganizationUiDto(organizationUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;
          let newOrganizationUiDto = apiResponseDto.data as OrganizationWrapperUiDto;
          this.onboardingService.updateOrganizationUiDto(newOrganizationUiDto)
          
          setTimeout(() => {
            this._showSuccessToast$.next(false);
            this.closeModal();
          }, 2000)
        } else {
          this.isLoading = false;
          this._showErrorToast$.next(true);
          this._errorMsg$.next(apiResponseDto?.message ?? "Error while saving data. Try again.");
        }
      },
      error: (error) => {
        console.error(error);

        this.isLoading = false;
        this._showErrorToast$.next(true);
        this._errorMsg$.next("Error while saving data. Try again.");
      }
    })
  }

  changeColor(selectedColor: any, colorType: string) {
    if (colorType == 'PRIMARY') {
      this.formGroup.controls['primaryColor'].patchValue(selectedColor);
    } else {
      this.formGroup.controls['secondaryColor'].patchValue(selectedColor);
    }
    this.formGroup.updateValueAndValidity()
  }

  openNewProfileDrawer(event: any) {
    event.stopPropagation();
    this.drawerService.openDrawer('ONBOARDING_NEW_PROFILE');
  }

  ngAfterContentInit(): void {
    ApplicationUtils.clearTextFormatOnPaste('descriptionEditor');
  }

  ngOnDestroy(): void {
    if (this.organizationUiDtoSubscription$) {
      this.organizationUiDtoSubscription$.unsubscribe();
    }
    if (this.drawerSubscription$) {
      this.drawerSubscription$.unsubscribe();
    }
  }
}




