import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TenderOpportunityWrapperDto } from 'src/app/shared/models/user/TenderOpportunityWrapperDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { selectTenderOpportunityWrapperDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.component.html',
  styleUrls: ['./opportunity-details.component.sass']
})
export class OpportunityDetailsComponent implements OnInit, OnDestroy {
  tenderOpportunityWrapperDto?: TenderOpportunityWrapperDto
  isShowDescription : boolean = false

  tenderOpportunityWrapperDtoSubscription$?: Subscription;

  @ViewChild('descriptionRef') descriptionRef?: ElementRef<HTMLParagraphElement>
  
  constructor(
    private store: Store,
    private drawerService: DrawerService,
  ) {}

  ngOnInit(): void {
    this.tenderOpportunityWrapperDtoSubscription$ = this.store.pipe(select(selectTenderOpportunityWrapperDto)).subscribe(data => {
      if (data) {
        this.tenderOpportunityWrapperDto = ApplicationUtils.deepClone(data);
      } else {
        this.tenderOpportunityWrapperDto = undefined;
      }
    })
  }

  showOrHideDescription(){
    this.isShowDescription = !this.isShowDescription;
    if (!this.isShowDescription) {
      this.descriptionRef!.nativeElement.style.height = '120px'
    } else {
      this.descriptionRef!.nativeElement.style.height = this.descriptionRef!.nativeElement.scrollHeight + 'px';
    }
  }

  openEditOpportunityDrawer() {
    this.drawerService.isFormEditMode(true);
    this.drawerService.openDrawer(ApplicationConstants.NEW_OPPORTUNITY_PAGE, 'BASIC');
  }

  ngOnDestroy(): void {
    if (this.tenderOpportunityWrapperDtoSubscription$) {
      this.tenderOpportunityWrapperDtoSubscription$.unsubscribe();
    }
  }
}
