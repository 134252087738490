import { EventEnum } from "../../enums/EventEnum";
import { QuestionnaireStatus } from "../../enums/questionnaire/QuestionnaireStatus";
import { TechnicalGradationDto } from "../rfx/TechnicalGradationDto";

export class TenderQuestionnaireTemplateDto {
  templateId?: string;
  templateName?: string;
  templateDescription?: string;
  orgCode?: string;
  resourceType?: EventEnum;
  resourceId?: string;
  subResourceId?: string;
  saveAsTemplate?: boolean;
  guideLines?: string;

  status?: QuestionnaireStatus;

  scoringTemplate?: string;
  technicalWeightage?: number;
  financialWeightage?: number;
  maximumScore?: number;
  qualifyingScore?: number;
  gradationSystem?: boolean;
  range?: number;
  technicalGradationDtos?: TechnicalGradationDto[];

  updateTimeUtc?: string
  updateTimeUtcMillis?: number;
  version?: number;  
}