import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AddressDto } from 'src/app/shared/models/Address';
import { CompanyAccountUiDto } from 'src/app/shared/models/CompanyAccountUiDto';
import { CompanyUiDto } from 'src/app/shared/models/CompanyUiDto';
import { CountryCodeDto } from 'src/app/shared/models/CountryCodeDto';
import { Currency } from 'src/app/shared/models/Currency';
import { OnboardingCountry } from 'src/app/shared/models/OnboardingCountry';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { TimeZoneDto } from 'src/app/shared/models/TimeZoneDto';
import { AdminDashboardService } from 'src/app/shared/services/admin-dashboard.service';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { OnboardingService } from 'src/app/shared/services/onboarding.service';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { Pattern } from 'src/app/shared/util/Patterns';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-onboarding-new-company',
  templateUrl: './onboarding-new-company.component.html',
  styleUrls: ['./onboarding-new-company.component.sass']
})
export class OnboardingNewCompanyComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onCloseEvent = new EventEmitter<string | undefined>();

  currentTab: string = 'BASIC_DETAILS';

  formGroup: FormGroup;
  formGroupAddress: FormGroup;
  formConfig: { [key: string]: any } = {};
  successMessages: { [key: string]: any } = {};
  formGroupBank: FormGroup;

  isLoading: boolean = false;
  newCompanyCode?: string;

  shippingLatitude?: number;
  shippingLongitude?: number;
  billingLatitude?: number;
  billingLongitude?: number;
  shippingCountryShortName?: string
  billingCountryShortName?: string

  selectedCompanyUiDto?: CompanyUiDto;
  selectedBillingAddress?: AddressDto;
  selectedShippingAddress?: AddressDto;
  selectedOnboardingCountry?: OnboardingCountry;
  companiesList: CompanyUiDto[] = []
  timezones: Array<TimeZoneDto> = [];
  onboardingCountries: Array<OnboardingCountry> = [];

  currencyList: Currency[] = []
  orgCurrencyList: Currency[] = []

  phoneCountryCode = CountryCodeDto.default();
  faxCountryCode = CountryCodeDto.default();

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showErrorMsg$ = new BehaviorSubject<string>("");
  countryCodePhone$ = new BehaviorSubject<CountryCodeDto | null>(null);
  countryCodeFax$ = new BehaviorSubject<CountryCodeDto | null>(null);

  selectedCompanySubscription$?: Subscription
  companiesSubscription$?: Subscription
  masterTimeZoneSubscription$?: Subscription;
  drawerSubscription$?: Subscription;

  organizationUiDto?: OrganizationWrapperUiDto;

  @ViewChild(GoogleMap) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info!: MapInfoWindow;

  zoom = 12;
  positionBilling?: google.maps.LatLngLiteral;
  positionShipping?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    minZoom: 8,
  };
  markerBilling = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }
  markerShipping = {
    label: { color: 'black', text: '' },
    title: '',
    options: { animation: google.maps.Animation.DROP },
    info: ''
  }


  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private changeDetectRef: ChangeDetectorRef,
    private adminDashboardService: AdminDashboardService,
    private onboardingService: OnboardingService,
    private currencyService: CurrencyService,
    private drawerService: DrawerService,
    private  themeService: ThemeService
  ) {
    this.formGroup = this.fb.group({
      companyCode: [''],
      companyName: [''],
      primaryCompany: [false],
      legalType: [''],
      industrySector: [''],
      region: [''],
      country: [''],
      phoneNumber: [''],
      faxNumber: [''],
      taxId1: [''],
      taxId2: [''],
      branch: [''],
      timeZone: [''],
      fiscalYrStart: [''],
      fiscalYrEnd: [''],
      languageCode: [null],
      currencyCode: [null]
    });

    this.formGroupAddress = this.fb.group({
      shippingAddress: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: [''],
      }),
      billingAddress: this.fb.group({
        addressType: [''],
        searchAddress: [''],
        addressLine1: [''],
        addressLine2: [''],
        city: [''],
        state: [''],
        country: [''],
        zipCode: [''],
      }),
    });

    this.formGroupBank = this.fb.group({
      accountTitle: [''],
      accNumber: [''],
      accHolderName: [''],
      accType: [''],
      accCode: [''],
      swift: [''],
      iban: [''],
    });
  }

  ngOnInit(): void {
    this.organizationUiDto = this.onboardingService.getOrganizationUiDto;
    this.currencyList = this.currencyService.getCurrencies;

    if (this.organizationUiDto) {
      this.populateOrgCurrencyList(this.organizationUiDto);
    }

    this.setupThemeStructure()

    this.onboardingCountries = this.onboardingService.getOnboardingCountries;

    this.masterTimeZoneSubscription$ = this.adminDashboardService.getMasterTimezones$.subscribe((data) => {
      if (data) {
        this.timezones = data;
      }
    });

    this.companiesSubscription$ = this.onboardingService.getCompanyUiDtos$.subscribe(data => {
      if (data) {
        this.companiesList = data;

        if (this.selectedCompanyUiDto) {
          this.selectedCompanyUiDto = this.companiesList.find(item => item.companyCode == this.selectedCompanyUiDto?.companyCode);
        }
      } else {
        this.companiesList = [];
      }
    })

    this.selectedCompanySubscription$ = this.onboardingService.getSelectedCompanyUiDto$.subscribe(data => {
      if (data) {
        this.selectedCompanyUiDto = data;
        this.populateSelectedCompanyDetails();
      } else {
        this.selectedCompanyUiDto = undefined;

        this.handlePhoneCountryCode(CountryCodeDto.default())
        this.setCountryValidation();
      }
    })
  }
  setupThemeStructure() {
    const themeStructure = this.themeService.themeStructure;

    if (themeStructure?.companyUiDto) {
      this.successMessages = this.themeService.createSuccessMessage(themeStructure.companyUiDto);
      this.formConfig = this.themeService.createFormConfig(themeStructure.companyUiDto.model);
      this.themeService.applyValidations(this.formGroup, themeStructure.companyUiDto.model);
      this.themeService.applyValidations(this.formGroupAddress, themeStructure.companyUiDto.model);
      this.themeService.applyValidations(this.formGroupBank, themeStructure.companyUiDto.model);
    }
  }
  ngAfterViewInit(): void {
    this.drawerSubscription$ = this.drawerService.drawerPageName$.subscribe(() => {
      if (this.drawerService.drawerPageTab) {
        this.currentTab = this.drawerService.drawerPageTab;
      } else {
        this.currentTab = 'BASIC_DETAILS';
      }

      if (!this.drawerService.isEditForm) {
        this.formGroup.reset();
        this.formGroupAddress.reset();
        this.formGroupBank.reset();

        this.newCompanyCode = 'C' + (this.companiesList.length + 1).toString().padStart(2, "0");
        this.fc['companyCode'].patchValue(this.newCompanyCode);
        this.fc['companyCode'].updateValueAndValidity();
      }
    })
  }

  get fc() { return this.formGroup.controls; }
  get fcAddress() { return this.formGroupAddress.controls; }
  get fcBank() { return this.formGroupBank.controls; }

  validBillingLocation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidLocation = this.billingLatitude != undefined;
      return control.value && !isValidLocation ? { address: { value: control.value } } : null;
    }
  }

  validShippingLocation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidLocation = this.shippingLatitude != undefined;
      return control.value && !isValidLocation ? { address: { value: control.value } } : null;
    }
  }

  closeDrawer() {
    this.onCloseEvent.emit(undefined);
  }

  populateSelectedCompanyDetails() {
    this.formGroup.patchValue(this.selectedCompanyUiDto!);
    this.formGroup.controls['currencyCode'].setValue(this.selectedCompanyUiDto?.currencyDto?.code);
    this.formGroup.controls['languageCode'].setValue(this.selectedCompanyUiDto?.languageDto?.languageCode)
    this.formGroup.controls['fiscalYrStart'].setValue(ApplicationUtils.getNgbPickerDate(this.selectedCompanyUiDto!.fiscalYrStart!));
    this.formGroup.controls['fiscalYrEnd'].setValue(ApplicationUtils.getNgbPickerDate(this.selectedCompanyUiDto!.fiscalYrEnd!));
    this.formGroup.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();

    this.handlePhoneCountryCode(this.selectedCompanyUiDto?.phoneCountryCode!)
    this.handleFaxCountryCode(this.selectedCompanyUiDto?.faxCountryCode!)
    this.setCountryValidation();

    if (this.selectedCompanyUiDto?.billingAddress && this.selectedCompanyUiDto.billingAddress.length > 0) {
      this.selectedBillingAddress = this.selectedCompanyUiDto.billingAddress[0];

      this.billingLatitude = parseFloat(this.selectedBillingAddress.latitude!);
      this.billingLongitude = parseFloat(this.selectedBillingAddress.longitude!);
      this.billingCountryShortName = this.selectedBillingAddress.countryShortName;

      this.positionBilling = { lat: this.billingLatitude, lng: this.billingLongitude }
      this.markerBilling.label.text = this.selectedBillingAddress.searchAddress!;
      this.markerBilling.title = this.selectedBillingAddress.searchAddress!;
      this.markerBilling.info = this.selectedBillingAddress.searchAddress!;

      this.formGroupAddress.controls['billingAddress'].patchValue(this.selectedBillingAddress);
    }

    if (this.selectedCompanyUiDto?.shippingAddress && this.selectedCompanyUiDto.shippingAddress.length > 0) {
      this.selectedShippingAddress = this.selectedCompanyUiDto.shippingAddress[0];

      this.shippingLatitude = parseFloat(this.selectedShippingAddress.latitude!);
      this.shippingLongitude = parseFloat(this.selectedShippingAddress.longitude!);
      this.shippingCountryShortName = this.selectedShippingAddress.countryShortName;

      this.positionShipping = { lat: this.shippingLatitude, lng: this.shippingLongitude }
      this.markerShipping.label.text = this.selectedShippingAddress.searchAddress!;
      this.markerShipping.title = this.selectedShippingAddress.searchAddress!;
      this.markerShipping.info = this.selectedShippingAddress.searchAddress!;

      this.formGroupAddress.controls['shippingAddress'].patchValue(this.selectedShippingAddress);
    }

    if (this.selectedCompanyUiDto?.companyAccountUiDtos && this.selectedCompanyUiDto.companyAccountUiDtos.length > 0) {
      let companyAccountUiDto = this.selectedCompanyUiDto.companyAccountUiDtos[0];
      this.formGroupBank.controls['bankTitle'].patchValue(companyAccountUiDto?.bankTitle);
      this.formGroupBank.controls['bankAccNo'].patchValue(companyAccountUiDto?.bankAccNo);
      this.formGroupBank.controls['bankAccHolder'].patchValue(companyAccountUiDto?.bankAccHolder);
      this.formGroupBank.controls['bankAccType'].patchValue(companyAccountUiDto?.bankAccType);
      this.formGroupBank.controls['bankAccCode'].patchValue(companyAccountUiDto?.bankAccCode);
      this.formGroupBank.controls['bankAccSwift'].patchValue(companyAccountUiDto?.bankAccSwift);
      this.formGroupBank.controls['bankAccIban'].patchValue(companyAccountUiDto?.bankAccIban);
    }
  }

  populateOrgCurrencyList(organizationUiDto: OrganizationWrapperUiDto) {

    if (organizationUiDto?.currencyCodes) {
      this.orgCurrencyList = this.currencyList.filter(item => organizationUiDto.currencyCodes?.includes(item.code!));
    } else {
      this.orgCurrencyList = [];
    }

  }

  handleBillingAddressChange(address: google.maps.places.PlaceResult) {
    this.billingLatitude = address.geometry?.location?.lat();
    this.billingLongitude = address.geometry?.location?.lng();

    let city = ApplicationUtils.getAddressByType(address, 'locality');
    if (!city) {
      city = ApplicationUtils.getAddressByType(address, 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let country = ApplicationUtils.getAddressByType(address, 'country');
    this.billingCountryShortName = ApplicationUtils.getAddressShortNameByType(address, 'country');

    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 = ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 = ApplicationUtils.getAddressByType(address, 'route');

    let addressLine1 = address.formatted_address;
    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address?.includes(',')) {
        addressLine1 = address.formatted_address.split(',')[0];
      }
    } else {
      addressLine1 = addressPart1 + " " + addressPart2;
    }


    this.formGroupAddress.controls['billingAddress'].get('addressLine1')?.patchValue(addressLine1);
    this.formGroupAddress.controls['billingAddress'].get('searchAddress')?.patchValue(address.formatted_address);
    this.formGroupAddress.controls['billingAddress'].get('city')?.patchValue(city);
    this.formGroupAddress.controls['billingAddress'].get('state')?.patchValue(state);
    this.formGroupAddress.controls['billingAddress'].get('country')?.patchValue(country);
    this.formGroupAddress.controls['billingAddress'].get('zipCode')?.patchValue(zip);
    this.formGroupAddress.updateValueAndValidity();


    this.changeDetectRef.detectChanges();
  }



  handleShippingAddressChange(address: google.maps.places.PlaceResult) {
    this.shippingLatitude = address.geometry?.location?.lat();
    this.shippingLongitude = address.geometry?.location?.lng();

    let city = ApplicationUtils.getAddressByType(address, 'locality');
    if (!city) {
      city = ApplicationUtils.getAddressByType(address, 'neighborhood');
    }
    let state = ApplicationUtils.getAddressByType(address, 'administrative_area_level_1');
    let country = ApplicationUtils.getAddressByType(address, 'country');
    this.shippingCountryShortName = ApplicationUtils.getAddressShortNameByType(address, 'country');

    let zip = ApplicationUtils.getAddressByType(address, 'postal_code');
    let addressPart1 = ApplicationUtils.getAddressByType(address, 'street_number');
    let addressPart2 = ApplicationUtils.getAddressByType(address, 'route');

    let addressLine1 = address.formatted_address;
    if (addressPart1 == '' || addressPart2 == '') {
      if (address.formatted_address?.includes(',')) {
        addressLine1 = address.formatted_address.split(',')[0];
      }
    } else {
      addressLine1 = addressPart1 + " " + addressPart2;
    }

    this.formGroupAddress.controls['shippingAddress'].get('addressLine1')?.patchValue(addressLine1);
    this.formGroupAddress.controls['shippingAddress'].get('searchAddress')?.patchValue(address.formatted_address);
    this.formGroupAddress.controls['shippingAddress'].get('city')?.patchValue(city);
    this.formGroupAddress.controls['shippingAddress'].get('state')?.patchValue(state);
    this.formGroupAddress.controls['shippingAddress'].get('country')?.patchValue(country);
    this.formGroupAddress.controls['shippingAddress'].get('zipCode')?.patchValue(zip);
    this.formGroupAddress.updateValueAndValidity();

    this.changeDetectRef.detectChanges();
  }

  toggleDetailsTab(tab: string) {
    this.currentTab = tab;
  }

  setCountryValidation() {
    let selectedCountry = this.formGroup.controls['country'].value;
    if (selectedCountry) {
      let onboardingCountry = this.onboardingCountries.find(item => item.name == selectedCountry)!;
      this.selectedOnboardingCountry = onboardingCountry;

      let validators1 = onboardingCountry.taxOneType == 'Numeric' ? [Validators.required, Validators.pattern(Pattern.onlyNumber)] : [Validators.required];
      this.fc['taxId1'].setValidators([...validators1, Validators.minLength(onboardingCountry.taxOneLengthMin!), Validators.maxLength(onboardingCountry.taxOneLengthMax!)]);
      this.fc['taxId1'].updateValueAndValidity();

      if (onboardingCountry?.taxTwo) {
        let validators2 = onboardingCountry.taxTwoType == 'Numeric' ? [Validators.required, Validators.pattern(Pattern.onlyNumber)] : [Validators.required];
        this.fc['taxId2'].setValidators([...validators2, Validators.minLength(onboardingCountry.taxTwoLengthMin!), Validators.maxLength(onboardingCountry.taxTwoLengthMax!)]);
        this.fc['taxId2'].updateValueAndValidity();
      } else {
        this.fc['taxId2'].clearValidators();
        this.fc['taxId2'].updateValueAndValidity();
      }
    } else {
      this.selectedOnboardingCountry = undefined;
    }

    this.changeDetectRef.detectChanges();
  }

  handlePhoneCountryCode(countryCodeDto: CountryCodeDto) {
    this.phoneCountryCode = countryCodeDto;

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength + "}"

    this.formGroup.controls['phoneNumber'].clearValidators();
    this.formGroup.controls['phoneNumber'].setValidators([Validators.required, Validators.pattern(pattern)]);
    this.formGroup.controls['phoneNumber'].updateValueAndValidity();
  }

  handleFaxCountryCode(countryCodeDto: CountryCodeDto) {
    this.faxCountryCode = countryCodeDto;

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength + "}"

    this.formGroup.controls['faxNumber'].clearValidators();
    this.formGroup.controls['faxNumber'].setValidators([Validators.pattern(pattern)]);
    this.formGroup.controls['faxNumber'].updateValueAndValidity();
  }

  mergeBasicDetails() {
    let formValue = this.formGroup.getRawValue();
    let companyUiDto = new CompanyUiDto();

    if (this.selectedCompanyUiDto) {
      companyUiDto = ApplicationUtils.clone(this.selectedCompanyUiDto) as CompanyUiDto;
    }

    let organizationUiDto = this.onboardingService.getOrganizationUiDto;

    companyUiDto.orgCode = organizationUiDto?.orgCode;
    companyUiDto.companyCode = formValue.companyCode;
    companyUiDto.companyName = formValue.companyName;
    companyUiDto.primaryCompany = formValue.primaryCompany;
    companyUiDto.legalType = formValue.legalType;
    companyUiDto.industrySector = formValue.industrySector;
    companyUiDto.region = formValue.region;
    companyUiDto.country = formValue.country;
    companyUiDto.phoneCountryCode = this.phoneCountryCode;
    companyUiDto.phoneNumber = formValue.phoneNumber;
    companyUiDto.faxCountryCode = this.faxCountryCode;
    companyUiDto.faxNumber = formValue.faxNumber;
    companyUiDto.taxId1 = formValue.taxId1;
    companyUiDto.taxId2 = formValue.taxId2;
    companyUiDto.branch = formValue.branch == 'true' ? true : false;
    companyUiDto.timeZone = formValue.timeZone;
    companyUiDto.fiscalYrStart = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, formValue.fiscalYrStart)!;
    companyUiDto.fiscalYrEnd = ApplicationUtils.getDateFromNgDatePicker(this.datePipe, formValue.fiscalYrEnd)!;
    companyUiDto.languageDto = this.organizationUiDto?.orgLanguageDtos?.find(item => item.languageCode == formValue.languageCode);
    companyUiDto.currencyDto = this.orgCurrencyList.find(item => item.code == formValue.currencyCode);

    return companyUiDto;
  }

  mergeAddressDetails() {
    let formValue = this.formGroupAddress.getRawValue();
    let companyUiDto = new CompanyUiDto();

    if (this.selectedCompanyUiDto) {
      companyUiDto = ApplicationUtils.clone(this.selectedCompanyUiDto) as CompanyUiDto;
    }

    if (!companyUiDto.shippingAddress) {
      companyUiDto.shippingAddress = [];
    }

    if (!companyUiDto.billingAddress) {
      companyUiDto.billingAddress = [];
    }

    let billingAddress = new AddressDto();
    billingAddress.addressType = formValue.billingAddress.addressType;
    billingAddress.searchAddress = formValue.billingAddress.searchAddress;
    billingAddress.addressLine1 = formValue.billingAddress.addressLine1;
    billingAddress.addressLine2 = formValue.billingAddress.addressLine2;
    billingAddress.city = formValue.billingAddress.city;
    billingAddress.state = formValue.billingAddress.state;
    billingAddress.country = formValue.billingAddress.country;
    billingAddress.zipCode = formValue.billingAddress.zipCode;
    billingAddress.mobileNo = formValue.billingAddress.mobileNo;
    billingAddress.latitude = this.billingLatitude?.toString();
    billingAddress.longitude = this.billingLongitude?.toString();
    billingAddress.countryShortName = this.billingCountryShortName;

    let shippingAddress = new AddressDto();
    shippingAddress.addressType = formValue.shippingAddress.addressType;
    shippingAddress.searchAddress = formValue.shippingAddress.searchAddress;
    shippingAddress.addressLine1 = formValue.shippingAddress.addressLine1;
    shippingAddress.addressLine2 = formValue.shippingAddress.addressLine2;
    shippingAddress.city = formValue.shippingAddress.city;
    shippingAddress.state = formValue.shippingAddress.state;
    shippingAddress.country = formValue.shippingAddress.country;
    shippingAddress.zipCode = formValue.shippingAddress.zipCode;
    shippingAddress.mobileNo = formValue.shippingAddress.mobileNo;
    shippingAddress.latitude = this.shippingLatitude?.toString();
    shippingAddress.longitude = this.shippingLongitude?.toString();
    shippingAddress.countryShortName = this.shippingCountryShortName;

    if (companyUiDto.billingAddress.length > 0) {
      billingAddress.id = companyUiDto.billingAddress[0].id;
      shippingAddress.id = companyUiDto.shippingAddress[0].id;

      Object.assign(companyUiDto.billingAddress[0], billingAddress);
      Object.assign(companyUiDto.shippingAddress[0], shippingAddress);
    } else {
      billingAddress.id = UUID.UUID().toString();
      shippingAddress.id = UUID.UUID().toString();

      companyUiDto.shippingAddress.push(shippingAddress);
      companyUiDto.billingAddress.push(billingAddress);
    }

    return companyUiDto;
  }

  mergeBankDetails() {
    let formValue = this.formGroupBank.getRawValue();
    let companyUiDto = new CompanyUiDto();

    if (this.selectedCompanyUiDto) {
      companyUiDto = ApplicationUtils.clone(this.selectedCompanyUiDto) as CompanyUiDto;
    }

    if (!companyUiDto.companyAccountUiDtos) {
      companyUiDto.companyAccountUiDtos = [];
    }

    let companyAccountUiDto = new CompanyAccountUiDto();

    companyAccountUiDto.orgCode = companyUiDto.orgCode;
    companyAccountUiDto.companyCode = companyUiDto.companyCode;
    companyAccountUiDto.bankTitle = formValue.accountTitle;
    companyAccountUiDto.bankAccNo = formValue.accNumber;
    companyAccountUiDto.bankAccHolder = formValue.accHolderName;
    companyAccountUiDto.bankAccType = formValue.accType;
    companyAccountUiDto.bankAccCode = formValue.accCode;
    companyAccountUiDto.bankAccSwift = formValue.swift;
    companyAccountUiDto.bankAccIban = formValue.iban;

    companyUiDto.companyAccountUiDtos.push(companyAccountUiDto);

    return companyUiDto;
  }

  saveCompany(isAddPlant?: boolean) {
    this.isLoading = false;
    this._showErrorMsg$.next("")
    this._showErrorToast$.next(false);

    if (this.currentTab == 'BASIC_DETAILS' && this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (this.currentTab == 'BILLING_AND_SHIPPING' && this.formGroupAddress.invalid) {
      this.formGroupAddress.markAllAsTouched();
      return;
    }

    if (this.currentTab == 'BANK_DETAILS' && this.formGroupBank.invalid) {
      this.formGroupBank.markAllAsTouched();
      return;
    }

    let companyUiDto = this.currentTab == 'BILLING_AND_SHIPPING' ? this.mergeAddressDetails()
      : this.currentTab == 'BANK_DETAILS' ? this.mergeBankDetails()
        : this.mergeBasicDetails()

    this.isLoading = true;

    this.onboardingService.saveCompanyUiDto(companyUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._showSuccessToast$.next(true);
          this.isLoading = false;

          let newCompanyUiDto = apiResponseDto.data as CompanyUiDto;
          this.onboardingService.updateCompanyUiDto(newCompanyUiDto);
          this.onboardingService.setCurrentCompanyUiDto(newCompanyUiDto);

          setTimeout(() => {
            this._showSuccessToast$.next(false);

            if (this.currentTab == 'BASIC_DETAILS') {
              this.toggleDetailsTab('BILLING_AND_SHIPPING');
            } else if (this.currentTab == 'BILLING_AND_SHIPPING') {
              this.toggleDetailsTab('BANK_DETAILS');
            } else if (this.currentTab == 'BANK_DETAILS') {
              if (isAddPlant) {
                this.onCloseEvent.emit('ONBOARDING_NEW_PLANT');
              } else {
                this.onCloseEvent.emit(undefined);
              }

              this.toggleDetailsTab('BASIC_DETAILS');
            }
          }, 2000)
        } else {
          this._showErrorMsg$.next(apiResponseDto.message as string);
          this._showErrorToast$.next(true);
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this._showErrorMsg$.next("Error Saving Company");
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.masterTimeZoneSubscription$) {
      this.masterTimeZoneSubscription$.unsubscribe();
    }
    if (this.companiesSubscription$) {
      this.companiesSubscription$.unsubscribe();
    }
    if (this.selectedCompanySubscription$) {
      this.selectedCompanySubscription$.unsubscribe();
    }
    if (this.drawerSubscription$) {
      this.drawerSubscription$.unsubscribe();
    }
  }
}
