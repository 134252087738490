import { Timestamp } from "@angular/fire/firestore";
import { FileInfoDto } from "../FileInfoDto";
import { LotFeatures } from "./LotFeatures";
import { BidIncrementalWrapperDto } from "./BidIncrementalWrapperDto";
import { PreferenceCategoryDto } from "./PreferenceCategoryDto";

export class AuctionLotEntityDto {
  auctionId?: string;
  auctionHouseId?: string;
  lotId?: string;
  title?: string;
  lotSequence?: number
  description?: string;
  address?: string;
  domainName?: string;
  endDate?: string;
  endTime?: string;
  endDateUTC?: string | Timestamp;
  hashtags?: Array<string>;
  highlights?: Array<string>;
  startingBid?: number;
  reservePrice?: number;
  estStartingPrice?: number;
  estEndingPrice?: number;
  publishReservePrice?: boolean = false;
  bidIncrement?: number;
  lotFeatures?: Array<LotFeatures>;
  incrementalWrapperDto?: BidIncrementalWrapperDto;
  lotImages?: Array<FileInfoDto>;
  inspectionDetails?: string;
  pickUpDetails?: string;
  timeZone?: string;
  active?: boolean;
  version?: number;
  discardedLot? :boolean;
  buyItNowEnable?: boolean;
  lotAllocated?: boolean;
  lotType?: string;
  barcodeImageDto?: FileInfoDto;
  enableAutoSeller?: boolean;
  lotAllocateUtcTime?: string | Timestamp;

  warnBid?: boolean = false;
  warnBidValue?: number;
  warnBidValueInPercent?: number;


  blockBid?: boolean = false;
  blockBidValue?: number;
  blockBidValueInPercent?: number;

  allowXRank?: number;
  allowYMinutes?: number;

  updateTimeUtc?: string | Timestamp
  updateTimeUtcMillis?: number;
  preferenceCategory?: PreferenceCategoryDto;

  static parseAuctionLotEntityDto(data: any): AuctionLotEntityDto {
    let auctionLotEntityDto = new AuctionLotEntityDto();
    auctionLotEntityDto.auctionId = data.auctionId
    auctionLotEntityDto.auctionHouseId = data.auctionHouseId
    auctionLotEntityDto.lotId = data.lotId
    auctionLotEntityDto.title = data.title
    auctionLotEntityDto.lotSequence = Number(data.lotSequence)
    auctionLotEntityDto.description = data.description
    auctionLotEntityDto.address = data.address
    auctionLotEntityDto.domainName = data.domainName
    auctionLotEntityDto.endDate = data.endDate
    auctionLotEntityDto.endTime = data.endTime
    auctionLotEntityDto.endDateUTC = data.endDateUTC
    auctionLotEntityDto.hashtags = data.hashtags
    auctionLotEntityDto.highlights = data.highlights
    auctionLotEntityDto.startingBid = Number(data.startingBid)
    auctionLotEntityDto.reservePrice = Number(data.reservePrice)
    auctionLotEntityDto.publishReservePrice = Boolean(data.publishReservePrice)
    auctionLotEntityDto.estStartingPrice = Number(data.estStartingPrice)
    auctionLotEntityDto.estEndingPrice = Number(data.estEndingPrice)
    auctionLotEntityDto.bidIncrement = Number(data.bidIncrement)
    auctionLotEntityDto.lotFeatures = data.lotFeatures
    auctionLotEntityDto.incrementalWrapperDto = BidIncrementalWrapperDto.parseBidIncrementalWrapperDto(data.incrementalWrapperDto)
    auctionLotEntityDto.lotImages = data.lotImages
    auctionLotEntityDto.inspectionDetails = data.inspectionDetails
    auctionLotEntityDto.pickUpDetails = data.pickUpDetails
    auctionLotEntityDto.timeZone = data.timeZone
    auctionLotEntityDto.active = Boolean(data.active)
    auctionLotEntityDto.version = Number(data.version)
    auctionLotEntityDto.discardedLot = data.discardedLot;
    auctionLotEntityDto.lotType = data.lotType;
    auctionLotEntityDto.buyItNowEnable = data.buyItNowEnable;
    auctionLotEntityDto.warnBid = data.warnBid;
    auctionLotEntityDto.warnBidValue = data.warnBidValue;
    auctionLotEntityDto.blockBid = data.blockBid;
    auctionLotEntityDto.blockBidValue = data.blockBidValue;
    auctionLotEntityDto.lotAllocated = data.lotAllocated;
    auctionLotEntityDto.barcodeImageDto = data.barcodeImageDto;
    auctionLotEntityDto.enableAutoSeller = data.enableAutoSeller;
    auctionLotEntityDto.lotAllocateUtcTime = data.lotAllocateUtcTime;
    auctionLotEntityDto.allowXRank = data.allowXRank;
    auctionLotEntityDto.allowYMinutes = data.allowYMinutes;
    auctionLotEntityDto.preferenceCategory = data.preferenceCategory;
    return auctionLotEntityDto;
  }
}
