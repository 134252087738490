import { FileInfoDto } from "./FileInfoDto";
import { PreferenceCategoryDto } from "./user/PreferenceCategoryDto";

export class MaterialUiDto {
  orgCode?: string;

  //Material Details
  materialId?: string;
  materialName?: string;
  description?: string;
  image?: FileInfoDto;
  preferenceCategoryDto?: PreferenceCategoryDto;
  uom?: string;
  qty?: number;
  version?: number;


  //Company plants Details
  materialCompanyUiDtos?: MaterialCompanyUiDto[];

  static convertForPurchaseRequest(materialUiDto: MaterialUiDto): MaterialUiDto {
    materialUiDto.materialCompanyUiDtos = undefined;
    materialUiDto.preferenceCategoryDto = undefined;
    materialUiDto.qty = 0;
    materialUiDto.version = undefined;
    return materialUiDto;
  }
}

export class MaterialCompanyUiDto {
  companyCode?: string;
  companyName?: string;
  materialPlantUiDtos?: MaterialPlantUiDto[];
}

export class MaterialPlantUiDto {
  plantCode?: string;
  plantName?: string;
  currentLevel?: number;
  safetyMin?: number;
  safetyMax?: number;
}