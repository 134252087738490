<div class=" nav-bg text-start mt-3">
  <ul class="" style="list-style: none;" class="ps-0">
    <li class="list-class" (click)="toggleDetailsTab('EVENTS')"
      [ngClass]="currentTab == 'EVENTS' ? 'btn-active' : 'btn-default'">
      Events
    </li>
    <li class="list-class" (click)="toggleDetailsTab('TECHNICAL')"
      [ngClass]="currentTab == 'TECHNICAL' ? 'btn-active' : 'btn-default'">
      Technical
    </li>
    <li class="list-class" (click)="toggleDetailsTab('FINANCIAL')" *ngIf="rfxEntityDto?.rfxType != 'PQ'"
      [ngClass]="currentTab == 'FINANCIAL' ? 'btn-active' : 'btn-default'">
      Financial
    </li>
    <li class="list-class" (click)="toggleDetailsTab('COMPARISON_SHEET')" *ngIf="rfxEntityDto?.rfxType != 'PQ'"
      [ngClass]="currentTab == 'COMPARISON_SHEET' ? 'btn-active' : 'btn-default'">
      Comparison Sheet
    </li>
    <li class="list-class" (click)="toggleDetailsTab('NORMALISE')" *ngIf="rfxEntityDto?.rfxType != 'PQ'"
      [ngClass]="currentTab == 'NORMALISE' ? 'btn-active' : 'btn-default'">
      Negotiate
    </li>
    <!-- <li class="list-class" (click)="toggleDetailsTab('APPROVAL_WORKFLOW')"
      [ngClass]="currentTab == 'APPROVAL_WORKFLOW' ? 'btn-active' : 'btn-default'">
      Approval Workflow
    </li>
    <li class="list-class btn-default" (click)="toggleDetailsTab('GRAPH')"
      [ngClass]="currentTab == 'GRAPH' ? 'btn-active' : 'btn-default'">
      Graph
    </li>
    <li class="list-class btn-default float-end" *ngIf="currentTab == 'TECHNICAL'">
      <small>*Auto Calculated Scores.</small>
    </li> -->
  </ul>
</div>

<!-- Events Page -->
<div class="container" *ngIf="currentTab == 'EVENTS'">

  <div class="table-responsive">
    <table class="table medium-font mb-0 table-bordered">
      <thead class="table-dark">
        <tr class="align-middle">
          <th scope="col" class="text-center">#</th>
          <th scope="col" class="text-center">
            SNo
          </th>
          <th scope="col" class="text-center">
            Name
          </th>
          <th scope="col" class="text-center">
            Ends
          </th>
          <th scope="col" class="text-center">
            Submissions
          </th>

        </tr>
      </thead>
      <tbody class="bg-white">
        <tr *ngIf="isDataLoading">
          <td colspan="6">
            <div class="d-grid gap-2 mb-3">
              <div class="e-spinner"></div>
            </div>
          </td>
        </tr>
        <tr class="align-middle" *ngFor="let item of (subcategorySubmissionsList$|async); index as i"
          [ngClass]="{'submitted': item.evaluatorStatus == 'Complete'}">
          <td class=" text-center">
            <input #radioEvent type="radio" name="radioEvent" id="radioEvent"
              [checked]="item.subcategoryId == selectedSubcategorySubmissionDto?.subcategoryId"
              (click)="selectSubcategorySubmission(item, radioEvent)">
          </td>
          <td class="text-center">{{item.subcategorySequenceNo}}</td>
          <td class="text-center">{{item.subcategoryName}}</td>
          <td class="text-center">{{getDisplayDate(item.endDate!, item.endTime!)}}</td>
          <td class="text-center">{{item.submissions}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Technical Submissions of Selected Subcategory -->
<div class="container" *ngIf="currentTab == 'TECHNICAL' && currentPage == 'TECHNICAL_SUBMISSION_USERS'">
  <div class="row justify-content-between mt-3">
    <div class="col-md-6">
      <span class="badge-gray-class badge me-3">Not Qualified - {{totalNotQualifiedUsers}}</span>
      <span class="badge-blue-class badge">Qualified - {{totalQualifiedUsers}}</span>
    </div>
    <div class="col-md-6 text-md-end">
      <a class="link-class mb-2 mb-mt-0" (click)="openConfirmFinancialEnvelopeModal(confirmFinancialEnvelopeModal)"
        *ngIf="isShowOpenFinancialEnvelope">
        Open Financial Envelope?
      </a>
      <a class="link-class mb-2 mb-mt-0" (click)="openConfirmTechnicalEnvelopeModal(confirmTechnicalEnvelopeModal)"
        *ngIf="isShowOpenTechnicalEnvelope">
        Open Technical Envelope?
      </a>
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0 mb-2 mb-mt-0"
        [disabled]="totalNotEvaluatedUsers == 0"
        (click)="openSubmitUserSubmissionsModal(submitAllUserSubmissionsConfirmModal)">
        Submit All
      </button>
    </div>
  </div>

  <div class="row mt-2 mb-3">
    <div class="me-3">
      <span class="me-2">Event Name:</span>
      <span>{{selectedSubcategorySubmissionDto?.subcategoryName}}</span>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table medium-font table-bordered mb-0">
      <thead class="table-dark">
        <tr class="align-middle">
          <th scope="col" class="text-center">
            SNo
          </th>
          <th scope="col" class="text-center">
            Name
          </th>
          <th scope="col" class="text-center">
            Ends
          </th>
          <th scope="col" class="text-center">
            Score
          </th>
          <th scope="col" class="text-center">
            Status
          </th>
          <th scope="col" class="text-center">
            Evaluation Status
          </th>
          <th scope="col" class="text-center">
            Action
          </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr *ngIf="isDataLoading">
          <td colspan="7">
            <div class="d-grid gap-2 mb-3">
              <div class="e-spinner"></div>
            </div>
          </td>
        </tr>
        <tr *ngIf="isShowOpenTechnicalEnvelope">
          <td colspan="7">
            <div class="d-grid gap-2 mb-3">
              <div class="text-center div-class">Open the technical envelope to review the evaluated technical data
              </div>
            </div>
          </td>
        </tr>
        <tr class="align-middle" *ngFor="let item of (evaluationRfxSubcategoryList$|async); index as i"
          [ngClass]="{'submitted': item.status == 'QUALIFIED'}">
          <td class="text-center">{{i+1}}</td>
          <td class="text-center">{{item.companyName}}</td>
          <td class="text-center">{{item.updateTimeUtc | date: 'dd MMM yyyy'}}</td>
          <td class="text-center">{{item.score}}<span
              *ngIf="item.autoResponseScore && item.autoResponseScore > 0">*</span></td>
          <td class="text-center">
            <div class=""
              [ngClass]="{'text-orange-500 ' : item.status == 'NOT_QUALIFIED', 'text-green-500 ' : item.status == 'QUALIFIED'}">
              <span class="ms-2">{{item.status == 'QUALIFIED' ? 'Qualified' : 'Disqualified'}}</span>
            </div>
          </td>
          <td class="text-center">
            <div class="" [ngClass]="{'text-orange-500 ' : getUserEvaluationStatus(item.companyId!) == 'Pending', 'text-green-500 ' : getUserEvaluationStatus(item.companyId!) == 'Submitted',
            'text-blue' : getUserEvaluationStatus(item.companyId!) == 'In Progress'}">
              <span class="ms-2">{{getUserEvaluationStatus(item.companyId!)}}</span>
            </div>
          </td>
          <td class="text-center">
            <a class="link-class" (click)="loadUserSubmissionQuestions(item)">Edit</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="container" *ngIf="currentTab == 'TECHNICAL' && currentPage == 'TECHNICAL_SUBMISSION_QUESTIONS'">
  <div class="row mt-3">
    <div class="col-md-6">
      <span class="badge-gray-class badge me-3">Pending - {{totalPendingQuestions}}</span>
      <span class="badge-blue-class badge">Verified - {{totalVerifiedQuestions}}</span>
    </div>
    <div class="col-md-6 text-end">
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0 me-2"
        (click)="changeDetailsPage('TECHNICAL_SUBMISSION_USERS')">Back</button>
      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0" [disabled]="totalPendingQuestions == 0"
        (click)="openSubmitSubmissionQuestionsModal(submitAllQuestionsConfirmModal)">Submit All</button>
    </div>
  </div>
  <div class="row mt-2">
    <div class="me-3">
      <span class="me-2">Event Name:</span>
      <span>{{selectedSubcategorySubmissionDto?.subcategoryName}}</span>
    </div>
  </div>
  <div class="row mt-2 mb-3">
    <div class="me-3">
      <span class="me-2">Company:</span>
      <span>{{selectedEvaluationUserRfxSubcategory?.companyName}}</span>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table medium-font table-bordered mb-0">
      <thead class="table-dark">
        <tr class="align-middle">
          <th scope="col" class="text-center">
            Q No
          </th>
          <th scope="col" class="text-center">
            Q Criteria
          </th>
          <th scope="col" class="text-center">
            Response Type
          </th>
          <th scope="col" class="text-center">
            Score
          </th>
          <th scope="col" class="text-center">
            Total
          </th>
          <th scope="col" class="text-center">
            Status
          </th>
          <th scope="col" class="text-center">
            Action
          </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr *ngIf="isDataLoading">
          <td colspan="7">
            <div class="d-grid gap-2 mb-3">
              <div class="e-spinner"></div>
            </div>
          </td>
        </tr>
        <tr *ngIf="isFinancialEnvelopeNotOpened && rfxEntityDto?.rfxType == 'RFQ'">
          <td colspan="7">
            <div class="d-grid gap-2 mb-3">
              <div class="text-center">Open the financial envelope to review the financial data</div>
            </div>
          </td>
        </tr>
        <tr class="align-middle" *ngFor="let item of (userSubmissionTechQuestions$|async); index as i">
          <td class="text-center">{{item.preSequenceText}}.{{item.sequenceNo}}</td>
          <td class="text-center">{{item.questionText}}</td>
          <td class="text-center">{{getTitleResponseType(item.responseType!)}}</td>
          <td class="text-center">
            {{getEvaluationScore(item.questionId!)}}
            <span *ngIf="isQuestionAutoResponse(item.questionId!)">*</span>
          </td>
          <td class="text-center">{{item.score}}</td>
          <td class="text-center">

            <div class="" [ngClass]="{'text-orange-500 ' : getQuestionEvaluationStatus(item.questionId!) == 'Pending', 'text-green-500 ' : getQuestionEvaluationStatus(item.questionId!) == 'Verified',
            'text-blue' : getQuestionEvaluationStatus(item.questionId!) == 'Not Submitted'}">
              <span class="ms-2">{{getQuestionEvaluationStatus(item.questionId!)}}</span>
            </div>
          </td>
          <td class="text-center">
            <a class="link-class" (click)="openEvaluationQuestionModal(item)">Edit</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Financial Submissions of Selected Subcategory -->
<div class="container" *ngIf="currentTab == 'FINANCIAL'">
  <div class="row mt-3 mb-2">
    <span class="me-1">
      Event Name: {{selectedSubcategorySubmissionDto?.subcategoryName}}
    </span>
  </div>
  <div class="row mb-3">
    <div class="col-md-8">
      <span class="me-1">
        Contract Type: {{selectedSubcategorySubmissionDto?.contractAlgo}}
        {{getFormattedContractType(selectedSubcategorySubmissionDto?.contractType!)}}
      </span>
      <span class="me-1" *ngIf="!isTechnicalEvaluationPending">
        Normalised Price is {{getFormattedPrice(normalizePrice)}}
      </span>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table medium-font table-bordered mb-0">
      <thead class="table-dark">
        <tr class="align-middle">
          <th scope="col" class="text-center">
            Bidder
          </th>
          <th scope="col" class="text-center">
            Name
          </th>
          <th scope="col" class="text-center">
            Total Bid Amount
          </th>
          <th scope="col" class="text-center" *ngIf="selectedSubcategorySubmissionDto?.contractAlgo == 'QCBS'">
            QCBS Score
          </th>
          <th scope="col" class="text-center">
            Technical
          </th>
          <th scope="col" class="text-center">
            Qualification
          </th>
          <th scope="col" class="text-center">
            Action
          </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr *ngIf="isDataLoading">
          <td colSpan="7">
            <div class="d-grid gap-2 mb-3">
              <div class="e-spinner"></div>
            </div>
          </td>
        </tr>

        <tr *ngIf="isTechnicalEvaluationPending && !isDataLoading">
          <td colSpan="7">
            <div class="d-grid gap-2 my-3">
              <p class="text-center fs-6">Financials will be available after the technical evaluation is complete.</p>
            </div>
          </td>
        </tr>

        <tr class="align-middle" *ngFor="let item of (comFinancialWrapperDtos$|async); index as i">
          <td class="text-center">{{getBidderRankingText(selectedSubcategorySubmissionDto?.contractAlgo! ,
            item.compSequence!, i)}}</td>
          <td class="text-center">{{item.companyName}}</td>
          <td class="text-center">{{getFormattedPrice(item.totalBidAmount!)}}</td>
          <td class="text-center" *ngIf="selectedSubcategorySubmissionDto?.contractAlgo == 'QCBS'">
            {{item.qcbsScore!}}
          </td>
          <td class="text-center">{{item.gradedTechnicalScore ?
            item.gradedTechnicalScore : item.technicalScore}}</td>
          <td class="text-center">
            <div class=""
              [ngClass]="{'text-orange-500 ' : item.qualificationStatus == 'NOT_QUALIFIED', 'text-green-500 ' : item.qualificationStatus == 'QUALIFIED'}">
              <span class="ms-2">{{item.qualificationStatus == 'QUALIFIED' ? 'Qualified' : 'Disqualified'}}</span>
            </div>
            <!-- {{item.qualificationStatus == 'QUALIFIED' ? 'Qualified' : 'Disqualified'}} -->
          </td>
          <td class="text-center">
            <a class="link-class" (click)="openEvaluationFinancialQuestionModal(item)">View</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngIf="currentTab == 'COMPARISON_SHEET'">
  <app-rfx-evaluation-comparison-sheet [subcategorySubmissionDto]="selectedSubcategorySubmissionDto">
  </app-rfx-evaluation-comparison-sheet>
</div>

<div *ngIf="currentTab == 'NORMALISE'">
  <app-rfx-evaluation-normalise [selectedSubcategoryId]="selectedSubcategorySubmissionDto?.subcategoryId!">
  </app-rfx-evaluation-normalise>
</div>

<div *ngIf="currentTab == 'GRAPH'">
  <app-rfx-evaluation-graph></app-rfx-evaluation-graph>
</div>

<ng-template #submitAllQuestionsConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>CONFIRMATION</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(submitSubmissionQuestionsModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>
          You've reviewed {{totalVerifiedQuestions}}/{{totalVerifiedQuestions + totalPendingQuestions}} questions.
          Submitting without verification could lead to errors.
        </p>
        <p>
          <a class="link-class" (click)="closeModal(submitSubmissionQuestionsModalRef)">Click here to continue
            Verification</a>
        </p>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'Submissions submitted successfully.'"></app-message-toast>
        </div>

        <div class="mb-3 text-center" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0"
            (click)="submitAllSubcategorySubmissionQuestions()">Submit All</button>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #submitAllUserSubmissionsConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>CONFIRMATION</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(submitSubcategorySubmissionModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>
          You've reviewed {{totalNotEvaluatedUsers}}/{{totalEvaluatedUsers + totalNotEvaluatedUsers}}
          users.
          Submitting without verification could lead to errors.
        </p>
        <p>
          <a class="link-class" (click)="closeModal(submitSubcategorySubmissionModalRef)">Click here to continue
            Verification</a>
        </p>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            [message]="'Submissions submitted successfully.'"></app-message-toast>
        </div>

        <div class="mb-3 text-center" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex -clr-class mt-2 mt-sm-0" (click)="submitSubcategorySubmissions()">Submit
            All</button>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading" [ngClass]="{'center-spinner' : isLoading}"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmFinancialEnvelopeModal>
  <div class="my-2">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>CONFIRM</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(openFinancialConfirmModalRef)" [ngClass]="{'disabled': isLoading}">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <span>
        Are you sure you want to open Financial Envelope?
      </span>
    </div>

    <div class="m-3">
      <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true" [message]="errorMsg"></app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
        message="Financial Envelope successfully opened.">
      </app-message-toast>
    </div>

    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner"></div>
    </div>

    <div class="m-3">
      <div class="text-end my-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-3 me-3"
          (click)="closeModal(openFinancialConfirmModalRef)">Cancel</button>
        <button type="button" class="btn btn-sm btn-outline-primary px-3"
          (click)="openFinancialEnvelopeRequest()">Submit</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmTechnicalEnvelopeModal>
  <div class="my-2">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2 ngbAutoFocus>CONFIRM</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(openTechnicalConfirmModalRef)" [ngClass]="{'disabled': isLoading}">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <span>
        Are you sure you want to open Technical Envelope?
      </span>
    </div>

    <div class="m-3">
      <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true" [message]="errorMsg"></app-message-toast>
      <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
        message="Technical Envelope successfully opened.">
      </app-message-toast>
    </div>

    <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
      <div class="e-spinner"></div>
    </div>

    <div class="m-3">
      <div class="text-end my-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-3 me-3"
          (click)="closeModal(openTechnicalConfirmModalRef)">Cancel</button>
        <button type="button" class="btn btn-sm btn-outline-primary px-3"
          (click)="openTechnicalEnvelopeRequest()">Submit</button>
      </div>
    </div>
  </div>
</ng-template>
