<div class="">
  <div class="flex flex-row gap-4 items-center justify-between h-12 px-6 py-4 border-b border-gray-300">
  
      <div class="inline-block ms-2">
        <div class="font-semibold text-black text-xl">
          Creating new tender
        </div>
      
    </div>
   
  </div>
  <form class="needs-validation w-full" novalidate novalidate [formGroup]="formGroup"
    (keyup.enter)="saveTenderWrapperUiDto()">
    <div class="h-screen">
      <div class="p-6 tr-overflow-y-drawer ">
        <div class="w-4/5">
          <div class="grid grid-cols-12 mb-3">
            <div class="col-span-6">
              <label for="title" class="text-gray-700 text-sm font-semibold leading-4">
                <div>Name of the tender</div>
              </label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
                type="text" formControlName="title" id="title" placeholder="---"
                [ngClass]="{'is-invalid': fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)}">
              <div *ngIf="fc['title'].invalid && (fc['title'].dirty || fc['title'].touched)">
                <p class="text-red-500 " *ngIf="fc['title'].errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="fc['title'].errors?.['maxlength']">max 250 character</p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 mb-3">
            <div class="col-span-6">
              <label for="tenderReferenceNo" class="text-gray-700 text-sm font-semibold leading-4">
                <div>Tender reference number</div>
              </label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
                type="text" formControlName="tenderReferenceNo" id="tenderReferenceNo" placeholder="---"
                [ngClass]="{'is-invalid': fc['tenderReferenceNo'].invalid && (fc['tenderReferenceNo'].dirty || fc['tenderReferenceNo'].touched)}">
              <div
                *ngIf="fc['tenderReferenceNo'].invalid && (fc['tenderReferenceNo'].dirty || fc['tenderReferenceNo'].touched)">
                <p class="text-red-500 " *ngIf="fc['tenderReferenceNo'].errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="fc['tenderReferenceNo'].errors?.['maxlength']">max 250 character</p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 mb-3">
            <div class="">
              <label for="description" class="text-gray-700 text-sm font-semibold leading-4">
                <div>Tender description</div>
              </label>
              <textarea type="text"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500  px-2 py-1 text-gray-700 text-sm"
                id="description" formControlName="description" rows="6"
                [ngClass]="{'is-invalid': fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)}">
              </textarea>
              <div *ngIf="fc['description'].invalid && (fc['description'].dirty || fc['description'].touched)">
                <p class="text-red-500 " *ngIf="fc['description'].errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="fc['description'].errors?.['maxlength']">max 1000 character</p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 mb-3">
            <div class="col-span-6">
              <label for="materialId" class="text-gray-700 text-sm font-semibold leading-4 ">
                <div class="mb-1">Select opportunity type</div>
              </label>
              <div class="select-multi-button ">
                <span class="inline-block border border-blue-500 rounded-lg">
                  <p-selectButton [unselectable]="true" [options]="stateOptions" formControlName="opportunityType" id="opportunityType"
                    optionLabel="label" optionValue="value"></p-selectButton>
                </span>
                <div *ngIf="fc['opportunityType'].invalid && (fc['opportunityType'].dirty || fc['opportunityType'].touched)">
                  <p class="text-red-500 " *ngIf="fc['opportunityType'].errors?.['required']">Required</p>
                  <p class="text-red-500 " *ngIf="fc['opportunityType'].errors?.['maxlength']">max 1000 character</p>
                </div>
              </div>
              <!-- <div
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 text-gray-100 text-sm mb-2"
            type="text" id="materialId" placeholder="---">
            <div class="flex flex-row">
              <div
                class="text-center basis-1/2 text-gray-700 bg-gray-100 hover:bg-gray-300 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800">
                Single
              </div>
              <div class="basis-1/2 text-center py-1.5">
                  Multiple
                </div>
            </div>
          </div> -->

            </div>
          </div>
          <div class="text-xs font-normal text-gray-500 mb-10">
            General conditions of Sale along with any other document annexed. <span
              class="text-gray-700 font-semibold text-xs"></span> <span
              class="text-xs font-normal text-gray-100 underline "> You can always change opportunity type
            </span>
          </div>
          <div class="flex flex-row gap-4 items-center justify-between mb-3">
            <div class="text-gray-700 font-semibold text-xs">
              Add NIT(Notice Inviting Tender) Documents
            </div>
            <div class=" text-end flex items-center">
              <div class="me-3 inline-flex items-center" *ngIf="nitDocuments.length">
                <button type="button"
                  class="inline-flex text-gray-700 bg-gray-100 hover:bg-gray-300 focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-xs px-3 py-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800">
                  <span
                    class="inline-flex h-4 w-4  ps-1` items-center justify-center bg-gray-300 text-gray-700 rounded-full me-1">
                    {{nitDocuments.length || 0}}
                  </span> Document added yet
                </button>
              </div>
              <div class="me-3 inline-flex ">
                <button type="button" (click)="openAddNitDocumentDialog()"
                  class="inline-flex items-center py-1.5 px-3 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-800 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                  <i-lucide name="plus" [size]="16" color="#000" class="my-icon inline-flex"></i-lucide> New NIT
                  Document</button>
              </div>
            </div>
          </div>
          <div class="grid bg-gray-100 rounded-lg py-4 items-center justify-center" *ngIf="nitDocuments.length == 0">
            <img src="assets/images/tender-drawer.svg" class=" h-16 w-16 mx-auto my-0">
            <div class="text-xs font-normal text-black mt-2">
              Not NIT documents added yet.
            </div>
          </div>
          <div class="mt-2 border border-gray-500" *ngIf="nitDocuments.length > 0">
            <p-table [value]="nitDocuments" [customSort]="true" [tableStyle]="{'min-width': '60rem'}">
              <ng-template pTemplate="header">
                <tr class="bg-white">
                  <th pSortableColumn="code" class=""></th>
                  <th pSortableColumn="code"
                    class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">NIT document
                    <p-sortIcon field="code" class="float-end"></p-sortIcon>
                  </th>
                  <th pSortableColumn="name"
                    class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">Uploaded date
                    <p-sortIcon field="name" class="float-end"></p-sortIcon>
                  </th>
                  <th pSortableColumn="category"
                    class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">Verified by
                    <p-sortIcon field="category" class="float-end"></p-sortIcon>
                  </th>
                  <th pSortableColumn="price"
                    class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
                    Status
                    <p-sortIcon field="quantity" class="float-end tr-xs-icon"></p-sortIcon>
                  </th>
                  <th pSortableColumn="quantity"
                    class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-item let-i="rowIndex">
                <tr>
                  <td class="border-e">
                    <div class="font-medium text-sm">
                      {{i+1}}
                    </div>
                  </td>
                  <td class="font-medium text-sm  underline ">
                    {{item.displayName}}
                  </td>
                  <td class="font-medium text-sm">
                    {{item.uploadDate | ordinalDate}}
                  </td>
                  <td class="font-medium text-sm">
                    <span for="" class="leading-6 text-sm font-medium " *ngIf="item?.verifiedBy">
                      <span
                        class="inline-flex h-6 w-6 gap-2.5 p-1 items-center justify-between bg-gray-100 text-gray-700 rounded-full me-2">
                        <img src="/downloadLandingBlob?fileId={{item?.verifiedBy?.profileImage?.fileId}}"
                          *ngIf="item?.verifiedBy?.profileImage">
                        <span *ngIf="!item?.verifiedBy?.profileImage">
                          {{(item?.verifiedBy?.firstName + ' ' + item?.verifiedBy?.lastName) | textAvatar}}
                        </span>
                      </span>
                      {{item?.verifiedBy?.firstName}} {{item?.verifiedBy?.lastName}}
                    </span>
                  </td>
                  <td class="font-medium text-sm">
                    <span class="me-3 flex items-center" *ngIf="item?.verifiedBy">
                      <button type="button"
                        class="inline-flex py-1 px-3 text-sm font-medium text-gray-700 focus:outline-none bg-gray-100 rounded-full border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        <i-lucide name="check-check" [size]="20" color="#0555F5"
                          class="my-icon inline-flex "></i-lucide>
                        Verified</button>
                    </span>
                  </td>
                  <td>
                    <div>
                      <span (click)="deleteNitDocument(i)">
                        <i-lucide name="trash-2" [size]="18" color="#0555F5" class="my-icon"></i-lucide>
                      </span>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
          <div class="mt-3 mb-5">
            <app-message-toast *ngIf="_showSuccessToast$ | async" [message]="'Tender saved successfully.'">
            </app-message-toast>
            <app-message-toast *ngIf="_showErrorToast$ | async" [isError]="true" [message]="errorMsg">
            </app-message-toast>
          </div>
          <div class="mb-5" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>
        </div>
        <div class="footer">
          <div class="flex flex-row items-center justify-end h-8 my-2">
            <!-- <div class="basis-1/2 mt-2 ps-6">
              <div class="h-2 w-20 bg-gray-100 inline-block"></div>
              Some information is pending
            </div> -->
            <div class="text-end  pe-7" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
              
                <p-button [disabled]="isLoading" label="Cancel"
                class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800 me-3"
                (onClick)="closeDrawer()"></p-button>
              <p-button [loading]="isLoading" label="Save"
                class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
                (onClick)="saveTenderWrapperUiDto()"></p-button>
            </div>
          </div>
        </div>
     
      
    </div>
  </form>
</div>


<p-dialog header="Select Documents" [(visible)]="isShowNitDocumentDialog" [style]="{width: '50vw'}" [draggable]="false" [modal]="true" [resizable]="false"
  [autoZIndex]="true">
  <div>
    <div class="grid grid-cols-12 mb-3">
      <div class="col-span-6">
        <label for="title" class="text-gray-700 text-sm font-semibold leading-4">
          <div>Document Title</div>
        </label>
        <input
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
          type="text" [formControl]="docTitle" id="title" placeholder="---"
          [ngClass]="{'is-invalid': docTitle.invalid && (docTitle.dirty || docTitle.touched)}">
        <div *ngIf="docTitle.invalid && (docTitle.dirty || docTitle.touched)">
          <p class="text-red-500 " *ngIf="docTitle.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="docTitle.errors?.['maxlength']">max 250 character</p>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 mb-3">
      <div class="col-span-6">
        <label for="materialId" class="text-gray-700 text-sm font-semibold leading-4">
          <div>Description</div>
        </label>

        <textarea type="text" type="text" [formControl]="docDescription" id="title" placeholder="---"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 px-2 py-1 text-gray-800 text-sm"
          id="description" rows="6"
          [ngClass]="{'is-invalid': docDescription.invalid && (docDescription.dirty || docDescription.touched)}"></textarea>
        <div *ngIf="docDescription.invalid && (docDescription.dirty || docDescription.touched)">
          <p class="text-red-500 " *ngIf="docDescription.errors?.['required']">Required</p>
          <p class="text-red-500 " *ngIf="docDescription.errors?.['maxlength']">max 1000 character</p>
        </div>
      </div>
    </div>


    <div class="grid grid-cols-2 w-full gap-2 mb-4">
      <div class="">
        <label for="title" class="text-gray-700 text-sm font-semibold leading-4">
          <div>Select Document</div>
        </label>
        <div class="">
          <input id="documentDoc1" type="text" (click)="documentDoc.click()" [formControl]="docFile"
            class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm"
            readonly />
          <input id="documentDoc2" #documentDoc onclick="this.value=null;" type="file" (change)="chooseFile($event)"
            class="hidden" />
          <div *ngIf="docFile.invalid && (docFile.dirty || docFile.touched)">
            <p class="text-red-500 " *ngIf="docFile.errors?.['required']">Required</p>
          </div>
        </div>
      </div>
      <div class="">
        <div class="grid grid-cols-4 w-full gap-2 items-center">
          <div class="col-span-2 mt-2">
          </div>

          <div class=" md:me-2 mt-3">
            <button
              class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-600 font-medium rounded text-sm p-1 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
              *ngIf="currentFileInfoDto" (click)="previewRfxDoc('SERVER')">
              <span> 
                <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class"></span>
            </button>
            <button
              class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-600 font-medium rounded text-sm p-1 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
              *ngIf="currentFile && !currentFileInfoDto" (click)="previewRfxDoc('LOCAL')">
              <span> <img src="assets/icons/info_dark.svg" class=" c-pointer svg-icon-class"></span>
            </button>
          </div>
          <!-- <div class=" mt-3">
            <span (click)="removeRfxDoc()">
              <span> <img src="assets/icons/delete_danger.svg" class=" c-pointer svg-icon-class" ></span>
            </span>
          </div> -->
          <div class="mt-3" *ngIf="currentFile || !currentFileInfoDto">
            <span (click)="removeDoc()">
              <span>
                <img src="assets/icons/delete_danger.svg" class="c-pointer svg-icon-class"
                  [class.disabled]="!currentFile">
              </span>
            </span>
          </div>


        </div>
      </div>
    </div>
    <hr class="hr my-3">

    <div class="text-end ">
      <div class="my-3">
        <app-message-toast *ngIf="_showSuccessToastModal$ | async"
          [message]="'Document saved successfully.'"></app-message-toast>
        <app-message-toast *ngIf="_showErrorToastModal$ | async" [isError]="true"
          [message]="errorMsg"></app-message-toast>
      </div>

      <div class="mb-3" *ngIf="isLoadingModal">
        <div class="e-spinner"></div>
      </div>

      <div class=" gap-2 text-end" *ngIf="!isLoadingModal && !(_showSuccessToastModal$ | async)">
        <p-button [loading]="isLoading" label="Save"
          class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-600 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
          (onClick)="uploadFile()"></p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-toast></p-toast>