export class AdditionalFinancialPrice {
  label?: string;
  mandatory?: boolean;
  additionalPrice?: number;

  // Constructor to initialize properties
  constructor(label?: string, mandatory?: boolean, additionalPrice?: number) {
    this.label = label;
    this.mandatory = mandatory || true;
    this.additionalPrice = additionalPrice;
  }
}