<div class="">
  <div class="border rounded bg-white" [ngClass]="{'border-danger' : haveQuestionnaireValidationIssues()}">
    <header class="p-2">
      <div class="row ">
        <div class="col-md-6">
          <h5 class="heading-h5">QUESTIONNAIRE</h5>
        </div>
        <div class="col-md-6 text-md-end">
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 me-2" (click)="openPublishConfirmModal(publishConfirmModal)"
            *ngIf="isShowPublishButton">
            PUBLISH
          </button>
          <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 " (click)="openAddQuestionnaireModal(addQuestionnaireModal)">
            {{questionnaireTemplate ? 'EDIT' : 'ADD'}}
          </button>
        </div>
      </div>
    </header>
    <hr class="hr" />
    <div class="col-12">
      <div class="d-grid gap-2 my-1" *ngIf="isDataLoading">
        <div class="e-spinner"></div>
      </div>
      <div class="row mx-0">
        <div class="col-12 div-class py-2">
          Create bidder criteria for the opportunity
        </div>

        <hr class="hr" *ngIf="contractAlgos.length > 0" />
        <div class="col-4 font-weight-normal py-2" *ngIf="contractAlgos.length > 0"
          [ngClass]="{'text-red-500 ': !questionnaireTemplate?.contractAlgo}">
          Project Type
        </div>
        <div class="col-8 div-class py-2" *ngIf="contractAlgos.length > 0">
          {{getContractAlgo(questionnaireTemplate?.contractAlgo)}}
        </div>

        <hr class="hr" *ngIf="questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS" />
        <div class="col-4 font-weight-normal py-2" *ngIf="questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS">
          Technical Weightage
        </div>
        <div class="col-8 div-class py-2" *ngIf="questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS">
          {{questionnaireTemplate?.technicalWeightage}}
        </div>

        <hr class="hr" *ngIf="questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS" />
        <div class="col-4 font-weight-normal py-2" *ngIf="questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS">
          Financial Weightage
        </div>
        <div class="col-8 div-class py-2" *ngIf="questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS">
          {{questionnaireTemplate?.financialWeightage}}
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
          Scoring
        </div>
        <div class="col-8 div-class py-2">
          {{questionnaireTemplate?.scoringTemplate}}
        </div>
        <hr class="hr" *ngIf="questionnaireTemplate?.scoringTemplate == 'YES'" />
        <div class="col-4 font-weight-normal py-2" *ngIf="questionnaireTemplate?.scoringTemplate == 'YES'">
          Maximum Score
        </div>
        <div class="col-8 div-class py-2" *ngIf="questionnaireTemplate?.scoringTemplate == 'YES'">
          {{questionnaireTemplate?.maximumScore}}
        </div>

        <hr class="hr"
          *ngIf="questionnaireTemplate?.scoringTemplate == 'YES'" />
        <div class="col-4 font-weight-normal py-2"
          *ngIf="questionnaireTemplate?.scoringTemplate == 'YES'">
          Qualifying Score
        </div>
        <div class="col-6 div-class py-2"
          *ngIf="questionnaireTemplate?.scoringTemplate == 'YES'">
          {{questionnaireTemplate?.qualifyingScore}}
        </div>

        <hr class="hr"
          *ngIf="questionnaireTemplate?.gradationSystem && questionnaireTemplate?.scoringTemplate == 'YES'" />
        <div class="col-4 font-weight-normal py-2"
          *ngIf="questionnaireTemplate?.gradationSystem && questionnaireTemplate?.scoringTemplate == 'YES'">
          Range
        </div>
        <div class="col-6 div-class py-2"
          *ngIf="questionnaireTemplate?.gradationSystem && questionnaireTemplate?.scoringTemplate == 'YES'">
          {{questionnaireTemplate?.range}}
        </div>

        <hr class="hr"
          *ngIf="questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS || questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QBS" />
        <div class="col-4 font-weight-normal py-2"
          *ngIf="questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS || questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QBS">
          Gradation System
        </div>
        <div class="col-6 div-class py-2"
          *ngIf="questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS || questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QBS">
          {{questionnaireTemplate?.gradationSystem ? 'Yes' : 'No'}}
        </div>
        <div class="col-2 text-end mt-2"
          *ngIf="questionnaireTemplate?.gradationSystem && (questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QCBS || questionnaireTemplate?.contractAlgo == ContractAlgoEnum.QBS)">
          <img src="assets/icons/view_light.svg" class=" c-pointer svg-icon-class ms-2"
            (click)="openTechnicalGradationModal(technicalGradationModal)">
        </div>

        <hr class="hr" />
        <div class="col-4 font-weight-normal py-2">
          Guidelines for bidders
        </div>
        <div class="col-8 div-class py-2">
          <ul class="animate-show-more ps-0" #guidelineRef
            [ngClass]="{'hide-guideline': !isShowMoreGuideline && guidelineRef.scrollHeight > 120 && questionnaireTemplate?.guideLines}">
            <li class="c-default" *ngFor="let item of guidelinesList; index as i;">
              {{item}}
            </li>
          </ul>
          <hr class="hr" *ngIf="isShowMoreGuideline" />
          <div class="col-12 text-end btn-link pb-2" (click)="showMoreGuidelineModal()"
            *ngIf="guidelineRef.scrollHeight > 90">
            {{isShowMoreGuideline ? 'Hide More' : 'See More'}}
          </div>
        </div>

        <!-- Technical Questions -->
        <hr class="hr mb-3" />
        <div class="row mb-2">
          <div class="col-md-6 text-start">
            Technical Envelope
          </div>
          <div class="col-md-6 text-md-end">
            <a class="link-class" [ngClass]="{'disabled': !questionnaireTemplate?.templateId}"
              (click)="openAddTechSectionModal()">
              Add Question
            </a>
          </div>
        </div>
        <div class="col-12 table-responsive">
          <table class="table table-bordered medium-font">
            <thead class="table-secondary">
              <tr>
                <th scope="col">S No</th>
                <th scope="col">Section</th>
                <th scope="col">Total Questions</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!techSectionTemplateList || techSectionTemplateList!.length == 0">
                <td colspan="4">
                  <div class="medium-font text-center">
                    No question is available.
                  </div>
                </td>
              </tr>
              <tr *ngFor="let item of techSectionTemplateList">
                <td>{{item?.sequenceNo}}</td>
                <td>{{item?.sectionName}}</td>
                <td>{{getQuestionsCountOfSection(item?.sectionId!)}}</td>
                <td>
                  <a class="link-class" (click)="openEditTechSectionModal(item?.sectionId!)">Edit</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Financial Questions -->
        <hr class="hr mb-3" *ngIf="selectedRfx?.rfxType != 'PQ'"/>
        <div class="row mb-2" *ngIf="selectedRfx?.rfxType != 'PQ'">
          <div class="col-md-6 text-md-start">
            Financial Envelope
          </div>
          <div class="col-md-6 text-md-end">
            <a class="link-class" [ngClass]="{'disabled': !questionnaireTemplate?.templateId}"
              (click)="openAddFinancialSectionModal()">
              Add Item
            </a>
          </div>
        </div>
        <div class="col-12 table-responsive" *ngIf="selectedRfx?.rfxType != 'PQ'">
          <table class="table table-bordered medium-font">
            <thead class="table-secondary">
              <tr>
                <th scope="col">S No</th>
                <th scope="col">Section</th>
                <th scope="col">Total Items</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!finSectionTemplateList || finSectionTemplateList!.length == 0">
                <td colspan="4">
                  <div class="medium-font text-center">
                    No question is available.
                  </div>
                </td>
              </tr>
              <tr *ngFor="let item of finSectionTemplateList">
                <td>{{item?.sequenceNo}}</td>
                <td>{{item?.sectionName}}</td>
                <td>{{getQuestionsCountOfSection(item?.sectionId!)}}</td>
                <td>
                  <a class="link-class" (click)="openEditFinancialSectionModal(item?.sectionId!)">Edit</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</div>


<ng-template #addQuestionnaireModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Questionnaire</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form [formGroup]="formGroup">
          <p class="medium-font">
            Create bidder criteria for the opportunity
          </p>
          <hr class="hr my-1" />

          <div class="form-floating mb-3" *ngIf="contractAlgos.length > 0">
            <select name="Select Contract Algo" class="form-control form-select" formControlName="contractAlgo" id="contractAlgo"
              [ngClass]="{'is-invalid': fc.contractAlgo.invalid && (fc.contractAlgo.dirty || fc.contractAlgo.touched)}">
              <option value="" disabled>Select . . .</option>
              <option [value]="item" *ngFor="let item of contractAlgos">{{getContractAlgo(item)}}</option>
            </select>
            <label for="contractAlgo">Select Contract Algo</label>
            <div *ngIf="fc.contractAlgo.invalid && (fc.contractAlgo.dirty || fc.contractAlgo.touched)">
              <p class="text-red-500 " *ngIf="fc.contractAlgo.errors?.required">Required</p>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-10 col-md-10">
              <span class="me-3 medium-font">Scoring</span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="fc.scoringTemplate.value == 'YES' ? 'selected-event' : 'select-event'"
                (click)="changeScoringTemple('YES')">
                Yes
              </span>
              <span class="badge me-3 mb-2 p-2"
                [ngClass]="fc.scoringTemplate.value == 'NO' ? 'selected-event' : 'select-event'"
                (click)="changeScoringTemple('NO')">
                No
              </span>
            </div>
            <div class="col-2 col-md-1 text-end" *ngIf="questionnaireTemplate?.templateId">
              <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
                (click)="openDeleteConfirmModal(deleteConfirmModal)">
            </div>
          </div>
          <div *ngIf="fc.scoringTemplate.invalid && (fc.scoringTemplate.dirty || fc.scoringTemplate.touched)">
            <p class="text-red-500 " *ngIf="fc.scoringTemplate.errors?.['required']">Required</p>
          </div>

          <div class="row mt-3"
            *ngIf="fc.scoringTemplate.value == 'YES' && (selectedContractAlgo == ContractAlgoEnum.QCBS || selectedContractAlgo == ContractAlgoEnum.QBS)">
            
            <div class="col-md-4">
              Gradation System
            </div>

            <div class="col-md-3">              
              <span class="me-4">
                <input type="radio" [value]="true" formControlName="gradationSystem" id="gradationSystem1">
                <label for="gradationSystem1" class="ms-2">Yes</label>
              </span>
              <span>
                <input type="radio" [value]="false" formControlName="gradationSystem" id="gradationSystem2">
                <label for="gradationSystem2" class="ms-2">No</label>
              </span>
            </div>

            <div class="col-md-5" *ngIf="fc.scoringTemplate.value == 'YES' && fc.gradationSystem.value">
              <div class="input-group">
                <div class="form-floating">
                  <input type="email" class="form-control" id="range" formControlName="range" (blur)="generateRangeGrading()" />
                  <label for="range">Grade Range</label>
                </div>
              </div>
              <div *ngIf="fc.range.invalid && (fc.range.dirty || fc.range.touched)">
                <p class="text-red-500 " *ngIf="fc.range.errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="fc.range.errors?.['multipleOfFive']">Invalid</p>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <span class="small-font" *ngIf="fc.scoringTemplate.value == 'NO'">
              There is no scoring to this sourcing event
            </span>

            <div class="col-md-6" *ngIf="fc.scoringTemplate.value == 'YES'">
              <div class="input-group">
                <div class="form-floating">
                  <input type="email" class="form-control" id="maximumScore" formControlName="maximumScore" (blur)="generateRangeGrading()" />
                  <label for="maximumScore">Maximum Score</label>
                </div>
              </div>
              <div *ngIf="fc.maximumScore.invalid && (fc.maximumScore.dirty || fc.maximumScore.touched)">
                <p class="text-red-500 " *ngIf="fc.maximumScore.errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="fc.maximumScore.errors?.['pattern']">Invalid</p>
              </div>
            </div>

            <div class="col-md-6" *ngIf="fc.scoringTemplate.value == 'YES'">
              <div class="input-group">
                <div class="form-floating">
                  <input type="email" class="form-control" id="qualifyingScore" formControlName="qualifyingScore" />
                  <label for="qualifyingScore">Qualifying Score</label>
                </div>
              </div>
              <div *ngIf="fc.qualifyingScore.invalid && (fc.qualifyingScore.dirty || fc.qualifyingScore.touched)">
                <p class="text-red-500 " *ngIf="fc.qualifyingScore.errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="fc.qualifyingScore.errors?.['pattern']">Invalid</p>
              </div>
            </div>
          </div>

          <div class="mt-3" *ngIf="fc.gradationSystem.value && technicalGradationConfig.controls.length > 0
            && (selectedContractAlgo == ContractAlgoEnum.QCBS || selectedContractAlgo == ContractAlgoEnum.QBS)">
            <table class="table table-bordered text-center medium-font">
              <thead class="font-weight-bold">
                <tr>
                  <th scope="col" class="">#</th>
                  <th scope="col" class="">Range</th>
                  <th scope="col" class="">Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of technicalGradationConfig.controls; index as i">
                  <th scope="row" class="">{{i+1}}</th>
                  <td class="">{{item.get('startRange')?.value}} to {{item.get('endRange')?.value}}</td>
                  <td class="">{{item.get('marks')?.value}}</td>
                </tr>
              </tbody>
            </table>  
          </div>

          <div class="row mt-3" *ngIf="selectedContractAlgo == ContractAlgoEnum.QCBS">
            <div class="col-md-6">
              <div class="form-floating">
                <input type="text" placeholder="Technical Weightage" id="technicalWeightage" formControlName="technicalWeightage"
                  class="form-control" />
                <label for="technicalWeightage">Technical Weightage</label>
                <div
                  *ngIf="fc.technicalWeightage.invalid && (fc.technicalWeightage.dirty || fc.technicalWeightage.touched)">
                  <p class="text-red-500 " *ngIf="fc.technicalWeightage.errors?.required">Required</p>
                  <p class="text-red-500 " *ngIf="fc.technicalWeightage.errors?.pattern">Invalid</p>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-floating">
                <input type="text" placeholder="Financial Weightage" id="financialWeightage" formControlName="financialWeightage"
                  class="form-control" />
                <label for="financialWeightage">Financial Weightage</label>
                <div
                  *ngIf="fc.financialWeightage.invalid && (fc.financialWeightage.dirty || fc.financialWeightage.touched)">
                  <p class="text-red-500 " *ngIf="fc.financialWeightage.errors?.required">Required</p>
                  <p class="text-red-500 " *ngIf="fc.financialWeightage.errors?.pattern">Invalid</p>
                </div>
              </div>
            </div>
          </div>

          <hr class="hr mt-2" />
          <div class="row mt-2">
            <div class="col-8">
              <h6 class="heading-small">
                Guidelines for bidders
              </h6>
            </div>
          </div>
          <div>
            <div class="mb-3">
              <label class="small-font">
                Enter a new line for next guideline
              </label>
              <textarea class=" medium-font form-control" rows="6" formControlName="guideLines">
              </textarea>
              <div *ngIf="fc.guideLines.invalid && (fc.guideLines.dirty || fc.guideLines.touched)">
                <p class="text-red-500 " *ngIf="fc.guideLines.errors?.['required']">Required</p>
              </div>
            </div>

            <div class="my-1">
              <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
                [message]="errorMsg"></app-message-toast>
              <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
                [message]="'Questionnaire saved successfully.'"></app-message-toast>
            </div>

            <div class="d-grid gap-2 my-1" *ngIf="isLoading">
              <div class="e-spinner"></div>
            </div>
            <div class="col-12 text-end my-3">
              <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 "
                [disabled]="selectedRfx?.status == 'LIVE' || selectedRfx?.status == 'LIVE_WAIT'"
                *ngIf="!isLoading && !(_showSuccessToast$ | async)" (click)="saveQuestionnaire()">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>DELETE QUESTIONNAIRE</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoadingForModal}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(deleteConfirmModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Are you sure you want to delete this questionnaire?</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToastForModal$ |async" [isError]="true"
          [message]="errorMsgForModal"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToastForModal$ |async" [isError]="false"
          message="Questionnaire deleted successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoadingForModal">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3 mt-2" *ngIf="!isLoadingForModal && !(_showSuccessToastForModal$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3"
          (click)="closeModal(deleteConfirmModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="deleteQuestionnaireTemplate()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #publishConfirmModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>QUESTIONNAIRE PUBLISH</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoadingForModal}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer"
            (click)="closeModal(publishConfirmModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="m-3">
      <div class="">
        <p>Are you sure you want to publish this questionnaire?</p>
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToastForModal$ |async" [isError]="true"
          [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToastForModal$ |async" [isError]="false"
          message="Questionnaire published successfully."></app-message-toast>
      </div>

      <div class="d-grid gap-2 mb-3" *ngIf="isLoadingForModal">
        <div class="e-spinner"></div>
      </div>

      <div class="text-end mb-3" *ngIf="!isLoadingForModal && !(_showSuccessToastForModal$ | async)">
        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3"
          (click)="closeModal(publishConfirmModalRef)">CANCEL</button>
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="markQuestionnairePublish()">OK</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #technicalGradationModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Technical Gradation List</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <table class="table table-bordered text-center medium-font">
          <thead class="font-weight-bold">
            <tr>
              <th scope="col" class="">#</th>
              <th scope="col" class="">Range</th>
              <th scope="col" class="">Marks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of technicalGradationDtos; index as i">
              <th scope="row" class="">{{i+1}}</th>
              <td class="">{{item?.startRange}} to {{item?.endRange}}</td>
              <td class="">{{item?.marks}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>