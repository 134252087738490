import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuctionWrapperReportDto } from '../../models/reports/AuctionWrapperReportDto';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { Currency } from '../../models/Currency';
import { LotWrapperReportDto } from '../../models/reports/LotWrapperReportDto';
import { AuctionHouseDto } from '../../models/user/AuctionHouseDto';

declare const require: any;
const html2pdf = require('html2pdf.js');

@Component({
  selector: 'app-generate-lot-report',
  templateUrl: './generate-lot-report.component.html',
  styleUrls: ['./generate-lot-report.component.sass']
})
export class GenerateLotReportComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() auctionId!: string

  isLoading$ = new BehaviorSubject<boolean>(false);
  isShowPdfView$ = new BehaviorSubject<boolean>(false);

  auctionReportWrapper?: AuctionWrapperReportDto;
  auctionHouseDto?: AuctionHouseDto;

  auctionReportWrapperSubscription$: Subscription = Subscription.EMPTY;
  auctionHouseDtoSubscription$: Subscription = Subscription.EMPTY;

  chartOption: any = {};

  constructor(
    private activeModal: NgbActiveModal,
    private adminDashboardService: AdminDashboardService,
    private changeDetectRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.auctionHouseDtoSubscription$ = this.adminDashboardService.getCurrentAuctionHouse$.subscribe(data => {
      if (data) {
        this.auctionHouseDto = data;
      }
    })
  }

  ngAfterViewInit(): void {
    this.loadAuctionReportWrapper();
  }

  closeModal() {
    this.activeModal.close();
  }

  loadAuctionReportWrapper() {
    this.isLoading$.next(true);
    this.adminDashboardService.getAuctionReportWrapper(this.auctionId!).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading$.next(false);
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.auctionReportWrapper = apiResponseDto.data as AuctionWrapperReportDto;
          this.auctionReportWrapper?.lotWrapperReportDtoList?.sort((a, b) => Number(a.lotIndex) - Number(b.lotIndex));
          this.auctionReportWrapper.lotWrapperReportDtoList?.forEach(element => {
            this.loadChartData(element);
          });
        }
      },
      error: (error) => {
        this.isLoading$.next(false);
        console.error(error);
      }
    })
  }

  generatePdf() {
    this.isShowPdfView$.next(true);
    this.isLoading$.next(true);
    this.changeDetectRef.detectChanges();
  }

  async pdfExport() {
    const element = document.getElementById('pdfContent');
    const secondaryColor = this.auctionHouseDto?.secondaryColor ?? '#1b5887';

    if (element) {
      let opt = {
        // margin: [25, 12, 30, 12],
        filename: this.auctionId + '.pdf',
        html2canvas: { scale: 4 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
      };

      html2pdf().from(element).set(opt).set({margin: [25, 12, 25, 12]}).toPdf().get('pdf').then((pdf: any) => {
        const totalPages = pdf.internal.getNumberOfPages();
      
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);

          pdf.setDrawColor(secondaryColor);
          pdf.setLineWidth(1);
          pdf.line(20, pdf.internal.pageSize.getHeight() - 25, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 25);

          pdf.setFontSize(9);
          pdf.setTextColor('#000000');

          let text = `${this.auctionReportWrapper?.companyName} | Page ${i}`;
          let textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          let textOffset = (pdf.internal.pageSize.getWidth() - textWidth) / 2;

          pdf.text(text, textOffset, pdf.internal.pageSize.getHeight() - 10);
        }

        this.isLoading$.next(false);
        this.isShowPdfView$.next(false);
        this.changeDetectRef.detectChanges();
      }).save();
    }
  }

  getFormattedPrice(currency: Currency, price: any) {
    return currency.symbol + ApplicationUtils.getFormattedPrice(currency.locale, price);
  }

  getReductionOrIncreaseMsg(auctionMethodType: string) {
    if (auctionMethodType == 'REVERSE_AUCTION') {
      return 'Reduction';
    } else { return 'Increase'; }
  }

  downloadAuctionReportData() {
    this.isLoading$.next(true);
    this.adminDashboardService.downloadAuctionReport(this.auctionId!).subscribe(data => {
      this.isLoading$.next(false);
      if (data) {
        let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
  }

  loadChartData(lotWrapperReportDto: LotWrapperReportDto) {
    let biddingData = lotWrapperReportDto.biddingWrapperDtoList ?? []
    biddingData.sort((a, b) => new Date(a.bidDateAndTime || '').getTime() - new Date(b.bidDateAndTime || '').getTime());

    // Group data by company Name
    const groupedData = biddingData.reduce((acc, entry) => {
      const companyName = entry.companyName || '';
      if (!acc[companyName]) {
        acc[companyName] = { bidDateAndTime: [], bidValue: [] };
      }
      acc[companyName].bidDateAndTime.push(entry.bidDateAndTime || '');
      acc[companyName].bidValue.push(entry.bidValue || 0);
      return acc;
    }, {} as { [companyName: string]: { bidDateAndTime: string[]; bidValue: number[] } });

    const companyNames = Object.keys(groupedData);

    // Create series dynamically based on paddleNo
    const series = companyNames.map((companyName) => ({
      name: `${companyName}`,
      type: 'line',
      data: groupedData[companyName].bidValue.map((value, index) => [groupedData[companyName].bidDateAndTime[index], value]),
    }));

    this.chartOption[lotWrapperReportDto.lotIndex!] = {
      legend: {},
      xAxis: {
        type: 'category',
        data: biddingData.map(item => item.bidDateAndTime),
      },
      yAxis: {
        type: 'value',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
      },
      series: series,
    }
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngOnDestroy(): void {
    if (this.auctionReportWrapperSubscription$) {
      this.auctionReportWrapperSubscription$.unsubscribe();
    }
    if (this.auctionHouseDtoSubscription$) {
      this.auctionHouseDtoSubscription$.unsubscribe();
    }
  }
}
