export class BidIncrementalDto {
  upToAmount?: number;
  incrementValue?: number;

  static parseBidIncrementalDto(data: any): BidIncrementalDto {
    let bidIncrementalDto = new BidIncrementalDto();
    bidIncrementalDto.upToAmount = Number(data.upToAmount);
    bidIncrementalDto.incrementValue = Number(data.incrementValue);
    return bidIncrementalDto;
  }
}
