import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { OrgOrganogramUiDto } from "../../models/OrgOrganogramUiDto";
import { OrganizationWrapperUiDto } from "../../models/OrganizationWrapperUiDto";

export const OnboardingActions = createActionGroup({
  source: 'Onboarding',
  events: {
    'Save Org Organogram': props<{ orgOrganogramUiDto: OrgOrganogramUiDto }>(),
    'Update Organization Ui Dto': props<{ organizationUiDto: OrganizationWrapperUiDto }>(),
    'Get Org Organogram': emptyProps(),
    'Clear Org Organogram': emptyProps(),
  }
})
