import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';
import { UserUiDto } from '../models/user/UserUiDto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ErrorService } from './error.service';
import { SuperAdminDataUIWrapperDto } from '../models/user/SuperAdminDataUIWrapperDto';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { AddUserDto } from '../models/user/AddAdminDto';
import { EmailTemplateDto } from '../models/user/EmailTemplateDto';
import { OrganizationWrapperUiDto } from '../models/OrganizationWrapperUiDto';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminDashboardService {

  _selectedOrganization$ = new BehaviorSubject<OrganizationWrapperUiDto | null>(null);
  _superAdminDashboardData$ = new BehaviorSubject<SuperAdminDataUIWrapperDto | null>(null);
  _emailTemplatesList$ = new BehaviorSubject<EmailTemplateDto[]>([]);

  get getSuperAdminDashboardData$() { return this._superAdminDashboardData$; };
  get getEmailTemplatesList$() { return this._emailTemplatesList$; };
  get getSelectedOrganization$() { return this._selectedOrganization$; };

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
  ) { }

  // LOAD DATA
  loadSuperAdminDashboardData() {
    this._getSuperAdminDashboardData().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let _superAdminDashboardData = apiResponseDto.data as SuperAdminDataUIWrapperDto;
          this._superAdminDashboardData$.next(_superAdminDashboardData);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  updateOrganizationList(organization: OrganizationWrapperUiDto, isEdit: boolean) {
    let superAdminDashboardData = this._superAdminDashboardData$.value;

    if (isEdit) {
      let index = superAdminDashboardData?.organizationList?.findIndex(item => item.orgCode == organization.orgCode);
      if (index! > -1) {
        superAdminDashboardData?.organizationList?.splice(index!, 1);
        superAdminDashboardData?.organizationList?.push(organization);
      }
    } else {
      superAdminDashboardData?.organizationList?.push(organization);
    }

    this._superAdminDashboardData$.next(superAdminDashboardData);
  }

  setSelectedOrganization(organization: OrganizationWrapperUiDto) {
    this._selectedOrganization$.next(organization);
  }

  updateEmailTemplates(emailTemplates: Array<EmailTemplateDto>) {
    this._emailTemplatesList$.next(emailTemplates);
  }

  updateEmailTemplate(emailTemplate: EmailTemplateDto) {
    let emailTemplates = this._emailTemplatesList$.value;
    if(emailTemplate){
      let oldemailTemplate = emailTemplates.find(item => item.templateId == emailTemplate.templateId);
      if(oldemailTemplate){
        Object.assign(oldemailTemplate, emailTemplate)
      }else{
        emailTemplates.push(emailTemplate);
      }
    }
    this._emailTemplatesList$.next(emailTemplates);
  }

  loadEmailTemplateData(orgCode: string) {
    this.getEmailTemplates(orgCode).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let emailTemplateList = apiResponseDto.data as EmailTemplateDto[];
          this.updateEmailTemplates(emailTemplateList);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }


  /// API CALLS
  saveOrganizationUiDto(organizationUiDto: OrganizationWrapperUiDto): Observable<ServerAPIResponseDto> {
    return this.httpClient.post<ServerAPIResponseDto>(`organizations`, organizationUiDto).pipe(
      tap(_ => console.log("Save organizations")),
      catchError(this.errorService.handleError<any>("Error while saving organizations")));
  }

  _getSuperAdminDashboardData() {
    return this.httpClient.get<ServerAPIResponseDto>('superAdminDashboardData').pipe(
      tap(_ => console.log("Getting Super Admin Dashboard Data")),
      catchError(this.errorService.handleError<any>("Error while getting superAdminDashboardData")))
  }

  getEmailTemplates(orgCode: string) {
    let params = new HttpParams().set('orgCode', orgCode);
    return this.httpClient.get<ServerAPIResponseDto>('emailTemplates',{params}).pipe(
      tap(_ => console.log("Getting Email templates data")),
      catchError(this.errorService.handleError<any>("Error while getting Email templates data")))
  }

  saveEmailTemplate(emailTemplate: EmailTemplateDto): Observable<ServerAPIResponseDto> {
    return this.httpClient.post<ServerAPIResponseDto>(`emailTemplates`, emailTemplate).pipe(
      tap(_ => console.log("Save Email template")),
      catchError(this.errorService.handleError<any>("Error while saving Email template")));
  }

  addSuperAdmin(addAdminDto: AddUserDto): Observable<any> {
    return this.httpClient.post<ServerAPIResponseDto>('addUser', addAdminDto).pipe(
      tap(_ => console.log("Super Admin Add Successfully : " + addAdminDto.emailId)),
      catchError(this.errorService.handleError("Error while adding super admin : " + addAdminDto.emailId)))
  }

  markResourceActiveOrInActive(resourceId: string, active: boolean, actionName: string) {
    let params = new HttpParams().set('resourceId', resourceId).set('active', active).set('actionName', actionName);
    return this.httpClient.post<ServerAPIResponseDto>('markResourceActiveOrInActive', null, { params }).pipe(
      tap(_ => console.log("User active status change" + resourceId)),
      catchError(this.errorService.handleError<any>("Error while changing user active status" + resourceId)))
  }

}
