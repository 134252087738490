import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';
import { EntityActionLabelEnum } from 'src/app/shared/enums/EntityActionLabelEnum';
import { EntityActionTypeEnum } from 'src/app/shared/enums/EntityActionTypeEnum';
import { EntityActionDto } from 'src/app/shared/models/EntityActionDto';
import { FileInfoDto } from 'src/app/shared/models/FileInfoDto';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { TenderDocumentInfoDto } from 'src/app/shared/models/TenderDocumentInfoDto';
import { TenderWrapperUiDto } from 'src/app/shared/models/user/TenderWrapperUiDto';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { TenderManagementService } from 'src/app/shared/services/tender-management.service';
import { selectOrganizationUiDto } from 'src/app/shared/state-management/onboarding/onboarding.features';
import { TenderManagementActions } from 'src/app/shared/state-management/tender/tender.actions';
import { selectEntityActionDtos, selectTenderWrapperUiDto } from 'src/app/shared/state-management/tender/tender.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-new-tender-drawer',
  templateUrl: './new-tender-drawer.component.html',
  styleUrls: ['./new-tender-drawer.component.sass']
})
export class NewTenderDrawerComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  docTitle: FormControl = new FormControl('', [Validators.required, Validators.maxLength(250)]);
  docDescription: FormControl = new FormControl('', [Validators.required, Validators.maxLength(1000)]);
  docFile: FormControl = new FormControl('', Validators.required);

  nitDocuments: Array<TenderDocumentInfoDto> = []
  currentFileInfoDto?: FileInfoDto | null;
  organizationUiDto?: OrganizationWrapperUiDto;
  tenderWrapperUiDto?: TenderWrapperUiDto;

  isLoading: boolean = false;
  isLoadingModal: boolean = false;
  isShowNitDocumentDialog: boolean = false;
  currentFile?: File | null;
  errorMsg: string | undefined;
  documentTypes = ['application/pdf'];

  newResourceId: string = UUID.UUID().toString();

  _showSuccessToast$ = new BehaviorSubject<boolean>(false);
  _showErrorToast$ = new BehaviorSubject<boolean>(false);
  _showSuccessToastModal$ = new BehaviorSubject<boolean>(false);
  _showErrorToastModal$ = new BehaviorSubject<boolean>(false);

  organizationUiDto$: Observable<OrganizationWrapperUiDto | null>;

  tenderWrapperUiDtoSubscription$?: Subscription;
  organizationSubscription$?: Subscription;

  stateOptions: any[] = [
    { label: 'Single', value: 'SINGLE' },
    { label: 'Multiple', value: 'MULTIPLE' }
  ];

  @ViewChild('documentDoc') documentDoc?: ElementRef<HTMLInputElement>;

  get fc() { return this.formGroup.controls; }

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private drawerService: DrawerService,
    private tenderManagementService: TenderManagementService,
    private messageService: MessageService
  ) {
    this.formGroup = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(250)]],
      tenderReferenceNo: ['', [Validators.required, Validators.maxLength(250)]],
      description: ['', [Validators.required, Validators.maxLength(1000)]],
      opportunityType : ['SINGLE', Validators.required]
    });
    this.organizationUiDto$ = this.store.pipe(select(selectOrganizationUiDto));
  }

  ngOnInit() {
    this.organizationSubscription$ = this.organizationUiDto$.subscribe(data => {
      if (data) {
        this.organizationUiDto = ApplicationUtils.deepClone(data);
      }
    })

    this.tenderWrapperUiDtoSubscription$ = this.store.pipe(select(selectTenderWrapperUiDto)).subscribe(data => {
      if (data) {
        this.tenderWrapperUiDto = ApplicationUtils.deepClone(data);
        this.populateFormGroup();
      } else {
        this.tenderWrapperUiDto = undefined;
      }
    })

    this.store.dispatch(TenderManagementActions.clearEntityActionDtos());
  }

  populateFormGroup() {
    if (this.tenderWrapperUiDto) {
      this.formGroup.patchValue({
        title: this.tenderWrapperUiDto.title,
        tenderReferenceNo: this.tenderWrapperUiDto.tenderReferenceNo,
        description: this.tenderWrapperUiDto.description,
        opportunityType: this.tenderWrapperUiDto.opportunityType ?? 'SINGLE',
      })

      this.newResourceId = this.tenderWrapperUiDto.tenderId!;
      this.nitDocuments = this.tenderWrapperUiDto.nitDocuments || [];

      this.formGroup.updateValueAndValidity();
    }
  }

  openAddNitDocumentDialog() {
    this.docTitle.reset()
    this.docDescription.reset()
    this.docFile.reset()
    
    this.isShowNitDocumentDialog = true
  }

  chooseFile(event: any) {
    this.currentFile = event.target.files[0];

    if (!this.documentTypes.includes(this.currentFile!.type)) {
      
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Extension not supported'});
      this.currentFile = null;
      this.docFile.reset();
      this.documentDoc!.nativeElement.value = '';
      return;
    }

    this.docFile.patchValue(this.getFileName(this.currentFile?.name!) as string);
  }

  getFileName(fileName: string) {
    let extensionName = fileName?.slice(fileName.lastIndexOf('.'), fileName.length)
    let name = fileName?.slice(0, fileName.lastIndexOf('.'));
    let finalFileName = ApplicationUtils.removeSpecialCharacters(name!) as string
    return finalFileName + "" + extensionName;
  }

  removeDoc() {
    this.currentFileInfoDto = null;
    this.currentFile = null;
    this.docFile.reset();
    this.documentDoc!.nativeElement.value = '';
  }

  deleteNitDocument(index: number) {
    this.nitDocuments.splice(index, 1);
  }

  previewRfxDoc(type: string) {
    if (type == 'LOCAL') {
      var fileURL = URL.createObjectURL(this.currentFile!);
      window.open(fileURL, '_blank');

    } else if (type == 'SERVER') {
      this.isLoadingModal = true;

      this.tenderManagementService.downloadTenderDocument(this.currentFileInfoDto?.fileId!).subscribe({
        next: (response) => {
          if (response) {
            this.isLoadingModal = false;
            let file = new Blob([response], { type: this.currentFileInfoDto?.fileType });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_blank');
          }
        },
        error: (err) => {
          console.error(err);
          console.log("Error while previewing document");
        }
      });
    }
  }

  uploadFile() {
    this.isLoadingModal = false;
   

    if (this.docTitle.invalid || this.docDescription.invalid || this.docFile.invalid) {
      this.docTitle.markAllAsTouched();
      this.docDescription.markAllAsTouched();
      this.docFile.markAllAsTouched();
      return;
    }

    let metaData = {
      mimeType: this.currentFile?.type,
      version: 0,
      publicApi: false,
      dataType: 'Square',

      resourceId: this.newResourceId,
      subResourceId: undefined,
      resourceType: 'TENDER',
      digitallySigned: undefined,
      validateDownloadTime: undefined,

      title: this.docTitle.value,
      description: this.docDescription.value,
    };

    let formData = new FormData();
    formData.append("file", this.currentFile!);
    formData.append('metaData', JSON.stringify(metaData));

    this.isLoadingModal = true;

    this.tenderManagementService.uploadTenderDocument(formData).subscribe(apiResponseDto => {
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let tenderDocumentInfoDto = apiResponseDto.data as TenderDocumentInfoDto;

          this.isLoadingModal = false;
          this.currentFile = null;

          if (!this.nitDocuments) {
            this.nitDocuments = [];
          }

          this.nitDocuments.push(tenderDocumentInfoDto);

          this.isShowNitDocumentDialog = false;
        } else {
          this.isLoadingModal = false;

          
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Error while saving Document. Try again.'})
        }
      } else {
        this.isLoadingModal = false;

      
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Error while uploading document. Try again.'})

      }
    })
  }


  async margeTenderWrapperUiDto() {
    let formValue = this.formGroup.getRawValue();

    let tenderWrapperUiDto = new TenderWrapperUiDto();
    if (this.tenderWrapperUiDto) {
      tenderWrapperUiDto = ApplicationUtils.clone(this.tenderWrapperUiDto) as TenderWrapperUiDto;
      this.saveEntityActionDto(tenderWrapperUiDto.tenderId!, EntityActionLabelEnum.TENDER, EntityActionTypeEnum.UPDATE);
    } else {
      tenderWrapperUiDto.tenderId = this.newResourceId;
      this.saveEntityActionDto(tenderWrapperUiDto.tenderId!, EntityActionLabelEnum.TENDER, EntityActionTypeEnum.ADD);
    }

    tenderWrapperUiDto.orgCode = this.organizationUiDto?.orgCode;
    tenderWrapperUiDto.title = formValue.title;
    tenderWrapperUiDto.tenderReferenceNo = formValue.tenderReferenceNo;
    tenderWrapperUiDto.description = formValue.description;
    tenderWrapperUiDto.opportunityType = formValue.opportunityType;
    tenderWrapperUiDto.themeName = this.organizationUiDto?.theme;
    tenderWrapperUiDto.nitDocuments = this.nitDocuments;

    // Final Tender Wrapper Ui Dto
    const entityActionDtos = await firstValueFrom(this.store.pipe(select(selectEntityActionDtos)));
    tenderWrapperUiDto.entityActionDtos = entityActionDtos;
    tenderWrapperUiDto.validationErrorCodeDtoList = [];

    return tenderWrapperUiDto;
  }

  async saveTenderWrapperUiDto() {
   

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    if (this.nitDocuments.length == 0) {
     
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Please upload NIT Documents.'});
      return;
    }

    let tenderWrapperUiDto = await this.margeTenderWrapperUiDto();
    console.log(tenderWrapperUiDto);

    this.isLoading = true;

    try {
      const response = await firstValueFrom(this.tenderManagementService.saveTenderWrapperUiDto(tenderWrapperUiDto));
      if (response.code === ApplicationConstants.SUCCESS_CODE) {

        await this.tenderManagementService.loadTenderSourcingEvents();

        this.isLoading = false;
        
        this.messageService.add({ severity:'success', summary: 'Success', detail: 'Document saved successfully.' });

        setTimeout(() => {
          

          const tenderWrapperUiDto = response.data as TenderWrapperUiDto;
          this.store.dispatch(TenderManagementActions.setCurrentTenderWrapperUiDto({ tenderWrapperUiDto }));
          this.store.dispatch(TenderManagementActions.clearEntityActionDtos());

          this.drawerService.openDrawer(ApplicationConstants.NEW_OPPORTUNITY_PAGE, 'BASIC');
        }, 2000);
      } else {
        this.isLoading = false;
      
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: response.message });
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;

      
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Error while saving Tender Opportunity. Try again.' });
    }
  }

  closeDrawer() {
    this.formGroup.reset();
    this.nitDocuments = [];
    this.drawerService.closeDrawer();
  }

  // Save Entity Actions
  saveEntityActionDto(id: string, label: EntityActionLabelEnum, type: EntityActionTypeEnum) {
    let entityActionDto = new EntityActionDto(id, label, type);
    this.store.dispatch(TenderManagementActions.saveEntityActionDto({ entityActionDto }));
  }

  ngOnDestroy(): void {
    if (this.tenderWrapperUiDtoSubscription$) {
      this.tenderWrapperUiDtoSubscription$.unsubscribe();
    }
  }
}