<div class="col-12 mt-5">
  <div class="row px-2">
    <div class="col-9 mb-2">
      <h5 class="heading-h5 text-behind-svg-icon">ADMIN</h5>

      <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 inline-flex  ms-2 mt-2" (click)="openAddModal(addAdminModal)">
        <img src="assets/icons/addnew_dark.svg" class=" c-pointer svg-icon-small">ADD</button>

    </div>
    <div class="col-md-3 text-end">
      <div class="mb-2  input-group">
        <input #search placeholder="Search" type="text" class="form-control border-end-0 rounded-0 border bg-white"
          [formControl]="ctrlSearchAdmin" id="ctrlSearchAdmin"/>
        <span class="input-group-append">
          <button class="btn btn-outline-secondary  border-start-0 rounded-0 border ms-n5" type="button" (click)="searchAdminData()">
            <span> <img src="assets/icons/search_light.svg" class=" c-pointer svg-icon-class ms-2"></span>
          </button>
        </span>
      </div>
    </div>
  </div>
  <div class="col-12 p-2" *ngIf="_filteredUsers$|async">
      <div class="table-responsive">
        <table class="table table-bordered medium-font mb-0">
          <thead class="table-dark">
            <tr class="text-center">
              <th class=""><span >SNo</span></th>
              <th class=""><span >Profile</span></th>
              <th class=""><span >Name</span></th>
              <th class=""><span >Status</span></th>
              <th class=""><span >Email</span></th>
              <th class=""><span >Registration Time</span></th>
              <th class=""><span >Action</span></th>
            </tr>
          </thead>
    
          <tbody class="bg-white">
            <tr *ngIf="isLoading" class="align-middle">
              <td colspan="9" class="text-center">
                <div class="e-spinner"></div>
              </td>
            </tr>
    
            <tr class="text-center align-middle" *ngFor="let admin of _filteredUsers$|async ; index as i">
              <td class="" >
                {{i+1}}
              </td>
              <td class="">
                <img *ngIf="admin?.profileImage" src="/downloadLandingBlob?fileId={{admin?.profileImage?.fileId}}" alt=""
                class="profile-image-md mt-0">
              <img *ngIf="!admin?.profileImage" src="assets/images/avatar05.jpg" alt="" class="profile-image-md mt-0">
              </td>
              <td class="">
                {{admin?.firstName}} {{admin?.lastName}}
              </td>
              <td class="">
                <div >
                  <!-- <span>
                    <span class="form-switch">
                      <input #toggleRefAdmin class="form-check-input c-pointer" type="checkbox"
                        id="flexSwitchCheckChecked"
                        (click)="openDisableUserModal(disableAdminTemplate, admin, 'ADMIN', toggleRefAdmin)"
                        [checked]="admin!.active" [disabled]="userEntityDto?.userId == admin.userId" />
                    </span>
                  </span> -->
                  <span class=" p-0">
                    <a class=" ms-2 link-class"   (click)="openDisableUserModal(disableAdminTemplate, admin, 'ADMIN')">{{admin?.active ? "Active" : "InActive"}}</a>
                  </span>
  
                </div>
              </td>
              <td class="">
                {{admin?.primaryEmailId}}
              </td>
              <td class="">
                <!-- Member since {{getMemberSinceYear(admin?.registrationTime!)}} -->
              </td>
             
              <td>
                <div class="animate-dropdown" ngbDropdown #menuDropdown="ngbDropdown" container="body"
                placement="bottom-right">
                <span ngbDropdownToggle data-bs-toggle="dropdown">
                  <img src="assets/icons/elipsis_vertical_light.svg" class=" c-pointer svg-icon-class-small">
                </span>
                <ul ngbDropdownMenu class="shadow mt-1 ">
                  <li ngbDropdownItem class="c-pointer" (click)="openUserActivityModal(admin.userId!)">
                    <a>
                      <img src="assets/icons/user-activity.svg" class=" c-pointer svg-icon-class-md"> User Activity
                    </a>
                  </li>
                </ul>
              </div>
              </td>
             
            </tr>
          </tbody>
        </table>
      </div>
   
    <!-- <div class="row">
      <div class="col-md-6 col-lg-4 mb-2" *ngFor="let admin of _filteredUsers$|async">
        <div class="card p-2 bg-white hover-class border">
          <div class="row">
            <div class="col-3">
              <img *ngIf="admin?.profileImage" src="/downloadLandingBlob?fileId={{admin?.profileImage?.fileId}}" alt=""
                class="profile-image-large">
              <img *ngIf="!admin?.profileImage" src="assets/images/avatar05.jpg" alt="" class="profile-image-large">
            </div>
            <div class="col-7">
              <h5 class="heading-h5">{{admin?.firstName}} {{admin?.lastName}}</h5>
              <div class="row">
                <div class="col-3">
                  <div class="form-switch">
                    <input #toggleRefAdmin class="form-check-input c-pointer" type="checkbox"
                      id="flexSwitchCheckChecked"
                      (click)="openDisableUserModal(disableAdminTemplate, admin, 'ADMIN', toggleRefAdmin)"
                      [checked]="admin!.active" [disabled]="userEntityDto?.userId == admin.userId" />
                  </div>
                </div>
                <div class="col-4 p-0">
                  <b class="b-class ms-2">{{admin?.active ? "Active" : "InActive"}}</b>
                </div>

              </div>
              <div class="div-class">{{admin?.primaryEmailId}}</div>
              <div class="div-class">Member since {{getMemberSinceYear(admin?.registrationTime!)}}</div>
            </div>
            <div class="col-2 text-end z-index-class">
              <div class="animate-dropdown" ngbDropdown #menuDropdown="ngbDropdown" container="body"
                placement="bottom-right">
                <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800-small" ngbDropdownToggle data-bs-toggle="dropdown">
                  <img src="assets/icons/elipsis_vertical_dark.svg" class=" c-pointer svg-icon-class-small">
                </button>
                <ul ngbDropdownMenu class="shadow mt-1 ">
                  <li ngbDropdownItem class="c-pointer" (click)="openUserActivityModal(admin.userId!)">
                    <a>
                      <img src="assets/icons/user-activity.svg" class=" c-pointer svg-icon-class-md"> User Activity
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <h5 class="col-12 p-2 text-center heading-new"
    *ngIf="!(adminUIWrapper?.adminList) || adminUIWrapper?.adminList && (adminUIWrapper?.adminList!.length == 0)">
    No Admin created yet, please create new</h5>
</div>



<ng-template #addAdminModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>ADD ADMIN</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()">
          <div class="row">
          <div class="col-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="firstName" placeholder="First Name"
                formControlName="firstName"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.firstName.invalid}">
              <label for="firstName">First Name</label>
              <div
                *ngIf="fc.firstName.invalid && (_validationErrorPresent$ |async) ||fc.firstName.invalid && (fc.firstName.dirty || fc.firstName.touched)">
                <p class="text-red-500 " *ngIf="fc.firstName.errors?.required">Required
                </p>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="lastName" placeholder="Last Name"
                formControlName="lastName"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.lastName.invalid}">
              <label for="lastName">Last Name</label>
              <div
                *ngIf="fc.lastName.invalid &&  (_validationErrorPresent$ |async) || fc.lastName.invalid && (fc.lastName.dirty || fc.lastName.touched)">
                <p class="text-red-500 " *ngIf="fc.lastName.errors?.required">Required
                </p>
              </div>
            </div>
          </div>
        </div>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="emailId" placeholder="name@example.com"
              formControlName="emailId"
              [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.emailId.invalid}">
            <label for="emailId">Username(email)</label>
            <div *ngIf="fc.emailId.invalid && (fc.emailId.dirty || fc.emailId.touched)">
              <p class="text-red-500 " *ngIf="fc.emailId.errors?.required">Required</p>
              <p class="text-red-500 " *ngIf="fc.emailId.errors?.pattern">Invalid email address
              </p>
              <p class="text-red-500 " *ngIf="fc.emailId.value && fc.emailId.value.length > 50">
                Maximum Length
                Exceed</p>
            </div>
          </div>
          <div class="mb-3">New user shall receive email confirming account creation on this email</div>
          <div class="input-group">
            <div class="form-floating">
              <input [type]="isPasswordVisible ? 'text' : 'password'" class="form-control border-end-0"
                id="password" placeholder=" Password" autocomplete="new-password" formControlName="password"
                (keyup)="onChange()" (change)="onChange()"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.password.invalid}">
              <label for="password">Password</label>
            </div>
            <span class="input-group-text bg-white border-start-0 " (click)="togglePasswordVisibility()">
              <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer" *ngIf="!isPasswordVisible">
              <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer" *ngIf="isPasswordVisible">
            </span>
          </div>


          <div *ngIf="fc.password.invalid && (fc.password.dirty || fc.password.touched)">
            <p class="text-red-500 " *ngIf="fc.password.invalid && (!fc.password.value)">
              Required</p>
            <p class="text-red-500 " *ngIf="fc.password.invalid &&  (fc.password.value)">
              Password must be more than 8 characters, containing at least one capital letter, one lowercase letter,
              one number and one special character.</p>
          </div>
          <div class="input-group mt-3">
            <div class="form-floating">
              <input [type]="isRepeatPasswordVisible ? 'text' : 'password'" class="form-control border-end-0"
                id="confirmPassword" placeholder="Confirm Password" autocomplete="new-password"
                formControlName="confirmPassword" (keyup)="onChange()" (change)="onChange()"
                [ngClass]="{'is-invalid': (_validationErrorPresent$ |async) && fc.confirmPassword.invalid}">
              <label for="confirmPassword">Confirm Password</label>
            </div>
            <span class="input-group-text bg-white border-start-0" (click)="toggleRepeatPasswordVisibility()">
              <img src="assets/icons/visible_light.svg" class="svg-icon-class c-pointer"
                *ngIf="!isRepeatPasswordVisible">
              <img src="assets/icons/invisible_light.svg" class="svg-icon-class c-pointer"
                *ngIf="isRepeatPasswordVisible">
            </span>
          </div>
          <div class="mb-3">
            <p class="text-red-500 "
              *ngIf="fc.confirmPassword.invalid && (fc.confirmPassword.dirty || fc.confirmPassword.touched)">Required
            </p>
            <p class="text-red-500 " *ngIf="!fc.confirmPassword.invalid && !(_isPasswordMatch$ | async)">
              Password Not Match
            </p>
          </div>
          <div class="mb-3">You must provide password separately to user as same is not shared over email</div>

          <div class="mb-3">
            <app-message-toast *ngIf="_showSuccessToast$ | async"
              [message]="'User is added successfully.'"></app-message-toast>
            <app-message-toast *ngIf="(_showErrorToast$ | async)" [isError]="true"
              [message]="_showErrorMsg$ | async"></app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner" *ngIf="isLoading"></div>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " [disabled]="!(_isSaveButtonEnable$ | async)"
              (click)="handleValidSubmit()">ADD</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #disableAdminTemplate>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>{{userActiveFlag ? "DISABLE" : "ENABLE"}} / USER</h2>
        </div>
        <div class="col-auto text-end" [ngClass]="{'disabled': isLoading}">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" (click)="closeModal()"
            ngbAutoFocus>
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-2">
        <div class="mb-2">
          <p *ngIf="actionName == 'ADMIN' && !userActiveFlag">Admin will be able to enter in the system</p>
          <p *ngIf="actionName == 'ADMIN' && userActiveFlag">Admin will not be able to enter in the system</p>
          <p *ngIf="actionName == 'AGENT' && !userActiveFlag">Auctioneer will be able to enter in the system</p>
          <p *ngIf="actionName == 'AGENT' && userActiveFlag">Auctioneer will not be able to enter in the system</p>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner" *ngIf="isLoading"></div>
        </div>

        <div class="text-end mb-3" *ngIf="!isLoading">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal()">CANCEL</button>
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="markResourceActiveOrInActive('ENABLE')"
            *ngIf="!userActiveFlag">ENABLE</button>
          <button type="button" class="btn btn-sm btn-outline-primary "
            (click)="markResourceActiveOrInActive('DISABLE')" *ngIf="userActiveFlag">DISABLE</button>
        </div>
      </div>
    </div>


  </div>
</ng-template>