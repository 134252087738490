<div class="me-14 xl:me-0">
  <div class="flex flex-row items-center justify-between h-20 bg-gray-900 px-2">
    <div class="">
      <div class="inline-flex">
        <span class="h-12 w-12 p-3  items-center justify-between bg-gray-100 rounded-lg">
          <i-lucide name="gavel" color="#0555F5" class="my-icon"></i-lucide>
        </span>
      </div>
      <div class="inline-block ms-2">
        <div class="font-semibold text-gray-200 text-2xl">
          Tender management
        </div>
        <div class="text-gray-200 text-xs text-normal">
          Manage ALL Tender, EOI, PQ, Auction here
        </div>
      </div>
    </div>
    <div class="">

      <!-- <button
        class="flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        (click)="addNewTender($event)">
        <i-lucide name="plus" color="#fff" [size]="18" class="my-icon"></i-lucide> Create new
      </button> -->
      <span class="">
        <p-menu #menu [model]="createNewMenuItems" [popup]="true">
          <ng-template pTemplate="item" let-item>
            <div class="p-menuitem-link p-3 grid" (click)="addNewTender()">
              <div class="flex-row flex">
                <div class="basis-2/12">
                  <img src="assets/icons/tender_bid.svg" />
                </div>
                <div class="basis-10/12">
                  <div class="text-gray-900 font-semibold text-sm">
                    {{item.title}}
                  </div>
                  <div class="text-sm text-gray-800 font-normal">
                    {{item.subtitle}}
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </p-menu>
        <p-button label="Create new"
        class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-blue-800"
        (onClick)="menu.toggle($event)"><i-lucide name="plus" color="#fff" [size]="18" class="my-icon me-1"></i-lucide></p-button>
        <!-- <button
          class="flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          (click)="menu.toggle($event)">
          <i-lucide name="plus" color="#fff" [size]="18" class="my-icon"></i-lucide> Create new
        </button> -->
      </span>
    </div>
  </div>
  <div class="">
    <div class="flex flex-row h-full">
      <div class="w-11  py-6 px-2 bg-gray-300 border-e border-blue-100 h-screen"
        [ngClass]="{'hidden': isShowFilterSidebar}">
        <span class="inline-flex h-6 w-6 gap-2.5 p-1 items-center justify-between bg-blue-100 rounded-full cursor-pointer"
          (click)="toggleFilterSidebar()">
          <i-lucide name="arrow-right-to-line" [size]="18" class="my-icon"></i-lucide>
        </span>
        <div class="tr-vertical-text tr-margin-top-vrtical-txt mt-3 text-black">
          Filters
        </div>
      </div>
      <div class="w-48 xl:w-64  py-6 px-4 border-e border-blue-100" [ngClass]="{'hidden': !isShowFilterSidebar}">
        <div class="grid grid-cols-2">
          <div class=" text-black font-semibold text-base">
            Filter
          </div>
          <div class="text-end">
            <span
              class="inline-flex h-6 w-6 gap-2.5 p-1 items-center justify-center bg-gray-100 text-gray-700 rounded-full cursor-pointer"
              (click)="toggleFilterSidebar()">
              <i-lucide name="arrow-left-to-line" [size]="18" class="my-icon"></i-lucide>
            </span>
          </div>
        </div>

        <div class="mt-3">
          <div class=" text-black font-semibold text-sm mb-2">
            By Tender Status
          </div>
          <div class="border border-blue-100 rounded-lg py-2 ps-2 gap-28">
            <div class="flex flex-row">
              <div class="basis-11/12">
                <ul style="list-style: none;">
                  <li class="mb-2 flex" *ngFor="let status of statusFilters;">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded"
                      (change)="onFilterChange('status', status, $event)"
                      [checked]="(filters.status.length == 0) && status == 'ALL' ? true : filters.status.includes(status)"
                      [disabled]="(filters.status.length == 0) && status == 'ALL'" />
                    <label for="" class="leading-6 text-sm font-medium ms-3">{{status | titlecase}}</label>
                  </li>
                  <!-- <li class="mb-2 flex">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded"
                      (change)="onFilterChange('status', 'DRAFT', $event)" />
                    <label for="" class="leading-6 text-sm font-medium ms-3">Draft</label>
                  </li>
                  <li class="mb-2 flex">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded"
                      (change)="onFilterChange('status', 'SCHEDULED', $event)">
                    <label for="" class="leading-6 text-sm font-medium ms-3">Scheduled</label>
                  </li>
                  <li class="mb-2 flex">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded"
                      (change)="onFilterChange('status', 'PUBLISH', $event)">
                    <label for="" class="leading-6 text-sm font-medium ms-3">Publish</label>
                  </li>
                  <li class=" flex">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded"
                      (change)="onFilterChange('status', 'AWARDED', $event)">
                    <label for="" class="leading-6 text-sm font-medium ms-3">Awarded</label>
                  </li> -->
                </ul>



              </div>
              <div class="basis-1/12 text-end ">
                <div class="w-1.5 bg-blue-100 rounded-lg h-full">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class=" text-black font-semibold text-sm mb-2">
            By Tender type
          </div>
          <div class="border border-blue-100 rounded-lg py-2 ps-2 gap-28">
            <div class="flex flex-row">
              <div class="basis-11/12">
                <ul style="list-style: none;">
                  <li class="mb-2 flex" *ngFor="let type of typeFilters;">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded"
                      (change)="onFilterChange('type', type, $event)"
                      [checked]="!filters.type.length && type == 'ALL' ? true : filters.type.includes(type)"
                      [disabled]="(filters.type.length == 0) && type == 'ALL'" >
                    <label for="" class="leading-6 text-sm font-medium ms-3">{{type | titlecase}}</label>
                  </li>
                  <!-- <li class="mb-2 flex">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded"
                      (change)="onFilterChange('type', 'TENDER', $event)">
                    <label for="" class="leading-6 text-sm font-medium ms-3">Tender</label>
                  </li>
                  <li class="mb-2 flex">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded"
                      (change)="onFilterChange('type', 'EOI', $event)">
                    <label for="" class="leading-6 text-sm font-medium ms-3">EOI</label>
                  </li>
                  <li class="mb-2 flex">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded"
                      (change)="onFilterChange('type', 'PQ', $event)">
                    <label for="" class="leading-6 text-sm font-medium ms-3">Pre-qualification</label>
                  </li>
                  <li class=" flex">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded"
                      (change)="onFilterChange('type', 'AUCTION', $event)">
                    <label for="" class="leading-6 text-sm font-medium ms-3">Auction</label>
                  </li> -->
                </ul>
              </div>
              <div class="basis-1/12 text-end ">
                <div class="w-1.5 bg-blue-100 rounded-lg h-full">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class=" text-black font-semibold text-sm mb-2">
            By Creator
          </div>
          <div class="border border-blue-100 rounded-lg py-2 ps-2 gap-28">
            <div class="flex flex-row">
              <div class="basis-11/12">
                <ul style="list-style: none;">
                  <li class="mb-2 flex" *ngFor="let createdBy of creatorFilters;">
                    <input type="checkbox" class="w-5 h-5 border border-blue-100 rounded" name="" id=""
                      [checked]="isCreatorSelected(createdBy)" (change)="onCreatorFilterChange(createdBy, $event)">
                    <label for="" class="leading-6 text-sm font-medium ms-3">
                      <span
                        class="inline-flex h-6 w-6 gap-2.5 p-1 items-center justify-between bg-blue-100 text-blue-500 rounded-full me-2">
                        <img src="/downloadLandingBlob?fileId={{createdBy?.profileImage?.fileId}}"
                          *ngIf="createdBy?.profileImage">
                        <span class="text-xs" *ngIf="!createdBy?.profileImage">
                          {{(createdBy?.firstName + ' ' + createdBy?.lastName) | textAvatar}}
                        </span>
                      </span>
                      {{createdBy?.firstName}} {{createdBy?.lastName}}
                    </label>
                  </li>
                </ul>
              </div>
              <div class="basis-1/12 text-end ">
                <div class="w-1.5 bg-blue-100 rounded-lg h-full">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" w-[calc(100%-12rem)] xl:w-full  p-2 ">
        <div class="flex flex-row gap-4 w-full items-center justify-end">
          <!-- <div class="">
            <span class="text-black text-sm font-normal me-2">Showing results for:</span>
            <span class="text-black text-sm font-semibold underline  me-3">4 drafts</span>
            <span class="me-3">
              <button type="button"
                class="inline-flex py-1.5 px-3 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-gray-800 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                <i-lucide name="x" [size]="18" color="#9CA3AF" class="my-icon me-1"></i-lucide> Clear Filter</button>
            </span>
            <span class="me-3">
              <button type="button"
                class="inline-flex text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-600 font-medium rounded-lg text-sm px-5 py-1.5 dark:bg-white dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                <i-lucide name="filter" [size]="18" color="#fff" class="my-icon me-1"></i-lucide> Add Filter
              </button>
            </span>
          </div> -->
          <div class="w-80 ">
            <div class="flex w-full">
              <div class="input-group-append">
                <span class="input-group-text border-solid border-gray-300 border-r-0 rounded-l-lg h-8 bg-white"
                  id="basic-addon2">
                  <i-lucide name="search" [size]="16" class="my-icon c-pointer"></i-lucide>
                </span>
              </div>
              <input
                class="bg-white w-full border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-gray-300 h-8 px-2 py-0 text-gray-800  border-end-0"
                #search id="ctrlSearch" placeholder="Search by Tender Ref Number" [formControl]="ctrlSearch">
            </div>
            <!-- <div class="flex w-full ">
              <div class="input-group-append">
                <span class="input-group-text border-solid border-gray-200 border-r-0 rounded-l-lg h-8 bg-white"
                  id="basic-addon2">
                  <i-lucide name="search" [size]="16" color="#9CA3AF" class="my-icon c-pointer"></i-lucide>
                </span>
              </div>
              <input
                class="bg-white w-full text-blue-500 border-l-0 flex-col self-stretch rounded-r-lg border border-solid border-gray-200 h-8 px-2 py-0 placeholder-gray-500"
                [formControl]="ctrlSearch" id="ctrlSearch" #search id="search"
                placeholder="Search by Tender Ref Number">
            </div> -->
          </div>
        </div>

        <div class="grid px-6 py-12 items-center justify-center" *ngIf="filteredEvents.length == 0">
          <img src="assets/images/approval-1.svg" class=" h-16 w-16 mx-auto my-0">
          <div class="flex items-center justify-center">
            <div class="text-xl font-medium text-gray-700  mt-6">
              No data found.
            </div>
          </div>
          <div class="flex items-center justify-center">
            <div class="text-xs font-normal text-gray-500 mt-2 mb-6">
              There is no selected data.
            </div>
          </div>
        </div>

        <div class="mt-2" *ngIf="filteredEvents.length > 0">
          <p-table [customSort]="true" selectionMode="single" [value]="filteredEvents"
            [tableStyle]="{'min-width': '60rem'}" [loading]="isLoading">
            <ng-template pTemplate="header" let-columns>
              <tr class="bg-white">
                <th class=""></th>
                <th pSortableColumn="referenceNo"
                  class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">Tender Reference
                  number <p-sortIcon field="referenceNo" class="float-end"></p-sortIcon></th>
                <th pSortableColumn="title"
                  class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">Title<p-sortIcon
                    field="title" class="float-end"></p-sortIcon></th>
                <th pSortableColumn="type"
                  class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">Tender
                  type<p-sortIcon field="type" class="float-end"></p-sortIcon></th>
                <th pSortableColumn="createdOn"
                  class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
                  Created On
                  <p-sortIcon field="createdOn" class="float-end tr-xs-icon"></p-sortIcon>
                </th>
                <th pSortableColumn="price"
                  class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
                  Created By
                  <p-sortIcon field="quantity" class="float-end tr-xs-icon"></p-sortIcon>
                </th>
                <th pSortableColumn="status"
                  class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">Status
                  <p-sortIcon field="status" class="float-end"></p-sortIcon>
                </th>
                <th class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
                </th>
                <th class="border-e col-span-2 text-black font-normal leading-normal text-sm mb-4">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-i="rowIndex" let-columns="columns">
              <tr>
                <td class="border-e">
                  <div class="font-medium text-sm">
                    {{i+1}}
                  </div>
                </td>
                <td class="font-medium text-sm  underline ">
                  {{item.referenceNo}}
                </td>
                <td class="font-medium text-sm">
                  <div class="overflow-class-small"   [pTooltip]="tooltipProjects" tooltipPosition="top" placeholder="Top">
                    {{item.eventName}}
                    <ng-template #tooltipProjects>
                      <div class="flex items-center p-2">
                        {{item.eventName}}
                      </div>
                    </ng-template>
                  </div>

                </td>
                <td class="font-medium text-sm">
                  <span
                    class="inline-flex py-1 px-3 text-sm font-medium text-gray-700 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-gray-800 focus:z-10 focus:ring-4 focus:ring-gray-100 me-3">
                    {{item.type}}
                  </span>
                </td>
                <td class="font-medium text-sm">
                  {{item.createdOn | ordinalDate}}
                </td>
                <td class="font-medium text-sm">
                  <span for="" class="leading-6 text-sm font-medium ">
                    <span
                      class="inline-flex h-6 w-6 gap-2.5 p-1 items-center justify-center bg-gray-100 text-gray-700 rounded-full me-2">
                      <img src="/downloadLandingBlob?fileId={{item?.createdBy?.profileImage?.fileId}}"
                        *ngIf="item?.createdBy?.profileImage">
                      <span class="text-xs" *ngIf="!item?.createdBy?.profileImage">
                        {{(item?.createdBy?.firstName + ' ' + item?.createdBy?.lastName) | textAvatar}}
                      </span>
                    </span>
                    {{item?.createdBy?.firstName}} {{item?.createdBy?.lastName}}
                  </span>
                </td>
                <td class="font-medium text-sm">
                  <span
                    class="inline-flex py-1 px-3 text-sm font-medium text-gray-700 focus:outline-none bg-gray-200 rounded-full border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-100">
                    <i-lucide name="dot" [size]="22" color="#9CA3AF" class="my-icon"></i-lucide>
                    {{item.status}}
                  </span>
                </td>
                <td>
                  <!-- <span>
                    <p-menu #menu1 [model]="TableMenuItems" [popup]="true">
                      <ng-template pTemplate="item" let-item>
                        <div class="p-menuitem-link p-3 grid" (click)="addNewTender()">
                          <div class="text-gray-900 font-semibold text-sm">
                            {{item.title}}
                          </div>
                        </div>
                      </ng-template>
                    </p-menu>
            
                    <span class="cursor-pointer" (click)="menu1.toggle($event)">
                      <i-lucide name="ellipsis" [size]="12" color="#9CA3AF" class="my-icon"></i-lucide>
                    </span>
                  </span> -->

                  <p-progressSpinner styleClass="w-8 h-8"
                    *ngIf="currentIndex == i && isEditLoading"></p-progressSpinner>
                  <span class="inline-flex text-blue-500 underline text-xs font-medium cursor-pointer"
                    (click)="openEditTender(item, i)" *ngIf="currentIndex != i"
                    [ngClass]="{'disabled': isEditLoading || isNavLoading}">
                    Edit
                  </span>
                  <!-- <span class="cursor-pointer">
                    <i-lucide name="ellipsis" [size]="12" color="#9CA3AF" class="my-icon"></i-lucide>
                  </span> -->
                </td>
                <td>
                  <p-progressSpinner styleClass="w-8 h-8" *ngIf="currentIndex == i && isNavLoading"></p-progressSpinner>
                  <span class="c-pointer" (click)="navigateTenderOpportunityDetails(item, i)" *ngIf="currentIndex != i"
                    [ngClass]="{'disabled': isEditLoading || isNavLoading}">
                    <i-lucide name="chevron-right" [size]="22" color="#9CA3AF" class="my-icon"></i-lucide>
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>